import {isNumber} from '@joomcode/deprecated-utils/guards';
import cn from 'classnames';
import {LabelProps} from './types';
import styles from './index.css';

export const getLabelContainerClassName = ({active, filled, disabled, invalid}: LabelProps): string =>
  cn(styles.labelContainer, {
    [styles.labelContainerEmpty]: !filled,
    [styles.labelContainerFilled]: filled,
    [styles.labelContainerActive]: active,
    [styles.labelContainerDisabled]: disabled,
    [styles.labelContainerInvalid]: invalid,
  });

export const shouldShowSelectedAmount = (
  selectedAmount: number | undefined,
  {filled}: LabelProps,
): selectedAmount is number => {
  return Boolean(filled && isNumber(selectedAmount) && selectedAmount > 1);
};
