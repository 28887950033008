import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CheckboxGroup, Option} from '@joomcode/joom-ui/CheckboxGroup';
import {LandmarkConfig, LandmarkType} from 'domain/officeMap/landmark/model';
import {landmarkPermissions} from 'domain/officeMap/landmark/model/landmarkPermissions';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {landmarkTypeMessages, messages} from './messages';
import styles from './OfficeLandmarkTypeFilter.css';

type Props = {
  landmarkConfig: LandmarkConfig;
  setLandmarkConfig: (landmarkConfig: LandmarkConfig) => void;
};

const landmarkTypes = getEnumValues(LandmarkType);

export function OfficeLandmarkTypeFilter({landmarkConfig, setLandmarkConfig}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const options: Option<LandmarkType>[] = useMemo(
    () =>
      landmarkTypes
        .filter((landmarkType) => {
          const permission = landmarkPermissions[landmarkType];
          return !permission || acl.hasPermission(permission);
        })
        .map((landmarkType) => ({
          value: landmarkType,
          label: intl.formatMessage(landmarkTypeMessages[landmarkType]),
        })),
    [acl, intl],
  );
  const values: LandmarkType[] = useMemo(
    () => landmarkTypes.filter((landmarkType) => landmarkConfig[landmarkType]),
    [landmarkConfig],
  );
  const onChange = useCallback(
    (checkedTypes: LandmarkType[]) => {
      setLandmarkConfig(arrayToObject(landmarkTypes, identity, (type) => checkedTypes.includes(type)));
    },
    [setLandmarkConfig],
  );

  return (
    <div className={styles.buttonControlsWrapper}>
      <CheckboxGroup<LandmarkType>
        ariaLabel={intl.formatMessage(messages.ariaLabel)}
        direction='columns'
        onChange={onChange}
        options={options}
        values={values}
      />
    </div>
  );
}
