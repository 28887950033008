import {Button} from '@joomcode/joom-ui/Button';
import {ReactComponent as EyeIcon} from '@joomcode/joom-ui/icons/core/eye.svg';
import {ReactComponent as CrossEyeIcon} from '@joomcode/joom-ui/icons/core/eyeHidden.svg';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export type Props = {
  isDataHidden: boolean;
  onClick: () => void;
};

export function SensitiveDataSwitch({isDataHidden, onClick}: Props) {
  const intl = useIntl();
  return (
    <Button
      size='m'
      kind='primary'
      intent='neutral'
      onClick={onClick}
      iconLeft={isDataHidden ? <EyeIcon /> : <CrossEyeIcon />}
    >
      {intl.formatMessage(isDataHidden ? messages.show : messages.hide)}
    </Button>
  );
}
