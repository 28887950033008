import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Hiring protocol',
    description: '[title] Hiring process panel title',
  },
  linkToOffer: {
    defaultMessage: 'Offer',
    description: 'Hiring process panel: offer link text',
  },
  linkToInterviewFeedback: {
    defaultMessage: 'Interviewers’ feedback',
    description: 'Hiring process panel: interviewers’ feedback link text',
  },
  loadingError: {
    defaultMessage: 'Failed to load hiring protocol. Try to refresh the page.',
    description: 'Hiring process panel: data loading error',
  },
  empty: {
    defaultMessage: 'No hiring protocol available for this Joomer.',
    description: 'Hiring process panel: empty message',
  },
});
