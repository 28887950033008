import {LandmarkType} from 'domain/officeMap/landmark/model';
import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Landmark types',
    description: 'Aria-label for landmark filters',
  },
});

export const landmarkTypeMessages = defineMessages<LandmarkType>({
  [LandmarkType.ROOM]: {
    defaultMessage: 'Meeting rooms',
    description: 'Map landmarks filter label',
  },
  [LandmarkType.FOOD]: {
    defaultMessage: 'Food',
    description: 'Map landmarks filter label',
  },
  [LandmarkType.WC]: {
    defaultMessage: 'WC',
    description: 'Map landmarks filter label',
  },
  [LandmarkType.FUN]: {
    defaultMessage: 'Chill',
    description: 'Map landmarks filter label',
  },
  [LandmarkType.PRINTER]: {
    defaultMessage: 'Printers',
    description: 'Map landmarks filter label',
  },
  [LandmarkType.ENTRANCE]: {
    defaultMessage: 'Entrances',
    description: 'Map landmarks filter label',
  },
  [LandmarkType.WARDROBE]: {
    defaultMessage: 'Coatrooms',
    description: 'Map landmarks filter label',
  },
  [LandmarkType.HELP]: {
    defaultMessage: 'Help',
    description: 'Map landmarks filter label',
  },
  [LandmarkType.SEAT_NUMBER]: {
    defaultMessage: 'Desk numbers',
    description: 'Map landmarks filter label',
  },
});
