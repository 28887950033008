import {intranetApi} from 'apiClient';
import {Employment, employmentSchema} from 'domain/employment/model';
import {UserId} from 'domain/user/model';

export type TerminateParams = {
  diff: Partial<Employment>;
  userId: UserId;
};

export const terminate = async ({userId, diff}: TerminateParams): Promise<Employment> => {
  const {data} = await intranetApi.post('v1/users/employments/terminate', diff, {params: {userId}});

  return employmentSchema.runWithException(data);
};
