import {getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {Grid} from '@joomcode/joom-ui/Grid';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Breadcrumb, Page} from 'components/widgets/Page';
import {LegalInfoPanel} from 'domain/legalInfo/widgets/Panel';
import {useUserStockOptionData} from 'domain/stockOption/holder/hooks/useUserStockOptionData';
import {StockOptionReadAccess} from 'domain/stockOption/holder/model/access';
import {StockOptionAgreementsPanel} from 'domain/stockOption/holder/widgets/AgreementsPanel';
import {StockOptionBuyoutsPanel} from 'domain/stockOption/holder/widgets/BuyoutsPanel';
import {StockOptionCalculatorPanel} from 'domain/stockOption/holder/widgets/CalculatorPanel';
import {StockOptionFaqPanel} from 'domain/stockOption/holder/widgets/FaqPanel';
import {StockOptionSummaryPanel} from 'domain/stockOption/holder/widgets/SummaryPanel';
import {useStockOptionViewModes} from 'domain/stockOption/viewMode/hooks/useModes';
import {StockOptionViewMode} from 'domain/stockOption/viewMode/model';
import {StockOptionModeTabs} from 'domain/stockOption/viewMode/widgets/Tabs';
import {useUserLegalInfo} from 'domain/user/hooks/useUserLegalInfo';
import {useUserLoader} from 'domain/user/hooks/useUserLoader';
import {UserFull} from 'domain/user/model';
import {getFullNameWithPossession} from 'domain/user/utils/getFullNameWithPossession';
import {InactiveUserCallout} from 'domain/user/widgets/InactiveUserCallout';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps, useParams} from 'react-router-dom';
import {pageTitles} from 'routes/stockOptions/titles';
import {stockOptionsUrls} from 'routes/stockOptions/urls';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function UserStockOptionsPage({selfUser}: Props) {
  const intl = useIntl();
  const {login} = useParams<{login: string}>();
  const {data: userData, dataState: userDataState, error: userError} = useUserLoader(login);
  const {showTabs, availableModes} = useStockOptionViewModes(selfUser);
  const {
    readAccess,
    data: userStockOptionData,
    dataState: userStockOptionDataState,
    clientErrorStatus: userStockOptionError,
  } = useUserStockOptionData(userData?.id, userData?.ancestorIds);
  const dataState = getConsolidatedDataState(userDataState, userStockOptionDataState);
  const {hasReadAccess: canReadLegalInfo} = useUserLegalInfo();

  if (userData && !readAccess) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler
      data={userData && userStockOptionData && {user: userData, stockOptionData: userStockOptionData}}
      state={dataState}
      entityError={userStockOptionError || userError}
    >
      {({user, stockOptionData}) => {
        const ownOptions = user.id === selfUser.id;
        let title = intl.formatMessage(pageTitles.title);
        let breadcrumbs: Breadcrumb[] | undefined;

        if (!ownOptions) {
          title = intl.formatMessage(pageTitles.userStockOptions, getFullNameWithPossession(user));
          breadcrumbs = [
            {
              title: intl.formatMessage(pageTitles.title),
              href: stockOptionsUrls.root(),
            },
          ];
        } else if (!showTabs) {
          title = intl.formatMessage(pageTitles.ownStockOptions);
        }

        return (
          <Page
            title={title}
            breadcrumbs={breadcrumbs}
            headerContent={
              user.isTerminated || (ownOptions && showTabs) ? (
                <>
                  {user.isTerminated && <InactiveUserCallout />}
                  {ownOptions && showTabs && (
                    <StockOptionModeTabs availableModes={availableModes} activeMode={StockOptionViewMode.MY} />
                  )}
                </>
              ) : undefined
            }
          >
            <Grid>
              {readAccess === StockOptionReadAccess.ANY ? (
                <>
                  <Grid.Item xl={canReadLegalInfo ? 6 : 12} md={12}>
                    <StockOptionSummaryPanel
                      summary={stockOptionData.summary}
                      readAccess={readAccess}
                      user={user}
                      showUser={!ownOptions}
                    />
                  </Grid.Item>
                  {canReadLegalInfo && (
                    <Grid.Item xl={6} md={12}>
                      <LegalInfoPanel userId={user.id} stretchHeight />
                    </Grid.Item>
                  )}
                </>
              ) : (
                <>
                  <Grid.Item xl={4} lg={6} md={12}>
                    <StockOptionSummaryPanel
                      summary={stockOptionData.summary}
                      readAccess={readAccess}
                      user={user}
                      showUser={!ownOptions}
                    />
                  </Grid.Item>
                  <Grid.Item xl={4} lg={6} md={12}>
                    <StockOptionCalculatorPanel />
                  </Grid.Item>
                  <Grid.Item xl={4} lg={12}>
                    <StockOptionFaqPanel />
                  </Grid.Item>
                </>
              )}
              <Grid.Item xl={12}>
                <StockOptionAgreementsPanel
                  user={user}
                  agreements={stockOptionData.agreements}
                  readAccess={readAccess}
                />
              </Grid.Item>
              {(readAccess === StockOptionReadAccess.ANY || stockOptionData.buyouts.length > 0) && (
                <Grid.Item xl={12}>
                  <StockOptionBuyoutsPanel user={user} buyouts={stockOptionData.buyouts} />
                </Grid.Item>
              )}
            </Grid>
          </Page>
        );
      }}
    </EntityPageStateHandler>
  );
}
