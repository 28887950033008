import {FieldInputNumber, validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {StockOptionAgreementCreateConfig} from 'domain/stockOption/agreement/api/create';
import {FieldStockOptionAgreementType} from 'domain/stockOption/agreement/widgets/FieldType';
import {StockOptionIssuerFormControl} from 'domain/stockOption/issuer/widgets/FormControl';
import React from 'react';
import {FormRenderProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  formId: string;
  onSubmit: FormRenderProps<FormState>['handleSubmit'];
};

export type FormState = Omit<StockOptionAgreementCreateConfig, 'userId'>;

export function StockOptionAgreementCreationForm({formId, onSubmit}: Props) {
  const intl = useIntl();

  return (
    <form onSubmit={onSubmit} id={formId}>
      <Grid>
        <Grid.Item xl={6} xs={12}>
          <FieldDate name='issueDate' label={intl.formatMessage(labels.issueDate)} required />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <StockOptionIssuerFormControl name='issuer' label={intl.formatMessage(labels.issuer)} required />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldStockOptionAgreementType
            name='type'
            label={intl.formatMessage(labels.type)}
            required
            validate={validateFilled}
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldInputNumber
            name='amount'
            label={intl.formatMessage(labels.amount)}
            required
            min={0}
            step={100}
            minExcluded
          />
        </Grid.Item>
      </Grid>
    </form>
  );
}
