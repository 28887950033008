import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination, useOffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {ResourceId} from 'domain/resource/model';
import {RoleBinding} from 'domain/roleBinding/model';
import {getActiveRoleBindingsFx} from 'domain/roleBinding/stores/active';
import {$activeRoleBindingList, $activeRoleBindingsCount} from 'domain/roleBinding/stores/active/state';
import {useStore} from 'effector-react';
import {useEffect, useMemo, useState} from 'react';
import {toaster} from 'services/toaster';

type PaginationOptions = {
  initialLimit: number;
  initialPage: number;
};

type ReturnShape = {
  activeRoleBindings: RoleBinding[];
  activeRoleBindingsDataState: DataState;
  activeRoleBindingsCount: number;
  activeRoleBindingsPagination: OffsetPagination;
  setActiveRoleBindingsOutdated: (isOutdated: boolean) => void;
};

export function useResourceActiveRoleBindings(
  resourceId: ResourceId,
  paginationOptions: PaginationOptions = {initialLimit: 25, initialPage: 1},
): ReturnShape {
  const {data, dataState} = useStore($activeRoleBindingList);
  const {count} = useStore($activeRoleBindingsCount);
  const pagination = useOffsetPagination(paginationOptions);
  const pagingAndFilterOptions = useMemo(() => {
    return {
      offset: pagination.offset,
      limit: pagination.limit,
      filters: {
        resourceId,
      },
    };
  }, [pagination.offset, pagination.limit, resourceId]);
  const [isOutdated, setOutdated] = useState(false);

  useEffect(() => {
    getActiveRoleBindingsFx(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
  }, [pagingAndFilterOptions]);

  useEffect(() => {
    if (isOutdated) {
      getActiveRoleBindingsFx(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
      setOutdated(false);
    }
  }, [isOutdated]);

  return {
    activeRoleBindings: data,
    activeRoleBindingsCount: count,
    activeRoleBindingsDataState: dataState,
    activeRoleBindingsPagination: pagination,
    setActiveRoleBindingsOutdated: setOutdated,
  };
}
