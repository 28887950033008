import {intranetApi} from 'apiClient';
import {Employment, EmploymentId, employmentSchema} from 'domain/employment/model';
import {UserId} from 'domain/user/model';

export type UpdateParams = {
  diff: Partial<Employment>;
  employmentId: EmploymentId;
  userId: UserId;
};

export const update = async ({diff, ...params}: UpdateParams): Promise<Employment> => {
  const {data} = await intranetApi.post('v1/users/employments/updateTerminationInformation', diff, {
    params,
  });

  return employmentSchema.runWithException(data);
};
