import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {getImage} from '@joomcode/deprecated-utils/image';
import {isBlank} from '@joomcode/deprecated-utils/string/isBlank';
import type {ValidationFunction} from '../../types';
import {ImageUrlValidatorErrorCode, ImageUrlValidatorOptions} from './types';

export const validateImageUrl: ValidationFunction<
  unknown,
  ImageUrlValidatorOptions,
  ImageUrlValidatorErrorCode
> = async (value, options = {}) => {
  if (isNullish(value) || (typeof value === 'string' && isBlank(value))) return undefined;

  if (typeof value !== 'string') {
    return {code: ImageUrlValidatorErrorCode.IMAGE_URL_INVALID, options};
  }

  try {
    await getImage(value);
  } catch {
    return {code: ImageUrlValidatorErrorCode.IMAGE_URL_CANNOT_BE_RESOLVED, options};
  }

  return undefined;
};
