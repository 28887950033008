import {defineMessages} from 'react-intl';
import {OfficeAlias} from '.';

export const officeAliasMessages = defineMessages<OfficeAlias>({
  [OfficeAlias.LISBON]: {
    defaultMessage: '🇵🇹 Lisbon',
    description: 'Office name',
  },
  [OfficeAlias.MOSCOW]: {
    defaultMessage: '🇷🇺 Moscow',
    description: 'Office name',
  },
  [OfficeAlias.CHINA]: {
    defaultMessage: '🇨🇳 Shenzhen',
    description: 'Office name',
  },
});
