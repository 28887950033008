import React, {ReactNode} from 'react';
import cn from 'classnames';
import {Item, ItemProps} from './Item';
import styles from './index.css';
import {GridGapMode} from './types';

export type {ItemProps as GridItemProps};

export type GridProps = {
  gapMode?: GridGapMode;
  children: ReactNode;
};

const GridComponent = React.forwardRef<HTMLDivElement, GridProps>(function Grid({children, gapMode = 'both'}, ref) {
  return (
    <div ref={ref} className={cn(styles.grid, styles[gapMode])}>
      {children}
    </div>
  );
});

export const Grid = Object.assign(GridComponent, {
  Item, // eslint-disable-line @typescript-eslint/naming-convention
});
