import {UserFull} from 'domain/user/model';
import {Feature, useFeature} from 'services/features';
import {useOfficePolicyReadAccess} from './useOfficePolicyReadAccess';

export function useOfficePolicyAvailability(user: UserFull | undefined): boolean {
  const officePolicyFeature = useFeature(Feature.OFFICE_POLICY);
  const readAccess = useOfficePolicyReadAccess(user);

  return officePolicyFeature.isAvailable && readAccess !== null;
}
