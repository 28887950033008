import cn from 'classnames';
import * as React from 'react';
import {isFunction} from '@joomcode/deprecated-utils/guards';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../create-locator';
import styles from './index.css';
import {CloseButton} from '../TagCloseButton';

export type InputTestId = ConvertLocatorToTestId<InputLocator>;
export type InputLocator = LocatorOfElement<void>;

export enum InputSizeMeasure {
  L = 'l',
  M = 'm',
  XL = 'xl',
}

export type InputProps = JSX.IntrinsicElements['input'] & {
  inputSize?: `${InputSizeMeasure}`;
  invalid?: boolean;
  onClear?: () => void;
} & Partial<Mark<InputLocator>>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  {className, invalid, onClear, inputSize = 'm', ...restPropertiesWithMark}: InputProps,
  ref,
) {
  const locator = createLocator(restPropertiesWithMark);
  const restProps = removeMarkFromProperties(restPropertiesWithMark);

  const showClear = isFunction(onClear) && restProps.type !== 'date';

  const input = (
    <input
      {...restProps}
      ref={ref}
      className={cn(className, styles.input, styles[`inputSize${capitalize(inputSize)}` as keyof typeof styles], {
        [styles.controlInvalid]: invalid,
        [styles.clearShown]: showClear,
      })}
      {...locator()}
    />
  );

  if (showClear) {
    return (
      <span className={styles.clearFix}>
        {input}
        <span className={styles.clearButton}>
          <CloseButton onClick={onClear} />
        </span>
      </span>
    );
  }
  return input;
});
