import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  updateButton: {
    defaultMessage: 'Update',
    description: '[button] Update button',
  },
  deleteButton: {
    defaultMessage: 'Delete',
    description: '[button] Delete button',
  },
  confirmDeleteTitle: {
    defaultMessage: 'Delete runtime config item',
    description: '[title] Confirm dialog title',
  },
  confirmDeleteText: {
    defaultMessage:
      'Deleting runtime config item may break certain functionality. Please do it only if you know exactly what you are doing.',
    description: 'Confirm dialog text',
  },
  confirmUpdateTitle: {
    defaultMessage: 'Update runtime config item',
    description: '[title] Confirm dialog title',
  },
  confirmUpdateText: {
    defaultMessage:
      'Updating runtime config item may break certain functionality. Please do it only if you know exactly what you are doing.',
    description: 'Confirm dialog text',
  },
});
