import React from 'react';
import cn from 'classnames';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../../../create-locator';
import styles from './index.css';

export type SelectedValueTestId = ConvertLocatorToTestId<SelectedValueLocator>;
export type SelectedValueLocator = Locator<void>;

type Props<Item> = {
  placeholder?: string;
  item: Item | null;
  renderValue: (item: Item) => React.ReactNode;
  disabled: boolean;
} & Partial<Mark<SelectedValueLocator>>;

export function SelectedValue<Item>({placeholder, item, renderValue, disabled, ...restProps}: Props<Item>) {
  const locator = createLocator(restProps);

  return item !== null ? (
    <span
      className={cn(styles.selectedValue, {
        [styles.selectedValueDisabled]: disabled,
      })}
      {...locator()}
    >
      {renderValue(item)}
    </span>
  ) : (
    <span className={styles.placeholder}>{placeholder}</span>
  );
}
