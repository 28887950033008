import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  officeSeat: {
    defaultMessage: 'Seat {seatNumber}',
    description: 'User contact item label',
  },
  officeSeatWithoutNumber: {
    defaultMessage: 'Me on the office map',
    description: 'User contact item label',
  },
});
