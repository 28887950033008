import {array, ExtractSchemaType, object, boolean} from '@joomcode/deprecated-utils/jsonValidation';
import {legalEntitySchema} from 'domain/legalEntity/model';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {jobContractRecordSchema} from './record';

export const jobContractSchema = object({
  jobContractRecords: array(jobContractRecordSchema),
  legalEntity: legalEntitySchema,
  isPrimary: optionalWithDefault(false, boolean()),
});

export type JobContract = ExtractSchemaType<typeof jobContractSchema>;
