import {Grid} from '@joomcode/joom-ui/Grid';
import {SkeletonShape} from '@joomcode/joom-ui/Skeleton';
import {EmptyStockOptions} from 'domain/compensationReviewRequest/widgets/EmptyStockOptions';
import {Hint} from 'domain/compensationReviewRequest/widgets/Hint';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import {StockOptionSummary} from 'domain/stockOption/summary/model';
import {UserId} from 'domain/user/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  error?: unknown;
  initialStockOptions?: StockOptionSummary;
  loading?: boolean;
  stockOptions?: StockOptionSummary;
  userId: UserId;
};

export function StockOptions({error, initialStockOptions, loading, stockOptions, userId}: Props) {
  const intl = useIntl();

  if (error) {
    return <div className={styles.error}>{intl.formatMessage(messages.error)}</div>;
  }

  if (loading) {
    return <SkeletonShape kind='pill' width='50%' height='1.3125rem' />;
  }

  if (stockOptions) {
    return (
      <Grid>
        <Grid.Item xl={3} xs={6}>
          <div className={styles.item}>
            <div className={styles.label}>{intl.formatMessage(messages.granted)}</div>
            <FormattedOptionsAmount amount={stockOptions.granted.total} />
          </div>
          {!initialStockOptions && <Hint value={<EmptyStockOptions userId={userId} />} withStyles />}
          {initialStockOptions && initialStockOptions.granted.total !== stockOptions.granted.total && (
            <Hint value={<FormattedOptionsAmount amount={initialStockOptions.granted.total} />} withStyles />
          )}
        </Grid.Item>
        <Grid.Item xl={3} xs={6}>
          <div className={styles.item}>
            <div className={styles.label}>{intl.formatMessage(messages.nextYear)}</div>
            <FormattedOptionsAmount amount={stockOptions.nextYear} />
          </div>
          {initialStockOptions && initialStockOptions.nextYear !== stockOptions.nextYear && (
            <Hint value={<FormattedOptionsAmount amount={initialStockOptions.nextYear} />} withStyles />
          )}
        </Grid.Item>
        {initialStockOptions && (
          <>
            <Grid.Item xl={3} xs={6}>
              <div className={styles.item}>
                <div className={styles.label}>{intl.formatMessage(messages.vested)}</div>
                <FormattedOptionsAmount amount={stockOptions.vested} />
              </div>
            </Grid.Item>
            <Grid.Item xl={3} xs={6}>
              <div className={styles.item}>
                <div className={styles.label}>{intl.formatMessage(messages.available)}</div>
                <FormattedOptionsAmount amount={stockOptions.available} />
              </div>
            </Grid.Item>
          </>
        )}
      </Grid>
    );
  }

  return <EmptyStockOptions userId={userId} />;
}
