import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  value: React.ReactNode;
  withStyles?: boolean;
};

export function Hint({value, withStyles = false}: Props) {
  const intl = useIntl();
  const text = intl.formatMessage(messages.hint, {value});

  return withStyles ? <div className={styles.root}>{text}</div> : text;
}
