import {defineMessages} from 'react-intl';
import {StockOptionViewMode} from '.';

export const stockOptionViewModes = defineMessages<StockOptionViewMode>({
  [StockOptionViewMode.ALL]: {
    defaultMessage: 'All joomers',
    description: '[title] Stock option view mode name',
  },
  [StockOptionViewMode.SUBORDINATES]: {
    defaultMessage: 'My reports',
    description: '[title] Stock option view mode name',
  },
  [StockOptionViewMode.MY]: {
    defaultMessage: 'My options',
    description: '[title] Stock option view mode name',
  },
});
