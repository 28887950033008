import {FieldInput, validateFilled, validateUrl} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {CompanyUnitFormControl} from 'components/widgets/CompanyUnitFormControl';
import {FieldPhone} from 'components/widgets/fields/FieldPhone';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {UpdateGeneralInfoDiff} from 'domain/offer/api/updateGeneral';
import {Offer} from 'domain/offer/model';
import {OfferStatus} from 'domain/offer/model/status';
import {updateGeneralInfoFx} from 'domain/offer/stores/main';
import {FieldOfferPriority} from 'domain/offer/widgets/FieldPriority';
import {FieldUsers} from 'domain/user/widgets/FieldMultiple';
import React, {useCallback, useMemo} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {offerUrls} from 'routes/offer/urls';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {fieldLabels, messages} from './messages';
import styles from './styles.css';

const URL_FIELD_PLACEHOLDER = 'https://';

type FormState = UpdateGeneralInfoDiff;

type Props = {
  offer: Offer;
};

export function OfferEditGeneralForm({offer}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const formId = useMemo(uuid, []);
  const {units, dataState} = useCompanyUnits();
  const {
    general: {firstName, lastName, phone, referrers, priority, source, ticketLink, linkToInterviewFeedback},
    status,
  } = offer;
  const referrerIds = useMemo(() => referrers.map(({id}) => id) ?? [], [referrers]);

  const onSubmit = useCallback(
    (diff: FormState) =>
      updateGeneralInfoFx({id: offer.id, diff})
        .then(({id}) => history.push(offerUrls.offer({id})))
        .catch(toaster.interceptThenThrowError),
    [],
  );

  return (
    <StateHandler data={units} state={dataState}>
      {(companyUnits) => (
        <Form<FormState> onSubmit={onSubmit}>
          {({handleSubmit, valid, submitting}) => (
            <form onSubmit={handleSubmit} id={formId}>
              <Panel withPadding>
                <div className={styles.fieldsWrap}>
                  <Grid>
                    {status === OfferStatus.CREATED && (
                      <>
                        <Grid.Item xl={6} sm={12}>
                          <FieldInput
                            name='firstName'
                            label={intl.formatMessage(fieldLabels.firstName)}
                            required
                            reserveSpaceForError={false}
                            initialValue={firstName}
                          />
                        </Grid.Item>
                        <Grid.Item xl={6} sm={12}>
                          <FieldInput
                            name='lastName'
                            label={intl.formatMessage(fieldLabels.lastName)}
                            required
                            reserveSpaceForError={false}
                            initialValue={lastName}
                          />
                        </Grid.Item>
                      </>
                    )}
                    <Grid.Item sm={12} xl={6}>
                      <Field<string> name='phone'>
                        {({input, meta}) => (
                          <FormControl
                            label={intl.formatMessage(fieldLabels.phone)}
                            required
                            error={getFieldErrorText(meta, {intl})}
                            reserveSpaceForError={false}
                          >
                            {(formControlProps) => (
                              <FieldPhone
                                {...formControlProps}
                                initialValue={phone}
                                name={input.name}
                                validate={validateFilled}
                              />
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Grid.Item>
                    <Grid.Item sm={12} xl={6}>
                      <FormControl label={intl.formatMessage(fieldLabels.referrers)} reserveSpaceForError={false}>
                        {(formControlProps) => (
                          <FieldUsers
                            initialValue={referrerIds}
                            name='referrerIds'
                            suggestTerminatedUsers
                            {...formControlProps}
                          />
                        )}
                      </FormControl>
                    </Grid.Item>
                    <Grid.Item sm={8} xl={6}>
                      <CompanyUnitFormControl
                        name='source'
                        type='offerSource'
                        items={companyUnits.offerSources}
                        required
                        validate={validateFilled}
                        reserveSpaceForError={false}
                        initialValue={source}
                      />
                    </Grid.Item>
                    <Grid.Item sm={4} xl={6}>
                      <FieldOfferPriority
                        name='priority'
                        label={intl.formatMessage(fieldLabels.priority)}
                        required
                        validate={validateFilled}
                        reserveSpaceForError={false}
                        initialValue={priority}
                      />
                    </Grid.Item>
                    <Grid.Item xl={12}>
                      <FieldInput
                        name='ticketLink'
                        label={intl.formatMessage(fieldLabels.ticketLink)}
                        reserveSpaceForError={false}
                        placeholder={URL_FIELD_PLACEHOLDER}
                        initialValue={ticketLink}
                        validate={validateUrl}
                      />
                    </Grid.Item>
                    <Grid.Item xl={12}>
                      <FieldInput
                        name='linkToInterviewFeedback'
                        label={intl.formatMessage(fieldLabels.linkToInterviewFeedback)}
                        reserveSpaceForError={false}
                        placeholder={URL_FIELD_PLACEHOLDER}
                        initialValue={linkToInterviewFeedback}
                        validate={validateUrl}
                      />
                    </Grid.Item>
                  </Grid>
                </div>
              </Panel>
              <SubmitFormPanel>
                <ButtonGroup spaced size='l'>
                  <Button
                    kind='primary'
                    intent='primary'
                    type='submit'
                    form={formId}
                    disabled={!valid}
                    loading={submitting}
                  >
                    {intl.formatMessage(messages.buttonUpdate)}
                  </Button>
                  <RouterLinkButton size='l' intent='neutral' kind='secondary' to={offerUrls.offer({id: offer.id})}>
                    {intl.formatMessage(messages.buttonCancel)}
                  </RouterLinkButton>
                </ButtonGroup>
              </SubmitFormPanel>
            </form>
          )}
        </Form>
      )}
    </StateHandler>
  );
}
