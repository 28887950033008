import {StockOptionSummary} from 'domain/stockOption/summary/model';
import {defineMessages} from 'react-intl';

export const labels = defineMessages<keyof StockOptionSummary>({
  granted: {
    defaultMessage: 'Granted',
    description: 'Stock option summary label',
  },
  vested: {
    defaultMessage: 'Vested',
    description: 'Stock option summary label',
  },
  available: {
    defaultMessage: 'Available',
    description: 'Stock option summary label',
  },
  nextYear: {
    defaultMessage: 'Next 12 months',
    description: 'Stock option summary label',
  },
});

export const subrowLabels = defineMessages<keyof StockOptionSummary['granted']['byType']>({
  historic: {
    defaultMessage: 'Historic',
    description: 'Stock option summary label',
  },
  general: {
    defaultMessage: 'General',
    description: 'Stock option summary label',
  },
  performance: {
    defaultMessage: 'Performance',
    description: 'Stock option summary label',
  },
});

export const messages = defineMessages({
  title: {
    defaultMessage: 'Summary',
    description: '[title] Stock option summary panel title',
  },
});
