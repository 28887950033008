import {crApi} from 'domain/compaRatio/api';
import {CalculateCrConfig} from 'domain/compaRatio/api/calculateCr';
import {CrResult} from 'domain/compaRatio/model';
import {CrResultStatus} from 'domain/compaRatio/model/status';
import {CrBadge} from 'domain/compaRatio/widgets/Badge';
import pDebounce from 'p-debounce';
import React, {useCallback, useEffect, useState} from 'react';
import {OnChange} from 'react-final-form-listeners';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  fieldNames: string[];
  debouncedFieldNames: string[];
  getValuesForCalculating: () => CalculateCrConfig | undefined;
  onCalculate?: (cr: CrResult) => void;
};

const EMPTY_CR: CrResult = {
  status: CrResultStatus.OK,
  value: undefined,
  marketLevelPosition: undefined,
  error: undefined,
};

export function CalculatableCrBadge({fieldNames, debouncedFieldNames, getValuesForCalculating, onCalculate}: Props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [cr, setCr] = useState<CrResult>(EMPTY_CR);

  const calculateCr = useCallback(async () => {
    const values = getValuesForCalculating();
    if (values) {
      setLoading(true);
      crApi
        .calculateCr(values)
        .then((cr) => {
          setCr(cr);
          onCalculate?.(cr);
        })
        .catch(() => {
          const cr = {
            status: CrResultStatus.ERROR,
            value: undefined,
            marketLevelPosition: undefined,
            error: intl.formatMessage(messages.loadingError),
          };
          setCr(cr);
          onCalculate?.(cr);
        })
        .finally(() => setLoading(false));
    } else {
      setCr(EMPTY_CR);
    }
  }, [intl, getValuesForCalculating, onCalculate, setCr, setLoading]);
  const calculateCrWithDebounce = useCallback(pDebounce(calculateCr, 500), [calculateCr]);

  // In case of prefilled form
  useEffect(() => {
    calculateCr();
  }, []);

  return (
    <>
      {fieldNames.map((fieldName) => (
        <OnChange key={fieldName} name={fieldName}>
          {debouncedFieldNames.includes(fieldName) ? calculateCrWithDebounce : calculateCr}
        </OnChange>
      ))}
      <CrBadge cr={cr} loading={loading} />
    </>
  );
}
