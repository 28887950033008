import {Button} from '@joomcode/joom-ui/Button';
import {Permission} from 'domain/permission/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

type Props = {
  onClick(): void;
};

export function ResourceCreationButton({onClick}: Props) {
  const acl = useAcl();
  const intl = useIntl();

  if (!acl.hasPermission(Permission.RESOURCE_WRITE)) {
    return null;
  }

  return (
    <Button size='m' kind='primary' intent='primary' onClick={onClick}>
      {intl.formatMessage(messages.btnCreate)}
    </Button>
  );
}
