import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {ChangeValue} from 'domain/compensationReviewRequest/model/changeValue';
import {Status} from 'domain/compensationReviewRequest/model/status';
import {SecurePermission} from 'domain/permission/model/secure';
import {UserId} from 'domain/user/model';
import {Acl} from 'services/acl/types';
import {getCurrentDecision} from './decisions';

export function checkRequestNeedsReaction(
  {compensationChange, decisions, status, stockOptionsChange}: CompensationReviewRequest,
  selfUserId: UserId | undefined,
  acl: Acl,
): boolean {
  const currentDecision = getCurrentDecision(decisions);

  if (status === Status.IN_PROCESS && currentDecision?.user.id === selfUserId) {
    return true;
  }

  if (status === Status.APPROVED) {
    if (
      compensationChange === ChangeValue.YES &&
      acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_SET_COMPENSATIONS_RECORDED)
    ) {
      return true;
    }

    if (
      stockOptionsChange === ChangeValue.YES &&
      acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_SET_STOCK_OPTIONS_RECORDED)
    ) {
      return true;
    }
  }

  return false;
}
