import {noop} from '@joomcode/deprecated-utils/function';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {DialogInfoStripe} from 'components/ui/DialogInfoStripe';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {CompensationReviewRequestSetRecordedDiff} from 'domain/compensationReviewRequest/api/setRecorded';
import React, {useMemo} from 'react';
import {Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: FormProps<CompensationReviewRequestSetRecordedDiff>['onSubmit'];
};

export function CompensationReviewRequestDialogSetRecorded({isOpen, onClose, onSubmit}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  return (
    <Form<CompensationReviewRequestSetRecordedDiff> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid}) => (
        <Dialog
          ariaLabel={intl.formatMessage(messages.dialogAriaLabel)}
          isOpen={isOpen}
          onClose={submitting ? noop : onClose}
          width={600}
        >
          <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
          <Dialog.Body withDefaultPadding>
            <DialogInfoStripe>{intl.formatMessage(messages.description)}</DialogInfoStripe>
            <form onSubmit={handleSubmit} id={formId}>
              <FieldDate name='date' label={intl.formatMessage(messages.label)} required />
            </form>
          </Dialog.Body>
          <Dialog.Footer align='right'>
            <ButtonGroup spaced size='l'>
              <Button kind='text' intent='neutral' disabled={submitting} onClick={onClose}>
                {intl.formatMessage(messages.buttonCancel)}
              </Button>
              <Button
                form={formId}
                kind='primary'
                intent='primary'
                type='submit'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.buttonSubmit)}
              </Button>
            </ButtonGroup>
          </Dialog.Footer>
        </Dialog>
      )}
    </Form>
  );
}
