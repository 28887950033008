import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Target bonus',
    description: '[title] Compensation review request form: section title',
  },
  hint: {
    defaultMessage: 'A set reward an employee can earn by meeting specific goals within a given period.',
    description: 'Compensation review request: regular bonus title hint',
  },
});
