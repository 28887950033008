import {ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {fileMetaSchema} from 'models/system/fileMeta';

export const newcomerDocumentsSchema = object({
  bankDetails: optional(fileMetaSchema),
  idCard: optional(fileMetaSchema),
  insuranceNumberFile: optional(fileMetaSchema),
  internalPassportAddressPage: optional(fileMetaSchema),
  internalPassportMainPage: optional(fileMetaSchema),
  taxNumberFile: optional(fileMetaSchema),
});

export type NewcomerDocuments = ExtractSchemaType<typeof newcomerDocumentsSchema>;
