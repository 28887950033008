import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Bonuses',
    description: '[title] Regular bonus bulk upload panel title',
  },
});

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.PARSING_STATUS]: {
    defaultMessage: 'Parsed',
    description: 'Regular bonus bulk upload table column name',
  },
  [ColumnId.ROW_NUMBER]: {
    defaultMessage: 'Row #',
    description: 'Regular bonus bulk upload table column name',
  },
  [ColumnId.USER]: {
    defaultMessage: 'Joomer',
    description: 'Regular bonus bulk upload table column name',
  },
  [ColumnId.EMAIL]: {
    defaultMessage: 'Email',
    description: 'Regular bonus bulk upload table column name',
  },
  [ColumnId.START_DATE]: {
    defaultMessage: 'Start date',
    description: 'Regular bonus bulk upload table column name',
  },
  [ColumnId.END_DATE]: {
    defaultMessage: 'End date',
    description: 'Regular bonus bulk upload table column name',
  },
  [ColumnId.AMOUNT]: {
    defaultMessage: 'Amount',
    description: 'Regular bonus bulk upload table column name',
  },
  [ColumnId.FREQUENCY]: {
    defaultMessage: 'Frequency',
    description: 'Regular bonus bulk upload table column name',
  },
  [ColumnId.COMMENT]: {
    defaultMessage: 'Comment',
    description: 'Regular bonus bulk upload table column name',
  },
});
