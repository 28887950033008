import {QueryRequestPayload} from 'api/types';
import {CountableTimeOffRequests} from 'domain/timeOff/request/api/all';
import {TimeOffRequest, TimeOffRequestsSearchFilters} from 'domain/timeOff/request/model';
import {createEffect} from 'effector';

export const getAllTimeOffRequestsFx = createEffect<
  QueryRequestPayload<TimeOffRequestsSearchFilters>,
  TimeOffRequest[]
>('load all time off requests without count');
export const getAllTimeOffRequestsWithCountFx = createEffect<
  QueryRequestPayload<TimeOffRequestsSearchFilters>,
  CountableTimeOffRequests
>('load all time off requests with count');
