import {defineMessages} from 'react-intl';
import {LoginValidationResult} from '.';

export const loginAsyncValidationMessages = defineMessages<Exclude<LoginValidationResult, LoginValidationResult.OK>>({
  [LoginValidationResult.SIMILAR]: {
    defaultMessage: 'A similar login is already taken',
    description: 'Newcomer login validation error: similar login already exists',
  },
  [LoginValidationResult.INVALID]: {
    defaultMessage: 'Invalid login format',
    description: 'Newcomer login validation error: login is malformed',
  },
});
