import {Locale as DateFnsLocale} from 'date-fns';
import {Locale} from 'i18n/locale/types';
import {loadLocalePolyfills} from 'i18n/polyfills';
import {registerLocale} from 'react-datepicker';
import {IntlConfig} from 'react-intl';
import {LocaleData} from './types';

export const messages: {[key: string]: IntlConfig['messages'] | undefined} = {};
export const dateFnsLocales: {[key: string]: DateFnsLocale | undefined} = {};

const loading: {[key: string]: Promise<void>} = {};
const registered: Set<string> = new Set();

async function registerAppLocale(locale: Locale, data: LocaleData) {
  if (registered.has(locale)) {
    return;
  }

  await loadLocalePolyfills(locale);

  registerLocale(locale, data.dateLocale);
  messages[locale] = data.messages;
  registered.add(locale);
}

export function isLocaleLoaded(locale: Locale): boolean {
  return registered.has(locale);
}

export function isLocaleLoading(locale: Locale): boolean {
  return !!loading[locale];
}

export function loadLocale(locale: Locale): Promise<void> {
  if (isLocaleLoaded(locale)) {
    return Promise.resolve();
  }

  if (isLocaleLoading(locale)) {
    return loading[locale];
  }

  loading[locale] = import(`i18n/locales-data/${locale.toLowerCase()}` /* webpackChunkName: "[request]" */).then(
    (module) => registerAppLocale(locale, module.locale),
  );
  const removeLoading = () => {
    delete loading[locale];
  };
  loading[locale].then(removeLoading, removeLoading);

  return loading[locale];
}
