import {
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {crResultSchema} from 'domain/compaRatio/model';
import {offerSalarySchema} from 'domain/offer/model/salary';
import {FurtherSalaryReason} from './reason';

export const furtherSalarySchema = object({
  salary: offerSalarySchema,
  reason: oneOfEnum(FurtherSalaryReason),
  reasonDetails: optional(string()),
  employmentRate: number(),
  crResult: optional(crResultSchema),
});

export type FurtherSalary = ExtractSchemaType<typeof furtherSalarySchema>;
