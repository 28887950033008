import {csvErrorMessages} from './validators/csv';
import {emailErrorMessages} from './validators/email';
import {equalErrorMessages} from './validators/equal';
import {fileErrorMessages} from './validators/file';
import {filledErrorMessages} from './validators/filled';
import {idErrorMessages} from './validators/id';
import {imageErrorMessages} from './validators/image';
import {imageUrlErrorMessages} from './validators/imageUrl';
import {jsonErrorMessages} from './validators/json';
import {numberErrorMessages} from './validators/number';
import {stringErrorMessages} from './validators/string';
import {urlErrorMessages} from './validators/url';

export const errorMessages = {
  ...csvErrorMessages,
  ...emailErrorMessages,
  ...equalErrorMessages,
  ...fileErrorMessages,
  ...filledErrorMessages,
  ...idErrorMessages,
  ...imageErrorMessages,
  ...imageUrlErrorMessages,
  ...jsonErrorMessages,
  ...numberErrorMessages,
  ...stringErrorMessages,
  ...urlErrorMessages,
};
