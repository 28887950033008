import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {LegalEntityId} from 'domain/legalEntity/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {ExtendedSalaryRecord} from 'domain/salaryRecord/model';
import {AnySalaryRecordDiff} from 'domain/salaryRecord/model/diff';
import {
  createExtendedSalaryRecordFx,
  createSalaryRecordFx,
  replaceExtendedSalaryRecordFx,
  replaceSalaryRecordFx,
  updateExtendedSalaryRecordFx,
  updateSalaryRecordFx,
} from 'domain/salaryRecord/stores/main';
import {SalaryRecordForm} from 'domain/salaryRecord/widgets/Form';
import {UserId} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  userId: UserId;
  legalEntityId: LegalEntityId;
  recordToUpdate?: ExtendedSalaryRecord;
  needToReplace?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

type FormSubmitHandler = FormProps<AnySalaryRecordDiff>['onSubmit'];

export function SalaryRecordDialog({userId, legalEntityId, recordToUpdate, needToReplace, isOpen, onClose}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const extendedWriteMode = acl.hasSecurePermission(SecurePermission.SALARY_RECORD_WRITE_EXTENDED);
  const {units, dataState} = useCompanyUnits();
  const renderError = useCallback(
    () => (
      <Dialog.Body withDefaultPadding>
        <Error message={intl.formatMessage(messages.loadingError)} />
      </Dialog.Body>
    ),
    [intl],
  );
  const onSubmit: FormSubmitHandler = useCallback(
    (diff) => {
      const createFx = extendedWriteMode ? createExtendedSalaryRecordFx : createSalaryRecordFx;
      const updateFx = extendedWriteMode ? updateExtendedSalaryRecordFx : updateSalaryRecordFx;
      const replaceFx = extendedWriteMode ? replaceExtendedSalaryRecordFx : replaceSalaryRecordFx;

      if (recordToUpdate) {
        if (needToReplace) {
          return replaceFx({previousRecordId: recordToUpdate.id, userId, legalEntityId, diff})
            .then(onClose)
            .catch(toaster.interceptThenThrowError);
        }
        return updateFx({id: recordToUpdate.id, userId, legalEntityId, diff})
          .then(onClose)
          .catch(toaster.interceptThenThrowError);
      }
      return createFx({userId, legalEntityId, diff}).then(onClose).catch(toaster.interceptThenThrowError);
    },
    [extendedWriteMode, userId, legalEntityId, recordToUpdate, needToReplace, onClose],
  );
  const title = useMemo(() => {
    if (recordToUpdate) {
      if (needToReplace) {
        return intl.formatMessage(messages.titleReplace);
      }
      return intl.formatMessage(messages.titleUpdate);
    }
    return intl.formatMessage(messages.titleAdd);
  }, [intl, recordToUpdate, needToReplace]);

  return (
    <Dialog
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      isOpen={isOpen}
      onClose={onClose}
      width='min(90vw, 48rem)'
    >
      <Form<AnySalaryRecordDiff> onSubmit={onSubmit} subscription={{submitting: true, invalid: true}}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <StateHandler data={units} state={dataState} renderError={renderError}>
              {(companyUnits) => (
                <>
                  <Dialog.Header>{title}</Dialog.Header>
                  <Dialog.Body withDefaultPadding>
                    <SalaryRecordForm
                      formId={formId}
                      companyUnits={companyUnits}
                      recordToUpdate={recordToUpdate}
                      needToReplace={needToReplace}
                      extendedMode={extendedWriteMode}
                      onSubmit={handleSubmit}
                      submitting={submitting}
                    />
                  </Dialog.Body>
                  <Dialog.Footer>
                    <ButtonGroup spaced size='l'>
                      <Button kind='text' intent='neutral' onClick={onClose}>
                        {intl.formatMessage(messages.cancelButton)}
                      </Button>
                      <Button
                        form={formId}
                        kind='primary'
                        intent='primary'
                        type='submit'
                        disabled={invalid}
                        loading={submitting}
                      >
                        {intl.formatMessage(messages.submitButton)}
                      </Button>
                    </ButtonGroup>
                  </Dialog.Footer>
                </>
              )}
            </StateHandler>
          </>
        )}
      </Form>
    </Dialog>
  );
}
