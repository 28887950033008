import {FieldMetaState} from 'react-final-form';
import {IntlShape, MessageDescriptor} from 'react-intl';
import {formatError, isRootValidationError, ValidationErrorCode} from '../validation';
import {ValidationError, ValidationFormatter} from '../validation/types';
import {getFieldError} from './getFieldError';

type FieldMeta = FieldMetaState<unknown>;

export type ExtraMessages = {[key in ValidationErrorCode]: MessageDescriptor};

export type GetFieldErrorTextOptions = {
  intl?: IntlShape;
  extraMessages?: ExtraMessages;
  extraFormatters?: {[key in ValidationErrorCode]: ValidationFormatter<ValidationError<unknown>>};
};

export function getFieldErrorText<T>(
  meta: FieldMeta,
  {intl, extraMessages, extraFormatters}: GetFieldErrorTextOptions = {},
): string | null {
  const error = getFieldError<T>(meta);

  if (intl && isRootValidationError(error)) {
    return formatError(error, intl, extraMessages, extraFormatters);
  }

  if (error && typeof error === 'string') {
    return error;
  }

  return null;
}
