import {Page} from 'components/widgets/Page';
import {useManagerConsolePageTabs} from 'domain/managerConsole/hooks/useManagerConsolePageTabs';
import {ManagerConsoleTab} from 'domain/managerConsole/model/tabs';
import {ManagerConsolePageTabs} from 'domain/managerConsole/widgets/PageTabs';
import {SalaryRangesList} from 'domain/salaryRange/widgets/List';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/managerConsole/titles';

export function ManagerConsoleSalaryRangesPage() {
  const intl = useIntl();
  const {showTabs, availableTabs} = useManagerConsolePageTabs();

  return (
    <Page
      title={intl.formatMessage(pageTitles.managerConsole)}
      headerContent={
        showTabs && <ManagerConsolePageTabs availableTabs={availableTabs} activeTab={ManagerConsoleTab.SALARY_RANGES} />
      }
    >
      <SalaryRangesList />
    </Page>
  );
}
