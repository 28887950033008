/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import {toast} from 'react-toastify';
import {convertToastOptionsToToastCoreOptions, toastIconByToastType} from './utils';
import {ToastOptions, ToastType} from './types';
import {ToastContext} from './Context';

export class ToastService {
  protected show(type: ToastType, message: React.ReactNode, options?: ToastOptions) {
    toast(<ToastContext.Provider value={{type}}>{message}</ToastContext.Provider>, {
      type,
      icon: toastIconByToastType[type],
      ...convertToastOptionsToToastCoreOptions(options),
    });
  }

  // Public methods below are defined as arrow functions to keep the context of `this` when they are passed as callbacks.
  // Public methods should only call protected implementation methods.
  // Protected implementation methods are defined as usual to allow overriding them in subclasses.

  public info = (message: React.ReactNode, options?: ToastOptions) => {
    return this.infoImplementation(message, options);
  };

  protected infoImplementation(message: React.ReactNode, options?: ToastOptions) {
    return this.show('info', message, options);
  }

  public warning = (message: React.ReactNode, options?: ToastOptions) => {
    return this.warningImplementation(message, options);
  };

  protected warningImplementation(message: React.ReactNode, options?: ToastOptions) {
    return this.show('warning', message, options);
  }

  public error = (message: React.ReactNode | Error, options?: ToastOptions) => {
    return this.errorImplementation(message, options);
  };

  protected errorImplementation(message: React.ReactNode | Error, options?: ToastOptions) {
    return this.show('error', message instanceof Error ? message.toString() : message, options);
  }

  public success = (message: React.ReactNode, options?: ToastOptions) => {
    return this.successImplementation(message, options);
  };

  protected successImplementation(message: React.ReactNode, options?: ToastOptions) {
    return this.show('success', message, options);
  }

  /**
   * Intended to be used within promise.catch(toaster.interceptThenThrowError).
   */
  public interceptThenThrowError = (error: Error | string): never => {
    this.error(typeof error === 'string' ? error : error.message);
    throw error;
  };
}
