import React from 'react';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../../create-locator';
import {ReactComponent as CircleCrossIcon} from '../../icons/core/crossRounded.svg';
import styles from '../index.css';

export type CloseButtonTestId = ConvertLocatorToTestId<CloseButtonLocator>;
export type CloseButtonLocator = Locator<void>;

type Props = Partial<Mark<CloseButtonLocator>> & {
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  ariaLabel?: string;
};

export function CloseButton({disabled, onClick, ariaLabel, ...restProps}: Props) {
  const locator = createLocator(restProps);

  return (
    <button
      type='button'
      {...locator()}
      aria-label={ariaLabel}
      className={styles.button}
      disabled={disabled}
      onClick={onClick}
    >
      <CircleCrossIcon className={styles.icon} />
    </button>
  );
}
