import {useSortable} from '@dnd-kit/sortable';
import {isNotNullish, isNullish} from '@joomcode/deprecated-utils/function';
import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import React, {memo, useCallback} from 'react';
import cn from 'classnames';
import {omit} from '@joomcode/deprecated-utils/object/omit';
import {useDisclosureState, useDisclosureUi} from '../Disclosure';
import styles from './index.css';
import {TreeViewProps} from './types';
import {deepSome} from './utils';
import {Checkbox} from '../Checkbox';
import {ReactComponent as ArrowLeftIcon} from '../icons/core/arrowRightThin.svg';
import {TreeList} from './List';

type ItemProps<T> = Omit<TreeViewProps<T>, 'items'> & {
  item: T;
  level: number;
  sortable?: ReturnType<typeof useSortable>;
  overItemKey?: ReturnType<TreeViewProps<T>['getItemKey']> | null;
  activeItemKey?: ReturnType<TreeViewProps<T>['getItemKey']> | null;
  itemComponent?: typeof TreeItemLayout | typeof TreeItem;
};

export const TreeItemLayout = memo(function TreeItemLayout<T>(props: ItemProps<T>) {
  const {
    expanded,
    filterItem,
    item,
    level,
    onItemSelect,
    onItemDeselect,
    getItemChildren,
    getItemKey,
    isItemSelected,
    isItemDisabled = () => false,
    isItemSelectable = () => true,
    isItemIndeterminate,
    multiselect,
    renderItem,
    overItemKey,
    activeItemKey,
    padForButton = false,
    draggable = false,
    hoverable = false,
    sortable = null,
    itemComponent = TreeItemLayout,
  } = props;
  const disclosure = useDisclosureState({
    initiallyExpanded: () => {
      if (isNotNullish(expanded)) {
        return expanded;
      }

      return isItemSelected(item) || deepSome(item, getItemChildren, isItemSelected);
    },
  });
  const disclosureUi = useDisclosureUi(String(getItemKey(item)), disclosure);
  useUpdateEffect(() => {
    if (isNotNullish(expanded) && disclosure.isExpanded !== expanded) {
      disclosure.toggle();
    } else if (isNullish(expanded) && disclosure.isExpanded !== isItemSelected(item)) {
      disclosure.toggle();
    }
  }, [expanded]);

  const itemKey = getItemKey(item);
  const isOverThisItem = itemKey === overItemKey;
  const isActiveSortableItem = sortable && itemKey === activeItemKey;

  const children = getItemChildren(item);
  const disabled = isItemDisabled(item);
  const selectable = isItemSelectable(item);

  const toggle = useCallback(() => {
    return isItemSelected(item) ? onItemDeselect(item) : onItemSelect(item);
  }, [isItemSelected, onItemSelect, onItemDeselect, item]);

  const isExpanded = Boolean(children?.length) && disclosure.isExpanded;

  if (filterItem && !filterItem(item)) {
    return null;
  }

  const hasButton = Boolean(children?.length);

  let sortableAttributes = {};
  if (sortable && draggable) {
    const {attributes, listeners, setNodeRef} = sortable;

    sortableAttributes = {...attributes, ...listeners, ref: setNodeRef};
  }

  const ChildItemComponent = itemComponent;

  return (
    <li
      className={cn(styles.item, styles[`level${level}` as keyof typeof styles], {
        [styles.isOver]: isOverThisItem,
        [styles.isActive]: isActiveSortableItem,
        [styles.isHoverable]: hoverable,
      })}
    >
      <div
        className={cn(styles.line, {
          [styles.lineDisabled]: disabled,
          [styles.lineExpanded]: isExpanded,
          [styles.lineSelected]: isItemSelected(item),
        })}
        {...sortableAttributes}
      >
        {multiselect && (
          <Checkbox
            className={styles.checkbox}
            disabled={disabled || !selectable}
            checked={isItemSelected(item)}
            indeterminate={isItemIndeterminate?.(item)}
            onChange={toggle}
            tabIndex={-1}
          />
        )}

        <div className={cn(styles.button, {[styles.padForButton]: padForButton && !hasButton})}>
          {hasButton && (
            <button
              className={cn(styles.icon, {[styles.iconExpanded]: disclosure.isExpanded})}
              {...disclosureUi.getButtonProps()}
              tabIndex={-1}
              type='button'
            >
              <ArrowLeftIcon />
            </button>
          )}

          <button
            disabled={disabled || !selectable}
            className={styles.label}
            onClick={toggle}
            tabIndex={-1}
            type='button'
          >
            {renderItem(item)}
          </button>
        </div>
      </div>

      {children && (
        <TreeList {...disclosureUi.getContentProps()}>
          {isExpanded &&
            children.map((child) => (
              <ChildItemComponent
                {...omit(props, ['sortable'])}
                item={child}
                level={level + 1}
                key={getItemKey(child)}
              />
            ))}
        </TreeList>
      )}
    </li>
  );
});

type SortableItemProps<T> = Omit<ItemProps<T>, 'sortable'>;

export const TreeItem = function SortableTreeItem<T>(props: SortableItemProps<T>) {
  const {getItemKey, item} = props;
  const sortable = useSortable({id: getItemKey(item)});

  return <TreeItemLayout sortable={sortable} {...props} itemComponent={SortableTreeItem} />;
};
