import React from 'react';
import styles from './styles.css';

type Props = {
  value: React.ReactNode;
};

export function NewValue({value}: Props) {
  return <div className={styles.root}>{value}</div>;
}
