import {QueryRequestPayload} from 'api/types';
import {CreateOfferDiff} from 'domain/offer/api/create';
import {EnhanceOfferConfig} from 'domain/offer/api/enhance';
import {OfferSetAcceptedConfig} from 'domain/offer/api/setAccepted';
import {OfferSetDeclinedConfig} from 'domain/offer/api/setDeclined';
import {UpdateOfferConfig} from 'domain/offer/api/update';
import {UpdateGeneralInfoConfig} from 'domain/offer/api/updateGeneral';
import {UpdateLoginConfig} from 'domain/offer/api/updateLogin';
import {UpdateStartWorkingDateConfig} from 'domain/offer/api/updateStartWorkingDate';
import {CountableOffers, Offer, OfferId} from 'domain/offer/model';
import {OffersSearchFilters} from 'domain/offer/model/filters';
import {NewcomerInOffer} from 'domain/offer/model/newcomer';
import {createEffect} from 'effector';

export const approveNewcomerApplicationFx = createEffect<OfferId, Offer>('approve offer application');
export const createOfferFx = createEffect<CreateOfferDiff, Offer>('create offer');
export const enhanceOfferFx = createEffect<EnhanceOfferConfig, Offer>('enhance offer');
export const getOfferByIdFx = createEffect<OfferId, Offer>('load offer by id');
export const getOffersFx = createEffect<QueryRequestPayload<OffersSearchFilters>, Offer[]>(
  'load filtered offers with pagination',
);
export const getOffersWithCountFx = createEffect<QueryRequestPayload<OffersSearchFilters>, CountableOffers>(
  'load filtered offers with pagination and count',
);
export const setOfferAcceptedFx = createEffect<OfferSetAcceptedConfig, Offer>('set accepted as offer status');
export const setOfferDeclinedFx = createEffect<OfferSetDeclinedConfig, Offer>('set declined as offer status');
export const updateGeneralInfoFx = createEffect<UpdateGeneralInfoConfig, Offer>('update offer general info');
export const updateNewcomerLoginFx = createEffect<UpdateLoginConfig, NewcomerInOffer>('update newcomer login');
export const updateNewcomerStartWorkingDateFx = createEffect<UpdateStartWorkingDateConfig, NewcomerInOffer>(
  'update newcomer start working date',
);
export const updateOfferFx = createEffect<UpdateOfferConfig, Offer>('update offer details');
