import ipRegex from 'ip-regex';
import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {isBlank} from '@joomcode/deprecated-utils/string/isBlank';
import type {ValidationFunction} from '../../types';
import {UrlValidatorErrorCode, UrlValidatorOptions} from './types';

const PROTOCOL = `(?:(?:[a-z]+:)?//)`;
const AUTH = '(?:\\S+(?::\\S*)?@)?';
const IP = ipRegex.v4().source;
const HOST = '(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)';
const DOMAIN = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
const TLD = `(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?`;
const PORT = '(?::\\d{2,5})?';
const PATH = '(?:[/?#][^\\s"]*)?';
const REGEX = `(?:${PROTOCOL}|www\\.)${AUTH}(?:localhost|${IP}|${HOST}${DOMAIN}${TLD})${PORT}${PATH}`;

export const validateUrl: ValidationFunction<unknown, UrlValidatorOptions, UrlValidatorErrorCode> = (
  value,
  options = {},
) => {
  const {schemas = []} = options;
  if (isNullish(value) || (typeof value === 'string' && isBlank(value))) return undefined;

  if (typeof value !== 'string') {
    return {code: UrlValidatorErrorCode.URL_INVALID, options};
  }

  if (schemas.length > 0 && schemas.every((schema) => !value.toLowerCase().startsWith(`${schema.toLowerCase()}:`))) {
    return {code: UrlValidatorErrorCode.URL_INVALID_SCHEMA, options};
  }

  if (!new RegExp(`(?:^${REGEX}$)`, 'i').test(value)) {
    return {code: UrlValidatorErrorCode.URL_INVALID_FORMAT, options};
  }

  return undefined;
};
