export function createMockId(): string {
  const alphabet = 'abcdef0123456789';
  let result = '';

  for (let i = 0; i < 24; i++) {
    result += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
  }

  return result;
}
