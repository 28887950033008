import {SectionHeader} from 'domain/compensationReviewRequest/widgets/SectionHeader';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  onReset?: () => void;
};

export function OneTimeBonusHeader({onReset}: Props) {
  const intl = useIntl();

  return (
    <SectionHeader
      title={intl.formatMessage(messages.title)}
      hint={intl.formatMessage(messages.hint)}
      onReset={onReset}
    />
  );
}
