import {QueryRequestPayload} from 'api/types';
import {intranetApi} from 'apiClient';
import {CountableStockOptionHolders, countableStockOptionHolderSchema} from 'domain/stockOption/holder/model';
import {SubordinateStockOptionHoldersFilters} from 'domain/stockOption/holder/model/filters';

export const getSubordinates = async ({
  filters,
  offset,
  limit,
}: QueryRequestPayload<SubordinateStockOptionHoldersFilters>): Promise<CountableStockOptionHolders> => {
  const {data} = await intranetApi.post('/v1/secure/stockOptions/holders/getSubordinates', filters, {
    params: {offset, limit},
  });

  return countableStockOptionHolderSchema.runWithException(data);
};
