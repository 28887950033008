import {RegularBonusRecordDiff} from 'domain/regularBonusRecord/model/diff';
import {RegularBonusType} from 'domain/regularBonusRecord/model/type';
import {RegularBonusValue} from 'domain/regularBonusRecord/model/value';

const DEFAULT_BASIS = 'annual salary';

export function convertRegularBonusValue(
  type: RegularBonusType,
  value: RegularBonusValue,
): RegularBonusRecordDiff['value'] {
  return {
    absoluteValue:
      type === RegularBonusType.ABSOLUTE && value.absoluteValue
        ? {
            ...value.absoluteValue,
            type,
          }
        : undefined,
    percentValue:
      type === RegularBonusType.PERCENT && value.percentValue
        ? {
            ...value.percentValue,
            comment: DEFAULT_BASIS,
            type,
          }
        : undefined,
  };
}
