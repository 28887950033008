import {defineMessages} from 'react-intl';

export const generalMessages = defineMessages({
  fullName: {
    defaultMessage: '{firstName} {lastName}',
    description: 'Person’s full name',
  },
  yes: {
    defaultMessage: 'Yes',
    description: 'General translation, value of boolean flag',
  },
  no: {
    defaultMessage: 'No',
    description: 'General translation, value of boolean flag',
  },
  partiallyFilledError: {
    defaultMessage: 'Not all the required fields are filled',
    description: 'General form submission error',
  },
});
