import {intranetApi} from 'apiClient';
import {OfficeSeat, OfficeSeatId, officeSeatSchema} from 'domain/officeMap/seat/model';

export type SeatDiff = Partial<OfficeSeat>;
export type SeatUpdateConfig = {
  id: OfficeSeatId;
  diff: SeatDiff;
};

export const updateSeat = async ({id, diff}: SeatUpdateConfig): Promise<OfficeSeat> => {
  const {data} = await intranetApi.post('/v1/offices/seats/update', diff, {params: {id}});

  return officeSeatSchema.runWithException(data);
};
