import {defineMessages} from 'react-intl';
import {RoleBindingStatus} from '.';

export const roleBindingStatuses = defineMessages<RoleBindingStatus>({
  [RoleBindingStatus.APPROVED]: {
    defaultMessage: 'Approved',
    description: 'Role binding status',
  },
  [RoleBindingStatus.CANCELED]: {
    defaultMessage: 'Canceled',
    description: 'Role binding status',
  },
  [RoleBindingStatus.PENDING]: {
    defaultMessage: 'Pending',
    description: 'Role binding status',
  },
  [RoleBindingStatus.REJECTED]: {
    defaultMessage: 'Rejected',
    description: 'Role binding status',
  },
  [RoleBindingStatus.REVOKED]: {
    defaultMessage: 'Revoked',
    description: 'Role binding status',
  },
});
