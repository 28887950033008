import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {isBlank} from '@joomcode/deprecated-utils/string/isBlank';
import type {ValidationFunction} from '../../types';
import {FilledValidatorErrorCode, FilledValidatorOptions} from './types';

export const validateFilled: ValidationFunction<unknown, FilledValidatorOptions, FilledValidatorErrorCode> = (
  value,
  options = {},
) => {
  const {trimBeforeCheck, checkEmptyArrays, checkEmptyObjects} = options;
  const defaultError = {code: FilledValidatorErrorCode.FILLED_EMPTY, options};

  if (isNullish(value)) {
    return defaultError;
  }

  if (typeof value === 'string') {
    if ((trimBeforeCheck && isBlank(value)) || !value) {
      return defaultError;
    }
  }

  if (checkEmptyArrays && Array.isArray(value) && value.length === 0) {
    return defaultError;
  }

  if (checkEmptyObjects && typeof value === 'object' && isNotNullish(value) && Object.keys(value).length === 0) {
    return defaultError;
  }

  return undefined;
};
