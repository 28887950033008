import {constant, ExtractSchemaType, number, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {currencySchema} from 'domain/currency/model';
import {RegularBonusType} from 'domain/regularBonusRecord/model/type';

const regularBonusAbsoluteValueSchema = object({
  amount: number(),
  currency: currencySchema,
  type: constant(RegularBonusType.ABSOLUTE),
});

const regularBonusPercentValueSchema = object({
  amount: number(),
  comment: string(),
  type: constant(RegularBonusType.PERCENT),
});

export const regularBonusValueSchema = object({
  percentValue: optional(regularBonusPercentValueSchema),
  absoluteValue: optional(regularBonusAbsoluteValueSchema),
});

export type RegularBonusValue = ExtractSchemaType<typeof regularBonusValueSchema>;
