import {ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {CountryCode} from 'domain/countryCode/model';

export const legalAddressSchema = object({
  addressLine1: string(),
  addressLine2: optional(string()),
  city: optional(string()),
  country: optional(oneOfEnum(CountryCode)),
  region: optional(string()),
  zip: optional(string()),
});

export type LegalAddress = ExtractSchemaType<typeof legalAddressSchema>;
