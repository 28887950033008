import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Approximate sum of daily allowance',
    description: '[title] Daily allowance calculator title',
  },
  hint: {
    defaultMessage:
      'The daily allowance can be spent on food and personal needs during the trip. Receipts for these costs are not required.',
    description: 'Daily allowance calculator hint',
  },
});
