import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Edit Resource',
    description: '[title] Resource update dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Resource update dialog',
    description: 'Resource update dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Resource update dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Save',
    description: '[button] Resource update dialog: submit button',
  },
});
