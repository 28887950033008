import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  show: {
    defaultMessage: 'Show data',
    description: '[button] Sensitive data switch',
  },
  hide: {
    defaultMessage: 'Hide data',
    description: '[button] Sensitive data switch',
  },
});
