import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {useCallback, useState} from 'react';

export enum OfferToggableSection {
  STOCK_OPTIONS = 'stockOptions',
  FURTHER_SALARY = 'furtherSalary',
}

export type OfferToggableSections = {
  sections: OfferToggableSection[];
  enableStockOptions(): void;
  toggleStockOptions(): void;
  toggleFurtherSalary(): void;
  hasStockOptions: boolean;
  hasFurtherSalary: boolean;
};

type Props = {
  hasStockOptions: boolean;
  hasFurtherSalary: boolean;
};

export function useOfferToggableSections(props: Props): OfferToggableSections {
  const hasStockOptions = useBooleanState(props.hasStockOptions);
  const hasFurtherSalary = useBooleanState(props.hasFurtherSalary);
  const [sections, setSections] = useState(() => {
    const sectionsVisibility = {
      [OfferToggableSection.STOCK_OPTIONS]: hasStockOptions.value,
      [OfferToggableSection.FURTHER_SALARY]: hasFurtherSalary.value,
    };
    return Object.entries(sectionsVisibility)
      .filter(([, visible]) => visible)
      .map(([section]) => section as OfferToggableSection);
  });

  const enableStockOptions = useCallback(() => {
    if (hasStockOptions.value) {
      return;
    }

    hasStockOptions.setTrue();
    setSections([...sections, OfferToggableSection.STOCK_OPTIONS]);
  }, [hasStockOptions.value, sections]);

  const toggleStockOptions = useCallback(() => {
    if (hasStockOptions.value) {
      setSections(sections.filter((section) => section !== OfferToggableSection.STOCK_OPTIONS));
    } else {
      setSections([...sections, OfferToggableSection.STOCK_OPTIONS]);
    }

    hasStockOptions.toggle();
  }, [hasStockOptions.value, sections]);

  const toggleFurtherSalary = useCallback(() => {
    if (hasFurtherSalary.value) {
      setSections(sections.filter((section) => section !== OfferToggableSection.FURTHER_SALARY));
    } else {
      setSections([...sections, OfferToggableSection.FURTHER_SALARY]);
    }

    hasFurtherSalary.toggle();
  }, [hasFurtherSalary.value, sections]);

  return {
    sections,
    enableStockOptions,
    toggleStockOptions,
    toggleFurtherSalary,
    hasStockOptions: sections.includes(OfferToggableSection.STOCK_OPTIONS),
    hasFurtherSalary: sections.includes(OfferToggableSection.FURTHER_SALARY),
  };
}
