import {TeamPath} from 'domain/team/widgets/Path';
import {UserMemberships} from 'domain/user/model/memberships';
import sortBy from 'lodash/sortBy';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import styles from './styles.css';

type Props = {
  memberships: UserMemberships;
};

export function UserMembershipList({memberships}: Props) {
  const intl = useIntl();
  const sortedMemberships: UserMemberships = useMemo(
    () =>
      sortBy(
        memberships,
        ({teamAncestors}) => teamAncestors.length, // sort memberships by team depth
      ),
    [memberships],
  );
  return (
    <>
      {sortedMemberships.map(({team, roles, teamAncestors}) => (
        <div className={styles.membership} key={team.id}>
          <TeamPath team={team} teamAncestors={teamAncestors} />
          <div className={styles.roles}>
            {intl.formatList(
              roles.map((role) => (
                <span className={styles.role} key={role.id}>
                  {role.name}
                </span>
              )),
              {type: 'conjunction', style: 'narrow'},
            )}
          </div>
        </div>
      ))}
    </>
  );
}
