import {StringValidatorErrorCode} from '@joomcode/joom-form';
import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  createButton: {
    defaultMessage: 'Create',
    description: '[button] Redirect route form: create button',
  },
  wildcardHintHeader: {
    defaultMessage: 'Allows to create catch-all links.',
    description: 'Redirect route form: Wildcard hint header',
  },
  wildcardHintExample: {
    defaultMessage:
      'For example, when you have wildcard <example>go/x/</example> → <example>x.com/</example>, opening <example>go/x/</example><emphasis>123</emphasis> will get you to <example>x.com/</example><emphasis>123</emphasis>',
    description: 'Redirect route form: Wildcard hint example',
  },
});

export const fieldLabels = defineMessages({
  domain: {
    defaultMessage: 'Domain',
    description: 'Redirect route form: Field label',
  },
  path: {
    defaultMessage: 'Path',
    description: 'Redirect route form: Field label',
  },
  isWildcard: {
    defaultMessage: 'Wildcard',
    description: 'Redirect route form: Field label',
  },
  target: {
    defaultMessage: 'Target',
    description: 'Redirect route form: Field label',
  },
});

export const domainErrorMessages = defineMessages({
  [StringValidatorErrorCode.STRING_REG_EXP_NOT_MATCH]: {
    defaultMessage: 'Incorrect domain',
    description: 'Redirect route form: error message',
  },
});

export const pathErrorMessages = defineMessages({
  [StringValidatorErrorCode.STRING_REG_EXP_NOT_MATCH]: {
    defaultMessage: 'Incorrect path',
    description: 'Redirect route form: error message',
  },
});
