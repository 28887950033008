import {Page} from 'components/widgets/Page';
import {OfferCreateForm} from 'domain/offer/widgets/CreateForm';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/offer/titles';
import {offerUrls} from 'routes/offer/urls';

export function OfferCreatePage() {
  const intl = useIntl();

  return (
    <Page
      title={intl.formatMessage(pageTitles.createOffer)}
      breadcrumbs={[{title: intl.formatMessage(pageTitles.offers), href: offerUrls.root()}]}
    >
      <OfferCreateForm />
    </Page>
  );
}
