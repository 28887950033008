import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages({
  [ColumnId.NAME]: {
    defaultMessage: 'Name',
    description: '[title] Service table column name',
  },
  [ColumnId.DESCRIPTION]: {
    defaultMessage: 'Description',
    description: '[title] Service table column name',
  },
  [ColumnId.OWNERS]: {
    defaultMessage: 'Owners',
    description: '[title] Service table column name',
  },
});

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No services',
    description: 'Service table: empty',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load services',
    description: 'Service table: error',
  },
});
