import {
  boolean,
  dateFromString,
  ExtractSchemaType,
  intersection,
  object,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {employmentTypeSchema} from 'domain/employmentType/model';
import {legalEntitySchema} from 'domain/legalEntity/model';
import {userSchema} from 'domain/user/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const jobInfoRecordIdSchema = idSchema;

export const jobInfoRecordBaseSchema = object({
  active: optionalWithDefault(false, boolean()),
  createdTime: dateFromString(),
  editable: optionalWithDefault(false, boolean()),
  effectiveDate: formattedDateSchema,
  employmentType: optional(employmentTypeSchema),
  department: string(),
  division: string(),
  id: jobInfoRecordIdSchema,
  jobTitle: string(),
  location: string(),
});

export const jobInfoRecordSchema = intersection(
  jobInfoRecordBaseSchema,
  object({
    legalEntity: optional(legalEntitySchema),
    manager: optional(userSchema),
  }),
);

export type JobInfoRecord = ExtractSchemaType<typeof jobInfoRecordSchema>;
export type JobInfoRecordId = ExtractSchemaType<typeof jobInfoRecordIdSchema>;
