import {defineMessages} from 'react-intl';
import {FilledValidatorErrorCode} from './types';

export const filledErrorMessages = defineMessages<FilledValidatorErrorCode>({
  [FilledValidatorErrorCode.FILLED_EMPTY]: {
    id: 'validator-filled-empty',
    description: 'Validation error about the field is empty',
    defaultMessage: 'Field is required',
  },
});
