import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CommonFieldProps, composeValidators, getFieldValidator, useField, validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {IntentionedText} from '@joomcode/joom-ui/IntentionedText';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {TimeOffPolicy} from 'domain/timeOff/policy/model';
import {formatTimeOffPolicy} from 'domain/timeOff/policy/model/formatter';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Feature, useFeature} from 'services/features';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {messages} from './messages';

export type Props = CommonFieldProps<TimeOffPolicy> & {
  showWarnings?: boolean;
};

export function FieldTimeOffPolicy({
  name,
  label,
  hint,
  required,
  disabled,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  showWarnings,
  ...restProps
}: Props) {
  const intl = useIntl();
  const onHoldFeature = useFeature(Feature.JOB_CONTRACT_ON_HOLD);
  const policyToString = useCallback((policy: TimeOffPolicy) => formatTimeOffPolicy(policy, intl), [intl]);
  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const items = useMemo(
    () =>
      onHoldFeature.isAvailable
        ? getEnumValues(TimeOffPolicy)
        : getEnumValues(TimeOffPolicy).filter((status) => status !== TimeOffPolicy.NO_POLICY),
    [onHoldFeature.isAvailable],
  );
  const {input, meta} = useField<TimeOffPolicy>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    subscription: {dirty: true},
  });
  const {hintText, warning} = useMemo(() => {
    if (showWarnings) {
      if (!onHoldFeature.isAvailable && initialValue && !input.value && meta.dirty) {
        return {
          hintText: intl.formatMessage(messages.dirtyEmptyValue),
          warning: true,
        };
      }
      if (!onHoldFeature.isAvailable && !input.value) {
        return {
          hintText: intl.formatMessage(messages.emptyValue),
          warning: false,
        };
      }
      if (meta.dirty) {
        if (!onHoldFeature.isAvailable) {
          return {
            hintText: intl.formatMessage(messages.dirtyValueWithReset),
            warning: true,
          };
        } else if (input.value !== TimeOffPolicy.NO_POLICY) {
          return {
            hintText: intl.formatMessage(messages.dirtyValue),
            warning: true,
          };
        }
      }
    }

    return {
      hintText: hint,
      warning: false,
    };
  }, [initialValue, input.value, meta.dirty, hint]);

  return (
    <FormControl
      hint={warning ? <IntentionedText intent='warning'>{hintText}</IntentionedText> : hintText}
      label={label}
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <SimpleAutocomplete<TimeOffPolicy>
          items={items}
          getItemKey={identity}
          itemToString={policyToString}
          renderItem={policyToString}
          {...formControlProps}
          {...input}
          {...restProps}
          disabled={meta.submitting || disabled}
          clearable={!required}
        />
      )}
    </FormControl>
  );
}
