import {getConsolidatedAccessDependentDataState} from '@joomcode/deprecated-utils/dataState';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {EmptyMessage} from 'components/ui/EmptyMessage';
import {StyledExternalLink, StyledLink} from 'components/ui/StyledLink';
import {useHiringProcessAccess} from 'domain/hiringProcessInfo/hooks/useHiringProcessAccess';
import {useInterviewFeedbackLink} from 'domain/hiringProcessInfo/hooks/useInterviewFeedbackLink';
import {useOfferLink} from 'domain/hiringProcessInfo/hooks/useOfferLink';
import {UserFull} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  user: UserFull;
};

export function HiringProcessInfoPanel({user}: Props) {
  const intl = useIntl();
  const {canReadInterviewFeedbackLink, canReadOfferLink} = useHiringProcessAccess(user);
  const {interviewFeedbackLink, dataState: interviewFeedbackDataState} = useInterviewFeedbackLink(user);
  const {offerLink, dataState: offerLinkDataState} = useOfferLink(user);
  const renderError = useCallback(() => <Error message={intl.formatMessage(messages.loadingError)} />, [intl]);
  const dataState = getConsolidatedAccessDependentDataState(
    [interviewFeedbackDataState, canReadInterviewFeedbackLink],
    [offerLinkDataState, canReadOfferLink],
  );

  return (
    <Panel className={styles.root} title={intl.formatMessage(messages.title)} withPadding withMarginBottom>
      <StateHandler data={{interviewFeedbackLink, offerLink}} state={dataState} renderError={renderError}>
        {({interviewFeedbackLink: linkToInterviewFeedback, offerLink: linkToOffer}) => {
          if (!linkToInterviewFeedback && !linkToOffer) {
            return <EmptyMessage>{intl.formatMessage(messages.empty)}</EmptyMessage>;
          }

          return (
            <>
              {linkToOffer && (
                <div className={styles.section}>
                  <StyledLink colored to={linkToOffer}>
                    {intl.formatMessage(messages.linkToOffer)}
                  </StyledLink>
                </div>
              )}
              {linkToInterviewFeedback && (
                <div className={styles.section}>
                  <StyledExternalLink href={linkToInterviewFeedback} colored withIcon>
                    {intl.formatMessage(messages.linkToInterviewFeedback)}
                  </StyledExternalLink>
                </div>
              )}
            </>
          );
        }}
      </StateHandler>
    </Panel>
  );
}
