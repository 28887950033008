import {useFieldArrayWithInitialValue} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Panel} from '@joomcode/joom-ui/Panel';
import {CompensationReviewRequestFormNewOneTimeBonus} from 'domain/compensationReviewRequest/widgets/FormNewOneTimeBonus';
import {OneTimeBonusHeader} from 'domain/compensationReviewRequest/widgets/OneTimeBonusHeader';
import {Currency} from 'domain/currency/model';
import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import React, {useCallback} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  currency: Currency | undefined;
};

export function CompensationReviewRequestFormSectionOneTimeBonus({currency}: Props) {
  const intl = useIntl();
  const {change, getState} = useForm();
  const {values, submitting} = getState();
  const fields = useFieldArrayWithInitialValue<Partial<OneTimeBonusRecord>>('userInfo.oneTimeBonuses');
  const addOneTimeBonus = useCallback(() => fields.push({}), [fields]);

  const hasChanges = fields.length > 0;
  const reset = useCallback(() => {
    change(`userInfo.oneTimeBonuses`, undefined);
  }, [change]);

  return (
    <>
      <OneTimeBonusHeader onReset={hasChanges ? reset : undefined} />
      {fields.map((fieldName, index) => (
        <CompensationReviewRequestFormNewOneTimeBonus
          key={fieldName}
          currency={currency}
          fieldName={fieldName}
          onRemove={() => fields.remove(index)}
          values={values?.userInfo?.oneTimeBonuses?.[index]}
        />
      ))}
      <Panel.Content withPadding>
        <Button
          size='m'
          kind='secondary'
          intent='neutral'
          type='button'
          onClick={addOneTimeBonus}
          disabled={submitting}
        >
          {intl.formatMessage(messages.buttonAdd)}
        </Button>
      </Panel.Content>
    </>
  );
}
