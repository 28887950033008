import {DataState} from '@joomcode/deprecated-utils/dataState';
import {JobInfoRecord} from 'domain/jobInfoRecord/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {addRecordFx, deleteRecordFx, getMyRecordsFx, getRecordsByUserIdFx, updateRecordFx} from '.';

type State = {
  byUserId: Record<UserId, JobInfoRecord[]>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $jobInfoRecords = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on([getMyRecordsFx, getRecordsByUserIdFx], (state, userId) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on([getMyRecordsFx.fail, getRecordsByUserIdFx.fail], (state, {params: userId}) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.FAILED,
      },
    };
  })
  .on([getMyRecordsFx.done, getRecordsByUserIdFx.done], (state, {params: userId, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: result,
      },
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADED,
      },
    };
  })
  .on([addRecordFx.done, updateRecordFx.done], (state, {params: {userId}, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: result,
      },
    };
  })
  .on(deleteRecordFx.done, (state, {params: {userId, jobInfoRecordId}}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: state.byUserId[userId].filter(({id}) => id !== jobInfoRecordId),
      },
    };
  });
