import {defineMessages} from 'react-intl';
import {ComputerType} from './index';

export const computerTypeLabels = defineMessages<ComputerType>({
  [ComputerType.DESKTOP]: {
    defaultMessage: 'Desktop computer',
    description: 'Computer type',
  },
  [ComputerType.LAPTOP]: {
    defaultMessage: 'Laptop',
    description: 'Computer type',
  },
});
