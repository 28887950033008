import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {Page} from '@joomcode/joom-ui/Page';
import cn from 'classnames';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {JoomersPageTabs} from 'components/widgets/JoomersPageTabs';
import {Permission} from 'domain/permission/model';
import {usersApi} from 'domain/user/api';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {User} from 'domain/user/model';
import {UserSidePane} from 'domain/user/widgets/SidePane';
import {UsersTreePanel} from 'domain/user/widgets/TreePanel';
import {generalMessages} from 'i18n/messages/general';
import {NotFoundPage} from 'pages/NotFound';
import React, {useCallback} from 'react';
import Helmet from 'react-helmet';
import {useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router-dom';
import {pageTitles} from 'routes/users/titles';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import styles from './usersPageLayout.css';

export function UsersPage() {
  const acl = useAcl();
  const intl = useIntl();
  const history = useHistory();
  const {search} = useLocation();
  const [searchQuery, setSearchQuery] = useQueryParam('search', string(), '');
  const login = new URLSearchParams(search).get('login') ?? undefined;

  const onSidePaneClose = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete('login');
    history.push({
      pathname: usersUrls.users(),
      search: searchParams.toString(),
    });
  }, [search]);

  const {userById, userByLogin, dataState} = useAllUsers({withTerminated: true});

  return (
    <PageStateHandler data={userByLogin} state={dataState}>
      {(usersByLogin) => {
        const user = login && usersByLogin[login];

        if (login && !user) {
          return <NotFoundPage />;
        }

        return (
          <div className={cn(styles.layout, {[styles.withSidePanel]: !!user})}>
            <div className={styles.main}>
              <Page
                title={intl.formatMessage(pageTitles.users)}
                actions={
                  acl.hasPermission(Permission.USERS_DATA_DOWNLOAD) && (
                    <DownloadLinkButton
                      size='m'
                      kind='text'
                      intent='neutral'
                      href={usersApi.buildDirectoryDownloadLink()}
                      iconLeft={<DownloadIcon />}
                    >
                      {intl.formatMessage(messages.downloadDirectoryData)}
                    </DownloadLinkButton>
                  )
                }
                headerContent={
                  acl.hasPermission(Permission.TEAM_READ) ? <JoomersPageTabs activeTab='users' /> : undefined
                }
              >
                {user && (
                  <Helmet
                    title={intl.formatMessage(generalMessages.fullName, {
                      firstName: user.firstName,
                      lastName: user.lastName,
                    })}
                  />
                )}
                <UsersTreePanel
                  narrow={Boolean(user)}
                  allUsersById={userById}
                  searchQuery={searchQuery}
                  selectedUserId={user && user.id}
                  onSearch={setSearchQuery}
                  linkToUserPane={(user: User) => usersUrls.users({login: user.login})}
                />
              </Page>
            </div>
            {user && <UserSidePane user={user} onPaneClose={onSidePaneClose} />}
          </div>
        );
      }}
    </PageStateHandler>
  );
}
