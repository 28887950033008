import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {Currency} from 'domain/currency/model';
import {RegularBonusType} from 'domain/regularBonusRecord/model/type';
import faker from 'faker';
import {RegularBonusValue} from '.';

const currencies = getEnumValues(Currency);

export function mockRegularBonusValue(type: RegularBonusType): RegularBonusValue {
  if (type === RegularBonusType.ABSOLUTE) {
    return {
      absoluteValue: {
        amount: faker.random.number({min: 10, max: 1000}) * 1000,
        currency: faker.random.arrayElement(currencies),
        type,
      },
      percentValue: undefined,
    };
  }
  return {
    absoluteValue: undefined,
    percentValue: {
      amount: faker.random.number({min: 1, max: 100}),
      comment: faker.random.word(),
      type,
    },
  };
}
