import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CommonFieldProps, FieldSelect} from '@joomcode/joom-form';
import {RegularBonusType} from 'domain/regularBonusRecord/model/type';
import {formatRegularBonusType} from 'domain/regularBonusRecord/model/type/formatter';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

type Props = CommonFieldProps<RegularBonusType>;

export function FieldRegularBonusType(props: Props) {
  const intl = useIntl();
  const typeToString = useCallback((type: RegularBonusType) => formatRegularBonusType(type, intl), [intl]);

  return (
    <FieldSelect<RegularBonusType>
      items={getEnumValues(RegularBonusType)}
      itemToString={typeToString}
      renderItem={typeToString}
      {...props}
    />
  );
}
