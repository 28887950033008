import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No records',
    description: 'One-time bonus table: empty message',
  },
  conditionHint: {
    defaultMessage: 'Whether it has to be paid back to the company if the terms aren’t met by the penal date.',
    description: 'One-time bonus table: condition column hint',
  },
});

export const columnNames = defineMessages<Exclude<ColumnId, ColumnId.ACTIONS>>({
  [ColumnId.AMOUNT]: {
    defaultMessage: 'Bonus (gross)',
    description: 'One-time bonus table column name',
  },
  [ColumnId.CONDITION]: {
    defaultMessage: 'Condition',
    description: 'One-time bonus table column name',
  },
  [ColumnId.COMMENT]: {
    defaultMessage: 'Comment',
    description: 'One-time bonus table column name',
  },
  [ColumnId.DATE]: {
    defaultMessage: 'Date',
    description: 'One-time bonus table column name',
  },
  [ColumnId.PENAL_DEADLINE]: {
    defaultMessage: 'Penal date',
    description: 'One-time bonus table column name',
  },
  [ColumnId.TYPE]: {
    defaultMessage: 'Type',
    description: 'One-time bonus table column name',
  },
});
