import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Button} from '@joomcode/joom-ui/Button';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ReactComponent as PenIcon} from '@joomcode/joom-ui/icons/core/pen.svg';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import {ClampedText} from 'components/ui/ClampedText';
import {Employment} from 'domain/employment/model';
import {
  formatTerminationInitiatedBy,
  formatTerminationReason,
  formatTerminationType,
} from 'domain/employment/utils/format';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {columnNames, messages} from './messages';
import styles from './styles.css';

export type Props = {
  dataState?: DataState;
  employments: Employment[];
  onEditItem?: (employment: Employment) => void;
};

const getRowClassName = (employment: Employment) => (employment.active ? '' : styles.inactiveRow);
const getRowKey = (employment: Employment) => employment.id;

export function EmploymentsTable({dataState = DataState.LOADED, employments, onEditItem}: Props) {
  const intl = useIntl();

  const columns: Column<Employment>[] = useMemo(() => {
    const basicColumns: Column<Employment>[] = [
      {
        defaultWidth: 10,
        id: 'hireDate',
        name: intl.formatMessage(columnNames.hireDate),
        noWrap: true,
        render: ({hireDate}) => <HumanDate value={hireDate} format='short' utc />,
      },
      {
        defaultWidth: 10,
        id: 'terminationDate',
        name: intl.formatMessage(columnNames.terminationDate),
        noWrap: true,
        render: ({terminationDate}) =>
          terminationDate ? <HumanDate value={terminationDate} format='short' utc /> : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 10,
        id: 'legalTerminationDate',
        name: intl.formatMessage(columnNames.legalTerminationDate),
        noWrap: true,
        render: ({legalTerminationDate}) =>
          legalTerminationDate ? (
            <HumanDate value={legalTerminationDate} format='short' utc />
          ) : (
            <DataTable.CellEmpty />
          ),
      },
      {
        defaultWidth: 20,
        id: 'terminationReason',
        name: intl.formatMessage(columnNames.terminationReason),
        noWrap: true,
        render: ({terminationReason}) =>
          terminationReason ? formatTerminationReason(terminationReason, intl) : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 10,
        id: 'terminationInitiatedBy',
        name: intl.formatMessage(columnNames.terminationInitiatedBy),
        noWrap: true,
        render: ({terminationInitiatedBy}) =>
          terminationInitiatedBy ? formatTerminationInitiatedBy(terminationInitiatedBy, intl) : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 15,
        id: 'terminationType',
        name: intl.formatMessage(columnNames.terminationType),
        noWrap: true,
        render: ({terminationType}) =>
          terminationType ? formatTerminationType(terminationType, intl) : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 25,
        id: 'terminationComment',
        name: intl.formatMessage(columnNames.terminationComment),
        render: ({terminationComment}) =>
          terminationComment ? <ClampedText text={terminationComment} /> : <DataTable.CellEmpty />,
      },
    ];
    return onEditItem
      ? [
          ...basicColumns,
          {
            defaultWidth: 5,
            id: 'edit',
            name: '',
            render: (employment) =>
              employment.terminated && (
                <TooltipAlt content={intl.formatMessage(messages.editButtonAlt)}>
                  <span className={styles.editButtonWrap}>
                    <Button
                      aria-label={intl.formatMessage(messages.editButtonAlt)}
                      kind='text'
                      size='m'
                      intent='neutral'
                      iconLeft={<PenIcon />}
                      onClick={() => onEditItem(employment)}
                    />
                  </span>
                </TooltipAlt>
              ),
          },
        ]
      : basicColumns;
  }, [onEditItem, intl]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={employments}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.fail)}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      storageName='employmentsList'
    />
  );
}
