import {DataState} from '@joomcode/deprecated-utils/dataState';
import {CompanyUnits} from 'domain/companyUnits/model';
import {createStore} from 'effector';
import {getCompanyUnitsFx} from '.';

type State = {
  units: CompanyUnits;
  dataState: DataState;
};

const emptyUnits: CompanyUnits = {
  divisions: [],
  departments: [],
  groupsOfFunctions: [],
  jobTitles: [],
  emergencyContactRelationships: [],
  locations: [],
  userFunctions: [],
  salaryChangeReasons: [],
  stockOptionIssuers: [],
  offerSources: [],
  offerDeclineReasons: [],
  regularBonusBases: [],
  jobContractOnHoldReasons: [],
};

const compareUnits = (a: string, b: string): number => a.toLowerCase().localeCompare(b.toLowerCase(), 'en-US');

export const $companyUnits = createStore<State>({
  units: emptyUnits,
  dataState: DataState.IDLE,
})
  .on(getCompanyUnitsFx, (state) => {
    return {
      ...state,
      dataState: DataState.LOADING,
    };
  })
  .on(getCompanyUnitsFx.fail, (state) => {
    return {
      ...state,
      units: emptyUnits,
      dataState: DataState.FAILED,
    };
  })
  .on(getCompanyUnitsFx.done, (state, {result}) => {
    return {
      ...state,
      units: {
        ...result,
        divisions: [...result.divisions].sort(compareUnits),
        departments: [...result.departments].sort(compareUnits),
        jobTitles: [...result.jobTitles].sort(compareUnits),
        emergencyContactRelationships: [...result.emergencyContactRelationships].sort(compareUnits),
        locations: [...result.locations].sort(compareUnits),
        userFunctions: [...result.userFunctions].sort(compareUnits),
        salaryChangeReasons: [...result.salaryChangeReasons].sort(compareUnits),
        stockOptionIssuers: [...result.stockOptionIssuers].sort(compareUnits),
      },
      dataState: DataState.LOADED,
    };
  });
