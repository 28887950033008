import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {LegalEntitiesPage} from 'pages/LegalEntities';
import {LegalEntityPage} from 'pages/LegalEntity';
import {RouteConfig} from 'react-router-config';
import {legalEntitiesUrlPatterns} from 'routes/legalEntities/urls';
import {config as notFoundRouteConfig} from 'routes/notFound';

export const config: RouteConfig = {
  component: withAuth(RegularLayout),
  path: legalEntitiesUrlPatterns.root,
  routes: [
    {
      component: withPermission(LegalEntityPage, Permission.LEGAL_ENTITY_WRITE),
      exact: true,
      path: legalEntitiesUrlPatterns.legalEntity,
    },
    {
      component: withPermission(LegalEntitiesPage, Permission.LEGAL_ENTITY_READ),
      exact: true,
      path: legalEntitiesUrlPatterns.root,
    },
    notFoundRouteConfig,
  ],
};
