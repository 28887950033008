import {createLocator, type Mark, type Locator} from 'create-locator';
import React from 'react';
import styles from './index.css';

export type TooltipFaqParagraphLocator = Locator<void>;

type Props = {
  children: React.ReactNode;
} & Partial<Mark<TooltipFaqParagraphLocator>>;

export function Paragraph({children, ...rest}: Props) {
  const locator = createLocator(rest);

  return (
    <p className={styles.paragraph} {...locator()}>
      {children}
    </p>
  );
}
