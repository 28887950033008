import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Work history record dialog',
    description: 'Job history record dialog: aria label',
  },
  titleAdd: {
    defaultMessage: 'Add work history record',
    description: '[title] Job history record dialog: title (addition mode)',
  },
  titleUpdate: {
    defaultMessage: 'Update work history record',
    description: '[title] Job history record dialog: title (update mode)',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Job history record dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Submit',
    description: '[button] Job history record dialog: submit button',
  },
  loadingError: {
    defaultMessage: 'Failed to load company units data',
    description: 'Job history record dialog: error message',
  },
  accessWarning: {
    defaultMessage:
      'When a job title, department or business unit changes, the Joomer’s corporate accesses are restricted. To avoid this, let <user>yesview</user> or <user>svaliev</user> know, and they will assist with the change.',
    description: 'Job history record dialog: warning',
  },
});
