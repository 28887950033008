import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  showUsers: {
    defaultMessage: 'Show people',
    description: 'Teams tree: view mode switcher tooltip',
  },
  hideUsers: {
    defaultMessage: 'Hide people',
    description: 'Teams tree: view mode switcher tooltip',
  },
});
