import composeRefs from '@seznam/compose-react-refs';
import React, {useCallback, useRef} from 'react';
import cn from 'classnames';
import {useIntl} from 'react-intl';
import {createLocator} from '../../create-locator';
import {ReactComponent as CloseIcon} from '../../icons/core/crossSmall.svg';
import {ReactComponent as ArrowBottomIcon} from '../../icons/core/arrowBottom.svg';
import styles from './index.css';
import type {LabelProps} from './types';
import {getLabelContainerClassName, shouldShowSelectedAmount} from './utils';
import {ValueWrapper, ValueWrapperProps} from './ValueWrapper';

export const Label = React.forwardRef<HTMLButtonElement, LabelProps>(function Label(props: LabelProps, buttonRef) {
  const intl = useIntl();
  const locator = createLocator(props);
  const {children, active, filled, disabled, selectedAmount, onClear, onClick} = props;
  const localButtonRef = useRef<HTMLButtonElement>(null);

  const handleClear = () => {
    if (localButtonRef.current) {
      localButtonRef.current.focus();
    }
    if (onClear) {
      onClear();
    }
  };

  const ValueWithLocator = useCallback(
    (valueWrapperProps: ValueWrapperProps) => <ValueWrapper {...valueWrapperProps} {...locator.value()} />,
    [props.testId?.value],
  );

  const hasClearButton = Boolean(filled && !disabled && onClear);
  const hasSelectedAmount = shouldShowSelectedAmount(selectedAmount, props);

  return (
    <span className={getLabelContainerClassName(props)}>
      <button
        className={cn(styles.label, {
          [styles.labelEmpty]: !filled,
          [styles.labelWithClearButton]: hasClearButton,
        })}
        {...locator.button()}
        onClick={onClick}
        ref={composeRefs(buttonRef, localButtonRef)}
        type='button'
        disabled={disabled}
      >
        <span className={styles.labelContent} {...locator.text()}>
          {typeof children === 'function'
            ? children({ValueWrapper: ValueWithLocator}) // eslint-disable-line @typescript-eslint/naming-convention
            : children}
        </span>

        {!filled && (
          <span className={styles.arrowBottomIconWrapper}>
            <ArrowBottomIcon className={cn(styles.arrowBottomIcon, {[styles.arrowBottomIconInverted]: active})} />
          </span>
        )}

        {hasSelectedAmount && (
          <span className={styles.selectedAmount} {...locator.selectedAmount()}>
            {intl.formatNumber(selectedAmount)}
          </span>
        )}
      </button>

      {hasClearButton && (
        <button className={styles.removeButton} {...locator.clearButton()} onClick={handleClear} type='button'>
          <CloseIcon />
        </button>
      )}
    </span>
  );
});
