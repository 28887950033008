import {dateFromString, ExtractSchemaType, object, oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';
import {TimeOffType} from 'domain/timeOff/type/model';

export const timeOffSchema = object({
  start: dateFromString(),
  end: dateFromString(),
  type: oneOfEnum(TimeOffType),
});

export type TimeOff = ExtractSchemaType<typeof timeOffSchema>;
