import {useCompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestReadAccess';
import {SecurePermission} from 'domain/permission/model/secure';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {Redirect} from 'react-router-dom';
import {hrConsoleUrls} from 'routes/hrConsole/urls';
import {useAcl} from 'services/acl';

export function HrConsoleRedirect() {
  const acl = useAcl();
  const compensationReviewRequestReadAccess = useCompensationReviewRequestReadAccess();

  if (acl.hasSecurePermission(SecurePermission.USER_FULL_INFO_RECORD_READ)) {
    return <Redirect to={hrConsoleUrls.users()} />;
  }

  if (acl.hasSecurePermission(SecurePermission.SALARY_RANGE_READ_AS_ADMIN)) {
    return <Redirect to={hrConsoleUrls.currentSalaryRanges()} />;
  }

  if (acl.hasSecurePermission(SecurePermission.SALARY_RANGE_MANAGE)) {
    return <Redirect to={hrConsoleUrls.management()} />;
  }

  if (compensationReviewRequestReadAccess) {
    return <Redirect to={hrConsoleUrls.compensationReviewRequests()} />;
  }

  return <NotFoundPage />;
}
