import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ClampedText} from 'components/ui/ClampedText';
import {FormattedPrice} from 'components/ui/FormattedPrice';
import {SensitiveData} from 'components/ui/SensitiveData';
import {ActiveBadge} from 'components/widgets/ActiveBadge';
import {formatFrequency} from 'domain/frequency/model/formatter';
import {AnySalaryRecord, ExtendedSalaryRecord, SalaryRecordId} from 'domain/salaryRecord/model';
import {SalaryReadAccess} from 'domain/salaryRecord/model/access';
import {SalaryRecordMenu} from 'domain/salaryRecord/widgets/Menu';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';
import styles from './styles.css';

export type Props = {
  readAccess: SalaryReadAccess | null;
  records: AnySalaryRecord[];
  dataState: DataState;
  isSensitiveDataHidden: boolean;
  onDeleteItem?: (id: SalaryRecordId) => void;
  onEditItem?: (record: AnySalaryRecord) => void;
  onReplaceItem?: (record: AnySalaryRecord) => void;
};

const getRowClassName = ({active}: AnySalaryRecord) => (!active ? styles.inactiveRow : '');
const getRowKey = ({id}: AnySalaryRecord) => id;

export function SalaryRecordsTable({
  readAccess,
  records,
  dataState,
  isSensitiveDataHidden,
  onDeleteItem,
  onEditItem,
  onReplaceItem,
}: Props) {
  const intl = useIntl();
  const columns: Column<AnySalaryRecord>[] = useMemo(() => {
    const basicColumns: Column<AnySalaryRecord>[] = [
      {
        defaultWidth: 8,
        id: ColumnId.START_DATE,
        name: intl.formatMessage(columnNames[ColumnId.START_DATE]),
        render: ({startDate}) => <HumanDate value={startDate} format='short' utc />,
      },
      {
        defaultWidth: 8,
        id: ColumnId.END_DATE,
        name: intl.formatMessage(columnNames[ColumnId.END_DATE]),
        render: ({endDate}) => (endDate ? <HumanDate value={endDate} format='short' utc /> : <DataTable.CellEmpty />),
      },
      {
        align: 'right',
        defaultWidth: 8,
        id: ColumnId.AMOUNT,
        name: intl.formatMessage(columnNames[ColumnId.AMOUNT]),
        render: (record) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            <FormattedPrice value={record} />
          </SensitiveData>
        ),
      },
      {
        defaultWidth: 7,
        id: ColumnId.FREQUENCY,
        name: intl.formatMessage(columnNames[ColumnId.FREQUENCY]),
        render: ({frequency}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>{formatFrequency(frequency, intl)}</SensitiveData>
        ),
      },
      {
        defaultWidth: 5,
        id: ColumnId.EMPLOYMENT_RATE,
        name: intl.formatMessage(columnNames[ColumnId.EMPLOYMENT_RATE]),
        render: ({employmentRate}) => <SensitiveData isHidden={isSensitiveDataHidden}>{employmentRate}</SensitiveData>,
      },
      {
        defaultWidth: 3,
        id: ColumnId.REGION,
        name: intl.formatMessage(columnNames[ColumnId.REGION]),
        render: ({region}) => <SensitiveData isHidden={isSensitiveDataHidden}>{region}</SensitiveData>,
      },
      {
        defaultWidth: 10,
        id: ColumnId.REASON,
        name: intl.formatMessage(columnNames[ColumnId.REASON]),
        render: ({reason}) => <SensitiveData isHidden={isSensitiveDataHidden}>{reason}</SensitiveData>,
      },
      {
        defaultWidth: readAccess === SalaryReadAccess.ANY_EXTENDED ? 12 : 20,
        id: ColumnId.COMMENT,
        name: intl.formatMessage(columnNames[ColumnId.COMMENT]),
        render: ({comment}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            {comment ? <ClampedText text={comment} /> : <DataTable.CellEmpty />}
          </SensitiveData>
        ),
      },
      {
        defaultWidth: 7,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({active}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            <ActiveBadge active={active} />
          </SensitiveData>
        ),
      },
    ];

    const adminColumns: Column<ExtendedSalaryRecord>[] = [
      {
        defaultWidth: 12,
        id: ColumnId.SPECIAL_ARRANGEMENTS,
        name: intl.formatMessage(columnNames[ColumnId.SPECIAL_ARRANGEMENTS]),
        render: ({specialArrangements}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            {specialArrangements ? <ClampedText text={specialArrangements} /> : <DataTable.CellEmpty />}
          </SensitiveData>
        ),
      },
    ];

    const actionColumns: Column<AnySalaryRecord>[] =
      onDeleteItem || onEditItem || onReplaceItem
        ? [
            {
              defaultWidth: 5,
              id: 'actions',
              name: '',
              render: (record) => (
                <SalaryRecordMenu
                  salaryRecord={record}
                  onDelete={onDeleteItem}
                  onEdit={onEditItem}
                  onReplace={onReplaceItem}
                />
              ),
            },
          ]
        : [];

    return readAccess === SalaryReadAccess.ANY_EXTENDED
      ? [...basicColumns, ...adminColumns, ...actionColumns]
      : [...basicColumns, ...actionColumns];
  }, [intl, isSensitiveDataHidden]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={records}
      emptyMessage={intl.formatMessage(messages.empty)}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      storageName='salaryRecords.list'
    />
  );
}
