import {Page} from '@joomcode/joom-ui/Page';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {useSecureRoles} from 'domain/secureRole/hooks/useSecureRoles';
import {SecureRolesTable} from 'domain/secureRole/widgets/SecureRolesTable';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function SecureRolesPage() {
  const intl = useIntl();
  const {rolesById, dataState} = useSecureRoles();

  return (
    <PageStateHandler data={Object.values(rolesById)} state={dataState}>
      {(secureRoles) => (
        <Page title={intl.formatMessage(messages.title)}>
          <SecureRolesTable secureRoles={secureRoles} />
        </Page>
      )}
    </PageStateHandler>
  );
}
