import React from 'react';
import {CopyLinkIcon} from './CopyLinkIcon';
import styles from './pointInfo.css';

type Props = {
  children: React.ReactNode;
  button?: React.ReactNode;
  description?: React.ReactNode;
  title: React.ReactNode;
  pointUrl: string;
  copyIconLabel: string;
};

export function PointInfo({children, button, description, title, copyIconLabel, pointUrl}: Props) {
  return (
    <>
      <div className={styles.content}>
        <div className={styles.header}>
          <CopyLinkIcon label={copyIconLabel} value={window.location.origin + pointUrl} />
          <div className={styles.title}>{title}</div>
        </div>
        {children}
      </div>
      {(description || button) && (
        <div className={styles.additionalInfoWrapper}>
          {button && <div className={styles.button}>{button}</div>}
          {description}
        </div>
      )}
    </>
  );
}
