import isEqual from 'lodash/isEqual';
import {DependencyList, RefObject, useLayoutEffect, useState} from 'react';
import {OverflowState} from './types';
import {getInitialOverflows, getNodeOverflows} from './utils';

type Options = {
  listenWindowResize?: boolean;
};
export function useScrollViewOverflow(
  ref: RefObject<HTMLElement>,
  options: Options = {},
  deps: DependencyList = [],
): OverflowState {
  const [overflows, setOverflows] = useState<OverflowState>(
    ref.current ? getNodeOverflows(ref.current) : getInitialOverflows(),
  );

  useLayoutEffect(() => {
    const node = ref.current;

    function update() {
      if (node) {
        const next = getNodeOverflows(node);
        setOverflows((current) => (isEqual(current, next) ? current : next));
      }
    }

    update();

    if (node) {
      node.addEventListener('scroll', update, {passive: true});
      if (options.listenWindowResize) {
        window.addEventListener('resize', update, {passive: true});
      }
    }

    return () => {
      if (node) {
        node.removeEventListener('scroll', update);
        if (options.listenWindowResize) {
          window.removeEventListener('resize', update);
        }
      }
    };
  }, [ref.current, options.listenWindowResize, ...deps]);

  return overflows;
}
