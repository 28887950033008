import {Panel} from '@joomcode/joom-ui/Panel';
import {useSalaryRangesVersions} from 'domain/salaryRange/hooks/useSalaryRangesVersions';
import {SalaryRangesVersionsTable} from 'domain/salaryRange/widgets/VersionsTable';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function SalaryRangesVersionsPanel() {
  const intl = useIntl();
  const {data, dataState} = useSalaryRangesVersions();

  return (
    <Panel title={intl.formatMessage(messages.title)} withSeparator>
      <SalaryRangesVersionsTable data={data} dataState={dataState} />
    </Panel>
  );
}
