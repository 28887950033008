import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {Placeholder} from 'components/ui/Placeholder';
import {CompanyUnits} from 'domain/companyUnits/model';
import {UserFull} from 'domain/user/model';
import {UserDiff} from 'domain/user/model/diff';
import {UserFormMode} from 'domain/user/model/formMode';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import {UserProfileLayout} from 'domain/user/widgets/ProfileLayout';
import React, {useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {usersUrls} from 'routes/users/urls';
import uuid from 'uuid/v4';
import {messages} from './messages';
import {SubmitButton} from './SubmitButton';
import styles from './UserForm.css';
import {UserFormAddress} from './UserFormAddress';
import {UserFormEmergency} from './UserFormEmergency';
import {UserFormGeneral} from './UserFormGeneral';

type Props = {
  isSelfUser: boolean;
  companyUnits: CompanyUnits;
  mode: UserFormMode;
  onSubmit: (userData: Partial<UserFull>) => Promise<void>;
  privateInfo?: UserPrivateInfo;
  user: UserFull;
};

export function UserForm({isSelfUser, companyUnits, mode, privateInfo, user, onSubmit}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  // Impossible situation in normal flow; just a safety net
  if (mode === UserFormMode.CONTACTS && !privateInfo) {
    return <Placeholder.Error title={intl.formatMessage(messages.errorNoData)} />;
  }

  return (
    <Form<UserDiff> onSubmit={onSubmit} destroyOnUnregister>
      {({handleSubmit, submitting, invalid}) => (
        <form id={formId} onSubmit={handleSubmit} autoComplete='off' noValidate>
          <Panel className={styles.panel}>
            {mode === UserFormMode.GENERAL && (
              <UserProfileLayout user={user} isSelfUser={isSelfUser}>
                <UserFormGeneral privateInfo={privateInfo} submitting={submitting} user={user} />
              </UserProfileLayout>
            )}
            {mode === UserFormMode.CONTACTS && (
              <>
                {/* eslint-disable @typescript-eslint/no-non-null-assertion */}
                <UserFormAddress submitting={submitting} privateInfo={privateInfo!} />
                <UserFormEmergency companyUnits={companyUnits} submitting={submitting} privateInfo={privateInfo!} />
                {/* eslint-enable @typescript-eslint/no-non-null-assertion */}
              </>
            )}
            <SubmitFormPanel>
              <ButtonGroup spaced size='l'>
                <SubmitButton formId={formId} loading={submitting} disabled={invalid}>
                  {intl.formatMessage(messages.saveButton)}
                </SubmitButton>
                <RouterLinkButton size='l' kind='primary' intent='neutral' to={usersUrls.user({login: user.login})}>
                  {intl.formatMessage(messages.cancelButton)}
                </RouterLinkButton>
              </ButtonGroup>
            </SubmitFormPanel>
          </Panel>
        </form>
      )}
    </Form>
  );
}
