import {Panel} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {useLegalEntities} from 'domain/legalEntity/hooks/useLegalEntities';
import {LegalInfo} from 'domain/legalInfo/model';
import {LegalAddress} from 'domain/legalInfo/model/address';
import {LegalInfoForm} from 'domain/legalInfo/widgets/Form';
import {EnhanceOfferDiff} from 'domain/offer/api/enhance';
import {OfferId} from 'domain/offer/model';
import {OfferJobContract} from 'domain/offer/model/jobContract';
import {NewcomerDocuments} from 'domain/offer/model/newcomer/documents';
import {NewcomerFormTemplateCountry} from 'domain/offer/model/templateCountry';
import {DocumentPreview} from 'domain/offer/widgets/DocumentPreview';
import {NewcomerDocumentDownloadButton} from 'domain/offer/widgets/NewcomerDocumentDownloadButton';
import React from 'react';
import {useIntl} from 'react-intl';
import {offerUrls} from 'routes/offer/urls';
import {messages, messagesByCountry} from './messages';
import styles from './styles.css';

export type Props = {
  address?: LegalAddress;
  documents?: NewcomerDocuments;
  documentsData?: LegalInfo;
  id: OfferId;
  jobContract?: OfferJobContract;
  hasFurtherSalary?: boolean;
  country: NewcomerFormTemplateCountry | undefined;
  onSubmit: (diff: EnhanceOfferDiff) => Promise<unknown>;
};

export function OfferEnhanceForm({
  address,
  documents,
  documentsData,
  id,
  hasFurtherSalary,
  jobContract,
  country,
  onSubmit,
}: Props) {
  const intl = useIntl();
  const {legalEntities: legalEntitiesData, dataState} = useLegalEntities();
  const labels = {
    ...messages,
    ...((country && messagesByCountry[country]) ?? {}),
  };
  const cancelUrl = offerUrls.offer({id});

  return (
    <StateHandler data={legalEntitiesData} state={dataState}>
      {(legalEntities) => (
        <LegalInfoForm
          address={address}
          legalInfoData={documentsData}
          jobContractData={jobContract}
          onSubmit={onSubmit}
          cancelUrl={cancelUrl}
          legalEntities={legalEntities}
        >
          {({
            internationalPassportFields,
            localIdFields,
            legalAddressFields,
            nameAndGenderFields,
            jobContractFields,
            furtherJobContractFields,
          }) => (
            <div className={styles.root}>
              <div className={styles.document}>
                <div className={styles.form}>
                  <Panel.Header title={intl.formatMessage(labels.jobContract)} />
                  <Panel.Content withPadding>{jobContractFields}</Panel.Content>
                </div>
                <div className={styles.preview} />
              </div>
              {hasFurtherSalary && (
                <div className={styles.document}>
                  <div className={styles.form}>
                    <Panel.Header
                      title={intl.formatMessage(labels.furtherJobContract)}
                      subTitle={intl.formatMessage(messages.furtherJobContractHint)}
                    />
                    <Panel.Content withPadding>{furtherJobContractFields}</Panel.Content>
                  </div>
                  <div className={styles.preview} />
                </div>
              )}
              <div className={styles.document}>
                <div className={styles.form}>
                  <Panel.Header title={intl.formatMessage(labels.personalData)} />
                  <Panel.Content withPadding>{nameAndGenderFields}</Panel.Content>
                  <Panel.Header
                    title={intl.formatMessage(labels.internationalPassport)}
                    toolbar={
                      documents?.idCard && <NewcomerDocumentDownloadButton fileMeta={documents.idCard} offerId={id} />
                    }
                  />
                  <Panel.Content withPadding>{internationalPassportFields}</Panel.Content>
                </div>
                <div className={styles.preview}>
                  {documents?.idCard && (
                    <DocumentPreview
                      fileMeta={documents.idCard}
                      offerId={id}
                      altText={intl.formatMessage(labels.internationalPassportPreviewAlt)}
                    />
                  )}
                </div>
              </div>
              <div className={styles.document}>
                <div className={styles.form}>
                  <Panel.Header
                    title={intl.formatMessage(labels.localId)}
                    toolbar={
                      documents?.internalPassportMainPage && (
                        <NewcomerDocumentDownloadButton fileMeta={documents.internalPassportMainPage} offerId={id} />
                      )
                    }
                  />
                  <Panel.Content withPadding>{localIdFields}</Panel.Content>
                </div>
                <div className={styles.preview}>
                  {documents?.internalPassportMainPage && (
                    <DocumentPreview
                      fileMeta={documents.internalPassportMainPage}
                      offerId={id}
                      altText={intl.formatMessage(labels.localIdPreviewAlt)}
                    />
                  )}
                </div>
              </div>
              <div className={styles.document}>
                <div className={styles.form}>
                  <Panel.Header
                    title={intl.formatMessage(
                      documents?.internalPassportAddressPage ? labels.localIdAddress : labels.placeOfCurrentResidence,
                    )}
                    toolbar={
                      documents?.internalPassportAddressPage && (
                        <NewcomerDocumentDownloadButton fileMeta={documents.internalPassportAddressPage} offerId={id} />
                      )
                    }
                  />
                  <Panel.Content withPadding>{legalAddressFields}</Panel.Content>
                </div>
                <div className={styles.preview}>
                  {documents?.internalPassportAddressPage && (
                    <DocumentPreview
                      fileMeta={documents.internalPassportAddressPage}
                      offerId={id}
                      altText={intl.formatMessage(labels.localIdAddressPreviewAlt)}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </LegalInfoForm>
      )}
    </StateHandler>
  );
}
