import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No data available',
    description: 'Stock option holders table: empty message',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load options data',
    description: 'Stock option holders table: error message',
  },
  employmentStatusActive: {
    defaultMessage: 'Employed',
    description: 'Stock option holders table: employments status value',
  },
  employmentStatusTerminated: {
    defaultMessage: 'Terminated',
    description: 'Stock option holders table: employments status value',
  },
});

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.USER]: {
    defaultMessage: 'Joomer',
    description: 'Stock option holders table column name',
  },
  [ColumnId.LOGIN]: {
    defaultMessage: 'Login',
    description: 'Stock option holders table column name',
  },
  [ColumnId.GRANTED]: {
    defaultMessage: 'Granted options',
    description: 'Stock option holders table column name',
  },
  [ColumnId.AVAILABLE]: {
    defaultMessage: 'Available balance',
    description: 'Stock option holders table column name',
  },
  [ColumnId.HIRE_DATE]: {
    defaultMessage: 'Hire date',
    description: 'Stock option holders table column name',
  },
  [ColumnId.EMPLOYMENT_STATUS]: {
    defaultMessage: 'Employment status',
    description: 'Stock option holders table column name',
  },
});
