import {Stack} from '@joomcode/joom-ui/Stack';
import {RtconfItem} from 'domain/rtconf/model';
import {RtconfForm} from 'domain/rtconf/widgets/Form';
import {RtconfSearch} from 'domain/rtconf/widgets/Search';
import React from 'react';

type Props = {
  items: RtconfItem[];
  searchQuery: string;
  setSearchQuery: (search: string) => void;
};

export function RtconfList({items, searchQuery, setSearchQuery}: Props) {
  const filteredItems = items.filter((item) => item.key.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <>
      <RtconfSearch query={searchQuery} onSearchChange={setSearchQuery} />
      <Stack vertical>
        {filteredItems.map((rtconfItem) => (
          <React.Fragment key={rtconfItem.key}>
            <Stack.Divider />
            <RtconfForm rtconf={rtconfItem} setKeyAsLink />
          </React.Fragment>
        ))}
      </Stack>
    </>
  );
}
