import {User, UserId} from 'domain/user/model';

type PickKeysByValueType<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];

// Only allow string user fields here
const FIELDS_TO_SEARCH_IN: PickKeysByValueType<User, string | undefined>[] = [
  'email',
  'firstName',
  'jobTitle',
  'lastName',
  'slackDisplayName',
  'verifiedGithubLogin',
];
const WORD_DELIMITER_RE = /[\s,;]+/;

export const parseSearchWords = (searchQuery: string): string[] =>
  searchQuery
    .toLowerCase()
    .split(WORD_DELIMITER_RE)
    .filter((word) => word !== '');

const userIncludesWord = (user: User, word: string): boolean =>
  FIELDS_TO_SEARCH_IN.some((field) => user[field]?.toLowerCase().includes(word));

export const searchUsers = (usersById: Record<UserId, User>, searchWords: string[]): User[] => {
  const users = Object.values(usersById);

  if (!searchWords.length) {
    return users;
  }

  const resultUsers: Set<User> = new Set();
  const addUserWithAllManagers = (id: UserId) => {
    const user = usersById[id];
    if (!user) {
      return; // Safety net for the case of broken managerId in user
    }
    resultUsers.add(user);
    if (user.managerId) {
      addUserWithAllManagers(user.managerId);
    }
  };

  for (const user of users) {
    if (searchWords.every((word) => userIncludesWord(user, word))) {
      addUserWithAllManagers(user.id);
    }
  }

  return Array.from(resultUsers);
};
