import {Page as CommonPage, Props as CommonProps} from '@joomcode/joom-ui/Page';
import {Breadcrumbs} from 'components/ui/Breadcrumbs';
import {breadcrumbsTeleport, notionGuideTeleport} from 'components/widgets/Header/teleport';
import {NotionGuide} from 'components/widgets/NotionGuide';
import hoistNonReactStatics from 'hoist-non-react-statics';
import React, {forwardRef} from 'react';

export type Breadcrumb = {
  title: string;
  href: string;
};

type Props = Omit<CommonProps, 'breadcrumbs'> & {
  breadcrumbs?: Breadcrumb[];
  notionGuide?: string;
};

export const Page = hoistNonReactStatics(
  forwardRef(function Page({breadcrumbs, notionGuide, ...props}: Props, ref: React.Ref<HTMLDivElement>) {
    return (
      <>
        {breadcrumbs && (
          <breadcrumbsTeleport.Source>
            <Breadcrumbs>
              {breadcrumbs.map((breadcrumb) => (
                <Breadcrumbs.Item key={breadcrumb.href} href={breadcrumb.href}>
                  {breadcrumb.title}
                </Breadcrumbs.Item>
              ))}
            </Breadcrumbs>
          </breadcrumbsTeleport.Source>
        )}
        {notionGuide && (
          <notionGuideTeleport.Source>
            <NotionGuide href={notionGuide} />
          </notionGuideTeleport.Source>
        )}

        <div ref={ref}>
          <CommonPage {...props} />
        </div>
      </>
    );
  }),
  CommonPage,
);
