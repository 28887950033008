import {identity} from '../../../../function/identity';
import {memoizeOne} from '../../../../memoize/one';

export const getNavigatorLocales = memoizeOne((navigator: Navigator): string[] => {
  const list = navigator.languages || [
    // navigator.userLanguage is IE-only
    // @ts-ignore
    navigator.userLanguage || '',
    navigator.language || '',
  ];

  return list.filter(identity).map((language) => language.trim().toLowerCase());
});
