import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {LegalEntityId} from 'domain/legalEntity/model';
import {GetOneTimeBonusRecordsParams} from 'domain/oneTimeBonusRecord/api/getAny';
import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import {OneTimeBonusReadAccess} from 'domain/oneTimeBonusRecord/model/access';
import {
  getAnyOneTimeBonusRecordsFx,
  getMyOneTimeBonusRecordsFx,
  getSubordinateOneTimeBonusRecordsFx,
} from 'domain/oneTimeBonusRecord/stores/main';
import {$oneTimeBonusRecords} from 'domain/oneTimeBonusRecord/stores/main/state';
import {UserFull} from 'domain/user/model';
import {Effect} from 'effector';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';
import {useOneTimeBonusReadAccess} from './useOneTimeBonusReadAccess';

const handlerByAccess: Record<OneTimeBonusReadAccess, Effect<GetOneTimeBonusRecordsParams, OneTimeBonusRecord[]>> = {
  [OneTimeBonusReadAccess.ANY]: getAnyOneTimeBonusRecordsFx,
  [OneTimeBonusReadAccess.MY]: getMyOneTimeBonusRecordsFx,
  [OneTimeBonusReadAccess.SUBORDINATE]: getSubordinateOneTimeBonusRecordsFx,
};

type ReturnShape = {
  data: OneTimeBonusRecord[];
  dataState: DataState;
};

export function useUserOneTimeBonusRecords(user: UserFull, legalEntityId: LegalEntityId): ReturnShape {
  const readAccess = useOneTimeBonusReadAccess(user);
  const [error, setError] = useState<Error>();

  const data = useStoreMap({
    store: $oneTimeBonusRecords,
    keys: [user.id, legalEntityId],
    fn: ({byUserId}, [id, legalEntityId]) => byUserId[id]?.[legalEntityId] || [],
  });
  const dataState = useStoreMap({
    store: $oneTimeBonusRecords,
    keys: [user.id, legalEntityId],
    fn: ({dataStateByUserId}, [id, legalEntityId]) => dataStateByUserId[id]?.[legalEntityId] || DataState.IDLE,
  });

  useEffect(() => {
    if (readAccess && !isLoadingOrLoaded(dataState)) {
      handlerByAccess[readAccess]({userId: user.id, legalEntityId}).catch(setError);
    }
  }, [readAccess, user.id, legalEntityId]);

  useEffect(() => {
    if (error) {
      toaster.error(error.message);
    }
  }, [error]);

  return {
    data,
    dataState,
  };
}
