import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {Team} from 'domain/team/model';
import {TeamDiff} from 'domain/team/model/diff';
import {updateTeamFx} from 'domain/team/stores/main';
import {TeamForm} from 'domain/team/widgets/Form';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  team: Team;
  onClose: () => void;
};

export function TeamUpdateDialog({team, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const onSubmit = useCallback(
    (diff: TeamDiff) =>
      updateTeamFx({id: team.id, diff})
        .then(() => {
          onClose();
        })
        .catch(toaster.interceptThenThrowError),
    [team.id, onClose],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 48rem)'>
      <Form<TeamDiff> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <TeamForm formId={formId} onSubmit={handleSubmit} submitting={submitting} teamToUpdate={team} />
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
