import {DataState} from '@joomcode/deprecated-utils/dataState';
import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {TimeOffRequest} from 'domain/timeOff/request/model';
import {
  approveSubordinateTimeOffRequestFx,
  cancelTimeOffRequestFx,
  createTimeOffRequestFx,
  createTimeOffRequestOnBehalfFx,
  getMyTimeOffRequestsFx,
  getSubordinatesTimeOffRequestsFx,
  rejectSubordinateTimeOffRequestFx,
  setRecordedInAccountingSystemFx,
} from '.';

export const $myTimeOffRequests = createEntityListStore(
  createEntityStore({
    getEntityId: (timeOffRequest: TimeOffRequest) => timeOffRequest.id,
    query: {
      fx: getMyTimeOffRequestsFx,
      getIdsFromParams: () => [],
    },
  })
    .on(createTimeOffRequestFx.done, (state, {result}) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [result.id]: result,
        },
        byIdState: {
          ...state.byIdState,
          [result.id]: DataState.LOADED,
        },
        list: [...state.list, result.id],
      };
    })
    .on(cancelTimeOffRequestFx.done, (state, {result}) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [result.id]: result,
        },
      };
    }),
);

export const $subordinatesTimeOffRequests = createEntityListStore(
  createEntityStore({
    getEntityId: (timeOffRequest: TimeOffRequest) => timeOffRequest.id,
    query: {
      fx: getSubordinatesTimeOffRequestsFx,
      getIdsFromParams: () => [],
    },
  })
    .on(
      [
        approveSubordinateTimeOffRequestFx.doneData,
        rejectSubordinateTimeOffRequestFx.doneData,
        setRecordedInAccountingSystemFx.doneData,
      ],
      (state, result) => {
        return {
          ...state,
          byId: {
            ...state.byId,
            [result.id]: result,
          },
        };
      },
    )
    .reset(createTimeOffRequestOnBehalfFx.done),
);
