import {identity} from '@joomcode/deprecated-utils/function';
import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {FieldFloat, FieldFloatValue} from 'components/widgets/fields/FieldFloat';
import {CompensationRequestClosureDiff} from 'domain/compensations/request/api/close';
import {Currency} from 'domain/currency/model';
import {CurrencySelect} from 'domain/currency/widgets/Select';
import React, {useMemo} from 'react';
import {Field, Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  onClose: () => void;
  onSubmit: FormProps<CompensationRequestClosureDiff>['onSubmit'];
};

export function CloseDialog({onClose, onSubmit}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  return (
    <Form<CompensationRequestClosureDiff> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid}) => (
        <Dialog ariaLabel={intl.formatMessage(messages.dialogAriaLabel)} isOpen onClose={onClose} width={600}>
          <Dialog.Header>{intl.formatMessage(messages.header)}</Dialog.Header>
          <Dialog.Body withDefaultPadding>
            <div className={styles.text}>{intl.formatMessage(messages.text)}</div>
            <form className={styles.form} onSubmit={handleSubmit} id={formId}>
              <div className={styles.amount}>
                <Field<FieldFloatValue>
                  name='actualCompensationPaid.amount'
                  subscription={{error: true, touched: true}}
                >
                  {({meta}) => (
                    <FormControl
                      disabled={submitting}
                      error={getFieldErrorText(meta, {intl})}
                      label={intl.formatMessage(messages.amountLabel)}
                      required
                    >
                      {(formControlProps) => (
                        <FieldFloat
                          {...formControlProps}
                          disabled={submitting}
                          name='actualCompensationPaid.amount'
                          validate={validateFilled}
                        />
                      )}
                    </FormControl>
                  )}
                </Field>
              </div>
              <div className={styles.currency}>
                <Field<Currency> name='actualCompensationPaid.currency' parse={identity} validate={validateFilled}>
                  {({input, meta}) => (
                    <FormControl
                      disabled={submitting}
                      error={getFieldErrorText(meta, {intl})}
                      label={intl.formatMessage(messages.currencyLabel)}
                      required
                    >
                      {(formControlProps) => <CurrencySelect disabled={submitting} {...formControlProps} {...input} />}
                    </FormControl>
                  )}
                </Field>
              </div>
            </form>
          </Dialog.Body>
          <Dialog.Footer align='right'>
            <ButtonGroup spaced size='l'>
              <Button kind='text' intent='neutral' onClick={onClose}>
                {intl.formatMessage(messages.cancelButton)}
              </Button>
              <Button
                form={formId}
                kind='primary'
                intent='primary'
                type='submit'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.submitButton)}
              </Button>
            </ButtonGroup>
          </Dialog.Footer>
        </Dialog>
      )}
    </Form>
  );
}
