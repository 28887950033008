import {PageTabs} from 'components/ui/PageTabs';
import {HrConsoleTab} from 'domain/hrConsole/model/tabs';
import {hrConsoleTabNames} from 'domain/hrConsole/model/tabs/messages';
import {pathByTab} from 'domain/hrConsole/model/tabs/paths';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  availableTabs: HrConsoleTab[];
  activeTab: HrConsoleTab;
};

export function HrConsolePageTabs({availableTabs, activeTab}: Props) {
  const intl = useIntl();

  return (
    <PageTabs>
      {availableTabs.map((tab) => (
        <PageTabs.Tab
          title={intl.formatMessage(hrConsoleTabNames[tab])}
          path={pathByTab[tab]}
          active={activeTab === tab}
          key={tab}
        />
      ))}
    </PageTabs>
  );
}
