import {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {DecoderObject, successesKeys} from '../jsonValidation';
import {AnyObject} from '../types';

export const useTypedParams = <T extends AnyObject>(schemas: DecoderObject<T>): Partial<T> => {
  const params = useParams();
  return useMemo(() => {
    const res = successesKeys(schemas).run(params);
    return res.ok ? res.result : {};
  }, [params]);
};
