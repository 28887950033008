import {buildDownloadLink} from './buildDownloadLink';
import {create} from './create';
import {deleteStockOptionAgreement} from './delete';
import {sendToHolder} from './sendToHolder';
import {setDocumentsStatus} from './setDocumentsStatus';

export const stockOptionAgreementApi = {
  buildDownloadLink,
  create,
  delete: deleteStockOptionAgreement,
  sendToHolder,
  setDocumentsStatus,
};
