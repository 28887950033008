import cn from 'classnames';
import React, {CSSProperties, FC, ReactNode} from 'react';
import {Breakpoint} from '../types';
import styles from './index.css';

export type Breakpoints = {
  [mode in Breakpoint]?: 2 | 3 | 4 | 6 | 8 | 10 | 12;
};

export type ItemProps = Breakpoints & {
  children: ReactNode;
  stretch?: boolean;
};

export const Item: FC<ItemProps> = function Item({children, xl = 1, lg = xl, md = lg, sm = md, xs = sm, stretch}) {
  return (
    <div
      className={cn(styles.item, {[styles.stretch]: stretch})}
      style={
        {
          '--xs': xs,
          '--sm': sm,
          '--md': md,
          '--lg': lg,
          '--xl': xl,
        } as CSSProperties
      }
    >
      {children}
    </div>
  );
};

Item.displayName = 'Grid.Item';
