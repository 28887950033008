import {composeValidators, getFieldValidator, validateFilled, validateUrl} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Checkbox} from '@joomcode/joom-ui/Checkbox';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {Panel} from '@joomcode/joom-ui/Panel';
import {HintIcon} from 'components/ui/HintIcon';
import {RedirectRouteCreationConfig} from 'domain/redirectRoute/model';
import React from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {domainErrorMessages, fieldLabels, messages, pathErrorMessages} from './messages';
import styles from './styles.css';
import {validateDomain, validatePath} from './validate';

type Props = {
  onSubmit: (config: RedirectRouteCreationConfig) => void;
};

export function RedirectRouteForm({onSubmit}: Props) {
  const intl = useIntl();

  return (
    <Form<RedirectRouteCreationConfig> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid}) => (
        <Panel withPadding>
          <form onSubmit={handleSubmit}>
            <div className={styles.newRouteFormWrapper}>
              <div className={styles.newRouteFormShortField}>
                <Field<string>
                  name='domain'
                  initialValue='go'
                  validate={getFieldValidator(composeValidators(validateFilled, validateDomain))}
                >
                  {({input, meta}) => (
                    <FormControl
                      disabled={submitting}
                      error={getFieldErrorText(meta, {intl, extraMessages: domainErrorMessages})}
                      label={intl.formatMessage(fieldLabels.domain)}
                      required
                    >
                      {/* Always disabled for now; will be editable under permission in the future */}
                      {(formControlProps) => <Input {...formControlProps} {...input} disabled />}
                    </FormControl>
                  )}
                </Field>
              </div>
              <div className={styles.newRouteFormMediumField}>
                <Field<string>
                  name='path'
                  initialValue='/'
                  validate={getFieldValidator(composeValidators(validateFilled, validatePath))}
                >
                  {({input, meta}) => (
                    <FormControl
                      disabled={submitting}
                      error={getFieldErrorText(meta, {intl, extraMessages: pathErrorMessages})}
                      label={intl.formatMessage(fieldLabels.path)}
                      required
                    >
                      {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                    </FormControl>
                  )}
                </Field>
              </div>
              <div className={styles.newRouteFormField}>
                <Field<string> name='target' validate={composeValidators(validateFilled, validateUrl)}>
                  {({input, meta}) => (
                    <FormControl
                      disabled={submitting}
                      error={getFieldErrorText(meta, {intl})}
                      label={intl.formatMessage(fieldLabels.target)}
                      required
                    >
                      {(formControlProps) => (
                        <Input placeholder='https://' {...formControlProps} {...input} disabled={submitting} />
                      )}
                    </FormControl>
                  )}
                </Field>
              </div>
              <div className={styles.newRouteFormShortField}>
                <Field<boolean> name='isWildcard' type='checkbox'>
                  {({input: {value, ...input}}) => (
                    <FormControl
                      disabled={submitting}
                      label={
                        <>
                          {intl.formatMessage(fieldLabels.isWildcard)}
                          <HintIcon>
                            <>
                              {intl.formatMessage(messages.wildcardHintHeader)}
                              <br />
                              <br />
                              {intl.formatMessage(messages.wildcardHintExample, {
                                example: (text: React.ReactNode) => <span className={styles.example}>{text}</span>,
                                emphasis: (text: React.ReactNode) => <span className={styles.emphasis}>{text}</span>,
                              })}
                            </>
                          </HintIcon>
                        </>
                      }
                    >
                      {(formControlProps) => <Checkbox {...formControlProps} {...input} disabled={submitting} />}
                    </FormControl>
                  )}
                </Field>
              </div>
              <div className={styles.newRouteSubmitButton}>
                <Button size='l' kind='primary' intent='primary' type='submit' loading={submitting} disabled={invalid}>
                  {intl.formatMessage(messages.createButton)}
                </Button>
              </div>
            </div>
          </form>
        </Panel>
      )}
    </Form>
  );
}
