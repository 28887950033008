import {defineMessages} from 'react-intl';
import {Type} from '.';

export const typeMessages = defineMessages<Type>({
  [Type.PROMOTION]: {
    defaultMessage: 'Promotion',
    description: 'Compensation review request type',
  },
  [Type.RETENTION]: {
    defaultMessage: 'Retention',
    description: 'Compensation review request type',
  },
  [Type.ROTATION]: {
    defaultMessage: 'Rotation',
    description: 'Compensation review request type',
  },
  [Type.OTHER]: {
    defaultMessage: 'Other',
    description: 'Compensation review request type',
  },
});
