import {isNullish} from '../function/isNullish';
import {AnyObject} from '../types';

export function isEmptyValue(value?: boolean | number | string | null | AnyObject | unknown): boolean {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (value instanceof Date) {
    return false;
  }

  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length === 0;
  }

  return value === '' || isNullish(value);
}
