import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {IntentionedText} from '@joomcode/joom-ui/IntentionedText';
import {DialogInfoStripe} from 'components/ui/DialogInfoStripe';
import {PagePreloader} from 'components/ui/PagePreloader';
import {StyledLink} from 'components/ui/StyledLink';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {JobInfoRecord} from 'domain/jobInfoRecord/model';
import {JobInfoRecordDiff} from 'domain/jobInfoRecord/model/diff';
import {addRecordFx, updateRecordFx} from 'domain/jobInfoRecord/stores/main';
import {JobInfoRecordForm} from 'domain/jobInfoRecord/widgets/Form';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import {loadUserByLoginFx, loadUserEverWorkedByLoginFx} from 'domain/user/stores/main';
import {$users} from 'domain/user/stores/main/state';
import {useStore} from 'effector-react';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  activeRecord?: JobInfoRecord;
  isOpen: boolean;
  onClose: () => void;
  recordToUpdate?: JobInfoRecord;
  userId: UserId;
};

export function JobInfoRecordDialog({activeRecord, isOpen, onClose, recordToUpdate, userId}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const canReadTerminatedUsers = acl.hasPermission(Permission.USER_READ_ANY);
  const {units, dataState} = useCompanyUnits();
  const userLogin: string | undefined = useStore($users).loginById[userId];

  const onSubmit = useCallback(
    (diff: JobInfoRecordDiff) => {
      const action = recordToUpdate
        ? updateRecordFx({userId, jobInfoRecordId: recordToUpdate.id, diff})
        : addRecordFx({userId, diff});
      return action
        .then(() => {
          onClose();
          if (userLogin) {
            (canReadTerminatedUsers ? loadUserEverWorkedByLoginFx : loadUserByLoginFx)({login: userLogin});
          }
        })
        .catch(toaster.interceptThenThrowError);
    },
    [recordToUpdate, onClose, userId, userLogin, canReadTerminatedUsers],
  );

  const renderDialogContent = () => {
    if (dataState === DataState.LOADED && units) {
      return (
        <Form<JobInfoRecordDiff> onSubmit={onSubmit}>
          {({handleSubmit, submitting, invalid}) => (
            <>
              <Dialog.Body withDefaultPadding>
                {(recordToUpdate || activeRecord) && (
                  <DialogInfoStripe>
                    {intl.formatMessage(messages.accessWarning, {
                      user: (login: React.ReactNode) => (
                        <StyledLink to={usersUrls.user({login: encodeURIComponent(login as string)})} colored>
                          @{login}
                        </StyledLink>
                      ),
                    })}
                  </DialogInfoStripe>
                )}
                <JobInfoRecordForm
                  activeRecord={activeRecord}
                  companyUnits={units}
                  formId={formId}
                  onSubmit={handleSubmit}
                  recordToUpdate={recordToUpdate}
                  submitting={submitting}
                />
              </Dialog.Body>
              <Dialog.Footer>
                <ButtonGroup spaced size='l'>
                  <Button kind='text' intent='neutral' onClick={onClose}>
                    {intl.formatMessage(messages.cancelButton)}
                  </Button>
                  <Button
                    form={formId}
                    kind='primary'
                    intent='primary'
                    type='submit'
                    disabled={invalid}
                    loading={submitting}
                  >
                    {intl.formatMessage(messages.submitButton)}
                  </Button>
                </ButtonGroup>
              </Dialog.Footer>
            </>
          )}
        </Form>
      );
    }
    if ([DataState.LOADING, DataState.IDLE].includes(dataState)) {
      return (
        <Dialog.Body withDefaultPadding>
          <PagePreloader />
        </Dialog.Body>
      );
    }
    return (
      <Dialog.Body withDefaultPadding>
        <IntentionedText intent='negative'>{intl.formatMessage(messages.loadingError)}</IntentionedText>
      </Dialog.Body>
    );
  };

  return (
    <Dialog
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      isOpen={isOpen}
      onClose={onClose}
      width='min(90vw, 48rem)'
    >
      <Dialog.Header>{intl.formatMessage(recordToUpdate ? messages.titleUpdate : messages.titleAdd)}</Dialog.Header>
      {renderDialogContent()}
    </Dialog>
  );
}
