import {DataState, getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {Button} from '@joomcode/joom-ui/Button';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {useUserInfo} from 'domain/compensationReviewRequest/hooks/useUserInfo';
import {CompensationReviewRequestFormRequestBody} from 'domain/compensationReviewRequest/widgets/FormRequestBody';
import {CompensationReviewRequestFormRequestDetails} from 'domain/compensationReviewRequest/widgets/FormRequestDetails';
import {UserId} from 'domain/user/model';
import React, {useEffect} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  formId: string;
  onlySubordinates?: boolean;
  userId: UserId;
};

export function CompensationReviewRequestFormRest({formId, onlySubordinates, userId}: Props) {
  const intl = useIntl();
  const companyUnits = useCompanyUnits();
  const userInfo = useUserInfo(userId);
  const dataState = getConsolidatedDataState(companyUnits.dataState, userInfo.dataState);
  const {change, getState, reset, batch} = useForm();
  const {invalid, submitting, values} = getState();

  useEffect(() => {
    if (dataState === DataState.LOADED) {
      batch(() => {
        const {type} = values;
        reset();
        change('oldUserInfo', userInfo.data);
        change('userInfo.general', userInfo.data.general);
        change('userInfo.oneTimeBonuses', undefined);
        change('type', type);
        change('userId', userId);
      });
    }
  }, [userInfo.data, dataState, userId]);

  return (
    <StateHandler
      data={companyUnits.units && userInfo.data && {companyUnitsData: companyUnits.units, userInfoData: userInfo.data}}
      state={dataState}
    >
      {({companyUnitsData, userInfoData}) => (
        <>
          <CompensationReviewRequestFormRequestBody
            companyUnits={companyUnitsData}
            onlySubordinates={onlySubordinates}
            userId={userId}
            userInfo={userInfoData}
          />
          <CompensationReviewRequestFormRequestDetails />
          <SubmitFormPanel>
            <Button
              form={formId}
              size='l'
              kind='primary'
              intent='primary'
              type='submit'
              disabled={invalid}
              loading={submitting}
            >
              {intl.formatMessage(messages.button)}
            </Button>
          </SubmitFormPanel>
        </>
      )}
    </StateHandler>
  );
}
