import React from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../../create-locator';
import {ReactComponent as IconCross} from '../../icons/core/cross.svg';
import styles from './index.css';

export type CloseButtonTestId = ConvertLocatorToTestId<CloseButtonLocator>;
export type CloseButtonLocator = LocatorOfElement<void>;

type Props = Omit<JSX.IntrinsicElements['button'], 'className' | 'type'> & Partial<Mark<CloseButtonLocator>>;

export function CloseButton({...restPropertiesWithMark}: Props) {
  const locator = createLocator(restPropertiesWithMark);
  const restProps = removeMarkFromProperties(restPropertiesWithMark);

  return (
    <button {...locator()} type='button' className={styles.button} {...restProps}>
      <IconCross className={styles.icon} />
    </button>
  );
}
