import {oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {OfferId} from 'domain/offer/model';
import {LoginValidationResult} from 'domain/offer/model/loginValidationResult';

type LoginValidationParams = {
  offerId: OfferId;
  login: string;
};

export const validateLogin = async ({offerId, login}: LoginValidationParams): Promise<LoginValidationResult> => {
  const {data} = await intranetApi.get('/v1/offers/validateLogin', {params: {id: offerId, login}});

  return oneOfEnum(LoginValidationResult).runWithException(data);
};
