import {Sorting} from '@joomcode/deprecated-utils/sort/types';
import {getPrimaryLegalEntity} from 'domain/contract/utils/getPrimaryLegalEntity';
import {getSalariesFromContractRecords} from 'domain/contract/utils/getSalariesFromContractRecord';
import {levelCodeSortingOrder} from 'domain/levelCode/model';
import {OfficePolicy} from 'domain/officePolicyRecord/model/policy';
import {formatOfficePolicy} from 'domain/officePolicyRecord/model/policy/formatter';
import {formatTimeOffPolicy} from 'domain/timeOff/policy/model/formatter';
import {UserFullInfoRecord} from 'domain/userFullInfoRecord/model';
import {UserFullInfoColumn} from 'domain/userFullInfoRecord/model/columns';
import {getMainSalary} from 'domain/userFullInfoRecord/utils/getMainSalary';
import {generalMessages} from 'i18n/messages/general';
import orderBy from 'lodash/orderBy';
import {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {getCountryName} from 'utils/country/name';

type ReturnShape = {
  sorting: Sorting | undefined;
  setSorting: (sorting: Sorting | undefined) => void;
  sortUserFullInfoRecords: (record: UserFullInfoRecord[], sorting: Sorting | undefined) => UserFullInfoRecord[];
};

const levelCodeIteratee = ({activeMarketDataRecord}: UserFullInfoRecord) =>
  activeMarketDataRecord ? levelCodeSortingOrder.indexOf(activeMarketDataRecord.levelCode) : -1;
const birthdayIteratee = ({user}: UserFullInfoRecord) => user.monthAndDayOfBirth ?? '';
const hireDateIteratee = ({user}: UserFullInfoRecord) => user.hireDate;
const userFunctionIteratee = ({activeMarketDataRecord}: UserFullInfoRecord) =>
  activeMarketDataRecord ? activeMarketDataRecord.userFunction : '';
const primaryLegalEntityIteratee = ({contracts}: UserFullInfoRecord) => {
  const legalEntity = contracts && getPrimaryLegalEntity(contracts);
  return legalEntity ? legalEntity.title : '';
};
const divisionIteratee = ({user}: UserFullInfoRecord) => user.division;
const departmentIteratee = ({user}: UserFullInfoRecord) => user.department;
const locationIteratee = ({user}: UserFullInfoRecord) =>
  user.location && user.officePolicy !== OfficePolicy.REMOTE ? user.location : '';
const salaryStartDateIteratee = ({contracts}: UserFullInfoRecord) => {
  const mainSalary = getMainSalary(contracts);
  return mainSalary ? mainSalary.startDate : '';
};
const crValueIteratee = ({cr}: UserFullInfoRecord) => (cr ? cr.value : -1);
const salaryRangeMinimumIteratee = ({salaryRange}: UserFullInfoRecord) => salaryRange?.minAmount || 0;
const salaryRangeTargetIteratee = ({salaryRange}: UserFullInfoRecord) => salaryRange?.targetAmount || 0;
const salaryRangeMaximumIteratee = ({salaryRange}: UserFullInfoRecord) => salaryRange?.maxAmount || 0;
const optionsGrantedTotalIteratee = ({stockOptions}: UserFullInfoRecord) => stockOptions?.summary.granted.total || 0;
const optionsVestedIteratee = ({stockOptions}: UserFullInfoRecord) => stockOptions?.summary.vested || 0;
const optionsAvailableIteratee = ({stockOptions}: UserFullInfoRecord) => stockOptions?.summary.available || 0;
const optionsNextYearIteratee = ({stockOptions}: UserFullInfoRecord) => stockOptions?.summary.nextYear || 0;
const optionsAgreementsIteratee = ({stockOptions}: UserFullInfoRecord) => stockOptions?.agreements.length || 0;
const vacationBalanceIteratee = ({vacationBalance}: UserFullInfoRecord) => vacationBalance || 0;
const remoteDaysBalanceIteratee = ({remoteDaysBalance}: UserFullInfoRecord) => remoteDaysBalance || 0;

export function useSorting(): ReturnShape {
  const intl = useIntl();
  const [sorting, setSorting] = useState<Sorting | undefined>();

  const timeOffPolicyIteratee = useCallback(
    ({user}: UserFullInfoRecord) =>
      user.countryOfTimeOffPolicy ? formatTimeOffPolicy(user.countryOfTimeOffPolicy, intl) : '',
    [intl],
  );
  const officePolicyIteratee = useCallback(
    ({user}: UserFullInfoRecord) => (user.officePolicy ? formatOfficePolicy(user.officePolicy, intl) : ''),
    [intl],
  );
  const managerIteratee = useCallback(
    ({activeJobInfoRecord}: UserFullInfoRecord) =>
      activeJobInfoRecord?.manager
        ? intl.formatMessage(generalMessages.fullName, {
            firstName: activeJobInfoRecord.manager.firstName,
            lastName: activeJobInfoRecord.manager.lastName,
          })
        : '',
    [intl],
  );
  const salaryRegionIteratee = useCallback(
    ({contracts}: UserFullInfoRecord) => {
      const salaries = getSalariesFromContractRecords(contracts);
      return salaries && salaries[0] ? getCountryName(salaries[0].region, intl) : '';
    },
    [intl],
  );

  const sortUserFullInfoRecords = useCallback((records: UserFullInfoRecord[], sorting: Sorting | undefined) => {
    if (!sorting) {
      return records.sort((a, b) => a.user.lastName.localeCompare(b.user.lastName));
    }

    switch (sorting.by) {
      case UserFullInfoColumn.LEVEL_CODE:
        return orderBy(records, levelCodeIteratee, sorting.direction);
      case UserFullInfoColumn.TIME_OFF_POLICY:
        return orderBy(records, timeOffPolicyIteratee, sorting.direction);
      case UserFullInfoColumn.VACATION_BALANCE:
        return orderBy(records, vacationBalanceIteratee, sorting.direction);
      case UserFullInfoColumn.REMOTE_WORK_BALANCE:
        return orderBy(records, remoteDaysBalanceIteratee, sorting.direction);
      case UserFullInfoColumn.BIRTHDAY:
        return orderBy(records, birthdayIteratee, sorting.direction);
      case UserFullInfoColumn.HIRE_DATE:
        return orderBy(records, hireDateIteratee, sorting.direction);
      case UserFullInfoColumn.OFFICE_POLICY:
        return orderBy(records, officePolicyIteratee, sorting.direction);
      case UserFullInfoColumn.USER_FUNCTION:
        return orderBy(records, userFunctionIteratee, sorting.direction);
      case UserFullInfoColumn.PRIMARY_LEGAL_ENTITY:
        return orderBy(records, primaryLegalEntityIteratee, sorting.direction);
      case UserFullInfoColumn.DIVISION:
        return orderBy(records, divisionIteratee, sorting.direction);
      case UserFullInfoColumn.DEPARTMENT:
        return orderBy(records, departmentIteratee, sorting.direction);
      case UserFullInfoColumn.LOCATION:
        return orderBy(records, locationIteratee, sorting.direction);
      case UserFullInfoColumn.MANAGER:
        return orderBy(records, managerIteratee, sorting.direction);
      case UserFullInfoColumn.SALARY_START_DATE:
        return orderBy(records, salaryStartDateIteratee, sorting.direction);
      case UserFullInfoColumn.SALARY_REGION:
        return orderBy(records, salaryRegionIteratee, sorting.direction);
      case UserFullInfoColumn.SALARY_CR:
        return orderBy(records, crValueIteratee, sorting.direction);
      case UserFullInfoColumn.SALARY_RANGE_MINIMUM:
        return orderBy(records, salaryRangeMinimumIteratee, sorting.direction);
      case UserFullInfoColumn.SALARY_RANGE_TARGET:
        return orderBy(records, salaryRangeTargetIteratee, sorting.direction);
      case UserFullInfoColumn.SALARY_RANGE_MAXIMUM:
        return orderBy(records, salaryRangeMaximumIteratee, sorting.direction);
      case UserFullInfoColumn.OPTIONS_GRANTED_TOTAL:
        return orderBy(records, optionsGrantedTotalIteratee, sorting.direction);
      case UserFullInfoColumn.OPTIONS_VESTED:
        return orderBy(records, optionsVestedIteratee, sorting.direction);
      case UserFullInfoColumn.OPTIONS_AVAILABLE:
        return orderBy(records, optionsAvailableIteratee, sorting.direction);
      case UserFullInfoColumn.OPTIONS_NEXT_12_MONTHS:
        return orderBy(records, optionsNextYearIteratee, sorting.direction);
      case UserFullInfoColumn.OPTIONS_AGREEMENTS:
        return orderBy(records, optionsAgreementsIteratee, sorting.direction);
      default:
        return orderBy(records, sorting.by, sorting.direction);
    }
  }, []);

  return {
    sorting,
    setSorting,
    sortUserFullInfoRecords,
  };
}
