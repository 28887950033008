import {
  constant,
  ExtractSchemaType,
  object,
  oneOfEnum,
  optional,
  string,
  union,
} from '@joomcode/deprecated-utils/jsonValidation';
import {CountryCode} from 'domain/countryCode/model';
import {Gender} from 'domain/gender/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {legalAddressSchema} from './address';

export const legalInfoSchema = object({
  address: legalAddressSchema,
  addressLine: string(),
  firstName: string(),
  lastName: string(),
  middleName: optional(string()),
  legalAddress: optional(string()), // deprecated, use addressLine instead
  localId: object({
    country: oneOfEnum(CountryCode),
    number: string(),
  }),
  internationalPassport: optional(
    object({
      country: oneOfEnum(CountryCode),
      number: string(),
      expirationDate: optional(formattedDateSchema),
    }),
  ),
  gender: optional(oneOfEnum(Gender)),
});
export const nullableLegalInfoSchema = union(constant(null), legalInfoSchema);

export type LegalInfo = ExtractSchemaType<typeof legalInfoSchema>;
export type NullableLegalInfo = ExtractSchemaType<typeof nullableLegalInfoSchema>;
