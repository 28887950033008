import {JsonValidatorErrorCode, StringValidatorErrorCode} from '@joomcode/joom-form';
import {defineMessages} from 'react-intl';

export const rtconfKeyErrorMessages = defineMessages({
  [StringValidatorErrorCode.STRING_REG_EXP_NOT_MATCH]: {
    defaultMessage: 'Invalid key',
    description: 'Validation error message',
  },
});

export const rtconfValueErrorMessages = defineMessages({
  [JsonValidatorErrorCode.JSON_INVALID]: {
    defaultMessage: 'Invalid value',
    description: 'Validation error message',
  },
});
