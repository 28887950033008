import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Legal data',
    description: '[title] Legal info panel title',
  },
  personalData: {
    defaultMessage: 'Personal data',
    description: '[title] Legal info panel: section title',
  },
  internationalPassport: {
    defaultMessage: 'International passport',
    description: '[title] Legal info panel: section title',
  },
  localId: {
    defaultMessage: 'National ID or Residence card',
    description: '[title] Legal info panel: section title',
  },
  legalAddress: {
    defaultMessage: 'Legal address',
    description: '[title] Legal info panel: section title',
  },
  loadingError: {
    defaultMessage: 'Failed to load legal data. Try to refresh the page.',
    description: 'Legal info panel: data loading error',
  },
  empty: {
    defaultMessage: 'No legal data available for this Joomer.',
    description: 'Legal info panel: empty message',
  },
});

export const labels = defineMessages({
  fullName: {
    defaultMessage: 'Full name',
    description: 'Legal info panel: label',
  },
  gender: {
    defaultMessage: 'Gender',
    description: 'Legal info panel: label',
  },
  country: {
    defaultMessage: 'Country',
    description: 'Legal info panel: label',
  },
  number: {
    defaultMessage: 'Number',
    description: 'Legal info panel: label',
  },
  expirationDate: {
    defaultMessage: 'Expires',
    description: 'Legal info panel: label',
  },
});
