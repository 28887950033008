import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  effectiveDate: {
    defaultMessage: 'Effective date',
    description: 'Market data record form: field label',
  },
  levelCode: {
    defaultMessage: 'Level code',
    description: 'Market data record form: field label',
  },
});
