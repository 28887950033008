export enum OfferStatus {
  CREATED = 'created',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
  REVIEW = 'review',
  DIGITIZED = 'digitized',
  APPROVING = 'approving',
  COMPLETED = 'completed',
}

export const statusSortingOrder: OfferStatus[] = [
  OfferStatus.CREATED,
  OfferStatus.ACCEPTED,
  OfferStatus.REVIEW,
  OfferStatus.DIGITIZED,
  OfferStatus.APPROVING,
  OfferStatus.COMPLETED,
  OfferStatus.DECLINED,
];
