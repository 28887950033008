import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';

export type DisclosureState = {
  collapse(): void;
  expand(): void;
  isExpanded: boolean;
  toggle(): void;
};

export function useDisclosureState(
  options: {
    initiallyExpanded?: boolean | (() => boolean);
  } = {},
): DisclosureState {
  const booleanState = useBooleanState(options.initiallyExpanded || false);

  return {
    collapse: booleanState.setFalse,
    expand: booleanState.setTrue,
    isExpanded: booleanState.value,
    toggle: booleanState.toggle,
  };
}

export type DisclosureButtonProps = {
  id: string;
  'aria-expanded': boolean;
  'aria-controls': string;
  onClick(): void;
};

export type DisclosureContentProps = {
  id: string;
  'aria-hidden': boolean;
  'aria-labelledby': string;
};

export function useDisclosureUi(
  id: string,
  state: Pick<DisclosureState, 'isExpanded' | 'toggle'>,
): {
  getButtonProps(): DisclosureButtonProps;
  getContentProps(): DisclosureContentProps;
} {
  const contentId = `${id}:content`;
  const buttonId = `${id}:button`;

  return {
    getButtonProps: () => ({
      id: buttonId,
      'aria-expanded': state.isExpanded,
      'aria-controls': contentId,
      onClick: state.toggle,
    }),
    getContentProps: () => ({
      id: contentId,
      'aria-hidden': !state.isExpanded,
      'aria-labelledby': buttonId,
    }),
  };
}
