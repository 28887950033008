import Downshift, {GetInputPropsOptions} from 'downshift';
import React, {Ref, useCallback} from 'react';
import {Dropdown} from '../Select/Dropdown';
import {Listbox} from '../Listbox';
import styles from './index.css';

/**
 * Downshift’s GetInputPropsOptions uses React.LegacyRef, which is incompatible with React.forwardRef
 * To overcome this, we have to override GetInputPropsOptions.ref with React.Ref and use our own interface
 * https://github.com/downshift-js/downshift/issues/718
 */
interface GetInputPropsOptionsRef extends GetInputPropsOptions {
  ref?: Ref<HTMLInputElement>;
}

type Props<Item> = {
  children: (props: {
    getInputProps: (options?: JSX.IntrinsicElements['input']) => GetInputPropsOptionsRef;
  }) => React.ReactNode;
  disabled?: boolean;
  dropdownMinWidth?: string;
  getItemKey: (item: Item) => string;
  id?: string;
  isItemDisabled?: (item: Item) => boolean;
  items: Item[];
  itemToString?: (item: Item | null) => string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onSelect?: (item: Item) => void;
  placeholder?: string;
  renderItem?: (item: Item) => React.ReactNode;
  value: string;
  showSuggestionsOnFocus?: boolean;
};

export function Suggest<Item>({
  children,
  disabled = false,
  dropdownMinWidth,
  getItemKey,
  id,
  isItemDisabled = () => false,
  items,
  itemToString = (item: Item) => (item ? String(item) : ''),
  onBlur,
  onChange,
  onFocus,
  onSelect,
  placeholder = '',
  renderItem = String,
  showSuggestionsOnFocus,
  value,
}: Props<Item>) {
  const handleSelect = useCallback(
    (item: Item) => {
      const newValue = itemToString(item);
      onChange(newValue);
      if (onSelect) {
        onSelect(item);
      }
    },
    [onChange, onSelect],
  );

  return (
    <Downshift itemToString={itemToString} onSelect={handleSelect}>
      {({isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, openMenu}) => (
        <div>
          <Dropdown
            isOpen={isOpen && items.length !== 0}
            minWidth={dropdownMinWidth}
            getContentContainerProps={() => getMenuProps({}, {suppressRefError: true})}
            content={
              <Listbox>
                {isOpen &&
                  items.length !== 0 &&
                  items.map((item, index) => (
                    <Listbox.Option
                      key={getItemKey(item)}
                      isHighlighted={highlightedIndex === index}
                      {...getItemProps({
                        item,
                        index,
                        disabled: isItemDisabled(item),
                        tabIndex: -1,
                      })}
                    >
                      {renderItem(item)}
                    </Listbox.Option>
                  ))}
              </Listbox>
            }
          >
            <div className={styles.suggest}>
              {children({
                getInputProps: (props?: JSX.IntrinsicElements['input']) =>
                  getInputProps({
                    ...props,
                    disabled,
                    id,
                    placeholder,
                    value,
                    onBlur,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(event.target.value);
                      if (props?.onChange) {
                        props.onChange(event);
                      }
                    },
                    onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
                      if (event.target.value || showSuggestionsOnFocus) {
                        openMenu();
                      }
                      onFocus?.();
                      if (props?.onFocus) {
                        props.onFocus(event);
                      }
                    },
                  }) as GetInputPropsOptionsRef,
              })}
            </div>
          </Dropdown>
        </div>
      )}
    </Downshift>
  );
}
