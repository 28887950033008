import {DataState} from '@joomcode/deprecated-utils/dataState';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {LegalEntityId} from 'domain/legalEntity/model';
import {OneTimeBonusRecord, OneTimeBonusRecordId} from 'domain/oneTimeBonusRecord/model';
import {deleteOneTimeBonusRecordFx} from 'domain/oneTimeBonusRecord/stores/main';
import {OneTimeBonusRecordsTable} from 'domain/oneTimeBonusRecord/widgets/Table';
import {SecurePermission} from 'domain/permission/model/secure';
import {UserId} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  userId: UserId;
  legalEntityId: LegalEntityId;
  records: OneTimeBonusRecord[];
  dataState: DataState;
  isSensitiveDataHidden: boolean;
  onDialogOpenWithPayload(record: OneTimeBonusRecord): void;
};

export function JobContractOneTimeBonusTab({
  userId,
  legalEntityId,
  records,
  dataState,
  isSensitiveDataHidden,
  onDialogOpenWithPayload,
}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canEdit = acl.hasSecurePermission(SecurePermission.ONE_TIME_BONUS_RECORD_WRITE);
  const onDeleteRecordClick = useCallback(
    (id: OneTimeBonusRecordId) => {
      confirm(
        {
          title: intl.formatMessage(messages.deletionConfirmationTitle),
          text: intl.formatMessage(messages.deletionConfirmationText),
          confirmationText: intl.formatMessage(messages.deletionConfirmationButton),
          intent: 'negative',
          onConfirm: () =>
            deleteOneTimeBonusRecordFx({id, userId, legalEntityId}).catch(toaster.interceptThenThrowError),
        },
        intl,
      );
    },
    [intl, userId, legalEntityId],
  );
  return (
    <OneTimeBonusRecordsTable
      records={records}
      dataState={dataState}
      isSensitiveDataHidden={isSensitiveDataHidden}
      {...(canEdit && {
        onDeleteItem: onDeleteRecordClick,
        onEditItem: onDialogOpenWithPayload,
      })}
    />
  );
}
