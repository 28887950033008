import {DataState} from '@joomcode/deprecated-utils/dataState';
import {omit} from '@joomcode/deprecated-utils/object/omit';
import {createJobContractRecordFx, updateJobContractRecordFx} from 'domain/jobContract/stores/main';
import {TimeOffBalanceRecordType} from 'domain/timeOff/balanceRecord/model/type';
import {createTimeOffBalanceRecord} from 'domain/timeOff/balanceRecord/stores/main';
import {UserId} from 'domain/user/model';
import {UserTimeOffBalances} from 'domain/user/model/timeOffBalances';
import {getMyPrivateInfoFx, getUserPrivateInfoByIdFx, resetUserPrivateInfo} from 'domain/user/stores/privateInfo';
import {createStore} from 'effector';
import {getSubordinateTimeOffBalancesFx} from '.';

type State = {
  byUserId: Record<UserId, UserTimeOffBalances>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $userTimeOffBalances = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on([getMyPrivateInfoFx, getUserPrivateInfoByIdFx, getSubordinateTimeOffBalancesFx], (state, {userId}) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on(
    [getMyPrivateInfoFx.fail, getUserPrivateInfoByIdFx.fail, getSubordinateTimeOffBalancesFx.fail],
    (state, {params: {userId}}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.FAILED,
        },
      };
    },
  )
  .on(
    [getMyPrivateInfoFx.done, getUserPrivateInfoByIdFx.done, getSubordinateTimeOffBalancesFx.done],
    (state, {params, result}) => {
      const {availableRemoteWorkDays, availableVacationDays, plannedVacationDays} = result;
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [params.userId]: {
            availableRemoteWorkDays,
            availableVacationDays,
            plannedVacationDays,
          },
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [params.userId]: DataState.LOADED,
        },
      };
    },
  )
  .on(createTimeOffBalanceRecord.done, (state, {params, result: {type, totalBalance}}) => {
    const diff =
      type === TimeOffBalanceRecordType.VACATION
        ? {availableVacationDays: totalBalance}
        : {availableRemoteWorkDays: totalBalance};

    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [params.userId]: {
          ...state.byUserId[params.userId],
          ...diff,
        },
      },
    };
  })
  .on([resetUserPrivateInfo, updateJobContractRecordFx, createJobContractRecordFx], (state, {userId}) => {
    return {
      ...state,
      byUserId: omit(state.byUserId, [userId]),
      dataStateByUserId: omit(state.dataStateByUserId, [userId]),
    };
  });
