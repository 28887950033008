import {createStore, Effect, Store} from 'effector';
import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {identity, isNotNullish} from '@joomcode/deprecated-utils/function';
import difference from 'lodash/difference';
import {EntityStore, EntityStoreState, EntityListStore, EntityListState} from '../state';

export type TotalCountData<Entity> = {items: Entity[]; totalCount?: number};

type Reducer = <Entity>(state: EntityStoreState<Entity>, ids: string[]) => EntityStoreState<Entity> | void;

const onLoading: Reducer = (state, ids) => {
  return {
    ...state,
    byIdState:
      ids.length > 0
        ? {
            ...state.byIdState,
            ...arrayToObject(ids, identity, () => DataState.LOADING),
          }
        : state.byIdState,
    listState: DataState.LOADING,
  };
};

const onError: Reducer = (state, ids) => {
  return {
    ...state,
    byIdState:
      ids.length > 0
        ? {
            ...state.byIdState,
            ...arrayToObject(ids, identity, () => DataState.FAILED),
          }
        : state.byIdState,
    listState: DataState.FAILED,
  };
};

type OnLoadedProps<Entity> = {
  state: EntityStoreState<Entity>;
  initialIds: string[];
  result: Entity[];
  getEntityId: (entity: Entity) => string;
};

function onLoaded<Entity>({state, initialIds, result, getEntityId}: OnLoadedProps<Entity>): EntityStoreState<Entity> {
  const loadedIds = result.map((item) => getEntityId(item));
  const notLoadedIds = difference(initialIds, loadedIds);

  return {
    ...state,
    byId: {
      ...state.byId,
      ...arrayToObject(result, (item) => getEntityId(item)),
    },
    byIdState:
      loadedIds.length > 0 || notLoadedIds.length > 0
        ? {
            ...state.byIdState,
            ...arrayToObject(loadedIds, identity, () => DataState.LOADED),
            ...arrayToObject(notLoadedIds, identity, () => DataState.FAILED),
          }
        : state.byIdState,
    list: loadedIds,
    listState: DataState.LOADED,
  };
}

export function createRawEntityStore<Entity>() {
  return createStore<EntityStoreState<Entity>>({
    byId: {},
    byIdState: {},
    byIdSyncTime: {},
    list: [],
    listState: DataState.IDLE,
    listSyncTime: 0,
  });
}

export function createEntityStore<Entity, QueryParams>(
  props: {
    getEntityId(entity: Entity): string;
    loadByIdFx?: Effect<string, Entity>;
    store?: Store<EntityStoreState<Entity>>;
  } & (
    | {
        queryTotalCount: {
          fx: Effect<QueryParams, TotalCountData<Entity>>;
          getIdsFromParams(params: QueryParams): string[];
        };
      }
    | {
        query: {
          fx: Effect<QueryParams, Entity[]>;
          getIdsFromParams(params: QueryParams): string[];
        };
      }
    | {
        query?: never;
        queryTotalCount?: never;
      }
  ),
): EntityStore<Entity> {
  const store = props.store ?? createRawEntityStore<Entity>();
  const {getEntityId, loadByIdFx} = props;

  if ('query' in props && props.query !== undefined) {
    const {query} = props;
    store
      .on(query.fx, (state, params) => {
        const ids = query.getIdsFromParams(params);
        return onLoading(state, ids);
      })
      .on(query.fx.fail, (state, {params}) => {
        const ids = query.getIdsFromParams(params);
        return onError(state, ids);
      })
      .on(query.fx.done, (state, {params, result}) => {
        const initialIds = query.getIdsFromParams(params);
        return onLoaded({state, initialIds, result, getEntityId});
      });
  }

  if ('queryTotalCount' in props && props.queryTotalCount !== undefined) {
    const {queryTotalCount} = props;
    store
      .on(queryTotalCount.fx, (state, params) => {
        const ids = queryTotalCount.getIdsFromParams(params);
        return onLoading(state, ids);
      })
      .on(queryTotalCount.fx.fail, (state, {params}) => {
        const ids = queryTotalCount.getIdsFromParams(params);
        return onError(state, ids);
      })
      .on(queryTotalCount.fx.done, (state, {params, result}) => {
        const initialIds = queryTotalCount.getIdsFromParams(params);
        return {...onLoaded({state, initialIds, result: result.items, getEntityId}), totalCount: result.totalCount};
      });
  }

  if (loadByIdFx) {
    store
      .on(loadByIdFx, (state, id) => ({
        ...state,
        byIdState: {
          ...state.byIdState,
          [id]: DataState.LOADING,
        },
      }))
      .on(loadByIdFx.fail, (state, {params: id}) => ({
        ...state,
        byIdState: {
          ...state.byIdState,
          [id]: DataState.FAILED,
        },
      }))
      .on(loadByIdFx.done, (state, {params: id, result: item}) => ({
        ...state,
        byId: {
          ...state.byId,
          [id]: item,
        },
        byIdState: {
          ...state.byIdState,
          [id]: DataState.LOADED,
        },
      }));
  }

  return store;
}

export function createEntityListStore<Entity>($entityStore: EntityStore<Entity>): EntityListStore<Entity> {
  return $entityStore.map<EntityListState<Entity>>((state) => ({
    data: state.list.flatMap((id) => {
      const item = state.byId[id];

      if (isNotNullish(item)) {
        return [item];
      }

      return [];
    }),
    dataState: state.listState,
    nextPageToken: state.nextPageToken,
    totalCount: state.totalCount,
  }));
}

export function addEntityUpdateEffect<Entity, Fx extends Effect<unknown, Entity>>(
  store: EntityStore<Entity>,
  {
    fx,
    getIdFromParams,
    getIdFromResult,
  }: {
    fx: Fx;
    getIdFromParams?: (params: Fx['__']) => string;
    getIdFromResult?: (result: Entity) => string;
  },
): EntityStore<Entity> {
  if (getIdFromParams) {
    store
      .on(fx, (state, params) => {
        const id = getIdFromParams(params);

        return {
          ...state,
          byIdState: {
            ...state.byIdState,
            [id]: state.byIdState[id] ?? DataState.LOADING,
          },
        };
      })
      .on(fx.fail, (state, {params}) => {
        const id = getIdFromParams(params);

        return {
          ...state,
          byIdState: {
            ...state.byIdState,
            [id]: state.byIdState[id] ?? DataState.FAILED,
          },
        };
      });
  }

  if (getIdFromResult || getIdFromParams) {
    store.on(fx.done, (state, {params, result}) => {
      const id = (getIdFromResult?.(result) ?? getIdFromParams?.(params)) as string;

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: result,
        },
        byIdState: {
          ...state.byIdState,
          [id]: DataState.LOADED,
        },
      };
    });
  }

  return store;
}
