import {RolesInTeamsValidatorError} from 'domain/role/widgets/FieldRolesInTeams/validate';
import {defineMessages} from 'react-intl';

export const errorMessages = defineMessages({
  [RolesInTeamsValidatorError.PARTIALLY_FILLED]: {
    defaultMessage: 'Both role and team must present in each row',
    description: 'Roles in teams: errorMessage',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load JDM data. Please refresh the page',
    description: 'Roles in teams: errorMessage',
  },
});
