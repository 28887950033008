import {
  constant,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
  union,
} from '@joomcode/deprecated-utils/jsonValidation';
import {CountryCode} from 'domain/countryCode/model';
import {currencySchema} from 'domain/currency/model';
import {LevelCode} from 'domain/levelCode/model';
import uuid from 'uuid/v4';

export const salaryRangeSchema = object({
  comment: optional(string()),
  currency: currencySchema,
  groupOfFunctions: optional(string()),
  levelCode: oneOfEnum(LevelCode),
  maxAmount: number(),
  minAmount: number(),
  region: oneOfEnum(CountryCode),
  stockOptionsProposal: optional(number()),
  targetAmount: number(),
  userFunction: string(),
}).map((salaryRange) => ({...salaryRange, id: uuid()}));
export const nullableSalaryRangeSchema = union(constant(null), salaryRangeSchema);

export type SalaryRange = ExtractSchemaType<typeof salaryRangeSchema>;
export type NullableSalaryRange = ExtractSchemaType<typeof nullableSalaryRangeSchema>;
