import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {authApi} from 'api/auth';
import {Permission} from 'domain/permission/model';
import {selfApi} from 'domain/self/api';
import {UserFull} from 'domain/user/model';
import {withVpn} from 'hocs/withVpn';
import React from 'react';
import {useIntl} from 'react-intl';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';

type Props = {
  isSelfUser: boolean;
  user: UserFull;
};

export const UserPageActions = withVpn(({isSelfUser, user}: Props) => {
  const acl = useAcl();
  const intl = useIntl();
  const onAuthenticateAs = () => {
    if (user) {
      authApi.authenticateAs(user.id).then(() => window.location.reload());
    }
  };

  const onPretendToBeNormal = () => {
    if (user) {
      selfApi.toggleDefaultUserPretence().then(() => window.location.reload());
    }
  };

  return (
    <ButtonGroup spaced size='m'>
      {window.intranetEnv.APP_ENV !== 'production' && !isSelfUser && (
        <Button kind='primary' intent='primary' onClick={onAuthenticateAs}>
          {intl.formatMessage(messages.authenticateAs, {name: user.firstName})}
        </Button>
      )}
      {isSelfUser && acl.hasPermission(Permission.USER_PRETEND_TO_BE_NORMAL) && (
        <Button kind='primary' intent='primary' onClick={onPretendToBeNormal}>
          {intl.formatMessage(user.pretendingToBeNormal ? messages.backToSelf : messages.pretendToBeNormal)}
        </Button>
      )}
      {(isSelfUser || acl.hasPermission(Permission.USER_WRITE)) && (
        <RouterLinkButton to={usersUrls.userEdit({login: user.login})} size='m' kind='primary' intent='primary'>
          {intl.formatMessage(messages.editButton)}
        </RouterLinkButton>
      )}
    </ButtonGroup>
  );
});
