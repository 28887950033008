import {array, ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {marketDataRecordSchema} from 'domain/marketDataRecord/model';
import {regularBonusRecordSchema} from 'domain/regularBonusRecord/model';
import {salaryRecordSchema} from 'domain/salaryRecord/model';
import {User, userIdSchema, userSchema} from 'domain/user/model';
import {BulkUploadMeta, bulkUploadMetaSchema, BulkUploadStatus} from 'models/system/bulkUpload';
import {idSchema} from 'models/system/id';

export const financialBulkUploadResultIdSchema = idSchema;

export const financialBulkUploadResultSchema = object({
  id: financialBulkUploadResultIdSchema,
  error: optional(string()),
  salaryBulkUploadRecords: optional(
    array(
      object({
        record: optional(salaryRecordSchema),
        oldRecord: optional(salaryRecordSchema),
        meta: bulkUploadMetaSchema,
        user: optional(userSchema),
      }),
    ),
  ),
  marketDataBulkUploadRecords: optional(
    array(
      object({
        record: optional(marketDataRecordSchema),
        oldRecord: optional(marketDataRecordSchema),
        meta: bulkUploadMetaSchema,
        user: optional(userSchema),
      }),
    ),
  ),
  regularBonusBulkUploadRecords: optional(
    array(
      object({
        record: optional(regularBonusRecordSchema),
        oldRecord: optional(regularBonusRecordSchema),
        meta: bulkUploadMetaSchema,
        user: optional(userSchema),
      }),
    ),
  ),
  status: oneOfEnum(BulkUploadStatus),
});

export const financialBulkUploadInputSchema = object({
  id: financialBulkUploadResultIdSchema,
  salaryBulkUploadInputRecords: optional(
    array(
      object({
        record: salaryRecordSchema,
        oldRecord: optional(salaryRecordSchema),
        userId: userIdSchema,
      }),
    ),
  ),
  marketDataBulkUploadInputRecords: optional(
    array(
      object({
        record: marketDataRecordSchema,
        oldRecord: optional(marketDataRecordSchema),
        userId: userIdSchema,
      }),
    ),
  ),
  regularBonusBulkUploadInputRecords: optional(
    array(
      object({
        record: regularBonusRecordSchema,
        oldRecord: optional(regularBonusRecordSchema),
        userId: userIdSchema,
      }),
    ),
  ),
});

export type FinancialBulkUploadResultId = ExtractSchemaType<typeof financialBulkUploadResultIdSchema>;
export type FinancialBulkUploadResult = ExtractSchemaType<typeof financialBulkUploadResultSchema>;
export type FinancialBulkUploadInput = ExtractSchemaType<typeof financialBulkUploadInputSchema>;
export type FinancialBulkUploadRow<RecordType> = {
  record: RecordType | undefined;
  oldRecord: RecordType | undefined;
  meta: BulkUploadMeta;
  user: User | undefined;
};
