import {getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {oneOfEnum, string} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {JsonSerializer, LocalStorage, SingleStorageManager} from '@joomcode/deprecated-utils/Storage';
import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {Page} from '@joomcode/joom-ui/Page';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {LandmarkConfig, landmarkConfigSchema, LandmarkType} from 'domain/officeMap/landmark/model';
import {landmarkPermissions} from 'domain/officeMap/landmark/model/landmarkPermissions';
import {OfficeLandmarkTypeFilter} from 'domain/officeMap/landmark/widgets/Filter';
import {officesApi} from 'domain/officeMap/office/api';
import {useOffices} from 'domain/officeMap/office/hooks/useOffices';
import {useOfficesReload} from 'domain/officeMap/office/hooks/useOfficesReload';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {officeAliasMessages} from 'domain/officeMap/office/model/alias/messages';
import {favoriteOfficeStorage} from 'domain/officeMap/office/widgets/FavoriteOfficeRedirect';
import {OfficeMap} from 'domain/officeMap/office/widgets/Map';
import {OfficePageTabs} from 'domain/officeMap/office/widgets/PageTabs';
import {OfficeSearch} from 'domain/officeMap/office/widgets/Search';
import {OfficeRoom, OfficeRoomId, officeRoomIdSchema} from 'domain/officeMap/room/model';
import {BookingCallout} from 'domain/officeMap/room/widgets/BookingCallout';
import {OfficeSeat, OfficeSeatId, seatIdentificationFromString} from 'domain/officeMap/seat/model';
import {Permission} from 'domain/permission/model';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {User, UserFull} from 'domain/user/model';
import {NotFoundPage} from 'pages/NotFound';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Helmet from 'react-helmet';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {pageTitles} from 'routes/officeMap/titles';
import {officeUrls} from 'routes/officeMap/urls';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';
import {messages} from './messages';
import styles from './styles.css';

const landmarkConfigStorage = new SingleStorageManager(
  'landmarkConfig',
  new LocalStorage(),
  new JsonSerializer(landmarkConfigSchema),
);

type Props = {
  selfUser: UserFull;
};

function idFromUrl<T extends string>(valueInUrl: T): T | null {
  return valueInUrl !== '' ? valueInUrl : null;
}

export function OfficeMapPage({selfUser}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const history = useHistory();
  const {alias: officeAlias} = useTypedParams({alias: oneOfEnum(OfficeAlias)});
  const calendarIssuesFeature = useFeature(Feature.CALENDAR_ISSUES);

  const [landmarkConfig, setLandmarkConfig] = useState<LandmarkConfig>(() => {
    const config = landmarkConfigStorage.restore();
    if (!config) {
      // default config
      return landmarkConfigSchema.runWithException({[LandmarkType.ROOM]: true});
    }
    // hide restricted landmark types
    Object.entries(landmarkPermissions).forEach(([landmarkType, permission]) => {
      if (permission && !acl.hasPermission(permission)) {
        config[landmarkType as keyof typeof config] = false;
      }
    });
    return config;
  });
  const [selectedSeatIdQuery, setSelectedSeatIdQuery] = useQueryParam<OfficeSeatId | string>('seat', string(), '');
  const [selectedRoomIdQuery, setSelectedRoomIdQuery] = useQueryParam<OfficeRoomId>('room', officeRoomIdSchema, '');
  const {dataState: usersDataState, userByLogin} = useAllUsers();
  const officesStore = useOffices();

  useOfficesReload();
  useEffect(() => {
    landmarkConfigStorage.store(landmarkConfig);
  }, [landmarkConfig]);
  useEffect(() => {
    if (officeAlias) {
      favoriteOfficeStorage.store(officeAlias);
    }
  }, [officeAlias]);

  const usersBySeat = useMemo(
    () =>
      Object.values(userByLogin).reduce((obj: Record<OfficeSeatId, User>, user) => {
        if (user.seat !== undefined) {
          // eslint-disable-next-line no-param-reassign
          obj[user.seat.id] = user;
        }
        return obj;
      }, {}),
    [userByLogin],
  );

  const selectSeat = useCallback(
    (seat: OfficeSeat | null, alias: string | undefined = officeAlias) => {
      if (!alias) {
        return;
      }
      if (seat) {
        history.push(officeUrls.seat({alias}, seat));
      } else {
        setSelectedSeatIdQuery('');
      }
    },
    [history, officeAlias],
  );
  const selectRoom = useCallback(
    (room: OfficeRoom | null, alias: string | undefined = officeAlias) => {
      if (!alias) {
        return;
      }
      if (room) {
        history.push(officeUrls.room({alias}, room));
      } else {
        setSelectedRoomIdQuery('');
      }
    },
    [history, officeAlias],
  );

  const selectedSeatIdentification = seatIdentificationFromString(selectedSeatIdQuery);
  const selectedRoomId = idFromUrl<OfficeRoomId>(selectedRoomIdQuery);

  if (!officeAlias) {
    return <NotFoundPage />;
  }

  return (
    <PageStateHandler data={officesStore} state={getConsolidatedDataState(officesStore.dataState, usersDataState)}>
      {({officesByAlias, roomsById}) => {
        const office = officesByAlias[officeAlias];
        if (!office) {
          return <NotFoundPage />;
        }
        return (
          <Page
            title={intl.formatMessage(pageTitles.office)}
            stretchToViewport
            actions={
              <ButtonGroup spaced>
                {acl.hasPermission(Permission.OFFICE_MAP_SEATS_DOWNLOAD_DATA) && (
                  <DownloadLinkButton
                    size='m'
                    kind='text'
                    intent='neutral'
                    href={officesApi.buildSeatsDataDownloadLink()}
                    iconLeft={<DownloadIcon />}
                  >
                    {intl.formatMessage(messages.downloadSeatsData)}
                  </DownloadLinkButton>
                )}
                {acl.hasPermission(Permission.OFFICE_ROOM_TOKEN_GENERATE) && (
                  <RouterLinkButton size='m' kind='primary' intent='primary' to={officeUrls.roomTokens()}>
                    {intl.formatMessage(messages.generateRoomTokens)}
                  </RouterLinkButton>
                )}
              </ButtonGroup>
            }
            headerContent={<OfficePageTabs activeTab={officeAlias} />}
          >
            <Helmet
              title={intl.formatMessage(messages.htmlTitle, {
                officeName: intl.formatMessage(officeAliasMessages[office.alias]),
              })}
            />
            {calendarIssuesFeature.isAvailable && <BookingCallout />}
            <div className={styles.layout}>
              <div className={styles.controls}>
                <div className={styles.search}>
                  <OfficeSearch
                    autoFocus={!selectedSeatIdentification && !selectedRoomId}
                    usersByLogin={userByLogin}
                    selectSeat={selectSeat}
                    selectRoom={selectRoom}
                    officesByAlias={officesByAlias}
                    currentOfficeAlias={officeAlias}
                  />
                </div>
                <div className={styles.filters}>
                  <OfficeLandmarkTypeFilter landmarkConfig={landmarkConfig} setLandmarkConfig={setLandmarkConfig} />
                </div>
              </div>
              <div className={styles.mapContainer}>
                <div className={styles.map}>
                  <OfficeMap
                    usersBySeatId={usersBySeat}
                    selectedSeat={selectedSeatIdentification}
                    onSeatSelect={selectSeat}
                    selfUser={selfUser}
                    selectedRoomId={selectedRoomId}
                    onRoomSelect={selectRoom}
                    landmarkConfig={landmarkConfig}
                    roomsById={roomsById}
                    office={office}
                  />
                </div>
              </div>
            </div>
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
