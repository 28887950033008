import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  freeSeat: {
    defaultMessage: 'Move out',
    description: '[button] Leave seat on office map',
  },
  seatNumber: {
    defaultMessage: 'Desk {number}',
    description: 'Seat number note',
  },
  unassignSeat: {
    defaultMessage: 'Clear desk',
    description: '[button] Unassign seat on office map',
  },
  copyIconLabel: {
    defaultMessage: 'Copy desk link',
    description: 'Label for copy icon',
  },
  seatWithoutNumber: {
    defaultMessage: 'Desk without a number',
    description: 'Seat without number note',
  },
});
