import {QueryRequestPayload} from 'api/types';
import {intranetApi} from 'apiClient';
import {CountableOffers, countableOfferSchema} from 'domain/offer/model';
import {OffersSearchFilters} from 'domain/offer/model/filters';

export const getAll = async ({
  filters,
  offset,
  limit,
}: QueryRequestPayload<OffersSearchFilters>): Promise<CountableOffers> => {
  const {data} = await intranetApi.post('/v1/offers/getAll', filters, {params: {offset, limit}});

  return countableOfferSchema.runWithException(data);
};
