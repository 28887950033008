import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {UserInfo, userInfoSchema} from 'domain/compensationReviewRequest/model/userInfo';
import {SecurePermission} from 'domain/permission/model/secure';
import {UserId} from 'domain/user/model';

const getUserInfoGeneric =
  (endpoint: string) =>
  async (userId: UserId): Promise<UserInfo> => {
    const {data} = await intranetApi.get(endpoint, {params: {userId}});

    return userInfoSchema.runWithException(data);
  };

const getAnyUserInfo = getUserInfoGeneric('/v1/secure/compensationReviewRequests/getUserInfo');
const getSubordinateUserInfo = getUserInfoGeneric('/v1/secure/compensationReviewRequests/getSubordinateUserInfo');

export const getUserInfo = createPermissionDependentHandler(
  [{securePermission: SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY, handler: getAnyUserInfo}],
  getSubordinateUserInfo,
);
