import {DataState} from '@joomcode/deprecated-utils/dataState';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {createAutocompleteMultiselectFilter, DataFilter, DataFilterConfig} from '@joomcode/joom-ui/DataFilter';
import {Panel} from '@joomcode/joom-ui/Panel';
import {DataFilterSpinner} from 'components/ui/DataFilterSpinner';
import {createSimpleBooleanFilter} from 'components/ui/SimpleBooleanFilter';
import {marketLevelPositionSortingOrder} from 'domain/compaRatio/model/marketLevelPosition';
import {formatMarketLevelPosition} from 'domain/compaRatio/model/marketLevelPosition/formatter';
import {levelCodeSortingOrder} from 'domain/levelCode/model';
import {timeOffPolicySortingOrder} from 'domain/timeOff/policy/model';
import {formatTimeOffPolicy} from 'domain/timeOff/policy/model/formatter';
import {useUsersFilter} from 'domain/user/hooks/useUsersFilter';
import {User, UserId} from 'domain/user/model';
import {useAvailableFilterValues} from 'domain/userFullInfoRecord/hooks/useAvailableFilterValues';
import {UserFullInfoRecord} from 'domain/userFullInfoRecord/model';
import {UserFullInfoAvailability} from 'domain/userFullInfoRecord/model/availability';
import {UserFullInfoFilters} from 'domain/userFullInfoRecord/model/filters';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  dataState: DataState;
  availability: UserFullInfoAvailability;
  userById: Record<UserId, User>;
  records: UserFullInfoRecord[];
  onlySubordinates?: boolean;
  values: UserFullInfoFilters;
  onChange(payload: UserFullInfoFilters): void;
};

export const UserFullInfoRecordFilters = ({
  dataState,
  availability,
  userById,
  records,
  onlySubordinates,
  values,
  onChange,
}: Props) => {
  const intl = useIntl();
  const {countriesOfTimeOffPolicy, crMarketLevelPositions, divisions, levelCodes, managers, userFunctions, users} =
    useAvailableFilterValues({records, userById, onlySubordinates});
  const chosenManagersHaveSubordinateManagers = useMemo(
    () => values.managerIds?.some((id) => managers.some(({managerId}) => id === managerId)),
    [values.managerIds, managers],
  );
  const managersFilter = useUsersFilter({
    label: intl.formatMessage(messages.managerIds),
    users: managers,
    userById,
  });
  const usersFilter = useUsersFilter({
    label: intl.formatMessage(messages.userIds),
    users,
    userById,
  });

  const config = useMemo<DataFilterConfig<UserFullInfoFilters>>(() => {
    return {
      ...(managers.length > 1 && {
        managerIds: managersFilter,
      }),
      ...(users.length > 1 && {
        userIds: usersFilter,
      }),
      ...(!onlySubordinates && {
        divisions: createAutocompleteMultiselectFilter(intl.formatMessage(messages.divisions), {
          alwaysVisible: true,
          options: (divisions || [])
            .filter(isNotNullish)
            .sort()
            .map((division) => ({
              label: division,
              value: division,
            })),
        }),
      }),
      ...(countriesOfTimeOffPolicy.length > 1 && {
        countriesOfTimeOffPolicy: createAutocompleteMultiselectFilter(
          intl.formatMessage(messages.countriesOfTimeOffPolicy),
          {
            alwaysVisible: true,
            options: countriesOfTimeOffPolicy
              .filter(isNotNullish)
              .sort((a, b) => timeOffPolicySortingOrder.indexOf(a) - timeOffPolicySortingOrder.indexOf(b))
              .map((policy) => ({
                label: formatTimeOffPolicy(policy, intl),
                value: policy,
              })),
          },
        ),
      }),
      ...(availability.cr &&
        crMarketLevelPositions.length > 1 && {
          crMarketLevelPositions: createAutocompleteMultiselectFilter(
            intl.formatMessage(messages.crMarketLevelPositions),
            {
              alwaysVisible: true,
              options: crMarketLevelPositions
                .filter(isNotNullish)
                .sort((a, b) => marketLevelPositionSortingOrder.indexOf(a) - marketLevelPositionSortingOrder.indexOf(b))
                .map((position) => ({
                  label: formatMarketLevelPosition(position, intl),
                  value: position,
                })),
            },
          ),
        }),
      ...(userFunctions.length > 1 && {
        userFunctions: createAutocompleteMultiselectFilter(intl.formatMessage(messages.userFunctions), {
          alwaysVisible: true,
          options: userFunctions
            .filter(isNotNullish)
            .sort()
            .map((userFunction) => ({
              label: userFunction,
              value: userFunction,
            })),
        }),
      }),
      ...(availability.marketData &&
        levelCodes.length > 1 && {
          levelCodes: createAutocompleteMultiselectFilter(intl.formatMessage(messages.levelCodes), {
            alwaysVisible: true,
            options: levelCodes
              .filter(isNotNullish)
              .sort((a, b) => levelCodeSortingOrder.indexOf(a) - levelCodeSortingOrder.indexOf(b))
              .map((levelCode) => ({
                label: levelCode,
                value: levelCode,
              })),
          }),
        }),
      ...(chosenManagersHaveSubordinateManagers && {
        showIndirect: createSimpleBooleanFilter(intl.formatMessage(messages.showIndirect), {
          alwaysVisible: true,
        }),
      }),
    };
  }, [
    intl,
    managers,
    managersFilter,
    usersFilter,
    userFunctions,
    levelCodes,
    crMarketLevelPositions,
    countriesOfTimeOffPolicy,
    chosenManagersHaveSubordinateManagers,
    availability.cr,
    availability.marketData,
  ]);

  if (Object.keys(config).length === 0) {
    return null;
  }

  return (
    <Panel>
      {dataState === DataState.IDLE || dataState === DataState.LOADING ? (
        <DataFilterSpinner />
      ) : (
        <DataFilter config={config} onChange={onChange} values={values} />
      )}
    </Panel>
  );
};
