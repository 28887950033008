import {defineMessages} from 'react-intl';
import {RoomAttribute} from '.';

export const roomAttributeMessages = defineMessages<RoomAttribute>({
  [RoomAttribute.CAMERA]: {
    defaultMessage: 'camera',
    description: 'Camera attribute',
  },
  [RoomAttribute.MARKER_WALL]: {
    defaultMessage: 'marker wall',
    description: 'Marker wall attribute',
  },
  [RoomAttribute.PROJECTOR]: {
    defaultMessage: 'projector',
    description: 'Projector attribute',
  },
  [RoomAttribute.TABLE]: {
    defaultMessage: 'table',
    description: 'Table attribute',
  },
  [RoomAttribute.TV]: {
    defaultMessage: 'TV',
    description: 'TV attribute',
  },
});

export const roomMessages = defineMessages({
  name: {
    defaultMessage: 'Meeting room {roomNumber}',
    description: 'Meeting room name',
  },
  capacity: {
    defaultMessage: '{count, plural, one {# seat} other {# seats}}',
    description: 'Meeting room capacity',
  },
});
