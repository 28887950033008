import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Offer details',
    description: '[title] Offer page: offer details panel title',
  },
  buttonUpdate: {
    defaultMessage: 'Update offer',
    description: '[button] Offer page: update offer details button',
  },
});
