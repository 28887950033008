import {OfficePolicyReadAccess} from 'domain/officePolicyRecord/model/access';
import {Permission} from 'domain/permission/model';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFull} from 'domain/user/model';
import {useAcl} from 'services/acl';

export function useOfficePolicyReadAccess(user: UserFull | undefined): OfficePolicyReadAccess | null {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const canReadAny = acl.hasPermission(Permission.OFFICE_POLICY_RECORD_READ);

  if (!user || !acl.inVpn()) {
    return null;
  }

  if (canReadAny) {
    return OfficePolicyReadAccess.ANY;
  }
  if (selfUserId && user.ancestorIds.includes(selfUserId)) {
    return OfficePolicyReadAccess.SUBORDINATE;
  }
  if (user.id === selfUserId) {
    return OfficePolicyReadAccess.MY;
  }

  return null;
}
