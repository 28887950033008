import {createEvent} from 'effector';
import {useStore} from 'effector-react';
import {useMemo} from 'react';
import {createTreeViewStore} from './store';
import {TreeViewEvents, TreeViewInput, TreeViewOutput} from './types';
import {prepareInitialState} from './utils';

export function useTreeView<Item, ItemId extends string>(
  params: TreeViewInput<Item, ItemId>,
  dependencies: unknown[],
): TreeViewOutput<ItemId> {
  const events: TreeViewEvents<ItemId> = useMemo(
    () => ({
      toggleItem: createEvent<{id: ItemId}>('toggle item expanded state'),
      expandAllItems: createEvent<void>('set all items expanded'),
      resetAllItems: createEvent<void>('reset all items expanded state'),
    }),
    dependencies,
  );
  const $treeView = useMemo(() => createTreeViewStore<ItemId>(prepareInitialState(params), events), [events]);
  const viewState = useStore($treeView);

  return {viewState, events};
}
