import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {LegalEntity} from 'domain/legalEntity/model';
import {getLegalEntitiesFx} from 'domain/legalEntity/stores/main';
import {$legalEntities} from 'domain/legalEntity/stores/main/state';
import {Permission} from 'domain/permission/model';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  legalEntities: LegalEntity[];
  canReadLegalEntities: boolean;
  dataState: DataState;
};

type Options = {
  enabled?: boolean;
  force?: boolean;
};

export function useLegalEntities({enabled = true, force = false}: Options = {}): ReturnShape {
  const acl = useAcl();
  const {legalEntities, dataState} = useStore($legalEntities);
  const canReadLegalEntities = acl.hasPermission(Permission.LEGAL_ENTITY_READ);

  useEffect(() => {
    if (canReadLegalEntities && enabled && (!isLoadingOrLoaded(dataState) || force)) {
      getLegalEntitiesFx().catch(toaster.interceptThenThrowError);
    }
  }, [enabled, force]);

  return {legalEntities, canReadLegalEntities, dataState};
}
