import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Panel} from '@joomcode/joom-ui/Panel';
import {TreeViewEvents, TreeViewState} from 'hooks/useTreeView/types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type TreeViewHeaderProps = {
  toolbar?: React.ReactNode;
  title: React.ReactNode;
  events: TreeViewEvents<string>;
  viewState: TreeViewState<string>;
  additionalActions?: React.ReactNode;
};

export function TreeViewHeader({toolbar, title, viewState, events, additionalActions}: TreeViewHeaderProps) {
  const intl = useIntl();
  const {itemsCount, expanded} = viewState;
  const onCollapseClick = useCallback(() => events.resetAllItems(), [events.resetAllItems]);
  const onExpandClick = useCallback(() => events.expandAllItems(), [events.expandAllItems]);

  return (
    <Panel.Header
      title={
        <span className={styles.title}>
          {title}
          <span className={styles.counter}>{intl.formatNumber(itemsCount)}</span>
          <ButtonGroup spaced size='m'>
            {itemsCount > 0 &&
              (expanded ? (
                <Button intent='neutral' kind='secondary' onClick={onCollapseClick}>
                  {intl.formatMessage(messages.buttonCollapse)}
                </Button>
              ) : (
                <Button intent='neutral' kind='secondary' onClick={onExpandClick}>
                  {intl.formatMessage(messages.buttonExpand)}
                </Button>
              ))}
            {additionalActions}
          </ButtonGroup>
        </span>
      }
      withSeparator
      toolbar={toolbar}
    />
  );
}
