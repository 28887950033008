import {intranetApi} from 'apiClient';
import {UserFullInfoFilters} from 'domain/userFullInfoRecord/model/filters';

type UserFullInfoRecordDownloadBody = {
  filters: UserFullInfoFilters;
  includeFields: string[];
};

export const buildDownloadSubordinatesLink = (data: UserFullInfoRecordDownloadBody) =>
  intranetApi.getUri({
    data,
    url: `${intranetApi.defaults.baseURL}/v1/secure/users/fullInfoRecords/downloadSubordinates`,
  });
