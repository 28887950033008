import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {FormattedFileSize} from '@joomcode/joom-ui/FormattedFileSize';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {StyledDownloadLink} from 'components/ui/StyledLink';
import {FileMeta} from 'models/system/fileMeta';
import React from 'react';
import styles from './styles.css';

type Props = {
  disabled?: boolean;
  fileMeta: FileMeta;
  link: string;
};

export function FileToDownload({disabled, fileMeta, link}: Props) {
  return (
    <span className={styles.root}>
      <StyledDownloadLink href={link} className={styles.fileName} colored disabled={disabled} target='_blank'>
        <span className={styles.icon}>
          <DownloadIcon />
        </span>
        {fileMeta.fileName}
      </StyledDownloadLink>
      <span className={styles.fileSize}>
        {' '}
        <FormattedFileSize value={fileSizeUtils.createMeasure(fileMeta.size, FileSizeUnit.Byte)} />
      </span>
    </span>
  );
}
