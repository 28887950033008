import {approve, approveOnBehalf} from './approve';
import {cancel} from './cancel';
import {create} from './create';
import {buildDownloadAllLink} from './downloadAll';
import {getAll} from './getAll';
import {getById} from './getById';
import {getMy} from './getMy';
import {getRecommendedJobTitle} from './getRecommendedJobTitle';
import {getSubordinate} from './getSubordinate';
import {getUserInfo} from './getUserInfo';
import {reject, rejectOnBehalf} from './reject';
import {setCompensationsRecorded, setStockOptionsRecorded} from './setRecorded';

export const compensationReviewRequestApi = {
  approve,
  approveOnBehalf,
  buildDownloadAllLink,
  cancel,
  create,
  getAll,
  getById,
  getMy,
  getRecommendedJobTitle,
  getSubordinate,
  getUserInfo,
  reject,
  rejectOnBehalf,
  setCompensationsRecorded,
  setStockOptionsRecorded,
};
