import React, {forwardRef} from 'react';
import {LinkUiProps, useLinkUi} from '../Link';

export type DownloadLinkProps = Omit<JSX.IntrinsicElements['a'] & LinkUiProps, 'download'> & {download?: string};

export const DownloadLink = forwardRef(function DownloadLink(
  {children, download, ...restProps}: DownloadLinkProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const linkUiProps = useLinkUi({download: download ?? true, ...restProps});

  return (
    <a {...linkUiProps} ref={ref}>
      {children}
    </a>
  );
});
