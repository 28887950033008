import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  takeSeat: {
    defaultMessage: 'Take desk',
    description: '[button] Take table on office map',
  },
  moveHere: {
    defaultMessage: 'Move here',
    description: '[button] Change table on office map',
  },
  emptySeat: {
    defaultMessage: 'This desk is free',
    description: 'Free table message',
  },
  seatNumber: {
    defaultMessage: 'Desk {number}',
    description: 'Table number note',
  },
  copyIconLabel: {
    defaultMessage: 'Copy desk link',
    description: 'Label for copy icon',
  },
  seatWithoutNumber: {
    defaultMessage: 'Desk without a number',
    description: 'Seat without number note',
  },
});
