import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Request details',
    description: '[title] Compensation review request panel title',
  },
});

export const labels = defineMessages({
  effectiveDate: {
    defaultMessage: 'Proposed effective date',
    description: 'Compensation review request label',
  },
  user: {
    defaultMessage: 'Joomer',
    description: 'Compensation review request label',
  },
  reason: {
    defaultMessage: 'Reason',
    description: 'Compensation review request label',
  },
  type: {
    defaultMessage: 'Request type',
    description: 'Compensation review request label',
  },
  createdAt: {
    defaultMessage: 'Created',
    description: 'Compensation review request label',
  },
  createdBy: {
    defaultMessage: 'Creator',
    description: 'Compensation review request label',
  },
  status: {
    defaultMessage: 'Status',
    description: 'Compensation review request label',
  },
  approvers: {
    defaultMessage: 'Approvers',
    description: 'Compensation review request label',
  },
  compensationChange: {
    defaultMessage: 'Compensation or role change',
    description: 'Compensation review request label',
  },
  stockOptionsChange: {
    defaultMessage: 'Options change',
    description: 'Compensation review request label',
  },
});
