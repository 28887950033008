import React from 'react';
import styles from './styles.css';

type Props = {
  values: React.ReactNode[];
};

export function MultiValue({values}: Props) {
  return (
    <div className={styles.root}>
      {values.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.value}>
          {value}
        </div>
      ))}
    </div>
  );
}
