import {defineMessages} from 'react-intl';
import {CsvValidatorErrorCode} from './types';

export const csvErrorMessages = defineMessages<CsvValidatorErrorCode>({
  [CsvValidatorErrorCode.CSV_FILE_INVALID]: {
    id: 'validator-csv-file-invalid',
    description: 'Validation error about provided data is not a file',
    defaultMessage: 'CSV file is expected',
  },
  [CsvValidatorErrorCode.CSV_DELIMITER_NOT_FOUND]: {
    id: 'validator-csv-delimiter-not-found',
    description: 'Validation error about the delimiter that cannot be defined in provided csv',
    defaultMessage: 'Delimiter in CSV file cannot be found',
  },
  [CsvValidatorErrorCode.CSV_DELIMITER_INVALID]: {
    id: 'validator-csv-delimiter-invalid',
    description: 'Validation error about the delimiter in provided csv is not allowed',
    defaultMessage: 'Invalid delimiter in CSV file',
  },
});
