import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {RegularBonusRecordDiff} from 'domain/regularBonusRecord/model/diff';
import {
  createRegularBonusRecordFx,
  replaceRegularBonusRecordFx,
  updateRegularBonusRecordFx,
} from 'domain/regularBonusRecord/stores/main';
import {convertRegularBonusValue} from 'domain/regularBonusRecord/utils/convertFormValues';
import {RegularBonusRecordForm} from 'domain/regularBonusRecord/widgets/Form';
import {UserId} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  userId: UserId;
  recordToUpdate?: RegularBonusRecord;
  needToReplace?: boolean;
  isOpen: boolean;
  onClose: () => void;
};
type FormSubmitHandler = FormProps<RegularBonusRecord>['onSubmit'];

export function RegularBonusRecordDialog({userId, recordToUpdate, needToReplace, isOpen, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const onSubmit: FormSubmitHandler = useCallback(
    ({type, ...state}) => {
      const diff: RegularBonusRecordDiff = {
        ...state,
        value: convertRegularBonusValue(type, state.value),
      };

      if (recordToUpdate) {
        if (needToReplace) {
          return replaceRegularBonusRecordFx({previousRecordId: recordToUpdate.id, userId, diff})
            .then(onClose)
            .catch(toaster.interceptThenThrowError);
        }
        return updateRegularBonusRecordFx({id: recordToUpdate.id, userId, diff})
          .then(onClose)
          .catch(toaster.interceptThenThrowError);
      }
      return createRegularBonusRecordFx({userId, diff}).then(onClose).catch(toaster.interceptThenThrowError);
    },
    [userId, recordToUpdate, needToReplace, onClose],
  );
  const title = useMemo(() => {
    if (recordToUpdate) {
      if (needToReplace) {
        return intl.formatMessage(messages.titleReplace);
      }
      return intl.formatMessage(messages.titleUpdate);
    }
    return intl.formatMessage(messages.titleAdd);
  }, [intl, recordToUpdate, needToReplace]);

  return (
    <Dialog
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      isOpen={isOpen}
      onClose={onClose}
      width='min(90vw, 48rem)'
    >
      <Form<RegularBonusRecord> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid, values}) => (
          <>
            <Dialog.Header>{title}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <RegularBonusRecordForm
                formId={formId}
                recordToUpdate={recordToUpdate}
                needToReplace={needToReplace}
                onSubmit={handleSubmit}
                values={values}
              />
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
