import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  documentsStatus: {
    defaultMessage: 'Documents status',
    description: 'Stock option agreement documents status update dialog: field label',
  },
});

export const messages = defineMessages({
  title: {
    defaultMessage: 'Update documents status',
    description: '[title] Stock option agreement documents status update dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Option agreement documents status update dialog',
    description: 'Stock option agreement documents status update dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Stock option agreement documents status update dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Update',
    description: '[button] Stock option agreement documents status update dialog: submit button',
  },
  success: {
    defaultMessage: 'Documents status updated',
    description: 'Stock option agreement documents status update dialog: success message',
  },
  error: {
    defaultMessage: 'Documents status is not specified',
    description: 'Stock option agreement documents status update dialog: error message',
  },
});
