import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  saveButton: {
    defaultMessage: 'Save',
    description: '[button] Legal entity form: save button',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Legal entity form: cancel button',
  },
  currencyOption: {
    defaultMessage: '{currencyName} ({currencyCode})',
    description: 'Legal entity form: currency select option',
  },
});

export const fieldLabels = defineMessages({
  country: {
    defaultMessage: 'Country',
    description: 'Legal entity form: Field label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'Legal entity form: Field label',
  },
});
