import React, {useCallback} from 'react';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../create-locator';
import {Listbox} from '../Listbox';
import {OptionLocator} from '../Listbox/Option';
import {SelectCore, type SelectCoreLocator} from './SelectCore';
import {SelectControl, InputSizeMeasure, SelectedValue, SelectControlLocator, SelectedValueLocator} from './components';
import './theme.css';

export type SelectTestId = ConvertLocatorToTestId<SelectLocator>;
export type SelectLocator = Locator<{
  control: SelectControlLocator;
  value: SelectedValueLocator;
  option: OptionLocator;
  select: SelectCoreLocator;
}>;

export type Props<Item> = {
  id?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  invalid?: boolean;
  items: Item[];
  borderless?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  value: Item | null;
  onChange: (newValue: Item | null) => void;
  inputSize?: `${InputSizeMeasure}`;
  itemToString?: (item: Item) => string;
  placeholder?: string;
  readOnly?: boolean;
  renderItem?: (item: Item) => React.ReactNode;
  renderValue?: (item: Item) => React.ReactNode;
  getItemKey: (item: Item) => string;
  dropdownMinWidth?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  isItemDisabled?: (item: Item) => boolean;
  name?: string;
} & Partial<Mark<SelectLocator>>;

export function Select<Item>({
  items,
  invalid,
  renderItem = (item) => item as unknown as React.ReactNode,
  itemToString = (item) => String(item),
  renderValue,
  borderless = false,
  disabled = false,
  clearable = false,
  readOnly,
  inputSize,
  placeholder,
  getItemKey,
  isItemDisabled = () => false,
  onChange,
  ...rest
}: Props<Item>) {
  const locator = createLocator(rest);

  const onChangeValue = useCallback((newValue: Item | null) => onChange(newValue), [onChange]);

  return (
    <SelectCore
      {...rest}
      onChange={onChangeValue}
      closeOnSelect
      withInput={false}
      disabled={disabled || readOnly}
      clearValueOnSelect={false}
      itemToString={(item) => (item !== null ? itemToString(item as Item) : '')}
      renderControl={({selectedItem, clearSelection, isOpen, isActive, disabled: disabledInner}) => (
        <SelectControl
          isOpen={isOpen}
          isActive={isActive}
          disabled={disabledInner}
          borderless={borderless}
          clearable={clearable && Boolean(selectedItem)}
          invalid={invalid}
          onClearClick={clearSelection}
          inputSize={inputSize}
          {...locator.control()}
        >
          <SelectedValue
            placeholder={placeholder}
            item={selectedItem}
            renderValue={renderValue || itemToString}
            disabled={disabled}
            {...locator.value()}
          />
        </SelectControl>
      )}
      renderItems={({getItemProps, highlightedIndex}) =>
        items.map((item, index) => {
          const key = getItemKey(item);

          return (
            <Listbox.Option
              key={key}
              isHighlighted={highlightedIndex === index}
              {...getItemProps({
                item,
                index,
                disabled: isItemDisabled(item),
                tabIndex: -1,
              })}
              title={itemToString(item)}
              {...locator.option({item: key})}
            >
              {renderItem(item)}
            </Listbox.Option>
          );
        })
      }
      {...locator.select()}
    />
  );
}
