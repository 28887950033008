import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {Role} from 'domain/role/model';
import {TeamLite} from 'domain/team/model/lite';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import {ReactComponent as PropagationIcon} from './propagation.svg';
import styles from './styles.css';

type Props = {
  role: Role;
  team: TeamLite;
};

export function RoleBindingPropagationIcon({role, team}: Props) {
  const intl = useIntl();

  return (
    <span className={styles.root}>
      <TooltipFaq
        placement='top'
        content={intl.formatMessage(messages.propagation, {
          emphasis: (text: React.ReactNode) => <span className={styles.emphasis}>{text}</span>,
          roleName: role.name,
          teamName: team.name,
        })}
      >
        <PropagationIcon />
      </TooltipFaq>
    </span>
  );
}
