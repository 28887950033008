import React from 'react';
import {createLocator, type Locator, type Mark} from '../../create-locator';

import styles from './index.css';

export type ValueWrapperLocator = Locator<void>;

export type ValueWrapperProps = {children: string} & Partial<Mark<ValueWrapperLocator>>;

export function ValueWrapper({
  children,
  title,
  ...restProperties
}: ValueWrapperProps & {
  title?: string;
}) {
  const locator = createLocator(restProperties);

  return (
    <span className={styles.value} {...locator()} title={title}>
      {children}
    </span>
  );
}
