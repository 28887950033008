import {isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plus.svg';
import {Page} from '@joomcode/joom-ui/Page';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Permission} from 'domain/permission/model';
import {loadAllRtconfItemsFx} from 'domain/rtconf/stores/main';
import {$rtconfItems} from 'domain/rtconf/stores/main/state';
import {RtconfList} from 'domain/rtconf/widgets/List';
import {useStore} from 'effector-react';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {rtconfUrls} from 'routes/rtconf/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

export function RtconfPage() {
  const intl = useIntl();
  const rtconfItems = useStore($rtconfItems);
  const acl = useAcl();

  const [searchQuery, setSearchQuery] = useQueryParam<string>('query', string(), '');

  useEffect(() => {
    if (!isLoadingOrLoaded(rtconfItems.dataState)) {
      loadAllRtconfItemsFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return (
    <PageStateHandler data={rtconfItems.items} state={rtconfItems.dataState}>
      {(items) => (
        <Page
          title={intl.formatMessage(messages.title)}
          actions={
            acl.hasPermission(Permission.RTCONF_WRITE) && (
              <RouterLinkButton
                to={rtconfUrls.create}
                size='m'
                kind='primary'
                intent='primary'
                type='submit'
                iconLeft={<PlusIcon />}
              >
                {intl.formatMessage(messages.createButton)}
              </RouterLinkButton>
            )
          }
        >
          <Panel withPadding>
            <RtconfList items={items} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </Panel>
        </Page>
      )}
    </PageStateHandler>
  );
}
