import Downshift, {
  ControllerStateAndHelpers,
  DownshiftState,
  GetInputPropsOptions,
  StateChangeOptions,
} from 'downshift';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {PropsWithoutRef, useCallback} from 'react';
import {defaultStateReducer} from './stateReducer';

/**
 * Downshift’s GetInputPropsOptions uses React.LegacyRef, which is incompatible with React.forwardRef
 */
type GetInputPropsOptionsRef = PropsWithoutRef<GetInputPropsOptions>;

type SuggestionFilter<Item> = (item: Item, query: string) => boolean;

type ChildrenRenderProps<Item> = {
  isOpen: boolean;
  filteredItems: Item[];
  getInputProps: <T extends JSX.IntrinsicElements['input']>(options?: T) => GetInputPropsOptionsRef;
  downshift: ControllerStateAndHelpers<Item>;
};

export type HeadlessSearchableSelectProps<Item> = {
  children(props: ChildrenRenderProps<Item>): JSX.Element | null;
  disabled?: boolean;
  items: Item[];
  itemToString(item: Item): string;
  isOpen?: boolean;
  onChange(value: Item | null): void;
  suggestionFilter?: SuggestionFilter<Item>;
  value: Item | null;
  optionsAreAlwaysVisible?: boolean;
  stateReducer?: (
    state: DownshiftState<unknown>,
    changes: StateChangeOptions<unknown>,
  ) => Partial<StateChangeOptions<unknown>>;
};

function SelectWithDownshift<Item>({
  children,
  downshift,
  disabled,
  items,
  itemToString,
  suggestionFilter: customSuggestionFilter,
}: HeadlessSearchableSelectProps<Item> & {downshift: ControllerStateAndHelpers<Item>}) {
  const isOpen = !disabled && downshift.isOpen;
  const suggestionFilter: SuggestionFilter<Item> =
    customSuggestionFilter || ((item, query) => itemToString(item).toLowerCase().includes(query.toLowerCase()));

  const {inputValue} = downshift;
  const filteredItems = items.filter((item) => !inputValue || suggestionFilter(item, inputValue));

  return children({
    downshift,
    filteredItems,
    getInputProps: (options) =>
      downshift.getInputProps<JSX.IntrinsicElements['input']>({
        ...options,
        onFocus() {
          downshift.openMenu();
          downshift.setHighlightedIndex(0);
        },
      }),
    isOpen,
  });
}

export function HeadlessSearchableSelect<Item>(props: HeadlessSearchableSelectProps<Item>) {
  const handleSelect: Downshift<Item>['props']['onSelect'] = useCallback(
    (selectedItem: Item | null) => props.onChange(selectedItem ?? null),
    [props.onChange],
  );

  const itemToString = useCallback(
    (item: Item | null) => (isNotNullish(item) ? props.itemToString(item) : ''),
    [props.itemToString],
  );

  return (
    <Downshift
      isOpen={props.isOpen}
      onSelect={handleSelect}
      selectedItem={props.value}
      stateReducer={props.stateReducer || defaultStateReducer}
      itemToString={itemToString}
    >
      {(downshift) => {
        return (
          <div>
            <SelectWithDownshift {...props} downshift={downshift}>
              {props.children}
            </SelectWithDownshift>
          </div>
        );
      }}
    </Downshift>
  );
}
