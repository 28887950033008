import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {TimeOffRequestStatus} from 'domain/timeOff/request/model/status';
import {timeOffRequestStatuses} from 'domain/timeOff/request/model/status/messages';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  status: TimeOffRequestStatus;
};

const intentByStatus: Record<TimeOffRequestStatus, BadgeIntent> = {
  [TimeOffRequestStatus.REJECTED]: 'negative',
  [TimeOffRequestStatus.APPROVED]: 'positive',
  [TimeOffRequestStatus.REQUESTED]: 'primary',
  [TimeOffRequestStatus.CANCELED]: 'neutral',
};

export function TimeOffRequestStatusBadge({status}: Props) {
  const intl = useIntl();
  return <Badge intent={intentByStatus[status]}>{intl.formatMessage(timeOffRequestStatuses[status])}</Badge>;
}
