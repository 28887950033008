import {Button} from '@joomcode/joom-ui/Button';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Permission, ServerPermission} from 'domain/permission/model';
import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {UserRole, UserRoleId} from 'domain/userRole/model';
import React from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import styles from './styles.css';
import {UserRoleCanGrantRolesFields} from './UserRoleCanGrantRolesFields';
import {UserRoleNameField} from './UserRoleNameField';
import {UserRolePermissionsField} from './UserRolePermissionsField';
import {UserRoleRolesField} from './UserRoleRolesField';

type Props = {
  role?: UserRole;
  onSubmit: (roleDiff: Partial<UserRole>) => void;
  submitButtonText: string;
};

const initialPermissionsValue: ServerPermission[] = [];
const initialRoleIdsValue: UserRoleId[] = [];

export function UserRoleForm({role, onSubmit, submitButtonText}: Props) {
  const intl = useIntl();
  const selfUser = useSelfUser();

  const acl = useAcl();
  const canChange = acl.hasPermission(Permission.USER_ROLE_WRITE);

  return (
    <Panel withPadding>
      <Form<Partial<UserRole>> onSubmit={onSubmit}>
        {({handleSubmit, submitting, valid}) => (
          <form onSubmit={handleSubmit}>
            <UserRoleNameField
              disabled={!canChange || submitting}
              name='name'
              initialValue={role?.name ?? ''}
              currentRoleId={role?.id}
            />
            <UserRolePermissionsField
              disabled={!canChange || submitting}
              name='permissions'
              initialValue={role?.permissions ?? initialPermissionsValue}
            />
            <div className={styles.rolesSelectWrapper}>
              <div className={styles.rolesSelect}>
                <UserRoleCanGrantRolesFields role={role} disabled={!canChange || submitting} />
              </div>
              <div className={styles.rolesSelect}>
                <UserRoleRolesField
                  name='managedBy'
                  initialValue={role?.managedBy ?? selfUser?.roleIds ?? initialRoleIdsValue}
                  disabled={!canChange || submitting}
                  label={intl.formatMessage(messages.managedByLabel)}
                />
              </div>
            </div>
            <Button
              size='l'
              kind='primary'
              intent='primary'
              type='submit'
              disabled={!valid || submitting || !canChange}
              loading={submitting}
            >
              {submitButtonText}
            </Button>
          </form>
        )}
      </Form>
    </Panel>
  );
}
