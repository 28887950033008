import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Update termination information dialog',
    description: 'Employment dialog: aria label',
  },
  titleTerminate: {
    defaultMessage: 'Terminate employment',
    description: '[title] Employment dialog: title',
  },
  titleUpdate: {
    defaultMessage: 'Update termination',
    description: '[title] Employment dialog: title',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Employment dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Submit',
    description: '[button] Employment dialog: submit button',
  },
  terminateButton: {
    defaultMessage: 'Terminate',
    description: '[button] Employment dialog: submit button',
  },
  subordinatesWarning: {
    defaultMessage:
      'Upon the termination date all employee’s subordinates (if any) will be moved under the employee’s manager.',
    description: 'Employment dialog: warning text',
  },
});
