import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Role {name}',
    description: '[title] Page title',
  },
  submitButtonText: {
    defaultMessage: 'Save',
    description: '[button] role form submit button',
  },
});
