import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  emergencyContactSectionTitle: {
    defaultMessage: 'Emergency contact',
    description: '[title] User form section title',
  },
});

export const fieldLabels = defineMessages({
  name: {
    defaultMessage: 'Preferred name',
    description: 'User form field label',
  },
  relationship: {
    defaultMessage: 'Relationship',
    description: 'User form field label',
  },
  phone: {
    defaultMessage: 'Phone number',
    description: 'User form field label',
  },
});
