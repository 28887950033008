import {Panel} from '@joomcode/joom-ui/Panel';
import {Page} from 'components/widgets/Page';
import {UserRole} from 'domain/userRole/model';
import {createUserRoleFx} from 'domain/userRole/stores/main';
import {UserRoleForm} from 'domain/userRole/widgets/Form';
import React from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {pageTitles} from 'routes/userRoles/titles';
import {userRolesUrls} from 'routes/userRoles/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';

export function UserRoleCreatePage() {
  const intl = useIntl();
  const history = useHistory();

  const createRole = (roleDiff: Partial<UserRole>) =>
    createUserRoleFx(roleDiff)
      .then((role) => history.push(userRolesUrls.role({id: role.id})))
      .catch(toaster.interceptThenThrowError);

  return (
    <Page
      breadcrumbs={[
        {
          title: intl.formatMessage(pageTitles.root),
          href: userRolesUrls.root(),
        },
      ]}
      title={intl.formatMessage(messages.title)}
    >
      <Panel withPadding>
        <UserRoleForm onSubmit={createRole} submitButtonText={intl.formatMessage(messages.submitButtonText)} />
      </Panel>
    </Page>
  );
}
