import {StringValidatorErrorCode} from '@joomcode/joom-form';
import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  createNew: {
    defaultMessage: 'Create new',
    description: '[button] Input mode switch: create new job title',
  },
  selectExisting: {
    defaultMessage: 'Select existing',
    description: '[button] Input mode switch: select existing job title',
  },
  or: {
    defaultMessage: 'or',
    description: 'Input mode switch delimiter',
  },
  hint: {
    defaultMessage: 'Enter job title similar to the same manager’s reports',
    description: 'Field hint',
  },
});

export const jobTitleErrorMessages = defineMessages({
  [StringValidatorErrorCode.STRING_REG_EXP_NOT_MATCH]: {
    defaultMessage: 'Please use only latin letters, numbers, hyphens and spaces',
    description: 'Job title validation error',
  },
});
