import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {Placeholder} from 'components/ui/Placeholder';
import React from 'react';
import Helmet from 'react-helmet';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function UnauthenticatedPage() {
  const intl = useIntl();

  return (
    <>
      <Helmet title={intl.formatMessage(messages.title)} />
      <Placeholder.EmptyState title={intl.formatMessage(messages.description)}>
        <RouterLinkButton to='/' size='m' kind='primary' intent='primary'>
          {intl.formatMessage(messages.retry)}
        </RouterLinkButton>
      </Placeholder.EmptyState>
    </>
  );
}
