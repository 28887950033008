import {createEntityGetter} from 'apiClient/entityResponse';
import {compensationReportsApi} from 'domain/compensations/report/api';
import {compensationRequestsApi} from 'domain/compensations/request/api';
import {
  approveRequestAsAdminFx,
  approveRequestAsManagerFx,
  cancelRequestFx,
  closeRequestFx,
  createRequestFx,
  getAllRequestsByTypeFx,
  getAvailableRequestsByTypeFx,
  getRequestByIdFx,
  rejectRequestFx,
  submitReportFx,
  updateCommentFromAdminFx,
  updateReportFx,
} from '.';

approveRequestAsAdminFx.use(compensationRequestsApi.approveAsAdmin);
approveRequestAsManagerFx.use(compensationRequestsApi.approveAsManager);
cancelRequestFx.use(compensationRequestsApi.cancel);
closeRequestFx.use(compensationRequestsApi.close);
createRequestFx.use(compensationRequestsApi.create);
getAllRequestsByTypeFx.use(compensationRequestsApi.getAll);
getAvailableRequestsByTypeFx.use(compensationRequestsApi.getAvailable);
getRequestByIdFx.use(createEntityGetter(compensationRequestsApi.getById));
rejectRequestFx.use(compensationRequestsApi.reject);
submitReportFx.use(compensationReportsApi.submit);
updateCommentFromAdminFx.use(compensationRequestsApi.updateCommentFromAdmin);
updateReportFx.use(compensationReportsApi.update);
