import {DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {ListedProperty} from 'components/ui/ListedProperty';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {useOfferUpdatePossibility} from 'domain/offer/hooks/useOfferUpdatePossibility';
import {Offer} from 'domain/offer/model';
import {formatOfferPriority} from 'domain/offer/model/priority/formatter';
import {formatTemplateCountry} from 'domain/offer/model/templateCountry/formatter';
import {OfferStatusBadge} from 'domain/offer/widgets/StatusBadge';
import {UserLink} from 'domain/user/widgets/Link';
import {formatPhone} from 'models/system/phone';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {offerUrls} from 'routes/offer/urls';
import {labels, messages} from './messages';
import styles from './styles.css';

type Props = {
  offer: Offer;
};

export function OfferGeneralInfoPanel({offer}: Props) {
  const intl = useIntl();
  const {general, status, createdTime, updatedTime} = offer;
  const {phone, referrers, recruiter, source, priority, ticketLink, linkToInterviewFeedback} = general;
  const {canUpdateGeneralInfo} = useOfferUpdatePossibility(offer);
  const declineReasons = useMemo(
    () => offer.declineReasons?.map((reason, i) => (i === 0 ? reason : reason.toLowerCase())) ?? [],
    [offer.declineReasons],
  );

  return (
    <Panel
      title={intl.formatMessage(messages.title)}
      stretchHeight
      withPadding
      toolbarTop={
        canUpdateGeneralInfo && (
          <RouterLinkButton size='m' kind='primary' intent='neutral' to={offerUrls.editGeneralInfo({id: offer.id})}>
            {intl.formatMessage(messages.buttonEdit)}
          </RouterLinkButton>
        )
      }
    >
      <div className={styles.properties}>
        <ListedProperty label={intl.formatMessage(labels.status)}>
          <OfferStatusBadge status={status} />
        </ListedProperty>
        {declineReasons.length > 0 && (
          <ListedProperty label={intl.formatMessage(labels.declineReasons)}>
            {intl.formatList(declineReasons, {type: 'conjunction', style: 'narrow'})}
          </ListedProperty>
        )}
        <ListedProperty label={intl.formatMessage(labels.phone)}>{formatPhone(phone)}</ListedProperty>
        <ListedProperty label={intl.formatMessage(labels.referrers, {count: referrers.length})}>
          {referrers.length > 0 ? (
            intl.formatList(
              referrers.map((referrer) => <UserLink key={referrer.id} user={referrer} withAvatar={false} colored />),
              {type: 'conjunction', style: 'narrow'},
            )
          ) : (
            <DataTable.CellEmpty />
          )}
        </ListedProperty>
        {recruiter && (
          <ListedProperty label={intl.formatMessage(labels.recruiter)}>
            <UserLink key={recruiter.id} user={recruiter} withAvatar={false} colored />
          </ListedProperty>
        )}
        {ticketLink && (
          <ListedProperty label={intl.formatMessage(labels.ticketLink)} multiline={false}>
            <StyledExternalLink colored href={ticketLink}>
              {ticketLink}
            </StyledExternalLink>
          </ListedProperty>
        )}
        <ListedProperty label={intl.formatMessage(labels.source)}>{source}</ListedProperty>
        <ListedProperty label={intl.formatMessage(labels.priority)}>
          {formatOfferPriority(priority, intl)}
        </ListedProperty>
        <ListedProperty label={intl.formatMessage(labels.sentDate)}>
          <HumanDate value={createdTime} utc format='long' />
        </ListedProperty>
        <ListedProperty label={intl.formatMessage(labels.lastUpdateDate)}>
          <HumanDate value={updatedTime} utc format='long' />
        </ListedProperty>
        {general.templateCountry && (
          <ListedProperty label={intl.formatMessage(labels.formTemplate)}>
            {formatTemplateCountry(general.templateCountry, intl)}
          </ListedProperty>
        )}
        {linkToInterviewFeedback && (
          <ListedProperty label={intl.formatMessage(labels.linkToInterviewFeedback)} multiline={false}>
            <StyledExternalLink colored href={linkToInterviewFeedback}>
              {linkToInterviewFeedback}
            </StyledExternalLink>
          </ListedProperty>
        )}
      </div>
    </Panel>
  );
}
