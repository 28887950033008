import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  issuer: {
    defaultMessage: 'Issuer',
    description: 'Stock option agreement creation dialog: field label',
  },
  issueDate: {
    defaultMessage: 'Issue date',
    description: 'Stock option agreement creation dialog: field label',
  },
  amount: {
    defaultMessage: 'Amount',
    description: 'Stock option agreement creation dialog: field label',
  },
  type: {
    defaultMessage: 'Type',
    description: 'Stock option agreement creation dialog: field label',
  },
});
