import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Permission} from 'domain/permission/model';
import {TeamMembers, teamMembersSchema} from 'domain/team/model/members';
import {MembershipRevokeConfig} from 'domain/team/model/members/membershipConfig';

const revokeMembershipGeneric =
  (endpoint: string) =>
  async ({id}: MembershipRevokeConfig): Promise<TeamMembers> => {
    const {data} = await intranetApi.post(endpoint, undefined, {
      params: {id},
    });

    return teamMembersSchema.runWithException(data);
  };

const revokeMembershipDefault = revokeMembershipGeneric('/v1/teams/memberships/revoke');
const revokeMembershipAsAdmin = revokeMembershipGeneric('/v1/teams/memberships/revokeAsAdmin');

export const revokeMembership = createPermissionDependentHandler(
  [{permission: Permission.MEMBERSHIP_ADMIN_WRITE, handler: revokeMembershipAsAdmin}],
  revokeMembershipDefault,
);
