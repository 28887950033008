import {defineMessages} from 'react-intl';
import {OwnersFilledValidatorError} from './validate';

export const labels = defineMessages({
  name: {
    defaultMessage: 'Friendly name',
    description: 'Resource form: field label',
  },
  value: {
    defaultMessage: 'Value',
    description: 'Resource form: field label',
  },
  description: {
    defaultMessage: 'Description',
    description: 'Resource form: field label',
  },
  owners: {
    defaultMessage: 'Owners (people)',
    description: 'Resource form: field label',
  },
  owningMemberships: {
    defaultMessage: 'Owners (roles)',
    description: 'Resource form: field label',
  },
  service: {
    defaultMessage: 'Service',
    description: 'Resource form: field label',
  },
});

export const hints = defineMessages({
  owners: {
    defaultMessage: 'People responsible for approving access requests and updating the resource info',
    description: 'Resource form: field hint',
  },
  owningMemberships: {
    defaultMessage: 'Team members with these roles will have the rights of resource owners',
    description: 'Resource form: field hint',
  },
  description: {
    defaultMessage: 'Fill out in English. <link>Markdown cheatsheet</link>',
    description: 'Resource form: field hint',
  },
  useLatin: {
    defaultMessage: 'Fill out in English',
    description: 'Resource form: field hint',
  },
});

export const errorMessages = defineMessages({
  [OwnersFilledValidatorError.EMPTY_OWNERS]: {
    defaultMessage: 'At least one owner (joomer or role) must be specified',
    description: 'Resource form: errorMessage',
  },
});
