import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {Panel} from '@joomcode/joom-ui/Panel';
import {dropNonFloatChars, parse} from 'components/widgets/fields/FieldFloat';
import {setStockOptionValue} from 'domain/stockOption/buyout/stores/main';
import {$stockOptionValue} from 'domain/stockOption/buyout/stores/main/state';
import {StockOptionCalculatorHint} from 'domain/stockOption/holder/widgets/CalculatorHint';
import {useStore} from 'effector-react';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export function StockOptionCalculatorPanel() {
  const intl = useIntl();
  const {stockOptionValue} = useStore($stockOptionValue);
  const [value, setValue] = useState(String(stockOptionValue));

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(dropNonFloatChars(e.target.value));
      setStockOptionValue(parse(e.target.value) ?? 0);
    },
    [setValue],
  );

  return (
    <Panel
      withPadding
      withMarginBottom={false}
      stretchHeight
      title={
        <div>
          {intl.formatMessage(messages.title)}
          <StockOptionCalculatorHint />
        </div>
      }
    >
      <FormControl
        label={intl.formatMessage(messages.label)}
        hint={intl.formatMessage(messages.disclaimer)}
        reserveSpaceForError={false}
      >
        {(formControlProps) => (
          <Input
            {...formControlProps}
            value={value}
            onChange={handleChange}
            type='number'
            min='0'
            step='0.1'
            className={styles.input}
          />
        )}
      </FormControl>
    </Panel>
  );
}
