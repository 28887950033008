import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {JobInfoRecord, JobInfoRecordId, jobInfoRecordSchema} from 'domain/jobInfoRecord/model';
import {JobInfoRecordDiff} from 'domain/jobInfoRecord/model/diff';
import {UserId} from 'domain/user/model';

export type UpdateParams = {
  userId: UserId;
  jobInfoRecordId: JobInfoRecordId;
  diff: JobInfoRecordDiff;
};

export const update = async ({userId, jobInfoRecordId, diff}: UpdateParams): Promise<JobInfoRecord[]> => {
  const {data} = await intranetApi.post('v1/users/jobInfo/updateRecord', diff, {
    params: {userId, jobInfoRecordId},
  });

  return array(jobInfoRecordSchema).runWithException(data);
};
