import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonDeclined: {
    defaultMessage: 'Mark as declined',
    description: '[button] Set offer status as Declined',
  },
  buttonAccepted: {
    defaultMessage: 'Mark as accepted',
    description: '[button] Set offer status as Accepted',
  },
});
