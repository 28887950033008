import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  name: {
    defaultMessage: 'Name',
    description: 'Newcomer document data label',
  },
  placeOfCurrentResidence: {
    defaultMessage: 'Place of current residence',
    description: 'Newcomer document data label',
  },
  number: {
    defaultMessage: 'Number',
    description: 'Newcomer document data label',
  },
  country: {
    defaultMessage: 'Country of issue',
    description: 'Newcomer document data label',
  },
  firstName: {
    defaultMessage: 'First name',
    description: 'Newcomer document data label',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: 'Newcomer document data label',
  },
  middleName: {
    defaultMessage: 'Middle name',
    description: 'Newcomer document data label',
  },
  expirationDate: {
    defaultMessage: 'Expiration date',
    description: 'Newcomer document data label',
  },
  address: {
    defaultMessage: 'Address',
    description: 'Newcomer document data label',
  },
  bankDetails: {
    defaultMessage: 'Bank details',
    description: 'Newcomer document data label',
  },
  taxDetails: {
    defaultMessage: 'Tax details',
    description: 'Newcomer document data label',
  },
  children: {
    defaultMessage: 'Children and dependents',
    description: 'Newcomer document data label',
  },
  maritalStatus: {
    defaultMessage: 'Marital status',
    description: 'Newcomer document data label',
  },
  educationLevel: {
    defaultMessage: 'Education level',
    description: 'Newcomer document data label',
  },
  fiscalSituation: {
    defaultMessage: 'Fiscal situation',
    description: 'Newcomer document data label',
  },
});
