import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  empty: {
    defaultMessage: 'CR value is empty',
    description: 'CR badge text',
  },
  value: {
    defaultMessage: '{withLabel, select, true {CR } other {}}{value, number}',
    description: 'CR badge text',
  },
});
