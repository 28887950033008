import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {LegalEntity} from 'domain/legalEntity/model';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import {getMainLegalEntityFx} from 'domain/user/stores/mainLegalEntity';
import {$userMainLegalEntities} from 'domain/user/stores/mainLegalEntity/state';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: LegalEntity | undefined;
  dataState: DataState;
  hasReadAccess: boolean;
};

export const useUserMainLegalEntity = (userId: UserId | undefined): ReturnShape => {
  const acl = useAcl();
  const canSeeMainLegalEntity = acl.hasPermission(Permission.USER_MAIN_LEGAL_ENTITY_READ);

  const data = useStoreMap({
    store: $userMainLegalEntities,
    keys: [userId],
    fn: ({byUserId}, [id]) => (id ? byUserId[id] : undefined),
  });
  const dataState = useStoreMap({
    store: $userMainLegalEntities,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => (id && dataStateByUserId[id]) || DataState.IDLE,
  });

  useEffect(() => {
    if (userId && canSeeMainLegalEntity && !isLoadingOrLoaded(dataState)) {
      getMainLegalEntityFx(userId).catch(toaster.interceptThenThrowError);
    }
  }, [userId, canSeeMainLegalEntity]);

  return {
    data,
    dataState,
    hasReadAccess: canSeeMainLegalEntity,
  };
};
