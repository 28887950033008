export enum ColumnName {
  TYPE = 'type',
  CREATED_AT = 'createdAt',
  DATES = 'dates',
  DAYS_TAKEN = 'daysTaken',
  STATUS = 'status',
  LEGAL_ENTITY = 'legalEntity',
  USER = 'user',
  ACTIONS = 'actions',
  RECORDED = 'recorded',
}
