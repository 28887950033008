import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plusSmall.svg';
import {PageLock, PageLocker} from '@joomcode/joom-ui/PageLock';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {OfferToggableSection, OfferToggableSections} from 'domain/offer/hooks/useOfferToggableSections';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {OfferEmploymentInfo} from 'domain/offer/model/employmentInfo';
import {OfferStockOptions} from 'domain/offer/model/stockOption';
import {OfferCandidateInfoFields} from 'domain/offer/widgets/CandidateInfoFields';
import {OfferCommentFields} from 'domain/offer/widgets/CompensationCommentFields';
import {FurtherSalaryFields} from 'domain/offer/widgets/FurtherSalaryFields';
import {OfferJobInfoFields} from 'domain/offer/widgets/JobInfoFields';
import {OfferInfoFields} from 'domain/offer/widgets/OfferInfoFields';
import {OfferOneTimeBonusesFields} from 'domain/offer/widgets/OneTimeBonuses';
import {OfferRegularBonusFields} from 'domain/offer/widgets/RegularBonusFields';
import {OfferSalaryFields} from 'domain/offer/widgets/SalaryFields';
import {OfferStockOptionsFields} from 'domain/offer/widgets/StockOptionsFields';
import {StockOptionsProposalListener} from 'domain/offer/widgets/StockOptionsProposalListener';
import React, {useMemo} from 'react';
import {Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import uuid from 'uuid/v4';
import {messages} from './messages';
import styles from './styles.css';

export type BasicFormState = {
  employmentInfo: OfferEmploymentInfo;
  compensation: OfferCompensation;
  stockOptions?: OfferStockOptions;
};

type RenderProps<State> = {
  values: State;
  invalid: boolean;
  submitting: boolean;
  formId: string;
};

type Props<State> = {
  employmentInfo?: OfferEmploymentInfo;
  compensation?: OfferCompensation;
  stockOptions?: OfferStockOptions;
  extended?: boolean;
  toggableSections: OfferToggableSections;
  pageLocker: PageLocker;
  onStockOptionsProposalLoad?: () => void;
  onSubmit: FormProps<State>['onSubmit'];
  children: (props: RenderProps<State>) => React.ReactNode;
};

export function OfferForm<State extends BasicFormState>({
  employmentInfo,
  compensation,
  stockOptions,
  extended,
  toggableSections,
  pageLocker,
  onStockOptionsProposalLoad,
  onSubmit,
  children,
}: Props<State>) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const {units, dataState} = useCompanyUnits();

  return (
    <StateHandler data={units} state={dataState}>
      {(companyUnits) => (
        <Form<State> onSubmit={onSubmit} subscription={{values: true, invalid: true, submitting: true, dirty: true}}>
          {({handleSubmit, values, invalid, submitting, dirty, form}) => (
            <form className={styles.form} onSubmit={handleSubmit} id={formId}>
              <PageLock when={dirty} pageLocker={pageLocker} />
              <Panel>
                <div className={styles.fieldsWrap}>
                  {extended && <OfferCandidateInfoFields />}
                  <OfferJobInfoFields
                    companyUnits={companyUnits}
                    compensation={compensation}
                    employmentInfo={employmentInfo}
                  />
                  <OfferSalaryFields compensation={compensation} employmentInfo={employmentInfo} />
                  <StockOptionsProposalListener onSuccessfulLoad={onStockOptionsProposalLoad} />
                  <OfferRegularBonusFields compensation={compensation} />
                  <OfferOneTimeBonusesFields compensation={compensation} />
                  <OfferCommentFields compensation={compensation} />
                  {extended && <OfferInfoFields companyUnits={companyUnits} />}
                  {toggableSections.sections.map((section) => {
                    switch (section) {
                      case OfferToggableSection.STOCK_OPTIONS:
                        return (
                          <OfferStockOptionsFields
                            key={section}
                            stockOptions={stockOptions}
                            onRemove={() => {
                              toggableSections.toggleStockOptions();
                              form.change('stockOptions', undefined);
                            }}
                          />
                        );
                      case OfferToggableSection.FURTHER_SALARY:
                        return (
                          <FurtherSalaryFields
                            key={section}
                            compensation={compensation}
                            employmentInfo={employmentInfo}
                            onRemove={() => {
                              toggableSections.toggleFurtherSalary();
                              form.change('compensation', {...values.compensation, furtherSalary: undefined});
                            }}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
                </div>
                <Panel.Content withPadding>
                  <ButtonGroup spaced size='m'>
                    {!toggableSections.hasStockOptions && (
                      <Button
                        kind='secondary'
                        intent='neutral'
                        iconLeft={<PlusIcon />}
                        onClick={toggableSections.toggleStockOptions}
                      >
                        {intl.formatMessage(messages.buttonAddStockOptions)}
                      </Button>
                    )}
                    {!toggableSections.hasFurtherSalary && (
                      <Button
                        kind='secondary'
                        intent='neutral'
                        iconLeft={<PlusIcon />}
                        onClick={toggableSections.toggleFurtherSalary}
                      >
                        {intl.formatMessage(messages.buttonAddFurtherSalary)}
                      </Button>
                    )}
                  </ButtonGroup>
                </Panel.Content>
              </Panel>
              <SubmitFormPanel>{children({values, invalid, submitting, formId})}</SubmitFormPanel>
            </form>
          )}
        </Form>
      )}
    </StateHandler>
  );
}
