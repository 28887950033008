import {defineMessages} from 'react-intl';
import {OfferEmploymentInfo} from '.';

export const employmentInfoFieldLabels = defineMessages<keyof OfferEmploymentInfo>({
  employmentCountry: {
    defaultMessage: 'Salary range country',
    description: 'Offer field label',
  },
  relocationCountry: {
    defaultMessage: 'Salary range after relocation',
    description: 'Offer field label',
  },
  jobTitle: {
    defaultMessage: 'Job title',
    description: 'Offer field label',
  },
  manager: {
    defaultMessage: 'Manager',
    description: 'Offer field label',
  },
  division: {
    defaultMessage: 'Business unit',
    description: 'Offer field label',
  },
  department: {
    defaultMessage: 'Department',
    description: 'Offer field label',
  },
});
