import {DAY} from '@joomcode/deprecated-utils/time';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {TimeOffType} from 'domain/timeOff/type/model';
import faker from 'faker';
import {TimeOff} from '.';

const types = getEnumValues(TimeOffType);

export function mockTimeOff(data: Partial<TimeOff> = {}): TimeOff {
  const now = Date.now();
  const durationDays = faker.random.number({min: 1, max: 21});
  const startsInDays = faker.random.number({min: 1 - durationDays, max: 14});
  return {
    start: new Date(now + startsInDays * DAY),
    end: new Date(now + (startsInDays + durationDays - 1) * DAY),
    type: faker.random.arrayElement(types),
    ...data,
  };
}
