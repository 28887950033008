import {NOTION_GUIDE} from 'domain/compensationReviewRequest/model/notionGuide';
import {CompensationReviewRequestPage} from 'pages/CompensationReviewRequest';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/managerConsole/titles';
import {managerConsoleUrls} from 'routes/managerConsole/urls';

export function ManagerConsoleCompensationReviewRequestPage() {
  const intl = useIntl();

  return (
    <CompensationReviewRequestPage
      breadcrumbs={[
        {title: intl.formatMessage(pageTitles.managerConsole), href: managerConsoleUrls.compensationReviewRequests()},
      ]}
      title={intl.formatMessage(pageTitles.compensationReviewRequest)}
      notionGuide={NOTION_GUIDE}
    />
  );
}
