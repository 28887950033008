import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  seatForUserNotFound: {
    defaultMessage: '{userName} does not have a seat',
    description: 'Seat for user not found message',
  },
  nothingFound: {
    defaultMessage: 'Nothing found',
    description: 'Nothing found in office search',
  },
  userSuggestItem: {
    defaultMessage: '{fullName} ({login})',
    description: 'User search suggest item',
  },
});
