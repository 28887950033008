import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {SkeletonShape} from '@joomcode/joom-ui/Skeleton';
import {compensationReviewRequestApi} from 'domain/compensationReviewRequest/api';
import {GetRecommendedJobTitleParams} from 'domain/compensationReviewRequest/api/getRecommendedJobTitle';
import {LevelCode} from 'domain/levelCode/model';
import {SecurePermission} from 'domain/permission/model/secure';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  jobTitle?: string;
  levelCode?: LevelCode;
  userFunction?: string;
  onClick: (recommendedJobTitle: string) => void;
};

export function JobTitleWithRecommendation({jobTitle, levelCode, userFunction, onClick}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const [recommendedJobTitle, setRecommendedJobTitle] = useState<string | undefined>(undefined);
  const label = intl.formatMessage(messages.label);
  const getRecommendedJobTitle = useAsyncTask<[GetRecommendedJobTitleParams], void>(
    (params) => compensationReviewRequestApi.getRecommendedJobTitle(params).then(setRecommendedJobTitle),
    [setRecommendedJobTitle],
  );

  useEffect(() => {
    if (acl.hasSecurePermission(SecurePermission.RECOMMENDED_JOB_TITLE_READ) && levelCode && userFunction) {
      getRecommendedJobTitle.perform({levelCode, userFunction});
    }
  }, [acl, getRecommendedJobTitle.perform, levelCode, userFunction]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (recommendedJobTitle) {
        onClick(recommendedJobTitle);
      }
    },
    [recommendedJobTitle, onClick],
  );

  return (
    <div className={styles.root}>
      {label}
      {getRecommendedJobTitle.isPerforming ? (
        <div className={styles.skeleton}>
          <SkeletonShape kind='pill' width='10rem' height='1rem' />
        </div>
      ) : (
        recommendedJobTitle &&
        recommendedJobTitle !== jobTitle && (
          <div className={styles.recommendation}>
            <div className={styles.prefix}>{intl.formatMessage(messages.recommendation)}</div>
            <button type='button' className={styles.jobTitle} onClick={handleClick}>
              {recommendedJobTitle}
            </button>
          </div>
        )
      )}
    </div>
  );
}
