import {UserId} from 'domain/user/model';
import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const usersUrlPatterns = {
  user: '/joomers/:login',
  users: '/joomers',
  userById: '/joomers/id/:id',
  userEdit: '/joomers/:login/edit',
  userEditLegalInfo: '/joomers/:login/edit/legalInfo',
  timeOffBalance: '/joomers/:login/time-off-balance',
};

export const usersUrls = {
  user: (pathParams?: {login: string}) => generatePath(usersUrlPatterns.user, pathParams),
  users: (searchParams?: {login?: string; search?: string}) =>
    addSearchParams(generatePath(usersUrlPatterns.users), searchParams),
  userById: (pathParams: {id: UserId}) => generatePath(usersUrlPatterns.userById, pathParams),
  userEdit: (pathParams: {login: string}, searchParams?: {mode?: string}) =>
    addSearchParams(generatePath(usersUrlPatterns.userEdit, pathParams), searchParams),
  userEditLegalInfo: (pathParams: {login: string}) => generatePath(usersUrlPatterns.userEditLegalInfo, pathParams),
  timeOffBalance: (pathParams: {login: string}) => generatePath(usersUrlPatterns.timeOffBalance, pathParams),
};
