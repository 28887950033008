import {RoomSvg} from 'domain/officeMap/room/widgets/RoomSvg';
import {SeatSvg} from 'domain/officeMap/seat/widgets/SeatSvg';
import React from 'react';
import styles from './styles.css';
import svgStyles from './svgStyles.css';

type Props = {
  children: React.ReactNode[];
  showExits: boolean;
  shadowElementId: string;
  showWc: boolean;
};

export function OfficeMapSvgChina({children, shadowElementId, showExits, showWc}: Props) {
  return (
    <svg viewBox='0 0 785.5 576.6'>
      <defs>
        <filter id={shadowElementId}>
          <feDropShadow dx='-1' dy='1' stdDeviation='3' floodOpacity='0.25' />
        </filter>
      </defs>
      <g className={styles.sample}>
        <SeatSvg.Sample />
        <RoomSvg.Sample />
      </g>

      <g id='walls' style={{filter: `url(#${shadowElementId})`}}>
        <g>
          <path
            className={svgStyles.st0}
            d='M602.2,26.3c3.1,0,5.6,2.5,5.6,5.6v249.2l0.1,241.7c0,3.1-2.5,5.5-5.6,5.5H137.8c-3.1,0-68.8,0-68.8,0
			c-3.1,0-5.6-2.7-5.6-5.8v-235c0-3.1,2.5-8.6,5.6-8.6h291.9v-247c0-3.1,2.5-5.6,5.6-5.6H602.2z'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M368.7,239.7c0,1.6,0,4.3,0,6v41.2c0,1.6-1.3,3-3,3l-289.6,0c-1.7,0-3,1.4-3,3l0,223.4c0,1.6,1.3,3,3,3h255.6
			c1.6,0,4.4,0,6,0l2,0c1.6,0,4.4,0,6,0h251c1.7,0,3-1.4,3-3L599,58c0-1.6,0-4.3,0.1-6l0-0.4c0-1.6,0.1-4.3,0.2-6l0.3-6.9
			c0.1-1.6-1.2-3-2.9-3l-225,0c-1.6,0-3,1.3-3,3v170.8c0,1.7,0,4.4,0,6V239.7z'
          />
        </g>
        <circle className={svgStyles.st1} cx='439.8' cy='501' r='9.1' />
        <circle className={svgStyles.st1} cx='188.8' cy='501' r='9.1' />
        <circle className={svgStyles.st1} cx='188.8' cy='369' r='9.1' />
        <circle className={svgStyles.st1} cx='307.8' cy='369' r='9.1' />
        <circle className={svgStyles.st1} cx='580.8' cy='205' r='9.1' />
        <circle className={svgStyles.st1} cx='580.8' cy='48.8' r='9.1' />
        <g>
          <line className={svgStyles.st1} x1='514.4' y1='85.4' x2='457.1' y2='85.4' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='458.5' y1='85.4' x2='458.5' y2='36' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='542.6' y1='81.3' x2='531.4' y2='85.4' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M520.3,81.9l8.3,2.5c1.6,0.5,2.9-0.5,2.9-2.1V35.9' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='599' y1='85.4' x2='549.3' y2='85.4' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M368.8,135.8h64.1c1.6,0,3-1.4,3-3V98.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='457' y1='85.4' x2='445.8' y2='81.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M74.1,397.2h49.3c1.5,0,2.7,1.3,2.7,3v27.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='125' y1='483.6' x2='74.1' y2='483.6' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M125.5,464.7v-17.4c0-1.7-0.5-4.2-1.1-5.8l-5.3-13.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='123.9' y1='444.3' x2='74.1' y2='444.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M126.2,519.3v-31.4c0-1.7-0.5-4.2-1.1-5.8l-5.3-13.1' />
        </g>
        <g className={svgStyles.st2}>
          <line className={svgStyles.st3} x1='430.7' y1='397.8' x2='185.7' y2='397.8' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='259.2' y1='356.9' x2='168.6' y2='356.9' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M334.4,315.4v38.5c0,1.6-1.4,3-3,3h-72.2' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M377.3,366.5h-6.2c-1.6,0-3-1.4-3-3v-48.8' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='413.3' y1='315.4' x2='413.3' y2='366.1' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M368.7,290.1h41.5c1.6,0,3,1.4,3,3v9.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M368.6,314.7h41.6c1.6,0,2.5-1.3,1.9-2.8l-3.3-8.4' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='368.1' y1='290.8' x2='368.1' y2='314.3' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='413.3' y1='366.8' x2='393.4' y2='366.8' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='388.7' y1='362.3' x2='377.6' y2='366.4' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='492.1' y1='347.9' x2='492.1' y2='179.4' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M368.8,85.4h64.1c1.6,0,2.3,1.2,1.5,2.6l-5.1,8.6' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M368.6,179.9h64.3c1.7,0,2.3-1.2,1.5-2.6l-5.1-8.7' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='435.9' y1='166.6' x2='435.9' y2='136.8' />
        </g>
        {showWc && (
          <>
            <g>
              <g>
                <polygon
                  className={svgStyles.st4}
                  points='360.3,184 353.6,149.5 346.8,184 350.8,184 350.8,189.3 350.8,221.2 356.4,221.2 356.4,189.3
				356.4,184 			'
                />
              </g>
            </g>
            <g>
              <g>
                <polygon
                  className={svgStyles.st4}
                  points='322.8,268.6 288.3,275.4 322.8,282.1 322.8,278.2 328.2,278.2 360,278.2 360,272.6 328.2,272.6
				322.8,272.6 			'
                />
              </g>
            </g>
          </>
        )}
        <g>
          <path className={svgStyles.st1} d='M275.4,314.9H258c-1.7,0-4.2,0.5-5.8,1.1l-13.1,5.3' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='255' y1='316.4' x2='255' y2='356.9' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M232,315.1h-17.4c-1.7,0-4.2,0.5-5.8,1.1l-13.1,5.3' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='211.6' y1='316.6' x2='211.6' y2='357.1' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M316.8,315.1h-17.4c-1.7,0-4.2,0.5-5.8,1.1l-13.1,5.3' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='296.4' y1='316.6' x2='296.4' y2='357.1' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M168.6,356.9l0-39.6c0-1.2,1-2.2,2.4-2.2h21.8' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M492.1,519.4l0-119.2c0-1.2,1-2.2,2.4-2.2h21.8' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M599.2,397.3h-50.4c-1.7,0-4.2,0.5-5.8,1.1l-13.1,5.3' />
        </g>
      </g>

      {showWc && (
        <>
          <g>
            <g>
              <polygon
                className={svgStyles.st2}
                points='360.3,184 353.6,149.5 346.8,184 350.8,184 350.8,189.3 350.8,221.2 356.4,221.2 356.4,189.3
				356.4,184 			'
              />
            </g>
          </g>
          <g>
            <g>
              <polygon
                className={svgStyles.st2}
                points='322.8,268.6 288.3,275.4 322.8,282.1 322.8,278.2 328.2,278.2 360,278.2 360,272.6 328.2,272.6322.8,272.6'
              />
            </g>
          </g>
        </>
      )}

      {showExits && (
        <g id='exits'>
          <g id='exit1_00000008134367457327846670000008023812672335354254_'>
            <g>
              <rect x='353.6' y='230.8' className={svgStyles.st5} width='43.8' height='21.9' />
              <path
                className={svgStyles.st6}
                d='M397.4,251.9c0,0.4-0.4,0.8-0.8,0.8h-42.2c-0.4,0-0.8-0.4-0.8-0.8v-20.3c0-0.4,0.4-0.8,0.8-0.8h42.2
				c0.4,0,0.8,0.4,0.8,0.8V251.9z'
              />
            </g>
            <g className={svgStyles.st7}>
              <path className={svgStyles.st8} d='M357.7,247.3v-12.2h8.8v1.4h-7.2v3.7h6.7v1.4h-6.7v4.2h7.5v1.4H357.7z' />
              <path
                className={svgStyles.st8}
                d='M367.8,247.3l4.7-6.4l-4.2-5.8h1.9l2.2,3.1c0.5,0.6,0.8,1.1,1,1.5c0.3-0.4,0.6-0.9,1-1.4l2.5-3.2h1.8
				l-4.3,5.7l4.6,6.4h-2l-3.1-4.4c-0.2-0.2-0.3-0.5-0.5-0.8c-0.3,0.4-0.5,0.7-0.6,0.9l-3.1,4.3H367.8z'
              />
              <path className={svgStyles.st8} d='M380.7,247.3v-12.2h1.6v12.2H380.7z' />
              <path className={svgStyles.st8} d='M388.2,247.3v-10.8h-4v-1.4h9.7v1.4h-4v10.8H388.2z' />
            </g>
          </g>
        </g>
      )}
      <g id='seats'>{children}</g>
    </svg>
  );
}
