import {intranetApi} from 'apiClient';
import {StockOptionAgreementId} from 'domain/stockOption/agreement/model';
import {
  StockOptionAgreementWithSummary,
  stockOptionAgreementWithSummarySchema,
} from 'domain/stockOption/agreement/model/withSummary';
import {StockOptionAgreementFreezeId} from 'domain/stockOption/agreementFreeze/model';
import {UserId} from 'domain/user/model';

export type StockOptionAgreementFreezeUpdateConfig = {
  userId: UserId;
  id: StockOptionAgreementFreezeId;
  agreementId: StockOptionAgreementId;
  freezeDate: string;
};

export const update = async ({
  userId,
  id,
  agreementId,
  freezeDate,
}: StockOptionAgreementFreezeUpdateConfig): Promise<StockOptionAgreementWithSummary> => {
  const {data} = await intranetApi.post(
    '/v1/secure/stockOptions/agreements/freezes/update',
    {freezeDate},
    {params: {userId, id, agreementId}},
  );

  return stockOptionAgreementWithSummarySchema.runWithException(data);
};
