import {AddParams} from 'domain/jobInfoRecord/api/add';
import {DeleteParams} from 'domain/jobInfoRecord/api/delete';
import {UpdateParams} from 'domain/jobInfoRecord/api/update';
import {JobInfoRecord} from 'domain/jobInfoRecord/model';
import {UserId} from 'domain/user/model';
import {createEffect} from 'effector';

export const addRecordFx = createEffect<AddParams, JobInfoRecord[]>('add job info record to user');
export const deleteRecordFx = createEffect<DeleteParams, void>('delete user’s job info record');
export const getRecordsByUserIdFx = createEffect<UserId, JobInfoRecord[]>('get user’s job info records as admin');
export const getMyRecordsFx = createEffect<UserId, JobInfoRecord[]>('get own job info records');
export const updateRecordFx = createEffect<UpdateParams, JobInfoRecord[]>('update user’s job info record by ID');
