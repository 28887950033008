import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {AddRecordButton} from 'domain/jobContract/widgets/AddRecordButton';
import {JobContractPanelTab} from 'domain/jobContract/widgets/Panel';
import {JobContractSalaryTab} from 'domain/jobContract/widgets/SalaryTab';
import {LegalEntityId} from 'domain/legalEntity/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {useUserSalaryRecords} from 'domain/salaryRecord/hooks/useUserSalaryRecords';
import {ExtendedSalaryRecord, SalaryRecord} from 'domain/salaryRecord/model';
import {SalaryRecordDialog} from 'domain/salaryRecord/widgets/Dialog';
import {UserFull} from 'domain/user/model';
import React, {useMemo, useState} from 'react';
import {useAcl} from 'services/acl';

type Props = {
  user: UserFull;
  legalEntityId: LegalEntityId;
  isSensitiveDataHidden: boolean;
};

export function useSalaryTab({user, legalEntityId, isSensitiveDataHidden}: Props): JobContractPanelTab<SalaryRecord> {
  const acl = useAcl();
  const canEdit =
    acl.hasSecurePermission(SecurePermission.SALARY_RECORD_WRITE) ||
    acl.hasSecurePermission(SecurePermission.SALARY_RECORD_WRITE_EXTENDED);
  const {data, dataState} = useUserSalaryRecords(user, legalEntityId);
  const salaryRecordDialog = usePopupState<ExtendedSalaryRecord>();
  const [needToReplaceSalary, setNeedToReplaceSalary] = useState(false);

  const result = useMemo(
    () => ({
      addon: canEdit ? <AddRecordButton onClick={salaryRecordDialog.open} /> : null,
      content: (
        <JobContractSalaryTab
          user={user}
          legalEntityId={legalEntityId}
          canEdit={canEdit}
          isSensitiveDataHidden={isSensitiveDataHidden}
          onDialogOpenWithPayload={salaryRecordDialog.openWithPayload}
          setNeedToReplace={setNeedToReplaceSalary}
          records={data}
          dataState={dataState}
        />
      ),
      dialog: canEdit ? (
        <SalaryRecordDialog
          userId={user.id}
          legalEntityId={legalEntityId}
          recordToUpdate={salaryRecordDialog.payload}
          needToReplace={needToReplaceSalary}
          isOpen={salaryRecordDialog.isOpen}
          onClose={salaryRecordDialog.close}
        />
      ) : null,
      records: data,
      dataState,
    }),
    [
      canEdit,
      user,
      legalEntityId,
      isSensitiveDataHidden,
      salaryRecordDialog,
      needToReplaceSalary,
      setNeedToReplaceSalary,
      data,
      dataState,
    ],
  );

  return result;
}
