import {type Mark, type Locator} from 'create-locator';
import React from 'react';
import {ModalPopupProps} from '../ModalPopup';

import {type DialogBodyLocator} from './Body';
import {type DialogFooterLocator} from './Footer';
import {type DialogHeaderLocator} from './Header';

export type {DialogHeaderLocator, DialogFooterLocator, DialogBodyLocator};

export enum DialogSize {
  L = 'l',
  M = 'm',
  S = 's',
}

export type DialogLockFnPayload = {
  confirmed: boolean;
  forceClose?: boolean;
};

export type DialogLockFn = () => Promise<DialogLockFnPayload>;

export type DialogPrivateContextValue = {
  size: `${DialogSize}`;
  close: () => void;
  forceClose: () => void;
  registerLock: (fn: DialogLockFn) => void;
  unregisterLock: (fn: DialogLockFn) => void;
};

export type DialogPublicContextValue = {
  close: () => void;
  forceClose: () => void;
};

export type DialogContent = React.ReactNode | ((context: DialogPublicContextValue) => React.ReactNode);

export type DialogLocator = Locator<
  {
    wrapper: {type?: string};
  },
  {
    type?: string;
  },
  'sameParameters'
>;

export type DialogProps = {
  autoFocus?: boolean;
  ariaLabel: string;
  afterClose?: () => void;
  children: DialogContent;
  closeOnEscape?: boolean;
  closeOnOutsideClick?: boolean;
  headerImageUrl?: string;
  headerImageAlt?: string;
  icon?: React.ReactNode;
  isOpen: boolean;
  shards?: ModalPopupProps['shards'];
  size?: `${DialogSize}`;
  onClose: () => void;
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
} & Partial<Mark<DialogLocator>>;
