import {isNullish} from '../function/isNullish';
import {createNumbersComparator} from '../sort/numeric';
import {Definition, Measure, MeasureUtils, GetSequenceOptions} from './types';

export const constructMeasureConverter = <Unit>(definitions: Definition<Unit>[]): MeasureUtils<Unit> => {
  const definitionsAsc = [...definitions].sort(createNumbersComparator('ascending', (definition) => definition.rate));
  const definitionsDesc = [...definitionsAsc].reverse();
  const indexByUnit: Map<Unit, number> = definitionsAsc.reduce(
    (map, definition, index) => map.set(definition.unit, index),
    new Map<Unit, number>(),
  );

  const pivotDefinition = definitions.find((definition) => definition.rate === 1);

  if (!pivotDefinition) {
    throw new Error('You must provide pivot definition');
  }
  const pivotUnit = pivotDefinition.unit;

  const createMeasure = (value: number, unit: Unit): Measure<Unit> => ({
    value,
    unit,
  });

  const getCurrentDefinition = (unit: Unit): Definition<Unit> | undefined => {
    const index = indexByUnit.get(unit);
    return !isNullish(index) ? definitions[index] : undefined;
  };

  const getNextDefinition = (unit: Unit): Definition<Unit> | undefined => {
    const index = indexByUnit.get(unit);
    return !isNullish(index) ? definitions[index + 1] : undefined;
  };

  const getPrevDefinition = (unit: Unit): Definition<Unit> | undefined => {
    const index = indexByUnit.get(unit);
    return !isNullish(index) ? definitions[index - 1] : undefined;
  };

  const downward = (measure: Measure<Unit>): Measure<Unit> | undefined => {
    const {unit, value} = measure;

    const current = getCurrentDefinition(unit);
    const next = getNextDefinition(unit);

    if (!next || !current) return undefined;

    const relationRate = next.rate / current.rate;
    const result = value / relationRate;

    return createMeasure(result, next.unit);
  };

  const upward = (measure: Measure<Unit>): Measure<Unit> | undefined => {
    const {unit, value} = measure;

    const current = getCurrentDefinition(unit);
    const prev = getPrevDefinition(unit);

    if (!prev || !current) return undefined;

    const relationRate = prev.rate / current.rate;
    const result = value / relationRate;

    return createMeasure(result, prev.unit);
  };

  const normalize = (measure: Measure<Unit>): Measure<Unit> => {
    let result;
    const {unit, value} = measure;
    const current = getCurrentDefinition(unit);

    if (!current) return measure;

    if (Math.abs(value) < 1) result = upward(measure);

    const next = getNextDefinition(unit);

    if (!next) return measure;

    const relationRate = next.rate / current.rate;

    if ((Math.abs(value) >= 1 && Math.abs(value) < relationRate) || value === 0) return measure;
    if (Math.abs(value) >= relationRate) result = downward(measure);

    return result ? normalize(result) : measure;
  };

  const convert = (measure: Measure<Unit>, target: Unit): Measure<Unit> | undefined => {
    const {unit} = measure;
    const currentIndex = indexByUnit.get(unit);
    const targetIndex = indexByUnit.get(target);

    let result;

    if (isNullish(currentIndex) || isNullish(targetIndex)) return undefined;

    if (currentIndex === targetIndex) result = undefined;

    if (currentIndex > targetIndex) {
      result = upward(measure);
    }
    if (currentIndex < targetIndex) {
      result = downward(measure);
    }

    return result ? convert(result, target) : measure;
  };

  const getSequenceMesureMinUnit = (measure: Measure<Unit>): Measure<Unit> | undefined => {
    if (Number.isInteger(measure.value)) {
      return measure;
    }

    let measureMinUnit: Measure<Unit> | undefined;
    const definitionMinUnit = definitionsAsc.find((definition) => {
      measureMinUnit = convert(measure, definition.unit);
      return measureMinUnit && measureMinUnit.value < Number.MAX_SAFE_INTEGER;
    });

    if (!definitionMinUnit) {
      return undefined;
    }

    return measureMinUnit;
  };

  const getSequence = (measure: Measure<Unit>, options: GetSequenceOptions<Unit> = {}): Measure<Unit>[] => {
    const parts: Measure<Unit>[] = [];
    const measureMinUnit = options.minUnit ? convert(measure, options.minUnit) : getSequenceMesureMinUnit(measure);

    if (!measureMinUnit) {
      return parts;
    }

    if (measureMinUnit.value === 0) {
      return [measureMinUnit];
    }

    const left = {...measureMinUnit};
    const minUnit = measureMinUnit.unit;

    const descMaxIndex = options.maxUnit
      ? definitionsDesc.findIndex((definition) => definition.unit === options.maxUnit)
      : 0;
    const descMinIndex = definitionsDesc.findIndex((definition) => definition.unit === minUnit);

    const croppedDefinitionsDesc = definitionsDesc.slice(
      descMaxIndex !== -1 ? descMaxIndex : 0,
      descMinIndex !== -1 ? descMinIndex + 1 : Infinity,
    );

    croppedDefinitionsDesc.forEach(({unit}) => {
      const current = convert(left, unit);

      if (!current) {
        return;
      }

      const lastElement = unit === minUnit;
      const sign = Math.sign(current.value);
      const currentInteger = {
        value: lastElement ? Math.round(Math.abs(current.value)) : Math.floor(Math.abs(current.value)),
        unit: current.unit,
      };

      if (!currentInteger.value) {
        return;
      }

      parts.push({
        value: currentInteger.value * sign,
        unit: currentInteger.unit,
      });

      const currentMinUnit = convert(currentInteger, minUnit);
      if (currentMinUnit?.value) {
        left.value = (Math.abs(left.value) - Math.abs(currentMinUnit.value)) * sign;
      }
    });

    if (!parts.length) {
      return [{value: 0, unit: minUnit}];
    }

    return parts;
  };

  return {createMeasure, convert, upward, downward, normalize, getSequence, pivotUnit};
};
