import React from 'react';
import {Optional} from 'utility-types';
import {CopyIcon} from '../../Copy/Icon';
import {Column} from '../types';
import {Nullable} from './types';
import {isNotEmpty} from './utils';

type Options<Item, NestedItem> = Optional<Column<Item, NestedItem>, 'render' | 'renderNested'> & {
  getValue: (item: Item) => Nullable<string>;
  getNestedValue?: (nestedItem: NestedItem, item: Item) => Nullable<string>;
};

function render(value: Nullable<string>) {
  return isNotEmpty(value) ? <CopyIcon value={value} /> : null;
}

export function createColumnCopyIcon<Item, NestedItem>({
  getValue,
  getNestedValue,
  ...rest
}: Options<Item, NestedItem>): Column<Item, NestedItem> {
  return {
    align: 'center',
    defaultWidth: 50,
    noWrap: true,
    render: (item) => render(getValue(item)),
    renderNested: getNestedValue && ((nestedItem, item) => render(getNestedValue(nestedItem, item))),
    ...rest,
  };
}
