import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  pageTitle: {
    defaultMessage: 'Joom Identity Management',
    description: '[title] Page title',
  },
  panelTitle: {
    defaultMessage: 'Services',
    description: '[title] Services panel title',
  },
  disclaimer: {
    defaultMessage: 'JDM is a system for granting access to corporate services. <link>Read more</link>.',
    description: 'JDM disclaimer: panel text',
  },
  btnDownloadByUsers: {
    defaultMessage: 'Export by joomers',
    description: '[button] JDM Services page: export by users',
  },
  btnDownloadByServices: {
    defaultMessage: 'Export by services',
    description: '[button] JDM Services page: export by services',
  },
});
