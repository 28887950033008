import {identity} from '@joomcode/deprecated-utils/function';
import {bindValidatorOptions, validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Input} from '@joomcode/joom-ui/Input';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {HintIcon} from 'components/ui/HintIcon';
import {MarkdownCheatsheetLink} from 'components/ui/MarkdownCheatsheetLink';
import {Resource} from 'domain/resource/model';
import {ResourceDiff} from 'domain/resource/model/diff';
import {roleInTeamToDiff} from 'domain/role/utils/roleInTeamToDiff';
import {RolesInTeamsFormControl} from 'domain/role/widgets/RolesInTeamsFormControl';
import {ServiceId} from 'domain/service/model';
import {ServiceSelectFormControl} from 'domain/service/widgets/SelectFormControl';
import {UsersFormControl} from 'domain/user/widgets/FormControlMultiple';
import React, {useMemo} from 'react';
import {Field, FormRenderProps, useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {errorMessages, hints, labels} from './messages';
import styles from './styles.css';
import {validateOwnersFilled} from './validate';

type Props = {
  formId: string;
  onSubmit: FormRenderProps<ResourceDiff>['handleSubmit'];
  resourceToUpdate?: Resource;
  serviceId?: ServiceId;
  submitting: boolean;
};

export const normalizeValues = (diff: ResourceDiff): ResourceDiff => ({
  ...diff,
  // filter out empty rows:
  owningMemberships: diff.owningMemberships?.filter(({roleId, teamId}) => roleId || teamId) ?? [],
});

export function ResourceForm({formId, onSubmit, resourceToUpdate, serviceId, submitting}: Props) {
  const intl = useIntl();
  const initialOwnerIds = useMemo(() => resourceToUpdate?.owners.map(({id}) => id), [resourceToUpdate]);
  const initialOwningMemberships = useMemo(
    () => resourceToUpdate?.owningMemberships.map(roleInTeamToDiff),
    [resourceToUpdate],
  );
  const {getState} = useForm<ResourceDiff>();
  const validateOwners = bindValidatorOptions(validateOwnersFilled, {getFormState: getState});

  return (
    <form onSubmit={onSubmit} id={formId}>
      <Grid>
        <Grid.Item xl={6} xs={12}>
          <div className={styles.paddedFormControl}>
            <ServiceSelectFormControl
              disabled={Boolean(resourceToUpdate)}
              initialValue={resourceToUpdate?.service.id ?? serviceId}
              label={intl.formatMessage(labels.service)}
              name='serviceId'
              submitting={submitting}
            />
          </div>
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <div className={styles.paddedFormControl}>
            <Field<string> name='value' validate={validateFilled} initialValue={resourceToUpdate?.value}>
              {({input, meta}) => {
                const disabled = Boolean(resourceToUpdate) || submitting;
                return (
                  <FormControl
                    disabled={disabled}
                    error={getFieldErrorText(meta, {intl})}
                    label={intl.formatMessage(labels.value)}
                    required
                  >
                    {(formControlProps) => <Input {...formControlProps} {...input} disabled={disabled} />}
                  </FormControl>
                );
              }}
            </Field>
          </div>
        </Grid.Item>
      </Grid>
      <Field<string> name='name' parse={identity} initialValue={resourceToUpdate?.name}>
        {({input, meta}) => (
          <FormControl
            disabled={submitting}
            error={getFieldErrorText(meta, {intl})}
            label={intl.formatMessage(labels.name)}
            hint={intl.formatMessage(hints.useLatin)}
          >
            {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
          </FormControl>
        )}
      </Field>
      <UsersFormControl
        disabled={submitting}
        errorMessages={errorMessages}
        initialValue={initialOwnerIds}
        label={
          <>
            {intl.formatMessage(labels.owners)}
            <HintIcon>{intl.formatMessage(hints.owners)}</HintIcon>
          </>
        }
        name='ownerIds'
        validate={validateOwners}
      />
      <RolesInTeamsFormControl
        disabled={submitting}
        errorMessages={errorMessages}
        initialValue={initialOwningMemberships}
        label={
          <>
            {intl.formatMessage(labels.owningMemberships)}
            <HintIcon>{intl.formatMessage(hints.owningMemberships)}</HintIcon>
          </>
        }
        name='owningMemberships'
        validate={validateOwners}
      />
      <Field<string> name='description' parse={identity} initialValue={resourceToUpdate?.description}>
        {({input}) => (
          <FormControl
            disabled={submitting}
            label={intl.formatMessage(labels.description)}
            hint={intl.formatMessage(hints.description, {
              link: (text) => <MarkdownCheatsheetLink>{text}</MarkdownCheatsheetLink>,
            })}
          >
            {(formControlProps) => <Textarea {...formControlProps} {...input} disabled={submitting} />}
          </FormControl>
        )}
      </Field>
    </form>
  );
}
