import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useUsersEmployments} from 'domain/employment/hooks/useUsersEmployments';
import {Employment} from 'domain/employment/model';
import {EmploymentDialog} from 'domain/employment/widgets/Dialog';
import {EmploymentsTable} from 'domain/employment/widgets/Table';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import {EmploymentTabAddon} from 'domain/user/widgets/EmploymentTabAddon';
import {JobPanelTab} from 'domain/user/widgets/JobPanel';
import React, {useMemo} from 'react';
import {useAcl} from 'services/acl';

export function useEmploymentTab(userId: UserId): JobPanelTab<Employment> {
  const acl = useAcl();
  const employments = useUsersEmployments(userId);
  const employmentDialog = usePopupState<Employment>();
  const canEditEmployments = acl.hasPermission(Permission.USER_EMPLOYMENT_WRITE);

  const result = useMemo(
    () => ({
      addon: <EmploymentTabAddon userId={userId} employments={employments.data} onDialogOpen={employmentDialog.open} />,
      content: (
        <EmploymentsTable
          employments={employments.data}
          dataState={employments.dataState}
          onEditItem={canEditEmployments ? employmentDialog.openWithPayload : undefined}
        />
      ),
      dialog: (
        <EmploymentDialog
          userId={userId}
          employment={employmentDialog.payload}
          isOpen={employmentDialog.isOpen}
          onClose={employmentDialog.close}
        />
      ),
      records: employments.data,
      dataState: employments.dataState,
    }),
    [userId, employments.data, employments.dataState, canEditEmployments, employmentDialog],
  );

  return result;
}
