import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {UpdateSalaryRecordParams} from 'domain/salaryRecord/api/types/update';
import {ExtendedSalaryRecord, extendedSalaryRecordSchema} from 'domain/salaryRecord/model';

export const updateExtended = async ({id, userId, diff}: UpdateSalaryRecordParams): Promise<ExtendedSalaryRecord[]> => {
  const {data} = await intranetApi.post('/v1/secure/users/salaryRecords/updateExtended', diff, {params: {id, userId}});

  return array(extendedSalaryRecordSchema).runWithException(data);
};
