import {CopyInteractiveText} from '@joomcode/joom-ui/Copy/InteractiveText';
import {PageProperty} from '@joomcode/joom-ui/PageProperty';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Markdown} from 'components/ui/Markdown';
import {JdmOwners} from 'components/widgets/JdmOwners';
import {Resource} from 'domain/resource/model';
import {ServiceLink} from 'domain/service/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  resource: Resource;
};

export function ResourceSummaryPanel({resource}: Props) {
  const intl = useIntl();
  return (
    <Panel>
      <Panel.Header withSeparator title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <div className={styles.content}>
          <div className={styles.propertyList}>
            <div className={styles.property}>
              <PageProperty title={intl.formatMessage(messages.serviceLabel)}>
                <ServiceLink service={resource.service} />
              </PageProperty>
            </div>
            <div className={styles.property}>
              <PageProperty title={intl.formatMessage(messages.valueLabel)}>
                <CopyInteractiveText value={resource.value}>{resource.value}</CopyInteractiveText>
              </PageProperty>
            </div>
          </div>
          {resource.description && (
            <div className={styles.section}>
              <PageProperty title={intl.formatMessage(messages.descriptionLabel)}>
                <Markdown source={resource.description} />
              </PageProperty>
            </div>
          )}
          <div className={styles.section}>
            <PageProperty title={intl.formatMessage(messages.ownersLabel)}>
              <JdmOwners owners={resource.owners} owningMemberships={resource.owningMemberships} />
            </PageProperty>
          </div>
        </div>
      </Panel.Content>
    </Panel>
  );
}
