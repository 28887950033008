import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  name: {
    defaultMessage: 'Name',
    description: 'Team form: field label',
  },
  description: {
    defaultMessage: 'About team',
    description: 'Team form: field label',
  },
  slackLink: {
    defaultMessage: 'Slack channel link',
    description: 'Team form: field label',
  },
  notionLink: {
    defaultMessage: 'Team documentation in Notion',
    description: 'Team form: field label',
  },
  heads: {
    defaultMessage: 'Team owners',
    description: 'Team form: field label',
  },
  vTeam: {
    defaultMessage: 'V-team',
    description: 'Team form: field label',
  },
});

export const hints = defineMessages({
  slackLink: {
    defaultMessage: 'Copy link from the context menu in Slack channel: right click on the channel name → Copy Link',
    description: 'Team form: field hint',
  },
  heads: {
    defaultMessage: 'People responsible for granting roles and updating the team info',
    description: 'Team form: field hint',
  },
  useLatin: {
    defaultMessage: 'Fill out in English',
    description: 'Team form: field hint',
  },
  description: {
    defaultMessage: 'Fill out in English. <link>Markdown cheatsheet</link>',
    description: 'Team form: field hint',
  },
});
