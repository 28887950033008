import {
  CommonFieldProps,
  composeValidators,
  getFieldValidator,
  useFieldWithInitialValue,
  validateFilled,
} from '@joomcode/joom-form';
import {DatePicker, DatePickerExternalProps} from '@joomcode/joom-ui/DatePicker';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {dateToString, fixDateType, stringToDate} from 'models/system/formattedDate';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';

type Props = Omit<DatePickerExternalProps, 'onChange' | 'value'> &
  CommonFieldProps<string> & {
    emptyValue?: string | null | undefined;
    withFormControl?: boolean;
  };

export function FieldDate({
  disabled,
  hint,
  initialValue,
  label,
  labelHint,
  name,
  required,
  reserveSpaceForError,
  validate,
  portalId,
  extraErrorMessages,
  emptyValue,
  withFormControl = true,
  ...datePickerProps
}: Props) {
  const intl = useIntl();
  const defaultPortalId = useMemo(uuid, []);

  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;
  const parse = useCallback((date: Date | null) => dateToString(date) || emptyValue, [emptyValue]);

  const {
    input: {value, ...input},
    meta,
  } = useFieldWithInitialValue<string | null | undefined>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    parse,
    format: stringToDate,
  });

  const renderDatePicker = (props: Omit<DatePickerExternalProps, 'value'>) => (
    <DatePicker
      {...props}
      autoComplete='off'
      disabled={meta.submitting || disabled}
      portalId={portalId ?? defaultPortalId}
      value={fixDateType(value ?? null)}
      onCalendarClose={input.onBlur}
      withInput
      placeholder='DD/MM/YYYY' // TODO INTRANET-1777 accept other formats, store placeholder in locale config?
      utc
    />
  );

  if (!withFormControl) {
    return renderDatePicker({...input, ...datePickerProps});
  }

  return (
    <FormControl
      disabled={meta.submitting || disabled}
      error={getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      label={label}
      labelHint={labelHint}
      hint={hint}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => renderDatePicker({...formControlProps, ...input, ...datePickerProps})}
    </FormControl>
  );
}
