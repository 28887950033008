import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Sorting} from '@joomcode/deprecated-utils/sort/types';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {Pagination} from '@joomcode/joom-ui/Pagination';
import {ClampedText} from 'components/ui/ClampedText';
import {SensitiveData} from 'components/ui/SensitiveData';
import {SalaryRange} from 'domain/salaryRange/model';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {getCountryName} from 'utils/country/name';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

type SalaryRangeColumn = Column<SalaryRange> & {id: ColumnId};
type Props = {
  data: SalaryRange[];
  dataState: DataState;
  isSensitiveDataHidden: boolean;
  columnIds: ColumnId[];
  pagination: OffsetPagination;
  count: number;
  sorting: Sorting | undefined;
  onSort: (sorting: Sorting | undefined) => void;
};

const getRowKey = ({id}: SalaryRange) => id;

export function SalaryRangesTable({
  data,
  dataState,
  isSensitiveDataHidden,
  columnIds,
  pagination,
  count,
  sorting,
  onSort,
}: Props) {
  const acl = useAcl();
  const intl = useIntl();

  const columns: SalaryRangeColumn[] = useMemo(() => {
    const allColumns: SalaryRangeColumn[] = [
      {
        defaultWidth: 110,
        id: ColumnId.REGION,
        name: intl.formatMessage(columnNames[ColumnId.REGION]),
        render: ({region}) => getCountryName(region, intl),
        sortable: true,
      },
      {
        defaultWidth: 90,
        id: ColumnId.GROUP_OF_FUNCTIONS,
        name: intl.formatMessage(columnNames[ColumnId.GROUP_OF_FUNCTIONS]),
        render: ({groupOfFunctions}) => groupOfFunctions,
        sortable: true,
      },
      {
        defaultWidth: 220,
        id: ColumnId.USER_FUNCTION,
        name: intl.formatMessage(columnNames[ColumnId.USER_FUNCTION]),
        render: ({userFunction}) => userFunction,
        sortable: true,
      },
      {
        defaultWidth: 98,
        id: ColumnId.LEVEL_CODE,
        name: intl.formatMessage(columnNames[ColumnId.LEVEL_CODE]),
        render: ({levelCode}) => levelCode,
        sortable: true,
      },
      {
        defaultWidth: 90,
        id: ColumnId.CURRENCY,
        name: intl.formatMessage(columnNames[ColumnId.CURRENCY]),
        render: ({currency}) => currency,
        sortable: true,
      },
      {
        defaultWidth: 100,
        align: 'right',
        id: ColumnId.MIN_AMOUNT,
        name: intl.formatMessage(columnNames[ColumnId.MIN_AMOUNT]),
        render: ({minAmount}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>{intl.formatNumber(minAmount)}</SensitiveData>
        ),
        sortable: true,
      },
      {
        defaultWidth: 100,
        align: 'right',
        id: ColumnId.TARGET_AMOUNT,
        name: intl.formatMessage(columnNames[ColumnId.TARGET_AMOUNT]),
        render: ({targetAmount}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>{intl.formatNumber(targetAmount)}</SensitiveData>
        ),
        sortable: true,
      },
      {
        defaultWidth: 100,
        align: 'right',
        id: ColumnId.MAX_AMOUNT,
        name: intl.formatMessage(columnNames[ColumnId.MAX_AMOUNT]),
        render: ({maxAmount}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>{intl.formatNumber(maxAmount)}</SensitiveData>
        ),
        sortable: true,
      },
      {
        defaultWidth: 135,
        align: 'right',
        id: ColumnId.STOCK_OPTIONS_PROPOSAL,
        name: intl.formatMessage(columnNames[ColumnId.STOCK_OPTIONS_PROPOSAL]),
        render: ({stockOptionsProposal}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            {stockOptionsProposal !== undefined ? intl.formatNumber(stockOptionsProposal) : <DataTable.CellEmpty />}
          </SensitiveData>
        ),
        sortable: true,
      },
      {
        defaultWidth: 200,
        id: ColumnId.COMMENT,
        name: intl.formatMessage(columnNames[ColumnId.COMMENT]),
        render: ({comment}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            {comment ? <ClampedText text={comment} /> : <DataTable.CellEmpty />}
          </SensitiveData>
        ),
      },
    ];

    return allColumns.filter(({id}) => columnIds.includes(id));
  }, [acl, intl, isSensitiveDataHidden, columnIds]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={data}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowKey={getRowKey}
      sorting={sorting}
      onSort={onSort}
      storageName='salaryRanges.list.v3'
      toolbarBottom={
        <Pagination
          disabled={dataState === DataState.LOADING}
          limit={pagination.limit}
          onLimitChange={pagination.setLimit}
          onPageChange={pagination.setPage}
          page={pagination.page}
          pagesCount={Math.ceil(count / pagination.limit)}
        />
      }
    />
  );
}
