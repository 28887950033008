import {ReactComponent as ArrowIcon} from '@joomcode/joom-ui/icons/core/arrowRightThin.svg';
import cn from 'classnames';
import React, {forwardRef, KeyboardEventHandler, MouseEventHandler} from 'react';
import styles from './styles.css';

const ITEM_SHIFT_REM = 3.5;

type Props = {
  children?: React.ReactNode;
  content: React.ReactNode;
  depth: number;
  expandableContent?: React.ReactNode;
  expanded?: boolean;
  onArrowClick?: MouseEventHandler;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>;
  selected?: boolean;
};

export const TreeItem = forwardRef(function TreeItem(
  {
    children,
    content,
    depth,
    expandableContent,
    expanded = false,
    onArrowClick,
    onClick,
    onKeyPress,
    selected = false,
  }: Props,
  ref: React.Ref<HTMLDivElement>,
) {
  const expandable = Boolean(children || expandableContent);
  const shiftStyles = {paddingLeft: `${depth * ITEM_SHIFT_REM}rem`};
  return (
    <>
      <div className={styles.itemWrap} ref={ref}>
        <div
          className={cn(styles.item, {
            [styles.itemExpanded]: expanded,
            [styles.itemSelected]: selected,
            [styles.itemClickable]: Boolean(onClick),
          })}
          onClick={onClick}
          onKeyPress={onKeyPress}
          role='button'
          tabIndex={onKeyPress ? 0 : undefined}
        >
          {expandable ? (
            <button className={styles.arrow} style={shiftStyles} type='button' onClick={onArrowClick}>
              <ArrowIcon className={styles.arrowIcon} />
            </button>
          ) : (
            <div className={styles.arrow} style={shiftStyles} />
          )}
          {content}
        </div>
        {expanded && expandableContent && (
          <div className={styles.expandableContent} style={shiftStyles}>
            {expandableContent}
          </div>
        )}
      </div>
      {expanded && children}
    </>
  );
});
