import {CompensationRequestReason} from 'domain/compensations/request/model/reason';
import {IntlShape} from 'react-intl';
import {hints, labels} from './messages';

export const getAdditionalInformationHintByReason = (reason: CompensationRequestReason, intl: IntlShape): string => {
  switch (reason) {
    case CompensationRequestReason.MEETING_WITH_PARTNERS:
      return intl.formatMessage(hints.partnerCompanyNames);
    case CompensationRequestReason.BUSINESS_EVENT:
      return intl.formatMessage(hints.eventNames);
    default:
      return intl.formatMessage(hints.businessNecessity);
  }
};

export const getAdditionalInformationLabelByReason = (reason: CompensationRequestReason, intl: IntlShape): string => {
  switch (reason) {
    case CompensationRequestReason.MEETING_WITH_PARTNERS:
      return intl.formatMessage(labels.partnerCompanyNames);
    case CompensationRequestReason.BUSINESS_EVENT:
      return intl.formatMessage(labels.eventNames);
    default:
      return intl.formatMessage(labels.businessNecessity);
  }
};

export const getEventDatesLabelByReason = (reason: CompensationRequestReason, intl: IntlShape): string => {
  switch (reason) {
    case CompensationRequestReason.MEETING_WITH_PARTNERS:
    case CompensationRequestReason.MEETING_WITH_COLLEAGUES:
      return intl.formatMessage(labels.meetingDates);
    default:
      return intl.formatMessage(labels.eventDates);
  }
};
