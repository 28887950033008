import {TeamRoleBindingMode} from 'domain/team/model/roleBindingMode';
import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const teamsUrlPatterns = {
  team: '/teams/:id',
  teams: '/teams',
  roleBindings: '/teams/:id/accesses',
};

export const teamsUrls = {
  team: (pathParams: {id: string}) => generatePath(teamsUrlPatterns.team, pathParams),
  teams: () => teamsUrlPatterns.teams,
  roleBindings: (pathParams: {id: string}, searchParams?: {mode?: TeamRoleBindingMode}) =>
    addSearchParams(generatePath(teamsUrlPatterns.roleBindings, pathParams), searchParams),
};
