import {intranetApi} from 'apiClient';
import {StockOptionSummary, stockOptionSummarySchema} from 'domain/stockOption/summary/model';

export const calculateSummaryByGranted = async (granted: number): Promise<StockOptionSummary> => {
  const {data} = await intranetApi.get('/v1/secure/stockOptions/calculateSummaryByGranted', {
    params: {granted},
  });

  return stockOptionSummarySchema.runWithException(data);
};
