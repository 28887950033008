type Primitive = string | number | boolean | null | undefined;

export function uniqueArray<T>(array: T[], getKey?: (arg: T) => Primitive): T[] {
  const seen = new Map();

  return array.filter((item) => {
    const key = getKey ? getKey(item) : item;

    if (seen.has(key)) {
      return false;
    }

    seen.set(key, true);
    return true;
  });
}
