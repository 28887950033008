import {ContractRecord} from 'domain/contract/model';
import {SalaryRecord} from 'domain/salaryRecord/model';
import orderBy from 'lodash/orderBy';
import {getPrimaryLegalEntity} from './getPrimaryLegalEntity';

export function getSalariesFromContractRecords(contracts: ContractRecord[] | undefined): SalaryRecord[] | undefined {
  if (!contracts) {
    return undefined;
  }

  const primaryLegalEntityId = getPrimaryLegalEntity(contracts)?.id;
  const salaries = contracts
    .flatMap(({activeSalaries}) => activeSalaries || [])
    .map((salary) => ({...salary, isPrimary: salary.legalEntity.id === primaryLegalEntityId}));

  return salaries
    ? orderBy(salaries, ['isPrimary', 'employmentRate', 'startDate', 'amount'], ['desc', 'desc', 'asc', 'desc'])
    : undefined;
}
