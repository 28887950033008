import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {ListedProperty} from 'components/ui/ListedProperty';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {Value} from 'domain/compensationReviewRequest/widgets/Value';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {labels, messages} from './messages';

type Props = {
  newUserInfo: CompensationReviewRequest['newUserInfo'];
  oldUserInfo: CompensationReviewRequest['oldUserInfo'];
};

export function CompensationReviewRequestSectionGeneral({newUserInfo, oldUserInfo}: Props) {
  const intl = useIntl();

  return (
    <>
      <Panel.Header title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item xl={6} xs={12}>
            <ListedProperty label={intl.formatMessage(labels.division)}>
              <Value oldValue={oldUserInfo.general.division} newValue={newUserInfo.general.division} />
            </ListedProperty>
          </Grid.Item>
          <Grid.Item xl={6} xs={12}>
            <ListedProperty label={intl.formatMessage(labels.department)}>
              <Value oldValue={oldUserInfo.general.department} newValue={newUserInfo.general.department} />
            </ListedProperty>
          </Grid.Item>
          <Grid.Item xl={6} xs={12}>
            <ListedProperty label={intl.formatMessage(labels.managerId)}>
              {oldUserInfo.general.manager.id === newUserInfo.general.manager.id ? (
                <UserLink user={newUserInfo.general.manager} target='_blank' withAvatar={false} />
              ) : (
                <Value
                  oldValue={<UserLink user={oldUserInfo.general.manager} target='_blank' withAvatar={false} />}
                  newValue={<UserLink user={newUserInfo.general.manager} target='_blank' withAvatar={false} />}
                />
              )}
            </ListedProperty>
          </Grid.Item>
          {newUserInfo.general.jobTitle && (
            <Grid.Item xl={6} xs={12}>
              <ListedProperty label={intl.formatMessage(labels.jobTitle)}>
                <Value oldValue={oldUserInfo.general.jobTitle} newValue={newUserInfo.general.jobTitle} />
              </ListedProperty>
            </Grid.Item>
          )}
          {newUserInfo.general.userFunction && (
            <Grid.Item xl={6} xs={12}>
              <ListedProperty label={intl.formatMessage(labels.userFunction)}>
                <Value oldValue={oldUserInfo.general.userFunction} newValue={newUserInfo.general.userFunction} />
              </ListedProperty>
            </Grid.Item>
          )}
          {newUserInfo.general.levelCode && (
            <Grid.Item xl={6} xs={12}>
              <ListedProperty label={intl.formatMessage(labels.levelCode)}>
                <Value oldValue={oldUserInfo.general.levelCode} newValue={newUserInfo.general.levelCode} />
              </ListedProperty>
            </Grid.Item>
          )}
        </Grid>
      </Panel.Content>
    </>
  );
}
