import React from 'react';
import styles from './index.css';

type Props = Omit<JSX.IntrinsicElements['div'], 'className'>;

export const GroupTitleComponent = React.forwardRef<HTMLDivElement, Props>(function GroupTitle(props: Props, ref) {
  return (
    <div ref={ref} className={styles.title}>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
});
