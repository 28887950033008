import {defineMessages} from 'react-intl';
import {CompensationRequestStatus} from '.';

export const statusMessages = defineMessages<CompensationRequestStatus>({
  [CompensationRequestStatus.APPROVED]: {
    defaultMessage: 'Approved',
    description: '[title] Compensation requests status',
  },
  [CompensationRequestStatus.CANCELED]: {
    defaultMessage: 'Canceled',
    description: '[title] Compensation requests status',
  },
  [CompensationRequestStatus.CLOSED]: {
    defaultMessage: 'Closed',
    description: '[title] Compensation requests status',
  },
  [CompensationRequestStatus.CREATED]: {
    defaultMessage: 'Created',
    description: '[title] Compensation requests status',
  },
  [CompensationRequestStatus.MANAGER_APPROVED]: {
    defaultMessage: 'Manager approved',
    description: '[title] Compensation requests status',
  },
  [CompensationRequestStatus.NEEDS_REPORT]: {
    defaultMessage: 'Needs report',
    description: '[title] Compensation requests status',
  },
  [CompensationRequestStatus.OFFICE_CARE_APPROVED]: {
    defaultMessage: 'Approved',
    description: '[title] Compensation requests status',
  },
  [CompensationRequestStatus.REJECTED]: {
    defaultMessage: 'Rejected',
    description: '[title] Compensation requests status',
  },
  [CompensationRequestStatus.REPORTED]: {
    defaultMessage: 'Reported',
    description: '[title] Compensation requests status',
  },
});
