import React from 'react';
import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {AsyncSelectFilterOptions} from './types';
import {AsyncSelectControl} from './AsyncSelectControl';
import {AsyncSelectLabel} from './AsyncSelectLabel';
import {FilterItem} from '../../types';

export const createAsyncSelectFilter = <T,>(
  filterName: string,
  filterOptions: AsyncSelectFilterOptions<T>,
): FilterItem<T, AsyncSelectFilterOptions<T>> => {
  return {
    name: filterName,
    options: filterOptions,
    isEmpty: (value) => isNullish(value),
    renderControl: (props) => <AsyncSelectControl {...props} />,
    renderLabel: (props) => <AsyncSelectLabel {...props} />,
  };
};
