import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {memo, ReactNode} from 'react';
import {Cell} from '../Cell';
import {CellAlign, StickyColumnOffset} from '../../types';
import styles from './index.css';

type SummaryCellProps = {
  align?: CellAlign;
  colSpan?: number;
  hint?: ReactNode;
  noWrap?: boolean;
  value?: ReactNode;
  stickyOffset?: StickyColumnOffset;
  width: number;
};

export const SummaryCell = memo(function SummaryCell({
  align,
  colSpan,
  hint,
  noWrap,
  value,
  stickyOffset,
  width,
}: SummaryCellProps) {
  return (
    <Cell
      align={align}
      colSpan={colSpan}
      item={null}
      noWrap={noWrap}
      render={() =>
        isNotNullish(value) && (
          <>
            <div className={styles.value}>{value}</div>
            {isNotNullish(hint) && <div className={styles.hint}>{hint}</div>}
          </>
        )
      }
      stickyOffset={stickyOffset}
      width={width}
    />
  );
});
