import composeRefs from '@seznam/compose-react-refs';
import {useOnResize} from '@joomcode/deprecated-utils/react/useOnResize';
import React, {forwardRef, useRef, ReactNode, useEffect, useCallback} from 'react';
import {createLocator, type Mark, type Locator} from 'create-locator';
import {Portal} from 'react-portal';
import cn from 'classnames';
import styles from './index.css';

const CUSTOM_PROPERTY_NAME = '--submit-form-panel-height';

export type SubmitFormPanelLocator = Locator<{
  content: void;
  extra: void;
}>;

export type Props = {
  children: React.ReactNode;
  extra?: React.ReactNode;
  spaced?: boolean;
  reversed?: boolean;
} & Partial<Mark<SubmitFormPanelLocator>>;

const SubmitFormPanelComponent = forwardRef<HTMLDivElement, Props>(function SubmitFormPanel(
  {children, extra, spaced = false, reversed = false, ...restProps},
  ref,
) {
  const locator = createLocator(restProps);
  const localRef = useRef<HTMLDivElement>(null);

  const onResize = useCallback(() => {
    const node = localRef.current;
    if (node) {
      document.documentElement.style.setProperty(CUSTOM_PROPERTY_NAME, `${node.offsetHeight}px`);
    }
  }, []);

  useOnResize(localRef, onResize, [localRef.current]);

  useEffect(() => {
    return () => {
      document.documentElement.style.removeProperty(CUSTOM_PROPERTY_NAME);
    };
  }, []);

  return (
    <Portal>
      <div
        ref={composeRefs(ref, localRef)}
        className={cn(styles.submitFormPanel, {
          [styles.submitFormPanelSpaced]: spaced,
          [styles.submitFormPanelReversed]: reversed,
        })}
        {...locator()}
      >
        <div className={styles.content} {...locator.content()}>
          {children}
        </div>
        {extra && (
          <div className={styles.extra} {...locator.extra()}>
            {extra}
          </div>
        )}
      </div>
    </Portal>
  );
});

export type SubmitFormPanelErrorLocator = Locator<void>;

type SubmitFormPanelErrorProps = {children: ReactNode} & Partial<Mark<SubmitFormPanelErrorLocator>>;

function SubmitFormPanelError({children, ...restProps}: SubmitFormPanelErrorProps) {
  const locator = createLocator(restProps);

  return (
    <div className={styles.error} {...locator()}>
      {children}
    </div>
  );
}

export const SubmitFormPanel = Object.assign(SubmitFormPanelComponent, {
  Error: SubmitFormPanelError, // eslint-disable-line @typescript-eslint/naming-convention
});
