import {ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {CountryCode} from 'domain/countryCode/model';
import {userSchema} from 'domain/user/model';

export const offerEmploymentInfoSchema = object({
  employmentCountry: oneOfEnum(CountryCode),
  relocationCountry: optional(oneOfEnum(CountryCode)),
  jobTitle: string(),
  manager: userSchema,
  division: string(),
  department: string(),
});

export type OfferEmploymentInfo = ExtractSchemaType<typeof offerEmploymentInfoSchema>;
