import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {CompensationReviewRequestBody} from 'domain/compensationReviewRequest/widgets/RequestBody';
import {CompensationReviewRequestComments} from 'domain/compensationReviewRequest/widgets/RequestComments';
import {CompensationReviewRequestDetails} from 'domain/compensationReviewRequest/widgets/RequestDetails';
import React, {useMemo} from 'react';

type Props = {
  request: CompensationReviewRequest;
};

export function CompensationReviewRequest({request}: Props) {
  const decisionsWithComment = useMemo(() => request.decisions.filter(({comment}) => comment), [request.decisions]);

  return (
    <>
      <CompensationReviewRequestDetails request={request} />
      {decisionsWithComment.length > 0 && <CompensationReviewRequestComments decisions={decisionsWithComment} />}
      <CompensationReviewRequestBody {...request} />
    </>
  );
}
