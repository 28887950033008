import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Confirmation dialog',
    description: 'Generic label for confirmation dialog',
    id: 'confirmation-dialog-aria-label',
  },
  confirmationText: {
    defaultMessage: 'Confirm',
    description: '[button] Generic confirmation text for confirmation dialog',
    id: 'confirmation-dialog-confirmation-text',
  },
  denialText: {
    defaultMessage: 'Cancel',
    description: '[button] Generic denial text for confirmation dialog',
    id: 'confirmation-dialog-denial-text',
  },
});
