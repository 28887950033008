import {CreateJobContractRecordConfig} from 'domain/jobContract/api/createRecord';
import {DeleteJobContractRecordParams} from 'domain/jobContract/api/deleteRecord';
import {GetJobContractsParams} from 'domain/jobContract/api/getByUserId';
import {UpdateJobContractRecordConfig} from 'domain/jobContract/api/updateRecord';
import {JobContract} from 'domain/jobContract/model';
import {createEffect} from 'effector';

export const createJobContractRecordFx = createEffect<CreateJobContractRecordConfig, JobContract[]>(
  'create new job contract record',
);
export const deleteJobContractRecordFx = createEffect<DeleteJobContractRecordParams, void>(
  'delete job contract record',
);
export const getJobContractsByUserIdFx = createEffect<GetJobContractsParams, JobContract[]>('get user’s job contracts');
export const getMyJobContractsFx = createEffect<GetJobContractsParams, JobContract[]>('get own job contracts');
export const getSubordinateJobContractsFx = createEffect<GetJobContractsParams, JobContract[]>(
  'get subordinate job contracts',
);
export const updateJobContractRecordFx = createEffect<UpdateJobContractRecordConfig, JobContract[]>(
  'update job contract record',
);
