import {Decision} from 'domain/compensationReviewRequest/model/decision';
import {DecisionStatus} from 'domain/compensationReviewRequest/model/decisionStatus';

export function getCurrentDecision(decisions: Decision[]): Decision | undefined {
  return decisions.find(({status}, index) => isCurrentDecision(decisions, status, index));
}

export function isCurrentDecision(decisions: Decision[], status: DecisionStatus | undefined, index: number): boolean {
  return !status && (index === 0 || decisions[index - 1].status === DecisionStatus.APPROVED);
}

export function getFutureDecisions(decisions: Decision[]): Decision[] {
  return decisions.filter(({status}) => !status).slice(1);
}
