import {getDescendants} from '@joomcode/deprecated-utils/tree/getDescendants';
import {User, UserId} from 'domain/user/model';

/**
 * Checks whether user with given ID is a direct or indirect subordinate of the self user.
 */
export function checkIsSubordinate(selfUser: User | undefined, userId: UserId, users: User[]): boolean {
  if (!selfUser) {
    return false;
  }

  const getChildren = (user: User) => users.filter(({managerId}) => managerId === user.id);
  return getDescendants(selfUser, getChildren).some((user) => user.id === userId);
}
