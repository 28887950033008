import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Sorting, SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {EmptyDash} from '@joomcode/joom-ui/EmptyDash';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {StyledLink} from 'components/ui/StyledLink';
import {CompensationRequest} from 'domain/compensations/request/model';
import {checkRequestNeedsReaction, CompensationRequestsMode} from 'domain/compensations/request/model/mode';
import {groupStays} from 'domain/compensations/request/utils/groupStays';
import {CompensationRequestStatusBadge} from 'domain/compensations/request/widgets/StatusBadge';
import {StayGroup} from 'domain/compensations/stay/model';
import {UserId} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import orderBy from 'lodash/orderBy';
import React, {useCallback, useMemo, useState} from 'react';
import {IntlShape, useIntl} from 'react-intl';
import {compensationsUrls} from 'routes/compensations/urls';
import {getCountryName} from 'utils/country/name';
import {columnNames, messages} from './messages';
import styles from './styles.css';

type Props = {
  compensationRequests: CompensationRequest[];
  dataState: DataState;
  mode: CompensationRequestsMode;
  selfUserId: UserId;
  title?: string;
};

type RichCompensationRequest = CompensationRequest & {
  formattedCountryList: string;
};

const getRowKey = ({id}: RichCompensationRequest) => id;

const getFormattedCountryList = (stays: StayGroup[], intl: IntlShape): string => {
  const countryNames = stays.map(({countryCode}) => getCountryName(countryCode, intl));
  return intl.formatList(countryNames, {type: 'conjunction', style: 'long'});
};

export function CompensationRequestsTable({compensationRequests, dataState, mode, selfUserId, title}: Props) {
  const intl = useIntl();
  const getRowClassName = useCallback(
    (request: RichCompensationRequest) =>
      checkRequestNeedsReaction(request, mode, selfUserId) ? styles.needsReaction : '',
    [mode, selfUserId],
  );

  const columns: Column<RichCompensationRequest>[] = useMemo(() => {
    const createdDateColumn: Column<RichCompensationRequest> = {
      defaultWidth: 10,
      id: 'createdTime',
      name: intl.formatMessage(columnNames.created),
      render: ({createdTime}) => <HumanDate value={createdTime} format='short' />,
      sortable: true,
    };
    const countriesColumn: Column<RichCompensationRequest> = {
      defaultWidth: 15,
      id: 'formattedCountryList',
      name: intl.formatMessage(columnNames.country),
      render: ({id, formattedCountryList}) => (
        <StyledLink to={compensationsUrls.request({compensationRequestId: id})} className={styles.requestLink} colored>
          {formattedCountryList}
        </StyledLink>
      ),
      sortable: true,
    };
    const nameColumn: Column<RichCompensationRequest> = {
      defaultWidth: 15,
      id: 'owner',
      name: intl.formatMessage(columnNames.owner),
      render: ({owner}) =>
        owner ? (
          intl.formatMessage(generalMessages.fullName, {
            firstName: owner.firstName,
            lastName: owner.lastName,
          })
        ) : (
          <EmptyDash />
        ),
      sortable: true,
    };

    const legalEntityColumn: Column<RichCompensationRequest> = {
      defaultWidth: 15,
      id: 'legalEntity',
      name: intl.formatMessage(columnNames.legalEntity),
      render: ({legalEntity}) => legalEntity.title,
    };

    const restColumns: Column<RichCompensationRequest>[] = [
      {
        defaultWidth: 10,
        id: 'startDate',
        name: intl.formatMessage(columnNames.startDate),
        render: ({startDate}) => <HumanDate value={startDate} format='short' utc />,
        sortable: true,
      },
      {
        defaultWidth: 10,
        id: 'endDate',
        name: intl.formatMessage(columnNames.endDate),
        render: ({endDate}) => <HumanDate value={endDate} format='short' utc />,
        sortable: true,
      },
      {
        defaultWidth: 10,
        id: 'status',
        name: intl.formatMessage(columnNames.status),
        render: ({status}) => (
          <div className={styles.status}>
            <CompensationRequestStatusBadge status={status} />
          </div>
        ),
      },
    ];
    return mode === CompensationRequestsMode.ME
      ? [createdDateColumn, countriesColumn, ...restColumns]
      : [createdDateColumn, countriesColumn, nameColumn, legalEntityColumn, ...restColumns];
  }, [intl, mode]);

  const [sorting, setSorting] = useState<Sorting | undefined>({
    by: 'createdTime',
    direction: SortingDirection.DESC,
  });

  const richRequests = useMemo(() => {
    return compensationRequests.map((compensationRequest: CompensationRequest) => ({
      ...compensationRequest,
      formattedCountryList: getFormattedCountryList(groupStays(compensationRequest.stays), intl),
    }));
  }, [compensationRequests, intl]);
  const sortedRichRequests = useMemo(
    () => (sorting ? orderBy(richRequests, sorting.by, sorting.direction) : richRequests),
    [richRequests, sorting],
  );

  return (
    <DataTable<RichCompensationRequest>
      columns={columns}
      dataState={dataState}
      data={sortedRichRequests}
      emptyMessage={intl.formatMessage(messages.noRequests)}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      key={mode}
      onSort={setSorting}
      sorting={sorting}
      storageName='compensations.requestList'
      title={title}
    />
  );
}
