import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  htmlTitle: {
    defaultMessage: 'Office map: {officeName}',
    description: '[title] Page HTML title',
  },
  downloadSeatsData: {
    defaultMessage: 'Download desks data',
    description: 'Download link text',
  },
  generateRoomTokens: {
    defaultMessage: 'Generate room tokens',
    description: '[button] Generate room tokens button text',
  },
});
