import React from 'react';
import {VisuallyHidden} from '../VisuallyHidden';

export function RemoveAutoFocusInside({children}: {children: React.ReactNode}) {
  return (
    <>
      <VisuallyHidden>
        <input type='text' />
      </VisuallyHidden>
      {children}
    </>
  );
}
