import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Compensation request close: cancel',
  },
  submitButton: {
    defaultMessage: 'Close request',
    description: '[button] Compensation request close: submit',
  },
  dialogAriaLabel: {
    defaultMessage: 'Close compensation request',
    description: 'Compensation request close: aria label',
  },
  header: {
    defaultMessage: 'Close compensation request',
    description: '[title] Compensation request close: title',
  },
  text: {
    defaultMessage: 'To close the request enter the amount of compensation paid.',
    description: 'Compensation request close: compensation paid field label',
  },
  amountLabel: {
    defaultMessage: 'Amount',
    description: 'Compensation request receipt field label',
  },
  currencyLabel: {
    defaultMessage: 'Currency',
    description: 'Compensation request receipt field label',
  },
});
