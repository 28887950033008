import {ValidationFunction, ValidationOptions} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {FieldUsers, FieldUsersValue} from 'domain/user/widgets/FieldMultiple';
import React, {useState} from 'react';
import {useField} from 'react-final-form';
import {MessageDescriptor, useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {errorMessages} from './messages';

export type Props = {
  disabled: boolean;
  errorMessages?: Record<string, MessageDescriptor>;
  initialValue?: FieldUsersValue;
  label: React.ReactNode;
  name: string;
  validate?: ValidationFunction<FieldUsersValue, ValidationOptions, unknown>;
};

export function UsersFormControl({
  disabled,
  errorMessages: externalErrorMessages = {},
  initialValue,
  label,
  name,
  validate,
}: Props) {
  const intl = useIntl();
  const {meta} = useField<FieldUsersValue>(name, {subscription: {error: true, touched: true}});
  const [loadingError, setLoadingError] = useState<Error | undefined>();
  const loadingErrorText = loadingError && intl.formatMessage(errorMessages.loadingFailed);

  return (
    <FormControl
      disabled={disabled}
      label={label}
      error={
        loadingErrorText ?? getFieldErrorText(meta, {intl, extraMessages: {...errorMessages, ...externalErrorMessages}})
      }
    >
      {(formControlProps) => (
        <FieldUsers
          disabled={disabled}
          name={name}
          onLoadingError={setLoadingError}
          initialValue={initialValue}
          validate={validate}
          {...formControlProps}
        />
      )}
    </FormControl>
  );
}
