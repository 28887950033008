import {ValidationFunction} from '@joomcode/joom-form';

export enum RestrictedValueValidatorErrorCode {
  RESTRICTED_VALUE_USED = 'restrictedValueUsed',
}

export type RestrictedValuesValidatorOptions = {
  restrictedValues: Set<string>;
};

export const validateForRestrictedValues: ValidationFunction<
  string,
  RestrictedValuesValidatorOptions,
  RestrictedValueValidatorErrorCode
> = (value: string | undefined, options: RestrictedValuesValidatorOptions) => {
  if (value === undefined) return undefined;
  return options.restrictedValues.has(value)
    ? {code: RestrictedValueValidatorErrorCode.RESTRICTED_VALUE_USED, options}
    : undefined;
};
