import {Button} from '@joomcode/joom-ui/Button';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plus.svg';
import {Permission} from 'domain/permission/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

type Props = {
  onDialogOpen(): void;
};

export function JobInfoTabAddon({onDialogOpen}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canCreate = acl.hasPermission(Permission.USER_JOB_INFO_RECORD_WRITE);

  if (!canCreate) {
    return null;
  }

  return (
    <Button size='m' kind='secondary' intent='neutral' iconLeft={<PlusIcon />} onClick={onDialogOpen}>
      {intl.formatMessage(messages.button)}
    </Button>
  );
}
