import {HintIcon} from 'components/ui/HintIcon';
import React from 'react';
import styles from './styles.css';

type Props = {
  hint: React.ReactNode;
};

export function HelpIcon({hint}: Props) {
  return (
    <div className={styles.root}>
      <HintIcon colored={false}>{hint}</HintIcon>
    </div>
  );
}
