import {Panel} from '@joomcode/joom-ui/Panel';
import {useSubordinateStockOptionHolders} from 'domain/stockOption/holder/hooks/useSubordinateStockOptionHolders';
import {
  SubordinateStockOptionHoldersFilters,
  subordinateStockOptionHoldersFiltersSchema,
} from 'domain/stockOption/holder/model/filters';
import {SubordinateStockOptionHoldersDataFilters} from 'domain/stockOption/holder/widgets/SubordinateHoldersDataFilters';
import {StockOptionHoldersTable} from 'domain/stockOption/holder/widgets/Table';
import {useStoredFilters} from 'hooks/useStoredFilters';
import React from 'react';

export function SubordinateStockOptionHoldersList() {
  const {filterValues, setFilterValues} = useStoredFilters<SubordinateStockOptionHoldersFilters>({
    id: 'stockOption.subordinateHolders.filters',
    initialValues: {},
    valuesSchema: subordinateStockOptionHoldersFiltersSchema,
  });
  const {stockOptionHolders, stockOptionHoldersCount, dataState, pagination} =
    useSubordinateStockOptionHolders(filterValues);

  return (
    <>
      <Panel>
        <SubordinateStockOptionHoldersDataFilters values={filterValues} onChange={setFilterValues} />
      </Panel>
      <Panel>
        <StockOptionHoldersTable
          holders={stockOptionHolders}
          count={stockOptionHoldersCount}
          dataState={dataState}
          pagination={pagination}
          storageName='stockOption.subordinateHolders.list'
        />
      </Panel>
    </>
  );
}
