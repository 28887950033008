import {OneTimeBonusReadAccess} from 'domain/oneTimeBonusRecord/model/access';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFull} from 'domain/user/model';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';

export function useOneTimeBonusReadAccess(user: UserFull | undefined): OneTimeBonusReadAccess | null {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const oneTimeBonusFeature = useFeature(Feature.ONE_TIME_BONUSES);
  const ownOneTimeBonusFeature = useFeature(Feature.OWN_ONE_TIME_BONUSES);

  const canReadAny = acl.hasSecurePermission(SecurePermission.ONE_TIME_BONUS_RECORD_READ_ANY);
  const canReadSubordinate = acl.hasSecurePermission(SecurePermission.ONE_TIME_BONUS_RECORD_READ_SUBORDINATE);

  if (!user || !acl.inVpn()) {
    return null;
  }

  if (oneTimeBonusFeature.isAvailable && canReadAny) {
    return OneTimeBonusReadAccess.ANY;
  }
  if (oneTimeBonusFeature.isAvailable && canReadSubordinate && selfUserId && user.ancestorIds.includes(selfUserId)) {
    return OneTimeBonusReadAccess.SUBORDINATE;
  }
  if (ownOneTimeBonusFeature.isAvailable && user.id === selfUserId) {
    return OneTimeBonusReadAccess.MY;
  }

  return null;
}
