import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useTeams} from 'domain/team/hooks/useTeams';
import {TeamId} from 'domain/team/model/id';
import {TeamSelect} from 'domain/team/widgets/Select';
import {FieldValidator} from 'final-form';
import React, {useCallback, useEffect} from 'react';
import {useField} from 'react-final-form';

export type FieldTeamValue = TeamId | null;

export type Props = {
  clearable?: boolean;
  disabled?: boolean;
  id?: string;
  initialValue?: FieldTeamValue;
  name: string;
  onLoadingFail?: (error: Error) => void;
  validate?: FieldValidator<string>;
};

export function FieldTeam({
  initialValue = null,
  disabled: initialDisabled,
  name,
  validate,
  onLoadingFail,
  ...restProps
}: Props) {
  const {teams, dataState} = useTeams({onError: onLoadingFail});
  const disabled = initialDisabled || dataState !== DataState.LOADED;

  const {input} = useField<FieldTeamValue>(name, {allowNull: true, initialValue, validate});

  useEffect(() => {
    input.onChange(dataState === DataState.LOADED ? initialValue : null);
  }, [dataState]);

  const onChange = useCallback(
    (teamId: TeamId | null) => {
      input.onChange(teamId || null);
    },
    [input.onChange],
  );

  return (
    <TeamSelect
      disabled={disabled}
      items={teams.map((team) => team.id)}
      {...input}
      value={input.value}
      onChange={onChange}
      {...restProps}
      teams={teams}
    />
  );
}
