import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonCancel: {
    defaultMessage: 'Cancel',
    description: '[button] Compensation review request: setting data recorded: cancel',
  },
  buttonSubmit: {
    defaultMessage: 'Submit',
    description: '[button] Compensation review request: setting data recorded: submit',
  },
  dialogAriaLabel: {
    defaultMessage: 'Compensation review request: set data recorded',
    description: 'Compensation review request: setting data recorded: aria label',
  },
  title: {
    defaultMessage: 'Real effective date',
    description: '[title] Compensation review request: setting data recorded: title',
  },
  description: {
    defaultMessage:
      'Specify the date the new data from the request takes effect to ensure transparency for participants.',
    description: 'Compensation review request: setting data recorded: description',
  },
  label: {
    defaultMessage: 'Effective date',
    description: 'Compensation review request: setting data recorded: comment field label',
  },
});
