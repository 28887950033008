import {useCallback, useState} from 'react';

type BooleanState = {
  setFalse(): void;
  setTrue(): void;
  toggle(): void;
  value: boolean;
};

export function useBooleanState(initialState: boolean | (() => boolean)): BooleanState {
  const [value, setValue] = useState(initialState);
  const disable = useCallback(() => setValue(false), [setValue]);
  const enable = useCallback(() => setValue(true), [setValue]);
  const toggle = useCallback(() => setValue((isExpanded) => !isExpanded), [setValue]);

  return {
    setFalse: disable,
    setTrue: enable,
    value,
    toggle,
  };
}
