import {Decoder, succeed} from '@joomcode/deprecated-utils/jsonValidation';

/**
 * Decoder that succeeds with default value when input is `undefined`. Otherwise, the original decoder is used
 *
 * Example:
 * ```
 * object({
 *   x: number(),
 *   y: optionalWithDefault(0, number())
 * }).run({x: 5})
 * // => {ok: true, result: {x: 5, y: 0}}
 *
 * object({
 *   x: number(),
 *   y: optionalWithDefault(0, number())
 * }).run({x: 5, y: true})
 * // => {ok: false, error: {..., message: "expected a number, got a boolean"}
 * ```
 */
export const optionalWithDefault = <A>(defaultValue: A, decoder: Decoder<A>): Decoder<A> =>
  Decoder.optional(decoder).andThen((v) => (v === undefined ? succeed(defaultValue) : decoder));
