import {validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {CompanyUnitFormControl} from 'components/widgets/CompanyUnitFormControl';
import {JobTitleWithRecommendation} from 'components/widgets/JobTitleWithRecommendation';
import {CompanyUnits} from 'domain/companyUnits/model';
import {UserInfo} from 'domain/compensationReviewRequest/model/userInfo';
import {Hint} from 'domain/compensationReviewRequest/widgets/Hint';
import {SectionHeader} from 'domain/compensationReviewRequest/widgets/SectionHeader';
import {FieldLevelCode} from 'domain/levelCode/widgets/Field';
import {FieldUser} from 'domain/user/widgets/Field';
import {UserLink} from 'domain/user/widgets/Link';
import React, {useCallback, useMemo} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels, messages} from './messages';

type Props = {
  companyUnits: CompanyUnits;
  general: UserInfo['general'];
  onlySubordinates?: boolean;
};

export function CompensationReviewRequestFormSectionGeneral({companyUnits, general, onlySubordinates}: Props) {
  const intl = useIntl();
  const {change, getState} = useForm();
  const {values, submitting} = getState();

  const hasChanges = useMemo(
    () =>
      general.division !== values.userInfo?.general?.division ||
      general.department !== values.userInfo?.general?.department ||
      general.manager.id !== values.userInfo?.general?.manager?.id ||
      general.jobTitle !== values.userInfo?.general?.jobTitle ||
      general.userFunction !== values.userInfo?.general?.userFunction ||
      general.levelCode !== values.userInfo?.general?.levelCode,
    [values, general],
  );
  const reset = useCallback(() => {
    change('userInfo.general.division', general.division);
    change('userInfo.general.department', general.department);
    change('userInfo.general.manager.id', general.manager.id);
    change('userInfo.general.jobTitle', general.jobTitle);
    change('userInfo.general.userFunction', general.userFunction);
    change('userInfo.general.levelCode', general.levelCode);
  }, [change, general]);

  return (
    <>
      <SectionHeader title={intl.formatMessage(messages.title)} onReset={hasChanges ? reset : undefined} />
      <Panel.Content withPadding>
        <Grid>
          {!onlySubordinates && (
            <>
              <Grid.Item xl={6} xs={12}>
                <CompanyUnitFormControl
                  name='userInfo.general.division'
                  type='division'
                  disabled={submitting}
                  items={companyUnits.divisions}
                  initialValue={general.division}
                  required
                  validate={validateFilled}
                  allowCreatingNewItems={false}
                  clearable={false}
                  reserveSpaceForError={false}
                  hint={
                    general.division !== values.userInfo?.general?.division ? (
                      <Hint value={general.division} />
                    ) : undefined
                  }
                />
              </Grid.Item>
              <Grid.Item xl={6} xs={12}>
                <CompanyUnitFormControl
                  name='userInfo.general.department'
                  type='department'
                  disabled={submitting}
                  items={companyUnits.departments}
                  initialValue={general.department}
                  required
                  validate={validateFilled}
                  allowCreatingNewItems={false}
                  clearable={false}
                  reserveSpaceForError={false}
                  hint={
                    general.department !== values.userInfo?.general?.department ? (
                      <Hint value={general.department} />
                    ) : undefined
                  }
                />
              </Grid.Item>
            </>
          )}
          <Grid.Item xl={12}>
            <Grid>
              {!onlySubordinates && (
                <Grid.Item xl={6} xs={12}>
                  <FormControl
                    disabled={submitting}
                    label={intl.formatMessage(labels.managerId)}
                    required
                    reserveSpaceForError={false}
                    hint={
                      general.manager.id !== values.userInfo?.general?.manager?.id ? (
                        <Hint value={<UserLink user={general.manager} withAvatar={false} target='_blank' />} />
                      ) : undefined
                    }
                  >
                    {(formControlProps) => (
                      <FieldUser
                        disabled={submitting}
                        name='userInfo.general.manager.id'
                        validate={validateFilled}
                        initialValue={general.manager.id}
                        {...formControlProps}
                      />
                    )}
                  </FormControl>
                </Grid.Item>
              )}
              <Grid.Item xl={6} xs={12}>
                <CompanyUnitFormControl
                  name='userInfo.general.jobTitle'
                  type='jobTitle'
                  label={
                    <JobTitleWithRecommendation
                      jobTitle={values.userInfo?.general?.jobTitle}
                      userFunction={values.userInfo?.general?.userFunction}
                      levelCode={values.userInfo?.general?.levelCode}
                      onClick={(recommendedJobTitle) => change('userInfo.general.jobTitle', recommendedJobTitle)}
                    />
                  }
                  disabled={submitting}
                  items={companyUnits.jobTitles}
                  initialValue={general.jobTitle}
                  required
                  validate={validateFilled}
                  reserveSpaceForError={false}
                  hint={
                    general.jobTitle !== values.userInfo?.general?.jobTitle ? (
                      <Hint value={general.jobTitle} />
                    ) : undefined
                  }
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
          <Grid.Item xl={6} xs={12}>
            <CompanyUnitFormControl
              name='userInfo.general.userFunction'
              type='userFunction'
              disabled={submitting}
              items={companyUnits.userFunctions}
              initialValue={general.userFunction}
              required
              validate={validateFilled}
              allowCreatingNewItems={false}
              clearable={false}
              reserveSpaceForError={false}
              hint={
                general.userFunction !== values.userInfo?.general?.userFunction ? (
                  <Hint value={general.userFunction} />
                ) : undefined
              }
            />
          </Grid.Item>
          <Grid.Item xl={6} xs={12}>
            <FieldLevelCode
              name='userInfo.general.levelCode'
              label={intl.formatMessage(labels.levelCode)}
              initialValue={general.levelCode}
              required
              reserveSpaceForError={false}
              hint={
                general.levelCode !== values.userInfo?.general?.levelCode ? (
                  <Hint value={general.levelCode} />
                ) : undefined
              }
            />
          </Grid.Item>
        </Grid>
      </Panel.Content>
    </>
  );
}
