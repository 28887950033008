import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.ROLE]: {
    defaultMessage: 'Role',
    description: 'Resource role binding table column name',
  },
  [ColumnId.TEAM]: {
    defaultMessage: 'Team',
    description: 'Resource role binding table column name',
  },
  [ColumnId.CREATED_BY]: {
    defaultMessage: 'Request author',
    description: 'Resource role binding table column name',
  },
  [ColumnId.ACTIONS]: {
    defaultMessage: 'Actions',
    description: 'Resource role binding table column name',
  },
});

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No accesses',
    description: 'Resource role binding table: empty',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load accesses',
    description: 'Resource role binding table: error',
  },
});
