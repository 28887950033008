import {CrResult} from 'domain/compaRatio/model';
import {MarketLevelPosition} from 'domain/compaRatio/model/marketLevelPosition';

type Intent = 'neutral' | 'positive' | 'warning' | 'negative';

export function getCrIntent(cr: CrResult): Intent {
  const {error, marketLevelPosition} = cr;

  if (
    error ||
    (marketLevelPosition &&
      [MarketLevelPosition.ABOVE_MARKET_LEVEL, MarketLevelPosition.BELOW_MARKET_LEVEL].includes(marketLevelPosition))
  ) {
    return 'negative';
  }
  if (
    marketLevelPosition &&
    [MarketLevelPosition.BOTTOM_OF_MARKET_LEVEL, MarketLevelPosition.TOP_OF_MARKET_LEVEL].includes(marketLevelPosition)
  ) {
    return 'warning';
  }
  if (marketLevelPosition === MarketLevelPosition.AT_MARKET_LEVEL) {
    return 'positive';
  }

  return 'neutral';
}
