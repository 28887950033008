export enum ClientErrorStatus {
  BAD_REQUEST = 'badRequest',
  FORBIDDEN = 'forbidden',
  NOT_FOUND = 'notFound',
  UNAUTHORIZED = 'unauthorized',
}

export class ClientError extends Error {
  public status: ClientErrorStatus;

  public constructor(statusText: string, status: ClientErrorStatus) {
    super(statusText);
    this.name = 'ClientError';
    this.status = status;

    Object.setPrototypeOf(this, ClientError.prototype);
  }
}
