import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {User, UserFull, UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {getAllOutstaffUsersFx, getOutstaffUserByEmailFx} from './index';

type State = {
  outstaffUsers: User[];
  outstaffUsersById: Record<UserId, User>;
  dataState: DataState;
  fullOutstaffUsersByEmail: Record<string, UserFull>;
  fullOutstaffUsersDataState: Record<string, DataState>;
};

export const $outstaffUsers = createStore<State>({
  outstaffUsers: [],
  outstaffUsersById: {},
  dataState: DataState.IDLE,
  fullOutstaffUsersByEmail: {},
  fullOutstaffUsersDataState: {},
})
  .on(getAllOutstaffUsersFx, (state) => {
    return {
      ...state,
      dataState: DataState.LOADING,
    };
  })
  .on(getAllOutstaffUsersFx.fail, (state) => {
    return {
      ...state,
      dataState: DataState.FAILED,
    };
  })
  .on(getAllOutstaffUsersFx.done, (state, {result}) => {
    return {
      ...state,
      outstaffUsers: result,
      outstaffUsersById: arrayToObject(result, ({id}) => id),
      dataState: DataState.LOADED,
    };
  })
  .on(getOutstaffUserByEmailFx, (state, email) => {
    return {
      ...state,
      fullOutstaffUsersDataState: {[email]: DataState.LOADING},
    };
  })
  .on(getOutstaffUserByEmailFx.fail, (state, {params: email}) => {
    return {
      ...state,
      fullOutstaffUsersDataState: {[email]: DataState.FAILED},
    };
  })
  .on(getOutstaffUserByEmailFx.done, (state, {result, params: email}) => {
    return {
      ...state,
      fullOutstaffUsersDataState: {[email]: DataState.LOADED},
      fullOutstaffUsersByEmail: {...state.fullOutstaffUsersByEmail, [email]: result},
    };
  });
