import {array, ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {SalaryRange, salaryRangeSchema} from 'domain/salaryRange/model';
import {bulkUploadMetaSchema, BulkUploadStatus} from 'models/system/bulkUpload';
import {idSchema} from 'models/system/id';
import {SalaryRangesVersion, salaryRangesVersionSchema} from '../version';
import {salaryRangesUploadResultWarningSchema} from './warning';

const salaryRangesUploadResultIdSchema = idSchema;
const salaryRangesUploadResultRowSchema = object({
  meta: bulkUploadMetaSchema,
  record: optional(salaryRangeSchema),
});

const salaryRangesUploadResultVersionSchema = object({
  meta: bulkUploadMetaSchema,
  record: optional(salaryRangesVersionSchema),
});

export const salaryRangesUploadResultSchema = object({
  error: optional(string()),
  id: salaryRangesUploadResultIdSchema,
  salaryRangeUploadRecords: optional(array(salaryRangesUploadResultRowSchema)),
  status: oneOfEnum(BulkUploadStatus),
  versionUploadRecord: salaryRangesUploadResultVersionSchema,
  warning: optional(salaryRangesUploadResultWarningSchema),
});

export type SalaryRangesUploadResultId = ExtractSchemaType<typeof salaryRangesUploadResultIdSchema>;
export type SalaryRangesUploadResult = ExtractSchemaType<typeof salaryRangesUploadResultSchema>;
export type SalaryRangesUploadResultRow = ExtractSchemaType<typeof salaryRangesUploadResultRowSchema>;
export type SalaryRangesUploadResultVersion = ExtractSchemaType<typeof salaryRangesUploadResultVersionSchema>;
export type SalaryRangesUploadInput = {
  id: SalaryRangesUploadResultId;
  salaryRangeRecords: SalaryRange[];
  versionRecord: SalaryRangesVersion;
};
