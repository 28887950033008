import {
  type Mark,
  createLocator,
  type RemoveMarkFromProperties,
  removeMarkFromProperties,
} from '@joomcode/joom-ui/create-locator';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input, InputProps} from '@joomcode/joom-ui/Input';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {memo, forwardRef, useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useFieldWithInitialValue} from '../../components';
import {CommonFieldProps, FieldInputLocator} from '../types';
import {getFieldErrorText} from '../../utils';
import {
  NumberValidatorOptions,
  validateFilled,
  bindValidatorOptions,
  validateNumber,
  composeValidators,
  getFieldValidator,
} from '../../validation';

export type FieldInputNumberProps = Omit<RemoveMarkFromProperties<InputProps>, 'ref' | 'type'> &
  CommonFieldProps<number> &
  NumberValidatorOptions &
  Partial<Mark<FieldInputLocator>>;

const parseToNumber = (value: string): number | undefined =>
  Number.isNaN(parseFloat(value)) ? undefined : parseFloat(value);

export const FieldInputNumber = memo(
  forwardRef<HTMLInputElement, FieldInputNumberProps>(function FieldInputNumber(
    {
      disabled,
      error,
      hint,
      label,
      labelHint,
      name,
      required,
      reserveSpaceForError,
      validate,
      validateFields,
      initialValue,
      integer,
      min,
      minExcluded,
      max,
      maxExcluded,
      maxDecimalPlaces,
      extraErrorMessages,
      ...rest
    }: FieldInputNumberProps,
    ref,
  ) {
    const locator = createLocator(rest);
    const inputProps = removeMarkFromProperties(rest);
    const intl = useIntl();

    const validateRequired = required ? validateFilled : undefined;
    const boundValidateNumber = useMemo(
      () => bindValidatorOptions(validateNumber, {integer, min, minExcluded, max, maxExcluded, maxDecimalPlaces}),
      [min, max, integer],
    );
    const composedValidators = composeValidators(
      ...[validateRequired, boundValidateNumber, validate].filter(isNotNullish),
    );
    const format = useCallback((value: unknown) => (value !== undefined ? value : ''), []);

    const {input, meta} = useFieldWithInitialValue<number | undefined>(name, {
      type: 'number',
      parse: parseToNumber,
      format,
      validate: getFieldValidator(composedValidators),
      validateFields,
      initialValue: isNotNullish(initialValue) ? initialValue : undefined,
    });

    // Format only on blur
    // `formatOnBlur` doesn't work well: https://github.com/final-form/react-final-form/issues/560
    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        input.onBlur(event);

        const {currentTarget} = event;
        const numberValue = parseToNumber(currentTarget.value);
        currentTarget.value = numberValue !== undefined ? String(numberValue) : '';

        if (inputProps.onBlur) {
          inputProps.onBlur(event);
        }
      },
      [input.onBlur, inputProps.onBlur],
    );

    // Hack to prevent value changing with scroll
    const handleWheel = useCallback(
      (event: React.WheelEvent<HTMLInputElement>) => {
        event.currentTarget.blur();

        if (inputProps.onWheel) {
          inputProps.onWheel(event);
        }
      },
      [inputProps.onWheel],
    );

    return (
      <FormControl
        {...locator.formControl()}
        hint={hint}
        label={label}
        labelHint={labelHint}
        disabled={meta.submitting || disabled}
        error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
        required={required}
        reserveSpaceForError={reserveSpaceForError}
      >
        {(formControlProps) => (
          <Input
            {...formControlProps}
            {...input}
            step='any' // to disable browser native validation
            {...inputProps}
            {...locator.input()}
            disabled={meta.submitting || disabled}
            onWheel={handleWheel}
            onBlur={handleBlur}
            ref={ref}
          />
        )}
      </FormControl>
    );
  }),
);
