import delay from 'delay';
import {DependencyList, useCallback, useState} from 'react';
import {DataState} from '../dataState';
import {useIsMounted} from './useIsMounted';

export type TaskFn<Args extends unknown[], Result> = (...args: Args) => Promise<Result> | Result;

export type Task<Args extends unknown[], Result> = {
  dataState: DataState;
  error: unknown | undefined;
  isPerforming: boolean;
  perform: (...args: Args) => Promise<Result>;
  result: Result | undefined;
  reset: () => void;
};

type Options = {
  /**
   * Allows to prevent UI blinking when task is performed too fast
   */
  minDuration?: number;
};

export function useTask<Args extends unknown[], Result>(
  task: TaskFn<Args, Result>,
  deps: DependencyList,
  {minDuration = 0}: Options = {},
): Task<Args, Result> {
  const isMounted = useIsMounted();
  const [dataState, setDataState] = useState<DataState>(DataState.IDLE);
  const [result, setResult] = useState<Result>();
  const [error, setError] = useState<unknown>();

  const reset = useCallback(() => {
    setResult(undefined);
    setDataState(DataState.IDLE);
  }, []);

  const perform = useCallback(async (...args: Args) => {
    try {
      setResult(undefined);
      let innerResult: Result;
      const taskResult = task(...args);

      if (taskResult instanceof Promise || minDuration > 0) {
        setDataState(DataState.LOADING);
        [innerResult] = await Promise.all([taskResult, delay(minDuration)]);
      } else {
        innerResult = taskResult;
      }

      if (isMounted()) {
        setResult(innerResult);
        setDataState(DataState.LOADED);
      }

      return innerResult;
    } catch (innerError) {
      if (isMounted()) {
        setDataState(DataState.FAILED);
        setError(innerError);
      }

      throw innerError;
    }
  }, deps);

  return {
    dataState,
    error,
    isPerforming: dataState === DataState.LOADING,
    perform,
    result,
    reset,
  };
}
