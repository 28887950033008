import {defineMessages} from 'react-intl';
import {StockOptionAgreementStatus} from '.';

export const stockOptionAgreementStatuses = defineMessages<StockOptionAgreementStatus>({
  [StockOptionAgreementStatus.CREATED]: {
    defaultMessage: 'Created',
    description: 'Stock option agreement status',
  },
  [StockOptionAgreementStatus.VESTING]: {
    defaultMessage: 'Vesting',
    description: 'Stock option agreement status',
  },
  [StockOptionAgreementStatus.VESTED]: {
    defaultMessage: 'Vested',
    description: 'Stock option agreement status',
  },
  [StockOptionAgreementStatus.FROZEN]: {
    defaultMessage: 'Frozen',
    description: 'Stock option agreement status',
  },
});
