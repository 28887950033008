import {stockOptionAgreementFreezeApi} from 'domain/stockOption/agreementFreeze/api';
import {
  createStockOptionAgreementFreezeFx,
  deleteStockOptionAgreementFreezeFx,
  updateStockOptionAgreementFreezeFx,
} from '.';

createStockOptionAgreementFreezeFx.use(stockOptionAgreementFreezeApi.create);
deleteStockOptionAgreementFreezeFx.use(stockOptionAgreementFreezeApi.delete);
updateStockOptionAgreementFreezeFx.use(stockOptionAgreementFreezeApi.update);
