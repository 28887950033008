import {Avatar} from 'components/ui/Avatar';
import {OfficeSeat} from 'domain/officeMap/seat/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import {ReactComponent as SeatIcon} from './seat.svg';
import styles from './styles.css';

type Props = {
  seat: OfficeSeat;
};

export function SeatSuggestion({seat}: Props) {
  const intl = useIntl();
  return (
    <div>
      <Avatar name={seat.number ?? ''} icon={<SeatIcon />} />
      <span className={styles.note}>{intl.formatMessage(messages.editTooltip, {number: seat.number})}</span>
    </div>
  );
}
