import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {ReactComponent as CrossIcon} from '@joomcode/joom-ui/icons/core/crossSmall.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {User} from 'domain/user/model';
import {setRolesFx} from 'domain/user/stores/main';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import {UserRole} from 'domain/userRole/model';
import {loadUserRoleByIdFx} from 'domain/userRole/stores/main';
import {generalMessages} from 'i18n/messages/general';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  role: UserRole;
};

export function UserRoleOwners({role}: Props) {
  const intl = useIntl();
  const {dataState, userByLogin} = useAllUsers();

  const revokeRoleFromUser = useAsyncTask(
    async (user: User) =>
      setRolesFx({id: user.id, roleIds: user.roleIds.filter((roleId) => roleId !== role.id)})
        .then(() => loadUserRoleByIdFx({id: role.id}))
        .catch(toaster.interceptThenThrowError),
    [role],
  );

  const onRevokeClick = useCallback(
    (user: User) =>
      confirm(
        {
          title: intl.formatMessage(messages.confirmTitle),
          text: intl.formatMessage(messages.confirmText, {
            fullName: intl.formatMessage(generalMessages.fullName, {
              firstName: user.firstName,
              lastName: user.lastName,
            }),
            login: user.login,
            role: role.name,
          }),
          confirmationText: intl.formatMessage(messages.confirmApprove),
          onConfirm: () => revokeRoleFromUser.perform(user),
        },
        intl,
      ),
    [revokeRoleFromUser, intl],
  );

  const owners = useMemo(
    () => Object.values(userByLogin).filter((user) => user.roleIds.includes(role.id)),
    [userByLogin, role],
  );

  return (
    <Panel withPadding>
      {dataState === DataState.LOADING && <Spinner />}
      {dataState === DataState.FAILED && <Error message={intl.formatMessage(messages.failedToLoadUsers)} />}
      {dataState === DataState.LOADED &&
        (owners.length === 0 ? (
          <div>{intl.formatMessage(messages.roleIsNotUsed)}</div>
        ) : (
          <>
            <h5 className={styles.title}>
              {intl.formatMessage(messages.title, {name: role.name, quantity: role.usedBy})}
            </h5>
            <div className={styles.columns}>
              {owners.map((owner) => (
                <div key={owner.id} className={styles.item}>
                  <ListedUser user={owner} />
                  <div className={styles.revokeButton}>
                    <TooltipAlt content={intl.formatMessage(messages.revokeTooltip)}>
                      <Button intent='neutral' kind='text' size='m' onClick={() => onRevokeClick(owner)}>
                        <span className={styles.revokeIcon}>
                          <CrossIcon />
                        </span>
                      </Button>
                    </TooltipAlt>
                  </div>
                </div>
              ))}
            </div>
          </>
        ))}
    </Panel>
  );
}
