import {ReactComponent as Checkmark} from '@joomcode/joom-ui/icons/circle/checkmark.svg';
import {ReactComponent as Cross} from '@joomcode/joom-ui/icons/circle/cross.svg';
import {ReactComponent as QuestionIcon} from '@joomcode/joom-ui/icons/circle/question.svg';
import {ReactComponent as Watch} from '@joomcode/joom-ui/icons/circle/watch.svg';
import {ReactComponent as Arrow} from '@joomcode/joom-ui/icons/core/arrowRightThin.svg';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import cn from 'classnames';
import {Decision} from 'domain/compensationReviewRequest/model/decision';
import {DecisionStatus} from 'domain/compensationReviewRequest/model/decisionStatus';
import {isCurrentDecision} from 'domain/compensationReviewRequest/utils/decisions';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  decisions: Decision[];
};

const iconByStatus = {
  [DecisionStatus.APPROVED]: Checkmark,
  [DecisionStatus.REJECTED]: Cross,
};

const styleByStatus = {
  [DecisionStatus.APPROVED]: styles.approved,
  [DecisionStatus.REJECTED]: styles.rejected,
};

export function ApproversChain({decisions}: Props) {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      {decisions.map(({user, createdBy, status}, index) => {
        const currentDecision = isCurrentDecision(decisions, status, index);
        const futureDecision = !status && !currentDecision && decisions[index - 1].status !== DecisionStatus.APPROVED;

        let Icon;
        let iconStyle;
        if (status) {
          Icon = iconByStatus[status];
          iconStyle = styleByStatus[status];
        } else if (currentDecision) {
          Icon = Watch;
          iconStyle = styles.current;
        }

        return (
          <div key={user.id} className={cn(styles.approver, {[styles.future]: futureDecision})}>
            <div className={cn(styles.icon, iconStyle)}>{Icon ? <Icon /> : <div className={styles.empty} />}</div>
            <UserLink user={user} withAvatar={false} colored={currentDecision} />
            {createdBy && createdBy.id !== user.id && (
              <div className={styles.hint}>
                <TooltipFaq
                  content={intl.formatMessage(messages.hint, {
                    user: <UserLink user={createdBy} withAvatar={false} colored />,
                  })}
                >
                  <QuestionIcon />
                </TooltipFaq>
              </div>
            )}
            {index < decisions.length - 1 && (
              <div className={styles.arrow}>
                <Arrow />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
