import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {
  bindValidatorOptions,
  composeValidators,
  getFieldValidator,
  useFieldWithInitialValue,
  validateFilled,
} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {loadAllUserRolesFx} from 'domain/userRole/stores/main';
import {$userRoles} from 'domain/userRole/stores/main/state';
import {useStore} from 'effector-react';
import React, {useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {validateForRestrictedValues} from 'utils/form/validateForRestrictedValues';
import {errorMessages, messages} from './messages';

type Props = {
  name: string;
  initialValue: string;
  currentRoleId?: string;
  disabled: boolean;
};

export function UserRoleNameField({name, initialValue, currentRoleId, disabled}: Props) {
  const intl = useIntl();
  const {rolesById, rolesDataState} = useStore($userRoles);

  useEffect(() => {
    if (!isLoadingOrLoaded(rolesDataState)) {
      loadAllUserRolesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  const existingRoleNames: Set<string> = useMemo(() => {
    const allRoles = new Set<string>();
    Object.values(rolesById).forEach((role) => role.id !== currentRoleId && allRoles.add(role.name));
    return allRoles;
  }, [rolesById]);

  const composedValidator = composeValidators(
    validateFilled,
    bindValidatorOptions(validateForRestrictedValues, {restrictedValues: existingRoleNames}),
  );

  const {input, meta} = useFieldWithInitialValue<string>(name, {
    initialValue,
    validate: getFieldValidator(composedValidator),
    parse: identity,
  });

  const fieldDisabled = disabled || rolesDataState !== DataState.LOADED;

  return (
    <FormControl
      error={getFieldErrorText(meta, {intl, extraMessages: errorMessages})}
      label={intl.formatMessage(messages.nameLabel)}
      disabled={fieldDisabled}
    >
      {(formControlProps) => <Input disabled={fieldDisabled} {...formControlProps} {...input} />}
    </FormControl>
  );
}
