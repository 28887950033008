import {array, ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {OfferPriority} from 'domain/offer/model/priority';
import {NewcomerFormTemplateCountry} from 'domain/offer/model/templateCountry';
import {userSchema} from 'domain/user/model';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const offerGeneralInfoSchema = object({
  firstName: string(),
  lastName: string(),
  phone: string(),
  referrers: optionalWithDefault([], array(userSchema)),
  recruiter: optional(userSchema),
  source: string(),
  priority: oneOfEnum(OfferPriority),
  ticketLink: optional(string()),
  templateCountry: optional(oneOfEnum(NewcomerFormTemplateCountry)),
  linkToInterviewFeedback: optional(string()),
});

export type OfferGeneralInfo = ExtractSchemaType<typeof offerGeneralInfoSchema>;
