import {FormattedPrice} from 'components/ui/FormattedPrice';
import {RegularBonusValue} from 'domain/regularBonusRecord/model/value';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  value: RegularBonusValue;
};

export function FormattedRegularBonusValue({value}: Props) {
  const intl = useIntl();

  if (value.absoluteValue) {
    return <FormattedPrice value={value.absoluteValue} />;
  }

  if (value.percentValue) {
    return (
      <>
        {intl.formatNumber(value.percentValue.amount / 100, {
          style: 'percent',
          maximumFractionDigits: 3,
        })}
      </>
    );
  }

  return null;
}
