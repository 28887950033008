import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'All users with role {name} ({quantity})',
    description: '[title] Title for user role owners',
  },
  revokeTooltip: {
    defaultMessage: 'Revoke role',
    description: 'Tooltip for revoke role button',
  },
  confirmTitle: {
    defaultMessage: 'Revoke role',
    description: '[title] Confirmation dialog title',
  },
  confirmText: {
    defaultMessage: 'Are you sure you want to revoke role {role} from user {fullName} ({login})?',
    description: 'Confirmation dialog text',
  },
  confirmApprove: {
    defaultMessage: 'Revoke role',
    description: '[button] Confirmation dialog approve Button',
  },
  failedToLoadUsers: {
    defaultMessage: 'Can’t show role owners: failed to load users',
    description: 'Error message',
  },
  roleIsNotUsed: {
    defaultMessage: 'Role is not used',
    description: 'Error message',
  },
});
