import {useContext} from 'react';
import type {Analytics} from '.';
import {AnalyticsContext} from './context';

export function useAnalytics(): Analytics {
  const analytics = useContext(AnalyticsContext);

  if (!analytics) {
    throw Error('AnalyticsContext not provided');
  }

  return analytics;
}
