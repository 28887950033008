import {DataState} from '@joomcode/deprecated-utils/dataState';
import {NullableLegalInfo} from 'domain/legalInfo/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {getUserLegalInfoByIdFx, setUserLegalInfoFx} from '.';

type State = {
  byUserId: Record<UserId, NullableLegalInfo>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $usersLegalInfo = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on(getUserLegalInfoByIdFx, (state, userId) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on(getUserLegalInfoByIdFx.fail, (state, {params: userId}) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.FAILED,
      },
    };
  })
  .on(getUserLegalInfoByIdFx.done, (state, {result, params: userId}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: result,
      },
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADED,
      },
    };
  })
  .on(setUserLegalInfoFx.done, (state, {result, params: {userId}}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: result,
      },
    };
  });
