import {identity} from '@joomcode/deprecated-utils/function';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {IntentionedText} from '@joomcode/joom-ui/IntentionedText';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {SuggestedInput} from 'components/ui/SuggestedInput';
import {FieldValidator} from 'final-form';
import React from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {labels, warnings} from './messages';
import styles from './styles.css';
import {CompanyUnitType} from './types';

type Props = {
  disabled?: boolean;
  initialValue?: string;
  label?: React.ReactNode;
  hint?: React.ReactNode;
  type: CompanyUnitType;
  name: string;
  items: string[];
  required?: boolean;
  validate?: FieldValidator<string>;
  allowCreatingNewItems?: boolean;
  reserveSpaceForError?: boolean;
  error?: string;
  clearable?: boolean;
};

export function CompanyUnitFormControl({
  disabled,
  initialValue,
  label,
  hint,
  type,
  name,
  items,
  required,
  validate,
  allowCreatingNewItems = true,
  reserveSpaceForError,
  error,
  clearable = true,
}: Props) {
  const intl = useIntl();
  return (
    <Field<string> name={name} validate={validate} initialValue={initialValue}>
      {({input, meta}) => {
        const needsWarning = (meta.touched || meta.dirty) && Boolean(input.value) && !items.includes(input.value);
        return (
          <FormControl
            disabled={disabled}
            label={label ?? intl.formatMessage(labels[type])}
            hint={hint}
            error={
              error ??
              getFieldErrorText(meta, {intl}) ??
              (needsWarning && (
                <div className={styles.warning}>
                  <IntentionedText intent='warning'>{intl.formatMessage(warnings[type])}</IntentionedText>
                </div>
              ))
            }
            required={required}
            reserveSpaceForError={reserveSpaceForError}
          >
            {(formControlProps) => {
              return allowCreatingNewItems ? (
                <SuggestedInput items={items} {...formControlProps} {...input} disabled={disabled} />
              ) : (
                <SimpleAutocomplete
                  items={items}
                  getItemKey={identity}
                  clearable={clearable}
                  {...formControlProps}
                  {...input}
                  disabled={disabled}
                />
              );
            }}
          </FormControl>
        );
      }}
    </Field>
  );
}
