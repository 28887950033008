import {MultilineText} from 'components/ui/MultilineText';
import {Frequency} from 'domain/frequency/model';
import {formatFrequency} from 'domain/frequency/model/formatter';
import {RegularBonusValue} from 'domain/regularBonusRecord/model/value';
import {FormattedRegularBonusValue} from 'domain/regularBonusRecord/widgets/FormattedValue';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  value: RegularBonusValue;
  frequency: Frequency;
  comment?: string;
};

export function FormattedRegularBonus({value, frequency, comment}: Props) {
  const intl = useIntl();

  return (
    <>
      {intl.formatMessage(messages.formattedRegularBonus, {
        amount: <FormattedRegularBonusValue value={value} />,
        frequency: frequency === Frequency.OTHER ? undefined : formatFrequency(frequency, intl),
        comment: comment ? (
          <i>
            <MultilineText text={comment} />
          </i>
        ) : undefined,
      })}
    </>
  );
}
