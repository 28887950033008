import {TerminateParams} from 'domain/employment/api/terminate';
import {UpdateParams} from 'domain/employment/api/update';
import {Employment} from 'domain/employment/model';
import {UserId} from 'domain/user/model';
import {createEffect} from 'effector';

export const cancelTerminationFx = createEffect<{userId: UserId}, Employment>('cancel user’s termination');
export const getEmploymentsByUserIdFx = createEffect<UserId, Employment[]>('get user’s employments');
export const terminateFx = createEffect<TerminateParams, Employment>('terminate user’s employment');
export const updateTerminationFx = createEffect<UpdateParams, Employment>('update user’s termination info');
