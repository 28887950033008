import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {ResourceStatus} from 'domain/resource/model/status';
import {resourceStatuses} from 'domain/resource/model/status/messages';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  status: ResourceStatus;
};

const intentByStatus: Record<ResourceStatus, BadgeIntent> = {
  [ResourceStatus.APPROVED]: 'positive',
  [ResourceStatus.ARCHIVED]: 'neutral',
  [ResourceStatus.CANCELED]: 'neutral',
  [ResourceStatus.PENDING]: 'neutral',
  [ResourceStatus.REJECTED]: 'negative',
};

export function ResourceStatusBadge({status}: Props) {
  const intl = useIntl();
  return <Badge intent={intentByStatus[status]}>{intl.formatMessage(resourceStatuses[status])}</Badge>;
}
