import {stockOptionAgreementApi} from 'domain/stockOption/agreement/api';
import {
  createStockOptionAgreementFx,
  deleteStockOptionAgreementFx,
  sendStockOptionAgreementToHolderFx,
  setDocumentsStatusFx,
} from '.';

createStockOptionAgreementFx.use(stockOptionAgreementApi.create);
deleteStockOptionAgreementFx.use(stockOptionAgreementApi.delete);
sendStockOptionAgreementToHolderFx.use(stockOptionAgreementApi.sendToHolder);
setDocumentsStatusFx.use(stockOptionAgreementApi.setDocumentsStatus);
