import {StockOptionAgreementWithSummary} from 'domain/stockOption/agreement/model/withSummary';
import {StockOptionAgreementFreezeCreateConfig} from 'domain/stockOption/agreementFreeze/api/create';
import {StockOptionAgreementFreezeDeleteConfig} from 'domain/stockOption/agreementFreeze/api/delete';
import {StockOptionAgreementFreezeUpdateConfig} from 'domain/stockOption/agreementFreeze/api/update';
import {createEffect} from 'effector';

export const createStockOptionAgreementFreezeFx = createEffect<
  StockOptionAgreementFreezeCreateConfig,
  StockOptionAgreementWithSummary
>('create stock option agreement freeze');

export const deleteStockOptionAgreementFreezeFx = createEffect<
  StockOptionAgreementFreezeDeleteConfig,
  StockOptionAgreementWithSummary
>('delete stock option agreement freeze');

export const updateStockOptionAgreementFreezeFx = createEffect<
  StockOptionAgreementFreezeUpdateConfig,
  StockOptionAgreementWithSummary
>('update stock option agreement freeze');
