import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  issuer: {
    defaultMessage: 'Issuer',
    description: 'Stock option buyout creation dialog: field label',
  },
  date: {
    defaultMessage: 'Buyout date',
    description: 'Stock option buyout creation dialog: field label',
  },
  amount: {
    defaultMessage: 'Options amount',
    description: 'Stock option buyout creation dialog: field label',
  },
});

export const messages = defineMessages({
  title: {
    defaultMessage: 'Create buyout',
    description: '[title] Stock option buyout creation dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Option buyout creation dialog',
    description: 'Stock option buyout creation dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Stock option buyout creation dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Create',
    description: '[button] Stock option buyout creation dialog: submit button',
  },
  success: {
    defaultMessage: 'Option buyout created',
    description: 'Stock option buyout creation dialog: success message',
  },
});
