import {Callout} from '@joomcode/joom-ui/Callout';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function OutstaffUserCallout() {
  const intl = useIntl();
  return (
    <Callout intent='warning' withIcon>
      {intl.formatMessage(messages.text)}
    </Callout>
  );
}
