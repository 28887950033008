import cn from 'classnames';
import React from 'react';
import {FormattedNumber} from 'react-intl';
import styles from './index.css';

export enum CounterIntent {
  INVERTED_NEUTRAL = 'invertedNeutral',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
  WARNING = 'warning',
}

export type CounterProps = {
  /**
   * The appearance of the element. By default `neutral`. In cases where it
   * is necessary to highlight the element (for instance, when the user
   * is expected to take some action), the value can be set to another value
   * (e.g. `negative`).
   */
  intent?: `${CounterIntent}`;

  /**
   * The numerical value displayed in the counter. Inside the component, it
   * is formatted in the desired manner.
   */
  value: number;
};

const classNameByIntent: Record<CounterIntent, string> = {
  neutral: styles.intentNeutral,
  negative: styles.intentNegative,
  invertedNeutral: styles.intentInvertedNeutral,
  warning: styles.intentWarning,
};

/**
 * The counter displays the number of items in an entity. It can be used
 * in navigation menus, tabs, tables, and other components.
 */
export const Counter = ({value, intent = 'neutral'}: CounterProps) => (
  <span className={cn(styles.root, classNameByIntent[intent])}>
    <FormattedNumber value={value} />
  </span>
);
