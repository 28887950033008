import {SecurePermission} from 'domain/permission/model/secure';
import {withAuth} from 'hocs/withAuth';
import {withSecurePermission} from 'hocs/withSecurePermission';
import {RegularLayout} from 'layouts/Regular';
import {SensitiveDataPage} from 'pages/SensitiveData';
import {RouteConfig} from 'react-router-config';
import {sensitiveDataUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: sensitiveDataUrlPatterns.root,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: withSecurePermission(SensitiveDataPage, SecurePermission.SENSITIVE_DATA_VIEW_ADMIN_UI),
      exact: true,
      path: sensitiveDataUrlPatterns.root,
    },
  ],
};
