import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {OfferStatus} from 'domain/offer/model/status';
import {formatOfferStatus} from 'domain/offer/model/status/formatter';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  status: OfferStatus;
};

const intentByStatus: Record<OfferStatus, BadgeIntent> = {
  [OfferStatus.APPROVING]: 'neutral',
  [OfferStatus.COMPLETED]: 'neutral',
  [OfferStatus.CREATED]: 'primary',
  [OfferStatus.DIGITIZED]: 'invertedPositive',
  [OfferStatus.ACCEPTED]: 'positive',
  [OfferStatus.REVIEW]: 'positive',
  [OfferStatus.DECLINED]: 'negative',
};

export function OfferStatusBadge({status}: Props) {
  const intl = useIntl();
  return <Badge intent={intentByStatus[status]}>{formatOfferStatus(status, intl)}</Badge>;
}
