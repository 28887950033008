import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {Page} from 'components/widgets/Page';
import {Permission} from 'domain/permission/model';
import {roleBindingApi} from 'domain/roleBinding/api';
import {useServices} from 'domain/service/hooks/useServices';
import {ServiceTable} from 'domain/service/widgets/Table';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

export function ServicesPage() {
  const acl = useAcl();
  const intl = useIntl();
  const {services, servicesState} = useServices();

  return (
    <Page
      title={intl.formatMessage(messages.pageTitle)}
      actions={
        acl.hasPermission(Permission.ROLE_BINDING_DOWNLOAD) && (
          <ButtonGroup spaced size='m'>
            <DownloadLinkButton
              kind='text'
              intent='neutral'
              href={roleBindingApi.buildExportByUsersDownloadLink()}
              iconLeft={<DownloadIcon />}
            >
              {intl.formatMessage(messages.btnDownloadByUsers)}
            </DownloadLinkButton>
            <DownloadLinkButton
              kind='text'
              intent='neutral'
              href={roleBindingApi.buildExportByServicesDownloadLink()}
              iconLeft={<DownloadIcon />}
            >
              {intl.formatMessage(messages.btnDownloadByServices)}
            </DownloadLinkButton>
          </ButtonGroup>
        )
      }
    >
      <Panel>
        <Panel.Content withPadding>
          {intl.formatMessage(messages.disclaimer, {
            link: (text) => (
              <StyledExternalLink
                colored
                href='https://www.notion.so/joomteam/About-JDM-45ce6dec99684fa893371446d3a5110c'
              >
                {text}
              </StyledExternalLink>
            ),
          })}
        </Panel.Content>
      </Panel>
      <Panel>
        <Panel.Header title={intl.formatMessage(messages.panelTitle)} withSeparator />
        <Panel.Content>
          <ServiceTable services={services} dataState={servicesState} />
        </Panel.Content>
      </Panel>
    </Page>
  );
}
