import {
  composeValidators,
  getFieldValidator,
  useFieldWithInitialValue,
  ValidationFunction,
  ValidationOptions,
} from '@joomcode/joom-form';
import {Input} from '@joomcode/joom-ui/Input';
import {CountryCode} from 'domain/countryCode/model';
import {formatPhone, parsePhone} from 'models/system/phone';
import {validatePhone} from 'models/system/phone/validate';
import React, {useCallback} from 'react';
import {getCountryCallingCode, Value} from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

type Props = {
  countryCode?: CountryCode;
  disabled?: boolean;
  id?: string;
  initialValue?: string | undefined;
  invalid?: boolean;
  name: string;
  validate?: ValidationFunction<string, ValidationOptions, unknown>;
};

const DEFAULT_COUNTRY_CODE = CountryCode.RU;

export function FieldPhone({countryCode = DEFAULT_COUNTRY_CODE, initialValue, name, validate, ...restProps}: Props) {
  const composedValidator = useCallback(
    getFieldValidator(validate ? composeValidators(validate, validatePhone) : validatePhone),
    [validate],
  );
  const {
    input: {onBlur, onFocus, ...input},
  } = useFieldWithInitialValue<string, HTMLElement, Value>(name, {
    initialValue: initialValue ?? '',
    validate: composedValidator,
    parse: parsePhone,
    format: formatPhone,
  });

  return (
    <PhoneInput
      inputComponent={Input}
      onBlur={() => onBlur()}
      onFocus={() => onFocus()}
      // @ts-expect-error
      placeholder={`+${getCountryCallingCode(countryCode)}`}
      {...input}
      {...restProps}
    />
  );
}
