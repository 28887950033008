import {useCompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestReadAccess';
import {useSubordinateTableAvailabitilty} from 'domain/managerConsole/hooks/useSubordinateTableAvailability';
import {SecurePermission} from 'domain/permission/model/secure';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {Redirect} from 'react-router-dom';
import {hrConsoleUrls} from 'routes/hrConsole/urls';
import {managerConsoleUrls} from 'routes/managerConsole/urls';
import {useAcl} from 'services/acl';

export function ManagerConsoleRedirect() {
  const acl = useAcl();
  const isSubordinateTableAvailable = useSubordinateTableAvailabitilty();
  const compensationReviewRequestReadAccess = useCompensationReviewRequestReadAccess();

  // Manager console isn't available for those who can view HR console
  if (acl.hasSecurePermission(SecurePermission.HR_CONSOLE_VIEW)) {
    return <Redirect to={hrConsoleUrls.root()} />;
  }

  if (isSubordinateTableAvailable) {
    return <Redirect to={managerConsoleUrls.subordinates()} />;
  }

  if (acl.hasSecurePermission(SecurePermission.SALARY_RANGE_READ_AS_MANAGER)) {
    return <Redirect to={managerConsoleUrls.salaryRanges()} />;
  }

  if (compensationReviewRequestReadAccess) {
    return <Redirect to={managerConsoleUrls.compensationReviewRequests()} />;
  }

  return <NotFoundPage />;
}
