import {boolean, ExtractSchemaType, number, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {userSchema} from 'domain/user/model';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {teamIdSchema} from './id';
import {teamMembersSchema} from './members';

export const teamSchema = object({
  calendarLink: optional(string()),
  calendarWithSubteamsLink: optional(string()),
  createdBy: userSchema,
  description: optional(string()),
  id: teamIdSchema,
  members: teamMembersSchema,
  name: string(),
  notionLink: optional(string()),
  parentTeamId: optional(teamIdSchema),
  priorityNumber: optional(number()),
  slackChannelLink: optional(string()),
  slackChannelName: optional(string()),
  vTeam: optionalWithDefault(false, boolean()),
  deleting: optional(boolean()),
});

export type Team = ExtractSchemaType<typeof teamSchema>;
