import {Input} from '@joomcode/joom-ui/Input';
import React, {ChangeEvent, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  query: string;
  onSearchChange: (query: string) => void;
};

export const RtconfSearch = ({query, onSearchChange}: Props) => {
  const intl = useIntl();
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onSearchChange(event.target.value);
    },
    [onSearchChange],
  );
  return (
    <div className={styles.searchWrapper}>
      <div className={styles.header}>{intl.formatMessage(messages.header)}</div>
      <Input value={query} onChange={onChange} placeholder={intl.formatMessage(messages.placeholder)} />
    </div>
  );
};
