import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {Offer} from 'domain/offer/model';
import {VersionedOfferStockOptions} from 'domain/offer/model/stockOption';
import {OfferStockOptionsAccess} from 'domain/offer/model/stockOption/access';
import {getOfferStockOptionsExistenceFx, getOfferStockOptionsFx} from 'domain/offer/stores/stockOptions';
import {$offerStockOptions} from 'domain/offer/stores/stockOptions/state';
import {useStoreMap} from 'effector-react';
import {useCallback, useEffect, useState} from 'react';
import {toaster} from 'services/toaster';
import {useOfferStockOptionsAccess} from './useOfferStockOptionsAccess';

type ReturnShape = {
  data: VersionedOfferStockOptions | null;
  dataState: DataState;
  clientErrorStatus?: ClientErrorStatus;
  readAccess: OfferStockOptionsAccess | null;
  exist: boolean;
};

export function useOfferStockOptions(offer: Offer | null): ReturnShape {
  const readAccess = useOfferStockOptionsAccess(offer);
  const [clientErrorStatus, setClientErrorStatus] = useState<ClientErrorStatus>();
  const stockOptions = useStoreMap({
    store: $offerStockOptions,
    keys: [offer?.id],
    fn: ({byOfferId}, [offerId]) => (offerId && byOfferId[offerId]) || null,
  });
  const dataState = useStoreMap({
    store: $offerStockOptions,
    keys: [offer?.id],
    fn: ({byOfferIdState}, [offerId]) => (offerId && byOfferIdState[offerId]) || DataState.IDLE,
  });

  const handleError = useCallback(
    (error: Error) => {
      if (error instanceof ClientError && error.status === ClientErrorStatus.NOT_FOUND) {
        setClientErrorStatus(error.status);
      } else {
        toaster.error(error.message);
      }
    },
    [setClientErrorStatus],
  );

  useEffect(() => {
    setClientErrorStatus(undefined);

    if (offer && !isLoadingOrLoaded(dataState) && readAccess) {
      const {id, versions} = offer;

      if (readAccess === OfferStockOptionsAccess.FULL) {
        const versionIds = versions.map((version) => version.id);
        getOfferStockOptionsFx({id, versionIds}).catch(handleError);
      } else if (readAccess === OfferStockOptionsAccess.EXISTENCE) {
        getOfferStockOptionsExistenceFx({id}).catch(handleError);
      }
    }
  }, [offer, setClientErrorStatus, readAccess]);

  return {
    clientErrorStatus,
    dataState,
    data: stockOptions?.data ?? null,
    readAccess,
    exist: Boolean(stockOptions?.exist),
  };
}
