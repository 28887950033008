import {Serializer} from '@joomcode/deprecated-utils/Storage';
import {Locale} from 'i18n/locale/types';
import {verifyLocale} from 'i18n/locale/utils/verifyLocale';

export class LocaleSerializer implements Serializer<Locale> {
  public serialize(data: Locale | null): string {
    return data || '';
  }

  public deserialize(value: string): Locale | null {
    return verifyLocale(value);
  }
}
