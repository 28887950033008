import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {MarketDataRecord, MarketDataRecordId, marketDataRecordSchema} from 'domain/marketDataRecord/model';
import {MarketDataRecordDiff} from 'domain/marketDataRecord/model/diff';
import {UserId} from 'domain/user/model';

export type UpdateMarketDataRecordConfig = {
  id: MarketDataRecordId;
  userId: UserId;
  diff: MarketDataRecordDiff;
};

export const update = async ({diff, ...params}: UpdateMarketDataRecordConfig): Promise<MarketDataRecord[]> => {
  const {data} = await intranetApi.post('/v1/secure/users/marketDataRecords/update', diff, {params});

  return array(marketDataRecordSchema).runWithException(data);
};
