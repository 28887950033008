import {FieldInput, useFieldArrayWithInitialValue, validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plusSmall.svg';
import {ReactComponent as TrashIcon} from '@joomcode/joom-ui/icons/core/trash.svg';
import {getDateConstraints} from 'domain/compensations/request/utils/getDateConstraints';
import {errorMessages, fieldLabels, messages} from 'domain/compensations/request/widgets/Form/messages';
import {Stay} from 'domain/compensations/stay/model';
import {CountryCode} from 'domain/countryCode/model';
import {CountrySelect} from 'domain/countryCode/widgets/Select';
import React, {useCallback} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {validateLatinName} from 'utils/form/latinNameValidation';
import {StayDateControl} from './StayDateControl';
import styles from './styles.css';

type Props = {
  disabled?: boolean;
  isOneDayTripForbidden: boolean;
};

export function FieldStays({disabled, isOneDayTripForbidden}: Props) {
  const intl = useIntl();
  const fields = useFieldArrayWithInitialValue<Partial<Stay>>('stays', {initialValue: [{}]});
  const addStay = useCallback(() => fields.push({}), [fields]);
  const addStayWithCity = useCallback(() => fields.push({countryCode: CountryCode.CN}), [fields]);
  const lastStay = fields.value[fields.value.length - 1];

  return (
    <>
      {fields.map((fieldName, index) => {
        const previousStay = fields.value[index - 1];
        const currentStay = fields.value[index];
        const {minStartDate, maxStartDate, minEndDate} = getDateConstraints({
          previousStay,
          currentStay,
          isOneDayTripForbidden,
        });

        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <div className={styles.stay}>
              <div className={styles.country}>
                <FormControl
                  disabled={disabled}
                  label={intl.formatMessage(fieldLabels.country)}
                  reserveSpaceForError={false}
                  required
                >
                  {(formControlProps) => (
                    <Field<CountryCode> name={`${fieldName}.countryCode`} disabled={disabled} validate={validateFilled}>
                      {({input}) => <CountrySelect {...formControlProps} {...input} />}
                    </Field>
                  )}
                </FormControl>
              </div>
              {currentStay.countryCode === CountryCode.CN && (
                <div className={styles.city}>
                  <FieldInput
                    name={`${fieldName}.city`}
                    disabled={disabled}
                    label={intl.formatMessage(fieldLabels.city)}
                    reserveSpaceForError={false}
                    required
                    validate={validateLatinName}
                    extraErrorMessages={errorMessages}
                  />
                </div>
              )}
              <div className={styles.dates}>
                <StayDateControl
                  disabled={disabled}
                  fieldName={`${fieldName}.startDate`}
                  label={intl.formatMessage(fieldLabels.startDate)}
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  stayValues={fields.value[index]}
                  selectsStart
                />
                <StayDateControl
                  disabled={disabled}
                  fieldName={`${fieldName}.endDate`}
                  label={intl.formatMessage(fieldLabels.endDate)}
                  minDate={minEndDate}
                  stayValues={fields.value[index]}
                  selectsEnd
                />
              </div>
              {index > 0 && (
                <div className={styles.removeButtonWrap}>
                  <Button
                    aria-label={intl.formatMessage(messages.removeStayButton)}
                    iconLeft={<TrashIcon />}
                    size='m'
                    kind='text'
                    intent='neutral'
                    type='button'
                    onClick={() => fields.remove(index)}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        );
      })}
      <div className={styles.addButtonWrap}>
        <ButtonGroup spaced>
          {lastStay && lastStay.countryCode === CountryCode.CN && (
            <Button iconLeft={<PlusIcon />} kind='secondary' intent='neutral' type='button' onClick={addStayWithCity}>
              {intl.formatMessage(messages.addCityButton)}
            </Button>
          )}
          <Button iconLeft={<PlusIcon />} kind='secondary' intent='neutral' type='button' onClick={addStay}>
            {intl.formatMessage(messages.addStayButton)}
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
}
