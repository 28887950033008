import {HumanDateFormat} from '@joomcode/deprecated-utils/humanDate';
import React from 'react';
import {useIntl} from 'react-intl';
import {formatHumanDate, formatHumanTime, formatHumanDateTime} from './formatters';

export * from './Range';
export * from './formatters';

type Props = {
  value: number | string | Date;
  utc?: boolean;
  format: HumanDateFormat;
};

export function HumanDate({format, value, utc = false}: Props) {
  const intl = useIntl();

  return <>{formatHumanDate({intl, format, value, utc})}</>;
}

export function HumanTime({format, value, utc = false}: Props) {
  const intl = useIntl();

  return <>{formatHumanTime({intl, format, value, utc})}</>;
}

export function HumanDateTime({format, value, utc = false}: Props) {
  const intl = useIntl();

  return <>{formatHumanDateTime({intl, value, format, utc})}</>;
}
