import {boolean, ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {userIdSchema} from 'domain/user/model';

export const subordinateStockOptionHoldersFiltersSchema = object({
  userId: optional(userIdSchema),
  isDirectSubordinate: optional(boolean()),
});

export const allStockOptionHoldersFiltersSchema = object({
  userId: optional(userIdSchema),
  isTerminated: optional(boolean()),
});

export type AllStockOptionHoldersFilters = Partial<ExtractSchemaType<typeof allStockOptionHoldersFiltersSchema>>;
export type SubordinateStockOptionHoldersFilters = Partial<
  ExtractSchemaType<typeof subordinateStockOptionHoldersFiltersSchema>
>;
