import {defineMessages} from 'react-intl';

export const columnNames = defineMessages({
  short: {
    defaultMessage: 'Short link',
    description: '[title] Redirector column name',
  },
  target: {
    defaultMessage: 'Target',
    description: '[title] Redirector column name',
  },
});

export const messages = defineMessages({
  deleteButton: {
    defaultMessage: 'Delete',
    description: '[button] Redirect route form: delete button',
  },
  confirmDeleteTitle: {
    defaultMessage: 'Delete redirect route',
    description: '[title] Confirm dialog title',
  },
  confirmDeleteText: {
    defaultMessage: 'Deleting redirect route will break existing links. Please proceed with caution.',
    description: 'Confirm dialog text',
  },
});
