import {Panel} from '@joomcode/joom-ui/Panel';
import {HelpIcon} from 'domain/compensationReviewRequest/widgets/HelpIcon';
import {ValuesReset} from 'domain/compensationReviewRequest/widgets/ValuesReset';
import React from 'react';
import styles from './styles.css';

type Props = {
  hint?: React.ReactNode;
  title: React.ReactNode;
  onReset?: () => void;
};

export function SectionHeader({hint, title, onReset}: Props) {
  return (
    <div className={styles.root}>
      <Panel.Header
        title={
          <>
            {title}
            {hint && <HelpIcon hint={hint} />}
          </>
        }
      />
      {onReset && <ValuesReset onClick={onReset} />}
    </div>
  );
}
