import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Options',
    description: 'Compensation review request form: section title',
  },
  button: {
    defaultMessage: 'Add new grant',
    description: '[button] Compensation review request form: add new options grant button',
  },
});
