import React, {ReactNode} from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  getLocatorParameters,
  type Locator,
  type Mark,
} from '../../create-locator';
import styles from './index.css';

export type PanelSectionTestId = ConvertLocatorToTestId<PanelSectionLocator>;
export type PanelSectionLocator = Locator<void, {item: string}, 'sameParameters'>;

type Props = {children: ReactNode} & Partial<Mark<PanelSectionLocator>>;

export function PanelSection({children, ...restProperties}: Props) {
  const locator = createLocator(restProperties);
  const locatorParameters = getLocatorParameters(restProperties);

  return (
    <div className={styles.section} {...locator(locatorParameters)}>
      {children}
    </div>
  );
}
