import React from 'react';
import {ReactComponent as BaseCopyIcon} from '../../icons/core/copy.svg';
import styles from './index.css';
import {CopyHeadless} from '../Headless';

export type CopyIconProps = {
  label?: string;
  value: string;
};

export function CopyIcon({label, value}: CopyIconProps) {
  return (
    <CopyHeadless label={label} value={value}>
      {({copy, label: labelInner}) => (
        <button aria-label={labelInner} className={styles.button} onClick={copy} type='button'>
          <BaseCopyIcon />
        </button>
      )}
    </CopyHeadless>
  );
}
