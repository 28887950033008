import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {FormattedMessage} from 'react-intl';
import React, {useState} from 'react';
import {Input} from '../../../Input';
import {ControlRenderProps} from '../../types';
import {Control} from '../../Control';
import styles from './index.css';
import {Options, InnerValue} from './types';
import {createLocator} from '../../../create-locator';

type NumericValue = number | undefined;
const createReducer =
  (reducer: (...values: NumericValue[]) => NumericValue) =>
  (...values: NumericValue[]): NumericValue => {
    const numbers = values.filter(isNotNullish);
    return numbers.length > 0 ? reducer(...numbers) : undefined;
  };
const getMin = createReducer(Math.min);
const getMax = createReducer(Math.max);

const numbersOnly = (str: string) => str.replace(/\D\./g, '');
const toValue = (string: string, acceptFloat = true): number | undefined => {
  const parser = acceptFloat ? parseFloat : parseInt;
  const value = parser(numbersOnly(string));
  return Number.isNaN(value) ? undefined : value;
};

type Props = ControlRenderProps<InnerValue, Options>;

export function NumberRangeControl({onSubmit, onClose, options, value}: Props) {
  const locator = createLocator(options);
  const [from, setFrom] = useState<InnerValue['from']>(value?.from);
  const [to, setTo] = useState<InnerValue['to']>(value?.to);

  return (
    <Control onClose={onClose} onSubmit={() => onSubmit({from, to})} {...locator.control()}>
      <div className={styles.control}>
        <div className={styles.inputWrapper}>
          <Input
            min={options.min}
            max={getMin(options.max, to)}
            type='number'
            placeholder={options.min?.toString() ?? ''}
            className={styles.input}
            value={from}
            onChange={(e) => setFrom(toValue(e.currentTarget.value, options.acceptFloat))}
            inputMode='decimal'
            step='any'
            {...locator.inputMin()}
          />
          <div className={styles.sign}>≥</div>
        </div>
        <div className={styles.and}>
          <FormattedMessage
            defaultMessage='and'
            description='Conjunction and'
            id='datafilter-number-range-label-conjunction'
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            min={getMax(options.min, from)}
            max={options.max}
            type='number'
            placeholder={options.max?.toString() ?? ''}
            className={styles.input}
            value={to}
            onChange={(e) => setTo(toValue(e.currentTarget.value, options.acceptFloat))}
            inputMode='decimal'
            step='any'
            {...locator.inputMax()}
          />
          <div className={styles.sign}>≤</div>
        </div>
      </div>
    </Control>
  );
}
