import {getEnumValues} from '../ts-enum';

export enum Delimiter {
  COMMA = ',',
  PIPE = '|',
  SEMICOLON = ';',
  TAB = '\t',
}

export const delimiters = getEnumValues(Delimiter);
