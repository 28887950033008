import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {SecurePermission} from 'domain/permission/model/secure';
import {$self} from 'domain/self/stores/main/state';
import {StockOptionViewMode} from 'domain/stockOption/viewMode/model';
import {UserFull} from 'domain/user/model';
import {useStore} from 'effector-react';
import {useMemo} from 'react';
import {useAcl} from 'services/acl';

type ReturnShape = {
  defaultMode: StockOptionViewMode;
  availableModes: StockOptionViewMode[];
  showTabs: boolean;
};

export function useStockOptionViewModes(selfUser: UserFull): ReturnShape {
  const acl = useAcl();
  const {isStockOptionHolder} = useStore($self);
  const hasSubordinates = Boolean(selfUser.subordinates && selfUser.subordinates.length > 0);
  const canReadAny = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_ANY);
  const canReadSubordinates =
    hasSubordinates && acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_SUBORDINATE);

  const modeAvailability: Record<StockOptionViewMode, boolean> = useMemo(
    () => ({
      [StockOptionViewMode.ALL]: canReadAny,
      [StockOptionViewMode.SUBORDINATES]: canReadSubordinates,
      [StockOptionViewMode.MY]: isStockOptionHolder,
    }),
    [isStockOptionHolder, canReadAny, canReadSubordinates],
  );

  const availableModes = useMemo(
    () => getEnumValues(StockOptionViewMode).filter((mode) => modeAvailability[mode]),
    [modeAvailability],
  );

  return {
    defaultMode: availableModes[0] ?? StockOptionViewMode.MY,
    availableModes,
    showTabs: availableModes.length > 1,
  };
}
