import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonApply: {
    defaultMessage: 'Apply changes',
    description: '[button] Salary ranges upload result applying',
  },
  buttonReset: {
    defaultMessage: 'Start over',
    description: '[button] Salary ranges upload reset',
  },
});
