import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  menuAriaLabel: {
    defaultMessage: 'Office policy record menu',
    description: 'Office policy menu: icon label',
  },
  edit: {
    defaultMessage: 'Edit',
    description: 'Office policy menu: item',
  },
  delete: {
    defaultMessage: 'Delete',
    description: 'Office policy menu: item',
  },
});
