import cn from 'classnames';
import React, {PropsWithChildren} from 'react';
import styles from './styles.css';

type Props = PropsWithChildren<{
  isCompact?: boolean;
}>;

export function CompensationReviewRequestLayout({children, isCompact}: Props) {
  return <div className={cn(styles.root, {[styles.compact]: isCompact})}>{children}</div>;
}
