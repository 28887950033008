import {ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {Frequency} from 'domain/frequency/model';
import {RegularBonusType} from 'domain/regularBonusRecord/model/type';
import {regularBonusValueSchema} from 'domain/regularBonusRecord/model/value';

export const offerRegularBonusSchema = object({
  comment: optional(string()),
  frequency: oneOfEnum(Frequency),
  value: regularBonusValueSchema,
}).map((record) => ({
  ...record,
  type: record.value.absoluteValue ? RegularBonusType.ABSOLUTE : RegularBonusType.PERCENT,
}));

export type OfferRegularBonus = ExtractSchemaType<typeof offerRegularBonusSchema>;
