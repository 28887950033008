import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {Panel} from '@joomcode/joom-ui/Panel';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  title?: string;
};

export function CorruptedDataPanel({title}: Props) {
  const intl = useIntl();

  return (
    <Panel title={title} withPadding>
      <Error message={intl.formatMessage(messages.error)} />
    </Panel>
  );
}
