import {FieldValidator} from 'final-form';

// Final-form implies `error` prop of an array field to contain an array,
// so instead of setting an `error` object as is we wrap it in an array (i.e. set an error of the first element instead)

// wraps the error after validation
export function getArrayFieldValidator<Value extends unknown[]>(validator: FieldValidator<Value>) {
  return (value: Value) => {
    const result = validator(value, {});
    if (result instanceof Promise) {
      return result.then((error) => error && [error]);
    }
    return [result];
  };
}

// unwraps the error
export const getArrayFieldError = (error: unknown) => (Array.isArray(error) ? error[0] : error);
