import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {getAncestors} from '@joomcode/deprecated-utils/tree/getAncestors';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {Toggle} from '@joomcode/joom-ui/Toggle';
import {SensitiveDataSwitch} from 'components/ui/SensitiveDataSwitch';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {User} from 'domain/user/model';
import {useAvailability} from 'domain/userFullInfoRecord/hooks/useAvailability';
import {useSorting} from 'domain/userFullInfoRecord/hooks/useSorting';
import {useUserFullInfoRecords} from 'domain/userFullInfoRecord/hooks/useUserFullInfoRecords';
import {UserFullInfoColumn} from 'domain/userFullInfoRecord/model/columns';
import {UserFullInfoFilters, userFullInfoFiltersSchema} from 'domain/userFullInfoRecord/model/filters';
import {UserFullInfoRecordFilters} from 'domain/userFullInfoRecord/widgets/Filters';
import {UserFullInfoRecordTable} from 'domain/userFullInfoRecord/widgets/Table';
import {useStoredFilters} from 'hooks/useStoredFilters';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAnalytics} from 'services/analytics/useAnalytics';
import {filterRecords} from './filterRecords';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  onlySubordinates?: boolean;
};

export function UserFullInfoRecordList({onlySubordinates}: Props) {
  const intl = useIntl();
  const {gtag} = useAnalytics();
  const isSensitiveDataHidden = useBooleanState(true);
  const [showSalaryConversionSwitch, setShowSalaryConversionSwitch] = useState(false);
  const [showSensitiveDataSwitch, setShowSensitiveDataSwitch] = useState(false);
  const showMonthlySalaries = useBooleanState(true);
  const lastLoggedColumnIds = useRef<string>('');
  const users = useAllUsers();
  const ancestorIdsByUserId = useMemo(() => {
    const getParent = (user: User) => (user.managerId ? users.userById[user.managerId] : undefined);
    return arrayToObject(
      Object.values(users.userById),
      ({id}) => id,
      (user) => getAncestors(user, getParent).map(({id}) => id),
    );
  }, [users.userById]);
  const {filterValues, setFilterValues} = useStoredFilters<UserFullInfoFilters>({
    id: 'userFullInfoRecords.filters',
    initialValues: {},
    valuesSchema: userFullInfoFiltersSchema,
  });
  const records = useUserFullInfoRecords();
  const {sorting, setSorting, sortUserFullInfoRecords} = useSorting();
  const sortedRecords = useMemo(
    () => sortUserFullInfoRecords(records.data, sorting),
    [records.data, sorting, sortUserFullInfoRecords],
  );
  const filteredRecords = useMemo(
    () => filterRecords(sortedRecords, ancestorIdsByUserId, filterValues),
    [sortedRecords, ancestorIdsByUserId, filterValues],
  );
  const availability = useAvailability();
  const dataState = getConsolidatedDataState(records.dataState, availability.dataState, users.dataState);

  const handleColumnsVisibilityChange = useCallback(
    (visibleIds: UserFullInfoColumn[]) => {
      setShowSalaryConversionSwitch(visibleIds.includes(UserFullInfoColumn.SALARY_FIX_BASE));
      setShowSensitiveDataSwitch(
        visibleIds.some((id) => availability.availableColumns.some((column) => column.key === id && column.sensitive)),
      );

      if (onlySubordinates) {
        const value = visibleIds.join(',');
        if (lastLoggedColumnIds.current !== value) {
          gtag.logEvent('subordinate_table_visible_columns_change', {value});
          lastLoggedColumnIds.current = value;
        }
      }
    },
    [setShowSalaryConversionSwitch, setShowSensitiveDataSwitch, availability.availableColumns, gtag, onlySubordinates],
  );

  const handleFiltersChange = useCallback(
    (payload: UserFullInfoFilters) => {
      setFilterValues(payload);

      if (onlySubordinates) {
        gtag.logEvent('subordinate_table_filters_value_change', {
          value: JSON.stringify(payload),
        });
      }
    },
    [setFilterValues, gtag, onlySubordinates],
  );

  return (
    <>
      <UserFullInfoRecordFilters
        dataState={dataState}
        userById={users.userById}
        availability={availability.availability}
        records={records.data}
        onlySubordinates={onlySubordinates}
        values={filterValues}
        onChange={handleFiltersChange}
      />
      <Panel>
        <StateHandler data={records.data} state={dataState}>
          {() => (
            <div className={styles.content}>
              <div className={styles.header}>
                <Panel.Header
                  title={
                    <>
                      {intl.formatMessage(messages.title, {onlySubordinates})}
                      <span className={styles.counter}>{intl.formatNumber(filteredRecords.length)}</span>
                    </>
                  }
                  toolbar={
                    <div className={styles.toolbar}>
                      {showSalaryConversionSwitch && !isSensitiveDataHidden.value && (
                        <div className={styles.toggle}>
                          <Toggle
                            label={intl.formatMessage(messages.salariesToggle)}
                            checked={showMonthlySalaries.value}
                            onChange={showMonthlySalaries.toggle}
                          />
                        </div>
                      )}
                      {showSensitiveDataSwitch && (
                        <SensitiveDataSwitch
                          isDataHidden={isSensitiveDataHidden.value}
                          onClick={isSensitiveDataHidden.toggle}
                        />
                      )}
                    </div>
                  }
                  withSeparator
                />
              </div>
              <UserFullInfoRecordTable
                data={filteredRecords}
                availableColumns={availability.availableColumns}
                isSensitiveDataHidden={isSensitiveDataHidden.value}
                showMonthlySalaries={showMonthlySalaries.value}
                onlySubordinates={onlySubordinates}
                sorting={sorting}
                onSort={setSorting}
                onVisibleColumnsChange={handleColumnsVisibilityChange}
              />
            </div>
          )}
        </StateHandler>
      </Panel>
    </>
  );
}
