import {Avatar} from 'components/ui/Avatar';
import {DottedList} from 'components/ui/DottedList';
import {OfficeRoom} from 'domain/officeMap/room/model';
import {roomAttributeMessages, roomMessages} from 'domain/officeMap/room/model/messages';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './styles.css';

type Props = {
  room: OfficeRoom;
};

export function RoomSuggestion({room}: Props) {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <Avatar name={room.text} placeholder={room.text} />
      <div className={styles.info}>
        <div className={styles.name}>
          {intl.formatMessage(roomMessages.name, {roomNumber: room.fullText || room.text})}
        </div>
        <div className={styles.equipment}>
          <DottedList>
            {room.capacity && (
              <DottedList.Item>{intl.formatMessage(roomMessages.capacity, {count: room.capacity})}</DottedList.Item>
            )}
            {room.attributes?.map((attribute) => (
              <DottedList.Item key={attribute}>{intl.formatMessage(roomAttributeMessages[attribute])}</DottedList.Item>
            ))}
          </DottedList>
        </div>
      </div>
    </div>
  );
}
