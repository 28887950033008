import {isNullish} from '../../function/isNullish';
import {Decoder} from '../../jsonValidation';
import {Serializer} from '../types';

export class JsonSerializer<T> implements Serializer<T> {
  private schema: Decoder<T> | undefined;

  public constructor(schema?: Decoder<T>) {
    this.schema = schema;
  }

  public serialize(data: T | null): string {
    if (isNullish(data)) return '';
    return typeof data === 'string' ? data : JSON.stringify(data);
  }

  public deserialize(value: string): T | null {
    let deserialized = null;
    try {
      const json = JSON.parse(value);

      if (this.schema) {
        deserialized = this.schema.runWithException(json);
      } else {
        deserialized = json;
      }
    } catch (e) {
      if (typeof value === 'string') {
        deserialized = value;
      }
    }

    return deserialized as T | null;
  }
}
