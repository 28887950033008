import {FileValidatorErrorCode} from '@joomcode/joom-form';
import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  progressStatusUploading: {
    defaultMessage: 'Uploading...',
    description: 'Image upload: upload progress status',
  },
  progressStatusProcessing: {
    defaultMessage: 'Processing...',
    description: 'Image upload: upload progress status',
  },
});

export const errorMessages = defineMessages({
  [FileValidatorErrorCode.FILE_SIZE_TOO_BIG]: {
    defaultMessage: 'The file size exceeds {maxFileSize}',
    description: 'Image upload: error message',
  },
  [FileValidatorErrorCode.FILE_INVALID_MIME_TYPE]: {
    defaultMessage: 'Invalid file type',
    description: 'Image upload: error message',
  },
});
