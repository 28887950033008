import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {Page} from 'components/widgets/Page';
import {financialRecordsBulkUploadApi} from 'domain/financialRecord/api';
import {FinancialBulkUploadResult} from 'domain/financialRecord/model';
import {convertBulkUploadResult} from 'domain/financialRecord/utils/convertBulkUploadResult';
import {FinancialRecordsExportPanel} from 'domain/financialRecord/widgets/ExportPanel';
import {MarketDataUploadPanel} from 'domain/financialRecord/widgets/MarketDataPanel';
import {RegularBonusUploadPanel} from 'domain/financialRecord/widgets/RegularBonusPanel';
import {SalaryUploadPanel} from 'domain/financialRecord/widgets/SalaryPanel';
import {FinancialRecordsBulkUploadPanel} from 'domain/financialRecord/widgets/UploadPanel';
import {SecurePermission} from 'domain/permission/model/secure';
import {BulkUploadStatus} from 'models/system/bulkUpload';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/sensitiveData/titles';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

export function SensitiveDataPage() {
  const acl = useAcl();
  const intl = useIntl();
  const [uploadResult, setUploadResult] = useState<FinancialBulkUploadResult | undefined>(undefined);

  const onReset = useCallback(() => {
    setUploadResult(undefined);
  }, [setUploadResult]);

  const bulkApplyTask = useAsyncTask(() => {
    if (!uploadResult) {
      return Promise.reject().catch(() => toaster.error(intl.formatMessage(messages.nonExistentError)));
    }

    const uploadInput = convertBulkUploadResult(uploadResult);
    if (!uploadInput) {
      return Promise.reject().catch(() => toaster.error(intl.formatMessage(messages.malformedInputError)));
    }

    return financialRecordsBulkUploadApi
      .apply(uploadInput)
      .then(() => {
        onReset();
        bulkApplyTask.reset();
        toaster.success(intl.formatMessage(messages.success));
      })
      .catch(toaster.interceptThenThrowError);
  }, [uploadResult?.id, setUploadResult, onReset]);

  return (
    <Page title={intl.formatMessage(pageTitles.sensitiveData)}>
      {acl.hasSecurePermission(SecurePermission.SENSITIVE_DATA_DOWNLOAD) && <FinancialRecordsExportPanel />}
      {acl.hasSecurePermission(SecurePermission.FINANCIAL_RECORD_BULK_UPLOAD) && (
        <>
          <FinancialRecordsBulkUploadPanel uploadResult={uploadResult} onUpload={setUploadResult} />
          {uploadResult && (
            <>
              {uploadResult.marketDataBulkUploadRecords && (
                <MarketDataUploadPanel rows={uploadResult.marketDataBulkUploadRecords} />
              )}
              {uploadResult.salaryBulkUploadRecords && (
                <SalaryUploadPanel rows={uploadResult.salaryBulkUploadRecords} />
              )}
              {uploadResult.regularBonusBulkUploadRecords && (
                <RegularBonusUploadPanel rows={uploadResult.regularBonusBulkUploadRecords} />
              )}
              <div className={styles.indent} />
              <SubmitFormPanel>
                <ButtonGroup spaced size='l'>
                  {uploadResult.status === BulkUploadStatus.CREATED && (
                    <Button
                      kind='primary'
                      intent='primary'
                      onClick={bulkApplyTask.perform}
                      loading={bulkApplyTask.isPerforming}
                    >
                      {intl.formatMessage(messages.buttonApply)}
                    </Button>
                  )}
                  <Button kind='primary' intent='neutral' onClick={onReset}>
                    {intl.formatMessage(messages.buttonReset)}
                  </Button>
                </ButtonGroup>
              </SubmitFormPanel>
            </>
          )}
        </>
      )}
    </Page>
  );
}
