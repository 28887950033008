import {defineMessages} from 'react-intl';
import {JsonValidatorErrorCode} from './types';

export const jsonErrorMessages = defineMessages<JsonValidatorErrorCode>({
  [JsonValidatorErrorCode.JSON_INVALID]: {
    id: 'validator-json-invalid',
    description: 'Validation error about provided value is not a json (as expected)',
    defaultMessage: 'A valid JSON value is expected',
  },
});
