import {DataFilterConfig} from '@joomcode/joom-ui/DataFilter';
import {createSimpleBooleanFilter} from 'components/ui/SimpleBooleanFilter';
import {SubordinateStockOptionHoldersFilters} from 'domain/stockOption/holder/model/filters';
import {StockOptionHoldersFilters} from 'domain/stockOption/holder/widgets/Filters';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  values: SubordinateStockOptionHoldersFilters;
  onChange(payload: SubordinateStockOptionHoldersFilters): void;
};

export function SubordinateStockOptionHoldersDataFilters({values, onChange}: Props) {
  const intl = useIntl();
  const config = useMemo<DataFilterConfig<SubordinateStockOptionHoldersFilters>>(
    () => ({
      isDirectSubordinate: createSimpleBooleanFilter(intl.formatMessage(messages.isDirectSubordinate), {
        alwaysVisible: true,
      }),
    }),
    [intl],
  );

  return <StockOptionHoldersFilters values={values} onChange={onChange} config={config} />;
}
