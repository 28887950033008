import {getIn} from 'final-form';
import {useFormState} from 'react-final-form';
import {useField as useFieldBase, UseField} from './useField';

/**
 * The hook solves the problem that the initial values specified
 * when creating the field overwrite the initial values from the form.
 * This problem appeared after the decision in the issue
 * https://github.com/final-form/react-final-form/issues/823
 */
export const useFieldWithInitialValue: UseField = (name, config = {}) => {
  const {initialValues} = useFormState();

  const formInitialValue = getIn(initialValues, name);

  const extraConfig =
    config.initialValue !== undefined
      ? {
          ...config,
          initialValue: formInitialValue ?? config.initialValue,
        }
      : config;

  return useFieldBase(name, extraConfig);
};
