import React, {useState, useCallback} from 'react';
import {createLocator} from '../../../create-locator';
import {Input} from '../../../Input';
import {InputMask} from '../../../InputMask';
import {Control} from '../../Control';
import type {ControlRenderProps} from '../../types';
import type {Options} from './types';
import styles from './index.css';

type Props = ControlRenderProps<string, Options> & {
  mask?: string;
  maskPlaceholder?: string;
};

export function TextControl({value, onClose, onSubmit, name, mask, maskPlaceholder, options}: Props) {
  const locator = createLocator(options);
  const [localValue, setLocalValue] = useState<string>(value ?? '');
  const [error, setError] = useState<string | undefined>();
  const {validate} = options;

  const onControlSubmit = useCallback(async () => {
    const preparedValue = localValue.trim();
    const validateResult = await validate?.(preparedValue);

    if (validateResult) {
      setError(validateResult);
      return;
    }

    onSubmit(preparedValue);
  }, [onSubmit, localValue, validate]);

  return (
    <Control onClose={onClose} onSubmit={onControlSubmit} {...locator.control()}>
      {mask ? (
        <InputMask
          aria-label={name}
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          onChange={(event) => setLocalValue(event.target.value)}
          {...locator.input()}
          type='text'
          value={localValue}
        />
      ) : (
        <Input
          aria-label={name}
          invalid={!!error}
          {...locator.input()}
          type='text'
          onChange={(event) => setLocalValue(event.target.value)}
          value={localValue}
        />
      )}
      {error && (
        <div className={styles.errorContainer} role='alert'>
          {error}
        </div>
      )}
    </Control>
  );
}
