import {useFieldWithInitialValue} from '@joomcode/joom-form';
import {Input} from '@joomcode/joom-ui/Input';
import {FieldValidator} from 'final-form';
import React, {useCallback, useRef} from 'react';

export type FieldFloatValue = number | null;

type Props = {
  disabled?: boolean;
  id?: string;
  initialValue?: FieldFloatValue;
  invalid?: boolean;
  name: string;
  validate?: FieldValidator<number>;
  className?: string;
};

export const dropNonFloatChars = (value: string): string =>
  value
    .replace(/[^0-9.,]/g, '')
    .replace(/^0+(\d)/, '$1')
    .replace(',', '.')
    .replace(/^\./, '0.')
    .replace(/(^\d+\.\d*).*/, '$1');

export const parse = (value: string): FieldFloatValue => {
  const float = parseFloat(dropNonFloatChars(value));
  return Number.isNaN(float) ? null : float;
};

// TODO INTRANET-3377 Refactor it to use FieldInputNumber
export function FieldFloat({disabled, initialValue, name, validate, ...restProps}: Props) {
  const dirtyAmountValue = useRef<string | null>(initialValue?.toString() ?? null);
  const format = useCallback(
    (value: unknown) => (value === initialValue ? value : dropNonFloatChars(dirtyAmountValue.current ?? '')),
    [],
  );

  const {
    input: {onChange, value, ...input},
  } = useFieldWithInitialValue<FieldFloatValue, HTMLInputElement, string>(name, {
    initialValue,
    validate,
    parse,
    format,
  });

  return (
    <Input
      disabled={disabled}
      onChange={(event) => {
        dirtyAmountValue.current = event.target.value;
        onChange(''); // Force re-format even if stored value has not changed
        onChange(event);
      }}
      value={value ?? ''}
      {...restProps}
      {...input}
    />
  );
}
