import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonUpdate: {
    defaultMessage: 'Update',
    description: '[button] Update job offer general info button',
  },
  buttonCancel: {
    defaultMessage: 'Cancel',
    description: '[button] Cancel job offer general info edition',
  },
});

export const fieldLabels = defineMessages({
  firstName: {
    defaultMessage: 'First name',
    description: 'Field label',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: 'Field label',
  },
  phone: {
    defaultMessage: 'Phone number',
    description: 'Field label',
  },
  referrers: {
    defaultMessage: 'Referrers',
    description: 'Field label',
  },
  priority: {
    defaultMessage: 'Priority',
    description: 'Field label',
  },
  ticketLink: {
    defaultMessage: 'Ticket',
    description: 'Field label',
  },
  linkToInterviewFeedback: {
    defaultMessage: 'Link to interviewers’ feedback',
    description: 'Field label',
  },
});
