import React from 'react';
import {useIntl} from 'react-intl';
import {truncate} from 'utils/number/truncate';

export type Props = {
  amount: number;
};

export function FormattedOptionsAmount({amount}: Props) {
  const intl = useIntl();
  const roundedAmount = truncate(amount, 1);
  return <>{intl.formatNumber(roundedAmount)}</>;
}
