import {findBestImageForSize} from '@joomcode/deprecated-utils/image/bundle';
import {Button} from '@joomcode/joom-ui/Button';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {offersApi} from 'domain/offer/api';
import {OfferId} from 'domain/offer/model';
import {ImageBundle} from 'models/system/imageBundle';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

const PREVIEW_MAX_SIZE = 300;
const previewImageMinSize = {width: PREVIEW_MAX_SIZE * 2, height: PREVIEW_MAX_SIZE * 2};

type Dimension = 'width' | 'height';

type Props = {
  imageBundle: ImageBundle;
  offerId: OfferId;
};

export function NewcomerPhoto({imageBundle, offerId}: Props) {
  const intl = useIntl();
  const downloadLink = useMemo(() => offersApi.buildAvatarDownloadLink({offerId}), [offerId]);

  const preview = findBestImageForSize(imageBundle.images, previewImageMinSize);

  if (!preview) {
    return null;
  }

  const bigDimension: Dimension = preview.width > preview.height ? 'width' : 'height';
  const smallDimension: Dimension = bigDimension === 'width' ? 'height' : 'width';
  const bigDimensionValue = Math.min(PREVIEW_MAX_SIZE, preview[bigDimension]);
  const smallDimensionValue = (preview[smallDimension] / preview[bigDimension]) * bigDimensionValue;
  const previewSize = {
    [bigDimension]: bigDimensionValue,
    [smallDimension]: smallDimensionValue,
  };

  return (
    <div className={styles.wrap}>
      <img alt='' src={preview.url} {...previewSize} />
      <div className={styles.buttonWrap}>
        <a href={downloadLink}>
          <Button kind='primary' size='m' intent='neutral' iconLeft={<DownloadIcon />}>
            {intl.formatMessage(messages.download)}
          </Button>
        </a>
      </div>
    </div>
  );
}
