import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Time off info',
    description: '[title] Time off info panel title',
  },
  vacations: {
    defaultMessage: 'Vacations',
    description: '[title] Time off info value label',
  },
  remoteWork: {
    defaultMessage: 'Remote work',
    description: '[title] Time off info value label',
  },
  available: {
    defaultMessage: 'Available',
    description: '[title] Time off info value label',
  },
  requested: {
    defaultMessage: 'Requested',
    description: '[title] Time off info value label',
  },
  requestButton: {
    defaultMessage: 'Request time off{isSelfUser, select, true {} other { on behalf}}',
    description: '[button] Time off info panel: request time off',
  },
  details: {
    defaultMessage: 'Details',
    description: 'Time off info panel: details link',
  },
  hint: {
    defaultMessage: 'Your upcoming vacation will be subtracted when your time off starts',
    description: 'Time off balance: calculation hint',
  },
  loadingError: {
    defaultMessage: 'Failed to load time off data. Try to refresh the page.',
    description: 'Time off info panel: data loading error',
  },
});
