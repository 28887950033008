import {DownloadLink} from '@joomcode/joom-ui/DownloadLink';
import {ExternalLink} from '@joomcode/joom-ui/ExternalLink';
import {ReactComponent as ExternalLinkIcon} from '@joomcode/joom-ui/icons/core/linkExternal.svg';
import cn from 'classnames';
import React, {forwardRef} from 'react';
import {Link, LinkProps} from 'react-router-dom';
import styles from './styledLink.css';

type StyledProps = {
  colored?: boolean;
  disabled?: boolean;
};

type StyledLinkProps = LinkProps & StyledProps;
type StyledDownloadLinkProps = JSX.IntrinsicElements['a'] & StyledProps;
type StyledExternalLinkProps = JSX.IntrinsicElements['a'] &
  StyledProps & {
    withIcon?: boolean;
  };

export function StyledLink({className, colored, disabled, ...restProps}: StyledLinkProps) {
  if (disabled) {
    return <span className={className}>{restProps.children}</span>;
  }
  return <Link {...restProps} className={cn(className, styles.root, {[styles.plain]: !colored})} />;
}

export const StyledExternalLink = forwardRef(function StyledExternalLink(
  {className, colored, disabled, children, withIcon, ...restProps}: StyledExternalLinkProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  if (disabled) {
    return <span className={className}>{children}</span>;
  }

  const combinedClassName = cn(className, styles.root, {[styles.plain]: !colored});

  if (withIcon) {
    return (
      <ExternalLink {...restProps} className={combinedClassName} ref={ref}>
        {children}
        <span className={styles.icon}>
          <ExternalLinkIcon />
        </span>
      </ExternalLink>
    );
  }

  return (
    <ExternalLink {...restProps} className={combinedClassName} ref={ref}>
      {children}
    </ExternalLink>
  );
});

export const StyledDownloadLink = forwardRef(function StyledDownloadLink(
  {className, colored, disabled, ...restProps}: StyledDownloadLinkProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  if (disabled) {
    return <span className={className}>{restProps.children}</span>;
  }
  return <DownloadLink {...restProps} className={cn(className, styles.root, {[styles.plain]: !colored})} ref={ref} />;
});
