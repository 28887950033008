import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Agreements',
    description: '[title] Stock option agreements bulk upload panel title',
  },
});

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.AMOUNT]: {
    defaultMessage: 'Options',
    description: 'Stock option agreements bulk upload table column name',
  },
  [ColumnId.VESTED]: {
    defaultMessage: 'Vested',
    description: 'Stock option agreements bulk upload table column name',
  },
  [ColumnId.PARSING_STATUS]: {
    defaultMessage: 'Parsed',
    description: 'Stock option agreements bulk upload table column name',
  },
  [ColumnId.ISSUE_DATE]: {
    defaultMessage: 'Issue date',
    description: 'Stock option agreements bulk upload table column name',
  },
  [ColumnId.TYPE]: {
    defaultMessage: 'Type',
    description: 'Stock option agreements bulk upload table column name',
  },
  [ColumnId.DOCUMENTS_STATUS]: {
    defaultMessage: 'Documents status',
    description: 'Stock option agreements bulk upload table column name',
  },
  [ColumnId.AGREEMENT_STATUS]: {
    defaultMessage: 'Status',
    description: 'Stock option agreements bulk upload table column name',
  },
  [ColumnId.USER]: {
    defaultMessage: 'Joomer',
    description: 'Stock option agreements bulk upload table column name',
  },
  [ColumnId.EMAIL]: {
    defaultMessage: 'Email',
    description: 'Stock option agreements bulk upload table column name',
  },
});
