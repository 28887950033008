import {Preloader} from '@joomcode/joom-ui/Preloader';
import * as React from 'react';
import styles from './PagePreloader.css';

export function PagePreloader() {
  return (
    <section className={styles.root}>
      <Preloader />
    </section>
  );
}
