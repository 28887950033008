import {useSubordinateTableAvailabitilty} from 'domain/managerConsole/hooks/useSubordinateTableAvailability';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';

export enum ConsoleAccess {
  HR = 'hr',
  MANAGER = 'manager',
}

export function useConsoleAccess(): ConsoleAccess | null {
  const acl = useAcl();
  const selfUser = useSelfUser();
  const adminSalaryRangesFeature = useFeature(Feature.SALARY_RANGES_ADMIN);
  const managerSalaryRangesFeature = useFeature(Feature.SALARY_RANGES_MANAGER);
  const isSubordinateTableAvailable = useSubordinateTableAvailabitilty();

  if (!selfUser || !acl.inVpn()) {
    return null;
  }

  if (adminSalaryRangesFeature.isAvailable && acl.hasSecurePermission(SecurePermission.HR_CONSOLE_VIEW)) {
    return ConsoleAccess.HR;
  }

  if (
    (managerSalaryRangesFeature.isAvailable && acl.hasSecurePermission(SecurePermission.MANAGER_CONSOLE_VIEW)) ||
    isSubordinateTableAvailable
  ) {
    return ConsoleAccess.MANAGER;
  }

  return null;
}
