import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {Office} from 'domain/officeMap/office/model';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {loadAllOfficesFx} from 'domain/officeMap/office/stores/main';
import {$offices} from 'domain/officeMap/office/stores/main/state';
import {OfficeRoom, OfficeRoomId} from 'domain/officeMap/room/model';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  officesByAlias: Partial<Record<OfficeAlias, Office>>;
  roomsById: Record<OfficeRoomId, OfficeRoom>;
  dataState: DataState;
};

export function useOffices(): ReturnShape {
  const {officesByAlias, roomsById, dataState} = useStore($offices);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      loadAllOfficesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return {officesByAlias, roomsById, dataState};
}
