import React, {useState} from 'react';
import {startOfDay, endOfDay} from 'date-fns';
import throttle from 'lodash/throttle';
import Popper from 'popper.js'; // eslint-disable-line
import {FormattedMessage} from 'react-intl';
import {createLocator} from '../../../create-locator';
import {Control} from '../../Control';
import {ControlRenderProps} from '../../types';
import {DatePicker} from '../../../DatePicker';
import {DatePickerProps} from '../../../DatePicker/types';
import {RemoveAutoFocusInside} from '../../../RemoveAutoFocusInside';
import {Options, DataRangeValue} from './types';
import styles from './index.css';

// Hack for MERCHANT-3670 bug
// Todo Remove (MERCHANT-3157)
const POPPER_PROPS: DatePickerProps['popperProps'] = {
  placement: 'bottom',
  modifiers: {
    flip: {
      behavior: ['bottom', 'right', 'left'],
      // @ts-ignore
      fn: throttle(Popper.Defaults.modifiers.flip.fn, 100), // Fix infinite flip loop on flipping boundary
    },
    preventOverflow: {
      enabled: true,
      escapeWithReference: true,
      boundariesElement: 'viewport',
    },
  },
};

export function DateRangeControl({value, onSubmit, onClose, options}: ControlRenderProps<DataRangeValue, Options>) {
  const locator = createLocator(options);
  const {withTime = false} = options;
  const [from, setFrom] = useState<Date | null>(value?.from || null);
  const [to, setTo] = useState<Date | null>(value?.to || null);

  const handleToChange = (date: Date | null): void => {
    if (date?.getHours() === 0 && date.getMinutes() === 0) {
      setTo(endOfDay(date));
    } else {
      setTo(date);
    }
  };

  const onSubmitControl = () => {
    const dateRangeValue: DataRangeValue = {};

    if (from) {
      dateRangeValue.from = withTime && !options.showTimeInput ? startOfDay(from) : from;
    }

    if (to) {
      dateRangeValue.to = withTime && !options.showTimeInput ? endOfDay(to) : to;
    }

    onSubmit(dateRangeValue);
  };

  return (
    <Control onClose={onClose} onSubmit={onSubmitControl} {...locator.control()}>
      <RemoveAutoFocusInside>
        <div className={styles.control}>
          <div className={styles.controlItem}>
            <div className={styles.title}>
              <FormattedMessage
                id='datafilter-data-range-label-title-from'
                description='[title] Label for start date at date range filter'
                defaultMessage='From'
              />
            </div>
            <DatePicker
              minDate={options.minDate}
              maxDate={options.maxDate}
              value={from}
              onChange={setFrom}
              popperProps={POPPER_PROPS}
              showClearButton
              showTimeInput={options.showTimeInput}
              {...locator.datePickerFrom()}
            />
          </div>
          <div className={styles.controlItem}>
            <div className={styles.title}>
              <FormattedMessage
                id='datafilter-data-range-label-title-to'
                description='[title] Label for end date at date range filter'
                defaultMessage='To'
              />
            </div>
            <DatePicker
              minDate={options.minDate}
              maxDate={options.maxDate}
              value={to}
              onChange={handleToChange}
              popperProps={POPPER_PROPS}
              showClearButton
              showTimeInput={options.showTimeInput}
              {...locator.datePickerTo()}
            />
          </div>
        </div>
      </RemoveAutoFocusInside>
    </Control>
  );
}
