import {AsyncTaskFn, useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {SalaryRangesUploadResult} from 'domain/salaryRange/model/upload';
import {applySalaryRangesUploadFx} from 'domain/salaryRange/stores/upload';
import {convertUploadResult} from 'domain/salaryRange/utils/convertUploadResult';
import {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type ReturnShape = {
  apply: AsyncTaskFn<unknown[], void>;
  isApplying: boolean;
  onUpload: (result: SalaryRangesUploadResult) => void;
  onReset: () => void;
  uploadResult?: SalaryRangesUploadResult;
};

export function useSalaryRangesUploadResult(): ReturnShape {
  const intl = useIntl();
  const [uploadResult, setUploadResult] = useState<SalaryRangesUploadResult | undefined>(undefined);

  const onReset = useCallback(() => {
    setUploadResult(undefined);
  }, [setUploadResult]);

  const applyTask = useAsyncTask(() => {
    if (!uploadResult) {
      return Promise.reject().catch(() => toaster.error(intl.formatMessage(messages.nonExistentError)));
    }

    const uploadInput = convertUploadResult(uploadResult);
    if (!uploadInput) {
      return Promise.reject().catch(() => toaster.error(intl.formatMessage(messages.malformedInputError)));
    }

    return applySalaryRangesUploadFx(uploadInput)
      .then(() => {
        onReset();
        applyTask.reset();
        toaster.success(intl.formatMessage(messages.success));
      })
      .catch(toaster.interceptThenThrowError);
  }, [uploadResult, onReset]);

  return {
    apply: applyTask.perform,
    isApplying: applyTask.isPerforming,
    onUpload: setUploadResult,
    onReset,
    uploadResult,
  };
}
