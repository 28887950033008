import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {useValueRef} from '@joomcode/deprecated-utils/react/useValueRef';
import {useFieldWithInitialValue} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Checkbox} from '@joomcode/joom-ui/Checkbox';
import {Option} from '@joomcode/joom-ui/CheckboxGroup';
import {useCheckboxGroupUi} from '@joomcode/joom-ui/CheckboxGroup/useCheckboxGroupUi';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {EmptyMessage} from 'components/ui/EmptyMessage';
import {SearchHighlighter} from 'components/ui/SearchHighlighter';
import {Tree} from 'components/ui/Tree';
import {PanelInstantSearch} from 'components/widgets/PanelInstantSearch';
import {useSecurePermissions} from 'domain/permission/hooks/useSecurePermissions';
import {SecurePermissionId, ServerSecurePermission} from 'domain/permission/model/secure';
import {SecurePermissionCheckboxLabel} from 'domain/secureRole/widgets/SecureRoleForm/CheckboxLabel';
import {searchPermissions} from 'domain/secureRole/widgets/SecureRoleForm/searchPermissions';
import {useTreeView} from 'hooks/useTreeView';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {highlightSearchWords} from 'utils/search/highlight';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  name: string;
  initialValue: SecurePermissionId[];
  disabled: boolean;
  allowedPermissionIds?: SecurePermissionId[];
};

export function SecureRolePermissionsField({name, initialValue, disabled, allowedPermissionIds}: Props) {
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState('');
  const normalizedSearchQuery = searchQuery.trim().toLowerCase();
  const {permissions, dataState} = useSecurePermissions();
  const securePermissionsById = useMemo(() => arrayToObject(permissions, ({id}) => id), [permissions]);
  const filteredPermissions: ServerSecurePermission[] = useMemo(
    () => (normalizedSearchQuery ? searchPermissions(permissions, normalizedSearchQuery, intl) : permissions),
    [permissions, normalizedSearchQuery, intl],
  );
  const filteredGroups = useMemo(
    () => uniq(filteredPermissions.flatMap((permission) => permission.groups)).sort(),
    [filteredPermissions],
  );
  const highlight = useCallback(
    (s: string): React.ReactNode =>
      highlightSearchWords(s, normalizedSearchQuery ? [normalizedSearchQuery] : [], SearchHighlighter),
    [normalizedSearchQuery],
  );

  const isExpanded = Boolean(normalizedSearchQuery);
  const {viewState, events} = useTreeView<string, string>(
    {
      items: filteredGroups,
      getItemId: identity,
      getParentId: () => undefined,
      isExpanded,
      isExpandedByDefault: false,
    },
    [filteredGroups, isExpanded],
  );

  const onCollapseClick = useCallback(() => events.resetAllItems(), [events.resetAllItems]);
  const onExpandClick = useCallback(() => events.expandAllItems(), [events.expandAllItems]);

  const {
    input: {value, onChange, ...restInput},
  } = useFieldWithInitialValue<SecurePermissionId[]>(name, {initialValue, parse: identity});

  const options = useMemo(
    (): Option<string>[] =>
      sortBy(filteredPermissions, [({nonGrantable}) => (nonGrantable ? 1 : -1), ({name}) => name])
        .filter((permission) =>
          allowedPermissionIds ? value.includes(permission.id) || allowedPermissionIds.includes(permission.id) : true,
        )
        .map((permission) => ({
          label: <SecurePermissionCheckboxLabel permission={permission} highlightSearch={highlight} />,
          value: permission.id,
        })),
    [filteredPermissions, allowedPermissionIds, highlight],
  );

  const optionsByGroup = useMemo(
    () =>
      arrayToObject(filteredGroups, identity, (group) =>
        options.filter((option) => securePermissionsById[option.value].groups.includes(group)),
      ),
    [filteredGroups, options],
  );

  const checkboxGroup = useCheckboxGroupUi({
    ariaLabel: intl.formatMessage(messages.permissionsLabel),
    disabled,
    options,
    values: value,
    onChange,
    ...restInput,
  });

  // Wrap values in ref to make memoized callback `handleChange` independent from values change
  const valuesRef = useValueRef(value);
  const handleChange = useCallback(
    ({option, checked}: {option: Option<string>; checked: boolean}) => {
      const currentValues = valuesRef.current;

      if (checked && !currentValues.includes(option.value)) {
        onChange([...currentValues, option.value]);
      }

      if (!checked) {
        onChange(currentValues.filter((item) => item !== option.value));
      }
    },
    [onChange],
  );

  if (dataState === DataState.FAILED) {
    return <FormControl.Error message={intl.formatMessage(messages.permissionsLoadError)} />;
  }

  return (
    <FormControl
      disabled={disabled}
      label={
        <div className={styles.label}>
          <div className={styles.title}>{intl.formatMessage(messages.permissionsLabel)}</div>
          <div className={styles.action}>
            {viewState.expanded ? (
              <Button intent='neutral' kind='secondary' size='m' onClick={onCollapseClick}>
                {intl.formatMessage(messages.buttonCollapse)}
              </Button>
            ) : (
              <Button intent='neutral' kind='secondary' size='m' onClick={onExpandClick}>
                {intl.formatMessage(messages.buttonExpand)}
              </Button>
            )}
          </div>
        </div>
      }
    >
      {(formControlProps) => (
        <>
          {dataState === DataState.LOADING && <Spinner />}
          {dataState === DataState.LOADED && (
            <>
              <PanelInstantSearch
                onSearch={setSearchQuery}
                placeholder={intl.formatMessage(messages.searchPlaceholder)}
                searchQuery={searchQuery}
              />
              <div {...checkboxGroup.getRootProps()}>
                {viewState.itemsCount > 0 ? (
                  <Tree>
                    {filteredGroups.map((group) => {
                      const viewItem = viewState.itemsById[group];
                      return (
                        <Tree.Item
                          depth={0}
                          content={group}
                          expanded={viewItem.expanded}
                          expandableContent={
                            <div className={styles.columns}>
                              {optionsByGroup[group].map((option) => {
                                return (
                                  <div className={styles.item}>
                                    <div className={styles.checkboxWrap}>
                                      <Checkbox
                                        {...restInput}
                                        {...checkboxGroup.getCheckboxProps({value: option.value})}
                                        {...formControlProps}
                                        id={option.value}
                                        disabled={disabled || securePermissionsById[option.value].nonGrantable}
                                        onChange={(event) =>
                                          handleChange({option, checked: event.currentTarget.checked})
                                        }
                                      />
                                    </div>
                                    <div className={styles.itemContent}>{option.label}</div>
                                  </div>
                                );
                              })}
                            </div>
                          }
                          onArrowClick={() => events.toggleItem({id: group})}
                          key={group}
                        />
                      );
                    })}
                  </Tree>
                ) : (
                  <Panel.Content withPadding>
                    <EmptyMessage>{intl.formatMessage(messages.nothingFound)}</EmptyMessage>
                  </Panel.Content>
                )}
              </div>
            </>
          )}
        </>
      )}
    </FormControl>
  );
}
