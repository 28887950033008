import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {Radio} from '@joomcode/joom-ui/Radio';
import {RadioGroup} from '@joomcode/joom-ui/RadioGroup';
import {OfferUpdateReason} from 'domain/offer/model/updateReason';
import {formatOfferUpdateReason} from 'domain/offer/model/updateReason/formatter';
import {OfferFormState} from 'domain/offer/widgets/UpdateForm/state';
import React, {useMemo} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  submitting: boolean;
  values: OfferFormState;
  invalid: boolean;
  formId: string;
  onClose(): void;
};

export function OfferUpdateDialog({formId, values, invalid, submitting, onClose}: Props) {
  const intl = useIntl();
  const options = useMemo(
    () =>
      getEnumValues(OfferUpdateReason).map((reason) => ({
        label: formatOfferUpdateReason(reason, intl),
        value: reason,
      })),
    [intl],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 24rem)'>
      <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
      <Dialog.Body withDefaultPadding>
        <RadioGroup ariaLabel={intl.formatMessage(messages.radioGroupAriaLabel)} direction='vertical'>
          {options.map((option) => (
            <Field<OfferUpdateReason> key={option.value} name='updateReason' validate={validateFilled}>
              {({input}) => (
                <Radio
                  {...input}
                  checked={option.value === values.updateReason}
                  label={option.label}
                  value={option.value}
                  onChange={() => input.onChange(option.value)}
                />
              )}
            </Field>
          ))}
        </RadioGroup>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          form={formId}
          size='l'
          kind='primary'
          intent='primary'
          type='submit'
          disabled={invalid}
          loading={submitting}
        >
          {intl.formatMessage(messages.buttonUpdate)}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
