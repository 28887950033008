import {Button} from '@joomcode/joom-ui/Button';
import {ReactComponent as TrashIcon} from '@joomcode/joom-ui/icons/core/trash.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import React from 'react';
import styles from './styles.css';

type Props = {
  onRemove: () => void;
  title: React.ReactNode;
};

export function RemovableSectionHeader({onRemove, title}: Props) {
  return (
    <div className={styles.root}>
      <Panel.Header title={title} />
      <div className={styles.button}>
        <Button kind='text' intent='neutral' size='s' iconLeft={<TrashIcon />} onClick={onRemove} />
      </div>
    </div>
  );
}
