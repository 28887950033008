import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  resource: {
    defaultMessage: 'Resource',
    description: 'Role binding creation dialog: field label',
  },
  team: {
    defaultMessage: 'Team',
    description: 'Role binding creation dialog: field label',
  },
  role: {
    defaultMessage: 'Role',
    description: 'Role binding creation dialog: field label',
  },
  propagate: {
    defaultMessage: 'Propagate to subteams',
    description: 'Role binding creation dialog: field label',
  },
});

export const messages = defineMessages({
  title: {
    defaultMessage: 'Add access',
    description: '[title] Role binding creation dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Resource access request dialog',
    description: 'Role binding creation dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Role binding creation dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Add access',
    description: '[button] Role binding creation dialog: submit button',
  },
  successfullyRequested: {
    defaultMessage: 'Access requested',
    description: 'Role binding creation dialog: success message for requested access',
  },
  successfullyGranted: {
    defaultMessage: 'Access granted',
    description: 'Role binding creation dialog: success message for granted access',
  },
});
