import {Button} from '@joomcode/joom-ui/Button';
import {ButtonIntent, ButtonKind, ButtonSize} from '@joomcode/joom-ui/Button/types';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import React, {PropsWithChildren} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = PropsWithChildren<{
  size?: ButtonSize;
  kind?: ButtonKind;
  intent?: ButtonIntent;
  disabled?: boolean;
  loading?: boolean;
  formId: string;
}>;

export function SubmitButton({
  size = 'l',
  kind = 'primary',
  intent = 'primary',
  disabled,
  loading,
  formId,
  children,
}: Props) {
  const intl = useIntl();

  return (
    <TooltipFaq content={intl.formatMessage(messages.tooltipText)} placement='top' enabled={disabled}>
      <div>
        <Button
          size={size}
          kind={kind}
          intent={intent}
          type='submit'
          form={formId}
          loading={loading}
          disabled={disabled}
        >
          {children}
        </Button>
      </div>
    </TooltipFaq>
  );
}
