import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {DataTable} from '@joomcode/joom-ui/DataTable';
import {MultiValue} from 'domain/userFullInfoRecord/widgets/MultiValue';
import React from 'react';

type Props<T> = {
  entities: (T | undefined)[];
  renderValue: (entity: T | undefined) => React.ReactNode;
};

export function SalaryRelatedEntity<T>({entities, renderValue}: Props<T>) {
  if (entities.length > 1) {
    if (entities.filter(isNotNullish).length > 0) {
      return <MultiValue values={entities.map((entity) => renderValue(entity) || <DataTable.CellEmpty />)} />;
    }
    return <DataTable.CellEmpty />;
  }
  return renderValue(entities[0]) || <DataTable.CellEmpty />;
}
