import {defineMessages} from 'react-intl';
import {TimeOffRequestsTableMode} from '.';

export const timeOffRequestModes = defineMessages<TimeOffRequestsTableMode>({
  [TimeOffRequestsTableMode.MY]: {
    defaultMessage: 'My requests',
    description: '[title] Time off requests mode name',
  },
  [TimeOffRequestsTableMode.SUBORDINATES]: {
    defaultMessage: 'My report requests',
    description: '[title] Time off requests mode name',
  },
  [TimeOffRequestsTableMode.ALL]: {
    defaultMessage: 'All requests',
    description: '[title] Time off requests mode name',
  },
});
