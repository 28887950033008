import {create} from './create';
import {createExtended} from './createExtended';
import {deleteSalaryRecord} from './delete';
import {buildDownloadAllLink} from './downloadAll';
import {buildDownloadByDateLink} from './downloadByDate';
import {getAny} from './getAny';
import {getAnyExtended} from './getAnyExtended';
import {getMy} from './getMy';
import {getSubordinate} from './getSubordinate';
import {replace} from './replace';
import {replaceExtended} from './replaceExtended';
import {update} from './update';
import {updateExtended} from './updateExtended';

export const salaryRecordsApi = {
  buildDownloadAllLink,
  buildDownloadByDateLink,
  create,
  createExtended,
  delete: deleteSalaryRecord,
  getAny,
  getAnyExtended,
  getMy,
  getSubordinate,
  replace,
  replaceExtended,
  update,
  updateExtended,
};
