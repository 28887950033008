import {StyledLink} from 'components/ui/StyledLink';
import {Tree} from 'components/ui/Tree';
import {RoleBadge} from 'domain/role/widgets/Badge';
import {Team} from 'domain/team/model';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import React from 'react';
import {teamsUrls} from 'routes/teams/urls';
import {highlightIfNeeded} from 'utils/search/highlight';
import styles from './styles.css';

type Props = React.PropsWithChildren<{
  depth: number;
  expanded: boolean;
  highlightSearch?: (s: string) => React.ReactNode;
  onArrowClick: () => void;
  showUsers: boolean;
  team: Team;
}>;

export function TeamsTreeItem({children, depth, expanded, highlightSearch, onArrowClick, showUsers, team}: Props) {
  const members = showUsers ? team.members.activeMembers : [];
  return (
    <Tree.Item
      content={
        <div className={styles.team}>
          <StyledLink to={teamsUrls.team({id: team.id})}>{highlightIfNeeded(team.name, highlightSearch)}</StyledLink>
        </div>
      }
      depth={depth}
      expandableContent={
        members.length
          ? members.map(({memberships, user}) => (
              <div className={styles.user} key={user.id}>
                <ListedUser user={user}>
                  {memberships.map(({membershipId, role}) => (
                    <RoleBadge role={role} key={membershipId} />
                  ))}
                </ListedUser>
              </div>
            ))
          : undefined
      }
      expanded={expanded}
      onArrowClick={onArrowClick}
    >
      {children}
    </Tree.Item>
  );
}
