import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Choose decline reasons',
    description: '[title] Offer decline dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Offer decline dialog',
    description: 'Offer decline dialog: aria label',
  },
  buttonSubmit: {
    defaultMessage: 'Submit',
    description: '[button] Offer decline dialog: submit button',
  },
  error: {
    defaultMessage: 'Decline reasons are not specified',
    description: 'Offer decline dialog: error message',
  },
});
