import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'New one-time bonus',
    description: 'Compensation review request form: section title',
  },
  conditionHint: {
    defaultMessage:
      'Refundable bonus requires the employee to stay with Joom for a certain period, typically one year. If the employee leaves early, the bonus must be paid back.',
    description: 'Compensation review request: one-time bonus form: field hint',
  },
  penalDeadlineHint: {
    defaultMessage: 'The deadline for meeting the condition, e.g., staying with Joom, to avoid repaying the bonus.',
    description: 'Compensation review request: one-time bonus form: field hint',
  },
  commentHint: {
    defaultMessage: 'Specify the condition an employee is expected to meet by the penal date, if applicable.',
    description: 'Compensation review request: one-time bonus form: field hint',
  },
});

export const labels = defineMessages({
  amount: {
    defaultMessage: 'Amount',
    description: 'Compensation review request: one-time bonus form: field label',
  },
  condition: {
    defaultMessage: 'Condition',
    description: 'Compensation review request: one-time bonus form: field label',
  },
  comment: {
    defaultMessage: 'Comment',
    description: 'Compensation review request: one-time bonus form: field label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'Compensation review request: one-time bonus form: field label',
  },
  date: {
    defaultMessage: 'Date',
    description: 'Compensation review request: one-time bonus form: field label',
  },
  penalDeadline: {
    defaultMessage: 'Penal date',
    description: 'Compensation review request: one-time bonus form: field label',
  },
  type: {
    defaultMessage: 'Type',
    description: 'Compensation review request: one-time bonus form: field label',
  },
});
