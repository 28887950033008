import {DataState} from '@joomcode/deprecated-utils/dataState';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {SecurePermission} from 'domain/permission/model/secure';
import {StockOptionBuyout} from 'domain/stockOption/buyout/model';
import {stockOptionBuyoutDocumentsStatuses} from 'domain/stockOption/buyout/model/documentsStatus/messages';
import {StockOptionBuyoutCreationDialog} from 'domain/stockOption/buyout/widgets/CreationDialog';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import {User} from 'domain/user/model';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

interface Props {
  user: User;
  buyouts: StockOptionBuyout[];
}

const getRowKey = (buyout: StockOptionBuyout) => buyout.id;

export function StockOptionBuyoutsPanel({user, buyouts}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  const canReadAnyOptionsData = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_ANY);
  const canManageBuyouts = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_WRITE_ANY);
  const buyoutCreationDialog = usePopupState();

  const columns = useMemo(() => {
    const result: Column<StockOptionBuyout>[] = [
      {
        defaultWidth: 3,
        id: ColumnId.AMOUNT,
        align: 'right',
        name: intl.formatMessage(columnNames[ColumnId.AMOUNT]),
        render: ({amount}) => <FormattedOptionsAmount amount={amount} />,
      },
      {
        defaultWidth: 3,
        id: ColumnId.DATE,
        name: intl.formatMessage(columnNames[ColumnId.DATE]),
        render: ({date}) => <HumanDate value={date} format='short' utc />,
      },
      {
        defaultWidth: 10,
        id: ColumnId.ISSUER,
        name: intl.formatMessage(columnNames[ColumnId.ISSUER]),
        render: ({issuer}) => issuer,
      },
    ];

    if (canReadAnyOptionsData) {
      result.push({
        defaultWidth: 7,
        id: ColumnId.DOCUMENTS_STATUS,
        name: intl.formatMessage(columnNames[ColumnId.DOCUMENTS_STATUS]),
        render: ({documentsStatus}) => intl.formatMessage(stockOptionBuyoutDocumentsStatuses[documentsStatus]),
      });
    }

    return result;
  }, [intl, canReadAnyOptionsData]);

  return (
    <>
      <Panel
        withSeparator
        withMarginBottom={false}
        title={intl.formatMessage(messages.title)}
        toolbarTop={
          canManageBuyouts && (
            <Button size='m' kind='primary' intent='primary' onClick={buyoutCreationDialog.open}>
              {intl.formatMessage(messages.btnCreate)}
            </Button>
          )
        }
      >
        <DataTable
          columns={columns}
          dataState={DataState.LOADED}
          data={buyouts}
          getRowKey={getRowKey}
          storageName='stockOption.buyouts.list'
        />
      </Panel>
      {buyoutCreationDialog.isOpen && (
        <StockOptionBuyoutCreationDialog userId={user.id} onClose={buyoutCreationDialog.close} />
      )}
    </>
  );
}
