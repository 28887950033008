import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Vacations',
    description: '[title] Vacation balance title',
  },
  availableDays: {
    defaultMessage: 'Days available',
    description: '[title] Vacation balance value label',
  },
  plannedDays: {
    defaultMessage: 'Days requested',
    description: '[title] Vacation balance value label',
  },
  requestButton: {
    defaultMessage: 'Request time off{isSelfUser, select, true {} other { on behalf}}',
    description: '[button] Vacation balance: request',
  },
  historyLink: {
    defaultMessage: 'History',
    description: 'Vacation balance: history link',
  },
  details: {
    defaultMessage: 'Details',
    description: 'Vacation balance: details link',
  },
  hint: {
    defaultMessage: 'Your upcoming vacations will be subtracted when your time off starts',
    description: 'Vacation balance: calculation hint',
  },
});
