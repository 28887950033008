import React from 'react';
import cn from 'classnames';
import {StickySide} from '../../types';
import styles from './index.css';

type Props = {
  offset: number;
  side: StickySide;
  visible: boolean;
};
export function StickyBorder({offset, side, visible}: Props) {
  if (!offset) {
    return null;
  }
  return (
    <div
      className={cn(styles.stickyBorder, {
        [styles.visible]: visible,
        [styles.right]: side === 'right',
      })}
      style={{[side]: offset}}
    />
  );
}
