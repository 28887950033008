import {generatePath} from 'react-router-dom';

export const rtconfUrlPatterns = {
  root: '/rtconf',
  item: '/rtconf/:itemKey',
  createItem: '/rtconf/create',
};

export const rtconfUrls = {
  root: () => rtconfUrlPatterns.root,
  item: (pathParams: {itemKey: string}) => generatePath(rtconfUrlPatterns.item, pathParams),
  create: () => rtconfUrlPatterns.createItem,
};
