import {FieldValidator} from 'final-form';
import {ValidationFunction, ValidationOptions} from '../types';

/**
 * FieldValidator signature is incompatible with our custom ValidationFunction signature.
 * FieldValidator expects all form values as a second argument, while ValidationFunction
 * expects validator options as a second argument.
 * This utility makes converts ValidationFunction to FieldValidator.
 */
export function getFieldValidator<FieldValue>(
  validate: ValidationFunction<FieldValue, ValidationOptions, unknown>,
): FieldValidator<FieldValue> {
  const fieldValidator: FieldValidator<FieldValue> = (value) => validate(value);

  return fieldValidator;
}
