import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {FieldInputNumber, FieldSelect, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {FieldCurrency} from 'domain/currency/widgets/Field';
import {availableBonusFrequencies} from 'domain/frequency/model/availableValues';
import {FieldFrequency} from 'domain/frequency/widgets/Field';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {OfferFormSectionTitle} from 'domain/offer/widgets/FormSectionTitle';
import {RegularBonusType} from 'domain/regularBonusRecord/model/type';
import {formatRegularBonusType} from 'domain/regularBonusRecord/model/type/formatter';
import React, {useCallback} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels, messages} from './messages';

type Props = {
  compensation?: OfferCompensation;
};

export function OfferRegularBonusFields({compensation}: Props) {
  const intl = useIntl();
  const typeToString = useCallback((type: RegularBonusType) => formatRegularBonusType(type, intl), [intl]);
  const {getState} = useForm();
  const type = getState().values.compensation?.regularBonus?.type;

  return (
    <>
      <OfferFormSectionTitle title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item xl={12}>
            <FieldSelect<RegularBonusType>
              name='compensation.regularBonus.type'
              label={intl.formatMessage(labels.type)}
              items={getEnumValues(RegularBonusType)}
              itemToString={typeToString}
              renderItem={typeToString}
              initialValue={compensation?.regularBonus?.type}
              reserveSpaceForError={false}
              clearable
            />
          </Grid.Item>
          {type === RegularBonusType.ABSOLUTE && (
            <>
              <Grid.Item xl={4} xs={12}>
                <FieldInputNumber
                  name='compensation.regularBonus.value.absoluteValue.amount'
                  label={intl.formatMessage(labels.amount)}
                  required
                  min={0}
                  step={1000}
                  initialValue={compensation?.regularBonus?.value.absoluteValue?.amount}
                  reserveSpaceForError={false}
                />
              </Grid.Item>
              <Grid.Item xl={4} xs={12}>
                <FieldCurrency
                  name='compensation.regularBonus.value.absoluteValue.currency'
                  label={intl.formatMessage(labels.currency)}
                  required
                  validate={validateFilled}
                  initialValue={compensation?.regularBonus?.value.absoluteValue?.currency}
                  reserveSpaceForError={false}
                />
              </Grid.Item>
            </>
          )}
          {type === RegularBonusType.PERCENT && (
            <>
              <Grid.Item xl={6} xs={12}>
                <FieldInputNumber
                  name='compensation.regularBonus.value.percentValue.amount'
                  label={intl.formatMessage(labels.amount)}
                  required
                  min={0}
                  step={0.01}
                  initialValue={compensation?.regularBonus?.value.percentValue?.amount}
                  reserveSpaceForError={false}
                />
              </Grid.Item>
            </>
          )}
          {type && (
            <>
              <Grid.Item xl={type === RegularBonusType.PERCENT ? 6 : 4} xs={12}>
                <FieldFrequency
                  name='compensation.regularBonus.frequency'
                  label={intl.formatMessage(labels.frequency)}
                  required
                  validate={validateFilled}
                  initialValue={compensation?.regularBonus?.frequency}
                  items={availableBonusFrequencies}
                  reserveSpaceForError={false}
                />
              </Grid.Item>
              <Grid.Item xl={12}>
                <FieldTextarea
                  name='compensation.regularBonus.comment'
                  label={intl.formatMessage(labels.comment)}
                  rows={2}
                  initialValue={compensation?.regularBonus?.comment}
                  parse={identity}
                  reserveSpaceForError={false}
                />
              </Grid.Item>
            </>
          )}
        </Grid>
      </Panel.Content>
    </>
  );
}
