import {ValidationFunction, ValidationOptions} from '../types';

type Vo = ValidationOptions;
type Vf<V, O extends Vo, E> = ValidationFunction<V, O, E>;

export function composeValidators<V1, O1 extends Vo, E1>(Vr1?: Vf<V1, O1, E1>): Vf<V1, O1, E1>;
export function composeValidators<V1, O1 extends Vo, E1, V2, O2 extends Vo, E2>(
  Vr1: Vf<V1, O1, E1>,
  Vr2: Vf<V2, O2, E2>,
): Vf<V1, O1, E1> | Vf<V2, O2, E2>;
export function composeValidators<V1, O1 extends Vo, E1, V2, O2 extends Vo, E2, V3, O3 extends Vo, E3>(
  Vr1: Vf<V1, O1, E1>,
  Vr2: Vf<V2, O2, E2>,
  Vr3: Vf<V3, O3, E3>,
): Vf<V1, O1, E1> | Vf<V2, O2, E2> | Vf<V3, O3, E3>;
export function composeValidators<
  V1,
  O1 extends Vo,
  E1,
  V2,
  O2 extends Vo,
  E2,
  V3,
  O3 extends Vo,
  E3,
  V4,
  O4 extends Vo,
  E4,
>(
  Vr1: Vf<V1, O1, E1>,
  Vr2: Vf<V2, O2, E2>,
  Vr3: Vf<V3, O3, E3>,
  Vr4: Vf<V4, O4, E4>,
): Vf<V1, O1, E1> | Vf<V2, O2, E2> | Vf<V3, O3, E3> | Vf<V4, O4, E4>;
export function composeValidators<
  V1,
  O1 extends Vo,
  E1,
  V2,
  O2 extends Vo,
  E2,
  V3,
  O3 extends Vo,
  E3,
  V4,
  O4 extends Vo,
  E4,
  V5,
  O5 extends Vo,
  E5,
>(
  Vr1: Vf<V1, O1, E1>,
  Vr2: Vf<V2, O2, E2>,
  Vr3: Vf<V3, O3, E3>,
  Vr4: Vf<V4, O4, E4>,
  Vr5: Vf<V5, O5, E5>,
): Vf<V1, O1, E1> | Vf<V2, O2, E2> | Vf<V3, O3, E3> | Vf<V4, O4, E4> | Vf<V5, O5, E5>;

export function composeValidators<V, O extends Vo, E>(...validators: Vf<V, O, E>[]): Vf<V, O, E> {
  return async (value: V, options: O) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const validate of validators) {
      const result = validate(value, options);

      if (result) {
        // eslint-disable-next-line no-await-in-loop
        const error = result instanceof Promise ? await result : result;
        if (error) {
          return error;
        }
      }
    }
    return undefined;
  };
}
