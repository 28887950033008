import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {SalaryRangesVersion} from 'domain/salaryRange/model/version';
import {getSalaryRangesVersionsFx} from 'domain/salaryRange/stores/versions';
import {$salaryRangesVersions} from 'domain/salaryRange/stores/versions/state';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: SalaryRangesVersion[];
  dataState: DataState;
};

export function useSalaryRangesVersions(): ReturnShape {
  const {data, dataState} = useStore($salaryRangesVersions);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      getSalaryRangesVersionsFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return {
    data,
    dataState,
  };
}
