import {ReceiptCard} from 'domain/compensations/receipt/widgets/Card';
import {CompensationReport} from 'domain/compensations/report/model';
import {CompensationReportFile} from 'domain/compensations/report/widgets/File';
import {CompensationRequestId} from 'domain/compensations/request/model';
import {Permission} from 'domain/permission/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  compensationReport: CompensationReport;
  compensationRequestId: CompensationRequestId;
  isApprover: boolean;
  isOwner: boolean;
};

export function CompensationReportSummary({
  compensationReport: {boardingPasses, hasNoFlights, receipts},
  compensationRequestId,
  isApprover,
  isOwner,
}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  const isDownloadAdmin = acl.hasPermission(Permission.COMPENSATION_DOWNLOAD_ALL);
  const canDownloadFiles = isOwner || isApprover || isDownloadAdmin;

  const renderBoardingPasses = () => {
    if (hasNoFlights) {
      return <div className={styles.empty}>{intl.formatMessage(messages.noFlights)}</div>;
    }
    if (!boardingPasses.length) {
      return <div className={styles.empty}>{intl.formatMessage(messages.noBoardingPasses)}</div>;
    }
    return boardingPasses.map((boardingPass) => (
      <div key={boardingPass.id}>
        <CompensationReportFile
          compensationRequestId={compensationRequestId}
          downloadable={canDownloadFiles}
          fileMeta={boardingPass}
        />
      </div>
    ));
  };

  return (
    <div className={styles.root}>
      <div className={styles.propertyLabel}>{intl.formatMessage(messages.boardingPasses)}</div>
      {renderBoardingPasses()}
      <div className={styles.propertyLabel}>{intl.formatMessage(messages.expenses)}</div>
      {receipts.length ? (
        receipts.map((receipt, index) => (
          <ReceiptCard
            canDownloadFiles={canDownloadFiles}
            compensationRequestId={compensationRequestId}
            receipt={receipt}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))
      ) : (
        <div className={styles.empty}>{intl.formatMessage(messages.noExpenses)}</div>
      )}
    </div>
  );
}
