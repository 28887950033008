import {CommonFieldProps, FieldSelect} from '@joomcode/joom-form';
import {OneTimeBonusType} from 'domain/oneTimeBonusRecord/model/type';
import {availableValues} from 'domain/oneTimeBonusRecord/model/type/availableValues';
import {formatOneTimeBonusType} from 'domain/oneTimeBonusRecord/model/type/formatter';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

type Props = CommonFieldProps<OneTimeBonusType> & {
  items?: OneTimeBonusType[];
};

export function FieldOneTimeBonusType({items = availableValues, ...props}: Props) {
  const intl = useIntl();
  const typeToString = useCallback((type: OneTimeBonusType) => formatOneTimeBonusType(type, intl), [intl]);

  return (
    <FieldSelect<OneTimeBonusType> items={items} itemToString={typeToString} renderItem={typeToString} {...props} />
  );
}
