import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  startDate: {
    defaultMessage: 'Start date',
    description: 'Time off request filter label',
  },
  endDate: {
    defaultMessage: 'End date',
    description: 'Time off request filter label',
  },
  legalEntityId: {
    defaultMessage: 'Legal entities',
    description: 'Time off request filter label',
  },
  type: {
    defaultMessage: 'Type',
    description: 'Time off request filter label',
  },
  status: {
    defaultMessage: 'Status',
    description: 'Time off request filter label',
  },
  userId: {
    defaultMessage: 'Joomer',
    description: 'Time off request filter label',
  },
  userFilterLabel: {
    defaultMessage: '{fullName} ({login})',
    description: 'Time off filters: User autocomplete item label',
  },
  recordedInAccountingSystem: {
    defaultMessage: 'Recorded',
    description: 'Time off request filter label',
  },
});
