import {
  array,
  boolean,
  ExtractSchemaType,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {countryCodeSchema} from 'domain/countryCode/model';
import {OfferStatus} from 'domain/offer/model/status';

export const offersSearchFiltersSchema = object({
  divisions: optional(array(string())),
  countriesInclude: optional(array(countryCodeSchema)),
  countriesExclude: optional(array(countryCodeSchema)),
  statuses: optional(array(oneOfEnum(OfferStatus))),
  hideEmployed: optional(boolean()),
});

export type OffersSearchFilters = Partial<ExtractSchemaType<typeof offersSearchFiltersSchema>>;
