import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {JobInfoRecord, JobInfoRecordId} from 'domain/jobInfoRecord/model';
import {JobInfoRecordMenu} from 'domain/jobInfoRecord/widgets/Menu';
import {UserLink} from 'domain/user/widgets/Link';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {columnNames, messages} from './messages';
import styles from './styles.css';

export type Props = {
  dataState: DataState;
  jobInfoRecords: JobInfoRecord[];
  onDeleteItem?: (jobInfoRecordId: JobInfoRecordId) => void;
  onEditItem?: (jobInfoRecord: JobInfoRecord) => void;
};

const getRowClassName = (jobInfoRecord: JobInfoRecord) => (jobInfoRecord.active ? '' : styles.inactiveRow);
const getRowKey = (jobInfoRecord: JobInfoRecord) => jobInfoRecord.id;

export function JobInfoTable({dataState, jobInfoRecords, onDeleteItem, onEditItem}: Props) {
  const intl = useIntl();
  const columns: Column<JobInfoRecord>[] = useMemo(() => {
    const basicColumns: Column<JobInfoRecord>[] = [
      {
        defaultWidth: 10,
        id: 'effectiveDate',
        name: intl.formatMessage(columnNames.effectiveDate),
        noWrap: true,
        render: ({effectiveDate}) => <HumanDate value={effectiveDate} format='short' utc />,
      },
      {
        defaultWidth: 15,
        id: 'location',
        name: intl.formatMessage(columnNames.location),
        render: ({location}) => location,
      },
      {
        defaultWidth: 10,
        id: 'division',
        name: intl.formatMessage(columnNames.division),
        render: ({division}) => division,
      },
      {
        defaultWidth: 15,
        id: 'department',
        name: intl.formatMessage(columnNames.department),
        render: ({department}) => department,
      },
      {
        defaultWidth: 15,
        id: 'jobTitle',
        name: intl.formatMessage(columnNames.jobTitle),
        render: ({jobTitle}) => jobTitle,
      },
      {
        defaultWidth: 15,
        id: 'manager',
        name: intl.formatMessage(columnNames.manager),
        render: ({manager}) => (manager ? <UserLink user={manager} withAvatar={false} /> : <DataTable.CellEmpty />),
      },
    ];

    return onDeleteItem || onEditItem
      ? [
          ...basicColumns,
          {
            defaultWidth: 5,
            id: 'edit',
            name: '',
            render: (record) =>
              record.editable && (
                <JobInfoRecordMenu jobInfoRecord={record} onDelete={onDeleteItem} onEdit={onEditItem} />
              ),
          },
        ]
      : basicColumns;
  }, [onEditItem, intl]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={jobInfoRecords}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.fail)}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      storageName='jobInfoRecordsList'
    />
  );
}
