import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import {ListedProperty} from 'components/ui/ListedProperty';
import {MultilineText} from 'components/ui/MultilineText';
import {OfferId} from 'domain/offer/model';
import {NewcomerInOffer} from 'domain/offer/model/newcomer';
import {computerTypeLabels} from 'domain/offer/model/newcomer/computerType/messages';
import {extraHardwareLabels} from 'domain/offer/model/newcomer/extraHardware/messages';
import {operatingSystemLabels} from 'domain/offer/model/newcomer/operatingSystem/messages';
import {OfferStatus} from 'domain/offer/model/status';
import {approveNewcomerApplicationFx} from 'domain/offer/stores/main';
import {Permission} from 'domain/permission/model';
import {shirtSizeLabels} from 'domain/shirtSize/model/messages';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {LoginForm} from './LoginForm';
import {labels, messages} from './messages';
import {NewcomerPhoto} from './NewcomerPhoto';
import {StartWorkingDateForm} from './StartWorkingDateForm';
import styles from './styles.css';

type Props = {
  isChanging?: boolean;
  newcomer: NewcomerInOffer;
  offerId: OfferId;
  offerStatus: OfferStatus;
};

export function NewcomerProfilePanel({
  isChanging,
  newcomer: {
    avatar,
    computerInfo,
    computerType,
    extraHardware,
    firstName,
    hardwareNotes,
    lastName,
    login,
    operatingSystem,
    placeOfFirstWorkingDay,
    shirtSize,
    startWorkingDate,
  },
  offerId,
  offerStatus,
}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const editDisabledHint = intl.formatMessage(messages.editDisabledHint);
  const digitizationRequired = offerStatus === OfferStatus.REVIEW;
  const approvalDisabled = isChanging || digitizationRequired;
  const approvalDisabledMessage = digitizationRequired
    ? messages.disabledHintNotDigitized
    : messages.disabledHintChanging;

  const approve = useAsyncTask(
    async () => approveNewcomerApplicationFx(offerId).catch(toaster.interceptThenThrowError),
    [offerId],
  );
  const onApproveClick = useCallback(
    () =>
      confirm(
        {
          title: intl.formatMessage(messages.confirmApproveTitle),
          text: intl.formatMessage(messages.confirmApproveText),
          confirmationText: intl.formatMessage(messages.approveButton),
          onConfirm: approve.perform,
        },
        intl,
      ),
    [approve, intl],
  );

  return (
    <Panel
      withPadding
      title={intl.formatMessage(messages.title)}
      toolbarTop={
        acl.hasPermission(Permission.OFFER_WRITE) &&
        [OfferStatus.REVIEW, OfferStatus.DIGITIZED].includes(offerStatus) && (
          <TooltipAlt content={intl.formatMessage(approvalDisabledMessage)} enabled={approvalDisabled}>
            {/* extra wrap fixes tippy */}
            <span>
              <Button
                kind='primary'
                intent='neutral'
                loading={approve.isPerforming}
                onClick={onApproveClick}
                size='m'
                disabled={approvalDisabled}
              >
                {intl.formatMessage(messages.approveButton)}
              </Button>
            </span>
          </TooltipAlt>
        )
      }
    >
      <div className={styles.root}>
        {avatar && (
          <div className={styles.photo}>
            <NewcomerPhoto offerId={offerId} imageBundle={avatar} />
          </div>
        )}
        <div className={styles.properties}>
          <div className={styles.general}>
            {firstName && <ListedProperty label={intl.formatMessage(labels.firstName)}>{firstName}</ListedProperty>}
            {lastName && <ListedProperty label={intl.formatMessage(labels.lastName)}>{lastName}</ListedProperty>}
            {startWorkingDate && (
              <ListedProperty label={intl.formatMessage(labels.startWorkingDate)}>
                {[OfferStatus.REVIEW, OfferStatus.DIGITIZED, OfferStatus.COMPLETED].includes(offerStatus) &&
                acl.hasPermission(Permission.OFFER_WRITE) ? (
                  <StartWorkingDateForm
                    initialValue={startWorkingDate}
                    disabled={isChanging}
                    disabledHint={editDisabledHint}
                    offerId={offerId}
                  />
                ) : (
                  <HumanDate format='long' value={startWorkingDate} utc />
                )}
              </ListedProperty>
            )}
            {placeOfFirstWorkingDay && (
              <ListedProperty label={intl.formatMessage(labels.placeOfFirstWorkingDay)}>
                {placeOfFirstWorkingDay}
              </ListedProperty>
            )}
            {login && (
              <ListedProperty label={intl.formatMessage(labels.login)}>
                {[OfferStatus.REVIEW, OfferStatus.DIGITIZED].includes(offerStatus) &&
                acl.hasPermission(Permission.OFFER_WRITE) ? (
                  <LoginForm
                    initialValue={login}
                    disabled={isChanging}
                    disabledHint={editDisabledHint}
                    offerId={offerId}
                  />
                ) : (
                  login
                )}
              </ListedProperty>
            )}
            {shirtSize && (
              <ListedProperty label={intl.formatMessage(labels.shirtSize)}>
                {intl.formatMessage(shirtSizeLabels[shirtSize])}
              </ListedProperty>
            )}
          </div>
          <div className={styles.equipment}>
            {computerType && (
              <ListedProperty label={intl.formatMessage(labels.computerType)}>
                {intl.formatMessage(computerTypeLabels[computerType])}
              </ListedProperty>
            )}
            {computerInfo && (
              <ListedProperty label={intl.formatMessage(labels.computerInfo)}>{computerInfo}</ListedProperty>
            )}
            {operatingSystem && (
              <ListedProperty label={intl.formatMessage(labels.operatingSystem)}>
                {intl.formatMessage(operatingSystemLabels[operatingSystem])}
              </ListedProperty>
            )}
            {extraHardware?.length ? (
              <ListedProperty label={intl.formatMessage(labels.extraHardware)}>
                {intl.formatList(
                  extraHardware.map((hardware) => intl.formatMessage(extraHardwareLabels[hardware])),
                  {type: 'conjunction', style: 'long'},
                )}
              </ListedProperty>
            ) : null}
            {hardwareNotes && (
              <ListedProperty label={intl.formatMessage(labels.hardwareNotes)}>
                <MultilineText text={hardwareNotes} />
              </ListedProperty>
            )}
          </div>
        </div>
      </div>
    </Panel>
  );
}
