import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {
  boolean,
  ExtractSchemaType,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {formattedDateSchema} from 'models/system/formattedDate';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {OfficePolicy} from './policy';

export const officePolicyRecordIdSchema = idSchema;
export const officePolicyRecordSchema = object({
  active: optionalWithDefault(false, boolean()),
  comment: optional(string()),
  effectiveDate: formattedDateSchema,
  id: officePolicyRecordIdSchema,
  policy: oneOfEnum(OfficePolicy),
});

export type OfficePolicyRecordId = ExtractSchemaType<typeof officePolicyRecordIdSchema>;
export type OfficePolicyRecord = ExtractSchemaType<typeof officePolicyRecordSchema>;
