import {SecurePermission} from 'domain/permission/model/secure';
import {$self} from 'domain/self/stores/main/state';
import {StockOptionReadAccess} from 'domain/stockOption/holder/model/access';
import {UserId} from 'domain/user/model';
import {useStore} from 'effector-react';
import {useAcl} from 'services/acl';

type Options = {
  userId?: UserId;
  userAncestorIds?: UserId[];
};

export const useStockOptionReadAccess = ({userId, userAncestorIds = []}: Options): StockOptionReadAccess | null => {
  const acl = useAcl();
  const {userId: selfUserId, isStockOptionHolder} = useStore($self);

  if (!userId) {
    return null;
  }

  const canReadSubordinate = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_SUBORDINATE);
  const canReadAny = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_ANY);

  if (canReadAny) {
    return StockOptionReadAccess.ANY;
  }

  if (canReadSubordinate && selfUserId && userAncestorIds.includes(selfUserId)) {
    return StockOptionReadAccess.SUBORDINATE;
  }

  if (userId === selfUserId && isStockOptionHolder) {
    return StockOptionReadAccess.MY;
  }

  return null;
};
