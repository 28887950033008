import React from 'react';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../../create-locator';
import styles from './index.css';

export {Button} from './Button';
export {Link} from './Link';

export type NavbarItemTestId = ConvertLocatorToTestId<NavbarItemLocator>;
export type NavbarItemLocator = Locator<void>;

type Props = {
  children: React.ReactNode;
} & Partial<Mark<NavbarItemLocator>>;

export function Item({children, ...restProps}: Props) {
  const locator = createLocator(restProps);

  return (
    <div className={styles.item} {...locator()}>
      {children}
    </div>
  );
}
