import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {Panel} from '@joomcode/joom-ui/Panel';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {EnhanceOfferDiff} from 'domain/offer/api/enhance';
import {useOffer} from 'domain/offer/hooks/useOffer';
import {offerIdSchema} from 'domain/offer/model';
import {enhanceOfferFx} from 'domain/offer/stores/main';
import {CorruptedDataPanel} from 'domain/offer/widgets/CorruptedDataPanel';
import {OfferEnhanceForm} from 'domain/offer/widgets/EnhanceForm';
import {NotFoundPage} from 'pages/NotFound';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {pageTitles} from 'routes/offer/titles';
import {offerUrls} from 'routes/offer/urls';
import {toaster} from 'services/toaster';
import styles from './styles.css';

export function OfferEnhancePage() {
  const intl = useIntl();
  const history = useHistory();
  const {id: offerId} = useTypedParams({id: offerIdSchema});
  const {clientErrorStatus, data, dataState} = useOffer(offerId);

  const onSubmit = useCallback(
    (diff: EnhanceOfferDiff) => {
      if (!offerId) {
        return Promise.reject().catch(() => {});
      }
      return enhanceOfferFx({id: offerId, diff})
        .then(({id}) => {
          history.push(offerUrls.offer({id}));
        })
        .catch(toaster.interceptThenThrowError);
    },
    [offerId, history],
  );

  if (!offerId) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={data} entityError={clientErrorStatus} state={dataState}>
      {({id, documentsData, versions, newcomer, general, nameToken, jobContract}) => {
        const lastVersion = versions.at(-1);

        return newcomer?.documents && lastVersion ? (
          <Page
            breadcrumbs={[
              {
                title: intl.formatMessage(pageTitles.offers),
                href: offerUrls.root(),
              },
              {
                title: nameToken,
                href: offerUrls.offer({id}),
              },
            ]}
            title={intl.formatMessage(pageTitles.enhance)}
          >
            {lastVersion ? (
              <Panel className={styles.panel}>
                <OfferEnhanceForm
                  id={id}
                  documents={newcomer.documents}
                  documentsData={documentsData}
                  address={newcomer.placeOfCurrentResidence}
                  onSubmit={onSubmit}
                  jobContract={jobContract}
                  hasFurtherSalary={lastVersion.hasFurtherSalary}
                  country={general.templateCountry}
                />
              </Panel>
            ) : (
              <CorruptedDataPanel />
            )}
          </Page>
        ) : (
          <NotFoundPage />
        );
      }}
    </EntityPageStateHandler>
  );
}
