import {useState, useCallback} from 'react';
import {useUpdateEffect} from '../../react/useUpdateEffect';
import {OffsetPagination} from './types';

type Props = {
  initialLimit: number;
  initialPage: number;
  onLimitChange?: (limit: number) => void;
  onPageChange?: (page: number) => void;
};

export function useOffsetPagination({initialLimit, initialPage, onLimitChange, onPageChange}: Props): OffsetPagination {
  const [limit, setLimit] = useState(initialLimit);
  const [page, setPage] = useState(initialPage);

  useUpdateEffect(() => {
    if (typeof onLimitChange === 'function') {
      onLimitChange(limit);
    }
  }, [limit, onLimitChange]);

  useUpdateEffect(() => {
    if (typeof onPageChange === 'function') {
      onPageChange(page);
    }
  }, [page, onPageChange]);

  return {
    limit,
    offset: (page - 1) * limit,
    page,
    setLimit: useCallback((nextLimit) => {
      setLimit(nextLimit);
      setPage(1);
    }, []),
    setPage,
  };
}
