import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {Currency, getCurrencyName} from 'domain/currency/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

export type Props = {
  clearable?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange: (currency: Currency | null) => void;
  onFocus?: () => void;
  options?: Currency[];
  value: Currency | null;
};

export function CurrencySelect({value, ...restProps}: Props) {
  const intl = useIntl();
  const currencyToString = useCallback(
    (currency: Currency) => `${currency} — ${getCurrencyName(currency, intl)}`,
    [intl],
  );
  return (
    <SimpleAutocomplete<Currency>
      items={getEnumValues(Currency)}
      getItemKey={identity}
      itemToString={currencyToString}
      renderItem={currencyToString}
      value={value || null}
      {...restProps}
    />
  );
}
