import React from 'react';
import {ReactComponent as WarningIcon} from '../../icons/circle/warning.svg';
import {ReactComponent as CheckmarkIcon} from '../../icons/circle/checkmark.svg';
import {ReactComponent as CrossIcon} from '../../icons/circle/cross.svg';
import {ReactComponent as WatchIcon} from '../../icons/circle/watch.svg';
import {ReactComponent as InfoIcon} from '../../icons/circle/info.svg';
import {CalloutIntent} from './calloutIntent';

export const intentToIcon = (intent: CalloutIntent): React.ReactNode => {
  switch (intent) {
    case 'negative':
      return <CrossIcon />;
    case 'neutral':
      return <WatchIcon />;
    case 'positive':
      return <CheckmarkIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'primary':
    default:
      return <InfoIcon />;
  }
};
