import {SecureRoleGrantParams} from 'domain/secureRole/api/addToUser';
import {SecureRoleRemoveParams} from 'domain/secureRole/api/removeFromUser';
import {SecureRoleUpdateConfig} from 'domain/secureRole/api/update';
import {SecureRole} from 'domain/secureRole/model';
import {User} from 'domain/user/model';
import {createEffect} from 'effector';

export const loadAllSecureRolesFx = createEffect<void, SecureRole[]>('get all secure roles');
export const updateSecureRoleFx = createEffect<SecureRoleUpdateConfig, SecureRole>('update secure role');
export const addSecureRoleToUserFx = createEffect<SecureRoleGrantParams, User>('add secure role to user');
export const removeSecureRoleFromUserFx = createEffect<SecureRoleRemoveParams, User>('remove secure role from user');
