import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages({
  [ColumnId.AMOUNT]: {
    defaultMessage: 'Options',
    description: 'Stock option buyouts table column name',
  },
  [ColumnId.DATE]: {
    defaultMessage: 'Date',
    description: 'Stock option buyouts table column name',
  },
  [ColumnId.ISSUER]: {
    defaultMessage: 'Issuer',
    description: 'Stock option buyouts table column name',
  },
  [ColumnId.DOCUMENTS_STATUS]: {
    defaultMessage: 'Documents status',
    description: 'Stock option buyouts table column name',
  },
});

export const messages = defineMessages({
  title: {
    defaultMessage: 'Buyouts',
    description: '[title] Stock option buyouts panel: title',
  },
  btnCreate: {
    defaultMessage: 'Add buyout',
    description: '[button] Stock option buyouts panel: buyout creation button',
  },
});
