import {ReactComponent as CircleCrossIcon} from '@joomcode/joom-ui/icons/circle/cross.svg';
import {Input} from '@joomcode/joom-ui/Input';
import React, {PropsWithoutRef} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  onClear: () => void;
};

export const ClearableInput = ({onClear, ...props}: Props) => {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <Input {...props} />
      {Boolean(props.value) && (
        <button
          aria-label={intl.formatMessage(messages.clearAriaLabel)}
          className={styles.clearButton}
          onClick={onClear}
          type='button'
        >
          <CircleCrossIcon />
        </button>
      )}
    </div>
  );
};
