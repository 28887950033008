import {Menu} from '@joomcode/joom-ui/Menu';
import {MenuButton} from 'components/ui/MenuButton';
import {MarketDataRecord, MarketDataRecordId} from 'domain/marketDataRecord/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export type Props = {
  record: MarketDataRecord;
  onDelete?: (id: MarketDataRecordId) => void;
  onEdit?: (record: MarketDataRecord) => void;
};

export function MarketDataRecordMenu({record, onDelete, onEdit}: Props) {
  const intl = useIntl();

  return (
    <Menu ariaLabel={intl.formatMessage(messages.menuAriaLabel)} disclosure={<MenuButton />}>
      {onEdit && <Menu.Item onClick={() => onEdit(record)}>{intl.formatMessage(messages.edit)}</Menu.Item>}
      {onDelete && <Menu.Item onClick={() => onDelete(record.id)}>{intl.formatMessage(messages.delete)}</Menu.Item>}
    </Menu>
  );
}
