import type {Column} from '../types';

type Options<T> = {
  isFlexible(column: Column<T>): boolean;
  hasContent(column: Column<T>): boolean;
};

type FlexibleColumns<T> = {
  columns: Column<T>[];
  getCellColSpan(column: Column<unknown>): number | undefined;
};

export function getFlexibleColumns<T>(columns: Column<T>[], {hasContent, isFlexible}: Options<T>): FlexibleColumns<T> {
  const columnMap = new Map<Column<T>, number>();
  let lastFlexColumn: Column<T> | null = null;

  columns.forEach((column) => {
    if (isFlexible(column)) {
      columnMap.set(column, 1);
      lastFlexColumn = column;
      return;
    }

    if (lastFlexColumn && !hasContent(column)) {
      columnMap.set(lastFlexColumn, (columnMap.get(lastFlexColumn) || 0) + 1);
      return;
    }

    lastFlexColumn = null;
  });

  const subColumns: Column<unknown>[] = [];

  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    const shift = columnMap.get(column);
    subColumns.push(column);
    i += shift ? shift - 1 : 0;
  }

  return {
    columns: subColumns,
    getCellColSpan: (column) => columnMap.get(column),
  };
}
