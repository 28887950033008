import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {BulkUploadRowStatusIcon} from 'components/widgets/BulkUploadRowStatusIcon';
import {FinancialBulkUploadRow} from 'domain/financialRecord/model';
import {ReplacedValue} from 'domain/financialRecord/widgets/ReplacedValue';
import {formatFrequency} from 'domain/frequency/model/formatter';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {FormattedRegularBonusValue} from 'domain/regularBonusRecord/widgets/FormattedValue';
import {UserLink} from 'domain/user/widgets/Link';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

export type Props = {
  rows: FinancialBulkUploadRow<RegularBonusRecord>[];
};

const getRowKey = ({meta}: FinancialBulkUploadRow<RegularBonusRecord>) => meta.row.toString();

export function RegularBonusUploadPanel({rows}: Props) {
  const intl = useIntl();
  const columns: Column<FinancialBulkUploadRow<RegularBonusRecord>>[] = useMemo(
    () => [
      {
        defaultWidth: 5,
        id: ColumnId.PARSING_STATUS,
        name: intl.formatMessage(columnNames[ColumnId.PARSING_STATUS]),
        render: ({meta}) => <BulkUploadRowStatusIcon meta={meta} />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.ROW_NUMBER,
        name: intl.formatMessage(columnNames[ColumnId.ROW_NUMBER]),
        render: ({meta}) => meta.row,
      },
      {
        defaultWidth: 12,
        id: ColumnId.USER,
        name: intl.formatMessage(columnNames[ColumnId.USER]),
        render: ({user}) => (user ? <UserLink user={user} colored withAvatar={false} /> : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: 13,
        id: ColumnId.EMAIL,
        name: intl.formatMessage(columnNames[ColumnId.EMAIL]),
        render: ({user}) => user?.email ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 7,
        id: ColumnId.START_DATE,
        name: intl.formatMessage(columnNames[ColumnId.START_DATE]),
        render: ({record, oldRecord}) => (
          <ReplacedValue
            newValue={record?.startDate}
            oldValue={oldRecord?.startDate}
            render={(value) => <HumanDate value={value} format='short' utc />}
          />
        ),
      },
      {
        defaultWidth: 7,
        id: ColumnId.END_DATE,
        name: intl.formatMessage(columnNames[ColumnId.END_DATE]),
        render: ({record, oldRecord}) => (
          <ReplacedValue
            newValue={record?.endDate}
            oldValue={oldRecord?.endDate}
            render={(value) => <HumanDate value={value} format='short' utc />}
            showOldValue={false}
          />
        ),
      },
      {
        align: 'right',
        defaultWidth: 14,
        id: ColumnId.AMOUNT,
        name: intl.formatMessage(columnNames[ColumnId.AMOUNT]),
        render: ({record, oldRecord}) => (
          <ReplacedValue
            newValue={record?.value}
            oldValue={oldRecord?.value}
            render={(value) => <FormattedRegularBonusValue value={value} />}
          />
        ),
      },
      {
        defaultWidth: 7,
        id: ColumnId.FREQUENCY,
        name: intl.formatMessage(columnNames[ColumnId.FREQUENCY]),
        render: ({record, oldRecord}) => (
          <ReplacedValue
            newValue={record?.frequency}
            oldValue={oldRecord?.frequency}
            render={(frequency) => formatFrequency(frequency, intl)}
          />
        ),
      },
      {
        defaultWidth: 20,
        id: ColumnId.COMMENT,
        name: intl.formatMessage(columnNames[ColumnId.COMMENT]),
        render: ({record, oldRecord}) => <ReplacedValue newValue={record?.comment} oldValue={oldRecord?.comment} />,
      },
    ],
    [intl],
  );

  return (
    <Panel title={intl.formatMessage(messages.title)} withSeparator>
      <DataTable<FinancialBulkUploadRow<RegularBonusRecord>>
        columns={columns}
        dataState={DataState.LOADED}
        data={rows}
        getRowKey={getRowKey}
        storageName='financialRecord.regularBonus.list'
      />
    </Panel>
  );
}
