import {createStore} from 'effector';
import {setStockOptionValue} from '.';

type State = {
  stockOptionValue: number;
};

export const $stockOptionValue = createStore<State>({
  stockOptionValue: 4.6,
}).on(setStockOptionValue, (state, stockOptionValue) => ({stockOptionValue}));
