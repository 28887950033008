import debounce from 'lodash/debounce';
import {useCallback, useMemo} from 'react';
import {createColumnsConfigStorage} from '../storage';
import {ColumnsConfig} from '../types';

type Options = {
  columnsHash: string;
  storageName: string;
};

export function useStorableConfig({columnsHash, storageName}: Options) {
  const storage = useMemo(() => createColumnsConfigStorage(storageName), [storageName]);
  const config = useMemo<Partial<ColumnsConfig>>(() => {
    const restoredConfig = storage.restore();
    return restoredConfig && restoredConfig.columnsHash === columnsHash ? restoredConfig : {};
  }, [storage]);

  const persist = useCallback(debounce(storage.store.bind(storage), 500), [storage]);

  return {
    config,
    persist,
    storage,
  };
}
