import {isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {UserRole} from 'domain/userRole/model';
import {loadAllUserRolesFx, updateUserRoleFx} from 'domain/userRole/stores/main';
import {$userRoles} from 'domain/userRole/stores/main/state';
import {UserRoleForm} from 'domain/userRole/widgets/Form';
import {UserRoleIssue} from 'domain/userRole/widgets/Issue';
import {UserRoleOwners} from 'domain/userRole/widgets/Owners';
import {useStore} from 'effector-react';
import {NotFoundPage} from 'pages/NotFound';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {pageTitles} from 'routes/userRoles/titles';
import {userRolesUrls} from 'routes/userRoles/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';

export function UserRolePage() {
  const intl = useIntl();
  const {id} = useParams<{id: string}>();
  const {rolesById, rolesDataState} = useStore($userRoles);

  useEffect(() => {
    if (!isLoadingOrLoaded(rolesDataState)) {
      loadAllUserRolesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  const updateRole = (updatedRole: Partial<UserRole>) =>
    updateUserRoleFx({id, roleData: updatedRole})
      .then(() => {})
      .catch(toaster.interceptThenThrowError);

  return (
    <PageStateHandler data={rolesById} state={rolesDataState}>
      {(roles) => {
        const role = roles[id];
        if (role === undefined) {
          return <NotFoundPage />;
        }

        return (
          <Page
            breadcrumbs={[
              {
                title: intl.formatMessage(pageTitles.root),
                href: userRolesUrls.root(),
              },
            ]}
            title={intl.formatMessage(messages.title, {name: role.name})}
          >
            <UserRoleForm
              role={role}
              onSubmit={updateRole}
              submitButtonText={intl.formatMessage(messages.submitButtonText)}
            />
            <UserRoleIssue role={role} />
            <UserRoleOwners role={role} />
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
