import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Offer information',
    description: '[title] Offer form section title',
  },
});

export const labels = defineMessages({
  referrers: {
    defaultMessage: 'Referrers',
    description: 'Offer form field label',
  },
  priority: {
    defaultMessage: 'Priority',
    description: 'Offer form field label',
  },
  ticketLink: {
    defaultMessage: 'Ticket',
    description: 'Offer form field label',
  },
});
