import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {Service} from 'domain/service/model';
import {ServiceDiff} from 'domain/service/model/diff';
import {updateServiceFx} from 'domain/service/stores/main';
import {normalizeValues, ServiceForm} from 'domain/service/widgets/Form';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  service: Service;
  onClose: () => void;
};

export function ServiceUpdateDialog({service, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const onSubmit = useCallback(
    (diff: ServiceDiff) =>
      updateServiceFx({id: service.id, diff: normalizeValues(diff)})
        .then(onClose)
        .catch(toaster.interceptThenThrowError),
    [onClose],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 48rem)'>
      <Form<ServiceDiff> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <ServiceForm serviceToUpdate={service} formId={formId} onSubmit={handleSubmit} submitting={submitting} />
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
