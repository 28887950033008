import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    id: 'file-drop-area-aria-label',
    description: 'Message about selected files',
    defaultMessage: 'Put file to this area or click to upload it',
  },
  selectedFiles: {
    id: 'file-drop-area-selected-files',
    description: 'Message about selected files',
    defaultMessage: 'Selected files ({count})',
  },
});
