import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {PointInfo} from 'domain/officeMap/office/widgets/PointInfo';
import {OfficeSeat} from 'domain/officeMap/seat/model';
import {SeatNumberEdit} from 'domain/officeMap/seat/widgets/NumberEdit';
import {Permission} from 'domain/permission/model';
import {takeSeatFx} from 'domain/user/stores/main';
import React from 'react';
import {useIntl} from 'react-intl';
import {officeUrls} from 'routes/officeMap/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type FreeCardProps = {
  seat: OfficeSeat;
  selfHasSeat: boolean;
};

export function EmptySeatCard({seat, selfHasSeat}: FreeCardProps) {
  const acl = useAcl();
  const intl = useIntl();
  const task = useAsyncTask(() => takeSeatFx(seat.id).catch(toaster.interceptThenThrowError), [seat.id]);
  const canUpdateSeats = acl.hasPermission(Permission.OFFICE_MAP_SEAT_WRITE);
  const title = seat.number
    ? intl.formatMessage(messages.seatNumber, {number: seat.number})
    : intl.formatMessage(messages.seatWithoutNumber);

  return (
    <PointInfo
      copyIconLabel={intl.formatMessage(messages.copyIconLabel)}
      title={canUpdateSeats ? <SeatNumberEdit seatId={seat.id} seatNumber={seat.number} /> : title}
      pointUrl={officeUrls.seat({alias: seat.officeAlias}, seat)}
      button={
        <Button loading={task.isPerforming} onClick={task.perform} intent='neutral' kind='primary' size='m'>
          {selfHasSeat ? intl.formatMessage(messages.moveHere) : intl.formatMessage(messages.takeSeat)}
        </Button>
      }
    >
      <div className={styles.emptyMessage}>{intl.formatMessage(messages.emptySeat)}</div>
    </PointInfo>
  );
}
