import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {FormattedFileSize} from '@joomcode/joom-ui/FormattedFileSize';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import {offersApi} from 'domain/offer/api';
import {OfferId} from 'domain/offer/model';
import {FileMeta} from 'models/system/fileMeta';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export type Props = {
  fileMeta: FileMeta;
  offerId: OfferId;
};

export function NewcomerDocumentDownloadButton({fileMeta, offerId}: Props) {
  const intl = useIntl();
  return (
    <TooltipAlt
      content={intl.formatMessage(messages.downloadTooltip, {
        fileSize: <FormattedFileSize value={fileSizeUtils.createMeasure(fileMeta.size, FileSizeUnit.Byte)} />,
      })}
    >
      <DownloadLinkButton
        size='m'
        kind='text'
        intent='primary'
        href={offersApi.buildDocumentDownloadLink({offerId, fileId: fileMeta.id})}
        iconLeft={<DownloadIcon />}
        target='_blank'
      />
    </TooltipAlt>
  );
}
