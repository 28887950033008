import {FieldMetaState} from 'react-final-form';

type FieldMeta = FieldMetaState<unknown>;

export function getFieldError<T = string>(meta: FieldMeta): T | null {
  if (meta.touched && meta.error) {
    return meta.error;
  }

  if (!meta.dirtySinceLastSubmit && meta.submitError) {
    return meta.submitError;
  }

  return null;
}
