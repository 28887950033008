import {defineMessages} from 'react-intl';
import {FiscalSituation} from '.';

export const fiscalSituationLabels = defineMessages<FiscalSituation>({
  [FiscalSituation.RESIDENT]: {
    defaultMessage: 'Resident',
    description: 'Fiscal situation',
  },
  [FiscalSituation.NON_RESIDENT]: {
    defaultMessage: 'Non-Resident',
    description: 'Fiscal situation',
  },
  [FiscalSituation.NON_HABITUAL_RESIDENT]: {
    defaultMessage: 'Non-Habitual Resident',
    description: 'Fiscal situation',
  },
  [FiscalSituation.FORMER_NON_RESIDENT]: {
    defaultMessage: 'Former non-resident',
    description: 'Fiscal situation',
  },
});
