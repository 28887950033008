import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {Service} from 'domain/service/model';
import {getServiceByIdFx, getServicesFx, updateServiceFx} from '.';

export const $services = createEntityStore({
  getEntityId: (service: Service) => service.id,
  loadByIdFx: getServiceByIdFx,
  query: {
    fx: getServicesFx,
    getIdsFromParams: () => [],
  },
}).on(updateServiceFx.doneData, (state, result) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [result.id]: result,
    },
  };
});

export const $serviceList = createEntityListStore($services);
