import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  salaryErrors: {
    defaultMessage: 'Salary errors:',
    description: '[title] Salary errors list title',
  },
  regularBonusErrors: {
    defaultMessage: 'Bonus errors:',
    description: '[title] Regular bonus errors list title',
  },
  marketDataErrors: {
    defaultMessage: 'Function and level errors:',
    description: '[title] Function and level errors list title',
  },
});
