import {isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {Page} from '@joomcode/joom-ui/Page';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {buildAllRequestsDownloadLinkForAdmin} from 'domain/compensations/request/api/downloadFile';
import {
  CompensationRequestsFilterValues,
  compensationRequestsFilterValuesSchema,
} from 'domain/compensations/request/model/filters';
import {CompensationRequestsMode} from 'domain/compensations/request/model/mode';
import {CompensationRequestType, compensationRequestTypeSchema} from 'domain/compensations/request/model/type';
import {getAllRequestsByTypeFx, getAvailableRequestsByTypeFx} from 'domain/compensations/request/stores/main';
import {$compensationRequests} from 'domain/compensations/request/stores/main/state';
import {checkRequestFitsFilters} from 'domain/compensations/request/utils/checkRequestFitsFilters';
import {getAvailableFilters} from 'domain/compensations/request/utils/getAvailableFilters';
import {CompensationRequestFiltersPanel} from 'domain/compensations/request/widgets/FiltersPanel';
import {CompensationRequestsTable} from 'domain/compensations/request/widgets/Table';
import {Permission} from 'domain/permission/model';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {UserFull} from 'domain/user/model';
import {checkHasIndirectSubordinates} from 'domain/user/utils/checkHasIndirectSubordinates';
import {checkIsSubordinate} from 'domain/user/utils/checkIsSubordinate';
import {useStore} from 'effector-react';
import {useStoredFilters} from 'hooks/useStoredFilters';
import React, {useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps} from 'react-router-dom';
import {pageTitles} from 'routes/compensations/titles';
import {compensationsUrls} from 'routes/compensations/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import {ModeTabs} from './ModeTabs';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function CompensationsRequestsPage({selfUser}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  const {requestIdsByType, requestsById, requestsState} = useStore($compensationRequests);
  const {userById} = useAllUsers(); // TODO INTRANET-3549 use dataState in filters panel
  const users = useMemo(() => Object.values(userById), [userById]);

  const [requestType] = useQueryParam('type', compensationRequestTypeSchema, CompensationRequestType.BUSINESS_TRIP);
  const [mode] = useQueryParam('mode', oneOfEnum(CompensationRequestsMode), CompensationRequestsMode.ME);

  const hasSubordinates = Boolean(selfUser.subordinates && selfUser.subordinates.length > 0);
  const canViewAllRequests = acl.hasPermission(Permission.COMPENSATION_READ_ALL);

  useEffect(() => {
    if (!requestType || isLoadingOrLoaded(requestsState)) {
      return;
    }
    if (canViewAllRequests) {
      getAllRequestsByTypeFx({type: requestType}).catch(toaster.interceptThenThrowError);
    } else {
      getAvailableRequestsByTypeFx({type: requestType}).catch(toaster.interceptThenThrowError);
    }
  }, [requestType]);

  const {filterValues, setFilterValues} = useStoredFilters<CompensationRequestsFilterValues>({
    id: 'compensationRequests.filters',
    initialValues: {},
    valuesSchema: compensationRequestsFilterValuesSchema,
  });

  const requestsOfType = useMemo(
    () => (requestType && requestIdsByType[requestType]?.map((id) => requestsById[id])) ?? [],
    [requestType, requestIdsByType, requestsById],
  );
  const myRequests = useMemo(
    () => requestsOfType.filter((request) => request.owner.id === selfUser.id),
    [requestsOfType, selfUser.id],
  );
  const subordinateRequests = useMemo(
    () => requestsOfType.filter((request) => checkIsSubordinate(selfUser, request.owner.id, users)),
    [selfUser, users, requestsOfType],
  );
  const allRequests = requestsOfType;
  const requestsByMode = useMemo(
    () => ({
      [CompensationRequestsMode.ME]: myRequests,
      [CompensationRequestsMode.SUBORDINATES]: subordinateRequests,
      [CompensationRequestsMode.ALL]: allRequests,
    }),
    [myRequests, subordinateRequests, allRequests],
  );
  const hasIndirectSubordinates = useMemo(() => checkHasIndirectSubordinates(selfUser.id, users), [selfUser.id, users]);
  const availableFilters = useMemo(
    () => getAvailableFilters(mode, hasIndirectSubordinates),
    [mode, hasIndirectSubordinates],
  );
  const filteredRequests = useMemo(
    () =>
      requestsByMode[mode].filter((request) =>
        checkRequestFitsFilters({selfUserId: selfUser.id, request, filterValues, availableFilters}),
      ),
    [availableFilters, filterValues, mode, requestsByMode, selfUser.id],
  );

  return (
    <Page
      title={intl.formatMessage(pageTitles.requests)}
      actions={
        <ButtonGroup spaced size='m'>
          {acl.hasPermission(Permission.COMPENSATION_DOWNLOAD_ALL) && (
            <DownloadLinkButton
              kind='text'
              intent='neutral'
              href={buildAllRequestsDownloadLinkForAdmin()}
              iconLeft={<DownloadIcon />}
            >
              {intl.formatMessage(messages.downloadButton)}
            </DownloadLinkButton>
          )}
          {acl.hasPermission(Permission.COMPENSATION_WRITE) && (
            <RouterLinkButton
              to={compensationsUrls.createRequest()}
              size='m'
              kind='primary'
              intent='primary'
              type='submit'
            >
              {intl.formatMessage(messages.createButton)}
            </RouterLinkButton>
          )}
        </ButtonGroup>
      }
      headerContent={
        (hasSubordinates || canViewAllRequests) && (
          <ModeTabs
            activeMode={mode}
            canViewAllRequests={canViewAllRequests}
            hasSubordinates={hasSubordinates}
            requestsByMode={requestsByMode}
            requestType={requestType}
            selfUserId={selfUser.id}
          />
        )
      }
    >
      {[CompensationRequestsMode.ALL, CompensationRequestsMode.SUBORDINATES].includes(mode) && (
        <CompensationRequestFiltersPanel
          availableFilters={availableFilters}
          values={filterValues}
          onChange={setFilterValues}
        />
      )}
      <Panel withMarginBottom={false}>
        <CompensationRequestsTable
          compensationRequests={filteredRequests}
          dataState={requestsState}
          mode={mode}
          selfUserId={selfUser.id}
        />
      </Panel>
    </Page>
  );
}
