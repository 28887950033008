import * as React from 'react';
import styles from './index.css';

type Props = {
  message: string;
};

export function Error({message}: Props) {
  return (
    <div className={styles.error} title={message}>
      {message}
    </div>
  );
}
