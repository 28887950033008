import {SecurePermission} from 'domain/permission/model/secure';
import {useIsSubordinate} from 'domain/user/hooks/useIsSubordinate';
import {UserId} from 'domain/user/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  userId: UserId;
};

export function EmptyStockOptions({userId}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const isSubordinateUser = useIsSubordinate(userId);
  const canRead =
    acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_ANY) ||
    (isSubordinateUser && acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_SUBORDINATE));

  return (
    <div className={styles.root}>{intl.formatMessage(canRead ? messages.emptyStockOptions : messages.noAccess)}</div>
  );
}
