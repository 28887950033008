import {
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {currencySchema} from 'domain/currency/model';
import {legalEntitySchema} from 'domain/legalEntity/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';
import {OneTimeBonusCondition} from './condition';
import {OneTimeBonusType} from './type';

export const oneTimeBonusRecordIdSchema = idSchema;

export const oneTimeBonusRecordSchema = object({
  amount: number(),
  condition: oneOfEnum(OneTimeBonusCondition),
  comment: optional(string()),
  currency: currencySchema,
  date: formattedDateSchema,
  id: oneTimeBonusRecordIdSchema,
  legalEntity: optional(legalEntitySchema),
  penalDeadline: optional(formattedDateSchema),
  type: oneOfEnum(OneTimeBonusType),
});

export type OneTimeBonusRecordId = ExtractSchemaType<typeof oneTimeBonusRecordIdSchema>;
export type OneTimeBonusRecord = ExtractSchemaType<typeof oneTimeBonusRecordSchema>;
