import {useClipboardCopy} from '@joomcode/deprecated-utils/react/useClipboardCopy';
import React from 'react';
import {useIntl} from 'react-intl';
import {TooltipAlt} from '../../TooltipAlt';
import {messages} from './messages';

type RenderProps = {
  copied: boolean;
  copy(): void;
  label: string;
};

export type CopyHeadlessProps = {
  children: (renderProps: RenderProps) => React.ReactElement;
  label?: string;
  value: string;
};

export function CopyHeadless({children, label: maybeLabel, value}: CopyHeadlessProps) {
  const intl = useIntl();
  const {copy, copied, error} = useClipboardCopy(value);

  const label = maybeLabel ?? intl.formatMessage(messages.annotation);
  const copiedMessage = intl.formatMessage(messages.copied);

  return (
    <TooltipAlt
      content={error?.message || (copied ? copiedMessage : label)}
      hideOnClick={false}
      placement='top'
      trigger='mouseenter focus'
    >
      {children({copy, copied, label})}
    </TooltipAlt>
  );
}
