import {
  dateFromString,
  ExtractSchemaType,
  object,
  optional,
  positiveNumber,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {idSchema} from 'models/system/id';

export const fileMetaSchema = object({
  createdTime: dateFromString(),
  fileName: string(),
  id: idSchema,
  mimeType: optional(string()),
  size: positiveNumber(),
});

export type FileMeta = ExtractSchemaType<typeof fileMetaSchema>;
