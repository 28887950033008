import cn from 'classnames';
import React from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../create-locator';
import {ReactComponent as CheckIcon} from '../icons/core/checkmark.svg';
import {ReactComponent as CrossIcon} from '../icons/core/cross.svg';
import styles from './index.css';

export type ToggleTestId = ConvertLocatorToTestId<ToggleLocator>;
export type ToggleLocator = LocatorOfElement<{input: void}>;

export type ToggleProps = {
  label?: React.ReactNode;
} & Omit<JSX.IntrinsicElements['input'], 'className'> &
  Partial<Mark<ToggleLocator>>;

/**
 * A toggle control using to quickly toggle between two possible states: "on"/"off".
 *
 * [Design spec in Figma](https://www.figma.com/file/McAwzjQPbj72tAtbGWYNdN/B2B-Design-System?type=design&node-id=11870-30)
 */
export const Toggle = React.forwardRef<HTMLLabelElement, ToggleProps>(function Toggle(
  {label, ...restPropertiesWithMark}: ToggleProps,
  ref,
) {
  const locator = createLocator(restPropertiesWithMark);
  const restProps = removeMarkFromProperties(restPropertiesWithMark);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={cn(styles.root, {[styles.disabled]: restProps.disabled})} ref={ref} {...locator()}>
      <input {...restProps} {...locator.input()} className={styles.input} type='checkbox' role='switch' />

      <span className={styles.switch}>
        <span className={styles.handler}>
          <CheckIcon className={cn(styles.icon, styles.checkIcon)} />
          <CrossIcon className={cn(styles.icon, styles.crossIcon)} />
        </span>
      </span>

      {label && <span className={styles.label}>{label}</span>}
    </label>
  );
});
