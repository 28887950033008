export const activeTabIdCalculator = {
  first<TabId extends string>(tabs: TabId[]): TabId | undefined {
    return tabs[0];
  },
  last<TabId extends string>(tabs: TabId[]): TabId | undefined {
    return tabs[tabs.length - 1];
  },
  previous<TabId extends string>(tabs: TabId[], referenceId: TabId): TabId | undefined {
    const referenceIndex = tabs.findIndex((id) => id === referenceId);

    return tabs[referenceIndex - 1] || tabs[tabs.length - 1];
  },
  next<TabId extends string>(tabs: TabId[], referenceId: TabId): TabId | undefined {
    const referenceIndex = tabs.findIndex((id) => id === referenceId);

    return tabs[referenceIndex + 1] || tabs[0];
  },
  afterClose<TabId extends string>(tabs: TabId[], closedTabId: TabId): TabId | undefined {
    const currentTabIndex = closedTabId ? tabs.findIndex((id) => id === closedTabId) : -1;

    return tabs[currentTabIndex + 1] ?? tabs[currentTabIndex - 1];
  },
};
