import {ReactComponent as PeopleIcon} from '@joomcode/joom-ui/icons/core/people.svg';
import cn from 'classnames';
import {Avatar} from 'components/ui/Avatar';
import {StyledLink} from 'components/ui/StyledLink';
import {Permission} from 'domain/permission/model';
import {getMergedOngoingTimeOff, isTimeOffVacation} from 'domain/timeOff/userTimeOff/model/utils';
import {TimeOffIcon} from 'domain/timeOff/userTimeOff/widgets/Icon';
import {RemoteWorkIcon} from 'domain/timeOff/userTimeOff/widgets/RemoteWorkIcon';
import {User} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {outstaffUsersUrls} from 'routes/outstaffUsers/urls';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {highlightIfNeeded} from 'utils/search/highlight';
import styles from './listedUser.css';
import {messages} from './messages';

export type Props = {
  children?: React.ReactNode;
  fullWidth?: boolean;
  highlightSearch?: (s: string) => React.ReactNode;
  subordinateCount?: number;
  user: User;
};

export function ListedUser({children, fullWidth = false, highlightSearch, subordinateCount, user}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  // prettier-ignore
  const {
    avatarBundle,
    firstName,
    isTerminated,
    jobTitle,
    lastName,
    login,
    email,
    offTime,
    workingRemotely,
    isOutstaff
  } = user;
  const fullName = intl.formatMessage(generalMessages.fullName, {firstName, lastName});
  const disableLink = user.isTerminated && !acl.hasPermission(Permission.USER_READ_ANY);
  const timeOff = useMemo(() => {
    const vacationTimeOffs = offTime?.filter(isTimeOffVacation);
    return vacationTimeOffs ? getMergedOngoingTimeOff(vacationTimeOffs) : undefined;
  }, [offTime]);

  const link = isOutstaff ? outstaffUsersUrls.outstaffUser({email}) : usersUrls.user({login});
  return (
    <div className={cn(styles.root, {[styles.fullWidth]: fullWidth, [styles.inactive]: isTerminated})}>
      <StyledLink to={link} disabled={disableLink}>
        <Avatar
          name={fullName}
          imageBundle={avatarBundle}
          placeholder={isOutstaff ? intl.formatMessage(messages.outstaffPlaceholder) : undefined}
        />
      </StyledLink>
      <div className={styles.info}>
        <div className={styles.nameLine}>
          <StyledLink className={styles.name} to={link} disabled={disableLink}>
            {highlightIfNeeded(fullName, highlightSearch)}
          </StyledLink>
          {timeOff && <TimeOffIcon timeOff={timeOff} />}
          {workingRemotely && !timeOff && <RemoteWorkIcon />}
        </div>
        {children ? (
          <div className={styles.additionalInfo}>{children}</div>
        ) : (
          <div className={styles.jobInfo}>
            {jobTitle && <div className={styles.jobTitle}>{highlightIfNeeded(jobTitle, highlightSearch)}</div>}
            {subordinateCount ? (
              <div className={styles.subordinateCount}>
                <div className={styles.subordinatesIcon}>
                  <PeopleIcon />
                </div>
                {intl.formatNumber(subordinateCount)}
              </div>
            ) : undefined}
          </div>
        )}
      </div>
    </div>
  );
}
