import {FieldInput, validateFilled, validateUrl} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {CompanyUnitFormControl} from 'components/widgets/CompanyUnitFormControl';
import {CompanyUnits} from 'domain/companyUnits/model';
import {FieldOfferPriority} from 'domain/offer/widgets/FieldPriority';
import {OfferFormSectionTitle} from 'domain/offer/widgets/FormSectionTitle';
import {FieldUsers} from 'domain/user/widgets/FieldMultiple';
import React from 'react';
import {useIntl} from 'react-intl';
import {labels, messages} from './messages';

const URL_FIELD_PLACEHOLDER = 'https://';

type Props = {
  companyUnits: CompanyUnits;
};

export function OfferInfoFields({companyUnits}: Props) {
  const intl = useIntl();

  return (
    <>
      <OfferFormSectionTitle title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item sm={8} xl={6}>
            <CompanyUnitFormControl
              name='general.source'
              type='offerSource'
              items={companyUnits.offerSources}
              required
              validate={validateFilled}
              reserveSpaceForError={false}
            />
          </Grid.Item>
          <Grid.Item sm={4} xl={6}>
            <FieldOfferPriority
              name='general.priority'
              label={intl.formatMessage(labels.priority)}
              required
              validate={validateFilled}
              reserveSpaceForError={false}
            />
          </Grid.Item>
          <Grid.Item xl={12}>
            <FormControl label={intl.formatMessage(labels.referrers)} reserveSpaceForError={false}>
              {(formControlProps) => (
                <FieldUsers name='general.referrerIds' suggestTerminatedUsers {...formControlProps} />
              )}
            </FormControl>
          </Grid.Item>
          <Grid.Item xl={12}>
            <FieldInput
              name='general.ticketLink'
              label={intl.formatMessage(labels.ticketLink)}
              reserveSpaceForError={false}
              placeholder={URL_FIELD_PLACEHOLDER}
              validate={validateUrl}
            />
          </Grid.Item>
        </Grid>
      </Panel.Content>
    </>
  );
}
