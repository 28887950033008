import {defineMessages} from 'react-intl';
import {CompensationRequestReason} from '.';

export const compensationRequestReasons = defineMessages<CompensationRequestReason>({
  [CompensationRequestReason.MEETING_WITH_PARTNERS]: {
    defaultMessage: 'Meeting with current or potential partners',
    description: 'Compensation request reason',
  },
  [CompensationRequestReason.BUSINESS_EVENT]: {
    defaultMessage: 'Business event participation',
    description: 'Compensation request reason',
  },
  [CompensationRequestReason.MEETING_WITH_COLLEAGUES]: {
    defaultMessage: 'Business meeting with colleagues',
    description: 'Compensation request reason',
  },
  [CompensationRequestReason.OTHER]: {
    defaultMessage: 'Other',
    description: 'Compensation request reason',
  },
});
