import {defineMessages} from 'react-intl';
import {TerminationType} from '.';

export const terminationTypeMessages = defineMessages<TerminationType>({
  [TerminationType.FIRED]: {
    defaultMessage: 'Termination (involuntary)',
    description: 'Employment termination type',
  },
  [TerminationType.QUIT]: {
    defaultMessage: 'Resignation (voluntary)',
    description: 'Employment termination type',
  },
  [TerminationType.MUTUAL]: {
    defaultMessage: 'Mutual',
    description: 'Employment termination type',
  },
  [TerminationType.CONTRACT_ENDING]: {
    defaultMessage: 'End of employment contract',
    description: 'Employment termination type',
  },
  [TerminationType.DISMISSAL]: {
    defaultMessage: 'Dismissal',
    description: 'Employment termination type',
  },
});
