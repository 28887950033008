import {Permission} from 'domain/permission/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {checkByPermission} from './checkers';
import {checkBySecurePermission} from './checkersSecure';

export function hasPermission(permission: Permission) {
  const checkPermission = checkByPermission[permission];

  if (!checkPermission) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(`The permission “${permission}” is not found in the list`);
    }
    return false;
  }

  return checkPermission();
}

export function hasSecurePermission(securePermission: SecurePermission) {
  const checkPermission = checkBySecurePermission[securePermission];

  if (!checkPermission) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(`The secure permission “${securePermission}” is not found in the list`);
    }
    return false;
  }

  return checkPermission();
}
