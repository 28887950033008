import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {useOffer} from 'domain/offer/hooks/useOffer';
import {useOfferUpdatePossibility} from 'domain/offer/hooks/useOfferUpdatePossibility';
import {offerIdSchema} from 'domain/offer/model';
import {OfferEditGeneralForm} from 'domain/offer/widgets/EditGeneralForm';
import {generalMessages} from 'i18n/messages/general';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/offer/titles';
import {offerUrls} from 'routes/offer/urls';

export function OfferEditGeneralInfoPage() {
  const intl = useIntl();
  const {id} = useTypedParams({id: offerIdSchema});
  const {data, dataState, clientErrorStatus} = useOffer(id);
  const {canUpdateGeneralInfo} = useOfferUpdatePossibility(data);

  if (!id) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={data} entityError={clientErrorStatus} state={dataState}>
      {(offer) => {
        if (!canUpdateGeneralInfo) {
          return <NotFoundPage />;
        }

        return (
          <Page
            title={intl.formatMessage(pageTitles.editGeneralInfo)}
            breadcrumbs={[
              {
                title: intl.formatMessage(pageTitles.offers),
                href: offerUrls.root(),
              },
              {
                title: intl.formatMessage(generalMessages.fullName, {
                  firstName: offer.general.firstName,
                  lastName: offer.general.lastName,
                }),
                href: offerUrls.offer({id}),
              },
            ]}
          >
            <OfferEditGeneralForm offer={offer} />
          </Page>
        );
      }}
    </EntityPageStateHandler>
  );
}
