import {QueryRequestPayload} from 'api/types';
import {roleBindingApi} from 'domain/roleBinding/api';
import {RoleBinding, RoleBindingsSearchFilters} from 'domain/roleBinding/model';
import {
  approveRoleBindingRequestFx,
  cancelRoleBindingRequestFx,
  getRoleBindingsFx,
  getRoleBindingsWithCountFx,
  rejectRoleBindingRequestFx,
  requestRoleBindingAsAdminFx,
  requestRoleBindingFx,
  revokeRoleBindingFx,
} from '.';

const getRoleBindingsWithoutCount = async (
  query: QueryRequestPayload<RoleBindingsSearchFilters>,
): Promise<RoleBinding[]> => {
  const {items} = await getRoleBindingsWithCountFx(query);
  return items;
};

approveRoleBindingRequestFx.use(roleBindingApi.approve);
cancelRoleBindingRequestFx.use(roleBindingApi.cancel);
getRoleBindingsFx.use(getRoleBindingsWithoutCount);
getRoleBindingsWithCountFx.use(roleBindingApi.getAll);
rejectRoleBindingRequestFx.use(roleBindingApi.reject);
requestRoleBindingFx.use(roleBindingApi.request);
requestRoleBindingAsAdminFx.use(roleBindingApi.requestAsAdmin);
revokeRoleBindingFx.use(roleBindingApi.revoke);
