import {jobContractsApi} from 'domain/jobContract/api';
import {
  createJobContractRecordFx,
  deleteJobContractRecordFx,
  getJobContractsByUserIdFx,
  getMyJobContractsFx,
  getSubordinateJobContractsFx,
  updateJobContractRecordFx,
} from '.';

createJobContractRecordFx.use(jobContractsApi.createRecord);
deleteJobContractRecordFx.use(jobContractsApi.deleteRecord);
getJobContractsByUserIdFx.use(jobContractsApi.getByUserId);
getMyJobContractsFx.use(jobContractsApi.getMy);
getSubordinateJobContractsFx.use(jobContractsApi.getSubordinate);
updateJobContractRecordFx.use(jobContractsApi.updateRecord);
