import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {StockOptionHolder} from 'domain/stockOption/holder/model';
import {createStore} from 'effector';
import {
  getAllStockOptionHoldersFx,
  getAllStockOptionHoldersWithCountFx,
  getSubordinateStockOptionHoldersFx,
  getSubordinateStockOptionHoldersWithCountFx,
} from '.';

type StockOptionHoldersCountState = {
  count: number;
};

export const $allStockOptionHolders = createEntityStore({
  getEntityId: (holder: StockOptionHolder) => holder.user.id,
  query: {
    fx: getAllStockOptionHoldersFx,
    getIdsFromParams: () => [],
  },
});

export const $allStockOptionHoldersList = createEntityListStore($allStockOptionHolders);
export const $allStockOptionHoldersCount = createStore<StockOptionHoldersCountState>({
  count: 0,
}).on(getAllStockOptionHoldersWithCountFx.done, (state, {result}) => {
  return {
    ...state,
    count: result.count,
  };
});

export const $subordinateStockOptionHolders = createEntityStore({
  getEntityId: (holder: StockOptionHolder) => holder.user.id,
  query: {
    fx: getSubordinateStockOptionHoldersFx,
    getIdsFromParams: () => [],
  },
});

export const $subordinateStockOptionHoldersList = createEntityListStore($subordinateStockOptionHolders);
export const $subordinateStockOptionHoldersCount = createStore<StockOptionHoldersCountState>({
  count: 0,
}).on(getSubordinateStockOptionHoldersWithCountFx.done, (state, {result}) => {
  return {
    ...state,
    count: result.count,
  };
});
