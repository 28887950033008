import {buildDirectoryDownloadLink} from './buildDirectoryDownloadLink';
import {buildLegalDataDownloadLink} from './buildLegalDataDownloadLink';
import {freeSeat} from './freeSeat';
import {getActive} from './getActive';
import {getAll} from './getAll';
import {getById} from './getById';
import {getByLogin} from './getByLogin';
import {getInterviewLink} from './getInterviewLink';
import {getLegalInfoById} from './getLegalInfoById';
import {getMainLegalEntity} from './getMainLegalEntity';
import {getMyPrivateInfo} from './getMyPrivateInfo';
import {getPrivateInfoById} from './getPrivateInfoById';
import {getSubordinateTimeOffBalances} from './getSubordinateTimeOffBalances';
import {getTerminatedUserByLogin} from './getTerminatedUserByLogin';
import {setLegalInfo} from './setLegalInfo';
import {setMainLegalEntity} from './setMainLegalEntity';
import {setRoles} from './setRoles';
import {takeSeat} from './takeSeat';
import {unassignSeat} from './unassignSeat';
import {update} from './update';
import {updateAvatar} from './updateAvatar';

export const usersApi = {
  buildDirectoryDownloadLink,
  buildLegalDataDownloadLink,
  freeSeat,
  getActive,
  getAll,
  getById,
  getByLogin,
  getInterviewLink,
  getLegalInfoById,
  getMainLegalEntity,
  getMyPrivateInfo,
  getPrivateInfoById,
  getSubordinateTimeOffBalances,
  getTerminatedUserByLogin,
  setLegalInfo,
  setMainLegalEntity,
  setRoles,
  takeSeat,
  unassignSeat,
  update,
  updateAvatar,
};
