import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {containsSearchString} from '../utils';

type LabelByValueMap = Record<string, string>;

export type ListOption<T = string> = {
  label: string;
  value: T;
};

function getLabelByValueMap<T extends string>(options: ListOption<T>[]): LabelByValueMap {
  return arrayToObject(
    options,
    (option) => option.value,
    (option) => option.label,
  );
}

export function matchOption<T extends string>(option: ListOption<T>, query: string) {
  return containsSearchString(option.label, query) || containsSearchString(option.value, query);
}

export const listOptionsUtils = {
  getLabelByValueMap,
  matchOption,
};
