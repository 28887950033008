import {ButtonIntent, ButtonKind} from '@joomcode/joom-ui/Button/types';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {RoleBinding, RoleBindingId} from 'domain/roleBinding/model';
import {RoleBindingAction} from 'domain/roleBinding/model/actions';
import {RoleBindingStatus} from 'domain/roleBinding/model/status';
import {
  approveRoleBindingRequestFx,
  cancelRoleBindingRequestFx,
  rejectRoleBindingRequestFx,
  revokeRoleBindingFx,
} from 'domain/roleBinding/stores/main';
import {UserId} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type ActionConfig = {
  action: RoleBindingAction;
  buttonText: string;
  buttonKind: ButtonKind;
  buttonIntent: ButtonIntent;
  isApplicable: boolean;
  callback: (id: RoleBindingId) => Promise<unknown> | void;
};

export function useRoleBindingActions(roleBinding: RoleBinding, canManage: boolean, selfUserId?: UserId) {
  const intl = useIntl();
  const isMyRequest = useMemo(() => roleBinding.createdBy.id === selfUserId, [roleBinding, selfUserId]);

  const formatMessageWithParams = useCallback(
    (message: MessageDescriptor) =>
      intl.formatMessage(message, {
        emphasis: (text: React.ReactNode) => <span className={styles.emphasis}>{text}</span>,
        resourceName: roleBinding.resource.displayName,
        roleName: roleBinding.role.name,
        teamName: roleBinding.teamWithAncestors.team.name,
      }),
    [intl, roleBinding],
  );

  const allActions: ActionConfig[] = useMemo(
    () => [
      {
        action: RoleBindingAction.CANCEL,
        buttonText: intl.formatMessage(messages.cancel),
        buttonKind: 'secondary',
        buttonIntent: 'negative',
        isApplicable: isMyRequest && roleBinding.status === RoleBindingStatus.PENDING,
        callback: (id: RoleBindingId) =>
          confirm(
            {
              title: intl.formatMessage(messages.cancelConfirmationTitle),
              text: formatMessageWithParams(messages.cancelConfirmationText),
              confirmationText: intl.formatMessage(messages.cancelConfirmationButton),
              intent: 'negative',
              onConfirm: () => cancelRoleBindingRequestFx(id).catch(toaster.interceptThenThrowError),
            },
            intl,
          ),
      },
      {
        action: RoleBindingAction.REVOKE,
        buttonText: intl.formatMessage(messages.revoke),
        buttonKind: 'primary',
        buttonIntent: 'neutral',
        isApplicable: canManage && roleBinding.status === RoleBindingStatus.APPROVED,
        callback: (id: RoleBindingId) =>
          confirm(
            {
              title: intl.formatMessage(messages.revokeConfirmationTitle),
              text: roleBinding.propagate
                ? formatMessageWithParams(messages.revokePropagatingConfirmationText)
                : formatMessageWithParams(messages.revokeConfirmationText),
              confirmationText: intl.formatMessage(messages.revokeConfirmationButton),
              intent: 'negative',
              onConfirm: () => revokeRoleBindingFx(id).catch(toaster.interceptThenThrowError),
            },
            intl,
          ),
      },
      {
        action: RoleBindingAction.REJECT,
        buttonText: intl.formatMessage(messages.reject),
        buttonKind: 'secondary',
        buttonIntent: 'negative',
        isApplicable: canManage && roleBinding.status === RoleBindingStatus.PENDING,
        callback: (id: RoleBindingId) =>
          confirm(
            {
              title: intl.formatMessage(messages.rejectConfirmationTitle),
              text: roleBinding.propagate
                ? formatMessageWithParams(messages.rejectPropagatingConfirmationText)
                : formatMessageWithParams(messages.rejectConfirmationText),
              confirmationText: intl.formatMessage(messages.rejectConfirmationButton),
              intent: 'negative',
              onConfirm: () => rejectRoleBindingRequestFx(id).catch(toaster.interceptThenThrowError),
            },
            intl,
          ),
      },
      {
        action: RoleBindingAction.APPROVE,
        buttonText: intl.formatMessage(messages.approve),
        buttonKind: 'primary',
        buttonIntent: 'positive',
        isApplicable: canManage && roleBinding.status === RoleBindingStatus.PENDING,
        callback: (id: RoleBindingId) =>
          confirm(
            {
              title: intl.formatMessage(messages.approveConfirmationTitle),
              text: roleBinding.propagate
                ? formatMessageWithParams(messages.approvePropagatingConfirmationText)
                : formatMessageWithParams(messages.approveConfirmationText),
              confirmationText: intl.formatMessage(messages.approveConfirmationButton),
              intent: 'primary',
              onConfirm: () => approveRoleBindingRequestFx(id).catch(toaster.interceptThenThrowError),
            },
            intl,
          ),
      },
    ],
    [intl, canManage, isMyRequest, roleBinding],
  );

  const actions = useMemo(() => allActions.filter(({isApplicable}) => isApplicable), [allActions]);

  return {actions};
}
