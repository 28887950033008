import {Grid} from '@joomcode/joom-ui/Grid';
import {CompensationReviewRequestFormSalary} from 'domain/compensationReviewRequest/widgets/FormSalary';
import {CompensationReviewRequestFormSalaryRange} from 'domain/compensationReviewRequest/widgets/FormSalaryRange';
import {SalaryRecord} from 'domain/salaryRecord/model';
import React, {useCallback} from 'react';
import {useForm} from 'react-final-form';

type Props = {
  salaries: SalaryRecord[];
};

export function CompensationReviewRequestFormSectionSalary({salaries}: Props) {
  const {change, getState} = useForm();
  const {values} = getState();
  const reset = useCallback(
    (salary: SalaryRecord) => {
      change(`salaries.${salary.id}.amount`, salary.amount);
    },
    [change, values],
  );
  const isOnlySalary = salaries.length === 1;

  return salaries.map((salary) => {
    const hasChanges = salary.amount !== values.salaries?.[salary.id]?.amount;

    return (
      <Grid key={salary.id}>
        <Grid.Item xl={6} xs={12}>
          <CompensationReviewRequestFormSalary salary={salary} isOnlySalary={isOnlySalary} />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <CompensationReviewRequestFormSalaryRange
            levelCode={values.userInfo.general.levelCode}
            region={salary.region}
            userFunction={values.userInfo.general.userFunction}
            isOnlySalary={isOnlySalary}
            frequency={isOnlySalary ? salary.frequency : undefined}
            onReset={hasChanges ? () => reset(salary) : undefined}
          />
        </Grid.Item>
      </Grid>
    );
  });
}
