import React from 'react';
import {markdownParser} from './markdownParser';
import styles from './styles.css';

type Props = {
  source: string;
};

export function Markdown({source}: Props) {
  // eslint-disable-next-line react/no-danger
  return <div className={styles.root} dangerouslySetInnerHTML={{__html: markdownParser.parse(source)}} />;
}
