import cn from 'classnames';
import React from 'react';
import styles from './styles.css';

type Props = {
  value: React.ReactNode;
  className?: string;
};

export function OldValue({value, className}: Props) {
  return <div className={cn(styles.root, className)}>{value}</div>;
}
