import cn from 'classnames';
import React, {useCallback} from 'react';
import {ReactComponent as CheckmarkIcon} from '../../../icons/core/checkmark.svg';
import {ReactComponent as GearIcon} from '../../../icons/core/gear.svg';
import {Menu} from '../../../Menu';
import {Column} from '../../types';
import styles from './index.css';

export type ColumnSelectProps = {
  ariaLabel: string;
  availableColumns: Column<unknown>[];
  visibleColumnIds: string[];
  onColumnsVisibilityChange: (visibleColumns: string[]) => void;
};

export function toggleArrayElement<T>(array: T[], element: T): T[] {
  return array.includes(element) ? array.filter((current) => element !== current) : array.concat(element);
}

/** Discolsure button cell height plus some padding */
const cellHeight = '4rem';

/** Menu height should be small enough to be able to flip above the cell when there is no space below */
const maxMenuHeight = `calc(50vh - (${cellHeight} / 2))`;

export function ColumnsSelect({
  ariaLabel,
  availableColumns,
  visibleColumnIds,
  onColumnsVisibilityChange,
}: ColumnSelectProps) {
  const handleItemClick = useCallback(
    (columnId: string) => {
      onColumnsVisibilityChange(toggleArrayElement(visibleColumnIds, columnId));
    },
    [visibleColumnIds, onColumnsVisibilityChange],
  );

  return (
    <Menu
      ariaLabel={ariaLabel}
      placement='bottom-end'
      maxHeight={maxMenuHeight}
      // eslint-disable-next-line react/no-unstable-nested-components
      disclosure={(props, {isOpen}) => (
        <button
          {...props}
          type='button'
          className={cn(styles.disclosure, {[styles.disclosureOpen]: isOpen})}
          aria-label={ariaLabel}
        >
          <GearIcon />
        </button>
      )}
    >
      {availableColumns.map((column) => {
        const isItLastVisibleItem = visibleColumnIds.length === 1 && visibleColumnIds[0] === column.id;
        const isChecked = visibleColumnIds.includes(column.id);
        const isDisabled = !column.hideable || isItLastVisibleItem;
        return (
          <Menu.Item
            closeOnClick={false}
            disabled={isDisabled}
            onClick={() => handleItemClick(column.id)}
            key={column.id}
          >
            <span className={styles.itemContent}>
              <span className={styles.itemLabel}>{column.name}</span>

              <span
                className={cn(styles.check, {
                  [styles.checkChecked]: isChecked,
                  [styles.checkDisabled]: isDisabled,
                })}
              >
                {isChecked && <CheckmarkIcon />}
              </span>
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
}
