import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  personalData: {
    defaultMessage: 'Personal data',
    description: '[title] User legal info form: section title',
  },
  internationalPassport: {
    defaultMessage: 'International passport',
    description: '[title] User legal info form: section title',
  },
  localId: {
    defaultMessage: 'National ID or Residence card',
    description: '[title] User legal info form: section title',
  },
  legalAddress: {
    defaultMessage: 'Legal address',
    description: '[title] User legal info form: section title',
  },
  success: {
    defaultMessage: 'Joomer’s legal data has been updated.',
    description: 'User legal info form: success message',
  },
});
