import React, {ReactNode} from 'react';
import {ReactComponent as CopyIcon} from '../../icons/core/copy.svg';
import styles from './index.css';
import {CopyHeadless} from '../Headless';

export type CopyInteractiveTextProps = {
  children: ReactNode;
  label?: string;
  value: string;
};

export function CopyInteractiveText({children, label, value}: CopyInteractiveTextProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>

      <CopyHeadless label={label} value={value}>
        {(copy) => (
          <button aria-label={copy.label} className={styles.button} onClick={copy.copy} type='button'>
            <CopyIcon />
          </button>
        )}
      </CopyHeadless>
    </div>
  );
}
