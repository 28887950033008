import {isNullish} from '@joomcode/deprecated-utils/function';
import React, {ReactNode} from 'react';
import {HumanDateTime} from '../../../HumanDate';
import {TooltipAlt} from '../../../TooltipAlt';
import {CellEmpty} from '../../components/CellEmpty';
import {Column} from '../../types';
import {Nullable} from '../types';
import styles from './index.css';

type DateType = Date | number | string;

type Options<Item, NestedItem = never> = Omit<Column<Item, NestedItem>, 'render' | 'renderNested'> & {
  format?: 'short' | 'long';
  getNestedValue?: (nestedItem: NestedItem) => Nullable<DateType>;
  getValue: (item: Item) => Nullable<DateType>;
  render?: (item: Item, formattedValue: React.ReactNode) => React.ReactNode;
  renderNested?: (nestedItem: NestedItem, item: Item, formattedValue: ReactNode) => ReactNode;
  utc?: boolean;
};

function formatDate(date: DateType, format: 'short' | 'long' = 'short', utc = false): ReactNode {
  if (format === 'long') {
    return <HumanDateTime value={date} format='long' utc={utc} />;
  }

  return (
    <TooltipAlt content={<HumanDateTime value={date} format='long' />} placement='top'>
      <span className={styles.date}>
        <HumanDateTime value={date} format='short' utc={utc} />
      </span>
    </TooltipAlt>
  );
}

export function createColumnDateTime<Item, NestedItem>({
  format,
  getNestedValue,
  getValue,
  render,
  renderNested,
  utc = false,
  ...rest
}: Options<Item, NestedItem>): Column<Item, NestedItem> {
  return {
    align: 'center',

    render: (item) => {
      const value = getValue(item);

      if (isNullish(value)) {
        return <CellEmpty />;
      }

      return render ? render(item, formatDate(value, format)) : formatDate(value, format);
    },

    renderNested: (nestedItem, item) => {
      const value = getNestedValue?.(nestedItem);

      if (isNullish(value)) {
        return <CellEmpty />;
      }

      return renderNested ? renderNested(nestedItem, item, formatDate(value, format)) : formatDate(value, format);
    },

    ...rest,
  };
}
