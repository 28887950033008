import {Menu} from '@joomcode/joom-ui/Menu';
import {MenuButton} from 'components/ui/MenuButton';
import {JobInfoRecord, JobInfoRecordId} from 'domain/jobInfoRecord/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export type Props = {
  jobInfoRecord: JobInfoRecord;
  onDelete?: (jobInfoRecordId: JobInfoRecordId) => void;
  onEdit?: (jobInfoRecord: JobInfoRecord) => void;
};

export function JobInfoRecordMenu({jobInfoRecord, onDelete, onEdit}: Props) {
  const intl = useIntl();
  return (
    <Menu ariaLabel={intl.formatMessage(messages.menuAriaLabel)} disclosure={<MenuButton />}>
      {onEdit && <Menu.Item onClick={() => onEdit(jobInfoRecord)}>{intl.formatMessage(messages.edit)}</Menu.Item>}
      {onDelete && (
        <Menu.Item onClick={() => onDelete(jobInfoRecord.id)}>{intl.formatMessage(messages.delete)}</Menu.Item>
      )}
    </Menu>
  );
}
