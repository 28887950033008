import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {marketDataApi} from 'domain/marketDataRecord/api';
import {withVpn} from 'hocs/withVpn';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export const DownloadSubordinatesMarketData = withVpn(() => {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      <DownloadLinkButton
        size='m'
        kind='secondary'
        intent='neutral'
        href={marketDataApi.buildDownloadSubordinatesLink()}
        iconLeft={<DownloadIcon />}
        target='_blank'
      >
        {intl.formatMessage(messages.downloadSubordinatesMarketData)}
      </DownloadLinkButton>
    </div>
  );
});
