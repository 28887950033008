import {array, ExtractSchemaType, object, string} from '@joomcode/deprecated-utils/jsonValidation';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const companyUnitsSchema = object({
  departments: array(string()),
  divisions: array(string()),
  groupsOfFunctions: optionalWithDefault([], array(string())),
  jobTitles: array(string()),
  emergencyContactRelationships: array(string()),
  locations: array(string()),
  userFunctions: optionalWithDefault([], array(string())),
  salaryChangeReasons: optionalWithDefault([], array(string())),
  stockOptionIssuers: array(string()),
  offerSources: array(string()),
  offerDeclineReasons: array(string()),
  regularBonusBases: array(string()),
  jobContractOnHoldReasons: optionalWithDefault([], array(string())),
});

export type CompanyUnits = ExtractSchemaType<typeof companyUnitsSchema>;
