import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {CompensationReviewRequest, CompensationReviewRequestId} from 'domain/compensationReviewRequest/model';
import {getCompensationReviewRequestByIdFx} from 'domain/compensationReviewRequest/stores/main';
import {$compensationReviewRequests} from 'domain/compensationReviewRequest/stores/main/state';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';
import {useCompensationReviewRequestReadAccess} from './useCompensationReviewRequestReadAccess';

type ReturnShape = {
  data: CompensationReviewRequest | null;
  dataState: DataState;
  clientErrorStatus?: ClientErrorStatus;
};

export function useCompensationReviewRequest(id: CompensationReviewRequestId | undefined): ReturnShape {
  const readAccess = useCompensationReviewRequestReadAccess();
  const [clientErrorStatus, setClientErrorStatus] = useState<ClientErrorStatus>();
  const data = useStoreMap({
    store: $compensationReviewRequests,
    keys: [id],
    fn: ({byId}, [requestId]) => (requestId && byId[requestId]) || null,
  });
  const dataState = useStoreMap({
    store: $compensationReviewRequests,
    keys: [id],
    fn: ({byIdState}, [requestId]) => (requestId && byIdState[requestId]) || DataState.IDLE,
  });

  useEffect(() => {
    setClientErrorStatus(undefined);

    if (readAccess && id && !isLoadingOrLoaded(dataState)) {
      getCompensationReviewRequestByIdFx(id).catch((error: Error) => {
        if (error instanceof ClientError && error.status === ClientErrorStatus.NOT_FOUND) {
          setClientErrorStatus(error.status);
        } else {
          toaster.error(error.message);
        }
      });
    }
  }, [readAccess, id, setClientErrorStatus]);

  return {
    clientErrorStatus,
    dataState,
    data,
  };
}
