import {changeParent} from './changeParent';
import {checkIsMine} from './checkIsMine';
import {create} from './create';
import {deleteTeam} from './delete';
import {getAll} from './getAll';
import {requestMembership} from './requestMembership';
import {requestOutstaffMembership} from './requestOutstaffMembership';
import {revokeMembership} from './revokeMembership';
import {revokeOutstaffMembership} from './revokeOutstaffMembership';
import {update} from './update';

export const teamsApi = {
  changeParent,
  create,
  delete: deleteTeam,
  getAll,
  requestMembership,
  requestOutstaffMembership,
  revokeMembership,
  revokeOutstaffMembership,
  update,
  checkIsMine,
};
