import {CreateRegularBonusRecordConfig} from 'domain/regularBonusRecord/api/create';
import {DeleteRegularBonusRecordParams} from 'domain/regularBonusRecord/api/delete';
import {ReplaceRegularBonusRecordConfig} from 'domain/regularBonusRecord/api/replace';
import {UpdateRegularBonusRecordConfig} from 'domain/regularBonusRecord/api/update';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {UserId} from 'domain/user/model';
import {createEffect} from 'effector';

export const createRegularBonusRecordFx = createEffect<CreateRegularBonusRecordConfig, RegularBonusRecord[]>(
  'create new regular bonus record',
);
export const deleteRegularBonusRecordFx = createEffect<DeleteRegularBonusRecordParams, void>(
  'delete regular bonus record',
);
export const getRegularBonusRecordsFx = createEffect<UserId, RegularBonusRecord[]>('get user‘s regular bonus records');
export const getMyRegularBonusRecordsFx = createEffect<UserId, RegularBonusRecord[]>('get own regular bonus records');
export const getSubordinateRegularBonusRecordsFx = createEffect<UserId, RegularBonusRecord[]>(
  'get subordinate regular bonus records',
);
export const replaceRegularBonusRecordFx = createEffect<ReplaceRegularBonusRecordConfig, RegularBonusRecord[]>(
  'create new regular bonus record based on existing one',
);
export const updateRegularBonusRecordFx = createEffect<UpdateRegularBonusRecordConfig, RegularBonusRecord[]>(
  'update regular bonus record',
);
