import {QueryRequestPayload} from 'api/types';
import {offersApi} from 'domain/offer/api';
import {Offer} from 'domain/offer/model';
import {OffersSearchFilters} from 'domain/offer/model/filters';
import {
  approveNewcomerApplicationFx,
  createOfferFx,
  enhanceOfferFx,
  getOfferByIdFx,
  getOffersFx,
  getOffersWithCountFx,
  setOfferAcceptedFx,
  setOfferDeclinedFx,
  updateGeneralInfoFx,
  updateNewcomerLoginFx,
  updateNewcomerStartWorkingDateFx,
  updateOfferFx,
} from '.';

const getOffersWithoutCount = async (query: QueryRequestPayload<OffersSearchFilters>): Promise<Offer[]> => {
  const {items} = await getOffersWithCountFx(query);
  return items;
};

approveNewcomerApplicationFx.use(offersApi.approve);
createOfferFx.use(offersApi.create);
enhanceOfferFx.use(offersApi.enhance);
getOfferByIdFx.use(offersApi.getById);
getOffersFx.use(getOffersWithoutCount);
getOffersWithCountFx.use(offersApi.getAll);
setOfferAcceptedFx.use(offersApi.setAccepted);
setOfferDeclinedFx.use(offersApi.setDeclined);
updateGeneralInfoFx.use(offersApi.updateGeneral);
updateNewcomerLoginFx.use(offersApi.updateLogin);
updateNewcomerStartWorkingDateFx.use(offersApi.updateStartWorkingDate);
updateOfferFx.use(offersApi.update);
