export const getHorizontalScrollPosition = (el: Element): number => {
  const {clientWidth, scrollLeft, scrollWidth} = el;
  return scrollWidth === clientWidth ? 0 : scrollLeft / (scrollWidth - clientWidth);
};

export const getVerticalScrollPosition = (el: Element): number => {
  const {clientHeight, scrollTop, scrollHeight} = el;
  return scrollHeight === clientHeight ? 0 : scrollTop / (scrollHeight - clientHeight);
};

export function syncHorizontalScroll(source: Element, panes: Element[]): void {
  const position = getHorizontalScrollPosition(source);
  panes.forEach((pane) => {
    // eslint-disable-next-line no-param-reassign
    pane.scrollLeft = Math.round(position * (pane.scrollWidth - pane.clientWidth));
  });
}

export function syncVerticalScroll(source: Element, panes: Element[]): void {
  const position = getVerticalScrollPosition(source);
  panes.forEach((pane) => {
    // eslint-disable-next-line no-param-reassign
    pane.scrollTop = Math.round(position * (pane.scrollHeight - pane.clientHeight));
  });
}
