import {getDelimiterByString} from './getDelimiterByString';
import {Delimiter, delimiters} from '../types';
import {getTokens} from '../parseCsv/getTokens';
import {Token} from '../parseCsv/types';

export const detectDelimiter = (chunk: string): Delimiter | null => {
  const delimiterCount: Record<string, number> = {};
  let inString = false;
  let maxValue = 0;
  let maxChar = null;

  delimiters.forEach((delimiter) => {
    delimiterCount[delimiter] = 0;
  });

  // eslint-disable-next-line no-restricted-syntax
  for (const {token, index} of getTokens(chunk, delimiters)) {
    if (token === Token.NewLine || token === Token.EndOfFile) {
      break;
    }

    if (token === Token.Quote) {
      inString = !inString;
    }

    if (token === Token.Delimiter && !inString) {
      const char = chunk.charAt(index);
      delimiterCount[char]++;
      if (delimiterCount[char] > maxValue) {
        maxValue = delimiterCount[char];
        maxChar = char;
      }
    }
  }

  return maxChar ? getDelimiterByString(maxChar) : null;
};
