import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  loadingError: {
    defaultMessage: 'Failed to load salary data',
    description: 'Job contract salary tab: loading error',
  },
  deletionConfirmationTitle: {
    defaultMessage: 'Delete record',
    description: '[title] User job contract panel: delete salary record confirmation dialog',
  },
  deletionConfirmationText: {
    defaultMessage: 'Are you sure you want to delete this salary record?',
    description: 'User job contract panel: delete salary record confirmation text',
  },
  deletionConfirmationButton: {
    defaultMessage: 'Delete',
    description: '[button] User job contract panel: delete salary record confirmation button',
  },
});
