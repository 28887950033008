export type IntlConstructor = {
  supportedLocalesOf(locales: string | string[], options?: unknown): string[];
};

export function areIntlLocalesSupported(locales: string[], constructorsToCheck: IntlConstructor[]): boolean {
  if (locales.length === 0) {
    throw new RangeError('Expected locales to be non-empty array');
  }

  return constructorsToCheck.every(
    (constructor) => constructor && constructor.supportedLocalesOf(locales).length === locales.length,
  );
}
