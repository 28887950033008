import {IntlShape} from 'react-intl';
import {
  getHumanDateFormat,
  getHumanDateTimeFormat,
  getHumanTimeFormat,
  HumanDateFormat,
} from '@joomcode/deprecated-utils/humanDate';

type Options = {
  value: number | string | Date;
  utc?: boolean;
  format?: HumanDateFormat;
  intl: IntlShape;
};

export function formatHumanDate({intl, format = 'short', value, utc = false}: Options): string {
  return intl.formatDate(value, {...getHumanDateFormat(format), ...(utc ? {timeZone: 'utc'} : {})});
}

export function formatHumanTime({intl, format = 'short', value, utc = false}: Options): string {
  return intl.formatDate(value, {...getHumanTimeFormat(format), ...(utc ? {timeZone: 'utc'} : {})});
}

export function formatHumanDateTime({intl, format = 'short', value, utc = false}: Options): string {
  return intl.formatDate(value, {...getHumanDateTimeFormat(format), ...(utc ? {timeZone: 'utc'} : {})});
}
