import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  approve: {
    defaultMessage: 'Approve',
    description: 'Role binding action',
  },
  cancel: {
    defaultMessage: 'Cancel',
    description: 'Role binding action',
  },
  reject: {
    defaultMessage: 'Reject',
    description: 'Role binding action',
  },
  revoke: {
    defaultMessage: 'Revoke',
    description: 'Role binding action',
  },
  approveConfirmationTitle: {
    defaultMessage: 'Approve access request',
    description: '[title] Approve role binding request confirmation title',
  },
  approveConfirmationText: {
    defaultMessage:
      'Are you sure you want to approve access to <emphasis>{resourceName}</emphasis> for <emphasis>{roleName}</emphasis> role in <emphasis>{teamName}</emphasis> team?',
    description: 'Approve role binding request confirmation text',
  },
  approvePropagatingConfirmationText: {
    defaultMessage:
      'Are you sure you want to approve access to <emphasis>{resourceName}</emphasis> for <emphasis>{roleName}</emphasis> role in <emphasis>{teamName}</emphasis> team and <emphasis>all its subteams</emphasis>?',
    description: 'Approve role binding request with propagation confirmation text',
  },
  approveConfirmationButton: {
    defaultMessage: 'Approve',
    description: '[button] Approve role binding request confirmation button text',
  },
  cancelConfirmationTitle: {
    defaultMessage: 'Cancel access request',
    description: '[title] Cancel role binding request confirmation dialog',
  },
  cancelConfirmationText: {
    defaultMessage: 'Are you sure you want to cancel this access request?',
    description: 'Cancel role binding request confirmation text',
  },
  cancelConfirmationButton: {
    defaultMessage: 'Cancel request',
    description: '[button] Cancel role binding request confirmation button text',
  },
  rejectConfirmationTitle: {
    defaultMessage: 'Reject access request',
    description: '[title] Reject role binding request confirmation dialog',
  },
  rejectConfirmationText: {
    defaultMessage:
      'Are you sure you want to reject access to <emphasis>{resourceName}</emphasis> for <emphasis>{roleName}</emphasis> role in <emphasis>{teamName}</emphasis> team?',
    description: 'Reject role binding request confirmation text',
  },
  rejectPropagatingConfirmationText: {
    defaultMessage:
      'Are you sure you want to reject access to <emphasis>{resourceName}</emphasis> for <emphasis>{roleName}</emphasis> role in <emphasis>{teamName}</emphasis> team and <emphasis>all its subteams</emphasis>?',
    description: 'Reject role binding request with propagation confirmation text',
  },
  rejectConfirmationButton: {
    defaultMessage: 'Reject',
    description: '[button] Reject role binding request confirmation button',
  },
  revokeConfirmationTitle: {
    defaultMessage: 'Revoke access',
    description: '[title] Revoke role binding confirmation dialog',
  },
  revokeConfirmationText: {
    defaultMessage:
      'Are you sure you want to revoke access to <emphasis>{resourceName}</emphasis> from <emphasis>{roleName}</emphasis> role in <emphasis>{teamName}</emphasis> team?',
    description: 'Revoke role binding confirmation text',
  },
  revokePropagatingConfirmationText: {
    defaultMessage:
      'Are you sure you want to revoke access to <emphasis>{resourceName}</emphasis> from <emphasis>{roleName}</emphasis> role in <emphasis>{teamName}</emphasis> team and <emphasis>all its subteams</emphasis>?',
    description: 'Revoke role binding with propagation confirmation text',
  },
  revokeConfirmationButton: {
    defaultMessage: 'Revoke',
    description: '[button] Revoke role binding confirmation button',
  },
});
