import {defineMessages} from 'react-intl';
import {RegularBonusType} from '.';

export const regularBonusTypes = defineMessages<RegularBonusType>({
  [RegularBonusType.ABSOLUTE]: {
    defaultMessage: 'Absolute',
    description: 'Regular bonus type',
  },
  [RegularBonusType.PERCENT]: {
    defaultMessage: 'Percent',
    description: 'Regular bonus type',
  },
});
