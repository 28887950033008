import {intranetApi} from 'apiClient';
import {
  CompensationRequest,
  CompensationRequestId,
  compensationRequestSchema,
} from 'domain/compensations/request/model';

export const approveAsManager = async (compensationRequestId: CompensationRequestId): Promise<CompensationRequest> => {
  const {data} = await intranetApi.post('/v1/compensations/requests/approveAsManager', undefined, {
    params: {id: compensationRequestId},
  });

  return compensationRequestSchema.runWithException(data);
};
