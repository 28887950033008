import {useCallback, useEffect, useMemo, useState} from 'react';
import {defaultFeatureAvailability} from './defaultAvailability';
import {Feature, StoredFeatures} from './model';
import {getStoredFeatures, storeFeatures} from './storage';

type FeatureApi = {
  isAvailable: boolean;
  setAvailability: (isAvailable: boolean) => void;
};

export function useFeature(feature: Feature): FeatureApi {
  const [features, setFeatures] = useState<StoredFeatures>(getStoredFeatures);
  const setAvailability = useCallback(
    (isAvailable: boolean) => {
      setFeatures((oldFeatures) => ({
        ...oldFeatures,
        [feature]: isAvailable,
      }));
    },
    [feature],
  );

  useEffect(() => {
    storeFeatures(features);
  }, [features]);

  return useMemo(
    () => ({
      isAvailable: features[feature] ?? defaultFeatureAvailability[feature],
      setAvailability,
    }),
    [feature, features, setAvailability],
  );
}
