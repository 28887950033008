import {DataState} from '@joomcode/deprecated-utils/dataState';
import {CommonFieldProps, composeValidators, getFieldValidator, useField, validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {useOutstaffUsers} from 'domain/outstaffUser/hooks/useOutstaffUsers';
import {User, UserId} from 'domain/user/model';
import {UserAutocomplete} from 'domain/user/widgets/Autocomplete';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

export type FieldOutstaffUserValue = UserId | null;
export type Props = CommonFieldProps<FieldOutstaffUserValue>;

export function FieldOutstaffUser({
  name,
  label,
  hint,
  required,
  disabled,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const {dataState, outstaffUsers, outstaffUsersById} = useOutstaffUsers();
  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {
    input: {onChange, value, ...input},
    meta,
  } = useField<FieldOutstaffUserValue>(name, {
    allowNull: true,
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
  });

  const handleChange = useCallback(
    (user: User | null) => {
      onChange(user?.id ?? null);
    },
    [onChange],
  );

  return (
    <FormControl
      hint={hint}
      label={label}
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <UserAutocomplete
          disabled={dataState !== DataState.LOADED}
          items={outstaffUsers}
          onChange={handleChange}
          value={value ? outstaffUsersById[value] ?? null : null}
          {...formControlProps}
          {...restProps}
          {...input}
        />
      )}
    </FormControl>
  );
}
