import {identity} from '../../function/identity';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function arrayToObject<T, R, K extends string = string>(
  arr: readonly T[],
  keyHandler: (item: T, index: number) => K,
): Record<K, T>;
function arrayToObject<T, R, K extends string = string>(
  arr: readonly T[],
  keyHandler: (item: T, index: number) => K,
  valueHandler: (item: T, index: number) => R,
): Record<K, R>;
function arrayToObject<T, R, K extends string = string>(
  arr: readonly T[],
  keyHandler: (item: T, index: number) => K,
  valueHandler: (item: T, index: number) => T | R = identity,
): Record<K, T | R> {
  return arr.reduce(
    (acc, item, index) => {
      acc[keyHandler(item, index)] = valueHandler(item, index);
      return acc;
    },
    {} as Record<K, T | R>,
  );
}

export {arrayToObject};
