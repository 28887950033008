import {Panel} from '@joomcode/joom-ui/Panel';
import {EmptyMessage} from 'components/ui/EmptyMessage';
import {Markdown} from 'components/ui/Markdown';
import {Team} from 'domain/team/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  team: Team;
};

export function TeamDescriptionPanel({team}: Props) {
  const {description} = team;
  const intl = useIntl();

  return (
    <Panel stretchHeight>
      <Panel.Header title={intl.formatMessage(messages.title)} withSeparator />
      <div className={styles.contentWrap}>
        {description ? (
          <div className={styles.description}>
            <Markdown source={description} />
          </div>
        ) : (
          <EmptyMessage>{intl.formatMessage(messages.empty)}</EmptyMessage>
        )}
      </div>
    </Panel>
  );
}
