import {PageTabs} from 'components/ui/PageTabs';
import {StockOptionViewMode} from 'domain/stockOption/viewMode/model';
import {stockOptionViewModes} from 'domain/stockOption/viewMode/model/messages';
import React from 'react';
import {useIntl} from 'react-intl';
import {stockOptionsUrls} from 'routes/stockOptions/urls';

type Props = {
  availableModes: StockOptionViewMode[];
  activeMode?: StockOptionViewMode;
};

export function StockOptionModeTabs({availableModes, activeMode}: Props) {
  const intl = useIntl();

  return (
    <PageTabs>
      {availableModes.map((mode) => (
        <PageTabs.Tab
          title={intl.formatMessage(stockOptionViewModes[mode])}
          path={stockOptionsUrls.root({mode})}
          active={activeMode === mode}
          key={mode}
        />
      ))}
    </PageTabs>
  );
}
