import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {JobInfoRecord, jobInfoRecordSchema} from 'domain/jobInfoRecord/model';
import {JobInfoRecordDiff} from 'domain/jobInfoRecord/model/diff';
import {UserId} from 'domain/user/model';

export type AddParams = {
  userId: UserId;
  diff: JobInfoRecordDiff;
};

export const add = async ({userId, diff}: AddParams): Promise<JobInfoRecord[]> => {
  const {data} = await intranetApi.post('v1/users/jobInfo/addRecord', diff, {
    params: {userId},
  });

  return array(jobInfoRecordSchema).runWithException(data);
};
