import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'General info',
    description: '[title] Offer page: general info panel title',
  },
  buttonEdit: {
    defaultMessage: 'Edit',
    description: '[button] Offer page: edit general info button',
  },
});

export const labels = defineMessages({
  phone: {
    defaultMessage: 'Phone number',
    description: 'Offer general info panel: label',
  },
  referrers: {
    defaultMessage: '{count, plural, one {Referrer} other {Referrers}}',
    description: 'Offer general info panel: label',
  },
  status: {
    defaultMessage: 'Status',
    description: 'Offer general info panel: label',
  },
  declineReasons: {
    defaultMessage: 'Decline reasons',
    description: 'Offer general info panel: label',
  },
  recruiter: {
    defaultMessage: 'Recruiter',
    description: 'Offer general info panel: label',
  },
  source: {
    defaultMessage: 'Source',
    description: 'Offer general info panel: label',
  },
  priority: {
    defaultMessage: 'Priority',
    description: 'Offer general info panel: label',
  },
  ticketLink: {
    defaultMessage: 'Ticket',
    description: 'Offer general info panel: label',
  },
  sentDate: {
    defaultMessage: 'Sent date',
    description: 'Offer general info panel: label',
  },
  lastUpdateDate: {
    defaultMessage: 'Last update date',
    description: 'Offer general info panel: label',
  },
  linkToInterviewFeedback: {
    defaultMessage: 'Link to interviewers’ feedback',
    description: 'Offer general info panel: label',
  },
  formTemplate: {
    defaultMessage: 'Form template',
    description: 'Offer general info panel: label',
  },
});
