import {LoggerFactory, jsonToLevelConfig} from '@joomcode/joom-logger-next';
import {ConsoleProvider, EcsConsoleProvider, SentryProvider} from '@joomcode/joom-logger-next/providers';
import * as Sentry from '@sentry/react';

let env: Window['intranetEnv'];

if (typeof window !== 'undefined') {
  env = window.intranetEnv;
} else {
  env = process.env as Window['intranetEnv'];
}

export const loggerFactory = new LoggerFactory();
const consoleProvider = new ConsoleProvider(
  jsonToLevelConfig({
    '*': 'OFF',
    Transport: env.APP_ENV === 'production' ? 'OFF' : 'WARN',
  }),
);
const ecsConsoleProvider = new EcsConsoleProvider(
  jsonToLevelConfig({
    '*': 'OFF',
    Server: 'INFO',
  }),
);
const sentryProvider = new SentryProvider(Sentry, jsonToLevelConfig({'*': 'ERROR'}));

loggerFactory.addProvider(ecsConsoleProvider);
loggerFactory.addProvider(sentryProvider);
loggerFactory.addProvider(consoleProvider);

export const analyticsLogger = loggerFactory.getLogger('analytics');
export const usersnapLogger = loggerFactory.getLogger('usersnap');
