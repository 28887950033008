import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Functions and levels',
    description: '[title] Market data bulk upload panel title',
  },
});

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.PARSING_STATUS]: {
    defaultMessage: 'Parsed',
    description: 'Market data bulk upload table column name',
  },
  [ColumnId.ROW_NUMBER]: {
    defaultMessage: 'Row #',
    description: 'Market data bulk upload table column name',
  },
  [ColumnId.USER]: {
    defaultMessage: 'Joomer',
    description: 'Market data bulk upload table column name',
  },
  [ColumnId.EMAIL]: {
    defaultMessage: 'Email',
    description: 'Market data bulk upload table column name',
  },
  [ColumnId.USER_FUNCTION]: {
    defaultMessage: 'Function',
    description: 'Market data bulk upload table column name',
  },
  [ColumnId.LEVEL_CODE]: {
    defaultMessage: 'Level code',
    description: 'Market data bulk upload table column name',
  },
  [ColumnId.EFFECTIVE_DATE]: {
    defaultMessage: 'Effective date',
    description: 'Market data bulk upload table column name',
  },
});
