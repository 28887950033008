import {ReactComponent as ArrowRightIcon} from '@joomcode/joom-ui/icons/core/arrowRightSmall.svg';
import {StyledLink} from 'components/ui/StyledLink';
import React from 'react';
import styles from './breadcrumbs.css';

type ItemProps = React.PropsWithChildren<{
  href: string;
}>;

type Props = {
  children: React.ReactNode[];
};

export function Breadcrumbs({children}: Props) {
  return <div className={styles.breadcrumbs}>{children}</div>;
}

function BreadcrumbItem({children, href}: ItemProps) {
  return (
    <>
      <StyledLink className={styles.item} to={href}>
        {children}
      </StyledLink>
      <span className={styles.divider}>
        <ArrowRightIcon />
      </span>
    </>
  );
}

Breadcrumbs.Item = BreadcrumbItem;
