import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {useCallback, useMemo, useState} from 'react';
import {CheckboxGroup} from '../../../CheckboxGroup';
import {RemoveAutoFocusInside} from '../../../RemoveAutoFocusInside';
import {RemoveAutoScrollInside} from '../../../RemoveAutoScrollInside';
import {createLocator} from '../../../create-locator';
import {Control} from '../../Control';
import {ControlRenderProps, Value} from '../../types';
import {ListOption} from '../listOptions';
import {MultiSelectFilterOptions} from './types';

type Props<T> = ControlRenderProps<T[], MultiSelectFilterOptions<T>>;

const itemToString = (value: string) => value;

export function MultiSelectControl<T>({
  name,
  value,
  onSubmit,
  onClose,
  options: {options, optionValueToString = String, ...rest},
}: Props<T>) {
  const locator = createLocator(rest);
  const [localValue, setLocalValue] = useState<Value<T[]>>(value);
  const optionsById: Record<string, ListOption<T>> = useMemo(
    () => arrayToObject(options, (item) => optionValueToString(item.value)),
    [options],
  );

  const checkboxGroupOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        value: optionValueToString(option.value),
      })),
    [options],
  );
  const checkboxGroupValue = useMemo(
    () => localValue?.map((item) => optionValueToString(item)),
    [localValue, optionValueToString],
  );

  const handleChange = useCallback(
    (valueInner: string[]) => {
      setLocalValue(valueInner.map((id) => optionsById[id]?.value).filter(isNotNullish));
    },
    [optionsById, setLocalValue],
  );

  return (
    <Control onClose={onClose} onSubmit={() => onSubmit(localValue)} {...locator.control()}>
      <RemoveAutoFocusInside>
        <RemoveAutoScrollInside>
          <CheckboxGroup
            ariaLabel={name}
            itemToString={itemToString}
            options={checkboxGroupOptions}
            values={checkboxGroupValue ?? []}
            onChange={handleChange}
            {...locator.checkboxGroup()}
          />
        </RemoveAutoScrollInside>
      </RemoveAutoFocusInside>
    </Control>
  );
}
