import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {CrResult} from 'domain/compaRatio/model';
import {MarketLevelPosition} from 'domain/compaRatio/model/marketLevelPosition';
import React from 'react';
import {CrValue} from '../Value';

type Props = {
  cr: CrResult;
};

const intentByMarketLevelPosition: Record<MarketLevelPosition, BadgeIntent> = {
  [MarketLevelPosition.AT_MARKET_LEVEL]: 'positive',
  [MarketLevelPosition.TOP_OF_MARKET_LEVEL]: 'warning',
  [MarketLevelPosition.BOTTOM_OF_MARKET_LEVEL]: 'warning',
  [MarketLevelPosition.ABOVE_MARKET_LEVEL]: 'negative',
  [MarketLevelPosition.BELOW_MARKET_LEVEL]: 'negative',
};

export function CrLabel({cr}: Props) {
  const intent = cr.marketLevelPosition ? intentByMarketLevelPosition[cr.marketLevelPosition] : 'neutral';

  return (
    <Badge intent={intent} noMargin>
      <CrValue cr={cr} />
    </Badge>
  );
}
