import {DataState, getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {Permission} from 'domain/permission/model';
import {updateSelfFx} from 'domain/self/stores/main';
import {useFormModeAccess} from 'domain/user/hooks/useFormModeAccess';
import {useUserLoader} from 'domain/user/hooks/useUserLoader';
import {useUserPrivateInfo} from 'domain/user/hooks/useUserPrivateInfo';
import {UserFull} from 'domain/user/model';
import {UserDiff} from 'domain/user/model/diff';
import {UserFormMode} from 'domain/user/model/formMode';
import {updateUserFx} from 'domain/user/stores/main';
import {EditPageTabs} from 'domain/user/widgets/EditPageTabs';
import {UserForm} from 'domain/user/widgets/Form';
import {generalMessages} from 'i18n/messages/general';
import {NotFoundPage} from 'pages/NotFound';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps, useHistory, useLocation, useParams} from 'react-router-dom';
import {pageTitles} from 'routes/users/titles';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import styles from './styles.css';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function UserEditPage({selfUser}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const history = useHistory();
  const {login} = useParams<{login: string}>();
  const {search} = useLocation();

  const isSelfUser = selfUser.login === login;
  const canUpdateUsers = acl.hasPermission(Permission.USER_WRITE);

  const companyUnits = useCompanyUnits();
  const user = useUserLoader(login);
  const userPrivateInfo = useUserPrivateInfo(user.data?.id);

  const onSubmit = useCallback(
    (userData: UserDiff) => {
      if (!user.data) {
        return Promise.reject(new Error('Attempt to update unknown user'));
      }
      const {id} = user.data;
      const userUpdatePromise = canUpdateUsers ? updateUserFx({id, userData}) : updateSelfFx(userData);
      return userUpdatePromise
        .then(() => {
          history.push(usersUrls.user({login}));
        })
        .catch(toaster.interceptThenThrowError);
    },
    [login, user.data, canUpdateUsers],
  );

  const formModeAccess = useFormModeAccess({userId: user.data?.id});

  const modeParam = new URLSearchParams(search).get('mode') as UserFormMode;
  const mode = formModeAccess[modeParam] ? modeParam : UserFormMode.GENERAL;

  if (!formModeAccess[mode] || user.data?.isTerminated) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler
      data={
        user.data &&
        companyUnits.units && {
          userData: user.data,
          companyUnitsData: companyUnits.units,
          userPrivateInfoData: userPrivateInfo.data,
        }
      }
      entityError={user.error}
      state={getConsolidatedDataState(
        user.dataState,
        companyUnits.dataState,
        userPrivateInfo.isVisibleToCurrentUser ? userPrivateInfo.dataState : DataState.LOADED,
      )}
    >
      {({userData, companyUnitsData, userPrivateInfoData}) => {
        return (
          <div className={styles.root}>
            <Page
              title={intl.formatMessage(pageTitles.userEdit)}
              breadcrumbs={[
                {
                  title: intl.formatMessage(generalMessages.fullName, {
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                  }),
                  href: usersUrls.user({login}),
                },
              ]}
              headerContent={<EditPageTabs activeMode={mode} login={login} userId={user.data?.id} />}
            >
              <UserForm
                isSelfUser={isSelfUser}
                mode={mode}
                user={userData}
                privateInfo={userPrivateInfoData}
                companyUnits={companyUnitsData}
                onSubmit={onSubmit}
              />
            </Page>
          </div>
        );
      }}
    </EntityPageStateHandler>
  );
}
