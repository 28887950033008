import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CommonFieldProps, FieldSelect} from '@joomcode/joom-form';
import {OneTimeBonusCondition} from 'domain/oneTimeBonusRecord/model/condition';
import {formatOneTimeBonusCondition} from 'domain/oneTimeBonusRecord/model/condition/formatter';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

type Props = CommonFieldProps<OneTimeBonusCondition>;

export function FieldOneTimeBonusCondition(props: Props) {
  const intl = useIntl();
  const conditionToString = useCallback(
    (condition: OneTimeBonusCondition) => formatOneTimeBonusCondition(condition, intl),
    [intl],
  );

  return (
    <FieldSelect<OneTimeBonusCondition>
      items={getEnumValues(OneTimeBonusCondition)}
      itemToString={conditionToString}
      renderItem={conditionToString}
      {...props}
    />
  );
}
