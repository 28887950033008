import cn from 'classnames';
import React, {FC} from 'react';
import styles from './index.css';

type Props = {
  title?: string;
  isCompact?: boolean;
  children: React.ReactNode;
};

export const SideMenuSection: FC<Props> = ({title, isCompact, children}) => {
  return (
    <>
      <div className={cn(styles.separator, styles.topSeparator)} />
      {title && !isCompact && <div className={styles.title}>{title}</div>}
      {children}
      <div className={cn(styles.separator, styles.bottomSeparator)} />
    </>
  );
};
