import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';

const getInterviewLinkGeneric =
  (endpoint: string) =>
  async (id: UserId): Promise<string> => {
    const {data} = await intranetApi.get(endpoint, {
      params: {id},
    });

    return string().runWithException(data);
  };

const getInterviewLinkAsHr = getInterviewLinkGeneric('/v1/users/getLinkToInterviewFeedbackAsHr');
const getInterviewLinkAsManager = getInterviewLinkGeneric('/v1/users/getLinkToInterviewFeedbackAsManager');

export const getInterviewLink = createPermissionDependentHandler(
  [{permission: Permission.USER_INTERVIEW_LINK_READ, handler: getInterviewLinkAsHr}],
  getInterviewLinkAsManager,
);
