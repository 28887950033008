import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.COMPENSATIONS_RECORDED]: {
    defaultMessage: 'Compensation',
    description: 'Compensation review request table column name',
  },
  [ColumnId.CREATED_AT]: {
    defaultMessage: 'Created',
    description: 'Compensation review request table column name',
  },
  [ColumnId.CREATOR]: {
    defaultMessage: 'Creator',
    description: 'Compensation review request table column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Status',
    description: 'Compensation review request table column name',
  },
  [ColumnId.STOCK_OPTIONS_RECORDED]: {
    defaultMessage: 'Options',
    description: 'Compensation review request table column name',
  },
  [ColumnId.TYPE]: {
    defaultMessage: 'Type',
    description: 'Compensation review request table column name',
  },
  [ColumnId.USER]: {
    defaultMessage: 'Joomer',
    description: 'Compensation review request table column name',
  },
});

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No compensation review requests',
    description: 'Salary ranges table: empty',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load compensation review requests',
    description: 'Compensation review request table: error',
  },
});
