import {noop} from '@joomcode/deprecated-utils/function';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {MarketDataRecord} from 'domain/marketDataRecord/model';
import {MarketDataRecordDiff} from 'domain/marketDataRecord/model/diff';
import {createMarketDataRecordFx, updateMarketDataRecordFx} from 'domain/marketDataRecord/stores/main';
import {MarketDataRecordForm} from 'domain/marketDataRecord/widgets/Form';
import {UserId} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  userId: UserId;
  recordToUpdate?: MarketDataRecord;
  isOpen: boolean;
  onClose: () => void;
};

export function MarketDataRecordDialog({userId, recordToUpdate, isOpen, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const {units, dataState} = useCompanyUnits();
  const renderError = useCallback(
    () => (
      <Dialog.Body withDefaultPadding>
        <Error message={intl.formatMessage(messages.loadingError)} />
      </Dialog.Body>
    ),
    [intl],
  );

  const submitTask = useAsyncTask(
    (diff: MarketDataRecordDiff) => {
      if (recordToUpdate) {
        return updateMarketDataRecordFx({id: recordToUpdate.id, userId, diff})
          .then(onClose)
          .catch(toaster.interceptThenThrowError);
      }
      return createMarketDataRecordFx({userId, diff}).then(onClose).catch(toaster.interceptThenThrowError);
    },
    [userId, recordToUpdate, onClose],
  );

  return (
    <Dialog
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      isOpen={isOpen}
      onClose={submitTask.isPerforming ? noop : onClose}
      width='min(90vw, 48rem)'
    >
      <Form<MarketDataRecord> onSubmit={submitTask.perform} subscription={{submitting: true, invalid: true}}>
        {({handleSubmit, submitting, invalid}) => (
          <StateHandler data={units} state={dataState} renderError={renderError}>
            {(companyUnits) => (
              <>
                <Dialog.Header>
                  {intl.formatMessage(recordToUpdate ? messages.titleUpdate : messages.titleAdd)}
                </Dialog.Header>
                <Dialog.Body withDefaultPadding>
                  <MarketDataRecordForm
                    formId={formId}
                    recordToUpdate={recordToUpdate}
                    companyUnits={companyUnits}
                    onSubmit={handleSubmit}
                    submitting={submitting}
                  />
                </Dialog.Body>
                <Dialog.Footer>
                  <ButtonGroup spaced size='l'>
                    <Button kind='text' intent='neutral' onClick={onClose}>
                      {intl.formatMessage(messages.cancelButton)}
                    </Button>
                    <Button
                      form={formId}
                      kind='primary'
                      intent='primary'
                      type='submit'
                      disabled={invalid}
                      loading={submitting}
                    >
                      {intl.formatMessage(messages.submitButton)}
                    </Button>
                  </ButtonGroup>
                </Dialog.Footer>
              </>
            )}
          </StateHandler>
        )}
      </Form>
    </Dialog>
  );
}
