import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {buildDownloadLink} from 'domain/timeOff/request/api/buildDownloadLink';
import {stringToDate} from 'models/system/formattedDate';
import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import uuid from 'uuid/v4';
import {messages} from './messages';
import {FormState} from './state';
import styles from './styles.css';
import {validateForm} from './validate';

type Props = {
  onClose(): void;
};

export function TimeOffRequestsDownloadDropdown({onClose}: Props) {
  const intl = useIntl();

  // Hack: don't memoize portal ids and create new portals on each render instead.
  // While reusing the same portal it gets 'data-focus-on-hidden' attribute after parent dropdown is closed.
  // This leads to the calendar being irresponsive on re-open.
  const startPortalId = uuid();
  const endPortalId = uuid();

  const onSubmit = useCallback(
    ({start, end}: FormState) => {
      if (!start || !end) {
        return;
      }
      window.open(buildDownloadLink({start, end}));
      onClose();
    },
    [onClose],
  );
  return (
    <div className={styles.dropdown}>
      <Form<FormState> onSubmit={onSubmit} validate={validateForm}>
        {({handleSubmit, invalid, values}) => (
          <form onSubmit={handleSubmit}>
            <Panel.Section>
              <Panel.Content withPadding>
                <div className={styles.content}>
                  <Grid>
                    <Grid.Item xl={6}>
                      <FieldDate
                        name='start'
                        label={intl.formatMessage(messages.startLabel)}
                        required
                        reserveSpaceForError={false}
                        selectsStart
                        endDate={stringToDate(values.end || '') ?? undefined}
                        portalId={startPortalId}
                      />
                    </Grid.Item>
                    <Grid.Item xl={6}>
                      <FieldDate
                        name='end'
                        label={intl.formatMessage(messages.endLabel)}
                        required
                        reserveSpaceForError={false}
                        selectsEnd
                        startDate={stringToDate(values.start || '') ?? undefined}
                        portalId={endPortalId}
                      />
                    </Grid.Item>
                  </Grid>
                </div>
              </Panel.Content>
            </Panel.Section>
            <Panel.Section>
              <Panel.Content withPadding>
                <div className={styles.buttons}>
                  <ButtonGroup spaced align='right' size='m'>
                    <Button kind='primary' intent='neutral' onClick={onClose}>
                      {intl.formatMessage(messages.buttonCancel)}
                    </Button>
                    <Button kind='primary' intent='primary' type='submit' disabled={invalid}>
                      {intl.formatMessage(messages.buttonDownload)}
                    </Button>
                  </ButtonGroup>
                </div>
              </Panel.Content>
            </Panel.Section>
          </form>
        )}
      </Form>
    </div>
  );
}
