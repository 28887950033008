import React from 'react';
import styles from './styles.css';

export type Props = {
  title: React.ReactNode;
  value: React.ReactNode;
  hint: React.ReactNode;
};

export function SideCalculator({title, value, hint}: Props) {
  return (
    <div className={styles.root}>
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.value}>{value}</div>
      <div className={styles.hint}>{hint}</div>
    </div>
  );
}
