import {Panel} from '@joomcode/joom-ui/Panel';
import {Offer} from 'domain/offer/model';
import {VersionedOfferCompensations} from 'domain/offer/model/compensation';
import {VersionedOfferStockOptions} from 'domain/offer/model/stockOption';
import {OfferVersionsDiff} from 'domain/offer/widgets/VersionsDiff';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  offer: Offer;
  compensations: VersionedOfferCompensations;
  stockOptions: VersionedOfferStockOptions;
};

export function OfferHistoryPanel({offer, compensations, stockOptions}: Props) {
  const intl = useIntl();

  return (
    <Panel
      title={intl.formatMessage(messages.title)}
      stretchHeight
      withPadding
      withMarginBottom
      toolbarTop={<div className={styles.indent} />}
    >
      <OfferVersionsDiff offer={offer} compensations={compensations} stockOptions={stockOptions} />
    </Panel>
  );
}
