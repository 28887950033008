import {ReactComponent as EllipsisIcon} from '@joomcode/joom-ui/icons/core/ellipsisHorizontal.svg';
import React from 'react';
import styles from './styles.css';

export const MenuButton = React.forwardRef(
  (props: JSX.IntrinsicElements['button'], ref: React.Ref<HTMLButtonElement>) => {
    return (
      <button className={styles.menuButton} type='button' {...props} ref={ref}>
        <EllipsisIcon />
      </button>
    );
  },
);
