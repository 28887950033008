import {DataState} from '@joomcode/deprecated-utils/dataState';
import {detectUserLocale} from '@joomcode/deprecated-utils/intl/locale/detectUserLocale';
import {config} from 'config';
import {createStore} from 'effector';
import {localeCookie} from 'i18n/locale/cookie';
import {Locale} from 'i18n/locale/types';
import {switchLocaleFx} from '.';

type State = {
  state: DataState;
  locale: Locale;
};

export const $locale = createStore<State>({
  state: DataState.LOADED,
  locale: localeCookie.restore() || detectUserLocale(config.locales, config.defaultLocale),
})
  .on(switchLocaleFx, (state) => ({
    ...state,
    state: DataState.LOADING,
  }))
  .on(switchLocaleFx.fail, (state) => ({
    ...state,
    state: DataState.FAILED,
  }))
  .on(switchLocaleFx.done, (state, {params}) => ({
    ...state,
    state: DataState.LOADED,
    locale: params,
  }));

$locale.watch((state) => {
  localeCookie.store(state.locale);
});
