import {defineMessages} from 'react-intl';
import {OperatingSystem} from './index';

export const operatingSystemLabels = defineMessages<OperatingSystem>({
  [OperatingSystem.LINUX]: {
    defaultMessage: 'Linux',
    description: 'Operating system',
  },
  [OperatingSystem.MAC]: {
    defaultMessage: 'macOS',
    description: 'Operating system',
  },
  [OperatingSystem.WINDOWS]: {
    defaultMessage: 'Windows',
    description: 'Operating system',
  },
});
