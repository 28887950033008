import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {Permission} from 'domain/permission/model';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserId} from 'domain/user/model';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import {getMyPrivateInfoFx, getUserPrivateInfoByIdFx} from 'domain/user/stores/privateInfo';
import {$usersPrivateInfo} from 'domain/user/stores/privateInfo/state';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  canEdit: boolean;
  data: UserPrivateInfo | undefined;
  dataState: DataState;
  error?: Error;
  isVisibleToCurrentUser: boolean;
};

type Options = {
  enabled?: boolean;
  force?: boolean;
  handleError?: boolean;
};

export const useUserPrivateInfo = (
  userId?: UserId,
  {enabled = true, force = false, handleError = true}: Options = {},
): ReturnShape => {
  const acl = useAcl();
  const selfUserId = useSelfUserId();

  const isSelfUser = selfUserId === userId;
  const canReadOthersPrivateInfo = acl.hasPermission(Permission.USER_PRIVATE_INFO_READ);
  const canSeePrivateInfo = isSelfUser || canReadOthersPrivateInfo;

  const canEdit = acl.hasPermission(Permission.USER_PRIVATE_INFO_WRITE);

  const [error, setError] = useState<Error>();
  const [data, dataState] = useStoreMap({
    store: $usersPrivateInfo,
    keys: [userId],
    fn: ({byUserId, dataStateByUserId}, [id]) => [
      id ? byUserId[id] : undefined,
      (id && dataStateByUserId[id]) || DataState.IDLE,
    ],
  });

  useEffect(() => {
    if (userId && canSeePrivateInfo && enabled && (!isLoadingOrLoaded(dataState) || force)) {
      (canReadOthersPrivateInfo ? getUserPrivateInfoByIdFx : getMyPrivateInfoFx)({userId}).catch(setError);
    }
  }, [userId, canSeePrivateInfo, canReadOthersPrivateInfo, enabled, force]);

  useEffect(() => {
    if (error && handleError) {
      toaster.error(error.message);
    }
  }, [error, handleError]);

  return {canEdit, data, dataState, error, isVisibleToCurrentUser: canSeePrivateInfo};
};
