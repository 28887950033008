import {LevelCode} from '.';

// TODO INTRANET-2190 Remove this array, use getEnumValues(LevelCode) instead
export const availableLevelCodeValues = [
  LevelCode.INTERN,
  LevelCode.P_1,
  LevelCode.P_2,
  LevelCode.P_2_1,
  LevelCode.P_2_2,
  LevelCode.P_3,
  LevelCode.P_3_1,
  LevelCode.P_3_2,
  LevelCode.P_4,
  LevelCode.P_5,
  LevelCode.P_6,
  LevelCode.M_1,
  LevelCode.M_2,
  LevelCode.M_3,
  LevelCode.M_4,
  LevelCode.M_5,
  LevelCode.TOP,
];
