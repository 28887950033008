import {createContext, useContext} from 'react';
import {DialogPrivateContextValue, DialogPublicContextValue} from './types';

export const DialogPrivateContext = createContext<DialogPrivateContextValue | undefined>(undefined);

export const DialogPublicContext = createContext<DialogPublicContextValue | undefined>(undefined);

export function useDialogPrivateContext(): DialogPrivateContextValue {
  const context = useContext(DialogPrivateContext);

  if (!context) {
    throw new Error('DialogPrivateContext must be provided');
  }

  return context;
}

export function useDialogContext(): DialogPublicContextValue {
  const context = useContext(DialogPublicContext);

  if (!context) {
    throw new Error('DialogPublicContext must be provided');
  }

  return context;
}
