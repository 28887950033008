import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'One-time bonus',
    description: '[title] Compensation review request form: section title',
  },
  hint: {
    defaultMessage:
      'A single payment given to an employee on top of their regular salary. First, discuss the necessity with C&B.',
    description: 'Compensation review request: one-time bonus title hint',
  },
});
