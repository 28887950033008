import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  comment: {
    defaultMessage: 'Comment',
    description: 'Office policy record form: field label',
  },
  effectiveDate: {
    defaultMessage: 'Effective date',
    description: 'Office policy record form: field label',
  },
  policy: {
    defaultMessage: 'Policy',
    description: 'Office policy record form: field label',
  },
});
