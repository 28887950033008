import cn from 'classnames';
import {useTask} from '@joomcode/deprecated-utils/react/useTask';
import React, {memo, useCallback, useEffect} from 'react';
import {createLocator, getLocatorParameters, type Mark} from '../../../create-locator';
import {Spinner} from '../../../Spinner';
import {RowDisclosureContext, useRowDisclosure} from '../../contexts/Disclosure';
import {useSelection} from '../../contexts/Selection';
import type {StickyColumnOffset, Column, NestedRowsOptions, RowLocator} from '../../types';
import {getFlexibleColumns} from '../../utils/getFlexibleColumns';
import {Cell} from '../Cell';
import styles from './index.css';

export type RowProps<Item, NestedItem> = Partial<NestedRowsOptions<Item, NestedItem>> & {
  className?: string;
  style?: React.CSSProperties;
  columns: Column<Item, NestedItem>[];
  getColumnOffset(column: Column<Item, NestedItem>): StickyColumnOffset | undefined;
  getColumnWidth(column: Column<Item, NestedItem>): number;
  item: Item;
  highlightSelected?: boolean;
} & Partial<Mark<RowLocator>>;

const getNull = () => null;

export const Row = memo(function Row<Item, NestedItem>({
  item,
  className,
  style,
  columns,
  getColumnOffset,
  getColumnWidth,
  getNestedRowKey,
  getSource,
  onNestedRowShow = () => {},
  enabled,
  isInitiallyExpanded,
  highlightSelected = true,
  ...restProperties
}: RowProps<Item, NestedItem>) {
  const locator = createLocator(restProperties);
  const locatorParameters = getLocatorParameters(restProperties);

  const selection = useSelection();
  const getSourceTask = useTask((parentItem: Item) => getSource?.(parentItem) || [], [getSource]);
  const onExpand = useCallback(() => onNestedRowShow(item), [onNestedRowShow, item]);
  const rowDisclosure = useRowDisclosure({
    onExpand,
    initiallyExpanded: isInitiallyExpanded && isInitiallyExpanded(item),
  });

  const rowClassName = cn(styles.row, className, {
    [styles.rowExpanded]: rowDisclosure.isExpanded && enabled,
    [styles.rowSelected]: highlightSelected && selection?.isItemSelected(item),
  });

  const flexibleColumns = getFlexibleColumns(columns, {
    isFlexible: (column) => !!column.flexible,
    hasContent: (column) => !!column.renderNested,
  });

  useEffect(() => {
    if (rowDisclosure.isExpanded) {
      getSourceTask.perform(item);
    }
  }, [rowDisclosure.isExpanded, getSourceTask.perform, item]);

  return (
    <RowDisclosureContext.Provider value={rowDisclosure}>
      <tbody className={rowClassName} {...locator(locatorParameters)} style={style}>
        <tr>
          {columns.map((column) => (
            <Cell
              addon={column.renderAddon?.(item)}
              align={column.align}
              item={item}
              noWrap={column.noWrap}
              render={column.render}
              stickyOffset={getColumnOffset(column)}
              verticalAlign={column.verticalAlign}
              width={getColumnWidth(column)}
              key={column.id}
              {...locator.cell({item: column.id})}
            />
          ))}
        </tr>
      </tbody>

      {getNestedRowKey &&
        rowDisclosure.isExpanded &&
        (getSourceTask.isPerforming ? (
          <tbody className={cn(styles.row, styles.rowNested)} {...locator(locatorParameters)}>
            <tr>
              <td colSpan={flexibleColumns.columns.length} className={styles.onLoadColl}>
                <Spinner />
              </td>
            </tr>
          </tbody>
        ) : (
          getSourceTask.result?.map((child) => (
            <tbody
              className={cn(styles.row, styles.rowNested)}
              key={getNestedRowKey(child)}
              {...locator(locatorParameters)}
            >
              <tr>
                {flexibleColumns.columns.map((column) => (
                  <Cell
                    align={column.align}
                    colSpan={flexibleColumns.getCellColSpan(column)}
                    item={child}
                    parentItem={item}
                    noWrap={column.noWrap}
                    render={column.renderNested || getNull}
                    stickyOffset={getColumnOffset(column)}
                    width={getColumnWidth(column)}
                    key={column.id}
                    {...locator.cell({item: column.id})}
                  />
                ))}
              </tr>
            </tbody>
          ))
        ))}
    </RowDisclosureContext.Provider>
  );
});
