import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  effectiveDate: {
    defaultMessage: 'Start date',
    description: 'Job history record form: field label',
  },
  legalEntity: {
    defaultMessage: 'Legal entity',
    description: 'Job history record form: field label',
  },
  manager: {
    defaultMessage: 'Manager',
    description: 'Job history record form: field label',
  },
  employmentType: {
    defaultMessage: 'Contract',
    description: 'Job history record form: field label',
  },
});
