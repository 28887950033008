import {intranetApi} from 'apiClient';
import {StockOptionBulkUploadInput} from 'domain/stockOption/bulkUpload/model';

type StockOptionBulkUploadApplyConfig = {
  input: StockOptionBulkUploadInput;
  sendDocuments: boolean;
};

export const apply = ({input, sendDocuments}: StockOptionBulkUploadApplyConfig): Promise<void> => {
  return intranetApi.post('/v1/secure/stockOptions/bulkUploads/apply', input, {params: {sendDocuments}});
};
