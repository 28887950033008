import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {FormattedPrice} from 'components/ui/FormattedPrice';
import {SideCalculator} from 'components/ui/SideCalculator';
import {calculateAllowance} from 'domain/compensations/common/api/calculateAllowance';
import {Stay, staySchema} from 'domain/compensations/stay/model';
import {CountryCode} from 'domain/countryCode/model';
import {Money} from 'domain/money/model';
import pDebounce from 'p-debounce';
import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type StayValueError = {
  countryCode?: string;
  endDate?: string;
  startDate?: string;
};

type Props = {
  stayValues?: Partial<Stay>[];
  validationErrors?: StayValueError[];
};

const emptyStayValues: Partial<Stay>[] = [];

function hasStayError(stayValueError: StayValueError | undefined) {
  return stayValueError && Object.values(stayValueError).some(Boolean);
}

function isStayValueFull(stayValue: Partial<Stay>): stayValue is Stay {
  return (
    staySchema.run(stayValue).ok &&
    (!(stayValue.countryCode === CountryCode.CN) ||
      (stayValue.countryCode === CountryCode.CN && Boolean(stayValue.city)))
  );
}

export function DailyAllowance({stayValues = emptyStayValues, validationErrors}: Props) {
  const intl = useIntl();
  const validStays: Stay[] = useMemo(
    () => stayValues.filter((stayValue, index) => !hasStayError(validationErrors?.[index])).filter(isStayValueFull),
    [stayValues, validationErrors],
  );

  const [allowance, setAllowance] = useState<Money[] | null>(null);
  const fetchAllowance = useAsyncTask(
    pDebounce(
      (stays: Stay[]) => calculateAllowance(stays).then(setAllowance).catch(toaster.interceptThenThrowError),
      500,
    ),
    [],
  );

  useEffect(() => {
    if (validStays && validStays.length) {
      fetchAllowance.perform(validStays);
    } else {
      setAllowance(null);
    }
  }, [
    JSON.stringify(validStays), // Hack: call the effect only if validStays has changed in terms of deep equality
    setAllowance,
  ]);

  const renderValue = () => {
    if (fetchAllowance.dataState === DataState.LOADING) {
      return <Spinner />;
    }
    if (!allowance) {
      return '0';
    }
    return allowance.map((money, index) => (
      <React.Fragment key={money.currency}>
        {index > 0 && <span className={styles.plus}>&nbsp;+ </span>}
        <FormattedPrice value={money} />
      </React.Fragment>
    ));
  };

  return (
    <SideCalculator
      title={intl.formatMessage(messages.title)}
      value={renderValue()}
      hint={intl.formatMessage(messages.hint)}
    />
  );
}
