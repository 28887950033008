import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.OWNERS]: {
    defaultMessage: 'Resource owners',
    description: 'Resource table column name',
  },
  [ColumnId.SERVICE]: {
    defaultMessage: 'Service',
    description: 'Resource table column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Status',
    description: 'Resource table column name',
  },
  [ColumnId.VALUE]: {
    defaultMessage: 'Value',
    description: 'Resource table column name',
  },
  [ColumnId.DISPLAY_NAME]: {
    defaultMessage: 'Name',
    description: 'Resource table column name',
  },
});
