import React, {memo} from 'react';
import {type ConvertLocatorToTestId, createLocator, type LocatorOfElement, type Mark} from '../create-locator';
import {Checkbox, CheckboxLocator} from '../Checkbox';
import type {Option} from './useCheckboxGroupUi';
import styles from './index.css';
import type {Direction} from './types';

export type CheckboxGroupItemTestId = ConvertLocatorToTestId<CheckboxGroupItemLocator>;
export type CheckboxGroupItemLocator = LocatorOfElement<{
  checkBoxWrapper: {value?: string};
  checkBox: CheckboxLocator;
}>;

type CheckboxGroupItemProps<Item> = {
  direction: Direction;
  onChange(payload: {option: Option<Item>; checked: boolean}): void;
  option: Option<Item>;
  itemToString?: (item: Item) => string;
  renderItem?: (option: Option<Item>, props: JSX.IntrinsicElements['input']) => React.ReactNode;
} & Partial<Mark<CheckboxGroupItemLocator>> &
  Omit<JSX.IntrinsicElements['input'], 'onChange'>;

export const CheckboxGroupItem = memo(function CheckboxGroupItem<Item>({
  direction,
  onChange,
  option,
  renderItem,
  itemToString,
  ...restProps
}: CheckboxGroupItemProps<Item>) {
  const locator = createLocator(restProps);
  const checkboxProps: JSX.IntrinsicElements['input'] = {
    onChange: (event) => onChange({option, checked: event.currentTarget.checked}),
    ...restProps,
  };

  return (
    <div
      className={direction === 'horizontal' ? styles.itemHorizontal : styles.itemVertical}
      {...locator.checkBoxWrapper({value: itemToString?.(option.value)})}
    >
      {renderItem ? (
        renderItem(option, checkboxProps)
      ) : (
        <Checkbox {...checkboxProps} label={option.label} {...locator.checkBox()} />
      )}
    </div>
  );
});
