import {formatHumanDate} from '@joomcode/joom-ui/HumanDate';
import {Section} from 'components/ui/Section';
import {Offer} from 'domain/offer/model';
import {OfferCompensation, VersionedOfferCompensations} from 'domain/offer/model/compensation';
import {compensationFieldLabels} from 'domain/offer/model/compensation/messages';
import {employmentInfoFieldLabels} from 'domain/offer/model/employmentInfo/messages';
import {furtherSalaryFieldLabels} from 'domain/offer/model/furtherSalary/messages';
import {OfferStockOptions, VersionedOfferStockOptions} from 'domain/offer/model/stockOption';
import {stockOptionFieldLabels} from 'domain/offer/model/stockOption/messages';
import {getListOfKeysWithUnequalValues} from 'domain/offer/utils/diff';
import {OfferValueDiff} from 'domain/offer/widgets/ValueDiff';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Feature, useFeature} from 'services/features';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  offer: Offer;
  compensations: VersionedOfferCompensations;
  stockOptions: VersionedOfferStockOptions;
};

export function OfferVersionsDiff({offer, compensations, stockOptions}: Props) {
  const intl = useIntl();
  const offerCrFeature = useFeature(Feature.OFFER_CR);
  const versionPairs = useMemo(
    () =>
      [...offer.versions]
        .reverse()
        .map((version, i, array) => [version, array[i + 1]])
        .slice(0, offer.versions.length - 1),
    [offer.versions],
  );
  const diffs = useMemo(
    () =>
      versionPairs.map(([current, previous]) => {
        let furtherSalaryKeys: string[] = [];
        let compensationKeys = getListOfKeysWithUnequalValues(compensations[current.id], compensations[previous.id]);
        if (compensationKeys.includes('furtherSalary')) {
          furtherSalaryKeys = getListOfKeysWithUnequalValues(
            compensations[current.id]?.furtherSalary || {},
            compensations[previous.id]?.furtherSalary || {},
          ).filter((key) => offerCrFeature.isAvailable || key !== 'crResult');
          compensationKeys = compensationKeys.filter(
            (key) => key !== 'furtherSalary' && (offerCrFeature.isAvailable || key !== 'crResult'),
          );
        }

        return {
          current,
          previous,
          employmentKeys: getListOfKeysWithUnequalValues(current.employmentInfo, previous.employmentInfo),
          stockOptionKeys: getListOfKeysWithUnequalValues(stockOptions[current.id], stockOptions[previous.id]),
          compensationKeys,
          furtherSalaryKeys,
        };
      }),
    [versionPairs, compensations, stockOptions],
  );

  return (
    <div className={styles.root}>
      {diffs.map(({current, previous, employmentKeys, stockOptionKeys, compensationKeys, furtherSalaryKeys}, i) => (
        <Section
          key={current.id}
          title={intl.formatMessage(messages.sectionTitle, {
            latest: i === 0,
            date: formatHumanDate({intl, value: current.createdTime, utc: true}),
          })}
          bordered={i > 0}
        >
          {employmentKeys.map((key) => (
            <OfferValueDiff
              key={key}
              property={key}
              oldValue={previous.employmentInfo[key as keyof typeof previous.employmentInfo] as string}
              newValue={current.employmentInfo[key as keyof typeof current.employmentInfo] as string}
              label={intl.formatMessage(employmentInfoFieldLabels[key as keyof typeof employmentInfoFieldLabels])}
            />
          ))}
          {compensationKeys.map((key) => (
            <OfferValueDiff
              key={key}
              property={key}
              oldValue={compensations[previous.id]?.[key as keyof OfferCompensation] as string}
              newValue={compensations[current.id]?.[key as keyof OfferCompensation] as string}
              label={intl.formatMessage(compensationFieldLabels[key as keyof typeof compensationFieldLabels])}
            />
          ))}
          {furtherSalaryKeys.map((key) => (
            <OfferValueDiff
              key={key}
              property={key}
              oldValue={compensations[previous.id]?.furtherSalary?.[key as keyof OfferCompensation['furtherSalary']]}
              newValue={compensations[current.id]?.furtherSalary?.[key as keyof OfferCompensation['furtherSalary']]}
              label={intl.formatMessage(furtherSalaryFieldLabels[key as keyof typeof furtherSalaryFieldLabels])}
            />
          ))}
          {stockOptionKeys.map((key) => (
            <OfferValueDiff
              key={key}
              property={key}
              oldValue={stockOptions[previous.id]?.[key as keyof OfferStockOptions] as string}
              newValue={stockOptions[current.id]?.[key as keyof OfferStockOptions] as string}
              label={intl.formatMessage(stockOptionFieldLabels[key as keyof typeof stockOptionFieldLabels])}
            />
          ))}
        </Section>
      ))}
    </div>
  );
}
