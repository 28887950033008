import cn from 'classnames';
import {createLocator, type Mark, type Locator} from 'create-locator';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import React from 'react';
import {CloseButton} from '../../CloseButton';
import {useDialogPrivateContext} from '../context';
import styles from './index.css';

export type DialogHeaderLocator = Locator<{
  closeButton: void;
}>;

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  showClose?: boolean;
} & Partial<Mark<DialogHeaderLocator>>;

export function Header({children, disabled, showClose = true, ...rest}: Props) {
  const locator = createLocator(rest);
  const {close, size} = useDialogPrivateContext();

  return (
    <div className={cn(styles.header, styles[`headerSize${capitalize(size)}` as keyof typeof styles])} {...locator()}>
      {children}
      {showClose && (
        <div className={styles.close}>
          <CloseButton onClick={close} disabled={disabled} {...locator.closeButton()} />
        </div>
      )}
    </div>
  );
}
