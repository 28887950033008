import React from 'react';
import styles from './styles.css';

type Props = {
  children: string;
};

export function SearchHighlighter({children}: Props) {
  return <span className={styles.highlighted}>{children}</span>;
}
