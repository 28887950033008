import {identity} from '@joomcode/deprecated-utils/function';
import {FieldTextarea} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {getClosestBusinessDayInNextMonth} from 'domain/compensationReviewRequest/utils/date';
import {CompensationReviewRequestLayout} from 'domain/compensationReviewRequest/widgets/Layout';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {hints, labels} from './messages';

export function CompensationReviewRequestFormRequestDetails() {
  const intl = useIntl();
  const minDate = useMemo(() => getClosestBusinessDayInNextMonth(new Date()), []);

  return (
    <Panel>
      <CompensationReviewRequestLayout>
        <Panel.Content withPadding>
          <Grid>
            <Grid.Item xl={3} xs={12}>
              <FieldDate
                name='effectiveDate'
                label={intl.formatMessage(labels.effectiveDate)}
                minDate={minDate}
                required
                reserveSpaceForError={false}
              />
            </Grid.Item>
            <Grid.Item xl={3} xs={12}>
              <div /> {/* For correct placement */}
            </Grid.Item>
            <Grid.Item xl={6} xs={12}>
              <FieldTextarea
                name='reason'
                label={intl.formatMessage(labels.reason)}
                hint={intl.formatMessage(hints.reason)}
                rows={3}
                parse={identity}
                required
                reserveSpaceForError={false}
              />
            </Grid.Item>
          </Grid>
        </Panel.Content>
      </CompensationReviewRequestLayout>
    </Panel>
  );
}
