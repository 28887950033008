import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  user: {
    defaultMessage: '{fullName} ({login})',
    description: 'Compensation review request user value',
  },
});

export const labels = defineMessages({
  effectiveDate: {
    defaultMessage: 'Proposed effective date',
    description: 'Compensation review request label',
  },
  user: {
    defaultMessage: 'Joomer',
    description: 'Compensation review request label',
  },
  reason: {
    defaultMessage: 'Reason',
    description: 'Compensation review request label',
  },
  type: {
    defaultMessage: 'Request type',
    description: 'Compensation review request label',
  },
});
