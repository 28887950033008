import {identity} from '@joomcode/deprecated-utils/function';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {ReactComponent as PenIcon} from '@joomcode/joom-ui/icons/core/pen.svg';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import React, {PropsWithChildren, RefObject, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props<ItemType> = PropsWithChildren<{
  buttonHint?: string;
  disableEdit?: boolean;
  disableSubmit?: boolean;
  error?: React.ReactNode;
  inputRef?: RefObject<HTMLInputElement>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  processing?: boolean;
  renderValue?: (value: ItemType) => React.ReactNode;
  value: ItemType;
}>;

export function EditableValue<ItemType extends string | undefined = string>({
  buttonHint,
  children,
  disableEdit = false,
  disableSubmit = false,
  error,
  inputRef,
  onSubmit,
  processing,
  renderValue = identity,
  value,
}: Props<ItemType>) {
  const intl = useIntl();
  const {isOpen: editMode, close: leaveEditMode, open: enterEditMode} = usePopupState();

  useEffect(leaveEditMode, [value]);

  useEffect(() => {
    if (editMode && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode, inputRef]);

  return editMode ? (
    <form className={styles.form} onSubmit={onSubmit}>
      <span className={styles.inputWrap}>{children}</span>
      <ButtonGroup size='m'>
        <Button
          intent='neutral'
          kind='primary'
          type='submit'
          disabled={disableSubmit || processing}
          loading={processing}
        >
          {intl.formatMessage(messages.save)}
        </Button>
        <Button intent='neutral' kind='primary' onClick={leaveEditMode} disabled={processing}>
          {intl.formatMessage(messages.cancel)}
        </Button>
      </ButtonGroup>
      {error && <span className={styles.error}>{error}</span>}
    </form>
  ) : (
    <>
      {renderValue(value)}
      <TooltipAlt content={buttonHint ?? intl.formatMessage(messages.editTooltip)}>
        <span className={styles.editButton}>
          <Button
            intent='neutral'
            kind='text'
            size='m'
            onClick={enterEditMode}
            disabled={disableEdit}
            iconLeft={<PenIcon />}
          />
        </span>
      </TooltipAlt>
    </>
  );
}
