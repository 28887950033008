import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  btnSubmit: {
    defaultMessage: 'Submit',
    description: '[button] Legal info form button',
  },
  btnCancel: {
    defaultMessage: 'Cancel',
    description: '[button] Legal info form button',
  },
});

export const labels = defineMessages({
  legalEntity: {
    defaultMessage: 'Legal entity',
    description: 'Legal info form field label',
  },
  positionTitle: {
    defaultMessage: 'Position title',
    description: 'Legal info form field label',
  },
  firstName: {
    defaultMessage: 'First name',
    description: 'Legal info form field label',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: 'Legal info form field label',
  },
  middleName: {
    defaultMessage: 'Middle name',
    description: 'Legal info form field label',
  },
  gender: {
    defaultMessage: 'Gender',
    description: 'Legal info form field label',
  },
  countryOfIssue: {
    defaultMessage: 'Country of issue',
    description: 'Legal info form field label',
  },
  number: {
    defaultMessage: 'Number',
    description: 'Legal info form field label',
  },
  expirationDate: {
    defaultMessage: 'Expiration date',
    description: 'Legal info form field label',
  },
  legalAddress: {
    defaultMessage: 'Legal address',
    description: 'Legal info form field label',
  },
  hasNoInternationalPassport: {
    defaultMessage: 'No international passport',
    description: 'Legal info form field label',
  },
  timeOffPolicy: {
    defaultMessage: 'Time off policy',
    description: 'Legal info form field label',
  },
  contractType: {
    defaultMessage: 'Contract type',
    description: 'Legal info form field label',
  },
  comment: {
    defaultMessage: 'Comment',
    description: 'Legal info form field label',
  },
  addressLine1: {
    defaultMessage: 'Address line 1',
    description: 'Legal info form field label',
  },
  addressLine2: {
    defaultMessage: 'Address line 2',
    description: 'Legal info form field label',
  },
  city: {
    defaultMessage: 'City',
    description: 'Legal info form field label',
  },
  country: {
    defaultMessage: 'Country',
    description: 'Legal info form field label',
  },
  region: {
    defaultMessage: 'Region',
    description: 'Legal info form field label',
  },
  zip: {
    defaultMessage: 'ZIP',
    description: 'Legal info form field label',
  },
});
