import {DataState} from '@joomcode/deprecated-utils/dataState';
import {LegalEntityId} from 'domain/legalEntity/model';
import {AnySalaryRecord} from 'domain/salaryRecord/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {
  createExtendedSalaryRecordFx,
  createSalaryRecordFx,
  deleteSalaryRecordFx,
  getAnyExtendedSalaryRecordsFx,
  getAnySalaryRecordsFx,
  getMySalaryRecordsFx,
  getSubordinateSalaryRecordsFx,
  replaceExtendedSalaryRecordFx,
  replaceSalaryRecordFx,
  updateExtendedSalaryRecordFx,
  updateSalaryRecordFx,
} from '.';

type SalaryRecordsByLegalEntityId = Record<LegalEntityId, AnySalaryRecord[]>;
type DataStateByLegalEntityId = Record<LegalEntityId, DataState>;
type State = {
  byUserId: Record<UserId, SalaryRecordsByLegalEntityId>;
  dataStateByUserId: Record<UserId, DataStateByLegalEntityId>;
};

export const $salaryRecords = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on(
    [getAnyExtendedSalaryRecordsFx, getAnySalaryRecordsFx, getMySalaryRecordsFx, getSubordinateSalaryRecordsFx],
    (state, {userId, legalEntityId}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: {
            ...state.dataStateByUserId[userId],
            [legalEntityId]: DataState.LOADING,
          },
        },
      };
    },
  )
  .on(
    [
      getAnyExtendedSalaryRecordsFx.fail,
      getAnySalaryRecordsFx.fail,
      getMySalaryRecordsFx.fail,
      getSubordinateSalaryRecordsFx.fail,
    ],
    (state, {params: {userId, legalEntityId}}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: {
            ...state.dataStateByUserId[userId],
            [legalEntityId]: DataState.FAILED,
          },
        },
      };
    },
  )
  .on(
    [
      getAnyExtendedSalaryRecordsFx.done,
      getAnySalaryRecordsFx.done,
      getMySalaryRecordsFx.done,
      getSubordinateSalaryRecordsFx.done,
    ],
    (state, {params: {userId, legalEntityId}, result}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: {
            ...state.byUserId[userId],
            [legalEntityId]: result,
          },
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: {
            ...state.dataStateByUserId[userId],
            [legalEntityId]: DataState.LOADED,
          },
        },
      };
    },
  )
  .on(
    [
      createSalaryRecordFx.done,
      createExtendedSalaryRecordFx.done,
      replaceSalaryRecordFx.done,
      replaceExtendedSalaryRecordFx.done,
      updateSalaryRecordFx.done,
      updateExtendedSalaryRecordFx.done,
    ],
    (state, {params: {userId, legalEntityId}, result}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: {
            ...state.byUserId[userId],
            [legalEntityId]: result,
          },
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: {
            ...state.dataStateByUserId[userId],
            [legalEntityId]: DataState.LOADED,
          },
        },
      };
    },
  )
  .on(deleteSalaryRecordFx.done, (state, {params: {id, userId, legalEntityId}}) => {
    if (state.dataStateByUserId[userId]?.[legalEntityId] !== DataState.LOADED) {
      return state;
    }

    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: {
          ...state.byUserId[userId],
          [legalEntityId]: state.byUserId[userId]?.[legalEntityId].filter((record) => record.id !== id),
        },
      },
    };
  });
