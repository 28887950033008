import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {Status} from 'domain/compensationReviewRequest/model/status';
import {formatStatus} from 'domain/compensationReviewRequest/model/status/formatter';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  status: Status;
};

const intentByStatus: Record<Status, BadgeIntent> = {
  [Status.APPROVED]: 'positive',
  [Status.CANCELED]: 'neutral',
  [Status.IN_PROCESS]: 'primary',
  [Status.REJECTED]: 'invertedNeutral',
};

export function StatusBadge({status}: Props) {
  const intl = useIntl();
  return <Badge intent={intentByStatus[status]}>{formatStatus(status, intl)}</Badge>;
}
