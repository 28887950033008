import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {DialogInfoStripe} from 'components/ui/DialogInfoStripe';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {createStockOptionAgreementFreezeFx} from 'domain/stockOption/agreementFreeze/stores/main';
import {StockOptionAgreementFreezeForm} from 'domain/stockOption/agreementFreeze/widgets/Form';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  agreement: StockOptionAgreement;
  onClose(): void;
};

type FormState = {
  freezeDate?: string;
};

export function StockOptionAgreementFreezeCreateDialog({agreement, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  const onSubmit = useCallback(
    ({freezeDate}: FormState) => {
      if (!freezeDate) {
        return Promise.reject().catch(() => toaster.error(intl.formatMessage(messages.error)));
      }

      return createStockOptionAgreementFreezeFx({
        agreementId: agreement.id,
        userId: agreement.user.id,
        freezeDate,
      })
        .then(() => {
          onClose();
          toaster.success(intl.formatMessage(messages.success));
        })
        .catch(toaster.interceptThenThrowError);
    },
    [onClose, agreement],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 24rem)'>
      <Form<FormState> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <DialogInfoStripe>{intl.formatMessage(messages.note)}</DialogInfoStripe>
              <StockOptionAgreementFreezeForm onSubmit={handleSubmit} formId={formId} required />
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.createButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
