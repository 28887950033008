import cn from 'classnames';
import React from 'react';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../../create-locator';
import styles from './index.css';
import {PanelHeaderTitle} from './Title';

export type PanelHeaderTestId = ConvertLocatorToTestId<PanelHeaderLocator>;
export type PanelHeaderLocator = Locator<{
  title: void;
  toolbar: void;
  subTitle: void;
}>;

type Props = {
  subTitle?: React.ReactNode;
  title?: React.ReactNode;
  toolbar?: React.ReactNode;
  withPaddingBottom?: boolean;
  withSeparator?: boolean;
} & Partial<Mark<PanelHeaderLocator>>;

export function PanelHeader({
  title,
  toolbar,
  subTitle,
  withSeparator,
  withPaddingBottom = withSeparator,
  ...restProperties
}: Props) {
  const locator = createLocator(restProperties);

  return (
    <div
      className={cn(styles.header, {
        [styles.withPaddingBottom]: withPaddingBottom,
        [styles.withSeparator]: withSeparator,
      })}
      {...locator()}
    >
      <div className={styles.content}>
        {title && (
          <div className={styles.title} {...locator.title()}>
            {title}
          </div>
        )}
        {subTitle && (
          <div className={styles.subTitle} {...locator.subTitle()}>
            {subTitle}
          </div>
        )}
      </div>

      {toolbar ? (
        <div {...locator.toolbar()} className={styles.toolbar}>
          {toolbar}
        </div>
      ) : null}
    </div>
  );
}

PanelHeader.Title = PanelHeaderTitle;
