import {OfficeRoom} from 'domain/officeMap/room/model';
import {compareRoomsByQuery} from 'domain/officeMap/room/model/compare';
import {User} from 'domain/user/model';
import {compareUsersByQuery} from 'domain/user/model/compare';
import {SearchItem} from './types';

const compareUserWithRoom = (user: User, room: OfficeRoom, query: string): number => {
  // If room name equals to query (eg. query is 'e' or 'a' or '1') we prefer room
  if (room.text.toLowerCase() === query.toLowerCase()) {
    return 1;
  }

  // In other cases we prefer user
  return -1;
};

export const sortItems =
  (query: string | null) =>
  (a: SearchItem, b: SearchItem): number => {
    if (query === null) {
      return 0;
    }

    if (a.type === 'user' && b.type === 'user') {
      return compareUsersByQuery(a.value, b.value, query);
    }

    if (a.type === 'room' && b.type === 'room') {
      return compareRoomsByQuery(a.value, b.value, query);
    }

    if (a.type === 'user' && b.type === 'room') {
      return compareUserWithRoom(a.value, b.value, query);
    }

    if (a.type === 'room' && b.type === 'user') {
      return -compareUserWithRoom(b.value, a.value, query);
    }

    return 0;
  };
