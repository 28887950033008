import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import {HelpIcon} from 'domain/compensationReviewRequest/widgets/HelpIcon';
import {SectionHeader} from 'domain/compensationReviewRequest/widgets/SectionHeader';
import {Frequency} from 'domain/frequency/model';
import {formatFrequency} from 'domain/frequency/model/formatter';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  frequency?: Frequency;
  isNew?: boolean;
  onReset?: () => void;
};

export function SalaryRangeHeader({frequency, isNew, onReset}: Props) {
  const intl = useIntl();
  const formattedFrequency = useMemo(
    () =>
      frequency === Frequency.PER_MONTH || frequency === Frequency.PER_YEAR
        ? formatFrequency(frequency, intl)
        : undefined,
    [frequency, intl],
  );
  const title = useMemo(
    () => capitalize(intl.formatMessage(isNew ? messages.titleNew : messages.title, {frequency: formattedFrequency})),
    [intl, formattedFrequency, isNew],
  );

  return (
    <div className={styles.root}>
      <SectionHeader
        title={
          <>
            {title}
            <HelpIcon hint={intl.formatMessage(messages.hint)} />
          </>
        }
        onReset={onReset}
      />
    </div>
  );
}
