import {intranetApi} from 'apiClient';
import {OfferId} from 'domain/offer/model';
import {NewcomerInOffer, newcomerInOfferSchema} from 'domain/offer/model/newcomer';

export type UpdateLoginConfig = {
  offerId: OfferId;
  login: string;
};

export const updateLogin = async ({login, offerId}: UpdateLoginConfig): Promise<NewcomerInOffer> => {
  const {data} = await intranetApi.post('/v1/offers/changeLogin', {login}, {params: {id: offerId}});

  return newcomerInOfferSchema.runWithException(data);
};
