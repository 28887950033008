import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Update team',
    description: '[title] Team update dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Team update dialog',
    description: 'Team update dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Team update dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Update',
    description: '[button] Team update dialog: submit button',
  },
});
