import {boolean, ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';

export const salaryRangesVersionIdShema = idSchema;

export const salaryRangesVersionSchema = object({
  active: boolean(),
  comment: optional(string()),
  effectiveDate: formattedDateSchema,
  id: salaryRangesVersionIdShema,
});

export type SalaryRangesVersionId = ExtractSchemaType<typeof salaryRangesVersionIdShema>;
export type SalaryRangesVersion = ExtractSchemaType<typeof salaryRangesVersionSchema>;
