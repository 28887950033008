import {addBusinessDays, addDays, subDays} from 'date-fns';
import {PREPARATION_WORKDAYS} from 'domain/compensations/request/widgets/Form';
import {Stay} from 'domain/compensations/stay/model';

type Options = {
  previousStay?: Partial<Stay>;
  currentStay: Partial<Stay>;
  isOneDayTripForbidden: boolean;
};

type ReturnShape = {
  minStartDate: Date;
  maxStartDate?: Date;
  minEndDate: Date;
};

export function getDateConstraints({previousStay, currentStay, isOneDayTripForbidden}: Options): ReturnShape {
  const minDateAfterPreparation = addBusinessDays(new Date(), PREPARATION_WORKDAYS);
  const minStartDate = previousStay?.endDate ? new Date(previousStay.endDate) : minDateAfterPreparation;
  const maxStartDate = currentStay.endDate
    ? isOneDayTripForbidden
      ? subDays(new Date(currentStay.endDate), 1)
      : new Date(currentStay.endDate)
    : undefined;
  const previousSelectedDate = currentStay.startDate ?? previousStay?.endDate;
  const minEndDate = previousSelectedDate
    ? isOneDayTripForbidden
      ? addDays(new Date(previousSelectedDate), 1)
      : new Date(previousSelectedDate)
    : minDateAfterPreparation;

  return {
    minStartDate,
    maxStartDate,
    minEndDate,
  };
}
