import {ExtractSchemaType, object} from '@joomcode/deprecated-utils/jsonValidation';
import {roleIdSchema, roleSchema} from 'domain/role/model';
import {teamIdSchema} from 'domain/team/model/id';
import {teamLiteWithAncestorsSchema} from 'domain/team/model/liteWithAncestors';

export const roleInTeamSchema = object({
  role: roleSchema,
  teamWithAncestors: teamLiteWithAncestorsSchema,
});

export const roleInTeamDiffSchema = object({
  roleId: roleIdSchema,
  teamId: teamIdSchema,
});

export type RoleInTeam = ExtractSchemaType<typeof roleInTeamSchema>;
export type RoleInTeamDiff = ExtractSchemaType<typeof roleInTeamDiffSchema>;
