import {timeOffRequestsApi} from 'domain/timeOff/request/api';
import {
  approveSubordinateTimeOffRequestFx,
  approveTimeOffRequestFx,
  cancelTimeOffRequestFx,
  createTimeOffRequestFx,
  createTimeOffRequestOnBehalfFx,
  getMyTimeOffRequestsFx,
  getSubordinatesTimeOffRequestsFx,
  rejectSubordinateTimeOffRequestFx,
  rejectTimeOffRequestFx,
  setRecordedInAccountingSystemFx,
} from '.';

getMyTimeOffRequestsFx.use(timeOffRequestsApi.getMy);
getSubordinatesTimeOffRequestsFx.use(timeOffRequestsApi.getSubordinates);
createTimeOffRequestFx.use(timeOffRequestsApi.create);
createTimeOffRequestOnBehalfFx.use(timeOffRequestsApi.createOnBehalf);
approveTimeOffRequestFx.use(timeOffRequestsApi.approve);
approveSubordinateTimeOffRequestFx.use(timeOffRequestsApi.approveSubordinate);
cancelTimeOffRequestFx.use(timeOffRequestsApi.cancel);
rejectTimeOffRequestFx.use(timeOffRequestsApi.reject);
rejectSubordinateTimeOffRequestFx.use(timeOffRequestsApi.rejectSubordinate);
setRecordedInAccountingSystemFx.use(timeOffRequestsApi.setRecordedInAccountingSystem);
