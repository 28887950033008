export type HumanDateFormat = 'short' | 'long';

type FormatsConfig = {[key in HumanDateFormat]: Record<string, string>};

export const dateFormats: FormatsConfig = {
  long: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  short: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
};

export const timeFormats: FormatsConfig = {
  long: {
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  },
  short: {
    hour: 'numeric',
    minute: '2-digit',
  },
};

export function getHumanDateFormat(format: HumanDateFormat) {
  return dateFormats[format];
}

export function getHumanTimeFormat(format: HumanDateFormat) {
  return timeFormats[format];
}

export function getHumanDateTimeFormat(format: HumanDateFormat) {
  return {
    ...getHumanDateFormat(format),
    ...getHumanTimeFormat(format),
  };
}
