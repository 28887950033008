import {generatePath} from 'react-router-dom';

export const userRolesUrlPatterns = {
  root: '/userRoles',
  create: '/userRoles/create',
  item: '/userRoles/:id',
};

export const userRolesUrls = {
  root: () => userRolesUrlPatterns.root,
  create: () => userRolesUrlPatterns.create,
  role: (pathParams: {id: string}) => generatePath(userRolesUrlPatterns.item, pathParams),
};
