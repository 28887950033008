import {StringValidatorErrorCode} from '@joomcode/joom-form';
import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  submitButton: {
    defaultMessage: 'Submit',
    description: '[button] Submit compensation request',
  },
  staysTitle: {
    defaultMessage: 'Where are you going?',
    description: '[title] Compensation request: Stays panel title',
  },
  addStayButton: {
    defaultMessage: 'One more country',
    description: '[button] Add country to stays list',
  },
  addCityButton: {
    defaultMessage: 'One more city in China',
    description: '[button] Add counry with city to stays list',
  },
  removeStayButton: {
    defaultMessage: 'Remove',
    description: '[button] Remove country from stays list',
  },
  commonNotice: {
    defaultMessage:
      'Before requesting a business trip, you should make sure you’ve checked our <link1>Business trip policy</link1>.',
    description: 'Compensation request form: common notice',
  },
  reasonHint: {
    defaultMessage: 'Please choose the purpose of your trip — it’s important for all the paperwork.',
    description: 'Compensation request form: reason field hint',
  },
  detailsHintRussia: {
    defaultMessage: 'Enter any details that we should know about your trip.',
    description: 'Compensation request form: details field hint for Russians',
  },
  detailsHintNonRussia: {
    defaultMessage: 'Enter your trip preferences: desired flights, hotel options, and any additional info.',
    description: 'Compensation request form: details field hint for non-Russians',
  },
  staysHint: {
    defaultMessage: 'Enter countries and dates in proper order.',
    description: 'Compensation request form: stays field hint',
  },
  staysHintExtraStays: {
    defaultMessage:
      'If at the end of the trip you want to stay for the weekend, mention this in the comments. You’ll have to cover hotel expenses for these days on your own. The return ticket will be paid for by the company.',
    description: 'Compensation request form: additional hint for stays',
  },
});

export const fieldLabels = defineMessages({
  reason: {
    defaultMessage: 'Purpose',
    description: 'Compensation request field label',
  },
  comments: {
    defaultMessage: 'Comments',
    description: 'Compensation request field label',
  },
  country: {
    defaultMessage: 'Country',
    description: 'Compensation request field label',
  },
  city: {
    defaultMessage: 'City',
    description: 'Compensation request field label',
  },
  startDate: {
    defaultMessage: 'Start date',
    description: 'Compensation request field label',
  },
  endDate: {
    defaultMessage: 'End date',
    description: 'Compensation request field label',
  },
});

export const errorMessages = defineMessages({
  [StringValidatorErrorCode.STRING_REG_EXP_NOT_MATCH]: {
    defaultMessage: 'Please use Latin letters',
    description: 'Field error text',
  },
});
