import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Office} from 'domain/officeMap/office/model';
import {officeAliasMessages} from 'domain/officeMap/office/model/alias/messages';
import {FieldTokenGeneration} from 'domain/officeMap/office/widgets/FieldTokenGeneration';
import {OfficeRoom} from 'domain/officeMap/room/model';
import orderBy from 'lodash/orderBy';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import styles from './styles.css';

type Props = {
  office: Office;
};

const iteratee = (room: OfficeRoom) => {
  const name = room.fullText || room.text;
  return !isNaN(Number(name)) ? Number(name) : name;
};

export function TokenGenerationPanel({office: {alias, rooms}}: Props) {
  const intl = useIntl();
  const sortedRooms = useMemo(() => orderBy(rooms, iteratee), [rooms]);
  const firstHalf = useMemo(() => sortedRooms.slice(0, Math.ceil(sortedRooms.length / 2)), [sortedRooms]);
  const secondHalf = useMemo(() => sortedRooms.slice(Math.ceil(sortedRooms.length / 2)), [sortedRooms]);

  return (
    <Panel withPadding title={intl.formatMessage(officeAliasMessages[alias])}>
      <div className={styles.root}>
        <Grid>
          {[firstHalf, secondHalf].map((rooms) => (
            <Grid.Item key={rooms[0].id} sm={12} xl={6}>
              {rooms.map((room) => (
                <FieldTokenGeneration key={room.id} room={room} />
              ))}
            </Grid.Item>
          ))}
        </Grid>
      </div>
    </Panel>
  );
}
