import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {Measure} from '@joomcode/deprecated-utils/measure/types';
import {validateFile} from '@joomcode/joom-form';
import {FileObject, FilePane} from '@joomcode/joom-ui/FilePane';
import {formatFileSize} from '@joomcode/joom-ui/FormattedFileSize';
import {fileResourcesFileApi} from 'api/fileResources/files';
import {useFileUpload} from 'hooks/useFileUpload';
import {FileMeta} from 'models/system/fileMeta';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {fileValidationErrorMessages, messages} from './messages';
import styles from './styles.css';

type Props = {
  acceptedMimeTypes?: string[];
  disabled?: boolean;
  file?: File;
  fileMeta?: FileMeta;
  maxFileSize?: Measure<FileSizeUnit>;
  onRemove: () => void;
  onUploadComplete: (fileMeta: FileMeta) => void;
};

export function FileUpload({
  acceptedMimeTypes,
  disabled = false,
  file,
  fileMeta: initialFileMeta,
  maxFileSize,
  onRemove,
  onUploadComplete,
}: Props) {
  const intl = useIntl();
  const [fileMeta, setFileMeta] = useState<FileMeta | undefined>(initialFileMeta);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const fileObject: FileObject = useMemo(() => {
    return file ?? {name: fileMeta?.fileName ?? 'Unknown', size: fileMeta?.size ?? 0};
  }, [file, fileMeta]);

  const onComplete = useCallback(
    (uploadedFileMeta: FileMeta) => {
      setFileMeta(uploadedFileMeta);
      onUploadComplete(uploadedFileMeta);
    },
    [onUploadComplete, setFileMeta],
  );

  const onError = useCallback(() => {
    setErrorMessage(intl.formatMessage(messages.uploadError));
  }, [intl, setErrorMessage]);

  const {onAttach, cancel, progress} = useFileUpload({
    upload: fileResourcesFileApi.create,
    params: undefined,
    onComplete,
    onError,
  });

  const onFileAttached = useCallback(
    async (attachedFile: File) => {
      const fileError = await validateFile(attachedFile, {
        mimeTypes: acceptedMimeTypes,
        maxSize: maxFileSize && fileSizeUtils.convert(maxFileSize, FileSizeUnit.Byte)?.value,
      });
      if (fileError) {
        setErrorMessage(
          intl.formatMessage(fileValidationErrorMessages[fileError.code as keyof typeof fileValidationErrorMessages], {
            maxFileSize: maxFileSize && formatFileSize(intl, maxFileSize),
          }),
        );
        return;
      }
      onAttach(attachedFile);
    },
    [intl, setErrorMessage],
  );

  useEffect(() => () => cancel(), [cancel]);

  useEffect(() => {
    if (file && !fileMeta) {
      onFileAttached(file);
    }
  }, [onFileAttached]);

  return (
    <FilePane
      disabled={disabled}
      file={fileObject}
      progress={fileMeta || errorMessage ? undefined : progress}
      hasError={Boolean(errorMessage)}
      onRemove={onRemove}
    >
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </FilePane>
  );
}
