import React, {ReactNode} from 'react';
import type {DisclosureButtonProps} from '../../Disclosure';
import {ReactComponent as ArrowLeftIcon} from '../../icons/core/arrowRightThin.svg';
import styles from './index.css';

type Props = DisclosureButtonProps & {
  children: ReactNode;
};

export function PanelTitleDisclosure({children, ...buttonProps}: Props) {
  return (
    <button className={styles.disclosure} type='button' {...buttonProps} data-panel-title-disclosure>
      <ArrowLeftIcon className={styles.icon} />
      {children}
    </button>
  );
}
