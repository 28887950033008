import {array, ExtractSchemaType, number, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {OfferVersionId, offerVersionIdSchema} from 'domain/offer/model/version';
import {DEFAULT_ISSUER} from 'domain/stockOption/issuer/model';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const offerStockOptionsSchema = object({
  issuer: optionalWithDefault(DEFAULT_ISSUER, string()),
  amount: number(),
});

export const versionedOfferStockOptionListSchema = array(
  object({
    versionId: offerVersionIdSchema,
    stockOptions: optional(offerStockOptionsSchema),
  }),
);

export type OfferStockOptions = ExtractSchemaType<typeof offerStockOptionsSchema>;
export type VersionedOfferStockOptionList = ExtractSchemaType<typeof versionedOfferStockOptionListSchema>;
export type VersionedOfferStockOptions = Record<OfferVersionId, OfferStockOptions | undefined>;
