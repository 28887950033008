import React from 'react';
import {Listbox} from '../Listbox';
import {ReactComponent as ArrowRightIcon} from '../icons/core/arrowRight.svg';
import {Menu, MenuProps} from './Menu';
import {MenuContext, SubmenuDisclosureContext} from './context';
import {MenuItem} from './Item';

type Props = {
  ariaLabel?: string;
  children: React.ReactNode;
  disclosure?: MenuProps['disclosure'];
  label?: React.ReactNode;
  offset?: MenuProps['offset'];
};

const Submenu = React.forwardRef<HTMLButtonElement>(function Submenu(
  {ariaLabel, children, disclosure, label, offset, ...rest}: Props,
  ref,
) {
  const ariaLabelString = ariaLabel ?? (typeof label === 'string' ? label : undefined);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SubmenuDisclosureContext.Provider value={{...rest, ref}}>
      <Menu
        ariaLabel={ariaLabelString}
        disclosure={disclosure ?? <Listbox.Option iconRight={<ArrowRightIcon />}>{label}</Listbox.Option>}
        offset={offset}
      >
        {children}
      </Menu>
    </SubmenuDisclosureContext.Provider>
  );
});

export function MenuSubmenu(props: Props) {
  const menu = React.useContext(MenuContext);

  if (!menu) {
    throw Error(`<Menu.Submenu> must be used inside of a <Menu> component`);
  }
  return <MenuItem {...props} as={Submenu} closeOnClick={false} />;
}
