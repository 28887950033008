import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  startLabel: {
    defaultMessage: 'Start date',
    description: 'Time off requests download dropdown: field label',
  },
  endLabel: {
    defaultMessage: 'End date',
    description: 'Time off requests download dropdown: field label',
  },
  buttonCancel: {
    defaultMessage: 'Cancel',
    description: '[button] Time off requests download dropdown',
  },
  buttonDownload: {
    defaultMessage: 'Download',
    description: '[button] Time off requests download dropdown',
  },
});
