import cn from 'classnames';
import React, {useMemo} from 'react';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {StatusIcon} from './components/StatusIcon';
import {FilePaneContent} from './components/FilePaneContent';

import {FilePaneProps, StatusIconStyle} from './types';
import {getIconServiceStyle} from './utils';
import styles from './index.css';

export const FilePane = ({
  disabled = false,
  hasError = false,
  icon = StatusIconStyle.CLIP,
  progress,
  ...rest
}: FilePaneProps) => {
  const iconServiceStyle = useMemo(
    () => getIconServiceStyle({hasError, loading: Boolean(isNotNullish(progress) && progress < 1)}),
    [hasError, progress],
  );

  return (
    <div className={cn(styles.filePane, {[styles.filePaneDisabled]: disabled})}>
      <div className={styles.statusIcon}>
        <StatusIcon iconStyle={iconServiceStyle || icon} />
      </div>
      <div className={styles.mainContent}>
        <FilePaneContent disabled={disabled} progress={progress} hasError={hasError} {...rest} />
      </div>
    </div>
  );
};
