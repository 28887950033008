import {useMultiSelect} from '@joomcode/deprecated-utils/react/useMultiSelect';
import {useCallback} from 'react';
import {SelectionState, SelectionStateComplement} from '../types';

type Options<T> = SelectionStateComplement<T> & {
  isItemSelectedInitially?: (item: T, index: number) => boolean;
  onChange?: (updatedItems: T[]) => void;
};

/**
 * The hook returns the selection control object for the table. The control
 * object takes into account various features of the table operation,
 * for example, it considers the unavailability of certain table rows
 * for selection.
 */
export const useDataTableSelection = <T>(
  items: T[],
  {isItemSelectedInitially, onChange, ...complement}: Options<T> = {},
): SelectionState<T> => {
  const multiSelect = useMultiSelect(items, isItemSelectedInitially, onChange);

  // The function checks whether the item is available for selection.
  const isUndergo = useCallback((item: T) => !complement?.isItemDisabled?.(item), [complement?.isItemDisabled]);

  return {
    ...multiSelect,
    ...complement,
    toggleAll: useCallback(() => {
      const undergoItems = multiSelect.getAllItems().filter(isUndergo);
      const selectedItems = multiSelect.getSelectedItems().filter(isUndergo);
      const isAllSelected = undergoItems.length === selectedItems.length;

      undergoItems.forEach((item) => {
        if (isAllSelected) {
          multiSelect.deselectItem(item);
        } else {
          multiSelect.selectItem(item);
        }
      });
    }, [multiSelect, isUndergo]),
  };
};
