import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  addressSectionTitle: {
    defaultMessage: 'Address',
    description: '[title] User form section title',
  },
  streetAddressHint: {
    defaultMessage: 'Full address without abbreviations, e.g: {example}',
    description: 'Street address form field hint',
  },
  streetAddressExample: {
    defaultMessage: 'Flat 5, 221B Baker Street',
    description: 'Street address form field example',
  },
});

export const fieldLabels = defineMessages({
  country: {
    defaultMessage: 'Country',
    description: 'User form field label',
  },
  postalCode: {
    defaultMessage: 'Postal code',
    description: 'User form field label',
  },
  city: {
    defaultMessage: 'City/town',
    description: 'User form field label',
  },
  streetAddress: {
    defaultMessage: 'Address',
    description: 'User form field label',
  },
  comments: {
    defaultMessage: 'Comments for delivery',
    description: 'User form field label',
  },
});
