import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {createLocator} from '../../../create-locator';
import {Radio} from '../../../Radio';
import {RadioGroup} from '../../../RadioGroup';
import {Control} from '../../Control';
import {messages} from './messages';
import {ControlRenderProps, Value} from '../../types';
import {Options} from './types';

type Props = ControlRenderProps<boolean, Options>;

export function BooleanControl({value, onClose, onSubmit, name, options}: Props) {
  const [localValue, setLocalValue] = useState<Value<boolean>>(value);
  const locator = createLocator(options);

  return (
    <Control onClose={onClose} onSubmit={() => onSubmit(localValue)} {...locator.control()}>
      <RadioGroup ariaLabel={name}>
        {[
          {value: true, label: <FormattedMessage {...messages.yes} />},
          {value: false, label: <FormattedMessage {...messages.no} />},
        ].map((option) => (
          <Radio
            name={name}
            label={option.label}
            onChange={() => setLocalValue(option.value)}
            checked={localValue === option.value}
            key={String(option.value)}
            {...locator.radio()}
          />
        ))}
      </RadioGroup>
    </Control>
  );
}
