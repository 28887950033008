import {getDisplayName} from '@joomcode/deprecated-utils/react/getDisplayName';
import {SecurePermission} from 'domain/permission/model/secure';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {RouteProps} from 'react-router-dom';
import {useAcl} from 'services/acl/useAcl';

type Props = RouteProps;

export function withSecurePermission<ComponentProps>(
  Component: React.ComponentType<ComponentProps>,
  securePermission: SecurePermission,
  FallbackComponent: React.ComponentType = NotFoundPage,
) {
  function PrivateRoute(props: Props & ComponentProps) {
    const acl = useAcl();

    if (!acl.hasSecurePermission(securePermission)) {
      return <FallbackComponent />;
    }

    return <Component {...props} />;
  }

  PrivateRoute.displayName = `withPermission(${getDisplayName(Component)})`;
  return PrivateRoute;
}
