import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OfficePolicyRecord} from 'domain/officePolicyRecord/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {
  createOfficePolicyRecordFx,
  deleteOfficePolicyRecordFx,
  getAllOfficePolicyRecordsFx,
  getMyOfficePolicyRecordsFx,
  getSubordinateOfficePolicyRecordsFx,
  updateOfficePolicyRecordFx,
} from '.';

type State = {
  byUserId: Record<UserId, OfficePolicyRecord[]>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $officePolicyRecords = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on(
    [getAllOfficePolicyRecordsFx, getMyOfficePolicyRecordsFx, getSubordinateOfficePolicyRecordsFx],
    (state, userId) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADING,
        },
      };
    },
  )
  .on(
    [getAllOfficePolicyRecordsFx.fail, getMyOfficePolicyRecordsFx.fail, getSubordinateOfficePolicyRecordsFx.fail],
    (state, {params: userId}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.FAILED,
        },
      };
    },
  )
  .on(
    [getAllOfficePolicyRecordsFx.done, getMyOfficePolicyRecordsFx.done, getSubordinateOfficePolicyRecordsFx.done],
    (state, {params: userId, result}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: result,
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADED,
        },
      };
    },
  )
  .on([createOfficePolicyRecordFx.done, updateOfficePolicyRecordFx.done], (state, {params: {userId}, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: result,
      },
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADED,
      },
    };
  })
  .on(deleteOfficePolicyRecordFx.done, (state, {params: {userId, id}}) => {
    if (state.dataStateByUserId[userId] !== DataState.LOADED) {
      return state;
    }
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: state.byUserId[userId].filter((record) => record.id !== id),
      },
    };
  });
