import {defineMessages} from 'react-intl';
import {JobContractStatus} from '.';

export const contractStatusNames = defineMessages<JobContractStatus>({
  [JobContractStatus.ACTIVE]: {
    defaultMessage: 'Active',
    description: 'Job contract status',
  },
  [JobContractStatus.ON_HOLD]: {
    defaultMessage: 'On hold',
    description: 'Job contract status',
  },
  [JobContractStatus.TERMINATED]: {
    defaultMessage: 'Terminated',
    description: 'Job contract status',
  },
});
