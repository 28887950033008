export enum ItemType {
  OPTION = 'option',
  GROUP = 'group',
}

export type Option<Item> = {
  type: ItemType.OPTION;
  data: Item;
};

export type OptionGroup<Item, Group> = {
  type: ItemType.GROUP;
  data: Group;
  items: Option<Item>[];
};

export type OptionOrGroup<Item, Group> = Option<Item> | OptionGroup<Item, Group>;
