import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {useCompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestReadAccess';
import {HrConsoleTab} from 'domain/hrConsole/model/tabs';
import {SecurePermission} from 'domain/permission/model/secure';
import {useMemo} from 'react';
import {useAcl} from 'services/acl';

type ReturnShape = {
  availableTabs: HrConsoleTab[];
  showTabs: boolean;
};

export function useHrConsolePageTabs(): ReturnShape {
  const acl = useAcl();
  const canReadUserFullInfo = acl.hasSecurePermission(SecurePermission.USER_FULL_INFO_RECORD_READ);
  const canReadSalaryRanges = acl.hasSecurePermission(SecurePermission.SALARY_RANGE_READ_AS_ADMIN);
  const canManageSalaryRanges = acl.hasSecurePermission(SecurePermission.SALARY_RANGE_MANAGE);
  const compensationReviewRequestReadAccess = useCompensationReviewRequestReadAccess();
  const canReadCompensationReviewRequests = Boolean(compensationReviewRequestReadAccess);

  const tabAvailability: Record<HrConsoleTab, boolean> = useMemo(
    () => ({
      [HrConsoleTab.USERS]: canReadUserFullInfo,
      [HrConsoleTab.CURRENT_SALARY_RANGES]: canReadSalaryRanges,
      [HrConsoleTab.MANAGEMENT]: canManageSalaryRanges,
      [HrConsoleTab.COMPENSATION_REVIEW_REQUESTS]: canReadCompensationReviewRequests,
    }),
    [canReadUserFullInfo, canReadSalaryRanges, canManageSalaryRanges, canReadCompensationReviewRequests],
  );

  const availableTabs = useMemo(
    () => getEnumValues(HrConsoleTab).filter((tab) => tabAvailability[tab]),
    [tabAvailability],
  );

  return {
    availableTabs,
    showTabs: availableTabs.length > 1,
  };
}
