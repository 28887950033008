import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {OfficePolicyRecord, officePolicyRecordSchema} from 'domain/officePolicyRecord/model';
import {UserId} from 'domain/user/model';

export const getAll = async (userId: UserId): Promise<OfficePolicyRecord[]> => {
  const {data} = await intranetApi.get('/v1/users/officePolicyRecords/getAll', {params: {userId}});

  return array(officePolicyRecordSchema).runWithException(data);
};
