import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination, useOffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {ResourceId} from 'domain/resource/model';
import {RoleBinding} from 'domain/roleBinding/model';
import {getRoleBindingRequestsFx} from 'domain/roleBinding/stores/requests';
import {$roleBindingRequestList, $roleBindingRequestsCount} from 'domain/roleBinding/stores/requests/state';
import {useStore} from 'effector-react';
import {useEffect, useMemo} from 'react';
import {toaster} from 'services/toaster';

type PaginationOptions = {
  initialLimit: number;
  initialPage: number;
};

type ReturnShape = {
  roleBindingRequests: RoleBinding[];
  roleBindingRequestsDataState: DataState;
  roleBindingRequestsCount: number;
  roleBindingRequestsPagination: OffsetPagination;
};

export function useResourceRoleBindingRequests(
  resourceId: ResourceId,
  paginationOptions: PaginationOptions = {initialLimit: 25, initialPage: 1},
): ReturnShape {
  const {data, dataState} = useStore($roleBindingRequestList);
  const {count} = useStore($roleBindingRequestsCount);
  const pagination = useOffsetPagination(paginationOptions);
  const pagingAndFilterOptions = useMemo(() => {
    return {
      offset: pagination.offset,
      limit: pagination.limit,
      filters: {
        resourceId,
      },
    };
  }, [pagination.offset, pagination.limit, resourceId]);

  useEffect(() => {
    getRoleBindingRequestsFx(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
  }, [pagingAndFilterOptions]);

  return {
    roleBindingRequests: data,
    roleBindingRequestsCount: count,
    roleBindingRequestsDataState: dataState,
    roleBindingRequestsPagination: pagination,
  };
}
