import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {
  CommonFieldProps,
  composeValidators,
  getFieldErrorText,
  getFieldValidator,
  useField,
  validateFilled,
} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {ContractType} from 'domain/jobContract/model/type';
import {formatContractType} from 'domain/jobContract/model/type/formatter';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

export type Props = CommonFieldProps<ContractType>;

export function FieldContractType({
  disabled,
  hint,
  initialValue,
  label,
  name,
  required,
  reserveSpaceForError,
  validate,
  extraErrorMessages,
  ...restProps
}: Props) {
  const intl = useIntl();
  const itemToString = useCallback((contractType: ContractType) => formatContractType(contractType, intl), [intl]);
  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {input, meta} = useField<ContractType>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
  });

  return (
    <FormControl
      disabled={meta.submitting || disabled}
      error={getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      label={label}
      hint={hint}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <SimpleAutocomplete<ContractType>
          items={getEnumValues(ContractType)}
          getItemKey={identity}
          itemToString={itemToString}
          renderItem={itemToString}
          {...formControlProps}
          {...input}
          {...restProps}
          disabled={meta.submitting || disabled}
        />
      )}
    </FormControl>
  );
}
