import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {JobContractStatus} from 'domain/jobContract/model/status';
import {formatJobContractStatus} from 'domain/jobContract/model/status/formatter';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  status: JobContractStatus;
};

const intentByStatus: Record<JobContractStatus, BadgeIntent> = {
  [JobContractStatus.TERMINATED]: 'warning',
  [JobContractStatus.ON_HOLD]: 'primary',
  [JobContractStatus.ACTIVE]: 'positive',
};

export function JobContractStatusBadge({status}: Props) {
  const intl = useIntl();
  return <Badge intent={intentByStatus[status]}>{formatJobContractStatus(status, intl)}</Badge>;
}
