import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Field, validateFilled} from '@joomcode/joom-form';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {EditableValue} from 'components/ui/EditableValue';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import addYears from 'date-fns/addYears';
import {offersApi} from 'domain/offer/api';
import {OfferId} from 'domain/offer/model';
import {updateNewcomerStartWorkingDateFx} from 'domain/offer/stores/main';
import {dateToString, FormattedDate, stringToDate} from 'models/system/formattedDate';
import React, {useCallback, useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  disabled?: boolean;
  disabledHint?: string;
  initialValue: FormattedDate;
  offerId: OfferId;
};

type Diff = {
  startWorkingDate: FormattedDate;
};

const today = new Date();
const todayPlusYear = addYears(today, 1);

export function StartWorkingDateForm({disabled = false, disabledHint, initialValue, offerId}: Props) {
  const intl = useIntl();
  const [busyDates, setBusyDates] = useState<FormattedDate[]>([]);

  const loadBusyDates = useAsyncTask(() => offersApi.getBusyDates({id: offerId}).then(setBusyDates), [setBusyDates]);
  useEffect(() => {
    if (!isLoadingOrLoaded(loadBusyDates.dataState)) {
      loadBusyDates.perform();
    }
  }, []);

  const isDateBusy = useCallback((date: Date) => busyDates.includes(dateToString(date)), [busyDates]);

  const renderDayContents = useCallback(
    (dayOfMonth: number, date: Date) =>
      isDateBusy(date) ? (
        <span className={styles.busyDate} title={intl.formatMessage(messages.busyDateHint)}>
          {dayOfMonth}
        </span>
      ) : (
        dayOfMonth
      ),
    [isDateBusy, intl],
  );

  const onSubmit = useCallback(
    ({startWorkingDate}: Diff) =>
      updateNewcomerStartWorkingDateFx({offerId, startWorkingDate})
        .catch(toaster.interceptThenThrowError)
        .then(() => undefined),
    [offerId],
  );

  return (
    <Form<Diff> onSubmit={onSubmit}>
      {({handleSubmit, submitting, valid}) => (
        <Field<string, HTMLInputElement, Date | null>
          name='startWorkingDate'
          initialValue={initialValue}
          format={stringToDate}
          parse={dateToString}
          validate={validateFilled}
        >
          {({input: {name}, meta}) => (
            <EditableValue<FormattedDate>
              buttonHint={disabled ? disabledHint : undefined}
              disableEdit={disabled}
              disableSubmit={disabled || !valid || submitting}
              error={getFieldErrorText(meta, {intl})}
              onSubmit={handleSubmit}
              processing={submitting}
              renderValue={(date: FormattedDate) => <HumanDate format='long' value={date} utc />}
              value={initialValue}
            >
              {loadBusyDates.dataState === DataState.LOADING ? (
                <Spinner />
              ) : (
                <FieldDate
                  name={name}
                  minDate={today}
                  maxDate={todayPlusYear}
                  renderDayContents={renderDayContents}
                  withFormControl={false}
                />
              )}
            </EditableValue>
          )}
        </Field>
      )}
    </Form>
  );
}
