import {DataState} from '@joomcode/deprecated-utils/dataState';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {OfficePolicyRecord, OfficePolicyRecordId} from 'domain/officePolicyRecord/model';
import {deleteOfficePolicyRecordFx} from 'domain/officePolicyRecord/stores/main';
import {OfficePolicyRecordsTable} from 'domain/officePolicyRecord/widgets/Table';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  userId: UserId;
  records: OfficePolicyRecord[];
  dataState: DataState;
  onDialogOpenWithPayload(record: OfficePolicyRecord): void;
};

export function OfficePolicy({userId, records, dataState, onDialogOpenWithPayload}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canEdit = acl.hasPermission(Permission.OFFICE_POLICY_RECORD_WRITE);
  const onDeleteRecordClick = useCallback(
    (id: OfficePolicyRecordId) => {
      confirm(
        {
          title: intl.formatMessage(messages.deletionConfirmationTitle),
          text: intl.formatMessage(messages.deletionConfirmationText),
          confirmationText: intl.formatMessage(messages.deletionConfirmationButton),
          intent: 'negative',
          onConfirm: () => deleteOfficePolicyRecordFx({id, userId}).catch(toaster.interceptThenThrowError),
        },
        intl,
      );
    },
    [intl, userId],
  );

  return (
    <OfficePolicyRecordsTable
      records={records}
      dataState={dataState}
      {...(canEdit && {
        onEditItem: onDialogOpenWithPayload,
        onDeleteItem: onDeleteRecordClick,
      })}
    />
  );
}
