import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {OneTimeBonusRecord, oneTimeBonusRecordSchema} from 'domain/oneTimeBonusRecord/model';
import {GetOneTimeBonusRecordsParams} from './getAny';

export const getSubordinate = async (params: GetOneTimeBonusRecordsParams): Promise<OneTimeBonusRecord[]> => {
  const {data} = await intranetApi.get('/v1/secure/users/oneTimeBonusRecords/getSubordinateByLegalEntityId', {params});

  return array(oneTimeBonusRecordSchema).runWithException(data);
};
