import {$self} from 'domain/self/stores/main/state';
import {useStore} from 'effector-react';
import {hasPermission, hasSecurePermission} from './hasPermission';
import {inVpn} from './inVpn';
import {Acl} from './types';

const acl = {
  hasPermission,
  hasSecurePermission,
  inVpn,
};

export function useAcl(): Acl {
  useStore($self);

  return acl;
}
