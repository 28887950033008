import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OfferId} from 'domain/offer/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {getUserOfferIdFx} from '.';

type State = {
  byUserId: Record<UserId, OfferId>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $userOfferIds = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on(getUserOfferIdFx, (state, userId) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on(getUserOfferIdFx.fail, (state, {params: userId}) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.FAILED,
      },
    };
  })
  .on(getUserOfferIdFx.done, (state, {result, params: userId}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: result,
      },
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADED,
      },
    };
  });
