import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  startDate: {
    defaultMessage: 'Start date',
    description: 'Salary record form: field label',
  },
  endDate: {
    defaultMessage: 'End date',
    description: 'Salary record form: field label',
  },
  amount: {
    defaultMessage: 'Salary (gross)',
    description: 'Salary record form: field label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'Salary record form: field label',
  },
  frequency: {
    defaultMessage: 'Frequency',
    description: 'Salary record form: field label',
  },
  employmentRate: {
    defaultMessage: 'Employment rate',
    description: 'Salary record form: field label',
  },
  region: {
    defaultMessage: 'Region',
    description: 'Salary record form: field label',
  },
  comment: {
    defaultMessage: 'Comment',
    description: 'Salary record form: field label',
  },
  specialArrangements: {
    defaultMessage: 'Special arrangements',
    description: 'Salary record form: field label',
  },
});
