import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  permissionsLabel: {
    defaultMessage: 'Permissions',
    description: 'Form field label',
  },
  permissionsLoadError: {
    defaultMessage: 'No permissions loaded',
    description: 'Field error',
  },
});
