import cn from 'classnames';
import React from 'react';
import {ReactComponent as Icon} from '../../../icons/core/caret.svg';
import styles from './index.css';

type Props = {
  disabled?: boolean;
  isActive?: boolean;
  isOpen?: boolean;
};

export function IconCaret({disabled, isActive, isOpen}: Props) {
  return (
    <Icon
      className={cn(styles.icon, {
        [styles.iconActive]: isActive,
        [styles.iconDisabled]: disabled,
        [styles.iconOpen]: isOpen,
      })}
    />
  );
}
