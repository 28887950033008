import {DataState} from '@joomcode/deprecated-utils/dataState';
import {ClientErrorStatus} from 'apiClient/ClientError';
import {Permission} from 'domain/permission/model';
import {UserFull} from 'domain/user/model';
import {isUserFull} from 'domain/user/model/utils';
import {loadUserByLoginFx, loadUserEverWorkedByLoginFx} from 'domain/user/stores/main';
import {$users} from 'domain/user/stores/main/state';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: UserFull | undefined;
  dataState: DataState;
  error: ClientErrorStatus | undefined;
};

export const useUserLoader = (login: string): ReturnShape => {
  const acl = useAcl();
  const [user, error, dataState] = useStoreMap({
    store: $users,
    keys: [login],
    fn: (users, [userLogin]) => [
      users.byLogin[userLogin] ?? users.terminatedUserByLogin[userLogin],
      users.errorByLogin[userLogin],
      users.stateByLogin[userLogin] ?? DataState.IDLE,
    ],
  });
  const canReadTerminatedUsers = acl.hasPermission(Permission.USER_READ_ANY);

  useEffect(() => {
    if (dataState === DataState.LOADING || dataState === DataState.LOADED) {
      return;
    }
    (canReadTerminatedUsers ? loadUserEverWorkedByLoginFx : loadUserByLoginFx)({login}).catch(
      toaster.interceptThenThrowError,
    );
  }, [canReadTerminatedUsers, login]);

  return {
    data: user && isUserFull(user) ? user : undefined,
    dataState,
    error,
  };
};
