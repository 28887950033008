import {getEnumFormatter} from '@joomcode/deprecated-utils/intl/getEnumFormatter';
import {IntlShape} from 'react-intl';
import {paidTimeOffTypes, TimeOffType} from '.';
import {timeOffTypeMessages} from './messages';

export const formatTimeOffType = (type: TimeOffType, intl: IntlShape, withIcon: boolean = false) => {
  const formatter = getEnumFormatter(timeOffTypeMessages);
  const message = formatter(type, intl);

  return withIcon && paidTimeOffTypes.includes(type) ? `💰 ${message}` : message;
};
