import React from 'react';
import {FormattedNumber, FormatNumberOptions} from 'react-intl';

export type Price = {
  amount: number;
  currency?: string;
};

export type FormattedPriceProps = {
  value: Price;
  showFractionDigitsForInteger?: boolean;
  currencyDisplay?: FormatNumberOptions['currencyDisplay'];
};

const fractionDigitsCount = 2;

export const getFormattedPriceOptions = (value: Price, showFractionDigitsForInteger = false): FormatNumberOptions => {
  const minimumFractionDigits =
    !showFractionDigitsForInteger && Number.isInteger(value.amount) ? 0 : fractionDigitsCount;

  return {
    style: value.currency ? 'currency' : undefined,
    currency: value.currency || undefined,
    minimumFractionDigits,
    maximumFractionDigits: fractionDigitsCount,
  };
};

export function FormattedPrice({value, showFractionDigitsForInteger = false, currencyDisplay}: FormattedPriceProps) {
  return (
    <FormattedNumber
      {...getFormattedPriceOptions(value, showFractionDigitsForInteger)}
      value={value.amount}
      currencyDisplay={currencyDisplay}
    />
  );
}
