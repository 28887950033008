import cn from 'classnames';
import {createLocator, type Locator, type Mark, removeMarkFromProperties} from 'create-locator';
import React from 'react';
import styles from './index.css';

export type DialogBodyLocator = Locator<void>;

type Props = {
  children: React.ReactNode;
  withDefaultPadding?: boolean;
} & Partial<Mark<DialogBodyLocator>>;

export const Body = React.forwardRef<HTMLDivElement, Props>(({children, withDefaultPadding, ...rest}, ref) => {
  const locator = createLocator(rest);
  const props = removeMarkFromProperties(rest);

  return (
    <div
      ref={ref}
      className={cn(styles.body, {
        [styles.bodyWithDefaultPadding]: withDefaultPadding,
      })}
      {...props}
      {...locator()}
    >
      {children}
    </div>
  );
});
