import {createLocator, removeMarkFromProperties, type Locator, type Mark} from 'create-locator';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import cn from 'classnames';
import React from 'react';
import {useDialogPrivateContext} from '../context';

import styles from './index.css';

export type DialogFooterLocator = Locator<void>;

type Props = {
  align?: 'left' | 'right' | 'spaceBetween';
  children: React.ReactNode;
} & Partial<Mark<DialogFooterLocator>>;

export function Footer({children, align = 'right', ...rest}: Props) {
  const locator = createLocator(rest);
  const props = removeMarkFromProperties(rest);
  const {size} = useDialogPrivateContext();

  return (
    <div
      className={cn(
        styles.footer,
        styles[`align${capitalize(align)}` as keyof typeof styles],
        styles[`footerSize${capitalize(size)}` as keyof typeof styles],
      )}
      {...props}
      {...locator()}
    >
      {children}
    </div>
  );
}
