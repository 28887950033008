import React from 'react';
import {identity, isNullish} from '@joomcode/deprecated-utils/function';
import {FilterItem, Value} from '../../types';
import {DateRangeControl} from './DateRangeControl';
import {Options, DataRangeValue} from './types';
import {DateRangeLabel} from './DateRangeLabel';

export function createGenericDateRangeFilter<ExternalValue>(
  filterName: string,
  options: Options & {
    parseValue(innerValue: Value<DataRangeValue>): Value<ExternalValue>;
    formatValue(value: Value<ExternalValue>): Value<DataRangeValue>;
  },
): FilterItem<ExternalValue, Options> {
  return {
    name: filterName,
    options,
    isEmpty: (value) => {
      const formattedValue = options.formatValue(value);

      return isNullish(formattedValue?.from) && isNullish(formattedValue?.to);
    },
    renderControl: (props) => (
      <DateRangeControl
        {...props}
        onSubmit={(value) => props.onSubmit(options.parseValue(value))}
        value={options.formatValue(props.value)}
      />
    ),
    renderLabel: (props) => <DateRangeLabel {...props} value={options.formatValue(props.value)} />,
  };
}

export function createDateRangeFilter(filterName: string, options: Options): FilterItem<DataRangeValue, Options> {
  return createGenericDateRangeFilter(filterName, {
    ...options,
    parseValue: identity,
    formatValue: identity,
  });
}
