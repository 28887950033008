import {identity} from '@joomcode/deprecated-utils/function';
import {CommonFieldProps, composeValidators, getFieldValidator, useField, validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {
  oldTimeOffTypesAvailableForRequest,
  TimeOffType,
  timeOffTypesAvailableForRequest,
} from 'domain/timeOff/type/model';
import {formatTimeOffType} from 'domain/timeOff/type/model/formatter';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Feature, useFeature} from 'services/features';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

export type Props = CommonFieldProps<TimeOffType>;

export function FieldTimeOffType({
  name,
  label,
  hint,
  required,
  disabled,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const officePolicyFeature = useFeature(Feature.OFFICE_POLICY);
  const items = useMemo(
    () => (officePolicyFeature.isAvailable ? timeOffTypesAvailableForRequest : oldTimeOffTypesAvailableForRequest),
    [officePolicyFeature.isAvailable],
  );
  const timeOffTypeToString = useCallback((type: TimeOffType) => formatTimeOffType(type, intl), [intl]);
  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;
  const {input, meta} = useField<TimeOffType>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    subscription: {dirty: true},
  });

  return (
    <FormControl
      hint={hint}
      label={label}
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <SimpleAutocomplete<TimeOffType>
          items={items}
          getItemKey={identity}
          itemToString={timeOffTypeToString}
          renderItem={timeOffTypeToString}
          {...formControlProps}
          {...input}
          {...restProps}
          disabled={meta.submitting || disabled}
        />
      )}
    </FormControl>
  );
}
