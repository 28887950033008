import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {salaryRangesApi} from 'domain/salaryRange/api';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function SalaryRangesDownloadButton() {
  const intl = useIntl();

  return (
    <DownloadLinkButton
      size='m'
      kind='text'
      intent='neutral'
      href={salaryRangesApi.buildDownloadLink()}
      iconLeft={<DownloadIcon />}
    >
      {intl.formatMessage(messages.button)}
    </DownloadLinkButton>
  );
}
