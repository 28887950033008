import {intranetApi} from 'apiClient';
import {
  CompensationReviewRequest,
  CompensationReviewRequestId,
  compensationReviewRequestSchema,
} from 'domain/compensationReviewRequest/model';

export type CompensationReviewRequestRejectDiff = {
  comment: string;
};

export type CompensationReviewRequestRejectConfig = CompensationReviewRequestRejectDiff & {
  id: CompensationReviewRequestId;
};

const rejectGeneric =
  (endpoint: string) =>
  async ({id, comment}: CompensationReviewRequestRejectConfig): Promise<CompensationReviewRequest> => {
    const {data} = await intranetApi.post(endpoint, {comment}, {params: {id}});

    return compensationReviewRequestSchema.runWithException(data);
  };

export const reject = rejectGeneric('/v1/secure/compensationReviewRequests/reject');
export const rejectOnBehalf = rejectGeneric('/v1/secure/compensationReviewRequests/rejectOnBehalf');
