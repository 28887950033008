import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  managerConsole: {
    defaultMessage: 'Manager console',
    description: '[title] Page title',
  },
  compensationReviewRequest: {
    defaultMessage: 'Compensation review request',
    description: '[title] Page title',
  },
});
