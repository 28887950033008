import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {Panel} from '@joomcode/joom-ui/Panel';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {Toggle} from '@joomcode/joom-ui/Toggle';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {CompensationReport, CompensationReportDiff} from 'domain/compensations/report/model';
import {CompensationRequestId} from 'domain/compensations/request/model';
import {submitReportFx, updateReportFx} from 'domain/compensations/request/stores/main';
import {FormApi} from 'final-form';
import React, {useCallback, useMemo, useRef} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {compensationsUrls} from 'routes/compensations/urls';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {FieldBoardingPasses} from './FieldBoardingPasses';
import {FieldReceipts} from './FieldReceipts';
import {fieldLabels, messages} from './messages';
import styles from './styles.css';

type Props = {
  compensationRequestId: CompensationRequestId;
  report: CompensationReport;
};

export function CompensationReportForm({compensationRequestId, report}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const formId = useMemo(uuid, []);
  const noFlightsSwitchId = useMemo(uuid, []);
  const finalSubmitMode = useRef<boolean>(false);
  const submissionConfirmed = useRef<boolean>(false);
  const receiptsGuideLink = (linkText: React.ReactNode) => (
    <StyledExternalLink href='https://www.notion.so/joomteam/653e8819d7e44b00a21e76a332d36d80' colored>
      {linkText}
    </StyledExternalLink>
  );

  const resetSubmissionMode = () => {
    finalSubmitMode.current = false;
    submissionConfirmed.current = false;
  };

  const onSubmit = useCallback(
    (diff: CompensationReportDiff, form: FormApi<CompensationReportDiff>) => {
      if (finalSubmitMode.current) {
        if (submissionConfirmed.current) {
          return submitReportFx({id: report.id, diff})
            .catch((error) => {
              resetSubmissionMode();
              toaster.interceptThenThrowError(error);
            })
            .then(() => history.push(compensationsUrls.request({compensationRequestId}, {reported: 'true'})));
        }
        return confirm(
          {
            title: intl.formatMessage(messages.confirmSubmitTitle),
            text: intl.formatMessage(messages.confirmSubmitText),
            confirmationText: intl.formatMessage(messages.confirmSubmitButton),
            onConfirm: () => {
              submissionConfirmed.current = true;
              form.submit();
            },
            afterClose: () => {
              resetSubmissionMode();
            },
          },
          intl,
        );
      }

      return updateReportFx({id: report.id, diff})
        .catch(toaster.interceptThenThrowError)
        .then(() => toaster.success(intl.formatMessage(messages.successSaveMessage)));
    },
    [report.id, compensationRequestId, intl],
  );

  const onFinalSubmitClick = useCallback(() => {
    finalSubmitMode.current = true;
  }, []);

  return (
    <Form<CompensationReportDiff> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid, values}) => (
        <form id={formId} onSubmit={handleSubmit} className={styles.root}>
          <Panel stretchHeight withMarginBottom={false}>
            <Panel.Header title={intl.formatMessage(messages.boardingPassesTitle)} withSeparator />
            <Panel.Content withPadding>
              <div className={styles.annotation}>{intl.formatMessage(messages.boardingPassesAnnotation)}</div>

              <Field<boolean> name='hasNoFlights' initialValue={Boolean(report.hasNoFlights)} type='checkbox'>
                {({input: {value, ...input}}) => (
                  <div className={styles.switchField}>
                    <Toggle defaultChecked={value} disabled={submitting} id={noFlightsSwitchId} {...input} />
                    <label htmlFor={noFlightsSwitchId} className={styles.switchLabel}>
                      {intl.formatMessage(fieldLabels.hasNoFlights)}
                    </label>
                  </div>
                )}
              </Field>

              <div className={styles.boardingPasses}>
                <FieldBoardingPasses
                  initialFileMetas={report.boardingPasses}
                  disabled={submitting || values.hasNoFlights}
                />
              </div>
            </Panel.Content>
            <Panel.Header title={intl.formatMessage(messages.expensesTitle)} withSeparator />
            <Panel.Content withPadding>
              <div className={styles.annotation}>
                {intl.formatMessage(messages.receiptsAnnotation, {link1: receiptsGuideLink})}
              </div>
              <FieldReceipts
                compensationRequestId={compensationRequestId}
                disabled={submitting}
                initialReceipts={report.receipts}
              />
            </Panel.Content>
          </Panel>
          <SubmitFormPanel>
            <ButtonGroup spaced size='l'>
              <Button
                form={formId}
                type='submit'
                kind='primary'
                intent='neutral'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.saveDraftButton)}
              </Button>
              <Button
                form={formId}
                type='submit'
                kind='primary'
                intent='primary'
                disabled={invalid}
                loading={submitting}
                onClick={onFinalSubmitClick}
              >
                {intl.formatMessage(messages.submitButton)}
              </Button>
            </ButtonGroup>
          </SubmitFormPanel>
        </form>
      )}
    </Form>
  );
}
