import {defineMessages} from 'react-intl';
import {FileSizeUnit} from '@joomcode/deprecated-utils/measure/measures/fileSize';

export const messages = defineMessages<FileSizeUnit>({
  [FileSizeUnit.Byte]: {
    id: 'formatters-information-measure-unit-byte',
    description: 'Formatted file size in bytes (abbreviated form)',
    defaultMessage: '{value} B',
  },
  [FileSizeUnit.Kilobyte]: {
    id: 'formatters-information-measure-unit-kilobyte',
    description: 'Formatted file size in kilobytes (abbreviated form)',
    defaultMessage: '{value} kB',
  },
  [FileSizeUnit.Megabyte]: {
    id: 'formatters-information-measure-unit-megabyte',
    description: 'Formatted file size in megabytes (abbreviated form)',
    defaultMessage: '{value} MB',
  },
  [FileSizeUnit.Gigabyte]: {
    id: 'formatters-information-measure-unit-gigabyte',
    description: 'Formatted file size in gigabytes (abbreviated form)',
    defaultMessage: '{value} GB',
  },
  [FileSizeUnit.Terabyte]: {
    id: 'formatters-information-measure-unit-terabyte',
    description: 'Formatted file size in terabytes (abbreviated form)',
    defaultMessage: '{value} TB',
  },
});
