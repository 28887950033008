import cn from 'classnames';
import {SearchHighlighter} from 'components/ui/SearchHighlighter';
import {Tree} from 'components/ui/Tree';
import {User, UserId} from 'domain/user/model';
import {TreeViewState} from 'hooks/useTreeView/types';
import React, {useCallback} from 'react';
import {highlightSearchWords} from 'utils/search/highlight';
import styles from './styles.css';
import {UserTreeItem} from './TreeItem';

type Props = {
  searchWords: string[];
  onItemToggle: (params: {id: UserId}) => void;
  usersById: Record<UserId, User>;
  viewState: TreeViewState<UserId>;
  selectedUserId?: UserId;
  narrow?: boolean;
  linkToUserPane: (user: User) => string;
};

export function UsersTree({
  searchWords,
  usersById,
  viewState,
  onItemToggle,
  selectedUserId,
  narrow,
  linkToUserPane,
}: Props) {
  const highlight = useCallback(
    (s: string): React.ReactNode => highlightSearchWords(s, searchWords, SearchHighlighter),
    [searchWords],
  );

  const renderTree = (userIds: UserId[], depth: number) => {
    return userIds
      .map((userId) => usersById[userId])
      .map((user) => {
        const viewItem = viewState.itemsById[user.id];
        return (
          <UserTreeItem
            depth={depth}
            viewItem={viewItem}
            onArrowClick={() => onItemToggle({id: user.id})}
            user={user}
            key={user.id}
            highlightSearch={highlight}
            selectedUserId={selectedUserId}
            linkToUserPane={linkToUserPane}
          >
            {viewItem.subitemIds.length > 0 && renderTree(viewItem.subitemIds, depth + 1)}
          </UserTreeItem>
        );
      });
  };

  return (
    <div className={cn({[styles.narrow]: narrow})}>
      <Tree>{renderTree(viewState.rootIds, 0)}</Tree>
    </div>
  );
}
