import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {Employment} from 'domain/employment/model';
import {TerminationInitiatedBy} from 'domain/employment/model/terminationInitiatedBy';
import {TerminationReason} from 'domain/employment/model/terminationReason';
import {TerminationType} from 'domain/employment/model/terminationType';
import {
  formatTerminationInitiatedBy,
  formatTerminationReason,
  formatTerminationType,
} from 'domain/employment/utils/format';
import {stringToDate} from 'models/system/formattedDate';
import React, {useCallback} from 'react';
import {Field, FormRenderProps, useForm} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  employment?: Employment;
  formId: string;
  onSubmit: FormRenderProps<Partial<Employment>>['handleSubmit'];
  submitting: boolean;
};

export function EmploymentForm({employment, formId, onSubmit, submitting}: Props) {
  const intl = useIntl();
  const {change, getState} = useForm();
  const renderTerminationReason = useCallback(
    (terminationReason: string) => formatTerminationReason(terminationReason, intl),
    [intl],
  );
  const renderTerminationInitiatedBy = useCallback(
    (terminateInitiatedBy: string) => formatTerminationInitiatedBy(terminateInitiatedBy, intl),
    [intl],
  );
  const renderTerminationType = useCallback(
    (terminationType: string) => formatTerminationType(terminationType, intl),
    [intl],
  );

  return (
    <form onSubmit={onSubmit} id={formId}>
      <Grid>
        <Grid.Item xl={6} xs={12}>
          <FieldDate
            name='terminationDate'
            label={intl.formatMessage(labels.terminationDate)}
            minDate={employment && (stringToDate(employment.hireDate) ?? undefined)}
            initialValue={employment?.terminationDate}
            required
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldDate
            name='legalTerminationDate'
            label={intl.formatMessage(labels.legalTerminationDate)}
            initialValue={employment?.legalTerminationDate}
            required
            minDate={employment && (stringToDate(employment.hireDate) ?? undefined)}
          />
          <OnChange name='terminationDate'>
            {(terminationDateValue) => {
              if (!getState().values.legalTerminationDate) {
                change('legalTerminationDate', terminationDateValue);
              }
            }}
          </OnChange>
        </Grid.Item>
        <Grid.Item xl={12} xs={12}>
          <Field<TerminationReason> name='terminationReason' initialValue={employment?.terminationReason}>
            {({input}) => (
              <FormControl disabled={submitting} label={intl.formatMessage(labels.terminationReason)}>
                {(formControlProps) => (
                  <SimpleAutocomplete<TerminationReason>
                    items={getEnumValues(TerminationReason)}
                    getItemKey={identity}
                    itemToString={renderTerminationReason}
                    renderItem={renderTerminationReason}
                    disabled={submitting}
                    clearable
                    {...formControlProps}
                    {...input}
                  />
                )}
              </FormControl>
            )}
          </Field>
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <Field<TerminationInitiatedBy>
            name='terminationInitiatedBy'
            initialValue={employment?.terminationInitiatedBy}
          >
            {({input}) => (
              <FormControl disabled={submitting} label={intl.formatMessage(labels.terminationInitiatedBy)}>
                {(formControlProps) => (
                  <SimpleAutocomplete<TerminationInitiatedBy>
                    items={getEnumValues(TerminationInitiatedBy)}
                    getItemKey={identity}
                    itemToString={renderTerminationInitiatedBy}
                    renderItem={renderTerminationInitiatedBy}
                    disabled={submitting}
                    clearable
                    {...formControlProps}
                    {...input}
                  />
                )}
              </FormControl>
            )}
          </Field>
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <Field<TerminationType> name='terminationType' initialValue={employment?.terminationType}>
            {({input}) => (
              <FormControl disabled={submitting} label={intl.formatMessage(labels.terminationType)}>
                {(formControlProps) => (
                  <SimpleAutocomplete<TerminationType>
                    items={getEnumValues(TerminationType)}
                    getItemKey={identity}
                    itemToString={renderTerminationType}
                    renderItem={renderTerminationType}
                    disabled={submitting}
                    clearable
                    {...formControlProps}
                    {...input}
                  />
                )}
              </FormControl>
            )}
          </Field>
        </Grid.Item>
        <Grid.Item xl={12} xs={12}>
          <Field<string> name='terminationComment' initialValue={employment?.terminationComment} parse={identity}>
            {({input}) => (
              <FormControl disabled={submitting} label={intl.formatMessage(labels.terminationComment)}>
                {(formControlProps) => <Textarea {...formControlProps} {...input} disabled={submitting} />}
              </FormControl>
            )}
          </Field>
        </Grid.Item>
      </Grid>
    </form>
  );
}
