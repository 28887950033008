import {DataState} from '@joomcode/deprecated-utils/dataState';
import {TimeOffBalanceRecordsByType} from 'domain/timeOff/balanceRecord/model';
import {TimeOffBalanceRecordType} from 'domain/timeOff/balanceRecord/model/type';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {
  createTimeOffBalanceRecord,
  getMyTimeOffBalanceRecords,
  getMyTimeOffBalanceRecordsByTypeFx,
  getTimeOffBalanceRecords,
  getTimeOffBalanceRecordsByTypeFx,
} from '.';

type State = {
  byUserId: Record<UserId, TimeOffBalanceRecordsByType>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $balanceRecords = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on(
    [
      getMyTimeOffBalanceRecords,
      getMyTimeOffBalanceRecordsByTypeFx,
      getTimeOffBalanceRecords,
      getTimeOffBalanceRecordsByTypeFx,
    ],
    (state, userId) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADING,
        },
      };
    },
  )
  .on(
    [
      getMyTimeOffBalanceRecords.fail,
      getMyTimeOffBalanceRecordsByTypeFx.fail,
      getTimeOffBalanceRecords.fail,
      getTimeOffBalanceRecordsByTypeFx.fail,
    ],
    (state, {params: userId}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.FAILED,
        },
      };
    },
  )
  .on([getMyTimeOffBalanceRecords.done, getTimeOffBalanceRecords.done], (state, {params: userId, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: {
          remoteWorkBalanceRecords: [],
          vacationBalanceRecords: result.reverse(),
        },
      },
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADED,
      },
    };
  })
  .on(
    [getMyTimeOffBalanceRecordsByTypeFx.done, getTimeOffBalanceRecordsByTypeFx.done],
    (state, {params: userId, result}) => {
      const reversedResult = {
        remoteWorkBalanceRecords: result.remoteWorkBalanceRecords.reverse(),
        vacationBalanceRecords: result.vacationBalanceRecords.reverse(),
      };
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: reversedResult,
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADED,
        },
      };
    },
  )
  .on(createTimeOffBalanceRecord.done, (state, {params, result}) => {
    const existingVacationRecords = state.byUserId[params.userId].vacationBalanceRecords;
    const existingRemoteWorkRecords = state.byUserId[params.userId].remoteWorkBalanceRecords;

    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [params.userId]: {
          ...state.byUserId[params.userId],
          remoteWorkBalanceRecords:
            result.type === TimeOffBalanceRecordType.REMOTE_WORK
              ? [result].concat(existingRemoteWorkRecords)
              : existingRemoteWorkRecords,
          vacationBalanceRecords:
            result.type === TimeOffBalanceRecordType.VACATION
              ? [result].concat(existingVacationRecords)
              : existingVacationRecords,
        },
      },
    };
  });
