import {ValidationError} from '@joomcode/joom-form';
import {TimeOffRequestFormError} from 'domain/timeOff/request/model/error';
import {validateDatesOrder} from 'domain/timeOff/request/utils/validate';
import {FormState} from './state';

export const validateForm = (
  values: FormState,
): Partial<Record<keyof FormState, ValidationError<TimeOffRequestFormError> | undefined>> => {
  return {
    end: validateDatesOrder(values.start, values.end),
  };
};
