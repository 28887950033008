import composeRefs from '@seznam/compose-react-refs';
import cn from 'classnames';
import React, {forwardRef, useRef} from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../create-locator';
import styles from './index.css';

export type InputAutosizeTestId = ConvertLocatorToTestId<InputAutosizeLocator>;
export type InputAutosizeLocator = LocatorOfElement<void>;

const RESERVED_INPUT_WIDTH = 2;
function adjustInputWidthToContent(inputNode: HTMLInputElement) {
  /* eslint-disable no-param-reassign */
  inputNode.style.width = '0';
  inputNode.style.width = `${
    inputNode.offsetWidth + inputNode.scrollWidth - inputNode.clientWidth + RESERVED_INPUT_WIDTH
  }px`;
  /* eslint-enable no-param-reassign */
}

export const InputAutosize = forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements['input'] & Partial<Mark<InputAutosizeLocator>>
>((propertiesWithMark, ref) => {
  const locator = createLocator(propertiesWithMark);
  const props = removeMarkFromProperties(propertiesWithMark);

  const localRef = useRef<HTMLInputElement>(null);
  let scheduledFrameId: number | null = null;
  const scheduleUpdate = React.useCallback(() => {
    const inputNode = localRef.current;

    if (inputNode && !scheduledFrameId) {
      scheduledFrameId = requestAnimationFrame(() => {
        adjustInputWidthToContent(inputNode);
        scheduledFrameId = null;
      });
    }
  }, [localRef]);

  React.useLayoutEffect(() => {
    const inputNode = localRef.current;

    if (inputNode) {
      scheduleUpdate();

      inputNode.addEventListener('input', scheduleUpdate);

      return () => {
        inputNode.removeEventListener('input', scheduleUpdate);
      };
    }

    return undefined;
  }, [localRef.current]);

  React.useLayoutEffect(() => {
    scheduleUpdate();
  }, [props.value]);

  return (
    <input {...props} className={cn(styles.input, props.className)} {...locator()} ref={composeRefs(ref, localRef)} />
  );
});
