import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Questions',
    description: '[title] Stock option FAQ panel title',
  },
  basicInfo: {
    defaultMessage: 'You can find some useful information about options in <link>Notion</link>.',
    description: 'Stock option FAQ panel content',
  },
  furtherQuestions: {
    defaultMessage:
      "If you have any further questions, don't hesitate to contact <link>@{login}</link> in Slack or per email {email}.",
    description: 'Stock option FAQ panel content',
  },
});
