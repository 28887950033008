import {defineMessages} from 'react-intl';
import {TerminationReason} from '.';

export const terminationReasonMessages = defineMessages<TerminationReason>({
  [TerminationReason.BORING_TASKS]: {
    defaultMessage: 'Boring tasks',
    description: 'Employment termination reason',
  },
  [TerminationReason.COMPANY_SIZE]: {
    defaultMessage: 'Changing company scale',
    description: 'Employment termination reason',
  },
  [TerminationReason.CHANGING_JOB_AREA]: {
    defaultMessage: 'Changing job sphere',
    description: 'Employment termination reason',
  },
  [TerminationReason.COMPENSATION]: {
    defaultMessage: 'Compensation',
    description: 'Employment termination reason',
  },
  [TerminationReason.LACK_OF_GROWTH]: {
    defaultMessage: 'Lack of growth',
    description: 'Employment termination reason',
  },
  [TerminationReason.MANAGER_CONFLICT]: {
    defaultMessage: 'Didn’t work well with the manager',
    description: 'Employment termination reason',
  },
  [TerminationReason.POOR_PERFORMANCE]: {
    defaultMessage: 'Poor performance',
    description: 'Employment termination reason',
  },
  [TerminationReason.PROBATION_FAILED]: {
    defaultMessage: 'Probation failed',
    description: 'Employment termination reason',
  },
  [TerminationReason.OTHER]: {
    defaultMessage: 'Other',
    description: 'Employment termination reason',
  },
  [TerminationReason.RELOCATION]: {
    defaultMessage: 'Relocation',
    description: 'Employment termination reason',
  },
  [TerminationReason.TEAM_CONFLICT]: {
    defaultMessage: 'Didn’t work well with the team',
    description: 'Employment termination reason',
  },
  [TerminationReason.TIRED_OR_DOWNSHIFT]: {
    defaultMessage: 'Tired or downshifting',
    description: 'Employment termination reason',
  },
  [TerminationReason.LAYOFF]: {
    defaultMessage: 'Layoff',
    description: 'Employment termination reason',
  },
  [TerminationReason.DEAL]: {
    defaultMessage: 'Deal',
    description: 'Employment termination reason',
  },
});
