import {useRef, useEffect} from 'react';

export function usePrevious<T>(value: T): T | undefined {
  const prev = useRef<T>();

  useEffect(() => {
    prev.current = value;
  }, [value]);

  return prev.current;
}
