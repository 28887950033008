import {array, ExtractSchemaType, object, string, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {
  SecurePermissionId,
  serverSecurePermissionSchema,
  securePermissionIdSchema,
} from 'domain/permission/model/secure';
import {userSchema} from 'domain/user/model';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const secureRoleIdSchema = idSchema;

export const secureRoleSchema = object({
  holders: optionalWithDefault([], array(userSchema)),
  id: secureRoleIdSchema,
  title: string(),
  permissions: optionalWithDefault([], array(serverSecurePermissionSchema)),
  allowedPermissionIds: optional(array(securePermissionIdSchema)),
});

export type SecureRoleId = ExtractSchemaType<typeof secureRoleIdSchema>;
export type SecureRole = ExtractSchemaType<typeof secureRoleSchema>;

export type SecureRoleDiff = {
  title: string;
  permissionIds: SecurePermissionId[];
};
