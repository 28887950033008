import {array, dict, number, object, string} from '@joomcode/deprecated-utils/jsonValidation';
import {SingleStorageManager, LocalStorage, JsonSerializer} from '@joomcode/deprecated-utils/Storage';
import {ColumnsConfig} from './types';

const columnsConfigSchema = object({
  columnsHash: string(),
  columnsWidth: dict(number()),
  visibleColumns: array(string()),
});

export function createColumnsConfigStorage(storageName: string) {
  return new SingleStorageManager<ColumnsConfig>(
    storageName,
    new LocalStorage(),
    new JsonSerializer(columnsConfigSchema),
  );
}
