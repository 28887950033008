import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  noItems: {
    defaultMessage: 'No items',
    description: 'User multiselect no items text',
  },
  userSuggestItem: {
    defaultMessage: '{fullName} ({login})',
    description: 'User multiselect suggest item',
  },
});
