export enum ImageValidatorErrorCode {
  IMAGE_INVALID = 'image_invalid',
  IMAGE_IS_TOO_BIG = 'image_is_too_big',
  IMAGE_IS_TOO_SMALL = 'image_is_too_small',
  IMAGE_ASPECT_RATIO_TOO_BIG = 'image_aspect_ratio_too_big',
  IMAGE_ASPECT_RATIO_TOO_SMALL = 'image_aspect_ratio_too_small',
}

type AllOrNone<Options> = Options | {[K in keyof Options]?: undefined};

type ImageValidatorOptionsBase = {
  maxAspectRatio?: number;
  minAspectRatio?: number;
};

type ImageValidatorOptionsMinSize = {
  minWidth: number;
  minHeight: number;
};

type ImageValidatorOptionsMaxSize = {
  maxWidth: number;
  maxHeight: number;
};

export type ImageValidatorOptions = ImageValidatorOptionsBase &
  AllOrNone<ImageValidatorOptionsMinSize> &
  AllOrNone<ImageValidatorOptionsMaxSize>;
