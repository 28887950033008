import React, {useCallback} from 'react';

export type Option<Item> = {
  label: React.ReactNode;
  value: Item;
  disabled?: boolean;
};

export type Props<Item> = {
  ariaLabel: string;
  disabled?: boolean;
  invalid?: boolean;
  name?: string;
  onChange: (values: Item[]) => void;
  options: Option<Item>[];
  values: Item[];
};

type ReturnShape<Item> = {
  checkAll(): void;
  getCheckboxProps(options: {value: Item; disabled?: boolean}): Omit<JSX.IntrinsicElements['input'], 'ref'>;
  getRootProps(): {
    'aria-label': string;
    role: string;
  };
  uncheckAll(): void;
};

export function useCheckboxGroupUi<Item>({
  ariaLabel,
  disabled,
  invalid,
  name,
  onChange,
  options,
  values,
}: Props<Item>): ReturnShape<Item> {
  const checkAll = useCallback(() => onChange(options.map((option) => option.value)), [onChange, options]);
  const uncheckAll = useCallback(() => onChange([]), [onChange]);

  const getRootProps: ReturnShape<Item>['getRootProps'] = () => {
    return {
      'aria-label': ariaLabel,
      role: 'group',
    };
  };

  const getCheckboxProps: ReturnShape<Item>['getCheckboxProps'] = ({value, disabled: disabledProp = false}) => {
    return {
      checked: values.includes(value),
      disabled: disabledProp || disabled,
      invalid,
      name,
      onChange: (event) => {
        const {checked} = event.currentTarget;
        let result = values;

        if (checked && !values.includes(value)) {
          result = [...values, value];
        }

        if (!checked) {
          result = values.filter((item) => item !== value);
        }

        onChange(result);
      },
      value: String(value),
    };
  };

  return {
    checkAll,
    getCheckboxProps,
    getRootProps,
    uncheckAll,
  };
}
