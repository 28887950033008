/* eslint-disable @typescript-eslint/naming-convention */
export enum Token {
  Delimiter = 'Delimiter',
  EndOfFile = 'EndOfFile',
  NewLine = 'NewLine', // \r, \n, \r\n
  Symbol = 'Symbol', // any symbol except \r, \n, ", [delimiter]
  Quote = 'Quote', // double quotes
}

export enum State {
  Init = 'Init',
  ReadSymbol = 'ReadSymbol',
  ReadQuotedSymbol = 'ReadQuotedSymbol',
  ReadQuoteInQuotedString = 'ReadQuoteInQuotedString',
  Error = 'Error',
}

export type TokenGenerator = Generator<{token: Token; index: number}>;

export type StateMachine = {
  [stateKey in State]: {
    [tokenKey in Token]: {
      action?: (index: number) => void;
      next?: State;
    };
  };
};

export enum CsvErrorCode {
  UnexpectedCharacterAfterQuote = 'UnexpectedCharacterAfterQuote',
  UnexpectedQuote = 'UnexpectedQuote',
  UncoveredQuotationMark = 'UncoveredQuotationMark',
  UndetectableDelimiter = 'UndetectableDelimiter',
  TooManyFieldsInLine = 'TooManyFieldsInLine',
  TooFewFieldsInLine = 'TooFewFieldsInLine',
}

export type CsvError = {
  code: CsvErrorCode;
  row: number;
  line: string;
};
