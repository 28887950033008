import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  githubHint: {
    defaultMessage: 'Link different github account. Your old account will lose access to Joom organization.',
    description: 'User Github account: hint',
  },
  linkAccount: {
    defaultMessage: 'Link account',
    description: 'User Github account: link to github authentication flow',
  },
  editButtonAriaLabel: {
    defaultMessage: 'Edit',
    description: 'User Github account: edit button alt',
  },
});
