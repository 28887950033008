import {useMemo, useCallback} from 'react';
import {useForceUpdate} from './useForceUpdate';

export class SetApi<T> {
  private readonly set: Set<T>;

  private readonly onChange: (updated: SetApi<T>) => void;

  public constructor(onChange: (updated: SetApi<T>) => void, initial?: Iterable<T>) {
    this.set = new Set(initial);
    this.onChange = onChange;
  }

  public add = (value: T) => {
    if (!this.set.has(value)) {
      this.set.add(value);
      this.onChange(this);
    }
    return this;
  };

  public batchAdd = (values: T[]) => {
    values.forEach((value) => {
      if (!this.set.has(value)) {
        this.set.add(value);
      }
    });
    this.onChange(this);
    return this;
  };

  public delete = (value: T): boolean => {
    if (this.set.has(value)) {
      this.set.delete(value);
      this.onChange(this);
      return true;
    }
    return false;
  };

  public clear = () => {
    if (this.set.size > 0) {
      this.set.clear();
      this.onChange(this);
    }
  };

  public has = (item: T): boolean => {
    return this.set.has(item);
  };

  public get size() {
    return this.set.size;
  }

  public [Symbol.iterator]() {
    return this.set[Symbol.iterator]();
  }
}

// eslint-disable-next-line default-param-last
export function useSet<T>(initial: Iterable<T> = [], onChange: (api: SetApi<T>) => void): SetApi<T> {
  const forceUpdate = useForceUpdate();
  const onSetUpdate = useCallback(
    (api: SetApi<T>) => {
      forceUpdate();
      onChange(api);
    },
    [forceUpdate, onChange],
  );
  return useMemo<SetApi<T>>(() => new SetApi<T>(onSetUpdate, initial), [initial, onSetUpdate]);
}
