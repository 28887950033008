import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {FieldFloat} from 'components/widgets/fields/FieldFloat';
import {StockOptionBuyoutCreateConfig} from 'domain/stockOption/buyout/api/create';
import {createStockOptionBuyoutFx} from 'domain/stockOption/buyout/stores/main';
import {StockOptionIssuerFormControl} from 'domain/stockOption/issuer/widgets/FormControl';
import {UserId} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import React, {useCallback, useMemo} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {labels, messages} from './messages';

type Props = {
  userId: UserId;
  onClose(): void;
};

type FormState = Partial<StockOptionBuyoutCreateConfig>;

export function StockOptionBuyoutCreationDialog({userId, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  const onSubmit = useCallback(
    ({issuer, date, amount}: FormState) => {
      if (!issuer || !date || !amount) {
        return Promise.reject().catch(() => toaster.error(intl.formatMessage(generalMessages.partiallyFilledError)));
      }

      return createStockOptionBuyoutFx({
        userId,
        issuer,
        date,
        amount,
      })
        .then(() => {
          onClose();
          toaster.success(intl.formatMessage(messages.success));
        })
        .catch(toaster.interceptThenThrowError);
    },
    [userId, onClose],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 24rem)'>
      <Form<FormState> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <form onSubmit={handleSubmit} id={formId}>
                <StockOptionIssuerFormControl name='issuer' label={intl.formatMessage(labels.issuer)} required />
                <FieldDate name='date' label={intl.formatMessage(labels.date)} required />
                <Field<number> name='amount'>
                  {({meta}) => (
                    <FormControl
                      disabled={submitting}
                      label={intl.formatMessage(labels.amount)}
                      error={getFieldErrorText(meta, {intl})}
                      required
                    >
                      {(formControlProps) => (
                        <FieldFloat
                          disabled={submitting}
                          name='amount'
                          validate={validateFilled}
                          {...formControlProps}
                        />
                      )}
                    </FormControl>
                  )}
                </Field>
              </form>
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
