import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Service summary',
    description: '[title] Service info panel: title',
  },
  serviceDescription: {
    defaultMessage: 'Description',
    description: 'Service info panel: info section title',
  },
  owners: {
    defaultMessage: 'Owners',
    description: 'Service info panel: info section title',
  },
});
