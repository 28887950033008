import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {Option, OptionGroup, OptionOrGroup, ItemType} from './types';

export function createOption<Item>(item: Item): Option<Item> {
  return {
    type: ItemType.OPTION as const,
    data: item,
  };
}

export function createGroup<Group, Item>(data: Group, items: Option<Item>[]): OptionGroup<Item, Group> {
  return {
    type: ItemType.GROUP as const,
    data,
    items,
  };
}

export function filterCategorizedItemsAndGroups<Item, Group>(
  categorizedItemsOrGroups: OptionOrGroup<Item, Group>[],
  filterFunction: (item: Option<Item>) => boolean,
): OptionOrGroup<Item, Group>[] {
  return categorizedItemsOrGroups
    .map((itemOrGroup) => {
      if (itemOrGroup.type === ItemType.GROUP) {
        const filteredItems = itemOrGroup.items.filter(filterFunction);
        return filteredItems.length > 0
          ? {
              ...itemOrGroup,
              items: filteredItems,
            }
          : undefined;
      }
      return filterFunction(itemOrGroup) ? itemOrGroup : undefined;
    })
    .filter(isNotNullish);
}
