import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Panel} from '@joomcode/joom-ui/Panel';
import {EmptyMessage} from 'components/ui/EmptyMessage';
import {PanelInstantSearch} from 'components/widgets/PanelInstantSearch';
import {TreeViewHeader} from 'components/widgets/TreeViewHeader';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import {TeamsTree} from 'domain/team/widgets/Tree';
import {useTreeView} from 'hooks/useTreeView';
import {ExpandedChecker} from 'hooks/useTreeView/types';
import React, {useMemo, useRef} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import {searchTeams} from './searchTeams';
import styles from './styles.css';
import {TeamsToolbar} from './Toolbar';

export type TeamsTreePanelProps = {
  controls?: React.ReactNode;
  emptyMessage?: React.ReactNode;
  isExpandedByDefault: ExpandedChecker<Team, TeamId> | boolean;
  onSearch?: (searchQuery?: string) => void;
  searchQuery?: string;
  stretchHeight?: boolean;
  subtreeParentId?: TeamId;
  teamById: Record<TeamId, Team | undefined>;
  title: React.ReactNode;
  withMarginBottom?: boolean;
};

export function TeamsTreePanel({
  controls,
  emptyMessage,
  isExpandedByDefault,
  onSearch,
  searchQuery,
  stretchHeight,
  subtreeParentId,
  teamById,
  title,
  withMarginBottom = true,
}: TeamsTreePanelProps) {
  const intl = useIntl();
  const showUsers = useBooleanState(true);
  const normailzedSearchQuery = searchQuery?.trim().toLowerCase();
  const teams: Team[] = useMemo(() => {
    const allTeams = Object.values(teamById).filter(isNotNullish);
    return normailzedSearchQuery ? Array.from(searchTeams(allTeams, normailzedSearchQuery)) : allTeams;
  }, [teamById, normailzedSearchQuery]);
  const {viewState, events} = useTreeView<Team, TeamId>(
    {
      items: teams,
      getItemId: ({id}) => id,
      getParentId: ({parentTeamId}) => parentTeamId,
      isExpanded: normailzedSearchQuery ? true : isExpandedByDefault,
      isExpandedByDefault,
      subtreeParentId,
    },
    [isExpandedByDefault, teams, normailzedSearchQuery, subtreeParentId],
  );

  const panelRef = useRef<HTMLDivElement>(null);
  const emptyMessageText = onSearch ? intl.formatMessage(messages.nothingFound) : emptyMessage;

  useUpdateEffect(() => {
    panelRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [searchQuery]);

  return (
    <Panel stretchHeight={stretchHeight} withMarginBottom={withMarginBottom} ref={panelRef}>
      <div className={styles.header}>
        <TreeViewHeader
          title={title}
          viewState={viewState}
          events={events}
          toolbar={
            <ButtonGroup spaced size='m'>
              {viewState.itemsCount > 0 && (
                <TeamsToolbar onToggleUsers={showUsers.toggle} showUsers={showUsers.value} />
              )}
              {controls}
            </ButtonGroup>
          }
        />
        {onSearch && (
          <PanelInstantSearch
            onSearch={onSearch}
            placeholder={intl.formatMessage(messages.searchPlaceholder)}
            searchQuery={searchQuery ?? ''}
          />
        )}
      </div>
      {viewState.itemsCount > 0 ? (
        <TeamsTree
          normailzedSearchQuery={normailzedSearchQuery}
          onItemToggle={events.toggleItem}
          showUsers={showUsers.value}
          teamById={teamById}
          viewState={viewState}
        />
      ) : (
        emptyMessageText && (
          <Panel.Content withPadding>
            <EmptyMessage>{emptyMessageText}</EmptyMessage>
          </Panel.Content>
        )
      )}
    </Panel>
  );
}
