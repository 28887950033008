import {array, ExtractSchemaType, number, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {crResultSchema} from 'domain/compaRatio/model';
import {contractRecordSchema} from 'domain/contract/model';
import {jobInfoRecordSchema} from 'domain/jobInfoRecord/model';
import {marketDataRecordSchema} from 'domain/marketDataRecord/model';
import {regularBonusRecordSchema} from 'domain/regularBonusRecord/model';
import {salaryRangeSchema} from 'domain/salaryRange/model';
import {userStockOptionDataSchema} from 'domain/stockOption/holder/model/user';
import {userSchema} from 'domain/user/model';
import orderBy from 'lodash/orderBy';
import {userFullInfoAvailabilitySchema} from './availability';

export const userFullInfoRecordSchema = object({
  activeJobInfoRecord: optional(jobInfoRecordSchema),
  activeMarketDataRecord: optional(marketDataRecordSchema),
  activeRegularBonusRecords: optional(array(regularBonusRecordSchema)),
  availability: userFullInfoAvailabilitySchema,
  contracts: optional(array(contractRecordSchema)),
  cr: optional(crResultSchema),
  remoteDaysBalance: optional(number()),
  salaryRange: optional(salaryRangeSchema),
  stockOptions: optional(userStockOptionDataSchema),
  user: userSchema,
  vacationBalance: optional(number()),
}).map((record) => ({
  ...record,
  contracts: record.contracts
    ? orderBy(record.contracts, ({contract}) => Boolean(contract?.isPrimary), 'desc')
    : undefined,
}));

export type UserFullInfoRecord = ExtractSchemaType<typeof userFullInfoRecordSchema>;
