import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {User, UserFull, UserId} from 'domain/user/model';
import {loadActiveUsersFx, loadAllUsersFx} from 'domain/user/stores/main';
import {$users} from 'domain/user/stores/main/state';
import {useStore} from 'effector-react';
import {useEffect, useMemo} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  userById: Record<UserId, User | UserFull>;
  userByLogin: Record<string, User | UserFull>;
  loginById: Record<UserId, string>;
  dataState: DataState;
};

type Options = {
  onError?: (error: Error) => void;
  withTerminated?: boolean;
};

export function useAllUsers({withTerminated = false, onError}: Options = {}): ReturnShape {
  const {allUsersDataState, activeUsersDataState, byLogin, terminatedUserByLogin, loginById} = useStore($users);
  const dataState = withTerminated ? allUsersDataState : activeUsersDataState;
  const userByLogin = withTerminated ? {...byLogin, ...terminatedUserByLogin} : byLogin;
  const userById = useMemo(() => arrayToObject(Object.values(userByLogin), ({id}) => id), [userByLogin]);

  useEffect(() => {
    const loadUsersFx = withTerminated ? loadAllUsersFx : loadActiveUsersFx;

    if (!isLoadingOrLoaded(dataState)) {
      loadUsersFx().catch((error) => {
        onError?.(error);
        toaster.error(error);
      });
    }
  }, [withTerminated]);

  return {userById, userByLogin, loginById, dataState};
}
