import {useFieldArrayWithInitialValue} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {UserInfo} from 'domain/compensationReviewRequest/model/userInfo';
import {CompensationReviewRequestFormNewRegularBonus} from 'domain/compensationReviewRequest/widgets/FormNewRegularBonus';
import {RegularBonus} from 'domain/compensationReviewRequest/widgets/RegularBonus';
import {RegularBonusHeader} from 'domain/compensationReviewRequest/widgets/RegularBonusHeader';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import React, {useCallback} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  regularBonuses: UserInfo['regularBonuses'];
};

export function CompensationReviewRequestFormSectionRegularBonus({regularBonuses}: Props) {
  const intl = useIntl();
  const {change, getState} = useForm();
  const {values, submitting} = getState();
  const fields = useFieldArrayWithInitialValue<Partial<RegularBonusRecord>>('userInfo.regularBonuses');
  const addRegularBonus = useCallback(() => fields.push({}), [fields]);

  const hasChanges = fields.length > 0;
  const reset = useCallback(() => {
    change(`userInfo.regularBonuses`, undefined);
  }, [change]);

  return (
    <>
      <RegularBonusHeader onReset={hasChanges ? reset : undefined} />
      {regularBonuses && regularBonuses.length > 0 && (
        <Panel.Content withPadding>
          <div className={styles.content}>
            <Grid>
              {regularBonuses.map((regularBonus) => (
                <RegularBonus key={regularBonus.id} regularBonus={regularBonus} isBold />
              ))}
            </Grid>
          </div>
        </Panel.Content>
      )}
      {fields.map((fieldName, index) => (
        <CompensationReviewRequestFormNewRegularBonus
          key={fieldName}
          fieldName={fieldName}
          onRemove={() => fields.remove(index)}
          values={values?.userInfo?.regularBonuses?.[index]}
        />
      ))}
      <Panel.Content withPadding>
        <Button
          size='m'
          kind='secondary'
          intent='neutral'
          type='button'
          onClick={addRegularBonus}
          disabled={submitting}
        >
          {intl.formatMessage(messages.buttonAdd)}
        </Button>
      </Panel.Content>
    </>
  );
}
