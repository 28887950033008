import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {SecureRole, SecureRoleId} from 'domain/secureRole/model';
import {createStore} from 'effector';
import {addSecureRoleToUserFx, loadAllSecureRolesFx, removeSecureRoleFromUserFx, updateSecureRoleFx} from '.';

type State = {
  byId: Record<SecureRoleId, SecureRole>;
  dataState: DataState;
};

export const $secureRoles = createStore<State>({
  byId: {},
  dataState: DataState.IDLE,
})
  .on(loadAllSecureRolesFx, (state) => {
    return {
      ...state,
      dataState: DataState.LOADING,
    };
  })
  .on(loadAllSecureRolesFx.fail, (state) => {
    return {
      ...state,
      dataState: DataState.FAILED,
    };
  })
  .on(loadAllSecureRolesFx.done, (state, {result}) => {
    return {
      byId: arrayToObject(result, (option) => option.id),
      dataState: DataState.LOADED,
    };
  })
  .on(updateSecureRoleFx.done, (state, {result}) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [result.id]: result,
      },
    };
  })
  .on(addSecureRoleToUserFx.done, (state, {params, result}) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [params.secureRoleId]: {
          ...state.byId[params.secureRoleId],
          holders: state.byId[params.secureRoleId].holders.concat([result]),
        },
      },
    };
  })
  .on(removeSecureRoleFromUserFx.done, (state, {params}) => {
    return {
      ...state,
      byId: {
        ...state.byId,
        [params.secureRoleId]: {
          ...state.byId[params.secureRoleId],
          holders: state.byId[params.secureRoleId].holders.filter((holder) => holder.id !== params.userId),
        },
      },
    };
  });
