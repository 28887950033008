/* eslint-disable react/no-unused-prop-types,react/no-unstable-nested-components,react/jsx-no-useless-fragment,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import cn from 'classnames';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FileDragger} from '../FileDragger';
import {FormattedPercent} from '../FormattedPercent';
import {Progress} from '../Progress';
import {Spinner} from '../Spinner';
import {ReactComponent as CloudUploadIcon} from './icons/cloud-upload.svg';
import styles from './index.css';
import {messages} from './messages';

export type FileDropAreaProps = {
  accept?: string[];
  disabled?: boolean;
  hasError?: boolean;
  id?: string;
  invalid?: boolean;
  multiple?: boolean;
  name?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (files: File[]) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  processing?: boolean;
  progress?: number;
  showSelectedFiles?: boolean;
  value?: File[];
};

export function FileDropArea({
  accept,
  disabled = false,
  hasError = false,
  id,
  multiple,
  name,
  onBlur,
  onChange,
  onFocus,
  processing = false,
  progress,
  showSelectedFiles = false,
  value,
}: FileDropAreaProps) {
  const intl = useIntl();

  return (
    <FileDragger
      accept={accept}
      disabled={disabled}
      multiple={multiple}
      name={name}
      onChange={onChange}
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {({isDragging, isFocused, open, rootRef}) => (
        <div
          id={id}
          ref={rootRef}
          onClick={open}
          className={cn(styles.dropArea, {
            [styles.dragover]: isDragging,
            [styles.focused]: isFocused,
            [styles.disabled]: disabled,
            [styles.invalid]: hasError,
          })}
        >
          {processing ? (
            <>
              {isNotNullish(progress) ? (
                <>
                  <div className={styles.progress}>
                    <FormattedPercent value={progress} maximumFractionDigits={0} />
                  </div>
                  <div className={styles.progressBar}>
                    <Progress value={progress} />
                  </div>
                </>
              ) : (
                <div className={styles.spinner}>
                  <Spinner />
                </div>
              )}
            </>
          ) : (
            <>
              {showSelectedFiles && value && !!value.length && (
                <div className={styles.file}>
                  {value.length === 1
                    ? value[0].name
                    : intl.formatMessage(messages.selectedFiles, {count: value.length})}
                </div>
              )}
              <div className={styles.hint}>
                <span className={styles.hintIcon}>
                  <CloudUploadIcon />
                </span>
                <span className={styles.hintText}>
                  <span>
                    <FormattedMessage
                      id='file-drop-area-content'
                      description='Message on drop area'
                      defaultMessage='Drag and drop or <link>click to upload</link>'
                      values={{
                        link: (text: React.ReactNode): React.ReactNode => <span className={styles.link}>{text}</span>,
                      }}
                    />
                  </span>
                </span>
              </div>
            </>
          )}
        </div>
      )}
    </FileDragger>
  );
}
