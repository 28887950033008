import {JobContractReadAccess} from 'domain/jobContract/model/access';
import {Permission} from 'domain/permission/model';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFull} from 'domain/user/model';
import {useAcl} from 'services/acl';

export function useJobContractReadAccess(user: UserFull | undefined): JobContractReadAccess | null {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const canReadAny = acl.hasPermission(Permission.JOB_CONTRACT_READ);
  const canReadSubordinate = acl.hasPermission(Permission.JOB_CONTRACT_SUBORDINATE_READ);

  if (!user) {
    return null;
  }

  if (canReadAny) {
    return JobContractReadAccess.ANY;
  }
  if (canReadSubordinate && selfUserId && user.ancestorIds.includes(selfUserId)) {
    return JobContractReadAccess.SUBORDINATE;
  }
  if (user.id === selfUserId) {
    return JobContractReadAccess.MY;
  }

  return null;
}
