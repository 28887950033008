import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {getOutstaffUserByEmailFx} from 'domain/outstaffUser/stores/main';
import {$outstaffUsers} from 'domain/outstaffUser/stores/main/state';
import {Permission} from 'domain/permission/model';
import {UserFull} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  outstaffUser: UserFull;
  dataState: DataState;
};

type Options = {
  email: string;
};

export function useOutstaffUser({email}: Options): ReturnShape {
  const acl = useAcl();
  const [outstaffUser, dataState] = useStoreMap({
    store: $outstaffUsers,
    keys: [email],
    fn: (state, [outstaffUserEmail]) => [
      state.fullOutstaffUsersByEmail[outstaffUserEmail],
      state.fullOutstaffUsersDataState[outstaffUserEmail] ?? DataState.IDLE,
    ],
  });

  useEffect(() => {
    if (acl.hasPermission(Permission.OUTSTAFF_USER_READ) && !isLoadingOrLoaded(dataState)) {
      getOutstaffUserByEmailFx(email).catch((error) => {
        toaster.interceptThenThrowError(error);
      });
    }
  }, [acl]);

  return {outstaffUser, dataState};
}
