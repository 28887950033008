import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  statuses: {
    defaultMessage: 'Statuses',
    description: 'Resource filter label',
  },
  services: {
    defaultMessage: 'Services',
    description: 'Resource filter label',
  },
});
