import {LocalesConfig} from '..';
import {getNavigatorLocales} from './getNavigatorLocales';
import {getAcceptableLocale} from './getAcceptableLocalesByList';

export function detectUserLocale<AppLocale extends string>(
  localesConfig: LocalesConfig<AppLocale>,
  defaultLocale: AppLocale,
): AppLocale {
  return getAcceptableLocale(getNavigatorLocales(window.navigator), localesConfig, defaultLocale);
}
