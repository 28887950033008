import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {identity} from '@joomcode/deprecated-utils/function';
import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {mockEmploymentType} from 'domain/employmentType/model/mock';
import {mockSeat} from 'domain/officeMap/seat/model/mock';
import {OfficePolicy} from 'domain/officePolicyRecord/model/policy';
import {ShirtSize} from 'domain/shirtSize/model';
import {SocialMedia} from 'domain/socialMedia/model';
import {TimeOffPolicy} from 'domain/timeOff/policy/model';
import {TimeOffType} from 'domain/timeOff/type/model';
import {mockTimeOff} from 'domain/timeOff/userTimeOff/model/mock';
import {mockUserMemberships} from 'domain/user/model/memberships/mock';
import faker from 'faker';
import {mockImageBundle} from 'models/system/imageBundle/mock';
import {mockPhone} from 'models/system/phone/mock';
import {User, UserFull} from '.';

const shirtSizes = getEnumValues(ShirtSize);

export function mockUser(data: Partial<User> = {}): User {
  const dateOfBirth = faker.date.past().toISOString().slice(0, 10);
  const timeOffsCount = faker.random.number({min: 1, max: 3});

  return {
    avatarBundle: mockImageBundle(),
    countryOfTimeOffPolicy: faker.random.arrayElement(getEnumValues(TimeOffPolicy)),
    department: faker.name.jobArea(),
    division: faker.name.jobArea(),
    email: faker.internet.email(),
    firstName: faker.name.firstName(),
    githubLogin: faker.internet.userName(),
    hidden: false,
    hireDate: faker.date.past().toISOString().slice(0, 10),
    id: createMockId(),
    isTerminated: faker.random.boolean(),
    isOutstaff: faker.random.boolean(),
    jobTitle: faker.name.jobTitle(),
    lastName: faker.name.lastName(),
    location: faker.address.city(),
    login: faker.internet.userName(),
    managerId: createMockId(),
    monthAndDayOfBirth: dateOfBirth.slice(5),
    officePolicy: faker.random.arrayElement(getEnumValues(OfficePolicy)),
    offTime: Array.from({length: timeOffsCount}, () => mockTimeOff({type: TimeOffType.VACATION})),
    phone: mockPhone(),
    roleIds: [],
    seat: mockSeat(),
    slackDisplayName: faker.name.findName(),
    slackLink: faker.internet.url(),
    verifiedGithubLogin: faker.internet.userName(),
    workingRemotely: faker.random.boolean(),
    ...data,
  };
}

export function mockUserFull(data: Partial<UserFull> = {}): UserFull {
  const id = data.id ?? createMockId();
  const managerId = data.managerId ?? createMockId();
  const subordinatesCount = faker.random.number({min: 1, max: 20});
  return {
    ...mockUser({id, managerId}),
    about: `${faker.lorem.sentence()}\n${faker.lorem.sentence()}`,
    ancestorIds: Array.from({length: faker.random.number({min: 0, max: 5})}).map(createMockId),
    createdTime: faker.date.past(),
    employmentType: mockEmploymentType(),
    extraPhone: mockPhone(),
    isFull: true,
    manager: mockUser({id: managerId}),
    memberships: mockUserMemberships(),
    middleName: faker.name.firstName(),
    otherLanguages: [],
    personalEmail: faker.internet.email(),
    primaryLanguage: undefined,
    shirtSize: faker.random.arrayElement(shirtSizes),
    slackDisplayName: faker.name.findName(),
    slackLink: faker.internet.url(),
    pretendingToBeNormal: false,
    socialMediaLinks: arrayToObject(getEnumValues(SocialMedia), identity, () => faker.internet.url()),
    subordinates: Array.from({length: subordinatesCount}, () => mockUser({managerId: id})),
    updatedTime: faker.date.past(),
    ...data,
  };
}
