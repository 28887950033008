import {ServerSecurePermission} from 'domain/permission/model/secure';
import {getSecurePermissionDescription} from 'domain/permission/utils/getSecurePermissionDescription';
import {IntlShape} from 'react-intl';

export const searchPermissions = (
  permissions: ServerSecurePermission[],
  searchQuery: string,
  intl: IntlShape,
): ServerSecurePermission[] => {
  return permissions.filter(
    (permission) =>
      permission.name.toLowerCase().includes(searchQuery) ||
      getSecurePermissionDescription(permission, intl).toLowerCase().includes(searchQuery),
  );
};
