import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Newcomer',
    description: '[title] Offer newcomer application: profile panel title',
  },
  editDisabledHint: {
    defaultMessage: 'The application is being updated and is currently read-only - try again in a few minutes',
    description: 'Newcomer application info: field edit disabled hint',
  },
  confirmApproveTitle: {
    defaultMessage: 'A new Joomer is about to appear',
    description: '[title] Confirmation dialog title',
  },
  confirmApproveText: {
    defaultMessage: 'Approving this application creates new user accounts in all internal systems. Continue?',
    description: 'Confirmation dialog text',
  },
  approveButton: {
    defaultMessage: 'Approve',
    description: '[button] Approve newcomer application',
  },
  disabledHintChanging: {
    defaultMessage: 'The application is being updated and is currently read-only - try again in a few minutes.',
    description: 'Newcomer application actions: button disabled hint',
  },
  disabledHintNotDigitized: {
    defaultMessage: 'Approval will be available as soon as the newcomer‘s documents are digitized.',
    description: 'Newcomer application actions: button disabled hint',
  },
});

export const labels = defineMessages({
  computerInfo: {
    defaultMessage: 'Computer info',
    description: 'Newcomer application info label',
  },
  computerType: {
    defaultMessage: 'Computer',
    description: 'Newcomer application info label',
  },
  extraHardware: {
    defaultMessage: 'Equipment',
    description: 'Newcomer application info label',
  },
  firstName: {
    defaultMessage: 'First name',
    description: 'Newcomer application info label',
  },
  hardwareNotes: {
    defaultMessage: 'Equipment notes',
    description: 'Newcomer application info label',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: 'Newcomer application info label',
  },
  login: {
    defaultMessage: 'Login',
    description: 'Newcomer application info label',
  },
  operatingSystem: {
    defaultMessage: 'OS',
    description: 'Newcomer application info label',
  },
  placeOfFirstWorkingDay: {
    defaultMessage: 'City of first day',
    description: 'Newcomer application info label',
  },
  shirtSize: {
    defaultMessage: 'Shirt size',
    description: 'Newcomer application info label',
  },
  startWorkingDate: {
    defaultMessage: 'Start date',
    description: 'Newcomer application info label',
  },
});
