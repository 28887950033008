import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Permission} from 'domain/permission/model';
import {TeamMembers, teamMembersSchema} from 'domain/team/model/members';
import {MembershipRequestConfig} from 'domain/team/model/members/membershipConfig';

const requestMembershipGeneric =
  (endpoint: string) =>
  async (membershipConfig: MembershipRequestConfig): Promise<TeamMembers> => {
    const {data} = await intranetApi.post(endpoint, membershipConfig);

    return teamMembersSchema.runWithException(data);
  };

const requestMembershipDefault = requestMembershipGeneric('/v1/teams/memberships/request');
const createMembershipAsAdmin = requestMembershipGeneric('/v1/teams/memberships/createAsAdmin');

export const requestMembership = createPermissionDependentHandler(
  [{permission: Permission.MEMBERSHIP_ADMIN_WRITE, handler: createMembershipAsAdmin}],
  requestMembershipDefault,
);
