import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  searchPlaceholder: {
    defaultMessage: 'Search teams',
    description: 'Teams search input placeholder',
  },
  nothingFound: {
    defaultMessage: 'Nothing found',
    description: 'Teams search empty message',
  },
});
