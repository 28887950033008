import {assertNever} from '@joomcode/deprecated-utils/types';
import {ReactComponent as CheckmarkIcon} from '@joomcode/joom-ui/icons/circle/checkmark.svg';
import {ReactComponent as CrossIcon} from '@joomcode/joom-ui/icons/circle/cross.svg';
import {ReactComponent as WarningIcon} from '@joomcode/joom-ui/icons/circle/warning.svg';
import {IntentionedText, TextIntent} from '@joomcode/joom-ui/IntentionedText';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {BulkUploadMeta, BulkUploadRowStatus} from 'models/system/bulkUpload';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  meta: BulkUploadMeta;
};

const iconByStatus: Record<BulkUploadRowStatus, React.ComponentType> = {
  [BulkUploadRowStatus.SUCCESS]: CheckmarkIcon,
  [BulkUploadRowStatus.NOT_CHANGED]: CheckmarkIcon,
  [BulkUploadRowStatus.ERROR]: CrossIcon,
  [BulkUploadRowStatus.REPLACE]: WarningIcon,
};

const intentByStatus: Record<BulkUploadRowStatus, TextIntent> = {
  [BulkUploadRowStatus.SUCCESS]: 'positive',
  [BulkUploadRowStatus.NOT_CHANGED]: 'neutral',
  [BulkUploadRowStatus.ERROR]: 'negative',
  [BulkUploadRowStatus.REPLACE]: 'primary',
};

export function BulkUploadRowStatusIcon({meta}: Props) {
  const intl = useIntl();
  const {status} = meta;
  const Icon = iconByStatus[status];

  const renderContent = () => {
    if (status === BulkUploadRowStatus.SUCCESS) {
      return intl.formatMessage(messages.success);
    }
    if (status === BulkUploadRowStatus.NOT_CHANGED) {
      return intl.formatMessage(messages.notChanged);
    }
    if (status === BulkUploadRowStatus.REPLACE) {
      return intl.formatMessage(messages.replace);
    }
    if (status === BulkUploadRowStatus.ERROR) {
      return (
        <IntentionedText intent='negative'>
          {meta.errors.map((error) => (
            <TooltipFaq.Paragraph key={error}>{error}</TooltipFaq.Paragraph>
          ))}
        </IntentionedText>
      );
    }
    assertNever(status);
    return null;
  };

  return (
    <TooltipFaq content={renderContent()}>
      <div className={styles.iconWrap}>
        <IntentionedText intent={intentByStatus[status]}>
          <Icon />
        </IntentionedText>
      </div>
    </TooltipFaq>
  );
}
