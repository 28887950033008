import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {PageTabs} from 'components/ui/PageTabs';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {officeAliasMessages} from 'domain/officeMap/office/model/alias/messages';
import React from 'react';
import {useIntl} from 'react-intl';
import {officeUrls} from 'routes/officeMap/urls';

type Props = {
  activeTab: OfficeAlias;
};

export function OfficePageTabs({activeTab}: Props) {
  const intl = useIntl();
  const aliases = getEnumValues(OfficeAlias);
  return (
    <PageTabs>
      {aliases.map((alias) => (
        <PageTabs.Tab
          key={alias}
          title={intl.formatMessage(officeAliasMessages[alias])}
          path={officeUrls.office({alias})}
          active={activeTab === alias}
        />
      ))}
    </PageTabs>
  );
}
