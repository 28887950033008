import {DataState} from '@joomcode/deprecated-utils/dataState';
import {ServerSecurePermission} from 'domain/permission/model/secure';
import {createStore} from 'effector';
import {loadAllSecurePermissionsFx} from '.';

type State = {
  permissions: ServerSecurePermission[];
  dataState: DataState;
};

export const $securePermissions = createStore<State>({
  permissions: [],
  dataState: DataState.IDLE,
})
  .on(loadAllSecurePermissionsFx, (state) => {
    return {
      ...state,
      dataState: DataState.LOADING,
    };
  })
  .on(loadAllSecurePermissionsFx.fail, (state) => {
    return {
      ...state,
      dataState: DataState.FAILED,
    };
  })
  .on(loadAllSecurePermissionsFx.done, (state, {result}) => {
    return {
      permissions: result,
      dataState: DataState.LOADED,
    };
  });
