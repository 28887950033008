import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  days: {
    defaultMessage: 'Days',
    description: 'Duration part field caption',
  },
  hours: {
    defaultMessage: 'Hours',
    description: 'Duration part field caption',
  },
  minutes: {
    defaultMessage: 'Minutes',
    description: 'Duration part field caption',
  },
  seconds: {
    defaultMessage: 'Seconds',
    description: 'Duration part field caption',
  },
});
