import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {JobContract, jobContractSchema} from 'domain/jobContract/model';
import {JobContractRecordDiff} from 'domain/jobContract/model/record/diff';
import {LegalEntityId} from 'domain/legalEntity/model';
import {UserId} from 'domain/user/model';

export type CreateJobContractRecordConfig = JobContractRecordDiff & {
  userId: UserId;
  legalEntityId: LegalEntityId;
};

export const createRecord = async ({userId, ...body}: CreateJobContractRecordConfig): Promise<JobContract[]> => {
  const {data} = await intranetApi.post('/v1/users/jobContractRecords/create', body, {params: {userId}});

  return array(jobContractSchema).runWithException(data);
};
