import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import {mockMarketDataRecord} from 'domain/marketDataRecord/model/mock';
import {mockRegularBonusRecord} from 'domain/regularBonusRecord/model/mock';
import {mockSalaryRecord} from 'domain/salaryRecord/model/mock';
import {mockUser} from 'domain/user/model/mock';
import faker from 'faker';
import {BulkUploadRowStatus, BulkUploadStatus} from 'models/system/bulkUpload';
import {mockBulkUploadMeta} from 'models/system/bulkUpload/mock';
import {FinancialBulkUploadResult, FinancialBulkUploadRow} from '.';

function mockBulkRow<RecordType>(mocker: () => RecordType): FinancialBulkUploadRow<RecordType> {
  const replacing = Math.random() > 0.5;

  return {
    record: mocker(),
    oldRecord: replacing ? mocker() : undefined,
    meta: mockBulkUploadMeta(
      replacing
        ? {status: BulkUploadRowStatus.REPLACE}
        : {
            status: faker.random.arrayElement([
              BulkUploadRowStatus.ERROR,
              BulkUploadRowStatus.NOT_CHANGED,
              BulkUploadRowStatus.SUCCESS,
            ]),
          },
    ),
    user: mockUser(),
  };
}

export function mockFinancialBulkUploadResult(
  data: Partial<FinancialBulkUploadResult> = {},
): FinancialBulkUploadResult {
  const salaryRows = Array.from({length: faker.random.number({min: 1, max: 13})}).map(() =>
    mockBulkRow(mockSalaryRecord),
  );
  const marketDataRows = Array.from({length: faker.random.number({min: 1, max: 3})}).map(() =>
    mockBulkRow(mockMarketDataRecord),
  );
  const regularBonusRows = Array.from({length: faker.random.number({min: 1, max: 3})}).map(() =>
    mockBulkRow(mockRegularBonusRecord),
  );
  const hasErrors =
    salaryRows.some(({meta}) => meta.status === BulkUploadRowStatus.ERROR) ||
    marketDataRows.some(({meta}) => meta.status === BulkUploadRowStatus.ERROR) ||
    regularBonusRows.some(({meta}) => meta.status === BulkUploadRowStatus.ERROR);

  return {
    id: createMockId(),
    salaryBulkUploadRecords: salaryRows,
    marketDataBulkUploadRecords: marketDataRows,
    regularBonusBulkUploadRecords: regularBonusRows,
    status: hasErrors ? BulkUploadStatus.CREATED_WITH_ERRORS : BulkUploadStatus.CREATED,
    error: hasErrors ? faker.lorem.sentence() : undefined,
    ...data,
  };
}
