import {FilterItem, FilterItemOptions} from '@joomcode/joom-ui/DataFilter/types';
import React from 'react';
import {SimpleBooleanControl} from './SimpleBooleanControl';
import {SimpleBooleanLabel} from './SimpleBooleanLabel';

export const createSimpleBooleanFilter = (
  filterName: string,
  options: FilterItemOptions = {},
): FilterItem<boolean> => ({
  name: filterName,
  options,
  isEmpty: (value) => !value,
  renderControl: (props) => <SimpleBooleanControl {...props} />,
  renderLabel: (props) => <SimpleBooleanLabel {...props} />,
});
