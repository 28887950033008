import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  annotation: {
    defaultMessage:
      'This tool allows you to add some options data. After the xlsx file upload you will see the preview of the changes you are going to make.',
    description: 'Options data upload annotation',
  },
  wrongFileType: {
    defaultMessage: 'This file type is not supported',
    description: 'Options data upload error',
  },
  success: {
    defaultMessage:
      'Your file has been successfully parsed. Check the result below and apply changes if everything is OK.',
    description: 'Options data upload success message',
  },
});
