import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {useCompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestReadAccess';
import {ManagerConsoleTab} from 'domain/managerConsole/model/tabs';
import {SecurePermission} from 'domain/permission/model/secure';
import {useMemo} from 'react';
import {useAcl} from 'services/acl';
import {useSubordinateTableAvailabitilty} from './useSubordinateTableAvailability';

type ReturnShape = {
  availableTabs: ManagerConsoleTab[];
  showTabs: boolean;
};

export function useManagerConsolePageTabs(): ReturnShape {
  const acl = useAcl();
  const canReadSubordinateTable = useSubordinateTableAvailabitilty();
  const canReadSalaryRanges = acl.hasSecurePermission(SecurePermission.SALARY_RANGE_READ_AS_MANAGER);
  const compensationReviewRequestReadAccess = useCompensationReviewRequestReadAccess();
  const canReadCompensationReviewRequests = Boolean(compensationReviewRequestReadAccess);

  const tabAvailability: Record<ManagerConsoleTab, boolean> = useMemo(
    () => ({
      [ManagerConsoleTab.SALARY_RANGES]: canReadSalaryRanges,
      [ManagerConsoleTab.SUBORDINATES]: canReadSubordinateTable,
      [ManagerConsoleTab.COMPENSATION_REVIEW_REQUESTS]: canReadCompensationReviewRequests,
    }),
    [canReadSalaryRanges, canReadSubordinateTable, canReadCompensationReviewRequests],
  );

  const availableTabs = useMemo(
    () => getEnumValues(ManagerConsoleTab).filter((tab) => tabAvailability[tab]),
    [tabAvailability],
  );

  return {
    availableTabs,
    showTabs: availableTabs.length > 1,
  };
}
