export enum ColumnId {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  AMOUNT = 'amount',
  FREQUENCY = 'frequency',
  EMPLOYMENT_RATE = 'employmentRate',
  REGION = 'region',
  STATUS = 'status',
  REASON = 'reason',
  COMMENT = 'comment',
  SPECIAL_ARRANGEMENTS = 'specialArrangements',
}
