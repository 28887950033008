import {StatusIconStyle} from './types';

type Props = {
  loading?: boolean;
  hasError?: boolean;
};

export const getIconServiceStyle = ({loading, hasError}: Props): StatusIconStyle | undefined => {
  if (loading) {
    return StatusIconStyle.SPINNER;
  }
  if (hasError) {
    return StatusIconStyle.ERROR;
  }
  return undefined;
};
