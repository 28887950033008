import {Grid} from '@joomcode/joom-ui/Grid';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ClampedText} from 'components/ui/ClampedText';
import {FormattedPrice} from 'components/ui/FormattedPrice';
import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import {formatOneTimeBonusType} from 'domain/oneTimeBonusRecord/model/type/formatter';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  oneTimeBonus: OneTimeBonusRecord;
};

export function OneTimeBonus({oneTimeBonus}: Props) {
  const intl = useIntl();

  return (
    <>
      <Grid.Item xl={6} xs={12}>
        <div className={styles.title}>{formatOneTimeBonusType(oneTimeBonus.type, intl)}</div>
        <div className={styles.value}>
          <FormattedPrice value={oneTimeBonus} />
          <div className={styles.dates}>
            {intl.formatMessage(messages.dates, {
              from: <HumanDate value={oneTimeBonus.date} format='short' utc />,
              penalDeadline: oneTimeBonus.penalDeadline ? (
                <HumanDate value={oneTimeBonus.penalDeadline} format='short' utc />
              ) : undefined,
            })}
          </div>
        </div>
      </Grid.Item>
      <Grid.Item xl={6} xs={12}>
        <ClampedText text={oneTimeBonus.comment || ''} />
      </Grid.Item>
    </>
  );
}
