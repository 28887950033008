import {FifoCache} from '@joomcode/deprecated-utils/FifoCache';
import {identity} from '@joomcode/deprecated-utils/function/identity';
import {Field} from '@joomcode/joom-form';
import {Input} from '@joomcode/joom-ui/Input';
import {EditableValue} from 'components/ui/EditableValue';
import {offersApi} from 'domain/offer/api';
import {OfferId} from 'domain/offer/model';
import {LoginValidationResult} from 'domain/offer/model/loginValidationResult';
import {loginAsyncValidationMessages} from 'domain/offer/model/loginValidationResult/messages';
import {updateNewcomerLoginFx} from 'domain/offer/stores/main';
import pDebounce from 'p-debounce';
import React, {useCallback, useRef} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {loginFormatErrorMessages} from './messages';
import {validateLoginFormat} from './validate';

type Props = {
  disabled?: boolean;
  disabledHint?: string;
  initialValue: string;
  offerId: OfferId;
};

type Diff = {
  login: string;
};

const validationCache = new FifoCache<string, LoginValidationResult>([], {
  maxSize: 50,
});

export function LoginForm({disabled = false, disabledHint, initialValue, offerId}: Props) {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmit = useCallback(
    ({login}: Diff) =>
      updateNewcomerLoginFx({offerId, login})
        .catch(toaster.interceptThenThrowError)
        .then(() => undefined),
    [offerId],
  );

  const getValidationError = (result: LoginValidationResult): string | undefined =>
    result === LoginValidationResult.OK ? undefined : intl.formatMessage(loginAsyncValidationMessages[result]);

  const validateLoginAsync = pDebounce((login: string) => {
    return offersApi
      .validateLogin({offerId, login})
      .then((result: LoginValidationResult) => {
        validationCache.set(login, result);
        return getValidationError(result);
      })
      .catch(toaster.interceptThenThrowError);
  }, 300);

  const validate = useCallback(
    (login: string) => {
      const formattingError = validateLoginFormat(login);
      if (formattingError) {
        return intl.formatMessage(loginFormatErrorMessages[formattingError]);
      }
      const cachedCode = validationCache.get(login);
      if (cachedCode !== null) {
        return getValidationError(cachedCode);
      }
      return validateLoginAsync(login);
    },
    [offerId, intl],
  );

  return (
    <Form<Diff> onSubmit={onSubmit}>
      {({handleSubmit, submitting, valid, validating}) => (
        <Field name='login' initialValue={initialValue} parse={identity} validate={validate}>
          {({input, meta}) => (
            <EditableValue
              buttonHint={disabled ? disabledHint : undefined}
              disableEdit={disabled}
              disableSubmit={disabled || validating || !valid || submitting}
              error={meta.modified && meta.error}
              inputRef={inputRef}
              onSubmit={handleSubmit}
              processing={validating || submitting}
              value={initialValue}
            >
              <Input autoComplete='off' disabled={submitting} ref={inputRef} {...input} />
            </EditableValue>
          )}
        </Field>
      )}
    </Form>
  );
}
