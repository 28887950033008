import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonCancel: {
    defaultMessage: 'Cancel',
    description: '[button] Compensation review request approval: cancel',
  },
  buttonSubmit: {
    defaultMessage: 'Approve',
    description: '[button] Compensation review request approval: submit',
  },
  dialogAriaLabel: {
    defaultMessage: 'Approve compensation review request',
    description: 'Compensation review request approval: aria label',
  },
  dialogAriaLabelOnBehalf: {
    defaultMessage: 'Approve on behalf of {onBehalfOf}',
    description: 'Compensation review request approval on behalf of other user: aria label',
  },
  title: {
    defaultMessage: 'Approve compensation review request',
    description: '[title] Compensation review request approval: title',
  },
  titleOnBehalf: {
    defaultMessage: 'Approve on behalf of {onBehalfOf}',
    description: '[title] Compensation review request approval on behalf of other user: title',
  },
  label: {
    defaultMessage: 'Comment',
    description: 'Compensation review request approval: comment field label',
  },
});
