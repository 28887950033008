import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import React from 'react';
import {RouteConfig} from 'react-router-config';
import {Redirect} from 'react-router-dom';
import {meUrls} from 'routes/me/urls';
import {officeUrls} from 'routes/officeMap/urls';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {mainUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: mainUrlPatterns.root,
  component: withAuth(() => {
    const acl = useAcl();
    if (!acl.hasPermission(Permission.USERS_PAGE_VIEW)) {
      return <Redirect to={officeUrls.root()} />;
    }
    const defaultUrl = acl.hasPermission(Permission.USER_READ_ACTIVE) ? usersUrls.users() : meUrls.root();
    return <Redirect to={defaultUrl} />;
  }),
  exact: true,
};
