import {Checkbox, CheckboxLocator} from '@joomcode/joom-ui/Checkbox';
import {FormControl, FormControlLocator} from '@joomcode/joom-ui/FormControl';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';
import {ConvertLocatorToTestId, Locator, Mark, createLocator} from '@joomcode/joom-ui/create-locator';
import {useFieldWithInitialValue} from '../../components';
import {getFieldErrorText} from '../../utils';
import {CommonFieldProps} from '../types';

export type FieldCheckboxLocator = Locator<{
  formControl: FormControlLocator;
  checkbox: CheckboxLocator;
}>;
export type FieldCheckboxTestId = ConvertLocatorToTestId<FieldCheckboxLocator>;

type Props = CommonFieldProps<boolean | string> & {value?: string} & Partial<Mark<FieldCheckboxLocator>>;

export const FieldCheckbox = memo(function FieldCheckbox({
  disabled,
  error,
  hint,
  initialValue,
  label,
  name,
  required,
  reserveSpaceForError,
  validate,
  validateFields,
  format,
  extraErrorMessages,
  value,
  ...rest
}: Props) {
  const locator = createLocator(rest);
  const intl = useIntl();
  const {input, meta} = useFieldWithInitialValue<boolean | string>(name, {
    initialValue,
    value,
    type: 'checkbox',
    validateFields,
    format,
  });

  return (
    <FormControl
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      hint={hint}
      reserveSpaceForError={reserveSpaceForError}
      {...locator.formControl()}
    >
      {(formControlProps) => {
        return (
          <Checkbox
            disabled={meta.submitting || disabled}
            label={label}
            {...formControlProps}
            {...(input as Omit<typeof input, 'value'>)}
            {...locator.checkbox()}
          />
        );
      }}
    </FormControl>
  );
});
