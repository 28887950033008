import {array, ExtractSchemaType, number, object} from '@joomcode/deprecated-utils/jsonValidation';
import {QueryRequestPayload} from 'api/types';
import {intranetApi} from 'apiClient';
import {resourceSchema, ResourcesSearchFilters} from 'domain/resource/model';

const countableResourcesSchema = object({
  items: array(resourceSchema),
  count: number(),
});

export type CountableResources = ExtractSchemaType<typeof countableResourcesSchema>;
export const getAll = async ({
  filters,
  offset,
  limit,
}: QueryRequestPayload<ResourcesSearchFilters>): Promise<CountableResources> => {
  const {data} = await intranetApi.post('/v1/jdm/resources/getAll', filters, {params: {offset, limit}});

  return countableResourcesSchema.runWithException(data);
};
