import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {ValidationFunction} from '@joomcode/joom-form';
import {ResourceDiff} from 'domain/resource/model/diff';
import {RoleInTeamDiff} from 'domain/role/model/roleInTeam';
import {FormApi} from 'final-form';

export enum OwnersFilledValidatorError {
  EMPTY_OWNERS = 'emptyOwners',
}
export type OwnersFilledValidatorOptions = {
  getFormState: FormApi<ResourceDiff>['getState'];
};

export const validateOwnersFilled: ValidationFunction<
  unknown,
  OwnersFilledValidatorOptions,
  OwnersFilledValidatorError
> = (value, {getFormState}: OwnersFilledValidatorOptions) => {
  const allValues = getFormState().values;
  const isHumanOwnersFilled = isNotNullish(allValues.ownerIds) && allValues.ownerIds.length > 0;
  const isRoleOwnersFilled =
    isNotNullish(allValues.owningMemberships) &&
    allValues.owningMemberships.some(({roleId, teamId}: RoleInTeamDiff) => roleId && teamId);

  if (!isHumanOwnersFilled && !isRoleOwnersFilled) {
    return {code: OwnersFilledValidatorError.EMPTY_OWNERS};
  }
  return undefined;
};
