import {getSalaryRangeByParamsFx} from 'domain/salaryRange/stores/main';
import React, {useCallback, useEffect} from 'react';
import {useFormState} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';

const fieldsToListen = ['employmentInfo.employmentCountry', 'compensation.userFunction', 'compensation.levelCode'];

type Props = {
  onSuccessfulLoad?: () => void;
};

export function StockOptionsProposalListener({onSuccessfulLoad}: Props) {
  const {values} = useFormState();
  const getSalaryRange = useCallback(() => {
    const {levelCode, userFunction} = values.compensation;
    const {employmentCountry: region} = values.employmentInfo;

    if (!levelCode || !region || !userFunction) {
      return;
    }

    getSalaryRangeByParamsFx({levelCode, region, userFunction}).then((result) => {
      if (result?.stockOptionsProposal) {
        onSuccessfulLoad?.();
      }
    });
  }, [
    values.compensation.levelCode,
    values.employmentInfo.employmentCountry,
    values.compensation.userFunction,
    onSuccessfulLoad,
  ]);

  // In case of prefilled form
  useEffect(getSalaryRange, []);

  return (
    <>
      {fieldsToListen.map((name) => (
        <OnChange key={name} name={name}>
          {getSalaryRange}
        </OnChange>
      ))}
    </>
  );
}
