import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const stockOptionsUrlPatterns = {
  root: '/options',
  user: '/options/:login',
  bulkUpload: '/options/admin/upload',
};

export const stockOptionsUrls = {
  root: (searchParams?: {mode?: string}) => addSearchParams(stockOptionsUrlPatterns.root, searchParams),
  user: (pathParams: {login: string}) => generatePath(stockOptionsUrlPatterns.user, pathParams),
  bulkUpload: () => stockOptionsUrlPatterns.bulkUpload,
};
