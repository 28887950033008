const EXTENSION_SEPARATOR = '.';

type FileName = {
  name: string;
  extension?: string;
};

export const parseFilename = (basename: string): FileName => {
  if (basename.indexOf(EXTENSION_SEPARATOR) === -1) {
    return {name: basename};
  }
  const parts = basename.split(EXTENSION_SEPARATOR);
  const extension = parts.pop();
  const name = parts.join(EXTENSION_SEPARATOR);
  return {extension, name};
};
