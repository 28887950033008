import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {useFieldWithInitialValue} from '@joomcode/joom-form';
import {Checkbox} from '@joomcode/joom-ui/Checkbox';
import {CheckboxGroup, Option} from '@joomcode/joom-ui/CheckboxGroup';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {ServerPermission} from 'domain/permission/model';
import {loadAllPermissionsFx} from 'domain/userRole/stores/main';
import {$userRoles} from 'domain/userRole/stores/main/state';
import {useStore} from 'effector-react';
import {FieldValidator} from 'final-form';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  name: string;
  initialValue: ServerPermission[];
  disabled: boolean;
};

const validatePermissions = (state: DataState, error: string): FieldValidator<ServerPermission[] | undefined> => {
  return () => {
    if (state === DataState.LOADED) {
      return undefined;
    }

    return error;
  };
};

const getOptions = (permissions: string[]): Option<string>[] =>
  permissions
    .map((permission) => ({
      label: permission,
      value: permission,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export function UserRolePermissionsField({name, initialValue, disabled}: Props) {
  const intl = useIntl();
  const {permissions, permissionsDataState} = useStore($userRoles);

  useEffect(() => {
    if (!isLoadingOrLoaded(permissionsDataState)) {
      loadAllPermissionsFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  const permissionsValidator = validatePermissions(
    permissionsDataState,
    intl.formatMessage(messages.permissionsLoadError),
  );

  const {
    input: {value, ...restInput},
    meta,
  } = useFieldWithInitialValue<ServerPermission[]>(name, {
    initialValue,
    parse: identity,
    validate: permissionsValidator,
  });

  useEffect(() => {
    if (permissionsDataState === DataState.LOADED || permissionsDataState === DataState.FAILED) {
      // this needed to force re-validation of field
      restInput.onChange([]);
      restInput.onChange(value);
    }
  }, [permissionsDataState]);

  return (
    <FormControl
      error={permissionsDataState === DataState.FAILED && meta.error}
      disabled={disabled}
      label={intl.formatMessage(messages.permissionsLabel)}
    >
      {(formControlProps) => (
        <>
          {permissionsDataState === DataState.LOADING && <Spinner />}
          {permissionsDataState === DataState.LOADED && (
            <CheckboxGroup
              ariaLabel={intl.formatMessage(messages.permissionsLabel)}
              direction='columns'
              disabled={disabled}
              options={getOptions(permissions)}
              selectAllAllowed
              renderItem={(item, props) => (
                <div className={styles.row}>
                  <Checkbox {...props} label={item.label} />
                </div>
              )}
              values={value}
              {...formControlProps}
              {...restInput}
            />
          )}
        </>
      )}
    </FormControl>
  );
}
