import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {HeadlessSearchableSelect} from '@joomcode/joom-ui/HeadlessSearchableSelect';
import {Listbox} from '@joomcode/joom-ui/Listbox';
import {Dropdown} from '@joomcode/joom-ui/Select/Dropdown';
import {Office} from 'domain/officeMap/office/model';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {OfficeRoom} from 'domain/officeMap/room/model';
import {roomMessages} from 'domain/officeMap/room/model/messages';
import {RoomSuggestion} from 'domain/officeMap/room/widgets/Suggestion';
import {OfficeSeat} from 'domain/officeMap/seat/model';
import {SeatSuggestion} from 'domain/officeMap/seat/widgets/Suggestion';
import {User} from 'domain/user/model';
import {UserSuggestion} from 'domain/user/widgets/Suggestion';
import {generalMessages} from 'i18n/messages/general';
import sortBy from 'lodash/sortBy';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAnalytics} from 'services/analytics/useAnalytics';
import {UserSnapEvents, useUsersnapApi} from 'services/usersnap';
import {Input} from './Input';
import {messages} from './messages';
import styles from './OfficeSearch.css';
import {SeatLessUser} from './SeatlessUser';
import {sortItems} from './sortItems';
import {SearchItem, SearchItemRoom, SearchItemUser} from './types';

const MIN_QUERY_LENGTH_FOR_SUGGEST = 1;

const searchInArray = (src: string[], search: string): boolean =>
  src.some((singleSrc: string) => singleSrc.toLowerCase().includes(search.toLowerCase()));

const createSearchItems = (
  users: User[],
  officesByAlias: Partial<Record<OfficeAlias, Office>>,
  currentOfficeAlias: string,
): SearchItem[] => {
  const aliases = sortBy(getEnumValues(OfficeAlias), (alias) => alias !== currentOfficeAlias);
  const itemsRooms: SearchItem[] = aliases.flatMap(
    (alias) => officesByAlias[alias]?.rooms.map((room) => ({type: 'room', value: room, officeAlias: alias})) ?? [],
  );
  const itemsSeats: SearchItem[] = aliases.flatMap(
    (alias) => officesByAlias[alias]?.seats.map((seat) => ({type: 'seat', value: seat, officeAlias: alias})) ?? [],
  );
  const itemsUsers: SearchItem[] = users.map(
    (user: User): SearchItem => ({type: 'user', value: user, officeAlias: user.seat?.officeAlias}),
  );
  return itemsUsers.concat(itemsRooms).concat(itemsSeats);
};

type Props = {
  autoFocus: boolean;
  usersByLogin: Record<string, User>;
  officesByAlias: Partial<Record<OfficeAlias, Office>>;
  selectSeat: (seat: OfficeSeat | null, officeAlias?: string) => void;
  selectRoom: (room: OfficeRoom | null, officeAlias?: string) => void;
  currentOfficeAlias: string;
};

export function OfficeSearch({
  autoFocus,
  usersByLogin,
  selectSeat,
  selectRoom,
  officesByAlias,
  currentOfficeAlias,
}: Props) {
  const intl = useIntl();
  const {gtag} = useAnalytics();
  const [selectedItem, setSelectedItem] = useState<SearchItem | null>(null);
  const usersnapApi = useUsersnapApi();

  const items = useMemo<SearchItem[]>(
    () => createSearchItems(Object.values(usersByLogin), officesByAlias, currentOfficeAlias),
    [usersByLogin, officesByAlias, currentOfficeAlias],
  );

  const onItemSelect = useCallback(
    (item: SearchItem | null) => {
      // search was cleared
      if (item === null) {
        setSelectedItem(null);
        selectSeat(null);
        selectRoom(null);
        return;
      }

      setSelectedItem(item);
      if (item.type === 'user') {
        if (item.value.seat) {
          selectSeat(item.value.seat, item.officeAlias);
        }
      }

      if (item.type === 'seat') {
        selectSeat(item.value, item.officeAlias);
      }

      if (item.type === 'room') {
        selectRoom(item.value, item.officeAlias);
      }

      gtag.logEvent('map_search_suggest_item_click', {item_type: item.type, item_id: item.value.id});
      usersnapApi?.logEvent(UserSnapEvents.OFFICE_MAP_USAGE);
    },
    [selectSeat, selectRoom],
  );

  const userItemToString = useCallback(
    (userItem: SearchItemUser) =>
      intl.formatMessage(messages.userSuggestItem, {
        fullName: intl.formatMessage(generalMessages.fullName, {
          firstName: userItem.value.firstName,
          lastName: userItem.value.lastName,
        }),
        login: userItem.value.login,
      }),
    [intl],
  );

  const roomItemToString = useCallback(
    (roomItem: SearchItemRoom) => intl.formatMessage(roomMessages.name, {roomNumber: roomItem.value.text}),
    [intl],
  );

  const searchInItem = useCallback(
    (item: SearchItem, search: string) => {
      if (item.type === 'user') {
        return searchInArray(
          [userItemToString(item), item.value.firstName, item.value.lastName, item.value.login],
          search,
        );
      }

      if (item.type === 'room') {
        const searchableArray = [roomItemToString(item)];
        if (item.value.fullText) {
          searchableArray.push(item.value.fullText);
        }
        return searchInArray(searchableArray, search);
      }

      if (item.value.number) {
        return searchInArray([item.value.number], search);
      }

      return false;
    },
    [intl],
  );

  const itemToString = useCallback(
    (item: SearchItem): string => {
      if (item.type === 'user') {
        return userItemToString(item);
      }
      if (item.type === 'seat') {
        return item.value.number ?? item.value.id;
      }
      return roomItemToString(item);
    },
    [intl],
  );

  const renderItem = (item: SearchItem) => {
    if (item.type === 'user') {
      return <UserSuggestion user={item.value} />;
    }

    if (item.type === 'room') {
      return <RoomSuggestion room={item.value} />;
    }

    return <SeatSuggestion seat={item.value} />;
  };

  return (
    <div className={styles.wrap}>
      <HeadlessSearchableSelect<SearchItem>
        items={items}
        itemToString={itemToString}
        onChange={onItemSelect}
        suggestionFilter={searchInItem}
        value={selectedItem}
      >
        {({
          downshift: {clearSelection, getItemProps, highlightedIndex, inputValue},
          filteredItems,
          getInputProps,
          isOpen,
        }) => (
          <Dropdown
            isOpen={isOpen && !!inputValue && inputValue.length >= MIN_QUERY_LENGTH_FOR_SUGGEST}
            content={
              filteredItems.length === 0 ? (
                <Listbox.Option disabled isHighlighted={false}>
                  {intl.formatMessage(messages.nothingFound)}
                </Listbox.Option>
              ) : (
                <Listbox>
                  {filteredItems.sort(sortItems(inputValue)).map((item, index) => (
                    <Listbox.Option
                      key={item.value.id}
                      {...getItemProps({
                        item,
                        index,
                      })}
                      isHighlighted={index === highlightedIndex}
                    >
                      {renderItem(item)}
                    </Listbox.Option>
                  ))}
                </Listbox>
              )
            }
          >
            <Input
              autoFocus={autoFocus}
              inputProps={getInputProps()}
              onClear={() => {
                clearSelection();
                setSelectedItem(null);
              }}
            />
          </Dropdown>
        )}
      </HeadlessSearchableSelect>
      {selectedItem?.type === 'user' && selectedItem.value.seat === undefined && (
        <SeatLessUser user={selectedItem.value} />
      )}
    </div>
  );
}
