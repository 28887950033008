import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Permission} from 'domain/permission/model';
import {TeamMembers, teamMembersSchema} from 'domain/team/model/members';
import {OutstaffMembershipRevokeConfig} from 'domain/team/model/members/membershipConfig';

const revokeOutstaffMembershipGeneric =
  (endpoint: string) =>
  async ({id}: OutstaffMembershipRevokeConfig): Promise<TeamMembers> => {
    const {data} = await intranetApi.post(endpoint, undefined, {
      params: {id},
    });

    return teamMembersSchema.runWithException(data);
  };

const revokeOutstaffMembershipDefault = revokeOutstaffMembershipGeneric('/v1/teams/outstaff/memberships/revoke');
const revokeOutstaffMembershipAsAdmin = revokeOutstaffMembershipGeneric('/v1/teams/outstaff/memberships/revokeAsAdmin');

export const revokeOutstaffMembership = createPermissionDependentHandler(
  [{permission: Permission.OUTSTAFF_MEMBERSHIP_ADMIN_WRITE, handler: revokeOutstaffMembershipAsAdmin}],
  revokeOutstaffMembershipDefault,
);
