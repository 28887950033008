import cn from 'classnames';
import React from 'react';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../../create-locator';
import styles from './index.css';

export type PanelContentTestId = ConvertLocatorToTestId<PanelContentLocator>;
export type PanelContentLocator = Locator<void>;

type Props = {
  children: React.ReactNode;
  withPadding?: boolean;
} & Partial<Mark<PanelContentLocator>>;

export function PanelContent({children, withPadding = false, ...restProps}: Props) {
  const locator = createLocator(restProps);

  return (
    <div className={cn(styles.content, {[styles.contentPadding]: withPadding})} {...locator()}>
      {children}
    </div>
  );
}
