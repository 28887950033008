import findLastIndex from 'lodash/findLastIndex';
import last from 'lodash/last';
import {CONFIG_COLUMN_ID} from '../columns/configColumn';
import {StickyColumnOffset, Column, StickySide} from '../types';

type StickyBorderOffsets = {
  left: number;
  right: number;
};

type ColumnOffsetMap = Record<string, StickyColumnOffset>;

function getColumnOffsetsBySide(
  columns: Column<unknown>[],
  getColumnWidth: (column: Column<unknown>) => number,
  side: StickySide,
): {
  columnOffsetById: ColumnOffsetMap;
  stickyColumns: Column<unknown>[];
} {
  const lastStickyColumnIndex = findLastIndex(columns, (column) => column.sticky === side);
  const stickyColumns = columns.slice(0, lastStickyColumnIndex + 1);

  const columnOffsetById = stickyColumns.reduce<ColumnOffsetMap>((acc, column, index, cols) => {
    let offset = 0;
    if (index > 0) {
      const prevColumn = cols[index - 1];
      const prevColumnOffset = acc[prevColumn.id].value;
      offset = prevColumnOffset + getColumnWidth(prevColumn);
    }
    acc[column.id] = {side, value: offset};
    return acc;
  }, {});

  return {
    columnOffsetById,
    stickyColumns,
  };
}

type StickyOffsetState = {
  getColumnOffset: (column: Column<unknown>) => StickyColumnOffset | undefined;
  borderOffsets: StickyBorderOffsets;
};
export function getStickyOffsets({
  columns,
  getColumnWidth,
}: {
  columns: Column<unknown>[];
  getColumnWidth: (column: Column<unknown>) => number;
}): {
  header: StickyOffsetState;
  body: StickyOffsetState;
} {
  const reversedColumns = columns.concat().reverse();

  const left = getColumnOffsetsBySide(columns, getColumnWidth, 'left');
  const right = getColumnOffsetsBySide(reversedColumns, getColumnWidth, 'right');

  const lastLeftStickyColumn = last(left.stickyColumns);
  const firstRightStickyColumn = last(right.stickyColumns);

  const columnOffsetById: Record<string, StickyColumnOffset> = {
    ...left.columnOffsetById,
    ...right.columnOffsetById,
  };

  const shouldIgnoreColumn = (column: Column<unknown>) =>
    column.id === CONFIG_COLUMN_ID &&
    right.stickyColumns.length === 1 &&
    right.stickyColumns[0].id === CONFIG_COLUMN_ID;

  function getHeaderColumnOffset(column: Column<unknown>): StickyColumnOffset | undefined {
    return columnOffsetById[column.id];
  }

  function getBodyColumnOffset(column: Column<unknown>): StickyColumnOffset | undefined {
    return shouldIgnoreColumn(column) ? undefined : columnOffsetById[column.id];
  }

  function getHeaderColumnBorderOffset(column: Column<unknown> | undefined): number {
    if (!column) return 0;
    return (getHeaderColumnOffset(column)?.value || 0) + getColumnWidth(column);
  }

  function getBodyColumnBorderOffset(column: Column<unknown> | undefined): number {
    if (!column || shouldIgnoreColumn(column)) return 0;
    return (getBodyColumnOffset(column)?.value || 0) + getColumnWidth(column);
  }

  return {
    header: {
      getColumnOffset: getHeaderColumnOffset,
      borderOffsets: {
        left: getHeaderColumnBorderOffset(lastLeftStickyColumn),
        right: getHeaderColumnBorderOffset(firstRightStickyColumn),
      },
    },
    body: {
      getColumnOffset: getBodyColumnOffset,
      borderOffsets: {
        left: getBodyColumnBorderOffset(lastLeftStickyColumn),
        right: getBodyColumnBorderOffset(firstRightStickyColumn),
      },
    },
  };
}
