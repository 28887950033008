import cn from 'classnames';
import React, {ReactNode, useState, useMemo, useCallback} from 'react';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../create-locator';
import {CloseButton, type CloseButtonLocator} from './CloseButton';
import {useTabs} from './useTabs';
import {TabLabel, TabLabelLocator, TabSize, TabIntent} from './TabLabel';
import styles from './index.css';

export type TabsTestId = ConvertLocatorToTestId<TabsLocator>;
export type TabsLocator = Locator<{
  closeButton: CloseButtonLocator;
  tabLabel: TabLabelLocator;
  tabPanel: void;
}>;

export type Tab<TabId extends string = string> = {
  closable?: boolean;
  content?: ReactNode;
  extra?: ReactNode;
  id: TabId;
  label: ReactNode;
  maxWidth?: React.CSSProperties['maxWidth'];
  renderLabelWrapper?(children: React.ReactNode): React.ReactNode;
  intent?: TabIntent;
  tooltip?: ReactNode;
};

type CommonProps<TabId extends string> = Partial<Mark<TabsLocator>> & {
  /**
   * The element that is placed right after the tabs, in close proximity to them.
   */
  addonAfterTabs?: React.ReactNode;

  /**
   * The element that is positioned along the right edge of the tabs component.
   */
  addonRight?: React.ReactNode;

  /**
   * Whether the scroll bar of tabs should be hidden.
   */
  hiddenScrollBar?: boolean;

  /**
   * The flag that removes the outer margins in tabs, and also removes
   * the paddings inside the tabs themselves. As a result, the tabs are aligned
   * closely to the edges of the container, and the bottom bar of the active
   * tab obtain width equal to the content part of the tab.
   */
  noPaddings?: boolean;

  /**
   * @deprecated
   */
  panelOverflow?: React.CSSProperties['overflow'];

  /**
   * The flag determines whether the content should be rendered only for
   * the selected tab or for all tabs. Regardless of the value of this property,
   * all tabs are displayed on the screen.
   */
  renderActiveTabPanelOnly?: boolean;

  /**
   * Size of the tabs.
   */
  size?: TabSize;

  /**
   * An array of tabs, each tab can have various properties.
   */
  tabs: Tab<TabId>[];
};

type ControlledProps<TabId extends string> = {
  activeTabId: TabId;
  onChange(id: TabId): unknown;
  onClose?: (id: TabId) => unknown;
};

function ControlledTabs<T extends string>({
  activeTabId,
  addonAfterTabs,
  addonRight,
  onChange,
  onClose,
  renderActiveTabPanelOnly = false,
  tabs,
  size = 'm',
  hiddenScrollBar = false,
  noPaddings = false,
  panelOverflow,
  ...restProperties
}: ControlledProps<T> & CommonProps<T>) {
  const locator = createLocator(restProperties);
  const tabsOrder = React.useMemo(() => tabs.map(({id}) => id), [tabs]);
  const {getTabListProps, getTabPanelProps, getTabProps, getTabCloseButtonProps} = useTabs({
    activeTabId,
    onChange,
    onClose,
    tabsOrder,
  });

  return (
    <div className={styles.tabs} {...locator()}>
      <div className={cn(styles.header, {[styles.noPaddings]: noPaddings})}>
        <ul
          {...getTabListProps()}
          className={cn(styles.list, {
            [styles.hideScrollbar]: hiddenScrollBar,
          })}
        >
          {tabs.map((tab) => (
            <TabLabel
              {...getTabProps({id: tab.id, closable: tab.closable})}
              active={activeTabId === tab.id}
              closeButton={
                tab.closable && onClose ? (
                  <CloseButton {...getTabCloseButtonProps({id: tab.id})} {...locator.closeButton()} />
                ) : null
              }
              extra={tab.extra}
              size={size}
              key={tab.id}
              maxWidth={tab.maxWidth}
              renderLabelWrapper={tab.renderLabelWrapper}
              intent={tab.intent}
              tooltip={tab.tooltip}
              className={styles.tab}
              {...locator.tabLabel()}
            >
              {tab.label}
            </TabLabel>
          ))}
        </ul>
        {addonAfterTabs ? <div className={cn(styles.addon, styles.addonAfterTabs)}>{addonAfterTabs}</div> : null}
        {addonRight ? <div className={cn(styles.addon, styles.addonRight)}>{addonRight}</div> : null}
      </div>

      {tabs.map((tab) => {
        if ((renderActiveTabPanelOnly && tab.id !== activeTabId) || !tab.content) {
          return null;
        }

        const tabPanelProps = getTabPanelProps({id: tab.id});

        return (
          <div
            {...tabPanelProps}
            aria-hidden={tab.id !== activeTabId}
            className={styles.panel}
            {...locator.tabPanel()}
            data-test-item={tabPanelProps.id}
            key={tab.id}
            style={{overflow: panelOverflow}}
          >
            {tab.content}
          </div>
        );
      })}
    </div>
  );
}

type UncontrolledProps<TabId extends string> = {
  defaultId?: TabId;
};

function UncontrolledTabs<TabId extends string>({
  defaultId,
  tabs,
  ...restProps
}: UncontrolledProps<TabId> & CommonProps<TabId>) {
  const [activeTabId, setActiveTabId] = useState(defaultId || tabs[0].id);

  type ClosedTabsIds = Record<TabId, boolean | undefined>;
  const [closedTabsIds, setClosedTabsIds] = useState<ClosedTabsIds>({} as ClosedTabsIds);
  const handleClose = useCallback(
    (id: TabId) => {
      setClosedTabsIds({...closedTabsIds, [id]: true});
    },
    [closedTabsIds, setClosedTabsIds],
  );

  const openedTabs = useMemo(() => tabs.filter((tab) => !closedTabsIds[tab.id]), [tabs, closedTabsIds]);

  return (
    <ControlledTabs<TabId>
      {...restProps}
      activeTabId={activeTabId}
      onChange={setActiveTabId}
      onClose={handleClose}
      tabs={openedTabs}
    />
  );
}

export function Tabs<TabId extends string>({
  activeTabId,
  onChange,
  ...restProps
}: CommonProps<TabId> & Partial<UncontrolledProps<TabId>> & Partial<ControlledProps<TabId>>) {
  if (activeTabId && onChange) {
    return <ControlledTabs {...restProps} activeTabId={activeTabId} onChange={onChange} />;
  }

  return <UncontrolledTabs {...restProps} />;
}
