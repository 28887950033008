import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {identity} from '@joomcode/deprecated-utils/function';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {LegalEntity, LegalEntityId} from 'domain/legalEntity/model';
import {FieldValidator} from 'final-form';
import React, {useCallback, useMemo} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

export type Props = {
  clearable?: boolean;
  disabled?: boolean;
  initialValue?: LegalEntityId;
  label: React.ReactNode;
  legalEntities: LegalEntity[];
  name: string;
  required?: boolean;
  reserveSpaceForError?: boolean;
  validate?: FieldValidator<LegalEntityId | null>;
};

export function LegalEntityFormControl({
  clearable,
  disabled,
  initialValue,
  label,
  legalEntities,
  name,
  required,
  reserveSpaceForError,
  validate,
}: Props) {
  const intl = useIntl();

  const legalEntityTitleById: Record<LegalEntityId, string> = useMemo(
    () =>
      arrayToObject(
        legalEntities,
        ({id}) => id,
        ({title}) => title,
      ),
    [legalEntities],
  );
  const legalEntityIds = useMemo(() => Object.keys(legalEntityTitleById), [legalEntityTitleById]);
  const renderLegalEntityOption = useCallback(
    (legalEntityId: LegalEntityId): string => legalEntityTitleById[legalEntityId] || '',
    [legalEntityTitleById],
  );

  return (
    <Field<LegalEntityId | null> name={name} validate={validate} initialValue={initialValue ?? null}>
      {({input, meta}) => (
        <FormControl
          disabled={disabled}
          label={label}
          error={getFieldErrorText(meta, {intl})}
          required={required}
          reserveSpaceForError={reserveSpaceForError}
        >
          {(formControlProps) => (
            <SimpleAutocomplete<LegalEntityId>
              items={legalEntityIds}
              getItemKey={identity}
              itemToString={renderLegalEntityOption}
              renderItem={renderLegalEntityOption}
              clearable={clearable}
              disabled={disabled}
              {...formControlProps}
              {...input}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
}
