import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Unauthenticated',
    description: '[title] Page title',
  },
  description: {
    defaultMessage: 'Authentication failure',
    description: 'Unauthenticated description',
  },
  retry: {
    defaultMessage: 'Retry',
    description: '[button] Unauthenticated page: Retry button text',
  },
});
