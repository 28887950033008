import cn from 'classnames';
import {Avatar} from 'components/ui/Avatar';
import {StyledLink} from 'components/ui/StyledLink';
import {Permission} from 'domain/permission/model';
import {User} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import React, {HTMLAttributeAnchorTarget} from 'react';
import {useIntl} from 'react-intl';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import styles from './styles.css';

export type Props = {
  user: User;
  withAvatar?: boolean;
  colored?: boolean;
  target?: HTMLAttributeAnchorTarget;
};

export function UserLink({user, withAvatar = true, colored = false, target}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const fullName = intl.formatMessage(generalMessages.fullName, {
    firstName: user.firstName,
    lastName: user.lastName,
  });

  return (
    <StyledLink
      colored={colored}
      className={cn({[styles.inactive]: user.isTerminated})}
      to={usersUrls.user({login: user.login})}
      disabled={user.isTerminated && !acl.hasPermission(Permission.USER_READ_ANY)}
      target={target}
    >
      {withAvatar && (
        <span className={styles.avatar}>
          <Avatar name={fullName} imageBundle={user.avatarBundle} size='s' />
        </span>
      )}
      {fullName}
    </StyledLink>
  );
}
