import {defineMessages} from 'react-intl';
import {ExtraHardware} from './index';

export const extraHardwareLabels = defineMessages<ExtraHardware>({
  [ExtraHardware.HUMIDIFIER]: {
    defaultMessage: 'Humidifier',
    description: 'Extra hardware',
  },
  [ExtraHardware.KEYBOARD]: {
    defaultMessage: 'Keyboard',
    description: 'Extra hardware',
  },
  [ExtraHardware.MONITOR]: {
    defaultMessage: 'Monitor',
    description: 'Extra hardware',
  },
  [ExtraHardware.MOUSE]: {
    defaultMessage: 'Mouse',
    description: 'Extra hardware',
  },
  [ExtraHardware.TRACKPAD]: {
    defaultMessage: 'Trackpad',
    description: 'Extra hardware',
  },
});
