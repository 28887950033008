import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  terminationDate: {
    defaultMessage: 'Termination date',
    description: 'Employment form: field label',
  },
  legalTerminationDate: {
    defaultMessage: 'Legal termination date',
    description: 'Employment form: field label',
  },
  terminationReason: {
    defaultMessage: 'Termination reason',
    description: 'Employment form: field label',
  },
  terminationType: {
    defaultMessage: 'Termination type',
    description: 'Employment form: field label',
  },
  terminationComment: {
    defaultMessage: 'Comment',
    description: 'Employment form: field label',
  },
  terminationInitiatedBy: {
    defaultMessage: 'Termination initiated by',
    description: 'Employment form: field label',
  },
});
