import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {LegalEntityId} from 'domain/legalEntity/model';
import {OneTimeBonusRecord, oneTimeBonusRecordSchema} from 'domain/oneTimeBonusRecord/model';
import {OneTimeBonusRecordDiff} from 'domain/oneTimeBonusRecord/model/diff';
import {UserId} from 'domain/user/model';

export type CreateOneTimeBonusRecordConfig = {
  userId: UserId;
  legalEntityId: LegalEntityId;
  diff: OneTimeBonusRecordDiff;
};

export const create = async ({diff, ...params}: CreateOneTimeBonusRecordConfig): Promise<OneTimeBonusRecord[]> => {
  const {data} = await intranetApi.post('/v1/secure/users/oneTimeBonusRecords/create', diff, {params});

  return array(oneTimeBonusRecordSchema).runWithException(data);
};
