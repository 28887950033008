export enum ColumnId {
  FULL_NAME_OFFER = 'fullNameOffer',
  FULL_NAME_APPLICATION = 'fullNameApplication',
  DIVISION = 'division',
  JOB_TITLE = 'jobTitle',
  START_DATE = 'startDate',
  STATUS = 'status',
  LOGIN = 'login',
  LEGAL_ENTITY = 'legalEntity',
  REFERRERS = 'referrers',
  COUNTRY = 'country',
}
