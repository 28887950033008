import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {Autocomplete, AutocompleteProps} from '@joomcode/joom-ui/Select/Autocomplete';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export type SimpleAutocompleteProps<Item> = Omit<AutocompleteProps<Item>, 'filter' | 'noItemsText'>;

export function SimpleAutocomplete<Item extends string = string>(props: SimpleAutocompleteProps<Item>) {
  const intl = useIntl();
  const {itemToString} = props;
  const filter = useCallback(
    (item: Item | null, query: string) =>
      isNotNullish(item) && (itemToString ?? String)(item).toLowerCase().includes(query.toLowerCase()),
    [itemToString],
  );
  return <Autocomplete<Item> filter={filter} noItemsText={intl.formatMessage(messages.noItems)} {...props} />;
}
