import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CommonFieldProps, getFieldValidator} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {NewcomerFormTemplateCountry} from 'domain/offer/model/templateCountry';
import {formatTemplateCountry} from 'domain/offer/model/templateCountry/formatter';
import React, {useCallback} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

export type Props = CommonFieldProps<NewcomerFormTemplateCountry>;

export function FieldTemplateCountry({
  name,
  label,
  hint,
  required,
  disabled,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const countryToString = useCallback(
    (country: NewcomerFormTemplateCountry) => formatTemplateCountry(country, intl),
    [intl],
  );

  return (
    <Field<NewcomerFormTemplateCountry>
      name={name}
      validate={validate && getFieldValidator(validate)}
      initialValue={initialValue}
    >
      {({input, meta}) => (
        <FormControl
          hint={hint}
          label={label}
          disabled={meta.submitting || disabled}
          error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
          required={required}
          reserveSpaceForError={reserveSpaceForError}
        >
          {(formControlProps) => (
            <SimpleAutocomplete<NewcomerFormTemplateCountry>
              items={getEnumValues(NewcomerFormTemplateCountry)}
              getItemKey={identity}
              itemToString={countryToString}
              renderItem={countryToString}
              {...formControlProps}
              {...input}
              {...restProps}
              disabled={meta.submitting || disabled}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
}
