import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {OfferId} from 'domain/offer/model';
import {VersionedOfferStockOptionList, versionedOfferStockOptionListSchema} from 'domain/offer/model/stockOption';
import {OfferVersionId} from 'domain/offer/model/version';
import {SecurePermission} from 'domain/permission/model/secure';
import {stringify} from 'qs';

export type GetStockOptionsParams = {
  id: OfferId;
  versionIds: OfferVersionId[];
};

const getStockOptionsGeneric =
  (endpoint: string) =>
  async ({id, versionIds}: GetStockOptionsParams): Promise<VersionedOfferStockOptionList> => {
    const {data} = await intranetApi.get(endpoint, {
      params: {id, versionIds},
      paramsSerializer: (params) => stringify(params, {arrayFormat: 'repeat'}),
    });

    return versionedOfferStockOptionListSchema.runWithException(data);
  };

const getStockOptionsAny = getStockOptionsGeneric('/v1/secure/offers/getStockOptionsAny');
const getStockOptionsMy = getStockOptionsGeneric('/v1/secure/offers/getStockOptionsMy');

export const getStockOptions = createPermissionDependentHandler(
  [{securePermission: SecurePermission.OFFER_COMPENSATION_READ_ANY, handler: getStockOptionsAny}],
  getStockOptionsMy,
);
