import {Panel} from '@joomcode/joom-ui/Panel';
import {StyledExternalLink} from 'components/ui/StyledLink';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

const OPTIONS_DRI_LOGIN = 'avasiliev';
const OPTIONS_DRI_SLACK_URL = 'https://joom-team.slack.com/team/UFJMFPKB4';
const NOTION_URL = 'https://www.notion.so/joomteam/Options-c79d711f37e242a19d1012ef82fd3431';

export function StockOptionFaqPanel() {
  const intl = useIntl();
  const buildLink = useCallback(
    (url: string) => (text: React.ReactNode) => (
      <StyledExternalLink colored href={url}>
        {text}
      </StyledExternalLink>
    ),
    [],
  );

  return (
    <Panel withPadding withMarginBottom={false} stretchHeight title={intl.formatMessage(messages.title)}>
      <div className={styles.content}>
        <p>
          {intl.formatMessage(messages.basicInfo, {
            link: buildLink(NOTION_URL),
          })}
        </p>
        <p>
          {intl.formatMessage(messages.furtherQuestions, {
            link: buildLink(OPTIONS_DRI_SLACK_URL),
            login: OPTIONS_DRI_LOGIN,
            email: `${OPTIONS_DRI_LOGIN}@joom.com`,
          })}
        </p>
      </div>
    </Panel>
  );
}
