import {SecurePermission} from 'domain/permission/model/secure';
import {SalaryReadAccess} from 'domain/salaryRecord/model/access';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFull} from 'domain/user/model';
import {useAcl} from 'services/acl';

export function useSalaryReadAccess(user?: UserFull): SalaryReadAccess | null {
  const acl = useAcl();
  const selfUserId = useSelfUserId();

  const canReadSubordinate = acl.hasSecurePermission(SecurePermission.SALARY_RECORD_SUBORDINATE_READ);
  const canReadAny = acl.hasSecurePermission(SecurePermission.SALARY_RECORD_READ);
  const canReadAnyExtended = acl.hasSecurePermission(SecurePermission.SALARY_RECORD_READ_EXTENDED);

  if (!user || !acl.inVpn()) {
    return null;
  }

  if (canReadAnyExtended) {
    return SalaryReadAccess.ANY_EXTENDED;
  }
  if (canReadAny) {
    return SalaryReadAccess.ANY;
  }
  if (canReadSubordinate && selfUserId && user.ancestorIds.includes(selfUserId)) {
    return SalaryReadAccess.SUBORDINATE;
  }
  if (user.id === selfUserId) {
    return SalaryReadAccess.MY;
  }
  return null;
}
