import {ServerPermission} from 'domain/permission/model';
import {UpdateRoleParams} from 'domain/userRole/api/update';
import {UserRole, UserRoleId} from 'domain/userRole/model';
import {createEffect} from 'effector';

export const loadAllUserRolesFx = createEffect<void, UserRole[]>('load all user roles');
export const loadAllPermissionsFx = createEffect<void, ServerPermission[]>('load all permissions');
export const loadUserRoleByIdFx = createEffect<{id: UserRoleId}, UserRole>('load user role by id');
export const updateUserRoleFx = createEffect<UpdateRoleParams, UserRole>('update user role');
export const createUserRoleFx = createEffect<Partial<UserRole>, UserRole>('create user role');
