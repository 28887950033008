import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Salary',
    description: '[title] Offer form section title',
  },
});

export const labels = defineMessages({
  amount: {
    defaultMessage: 'Salary (gross)',
    description: 'Offer form field label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'Offer form field label',
  },
  frequency: {
    defaultMessage: 'Frequency',
    description: 'Offer form field label',
  },
});
