import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  roomTitle: {
    defaultMessage: 'Meeting room {name}',
    description: '[title] Meeting room',
  },
  copyIconLabel: {
    defaultMessage: 'Copy room link',
    description: 'Label for copy icon',
  },
});
