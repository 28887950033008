import {isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {getServicesFx} from 'domain/service/stores/main';
import {$serviceList, $services} from 'domain/service/stores/main/state';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

export function useServices() {
  const {byId} = useStore($services);
  const {data, dataState} = useStore($serviceList);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      getServicesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return {services: data, servicesById: byId, servicesState: dataState};
}
