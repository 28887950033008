import {ExternalLink} from '@joomcode/joom-ui/ExternalLink';
import {Navbar} from '@joomcode/joom-ui/Navbar';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {useAnalytics} from 'services/analytics/useAnalytics';
import {messages} from './messages';
import {ReactComponent as QuestionIcon} from './question.svg';
import styles from './styles.css';

type Props = {
  href: string;
};

export function NotionGuide({href}: Props) {
  const intl = useIntl();
  const {gtag} = useAnalytics();
  const {pathname} = useLocation();
  const handleClick = useCallback(() => {
    gtag.logEvent('notion_guide_click', {path: pathname});
    gtag.logEvent('element_click', {element: `notion_guide_${href.slice(-4)}`});
  }, [gtag, pathname]);

  return (
    <Navbar.Link>
      {({className}) => (
        <ExternalLink href={href} className={className} onClick={handleClick}>
          <div className={styles.root}>
            {intl.formatMessage(messages.text)}
            <div className={styles.icon}>
              <QuestionIcon />
            </div>
          </div>
        </ExternalLink>
      )}
    </Navbar.Link>
  );
}
