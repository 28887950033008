import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CommonFieldProps, getFieldValidator} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {Currency, getCurrencyName} from 'domain/currency/model';
import React, {useCallback} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

export type Props = CommonFieldProps<Currency> & {
  invalid?: boolean;
  clearable?: boolean;
};

export function FieldCurrency({
  name,
  label,
  hint,
  required,
  disabled,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const currencyToString = useCallback(
    (currency: Currency) => `${currency} — ${getCurrencyName(currency, intl)}`,
    [intl],
  );

  return (
    <Field<Currency> name={name} validate={validate && getFieldValidator(validate)} initialValue={initialValue}>
      {({input, meta}) => (
        <FormControl
          hint={hint}
          label={label}
          disabled={meta.submitting || disabled}
          error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
          required={required}
          reserveSpaceForError={reserveSpaceForError}
        >
          {(formControlProps) => (
            <SimpleAutocomplete<Currency>
              items={getEnumValues(Currency)}
              getItemKey={identity}
              itemToString={currencyToString}
              renderItem={currencyToString}
              {...formControlProps}
              {...input}
              {...restProps}
              value={input.value || null}
              disabled={meta.submitting || disabled}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
}
