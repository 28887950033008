import {intranetApi} from 'apiClient';
import {Team, teamSchema} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';

export const changeParent = async ({id, parentTeamId}: {id: TeamId; parentTeamId: TeamId}): Promise<Team> => {
  const {data} = await intranetApi.post(
    '/v1/teams/changeParent',
    {parentTeamId},
    {
      params: {id},
    },
  );

  return teamSchema.runWithException(data);
};
