import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {JobContract, jobContractSchema} from 'domain/jobContract/model';
import {UserId} from 'domain/user/model';

export type GetJobContractsParams = {
  userId: UserId;
};

export const getByUserId = async (params: GetJobContractsParams): Promise<JobContract[]> => {
  const {data} = await intranetApi.get('/v1/users/jobContractRecords/getAll', {params});

  return array(jobContractSchema).runWithException(data);
};
