import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  editTooltip: {
    defaultMessage: 'Edit',
    description: 'Editable value: tooltip',
  },
  save: {
    defaultMessage: 'Save',
    description: '[button] Editable value: submit',
  },
  cancel: {
    defaultMessage: 'Cancel',
    description: '[button] Editable value: cancel',
  },
});
