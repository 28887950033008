import React from 'react';
import {FieldProps, FieldRenderProps} from 'react-final-form';
import {useFieldWithInitialValue} from './useFieldWithInitialValue';

export * from './useField';
export * from './useFieldWithInitialValue';

export function Field<FieldValue, T extends HTMLElement = HTMLElement, InputValue = FieldValue>({
  name,
  children,
  component: Component,
  render,
  ...rest
}: FieldProps<FieldValue, FieldRenderProps<FieldValue, T, InputValue>, T, InputValue>) {
  const field = useFieldWithInitialValue<FieldValue, T, InputValue>(name, rest);

  if (Component) {
    return React.createElement(Component, {...field, ...rest}, children as React.ReactNode);
  }

  if (render) return render({...field, ...rest});

  if (children) {
    if (typeof children === 'function') {
      return children({...field, ...rest});
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
}
