import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {PaymentControls} from 'components/widgets/PaymentControls';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {OfferFormSectionTitle} from 'domain/offer/widgets/FormSectionTitle';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import {usePaymentControlsConfigs} from './usePaymentControlsConfigs';

type Props = {
  compensation?: OfferCompensation;
};

export function OfferOneTimeBonusesFields({compensation}: Props) {
  const intl = useIntl();
  const {signOnBonusPaymentControlsConfig, relocationBonusPaymentControlsConfig} =
    usePaymentControlsConfigs(compensation);

  return (
    <>
      <OfferFormSectionTitle title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item xl={12}>
            <PaymentControls config={signOnBonusPaymentControlsConfig} />
          </Grid.Item>
          <Grid.Item xl={12}>
            <PaymentControls config={relocationBonusPaymentControlsConfig} />
          </Grid.Item>
        </Grid>
      </Panel.Content>
    </>
  );
}
