import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  deletionConfirmationTitle: {
    defaultMessage: 'Delete record',
    description: '[title] User job info panel: delete market data record confirmation dialog',
  },
  deletionConfirmationText: {
    defaultMessage: 'Are you sure you want to delete this function and level record?',
    description: 'User job info panel: delete market data record confirmation text',
  },
  deletionConfirmationButton: {
    defaultMessage: 'Delete',
    description: '[button] User job info panel: delete market data record confirmation button',
  },
});
