import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {OfficeSeat, OfficeSeatId, SeatIdentification} from 'domain/officeMap/seat/model';
import {SeatSvg} from 'domain/officeMap/seat/widgets/SeatSvg';
import {User, UserFull} from 'domain/user/model';
import sortBy from 'lodash/sortBy';
import React, {useMemo} from 'react';
import styles from './styles.css';

type Props = {
  seats: OfficeSeat[];
  onSeatSelect: (seat: OfficeSeat | null, officeAlias?: OfficeAlias) => void;
  selectedSeat: SeatIdentification | null;
  selfUser: UserFull;
  usersBySeatId: Record<OfficeSeatId, User>;
  showSeatNumbers: boolean;
};

type TextCoordinates = {
  angle?: number;
  x: number;
  y: number;
};

const generateTextExtraRotation = (angle: number) => {
  // will rotate text upside down if the seat is rotated too much
  return angle > 90 || angle <= -90 ? 'rotate(180 9.5 4.3)' : '';
};

const generateTextTransform = ({x, y, angle}: TextCoordinates): string =>
  `translate(${x} ${y}) rotate(${angle ?? 0}) ${generateTextExtraRotation(angle ?? 0)}`;

export const SeatsSvg = React.memo(
  ({seats, onSeatSelect, selectedSeat, selfUser, usersBySeatId, showSeatNumbers}: Props) => {
    const selfSeatId = selfUser.seat?.id;
    // move own seat to the end of the list to show on top of others (no z-index for svg)
    const sortedSeats = useMemo(() => {
      const checkIsMine = ({id}: OfficeSeat) => id === selfSeatId;
      return sortBy(seats, checkIsMine);
    }, [seats, selfSeatId]);
    return (
      <>
        {sortedSeats.map((seat) => (
          <SeatSvg
            key={seat.id}
            onSeatSelect={onSeatSelect}
            seat={seat}
            isActive={
              (selectedSeat?.type === 'id' && seat.id === selectedSeat?.id) ||
              (selectedSeat?.type === 'number' && seat.number?.toLowerCase() === selectedSeat?.number?.toLowerCase())
            }
            selfUser={selfUser}
            user={usersBySeatId[seat.id]}
          />
        ))}
        {showSeatNumbers &&
          seats
            .filter((seat) => seat.number)
            .map((seat) => (
              <text
                key={seat.id}
                className={styles.number}
                // Shift the text relative to the seat coordinate
                x={1.8}
                y={6.5}
                transform={generateTextTransform(seat.coordinates)}
              >
                {seat.number}
              </text>
            ))}
      </>
    );
  },
);
