import {intranetApi} from 'apiClient';

export type TimeOffRequestsDownloadParams = {
  start: string;
  end: string;
};

export const buildDownloadLink = (params: TimeOffRequestsDownloadParams) =>
  intranetApi.getUri({
    params,
    url: `${intranetApi.defaults.baseURL}/v1/timeOffs/requests/download`,
  });
