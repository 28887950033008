import React, {useCallback} from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../../create-locator';
import styles from './index.css';

export type OptionActionTestId = ConvertLocatorToTestId<OptionActionLocator>;
export type OptionActionLocator = LocatorOfElement<void>;

type Props = Omit<JSX.IntrinsicElements['button'], 'className' | 'type'> & Partial<Mark<OptionActionLocator>>;

export const OptionAction = React.forwardRef<HTMLButtonElement, Props>(function OptionAction(
  {children, onClick, ...propertiesWithMark}: Props,
  ref,
) {
  const locator = createLocator(propertiesWithMark);
  const props = removeMarkFromProperties(propertiesWithMark);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();

      if (onClick) {
        onClick(event);
      }
    },
    [onClick],
  );

  return (
    <button {...props} {...locator()} className={styles.action} onClick={handleClick} ref={ref} type='button'>
      {children}
    </button>
  );
});
