/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import React from 'react';
import styles from './index.css';

type Props = {
  className?: string;
  onMove: (diff: number) => void;
};

type PointerPosition = {
  x: number;
};

function getPointerPosition(event: MouseEvent | React.MouseEvent<unknown>): PointerPosition {
  return {x: event.pageX};
}

export class Resizer extends React.Component<Props> {
  private isMoveHandleQueued = false;

  private prevPointerPosition: PointerPosition = {x: 0};

  private handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
    this.prevPointerPosition = getPointerPosition(event);
  };

  private handleMouseMove = (event: MouseEvent): void => {
    if (this.isMoveHandleQueued) {
      return;
    }
    this.isMoveHandleQueued = true;
    requestAnimationFrame(() => {
      const currentPointerPosition = getPointerPosition(event);
      const diff = currentPointerPosition.x - this.prevPointerPosition.x;

      if (diff !== 0) {
        this.props.onMove(diff);
      }

      this.prevPointerPosition = currentPointerPosition;
      this.isMoveHandleQueued = false;
    });
  };

  private handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };

  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {className, onMove, ...restProps} = this.props;

    return <div {...restProps} className={cn(className, styles.resizer)} onMouseDown={this.handleMouseDown} />;
  }
}
