import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {LegalEntityId} from 'domain/legalEntity/model';
import {GetSalaryRecordsParams} from 'domain/salaryRecord/api/types/get';
import {AnySalaryRecord} from 'domain/salaryRecord/model';
import {SalaryReadAccess} from 'domain/salaryRecord/model/access';
import {
  getAnyExtendedSalaryRecordsFx,
  getAnySalaryRecordsFx,
  getMySalaryRecordsFx,
  getSubordinateSalaryRecordsFx,
} from 'domain/salaryRecord/stores/main';
import {$salaryRecords} from 'domain/salaryRecord/stores/main/state';
import {UserFull} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';
import {useSalaryReadAccess} from './useSalaryReadAccess';

const handlerByAccess: Record<SalaryReadAccess, (params: GetSalaryRecordsParams) => Promise<AnySalaryRecord[]>> = {
  [SalaryReadAccess.ANY_EXTENDED]: getAnyExtendedSalaryRecordsFx,
  [SalaryReadAccess.ANY]: getAnySalaryRecordsFx,
  [SalaryReadAccess.SUBORDINATE]: getSubordinateSalaryRecordsFx,
  [SalaryReadAccess.MY]: getMySalaryRecordsFx,
};

type ReturnShape = {
  data: AnySalaryRecord[];
  dataState: DataState;
};

export function useUserSalaryRecords(user: UserFull, legalEntityId: LegalEntityId): ReturnShape {
  const readAccess = useSalaryReadAccess(user);
  const [error, setError] = useState<Error>();

  const data = useStoreMap({
    store: $salaryRecords,
    keys: [user.id, legalEntityId],
    fn: ({byUserId}, [id, legalEntityId]) => byUserId[id]?.[legalEntityId] || [],
  });
  const dataState = useStoreMap({
    store: $salaryRecords,
    keys: [user.id, legalEntityId],
    fn: ({dataStateByUserId}, [id, legalEntityId]) => dataStateByUserId[id]?.[legalEntityId] || DataState.IDLE,
  });

  useEffect(() => {
    if (readAccess && !isLoadingOrLoaded(dataState)) {
      handlerByAccess[readAccess]({userId: user.id, legalEntityId}).catch(setError);
    }
  }, [readAccess, user.id, legalEntityId]);

  useEffect(() => {
    if (error) {
      toaster.error(error.message);
    }
  }, [error]);

  return {
    data,
    dataState,
  };
}
