import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonUpdate: {
    defaultMessage: 'Update',
    description: '[button] Update job offer button',
  },
  buttonCancel: {
    defaultMessage: 'Cancel',
    description: '[button] Cancel updating job offer button',
  },
});
