import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {ReactNode} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {CellEmpty} from '../components/CellEmpty';
import {Column} from '../types';
import {Nullable} from './types';

const messages = defineMessages({
  yes: {
    id: 'data-table-cell-boolean-yes',
    description: 'Cell value in boolean data table column',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'data-table-cell-boolean-no',
    description: 'Cell value in boolean data table column',
    defaultMessage: 'No',
  },
});

function formatBoolean(value: boolean) {
  return <FormattedMessage {...(value ? messages.yes : messages.no)} />;
}

type Options<Item, NestedItem = never> = Omit<Column<Item, NestedItem>, 'render' | 'renderNested'> & {
  getValue: (item: Item) => Nullable<boolean>;
  render?: (item: Item, formattedValue: ReactNode) => ReactNode;
  getNestedValue?: (nestedItem: NestedItem) => Nullable<boolean>;
  renderNested?: (nestedItem: NestedItem, item: Item, formattedValue: ReactNode) => ReactNode;
};

export function createColumnBoolean<Item, NestedItem>({
  getNestedValue,
  getValue,
  render,
  renderNested,
  ...rest
}: Options<Item, NestedItem>): Column<Item, NestedItem> {
  return {
    align: 'center',

    render: (item) => {
      const value = getValue(item);

      if (isNotNullish(value)) {
        return render ? render(item, formatBoolean(value)) : formatBoolean(value);
      }
      return <CellEmpty />;
    },

    renderNested: (nestedItem, item) => {
      const value = getNestedValue?.(nestedItem);

      if (isNotNullish(value)) {
        return renderNested ? renderNested(nestedItem, item, formatBoolean(value)) : formatBoolean(value);
      }
      return <CellEmpty />;
    },

    ...rest,
  };
}
