import {ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {countryCodeSchema} from 'domain/countryCode/model';
import {formattedDateSchema} from 'models/system/formattedDate';

export const staySchema = object({
  city: optional(string()),
  countryCode: countryCodeSchema,
  endDate: formattedDateSchema,
  startDate: formattedDateSchema,
});

export type Stay = ExtractSchemaType<typeof staySchema>;
export type StayGroup = Omit<Stay, 'city'> & {
  includedStays: Stay[];
};
