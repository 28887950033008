import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useUserOfficePolicyRecords} from 'domain/officePolicyRecord/hooks/useOfficePolicyRecords';
import {OfficePolicyRecord} from 'domain/officePolicyRecord/model';
import {OfficePolicyRecordDialog} from 'domain/officePolicyRecord/widgets/Dialog';
import {UserFull} from 'domain/user/model';
import {JobPanelTab} from 'domain/user/widgets/JobPanel';
import {OfficePolicy} from 'domain/user/widgets/OfficePolicy';
import {OfficePolicyTabAddon} from 'domain/user/widgets/OfficePolicyTabAddon';
import React, {useMemo} from 'react';

export function useOfficePolicyTab(user: UserFull): JobPanelTab<OfficePolicyRecord> {
  const officePolicyRecords = useUserOfficePolicyRecords(user);
  const officePolicyRecordDialog = usePopupState<OfficePolicyRecord>();

  const result = useMemo(
    () => ({
      addon: <OfficePolicyTabAddon onDialogOpen={officePolicyRecordDialog.open} />,
      content: (
        <OfficePolicy
          userId={user.id}
          records={officePolicyRecords.data}
          dataState={officePolicyRecords.dataState}
          onDialogOpenWithPayload={officePolicyRecordDialog.openWithPayload}
        />
      ),
      dialog: (
        <OfficePolicyRecordDialog
          userId={user.id}
          recordToUpdate={officePolicyRecordDialog.payload}
          isOpen={officePolicyRecordDialog.isOpen}
          onClose={officePolicyRecordDialog.close}
        />
      ),
      records: officePolicyRecords.data,
      dataState: officePolicyRecords.dataState,
    }),
    [user, officePolicyRecords.data, officePolicyRecords.dataState, officePolicyRecordDialog],
  );

  return result;
}
