import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {Employment, employmentSchema} from 'domain/employment/model';
import {UserId} from 'domain/user/model';

export const getEmploymentsByUserId = async (userId: UserId): Promise<Employment[]> => {
  const {data} = await intranetApi.get('/v1/users/employments/getByUserId', {params: {userId}});

  return array(employmentSchema).runWithException(data);
};
