import {useLayoutEffect} from 'react';
import {pick} from '@joomcode/deprecated-utils/object/pick';
import {AutoStretchMode, Column, ColumnsWidthMap} from '../types';
import {getFixedColumns, getStretchableColumnIds, stretchColumnWidths} from '../utils';

type Options = {
  autoStretchMode: AutoStretchMode;
  columns: Column<unknown>[];
  getColumnWidth: (column: Column<unknown>) => number;
  content: React.RefObject<HTMLElement>;
  scrollView: React.RefObject<HTMLElement>;
  onStretch: (widthMap: (prev: ColumnsWidthMap) => ColumnsWidthMap) => void;
};
export function useStretchColumnsOnMount({
  autoStretchMode,
  columns,
  getColumnWidth,
  content,
  scrollView,
  onStretch,
}: Options): void {
  const stretchWidthMap = (widthMap: ColumnsWidthMap, targetWidth: number) => {
    const fixedColumnsWidth = getFixedColumns(columns).reduce((acc, column) => acc + getColumnWidth(column), 0);
    return {
      ...widthMap,
      ...stretchColumnWidths({
        widthMap: pick(widthMap, getStretchableColumnIds(columns)),
        targetWidth: targetWidth - fixedColumnsWidth,
      }),
    };
  };

  useLayoutEffect(() => {
    if (autoStretchMode === 'never') {
      return;
    }

    if (content.current && scrollView.current) {
      const targetWidth = scrollView.current.clientWidth;
      if (content.current.clientWidth < targetWidth) {
        onStretch((prevWidthMap) => stretchWidthMap(prevWidthMap, targetWidth));
      }
    }
  }, [content.current, scrollView.current]);
}
