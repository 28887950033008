import {identity, isNotNullish} from '@joomcode/deprecated-utils/function';
import {bindValidatorOptions, composeValidators, Field, formatError, getFieldValidator} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {FieldFloat, FieldFloatValue} from 'components/widgets/fields/FieldFloat';
import {Currency} from 'domain/currency/model';
import {CurrencySelect} from 'domain/currency/widgets/Select';
import {Frequency} from 'domain/frequency/model';
import {formatFrequency} from 'domain/frequency/model/formatter';
import React, {useCallback, useMemo} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {errorMessages} from './messages';
import styles from './styles.css';
import {PaymentControlsConfig} from './types';
import {validatePaymentFields} from './validate';

type Props = {
  config: PaymentControlsConfig;
  disabled?: boolean;
};

export function PaymentControls({config, disabled}: Props) {
  const intl = useIntl();

  const frequencyToString = useCallback((frequency: Frequency) => formatFrequency(frequency, intl), [intl]);

  const {getState, getFieldState} = useForm();
  const configItems = useMemo(() => Object.values(config), [config]);
  const fieldNames = useMemo(() => configItems.map(({name}) => name), [configItems]);
  const validate = bindValidatorOptions(validatePaymentFields, {
    getValues: () => configItems.map(({getValueFromFormState}) => getValueFromFormState(getState().values)),
  });
  const currencyConfig = config.currency;
  const frequencyConfig = config.frequency;

  const fieldMetas = fieldNames.map((name) => getFieldState(name)).filter(isNotNullish);
  const firstError = fieldMetas.map(({error}) => error).find(isNotNullish);
  const isAnyFieldActive = fieldMetas.some(({active}) => active);
  const errorText = firstError && formatError(firstError, intl, errorMessages);
  const showError = errorText && !isAnyFieldActive;

  return (
    <div className={styles.root}>
      <Grid>
        <Grid.Item {...config.amount.gridItemBreakpoints}>
          <Field<FieldFloatValue>
            name={config.amount.name}
            validate={
              config.amount.validate ? getFieldValidator(composeValidators(config.amount.validate, validate)) : validate
            }
            validateFields={fieldNames}
            subscription={{value: true, active: true, error: true}}
          >
            {() => (
              <FormControl
                disabled={disabled}
                label={config.amount.label}
                hint={config.amount.hint}
                required={config.amount.required}
                reserveSpaceForError={false}
              >
                {(formControlProps) => (
                  <FieldFloat
                    {...formControlProps}
                    initialValue={config.amount.initialValue}
                    disabled={disabled}
                    name={config.amount.name}
                    invalid={showError}
                  />
                )}
              </FormControl>
            )}
          </Field>
        </Grid.Item>
        {currencyConfig && (
          <Grid.Item {...currencyConfig.gridItemBreakpoints}>
            <Field<Currency | null>
              initialValue={currencyConfig.initialValue}
              name={currencyConfig.name}
              validate={
                currencyConfig.validate
                  ? getFieldValidator(composeValidators(currencyConfig.validate, validate))
                  : validate
              }
              validateFields={fieldNames}
              subscription={{value: true, active: true, error: true}}
            >
              {({input}) => (
                <FormControl
                  disabled={disabled}
                  label={currencyConfig.label}
                  hint={currencyConfig.hint}
                  required={currencyConfig.required}
                  reserveSpaceForError={false}
                >
                  {(formControlProps) => (
                    <CurrencySelect
                      {...formControlProps}
                      {...input}
                      disabled={disabled}
                      invalid={showError}
                      clearable
                    />
                  )}
                </FormControl>
              )}
            </Field>
          </Grid.Item>
        )}
        {frequencyConfig && (
          <Grid.Item {...frequencyConfig.gridItemBreakpoints}>
            <Field<Frequency | null>
              initialValue={frequencyConfig.initialValue}
              name={frequencyConfig.name}
              validate={
                frequencyConfig.validate
                  ? getFieldValidator(composeValidators(frequencyConfig.validate, validate))
                  : validate
              }
              validateFields={fieldNames}
              subscription={{value: true, active: true, error: true}}
            >
              {({input}) => (
                <FormControl
                  disabled={disabled}
                  label={frequencyConfig.label}
                  hint={frequencyConfig.hint}
                  required={frequencyConfig.required}
                  reserveSpaceForError={false}
                >
                  {(formControlProps) => (
                    <SimpleAutocomplete<Frequency>
                      {...formControlProps}
                      {...input}
                      items={frequencyConfig.availableValues}
                      getItemKey={identity}
                      itemToString={frequencyToString}
                      invalid={showError}
                      renderItem={frequencyToString}
                      disabled={disabled}
                      clearable
                    />
                  )}
                </FormControl>
              )}
            </Field>
          </Grid.Item>
        )}
      </Grid>
      {showError && <FormControl.Error message={errorText} />}
    </div>
  );
}

export type {PaymentControlsConfig};
