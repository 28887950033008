import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  cancelDialogTitle: {
    defaultMessage: 'Cancel compensation request',
    description: '[title] Cancel dialog title',
  },
  cancelDialogText: {
    defaultMessage: 'Are you sure you want to cancel this request?',
    description: 'Cancel dialog text',
  },
  cancelDialogConfirmButton: {
    defaultMessage: 'Cancel request',
    description: '[button] Compensation request cancellation: confirm',
  },
  cancelDialogDenyButton: {
    defaultMessage: 'Keep it',
    description: '[button] Compensation request cancellation: deny',
  },
  rejectButton: {
    defaultMessage: 'Reject',
    description: '[button] Compensation request action (on actions panel)',
  },
  cancelButton: {
    defaultMessage: 'Cancel request',
    description: '[button] Compensation request action (on actions panel)',
  },
  closeButton: {
    defaultMessage: 'Complete request',
    description: '[button] Compensation request action (on actions panel)',
  },
  fillReportButton: {
    defaultMessage: 'Report',
    description: '[button] Compensation request action (on actions panel)',
  },
  continueReportButton: {
    defaultMessage: 'Continue report',
    description: '[button] Compensation request action (on actions panel)',
  },
});
