import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const timeOffUrlPatterns = {
  root: '/time-off',
  requests: '/time-off/requests',
  createRequest: '/time-off/requests/create',
  createRequestOnBehalf: '/time-off/requests/create/:login',
};

export const timeOffUrls = {
  root: () => timeOffUrlPatterns.root,
  requests: (searchParams?: {mode?: string}) => addSearchParams(timeOffUrlPatterns.requests, searchParams),
  createRequest: () => timeOffUrlPatterns.createRequest,
  createRequestOnBehalf: (pathParams: {login: string}) =>
    generatePath(timeOffUrlPatterns.createRequestOnBehalf, pathParams),
};
