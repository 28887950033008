import {array} from '../../validation/jsonValidators';
import {validateFile, FileValidatorErrorCode, FileValidatorOptions, ValidationError} from '../../validation';

export const getFilesFromInputValue = (inputValue: string | File | File[]): File[] => {
  if (Array.isArray(inputValue)) {
    return inputValue;
  }

  if (inputValue instanceof File) {
    return [inputValue];
  }

  return [];
};

export const consolidatedFileValidator = (validateSingleFile: typeof validateFile): typeof validateFile => {
  return async (value: unknown) => {
    const errors = (await array(validateSingleFile)(value)) as
      | ValidationError<FileValidatorErrorCode, FileValidatorOptions>[]
      | undefined;
    if (errors) {
      return errors.find((error) => error);
    }
    return undefined;
  };
};
