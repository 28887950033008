// eslint-disable-next-line no-restricted-syntax
import {getFieldErrorText as getFieldErrorTextBase, GetFieldErrorTextOptions} from '@joomcode/joom-form';
import {FieldMetaState} from 'react-final-form';
import {errorMessages} from 'utils/form/errorMessages';

type FieldMeta = FieldMetaState<unknown>;

export function getFieldErrorText(meta: FieldMeta, options: GetFieldErrorTextOptions = {}): string | null {
  const {extraMessages} = options;

  return getFieldErrorTextBase(meta, {
    ...options,
    extraMessages: {
      ...errorMessages,
      ...extraMessages,
    },
  });
}
