import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  filledError: {
    defaultMessage: 'Both fields should be filled',
    description: 'Field error',
  },
  filledOrEmptyError: {
    defaultMessage: 'Both fields should be filled or empty',
    description: 'Field error',
  },
});
