import {ExtractSchemaType, object} from '@joomcode/deprecated-utils/jsonValidation';
import {stockOptionAgreementSchema} from 'domain/stockOption/agreement/model';
import {stockOptionSummarySchema} from 'domain/stockOption/summary/model';

export const stockOptionAgreementWithSummarySchema = object({
  agreement: stockOptionAgreementSchema,
  summary: stockOptionSummarySchema,
});

export type StockOptionAgreementWithSummary = ExtractSchemaType<typeof stockOptionAgreementWithSummarySchema>;
