import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {marketDataApi} from 'domain/marketDataRecord/api';
import {Permission} from 'domain/permission/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {salaryRecordsApi} from 'domain/salaryRecord/api';
import {SalariesExportByDateButton} from 'domain/salaryRecord/widgets/ExportByDateButton';
import {usersApi} from 'domain/user/api';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

export function FinancialRecordsExportPanel() {
  const acl = useAcl();
  const intl = useIntl();

  return (
    <Panel>
      <Panel.Header title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <ButtonGroup spaced size='m'>
          {acl.hasSecurePermission(SecurePermission.MARKET_DATA_RECORD_DOWNLOAD) && (
            <DownloadLinkButton
              kind='secondary'
              intent='neutral'
              href={marketDataApi.buildDownloadAllLink()}
              iconLeft={<DownloadIcon />}
            >
              {intl.formatMessage(messages.buttonDownloadMarketData)}
            </DownloadLinkButton>
          )}
          {(acl.hasSecurePermission(SecurePermission.SALARY_RECORD_DOWNLOAD) ||
            acl.hasSecurePermission(SecurePermission.SALARY_RECORD_DOWNLOAD_EXTENDED)) && (
            <>
              <DownloadLinkButton
                kind='secondary'
                intent='neutral'
                href={salaryRecordsApi.buildDownloadAllLink()}
                iconLeft={<DownloadIcon />}
              >
                {intl.formatMessage(messages.buttonDownloadSalaries)}
              </DownloadLinkButton>
              <SalariesExportByDateButton />
            </>
          )}
          {acl.hasPermission(Permission.USER_LEGAL_INFO_DOWNLOAD) && (
            <DownloadLinkButton
              kind='secondary'
              intent='neutral'
              href={usersApi.buildLegalDataDownloadLink()}
              iconLeft={<DownloadIcon />}
            >
              {intl.formatMessage(messages.buttonDownloadLegalData)}
            </DownloadLinkButton>
          )}
        </ButtonGroup>
      </Panel.Content>
    </Panel>
  );
}
