import styles from './index.css';

export enum TypographyToken {
  DISPLAY1 = 'display1',
  DISPLAY2 = 'display2',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  BODY_XL = 'bodyXL',
  BODY_L = 'bodyL',
  BODY_M = 'bodyM',
  BODY_S = 'bodyS',
  BODY_XS = 'bodyXS',
  CAPTION_S = 'captionS',
}

export const typographyTokenStyles = styles as Record<TypographyToken, string>;
