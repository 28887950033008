import cn from 'classnames';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {isRoomBusyNow, isRoomFreeButNonBookable, OfficeRoom, OfficeRoomId} from 'domain/officeMap/room/model';
import {RoomSvg} from 'domain/officeMap/room/widgets/RoomSvg';
import React from 'react';
import {Feature, useFeature} from 'services/features';
import styles from './styles.css';

type Props = {
  isActive: boolean;
  rooms: OfficeRoom[];
  selectedRoomId: OfficeRoomId | null;
  onRoomSelect: (room: OfficeRoom | null, officeAlias?: OfficeAlias) => void;
  shadowElementId: string;
};

export const RoomsSvg = React.memo(({isActive, rooms, onRoomSelect, shadowElementId, selectedRoomId}: Props) => {
  const calendarIssuesFeature = useFeature(Feature.CALENDAR_ISSUES);

  return (
    <g className={cn({[styles.roomsFaded]: selectedRoomId !== null})}>
      {isActive &&
        rooms.map((room) => (
          <RoomSvg
            key={room.id}
            room={room}
            onRoomSelect={onRoomSelect}
            isActive={selectedRoomId !== room.id}
            shadowElementId={shadowElementId}
            faded={
              calendarIssuesFeature.isAvailable ||
              selectedRoomId !== null ||
              isRoomBusyNow(room) ||
              isRoomFreeButNonBookable(room)
            }
          />
        ))}
    </g>
  );
});
