import {LocalesConfig, LocaleConfig} from '.';

export function getLocalesByAlias<AppLocale extends string>(
  localesConfig: LocalesConfig<AppLocale>,
): Record<string, AppLocale | undefined> {
  return Object.entries<LocaleConfig>(localesConfig).reduce<Record<string, AppLocale | undefined>>(
    (result, [localeName, localeConfig]) => {
      if (localeConfig.aliases) {
        localeConfig.aliases.forEach((alias) => {
          // eslint-disable-next-line no-param-reassign
          result[alias] = localeName as AppLocale;
        });
      }

      return result;
    },
    {},
  );
}
