import React, {forwardRef} from 'react';
import {Menu} from '../../Menu';
import {splitProps} from './props';
import {SideMenuItemProps} from './types';
import {SideMenuItem} from '.';

type Props = SideMenuItemProps & JSX.IntrinsicElements['button'];

export const SideMenuButton = forwardRef<HTMLButtonElement, Props>(function SideMenuButton(props, ref) {
  const {itemProps, nativeProps} = splitProps(props);

  return (
    <SideMenuItem
      {...itemProps}
      render={(navItem) => <Menu.Item {...nativeProps} {...navItem} as='button' ref={ref} type='button' />}
    />
  );
});
