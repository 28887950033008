import React from 'react';
import styles from './index.css';

type Props = {
  children: React.ReactNode;
};

export function VisuallyHidden({children}: Props) {
  return <div className={styles.visuallyHidden}>{children}</div>;
}
