import {Key} from '@joomcode/deprecated-utils/keyboard/keys';
import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import React, {useRef} from 'react';
import {useStoredDisclosureState} from '../../Disclosure/storedState';
import {useDisclosureUi} from '../../Disclosure';

type GroupSpoilerProps = {
  isActive: boolean;
  storageKey: string;
};

export function useGroupSpoiler({isActive, storageKey}: GroupSpoilerProps) {
  const spoilerState = useStoredDisclosureState(storageKey);
  const spoilerUi = useDisclosureUi(storageKey, spoilerState);

  const spoilerRef = useRef<HTMLAnchorElement>(null);

  useUpdateEffect(() => {
    if (isActive && !spoilerState.isExpanded) {
      spoilerState.expand();
    }
  }, [isActive]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const handlers: Partial<Record<Key, () => void>> = {
      [Key.ARROW_LEFT]: spoilerState.collapse,
      [Key.ARROW_RIGHT]: spoilerState.expand,
    };

    const handler = handlers[event.key as Key];

    if (handler) {
      handler();
      if (spoilerRef.current) {
        spoilerRef.current.focus();
      }
    }
  };

  return {
    ...spoilerUi,
    ...spoilerState,
    ref: spoilerRef,
    handleKeyDown,
  };
}
