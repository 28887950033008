import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';

export function getTeamBreadcrumbs(
  teamId: TeamId,
  teamsById: Record<TeamId, Team | undefined>,
  includeCurrentTeam = false,
): Team[] {
  const breadcrumbs: Team[] = [];
  let id = includeCurrentTeam ? teamId : teamsById[teamId]?.parentTeamId;
  let team = id && teamsById[id];

  while (team) {
    breadcrumbs.unshift(team);
    id = team.parentTeamId;
    team = id && teamsById[id];
  }
  return breadcrumbs;
}
