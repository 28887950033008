import React, {forwardRef} from 'react';
import {Link} from 'react-router-dom';
import {isAbsoluteUrl} from '@joomcode/deprecated-utils/url/isAbsoluteUrl';
import {Menu} from '../../Menu';
import {ExternalLink} from '../../ExternalLink';
import {splitProps} from './props';
import {SideMenuItemProps} from './types';
import {SideMenuItem} from '.';

export type SideMenuLinkProps = JSX.IntrinsicElements['a'] &
  SideMenuItemProps & {
    href: string;
    kind?: 'dynamic' | 'static' | 'external';
  };

export const SideMenuLink = forwardRef<HTMLAnchorElement, SideMenuLinkProps>(function SideMenuLink(props, ref) {
  const {
    itemProps,
    nativeProps: {href, kind = 'dynamic', ...nativeProps},
  } = splitProps(props);

  if (isAbsoluteUrl(href) || kind !== 'dynamic') {
    const originAs = kind === 'external' ? ExternalLink : 'a';

    return (
      <SideMenuItem
        {...itemProps}
        render={(navItem) => (
          <Menu.Item {...nativeProps} {...navItem} as={itemProps.disabled ? 'span' : originAs} href={href} ref={ref} />
        )}
      />
    );
  }

  return (
    <SideMenuItem
      {...itemProps}
      render={(navItem) => (
        <Menu.Item
          {...nativeProps}
          {...navItem}
          aria-current={itemProps.isActive ? 'location' : undefined}
          as={itemProps.disabled ? 'span' : Link}
          to={href}
          ref={ref}
        />
      )}
    />
  );
});
