import {defineMessages} from 'react-intl';
import {Status} from '.';

export const statusMessages = defineMessages<Status>({
  [Status.APPROVED]: {
    defaultMessage: 'Approved',
    description: 'Compensation review request status',
  },
  [Status.CANCELED]: {
    defaultMessage: 'Canceled',
    description: 'Compensation review request status',
  },
  [Status.IN_PROCESS]: {
    defaultMessage: 'In process',
    description: 'Compensation review request status',
  },
  [Status.REJECTED]: {
    defaultMessage: 'Rejected',
    description: 'Compensation review request status',
  },
});
