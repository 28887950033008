import {array, Decoder, fail, succeed} from '@joomcode/deprecated-utils/jsonValidation';

/**
 * Decoder that validates the array is not empty.
 *
 * Example:
 * ```
 * array(number()).run([1, 2, 3])
 * // => {ok: true, result: [1, 2, 3]}
 *
 * array(number()).run([])
 * // => {ok: false, error: {..., message: "expected a non-empty array, got an empty array"}}
 * ```
 */
export const nonEmptyArray = <A>(values: Decoder<A>): Decoder<A[]> =>
  array(values).andThen((arr) =>
    arr.length > 0 ? succeed(arr) : fail(`expected a non-empty array, got an empty array`),
  );
