import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Joom Space',
    description: '[title] Intranet default page title',
  },
  titleTemplate: {
    defaultMessage: '{pageTitle} · Joom Space',
    description: '[title] Intranet page title',
  },
});
