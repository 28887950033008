import delay from 'delay';
import {useState, useCallback} from 'react';
import {clipboard} from '../clipboard';
import {useIsMounted} from './useIsMounted';

export type ClipboardCopyValue = (() => string | number) | string | number;

function prepareValue(value: ClipboardCopyValue): string {
  return String(typeof value === 'function' ? value() : value);
}

export function useClipboardCopy(
  value: ClipboardCopyValue,
  options: {
    timeoutMs: number;
  } = {timeoutMs: 2000},
) {
  const isMounted = useIsMounted();
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const copy = useCallback(() => {
    clipboard
      .copy(prepareValue(value))
      .then(
        () => {
          if (isMounted()) {
            setCopied(true);
          }
        },
        (copyError: Error) => {
          if (isMounted()) {
            setError(copyError);
          }
        },
      )
      .then(async () => {
        await delay(options.timeoutMs);

        if (isMounted()) {
          setCopied(false);
          setError(undefined);
        }
      });
  }, [value, options.timeoutMs]);

  return {copied, copy, error};
}
