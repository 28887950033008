import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plus.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {Page} from 'components/widgets/Page';
import {offersApi} from 'domain/offer/api';
import {useOffers} from 'domain/offer/hooks/useOffers';
import {OffersSearchFilters, offersSearchFiltersSchema} from 'domain/offer/model/filters';
import {OfferFilters} from 'domain/offer/widgets/Filters';
import {OfferTable} from 'domain/offer/widgets/Table';
import {SecurePermission} from 'domain/permission/model/secure';
import {useStoredFilters} from 'hooks/useStoredFilters';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/offer/titles';
import {offerUrls} from 'routes/offer/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';

export function OffersPage() {
  const acl = useAcl();
  const intl = useIntl();
  const {filterValues, setFilterValues} = useStoredFilters<OffersSearchFilters>({
    id: 'offers.filters',
    initialValues: {},
    valuesSchema: offersSearchFiltersSchema,
  });
  const {offers, dataState, pagination, offersCount} = useOffers(filterValues);

  return (
    <Page
      title={intl.formatMessage(pageTitles.offers)}
      actions={
        <ButtonGroup spaced size='m'>
          {acl.hasSecurePermission(SecurePermission.OFFER_DOWNLOAD) && (
            <DownloadLinkButton
              kind='text'
              intent='neutral'
              href={offersApi.buildDownloadAllLink()}
              iconLeft={<DownloadIcon />}
            >
              {intl.formatMessage(messages.buttonDownloadAll)}
            </DownloadLinkButton>
          )}
          {acl.hasSecurePermission(SecurePermission.OFFER_CREATE) && (
            <RouterLinkButton
              to={offerUrls.create}
              size='m'
              kind='primary'
              intent='primary'
              type='button'
              iconLeft={<PlusIcon />}
            >
              {intl.formatMessage(messages.buttonCreate)}
            </RouterLinkButton>
          )}
        </ButtonGroup>
      }
    >
      <Panel>
        <OfferFilters values={filterValues} onChange={setFilterValues} />
      </Panel>
      <Panel>
        <OfferTable offers={offers} pagination={pagination} dataState={dataState} count={offersCount} />
      </Panel>
    </Page>
  );
}
