import {defineMessages} from 'react-intl';
import {UserFullInfoColumn} from '.';
import {UserFullInfoColumnCategory} from './category';

export const fieldLabels = defineMessages<UserFullInfoColumn | UserFullInfoColumnCategory>({
  [UserFullInfoColumnCategory.JOB_INFO]: {
    defaultMessage: 'Job info',
    description: 'User full info column category',
  },
  [UserFullInfoColumnCategory.JOB_CONTRACT]: {
    defaultMessage: 'Contract info',
    description: 'User full info column category',
  },
  [UserFullInfoColumnCategory.SALARY]: {
    defaultMessage: 'Salary info',
    description: 'User full info column category',
  },
  [UserFullInfoColumnCategory.SALARY_RANGE]: {
    defaultMessage: 'Salary range info',
    description: 'User full info column category',
  },
  [UserFullInfoColumnCategory.REGULAR_BONUS]: {
    defaultMessage: 'Bonus info',
    description: 'User full info column category',
  },
  [UserFullInfoColumnCategory.OPTIONS]: {
    defaultMessage: 'Options info',
    description: 'User full info column category',
  },
  [UserFullInfoColumnCategory.PERSONAL]: {
    defaultMessage: 'Personal info',
    description: 'User full info column category',
  },
  [UserFullInfoColumn.USER]: {
    defaultMessage: 'Joomer',
    description: 'User full info column',
  },
  [UserFullInfoColumn.BIRTHDAY]: {
    defaultMessage: 'Birthday',
    description: 'User full info column',
  },
  [UserFullInfoColumn.LOGIN]: {
    defaultMessage: 'Login',
    description: 'User full info column',
  },
  [UserFullInfoColumn.EMAIL]: {
    defaultMessage: 'Email',
    description: 'User full info column',
  },
  [UserFullInfoColumn.GITHUB_LOGIN]: {
    defaultMessage: 'Github',
    description: 'User full info column',
  },
  [UserFullInfoColumn.PHONE]: {
    defaultMessage: 'Phone number',
    description: 'User full info column',
  },
  [UserFullInfoColumn.TIME_OFF_POLICY]: {
    defaultMessage: 'Work calendar',
    description: 'User full info column',
  },
  [UserFullInfoColumn.VACATION_BALANCE]: {
    defaultMessage: 'Vacation days',
    description: 'User full info column',
  },
  [UserFullInfoColumn.REMOTE_WORK_BALANCE]: {
    defaultMessage: 'Remote days',
    description: 'User full info column',
  },
  [UserFullInfoColumn.HIRE_DATE]: {
    defaultMessage: 'Start date',
    description: 'User full info column',
  },
  [UserFullInfoColumn.OFFICE_POLICY]: {
    defaultMessage: 'Office policy',
    description: 'User full info column',
  },
  [UserFullInfoColumn.LEVEL_CODE]: {
    defaultMessage: 'Level',
    description: 'User full info column',
  },
  [UserFullInfoColumn.USER_FUNCTION]: {
    defaultMessage: 'Function',
    description: 'User full info column',
  },
  [UserFullInfoColumn.PRIMARY_LEGAL_ENTITY]: {
    defaultMessage: 'Primary legal entity',
    description: 'User full info column',
  },
  [UserFullInfoColumn.DIVISION]: {
    defaultMessage: 'Business unit',
    description: 'User full info column',
  },
  [UserFullInfoColumn.DEPARTMENT]: {
    defaultMessage: 'Department',
    description: 'User full info column',
  },
  [UserFullInfoColumn.JOB_TITLE]: {
    defaultMessage: 'Job title',
    description: 'User full info column',
  },
  [UserFullInfoColumn.LOCATION]: {
    defaultMessage: 'Office',
    description: 'User full info column',
  },
  [UserFullInfoColumn.MANAGER]: {
    defaultMessage: 'Direct manager',
    description: 'User full info column',
  },
  [UserFullInfoColumn.JOB_CONTRACT_EFFECTIVE_DATE]: {
    defaultMessage: 'Contract start date',
    description: 'User full info column',
  },
  [UserFullInfoColumn.JOB_CONTRACT_CONTRACT_TYPE]: {
    defaultMessage: 'Contract type',
    description: 'User full info column',
  },
  [UserFullInfoColumn.JOB_CONTRACT_LEGAL_ENTITY]: {
    defaultMessage: 'Contract with',
    description: 'User full info column',
  },
  [UserFullInfoColumn.JOB_CONTRACT_COMMENT]: {
    defaultMessage: 'Contract comment',
    description: 'User full info column',
  },
  [UserFullInfoColumn.JOB_CONTRACT_TIME_OFF_POLICY]: {
    defaultMessage: 'Contract work calendar',
    description: 'User full info column',
  },
  [UserFullInfoColumn.JOB_CONTRACT_STATUS]: {
    defaultMessage: 'Contract status',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_START_DATE]: {
    defaultMessage: 'Salary start date',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_LEGAL_ENTITY]: {
    defaultMessage: 'Salary legal entity',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_FIX_BASE]: {
    defaultMessage: 'Fix base salary',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_EMPLOYMENT_RATE]: {
    defaultMessage: 'Employment rate',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_REGION]: {
    defaultMessage: 'Salary region',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_CR]: {
    defaultMessage: 'CR',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_RANGE_MINIMUM]: {
    defaultMessage: 'Minimum',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_RANGE_TARGET]: {
    defaultMessage: 'Midpoint',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_RANGE_MAXIMUM]: {
    defaultMessage: 'Maximum',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_RANGE_COMMENT]: {
    defaultMessage: 'Salary range comment',
    description: 'User full info column',
  },
  [UserFullInfoColumn.SALARY_RANGE_COMPENSATION_TRACK]: {
    defaultMessage: 'Salary range link',
    description: 'User full info column',
  },
  [UserFullInfoColumn.REGULAR_BONUS_PERIOD]: {
    defaultMessage: 'Bonus period',
    description: 'User full info column',
  },
  [UserFullInfoColumn.REGULAR_BONUS]: {
    defaultMessage: 'Bonus',
    description: 'User full info column',
  },
  [UserFullInfoColumn.OPTIONS_GRANTED_TOTAL]: {
    defaultMessage: 'Options granted total',
    description: 'User full info column',
  },
  [UserFullInfoColumn.OPTIONS_GRANTED_HISTORIC]: {
    defaultMessage: 'Options granted historic',
    description: 'User full info column',
  },
  [UserFullInfoColumn.OPTIONS_GRANTED_GENERAL]: {
    defaultMessage: 'Options granted general',
    description: 'User full info column',
  },
  [UserFullInfoColumn.OPTIONS_GRANTED_PERFORMANCE]: {
    defaultMessage: 'Options granted perfomance',
    description: 'User full info column',
  },
  [UserFullInfoColumn.OPTIONS_VESTED]: {
    defaultMessage: 'Options vested',
    description: 'User full info column',
  },
  [UserFullInfoColumn.OPTIONS_AVAILABLE]: {
    defaultMessage: 'Options available',
    description: 'User full info column',
  },
  [UserFullInfoColumn.OPTIONS_NEXT_12_MONTHS]: {
    defaultMessage: 'Vesting in next 12 months',
    description: 'User full info column',
  },
  [UserFullInfoColumn.OPTIONS_AGREEMENTS]: {
    defaultMessage: '№ of options agreements',
    description: 'User full info column',
  },
});
