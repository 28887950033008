import {OwnersFilledValidatorError} from 'domain/resource/widgets/Form/validate';
import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  name: {
    defaultMessage: 'Name',
    description: 'Service form: field label',
  },
  description: {
    defaultMessage: 'Description',
    description: 'Service form: field label',
  },
  owners: {
    defaultMessage: 'Owners (people)',
    description: 'Service form: field label',
  },
  owningMemberships: {
    defaultMessage: 'Owners (roles)',
    description: 'Service form: field label',
  },
});

export const hints = defineMessages({
  owners: {
    defaultMessage:
      'People responsible for managing access and updating service info. They also have the owner’s rights in all service resources',
    description: 'Service form: field hint',
  },
  owningMemberships: {
    defaultMessage: 'Team members with these roles will have the rights of service owners',
    description: 'Service form: field hint',
  },
  description: {
    defaultMessage: 'Fill out in English. <link>Markdown cheatsheet</link>',
    description: 'Service form: field hint',
  },
  useLatin: {
    defaultMessage: 'Fill out in English',
    description: 'Service form: field hint',
  },
});

export const errorMessages = defineMessages({
  [OwnersFilledValidatorError.EMPTY_OWNERS]: {
    defaultMessage: 'At least one owner (joomer or role) must be specified',
    description: 'Service form: errorMessage',
  },
});
