import {Permission} from 'domain/permission/model';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFull} from 'domain/user/model';
import {useAcl} from 'services/acl';

type ReturnShape = {
  canReadInterviewFeedbackLink: boolean;
  canReadOfferLink: boolean;
};

export function useHiringProcessAccess(user: UserFull): ReturnShape {
  const acl = useAcl();
  const selfId = useSelfUserId();

  return {
    canReadInterviewFeedbackLink:
      (selfId && user.ancestorIds.includes(selfId)) || acl.hasPermission(Permission.USER_INTERVIEW_LINK_READ),
    canReadOfferLink: acl.hasPermission(Permission.OFFER_READ),
  };
}
