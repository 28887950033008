import {DataState} from '@joomcode/deprecated-utils/dataState';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {
  createAutocompleteMultiselectFilter,
  createMultiSelectFilter,
  DataFilter,
  DataFilterConfig,
} from '@joomcode/joom-ui/DataFilter';
import {DataFilterSpinner} from 'components/ui/DataFilterSpinner';
import {ResourcesSearchFilters} from 'domain/resource/model';
import {ResourceStatus} from 'domain/resource/model/status';
import {resourceStatuses} from 'domain/resource/model/status/messages';
import {useServices} from 'domain/service/hooks/useServices';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  values: ResourcesSearchFilters;
  onChange(payload: ResourcesSearchFilters): void;
};

export function ResourceFilters({values, onChange}: Props) {
  const intl = useIntl();
  const {services, servicesState} = useServices();
  const config = useMemo<DataFilterConfig<ResourcesSearchFilters>>(() => {
    return {
      statuses: createMultiSelectFilter(intl.formatMessage(messages.statuses), {
        alwaysVisible: true,
        options: getEnumValues(ResourceStatus).map((status) => ({
          value: status,
          label: intl.formatMessage(resourceStatuses[status]),
        })),
      }),
      serviceIds: createAutocompleteMultiselectFilter(intl.formatMessage(messages.services), {
        alwaysVisible: true,
        options: services.map(({id, name}) => ({
          label: name,
          value: id,
        })),
        renderOption: ({label: title}) => title,
      }),
    };
  }, [intl, services]);

  if (servicesState === DataState.IDLE || servicesState === DataState.LOADING) {
    return <DataFilterSpinner />;
  }

  return <DataFilter config={config} onChange={onChange} values={values} />;
}
