import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Regular bonus',
    description: '[title] Offer form section title',
  },
});

export const labels = defineMessages({
  type: {
    defaultMessage: 'Calculation approach',
    description: 'Offer form field label',
  },
  amount: {
    defaultMessage: 'Amount',
    description: 'Offer form field label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'Offer form field label',
  },
  frequency: {
    defaultMessage: 'Frequency',
    description: 'Offer form field label',
  },
  comment: {
    defaultMessage: 'Comment',
    description: 'Offer form field label',
  },
});
