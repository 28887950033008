import {HintIcon} from 'components/ui/HintIcon';
import React from 'react';
import {FormattedNumber, useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  totalDays: number;
  balanceDays?: number;
};

export function DaysTaken({totalDays, balanceDays}: Props) {
  const intl = useIntl();
  return (
    <>
      {totalDays}
      {balanceDays && totalDays !== balanceDays ? (
        <HintIcon>
          {intl.formatMessage(messages.balanceDaysHint, {
            days: balanceDays,
            formattedDays: <FormattedNumber value={balanceDays} />,
          })}
        </HintIcon>
      ) : (
        <span className={styles.hiddenHintIcon}>
          {/* using hidden icon as a placeholder to keep all cells evenly aligned */}
          <HintIcon />
        </span>
      )}
    </>
  );
}
