import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useUserRegularBonusRecords} from 'domain/regularBonusRecord/hooks/useUserRegularBonusRecords';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {RegularBonusRecordDialog} from 'domain/regularBonusRecord/widgets/Dialog';
import {UserFull} from 'domain/user/model';
import {JobPanelTab} from 'domain/user/widgets/JobPanel';
import {RegularBonus} from 'domain/user/widgets/RegularBonus';
import {RegularBonusTabAddon} from 'domain/user/widgets/RegularBonusTabAddon';
import React, {useMemo, useState} from 'react';

export function useRegularBonusTab(user: UserFull, isSensitiveDataHidden: boolean): JobPanelTab<RegularBonusRecord> {
  const regularBonusRecords = useUserRegularBonusRecords(user);
  const regularBonusRecordDialog = usePopupState<RegularBonusRecord>();
  const [needToReplaceRegularBonus, setNeedToReplaceRegularBonus] = useState(false);

  const result = useMemo(
    () => ({
      addon: <RegularBonusTabAddon onDialogOpen={regularBonusRecordDialog.open} />,
      content: (
        <RegularBonus
          userId={user.id}
          records={regularBonusRecords.data}
          dataState={regularBonusRecords.dataState}
          isSensitiveDataHidden={isSensitiveDataHidden}
          onDialogOpenWithPayload={regularBonusRecordDialog.openWithPayload}
          setNeedToReplace={setNeedToReplaceRegularBonus}
        />
      ),
      dialog: (
        <RegularBonusRecordDialog
          userId={user.id}
          recordToUpdate={regularBonusRecordDialog.payload}
          needToReplace={needToReplaceRegularBonus}
          isOpen={regularBonusRecordDialog.isOpen}
          onClose={regularBonusRecordDialog.close}
        />
      ),
      records: regularBonusRecords.data,
      dataState: regularBonusRecords.dataState,
    }),
    [
      user.id,
      regularBonusRecords.data,
      regularBonusRecords.dataState,
      isSensitiveDataHidden,
      regularBonusRecordDialog,
      needToReplaceRegularBonus,
      setNeedToReplaceRegularBonus,
    ],
  );

  return result;
}
