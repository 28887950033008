import {Callout} from '@joomcode/joom-ui/Callout';
import {ReactComponent as InfoIcon} from '@joomcode/joom-ui/icons/circle/info.svg';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function DeletingTeamCallout() {
  const intl = useIntl();
  return (
    <Callout intent='warning' icon={<InfoIcon />}>
      {intl.formatMessage(messages.text)}
    </Callout>
  );
}
