import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {CompensationReviewRequestLayout} from 'domain/compensationReviewRequest/widgets/Layout';
import {OneTimeBonus} from 'domain/compensationReviewRequest/widgets/OneTimeBonus';
import {OneTimeBonusHeader} from 'domain/compensationReviewRequest/widgets/OneTimeBonusHeader';
import React from 'react';

type Props = {
  newUserInfo: CompensationReviewRequest['newUserInfo'];
};

export function CompensationReviewRequestSectionOneTimeBonus({newUserInfo}: Props) {
  return (
    <CompensationReviewRequestLayout>
      <OneTimeBonusHeader />
      <Panel.Content withPadding>
        <Grid>
          {newUserInfo.oneTimeBonuses?.map((oneTimeBonus, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <OneTimeBonus key={index} oneTimeBonus={oneTimeBonus} />
          ))}
        </Grid>
      </Panel.Content>
    </CompensationReviewRequestLayout>
  );
}
