import {composeValidators, getFieldValidator, validateFilled, validateJson} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {Page} from 'components/widgets/Page';
import {RtconfItem} from 'domain/rtconf/model';
import {rtconfKeyErrorMessages, rtconfValueErrorMessages} from 'domain/rtconf/model/messages';
import {validateRtconfKey} from 'domain/rtconf/model/validate';
import {createRtconfItemFx} from 'domain/rtconf/stores/main';
import React, {useCallback} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {pageTitles} from 'routes/rtconf/titles';
import {rtconfUrls} from 'routes/rtconf/urls';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {messages} from './messages';

export function RtconfCreateItemPage() {
  const intl = useIntl();
  const history = useHistory();

  const keyValidator = composeValidators(validateFilled, validateRtconfKey);
  const valueValidator = composeValidators(validateFilled, validateJson);

  const onSubmit = useCallback(
    ({key, value}: RtconfItem) =>
      createRtconfItemFx({key, value})
        .then((rtconf) => history.push(rtconfUrls.item({itemKey: rtconf.key})))
        .catch(toaster.interceptThenThrowError),
    [history],
  );

  return (
    <Page
      breadcrumbs={[
        {
          title: intl.formatMessage(pageTitles.root),
          href: rtconfUrls.root(),
        },
      ]}
      title={intl.formatMessage(pageTitles.rtconfCreate)}
    >
      <Panel withPadding>
        <Form<RtconfItem> onSubmit={onSubmit}>
          {({handleSubmit, submitting, valid}) => (
            <form onSubmit={handleSubmit}>
              <Field<string> name='key' initialValue='' validate={getFieldValidator(keyValidator)}>
                {({input, meta}) => (
                  <FormControl
                    disabled={submitting}
                    error={getFieldErrorText(meta, {intl, extraMessages: rtconfKeyErrorMessages})}
                    label={intl.formatMessage(messages.keyLabel)}
                    hint={intl.formatMessage(messages.keyHint)}
                  >
                    {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                  </FormControl>
                )}
              </Field>
              <Field<string> name='value' initialValue='' validate={getFieldValidator(valueValidator)}>
                {({input, meta}) => (
                  <FormControl
                    disabled={submitting}
                    error={getFieldErrorText(meta, {intl, extraMessages: rtconfValueErrorMessages})}
                    label={intl.formatMessage(messages.valueLabel)}
                    hint={intl.formatMessage(messages.valueHint)}
                  >
                    {(formControlProps) => <Textarea {...formControlProps} {...input} disabled={submitting} />}
                  </FormControl>
                )}
              </Field>
              <div>
                <Button size='l' kind='primary' intent='primary' type='submit' disabled={!valid} loading={submitting}>
                  {intl.formatMessage(messages.createButton)}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Panel>
    </Page>
  );
}
