import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ClampedText} from 'components/ui/ClampedText';
import {SalaryRangesVersion} from 'domain/salaryRange/model/version';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {SalaryRangeVersionActions} from '../VersionActions';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';
import styles from './styles.css';

type Props = {
  data: SalaryRangesVersion[];
  dataState: DataState;
};

const getRowClassName = ({active}: SalaryRangesVersion) => (!active ? styles.inactiveRow : '');
const getRowKey = ({id}: SalaryRangesVersion) => id;

export function SalaryRangesVersionsTable({data, dataState}: Props) {
  const intl = useIntl();
  const columns: Column<SalaryRangesVersion>[] = useMemo(
    () => [
      {
        defaultWidth: 5,
        id: ColumnId.EFFECTIVE_DATE,
        name: intl.formatMessage(columnNames[ColumnId.EFFECTIVE_DATE]),
        render: ({effectiveDate}) => <HumanDate value={effectiveDate} format='short' utc />,
      },
      {
        defaultWidth: 23,
        id: ColumnId.COMMENT,
        name: intl.formatMessage(columnNames[ColumnId.COMMENT]),
        render: ({comment}) => (comment ? <ClampedText text={comment} /> : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: 5,
        id: ColumnId.ACTIONS,
        name: '',
        render: (version) => <SalaryRangeVersionActions version={version} />,
      },
    ],
    [intl],
  );

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={data}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      storageName='salaryRanges.versions.list'
    />
  );
}
