import {Permission} from 'domain/permission/model';
import {KnownServerSecurePermission, SecurePermission} from 'domain/permission/model/secure';
import {$self} from 'domain/self/stores/main/state';
import {hasPermission} from './hasPermission';
import {inVpn} from './inVpn';

type CheckFunction = () => boolean;

const isGranted = (permissions: KnownServerSecurePermission[], withVpn = true): boolean => {
  if (withVpn && !inVpn()) {
    return false;
  }
  return permissions.every((permission) => $self.getState().securePermissions.has(permission));
};

export const checkBySecurePermission: Record<SecurePermission, CheckFunction> = {
  [SecurePermission.COMPENSATION_REVIEW_REQUEST_CREATE]: () =>
    isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_CREATE]),
  [SecurePermission.COMPENSATION_REVIEW_REQUEST_DOWNLOAD_ANY]: () =>
    isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_DOWNLOAD_ANY]),
  [SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY]: () =>
    isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY]),
  [SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_SUBORDINATE]: () =>
    isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_READ_SUBORDINATE]),
  [SecurePermission.COMPENSATION_REVIEW_REQUEST_SET_COMPENSATIONS_RECORDED]: () =>
    isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_SET_COMPENSATIONS_RECORDED]),
  [SecurePermission.COMPENSATION_REVIEW_REQUEST_SET_STOCK_OPTIONS_RECORDED]: () =>
    isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_SET_STOCK_OPTIONS_RECORDED]),
  [SecurePermission.COMPENSATION_REVIEW_REQUEST_WRITE]: () =>
    isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_WRITE]),
  [SecurePermission.COMPENSATION_REVIEW_REQUEST_WRITE_ON_BEHALF]: () =>
    isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_WRITE_ON_BEHALF]),

  [SecurePermission.FINANCIAL_RECORD_BULK_UPLOAD]: () =>
    isGranted([KnownServerSecurePermission.FINANCIAL_RECORD_BULK_UPLOAD]),

  [SecurePermission.MARKET_DATA_RECORD_DOWNLOAD]: () =>
    isGranted([KnownServerSecurePermission.MARKET_DATA_RECORD_DOWNLOAD]),
  [SecurePermission.MARKET_DATA_RECORD_READ_ANY]: () =>
    isGranted([KnownServerSecurePermission.MARKET_DATA_RECORD_READ_ANY]),
  [SecurePermission.MARKET_DATA_RECORD_WRITE]: () => isGranted([KnownServerSecurePermission.MARKET_DATA_RECORD_WRITE]),

  [SecurePermission.OFFER_COMPENSATION_COMMENT_READ_ANY]: () =>
    isGranted([KnownServerSecurePermission.OFFER_COMPENSATION_COMMENT_READ_ANY]),
  [SecurePermission.OFFER_COMPENSATION_READ_ANY]: () =>
    isGranted([KnownServerSecurePermission.OFFER_COMPENSATION_READ_ANY]),
  [SecurePermission.OFFER_COMPENSATION_READ_MY]: () =>
    isGranted([KnownServerSecurePermission.OFFER_COMPENSATION_READ_MY]),
  [SecurePermission.OFFER_CREATE]: () => isGranted([KnownServerSecurePermission.OFFER_CREATE]),
  [SecurePermission.OFFER_DOWNLOAD]: () => isGranted([KnownServerSecurePermission.OFFER_DOWNLOAD]),
  [SecurePermission.OFFER_STOCK_OPTION_EXISTENCE_READ]: () =>
    isGranted([KnownServerSecurePermission.OFFER_STOCK_OPTION_EXISTENCE_READ]),
  [SecurePermission.OFFER_STOCK_OPTION_READ_ANY]: () =>
    isGranted([KnownServerSecurePermission.OFFER_STOCK_OPTION_READ_ANY]),
  [SecurePermission.OFFER_STOCK_OPTION_READ_MY]: () =>
    isGranted([KnownServerSecurePermission.OFFER_STOCK_OPTION_READ_MY]),
  [SecurePermission.OFFER_UPDATE_ANY]: () => isGranted([KnownServerSecurePermission.OFFER_UPDATE_ANY]),
  [SecurePermission.OFFER_UPDATE_MY]: () => isGranted([KnownServerSecurePermission.OFFER_UPDATE_MY]),

  [SecurePermission.ONE_TIME_BONUS_RECORD_READ_ANY]: () =>
    isGranted([KnownServerSecurePermission.ONE_TIME_BONUS_RECORD_READ_ANY]),
  [SecurePermission.ONE_TIME_BONUS_RECORD_READ_SUBORDINATE]: () =>
    isGranted([KnownServerSecurePermission.ONE_TIME_BONUS_RECORD_READ_SUBORDINATE]),
  [SecurePermission.ONE_TIME_BONUS_RECORD_WRITE]: () =>
    isGranted([KnownServerSecurePermission.ONE_TIME_BONUS_RECORD_WRITE]),

  [SecurePermission.RECOMMENDED_JOB_TITLE_READ]: () =>
    isGranted([KnownServerSecurePermission.RECOMMENDED_JOB_TITLE_READ]),

  [SecurePermission.REGULAR_BONUS_RECORD_READ_ANY]: () =>
    isGranted([KnownServerSecurePermission.REGULAR_BONUS_RECORD_READ_ANY]),
  [SecurePermission.REGULAR_BONUS_RECORD_READ_SUBORDINATE]: () =>
    isGranted([KnownServerSecurePermission.REGULAR_BONUS_RECORD_READ_SUBORDINATE]),
  [SecurePermission.REGULAR_BONUS_RECORD_WRITE]: () =>
    isGranted([KnownServerSecurePermission.REGULAR_BONUS_RECORD_WRITE]),

  [SecurePermission.SALARY_RANGE_CALCULATE_CR]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RANGE_CALCULATE_CR]),
  [SecurePermission.SALARY_RANGE_CALCULATE_SUBORDINATE_CR]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RANGE_CALCULATE_SUBORDINATE_CR]),
  [SecurePermission.HR_CONSOLE_VIEW]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RANGE_READ_AS_ADMIN]) ||
    isGranted([KnownServerSecurePermission.SALARY_RANGE_UPLOAD]) ||
    isGranted([KnownServerSecurePermission.SALARY_RANGE_VERSION_READ_AS_ADMIN]) ||
    isGranted([KnownServerSecurePermission.USER_FULL_INFO_RECORD_READ]) ||
    isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY]),
  [SecurePermission.SALARY_RANGE_MANAGE]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RANGE_UPLOAD]) ||
    isGranted([KnownServerSecurePermission.SALARY_RANGE_VERSION_READ_AS_ADMIN]),
  // Manager console isn't available for those who can view HR console
  [SecurePermission.MANAGER_CONSOLE_VIEW]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RANGE_READ_AS_MANAGER]) &&
    !(
      isGranted([KnownServerSecurePermission.SALARY_RANGE_READ_AS_ADMIN]) ||
      isGranted([KnownServerSecurePermission.SALARY_RANGE_UPLOAD]) ||
      isGranted([KnownServerSecurePermission.SALARY_RANGE_VERSION_READ_AS_ADMIN]) ||
      isGranted([KnownServerSecurePermission.USER_FULL_INFO_RECORD_READ]) ||
      isGranted([KnownServerSecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY])
    ),
  [SecurePermission.SALARY_RANGE_READ_AS_ADMIN]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RANGE_READ_AS_ADMIN]),
  [SecurePermission.SALARY_RANGE_READ_AS_MANAGER]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RANGE_READ_AS_MANAGER]),
  [SecurePermission.SALARY_RANGE_UPLOAD]: () => isGranted([KnownServerSecurePermission.SALARY_RANGE_UPLOAD]),
  [SecurePermission.SALARY_RANGE_VERSION_READ_AS_ADMIN]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RANGE_VERSION_READ_AS_ADMIN]),
  [SecurePermission.SALARY_RANGE_VERSION_WRITE]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RANGE_VERSION_WRITE]),

  [SecurePermission.SALARY_RECORD_BULK_IMPORT]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RECORD_BULK_IMPORT]),
  [SecurePermission.SALARY_RECORD_DOWNLOAD]: () => isGranted([KnownServerSecurePermission.SALARY_RECORD_DOWNLOAD]),
  [SecurePermission.SALARY_RECORD_DOWNLOAD_EXTENDED]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RECORD_DOWNLOAD_EXTENDED]),
  [SecurePermission.SALARY_RECORD_READ]: () => isGranted([KnownServerSecurePermission.SALARY_RECORD_READ]),
  [SecurePermission.SALARY_RECORD_READ_EXTENDED]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RECORD_READ_EXTENDED]),
  [SecurePermission.SALARY_RECORD_SUBORDINATE_READ]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RECORD_SUBORDINATE_READ]),
  [SecurePermission.SALARY_RECORD_WRITE]: () => isGranted([KnownServerSecurePermission.SALARY_RECORD_WRITE]),
  [SecurePermission.SALARY_RECORD_WRITE_EXTENDED]: () =>
    isGranted([KnownServerSecurePermission.SALARY_RECORD_WRITE_EXTENDED]),

  [SecurePermission.SECURE_PERMISSION_READ]: () => isGranted([KnownServerSecurePermission.SECURE_PERMISSION_READ]),

  [SecurePermission.SECURE_ROLE_GRANT]: () => isGranted([KnownServerSecurePermission.SECURE_ROLE_GRANT]),
  [SecurePermission.SECURE_ROLE_READ]: () => isGranted([KnownServerSecurePermission.SECURE_ROLE_READ]),
  [SecurePermission.SECURE_ROLE_REVOKE]: () => isGranted([KnownServerSecurePermission.SECURE_ROLE_REVOKE]),
  [SecurePermission.SECURE_ROLE_UPDATE]: () => isGranted([KnownServerSecurePermission.SECURE_ROLE_UPDATE]),
  [SecurePermission.SECURE_SELF_SHOW_COMPENSATION_TAB]: () => false,

  [SecurePermission.SENSITIVE_DATA_DOWNLOAD]: () =>
    isGranted([KnownServerSecurePermission.MARKET_DATA_RECORD_DOWNLOAD]) ||
    isGranted([KnownServerSecurePermission.SALARY_RECORD_DOWNLOAD]) ||
    isGranted([KnownServerSecurePermission.SALARY_RECORD_DOWNLOAD_EXTENDED]) ||
    hasPermission(Permission.USER_LEGAL_INFO_DOWNLOAD),
  [SecurePermission.SENSITIVE_DATA_VIEW_ADMIN_UI]: () =>
    isGranted([KnownServerSecurePermission.FINANCIAL_RECORD_BULK_UPLOAD]) ||
    isGranted([KnownServerSecurePermission.SALARY_RECORD_DOWNLOAD]) ||
    isGranted([KnownServerSecurePermission.SALARY_RECORD_DOWNLOAD_EXTENDED]) ||
    isGranted([KnownServerSecurePermission.MARKET_DATA_RECORD_DOWNLOAD]) ||
    hasPermission(Permission.USER_LEGAL_INFO_DOWNLOAD),

  [SecurePermission.STOCK_OPTION_DOWNLOAD_ANY]: () =>
    isGranted([KnownServerSecurePermission.STOCK_OPTION_DOWNLOAD_ANY]),
  [SecurePermission.STOCK_OPTION_BULK_UPLOAD]: () => isGranted([KnownServerSecurePermission.STOCK_OPTION_BULK_UPLOAD]),
  [SecurePermission.STOCK_OPTION_READ_ANY]: () => isGranted([KnownServerSecurePermission.STOCK_OPTION_READ_ANY]),
  [SecurePermission.STOCK_OPTION_READ_SUBORDINATE]: () =>
    isGranted([KnownServerSecurePermission.STOCK_OPTION_READ_SUBORDINATE]),
  [SecurePermission.STOCK_OPTION_SEND_AGREEMENT_TO_HOLDER]: () =>
    isGranted([KnownServerSecurePermission.STOCK_OPTION_SEND_AGREEMENT_TO_HOLDER]),
  [SecurePermission.STOCK_OPTION_WRITE_ANY]: () => isGranted([KnownServerSecurePermission.STOCK_OPTION_WRITE_ANY]),

  [SecurePermission.USER_FULL_INFO_RECORD_READ]: () =>
    isGranted([KnownServerSecurePermission.USER_FULL_INFO_RECORD_READ]),
};
