import cn from 'classnames';
import React, {useLayoutEffect, useMemo, useRef} from 'react';
import {useIntl} from 'react-intl';
import {ReactComponent as CollapseIcon} from '../icons/core/collapseThin.svg';
import {Menu} from '../Menu';
import {SideMenuContext, SideMenuContextShape} from './context';
import {SideMenuButton} from './Item/Button';
import {SideMenuGroup} from './Group';
import {SideMenuLink, SideMenuLinkProps} from './Item/Link';
import {SideMenuSection} from './Section';
import {messages} from './messages';
import styles from './index.css';

export {SideMenuContext};
export type {SideMenuContextShape, SideMenuLinkProps};

const CUSTOM_PROPERTY_NAME = '--nav-aside-width';

type Props = {
  children: React.ReactNode;
  isCompact?: boolean;
  toggleCompact?: () => void;
};
export function SideMenu({children, isCompact, toggleCompact}: Props) {
  const intl = useIntl();
  const navAside = useMemo(() => ({isCompact, level: 0}), [isCompact]);
  const containerRef = useRef<HTMLDivElement>(null);

  const collapseButtonMessage = useMemo(() => intl.formatMessage(messages.collapse), [intl]);

  useLayoutEffect(() => {
    const node = containerRef.current;
    if (node) {
      document.documentElement.style.setProperty(CUSTOM_PROPERTY_NAME, `${node.offsetWidth}px`);
    }
    return () => {
      document.documentElement.style.removeProperty(CUSTOM_PROPERTY_NAME);
    };
  }, [isCompact]);

  return (
    <div className={cn(styles.navRoot, {[styles.navRootCompact]: isCompact})} ref={containerRef}>
      <nav className={styles.nav}>
        <Menu.Bar orientation='vertical'>
          <SideMenuContext.Provider value={navAside}>{children}</SideMenuContext.Provider>
        </Menu.Bar>
      </nav>

      {toggleCompact ? (
        <footer className={styles.footer}>
          <button className={styles.toggle} onClick={toggleCompact} type='button'>
            <CollapseIcon className={cn(styles.toggleIcon, {[styles.toggleIconCollapsed]: isCompact})} />
            {!isCompact && collapseButtonMessage}
          </button>
        </footer>
      ) : null}
    </div>
  );
}

SideMenu.Button = SideMenuButton;
SideMenu.Button.displayName = 'SideMenu.Button';

SideMenu.Group = SideMenuGroup;
SideMenu.Group.displayName = 'SideMenu.Group';

SideMenu.Link = SideMenuLink;
SideMenu.Link.displayName = 'SideMenu.Link';

SideMenu.Section = SideMenuSection;
SideMenu.Section.displayName = 'SideMenu.Section';
