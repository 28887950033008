import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  dateInterval?: string;
};

export function RemoteWorkIcon({dateInterval}: Props) {
  const intl = useIntl();
  const tooltip = intl.formatMessage(messages.tooltip, {dateInterval});

  return (
    <TooltipAlt content={tooltip} placement='right'>
      <span className={styles.root}>
        <span role='img' aria-label={tooltip}>
          🏡
        </span>
      </span>
    </TooltipAlt>
  );
}
