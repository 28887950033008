import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonHint: {
    defaultMessage: 'Download agreement',
    description: 'Download stock option agreement button hint',
  },
  noLegalInfoHint: {
    defaultMessage: 'Agreement download is unavailable: Joomer‘s legal data is not filled.',
    description: 'Download stock option agreement button: disabled hint',
  },
});
