import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {omit} from '@joomcode/deprecated-utils/object/omit';
import {ClientErrorStatus} from 'apiClient/ClientError';
import {EntityResponseStatus} from 'apiClient/entityResponse';
import {loadSelfFx, updateSelfFx} from 'domain/self/stores/main';
import {User, UserFull, UserId} from 'domain/user/model';
import {isUserFull, userFullToUser} from 'domain/user/model/utils';
import {createStore} from 'effector';
import {
  freeSeatFx,
  loadActiveUsersFx,
  loadAllUsersFx,
  loadUserByLoginFx,
  loadUserEverWorkedByLoginFx,
  setRolesFx,
  takeSeatFx,
  unassignSeatFx,
  updateUser,
  updateUserFx,
} from '.';

type State = {
  byLogin: Record<string, User | UserFull>;
  activeUsersDataState: DataState;
  allUsersDataState: DataState;
  errorByLogin: Record<string, ClientErrorStatus>;
  loginById: Record<UserId, string>;
  stateByLogin: Record<string, DataState>;
  terminatedUserByLogin: Record<string, User | UserFull>;
};

const mergeUserData = (user: User, storedUser: User | UserFull): User | UserFull => {
  if (storedUser && isUserFull(storedUser)) {
    return {
      ...storedUser,
      ...user,
    };
  }
  return user;
};

export const $users = createStore<State>({
  byLogin: {},
  activeUsersDataState: DataState.IDLE,
  allUsersDataState: DataState.IDLE,
  errorByLogin: {},
  loginById: {},
  stateByLogin: {},
  terminatedUserByLogin: {},
})
  .on([loadUserByLoginFx, loadUserEverWorkedByLoginFx], (state, {login}) => {
    return {
      ...state,
      stateByLogin: {...state.stateByLogin, [login]: DataState.LOADING},
    };
  })
  .on([loadUserByLoginFx.fail, loadUserEverWorkedByLoginFx.fail], (state, {params: {login}}) => {
    return {
      ...state,
      byLogin: omit(state.byLogin, [login]),
      errorByLogin: omit(state.errorByLogin, [login]),
      stateByLogin: {...state.stateByLogin, [login]: DataState.FAILED},
    };
  })
  .on([loadUserByLoginFx.done, loadUserEverWorkedByLoginFx.done], (state, {params: {login}, result}) => {
    if (result.status === EntityResponseStatus.REJECTED) {
      return {
        ...state,
        byLogin: omit(state.byLogin, [login]),
        errorByLogin: {...state.errorByLogin, [login]: result.reason},
        stateByLogin: {...state.stateByLogin, [login]: DataState.IDLE},
        terminatedUserByLogin: omit(state.byLogin, [login]),
      };
    }
    const byIdStore = result.value.isTerminated ? 'terminatedUserByLogin' : 'byLogin';
    return {
      ...state,
      [byIdStore]: {
        ...state[byIdStore],
        [login]: result.value,
      },
      errorByLogin: omit(state.errorByLogin, [login]),
      loginById: {
        ...state.loginById,
        [result.value.id]: login,
      },
      stateByLogin: {...state.stateByLogin, [login]: DataState.LOADED},
    };
  })
  .on(loadSelfFx.doneData, (state, self) => {
    if (self === undefined) {
      return state;
    }
    const {user} = self;
    return {
      ...state,
      byLogin: {
        ...state.byLogin,
        [user.login]: user,
      },
      loginById: {
        ...state.loginById,
        [user.id]: user.login,
      },
      stateByLogin: {...state.stateByLogin, [user.login]: DataState.LOADED},
    };
  })
  .on([updateUserFx.doneData, updateSelfFx.doneData, updateUser], (state, user) => {
    const {login, subordinates} = user;
    const userBasic = userFullToUser(user);
    const subordinateLoginsToUpdate = (subordinates ?? [])
      .map((subordinate) => subordinate.login)
      .filter((subordinateLogin) => state.byLogin[subordinateLogin]); // only take subordinates that persist in the store
    const updatedSubordinates = arrayToObject(subordinateLoginsToUpdate, identity, (subordinateLogin) => {
      const storedSubordinate = state.byLogin[subordinateLogin];
      return {
        ...storedSubordinate,
        managerId: userBasic.id,
        manager: isUserFull(storedSubordinate) ? {...userBasic} : undefined,
      };
    });
    return {
      ...state,
      byLogin: {
        ...state.byLogin,
        ...updatedSubordinates,
        [login]: user,
      },
      errorByLogin: omit(state.errorByLogin, [login]),
      loginById: {
        ...state.loginById,
        [user.id]: login,
      },
      stateByLogin: {...state.stateByLogin, [login]: DataState.LOADED},
    };
  })
  .on(loadActiveUsersFx, (state) => {
    return {
      ...state,
      activeUsersDataState: DataState.LOADING,
    };
  })
  .on(loadActiveUsersFx.fail, (state) => {
    if (state.activeUsersDataState !== DataState.LOADING) {
      return state;
    }
    return {
      ...state,
      activeUsersDataState: DataState.FAILED,
    };
  })
  .on(loadActiveUsersFx.done, (state, {result}) => {
    if (state.activeUsersDataState !== DataState.LOADING) {
      return state;
    }

    return {
      ...state,
      byLogin: arrayToObject(
        result,
        ({login}) => login,
        (user) => mergeUserData(user, state.byLogin[user.login]),
      ),
      activeUsersDataState: DataState.LOADED,
      loginById: {
        ...state.loginById,
        ...arrayToObject(
          result,
          (user) => user.id,
          (user) => user.login,
        ),
      },
    };
  })
  .on(loadAllUsersFx, (state) => {
    return {
      ...state,
      allUsersDataState: DataState.LOADING,
    };
  })
  .on(loadAllUsersFx.fail, (state) => {
    if (state.allUsersDataState !== DataState.LOADING) {
      return state;
    }
    return {
      ...state,
      allUsersDataState: DataState.FAILED,
    };
  })
  .on(loadAllUsersFx.done, (state, {result}) => {
    if (state.allUsersDataState !== DataState.LOADING) {
      return state;
    }

    const activeUsers = result.filter((u) => !u.isTerminated);
    const terminatedUsers = result.filter((u) => u.isTerminated);

    return {
      ...state,
      byLogin: arrayToObject(
        activeUsers,
        ({login}) => login,
        (user) => mergeUserData(user, state.byLogin[user.login]),
      ),
      terminatedUserByLogin: arrayToObject(
        terminatedUsers,
        ({login}) => login,
        (user) => mergeUserData(user, state.terminatedUserByLogin[user.login]),
      ),
      activeUsersDataState: DataState.LOADED,
      allUsersDataState: DataState.LOADED,
      loginById: {
        ...state.loginById,
        ...arrayToObject(
          result,
          (user) => user.id,
          (user) => user.login,
        ),
      },
    };
  })
  .on([freeSeatFx.doneData, takeSeatFx.doneData, unassignSeatFx.doneData], (state, user) => {
    const {login} = user;

    const storedUser = state.byLogin[user.login];
    const updatedUser = storedUser && isUserFull(storedUser) ? {...storedUser, seat: user.seat} : user;
    return {
      ...state,
      byLogin: {
        ...state.byLogin,
        [login]: updatedUser,
      },
    };
  })
  .on(setRolesFx.doneData, (state, user) => {
    const {login} = user;
    return {
      ...state,
      byLogin: {
        ...state.byLogin,
        [login]: {...state.byLogin[login], roleIds: user.roleIds},
      },
    };
  });
