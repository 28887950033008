import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {SalaryRangesUploadInput} from 'domain/salaryRange/model/upload';
import {SalaryRangesVersion, salaryRangesVersionSchema} from 'domain/salaryRange/model/version';

export const apply = async (input: SalaryRangesUploadInput): Promise<SalaryRangesVersion[]> => {
  const {data} = await intranetApi.post('/v1/secure/salaryRanges/uploads/apply', input);

  return array(salaryRangesVersionSchema).runWithException(data);
};
