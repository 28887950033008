import cn from 'classnames';
import React from 'react';
import {OmitUnion} from '@joomcode/deprecated-utils/types';
import {useIntl} from 'react-intl';
import {ControllerStateAndHelpers} from 'downshift';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../../create-locator';
import {Dropdown} from '../Dropdown';
import {HeadlessMultiSelect, HeadlessMultiSelectProps} from '../../HeadlessMultiSelect';
import {Option, ItemType} from '../../HeadlessMultiSelect/groups';
import {InputTokens, type InputTokensLocator} from '../../InputTokens';
import {Listbox, type ListboxLocator} from '../../Listbox';
import type {OptionLocator} from '../../Listbox/Option';
import {Preloader} from '../../Preloader';
import {IconCaret} from '../components/IconCaret';
import {messages} from './messages';
import styles from './index.css';
import type {InputAutosizeLocator} from '../../InputAutosize';
import '../theme.css';
import {CloseButton, type CloseButtonLocator} from '../../TagCloseButton';

export type MultiSelectTestId = ConvertLocatorToTestId<MultiSelectLocator>;
export type MultiSelectLocator = Locator<{
  input: InputAutosizeLocator;
  tokens: InputTokensLocator;
  tokensCloseButton: CloseButtonLocator;
  option: OptionLocator;
  optionsList: ListboxLocator;
}>;

type ReducedItems = {
  nodes: JSX.Element[];
  currentIndex: number;
};

export type MultiSelectProps<Item, Group> = OmitUnion<HeadlessMultiSelectProps<Item, Group>, 'children'> & {
  id?: string;
  isLoading?: boolean;
  placeholder?: string;
  name?: string;
  noSuggestionsPlaceholder?: React.ReactNode;
  getItemKey?: (item: Item) => string;
  renderOption?: (item: Item) => JSX.Element | string;
  renderToken?: (item: Item) => JSX.Element | string;
  renderGroupTitle?: (group: Group) => JSX.Element | string;
  onClear?: () => void;
  invalid?: boolean;
} & Partial<Mark<MultiSelectLocator>>;

export function MultiSelect<Item, Group = unknown>(props: MultiSelectProps<Item, Group>) {
  const locator = createLocator(props);
  const intl = useIntl();
  const disabled = props.disabled || props.isLoading;

  const renderItem = (item: Option<Item>, index: number, downshift: ControllerStateAndHelpers<Item>) => {
    return (
      <Listbox.Option
        key={(props.getItemKey || props.itemToString)(item.data)}
        {...downshift.getItemProps({
          item: item.data,
          index,
        })}
        isHighlighted={index === downshift.highlightedIndex}
        title={props.itemToString(item.data)}
        {...locator.option({})}
      >
        {(props.renderOption || props.itemToString)(item.data)}
      </Listbox.Option>
    );
  };

  return (
    <HeadlessMultiSelect {...props} disabled={disabled}>
      {({downshift, filteredItems, getInputProps, isOpen}) => (
        <Dropdown
          isOpen={isOpen}
          getContentContainerProps={() => downshift.getMenuProps({}, {suppressRefError: true})}
          content={
            <Listbox {...locator.optionsList()}>
              {
                filteredItems.reduce<ReducedItems>(
                  (result, itemOrGroup) => {
                    if (itemOrGroup.type === ItemType.GROUP) {
                      if (props.groupToString) {
                        result.nodes.push(
                          <Listbox.Group key={props.groupToString(itemOrGroup.data)}>
                            <Listbox.GroupTitle>
                              {(props.renderGroupTitle || props.groupToString)(itemOrGroup.data)}
                            </Listbox.GroupTitle>
                            {itemOrGroup.items.map((item) => {
                              // eslint-disable-next-line no-param-reassign
                              const index = result.currentIndex++;
                              return renderItem(item, index, downshift);
                            })}
                          </Listbox.Group>,
                        );
                        return result;
                      }
                      return result;
                    }
                    // eslint-disable-next-line no-param-reassign
                    const index = result.currentIndex++;
                    result.nodes.push(renderItem(itemOrGroup, index, downshift));
                    return result;
                  },
                  {nodes: [], currentIndex: 0},
                ).nodes
              }

              {filteredItems.length === 0 && (
                <Listbox.Option isHighlighted={false} disabled>
                  {props.noSuggestionsPlaceholder || intl.formatMessage(messages.noSuggestionsPlaceholder)}
                </Listbox.Option>
              )}
            </Listbox>
          }
        >
          <div className={styles.container} {...locator()}>
            <InputTokens
              ariaLabel=''
              id={props.id}
              name={props.name}
              disabled={disabled}
              onChange={props.onChange}
              value={props.value}
              tokenToString={props.itemToString}
              renderToken={props.renderToken}
              invalid={props.invalid}
              {...locator.tokens()}
              iconRight={
                <span className={styles.actions}>
                  {props.onClear ? (
                    <span className={styles.action}>
                      <CloseButton onClick={props.onClear} {...locator.tokensCloseButton()} />
                    </span>
                  ) : null}
                  <span className={styles.action}>
                    <IconCaret disabled={disabled} isOpen={isOpen} isActive={isOpen} />
                  </span>
                </span>
              }
            >
              {props.value.length === 0 && !getInputProps().value && !props.isLoading && (
                <span aria-hidden className={cn(styles.placeholder, {[styles.disabled]: disabled})}>
                  {props.placeholder}
                </span>
              )}

              <InputTokens.Input {...getInputProps()} aria-placeholder={props.placeholder} {...locator.input()} />

              {props.isLoading && (
                <div className={styles.preloader}>
                  <Preloader />
                </div>
              )}
            </InputTokens>
          </div>
        </Dropdown>
      )}
    </HeadlessMultiSelect>
  );
}
