import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {
  boolean,
  constant,
  ExtractSchemaType,
  object,
  oneOf,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {coordinatesSchema} from 'domain/officeMap/coordinates/model';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export enum LandmarkType {
  ROOM = 'room',
  FOOD = 'food',
  WC = 'WC',
  PRINTER = 'printer',
  ENTRANCE = 'entrance',
  WARDROBE = 'wardrobe',
  HELP = 'help',
  FUN = 'fun',
  SEAT_NUMBER = 'seatNumber',
}

export const landmarkConfigSchema = object({
  ...arrayToObject(
    getEnumValues(LandmarkType),
    (elem) => elem,
    () => optionalWithDefault(false, boolean()),
  ),
});

export type LandmarkConfig = ExtractSchemaType<typeof landmarkConfigSchema>;

export const emojiLandmarkIdSchema = idSchema;

export const emojiLandmarks: LandmarkType[] = [
  LandmarkType.ROOM,
  LandmarkType.FOOD,
  LandmarkType.WC,
  LandmarkType.FUN,
  LandmarkType.PRINTER,
  LandmarkType.WARDROBE,
  LandmarkType.HELP,
];

export const emojiLandmarkSchema = object({
  id: emojiLandmarkIdSchema,
  coordinates: coordinatesSchema,
  icon: string(),
  type: oneOf(...emojiLandmarks.map((type) => constant(type))),
  disabled: optional(boolean()),
  name: optional(string()),
  description: optional(string()),
  link: optional(string()),
});

export type EmojiLandmark = ExtractSchemaType<typeof emojiLandmarkSchema>;
