import {mimeType} from '@joomcode/deprecated-utils/file/mime';
import {identity} from '@joomcode/deprecated-utils/function';
import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {Toggle} from '@joomcode/joom-ui/Toggle';
import {HintIcon} from 'components/ui/HintIcon';
import {FieldFloat, FieldFloatValue} from 'components/widgets/fields/FieldFloat';
import {MultipleFilesUpload} from 'components/widgets/MultipleFilesUpload';
import {Receipt} from 'domain/compensations/receipt/model';
import {Currency} from 'domain/currency/model';
import {CurrencySelect} from 'domain/currency/widgets/Select';
import {FieldValidator} from 'final-form';
import {FileMeta} from 'models/system/fileMeta';
import React, {useCallback, useMemo} from 'react';
import {Field, Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {errorMessages, fieldLabels, messages} from './messages';
import styles from './styles.css';

const ACCEPTED_MIME_TYPES = [mimeType.image.jpeg, mimeType.image.png, mimeType.pdf];
const MAX_FILE_SIZE = fileSizeUtils.createMeasure(8, FileSizeUnit.Megabyte);

type Props = {
  onClose: () => void;
  onSubmit: FormProps<Receipt>['onSubmit'];
};

export function CompensationReceiptForm({onClose, onSubmit}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const lostReceiptSwitchId = useMemo(uuid, []);
  const validateFiles: FieldValidator<FileMeta[]> = useCallback(
    (value, allValues: Record<string, unknown>) =>
      // eslint-disable-next-line dot-notation
      !allValues['lostReceipt'] && value.length === 0 ? intl.formatMessage(errorMessages.emptyFiles) : undefined,
    [intl],
  );

  return (
    <Form<Receipt> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid, values}) => (
        <Dialog ariaLabel={intl.formatMessage(messages.dialogAriaLabel)} isOpen onClose={onClose} width={600}>
          <Dialog.Header>{intl.formatMessage(messages.header)}</Dialog.Header>
          <Dialog.Body withDefaultPadding>
            <div className={styles.notice}>{intl.formatMessage(messages.notice)}</div>
            <form onSubmit={handleSubmit} id={formId}>
              <Field<string> name='name' parse={identity} validate={validateFilled}>
                {({input, meta}) => (
                  <FormControl
                    disabled={submitting}
                    error={getFieldErrorText(meta, {intl})}
                    label={intl.formatMessage(fieldLabels.name)}
                    required
                  >
                    {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                  </FormControl>
                )}
              </Field>
              <div className={styles.money}>
                <div className={styles.amount}>
                  <Field<FieldFloatValue> name='money.amount' subscription={{error: true, touched: true}}>
                    {({meta}) => (
                      <FormControl
                        disabled={submitting}
                        error={getFieldErrorText(meta, {intl})}
                        label={intl.formatMessage(fieldLabels.amount)}
                        required
                      >
                        {(formControlProps) => (
                          <FieldFloat
                            {...formControlProps}
                            disabled={submitting}
                            name='money.amount'
                            validate={validateFilled}
                          />
                        )}
                      </FormControl>
                    )}
                  </Field>
                </div>
                <div className={styles.currency}>
                  <Field<Currency> name='money.currency' parse={identity} validate={validateFilled}>
                    {({input, meta}) => (
                      <FormControl
                        disabled={submitting}
                        error={getFieldErrorText(meta, {intl})}
                        label={intl.formatMessage(fieldLabels.currency)}
                        required
                      >
                        {(formControlProps) => (
                          <CurrencySelect disabled={submitting} {...formControlProps} {...input} />
                        )}
                      </FormControl>
                    )}
                  </Field>
                </div>
              </div>
              <Field<boolean> name='lostReceipt' type='checkbox'>
                {({input: {value, ...input}}) => (
                  <div className={styles.switchField}>
                    <Toggle defaultChecked={value} disabled={submitting} id={lostReceiptSwitchId} {...input} />
                    <label htmlFor={lostReceiptSwitchId} className={styles.switchLabel}>
                      {intl.formatMessage(fieldLabels.lostReceipt)}
                      <HintIcon>{intl.formatMessage(messages.lostReceiptHint)}</HintIcon>
                    </label>
                  </div>
                )}
              </Field>
              <div className={styles.receiptFiles}>
                <Field<FileMeta[]> name='receiptFiles' parse={identity} validate={validateFiles}>
                  {({input, meta}) => {
                    const errorMessage = getFieldErrorText(meta, {intl});
                    return (
                      <>
                        <MultipleFilesUpload
                          acceptedMimeTypes={ACCEPTED_MIME_TYPES}
                          attachButtonLabel={intl.formatMessage(messages.attachReceiptsButton)}
                          attachMoreButtonLabel={intl.formatMessage(messages.attachMoreReceiptsButton)}
                          disabled={submitting || values.lostReceipt}
                          maxFileSize={MAX_FILE_SIZE}
                          onChange={input.onChange}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                        />
                        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                      </>
                    );
                  }}
                </Field>
              </div>
              <Field<string>
                name='comment'
                parse={identity}
                validate={values.lostReceipt ? validateFilled : undefined}
                // force re-render to update validation callback, see https://final-form.org/docs/react-final-form/types/FieldProps#validate
                key={values.lostReceipt ? 1 : 0}
              >
                {({input, meta}) => (
                  <FormControl
                    disabled={submitting}
                    error={values.lostReceipt ? getFieldErrorText(meta, {intl}) : undefined}
                    label={intl.formatMessage(fieldLabels.comment)}
                    hint={
                      values.lostReceipt
                        ? intl.formatMessage(messages.commentHintForLostReceipt)
                        : intl.formatMessage(messages.commentHint)
                    }
                    required={values.lostReceipt}
                  >
                    {(formControlProps) => <Textarea {...formControlProps} {...input} disabled={submitting} />}
                  </FormControl>
                )}
              </Field>
            </form>
          </Dialog.Body>
          <Dialog.Footer align='right'>
            <ButtonGroup spaced size='l'>
              <Button kind='text' intent='neutral' onClick={onClose}>
                {intl.formatMessage(messages.cancelButton)}
              </Button>
              <Button
                form={formId}
                kind='primary'
                intent='primary'
                type='submit'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.submitButton)}
              </Button>
            </ButtonGroup>
          </Dialog.Footer>
        </Dialog>
      )}
    </Form>
  );
}
