import {getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {useOffer} from 'domain/offer/hooks/useOffer';
import {useOfferCompensations} from 'domain/offer/hooks/useOfferCompensations';
import {useOfferStockOptions} from 'domain/offer/hooks/useOfferStockOptions';
import {useOfferUpdatePossibility} from 'domain/offer/hooks/useOfferUpdatePossibility';
import {offerIdSchema} from 'domain/offer/model';
import {CorruptedDataPanel} from 'domain/offer/widgets/CorruptedDataPanel';
import {OfferUpdateForm} from 'domain/offer/widgets/UpdateForm';
import {generalMessages} from 'i18n/messages/general';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/offer/titles';
import {offerUrls} from 'routes/offer/urls';

export function OfferUpdatePage() {
  const intl = useIntl();
  const {id} = useTypedParams({id: offerIdSchema});

  const {data: offerData, dataState: offerDataState, clientErrorStatus: offerError} = useOffer(id);
  const {canUpdateDetails} = useOfferUpdatePossibility(offerData);
  const {
    data: offerCompensationsData,
    dataState: offerCompensationsDataState,
    clientErrorStatus: offerCompensationsError,
  } = useOfferCompensations(offerData);
  const {
    data: offerStockOptionsData,
    dataState: offerStockOptionsDataState,
    clientErrorStatus: offerStockOptionsError,
  } = useOfferStockOptions(offerData);

  const dataState = getConsolidatedDataState(offerDataState, offerCompensationsDataState, offerStockOptionsDataState);

  if (!id) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler
      data={
        offerData &&
        offerCompensationsData &&
        offerStockOptionsData && {
          offer: offerData,
          offerCompensation: offerCompensationsData,
          offerStockOptions: offerStockOptionsData,
        }
      }
      entityError={offerError || offerCompensationsError || offerStockOptionsError}
      state={dataState}
    >
      {({offer, offerCompensation, offerStockOptions}) => {
        const lastVersion = offer.versions.at(-1);
        const compensation = lastVersion && offerCompensation[lastVersion.id];
        const stockOptions = lastVersion && offerStockOptions[lastVersion.id];

        if (!canUpdateDetails) {
          return <NotFoundPage />;
        }

        if (!lastVersion) {
          return <CorruptedDataPanel />;
        }

        return (
          <Page
            title={intl.formatMessage(pageTitles.update)}
            breadcrumbs={[
              {
                title: intl.formatMessage(pageTitles.offers),
                href: offerUrls.root(),
              },
              {
                title: intl.formatMessage(generalMessages.fullName, {
                  firstName: offer.general.firstName,
                  lastName: offer.general.lastName,
                }),
                href: offerUrls.offer({id}),
              },
            ]}
          >
            <OfferUpdateForm
              offerId={offer.id}
              employmentInfo={lastVersion.employmentInfo}
              compensation={compensation}
              stockOptions={stockOptions}
            />
          </Page>
        );
      }}
    </EntityPageStateHandler>
  );
}
