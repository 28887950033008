import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'New business trip request',
    description: '[title] Create compensation request: page title',
  },
  noLegalEntityTitle: {
    defaultMessage: 'We’re sorry :(',
    description: '[title] Create compensation request: error page title',
  },
  noLegalEntityError: {
    defaultMessage:
      'Compensation services are not available for you. If you are planning a business trip, please contact HR Love&Care and they will help you out.',
    description: 'Create compensation request: error message',
  },
});
