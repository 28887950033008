import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {LevelCode} from 'domain/levelCode/model';

export type GetRecommendedJobTitleParams = {
  levelCode: LevelCode;
  userFunction: string;
};

export const getRecommendedJobTitle = async (params: GetRecommendedJobTitleParams): Promise<string> => {
  const {data} = await intranetApi.get('/v1/secure/compensationReviewRequests/getRecommendedJobTitle', {params});

  return string().runWithException(data);
};
