import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Permission} from 'domain/permission/model';
import {Team, teamSchema} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';

export const deleteGeneric =
  (endpoint: string) =>
  async ({id}: {id: TeamId}): Promise<Team> => {
    const {data} = await intranetApi.post(endpoint, undefined, {
      params: {id},
    });

    return teamSchema.runWithException(data);
  };

export const deleteDefault = deleteGeneric('/v1/teams/delete');
export const deleteAsAdmin = deleteGeneric('/v1/teams/deleteAsAdmin');

export const deleteTeam = createPermissionDependentHandler(
  [{permission: Permission.TEAM_ADMIN_WRITE, handler: deleteAsAdmin}],
  deleteDefault,
);
