const LOGIN_MIN_LEN = 2;
const LOGIN_MAX_LEN = 64;
const badCharRegExp = /[^a-zA-Z\d_.]/;
const badEdgeCharRegExp = /[^a-zA-Z\d]/;
const badCharPairRegExp = /[^a-zA-Z\d]{2}/;
const capsRegExp = /[A-Z]/;
const letterRegExp = /[a-z]/;

export enum LoginFormattingError {
  BAD_CHAR = 'badChar',
  BAD_CHAR_PAIR = 'badCharPair',
  BAD_END_CHAR = 'badEndChar',
  BAD_START_CHAR = 'badStartChar',
  CAPS_FOUND = 'capsFound',
  NO_LETTERS = 'noLetters',
  TOO_LONG = 'tooLong',
  TOO_SHORT = 'tooShort',
}

export const validateLoginFormat = (value: string): LoginFormattingError | undefined => {
  const {length} = value;
  if (badEdgeCharRegExp.test(value.charAt(0))) {
    return LoginFormattingError.BAD_START_CHAR;
  }
  if (capsRegExp.test(value)) {
    return LoginFormattingError.CAPS_FOUND;
  }
  if (badCharRegExp.test(value)) {
    return LoginFormattingError.BAD_CHAR;
  }
  if (length < LOGIN_MIN_LEN) {
    return LoginFormattingError.TOO_SHORT;
  }
  if (badCharPairRegExp.test(value)) {
    return LoginFormattingError.BAD_CHAR_PAIR;
  }
  if (!letterRegExp.test(value)) {
    return LoginFormattingError.NO_LETTERS;
  }
  if (length > LOGIN_MAX_LEN) {
    return LoginFormattingError.TOO_LONG;
  }
  if (badEdgeCharRegExp.test(value.charAt(length - 1))) {
    return LoginFormattingError.BAD_END_CHAR;
  }
  return undefined;
};
