import React from 'react';

export const SEAT_TEMPLATE_ID = 'workplaceSeat';

export function SeatSvgSample() {
  return (
    <g id={SEAT_TEMPLATE_ID}>
      <rect width='19.057' height='9.078' />
      <circle cx='9.5' cy='-4.5' r='3.5' />
    </g>
  );
}
