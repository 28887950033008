import {Menu as MenuComponent} from './Menu';
import {MenuItem} from './Item';
import {MenuBar} from './MenuBar';
import {MenuSeparator} from './Separator';
import {MenuSubmenu} from './Submenu';

export type {MenuProps, MenuLocator} from './Menu';
export type {MenuButtonHTMLProps} from 'reakit/Menu';

/* eslint-disable @typescript-eslint/naming-convention */
export const Menu = Object.assign(MenuComponent, {
  Bar: MenuBar,
  Item: MenuItem,
  Separator: MenuSeparator,
  Submenu: MenuSubmenu,
});
