import {ReactComponent as CrossIcon} from '@joomcode/joom-ui/icons/core/cross.svg';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  onClick: () => void;
};

export function ValuesReset({onClick}: Props) {
  const intl = useIntl();

  return (
    <TooltipFaq content={intl.formatMessage(messages.tooltip)}>
      <button className={styles.root} onClick={onClick}>
        <CrossIcon />
      </button>
    </TooltipFaq>
  );
}
