import {NewValue} from 'domain/compensationReviewRequest/widgets/NewValue';
import {OldValue} from 'domain/compensationReviewRequest/widgets/OldValue';
import React from 'react';

type Props = {
  oldValue: React.ReactNode;
  newValue: React.ReactNode;
  isEqual?: boolean;
};

export function Value({oldValue, newValue, isEqual}: Props) {
  if (isEqual || oldValue === newValue) {
    return newValue;
  }

  return (
    <div>
      <NewValue value={newValue} />
      <OldValue value={oldValue} />
    </div>
  );
}
