import React, {useMemo} from 'react';
import {isNotNullish} from '@joomcode/deprecated-utils/function/isNotNullish';
import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {FormattedMessage} from 'react-intl';
import {LabelRenderProps} from '../../types';
import {listOptionsUtils} from '../listOptions';
import {SelectFilterOptions} from './types';

export function SelectLabel<T extends string>({
  name,
  options,
  value,
  ValueWrapper,
}: LabelRenderProps<T, SelectFilterOptions<T>>) {
  const valueLabelMap = useMemo(() => listOptionsUtils.getLabelByValueMap(options.options), [options.options]);
  const label = useMemo(() => (value ? valueLabelMap[value] : undefined), [value, valueLabelMap]);

  if (isNullish(value)) {
    return <>{name}</>; // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <FormattedMessage
      defaultMessage='{filterName} is {filterValue}'
      description='DataFilter single value label, e.g. “Color is red”'
      id='datafilter-select-label-value'
      values={{
        filterName: name,
        filterValue: isNotNullish(label) ? <ValueWrapper>{label}</ValueWrapper> : null,
      }}
    />
  );
}
