import {intranetApi} from 'apiClient';
import {CompensationRequestId} from 'domain/compensations/request/model';
import {FileMeta} from 'models/system/fileMeta';

type Params = {
  compensationRequestId: CompensationRequestId;
  fileId: FileMeta['id'];
};

export const buildFileDownloadLink = (params: Params) =>
  intranetApi.getUri({
    params,
    url: `${intranetApi.defaults.baseURL}/v1/compensations/requests/downloadFile`,
  });

export const buildFileDownloadLinkForAdmin = (params: Params) =>
  intranetApi.getUri({
    params,
    url: `${intranetApi.defaults.baseURL}/v1/compensations/admin/requests/downloadFile`,
  });

export const buildAllRequestsDownloadLinkForAdmin = () =>
  intranetApi.getUri({
    url: `${intranetApi.defaults.baseURL}/v1/compensations/admin/requests/downloadBusinessTripRequestsFile`,
  });
