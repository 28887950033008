import {PrimitiveType} from '@joomcode/deprecated-utils/types';
import {IntlShape, MessageDescriptor} from 'react-intl';
import {RootValidationError, ValidationErrorCode} from '../errors';
import {errorFormatters} from '../formatters';
import {errorMessages} from '../messages';
import {ValidationError, ValidationFormatter} from '../types';

export const formatError = (
  error: RootValidationError,
  intl: IntlShape,
  extraMessages?: {[key in ValidationErrorCode]: MessageDescriptor},
  extraFormatters?: {[key in ValidationErrorCode]: ValidationFormatter<ValidationError<unknown>>},
): string => {
  const messages = {...errorMessages, ...extraMessages};
  const formatters: {[key in ValidationErrorCode]: ValidationFormatter<ValidationError<unknown>>} = {
    ...errorFormatters,
    ...extraFormatters,
  };
  const formatter = formatters[error.code];

  if (formatter) {
    return formatter(error, intl);
  }

  return intl.formatMessage(messages[error.code], error.options as Record<string, PrimitiveType>);
};
