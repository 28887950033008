import Downshift, {DownshiftState, StateChangeOptions} from 'downshift';

export function stateReducer(state: DownshiftState<unknown>, changes: StateChangeOptions<unknown>) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem: {
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: true,
        inputValue: '',
      };
    }

    case Downshift.stateChangeTypes.changeInput: {
      return {
        ...changes,
        highlightedIndex: 0,
      };
    }

    default: {
      return changes;
    }
  }
}
