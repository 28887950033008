import {boolean, dateFromString, ExtractSchemaType, object} from '@joomcode/deprecated-utils/jsonValidation';
import {offerEmploymentInfoSchema} from 'domain/offer/model/employmentInfo';
import {idSchema} from 'models/system/id';

export const offerVersionIdSchema = idSchema;
export const offerVersionSchema = object({
  id: offerVersionIdSchema,
  createdTime: dateFromString(),
  employmentInfo: offerEmploymentInfoSchema,
  hasFurtherSalary: boolean(),
});

export type OfferVersionId = ExtractSchemaType<typeof offerVersionIdSchema>;
export type OfferVersion = ExtractSchemaType<typeof offerVersionSchema>;
