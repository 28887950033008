export enum StringValidatorErrorCode {
  STRING_INVALID = 'string_invalid',
  STRING_LENGTH_OUT_OF_RANGE = 'string_length_out_of_range',
  STRING_LENGTH_LESS_MIN = 'string_length_less_min',
  STRING_LENGTH_GREATER_MAX = 'string_length_greater_max',
  STRING_REG_EXP_NOT_MATCH = 'string_reg_exp_not_match',
}

export type StringValidatorOptions = {
  minLength?: number;
  maxLength?: number;
  regExp?: RegExp;
};
