import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {TimeOff} from 'domain/timeOff/userTimeOff/model';
import {isTimeOffVacation, sortTimeOffs} from 'domain/timeOff/userTimeOff/model/utils';
import {TimeOffNote} from 'domain/timeOff/userTimeOff/widgets/Note';
import React, {useMemo} from 'react';

const NEAREST_DAYS = 30;

type Props = {
  timeOffs: TimeOff[] | undefined;
  longPhrase?: boolean;
};

export function NearestTimeOffs({timeOffs = [], longPhrase = false}: Props) {
  const vacationTimeOffs = useMemo(() => timeOffs.filter(isTimeOffVacation), [timeOffs]);
  const sortedNearestTimeOffs = useMemo(
    () =>
      sortTimeOffs(
        vacationTimeOffs.filter((timeOff) => differenceInCalendarDays(timeOff.start, new Date()) <= NEAREST_DAYS),
      ),
    [vacationTimeOffs],
  );

  return (
    <>
      {sortedNearestTimeOffs.map((timeOff) => (
        <TimeOffNote
          timeOff={timeOff}
          key={`${timeOff.start.toUTCString()}-${timeOff.end.toUTCString()}`}
          longPhrase={longPhrase}
        />
      ))}
    </>
  );
}
