import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {ListedProperty} from 'components/ui/ListedProperty';
import {MultilineText} from 'components/ui/MultilineText';
import {CompensationReviewRequestDraft} from 'domain/compensationReviewRequest/model/draft';
import {formatType} from 'domain/compensationReviewRequest/model/type/formatter';
import {generalMessages} from 'i18n/messages/general';
import React from 'react';
import {useIntl} from 'react-intl';
import {labels, messages} from './messages';
import styles from './styles.css';

type Props = {
  draft: CompensationReviewRequestDraft;
};

export function CompensationReviewRequestDraftDetails({draft}: Props) {
  const intl = useIntl();
  const {effectiveDate, reason, user, type} = draft;
  const {firstName, lastName, login} = user;

  return (
    <Panel withPadding>
      <div className={styles.root}>
        <div className={styles.left}>
          <ListedProperty label={intl.formatMessage(labels.effectiveDate)}>
            <HumanDate format='short' value={effectiveDate} utc />
          </ListedProperty>
          <ListedProperty label={intl.formatMessage(labels.user)}>
            {intl.formatMessage(messages.user, {
              fullName: intl.formatMessage(generalMessages.fullName, {
                firstName,
                lastName,
              }),
              login,
            })}
          </ListedProperty>
        </div>
        <div>
          <ListedProperty label={intl.formatMessage(labels.reason)}>
            <MultilineText text={reason} />
          </ListedProperty>
          <ListedProperty label={intl.formatMessage(labels.type)}>{formatType(type, intl)}</ListedProperty>
        </div>
      </div>
    </Panel>
  );
}
