import {oneTimeBonusRecordsApi} from 'domain/oneTimeBonusRecord/api';
import {
  createOneTimeBonusRecordFx,
  deleteOneTimeBonusRecordFx,
  getAnyOneTimeBonusRecordsFx,
  getMyOneTimeBonusRecordsFx,
  getSubordinateOneTimeBonusRecordsFx,
  updateOneTimeBonusRecordFx,
} from '.';

createOneTimeBonusRecordFx.use(oneTimeBonusRecordsApi.create);
deleteOneTimeBonusRecordFx.use(oneTimeBonusRecordsApi.deleteRecord);
getAnyOneTimeBonusRecordsFx.use(oneTimeBonusRecordsApi.getAny);
getMyOneTimeBonusRecordsFx.use(oneTimeBonusRecordsApi.getMy);
getSubordinateOneTimeBonusRecordsFx.use(oneTimeBonusRecordsApi.getSubordinate);
updateOneTimeBonusRecordFx.use(oneTimeBonusRecordsApi.update);
