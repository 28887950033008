import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'New target bonus',
    description: 'Compensation review request form: section title',
  },
});

export const labels = defineMessages({
  startDate: {
    defaultMessage: 'Start date',
    description: 'Compensation review request: regular bonus form: field label',
  },
  endDate: {
    defaultMessage: 'End date',
    description: 'Compensation review request: regular bonus form: field label',
  },
  type: {
    defaultMessage: 'Calculation approach',
    description: 'Compensation review request: regular bonus form: field label',
  },
  amount: {
    defaultMessage: 'Amount',
    description: 'Compensation review request: regular bonus form: field label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'Compensation review request: regular bonus form: field label',
  },
  frequency: {
    defaultMessage: 'Frequency',
    description: 'Compensation review request: regular bonus form: field label',
  },
  comment: {
    defaultMessage: 'Comment',
    description: 'Compensation review request: regular bonus form: field label',
  },
});
