import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {UserFullInfoAvailability} from 'domain/userFullInfoRecord/model/availability';
import {UserFullInfoColumnTreeItem} from 'domain/userFullInfoRecord/model/columns/tree';
import {getUserFullInfoAvailabilityFx} from 'domain/userFullInfoRecord/stores/columns';
import {$userFullInfoColumns} from 'domain/userFullInfoRecord/stores/columns/state';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  availability: UserFullInfoAvailability;
  availableColumns: UserFullInfoColumnTreeItem[];
  dataState: DataState;
};

export function useAvailability(): ReturnShape {
  const {availability, availableColumns, dataState} = useStore($userFullInfoColumns);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      getUserFullInfoAvailabilityFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return {
    availability,
    availableColumns,
    dataState,
  };
}
