import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {array, ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {stockOptionAgreementRecordSchema} from 'domain/stockOption/agreement/model/record';
import {StockOptionBuyout} from 'domain/stockOption/buyout/model';
import {stockOptionBuyoutRecordSchema} from 'domain/stockOption/buyout/model/record';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {BulkUploadResultStatus} from './status';

export const stockOptionBulkUploadResultIdSchema = idSchema;
export const stockOptionBulkUploadResultSchema = object({
  id: stockOptionBulkUploadResultIdSchema,
  status: oneOfEnum(BulkUploadResultStatus),
  error: optional(string()),
  agreementRecords: optionalWithDefault([], array(stockOptionAgreementRecordSchema)),
  buyoutRecords: optionalWithDefault([], array(stockOptionBuyoutRecordSchema)),
});

export type StockOptionBulkUploadResultId = ExtractSchemaType<typeof stockOptionBulkUploadResultIdSchema>;
export type StockOptionBulkUploadResult = ExtractSchemaType<typeof stockOptionBulkUploadResultSchema>;
export type StockOptionBulkUploadInput = {
  id: StockOptionBulkUploadResultId;
  agreementRecords?: StockOptionAgreement[];
  buyoutRecords?: StockOptionBuyout[];
};
