import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {Panel} from '@joomcode/joom-ui/Panel';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {Team} from 'domain/team/model';
import {deleteTeamFx} from 'domain/team/stores/main';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  hasSubteams: boolean;
  team: Team;
};

export function TeamDeletionPanel({hasSubteams, team}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const onDeleteClick = useCallback(
    () =>
      confirm(
        {
          intent: 'negative',
          title: intl.formatMessage(messages.confirmTitle),
          text: intl.formatMessage(messages.confirmText, {teamName: team.name}),
          confirmationText: intl.formatMessage(messages.confirmButton),
          onConfirm: () => deleteTeamFx({id: team.id}).catch(toaster.interceptThenThrowError),
        },
        intl,
      ),
    [history, team],
  );
  return (
    <Panel>
      <Panel.Content withPadding>
        <TooltipFaq
          content={
            <TooltipFaq.Paragraph>
              {intl.formatMessage(messages.deletionWithSubteams, {
                slackChannel: (
                  <StyledExternalLink href='https://joom-team.slack.com/archives/CNJ4JJ1NF' colored>
                    #joom-space-inbox
                  </StyledExternalLink>
                ),
              })}
            </TooltipFaq.Paragraph>
          }
          enabled={hasSubteams}
        >
          {/* Disabled button suppresses mouseover event; using wrapper as a workaround */}
          <span className={styles.buttonWrap}>
            <Button intent='negative' kind='primary' disabled={hasSubteams} onClick={onDeleteClick} size='m'>
              {intl.formatMessage(messages.btnDelete)}
            </Button>
          </span>
        </TooltipFaq>
      </Panel.Content>
    </Panel>
  );
}
