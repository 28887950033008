import {getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Tabs} from '@joomcode/joom-ui/Tabs';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {Permission} from 'domain/permission/model';
import {useBalanceRecords} from 'domain/timeOff/balanceRecord/hooks/useBalanceRecords';
import {useBalanceRecordsReadAccess} from 'domain/timeOff/balanceRecord/hooks/useBalanceRecordsReadAccess';
import {TimeOffBalanceRecordType} from 'domain/timeOff/balanceRecord/model/type';
import {BalanceRecordForm} from 'domain/timeOff/balanceRecord/widgets/Form';
import {BalanceRecordsTable} from 'domain/timeOff/balanceRecord/widgets/Table';
import {useUserLoader} from 'domain/user/hooks/useUserLoader';
import {generalMessages} from 'i18n/messages/general';
import {NotFoundPage} from 'pages/NotFound';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {pageTitles} from 'routes/users/titles';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';
import {messages} from './messages';
import styles from './styles.css';

enum TabId {
  VACATION = 'vacation',
  REMOTE_WORK = 'remoteWork',
}

const typeByTab: Record<TabId, TimeOffBalanceRecordType> = {
  [TabId.VACATION]: TimeOffBalanceRecordType.VACATION,
  [TabId.REMOTE_WORK]: TimeOffBalanceRecordType.REMOTE_WORK,
};

export function UserTimeOffBalancePage() {
  const acl = useAcl();
  const intl = useIntl();
  const {login} = useParams<{login: string}>();
  const user = useUserLoader(login);
  const officePolicyFeature = useFeature(Feature.OFFICE_POLICY);
  const {open, close, isOpen} = usePopupState();
  const {vacationBalanceRecords, remoteWorkBalanceRecords, dataState} = useBalanceRecords(user.data?.id);
  const readAccess = useBalanceRecordsReadAccess(user.data?.id);
  const tabs = useMemo(() => {
    return [
      {
        id: TabId.VACATION,
        label: intl.formatMessage(messages.tabVacation),
        content: <BalanceRecordsTable records={vacationBalanceRecords} />,
      },
      {
        id: TabId.REMOTE_WORK,
        label: intl.formatMessage(messages.tabRemoteWork),
        content: <BalanceRecordsTable records={remoteWorkBalanceRecords} />,
      },
    ];
  }, [intl, vacationBalanceRecords, remoteWorkBalanceRecords]);
  const [activeTabId, setActiveTabId] = useState<TabId>(TabId.VACATION);
  const currentBalance = useMemo(() => {
    const records =
      officePolicyFeature.isAvailable && activeTabId === TabId.REMOTE_WORK
        ? remoteWorkBalanceRecords
        : vacationBalanceRecords;

    return records.length > 0 ? records[0].totalBalance : 0;
  }, [activeTabId, officePolicyFeature.isAvailable, vacationBalanceRecords, remoteWorkBalanceRecords]);

  if (!readAccess) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler
      data={vacationBalanceRecords}
      entityError={user.error}
      state={getConsolidatedDataState(dataState, user.dataState)}
    >
      {() => (
        <Page
          title={intl.formatMessage(
            officePolicyFeature.isAvailable ? pageTitles.timeOffBalance : pageTitles.vacationBalance,
          )}
          actions={
            !officePolicyFeature.isAvailable &&
            acl.hasPermission(Permission.TIME_OFF_BALANCE_RECORD_WRITE) && (
              <Button kind='primary' intent='primary' size='m' onClick={open}>
                {intl.formatMessage(messages.buttonChange)}
              </Button>
            )
          }
          breadcrumbs={[
            {
              title: intl.formatMessage(generalMessages.fullName, {
                firstName: user.data?.firstName,
                lastName: user.data?.lastName,
              }),
              href: usersUrls.user({login}),
            },
          ]}
        >
          <Panel>
            {officePolicyFeature.isAvailable ? (
              <Tabs<TabId>
                renderActiveTabPanelOnly
                activeTabId={activeTabId}
                onChange={setActiveTabId}
                tabs={tabs}
                addonRight={
                  acl.hasPermission(Permission.TIME_OFF_BALANCE_RECORD_WRITE) && (
                    <div className={styles.toolbar}>
                      <Button kind='primary' intent='neutral' size='m' onClick={open}>
                        {intl.formatMessage(messages.buttonChangeBalance)}
                      </Button>
                    </div>
                  )
                }
              />
            ) : (
              <BalanceRecordsTable records={vacationBalanceRecords} />
            )}
            {isOpen && user.data && (
              <BalanceRecordForm
                currentBalance={currentBalance}
                onClose={close}
                userId={user.data.id}
                type={typeByTab[activeTabId]}
              />
            )}
          </Panel>
        </Page>
      )}
    </EntityPageStateHandler>
  );
}
