import {DataState, getConsolidatedDataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {usePrevious} from '@joomcode/deprecated-utils/react/usePrevious';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {checkIsMine} from 'domain/resource/api/checkIsMine';
import {Resource, ResourceId} from 'domain/resource/model';
import {getResourceByIdFx} from 'domain/resource/stores/main';
import {$resources} from 'domain/resource/stores/main/state';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';
import {checkOwnersAreSame} from 'utils/jdm/checkOwnersAreSame';

type ReturnShape = {
  resource?: Resource;
  dataState: DataState;
  clientErrorStatus?: ClientErrorStatus;
  isSelfOwner: boolean;
};

export function useResource(id?: ResourceId): ReturnShape {
  const [clientErrorStatus, setClientErrorStatus] = useState<ClientErrorStatus>();
  const data = useStoreMap({
    store: $resources,
    keys: [id],
    fn: ({byId}, [resourceId]) => (resourceId && byId[resourceId]) || null,
  });
  const dataState = useStoreMap({
    store: $resources,
    keys: [id],
    fn: ({byIdState}, [resourceId]) => (resourceId && byIdState[resourceId]) || DataState.IDLE,
  });
  const previousData = usePrevious(data);
  const checkOwnership = useAsyncTask<[ResourceId], boolean>(
    () => (id ? checkIsMine(id).catch(toaster.interceptThenThrowError) : Promise.resolve(false)),
    [id],
  );

  useEffect(() => {
    setClientErrorStatus(undefined);

    if (id && !isLoadingOrLoaded(dataState)) {
      getResourceByIdFx(id).catch((error: Error) => {
        if (error instanceof ClientError && error.status === ClientErrorStatus.NOT_FOUND) {
          setClientErrorStatus(error.status);
        } else {
          toaster.error(error.message);
        }
      });
    }

    if (id) {
      checkOwnership.perform(id);
    }
  }, [id]);

  // if data was updated we should double-check the ownership
  useEffect(() => {
    if (id && data && previousData && !checkOwnersAreSame(data, previousData)) {
      checkOwnership.perform(id);
    }
  }, [data]);

  return {
    clientErrorStatus,
    dataState: getConsolidatedDataState(dataState, checkOwnership.dataState),
    isSelfOwner: checkOwnership.result ?? false,
    resource: data ?? undefined,
  };
}
