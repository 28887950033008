import React from 'react';
import {FormattedMessage} from 'react-intl';
import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {LabelRenderProps} from '../../types';
import {messages} from './messages';

export function BooleanLabel({value, name, ValueWrapper}: LabelRenderProps<boolean>) {
  if (isNullish(value)) {
    return <>{name}</>; // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <FormattedMessage
      defaultMessage='{filterName} {filterValue}'
      description='Boolean Filter label, e.g. "Active Yes"'
      id='datafilter-boolean-label'
      values={{
        filterName: name,
        filterValue: (
          <ValueWrapper>
            <FormattedMessage {...(value ? messages.yes : messages.no)} />
          </ValueWrapper>
        ),
      }}
    />
  );
}
