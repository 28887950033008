import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@joomcode/joom-ui/setup';
import {ToastContainer} from '@joomcode/joom-ui/Toast';
import {ErrorBoundary} from '@sentry/react';
import {$locale} from 'domain/locale/stores/main/state';
import {createBrowserHistory} from 'history';
import {loadLocale} from 'i18n';
import {loadIntlPolyfills} from 'i18n/polyfills';
import {IntlProvider} from 'i18n/provider';
import 'intl';
import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {Router} from 'react-router-dom';
import {Analytics} from 'services/analytics';
import {AnalyticsContext} from 'services/analytics/context';
import {initSentry} from 'services/sentry';
import 'stores';
import 'styles/theme/index.css';
import './global.css';
import {Root} from './Root';

export const history = createBrowserHistory();

const mountTarget = document.getElementById('content');

function renderError(error: Error) {
  if (mountTarget) {
    mountTarget.innerHTML = `<strong>${error.message}</strong><br><pre>${error.stack}</pre>`;
  }
}

async function initApplication() {
  const {locale} = $locale.getState();

  if (window.intranetEnv.APP_ENV === 'production') {
    initSentry(history);
  }

  const analytics = new Analytics(locale); // eslint-disable-line react/jsx-no-constructed-context-values

  await loadIntlPolyfills();
  await loadLocale(locale);

  const root = createRoot(mountTarget!);

  root.render(
    <ErrorBoundary>
      <AnalyticsContext.Provider value={analytics}>
        <IntlProvider>
          <Router history={history}>
            <Root />
          </Router>
          <ToastContainer />
        </IntlProvider>
      </AnalyticsContext.Provider>
    </ErrorBoundary>,
  );
}

try {
  initApplication();
} catch (error) {
  renderError(error);
}
