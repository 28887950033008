import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import {Button} from '@joomcode/joom-ui/Button';
import {Panel} from '@joomcode/joom-ui/Panel';
import {EmptyMessage} from 'components/ui/EmptyMessage';
import {PanelInstantSearch} from 'components/widgets/PanelInstantSearch';
import {TreeViewHeader} from 'components/widgets/TreeViewHeader';
import {Permission} from 'domain/permission/model';
import {User, UserId} from 'domain/user/model';
import {useTreeView} from 'hooks/useTreeView';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {UserSnapEvents, useUsersnapApi} from 'services/usersnap';
import {UsersTree} from '../Tree';
import {getAncestorIds} from './ancestorUtils';
import {messages} from './messages';
import {parseSearchWords, searchUsers} from './searchUtils';
import styles from './styles.css';

const EXPANDED_DEPTH = 1;

type UsersTreePanelProps = {
  onSearch: (searchQuery?: string) => void;
  searchQuery?: string;
  allUsersById: Record<UserId, User>;
  narrow?: boolean;
  selectedUserId?: UserId;
  showTerminatedUsersToggle?: boolean;
  linkToUserPane: (user: User) => string;
};

const TOGGLE_COUNT_FOR_USERSNAP_ACTIVATION = 3;

export function UsersTreePanel({
  onSearch,
  searchQuery = '',
  allUsersById,
  narrow,
  selectedUserId,
  linkToUserPane,
  showTerminatedUsersToggle = true,
}: UsersTreePanelProps) {
  const acl = useAcl();
  const intl = useIntl();
  const panelRef = useRef<HTMLDivElement>(null);
  const [showTerminated, setShowTerminated] = useState(false);
  const usersnapApi = useUsersnapApi();
  const [itemsToggled, setItemsToggled] = useState(0);
  const usersById = useMemo(
    () =>
      showTerminated
        ? pickBy(allUsersById, ({hireDate}) => hireDate && new Date(hireDate) < new Date())
        : pickBy(allUsersById, (user) => !user.isTerminated),
    [allUsersById, showTerminated],
  );
  const searchWords = useMemo(() => parseSearchWords(searchQuery), [searchQuery]);
  const users = useMemo(() => searchUsers(usersById, searchWords), [usersById, searchWords]);
  const selectedUserAncestorIds = useMemo(() => getAncestorIds(usersById, selectedUserId), [usersById, selectedUserId]);
  const toggleTerminatedUsersShowing = useCallback(
    () => setShowTerminated(!showTerminated),
    [showTerminated, setShowTerminated],
  );
  const isExpandedByDefault = useCallback((depth: number) => depth < EXPANDED_DEPTH, []);
  const isExpanded = useCallback(
    (depth: number, user: User, subitemIds: UserId[]) => {
      return (
        !!searchQuery ||
        depth < EXPANDED_DEPTH ||
        (subitemIds.length > 0 && selectedUserId && user?.id === selectedUserId) ||
        selectedUserAncestorIds.has(user?.id)
      );
    },
    [searchQuery, selectedUserId, selectedUserAncestorIds],
  );

  const {viewState, events} = useTreeView<User, UserId>(
    {
      items: users,
      getItemId: ({id}) => id,
      getParentId: ({managerId}) => managerId,
      prepareItemIds: (ids, subItemIdsById, itemsById) => {
        const filteredIds = ids.filter((id) => !itemsById[id].hidden);
        return sortBy(filteredIds, [
          (id) => !subItemIdsById[id].length,
          (id) => itemsById[id].isTerminated,
          (id) => itemsById[id].firstName.toLowerCase(),
        ]);
      },
      isExpanded: searchWords.length ? true : isExpanded,
      isExpandedByDefault,
    },
    [isExpandedByDefault, users, searchWords],
  );

  const onItemToggle = useCallback(
    ({id}: {id: string}) => {
      events.toggleItem({id});

      if (itemsToggled + 1 < TOGGLE_COUNT_FOR_USERSNAP_ACTIVATION) {
        setItemsToggled(itemsToggled + 1);
      }
      if (itemsToggled + 1 === TOGGLE_COUNT_FOR_USERSNAP_ACTIVATION) {
        usersnapApi?.logEvent(UserSnapEvents.USE_USER_TREE);
      }
    },
    [events.toggleItem, setItemsToggled, itemsToggled, usersnapApi],
  );

  useUpdateEffect(() => {
    panelRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [searchQuery]);

  return (
    <Panel stretchHeight ref={panelRef}>
      <div className={styles.header}>
        <TreeViewHeader
          title={intl.formatMessage(messages.title)}
          viewState={viewState}
          events={events}
          additionalActions={
            showTerminatedUsersToggle &&
            acl.hasPermission(Permission.USER_READ_ANY) && (
              <Button intent='neutral' kind='secondary' size='m' onClick={toggleTerminatedUsersShowing}>
                {intl.formatMessage(showTerminated ? messages.buttonHideTerminated : messages.buttonShowTerminated)}
              </Button>
            )
          }
        />
        <PanelInstantSearch
          onSearch={onSearch}
          placeholder={intl.formatMessage(messages.searchPlaceholder)}
          searchQuery={searchQuery}
        />
      </div>
      {viewState.itemsCount ? (
        <UsersTree
          searchWords={searchWords}
          usersById={usersById}
          viewState={viewState}
          onItemToggle={onItemToggle}
          narrow={narrow}
          selectedUserId={selectedUserId}
          linkToUserPane={linkToUserPane}
        />
      ) : (
        <Panel.Content withPadding>
          <EmptyMessage>{intl.formatMessage(messages.nothingFound)}</EmptyMessage>
        </Panel.Content>
      )}
    </Panel>
  );
}
