import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No records',
    description: 'Salary records table: empty message',
  },
});

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.START_DATE]: {
    defaultMessage: 'Start date',
    description: 'Salary table column name',
  },
  [ColumnId.END_DATE]: {
    defaultMessage: 'End date',
    description: 'Salary table column name',
  },
  [ColumnId.AMOUNT]: {
    defaultMessage: 'Salary (gross)',
    description: 'Salary table column name',
  },
  [ColumnId.FREQUENCY]: {
    defaultMessage: 'Frequency',
    description: 'Salary table column name',
  },
  [ColumnId.EMPLOYMENT_RATE]: {
    defaultMessage: 'Employment rate',
    description: 'Salary table column name',
  },
  [ColumnId.REGION]: {
    defaultMessage: 'Region',
    description: 'Salary table column name',
  },
  [ColumnId.REASON]: {
    defaultMessage: 'Reason',
    description: 'Salary table column name',
  },
  [ColumnId.COMMENT]: {
    defaultMessage: 'Comment',
    description: 'Salary table column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Status',
    description: 'Salary table column name',
  },
  [ColumnId.SPECIAL_ARRANGEMENTS]: {
    defaultMessage: 'Special arrangements',
    description: 'Salary table column name',
  },
});
