import {getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {Page} from '@joomcode/joom-ui/Page';
import cn from 'classnames';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {JoomersPageTabs} from 'components/widgets/JoomersPageTabs';
import {useOutstaffUsers} from 'domain/outstaffUser/hooks/useOutstaffUsers';
import {Permission} from 'domain/permission/model';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {UserId, User} from 'domain/user/model';
import {UserSidePane} from 'domain/user/widgets/SidePane';
import {UsersTreePanel} from 'domain/user/widgets/TreePanel';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router-dom';
import {outstaffUsersUrls} from 'routes/outstaffUsers/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import styles from './styles.css';

export function OutstaffUsersPage() {
  const acl = useAcl();
  const intl = useIntl();

  const {outstaffUsers, dataState: outstaffUsersDataState} = useOutstaffUsers();
  const {dataState: allUsersDataState, loginById, userByLogin} = useAllUsers();
  const [searchQuery, setSearchQuery] = useQueryParam('search', string(), '');
  const {search} = useLocation();
  const history = useHistory();
  const onSidePaneClose = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete('email');
    history.push({
      pathname: outstaffUsersUrls.outstaffUsers(),
      search: searchParams.toString(),
    });
  }, [search]);

  const email = new URLSearchParams(search).get('email') ?? undefined;

  const outstaffUsersWithManagersById: Record<UserId, User> = outstaffUsers.reduce<Record<UserId, User>>(
    (accumulator, outstaffUser) => {
      accumulator[outstaffUser.id] = outstaffUser;
      if (outstaffUser.managerId) {
        const managerLogin = loginById[outstaffUser.managerId];
        if (managerLogin) {
          const manager = userByLogin[managerLogin];
          if (manager) {
            accumulator[manager.id] = manager;
          }
        }
      }
      return accumulator;
    },
    {},
  );

  return (
    <PageStateHandler data={outstaffUsers} state={getConsolidatedDataState(outstaffUsersDataState, allUsersDataState)}>
      {() => {
        const user = email && Object.values(outstaffUsersWithManagersById).find((u) => u.email === email);
        return (
          <div className={cn(styles.layout, {[styles.withSidePanel]: !!user})}>
            <div className={styles.main}>
              <Page
                title={intl.formatMessage(messages.globalTitle)}
                headerContent={
                  acl.hasPermission(Permission.USER_READ_ACTIVE) ? <JoomersPageTabs activeTab='outstaff' /> : undefined
                }
              >
                <UsersTreePanel
                  narrow={false}
                  allUsersById={outstaffUsersWithManagersById}
                  searchQuery={searchQuery}
                  selectedUserId={undefined}
                  onSearch={setSearchQuery}
                  showTerminatedUsersToggle={false}
                  linkToUserPane={({email}: User) => outstaffUsersUrls.outstaffUsers({email})}
                />
                {user && <UserSidePane user={user} onPaneClose={onSidePaneClose} />}
              </Page>
            </div>
          </div>
        );
      }}
    </PageStateHandler>
  );
}
