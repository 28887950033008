import {formatError} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {FieldMultiDate} from 'domain/compensations/request/widgets/Form/FieldMultiDate';
import React from 'react';
import {useField, useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getArrayFieldError} from 'utils/form/arrayFieldValidation';
import {errorMessages} from './messages';

type Props = {
  name: string;
  label: React.ReactNode;
};

export function MultiDateFormControl({name, label}: Props) {
  const intl = useIntl();
  const {meta} = useField<string[]>(name);
  const {getFieldState, getRegisteredFields} = useForm();
  const fields = getRegisteredFields().filter((fieldName) => fieldName.startsWith(`${name}[`));
  const errors = fields.map((fieldName) => getFieldState(fieldName)?.error);
  const isAnyFieldTouched = fields.some((fieldName) => getFieldState(fieldName)?.touched);
  const isAnyFieldActive = fields.some((fieldName) => getFieldState(fieldName)?.active);
  const validationError = getArrayFieldError(errors);
  const validationErrorText =
    isAnyFieldTouched && !isAnyFieldActive && validationError && formatError(validationError, intl, {...errorMessages});

  return (
    <FormControl disabled={meta.submitting} label={label} error={validationErrorText} required>
      {(formControlProps) => <FieldMultiDate name={name} disabled={meta.submitting} {...formControlProps} />}
    </FormControl>
  );
}
