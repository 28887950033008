import {formatFileSize} from '@joomcode/joom-ui/FormattedFileSize';
import {getExtensionsByMimeType} from '@joomcode/deprecated-utils/file/mime';
import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {IntlShape} from 'react-intl';
import {ValidationError, ValidationFormatter} from '../../types';
import {fileErrorMessages} from './messages';
import {FileValidatorErrorCode, FileValidatorOptions} from './types';

type FileValidationError = ValidationError<FileValidatorErrorCode, FileValidatorOptions>;

export const fileErrorFormatters: Record<FileValidatorErrorCode, ValidationFormatter<FileValidationError>> = {
  [FileValidatorErrorCode.FILE_SIZE_OUT_OF_RANGE]: (error: FileValidationError, intl: IntlShape) => {
    const minSizeMeasure = fileSizeUtils.createMeasure(error.options?.minSize || 0, FileSizeUnit.Byte);
    const maxSizeMeasure = fileSizeUtils.createMeasure(error.options?.maxSize || 0, FileSizeUnit.Byte);
    return intl.formatMessage(fileErrorMessages[FileValidatorErrorCode.FILE_SIZE_OUT_OF_RANGE], {
      minFileSize: formatFileSize(intl, minSizeMeasure),
      maxFileSize: formatFileSize(intl, maxSizeMeasure),
    });
  },
  [FileValidatorErrorCode.FILE_INVALID]: (error: FileValidationError, intl: IntlShape) => {
    return intl.formatMessage(fileErrorMessages[FileValidatorErrorCode.FILE_INVALID]);
  },

  [FileValidatorErrorCode.FILE_SIZE_TOO_BIG]: (error: FileValidationError, intl: IntlShape) => {
    const measure = fileSizeUtils.createMeasure(error.options?.maxSize || 0, FileSizeUnit.Byte);
    return intl.formatMessage(fileErrorMessages[FileValidatorErrorCode.FILE_SIZE_TOO_BIG], {
      fileSize: formatFileSize(intl, measure),
    });
  },

  [FileValidatorErrorCode.FILE_SIZE_TOO_SMALL]: (error: FileValidationError, intl: IntlShape) => {
    const measure = fileSizeUtils.createMeasure(error.options?.minSize || 0, FileSizeUnit.Byte);
    return intl.formatMessage(fileErrorMessages[FileValidatorErrorCode.FILE_SIZE_TOO_SMALL], {
      fileSize: formatFileSize(intl, measure),
    });
  },

  [FileValidatorErrorCode.FILE_INVALID_MIME_TYPE]: (error: FileValidationError, intl: IntlShape) => {
    const mimeTypes = error.options?.mimeTypes;
    return intl.formatMessage(fileErrorMessages[FileValidatorErrorCode.FILE_INVALID_MIME_TYPE], {
      fileExtensions: intl.formatList(
        (mimeTypes || []).flatMap((mimeType) => getExtensionsByMimeType(mimeType)),
        {type: 'conjunction'},
      ),
    });
  },

  [FileValidatorErrorCode.FILE_INVALID_EXTENSION]: (error: FileValidationError, intl: IntlShape) =>
    intl.formatMessage(fileErrorMessages[FileValidatorErrorCode.FILE_INVALID_EXTENSION], {
      fileExtensions: intl.formatList(error.options?.extensions || [], {type: 'disjunction'}),
    }),
};
