export enum ColumnId {
  PARSING_STATUS = 'parsingStatus',
  ROW_NUMBER = 'rowNumber',
  USER = 'user',
  EMAIL = 'email',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  AMOUNT = 'amount',
  FREQUENCY = 'frequency',
  COMMENT = 'comment',
}
