import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  user: {
    defaultMessage: 'Joomer',
    description: 'Team membership request form: field label',
  },
  outstaffUser: {
    defaultMessage: 'Outstaff user',
    description: 'Team membership request form: field label',
  },
  role: {
    defaultMessage: 'Role',
    description: 'Team membership request form: field label',
  },
});

export const messages = defineMessages({
  title: {
    defaultMessage: 'Add member or role',
    description: '[title] Team membership request dialog: title',
  },
  outstaffTitle: {
    defaultMessage: 'Add outstaff member role',
    description: '[title] Team membership request dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Team role request dialog',
    description: 'Team membership request dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Team membership request dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Grant role',
    description: '[button] Team membership request dialog: submit button',
  },
});
