import {defineMessages} from 'react-intl';
import {ContractType} from '.';

export const contractTypeMessages = defineMessages<ContractType>({
  [ContractType.CONTRACTOR]: {
    defaultMessage: 'Contractor',
    description: 'Contract type',
  },
  [ContractType.FULL_TIME]: {
    defaultMessage: 'Full-time',
    description: 'Contract type',
  },
  [ContractType.INTERN]: {
    defaultMessage: 'Intern',
    description: 'Contract type',
  },
  [ContractType.PART_TIME]: {
    defaultMessage: 'Part-time',
    description: 'Contract type',
  },
  [ContractType.TEMPORARY]: {
    defaultMessage: 'Temporary',
    description: 'Contract type',
  },
});
