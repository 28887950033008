import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {CountryCode} from 'domain/countryCode/model';
import {CountrySelect} from 'domain/countryCode/widgets/Select';
import {Currency} from 'domain/currency/model';
import {CurrencySelect} from 'domain/currency/widgets/Select';
import {LegalEntity, LegalEntityDiff} from 'domain/legalEntity/model';
import React from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {legalEntitiesUrls} from 'routes/legalEntities/urls';
import {fieldLabels, messages} from './messages';
import styles from './styles.css';

type Props = {
  legalEntity: LegalEntity;
  onSubmit: (diff: LegalEntityDiff) => void;
};

export function LegalEntityForm({legalEntity, onSubmit}: Props) {
  const intl = useIntl();

  return (
    <Form<LegalEntityDiff> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid}) => (
        <Panel withPadding>
          <form onSubmit={handleSubmit} className={styles.wrap}>
            <Field<CountryCode> name='countryCode' initialValue={legalEntity.countryCode} validate={validateFilled}>
              {({input}) => (
                <FormControl disabled={submitting} label={intl.formatMessage(fieldLabels.country)} required>
                  {(formControlProps) => <CountrySelect {...formControlProps} {...input} />}
                </FormControl>
              )}
            </Field>
            <Field<Currency> name='currency' initialValue={legalEntity.currency} validate={validateFilled}>
              {({input: {value, ...input}}) => (
                <FormControl disabled={submitting} label={intl.formatMessage(fieldLabels.currency)} required>
                  {(formControlProps) => (
                    <CurrencySelect disabled={submitting} value={value || null} {...formControlProps} {...input} />
                  )}
                </FormControl>
              )}
            </Field>
            <ButtonGroup spaced size='l'>
              <Button kind='primary' intent='primary' type='submit' loading={submitting} disabled={invalid}>
                {intl.formatMessage(messages.saveButton)}
              </Button>
              <RouterLinkButton size='l' kind='secondary' intent='neutral' type='button' to={legalEntitiesUrls.root()}>
                {intl.formatMessage(messages.cancelButton)}
              </RouterLinkButton>
            </ButtonGroup>
          </form>
        </Panel>
      )}
    </Form>
  );
}
