import {intranetApi} from 'apiClient';
import {LegalEntityId} from 'domain/legalEntity/model';
import {OneTimeBonusRecordId} from 'domain/oneTimeBonusRecord/model';
import {UserId} from 'domain/user/model';

export type DeleteOneTimeBonusRecordParams = {
  id: OneTimeBonusRecordId;
  userId: UserId;
  legalEntityId: LegalEntityId; // to use in store
};

export const deleteRecord = ({id, userId}: DeleteOneTimeBonusRecordParams): Promise<void> => {
  return intranetApi.post('/v1/secure/users/oneTimeBonusRecords/delete', undefined, {params: {id, userId}});
};
