import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  countriesOfTimeOffPolicy: {
    defaultMessage: 'Work calendar',
    description: 'User full info record filter label',
  },
  crMarketLevelPositions: {
    defaultMessage: 'CR category',
    description: 'User full info record filter label',
  },
  divisions: {
    defaultMessage: 'Business unit',
    description: 'User full info record filter label',
  },
  levelCodes: {
    defaultMessage: 'Level',
    description: 'User full info record filter label',
  },
  managerIds: {
    defaultMessage: 'Manager',
    description: 'User full info record filter label',
  },
  showIndirect: {
    defaultMessage: 'Show indirect',
    description: 'User full info record filter label',
  },
  userFunctions: {
    defaultMessage: 'Function',
    description: 'User full info record filter label',
  },
  userIds: {
    defaultMessage: 'Joomer',
    description: 'User full info record filter label',
  },
});
