import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {
  approveCompensationReviewRequestFx,
  approveCompensationReviewRequestOnBehalfFx,
  cancelCompensationReviewRequestFx,
  getAllCompensationReviewRequestsFx,
  getCompensationReviewRequestByIdFx,
  getMyCompensationReviewRequestsFx,
  getSubordinateCompensationReviewRequestsFx,
  rejectCompensationReviewRequestFx,
  rejectCompensationReviewRequestOnBehalfFx,
  setCompensationsRecordedFx,
  setStockOptionsRecordedFx,
} from '.';

export const $allCompensationReviewRequests = createEntityListStore(
  createEntityStore({
    getEntityId: ({id}) => id,
    queryTotalCount: {
      fx: getAllCompensationReviewRequestsFx,
      getIdsFromParams: () => [],
    },
  }),
);

export const $myCompensationReviewRequests = createEntityListStore(
  createEntityStore({
    getEntityId: ({id}) => id,
    queryTotalCount: {
      fx: getMyCompensationReviewRequestsFx,
      getIdsFromParams: () => [],
    },
  }),
);

export const $subordinateCompensationReviewRequests = createEntityListStore(
  createEntityStore({
    getEntityId: ({id}) => id,
    queryTotalCount: {
      fx: getSubordinateCompensationReviewRequestsFx,
      getIdsFromParams: () => [],
    },
  }),
);

export const $compensationReviewRequests = createEntityStore({
  getEntityId: ({id}) => id,
  loadByIdFx: getCompensationReviewRequestByIdFx,
})
  .on(
    [
      getAllCompensationReviewRequestsFx.doneData,
      getMyCompensationReviewRequestsFx.doneData,
      getSubordinateCompensationReviewRequestsFx.doneData,
    ],
    (state, result) => {
      const loadedIds = result.items.map((item) => item.id);

      return {
        ...state,
        byId: {
          ...state.byId,
          ...arrayToObject(result.items, (item) => item.id),
        },
        byIdState: {
          ...state.byIdState,
          ...arrayToObject(loadedIds, identity, () => DataState.LOADED),
        },
      };
    },
  )
  .on(
    [
      approveCompensationReviewRequestFx.doneData,
      approveCompensationReviewRequestOnBehalfFx.doneData,
      cancelCompensationReviewRequestFx.doneData,
      rejectCompensationReviewRequestFx.doneData,
      rejectCompensationReviewRequestOnBehalfFx.doneData,
      setCompensationsRecordedFx.doneData,
      setStockOptionsRecordedFx.doneData,
    ],
    (state, result) => ({
      ...state,
      byId: {
        ...state.byId,
        [result.id]: result,
      },
    }),
  );
