import React from 'react';
import {ToastOptions as ToastCoreOptions} from 'react-toastify';
import {ReactComponent as SuccessIcon} from '../icons/circle/checkmark.svg';
import {ReactComponent as InfoIcon} from '../icons/circle/info.svg';
import {ReactComponent as WarningIcon} from '../icons/circle/warning.svg';
import {ReactComponent as CrossIcon} from '../icons/circle/cross.svg';
import {ButtonIntent} from '../Button';
import {ToastOptions, ToastType} from './types';

export function convertToastOptionsToToastCoreOptions({
  autoClose,
  noCloseButton,
  pauseOnHover,
}: ToastOptions = {}): ToastCoreOptions {
  return {
    autoClose,
    closeButton: typeof noCloseButton === 'boolean' ? !noCloseButton : undefined,
    pauseOnHover,
  };
}

export const buttonIntentByToastType: Record<ToastType, ButtonIntent> = {
  error: 'negative',
  warning: 'warning',
  info: 'primary',
  success: 'positive',
};

export const toastIconByToastType: Record<ToastType, React.ReactNode> = {
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <CrossIcon />,
  success: <SuccessIcon />,
};
