import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {array, boolean, ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {receiptDiffSchema, receiptSchema} from 'domain/compensations/receipt/model';
import {fileMetaSchema} from 'models/system/fileMeta';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const compensationReportIdSchema = idSchema;
export type CompensationReportId = ExtractSchemaType<typeof compensationReportIdSchema>;

export const compensationReportFromApiSchema = object({
  boardingPasses: optionalWithDefault([], array(fileMetaSchema)),
  hasNoFlights: optional(boolean()),
  id: compensationReportIdSchema,
  receipts: optionalWithDefault([], array(receiptSchema)),
});
export type CompensationReportFromApi = ExtractSchemaType<typeof compensationReportFromApiSchema>;

export const compensationReportApiMapping = (reportFromApi: CompensationReportFromApi) => ({
  ...reportFromApi,
  hasData: Boolean(reportFromApi.boardingPasses.length || reportFromApi.receipts.length || reportFromApi.hasNoFlights),
});

export const compensationReportSchema = compensationReportFromApiSchema.map(compensationReportApiMapping);
export type CompensationReport = ExtractSchemaType<typeof compensationReportSchema>;

export const compensationReportDiffSchema = object({
  boardingPasses: optional(array(idSchema)),
  hasNoFlights: optional(boolean()),
  receipts: optional(array(receiptDiffSchema)),
});
export type CompensationReportDiff = ExtractSchemaType<typeof compensationReportDiffSchema>;
