import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  compensation: {
    defaultMessage: 'Compensation',
    description: 'Compensation review requests filter label',
  },
  statuses: {
    defaultMessage: 'Status',
    description: 'Compensation review requests filter label',
  },
  stockOptions: {
    defaultMessage: 'Options',
    description: 'Compensation review requests filter label',
  },
  userIds: {
    defaultMessage: 'Joomer',
    description: 'Compensation review requests filter label',
  },
});
