import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Buyouts',
    description: '[title] Stock option buyouts bulk upload panel title',
  },
});

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.AMOUNT]: {
    defaultMessage: 'Options',
    description: 'Stock option buyouts bulk upload table column name',
  },
  [ColumnId.PARSING_STATUS]: {
    defaultMessage: 'Parsed',
    description: 'Stock option buyouts bulk upload table column name',
  },
  [ColumnId.DATE]: {
    defaultMessage: 'Date',
    description: 'Stock option buyouts bulk upload table column name',
  },
  [ColumnId.ISSUER]: {
    defaultMessage: 'Issuer',
    description: 'Stock option buyouts bulk upload table column name',
  },
  [ColumnId.DOCUMENTS_STATUS]: {
    defaultMessage: 'Documents status',
    description: 'Stock option buyouts bulk upload table column name',
  },
  [ColumnId.USER]: {
    defaultMessage: 'Joomer',
    description: 'Stock option buyouts bulk upload table column name',
  },
  [ColumnId.EMAIL]: {
    defaultMessage: 'Email',
    description: 'Stock option buyouts bulk upload table column name',
  },
});
