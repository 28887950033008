import {isNotNullish, isNullish} from '@joomcode/deprecated-utils/function';
import {ValidationFunction, ValidationOptions} from '@joomcode/joom-form';
import {RoleInTeamDiff} from 'domain/role/model/roleInTeam';

export enum RolesInTeamsValidatorError {
  PARTIALLY_FILLED = 'partiallyFilled',
}

export const validateRolesInTeamsNoPartial: ValidationFunction<
  Partial<RoleInTeamDiff>[],
  ValidationOptions,
  RolesInTeamsValidatorError
> = (rows) => {
  if (
    rows.some(
      ({roleId, teamId}) => (isNotNullish(roleId) && isNullish(teamId)) || (isNullish(roleId) && isNotNullish(teamId)),
    )
  ) {
    return {code: RolesInTeamsValidatorError.PARTIALLY_FILLED};
  }
  return undefined;
};
