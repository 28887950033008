import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  lostReceipt: {
    defaultMessage: 'I lost my receipt',
    description: 'Receipt card: lost receipt message',
  },
  noReceiptFiles: {
    defaultMessage: 'No receipt files',
    description: 'Receipt card: empty message',
  },
  removeReceipt: {
    defaultMessage: 'Remove expense',
    description: '[button] Compensation receipts: Remove expense',
  },
});
