import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.EFFECTIVE_DATE]: {
    defaultMessage: 'Effective date',
    description: 'Job contract table column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Contract status',
    description: 'Job contract table column name',
  },
  [ColumnId.CONTRACT_TYPE]: {
    defaultMessage: 'Contract type',
    description: 'Job contract table column name',
  },
  [ColumnId.POSITION_TITLE]: {
    defaultMessage: 'Position title',
    description: 'Job contract table column name',
  },
  [ColumnId.TIME_OFF_POLICY]: {
    defaultMessage: 'Time off policy',
    description: 'Job contract table column name',
  },
  [ColumnId.COMMENT]: {
    defaultMessage: 'Comment',
    description: 'Job contract table column name',
  },
});
