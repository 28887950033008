import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Update freeze date',
    description: '[title] Stock option agreement freeze update dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Option agreement freeze update dialog',
    description: 'Stock option agreement freeze update dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Stock option agreement freeze update dialog: cancel button',
  },
  updateButton: {
    defaultMessage: 'Update',
    description: '[button] Stock option agreement freeze update dialog: update button',
  },
  successfulUpdate: {
    defaultMessage: 'Freeze date has been updated',
    description: 'Stock option agreement freeze update dialog: success message',
  },
  successfulDeleting: {
    defaultMessage: 'Freeze date has been deleted',
    description: 'Stock option agreement freeze update dialog: success message',
  },
  note: {
    defaultMessage:
      'This option agreement already has a freeze date. If you update it with an empty value, the freeze date will be deleted.',
    description: 'Stock option agreement freeze update dialog: note',
  },
});
