import {DataState} from '@joomcode/deprecated-utils/dataState';
import {AutocompleteFilterOptions, createAutocompleteFilter, FilterItem} from '@joomcode/joom-ui/DataFilter';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {UserId} from 'domain/user/model';
import {UserSuggestion} from 'domain/user/widgets/Suggestion';
import {generalMessages} from 'i18n/messages/general';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type ReturnShape = {
  userFilter: FilterItem<UserId, AutocompleteFilterOptions<UserId>>;
  dataState: DataState;
};

export const useUserFilter = (label: string): ReturnShape => {
  const intl = useIntl();
  const {dataState, userByLogin, loginById} = useAllUsers({withTerminated: true});
  const userFilter = useMemo(
    () =>
      createAutocompleteFilter(label, {
        alwaysVisible: true,
        options: Object.values(userByLogin).map(({firstName, lastName, login, id}) => ({
          label: intl.formatMessage(messages.userFilterLabel, {
            fullName: intl.formatMessage(generalMessages.fullName, {
              firstName,
              lastName,
            }),
            login,
          }),
          value: id,
        })),
        renderOption: ({value: userId}) => <UserSuggestion user={userByLogin[loginById[userId]]} />,
      }),
    [label, userByLogin, loginById, intl],
  );

  return {
    dataState,
    userFilter,
  };
};
