import {$stockOptionValue} from 'domain/stockOption/buyout/stores/main/state';
import {useStore} from 'effector-react';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './styles.css';

type Props = {
  amount: number;
};

export function StockOptionValueEstimation({amount}: Props) {
  const intl = useIntl();
  const {stockOptionValue} = useStore($stockOptionValue);
  const roundedValue = Math.round(amount * stockOptionValue);

  return <span className={styles.root}>{`~$${intl.formatNumber(roundedValue)}`}</span>;
}
