import {getFieldValidator, useFieldArrayWithInitialValue} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {RenderProps} from '@joomcode/joom-ui/FormControl';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plusSmall.svg';
import {ReactComponent as TrashIcon} from '@joomcode/joom-ui/icons/core/trash.svg';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {addBusinessDays} from 'date-fns';
import {PREPARATION_WORKDAYS} from 'domain/compensations/request/widgets/Form';
import {stringToDate} from 'models/system/formattedDate';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {getArrayFieldValidator} from 'utils/form/arrayFieldValidation';
import uuid from 'uuid/v4';
import {messages} from './messages';
import styles from './styles.css';
import {validate} from './validate';

type Props = RenderProps & {
  name: string;
  disabled?: boolean;
};

export function FieldMultiDate({name, disabled, ...formControlProps}: Props) {
  const intl = useIntl();
  const calendarPortalId = useMemo(uuid, []);
  const fields = useFieldArrayWithInitialValue<string>(name, {
    initialValue: [''],
    validate: getArrayFieldValidator(getFieldValidator(validate)),
  });
  const addDate = useCallback(() => fields.push(''), [fields]);
  const minDates = useMemo(
    () =>
      Array.from(fields).map((_, index) => {
        const previousValue = fields.value[index - 1];
        const previousDate = previousValue !== undefined ? stringToDate(previousValue) : null;

        return previousDate ?? addBusinessDays(new Date(), PREPARATION_WORKDAYS);
      }),
    [fields.value],
  );

  return (
    <>
      {fields.map((fieldName, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.row} key={index}>
            <FieldDate
              minDate={minDates[index]}
              name={fieldName}
              portalId={calendarPortalId}
              disabled={disabled}
              withFormControl={false}
              {...formControlProps}
            />
            {fields.length > 1 && (
              <div className={styles.removeButtonWrap}>
                <Button
                  aria-label={intl.formatMessage(messages.buttonRemove)}
                  iconLeft={<TrashIcon />}
                  size='m'
                  kind='text'
                  intent='neutral'
                  type='button'
                  disabled={disabled}
                  onClick={() => fields.remove(index)}
                />
              </div>
            )}
          </div>
        );
      })}
      <Button
        iconLeft={<PlusIcon />}
        size='m'
        kind='secondary'
        intent='neutral'
        type='button'
        disabled={disabled}
        onClick={addDate}
      >
        {intl.formatMessage(messages.buttonAdd)}
      </Button>
    </>
  );
}
