import {bindValidatorOptions, validateFilled} from '@joomcode/joom-form';
import {Checkbox} from '@joomcode/joom-ui/Checkbox';
import {CheckboxGroup, Option} from '@joomcode/joom-ui/CheckboxGroup';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import React, {FormEventHandler} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';

const getOptions = (reasons: string[]): Option<string>[] =>
  reasons.map((reason) => ({
    label: reason,
    value: reason,
  }));

type Props = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  formId: string;
};

export function OfferDeclineForm({formId, onSubmit}: Props) {
  const intl = useIntl();
  const {units} = useCompanyUnits();
  const validate = bindValidatorOptions(validateFilled, {checkEmptyArrays: true});

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Field<string[]> name='reasons' validate={validate}>
        {({input, meta}) => (
          <CheckboxGroup
            ariaLabel={intl.formatMessage(messages.ariaLabel)}
            direction='columns'
            disabled={meta.submitting}
            options={getOptions(units?.offerDeclineReasons ?? [])}
            renderItem={(item, props) => <Checkbox {...props} label={item.label} />}
            values={input.value}
            {...input}
          />
        )}
      </Field>
    </form>
  );
}
