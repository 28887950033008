import {ReactComponent as PeopleIcon} from '@joomcode/joom-ui/icons/core/people.svg';
import {RoleInTeam} from 'domain/role/model/roleInTeam';
import {TeamPath} from 'domain/team/widgets/Path';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  roleInTeam: RoleInTeam;
};

export function ListedRoleInTeam({roleInTeam: {role, teamWithAncestors}}: Props) {
  const intl = useIntl();
  return (
    <div>
      <span className={styles.icon}>
        <PeopleIcon />
      </span>
      {intl.formatMessage(messages.text, {
        role: <span className={styles.role}>{role.name}</span>,
        team: <TeamPath {...teamWithAncestors} />,
      })}
    </div>
  );
}
