import {CreateBalanceRecordParams} from 'domain/timeOff/balanceRecord/api/create';
import {TimeOffBalanceRecord, TimeOffBalanceRecordsByType} from 'domain/timeOff/balanceRecord/model';
import {UserId} from 'domain/user/model';
import {createEffect} from 'effector';

export const getMyTimeOffBalanceRecords = createEffect<UserId, TimeOffBalanceRecord[]>('get my balance records');
export const getTimeOffBalanceRecords = createEffect<UserId, TimeOffBalanceRecord[]>('get balance records by user');
export const createTimeOffBalanceRecord = createEffect<CreateBalanceRecordParams, TimeOffBalanceRecord>(
  'create balance record',
);
export const getMyTimeOffBalanceRecordsByTypeFx = createEffect<UserId, TimeOffBalanceRecordsByType>(
  'get my balance records divided by type',
);
export const getTimeOffBalanceRecordsByTypeFx = createEffect<UserId, TimeOffBalanceRecordsByType>(
  'get user‘s balance records divided by type',
);
