import {useEffect, useMemo, useState} from 'react';

function removeReservedMediaKeyWord(mediaQueryString: string): string {
  return mediaQueryString.replace('@media', '').trim();
}

/**
 * Check if a media query matches the UI
 * @param {String} mediaQueryString
 * @returns {Boolean}
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/change_event
 *
 * Example:
 *    useMediaQuery('(max-width: 600px)');
 *    useMediaQuery('only screen and (min-width: 600px)');
 *    useMediaQuery('@media only screen and (min-width: 600px)');
 */
export function useMediaQuery(mediaQueryString: string): boolean {
  const queryString = removeReservedMediaKeyWord(mediaQueryString);
  const query = useMemo(() => window.matchMedia(queryString), [queryString]);
  const [matches, setMatches] = useState(query.matches); // one-time, instantaneous check
  useEffect(() => {
    const listener = (e: MediaQueryListEvent): void => {
      setMatches(e.matches);
    };
    query.addEventListener('change', listener);
    return () => query.removeEventListener('change', listener);
  }, [query]);
  return matches;
}
