import cn from 'classnames';
import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FocusOn} from 'react-focus-on';
import {createLocator, type Locator, type Mark} from '../create-locator';
import {ReactComponent as PlusIcon} from '../icons/core/plus.svg';
import {ReactComponent as FilterOffIcon} from '../icons/core/filterOff.svg';
import {Button, ButtonLocator} from '../Button';
import {Menu, type MenuLocator} from '../Menu';
import {AutocompleteInput, type AutocompleteInputLocator} from './AutocompleteInput';
import {Filter, Props as FilterProps} from './Filter';
import {FilterGroup} from './FilterGroup';
import {autocompleteMessages, dataFilterMessages} from './messages';
import {FilterId} from './types';
import {useFilterState, FilterOptions} from './useFilterState';
import styles from './DataFilter.css';
import {isDataFilterEmpty} from './utils';
import {MenuItemLocator} from '../Menu/Item';

export type DataFilterLocator = Locator<{
  addFilter: ButtonLocator;
  addFilterMenu: MenuLocator;
  addFilterMenuItem: MenuItemLocator;
  clearFilters: ButtonLocator;
  searchFiltersInput: AutocompleteInputLocator;
}>;

export type DataFilterProps<Values> = FilterOptions<Values> & {
  addon?: React.ReactNode;
  dialog?: boolean;
  dropdownPlacement?: FilterProps<Values>['dropdownPlacement'];
  clearable?: boolean;
  clearFiltersButtonText?: React.ReactNode;
  label?: React.ReactNode;
  addFilterButtonText?: React.ReactNode;
  withPadding?: boolean;
} & Partial<Mark<DataFilterLocator>>;

// Force React.memo to passing through Generic
const typedMemo: <T>(component: T) => T = React.memo;

export const DataFilter = typedMemo(function DataFilter<Values extends Record<FilterId, unknown>>({
  addon,
  config,
  clearable = false,
  clearFiltersButtonText,
  dropdownPlacement = 'bottom-start',
  values,
  onChange,
  label,
  orderedIds,
  addFilterButtonText,
  dialog: renderMenuToPortal = false,
  withPadding = true,
  ...rest
}: DataFilterProps<Values>) {
  const [isScrollLocked, setIsScrollLocked] = useState(false);
  const intl = useIntl();
  const locator = createLocator(rest);
  const {visibleFilterIds, hiddenFilterIds, isNewFilter, addFilter, removeFilter, clearFilters, onFilterChange} =
    useFilterState({
      config,
      orderedIds,
      onChange,
      values,
    });
  const [searchText, setSearchText] = useState<string>('');
  const filteredHiddenFilterIds = useMemo(
    () => hiddenFilterIds.filter((id) => config[id].name.toLowerCase().includes(searchText.trim().toLowerCase())),
    [searchText, hiddenFilterIds],
  );

  return (
    <div
      className={cn(styles.dataFilter, {
        [styles.dataFilterWithPadding]: withPadding,
      })}
      {...locator()}
    >
      <div className={styles.labelList}>
        {label !== null && (
          <div className={styles.label}>
            {label || (
              <FormattedMessage defaultMessage='Filters:' description='Data filters label' id='data-filters-label' />
            )}
          </div>
        )}

        <FilterGroup>
          {visibleFilterIds.map((id) => (
            <Filter
              config={config[id]}
              dropdownPlacement={dropdownPlacement}
              initiallyOpen={isNewFilter(id)}
              value={values[id]}
              onChange={(val) => onFilterChange(id, val)}
              onRemove={() => removeFilter(id)}
              key={id as string}
            />
          ))}

          {hiddenFilterIds.length > 0 && addFilter && (
            <FocusOn className={styles.addFilterButton} enabled={isScrollLocked && renderMenuToPortal} scrollLock>
              <Menu
                ariaLabel={intl.formatMessage(dataFilterMessages.addFilterMenuAriaLabel)}
                renderToPortal={renderMenuToPortal}
                disclosure={
                  <Button intent='primary' kind='text' size='m' iconLeft={<PlusIcon />} {...locator.addFilter()}>
                    {addFilterButtonText ?? (
                      <FormattedMessage
                        defaultMessage='Add Filter'
                        description='[button] Add new filter button'
                        id='datafilter-add-filter-button'
                      />
                    )}
                  </Button>
                }
                onToggleMenu={setIsScrollLocked}
                {...locator.addFilterMenu()}
              >
                {Object.keys(config).length > 7 && (
                  <div className={styles.autocompleteInput}>
                    <AutocompleteInput
                      placeholder={intl.formatMessage(dataFilterMessages.searchFiltersPlaceholder)}
                      value={searchText}
                      onChange={setSearchText}
                      {...locator.searchFiltersInput()}
                    />
                  </div>
                )}

                {filteredHiddenFilterIds.length > 0 ? (
                  filteredHiddenFilterIds
                    .map((id) => ({id, name: config[id].name}))
                    .sort((a, b) => a.name.localeCompare(b.name, intl.locale))
                    .map(({id, name}) => (
                      <Menu.Item
                        onClick={() => {
                          addFilter(id);
                          setSearchText('');
                        }}
                        key={id as string}
                        {...locator.addFilterMenuItem()}
                      >
                        {name}
                      </Menu.Item>
                    ))
                ) : (
                  <Menu.Item disabled {...locator.addFilterMenuItem()}>
                    {intl.formatMessage(autocompleteMessages.empty)}
                  </Menu.Item>
                )}
              </Menu>
            </FocusOn>
          )}

          {clearable && !isDataFilterEmpty(config, values) && (
            <Button
              intent='primary'
              kind='text'
              size='m'
              iconLeft={<FilterOffIcon />}
              onClick={clearFilters}
              {...locator.clearFilters()}
            >
              {clearFiltersButtonText ?? (
                <FormattedMessage
                  defaultMessage='Clear Filters'
                  description='[button] Clear filters button'
                  id='datafilter-clear-filters-button'
                />
              )}
            </Button>
          )}
        </FilterGroup>
      </div>

      {addon && <div className={styles.addon}>{addon}</div>}
    </div>
  );
});
