import {loadAsyncScript} from '@joomcode/deprecated-utils/dom/loadAsyncScript';
import {UserId} from 'domain/user/model';
import {Locale} from 'i18n/locale/types';
import {analyticsLogger} from 'services/logger';
import type {
  ElementClick,
  MapSearchSuggestItemClick,
  NotionGuideClick,
  SubordinateTableFiltersValueChange,
  SubordinateTableVisibleColumnsChange,
} from './eventTypes';

type GtagConfig = Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams;

type GtagClientOptions = {
  id: string;
  locale: Locale;
};

type GtagEvent =
  | ElementClick
  | MapSearchSuggestItemClick
  | SubordinateTableFiltersValueChange
  | SubordinateTableVisibleColumnsChange
  | NotionGuideClick;

export class GtagClient {
  private readonly id: string;

  private config: GtagConfig;

  private gtag: Gtag.Gtag;

  public constructor(options: GtagClientOptions) {
    this.id = options.id;
    this.config = {
      debug_mode: window.intranetEnv.APP_ENV === 'local',
      locale: options.locale,
    };

    this.init();
  }

  private init() {
    loadAsyncScript(`https://www.googletagmanager.com/gtag/js?id=${this.id}&l=dataLayer`).catch((error) => {
      analyticsLogger.error('GTag: loading failed', error);
    });

    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    this.gtag = function gtag() {
      /* eslint-disable prefer-rest-params */
      // arguments object works for gtag.js, rest params don't
      try {
        window.dataLayer.push(arguments);
      } catch (error) {
        analyticsLogger.error('GTag: send event error', {error, arguments});
      }
      /* eslint-enable prefer-rest-params */
    };

    this.gtag('js', new Date());
    this.reportConfig();
  }

  private reportConfig() {
    this.gtag('config', this.id, this.config);
  }

  public setLocale(locale: Locale) {
    this.config = {...this.config, locale};
    this.reportConfig();
  }

  public setUserProperties(userId: UserId) {
    this.gtag('set', {
      user_id: userId,
      user_properties: {
        with_vpn: window.intranetEnv.IN_VPN === 'true',
        with_user_id: Boolean(userId),
      },
    });
  }

  public logEvent<Event extends GtagEvent>(name: Event['name'], params: Event['params']) {
    this.gtag('event', name, params);
  }
}
