import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonChange: {
    defaultMessage: 'Change',
    description: '[button] Change balance records button',
  },
  buttonChangeBalance: {
    defaultMessage: 'Change balance',
    description: '[button] Change balance records button',
  },
  tabVacation: {
    defaultMessage: 'Vacation',
    description: '[tab] Vacation tab',
  },
  tabRemoteWork: {
    defaultMessage: 'Remote work',
    description: '[tab] Remote work tab',
  },
});
