import {useEffect} from 'react';
import {usePageLocker, PageLocker} from './usePageLocker';

export * from './usePageLocker';
export * from './shared';

type Props = {
  when: boolean;
  pageLocker?: PageLocker;
  message?: string;
};

export function PageLock({pageLocker, when, message}: Props) {
  const {lock: defaultLock, unlock: defaultUnLock} = usePageLocker(message);

  const lock = pageLocker ? pageLocker.lock : defaultLock;
  const unlock = pageLocker ? pageLocker.unlock : defaultUnLock;

  useEffect(() => {
    if (when) {
      lock();
    } else {
      unlock();
    }

    return () => {
      unlock();
    };
  }, [when]);

  return null;
}
