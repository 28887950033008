import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {ResourcePage} from 'pages/Resource';
import {ResourcesPage} from 'pages/Resources';
import {ServicePage} from 'pages/Service';
import {ServicesPage} from 'pages/Services';
import React from 'react';
import {RouteConfig} from 'react-router-config';
import {Redirect} from 'react-router-dom';
import {jdmUrlPatterns, jdmUrls} from './urls';

export const config: RouteConfig = {
  path: jdmUrlPatterns.root,
  component: withAuth(RegularLayout),
  routes: [
    {
      exact: true,
      component: withPermission(() => <Redirect to={{pathname: jdmUrls.services()}} />, Permission.SERVICE_READ),
      path: jdmUrlPatterns.root,
    },
    {
      component: withPermission(ServicesPage, Permission.SERVICE_READ),
      exact: true,
      path: jdmUrlPatterns.services,
    },
    {
      component: withPermission(ServicePage, Permission.SERVICE_READ),
      exact: true,
      path: jdmUrlPatterns.service,
    },
    {
      component: withPermission(ResourcesPage, Permission.RESOURCE_READ),
      exact: true,
      path: jdmUrlPatterns.resources,
    },
    {
      component: withPermission(ResourcePage, Permission.RESOURCE_READ),
      exact: true,
      path: jdmUrlPatterns.resource,
    },
  ],
};
