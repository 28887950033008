import {SocialContact} from 'components/ui/SocialContact';
import {SocialContactType} from 'components/ui/SocialContact/types';
import {StyledLink} from 'components/ui/StyledLink';
import {buildUserGithubUrl} from 'domain/user/api/buildUserGithubUrl';
import {User} from 'domain/user/model';
import {formatPhone} from 'models/system/phone';
import React from 'react';
import {useIntl} from 'react-intl';
import {officeUrls} from 'routes/officeMap/urls';
import {highlightIfNeeded} from 'utils/search/highlight';
import {messages} from './messages';
import styles from './userContact.css';

type Props = {
  highlightSearch?: (s: string) => React.ReactNode;
  iconClassName?: string;
  type: SocialContactType;
  user: User;
};

export function UserContact({highlightSearch, iconClassName, type, user}: Props) {
  const intl = useIntl();

  if (type === 'email') {
    return (
      <SocialContact type='email' iconClassName={iconClassName}>
        {highlightIfNeeded(user.email, highlightSearch)}
      </SocialContact>
    );
  }

  if (type === 'location') {
    if (!user.location && !user.seat) {
      return null;
    }
    return (
      <SocialContact type='location' iconClassName={iconClassName}>
        {user.location}
        {user.seat && (
          <span className={styles.seat}>
            {user.location && ' '}
            <StyledLink
              className={styles.seatLink}
              to={officeUrls.seat({alias: user.seat.officeAlias}, user.seat)}
              colored
            >
              {user.seat?.number
                ? intl.formatMessage(messages.officeSeat, {seatNumber: user.seat.number})
                : intl.formatMessage(messages.officeSeatWithoutNumber)}
            </StyledLink>
          </span>
        )}
      </SocialContact>
    );
  }

  if (type === 'phone') {
    if (!user.slackDisplayName || !user.slackLink) {
      return null;
    }
    return (
      <SocialContact type='phone' iconClassName={iconClassName}>
        {formatPhone(user.phone) || user.phone}
      </SocialContact>
    );
  }

  if (type === 'slack') {
    if (!user.slackDisplayName || !user.slackLink) {
      return null;
    }
    return (
      <SocialContact type='slack' link={user.slackLink} externalLink iconClassName={iconClassName}>
        {highlightIfNeeded(user.slackDisplayName, highlightSearch)}
      </SocialContact>
    );
  }

  if (type === 'github') {
    if (!user.verifiedGithubLogin) {
      return null;
    }

    return (
      <SocialContact
        type='github'
        link={buildUserGithubUrl(user.verifiedGithubLogin)}
        externalLink
        iconClassName={iconClassName}
      >
        {highlightIfNeeded(user.verifiedGithubLogin, highlightSearch)}
      </SocialContact>
    );
  }

  return null;
}
