import {DependencyList, RefObject, useEffect} from 'react';

export function useOnResize<Node extends Element>(
  ref: RefObject<Node>,
  onResize: () => void,
  deps: DependencyList,
): void {
  useEffect(() => {
    if (!('ResizeObserver' in window)) {
      return () => {};
    }

    const resizeObserver = new ResizeObserver(onResize);

    /* element that ref refers to is rendered in popup
     * that's we should wait for next frame before observing
     *  */
    const timer = requestAnimationFrame(() => {
      if (ref.current) {
        resizeObserver.observe(ref.current);
      }
    });

    return () => {
      cancelAnimationFrame(timer);

      resizeObserver.disconnect();
    };
  }, deps);
}
