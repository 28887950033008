import {defineMessages} from 'react-intl';

export const hints = defineMessages({
  partnerCompanyNames: {
    defaultMessage: 'Please provide the names of all the companies you will be meeting with during your business trip.',
    description: 'Compensation request: partner company names field hint',
  },
  eventNames: {
    defaultMessage: 'Please provide the names of all the events you will participate in during your business trip.',
    description: 'Compensation request: event names field hint',
  },
  businessNecessity: {
    defaultMessage: 'Business necessity that can be objectively evaluated.',
    description: 'Compensation request: business necessity field hint',
  },
});

export const labels = defineMessages({
  partnerCompanyNames: {
    defaultMessage: 'Partner company names',
    description: 'Compensation request field label',
  },
  eventNames: {
    defaultMessage: 'Event names',
    description: 'Compensation request field label',
  },
  businessNecessity: {
    defaultMessage: 'Business necessity',
    description: 'Compensation request field label',
  },
  meetingDates: {
    defaultMessage: 'Meeting dates',
    description: 'Compensation request field label',
  },
  eventDates: {
    defaultMessage: 'Event dates',
    description: 'Compensation request field label',
  },
});
