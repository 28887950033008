import {AnyObject} from '../../types';

export function pick<O extends AnyObject, K extends keyof O>(obj: O, keys: K[]): Pick<O, K> {
  return keys.reduce(
    (acc, key) => {
      acc[key] = obj[key];
      return acc;
    },
    {} as Pick<O, K>,
  );
}
