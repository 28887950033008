import {identity} from '@joomcode/deprecated-utils/function';
import {validateFilled, validateUrl} from '@joomcode/joom-form';
import {Checkbox} from '@joomcode/joom-ui/Checkbox';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {HintIcon} from 'components/ui/HintIcon';
import {MarkdownCheatsheetLink} from 'components/ui/MarkdownCheatsheetLink';
import {Team} from 'domain/team/model';
import {TeamDiff} from 'domain/team/model/diff';
import {FieldUsers, FieldUsersValue} from 'domain/user/widgets/FieldMultiple';
import React from 'react';
import {Field, FormRenderProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {hints, labels} from './messages';
import styles from './styles.css';

type Props = {
  formId: string;
  onSubmit: FormRenderProps<TeamDiff>['handleSubmit'];
  submitting: boolean;
  teamToUpdate?: Team;
};

export function TeamForm({formId, onSubmit, submitting, teamToUpdate}: Props) {
  const intl = useIntl();

  return (
    <form onSubmit={onSubmit} id={formId}>
      <Field<string> name='name' validate={validateFilled} initialValue={teamToUpdate?.name}>
        {({input, meta}) => (
          <FormControl
            disabled={submitting}
            error={getFieldErrorText(meta, {intl})}
            label={intl.formatMessage(labels.name)}
            hint={intl.formatMessage(hints.useLatin)}
            required
          >
            {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
          </FormControl>
        )}
      </Field>
      <Field<string> name='description' parse={identity} initialValue={teamToUpdate?.description}>
        {({input}) => (
          <FormControl
            disabled={submitting}
            label={intl.formatMessage(labels.description)}
            hint={intl.formatMessage(hints.description, {
              link: (text) => <MarkdownCheatsheetLink>{text}</MarkdownCheatsheetLink>,
            })}
          >
            {(formControlProps) => <Textarea {...formControlProps} {...input} disabled={submitting} />}
          </FormControl>
        )}
      </Field>
      <Field<string>
        name='slackChannelLink'
        parse={identity}
        validate={validateUrl}
        initialValue={teamToUpdate?.slackChannelLink}
      >
        {({input, meta}) => (
          <FormControl
            disabled={submitting}
            error={getFieldErrorText(meta, {intl})}
            label={
              <>
                {intl.formatMessage(labels.slackLink)}
                <HintIcon>{intl.formatMessage(hints.slackLink)}</HintIcon>
              </>
            }
          >
            {(formControlProps) => (
              <Input {...formControlProps} {...input} disabled={submitting} placeholder='https://' />
            )}
          </FormControl>
        )}
      </Field>
      <Field<string> name='notionLink' parse={identity} validate={validateUrl} initialValue={teamToUpdate?.notionLink}>
        {({input, meta}) => (
          <FormControl
            disabled={submitting}
            error={getFieldErrorText(meta, {intl})}
            label={intl.formatMessage(labels.notionLink)}
          >
            {(formControlProps) => (
              <Input {...formControlProps} {...input} disabled={submitting} placeholder='https://' />
            )}
          </FormControl>
        )}
      </Field>
      {!teamToUpdate && (
        <Field<FieldUsersValue> name='headIds' subscription={{error: true, touched: true}}>
          {({meta}) => (
            <FormControl
              disabled={submitting}
              label={
                <>
                  {intl.formatMessage(labels.heads)}
                  <HintIcon>{intl.formatMessage(hints.heads)}</HintIcon>
                </>
              }
              error={getFieldErrorText(meta, {intl})}
              required
            >
              {(formControlProps) => (
                <FieldUsers disabled={submitting} name='headIds' validate={validateFilled} {...formControlProps} />
              )}
            </FormControl>
          )}
        </Field>
      )}
      <Field<boolean> name='vTeam' type='checkbox' initialValue={teamToUpdate?.vTeam}>
        {({input: {value, ...input}}) => (
          <div className={styles.checkboxFieldWrap}>
            <Checkbox {...input} label={intl.formatMessage(labels.vTeam)} disabled={submitting} />
          </div>
        )}
      </Field>
    </form>
  );
}
