import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {OneTimeBonusRecord, oneTimeBonusRecordSchema} from 'domain/oneTimeBonusRecord/model';
import {GetOneTimeBonusRecordsParams} from './getAny';

export const getMy = async ({legalEntityId}: GetOneTimeBonusRecordsParams): Promise<OneTimeBonusRecord[]> => {
  const {data} = await intranetApi.get('/v1/secure/users/oneTimeBonusRecords/getMyByLegalEntityId', {
    params: {legalEntityId},
  });

  return array(oneTimeBonusRecordSchema).runWithException(data);
};
