import cn from 'classnames';
import React from 'react';
import styles from './index.css';

export type SkeletonShapeProps = {
  kind: 'rectangle' | 'pill';
} & (
  | {
      children: React.ReactNode;
      width?: never;
      height?: never;
    }
  | {
      children?: never;
      width: React.CSSProperties['width'];
      height: React.CSSProperties['height'];
    }
);

export function SkeletonShape({kind, children, width, height}: SkeletonShapeProps) {
  return (
    <div
      className={cn(styles.skeleton, {
        [styles.skeletonPill]: kind === 'pill',
        [styles.skeletonRectangle]: kind === 'rectangle',
      })}
      style={{
        // @ts-expect-error
        '--skeleton-height': height,
        '--skeleton-width': width,
      }}
    >
      {children}
    </div>
  );
}
