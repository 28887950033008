export enum NewcomerFormTemplateCountry {
  ARMENIA = 'AM',
  CHINA = 'CN',
  GERMANY = 'DE',
  LATVIA = 'LV',
  LUXEMBOURG = 'LU',
  PORTUGAL = 'PT',
  RUSSIA = 'RU',
  UKRAINE = 'UA',
  OTHER = 'other',
}
