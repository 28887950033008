import {CloseButton} from '@joomcode/joom-ui/CloseButton';
import {Stack} from '@joomcode/joom-ui/Stack';
import {User} from 'domain/user/model';
import {UserPreviewWrapper} from 'domain/user/widgets/PreviewWrapper';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  user: User;
  onPaneClose: () => void;
};

export function UserSidePane({user, onPaneClose}: Props) {
  const intl = useIntl();

  return (
    <div className={styles.sidePane}>
      <div className={styles.sidePaneTitle}>
        <Stack>
          {intl.formatMessage(messages.userPaneTitle)}
          <Stack.Spacer />
          <CloseButton onClick={onPaneClose} />
        </Stack>
      </div>
      <div className={styles.sidePaneContent}>
        <UserPreviewWrapper user={user} />
      </div>
    </div>
  );
}
