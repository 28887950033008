import {QueryRequestPayload} from 'api/types';
import {
  CountableRoleBindigns,
  RoleBinding,
  RoleBindingId,
  RoleBindingRequestConfig,
  RoleBindingsSearchFilters,
} from 'domain/roleBinding/model';
import {createEffect} from 'effector';

export const approveRoleBindingRequestFx = createEffect<RoleBindingId, RoleBinding>('approve role binding request');
export const cancelRoleBindingRequestFx = createEffect<RoleBindingId, RoleBinding>('cancel role binding request');
export const getRoleBindingsFx = createEffect<QueryRequestPayload<RoleBindingsSearchFilters>, RoleBinding[]>(
  'load filtered role bindings with pagination',
);
export const getRoleBindingsWithCountFx = createEffect<
  QueryRequestPayload<RoleBindingsSearchFilters>,
  CountableRoleBindigns
>('load filtered role bindings with pagination and count');
export const rejectRoleBindingRequestFx = createEffect<RoleBindingId, RoleBinding>('reject role binding request');
export const requestRoleBindingFx = createEffect<RoleBindingRequestConfig, RoleBinding>('request role binding');
export const requestRoleBindingAsAdminFx = createEffect<RoleBindingRequestConfig, RoleBinding>(
  'request role binding as admin',
);
export const revokeRoleBindingFx = createEffect<RoleBindingId, RoleBinding>('revoke role binding');
