import cn from 'classnames';
import React from 'react';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type Locator,
  type Mark,
  getLocatorParameters,
} from '../create-locator';
import {CloseButton, CloseButtonLocator} from './CloseButton';
import styles from './index.css';

export enum TagSize {
  S = 's',
  M = 'm',
  L = 'l',
}

export type TagTestId = ConvertLocatorToTestId<TagLocator>;
export type TagLocator = Locator<
  {
    removeButton: CloseButtonLocator;
  },
  {
    id?: string;
  },
  'sameParameters'
>;

type Props = {
  size?: `${TagSize}`;
  children: React.ReactNode;
  disabled?: boolean;
  onRemove(): void;
  removeButtonAriaLabel?: string;
} & Partial<Mark<TagLocator>>;

export function Tag({children, size = 'm', disabled, onRemove, removeButtonAriaLabel, ...restProps}: Props) {
  const locator = createLocator(restProps);
  const locatorParameters = getLocatorParameters(restProps);

  return (
    <div
      className={cn(styles.tag, styles[`size${capitalize(size)}` as keyof typeof styles], {
        [styles.tagDisabled]: disabled,
      })}
      {...locator(locatorParameters)}
    >
      <div className={styles.content}>{children}</div>
      <CloseButton
        ariaLabel={removeButtonAriaLabel}
        disabled={disabled}
        onClick={onRemove}
        {...locator.removeButton()}
      />
    </div>
  );
}
