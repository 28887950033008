import {CountryCode, sortCountryCodes} from '@joomcode/deprecated-utils/countries';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {identity} from '@joomcode/deprecated-utils/function';
import {MultiSelect} from '../Select/MultiSelect';

export type RenderItem = (data: {code: CountryCode; name: string}) => JSX.Element | string;

export type CountryMultiSelectProps = {
  disabled?: boolean;
  onChange: (countryCode: CountryCode[]) => void;
  options?: CountryCode[];
  value: CountryCode[];
  renderItem?: RenderItem;
};

export function CountryMultiSelect({disabled, onChange, options, renderItem, value}: CountryMultiSelectProps) {
  const intl = useIntl();

  const items = useMemo<CountryCode[]>(
    () => sortCountryCodes(options ?? getEnumValues(CountryCode), intl),
    [options, intl],
  );

  const getNameByCode = useCallback(
    (code: CountryCode) => intl.formatDisplayName(code, {type: 'region'}) ?? code,
    [intl],
  );

  const renderOption = useCallback(
    (code: CountryCode) => {
      const name = getNameByCode(code);
      if (renderItem === undefined) {
        return name;
      }
      return renderItem({code, name});
    },
    [getNameByCode, renderItem],
  );

  return (
    <MultiSelect
      disabled={disabled}
      items={items}
      itemToString={getNameByCode}
      getItemKey={identity}
      onChange={onChange}
      value={value}
      renderOption={renderOption}
    />
  );
}
