import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {getSalaryRangesFx} from '.';

export const $salaryRanges = createEntityListStore(
  createEntityStore({
    getEntityId: ({id}) => id,
    query: {
      fx: getSalaryRangesFx,
      getIdsFromParams: () => [],
    },
  }),
);
