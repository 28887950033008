import {DataState} from '@joomcode/deprecated-utils/dataState';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import cn from 'classnames';
import React, {useMemo, useRef} from 'react';
import {createLocator} from '../create-locator';
import {useScrollSync} from '../useScrollSync';
import {useScrollViewOverflow} from '../useScrollViewOverflow';
import {Footer} from './components/Footer';
import {Header} from './components/Header';
import {HeadingCell} from './components/HeadingCell';
import {Preloader} from './components/Preloader';
import {Row} from './components/Row';
import {Scrollbar} from './components/Scrollbar';
import {StickyBorder} from './components/StickyBorder';
import {SummaryRow} from './components/SummaryRow';
import {TableDisclosureContext, useTableDisclosure} from './contexts/Disclosure';
import {SelectionContext} from './contexts/Selection';
import styles from './DataTable.css';
import {useColumns} from './hooks/useColumns';
import {useStretchColumnsOnMount} from './hooks/useStretchColumnsOnMount';
import type {Props} from './types';
import {getStickyOffsets} from './utils/getStickyOffsets';
import {List} from './components/List';

export function DataTable<Item, NestedItem = never>({
  autoStretchMode = 'always',
  count,
  empty,
  emptyMessage = 'Empty List',
  failedMessage = 'Failed to load data',
  dataState,
  columns: availableColumns,
  columnTreeOptions,
  data,
  getRowKey,
  title,
  nestedRows,
  noResizeSeparators = false,
  columnSelectAriaLabel = 'Select columns',
  getRowClassName,
  highlightSelectedRows,
  selection,
  selectionTitle,
  selectionToolbar,
  sorting,
  toolbarBottom,
  toolbarTop,
  storageName,
  headingStyle,
  summaryPosition = 'top',
  virtualization,
  onSort,
  selectionColumnSortable,
  onVisibleColumnsChange,
  ...restProperties
}: Props<Item, NestedItem>) {
  const locator = createLocator(restProperties);

  const headerScrollView = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLTableElement>(null);
  const scrollView = useRef<HTMLDivElement>(null);
  const horizontalScroll = useRef<HTMLDivElement>(null);
  const isHovered = useBooleanState(false);
  const isSelectionDisabled = dataState !== DataState.LOADED || data.length === 0;

  const isEveryItemSelectionDisabled = useMemo(
    () => Boolean(selection && selection.isItemDisabled && data.every(selection.isItemDisabled)),
    [data, selection?.isItemDisabled],
  );

  const {columns, tableWidth, getColumnWidth, onColumnResize, onWidthMapChange} = useColumns<Item>({
    columns: availableColumns,
    columnSelectAriaLabel,
    isSelectionDisabled,
    isEveryItemSelectionDisabled,
    expandable: Boolean(nestedRows?.getNestedRowKey && nestedRows.enabled),
    expandableHeader: !nestedRows?.withoutHeaderDisclosure,
    selectable: Boolean(selection),
    storageName,
    selectionColumnSortable,
    selectionType: selection?.type,
    onVisibleColumnsChange,
    columnTreeOptions,
  });

  useStretchColumnsOnMount({
    autoStretchMode,
    columns,
    content,
    scrollView: headerScrollView,
    getColumnWidth,
    onStretch: onWidthMapChange,
  });

  useScrollSync([headerScrollView, horizontalScroll, scrollView], {horizontal: true});

  const overflow = useScrollViewOverflow(scrollView, {listenWindowResize: true}, [columns, data, getColumnWidth]);

  const stickyOffsets = useMemo(() => getStickyOffsets({columns, getColumnWidth}), [columns, getColumnWidth]);

  const tableDisclosure = useTableDisclosure();

  const isEmpty = dataState === DataState.LOADED && data.length === 0;

  const renderContent = (items: Item[]) => {
    if (dataState === DataState.FAILED) {
      return <div className={styles.placeholderFailed}>{failedMessage}</div>;
    }

    if (isEmpty) {
      return empty || <div className={styles.placeholderEmpty}>{emptyMessage}</div>;
    }

    if (items.length === 0) {
      /**
       * .itemsEmpty should not be set on <table>,
       * because Safari would ignore min-height
       * and render it with 0×0 size
       */
      return <div className={cn(styles.items, styles.itemsEmpty)} />;
    }

    const hasSummaryRow = columns.some((column) => isNotNullish(column.summary));
    const renderedSummaryRow = hasSummaryRow ? (
      <SummaryRow
        columns={columns}
        getColumnWidth={getColumnWidth}
        getColumnOffset={stickyOffsets.body.getColumnOffset}
      />
    ) : undefined;

    return (
      <List
        items={items}
        ref={scrollView}
        summaryPosition={summaryPosition}
        renderRow={(item, style) => (
          <Row
            {...nestedRows}
            key={getRowKey(item)}
            item={item}
            className={getRowClassName && getRowClassName(item)}
            style={style}
            columns={columns}
            getColumnWidth={getColumnWidth}
            getColumnOffset={stickyOffsets.body.getColumnOffset}
            highlightSelected={highlightSelectedRows}
            {...locator.row({item: getRowKey(item)})}
          />
        )}
        summaryRow={renderedSummaryRow}
        virtualization={virtualization}
      />
    );
  };

  return (
    <TableDisclosureContext.Provider value={tableDisclosure}>
      <SelectionContext.Provider value={selection}>
        <div
          className={cn(styles.dataTable, {
            [styles.dataTableSelected]: Boolean(selection?.hasSelectedItems && (selectionTitle || selectionToolbar)),
          })}
          onMouseEnter={isHovered.setTrue}
          onMouseLeave={isHovered.setFalse}
        >
          <Header
            count={count}
            selection={selection}
            selectionTitle={selectionTitle}
            selectionToolbar={selectionToolbar}
            title={title}
            toolbar={toolbarTop}
          />
          <div className={styles.headerWrapper}>
            <div ref={headerScrollView} className={cn(styles.scrollView, styles.header)}>
              <div ref={content} className={styles.headerInner}>
                {columns.map((column, index) => (
                  <HeadingCell
                    align={column.headingCellAlign}
                    column={column}
                    invisibleResizer={
                      noResizeSeparators ||
                      (overflow.left && column.sticky === 'left' && columns[index + 1]?.sticky !== 'left')
                    }
                    dataState={dataState}
                    stickyOffset={stickyOffsets.header.getColumnOffset(column)}
                    sorting={sorting}
                    onSort={onSort}
                    last={index === columns.length - 1}
                    width={getColumnWidth(column)}
                    // Resizing is disabled with noResizeSeparators flag
                    onResize={!noResizeSeparators ? onColumnResize : undefined}
                    key={column.id}
                    cellStyle={headingStyle}
                    {...locator.headingCell({})}
                  />
                ))}
              </div>
            </div>

            <StickyBorder side='left' offset={stickyOffsets.header.borderOffsets.left} visible={overflow.left} />
            <StickyBorder side='right' offset={stickyOffsets.header.borderOffsets.right} visible={overflow.right} />
          </div>

          <div className={cn(styles.content, toolbarBottom && styles.contentWithToolbarBottom)}>
            <div className={styles.contentInner}>
              {dataState === DataState.LOADING && <Preloader />}

              {renderContent(data)}

              <StickyBorder side='left' offset={stickyOffsets.body.borderOffsets.left} visible={overflow.left} />
              <StickyBorder side='right' offset={stickyOffsets.body.borderOffsets.right} visible={overflow.right} />
            </div>

            <Footer
              scrollbar={
                <Scrollbar
                  ref={horizontalScroll}
                  innerWidth={tableWidth}
                  visible={(overflow.left || overflow.right) && dataState === DataState.LOADED && isHovered.value}
                />
              }
            >
              {toolbarBottom}
            </Footer>
          </div>
        </div>
      </SelectionContext.Provider>
    </TableDisclosureContext.Provider>
  );
}
