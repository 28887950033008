import {DataState, getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Permission} from 'domain/permission/model';
import {checkIsMine} from 'domain/team/api/checkIsMine';
import {useTeams} from 'domain/team/hooks/useTeams';
import {Team} from 'domain/team/model/';
import {TeamId} from 'domain/team/model/id';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  canCreateTeam: boolean;
  canDeleteTeam: boolean;
  canEditMemberships: boolean;
  canEditOutstaffMemberships: boolean;
  canUpdateTeam: boolean;
  dataState: DataState;
  isTeamAdmin: boolean;
  isTeamOwner: boolean;
};

const restrictActionsOnDeletingTeam = (team: Team | undefined, condition: boolean | undefined): boolean =>
  (team ? !team.deleting : false) && Boolean(condition);

export function useTeamAcl(teamId: TeamId | undefined): ReturnShape {
  const acl = useAcl();
  const {byId, dataState} = useTeams();

  const canCreateTeams = acl.hasPermission(Permission.TEAM_CREATE);
  const canDeleteTeams = acl.hasPermission(Permission.TEAM_DELETE);
  const canUpdateTeams = acl.hasPermission(Permission.TEAM_UPDATE);
  const canUpdateMemberships = acl.hasPermission(Permission.MEMBERSHIP_WRITE);
  const canUpdateOutstaffMemberships = acl.hasPermission(Permission.OUTSTAFF_MEMBERSHIP_WRITE);
  const isMembershipAdmin = acl.hasPermission(Permission.MEMBERSHIP_ADMIN_WRITE);
  const isOutstaffMembershipAdmin = acl.hasPermission(Permission.OUTSTAFF_MEMBERSHIP_ADMIN_WRITE);
  const isTeamAdmin = acl.hasPermission(Permission.TEAM_ADMIN_WRITE);

  const checkOwnership = useAsyncTask<[TeamId], boolean>(() => {
    return teamId ? checkIsMine(teamId).catch(toaster.interceptThenThrowError) : Promise.resolve(false);
  }, [teamId]);

  useEffect(() => {
    if (teamId) {
      checkOwnership.perform(teamId);
    }
  }, [teamId]);

  const team = teamId ? byId[teamId] : undefined;
  const isTeamOwner = Boolean(checkOwnership.result);

  return {
    dataState: getConsolidatedDataState(checkOwnership.dataState, dataState),
    canEditMemberships: restrictActionsOnDeletingTeam(team, isMembershipAdmin || (isTeamOwner && canUpdateMemberships)),
    canEditOutstaffMemberships: restrictActionsOnDeletingTeam(
      team,
      isOutstaffMembershipAdmin || (isTeamOwner && canUpdateOutstaffMemberships),
    ),
    isTeamOwner: restrictActionsOnDeletingTeam(team, isTeamOwner),
    canCreateTeam: restrictActionsOnDeletingTeam(team, canCreateTeams),
    canUpdateTeam: restrictActionsOnDeletingTeam(team, isTeamAdmin || (isTeamOwner && canUpdateTeams)),
    canDeleteTeam: restrictActionsOnDeletingTeam(team, isTeamAdmin || (isTeamOwner && canDeleteTeams)),
    isTeamAdmin,
  };
}
