import {CreateOfficePolicyRecordConfig} from 'domain/officePolicyRecord/api/create';
import {DeleteOfficePolicyRecordParams} from 'domain/officePolicyRecord/api/delete';
import {UpdateOfficePolicyRecordConfig} from 'domain/officePolicyRecord/api/update';
import {OfficePolicyRecord} from 'domain/officePolicyRecord/model';
import {UserId} from 'domain/user/model';
import {createEffect} from 'effector';

export const createOfficePolicyRecordFx = createEffect<CreateOfficePolicyRecordConfig, OfficePolicyRecord[]>(
  'create new office policy record',
);
export const deleteOfficePolicyRecordFx = createEffect<DeleteOfficePolicyRecordParams, void>(
  'delete office policy record',
);
export const getAllOfficePolicyRecordsFx = createEffect<UserId, OfficePolicyRecord[]>(
  'get all user‘s office policy records',
);
export const getMyOfficePolicyRecordsFx = createEffect<UserId, OfficePolicyRecord[]>('get own office policy records');
export const getSubordinateOfficePolicyRecordsFx = createEffect<UserId, OfficePolicyRecord[]>(
  'get subordinate office policy records',
);
export const updateOfficePolicyRecordFx = createEffect<UpdateOfficePolicyRecordConfig, OfficePolicyRecord[]>(
  'update office policy record',
);
