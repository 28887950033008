import {DataState} from '@joomcode/deprecated-utils/dataState';
import {PagePreloader} from 'components/ui/PagePreloader';
import {$locale} from 'domain/locale/stores/main/state';
import {useStore} from 'effector-react';
import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import {useIntl} from 'react-intl';
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config';
import {useLocation} from 'react-router-dom';
import {useAnalytics} from 'services/analytics/useAnalytics';
import {UserSnap, UsersnapContext} from 'services/usersnap';
import './index.css';
import {messages} from './messages';

type Props = RouteConfigComponentProps;

export const Application = ({route}: Props) => {
  const intl = useIntl();
  const {gtag} = useAnalytics();
  const {pathname} = useLocation();
  const usersnapState = useState<UserSnap | null>(null);
  const {locale, state: localeLoadingState} = useStore($locale);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    gtag.setLocale(locale);
  }, [locale]);

  if (!route) {
    return null;
  }

  if (localeLoadingState === DataState.LOADING) {
    return <PagePreloader />;
  }

  return (
    <UsersnapContext.Provider value={usersnapState}>
      <Helmet title={intl.formatMessage(messages.title)} />
      <Helmet titleTemplate={intl.formatMessage(messages.titleTemplate, {pageTitle: '%s'})} />
      {renderRoutes(route.routes)}
    </UsersnapContext.Provider>
  );
};
