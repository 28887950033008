import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  attachFilesButton: {
    defaultMessage: 'Attach files',
    description: '[button] Multiple files upload: attach files',
  },
  attachMoreFilesButton: {
    defaultMessage: 'Attach more files',
    description: '[button] Multiple files upload: attach more files',
  },
  fileTypesHint: {
    defaultMessage: 'File types allowed: {types}',
    description: 'Multiple files upload: types hint',
  },
});
