import {intranetApi} from 'apiClient';

type Params = {
  offerId: string;
};

export const buildAvatarDownloadLink = ({offerId}: Params) =>
  intranetApi.getUri({
    params: {id: offerId},
    url: `${intranetApi.defaults.baseURL}/v1/offers/downloadAvatar`,
  });
