import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {ListedProperty} from 'components/ui/ListedProperty';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {EmptyStockOptions} from 'domain/compensationReviewRequest/widgets/EmptyStockOptions';
import {SectionHeader} from 'domain/compensationReviewRequest/widgets/SectionHeader';
import {Value} from 'domain/compensationReviewRequest/widgets/Value';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  newUserInfo: CompensationReviewRequest['newUserInfo'];
  oldUserInfo: CompensationReviewRequest['oldUserInfo'];
  user: CompensationReviewRequest['user'];
};

export function CompensationReviewRequestSectionStockOptions({newUserInfo, oldUserInfo, user}: Props) {
  const intl = useIntl();

  return (
    <>
      <SectionHeader title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        {newUserInfo.stockOptions ? (
          <Grid>
            <Grid.Item xl={3} xs={6}>
              <ListedProperty label={intl.formatMessage(messages.granted)}>
                <Value
                  oldValue={
                    oldUserInfo.stockOptions?.granted.total !== undefined ? (
                      <FormattedOptionsAmount amount={oldUserInfo.stockOptions.granted.total} />
                    ) : undefined
                  }
                  newValue={<FormattedOptionsAmount amount={newUserInfo.stockOptions.granted.total} />}
                  isEqual={oldUserInfo.stockOptions?.granted.total === newUserInfo.stockOptions.granted.total}
                />
              </ListedProperty>
              {!oldUserInfo.stockOptions && (
                <div className={styles.old}>
                  <EmptyStockOptions userId={user.id} />
                </div>
              )}
            </Grid.Item>
            <Grid.Item xl={3} xs={6}>
              <ListedProperty label={intl.formatMessage(messages.nextYear)}>
                <Value
                  oldValue={
                    oldUserInfo.stockOptions?.nextYear !== undefined ? (
                      <FormattedOptionsAmount amount={oldUserInfo.stockOptions.nextYear} />
                    ) : undefined
                  }
                  newValue={<FormattedOptionsAmount amount={newUserInfo.stockOptions.nextYear} />}
                  isEqual={oldUserInfo.stockOptions?.nextYear === newUserInfo.stockOptions.nextYear}
                />
              </ListedProperty>
            </Grid.Item>
            {oldUserInfo.stockOptions && (
              <>
                <Grid.Item xl={3} xs={6}>
                  <ListedProperty label={intl.formatMessage(messages.vested)}>
                    <FormattedOptionsAmount amount={oldUserInfo.stockOptions.vested} />
                  </ListedProperty>
                </Grid.Item>
                <Grid.Item xl={3} xs={6}>
                  <ListedProperty label={intl.formatMessage(messages.available)}>
                    <FormattedOptionsAmount amount={oldUserInfo.stockOptions.available} />
                  </ListedProperty>
                </Grid.Item>
              </>
            )}
          </Grid>
        ) : (
          <EmptyStockOptions userId={user.id} />
        )}
      </Panel.Content>
    </>
  );
}
