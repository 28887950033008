import {intranetApi} from 'apiClient';
import {OfferId} from 'domain/offer/model';
import {NewcomerInOffer, newcomerInOfferSchema} from 'domain/offer/model/newcomer';

export type UpdateStartWorkingDateConfig = {
  offerId: OfferId;
  startWorkingDate: string;
};

export const updateStartWorkingDate = async ({
  startWorkingDate,
  offerId,
}: UpdateStartWorkingDateConfig): Promise<NewcomerInOffer> => {
  const {data} = await intranetApi.post(
    '/v1/offers/changeStartWorkingDate',
    {startWorkingDate},
    {params: {id: offerId}},
  );

  return newcomerInOfferSchema.runWithException(data);
};
