import {Menu} from '@joomcode/joom-ui/Menu';
import {MenuButton} from 'components/ui/MenuButton';
import {AnySalaryRecord, SalaryRecordId} from 'domain/salaryRecord/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export type Props = {
  salaryRecord: AnySalaryRecord;
  onDelete?: (id: SalaryRecordId) => void;
  onEdit?: (record: AnySalaryRecord) => void;
  onReplace?: (record: AnySalaryRecord) => void;
};

export function SalaryRecordMenu({salaryRecord, onDelete, onEdit, onReplace}: Props) {
  const intl = useIntl();

  return (
    <Menu ariaLabel={intl.formatMessage(messages.menuAriaLabel)} disclosure={<MenuButton />}>
      {onReplace && (
        <Menu.Item onClick={() => onReplace(salaryRecord)}>{intl.formatMessage(messages.replace)}</Menu.Item>
      )}
      {onEdit && <Menu.Item onClick={() => onEdit(salaryRecord)}>{intl.formatMessage(messages.edit)}</Menu.Item>}
      {onDelete && (
        <Menu.Item onClick={() => onDelete(salaryRecord.id)}>{intl.formatMessage(messages.delete)}</Menu.Item>
      )}
    </Menu>
  );
}
