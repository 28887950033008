import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: '{frequency, select, undefined {} other {{frequency} }}salary range',
    description: '[title] Compensation review request: salary range title',
  },
  titleNew: {
    defaultMessage: 'New{frequency, select, undefined {} other { {frequency}}} salary range',
    description: '[title] Compensation review request: salary range title',
  },
  hint: {
    defaultMessage:
      'Minimum, midpoint, and maximum salary for the level, function, and region specified in the request.',
    description: 'Compensation review request: salary range title hint',
  },
});
