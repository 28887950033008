import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {NotificationBadge} from '@joomcode/joom-ui/NotificationBadge';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  children: React.ReactNode;
};

type TabProps = {
  active?: boolean;
  hidden?: boolean;
  notificationsCount?: number;
  isNew?: boolean;
  path: string;
  title: string;
};

export function PageTabs({children}: Props) {
  return (
    <nav className={styles.root}>
      <ButtonGroup spaced size='l'>
        {children}
      </ButtonGroup>
    </nav>
  );
}

function Tab({active = false, hidden = false, notificationsCount = 0, title, path, isNew}: TabProps) {
  const intl = useIntl();

  if (hidden) {
    return null;
  }

  const content = (
    <div className={styles.content}>
      {title}
      {isNew && (
        <NotificationBadge intent='primary' position='inlineEnd'>
          {intl.formatMessage(messages.newBadge)}
        </NotificationBadge>
      )}
      {notificationsCount > 0 && (
        <NotificationBadge position='inlineEnd' intent='primary'>
          {notificationsCount}
        </NotificationBadge>
      )}
    </div>
  );

  return (
    <RouterLinkButton size='l' intent={active ? 'primary' : 'neutral'} kind={active ? 'secondary' : 'text'} to={path}>
      {content}
    </RouterLinkButton>
  );
}

PageTabs.Tab = Tab;
