import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonSubmit: {
    defaultMessage: 'Submit request',
    description: '[button] Compensation review request submit button',
  },
  buttonBack: {
    defaultMessage: 'Go back',
    description: '[button] Compensation review request back button',
  },
  success: {
    defaultMessage: 'Compensation review request has been created.',
    description: 'Compensation review request success message',
  },
});
