import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  manager: {
    defaultMessage: 'Manager',
    description: '[title] User preview: Manager section title',
  },
  viewFullProfile: {
    defaultMessage: 'View full profile',
    description: 'Link to the full profile',
  },
});
