import {Storage} from '../types';

export class LocalStorage implements Storage {
  public get(name: string): string | null {
    try {
      return window.localStorage.getItem(name);
    } catch (e) {}

    return null;
  }

  public remove(name: string): void {
    try {
      window.localStorage.removeItem(name);
    } catch (e) {}
  }

  public set(name: string, value: string): void {
    try {
      window.localStorage.setItem(name, value);
    } catch (e) {}
  }
}
