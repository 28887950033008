import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  saveDraftButton: {
    defaultMessage: 'Save draft',
    description: '[button] Save compensation report draft',
  },
  submitButton: {
    defaultMessage: 'Submit report',
    description: '[button] Submit compensation report',
  },
  boardingPassesTitle: {
    defaultMessage: 'Boarding passes',
    description: '[title] Compensation report form: section title',
  },
  boardingPassesAnnotation: {
    defaultMessage:
      'Upload your boarding passes here. If you have paper copies, attach the photos/scans and save the hard copies - we’ll need them later.',
    description: 'Compensation report form: section annotation',
  },
  receiptsAnnotation: {
    defaultMessage:
      'You can upload all the receipts for compensation here. Hopefully you’ve learned about different types of receipts in <link1>our guide</link1>, since there are a lot of nuances.',
    description: 'Compensation report form: section annotation',
  },
  expensesTitle: {
    defaultMessage: 'Extra expenses',
    description: '[title] Compensation report form: section title',
  },
  successSaveMessage: {
    defaultMessage: 'Draft saved',
    description: 'Compensation report form: toaster message',
  },
  confirmSubmitTitle: {
    defaultMessage: 'Confirming report submission',
    description: '[title] Compensation report form: submission confirmation dialog title',
  },
  confirmSubmitText: {
    defaultMessage: 'After you’ve submitted your report, it cannot be changed. Submit?',
    description: 'Compensation report form: submission confirmation dialog text',
  },
  confirmSubmitButton: {
    defaultMessage: 'Submit report',
    description: '[button] Compensation report form: submission confirmation dialog button',
  },
});

export const fieldLabels = defineMessages({
  hasNoFlights: {
    defaultMessage: 'A trip without flights',
    description: 'Compensation report form field label',
  },
});
