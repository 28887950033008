import * as React from 'react';
import styles from './index.css';

type Props = {
  message: string;
};

export function Warning({message}: Props) {
  return (
    <div className={styles.warning} title={message}>
      {message}
    </div>
  );
}
