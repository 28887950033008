import {defineMessages} from 'react-intl';
import {SocialMedia} from '.';

export const socialMediaNames = defineMessages<SocialMedia>({
  [SocialMedia.FACEBOOK]: {
    defaultMessage: 'Facebook',
    description: 'Social media name',
  },
  [SocialMedia.INSTAGRAM]: {
    defaultMessage: 'Instagram',
    description: 'Social media name',
  },
  [SocialMedia.LINKED_IN]: {
    defaultMessage: 'LinkedIn',
    description: 'Social media name',
  },
  [SocialMedia.TWITTER]: {
    defaultMessage: 'Twitter',
    description: 'Social media name',
  },
});
