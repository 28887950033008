import composeRefs from '@seznam/compose-react-refs';
import React, {forwardRef} from 'react';
import {Tippy, TippyProps} from '../Tippy';
import styles from './index.css';

type Props = {
  content: React.ReactNode;
  children: TippyProps['children'];
  placement?: TippyProps['placement'];
  enabled?: TippyProps['enabled'];
  hideOnClick?: TippyProps['hideOnClick'];
  trigger?: TippyProps['trigger'];
  visible?: TippyProps['visible'];
  maxWidth?: TippyProps['maxWidth'];
};

export const TooltipAlt = forwardRef(
  (
    {
      content,
      enabled = true,
      children,
      hideOnClick,
      placement = 'auto',
      trigger = 'mouseenter',
      visible,
      maxWidth,
    }: Props,
    ref: React.Ref<unknown>,
  ) => {
    const onlyChildren = React.Children.only(children);

    return (
      <Tippy
        boundary='viewport'
        className={styles.tooltip}
        enabled={enabled}
        theme='none'
        content={<section className={styles.content}>{content}</section>}
        delay={[
          100, // avoid irrelevant flickering on unintentional mouseover
          0,
        ]}
        maxWidth={maxWidth}
        duration={500}
        hideOnClick={hideOnClick}
        visible={visible}
        placement={placement}
        trigger={trigger}
      >
        {React.cloneElement(onlyChildren, {
          // @ts-ignore
          ref: composeRefs(ref, onlyChildren && onlyChildren.ref),
        })}
      </Tippy>
    );
  },
);
