import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  collapse: {
    defaultMessage: ' — <button>hide</button>',
    description: 'Clamped text: collapse button',
  },
  expand: {
    defaultMessage: '...<button>more</button>',
    description: 'Clamped text: expand button',
  },
  expandPlain: {
    defaultMessage: '...more',
    description: 'Clamped text: expand plain text',
  },
});
