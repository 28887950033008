import {IntlShape} from 'react-intl';
import {TimeOffPolicy} from '.';
import {messages} from './messages';

export const formatTimeOffPolicy = (policy: TimeOffPolicy, intl: IntlShape) => {
  if (policy === TimeOffPolicy.NO_POLICY) {
    return intl.formatMessage(messages.noPolicy);
  }
  return intl.formatDisplayName(policy, {type: 'region'}) ?? policy;
};
