import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonApprove: {
    defaultMessage: 'Approve',
    description: '[button] Compensation review request action',
  },
  buttonApproveOnBehalf: {
    defaultMessage: 'Approve on behalf',
    description: '[button] Compensation review request action',
  },
  buttonCancel: {
    defaultMessage: 'Cancel request',
    description: '[button] Compensation review request action',
  },
  buttonRecorded: {
    defaultMessage: 'Applied',
    description: '[button] Compensation review request action',
  },
  buttonReject: {
    defaultMessage: 'Reject',
    description: '[button] Compensation review request action',
  },
  buttonRejectOnBehalf: {
    defaultMessage: 'Reject on behalf',
    description: '[button] Compensation review request action',
  },
  buttonStockOptionsRecorded: {
    defaultMessage: 'Options applied',
    description: '[button] Compensation review request action',
  },
  cancelDialogTitle: {
    defaultMessage: 'Cancel compensation review request',
    description: '[title] Cancel dialog title',
  },
  cancelDialogText: {
    defaultMessage: 'Are you sure you want to cancel this request?',
    description: 'Cancel dialog text',
  },
  cancelDialogConfirmButton: {
    defaultMessage: 'Cancel request',
    description: '[button] Compensation review request cancellation: confirm',
  },
  cancelDialogDenyButton: {
    defaultMessage: 'Keep it',
    description: '[button] Compensation review request cancellation: deny',
  },
  disabledDecisionButtonHint: {
    defaultMessage: 'Proceed once prior approvers decide',
    description: 'Disabled decision button hint',
  },
  successfulApprove: {
    defaultMessage:
      'The request has been approved{fullName, select, undefined{ on your side} other{ on behalf of {fullName}}}',
    description: 'Successful approval message',
  },
  successfulCancel: {
    defaultMessage: 'The request has been canceled',
    description: 'Successful cancellation message',
  },
  successfulReject: {
    defaultMessage:
      'The request has been rejected{fullName, select, undefined{ on your side} other{ on behalf of {fullName}}}',
    description: 'Successful rejection message',
  },
  successfulSetRecorded: {
    defaultMessage: 'Compensation data from the request has been marked as applied',
    description: 'Successful set recorded message',
  },
  successfulSetStockOptionsRecorded: {
    defaultMessage: 'Options data from the request has been marked as applied',
    description: 'Successful set recorded message',
  },
});
