import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  githubAriaLabel: {
    defaultMessage: 'Github link dialog',
    description: 'Github link dialog: aria label',
  },
  githubDialogTitle: {
    defaultMessage: 'Success',
    description: '[title] Github modal title',
  },
  githubDialogText: {
    defaultMessage:
      'Github account <link>{githubLogin}</link> has been successfully linked to your Joom Space profile.',
    description: 'Github modal text',
  },
  githubDialogSuccessButton: {
    defaultMessage: 'Nice!',
    description: '[button] Github modal button',
  },
  githubDialogCancelButton: {
    defaultMessage: 'Oh well…',
    description: '[button] Github modal button',
  },
});
