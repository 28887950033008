import {DataState} from '@joomcode/deprecated-utils/dataState';
import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {Resource} from 'domain/resource/model';
import {createStore} from 'effector';
import {createResourceFx, getResourceByIdFx, getResourcesFx, getResourcesWithCountFx, updateResourceFx} from '.';

type ResourcesCountState = {
  count: number;
};

export const $resources = createEntityStore({
  getEntityId: (resource: Resource) => resource.id,
  loadByIdFx: getResourceByIdFx,
  query: {
    fx: getResourcesFx,
    getIdsFromParams: () => [],
  },
}).on([createResourceFx.doneData, updateResourceFx.doneData], (state, result) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [result.id]: result,
    },
    byIdState: {
      ...state.byIdState,
      [result.id]: DataState.LOADED,
    },
  };
});

export const $resourceList = createEntityListStore($resources);

export const $resourcesCount = createStore<ResourcesCountState>({
  count: 0,
}).on(getResourcesWithCountFx.done, (state, {result}) => {
  return {
    ...state,
    count: result.count,
  };
});
