import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import {getAllTeamsFx} from 'domain/team/stores/main';
import {$teamList, $teams} from 'domain/team/stores/main/state';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  byId: Record<TeamId, Team | undefined>;
  teams: Team[];
  dataState: DataState;
};

type Options = {
  onError?: (error: Error) => void;
};

export function useTeams({onError}: Options = {}): ReturnShape {
  const {byId} = useStore($teams);
  const {data: teams, dataState} = useStore($teamList);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      getAllTeamsFx().catch((error) => {
        onError?.(error);
        toaster.interceptThenThrowError(error);
      });
    }
  }, []);

  return {byId, teams, dataState};
}
