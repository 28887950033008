import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {TeamRoleBindings} from 'domain/roleBinding/model';
import {getRoleBindingsByTeamIdFx} from 'domain/roleBinding/stores/team';
import {$teamRoleBindings} from 'domain/roleBinding/stores/team/state';
import {TeamId} from 'domain/team/model/id';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: TeamRoleBindings | null;
  dataState: DataState;
};

export function useTeamRoleBindings(teamId?: TeamId): ReturnShape {
  const data = useStoreMap({
    store: $teamRoleBindings,
    keys: [teamId],
    fn: ({byTeamId}, [id]) => (id && byTeamId[id]) || null,
  });
  const dataState = useStoreMap({
    store: $teamRoleBindings,
    keys: [teamId],
    fn: ({byTeamIdState}, [id]) => (id && byTeamIdState[id]) || DataState.IDLE,
  });

  useEffect(() => {
    if (teamId && !isLoadingOrLoaded(dataState)) {
      getRoleBindingsByTeamIdFx(teamId).catch(toaster.interceptThenThrowError);
    }
  }, [teamId]);

  return {
    data,
    dataState,
  };
}
