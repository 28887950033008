import {ExtractSchemaType, object} from '@joomcode/deprecated-utils/jsonValidation';
import {stockOptionSummarySchema} from 'domain/stockOption/summary/model';
import {stockOptionBuyoutSchema} from '.';

export const stockOptionBuyoutWithSummarySchema = object({
  buyout: stockOptionBuyoutSchema,
  summary: stockOptionSummarySchema,
});

export type StockOptionBuyoutWithSummary = ExtractSchemaType<typeof stockOptionBuyoutWithSummarySchema>;
