/* eslint-disable @typescript-eslint/naming-convention */
import cn from 'classnames';
import React from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../create-locator';
import styles from './index.css';
import {Option} from './Option';
import {OptionAction} from './OptionAction';
import {GroupComponent as Group} from './Group';
import {GroupTitleComponent as GroupTitle} from './GroupTitle';

export type ListboxTestId = ConvertLocatorToTestId<ListboxLocator>;
export type ListboxLocator = LocatorOfElement<void>;

type Props = Omit<JSX.IntrinsicElements['div'], 'className'> & {
  withDefaultPadding?: boolean;
} & Partial<Mark<ListboxLocator>>;

const ListboxComponent = React.forwardRef<HTMLDivElement, Props>(function Listbox(
  {withDefaultPadding, ...restPropsWithMark}: Props,
  ref,
) {
  const locator = createLocator(restPropsWithMark);
  const restProps = removeMarkFromProperties(restPropsWithMark);

  return (
    <div
      {...restProps}
      className={cn(styles.listbox, {
        [styles.listboxWithDefaultPadding]: withDefaultPadding,
      })}
      ref={ref}
      role='listbox'
      {...locator()}
    />
  );
});

export const Listbox = Object.assign(ListboxComponent, {
  Option,
  OptionAction,
  Group,
  GroupTitle,
});
