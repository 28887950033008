import {SecurePermission} from 'domain/permission/model/secure';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {User} from 'domain/user/model';
import {useAcl} from 'services/acl';

export function useStockOptionTypesVisibility(user: User) {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const isSelfUser = selfUserId === user.id;
  const canReadSubordinateOptionsData = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_SUBORDINATE);
  const canReadAnyOptionsData = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_ANY);

  return canReadAnyOptionsData || (canReadSubordinateOptionsData && !isSelfUser);
}
