import {marketDataApi} from 'domain/marketDataRecord/api';
import {
  createMarketDataRecordFx,
  deleteMarketDataRecordFx,
  getMarketDataRecordsFx,
  getMyMarketDataRecordsFx,
  getSubordinateMarketDataRecordsFx,
  updateMarketDataRecordFx,
} from '.';

createMarketDataRecordFx.use(marketDataApi.create);
deleteMarketDataRecordFx.use(marketDataApi.delete);
getMarketDataRecordsFx.use(marketDataApi.getAll);
getMyMarketDataRecordsFx.use(marketDataApi.getMy);
getSubordinateMarketDataRecordsFx.use(marketDataApi.getSubordinate);
updateMarketDataRecordFx.use(marketDataApi.update);
