import {Autocomplete, AutocompleteProps} from '@joomcode/joom-ui/Select/Autocomplete';
import {User} from 'domain/user/model';
import {compareUsersByQuery} from 'domain/user/model/compare';
import {UserSuggestion} from 'domain/user/widgets/Suggestion';
import {generalMessages} from 'i18n/messages/general';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

const trueFunction = () => true;

const sortUsers =
  (search: string | null) =>
  (a: User, b: User): number =>
    search ? compareUsersByQuery(a, b, search) : 0;

const getItemKey = (user: User) => user.id;

const renderItem = (user: User) => <UserSuggestion user={user} />;

type Props = Omit<
  AutocompleteProps<User>,
  'filter' | 'getItemKey' | 'noItemsText' | 'onInputValueChange' | 'renderItem' | 'itemToString'
>;

export function UserAutocomplete({items, ...props}: Props) {
  const intl = useIntl();

  const itemToString = useCallback(
    ({firstName, lastName, login}: User) =>
      intl.formatMessage(messages.userSuggestItem, {
        fullName: intl.formatMessage(generalMessages.fullName, {
          firstName,
          lastName,
        }),
        login,
      }),
    [intl],
  );

  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  const suggestionItems = useMemo(() => {
    if (!inputValue) {
      return items;
    }
    return items
      .filter((user) => itemToString(user).toLowerCase().includes(inputValue.toLowerCase().trim()))
      .sort(sortUsers(inputValue));
  }, [inputValue, items, itemToString]);

  return (
    <Autocomplete<User>
      getItemKey={getItemKey}
      items={suggestionItems}
      itemToString={itemToString}
      filter={trueFunction}
      noItemsText={intl.formatMessage(messages.noItems)}
      onInputValueChange={setInputValue}
      renderItem={renderItem}
      {...props}
    />
  );
}
