import cn from 'classnames';
import {HintIcon} from 'components/ui/HintIcon';
import React from 'react';
import styles from './styles.css';

type Props = React.PropsWithChildren<{
  label: string;
  hint?: React.ReactNode;
  multiline?: boolean;
}>;

export function ListedProperty({label, hint, multiline = true, children}: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.label}>
        {label}
        {hint && (
          <span className={styles.hint}>
            <HintIcon colored={false}>{hint}</HintIcon>
          </span>
        )}
      </div>
      <div className={cn(styles.value, {[styles.multiline]: multiline})}>{children}</div>
    </div>
  );
}
