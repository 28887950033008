import {ExtractSchemaType, number, object} from '@joomcode/deprecated-utils/jsonValidation';

export const brokenDownByTypeSummaryValueSchema = object({
  total: number(),
  byType: object({
    historic: number(),
    general: number(),
    performance: number(),
  }),
});

export const stockOptionSummarySchema = object({
  granted: brokenDownByTypeSummaryValueSchema,
  vested: number(),
  available: number(),
  nextYear: number(),
});

export type BrokenDownByTypeSummaryValue = ExtractSchemaType<typeof brokenDownByTypeSummaryValueSchema>;
export type StockOptionSummary = ExtractSchemaType<typeof stockOptionSummarySchema>;
export type StockOptionSummaryValue = number | BrokenDownByTypeSummaryValue;
