import {OfficeRoom} from 'domain/officeMap/room/model';
import {OfficeSeat} from 'domain/officeMap/seat/model';
import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const officeUrlPatterns = {
  root: '/office',
  roomTokens: '/office/room-tokens',
  office: '/office/:alias/',
};

export const officeUrls = {
  root: () => officeUrlPatterns.root,
  roomTokens: () => officeUrlPatterns.roomTokens,
  office: (pathParams: {alias: string}) => generatePath(officeUrlPatterns.office, pathParams),
  seat: (pathParams: {alias: string}, seat: OfficeSeat) =>
    addSearchParams(generatePath(officeUrlPatterns.office, pathParams), {seat: seat.number || seat.id}),
  room: (pathParams: {alias: string}, room: OfficeRoom) =>
    addSearchParams(generatePath(officeUrlPatterns.office, pathParams), {room: room.id}),
};
