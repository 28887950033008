import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  notChanged: {
    defaultMessage: 'This row duplicates actual data and will not be applied',
    description: 'Bulk upload: status message',
  },
  success: {
    defaultMessage: 'This row is fine and can be applied',
    description: 'Bulk upload: status message',
  },
  replace: {
    defaultMessage: 'This row will replace the other record after applying',
    description: 'Bulk upload: status message',
  },
});
