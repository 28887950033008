/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import React, {FC, ReactNode, useContext, useMemo} from 'react';
import {Menu} from '../../Menu';
import {ReactComponent as ArrowIconLarge} from '../../icons/core/arrowBottom.svg';
import {ReactComponent as ArrowIconSmall} from '../../icons/core/caretRight.svg';
import {SideMenuContext, SideMenuContextShape} from '../context';
import {SideMenuButton} from '../Item/Button';
import {SideMenuLink, SideMenuLinkProps} from '../Item/Link';
import {SideMenuItemProps} from '../Item/types';
import styles from './index.css';
import {useGroupSpoiler} from './useGroupSpoiler';

type Props = Omit<SideMenuItemProps, 'children'> & {
  children: ReactNode;
  href: string;
  kind?: SideMenuLinkProps['kind'];
  title: string;
  storageKey: string;
};

export const SideMenuGroup: FC<Props> = function SideMenuGroup({
  children,
  href,
  kind,
  title,
  storageKey,
  addon,
  ...props
}: Props) {
  const {level} = useContext(SideMenuContext);
  const childNavAside = useMemo<SideMenuContextShape>(() => ({level: level + 1}), [level]);

  const spoiler = useGroupSpoiler({isActive: Boolean(props.isActive || props.isChildrenActive), storageKey});

  if (props.isCompact || level > 0) {
    const isCompactItem = props.isCompact && level === 0;
    return (
      <Menu.Submenu
        ariaLabel={title}
        // eslint-disable-next-line react/no-unstable-nested-components
        disclosure={(disclosure, state) =>
          isCompactItem ? (
            <SideMenuButton {...props} {...disclosure} isOpen={state.isOpen}>
              {title}
            </SideMenuButton>
          ) : (
            <SideMenuLink
              {...props}
              {...disclosure}
              href={href}
              kind={kind}
              addon={addon}
              iconRight={<ArrowIconSmall className={styles.expandIconSmall} />}
            >
              {title}
            </SideMenuLink>
          )
        }
        offset={isCompactItem ? {horizontal: '0.0625rem', vertical: '-0.25rem'} : undefined}
      >
        <SideMenuContext.Provider value={childNavAside}>{children}</SideMenuContext.Provider>
      </Menu.Submenu>
    );
  }

  return (
    <SideMenuLink
      {...props}
      href={href}
      kind={kind}
      ref={spoiler.ref}
      addon={[
        addon,
        <button
          {...spoiler.getButtonProps()}
          className={cn(styles.expandButton, {[styles.disabled]: props.disabled})}
          type='button'
          key='arrow'
          tabIndex={-1}
        >
          <ArrowIconLarge className={styles.expandIcon} />
        </button>,
      ]}
      onKeyDown={spoiler.handleKeyDown}
      subItems={
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
        <section {...spoiler.getContentProps()} className={styles.content} onKeyDown={spoiler.handleKeyDown}>
          {spoiler.isExpanded && <SideMenuContext.Provider value={childNavAside}>{children}</SideMenuContext.Provider>}
        </section>
      }
    >
      {title}
    </SideMenuLink>
  );
};
