import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {boolean, ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';

export const redirectRouteIdSchema = idSchema;
export type RedirectRouteId = string;

export const redirectRouteSchema = object({
  id: redirectRouteIdSchema,
  domain: string(),
  path: string(),
  isWildcard: optional(boolean()),
  target: string(),
});

export const redirectRouteCreationConfigSchema = object({
  domain: string(),
  path: string(),
  isWildcard: optional(boolean()),
  target: string(),
});

export type RedirectRoute = ExtractSchemaType<typeof redirectRouteSchema>;
export type RedirectRouteCreationConfig = ExtractSchemaType<typeof redirectRouteCreationConfigSchema>;
