import {DataState} from '@joomcode/deprecated-utils/dataState';
import {JobContract} from 'domain/jobContract/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {
  createJobContractRecordFx,
  deleteJobContractRecordFx,
  getJobContractsByUserIdFx,
  getMyJobContractsFx,
  getSubordinateJobContractsFx,
  updateJobContractRecordFx,
} from '.';

type State = {
  byUserId: Record<UserId, JobContract[]>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $jobContracts = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on(
    [
      createJobContractRecordFx,
      getJobContractsByUserIdFx,
      getMyJobContractsFx,
      getSubordinateJobContractsFx,
      updateJobContractRecordFx,
    ],
    (state, {userId}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADING,
        },
      };
    },
  )
  .on(
    [
      createJobContractRecordFx.fail,
      getJobContractsByUserIdFx.fail,
      getMyJobContractsFx.fail,
      getSubordinateJobContractsFx.fail,
      updateJobContractRecordFx.fail,
    ],
    (state, {params: {userId}}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.FAILED,
        },
      };
    },
  )
  .on(
    [
      createJobContractRecordFx.done,
      getJobContractsByUserIdFx.done,
      getMyJobContractsFx.done,
      getSubordinateJobContractsFx.done,
      updateJobContractRecordFx.done,
    ],
    (state, {result, params: {userId}}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: result,
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADED,
        },
      };
    },
  )
  .on(deleteJobContractRecordFx.done, (state, {params: {id, userId, legalEntityId}}) => {
    const targetJobContract = state.byUserId[userId].find(({legalEntity}) => legalEntity.id === legalEntityId);

    if (!targetJobContract) {
      return state;
    }

    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: state.byUserId[userId].map((jobContract) => {
          if (jobContract !== targetJobContract) {
            return jobContract;
          }
          return {
            ...jobContract,
            jobContractRecords: jobContract.jobContractRecords.filter((record) => record.id !== id),
          };
        }),
      },
    };
  });
