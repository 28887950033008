import cn from 'classnames';
import React, {useCallback, useContext} from 'react';
import {getRemInPx} from '../../utils/getRemInPx';
import {ReactComponent as ArrowIcon} from '../../../icons/core/arrowRightThin.svg';
import {Checkbox} from '../../../Checkbox';
import {Radio} from '../../../Radio';
import {RowDisclosureContext, TableDisclosureContext} from '../../contexts/Disclosure';
import {useSelection} from '../../contexts/Selection';
import {Column, SelectionType, SortableColumnOption} from '../../types';
import styles from './index.css';

function TableCheckbox({disabled}: {disabled: boolean}) {
  const selection = useSelection();
  if (!selection) return null;
  return (
    <Checkbox
      className={cn(styles.selection, styles.checkbox, {[styles.selectionDisabled]: disabled})}
      checked={selection.isAllSelected}
      disabled={disabled}
      indeterminate={selection.isPartiallySelected}
      onChange={selection.toggleAll}
    />
  );
}

function RowCheckbox<Item>({disabled, item}: {disabled: boolean; item: Item}) {
  const selection = useSelection<Item>();
  if (!selection) return null;

  const handleChange = () => selection.toggleItem(item);

  const isDisabled = disabled || (selection.isItemDisabled && selection.isItemDisabled(item));

  return (
    <Checkbox
      className={cn(styles.selection, styles.checkbox, {[styles.selectionDisabled]: isDisabled})}
      checked={selection.isItemSelected(item)}
      disabled={isDisabled}
      onChange={handleChange}
    />
  );
}

function RowRadio<Item>({disabled, item}: {disabled: boolean; item: Item}) {
  const selection = useSelection<Item>();
  if (!selection) return null;

  const handleChange = () => selection.selectOnlyItems([item]);

  const isDisabled = disabled || (selection.isItemDisabled && selection.isItemDisabled(item));

  return (
    <div className={cn(styles.selection, styles.radio, {[styles.selectionDisabled]: isDisabled})}>
      <Radio label='' checked={selection.isItemSelected(item)} disabled={isDisabled} onChange={handleChange} />
    </div>
  );
}

export function TableDisclosure() {
  const disclosure = useContext(TableDisclosureContext);
  if (!disclosure) {
    throw new Error('TableExpander should be use inside TableDisclosureContext.Provider');
  }

  const isExpanded = disclosure.isExpanded || disclosure.isIndeterminated;
  const toggle = useCallback(() => (isExpanded ? disclosure.collapse() : disclosure.expand()), [isExpanded]);

  return (
    <button className={styles.button} onClick={toggle} type='button'>
      <ArrowIcon className={cn(styles.arrow, {[styles.arrowExpanded]: isExpanded})} />
    </button>
  );
}
export function RowDisclosure() {
  const disclosure = useContext(RowDisclosureContext);
  if (!disclosure) return null;
  return (
    <button className={styles.button} onClick={disclosure.toggle} type='button'>
      <ArrowIcon className={cn(styles.arrow, {[styles.arrowExpanded]: disclosure.isExpanded})} />
    </button>
  );
}

function getColumnWidth({
  expandable,
  selectable,
  sortable,
}: Pick<Options, 'expandable' | 'selectable' | 'sortable'>): number {
  const rem = getRemInPx();

  if (selectable && sortable && expandable) return 6.5625 * rem;
  if (expandable && selectable) return 5.25 * rem;
  if (expandable && sortable) return 5.25 * rem;
  if (selectable && sortable) return 4.625 * rem;
  if (selectable) return 4 * rem;
  if (expandable) return 3.25 * rem;
  return 0;
}

type Options = {
  disabled: boolean;

  /**
   * Whether the checkbox to select all records in the table is available.
   * It may be helpful to disable the 'select all' checkbox when no rows
   * in the table are available for selection.
   */
  disabledAllSelection?: boolean;

  expandable: boolean;
  expandableHeader?: boolean;
  selectable: boolean;
  selectionType?: SelectionType;
  sortable?: SortableColumnOption;
};

export const SELECTION_COLUMN_ID = '__check';

export function getSelectionColumn({
  disabled,
  disabledAllSelection = false,
  expandable,
  expandableHeader = true,
  selectable,
  sortable,
  selectionType = 'checkbox',
}: Options): Column<unknown> {
  return {
    id: SELECTION_COLUMN_ID,
    defaultWidth: getColumnWidth({expandable, selectable, sortable}),
    sortable,
    interactiveHeading: true,
    resizable: false,
    noStretch: true,
    sticky: 'left',
    name: (
      <div className={styles.cell}>
        {selectable &&
          (selectionType === 'checkbox' ? (
            <TableCheckbox disabled={disabled || disabledAllSelection} />
          ) : (
            <div className={cn(styles.selection, styles.radio)} /> // just empty space for radio
          ))}
        {expandable && expandableHeader && <TableDisclosure />}
      </div>
    ),
    render: (item) => (
      <div className={styles.cell}>
        {selectable &&
          (selectionType === 'checkbox' ? (
            <RowCheckbox disabled={disabled} item={item} />
          ) : (
            <RowRadio disabled={disabled} item={item} />
          ))}
        {expandable && <RowDisclosure />}
      </div>
    ),
  };
}
