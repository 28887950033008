import {intranetApi} from 'apiClient';
import {
  StockOptionBuyoutWithSummary,
  stockOptionBuyoutWithSummarySchema,
} from 'domain/stockOption/buyout/model/withSummary';
import {UserId} from 'domain/user/model';

export type StockOptionBuyoutCreateConfig = {
  amount: number;
  userId: UserId;
  date: string;
  issuer: string;
};

export const create = async ({
  userId,
  ...body
}: StockOptionBuyoutCreateConfig): Promise<StockOptionBuyoutWithSummary> => {
  const {data} = await intranetApi.post('/v1/secure/stockOptions/buyouts/create', body, {params: {userId}});

  return stockOptionBuyoutWithSummarySchema.runWithException(data);
};
