import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  menuAriaLabel: {
    defaultMessage: 'Time off request actions menu',
    description: 'Time off request actions menu: icon label',
  },
  noActionsAvailable: {
    defaultMessage: 'No actions available',
    description: 'Time off request actions menu: disabled menu button tooltip',
  },
  approve: {
    defaultMessage: 'Approve',
    description: 'Time off request actions menu: item',
  },
  cancel: {
    defaultMessage: 'Cancel',
    description: 'Time off request actions menu: item',
  },
  reject: {
    defaultMessage: 'Reject',
    description: 'Time off request actions menu: item',
  },
  setRecorded: {
    defaultMessage: 'Entered',
    description: 'Time off request actions menu: item (Mark as entered into accounting system)',
  },
  cancelConfirmationTitle: {
    defaultMessage: 'Cancel time off request',
    description: '[title] Time off requests page: cancel time off request confirmation dialog',
  },
  cancelConfirmationText: {
    defaultMessage: 'Are you sure you want to cancel this time off request?',
    description: 'Time off requests page: cancel time off request confirmation text',
  },
  cancelConfirmationButton: {
    defaultMessage: 'Cancel request',
    description: '[button] Time off requests page: cancel time off request confirmation button',
  },
  rejectConfirmationTitle: {
    defaultMessage: 'Reject time off request',
    description: '[title] Time off requests page: reject time off request confirmation dialog',
  },
  rejectConfirmationText: {
    defaultMessage: 'Are you sure you want to reject this time off request?',
    description: 'Time off requests page: reject time off request confirmation text',
  },
  rejectConfirmationButton: {
    defaultMessage: 'Reject',
    description: '[button] Time off requests page: reject time off request confirmation button',
  },
});
