import {Decoder} from '@joomcode/deprecated-utils/jsonValidation';
import {JsonSerializer, LocalStorage, SingleStorageManager} from '@joomcode/deprecated-utils/Storage';
import {useEffect, useMemo, useState} from 'react';

type Options<FilterValues> = {
  id: string;
  initialValues: FilterValues;
  valuesSchema: Decoder<FilterValues>;
};

export type StoredFilters<FilterValues> = {
  filterValues: FilterValues;
  setFilterValues: (filters: FilterValues) => void;
};

function createStorage<FilterValues>(id: string, filterValuesSchema: Decoder<FilterValues>) {
  return new SingleStorageManager(id, new LocalStorage(), new JsonSerializer(filterValuesSchema));
}

export function useStoredFilters<FilterValues>({
  id,
  initialValues,
  valuesSchema,
}: Options<FilterValues>): StoredFilters<FilterValues> {
  const storage = useMemo(() => createStorage<FilterValues>(id, valuesSchema), [id, valuesSchema]);

  const [filterValues, setFilterValues] = useState<FilterValues>(() => ({
    ...initialValues,
    ...storage?.restore(),
  }));

  useEffect(() => {
    storage?.store(filterValues);
  }, [filterValues]);

  return {filterValues, setFilterValues};
}
