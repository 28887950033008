import {IntlShape} from 'react-intl';
import {ValidationError, ValidationFormatter} from '../../types';
import {imageErrorMessages} from './messages';
import {ImageValidatorErrorCode, ImageValidatorOptions} from './types';

type ImageValidationError = ValidationError<ImageValidatorErrorCode, ImageValidatorOptions>;
type ImageValidationFormatter = ValidationFormatter<ImageValidationError>;

export const imageErrorFormatters: Record<ImageValidatorErrorCode, ImageValidationFormatter> = {
  [ImageValidatorErrorCode.IMAGE_INVALID]: (error: ImageValidationError, intl: IntlShape) =>
    intl.formatMessage(imageErrorMessages[ImageValidatorErrorCode.IMAGE_INVALID]),
  [ImageValidatorErrorCode.IMAGE_IS_TOO_SMALL]: (error: ImageValidationError, intl: IntlShape) => {
    return intl.formatMessage(imageErrorMessages[ImageValidatorErrorCode.IMAGE_IS_TOO_SMALL], {
      minWidth: error.options?.minWidth,
      minHeight: error.options?.minHeight,
    });
  },
  [ImageValidatorErrorCode.IMAGE_IS_TOO_BIG]: (error: ImageValidationError, intl: IntlShape) => {
    return intl.formatMessage(imageErrorMessages[ImageValidatorErrorCode.IMAGE_IS_TOO_BIG], {
      maxWidth: error.options?.maxWidth,
      maxHeight: error.options?.maxHeight,
    });
  },
  [ImageValidatorErrorCode.IMAGE_ASPECT_RATIO_TOO_BIG]: (error: ImageValidationError, intl: IntlShape) => {
    return intl.formatMessage(imageErrorMessages[ImageValidatorErrorCode.IMAGE_ASPECT_RATIO_TOO_BIG], {
      minAspectRatio: error.options?.minAspectRatio,
      maxAspectRatio: error.options?.maxAspectRatio,
    });
  },
  [ImageValidatorErrorCode.IMAGE_ASPECT_RATIO_TOO_SMALL]: (error: ImageValidationError, intl: IntlShape) => {
    return intl.formatMessage(imageErrorMessages[ImageValidatorErrorCode.IMAGE_ASPECT_RATIO_TOO_SMALL], {
      minAspectRatio: error.options?.minAspectRatio,
      maxAspectRatio: error.options?.maxAspectRatio,
    });
  },
};
