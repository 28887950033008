import {useIsMounted} from '@joomcode/deprecated-utils/react/useIsMounted';
import {loadAllOfficesInBackgroundFx} from 'domain/officeMap/office/stores/main';
import {useEffect, useRef} from 'react';

export function useOfficesReload() {
  const isMounted = useIsMounted();
  const updateTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    const scheduleOfficesReload = () => {
      if (isMounted()) {
        updateTimeout.current = setTimeout(() => {
          loadAllOfficesInBackgroundFx()
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
            .finally(scheduleOfficesReload);
        }, 3000);
      }
    };
    scheduleOfficesReload();
    return () => {
      if (updateTimeout.current) {
        clearTimeout(updateTimeout.current);
      }
    };
  }, []);
}
