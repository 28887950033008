import {defineMessages} from 'react-intl';
import {LoginFormattingError} from './validate';

export const loginFormatErrorMessages = defineMessages<LoginFormattingError>({
  [LoginFormattingError.TOO_SHORT]: {
    defaultMessage: 'Too short',
    description: 'Newcomer login validation: Error text for too short login',
  },
  [LoginFormattingError.TOO_LONG]: {
    defaultMessage: 'Too long',
    description: 'Newcomer login validation: Error text for too long login',
  },
  [LoginFormattingError.BAD_CHAR]: {
    defaultMessage: 'Only latin letters, numbers, underscores and periods are allowed',
    description: 'Newcomer login validation: Error text for bad login chars',
  },
  [LoginFormattingError.BAD_START_CHAR]: {
    defaultMessage: 'Login should start with a letter or a number',
    description: 'Newcomer login validation: Error text for bad login start char',
  },
  [LoginFormattingError.BAD_END_CHAR]: {
    defaultMessage: 'Login should end with a letter or a number',
    description: 'Newcomer login validation: Error text for bad login end char',
  },
  [LoginFormattingError.BAD_CHAR_PAIR]: {
    defaultMessage: 'Two underscores/periods in a row are not allowed',
    description: 'Newcomer login validation: Error text for bad login char pair',
  },
  [LoginFormattingError.CAPS_FOUND]: {
    defaultMessage: 'Capital letters are not allowed',
    description: 'Newcomer login validation: Error text for capital letter',
  },
  [LoginFormattingError.NO_LETTERS]: {
    defaultMessage: 'At least one letter is required',
    description: 'Newcomer login validation: Error text for login without letters',
  },
});
