import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Textarea, TextareaProps} from '@joomcode/joom-ui/Textarea';
import React, {memo, useCallback, useMemo, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {CommonFieldProps} from '../types';
import {messages} from './messages';
import {getFieldErrorText} from '../../utils';
import {bindValidatorOptions, validateFilled, composeValidators, getFieldValidator} from '../../validation';
import {useFieldWithInitialValue} from '../../components';

const dividerConfigByMode = {
  newLine: {
    splitter: /\n+/,
    joiner: '\n',
    hintMessage: messages.hintNewLine,
  },
  anyWhitespace: {
    splitter: /\s+/,
    joiner: ' ',
    hintMessage: messages.hintAnyWhitespace,
  },
  commaOrSemicolon: {
    splitter: /[,.;]+/,
    joiner: ', ',
    hintMessage: messages.hintCommaOrSemicolon,
  },
} as const;

type DividerMode = keyof typeof dividerConfigByMode;

export type FieldTextareaListProps = Omit<TextareaProps, 'label'> &
  CommonFieldProps<string[]> & {
    dividerMode?: DividerMode;
  };

export const FieldTextareaList = memo(function FieldTextareaList({
  dividerMode = 'anyWhitespace',
  disabled,
  error,
  hint,
  initialValue: localInitialValue,
  label,
  labelHint,
  name,
  required,
  reserveSpaceForError,
  rows = 3,
  validate,
  validateFields,
  ...textareaProps
}: FieldTextareaListProps) {
  const intl = useIntl();
  const [needFormat, setNeedFormat] = useState(false);

  const {splitter, joiner, hintMessage} = useMemo(() => dividerConfigByMode[dividerMode], [dividerMode]);
  const parse = useCallback(
    (value: string | undefined) => {
      const trimmedValue = value?.trim();
      if (!trimmedValue) {
        return [];
      }

      return trimmedValue
        .split(splitter)
        .map((part) => part.trim())
        .filter(Boolean);
    },
    [splitter],
  );
  const format = useCallback(
    (value: string[]) => (value !== undefined ? value.map((item) => item.trim()).join(joiner) : ''),
    [joiner],
  );
  const formatOnDemand = useCallback(
    (value: string[]) => {
      if (needFormat) {
        setNeedFormat(false);
        return format(value);
      }

      return undefined;
    },
    [format, needFormat],
  );

  const validateRequired = required ? bindValidatorOptions(validateFilled, {checkEmptyArrays: true}) : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {input, meta} = useFieldWithInitialValue<string[]>(name, {
    initialValue: localInitialValue,
    parse,
    format: formatOnDemand,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    validateFields,
  });

  // Format on dividerMode change
  useEffect(() => setNeedFormat(true), [format]);

  // Format on blur
  // `formatOnBlur` doesn't work well: https://github.com/final-form/react-final-form/issues/560
  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      input.onBlur(event);

      setNeedFormat(true);
      input.onChange(event.currentTarget.value);

      if (textareaProps.onBlur) {
        textareaProps.onBlur(event);
      }
    },
    [parse, input.onBlur, input.onChange, textareaProps.onBlur],
  );

  return (
    <FormControl
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl})}
      hint={hint || intl.formatMessage(hintMessage)}
      label={label}
      labelHint={labelHint}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <Textarea
          {...formControlProps}
          {...input}
          rows={rows}
          {...textareaProps}
          disabled={meta.submitting || disabled}
          onBlur={handleBlur}
        />
      )}
    </FormControl>
  );
});
