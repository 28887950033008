import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  outText: {
    defaultMessage: 'Out {dateInterval}',
    description: 'Time off text',
  },
  futureOutText: {
    defaultMessage: 'Will be out {dateInterval}',
    description: 'Future time off text',
  },
  remoteWorkText: {
    defaultMessage: 'Works remotely {dateInterval}',
    description: 'Time off text',
  },
  futureRemoteWorkText: {
    defaultMessage: 'Will work remotely {dateInterval}',
    description: 'Future time off text',
  },
});
