import React, {useCallback} from 'react';
import {noop} from '@joomcode/deprecated-utils/function';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../../create-locator';
import {Listbox} from '../../Listbox';
import type {OptionLocator} from '../../Listbox/Option';
import {SelectCore, DropdownPlacement, type SelectCoreLocator} from '../SelectCore';
import {SelectControl, InputSizeMeasure, SelectInput, SelectInputLocator, SelectControlLocator} from '../components';
import '../theme.css';

export type AutocompleteTestId = ConvertLocatorToTestId<AutocompleteLocator>;
export type AutocompleteLocator = Locator<{
  control: SelectControlLocator;
  input: SelectInputLocator;
  option: OptionLocator;
  select: SelectCoreLocator;
}>;

type Sort<Item> = (a: Item, b: Item, inputValue: string) => number;

export type AutocompleteProps<Item> = {
  autoComplete?: string | null;
  inputValue?: string;
  id?: string;
  invalid?: boolean;
  items: Item[];
  borderless?: boolean;
  disabled?: boolean;
  value: Item | null;
  clearable?: boolean;
  onChange: (newValue: Item | null) => void;
  inputSize?: `${InputSizeMeasure}`;
  itemToString?: (item: Item) => string;
  placeholder?: string;
  getItemTitle?: (item: Item) => string;
  noItemsText: string;
  filter?: (item: Item, inputValue: string) => boolean;
  sort?: Sort<Item>;
  renderItem?: (item: Item) => React.ReactNode;
  getItemKey: (item: Item) => string;
  dropdownMinWidth?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  preview?: React.ReactNode;
  isItemDisabled?: (item: Item) => boolean;
  onInputValueChange?: (inputValue: string) => void;
  name?: string;
  dropdownPlacement?: DropdownPlacement;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
} & Partial<Mark<AutocompleteLocator>>;

function filterItems<Item>(
  items: Item[],
  filter: ((item: Item, inputValue: string) => boolean) | undefined,
  inputValue: string | null,
) {
  if (!inputValue || !filter) {
    return items;
  }
  return items.filter((item) => filter(item, inputValue));
}

function sortItems<Item>(rawItems: Item[], sort: Sort<Item> | undefined, inputValue: string | null) {
  const items = [...rawItems];
  if (!inputValue || !sort) {
    return items;
  }
  items.sort((a, b) => sort(a, b, inputValue));
  return items;
}

export function Autocomplete<Item>({
  id,
  invalid,
  items,
  renderItem = (item) => item as unknown as React.ReactNode,
  itemToString = (item) => String(item),
  filter,
  sort,
  borderless = false,
  disabled = false,
  clearable = false,
  getItemTitle = itemToString,
  onKeyDown = noop,
  placeholder = '',
  preview,
  noItemsText,
  inputSize,
  getItemKey,
  isItemDisabled = () => false,
  ...rest
}: AutocompleteProps<Item>) {
  const locator = createLocator(rest);
  const noNullItemToString = useCallback(
    (item: Item | null) => (item !== null ? itemToString(item) : ''),
    [itemToString],
  );

  return (
    <SelectCore
      {...rest}
      disabled={disabled}
      withInput
      closeOnSelect
      clearValueOnSelect={false}
      preview={preview}
      itemToString={noNullItemToString}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderControl={({selectedItem, getInputProps, isOpen, isActive, disabled, clearSelection}) => {
        const inputProps = getInputProps({id, placeholder, onKeyDown});

        return (
          <SelectControl
            {...locator.control()}
            invalid={invalid}
            isActive={isActive}
            disabled={disabled}
            isOpen={isOpen}
            borderless={borderless}
            inputSize={inputSize}
            preview={preview}
            clearable={clearable && Boolean(rest.inputValue || selectedItem)}
            onClearClick={() => {
              clearSelection();
              if (inputProps.ref.current) {
                inputProps.ref.current.focus();
              }
            }}
          >
            <SelectInput
              {...locator.input()}
              inputProps={inputProps}
              onChange={rest.inputValue ? undefined : rest.onInputValueChange}
            />
          </SelectControl>
        );
      }}
      renderItems={({inputValue, getItemProps, highlightedIndex, skipFilter}) => {
        const filteredItems = skipFilter ? items : filterItems(items, filter, inputValue);
        const sortedItems = sortItems(filteredItems, sort, inputValue);
        if (sortedItems.length === 0) {
          return (
            <Listbox.Option {...locator.option({})} disabled isHighlighted={false}>
              {noItemsText}
            </Listbox.Option>
          );
        }

        return sortedItems.map((item, index) => (
          <Listbox.Option
            {...locator.option()}
            key={getItemKey(item)}
            isHighlighted={highlightedIndex === index}
            {...getItemProps({
              item,
              index,
              disabled: isItemDisabled(item),
              tabIndex: -1,
            })}
            title={getItemTitle(item)}
          >
            {renderItem(item)}
          </Listbox.Option>
        ));
      }}
      {...locator.select()}
    />
  );
}
