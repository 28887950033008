import {QueryRequestPayload} from 'api/types';
import {CountableRoleBindigns, RoleBinding, RoleBindingsSearchFilters} from 'domain/roleBinding/model';
import {RoleBindingStatus} from 'domain/roleBinding/model/status';
import {getRoleBindingsWithCountFx} from 'domain/roleBinding/stores/main';
import {getActiveRoleBindingsFx, getActiveRoleBindingsWithCountFx} from '.';

const getActiveRoleBindingsWithCount = async (
  query: QueryRequestPayload<RoleBindingsSearchFilters>,
): Promise<CountableRoleBindigns> =>
  getRoleBindingsWithCountFx({
    ...query,
    filters: {
      ...query.filters,
      statuses: [RoleBindingStatus.APPROVED],
    },
  });

const getActiveRoleBindingsWithoutCount = async (
  query: QueryRequestPayload<RoleBindingsSearchFilters>,
): Promise<RoleBinding[]> => {
  const {items} = await getActiveRoleBindingsWithCountFx(query);
  return items;
};

getActiveRoleBindingsWithCountFx.use(getActiveRoleBindingsWithCount);
getActiveRoleBindingsFx.use(getActiveRoleBindingsWithoutCount);
