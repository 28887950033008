import {Column} from 'domain/managerConsole/model/column';
import {fullColumnsTree} from 'domain/userFullInfoRecord/model/columns/tree';
import {NoAccessLock} from 'domain/userFullInfoRecord/widgets/NoAccessLock';
import React from 'react';

export function makeColumnLockable({id, render, ...rest}: Column): Column {
  return {
    id,
    render: (item) => {
      const access = fullColumnsTree.find(({key}) => key === id)?.access;
      if (access && !item.availability[access]) {
        return <NoAccessLock />;
      }
      return render(item);
    },
    ...rest,
  };
}
