/* eslint-disable  @typescript-eslint/naming-convention */
export enum HttpStatus {
  Ok = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  LimitReached = 429,
  InternalServerError = 500,
}
