import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Documents and legal information',
    description: '[title] Offer newcomer application: documents panel title',
  },
  linkToGoogleDrive: {
    defaultMessage: 'View documents on <link>Google Drive</link>',
    description: 'Newcomer documents panel: link to Google Drive',
  },
  noticeForHr: {
    defaultMessage:
      'On the first working day we’ll send <emphasis>NDA</emphasis>, <emphasis>Deed of Non-competition</emphasis> and <emphasis>Options Agreement</emphasis> to {login}@joom.com. You can edit passport data here until the application is approved. After approval you can edit passport data in the Joomer‘s profile.',
    description: 'Newcomer documents panel: notice for HR admin',
  },
  btnDigitize: {
    defaultMessage: 'Digitize',
    description: '[button] Newcomer documents panel: toolbar button',
  },
  btnEdit: {
    defaultMessage: 'Edit',
    description: '[button] Newcomer documents panel: toolbar button',
  },
  btnDownloadSigningPages: {
    defaultMessage: 'Download signing pages',
    description: '[button] Newcomer documents panel: toolbar button',
  },
});
