import {FormattedPrice} from 'components/ui/FormattedPrice';
import {Frequency} from 'domain/frequency/model';
import {formatFrequency} from 'domain/frequency/model/formatter';
import {Money} from 'domain/money/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  money: Money;
  frequency: Frequency;
};

export function FormattedSalary({money, frequency}: Props) {
  const intl = useIntl();

  return (
    <>
      {intl.formatMessage(messages.formattedSalary, {
        amount: <FormattedPrice value={money} />,
        frequency: formatFrequency(frequency, intl),
      })}
    </>
  );
}
