import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Grid} from '@joomcode/joom-ui/Grid';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {HintIcon} from 'components/ui/HintIcon';
import {ListedProperty} from 'components/ui/ListedProperty';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {LegalInfo} from 'domain/legalInfo/model';
import {buildSigningPagesDownloadLink} from 'domain/offer/api/downloadSigningPages';
import {OfferId} from 'domain/offer/model';
import {NewcomerInOffer} from 'domain/offer/model/newcomer';
import {educationLevelLabels} from 'domain/offer/model/newcomer/educationLevel/messages';
import {fiscalSituationLabels} from 'domain/offer/model/newcomer/fiscalSituation/messages';
import {maritalStatusLabels} from 'domain/offer/model/newcomer/maritalStatus/messages';
import {OfferStatus} from 'domain/offer/model/status';
import {NewcomerFormTemplateCountry} from 'domain/offer/model/templateCountry';
import {NewcomerDocumentDownloadButton} from 'domain/offer/widgets/NewcomerDocumentDownloadButton';
import {NewcomerDocumentInfo} from 'domain/offer/widgets/NewcomerDocumentInfo';
import {Permission} from 'domain/permission/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {offerUrls} from 'routes/offer/urls';
import {useAcl} from 'services/acl';
import {getCountryName} from 'utils/country/name';
import {documentNamesByCountry, documentNamesCommon, labels, messages} from './messages';
import styles from './styles.css';

type Props = {
  documentsData?: LegalInfo;
  newcomer: NewcomerInOffer;
  offerId: OfferId;
  offerStatus: OfferStatus;
  templateCountry: NewcomerFormTemplateCountry | undefined;
};

export function NewcomerDocumentsPanel({documentsData, newcomer, offerId, offerStatus, templateCountry}: Props) {
  const {
    accountNumber,
    bankIdentificationCode,
    bankName,
    children,
    documents,
    educationLevel,
    fiscalSituation,
    insuranceNumber,
    linkToGoogleDrive,
    login,
    maritalStatus,
    placeOfResidence,
    taxNumber,
  } = newcomer;

  const acl = useAcl();
  const intl = useIntl();

  const canDownload = acl.hasPermission(Permission.OFFER_DOCUMENT_DOWNLOAD);
  const canDownloadSigningPages = acl.hasPermission(Permission.OFFER_SIGNING_PAGES_DOWNLOAD);
  const canDigitize = acl.hasPermission(Permission.OFFER_ENHANCE);
  const documentNames = {
    ...documentNamesCommon,
    ...((templateCountry && documentNamesByCountry[templateCountry]) ?? {}),
  };

  return (
    <Panel
      withPadding
      title={
        <>
          {intl.formatMessage(messages.title)}
          {canDigitize && login && (
            <HintIcon>
              {intl.formatMessage(messages.noticeForHr, {
                login,
                emphasis: (text) => <span className={styles.emphasis}>{text}</span>,
              })}
            </HintIcon>
          )}
        </>
      }
      subTitle={
        canDownload &&
        linkToGoogleDrive &&
        intl.formatMessage(messages.linkToGoogleDrive, {
          link: (text) => (
            <StyledExternalLink href={linkToGoogleDrive} colored withIcon>
              {text}
            </StyledExternalLink>
          ),
        })
      }
      withMarginBottom={false}
      toolbarTop={
        <ButtonGroup spaced size='m'>
          {[OfferStatus.DIGITIZED, OfferStatus.APPROVING, OfferStatus.COMPLETED].includes(offerStatus) &&
            canDownloadSigningPages && (
              <DownloadLinkButton
                kind='primary'
                intent='neutral'
                href={buildSigningPagesDownloadLink(offerId)}
                iconLeft={<DownloadIcon />}
                target='_blank'
              >
                {intl.formatMessage(messages.btnDownloadSigningPages)}
              </DownloadLinkButton>
            )}
          {canDigitize && [OfferStatus.REVIEW, OfferStatus.DIGITIZED].includes(offerStatus) && (
            <RouterLinkButton size='m' kind='primary' intent='neutral' to={offerUrls.enhance({id: offerId})}>
              {intl.formatMessage(offerStatus === OfferStatus.DIGITIZED ? messages.btnEdit : messages.btnDigitize)}
            </RouterLinkButton>
          )}
        </ButtonGroup>
      }
    >
      <div className={styles.content}>
        <Grid>
          {documentsData && (
            <Grid.Item xl={6} md={12}>
              <h3 className={styles.groupTitle}>{intl.formatMessage(labels.name)}</h3>
              <div className={styles.properties}>
                <ListedProperty label={intl.formatMessage(labels.firstName)}>{documentsData.firstName}</ListedProperty>
                {documentsData.middleName && (
                  <ListedProperty label={intl.formatMessage(labels.middleName)}>
                    {documentsData.middleName}
                  </ListedProperty>
                )}
                <ListedProperty label={intl.formatMessage(labels.lastName)}>{documentsData.lastName}</ListedProperty>
              </div>
            </Grid.Item>
          )}
          {(documents?.idCard || documentsData?.internationalPassport) && (
            <Grid.Item xl={6} md={12}>
              <h3 className={styles.groupTitle}>
                {intl.formatMessage(documentNames.internationalPassport)}
                {documents?.idCard && canDownload && (
                  <NewcomerDocumentDownloadButton offerId={offerId} fileMeta={documents.idCard} />
                )}
              </h3>
              {documentsData?.internationalPassport && (
                <div className={styles.properties}>
                  <ListedProperty label={intl.formatMessage(labels.country)}>
                    {getCountryName(documentsData.internationalPassport.country, intl)}
                  </ListedProperty>
                  <ListedProperty label={intl.formatMessage(labels.number)}>
                    {documentsData.internationalPassport.number}
                  </ListedProperty>
                  {documentsData.internationalPassport.expirationDate && (
                    <ListedProperty label={intl.formatMessage(labels.expirationDate)}>
                      <HumanDate value={documentsData.internationalPassport.expirationDate} format='short' utc />
                    </ListedProperty>
                  )}
                </div>
              )}
            </Grid.Item>
          )}
          {(documents?.internalPassportMainPage || documentsData?.localId) && (
            <Grid.Item xl={6} md={12}>
              <h3 className={styles.groupTitle}>
                {intl.formatMessage(documentNames.localPassport)}
                {documents?.internalPassportMainPage && canDownload && (
                  <NewcomerDocumentDownloadButton offerId={offerId} fileMeta={documents.internalPassportMainPage} />
                )}
              </h3>
              {documentsData?.localId && (
                <div className={styles.properties}>
                  <ListedProperty label={intl.formatMessage(labels.country)}>
                    {getCountryName(documentsData.localId.country, intl)}
                  </ListedProperty>
                  <ListedProperty label={intl.formatMessage(labels.number)}>
                    {documentsData.localId.number}
                  </ListedProperty>
                </div>
              )}
            </Grid.Item>
          )}
          {(documents?.taxNumberFile ||
            documents?.insuranceNumberFile ||
            taxNumber ||
            insuranceNumber ||
            children !== undefined ||
            educationLevel ||
            fiscalSituation ||
            maritalStatus) && (
            <Grid.Item xl={6} md={12}>
              <h3 className={styles.groupTitle}>{intl.formatMessage(labels.taxDetails)}</h3>
              <div className={styles.properties}>
                {taxNumber && (
                  <ListedProperty label={intl.formatMessage(documentNames.taxNumber)}>{taxNumber}</ListedProperty>
                )}
                {documents?.taxNumberFile && (
                  <ListedProperty label={intl.formatMessage(documentNames.taxNumber)} multiline={false}>
                    <NewcomerDocumentInfo offerId={offerId} document={documents?.taxNumberFile} />
                  </ListedProperty>
                )}
                {insuranceNumber && (
                  <ListedProperty label={intl.formatMessage(documentNames.insuranceNumber)}>
                    {insuranceNumber}
                  </ListedProperty>
                )}
                {documents?.insuranceNumberFile && (
                  <ListedProperty label={intl.formatMessage(documentNames.insuranceNumber)} multiline={false}>
                    <NewcomerDocumentInfo offerId={offerId} document={documents?.insuranceNumberFile} />
                  </ListedProperty>
                )}
                {children !== undefined && (
                  <ListedProperty label={intl.formatMessage(labels.children)}>
                    {intl.formatNumber(children)}
                  </ListedProperty>
                )}
                {educationLevel && (
                  <ListedProperty label={intl.formatMessage(labels.educationLevel)}>
                    {intl.formatMessage(educationLevelLabels[educationLevel])}
                  </ListedProperty>
                )}
                {fiscalSituation && (
                  <ListedProperty label={intl.formatMessage(labels.fiscalSituation)}>
                    {intl.formatMessage(fiscalSituationLabels[fiscalSituation])}
                  </ListedProperty>
                )}
                {maritalStatus && (
                  <ListedProperty label={intl.formatMessage(labels.maritalStatus)}>
                    {intl.formatMessage(maritalStatusLabels[maritalStatus])}
                  </ListedProperty>
                )}
              </div>
            </Grid.Item>
          )}
          {(documents?.bankDetails || bankIdentificationCode || bankName || accountNumber) && (
            <Grid.Item xl={6} md={12}>
              <h3 className={styles.groupTitle}>
                {intl.formatMessage(labels.bankDetails)}
                {documents?.bankDetails && canDownload && (
                  <NewcomerDocumentDownloadButton offerId={offerId} fileMeta={documents.bankDetails} />
                )}
              </h3>
              {(bankIdentificationCode || bankName || accountNumber) && (
                <div className={styles.properties}>
                  {bankIdentificationCode && (
                    <ListedProperty label={intl.formatMessage(documentNames.bankIdentificationCode)}>
                      {bankIdentificationCode}
                    </ListedProperty>
                  )}
                  {bankName && (
                    <ListedProperty label={intl.formatMessage(documentNames.bankName)}>{bankName}</ListedProperty>
                  )}
                  {accountNumber && (
                    <ListedProperty label={intl.formatMessage(documentNames.accountNumber)}>
                      {accountNumber}
                    </ListedProperty>
                  )}
                </div>
              )}
            </Grid.Item>
          )}
          {(documents?.internalPassportAddressPage ||
            documentsData?.addressLine ||
            documentsData?.legalAddress ||
            placeOfResidence) && (
            <Grid.Item xl={6} md={12}>
              <h3 className={styles.groupTitle}>
                {intl.formatMessage(labels.placeOfCurrentResidence)}
                {documents?.internalPassportAddressPage && canDownload && (
                  <NewcomerDocumentDownloadButton offerId={offerId} fileMeta={documents.internalPassportAddressPage} />
                )}
              </h3>
              {(documentsData?.addressLine || documentsData?.legalAddress || placeOfResidence) && (
                <div className={styles.properties}>
                  <ListedProperty label={intl.formatMessage(labels.address)}>
                    {documentsData?.addressLine || documentsData?.legalAddress || placeOfResidence}
                  </ListedProperty>
                </div>
              )}
            </Grid.Item>
          )}
        </Grid>
      </div>
    </Panel>
  );
}
