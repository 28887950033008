import {getConsolidatedAccessDependentDataState} from '@joomcode/deprecated-utils/dataState';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Grid} from '@joomcode/joom-ui/Grid';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {useOffer} from 'domain/offer/hooks/useOffer';
import {useOfferCompensations} from 'domain/offer/hooks/useOfferCompensations';
import {useOfferCompensationsComment} from 'domain/offer/hooks/useOfferCompensationsComment';
import {useOfferStockOptions} from 'domain/offer/hooks/useOfferStockOptions';
import {offerIdSchema} from 'domain/offer/model';
import {OfferStatus} from 'domain/offer/model/status';
import {OfferAcceptDialog} from 'domain/offer/widgets/AcceptDialog';
import {OfferDeclineDialog} from 'domain/offer/widgets/DeclineDialog';
import {OfferDetailsPanel} from 'domain/offer/widgets/DetailsPanel';
import {OfferGeneralInfoPanel} from 'domain/offer/widgets/GeneralInfoPanel';
import {OfferHistoryPanel} from 'domain/offer/widgets/HistoryPanel';
import {NewcomerLinkGeneratorPanel} from 'domain/offer/widgets/LinkGeneratorPanel';
import {NewcomerDocumentsPanel} from 'domain/offer/widgets/NewcomerDocumentsPanel';
import {NewcomerProfilePanel} from 'domain/offer/widgets/NewcomerProfilePanel';
import {Permission} from 'domain/permission/model';
import {generalMessages} from 'i18n/messages/general';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/offer/titles';
import {offerUrls} from 'routes/offer/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';

export function OfferPage() {
  const acl = useAcl();
  const intl = useIntl();
  const {id} = useTypedParams({id: offerIdSchema});

  const {data: offerData, dataState: offerDataState, clientErrorStatus: offerError} = useOffer(id);
  const {
    data: offerCompensationsData,
    dataState: offerCompensationsDataState,
    clientErrorStatus: offerCompensationsError,
    hasReadAccess: hasCompensationReadAccess,
  } = useOfferCompensations(offerData);
  const {
    data: offerStockOptionsData,
    dataState: offerStockOptionsDataState,
    exist: offerStockOptionsExist,
    clientErrorStatus: offerStockOptionsError,
    readAccess: offerStockOptionsReadAccess,
  } = useOfferStockOptions(offerData);
  const {
    data: offerCompensationsCommentData,
    dataState: offerCompensationsCommentDataState,
    clientErrorStatus: offerCompensationsCommentError,
    hasReadAccess: hasCompensationCommentReadAccess,
  } = useOfferCompensationsComment(offerData);

  const dataState = getConsolidatedAccessDependentDataState(
    [offerDataState, true],
    [offerCompensationsDataState, hasCompensationReadAccess],
    [offerStockOptionsDataState, Boolean(offerStockOptionsReadAccess)],
    [offerCompensationsCommentDataState, hasCompensationCommentReadAccess],
  );

  const declineDialog = usePopupState();
  const acceptDialog = usePopupState();

  if (!id) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler
      data={
        offerData && {
          offer: offerData,
          compensations: offerCompensationsData,
          compensationsComment: offerCompensationsCommentData,
          stockOptions: offerStockOptionsData,
          stockOptionsExist: offerStockOptionsExist,
          stockOptionsReadAccess: offerStockOptionsReadAccess,
        }
      }
      entityError={offerError || offerCompensationsError || offerStockOptionsError || offerCompensationsCommentError}
      state={dataState}
    >
      {({offer, compensations, compensationsComment, stockOptions, stockOptionsExist, stockOptionsReadAccess}) => {
        const needToShowHistory = offer.versions.length > 1 && compensations && stockOptions;

        return (
          <Page
            title={intl.formatMessage(generalMessages.fullName, {
              firstName: offer.general.firstName,
              lastName: offer.general.lastName,
            })}
            breadcrumbs={[{title: intl.formatMessage(pageTitles.offers), href: offerUrls.root()}]}
            actions={
              acl.hasPermission(Permission.OFFER_CHANGE_STATUS) && (
                <ButtonGroup size='m'>
                  {![OfferStatus.APPROVING, OfferStatus.DECLINED].includes(offer.status) && (
                    <Button kind='primary' intent='neutral' onClick={declineDialog.open}>
                      {intl.formatMessage(messages.buttonDeclined)}
                    </Button>
                  )}
                  {offer.status === OfferStatus.CREATED && (
                    <Button kind='primary' intent='neutral' onClick={acceptDialog.open}>
                      {intl.formatMessage(messages.buttonAccepted)}
                    </Button>
                  )}
                </ButtonGroup>
              )
            }
          >
            {acl.hasPermission(Permission.OFFER_GENERATE_TOKEN) && offer.status === OfferStatus.ACCEPTED && (
              <NewcomerLinkGeneratorPanel
                templateCountry={offer.general.templateCountry}
                nameToken={offer.nameToken}
                offerId={offer.id}
              />
            )}
            <OfferGeneralInfoPanel offer={offer} />
            <Grid>
              <Grid.Item xl={needToShowHistory ? 8 : 12} lg={12}>
                <OfferDetailsPanel
                  offer={offer}
                  compensations={compensations}
                  compensationsComment={compensationsComment}
                  stockOptions={stockOptions}
                  stockOptionsExist={stockOptionsExist}
                  stockOptionsReadAccess={stockOptionsReadAccess}
                />
              </Grid.Item>
              {needToShowHistory && (
                <Grid.Item xl={4} lg={12}>
                  <OfferHistoryPanel offer={offer} compensations={compensations} stockOptions={stockOptions} />
                </Grid.Item>
              )}
              {offer.newcomer && (
                <Grid.Item xl={12}>
                  <NewcomerProfilePanel newcomer={offer.newcomer} offerId={offer.id} offerStatus={offer.status} />
                  {(offer.documentsData || offer.newcomer.documents) && (
                    <NewcomerDocumentsPanel
                      newcomer={offer.newcomer}
                      offerId={offer.id}
                      offerStatus={offer.status}
                      documentsData={offer.documentsData}
                      templateCountry={offer.general.templateCountry}
                    />
                  )}
                </Grid.Item>
              )}
            </Grid>
            {declineDialog.isOpen && <OfferDeclineDialog offerId={offer.id} onClose={declineDialog.close} />}
            {acceptDialog.isOpen && <OfferAcceptDialog offer={offer} onClose={acceptDialog.close} />}
          </Page>
        );
      }}
    </EntityPageStateHandler>
  );
}
