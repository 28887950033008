export enum DurationPart {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

export type DurationFormSchema = {
  [DurationPart.DAYS]?: number;
  [DurationPart.HOURS]?: number;
  [DurationPart.MINUTES]?: number;
  [DurationPart.SECONDS]?: number;
};
