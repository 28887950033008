import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {SecurePermission} from 'domain/permission/model/secure';

const buildLinkGeneric = (endpoint: string) => (date: string) =>
  intranetApi.getUri({
    url: `${intranetApi.defaults.baseURL}${endpoint}`,
    params: {date},
  });

const buildLinkByDate = buildLinkGeneric('/v1/secure/users/salaryRecords/downloadByEffectiveDate');
const buildLinkExtendedByDate = buildLinkGeneric('/v1/secure/users/salaryRecords/downloadExtendedByEffectiveDate');

export const buildDownloadByDateLink = createPermissionDependentHandler(
  [{securePermission: SecurePermission.SALARY_RECORD_DOWNLOAD_EXTENDED, handler: buildLinkExtendedByDate}],
  buildLinkByDate,
);
