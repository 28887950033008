import {NewcomerFormTemplateCountry} from 'domain/offer/model/templateCountry';
import {defineMessages, MessageDescriptor} from 'react-intl';

export const documentNamesCommon = defineMessages({
  accountNumber: {
    defaultMessage: 'Account number',
    description: 'Newcomer document name label',
  },
  bankIdentificationCode: {
    defaultMessage: 'BIC',
    description: 'Newcomer document name label',
  },
  bankName: {
    defaultMessage: 'Bank name',
    description: 'Newcomer document name label',
  },
  insuranceNumber: {
    defaultMessage: 'SNILS',
    description: 'Newcomer document name label',
  },
  placeOfResidence: {
    defaultMessage: 'Place of residence',
    description: 'Newcomer document name label',
  },
  taxNumber: {
    defaultMessage: 'TIN',
    description: 'Newcomer document name label',
  },
  updatedTime: {
    defaultMessage: 'Updated',
    description: 'Newcomer document name label',
  },
  internationalPassport: {
    defaultMessage: 'International passport',
    description: 'Newcomer document name label',
  },
  localPassport: {
    defaultMessage: 'Local passport (ID)',
    description: 'Newcomer document name label',
  },
});

export const documentNamesByCountry: Partial<Record<NewcomerFormTemplateCountry, Record<string, MessageDescriptor>>> = {
  [NewcomerFormTemplateCountry.RUSSIA]: defineMessages({
    idCardFileId: {
      defaultMessage: 'International passport',
      description: 'Newcomer document name label: Russia',
    },
    localPassport: {
      defaultMessage: 'Internal passport (main page)',
      description: 'Newcomer document name label: Russia',
    },
  }),
  [NewcomerFormTemplateCountry.ARMENIA]: defineMessages({
    idCardFileId: {
      defaultMessage: 'International passport',
      description: 'Newcomer document name label: Armenia',
    },
    localPassport: {
      defaultMessage: 'Internal passport (main page)',
      description: 'Newcomer document name label: Armenia',
    },
    insuranceNumber: {
      defaultMessage: 'Social card',
      description: 'Newcomer document name label: Armenia',
    },
  }),
  [NewcomerFormTemplateCountry.PORTUGAL]: defineMessages({
    accountNumber: {
      defaultMessage: 'Euro account IBAN',
      description: 'Newcomer document name label: Portugal',
    },
    idCardFileId: {
      defaultMessage: 'International passport',
      description: 'Newcomer document name label: Portugal',
    },
    insuranceNumber: {
      defaultMessage: 'Social security number (NISS)',
      description: 'Newcomer document name label: Portugal',
    },
    localPassport: {
      defaultMessage: 'Citizen or residence card or visa',
      description: 'Newcomer document name label: Portugal',
    },
    taxNumber: {
      defaultMessage: 'Tax identification number (NIF)',
      description: 'Newcomer document name label: Portugal',
    },
  }),
};
