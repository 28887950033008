import {intranetApi} from 'apiClient';
import {SecureRole, SecureRoleDiff, SecureRoleId, secureRoleSchema} from 'domain/secureRole/model';

export type SecureRoleUpdateConfig = {
  diff: Partial<SecureRoleDiff>;
  id: SecureRoleId;
};

export const update = async ({diff, id}: SecureRoleUpdateConfig): Promise<SecureRole> => {
  const {data} = await intranetApi.post('/v1/secureRoles/update', diff, {params: {id}});

  return secureRoleSchema.runWithException(data);
};
