import {StyledLink} from 'components/ui/StyledLink';
import {Offer} from 'domain/offer/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {offerUrls} from 'routes/offer/urls';
import {formatFullName} from 'utils/format/formatFullName';

export type Props = {
  offer: Offer;
};

export function OfferLink({offer}: Props) {
  const intl = useIntl();
  const {firstName, lastName} = offer.general;

  return (
    <StyledLink colored to={offerUrls.offer({id: offer.id})}>
      {formatFullName(firstName, lastName, intl)}
    </StyledLink>
  );
}
