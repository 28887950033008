import {CommonFieldProps, getFieldValidator} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {FieldFloat, FieldFloatValue} from 'components/widgets/fields/FieldFloat';
import {Currency} from 'domain/currency/model';
import React from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import styles from './styles.css';

export type Props = CommonFieldProps<FieldFloatValue> & {
  invalid?: boolean;
  currency?: Currency;
};

export function FieldSalaryAmount({
  name,
  label,
  hint,
  required,
  disabled,
  reserveSpaceForError,
  validate,
  error,
  extraErrorMessages,
  currency,
  ...restProps
}: Props) {
  const intl = useIntl();

  return (
    <Field<FieldFloatValue>
      name={name}
      validate={validate && getFieldValidator(validate)}
      subscription={{value: true, active: true, error: true}}
    >
      {({input, meta}) => (
        <FormControl
          hint={hint}
          label={label}
          disabled={meta.submitting || disabled}
          error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
          required={required}
          reserveSpaceForError={reserveSpaceForError}
        >
          {(formControlProps) =>
            currency ? (
              <div className={styles.inputWrap}>
                <FieldFloat
                  name={input.name}
                  {...formControlProps}
                  {...restProps}
                  className={styles.input}
                  disabled={meta.submitting || disabled}
                />
                <div className={styles.currency}>{currency}</div>
              </div>
            ) : (
              <FieldFloat
                name={input.name}
                {...formControlProps}
                {...restProps}
                disabled={meta.submitting || disabled}
              />
            )
          }
        </FormControl>
      )}
    </Field>
  );
}
