import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  manager: {
    defaultMessage: 'Manager',
    description: '[title] User profile: section title',
  },
  subordinates: {
    defaultMessage: 'Direct reports ({count})',
    description: '[title] User profile: section title',
  },
  about: {
    defaultMessage: 'About me',
    description: '[title] User profile: section title',
  },
  teams: {
    defaultMessage: 'Works in teams',
    description: '[title] User profile: section title',
  },
  address: {
    defaultMessage: 'Address',
    description: '[title] User profile: section title',
  },
  emergencyContact: {
    defaultMessage: 'Emergency contact',
    description: '[title] User profile: section title',
  },
});
