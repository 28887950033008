import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {SearchHighlighter} from 'components/ui/SearchHighlighter';
import {Tree} from 'components/ui/Tree';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import {TreeViewState} from 'hooks/useTreeView/types';
import sortBy from 'lodash/sortBy';
import React, {useCallback} from 'react';
import {highlightSearchWords} from 'utils/search/highlight';
import {TeamsTreeItem} from './TreeItem';

type Props = {
  normailzedSearchQuery?: string;
  onItemToggle: (params: {id: TeamId}) => void;
  showUsers: boolean;
  teamById: Record<TeamId, Team | undefined>;
  viewState: TreeViewState<TeamId>;
};

export function TeamsTree({normailzedSearchQuery, onItemToggle, showUsers, teamById, viewState}: Props) {
  const highlight = useCallback(
    (s: string): React.ReactNode =>
      highlightSearchWords(s, normailzedSearchQuery ? [normailzedSearchQuery] : [], SearchHighlighter),
    [normailzedSearchQuery],
  );

  const renderTree = (teamIds: TeamId[], depth: number) => {
    const teams = teamIds.map((teamId) => teamById[teamId]).filter(isNotNullish);
    const sortedTeams = sortBy(teams, [({priorityNumber}) => priorityNumber, ({name}) => name.toLowerCase()]);
    return sortedTeams.map((team) => {
      const viewItem = viewState.itemsById[team.id];
      return (
        <TeamsTreeItem
          depth={depth}
          expanded={viewItem.expanded}
          highlightSearch={normailzedSearchQuery ? highlight : undefined}
          onArrowClick={() => onItemToggle({id: team.id})}
          showUsers={showUsers}
          team={team}
          key={team.id}
        >
          {viewItem.subitemIds.length > 0 && renderTree(viewItem.subitemIds, depth + 1)}
        </TeamsTreeItem>
      );
    });
  };

  return <Tree>{renderTree(viewState.rootIds, 0)}</Tree>;
}
