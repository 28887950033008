import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Time off requests downloading dialog',
    description: 'Time off requests downloading dialog: aria label',
  },
  buttonDownload: {
    defaultMessage: 'Download requests',
    description: '[button] Time off requests downloading button',
  },
});
