import cn from 'classnames';
import React from 'react';
import styles from './index.css';

export const badgeIntent = {
  negative: 'negative',
  neutral: 'neutral',
  positive: 'positive',
  primary: 'primary',
  warning: 'warning',
} as const;

export type NotificationBadgeIntent = keyof typeof badgeIntent;

const classNameByIntent: {[key in NotificationBadgeIntent]: string} = {
  [badgeIntent.negative]: styles.badgeNegative,
  [badgeIntent.neutral]: styles.badgeNeutral,
  [badgeIntent.positive]: styles.badgePositive,
  [badgeIntent.primary]: styles.badgePrimary,
  [badgeIntent.warning]: styles.badgeWarning,
};

export const badgePosition = {
  topStart: 'topStart',
  topEnd: 'topEnd',
  inlineStart: 'inlineStart',
  inlineEnd: 'inlineEnd',
} as const;

export type NotificationBadgePosition = keyof typeof badgePosition;

const classNameByPosition: {[key in NotificationBadgePosition]: string} = {
  [badgePosition.topStart]: styles.badgeTopStart,
  [badgePosition.topEnd]: styles.badgeTopEnd,
  [badgePosition.inlineStart]: styles.badgeInlineStart,
  [badgePosition.inlineEnd]: styles.badgeInlineEnd,
};

type Props = {
  children?: React.ReactNode;
  intent: NotificationBadgeIntent;
  position?: NotificationBadgePosition;
};

export function NotificationBadge({children, intent, position}: Props) {
  return (
    <div className={cn(styles.badge, classNameByIntent[intent], position && classNameByPosition[position])}>
      {children}
    </div>
  );
}
