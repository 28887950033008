import {UserFormMode} from 'domain/user/model/formMode';
import {defineMessages} from 'react-intl';

export const modeTabNames = defineMessages<UserFormMode>({
  [UserFormMode.GENERAL]: {
    defaultMessage: 'General',
    description: '[title] User edit form title',
  },
  [UserFormMode.CONTACTS]: {
    defaultMessage: 'Contact info',
    description: '[title] User edit form title',
  },
});

export const messages = defineMessages({
  editLegalInfoTab: {
    defaultMessage: 'Legal data',
    description: 'User edit: navigation tab name',
  },
  editJobTab: {
    defaultMessage: 'Job information',
    description: 'User edit: navigation tab name',
  },
});
