import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  user: {
    defaultMessage: 'Profile',
    description: '[title] Page title',
  },
  outstaffUser: {
    defaultMessage: 'Outstaff Profile',
    description: '[title] Page title',
  },
  userEdit: {
    defaultMessage: 'Edit profile',
    description: '[title] Page title',
  },
  userEditLegalInfo: {
    defaultMessage: 'Edit profile',
    description: '[title] Page title',
  },
  userJob: {
    defaultMessage: 'Edit profile',
    description: '[title] Page title',
  },
  users: {
    defaultMessage: 'Joomers',
    description: '[title] Page title',
  },
  vacationBalance: {
    defaultMessage: 'Vacation balance records',
    description: '[title] Page title',
  },
  timeOffBalance: {
    defaultMessage: 'Time off balance records',
    description: '[title] Page title',
  },
});
