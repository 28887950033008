import cn from 'classnames';
import React from 'react';
import styles from './index.css';

export type Direction = 'horizontal' | 'vertical';

type Props = {
  ariaLabel: string;
  children: React.ReactNode;
  className?: string;
  direction?: Direction;
};

export function RadioGroup({ariaLabel, children, className, direction = 'vertical'}: Props) {
  const itemClassName = cn({
    [styles.itemHorizontal]: direction === 'horizontal',
    [styles.itemVertical]: direction === 'vertical',
  });

  return (
    <div aria-label={ariaLabel} className={cn(className, styles.radioGroup)} role='group'>
      {React.Children.map(children, (child) => (
        <div className={itemClassName}>{child}</div>
      ))}
    </div>
  );
}
