import {intranetApi} from 'apiClient';
import {Offer, offerSchema} from 'domain/offer/model';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {OfferEmploymentInfo} from 'domain/offer/model/employmentInfo';
import {OfferGeneralInfo} from 'domain/offer/model/generalInfo';
import {OfferStockOptions} from 'domain/offer/model/stockOption';

export type CreateOfferDiff = {
  general: OfferGeneralInfo;
  employmentInfo: OfferEmploymentInfo;
  compensation: OfferCompensation;
  stockOptions?: OfferStockOptions;
};

export const create = async (diff: CreateOfferDiff): Promise<Offer> => {
  const {data} = await intranetApi.post('/v1/secure/offers/create', diff);

  return offerSchema.runWithException(data);
};
