import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Offer, OfferId, offerSchema} from 'domain/offer/model';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {OfferEmploymentInfo} from 'domain/offer/model/employmentInfo';
import {OfferStockOptions} from 'domain/offer/model/stockOption';
import {SecurePermission} from 'domain/permission/model/secure';

export type UpdateOfferDiff = {
  employmentInfo: OfferEmploymentInfo;
  compensation: OfferCompensation;
  stockOptions?: OfferStockOptions;
};

type UpdateOfferParams = {
  id: OfferId;
  isTypo: boolean;
};

export type UpdateOfferConfig = {
  params: UpdateOfferParams;
  diff: UpdateOfferDiff;
};

const updateGeneric =
  (endpoint: string) =>
  async ({params, diff}: UpdateOfferConfig): Promise<Offer> => {
    const {data} = await intranetApi.post(endpoint, diff, {
      params,
    });

    return offerSchema.runWithException(data);
  };

const updateAny = updateGeneric('/v1/secure/offers/updateAny');
const updateMy = updateGeneric('/v1/secure/offers/updateMy');

export const update = createPermissionDependentHandler(
  [{securePermission: SecurePermission.OFFER_UPDATE_ANY, handler: updateAny}],
  updateMy,
);
