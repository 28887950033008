import {DataState} from '@joomcode/deprecated-utils/dataState';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {SecurePermission} from 'domain/permission/model/secure';
import {RegularBonusRecord, RegularBonusRecordId} from 'domain/regularBonusRecord/model';
import {deleteRegularBonusRecordFx} from 'domain/regularBonusRecord/stores/main';
import {RegularBonusRecordsTable} from 'domain/regularBonusRecord/widgets/Table';
import {UserId} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  records: RegularBonusRecord[];
  dataState: DataState;
  isSensitiveDataHidden: boolean;
  userId: UserId;
  onDialogOpenWithPayload(record: RegularBonusRecord): void;
  setNeedToReplace: (value: boolean) => void;
};

export function RegularBonus({
  records,
  dataState,
  isSensitiveDataHidden,
  userId,
  onDialogOpenWithPayload,
  setNeedToReplace,
}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canEdit = acl.hasSecurePermission(SecurePermission.REGULAR_BONUS_RECORD_WRITE);
  const onDeleteRecordClick = useCallback(
    (id: RegularBonusRecordId) => {
      confirm(
        {
          title: intl.formatMessage(messages.deletionConfirmationTitle),
          text: intl.formatMessage(messages.deletionConfirmationText),
          confirmationText: intl.formatMessage(messages.deletionConfirmationButton),
          intent: 'negative',
          onConfirm: () => deleteRegularBonusRecordFx({id, userId}).catch(toaster.interceptThenThrowError),
        },
        intl,
      );
    },
    [intl, userId],
  );
  const onEditRecordClick = useCallback(
    (record: RegularBonusRecord) => {
      setNeedToReplace(false);
      return onDialogOpenWithPayload(record);
    },
    [onDialogOpenWithPayload, setNeedToReplace],
  );
  const onReplaceRecordClick = useCallback(
    (record: RegularBonusRecord) => {
      setNeedToReplace(true);
      return onDialogOpenWithPayload(record);
    },
    [onDialogOpenWithPayload, setNeedToReplace],
  );

  return (
    <RegularBonusRecordsTable
      records={records}
      dataState={dataState}
      isSensitiveDataHidden={isSensitiveDataHidden}
      {...(canEdit && {
        onDeleteItem: onDeleteRecordClick,
        onEditItem: onEditRecordClick,
        onReplaceItem: onReplaceRecordClick,
      })}
    />
  );
}
