import {identity} from '@joomcode/deprecated-utils/function';
import {FieldInputNumber, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {FieldCurrency} from 'domain/currency/widgets/Field';
import {availableBonusFrequencies} from 'domain/frequency/model/availableValues';
import {FieldFrequency} from 'domain/frequency/widgets/Field';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {RegularBonusType} from 'domain/regularBonusRecord/model/type';
import {FieldRegularBonusType} from 'domain/regularBonusRecord/widgets/FieldType';
import React from 'react';
import {FormRenderProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  formId: string;
  values: RegularBonusRecord;
  recordToUpdate?: RegularBonusRecord;
  needToReplace?: boolean;
  onSubmit: FormRenderProps<RegularBonusRecord>['handleSubmit'];
};

export function RegularBonusRecordForm({formId, values, recordToUpdate, needToReplace, onSubmit}: Props) {
  const intl = useIntl();

  return (
    <form onSubmit={onSubmit} id={formId} noValidate autoComplete='off'>
      <Grid>
        <Grid.Item xl={4} xs={12}>
          <FieldDate
            name='startDate'
            label={intl.formatMessage(labels.startDate)}
            initialValue={needToReplace ? undefined : recordToUpdate?.startDate}
            required
          />
        </Grid.Item>
        <Grid.Item xl={4} xs={12}>
          <FieldDate
            name='endDate'
            label={intl.formatMessage(labels.endDate)}
            initialValue={needToReplace ? undefined : recordToUpdate?.endDate}
            emptyValue={recordToUpdate && !needToReplace ? null : undefined}
          />
        </Grid.Item>
        <Grid.Item xl={4} xs={12}>
          <FieldRegularBonusType
            name='type'
            label={intl.formatMessage(labels.type)}
            initialValue={recordToUpdate?.type}
            required
          />
        </Grid.Item>
        {values.type === RegularBonusType.ABSOLUTE && (
          <>
            <Grid.Item xl={4} xs={12}>
              <FieldInputNumber
                name='value.absoluteValue.amount'
                label={intl.formatMessage(labels.amount)}
                required
                min={0}
                step={1000}
                initialValue={recordToUpdate?.value.absoluteValue?.amount}
              />
            </Grid.Item>
            <Grid.Item xl={4} xs={12}>
              <FieldCurrency
                name='value.absoluteValue.currency'
                label={intl.formatMessage(labels.currency)}
                required
                validate={validateFilled}
                initialValue={recordToUpdate?.value.absoluteValue?.currency}
              />
            </Grid.Item>
          </>
        )}
        {values.type === RegularBonusType.PERCENT && (
          <>
            <Grid.Item xl={6} xs={12}>
              <FieldInputNumber
                name='value.percentValue.amount'
                label={intl.formatMessage(labels.amount)}
                required
                min={0}
                step={0.01}
                initialValue={recordToUpdate?.value.percentValue?.amount}
              />
            </Grid.Item>
          </>
        )}
        {values.type && (
          <Grid.Item xl={values.type === RegularBonusType.PERCENT ? 6 : 4} xs={12}>
            <FieldFrequency
              name='frequency'
              label={intl.formatMessage(labels.frequency)}
              required
              validate={validateFilled}
              initialValue={recordToUpdate?.frequency}
              items={availableBonusFrequencies}
            />
          </Grid.Item>
        )}
        <Grid.Item xl={12}>
          <FieldTextarea
            name='comment'
            label={intl.formatMessage(labels.comment)}
            rows={2}
            initialValue={needToReplace ? undefined : recordToUpdate?.comment}
            parse={identity}
          />
        </Grid.Item>
      </Grid>
    </form>
  );
}
