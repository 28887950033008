import {BlankLayout} from 'layouts/Blank';
import {UnauthenticatedPage} from 'pages/Unauthenticated';
import {RouteConfig} from 'react-router-config';

export const config: RouteConfig = {
  component: BlankLayout,
  path: '/unauthenticated',
  routes: [
    {
      component: UnauthenticatedPage,
      path: '*',
    },
  ],
};
