import {Tippy, TippyProps} from '@joomcode/joom-ui/Tippy';
import cn from 'classnames';
import React from 'react';
import styles from './styles.css';

type Props = Pick<TippyProps, 'children' | 'content' | 'enabled' | 'onHide' | 'placement'> & {type?: 'room'};

export function OfficeTooltip({children, content, enabled, onHide, placement, type}: Props) {
  return (
    <Tippy
      className={cn(styles.tooltip, {[styles.typeRoom]: type === 'room'})}
      content={content}
      distance={16}
      enabled={enabled}
      interactive
      onHide={onHide}
      placement={placement || 'auto'}
      trigger='click'
      visible
    >
      {children}
    </Tippy>
  );
}
