import {FieldCountry, FieldInputNumber, validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {CalculatableCrBadge} from 'domain/compaRatio/widgets/CalculatableBadge';
import {CountryCode} from 'domain/countryCode/model';
import {FieldCurrency} from 'domain/currency/widgets/Field';
import {frequencyBySalaryRangeCountry} from 'domain/frequency/model';
import {FieldFrequency} from 'domain/frequency/widgets/Field';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {compensationFieldLabels} from 'domain/offer/model/compensation/messages';
import {OfferEmploymentInfo} from 'domain/offer/model/employmentInfo';
import {employmentInfoFieldLabels} from 'domain/offer/model/employmentInfo/messages';
import {OfferFormSectionTitle} from 'domain/offer/widgets/FormSectionTitle';
import {SecurePermission} from 'domain/permission/model/secure';
import {FieldSalaryAmount} from 'domain/salaryRecord/widgets/FieldAmount';
import React, {useCallback} from 'react';
import {useForm} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';
import {labels, messages} from './messages';

type Props = {
  compensation?: OfferCompensation;
  employmentInfo?: OfferEmploymentInfo;
};

export function OfferSalaryFields({compensation, employmentInfo}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {change, getState} = useForm();
  const offerCrFeature = useFeature(Feature.OFFER_CR);
  const showCrBadge = offerCrFeature.isAvailable && acl.hasSecurePermission(SecurePermission.SALARY_RANGE_CALCULATE_CR);

  const getValuesForCalculating = useCallback(() => {
    const {values} = getState();
    const amount = values.compensation?.salary?.money?.amount;
    const currency = values.compensation?.salary?.money?.currency;
    const frequency = values.compensation?.salary?.frequency;
    const levelCode = values.compensation?.levelCode;
    const userFunction = values.compensation?.userFunction;
    const region = values.employmentInfo?.employmentCountry;

    if (!amount || !currency || !frequency || !levelCode || !userFunction || !region) {
      return undefined;
    }

    return {
      amount,
      currency,
      frequency,
      levelCode,
      userFunction,
      region,
    };
  }, [getState]);

  return (
    <>
      <OfferFormSectionTitle title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item sm={12} xl={6}>
            <FieldCountry
              name='employmentInfo.employmentCountry'
              label={intl.formatMessage(employmentInfoFieldLabels.employmentCountry)}
              required
              reserveSpaceForError={false}
              initialValue={employmentInfo?.employmentCountry}
            />
          </Grid.Item>
          <Grid.Item sm={12} xl={6}>
            <FieldInputNumber
              name='compensation.employmentRate'
              label={intl.formatMessage(compensationFieldLabels.employmentRate)}
              required
              initialValue={compensation?.employmentRate ?? 1}
              reserveSpaceForError={false}
              min={0}
              step={0.1}
              minExcluded
            />
          </Grid.Item>
          <Grid.Item xl={4} sm={6}>
            <FieldSalaryAmount
              name='compensation.salary.money.amount'
              label={intl.formatMessage(labels.amount)}
              required
              validate={validateFilled}
              reserveSpaceForError={false}
              initialValue={compensation?.salary.money.amount}
            />
          </Grid.Item>
          <Grid.Item xl={4} sm={6}>
            <FieldCurrency
              name='compensation.salary.money.currency'
              label={intl.formatMessage(labels.currency)}
              required
              validate={validateFilled}
              reserveSpaceForError={false}
              initialValue={compensation?.salary.money.currency}
            />
          </Grid.Item>
          <Grid.Item xl={4} sm={6}>
            <FieldFrequency
              name='compensation.salary.frequency'
              label={intl.formatMessage(labels.frequency)}
              required
              validate={validateFilled}
              reserveSpaceForError={false}
              initialValue={compensation?.salary.frequency}
            />
            <OnChange name='employmentInfo.employmentCountry'>
              {(employmentCountry: CountryCode) => {
                const frequency = frequencyBySalaryRangeCountry[employmentCountry];
                if (frequency && !getState().values.compensation?.salary?.frequency) {
                  change('compensation.salary.frequency', frequency);
                }
              }}
            </OnChange>
          </Grid.Item>
          {showCrBadge && (
            <Grid.Item xl={12}>
              <CalculatableCrBadge
                fieldNames={[
                  'employmentInfo.employmentCountry',
                  'compensation.salary.money.amount',
                  'compensation.salary.money.currency',
                  'compensation.salary.frequency',
                  'compensation.userFunction',
                  'compensation.levelCode',
                ]}
                debouncedFieldNames={['compensation.salary.money.amount']}
                getValuesForCalculating={getValuesForCalculating}
              />
            </Grid.Item>
          )}
        </Grid>
      </Panel.Content>
    </>
  );
}
