import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import {FieldInputNumber} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {UserInfo} from 'domain/compensationReviewRequest/model/userInfo';
import {SectionHeader} from 'domain/compensationReviewRequest/widgets/SectionHeader';
import {StockOptions} from 'domain/compensationReviewRequest/widgets/StockOptions';
import {stockOptionSummaryApi} from 'domain/stockOption/summary/api';
import {StockOptionSummary} from 'domain/stockOption/summary/model';
import {UserId} from 'domain/user/model';
import pDebounce from 'p-debounce';
import React, {useCallback, useState} from 'react';
import {useField, useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

const FIELD_NAME = 'stockOptionsNewGrant';
const TOTAL_STOCK_OPTIONS_FIELD_NAME = 'totalStockOptions';

type Props = {
  userId: UserId;
  initialStockOptions: UserInfo['stockOptions'];
};

export function CompensationReviewRequestFormSectionStockOptions({userId, initialStockOptions}: Props) {
  const intl = useIntl();
  const {change, getState} = useForm();
  const {submitting} = getState();
  const isInputShown = useBooleanState(false);
  const [stockOptions, setStockOptions] = useState<StockOptionSummary | undefined>(initialStockOptions);
  const {input} = useField(FIELD_NAME, {subscription: {value: true}});
  const hasChanges = initialStockOptions?.granted.total !== stockOptions?.granted.total;

  const calculateSummary = useAsyncTask(
    pDebounce(
      async (newGrant: number) =>
        stockOptionSummaryApi.calculateSummaryByGranted(newGrant).then((result) => {
          const totalStockOptions = initialStockOptions
            ? {
                ...initialStockOptions,
                granted: {
                  ...initialStockOptions.granted,
                  total: initialStockOptions.granted.total + result.granted.total,
                },
                nextYear: initialStockOptions.nextYear + result.nextYear,
              }
            : result;

          setStockOptions(totalStockOptions);
          change(TOTAL_STOCK_OPTIONS_FIELD_NAME, totalStockOptions);
        }),
      500,
    ),
    [setStockOptions, initialStockOptions],
  );

  const reset = useCallback(() => {
    setStockOptions(initialStockOptions);
    isInputShown.setFalse();
    change(FIELD_NAME, undefined);
    change(TOTAL_STOCK_OPTIONS_FIELD_NAME, initialStockOptions);
  }, [setStockOptions, initialStockOptions, isInputShown, change]);

  useUpdateEffect(() => {
    const {value} = input;
    if (value && value > 0) {
      calculateSummary.perform(value);
    }
  }, [input.value]);

  return (
    <>
      <SectionHeader title={intl.formatMessage(messages.title)} onReset={hasChanges ? reset : undefined} />
      <Panel.Content withPadding>
        <StockOptions
          error={calculateSummary.error}
          initialStockOptions={initialStockOptions}
          stockOptions={stockOptions}
          loading={calculateSummary.isPerforming}
          userId={userId}
        />
        <div className={styles.action}>
          {isInputShown.value ? (
            <Grid>
              <Grid.Item xl={3} xs={6}>
                <FieldInputNumber name={FIELD_NAME} min={0} step={100} minExcluded disabled={submitting} />
              </Grid.Item>
            </Grid>
          ) : (
            <Button
              size='m'
              kind='secondary'
              intent='neutral'
              type='button'
              onClick={isInputShown.setTrue}
              disabled={submitting}
            >
              {intl.formatMessage(messages.button)}
            </Button>
          )}
        </div>
      </Panel.Content>
    </>
  );
}
