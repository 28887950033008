import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  legalEntityId: {
    defaultMessage: 'Legal entities',
    description: 'Compensation request filter label',
  },
  showIndirect: {
    defaultMessage: 'Show indirect',
    description: 'Compensation request filter label',
  },
  status: {
    defaultMessage: 'Status',
    description: 'Compensation request filter label',
  },
  loadingError: {
    defaultMessage: 'Failed to load filters data. Try to refresh the page.',
    description: 'Compensation request filters: data loading error',
  },
});
