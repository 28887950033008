import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  granting: {
    defaultMessage: 'Options are granted based on the value of shares during the last round of funding.',
    description: 'Stock option calculator hint info',
  },
  currentValue: {
    defaultMessage: 'The current value is $4.6 per option and was set in December 2018.',
    description: 'Stock option calculator hint info',
  },
  valueChange: {
    defaultMessage:
      'The valuation of the option can change in any direction, but should generally increase with the growth of the business of the group.',
    description: 'Stock option calculator hint info',
  },
  buybacks: {
    defaultMessage:
      'We try to conduct regular buybacks of options, but we cannot guarantee that this will happen in the future. In case of new rounds of investments, we will try to negotiate with investors to ensure that such an opportunity is provided.',
    description: 'Stock option calculator hint info',
  },
  disclaimer: {
    defaultMessage:
      'The calculator and the amounts indicated on this page are for convenience only and are not a promise of payment.',
    description: 'Stock option calculator hint info',
  },
});
