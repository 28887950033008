import {isNullish} from '@joomcode/deprecated-utils/function';
import {isId} from '@joomcode/deprecated-utils/joomId';
import {FilterItem} from '../../types';
import {Options} from '../Text/types';
import {createTextFilter} from '../Text';

export const createIdFilter = (filterName: string, options?: Omit<Options, 'type'>): FilterItem<string, Options> => ({
  ...createTextFilter(filterName, {
    ...options,
    type: 'equal',
  }),
  isEmpty: (value) => isNullish(value) || !isId(value),
});
