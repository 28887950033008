import React, {useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import {IntlProvider, IntlShape} from 'react-intl';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {WithOptional} from '@joomcode/deprecated-utils/types/withOptional';
import {ConvertLocatorToTestId, Locator, Mark, createLocator} from '../../create-locator';
import {ConfirmationDialog, ConfirmationDialogProps, ConfirmationDialogLocator} from '../ConfirmationDialog';

export type ConfirmLocatorTestId = ConvertLocatorToTestId<ConfirmLocator>;
export type ConfirmLocator = Locator<{
  confirmationDialog: ConfirmationDialogLocator;
}>;

export type ConfirmProps = WithOptional<Omit<ConfirmationDialogProps, 'isOpen'>, 'onClose'> & {
  locatorMark?: Partial<Mark<ConfirmLocator>>;
};

function OpenedConfirmationDialog({locatorMark = {}, ...props}: ConfirmProps) {
  const {isOpen, open, close} = usePopupState();
  const locator = createLocator(locatorMark);

  useEffect(() => {
    open();
  }, []);

  const onConfirm = async () => {
    await props.onConfirm();
    close();
  };

  const onClose = async () => {
    props.onClose?.();
    close();
  };

  return (
    <ConfirmationDialog
      {...props}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      {...locator.confirmationDialog()}
    />
  );
}

export function confirm(props: ConfirmProps, intl: IntlShape) {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const root = createRoot(div);

  const destroy = () => {
    root.unmount();

    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }
  };

  root.render(
    <IntlProvider {...intl}>
      <OpenedConfirmationDialog
        {...props}
        afterClose={() => {
          destroy();
          if (props.afterClose) {
            props.afterClose();
          }
        }}
      />
    </IntlProvider>,
  );
}

export async function confirmAsync(
  props: Omit<ConfirmProps, 'onConfirm' | 'onClose'>,
  intl: IntlShape,
): Promise<boolean> {
  return new Promise((resolve) => {
    confirm({...props, onConfirm: () => resolve(true), onClose: () => resolve(false)}, intl);
  });
}
