import {defineMessages} from 'react-intl';
import {TimeOffRequestFormError} from '.';

export const errorMessages = defineMessages<TimeOffRequestFormError>({
  [TimeOffRequestFormError.BAD_DATES_ORDER]: {
    defaultMessage: 'Should come later',
    description: 'Time off request form: error',
  },
  [TimeOffRequestFormError.TOO_EARLY]: {
    defaultMessage: 'Too early',
    description: 'Time off request form: error',
  },
  [TimeOffRequestFormError.TOO_LATE]: {
    defaultMessage: 'Too late',
    description: 'Time off request form: error',
  },
});
