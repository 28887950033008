export enum ColumnId {
  AMOUNT = 'amount',
  VESTED = 'vested',
  STATUS = 'status',
  ISSUE_DATE = 'issueDate',
  ISSUER = 'issuer',
  TYPE = 'type',
  END_VESTING_DATE = 'endVestingDate',
  DOCUMENTS_STATUS = 'documentsStatus',
  ACTIONS = 'actions',
}
