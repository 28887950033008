import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {ResourceRoleBindingsTable} from 'domain/resource/widgets/RoleBindingsTable';
import {ColumnId} from 'domain/resource/widgets/RoleBindingsTable/columnId';
import {RoleBinding} from 'domain/roleBinding/model';
import React, {useMemo} from 'react';

type Props = {
  roleBindings: RoleBinding[];
  count: number;
  dataState: DataState;
  pagination: OffsetPagination;
  canManage: boolean;
};

export function ResourceActiveRoleBindingsTable({roleBindings, count, dataState, pagination, canManage}: Props) {
  const columnIds = useMemo(
    () => (canManage ? [ColumnId.ROLE, ColumnId.TEAM, ColumnId.ACTIONS] : [ColumnId.ROLE, ColumnId.TEAM]),
    [canManage],
  );

  return (
    <ResourceRoleBindingsTable
      roleBindings={roleBindings}
      count={count}
      dataState={dataState}
      pagination={pagination}
      canManage={canManage}
      columnIds={columnIds}
    />
  );
}
