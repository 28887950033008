import cn from 'classnames';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {OfficeTooltip} from 'domain/officeMap/office/widgets/Tooltip';
import {OfficeSeat} from 'domain/officeMap/seat/model';
import {EmptySeatCard} from 'domain/officeMap/seat/widgets/EmptySeatCard';
import {TakenSeatCard} from 'domain/officeMap/seat/widgets/TakenSeatCard';
import {User, UserFull} from 'domain/user/model';
import React, {useCallback} from 'react';
import {UserSnapEvents, useUsersnapApi} from 'services/usersnap';
import {SEAT_TEMPLATE_ID, SeatSvgSample} from './Sample';
import styles from './styles.css';

type SeatProps = {
  onSeatSelect: (seat: OfficeSeat | null, officeAlias?: OfficeAlias) => void;
  seat: OfficeSeat;
  selfUser: UserFull;
  user?: User;
  isActive: boolean;
};

export function SeatSvgComponent({seat, user, onSeatSelect, selfUser, isActive}: SeatProps) {
  const isMine = user?.id === selfUser.id;
  const usersnapApi = useUsersnapApi();

  const seatElement = (
    <use
      onClick={() => onSeatSelect(seat, seat.officeAlias)}
      xlinkHref={`#${SEAT_TEMPLATE_ID}`}
      className={cn({
        [styles.seat]: true,
        [styles.taken]: user !== undefined,
        [styles.active]: isActive,
        [styles.takenByMe]: isMine,
      })}
      transform={`translate(${seat.coordinates.x} ${seat.coordinates.y}) rotate(${seat.coordinates.angle ?? 0})`}
    />
  );

  const onSeatHide = useCallback(() => {
    usersnapApi?.logEvent(UserSnapEvents.OFFICE_MAP_USAGE);
    onSeatSelect(null);
  }, [onSeatSelect, usersnapApi]);

  if (isActive) {
    return (
      <OfficeTooltip
        content={
          user ? (
            <TakenSeatCard user={user} isMine={isMine} seat={seat} />
          ) : (
            <EmptySeatCard seat={seat} selfHasSeat={Boolean(selfUser.seat)} />
          )
        }
        onHide={onSeatHide}
      >
        {seatElement}
      </OfficeTooltip>
    );
  }

  return seatElement;
}

export const SeatSvg = Object.assign(SeatSvgComponent, {
  Sample: SeatSvgSample, // eslint-disable-line @typescript-eslint/naming-convention
});
