import {ControlRenderProps} from '@joomcode/joom-ui/DataFilter/types';
import {useEffect} from 'react';

type Props = ControlRenderProps<boolean>;

export const SimpleBooleanControl = ({value, onSubmit}: Props) => {
  useEffect(() => {
    onSubmit(!value);
  }, [onSubmit, value]);
  return null;
};
