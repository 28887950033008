import {Type} from 'domain/compensationReviewRequest/model/type';
import {defineMessages} from 'react-intl';

export const hints = defineMessages<Exclude<Type, Type.OTHER | Type.ROTATION>>({
  [Type.PROMOTION]: {
    defaultMessage: 'An employee’s advancement to a higher position within Joom.',
    description: 'Compensation review request type hint',
  },
  [Type.RETENTION]: {
    defaultMessage: 'A financial way to encourage an employee to stay with Joom.',
    description: 'Compensation review request type hint',
  },
});
