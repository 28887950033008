import {Permission} from 'domain/permission/model';
import {Team} from 'domain/team/model';
import {Acl} from 'services/acl/types';

export function getTeamLinksAvailability(team: Team, acl: Acl): boolean {
  const {slackChannelLink, notionLink, calendarLink, calendarWithSubteamsLink} = team;
  const canReadTeamRoleBindings = acl.hasPermission(Permission.TEAM_ROLE_BINDING_READ);

  return Boolean(slackChannelLink || notionLink || calendarLink || calendarWithSubteamsLink || canReadTeamRoleBindings);
}
