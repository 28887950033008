import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination, useOffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Resource, ResourceId, ResourcesSearchFilters} from 'domain/resource/model';
import {getResourcesFx} from 'domain/resource/stores/main';
import {$resourceList, $resources, $resourcesCount} from 'domain/resource/stores/main/state';
import {useStore} from 'effector-react';
import {useEffect, useMemo, useState} from 'react';
import {toaster} from 'services/toaster';

type PaginationOptions = {
  initialLimit: number;
  initialPage: number;
};

type ReturnShape = {
  resources: Resource[];
  resourceById: Record<ResourceId, Resource | undefined>;
  resourcesCount: number;
  dataState: DataState;
  pagination: OffsetPagination;
  setOutdated: (isOutdated: boolean) => void;
};

export function useResources(
  filters: ResourcesSearchFilters = {},
  paginationOptions: PaginationOptions = {initialLimit: 25, initialPage: 1},
): ReturnShape {
  const {data: resources, dataState} = useStore($resourceList);
  const {count: resourcesCount} = useStore($resourcesCount);
  const {byId: resourceById} = useStore($resources);
  const pagination = useOffsetPagination(paginationOptions);
  const pagingAndFilterOptions = useMemo(() => {
    return {
      offset: pagination.offset,
      limit: pagination.limit,
      filters,
    };
  }, [pagination.offset, pagination.limit, filters]);
  const [isOutdated, setOutdated] = useState(false);

  useEffect(() => {
    getResourcesFx(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
  }, [pagingAndFilterOptions]);

  useEffect(() => {
    if (isOutdated) {
      getResourcesFx(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
      setOutdated(false);
    }
  }, [isOutdated]);

  return {
    resources,
    resourceById,
    resourcesCount,
    dataState,
    pagination,
    setOutdated,
  };
}
