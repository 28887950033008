import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Bonus record dialog',
    description: 'Regular bonus record dialog: aria label',
  },
  titleAdd: {
    defaultMessage: 'Add bonus',
    description: '[title] Regular bonus record dialog: title (addition mode)',
  },
  titleUpdate: {
    defaultMessage: 'Update bonus',
    description: '[title] Regular bonus record dialog: title (update mode)',
  },
  titleReplace: {
    defaultMessage: 'Replace bonus record with a new one',
    description: '[title] Regular bonus record dialog: title (replace mode)',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Regular bonus record dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Submit',
    description: '[button] Regular bonus record dialog: submit button',
  },
});
