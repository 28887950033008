import React, {ReactNode} from 'react';
import styles from './index.css';

type Props = {
  children?: ReactNode;
  scrollbar: ReactNode;
};
export function Footer({children, scrollbar}: Props) {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.scrollbar}>{scrollbar}</div>

        {children && <div className={styles.content}>{children}</div>}
      </div>
    </footer>
  );
}
