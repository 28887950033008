import {PageTabs} from 'components/ui/PageTabs';
import {Permission} from 'domain/permission/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {outstaffUsersUrls} from 'routes/outstaffUsers/urls';
import {teamsUrls} from 'routes/teams/urls';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {tabNames} from './messages';

type Props = {
  activeTab?: 'teams' | 'users' | 'outstaff';
};

export function JoomersPageTabs({activeTab}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  return (
    <PageTabs>
      <PageTabs.Tab
        title={intl.formatMessage(tabNames.users)}
        path={usersUrls.users()}
        active={activeTab === 'users'}
      />
      <PageTabs.Tab
        title={intl.formatMessage(tabNames.teams)}
        path={teamsUrls.teams()}
        active={activeTab === 'teams'}
      />
      {acl.hasPermission(Permission.OUTSTAFF_USER_READ) && (
        <PageTabs.Tab
          title={intl.formatMessage(tabNames.outstaff)}
          path={outstaffUsersUrls.outstaffUsers()}
          active={activeTab === 'outstaff'}
        />
      )}
    </PageTabs>
  );
}
