import {isFileDragEvent} from '@joomcode/deprecated-utils/dragAndDrop/isFileDragEvent';
import React, {RefObject, useRef, useEffect, useState, useCallback, useLayoutEffect} from 'react';
import {VisuallyHidden} from '../VisuallyHidden';
import styles from './index.css';

enum BrowserEvent {
  DRAG_ENTER = 'dragenter',
  DRAG_OVER = 'dragover',
  DRAG_LEAVE = 'dragleave',
  DROP = 'drop',
}

type Props = {
  accept?: string | string[];
  children: (args: {
    isDragging: boolean;
    open: () => void;
    rootRef: RefObject<HTMLDivElement>;
    isFocused: boolean;
  }) => React.ReactNode;
  disabled?: boolean;
  getDragEnterElement?: () => Node;
  multiple?: boolean;
  onChange: (file: File[]) => void;
  ariaLabel?: string;
  name?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disableFocus?: boolean;
};

export function FileDragger({
  onChange,
  getDragEnterElement,
  multiple = false,
  accept,
  children,
  disabled,
  ariaLabel,
  name,
  onFocus,
  onBlur,
  disableFocus,
}: Props) {
  const [isFocused, setFocused] = useState(false);
  const [isDragging, setDragging] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const eventTarget = useRef<EventTarget>();
  const getTargetNode = (): HTMLElement => targetRef.current || document.body;

  const open = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const handleFiles = useCallback(
    (files: FileList | null) => {
      if (files && files.length > 0) {
        onChange(multiple ? Array.from(files) : [files[0]]);
      }
    },
    [onChange],
  );

  /**
   * Prevent triggering dragLeaveListener on target node
   * when cursor is over child elements by adding class
   * that disables pointer-events on children
   */
  useLayoutEffect(() => {
    getTargetNode().classList.toggle(styles.target, isDragging);
  }, [isDragging]);

  useEffect(() => {
    const targetNode = getTargetNode();
    const dragEnterNode = getDragEnterElement ? getDragEnterElement() : targetNode;

    const dragEnterListener = (event: DragEvent) => {
      if (!isFileDragEvent(event)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      eventTarget.current = event.target as HTMLDivElement;
      setDragging(true);
    };

    const dragOverListener = (event: DragEvent) => {
      if (!isFileDragEvent(event)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      setDragging(true);
    };

    const dragLeaveListener = (event: DragEvent) => {
      if (!isFileDragEvent(event)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      if (event.target === eventTarget.current) {
        setDragging(false);
      }
    };

    const dropListener = (event: DragEvent) => {
      if (!isFileDragEvent(event)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      setDragging(false);

      if (event.dataTransfer) {
        handleFiles(event.dataTransfer.files);
      }
    };

    if (!disabled) {
      dragEnterNode.addEventListener(BrowserEvent.DRAG_ENTER, dragEnterListener);
      targetNode.addEventListener(BrowserEvent.DRAG_OVER, dragOverListener);
      targetNode.addEventListener(BrowserEvent.DRAG_LEAVE, dragLeaveListener);
      targetNode.addEventListener(BrowserEvent.DROP, dropListener);
    }

    return () => {
      dragEnterNode.removeEventListener(BrowserEvent.DRAG_ENTER, dragEnterListener);
      targetNode.removeEventListener(BrowserEvent.DRAG_OVER, dragOverListener);
      targetNode.removeEventListener(BrowserEvent.DRAG_LEAVE, dragLeaveListener);
      targetNode.removeEventListener(BrowserEvent.DROP, dropListener);
    };
  }, [disabled, handleFiles]);

  return (
    <>
      <VisuallyHidden>
        <input
          name={name}
          tabIndex={disableFocus ? -1 : 0}
          aria-label={ariaLabel}
          onFocus={(event) => {
            if (onFocus) {
              onFocus(event);
            }
            setFocused(true);
          }}
          onBlur={(event) => {
            if (onBlur) {
              onBlur(event);
            }
            setFocused(false);
          }}
          disabled={disabled}
          multiple={multiple}
          accept={Array.isArray(accept) ? accept.join(',') : accept}
          onChange={(event) => {
            handleFiles(event.currentTarget.files);
            // Fixes onChange triggering when user selects same file.
            event.currentTarget.value = ''; // eslint-disable-line no-param-reassign
          }}
          ref={inputRef}
          type='file'
        />
      </VisuallyHidden>

      {children({open, isDragging, rootRef: targetRef, isFocused})}
    </>
  );
}
