import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  createButton: {
    defaultMessage: 'Create request',
    description: '[button] Create compensation request button on Compensation Requests page',
  },
  downloadButton: {
    defaultMessage: 'Download all business trips',
    description: '[button] Create compensation request button on Compensation Requests page',
  },
});
