export enum TimeOffType {
  VACATION = 'vacation',
  UNPAID_VACATION = 'unpaidVacation',
  BUSINESS_TRIP = 'businessTrip',
  SEMI_SICK_LEAVE = 'semiSickLeave',
  SICK_LEAVE = 'sickLeave',
  BLOOD_DONATION = 'bloodDonation',
  WORK_AT_WEEKEND = 'workAtWeekend',
  REMOTE_WORK = 'remoteWork',
  AUTHORS_DAY_OFF = 'authorsDayOff',
  FAMILY_LEAVE = 'familyLeave',
  TEMPORARILY_RELOCATED = 'temporarilyRelocated',
  MATERNITY_LEAVE = 'maternityLeave',
  STUDY_LEAVE = 'studyLeave',
  PARENTAL_LEAVE = 'parentalLeave',
}

export const paidTimeOffTypes = [
  TimeOffType.VACATION,
  TimeOffType.SICK_LEAVE,
  TimeOffType.WORK_AT_WEEKEND,
  TimeOffType.FAMILY_LEAVE,
  TimeOffType.AUTHORS_DAY_OFF,
  TimeOffType.BLOOD_DONATION,
  TimeOffType.MATERNITY_LEAVE,
  TimeOffType.STUDY_LEAVE,
  TimeOffType.TEMPORARILY_RELOCATED,
  TimeOffType.SEMI_SICK_LEAVE,
  TimeOffType.REMOTE_WORK,
];

export const oldTimeOffTypesAvailableForRequest = [
  TimeOffType.VACATION,
  TimeOffType.UNPAID_VACATION,
  TimeOffType.SICK_LEAVE,
  TimeOffType.WORK_AT_WEEKEND,
  TimeOffType.FAMILY_LEAVE,
  TimeOffType.AUTHORS_DAY_OFF,
  TimeOffType.BLOOD_DONATION,
  TimeOffType.MATERNITY_LEAVE,
  TimeOffType.STUDY_LEAVE,
];

export const timeOffTypesAvailableForRequest = [
  TimeOffType.VACATION,
  TimeOffType.REMOTE_WORK,
  TimeOffType.UNPAID_VACATION,
  TimeOffType.SEMI_SICK_LEAVE,
  TimeOffType.SICK_LEAVE,
  TimeOffType.WORK_AT_WEEKEND,
  TimeOffType.FAMILY_LEAVE,
  TimeOffType.AUTHORS_DAY_OFF,
  TimeOffType.BLOOD_DONATION,
  TimeOffType.MATERNITY_LEAVE,
  TimeOffType.STUDY_LEAVE,
  TimeOffType.PARENTAL_LEAVE,
];
