import {
  DragOverEvent,
  DragStartEvent,
  DragEndEvent,
  rectIntersection,
  DndContext,
  DragOverlay,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {not} from '@joomcode/deprecated-utils/function/not';
import React, {useMemo, useState, useCallback} from 'react';
import {Portal} from 'react-portal';
import styles from './index.css';
import {TreeList} from './List';
import {TreeItem, TreeItemLayout} from './Item';
import {TreeViewProps} from './types';
import {deepSome} from './utils';
import {RootDroppable, ROOT_LEVEL_DROPPABLE_ID} from './RootDroppable';
import {createLocator, removeMarkFromProperties} from '../create-locator';

const DRAGGABLE_ACTIVATION_CONSTRAINT = {
  delay: 250,
  tolerance: 5,
};

export const TreeView = <T,>({
  items,
  filterItem,
  draggable = false,
  onItemMove,
  rootNodeCaption,
  ...props
}: TreeViewProps<T>) => {
  const locator = createLocator(props);
  const rootItems = useMemo(() => items.filter(not(props.getItemParent)), [items, props.getItemParent]);

  const deepFilterItem = useMemo(() => {
    if (filterItem) {
      return (item: T): boolean => filterItem(item) || deepSome(item, props.getItemChildren, filterItem);
    }
    return undefined;
  }, [props.getItemChildren, filterItem]);

  const hasItems = useMemo(() => {
    if (deepFilterItem) {
      return rootItems.some(deepFilterItem);
    }
    return rootItems.length > 0;
  }, [deepFilterItem, rootItems]);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);

  const content = hasItems ? (
    <TreeList {...locator.treeList()}>
      {rootItems.map((item) => (
        <TreeItem
          {...removeMarkFromProperties(props)}
          filterItem={deepFilterItem}
          item={item}
          level={0}
          key={props.getItemKey(item)}
          draggable={draggable}
          overItemKey={overId}
          activeItemKey={activeId}
        />
      ))}
    </TreeList>
  ) : (
    props.placeholder && <div>{props.placeholder}</div>
  );

  const handleDragOver = useCallback(
    ({over}: DragOverEvent) => {
      if (over) {
        setOverId(over.id);
      } else {
        setOverId(null);
      }
    },
    [overId],
  );

  const handleDragStart = useCallback(({active}: DragStartEvent) => {
    setActiveId(active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      setActiveId(null);
      setOverId(null);
      const {active, over} = event;

      if (over?.id && onItemMove) {
        onItemMove(active.id, over.id);
      }
    },
    [onItemMove],
  );

  const sortableItems = items.map((item) => props.getItemKey(item)).concat([ROOT_LEVEL_DROPPABLE_ID]);
  const activeItem = items.find((item) => props.getItemKey(item) === activeId);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: DRAGGABLE_ACTIVATION_CONSTRAINT,
    }),
    useSensor(TouchSensor, {
      activationConstraint: DRAGGABLE_ACTIVATION_CONSTRAINT,
    }),
  );

  return draggable ? (
    <DndContext
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      collisionDetection={rectIntersection}
      sensors={sensors}
    >
      <SortableContext items={sortableItems} strategy={verticalListSortingStrategy}>
        <div className={styles.treeView}>
          <RootDroppable
            message={rootNodeCaption}
            active={Boolean(activeId)}
            isOver={overId === ROOT_LEVEL_DROPPABLE_ID}
          />
          {content}
        </div>
        <Portal>
          <DragOverlay adjustScale={false}>
            {(() => {
              if (activeId && activeItem) {
                return (
                  <div className={styles.dragOverlay}>
                    <TreeItemLayout
                      {...removeMarkFromProperties(props)}
                      expanded={false}
                      isItemSelected={() => false}
                      filterItem={deepFilterItem}
                      item={activeItem}
                      level={0}
                      key={props.getItemKey(activeItem)}
                      draggable={false}
                    />
                  </div>
                );
              }
              return null;
            })()}
          </DragOverlay>
        </Portal>
      </SortableContext>
    </DndContext>
  ) : (
    <div className={styles.treeView}>{content}</div>
  );
};
