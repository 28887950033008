import {QueryRequestPayload} from 'api/types';
import {intranetApi} from 'apiClient';
import {CountableRoleBindigns, countableRoleBindingsSchema, RoleBindingsSearchFilters} from 'domain/roleBinding/model';

export const getAll = async ({
  filters,
  offset,
  limit,
}: QueryRequestPayload<RoleBindingsSearchFilters>): Promise<CountableRoleBindigns> => {
  const {data} = await intranetApi.post('/v1/roleBindings/getAll', filters, {params: {offset, limit}});

  return countableRoleBindingsSchema.runWithException(data);
};
