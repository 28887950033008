import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import faker from 'faker';
import {OfficeSeat} from '.';

export function mockSeat(): OfficeSeat {
  return {
    id: createMockId(),
    label: undefined,
    coordinates: {
      x: faker.random.number(),
      y: faker.random.number(),
      angle: undefined,
    },
    number: faker.random.number({min: 10000, max: 99999}).toString(),
    officeAlias: faker.random.arrayElement(getEnumValues(OfficeAlias)),
  };
}
