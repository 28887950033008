import React from 'react';
import {Spinner} from '../../../Spinner';
import styles from './index.css';

export function Preloader() {
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.spinner}>
          <Spinner />
        </div>
      </div>
    </div>
  );
}
