import {defineMessages} from 'react-intl';
import {Frequency} from '.';

export const frequencyMessages = defineMessages<Frequency>({
  [Frequency.PER_HOUR]: {
    defaultMessage: 'hourly',
    description: 'Frequency',
  },
  [Frequency.PER_DAY]: {
    defaultMessage: 'daily',
    description: 'Frequency',
  },
  [Frequency.PER_MONTH]: {
    defaultMessage: 'monthly',
    description: 'Frequency',
  },
  [Frequency.PER_QUARTER]: {
    defaultMessage: 'quarterly',
    description: 'Frequency',
  },
  [Frequency.PER_YEAR]: {
    defaultMessage: 'annual',
    description: 'Frequency',
  },
  [Frequency.SEMI_ANNUAL]: {
    defaultMessage: 'semi-annual',
    description: 'Frequency',
  },
  [Frequency.OTHER]: {
    defaultMessage: 'other',
    description: 'Frequency',
  },
});
