import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  boardingPasses: {
    defaultMessage: 'Boarding passes',
    description: 'Compensation report summary: property label',
  },
  expenses: {
    defaultMessage: 'Extra expenses',
    description: 'Compensation report summary: property label',
  },
  noFlights: {
    defaultMessage: 'A trip without flights',
    description: 'Compensation report summary: no flights message',
  },
  noBoardingPasses: {
    defaultMessage: 'No boarding passes',
    description: 'Compensation report summary: empty list message',
  },
  noExpenses: {
    defaultMessage: 'No extra expenses',
    description: 'Compensation report summary: empty list message',
  },
});
