import {ValidationFunction, ValidationOptions} from '../types';

type Vo = ValidationOptions;
type Vf<V, O extends Vo, E> = ValidationFunction<V, O, E>;

export const bindValidatorOptions = <Value, Options extends Vo, ErrorCode>(
  validationFunction: Vf<Value, Options, ErrorCode>,
  options: Options,
): ((value: Value) => ReturnType<typeof validationFunction>) => {
  return (value: Value) => {
    return validationFunction(value, options);
  };
};
