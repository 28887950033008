import {generatePath} from 'react-router-dom';

export const offerUrlPatterns = {
  root: '/offers',
  create: '/offers/create',
  offer: '/offers/:id',
  editGeneralInfo: '/offers/:id/editGeneral',
  update: '/offers/:id/update',
  enhance: '/offers/:id/enhance',
};

export const offerUrls = {
  root: () => offerUrlPatterns.root,
  create: () => offerUrlPatterns.create,
  offer: (pathParams: {id: string}) => generatePath(offerUrlPatterns.offer, pathParams),
  editGeneralInfo: (pathParams: {id: string}) => generatePath(offerUrlPatterns.editGeneralInfo, pathParams),
  update: (pathParams: {id: string}) => generatePath(offerUrlPatterns.update, pathParams),
  enhance: (pathParams: {id: string}) => generatePath(offerUrlPatterns.enhance, pathParams),
};
