import {defineMessages} from 'react-intl';
import {EqualValidatorErrorCode} from './types';

export const equalErrorMessages = defineMessages<EqualValidatorErrorCode>({
  [EqualValidatorErrorCode.WRONG_VALUE]: {
    id: 'validator-equal-wrong',
    description: 'Validation error about the value of field is wrong',
    defaultMessage: 'Wrong value',
  },
});
