import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {HintIcon} from 'components/ui/HintIcon';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  freezeDate: string;
  frozen?: boolean;
};

export function StockOptionAgreementFreezeHint({freezeDate, frozen}: Props) {
  const intl = useIntl();
  const date = <HumanDate value={freezeDate} format='short' utc />;

  return <HintIcon>{intl.formatMessage(frozen ? messages.frozen : messages.willBeFrozen, {date})}</HintIcon>;
}
