import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Function and level record dialog',
    description: 'Market data record dialog: aria label',
  },
  titleAdd: {
    defaultMessage: 'Add record',
    description: '[title] Market data record dialog: title (addition mode)',
  },
  titleUpdate: {
    defaultMessage: 'Update record',
    description: '[title] Market data record dialog: title (update mode)',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Market data record dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Submit',
    description: '[button] Market data record dialog: submit button',
  },
  loadingError: {
    defaultMessage: 'Failed to load company units data. Try to refresh the page.',
    description: 'Market data record dialog: error message',
  },
});
