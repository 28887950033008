import {DataState} from '@joomcode/deprecated-utils/dataState';
import {RtconfItem} from 'domain/rtconf/model';
import {createStore} from 'effector';
import {createRtconfItemFx, deleteRtconfItemFx, loadAllRtconfItemsFx, updateRtconfItemFx} from '.';

type State = {
  items: RtconfItem[];
  dataState: DataState;
};

export const $rtconfItems = createStore<State>({
  items: [],
  dataState: DataState.IDLE,
})
  .on(loadAllRtconfItemsFx, (state) => {
    return {
      ...state,
      dataState: DataState.LOADING,
    };
  })
  .on(loadAllRtconfItemsFx.fail, (state) => {
    return {
      ...state,
      items: [],
      dataState: DataState.FAILED,
    };
  })
  .on(loadAllRtconfItemsFx.done, (state, {result}) => {
    return {
      ...state,
      items: result,
      dataState: DataState.LOADED,
    };
  })
  .on(updateRtconfItemFx.done, (state, {result}) => {
    return {
      ...state,
      items: state.items.map((item) => (item.key === result.key ? result : item)),
    };
  })
  .on(createRtconfItemFx.done, (state, {result}) => {
    return {
      ...state,
      items: [...state.items].concat(result),
    };
  })
  .on(deleteRtconfItemFx.done, (state, {params}) => {
    return {
      ...state,
      items: state.items.filter((item) => item.key !== params.key),
    };
  });
