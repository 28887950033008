export enum MarketLevelPosition {
  BELOW_MARKET_LEVEL = 'belowMarketLevel',
  BOTTOM_OF_MARKET_LEVEL = 'bottomOfMarketLevel',
  AT_MARKET_LEVEL = 'atMarketLevel',
  TOP_OF_MARKET_LEVEL = 'topOfMarketLevel',
  ABOVE_MARKET_LEVEL = 'aboveMarketLevel',
}

export const marketLevelPositionSortingOrder = [
  MarketLevelPosition.BELOW_MARKET_LEVEL,
  MarketLevelPosition.BOTTOM_OF_MARKET_LEVEL,
  MarketLevelPosition.AT_MARKET_LEVEL,
  MarketLevelPosition.TOP_OF_MARKET_LEVEL,
  MarketLevelPosition.ABOVE_MARKET_LEVEL,
];
