import {intranetApi} from 'apiClient';
import {
  StockOptionAgreement,
  StockOptionAgreementId,
  stockOptionAgreementSchema,
} from 'domain/stockOption/agreement/model';
import {StockOptionAgreementDocumentsStatus} from 'domain/stockOption/agreement/model/documentsStatus';
import {UserId} from 'domain/user/model';

export type SetDocumentsStatusConfig = {
  userId: UserId;
  id: StockOptionAgreementId;
  documentsStatus: StockOptionAgreementDocumentsStatus;
};

export const setDocumentsStatus = async ({
  documentsStatus,
  ...params
}: SetDocumentsStatusConfig): Promise<StockOptionAgreement> => {
  const {data} = await intranetApi.post(
    '/v1/secure/stockOptions/agreements/setDocumentsStatus',
    {documentsStatus},
    {params},
  );

  return stockOptionAgreementSchema.runWithException(data);
};
