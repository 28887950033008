import {defineMessages} from 'react-intl';
import {MarketLevelPosition} from '.';

export const marketLevelPositionMessages = defineMessages<MarketLevelPosition>({
  [MarketLevelPosition.BELOW_MARKET_LEVEL]: {
    defaultMessage: 'Below the minimum of the salary range',
    description: 'Market level position',
  },
  [MarketLevelPosition.BOTTOM_OF_MARKET_LEVEL]: {
    defaultMessage: 'At the bottom of the salary range',
    description: 'Market level position',
  },
  [MarketLevelPosition.AT_MARKET_LEVEL]: {
    defaultMessage: 'At the market level',
    description: 'Market level position',
  },
  [MarketLevelPosition.TOP_OF_MARKET_LEVEL]: {
    defaultMessage: 'At the top of the salary range',
    description: 'Market level position',
  },
  [MarketLevelPosition.ABOVE_MARKET_LEVEL]: {
    defaultMessage: 'Above the maximum of the salary range',
    description: 'Market level position',
  },
});
