import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Choose update reason',
    description: '[title] Offer update dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Offer update dialog',
    description: 'Offer update dialog: aria label',
  },
  radioGroupAriaLabel: {
    defaultMessage: 'Offer update reasons',
    description: 'Offer update dialog: radio group aria label',
  },
  buttonUpdate: {
    defaultMessage: 'Update',
    description: '[button] Offer update dialog: update button',
  },
});
