import React from 'react';
import styles from './styles.css';

export const PIN_TEMPLATE_ID = 'roomPin';

export function RoomSvgSample() {
  return (
    <path
      className={styles.pin}
      d='m34.02,5.841C26.974-1.733,15.118-1.956,7.809,5.257,1.538,11.438.675,21.04,5.275,28.149c.13.204.145.467.022.686L.054,38.021c-.195.342.18.721.52.534l9.218-5.084c.244-.127.534-.118.77.036,7.374,4.883,17.524,3.861,23.745-3.129,6.189-6.972,6.06-17.708-.286-24.538Z'
      id={PIN_TEMPLATE_ID}
    />
  );
}
