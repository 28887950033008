import {parseFilename} from './name';

export const mimeType = {
  binaryFileWithoutFormat: 'application/octet-stream',
  csv: 'text/csv',
  excel: {
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
  },
  googleApps: {
    document: 'application/vnd.google-apps.document',
    folder: 'application/vnd.google-apps.folder',
    sheet: 'application/vnd.google-apps.spreadsheet',
  },
  image: {
    gif: 'image/gif',
    jpeg: 'image/jpeg',
    png: 'image/png',
    svg: 'image/svg+xml',
    webp: 'image/webp',
  },
  json: 'application/json',
  pdf: 'application/pdf',
  rtf: 'text/rtf',
  rtf2: 'application/rtf',
  video: {
    m3uHls: 'application/vnd.apple.mpegurl',
    m3u: 'application/x-mpegURL',
    mp4: 'video/mp4',
    mov: 'video/quicktime',
  },
  word: {
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    odt: 'application/vnd.oasis.opendocument.text',
  },
};

const extensionsByMimeType = {
  [mimeType.csv]: ['csv'],
  [mimeType.excel.xls]: ['xls'],
  [mimeType.excel.xlsx]: ['xlsx'],
  [mimeType.image.gif]: ['gif'],
  [mimeType.image.jpeg]: ['jpg', 'jpeg'],
  [mimeType.image.png]: ['png'],
  [mimeType.image.svg]: ['svg'],
  [mimeType.image.webp]: ['webp'],
  [mimeType.json]: ['json'],
  [mimeType.pdf]: ['pdf'],
  [mimeType.video.m3u]: ['m3u', 'm3u8'],
  [mimeType.video.m3uHls]: ['m3u', 'm3u8'],
  [mimeType.video.mp4]: ['mp4'],
  [mimeType.video.mov]: ['mov'],
};

const wellKnownMimeTypeForExtension: Record<string, string | undefined> = {
  csv: mimeType.csv,
  xlsx: mimeType.excel.xlsx,
  xls: mimeType.excel.xls,
};

/**
 * @param file
 * @see https://groups.google.com/a/chromium.org/forum/#!topic/chromium-bugs/-jnVNx-p6jA
 * @see https://bugs.chromium.org/p/chromium/issues/detail?id=139105
 */
export const getMimeType = (file: File): string => {
  const {extension} = parseFilename(file.name);
  return (extension && wellKnownMimeTypeForExtension[extension.toLowerCase()]) || file.type;
};

export const getExtensionsByMimeType = (mimeTypeParam: string): string[] => {
  return extensionsByMimeType[mimeTypeParam] || [];
};

const imageMimeTypes = new Set<string>(Object.values(mimeType.image));
export const videoMimeTypes = new Set<string>(Object.values(mimeType.video));

export const isImageMimeType = (value: string): value is keyof (typeof mimeType)['image'] => imageMimeTypes.has(value);
export const isVideoMimeType = (value: string): boolean => value.startsWith('video/') || videoMimeTypes.has(value);
