import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Own accesses',
    description: '[title] Team own role bindings panel: title',
  },
  approvedBindingsTab: {
    defaultMessage: 'Current',
    description: '[title] Team own role bindings panel: tab label',
  },
  myRequestsTab: {
    defaultMessage: 'My requests',
    description: '[title] Team own role bindings panel: tab label',
  },
});
