import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  requests: {
    defaultMessage: 'Time off requests',
    description: '[title] Page title',
  },
  createRequest: {
    defaultMessage: 'Request time off',
    description: '[title] Page title',
  },
  createRequestOnBehalf: {
    defaultMessage: 'Request time off on behalf of {fullName}',
    description: '[title] Page title',
  },
});
