import {intranetApi} from 'apiClient';
import {TimeOffRequest, timeOffRequestSchema} from 'domain/timeOff/request/model';
import {TimeOffRequestId} from 'domain/timeOff/request/model/id';

export type AccountingSystemRecordConfig = {
  id: TimeOffRequestId;
  recordedInAccountingSystem: boolean;
};

export const setRecordedInAccountingSystem = async ({
  id,
  recordedInAccountingSystem,
}: AccountingSystemRecordConfig): Promise<TimeOffRequest> => {
  const {data} = await intranetApi.post(
    '/v1/timeOffs/requests/setRecordedInAccountingSystem',
    {recordedInAccountingSystem},
    {params: {id}},
  );

  return timeOffRequestSchema.runWithException(data);
};
