import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Button} from '@joomcode/joom-ui/Button';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {bookRoomFx} from 'domain/officeMap/office/stores/main';
import {$offices} from 'domain/officeMap/office/stores/main/state';
import {isRoomBusyNow, OfficeRoom, getAllBookingOptions} from 'domain/officeMap/room/model';
import {useStore} from 'effector-react';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './roomBooking.css';

type Props = {
  officeAlias: OfficeAlias;
  room: OfficeRoom;
};

export const RoomBooking = ({officeAlias, room}: Props) => {
  const intl = useIntl();
  const officesStore = useStore($offices);

  const onRoomBook = useCallback((endTime: Date) => {
    bookRoomFx({roomId: room.id, endTime, officeAlias}).catch(toaster.interceptThenThrowError);
  }, []);

  const roomBookingState = officesStore.bookRoomState[room.id];
  if (roomBookingState?.dataState === DataState.LOADING) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  if (roomBookingState?.dataState === DataState.LOADED) {
    return (
      <StyledExternalLink colored href={roomBookingState.link}>
        {intl.formatMessage(messages.viewEvent)}
      </StyledExternalLink>
    );
  }

  if (isRoomBusyNow(room)) {
    return <div className={styles.busy}>{intl.formatMessage(messages.roomIsBusy)}</div>;
  }

  const bookingOptions = getAllBookingOptions(room);
  return (
    <>
      {bookingOptions.length === 0 ? (
        <div>{intl.formatMessage(messages.roomIsAvailableButWillBeBusy)}</div>
      ) : (
        <>
          <div className={styles.bookNote}>{intl.formatMessage(messages.bookRoomUntil)}</div>
          <div className={styles.buttonsWrapper}>
            {bookingOptions.map((endTime) => {
              return (
                <div key={endTime.getTime()} className={styles.button}>
                  <Button onClick={() => onRoomBook(endTime)} intent='neutral' kind='primary' size='m'>
                    {`${endTime.toLocaleTimeString(intl.locale, {
                      hour12: false,
                      hour: '2-digit',
                      minute: '2-digit',
                    })}`}
                  </Button>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
