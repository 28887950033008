import {TimeOffRequestCreationOnBehalfConfig} from 'domain/timeOff/request/api/createOnBehalf';
import {AccountingSystemRecordConfig} from 'domain/timeOff/request/api/setRecordedInAccountingSystem';
import {TimeOffRequest, TimeOffRequestCreationConfig} from 'domain/timeOff/request/model';
import {TimeOffRequestId} from 'domain/timeOff/request/model/id';
import {createEffect} from 'effector';

export const getMyTimeOffRequestsFx = createEffect<void, TimeOffRequest[]>('load my time off requests');
export const getSubordinatesTimeOffRequestsFx = createEffect<void, TimeOffRequest[]>(
  'load subordinates time off requests',
);
export const createTimeOffRequestFx = createEffect<TimeOffRequestCreationConfig, TimeOffRequest>(
  'create time off request',
);
export const createTimeOffRequestOnBehalfFx = createEffect<TimeOffRequestCreationOnBehalfConfig, TimeOffRequest>(
  'create time off request on behalf of another joomer',
);
export const approveTimeOffRequestFx = createEffect<TimeOffRequestId, TimeOffRequest>('approve time off request');
export const approveSubordinateTimeOffRequestFx = createEffect<TimeOffRequestId, TimeOffRequest>(
  'approve subordinate’s time off request',
);
export const cancelTimeOffRequestFx = createEffect<TimeOffRequestId, TimeOffRequest>('cancel time off request');
export const rejectTimeOffRequestFx = createEffect<TimeOffRequestId, TimeOffRequest>('reject time off request');
export const rejectSubordinateTimeOffRequestFx = createEffect<TimeOffRequestId, TimeOffRequest>(
  'reject subordinate’s time off request',
);
export const setRecordedInAccountingSystemFx = createEffect<AccountingSystemRecordConfig, TimeOffRequest>(
  'set time off request as recorded in accounting system',
);
