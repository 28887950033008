import {SecurePermission} from 'domain/permission/model/secure';
import {withAuth} from 'hocs/withAuth';
import {withSecurePermission} from 'hocs/withSecurePermission';
import {RegularLayout} from 'layouts/Regular';
import {SecureRolePage} from 'pages/SecureRole';
import {SecureRolesPage} from 'pages/SecureRoles';
import {RouteConfig} from 'react-router-config';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {secureRolesUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: secureRolesUrlPatterns.root,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: withSecurePermission(SecureRolesPage, SecurePermission.SECURE_ROLE_READ),
      exact: true,
      path: secureRolesUrlPatterns.root,
    },
    {
      component: withSecurePermission(SecureRolePage, SecurePermission.SECURE_ROLE_READ),
      exact: true,
      path: secureRolesUrlPatterns.role,
    },
    notFoundRouteConfig,
  ],
};
