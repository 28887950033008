import React, {memo} from 'react';
import {useIntl, IntlShape} from 'react-intl';
import {FileSizeMeasure, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {messages} from './messages';

export function formatFileSize(
  intl: IntlShape,
  measure: FileSizeMeasure,
  options: {noAutoFit?: boolean} = {noAutoFit: false},
): string {
  const {value, unit} = !options.noAutoFit ? fileSizeUtils.normalize(measure) : measure;

  return intl.formatMessage(messages[unit], {
    value: intl.formatNumber(value, {
      maximumFractionDigits: 1,
    }),
  });
}

type Props = {value: FileSizeMeasure; noAutoFit?: boolean};

export const FormattedFileSize = memo(({value: measure, noAutoFit = false}: Props) => {
  const intl = useIntl();

  return <>{formatFileSize(intl, measure, {noAutoFit})}</>;
});
