export enum FileValidatorErrorCode {
  FILE_INVALID = 'file_invalid',
  FILE_SIZE_OUT_OF_RANGE = 'file_size_out_of_range',
  FILE_SIZE_TOO_BIG = 'file_size_to_big',
  FILE_SIZE_TOO_SMALL = 'file_size_to_small',
  FILE_INVALID_MIME_TYPE = 'file_invalid_mime_type',
  FILE_INVALID_EXTENSION = 'file_invalid_extension',
}

export type FileValidatorOptions = {
  minSize?: number;
  maxSize?: number;
  mimeTypes?: string[];
  extensions?: string[];
};
