import {identity} from '@joomcode/deprecated-utils/function';
import {FieldTextarea} from '@joomcode/joom-form';
import {Panel} from '@joomcode/joom-ui/Panel';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {OfferFormSectionTitle} from 'domain/offer/widgets/FormSectionTitle';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  compensation?: OfferCompensation;
};

export function OfferCommentFields({compensation}: Props) {
  const intl = useIntl();

  return (
    <>
      <OfferFormSectionTitle title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <FieldTextarea
          name='compensation.commentForHrAdmin'
          reserveSpaceForError={false}
          initialValue={compensation?.commentForHrAdmin}
          parse={identity}
          rows={2}
        />
      </Panel.Content>
    </>
  );
}
