import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {CreateSalaryRecordParams} from 'domain/salaryRecord/api/types/create';
import {SalaryRecord, salaryRecordSchema} from 'domain/salaryRecord/model';

export const create = async ({diff, ...params}: CreateSalaryRecordParams): Promise<SalaryRecord[]> => {
  const {data} = await intranetApi.post('/v1/secure/users/salaryRecords/create', diff, {params});

  return array(salaryRecordSchema).runWithException(data);
};
