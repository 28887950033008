import cn from 'classnames';
import * as React from 'react';
import {ErrorContainer} from './ErrorContainer';
import styles from './index.css';

export type FieldsetProps = {
  children: React.ReactNode;
  disabled?: boolean;
  error?: React.ReactNode;
  hint?: React.ReactNode;
  label?: React.ReactNode;
  required?: boolean;
  reserveSpaceForError?: boolean;
  useMargin?: boolean;
};

export function Fieldset({
  children,
  disabled,
  error,
  hint,
  label,
  required,
  reserveSpaceForError = true,
  useMargin = true,
}: FieldsetProps) {
  return (
    <fieldset
      disabled={disabled}
      className={cn(styles.formControl, {
        [styles.formControlRequired]: required,
        [styles.useMargin]: useMargin,
      })}
    >
      <legend className={cn(styles.label, {[styles.textDisabled]: disabled})}>{label}</legend>

      <div className={styles.content}>{children}</div>

      {hint && <div className={cn(styles.hint, {[styles.textDisabled]: disabled})}>{hint}</div>}

      <ErrorContainer error={error} reserveSpaceForError={reserveSpaceForError} />
    </fieldset>
  );
}
