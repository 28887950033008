import {identity} from '@joomcode/deprecated-utils/function';
import {validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {Panel} from '@joomcode/joom-ui/Panel';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {FieldPhone} from 'components/widgets/fields/FieldPhone';
import {CompanyUnits} from 'domain/companyUnits/model';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import React from 'react';
import {Field, useField} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import styles from '../UserForm.css';
import {fieldLabels, messages} from './messages';

type Props = {
  companyUnits: CompanyUnits;
  privateInfo: UserPrivateInfo;
  submitting?: boolean;
};

export function UserFormEmergency({companyUnits, privateInfo, submitting}: Props) {
  const intl = useIntl();
  const {meta: phoneFieldMeta} = useField<string>('emergencyContact.phone');

  return (
    <>
      <Panel.Header title={intl.formatMessage(messages.emergencyContactSectionTitle)} withSeparator />
      <Panel.Content withPadding>
        <div className={styles.form}>
          <Field<string>
            name='emergencyContact.name'
            initialValue={privateInfo.emergencyContact?.name}
            parse={identity}
            validate={validateFilled}
          >
            {({input, meta}) => (
              <FormControl
                disabled={submitting}
                label={intl.formatMessage(fieldLabels.name)}
                required
                error={getFieldErrorText(meta, {intl})}
              >
                {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
              </FormControl>
            )}
          </Field>
          <FormControl
            disabled={submitting}
            label={intl.formatMessage(fieldLabels.phone)}
            required
            error={getFieldErrorText(phoneFieldMeta, {intl})}
          >
            {(formControlProps) => (
              <FieldPhone
                countryCode={privateInfo.legalEntity?.countryCode}
                disabled={submitting}
                initialValue={privateInfo.emergencyContact?.phone}
                name='emergencyContact.phone'
                validate={validateFilled}
                {...formControlProps}
              />
            )}
          </FormControl>
          <Field<string | null>
            name='emergencyContact.relationship'
            initialValue={privateInfo.emergencyContact?.relationship}
          >
            {({input}) => (
              <FormControl disabled={submitting} label={intl.formatMessage(fieldLabels.relationship)}>
                {(formControlProps) => (
                  <SimpleAutocomplete
                    items={companyUnits.emergencyContactRelationships}
                    getItemKey={identity}
                    disabled={submitting}
                    clearable
                    {...formControlProps}
                    {...input}
                  />
                )}
              </FormControl>
            )}
          </Field>
        </div>
      </Panel.Content>
    </>
  );
}
