import {IntlShape} from 'react-intl';
import {NewcomerFormTemplateCountry} from '.';
import {messages} from './messages';

export const formatTemplateCountry = (country: NewcomerFormTemplateCountry, intl: IntlShape) => {
  if (country === NewcomerFormTemplateCountry.OTHER) {
    return intl.formatMessage(messages.other);
  }

  return intl.formatDisplayName(country, {type: 'region'}) ?? country;
};
