import {intranetApi} from 'apiClient';
import {OfferId} from 'domain/offer/model';

type Params = {
  offerId: OfferId;
  fileId: string;
};

export const buildDocumentPreviewLink = (params: Params) =>
  intranetApi.getUri({
    params,
    url: `${intranetApi.defaults.baseURL}/v1/offers/previewDocument`,
  });
