import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ClampedText} from 'components/ui/ClampedText';
import {HintIcon} from 'components/ui/HintIcon';
import {JobContractRecord, JobContractRecordId} from 'domain/jobContract/model/record';
import {ColumnId} from 'domain/jobContract/model/record/table/columnId';
import {columnNames} from 'domain/jobContract/model/record/table/messages';
import {JobContractStatus} from 'domain/jobContract/model/status';
import {formatJobContractStatus} from 'domain/jobContract/model/status/formatter';
import {formatContractType} from 'domain/jobContract/model/type/formatter';
import {JobContractRecordMenu} from 'domain/jobContract/widgets/Menu';
import {formatTimeOffPolicy} from 'domain/timeOff/policy/model/formatter';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  records: JobContractRecord[];
  onDeleteItem?: (id: JobContractRecordId) => void;
  onEditItem?: (record: JobContractRecord) => void;
};
type JobContractColumn = Column<JobContractRecord>;

const getRowClassName = ({active}: JobContractRecord) => (!active ? styles.inactiveRow : '');
const getRowKey = ({id}: JobContractRecord) => id;

export function JobContractRecordsTable({records, onDeleteItem, onEditItem}: Props) {
  const intl = useIntl();
  const columns: JobContractColumn[] = useMemo(() => {
    const basicColumns: JobContractColumn[] = [
      {
        defaultWidth: 10,
        id: ColumnId.EFFECTIVE_DATE,
        name: intl.formatMessage(columnNames[ColumnId.EFFECTIVE_DATE]),
        render: ({effectiveDate}) => <HumanDate value={effectiveDate} format='short' utc />,
      },
      {
        defaultWidth: 10,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({status, onHoldReason}) => (
          <>
            {formatJobContractStatus(status, intl)}
            {status === JobContractStatus.ON_HOLD && onHoldReason && <HintIcon>{onHoldReason}</HintIcon>}
          </>
        ),
      },
      {
        defaultWidth: 10,
        id: ColumnId.CONTRACT_TYPE,
        name: intl.formatMessage(columnNames[ColumnId.CONTRACT_TYPE]),
        render: ({contractType}) => (contractType ? formatContractType(contractType, intl) : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: 20,
        id: ColumnId.POSITION_TITLE,
        name: intl.formatMessage(columnNames[ColumnId.POSITION_TITLE]),
        render: ({positionTitle}) => positionTitle || <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 10,
        id: ColumnId.TIME_OFF_POLICY,
        name: intl.formatMessage(columnNames[ColumnId.TIME_OFF_POLICY]),
        render: ({countryOfTimeOffPolicy}) =>
          countryOfTimeOffPolicy ? formatTimeOffPolicy(countryOfTimeOffPolicy, intl) : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 15,
        id: ColumnId.COMMENT,
        name: intl.formatMessage(columnNames[ColumnId.COMMENT]),
        render: ({comment}) => (comment ? <ClampedText text={comment} /> : <DataTable.CellEmpty />),
      },
    ];

    const actionsColumn: JobContractColumn = {
      defaultWidth: 5,
      id: 'actions',
      name: '',
      render: (record) => (
        <JobContractRecordMenu jobContractRecord={record} onDelete={onDeleteItem} onEdit={onEditItem} />
      ),
    };

    return onDeleteItem || onEditItem ? [...basicColumns, actionsColumn] : basicColumns;
  }, [intl]);

  return (
    <DataTable
      columns={columns}
      dataState={DataState.LOADED}
      data={records}
      emptyMessage={intl.formatMessage(messages.empty)}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      storageName='jobContractRecordsList'
    />
  );
}
