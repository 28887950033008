import React from 'react';
import {TreeItem} from './TreeItem';

type Props = {
  children?: React.ReactNode;
};

export function Tree({children}: Props) {
  return <>{children}</>;
}

Tree.Item = TreeItem;
