import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  amount: {
    defaultMessage: 'Amount',
    description: 'One-time bonus record form: field label',
  },
  condition: {
    defaultMessage: 'Condition',
    description: 'One-time bonus record form: field label',
  },
  comment: {
    defaultMessage: 'Comment',
    description: 'One-time bonus record form: field label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'One-time bonus record form: field label',
  },
  date: {
    defaultMessage: 'Date',
    description: 'One-time bonus record form: field label',
  },
  penalDeadline: {
    defaultMessage: 'Penal date',
    description: 'One-time bonus record form: field label',
  },
  type: {
    defaultMessage: 'Type',
    description: 'One-time bonus record form: field label',
  },
});
