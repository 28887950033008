/**
 * Source https://github.com/DamonOehlman/wildcard
 */

export class Wildcard {
  private readonly hasWild: boolean;

  private readonly pattern: string;

  private readonly patternParts: string[];

  private readonly separator: RegExp;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private static SEPARATOR = /[/.]/;

  public constructor(pattern: string, separator?: RegExp) {
    const wildcardSeparator = separator || Wildcard.SEPARATOR;
    this.hasWild = pattern.indexOf('*') > -1;
    this.pattern = pattern;
    this.patternParts = pattern.split(wildcardSeparator);
    this.separator = wildcardSeparator;
  }

  public match(target: string): boolean {
    if (!this.hasWild) {
      return this.pattern === target;
    }

    const testParts = target.split(this.separator);

    for (let i = 0; i < this.patternParts.length; i++) {
      if (this.patternParts[i] !== '*' && this.patternParts[i] !== testParts[i]) {
        return false;
      }
    }

    return true;
  }

  public matchObject<T>(target: {[key: string]: T}): {[key: string]: T} {
    const matches: {[key: string]: T} = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key in target) {
      if (this.match(key)) {
        matches[key] = target[key];
      }
    }

    return matches;
  }

  public matchList(target: string[]): string[] {
    const matches: string[] = [];

    for (let i = target.length; i--; ) {
      if (this.match(target[i])) {
        matches[matches.length] = target[i];
      }
    }

    return matches;
  }

  /**
   * @example
   *    npf.string.Wildcard.match('foo.*', 'foo.bar') -> true
   *    npf.string.Wildcard.match('foo.*', 'foo') -> true
   */
  public static match(pattern: string, target: string, separator?: RegExp): boolean {
    return new Wildcard(pattern, separator).match(target);
  }

  /**
   * @example
   *    npf.string.Wildcard.matchList(
   *        'a.b.*', ['a.b.c', 'a.b', 'a', 'a.b.d']) -> ['a.b.c', 'a.b', 'a.b.d']
   */
  public static matchList(pattern: string, target: string[], separator?: RegExp): string[] {
    return new Wildcard(pattern, separator).matchList(target);
  }

  /**
   * @example
   *    npf.string.Wildcard.matchObject('a.*.c', {
   *      'a.b.c' : {},
   *      'a.b'   : {},
   *      'a'     : {},
   *      'a.b.d' : {}}) -> { 'a.b.c': {} }
   */
  public static matchObject<T>(pattern: string, target: {[key: string]: T}, separator?: RegExp): {[key: string]: T} {
    return new Wildcard(pattern, separator).matchObject(target);
  }
}
