import {identity} from '@joomcode/deprecated-utils/function';
import {CommonFieldProps, getFieldValidator} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {StockOptionAgreementType} from 'domain/stockOption/agreement/model/type';
import {availableTypeValues} from 'domain/stockOption/agreement/model/type/availableValues';
import {formatStockOptionAgreementType} from 'domain/stockOption/agreement/model/type/formatter';
import React, {useCallback} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

export type Props = CommonFieldProps<StockOptionAgreementType>;

export function FieldStockOptionAgreementType({
  name,
  label,
  hint,
  required,
  disabled,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const typeToString = useCallback(
    (type: StockOptionAgreementType) => formatStockOptionAgreementType(type, intl),
    [intl],
  );

  return (
    <Field<StockOptionAgreementType>
      name={name}
      validate={validate && getFieldValidator(validate)}
      initialValue={initialValue}
    >
      {({input, meta}) => (
        <FormControl
          hint={hint}
          label={label}
          disabled={meta.submitting || disabled}
          error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
          required={required}
          reserveSpaceForError={reserveSpaceForError}
        >
          {(formControlProps) => (
            <SimpleAutocomplete<StockOptionAgreementType>
              items={availableTypeValues}
              getItemKey={identity}
              itemToString={typeToString}
              renderItem={typeToString}
              {...formControlProps}
              {...input}
              {...restProps}
              disabled={meta.submitting || disabled}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
}
