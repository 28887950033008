import {Panel} from '@joomcode/joom-ui/Panel';
import {RoleBinding} from 'domain/roleBinding/model';
import {ColumnId} from 'domain/team/model/roleBindingsTableColumnId';
import React from 'react';
import {useIntl} from 'react-intl';
import {TeamRoleBindingsTable} from '../RoleBindingsTable';
import {messages} from './messages';

type Props = {
  roleBindings: RoleBinding[];
};

export function TeamInheritedRoleBindingsPanel({roleBindings}: Props) {
  const intl = useIntl();

  return (
    <Panel withSeparator title={intl.formatMessage(messages.title)}>
      <TeamRoleBindingsTable
        data={roleBindings}
        columnWidths={{[ColumnId.SERVICE]: 7, [ColumnId.RESOURCE]: 7, [ColumnId.ROLE]: 7, [ColumnId.TEAM]: 14}}
        storageName='team.inheritedRoleBindings.list'
      />
    </Panel>
  );
}
