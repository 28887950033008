import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Options',
    description: '[title] Compensation review request: section title',
  },
  granted: {
    defaultMessage: 'Granted',
    description: 'Compensation review request label',
  },
  nextYear: {
    defaultMessage: 'Next 12 months vesting',
    description: 'Compensation review request label',
  },
  vested: {
    defaultMessage: 'Vested',
    description: 'Compensation review request label',
  },
  available: {
    defaultMessage: 'Available',
    description: 'Compensation review request label',
  },
});
