import {generatePath} from 'react-router-dom';

export const jdmUrlPatterns = {
  root: '/jdm',
  resources: '/jdm/resources',
  resource: '/jdm/resources/:resourceId',
  services: '/jdm/services',
  service: '/jdm/services/:serviceId',
};

export const jdmUrls = {
  root: () => jdmUrlPatterns.root,
  resources: () => jdmUrlPatterns.resources,
  resource: (pathParams: {resourceId: string}) => generatePath(jdmUrlPatterns.resource, pathParams),
  services: () => jdmUrlPatterns.services,
  service: (pathParams: {serviceId: string}) => generatePath(jdmUrlPatterns.service, pathParams),
};
