import {generatePath} from 'react-router-dom';

export const legalEntitiesUrlPatterns = {
  root: '/legalEntities',
  legalEntity: '/legalEntities/:legalEntityId',
};

export const legalEntitiesUrls = {
  root: () => legalEntitiesUrlPatterns.root,
  legalEntity: (pathParams: {legalEntityId: string}) => generatePath(legalEntitiesUrlPatterns.legalEntity, pathParams),
};
