import {StyledLink} from 'components/ui/StyledLink';
import {Permission} from 'domain/permission/model';
import {Resource} from 'domain/resource/model';
import React from 'react';
import {jdmUrls} from 'routes/jdm/urls';
import {useAcl} from 'services/acl';

export type Props = {
  resource: Resource;
};

export function ResourceLink({resource}: Props) {
  const acl = useAcl();

  return (
    <StyledLink
      colored
      to={jdmUrls.resource({resourceId: resource.id})}
      disabled={!acl.hasPermission(Permission.RESOURCE_READ)}
    >
      {resource.displayName}
    </StyledLink>
  );
}
