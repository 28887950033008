import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Mark offer as accepted',
    description: '[title] Offer accept dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Offer accept dialog',
    description: 'Offer accept dialog: aria label',
  },
  buttonSubmit: {
    defaultMessage: 'Submit',
    description: '[button] Offer accept dialog: submit button',
  },
  error: {
    defaultMessage: 'Link to interviewers’ feedback is not specified',
    description: 'Offer accept dialog: error message',
  },
  templateCountryHint: {
    defaultMessage: 'Required documents depend on the template',
    description: 'Country field hint',
  },
});

export const labels = defineMessages({
  templateCountry: {
    defaultMessage: 'Form template',
    description: 'Offer accept dialog: field label',
  },
  linkToInterviewFeedback: {
    defaultMessage: 'Link to interviewers’ feedback',
    description: 'Offer accept dialog: field label',
  },
});
