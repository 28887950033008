import React from 'react';
import {FormattedMessage} from 'react-intl';
import {LabelRenderProps} from '../../types';
import {Options} from './types';

export function TextLabel({options, name, value, ValueWrapper}: LabelRenderProps<string, Options>) {
  if (options.type === 'equal') {
    return (
      <FormattedMessage
        defaultMessage='{filterName} is {filterValue}'
        description='Label for equal text filter, e.g. "Product ID is abc"'
        id='datafilter-text-equal-label'
        values={{
          filterName: name,
          filterValue: value ? <ValueWrapper>{value}</ValueWrapper> : '',
        }}
      />
    );
  }

  return (
    <FormattedMessage
      defaultMessage='{filterName} contains {filterValue}'
      description='TextContains Filter label, e.g. "Product Name contains Kitten"'
      id='datafilter-text-contain-label'
      values={{
        filterName: name,
        filterValue: value ? <ValueWrapper>{value}</ValueWrapper> : '',
      }}
    />
  );
}
