import {uniqueArray} from '@joomcode/deprecated-utils/array/unique';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {not} from '@joomcode/deprecated-utils/function/not';
import {MultiSelectState, useMultiSelect} from '@joomcode/deprecated-utils/react/useMultiSelect';
import {getAncestors} from '@joomcode/deprecated-utils/tree/getAncestors';
import {getDescendants} from '@joomcode/deprecated-utils/tree/getDescendants';
import {useCallback, useMemo} from 'react';

const falsePredicate = () => false;

type UseTreeViewOptions<T> = {
  items: T[];
  onlyLeaves?: boolean;
  getItemKey(item: T): string;
  getItemChildren(item: T): T[] | undefined | null;
  getItemParent(item: T): T | undefined | null;
  isItemDisabled?(key: string): boolean;
  isItemSelectedInitially?(key: string): boolean;
  limit?: number;
};
export type TreeMultiSelectState<T> = Pick<
  MultiSelectState<T>,
  'isItemSelected' | 'selectItem' | 'deselectItem' | 'toggleItem' | 'deselectAll'
> & {
  getSelectedItems(): string[];
  isItemIndeterminate(item: T): boolean;
  selectOnlyItems(keys: string[]): void;
  isItemDisabled(item: T): boolean;
};

export function useTreeMultiSelect<T>({
  items,
  onlyLeaves = false,
  getItemChildren,
  getItemKey,
  getItemParent,
  isItemDisabled = falsePredicate,
  isItemSelectedInitially,
  limit,
}: UseTreeViewOptions<T>): TreeMultiSelectState<T> {
  const flatItems = useMemo(() => {
    return items
      .map((item) => [item, ...getDescendants(item, getItemChildren)])
      .filter(isNotNullish)
      .flat();
  }, [items]);
  const itemByKey = useMemo<Map<string, T>>(
    () => new Map(flatItems.map((item) => [getItemKey(item), item])),
    [flatItems, getItemKey],
  );
  const keys = useMemo(() => flatItems.map(getItemKey), [flatItems, getItemKey]);
  const ancestorsByItemKey = useMemo<Map<string, string[]>>(
    () => new Map(flatItems.map((item) => [getItemKey(item), getAncestors(item, getItemParent).map(getItemKey)])),
    [flatItems, getItemKey, getItemParent],
  );
  const descendantsByItemKey = useMemo<Map<string, string[]>>(
    () => new Map(flatItems.map((item) => [getItemKey(item), getDescendants(item, getItemChildren).map(getItemKey)])),
    [flatItems, getItemKey, getItemChildren],
  );
  const childrenByItemKey = useMemo<Map<string, string[]>>(
    () => new Map(flatItems.map((item) => [getItemKey(item), (getItemChildren(item) ?? []).map(getItemKey)])),
    [flatItems, getItemKey, getItemChildren],
  );

  const initialSelectedKeys = useMemo(() => {
    const allSelectedKeys: string[] = [];
    const result: string[] = [];

    if (isItemSelectedInitially) {
      for (const key of keys) {
        if (isItemSelectedInitially(key)) {
          allSelectedKeys.push(key);
        } else {
          const descendants = descendantsByItemKey.get(key) ?? [];
          if (descendants.length > 0 && descendants.every((descendant) => isItemSelectedInitially(descendant))) {
            allSelectedKeys.push(key);
          }
        }
      }

      for (const key of allSelectedKeys) {
        const ancestors = ancestorsByItemKey.get(key) || [];
        if (!ancestors.some((ancestorKey) => allSelectedKeys.includes(ancestorKey))) {
          result.push(key);
        }
      }
    }
    return result;
  }, [isItemSelectedInitially, keys, descendantsByItemKey, ancestorsByItemKey]);

  const selection = useMultiSelect(keys, isItemSelectedInitially && ((key) => initialSelectedKeys.includes(key)));

  const isItemOrAncestorSelected = useCallback(
    (key: string) => {
      const ancestors = ancestorsByItemKey.get(key) || [];
      return selection.isItemSelected(key) || ancestors.some(selection.isItemSelected);
    },
    [ancestorsByItemKey, selection.isItemSelected],
  );

  const selectIfNotDisabled = useCallback(
    (key: string) => {
      if (!isItemDisabled(key)) {
        selection.selectItem(key);
      }
    },
    [isItemDisabled, selection.selectItem],
  );

  const deselectIfNotDisabled = useCallback(
    (key: string) => {
      if (!isItemDisabled(key)) {
        selection.deselectItem(key);
      }
    },
    [isItemDisabled, selection.deselectItem],
  );

  const moveSelectionUp = useCallback(
    (key: string) => {
      const ancestorKeys = ancestorsByItemKey.get(key) ?? [];
      ancestorKeys.reverse().forEach((ancestor) => {
        const children = childrenByItemKey.get(ancestor) || [];

        if (children.every(selection.isItemSelected)) {
          selectIfNotDisabled(ancestor);
          children.forEach(deselectIfNotDisabled);
        } else if (children.some(not(selection.isItemSelected))) {
          deselectIfNotDisabled(ancestor);
        }
      });
    },
    [ancestorsByItemKey, childrenByItemKey, selection.isItemSelected, selectIfNotDisabled, deselectIfNotDisabled],
  );

  const moveSelectionDown = useCallback(
    (key: string) => {
      const ancestorKeys = ancestorsByItemKey.get(key) ?? [];
      ancestorKeys.forEach((ancestorKey) => {
        if (isItemOrAncestorSelected(ancestorKey)) {
          deselectIfNotDisabled(ancestorKey);

          const children = childrenByItemKey.get(ancestorKey) ?? [];
          children.forEach((item) => {
            if (item !== key) {
              selectIfNotDisabled(item);
            }
          });
        }
      });
    },
    [ancestorsByItemKey, childrenByItemKey, isItemOrAncestorSelected, selectIfNotDisabled, deselectIfNotDisabled],
  );

  const select = useCallback(
    (key: string, checked: boolean) => {
      if (isItemDisabled(key)) {
        return;
      }

      if (limit) {
        if (checked) {
          if (limit > selection.getSelectedItems().length) {
            selection.selectItem(key);
          }
        } else {
          const descendantKeys = descendantsByItemKey.get(key) || [];

          selection.deselectItem(key);
          descendantKeys.forEach(selection.deselectItem);
        }
      } else if (checked) {
        selection.selectItem(key);
        moveSelectionUp(key);

        // Remove selection for all descendants.
        // We don't need to keep them selected,
        // because their ancestor covers this behaviour.
        const descendants = descendantsByItemKey.get(key) || [];
        descendants.forEach((descendant) => {
          if (selection.isItemSelected(descendant)) {
            deselectIfNotDisabled(descendant);
          }
        });
      } else {
        selection.deselectItem(key);
        moveSelectionDown(key);
      }
    },
    [
      limit,
      descendantsByItemKey,
      selection.getSelectedItems,
      selection.selectItem,
      selection.deselectItem,
      selection.isItemSelected,
      moveSelectionUp,
      moveSelectionDown,
      isItemDisabled,
      deselectIfNotDisabled,
    ],
  );

  const selectItem = useCallback((item: T) => select(getItemKey(item), true), [select, getItemKey]);
  const deselectItem = useCallback((item: T) => select(getItemKey(item), false), [select, getItemKey]);
  const toggleItem = useCallback(
    (item: T) => {
      return select(getItemKey(item), !selection.isItemSelected(getItemKey(item)));
    },
    [select, selection.isItemSelected, getItemKey],
  );

  const isItemSelected = useCallback(
    (item: T) => {
      const key = getItemKey(item);
      if (limit) {
        return selection.isItemSelected(key);
      }

      return isItemOrAncestorSelected(key);
    },
    [limit, selection.isItemSelected, isItemDisabled, isItemOrAncestorSelected, getItemKey],
  );

  const isItemIndeterminate = useCallback(
    (item: T) => {
      const descendants = (descendantsByItemKey.get(getItemKey(item)) || []).map((key) => itemByKey.get(key));
      return descendants.some(isItemSelected) && descendants.some(not(isItemSelected));
    },
    [descendantsByItemKey, itemByKey, isItemSelected, getItemKey],
  );

  const isItemDisabledConsideringLimit = useCallback(
    (item: T) => {
      const key = getItemKey(item);
      return (
        isItemDisabled(key) ||
        Boolean(limit && limit <= selection.getSelectedItems().length && !selection.isItemSelected(key))
      );
    },
    [limit, selection.getSelectedItems, selection.isItemSelected, getItemKey, isItemDisabled],
  );

  const getSelectedItems = useCallback(() => {
    const selectedItems = selection.getSelectedItems();

    return onlyLeaves
      ? uniqueArray(
          selectedItems
            .flatMap((selectedItem) => [
              selectedItem,
              ...getDescendants(selectedItem, (i) => descendantsByItemKey.get(i)),
            ])
            .filter((selectedItem) => descendantsByItemKey.get(selectedItem)?.length === 0),
        )
      : selectedItems;
  }, [onlyLeaves, descendantsByItemKey, selection.getSelectedItems]);

  return {
    getSelectedItems,
    deselectAll: selection.deselectAll,
    isItemIndeterminate,
    isItemSelected,
    isItemDisabled: isItemDisabledConsideringLimit,
    selectItem,
    deselectItem,
    toggleItem,
    selectOnlyItems: selection.selectOnlyItems,
  };
}
