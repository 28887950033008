import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  button: {
    defaultMessage: 'Approve',
    description: '[button] Compensation request action (on actions panel)',
  },
  success: {
    defaultMessage: 'Request successfully approved',
    description: 'Approve compensation request: success message',
  },
});
