import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {NullableLegalInfo} from 'domain/legalInfo/model';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import {getUserLegalInfoByIdFx} from 'domain/user/stores/legalInfo';
import {$usersLegalInfo} from 'domain/user/stores/legalInfo/state';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: {legalInfo: NullableLegalInfo} | undefined;
  dataState: DataState;
  hasReadAccess: boolean;
};

export const useUserLegalInfo = (userId?: UserId): ReturnShape => {
  const acl = useAcl();
  const hasReadAccess = acl.hasPermission(Permission.USER_LEGAL_INFO_READ);

  const data = useStoreMap({
    store: $usersLegalInfo,
    keys: [userId],
    fn: ({byUserId}, [id]) => (id ? byUserId[id] ?? null : null),
  });
  const dataState = useStoreMap({
    store: $usersLegalInfo,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => (id && dataStateByUserId[id]) || DataState.IDLE,
  });

  useEffect(() => {
    if (userId && hasReadAccess && !isLoadingOrLoaded(dataState)) {
      getUserLegalInfoByIdFx(userId).catch(toaster.interceptThenThrowError);
    }
  }, [userId, hasReadAccess]);

  return {data: data === undefined ? undefined : {legalInfo: data}, dataState, hasReadAccess};
};
