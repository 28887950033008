import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  value: {
    defaultMessage: '{value} {currency}',
    description: 'Salary range value',
  },
  labelMin: {
    defaultMessage: 'min',
    description: 'Salary range value label',
  },
  labelTarget: {
    defaultMessage: 'mid',
    description: 'Salary range value label',
  },
  labelMax: {
    defaultMessage: 'max',
    description: 'Salary range value label',
  },
});
