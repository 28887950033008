import {QueryRequestPayload} from 'api/types';
import {resourcesApi} from 'domain/resource/api';
import {Resource, ResourcesSearchFilters} from 'domain/resource/model';
import {createResourceFx, getResourceByIdFx, getResourcesFx, getResourcesWithCountFx, updateResourceFx} from '.';

const getResourcesWithoutCount = async (query: QueryRequestPayload<ResourcesSearchFilters>): Promise<Resource[]> => {
  const {items} = await getResourcesWithCountFx(query);
  return items;
};

createResourceFx.use(resourcesApi.create);
getResourceByIdFx.use(resourcesApi.getById);
getResourcesFx.use(getResourcesWithoutCount);
getResourcesWithCountFx.use(resourcesApi.getAll);
updateResourceFx.use(resourcesApi.update);
