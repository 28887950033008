import {defineMessages} from 'react-intl';
import {OfferStockOptions} from '.';

export const stockOptionFieldLabels = defineMessages<keyof OfferStockOptions>({
  amount: {
    defaultMessage: 'Options',
    description: 'Offer field label',
  },
  issuer: {
    defaultMessage: 'Option issuer',
    description: 'Offer field label',
  },
});
