import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {useOffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Panel} from '@joomcode/joom-ui/Panel';
import {
  TimeOffRequest,
  TimeOffRequestsSearchFilters,
  timeOffRequestsSearchFiltersSchema,
} from 'domain/timeOff/request/model';
import {TimeOffRequestId} from 'domain/timeOff/request/model/id';
import {TimeOffRequestsTableMode} from 'domain/timeOff/request/model/mode';
import {getMyTimeOffRequestsFx, getSubordinatesTimeOffRequestsFx} from 'domain/timeOff/request/stores/main';
import {$myTimeOffRequests, $subordinatesTimeOffRequests} from 'domain/timeOff/request/stores/main/state';
import {getAllTimeOffRequestsFx} from 'domain/timeOff/request/stores/search';
import {$paginatedTimeOffRequestsList, $timeOffRequestsCount} from 'domain/timeOff/request/stores/search/state';
import {TimeOffRequestFilters} from 'domain/timeOff/request/widgets/Filters';
import {TimeOffRequestIdFilters} from 'domain/timeOff/request/widgets/IdFilters';
import {TimeOffTable} from 'domain/timeOff/request/widgets/Table';
import {useStore} from 'effector-react';
import {useStoredFilters} from 'hooks/useStoredFilters';
import React, {useCallback, useEffect, useMemo} from 'react';
import {toaster} from 'services/toaster';
import styles from './styles.css';

type Props = {
  dropId: () => void;
  id?: TimeOffRequestId;
  mode: TimeOffRequestsTableMode;
  hasSubordinates: boolean;
};

type SavedStore = {
  requests: TimeOffRequest[];
  dataState: DataState;
};

// This is needed to support the same filters as backend
const filterRequests = (filters: TimeOffRequestsSearchFilters, requests: TimeOffRequest[]): TimeOffRequest[] => {
  return requests.filter((request): boolean => {
    if (filters.startDate?.start && request.start && new Date(filters.startDate.start) > request.start) return false;
    if (filters.startDate?.end && request.start && new Date(filters.startDate.end) < request.start) return false;
    if (filters.endDate?.start && request.end && new Date(filters.endDate.start) > request.end) return false;
    if (filters.endDate?.end && request.end && new Date(filters.endDate.end) < request.end) return false;
    if (filters.types?.length && !filters.types.includes(request.type)) return false;
    if (filters.statuses?.length && !filters.statuses.includes(request.status)) return false;
    if (filters.userId && filters.userId !== request.user.id) return false;
    if (filters.userId && filters.userId !== request.user.id) return false;
    if (filters.id && filters.id !== request.id) return false;

    return true;
  });
};

export const TimeOffRequestsList = ({dropId, id, mode, hasSubordinates}: Props) => {
  const {filterValues, setFilterValues} = useStoredFilters<Omit<TimeOffRequestsSearchFilters, 'id'>>({
    id: 'timeOffRequests.filters',
    initialValues: {},
    valuesSchema: timeOffRequestsSearchFiltersSchema,
  });

  const myRequests = useStore($myTimeOffRequests);
  const subordinatesRequests = useStore($subordinatesTimeOffRequests);
  const allRequests = useStore($paginatedTimeOffRequestsList);
  const {count} = useStore($timeOffRequestsCount);
  const pagination = useOffsetPagination({initialLimit: 25, initialPage: 1});

  const getRequestsAndDataState = (): SavedStore => {
    const filters = id === undefined ? filterValues : {id};
    if (mode === TimeOffRequestsTableMode.MY) {
      return {
        // don't filter own requests by userId
        requests: filterRequests({...filters, userId: undefined}, myRequests.data),
        dataState: myRequests.dataState,
      };
    }
    if (mode === TimeOffRequestsTableMode.SUBORDINATES) {
      return {
        requests: filterRequests(filters, subordinatesRequests.data),
        dataState: subordinatesRequests.dataState,
      };
    }
    return {requests: allRequests.data, dataState: allRequests.dataState};
  };

  const onIdChange = useCallback(
    ({id: newIdValue}: {id: string}) => {
      if (newIdValue === undefined) {
        dropId();
      }
    },
    [dropId],
  );

  const pagingAndFilterOptions = useMemo(() => {
    return {
      offset: pagination.offset,
      limit: pagination.limit,
      filters: id === undefined ? filterValues : {id},
    };
  }, [pagination.offset, pagination.limit, filterValues, id]);

  // initial requests for every mode
  useEffect(() => {
    if (mode === TimeOffRequestsTableMode.MY && !isLoadingOrLoaded(myRequests.dataState)) {
      getMyTimeOffRequestsFx().catch(toaster.interceptThenThrowError);
    }
    if (hasSubordinates && !isLoadingOrLoaded(subordinatesRequests.dataState)) {
      getSubordinatesTimeOffRequestsFx().catch(toaster.interceptThenThrowError);
    }
    if (mode === TimeOffRequestsTableMode.ALL && !isLoadingOrLoaded(allRequests.dataState)) {
      getAllTimeOffRequestsFx(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
    }
  }, [mode]);

  // if 'allRequests' store resets we need to update it
  useEffect(() => {
    if (mode === TimeOffRequestsTableMode.ALL && allRequests.dataState === DataState.IDLE) {
      getAllTimeOffRequestsFx(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
    }
  }, [mode, allRequests.dataState]);

  // if filter or pagination changes we need to update all requests list
  useEffect(() => {
    if (mode === TimeOffRequestsTableMode.ALL) {
      getAllTimeOffRequestsFx(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
    }
  }, [mode, pagingAndFilterOptions]);

  return (
    <>
      <Panel>
        {id === undefined ? (
          <TimeOffRequestFilters onChange={setFilterValues} values={filterValues} mode={mode} />
        ) : (
          <TimeOffRequestIdFilters onChange={onIdChange} id={id} />
        )}
      </Panel>
      <Panel className={styles.tablePanel}>
        <TimeOffTable
          timeOffRequests={getRequestsAndDataState().requests}
          dataState={getRequestsAndDataState().dataState}
          mode={mode}
          pagination={mode === TimeOffRequestsTableMode.ALL ? pagination : undefined}
          count={Math.ceil(count / pagination.limit)}
        />
      </Panel>
    </>
  );
};
