import {
  array,
  boolean,
  dateFromString,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {legalInfoSchema} from 'domain/legalInfo/model';
import {idSchema} from 'models/system/id';
import {offerGeneralInfoSchema} from './generalInfo';
import {offerJobContractSchema} from './jobContract';
import {newcomerInOfferSchema} from './newcomer';
import {OfferStatus} from './status';
import {offerVersionSchema} from './version';

export const offerIdSchema = idSchema;
export const offerSchema = object({
  id: offerIdSchema,
  status: oneOfEnum(OfferStatus),
  declineReasons: optional(array(string())),
  createdTime: dateFromString(),
  updatedTime: dateFromString(),
  isChanging: optional(boolean()),
  general: offerGeneralInfoSchema,
  jobContract: optional(offerJobContractSchema),
  versions: array(offerVersionSchema),
  newcomer: optional(newcomerInOfferSchema),
  nameToken: string(),
  documentsData: optional(legalInfoSchema),
});

export const countableOfferSchema = object({
  items: array(offerSchema),
  count: number(),
});

export type Offer = ExtractSchemaType<typeof offerSchema>;
export type OfferId = ExtractSchemaType<typeof offerIdSchema>;
export type CountableOffers = ExtractSchemaType<typeof countableOfferSchema>;
