import React from 'react';
import {Spinner} from '../Spinner';
import styles from './index.css';

type Props = {
  color?: string;
  paddingTop?: string | number;
};

export function Preloader({paddingTop, color}: Props) {
  return (
    <div className={styles.preloader} style={{paddingTop}}>
      <Spinner color={color} />
    </div>
  );
}
