import cn from 'classnames';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import styles from './index.css';

export type LinkKind = 'none' | 'primary' | 'secondary';
export type LinkIntent = 'warning' | 'positive' | 'negative' | 'primary';

export type LinkUiProps = {
  /**
   * Defines link type. Affects the appearance.
   * - With kind `none` no styles will be applyed.
   * - Kind `primary` used for default colored link.
   * - Kind `secondary` used for non-colored links with underline.
   */
  kind?: LinkKind;

  /** Defines link intention. Affects the appearance. With kind `none` has no effect. */
  intent?: LinkIntent;

  /** Using for links for downloadable content. Adds attribute `download`. */
  download?: boolean | string;

  /**
   * Using for links to the external web sites.
   * Adds attributes `target='_blank'` and `rel='noopener noreferrer'`.
   */
  external?: boolean;
};

export function useLinkUi({
  kind = 'none',
  intent = 'primary',
  download = false,
  external = false,
  ...rest
}: JSX.IntrinsicElements['a'] & LinkUiProps = {}): JSX.IntrinsicElements['a'] {
  const linkUiProps: JSX.IntrinsicElements['a'] = {...rest};

  if (kind !== 'none') {
    linkUiProps.className = cn(styles[`kind${capitalize(kind)}`], styles[`intent${capitalize(intent)}`]);
  }

  if (external) {
    linkUiProps.target = '_blank';
    linkUiProps.rel = 'noopener noreferrer';
  }

  if (download) {
    linkUiProps.download = download;
  }

  return linkUiProps;
}
