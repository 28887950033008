import {FormattedPrice} from 'components/ui/FormattedPrice';
import {ListedProperty} from 'components/ui/ListedProperty';
import {MultilineText} from 'components/ui/MultilineText';
import {Section} from 'components/ui/Section';
import {CrValue} from 'domain/compaRatio/widgets/Value';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {compensationFieldLabels} from 'domain/offer/model/compensation/messages';
import {formatFurtherSalaryReason} from 'domain/offer/model/furtherSalary/formatter';
import {furtherSalaryFieldLabels} from 'domain/offer/model/furtherSalary/messages';
import {FormattedRegularBonus} from 'domain/regularBonusRecord/widgets/FormattedRegularBonus';
import {FormattedSalary} from 'domain/salaryRecord/widgets/FormattedSalary';
import React from 'react';
import {useIntl} from 'react-intl';
import {Feature, useFeature} from 'services/features';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  compensation: OfferCompensation | null;
  compensationsComment: string | null;
};

export function OfferCompensationDetailsSection({compensation, compensationsComment}: Props) {
  const intl = useIntl();
  const comment = compensation?.commentForHrAdmin || compensationsComment;
  const offerCrFeature = useFeature(Feature.OFFER_CR);

  return (
    <Section title={intl.formatMessage(messages.title)}>
      <div className={styles.properties}>
        {compensation && (
          <>
            {compensation.userFunction && (
              <ListedProperty label={intl.formatMessage(compensationFieldLabels.userFunction)}>
                {compensation.userFunction}
              </ListedProperty>
            )}
            {compensation.levelCode && (
              <ListedProperty label={intl.formatMessage(compensationFieldLabels.levelCode)}>
                {compensation.levelCode}
              </ListedProperty>
            )}
            <ListedProperty label={intl.formatMessage(compensationFieldLabels.salary)}>
              <FormattedSalary {...compensation.salary} />
            </ListedProperty>
          </>
        )}
        {offerCrFeature.isAvailable && compensation?.crResult && (
          <ListedProperty label={intl.formatMessage(compensationFieldLabels.crResult)}>
            <CrValue cr={compensation.crResult} coloredIcon />
          </ListedProperty>
        )}
        {compensation?.regularBonus && (
          <ListedProperty label={intl.formatMessage(compensationFieldLabels.regularBonus)}>
            <FormattedRegularBonus {...compensation.regularBonus} />
          </ListedProperty>
        )}
        {compensation?.signOnBonus && (
          <ListedProperty label={intl.formatMessage(compensationFieldLabels.signOnBonus)}>
            <FormattedPrice value={compensation.signOnBonus} />
          </ListedProperty>
        )}
        {compensation?.relocationBonus && (
          <ListedProperty label={intl.formatMessage(compensationFieldLabels.relocationBonus)}>
            <FormattedPrice value={compensation.relocationBonus} />
          </ListedProperty>
        )}
        {comment && (
          <ListedProperty label={intl.formatMessage(compensationFieldLabels.commentForHrAdmin)}>
            <MultilineText text={comment} />
          </ListedProperty>
        )}
        {compensation?.furtherSalary && (
          <>
            <ListedProperty label={intl.formatMessage(furtherSalaryFieldLabels.salary)}>
              <FormattedSalary {...compensation.furtherSalary.salary} />
            </ListedProperty>
            <ListedProperty label={intl.formatMessage(furtherSalaryFieldLabels.reason)}>
              {formatFurtherSalaryReason(compensation.furtherSalary.reason, intl)}
            </ListedProperty>
            {compensation.furtherSalary.reasonDetails && (
              <ListedProperty label={intl.formatMessage(furtherSalaryFieldLabels.reasonDetails)}>
                <MultilineText text={compensation.furtherSalary.reasonDetails} />
              </ListedProperty>
            )}
          </>
        )}
      </div>
    </Section>
  );
}
