import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {DialogInfoStripe} from 'components/ui/DialogInfoStripe';
import {Employment} from 'domain/employment/model';
import {terminateFx, updateTerminationFx} from 'domain/employment/stores/main';
import {EmploymentForm} from 'domain/employment/widgets/Form';
import {UserId} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  userId: UserId;
  employment?: Employment;
  isOpen: boolean;
  onClose: () => void;
};

export function EmploymentDialog({userId, employment, isOpen, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  const onSubmit = useCallback(
    (diff: Partial<Employment>) => {
      const action = employment
        ? updateTerminationFx({userId, employmentId: employment.id, diff})
        : terminateFx({userId, diff});
      return action.then(onClose).catch(toaster.interceptThenThrowError);
    },
    [employment, onClose, userId],
  );

  return (
    <Dialog
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      isOpen={isOpen}
      onClose={onClose}
      width='min(90vw, 48rem)'
    >
      <Dialog.Header>{intl.formatMessage(employment ? messages.titleUpdate : messages.titleTerminate)}</Dialog.Header>
      <Form<Employment> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Body withDefaultPadding>
              {!employment && <DialogInfoStripe>{intl.formatMessage(messages.subordinatesWarning)}</DialogInfoStripe>}
              <EmploymentForm employment={employment} formId={formId} onSubmit={handleSubmit} submitting={submitting} />
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent={employment ? 'primary' : 'negative'}
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(employment ? messages.submitButton : messages.terminateButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
