import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Job contract record dialog',
    description: 'Job contract record dialog: aria label',
  },
  titleAdd: {
    defaultMessage: 'Add job contract record',
    description: '[title] Job contract record dialog: title (addition mode)',
  },
  titleUpdate: {
    defaultMessage: 'Update job contract record',
    description: '[title] Job contract record dialog: title (update mode)',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Job contract record dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Submit',
    description: '[button] Job contract record dialog: submit button',
  },
  loadingError: {
    defaultMessage: 'Failed to load data. Try to refresh the page.',
    description: 'Job contract record dialog: error message',
  },
  resetBalance: {
    defaultMessage: 'Reset current vacation balance of {currentBalance} days to zero',
    description: 'Job contract record dialog: form field label',
  },
});
