import {useCallback, useMemo, useRef} from 'react';
import {useField} from 'react-final-form';
import {FieldArrayProps, MapFn, Options, PushFn, PushManyFn, RemoveFn, UpdateFn, SetValueFn} from './types';

export const useFieldArray = <T,>(
  name: string,
  {initialValue = [], ...rest}: Omit<FieldArrayProps<T>, 'children' | 'name'> = {},
): Options<T> => {
  // initial value must be memoized to prevent infinite loop (https://github.com/final-form/react-final-form/issues/591#issuecomment-548112196)
  const initValue = useMemo(() => initialValue, []);
  const inputValueRef = useRef<T[]>(initValue);

  const field = useField<T[]>(name, {initialValue: initValue, ...rest});
  const length = field.meta.length || 0;
  // for async access
  inputValueRef.current = field.input.value || [];

  const push: PushFn<T> = useCallback(
    (el) => {
      const {value} = field.input;
      field.input.onChange(Array.isArray(value) ? [...value, el] : [el]);
    },
    [field],
  );

  const pushMany: PushManyFn<T> = useCallback(
    (els) => {
      const {value} = field.input;
      field.input.onChange(Array.isArray(value) ? [...value, ...els] : els);
    },
    [field],
  );

  const remove: RemoveFn = useCallback(
    (index) => {
      const {value} = field.input;
      const normalizedIndexes = new Set(Array.isArray(index) ? index : [index]);
      field.input.onChange(Array.isArray(value) ? value.filter((_, idx) => !normalizedIndexes.has(idx)) : []);
    },
    [field],
  );

  const map: MapFn = useCallback(
    (iterator) => {
      const result = [];

      for (let i = 0; i < length; i++) {
        result.push(iterator(`${name}[${i}]`, i));
      }

      return result;
    },
    [length],
  );

  const update: UpdateFn<T> = useCallback(
    (el, index) => {
      field.input.onChange(inputValueRef.current.map((item, idx) => (index === idx ? el : item)));
    },
    [field],
  );

  const setValue: SetValueFn<T> = useCallback(
    (els) => {
      if (Array.isArray(els)) {
        field.input.onChange([...els]);
      } else {
        field.input.onChange(els(field.input.value));
      }
    },
    [field],
  );

  return useMemo(
    () => ({
      map,
      push,
      pushMany,
      remove,
      setValue,
      name: field.input.name,
      update,
      value: field.input.value || [],
      meta: field.meta,
      length,
    }),
    [map, push, pushMany, update, field.input.name, field.input.value, field.meta, remove, length],
  );
};

export type UseFieldArray = typeof useFieldArray;
