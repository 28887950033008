import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No offers found',
    description: 'Offers table: empty',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load offers',
    description: 'Offers table: error',
  },
});
