import {intranetApi} from 'apiClient';
import {
  CompensationRequest,
  CompensationRequestId,
  compensationRequestSchema,
} from 'domain/compensations/request/model';

export type UpdateCommentFromAdminDiff = {
  commentFromAdmin: string;
};

export type UpdateCommentFromAdminConfig = {
  id: CompensationRequestId;
  diff: UpdateCommentFromAdminDiff;
};

export const updateCommentFromAdmin = async ({
  id,
  diff,
}: UpdateCommentFromAdminConfig): Promise<CompensationRequest> => {
  const {data} = await intranetApi.post('/v1/compensations/requests/updateCommentFromAdmin', diff, {params: {id}});

  return compensationRequestSchema.runWithException(data);
};
