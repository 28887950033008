import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  limited: {
    defaultMessage: 'Limited',
    description: 'Joom Space limited sign',
  },
  tooltipContent: {
    defaultMessage: 'You access Space without VPN. Some information is hidden.',
    description: 'Joom Space limited sign tooltip',
  },
});
