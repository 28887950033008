import {CountryCode} from 'domain/countryCode/model';
import {SalaryRangesSearchFilters} from 'domain/salaryRange/model/filters';
import {useCallback, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

type ReturnShape = {
  urlFilters: SalaryRangesSearchFilters | undefined;
  resetUrlFilters: () => void;
};

export function useUrlFilters(): ReturnShape {
  const {search} = useLocation();
  const [regions, setRegions] = useState(new URLSearchParams(search).getAll('region').filter(Boolean) as CountryCode[]);
  const [userFunctions, setUserFunctions] = useState(
    new URLSearchParams(search).getAll('userFunction').filter(Boolean),
  );

  const urlFilters = useMemo(() => {
    if (!regions.length && !userFunctions.length) {
      return undefined;
    }

    return {
      region: regions.length ? regions : undefined,
      userFunction: userFunctions.length ? userFunctions : undefined,
    };
  }, [regions, userFunctions]);

  const resetUrlFilters = useCallback(() => {
    setRegions([]);
    setUserFunctions([]);
  }, [setRegions, setUserFunctions]);

  return {
    urlFilters,
    resetUrlFilters,
  };
}
