/**
 * Checks whether any files are involved in drag event.
 * Can be used to ignore drag events that are triggered
 * by dragging text on the page
 */
export function isFileDragEvent(event: DragEvent): boolean {
  const types = Array.from(event.dataTransfer?.types ?? []);

  /**
   * It’s important to additionally check for `text/*` entries,
   * because dragging links on Windows emits DragEvent with such dataTransfer.types:
   * ['text/plain', 'text/uri-list', 'text/html', 'Files']
   */
  return types.includes('Files') && !types.some((item) => item.startsWith('text/'));
}
