import {Range} from '@joomcode/deprecated-utils/range';
import {DateRangeFilter} from 'models/system/filters/dataRange';
import {dateToString} from 'models/system/formattedDate';

export const parseDateRange = ({from, to}: Partial<Range<Date>>): DateRangeFilter => {
  return {
    start: from && dateToString(from),
    end: to && dateToString(to),
  };
};

export const formatDateRange = (range: DateRangeFilter | undefined): Partial<Range<Date>> => {
  return {
    from: range?.start ? new Date(range.start) : undefined,
    to: range?.end ? new Date(range.end) : undefined,
  };
};
