import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {OfferId} from 'domain/offer/model';
import {FormattedDate, formattedDateSchema} from 'models/system/formattedDate';

type Params = {
  id: OfferId;
};
export const getBusyDates = async (params: Params): Promise<FormattedDate[]> => {
  const {data} = await intranetApi.get('/v1/offers/busyDates/getAll', {params});

  return array(formattedDateSchema).runWithException(data.busyDates);
};
