/** Milliseconds in 1 second */
export const SECOND = 1000;
/** Milliseconds in 1 minute */
export const MINUTE = SECOND * 60;
/** Milliseconds in 1 hour */
export const HOUR = MINUTE * 60;
/** Milliseconds in 1 day */
export const DAY = HOUR * 24;

/** Get timezone offset related to UTC for given date */
const getUtcOffset = (date: Date | number) => new Date(date).getTimezoneOffset() * MINUTE;

export const isDateValid = (date: Date | number | undefined): date is Date | number => {
  if (!date) {
    return false;
  }

  if (date instanceof Date && !Number.isNaN(Number(date))) {
    return true;
  }

  return false;
};

/** Transform date from input in local timezone to UTC date */
export const convertDateToUtc = (date?: Date | number): Date | undefined => {
  if (!isDateValid(date)) {
    return undefined;
  }

  return new Date(Number(date) - getUtcOffset(date));
};

/** Transform date from UTC to local timezone */
export const convertDateToLocal = (date?: Date | number): Date | undefined => {
  if (!isDateValid(date)) {
    return undefined;
  }

  return new Date(Number(date) + getUtcOffset(date));
};
