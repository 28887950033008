import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {RtconfPage} from 'pages/Rtconf';
import {RtconfCreateItemPage} from 'pages/RtconfCreateItem';
import {RtconfItemPage} from 'pages/RtconfItem';
import {RouteConfig} from 'react-router-config';
import {rtconfUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: rtconfUrlPatterns.root,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: withPermission(RtconfPage, Permission.RTCONF_READ),
      exact: true,
      path: rtconfUrlPatterns.root,
    },
    {
      component: withPermission(RtconfCreateItemPage, Permission.RTCONF_WRITE),
      exact: true,
      path: rtconfUrlPatterns.createItem,
    },
    {
      component: withPermission(RtconfItemPage, Permission.RTCONF_READ),
      exact: true,
      path: rtconfUrlPatterns.item,
    },
  ],
};
