import {createBooleanFilter, DataFilterConfig} from '@joomcode/joom-ui/DataFilter';
import {Permission} from 'domain/permission/model';
import {AllStockOptionHoldersFilters} from 'domain/stockOption/holder/model/filters';
import {StockOptionHoldersFilters} from 'domain/stockOption/holder/widgets/Filters';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

type Props = {
  values: AllStockOptionHoldersFilters;
  onChange(payload: AllStockOptionHoldersFilters): void;
};

export function AllStockOptionHoldersDataFilters({values, onChange}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const config = useMemo<DataFilterConfig<AllStockOptionHoldersFilters>>(() => {
    if (acl.hasPermission(Permission.USER_READ_ANY)) {
      return {
        isTerminated: createBooleanFilter(intl.formatMessage(messages.isTerminated), {
          alwaysVisible: true,
        }),
      };
    }

    return {};
  }, [acl, intl]);

  return <StockOptionHoldersFilters values={values} onChange={onChange} config={config} />;
}
