import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {Employment} from 'domain/employment/model';
import {cancelTerminationFx} from 'domain/employment/stores/main';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  userId: UserId;
  employments: Employment[];
  onDialogOpen(): void;
};

export function EmploymentTabAddon({userId, employments, onDialogOpen}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canEditEmployments = acl.hasPermission(Permission.USER_EMPLOYMENT_WRITE);
  const activeEmployment: Employment | undefined = useMemo(
    () => employments?.find(({active}) => active),
    [employments],
  );

  const onCancelTerminationClick = useCallback(() => {
    confirm(
      {
        title: intl.formatMessage(messages.cancelTerminationConfirmTitle),
        text: intl.formatMessage(messages.cancelTerminationConfirmText),
        confirmationText: intl.formatMessage(messages.cancelTerminationConfirmButton),
        onConfirm: () => cancelTerminationFx({userId}).catch(toaster.interceptThenThrowError),
      },
      intl,
    );
  }, [intl, userId]);

  if (!canEditEmployments || !activeEmployment) {
    return null;
  }

  return activeEmployment.terminated ? (
    <Button size='m' kind='primary' intent='neutral' onClick={onCancelTerminationClick}>
      {intl.formatMessage(messages.buttonCancelTermination)}
    </Button>
  ) : (
    <Button size='m' kind='primary' intent='neutral' onClick={onDialogOpen}>
      {intl.formatMessage(messages.buttonTerminate)}
    </Button>
  );
}
