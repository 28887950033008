import {StringValidatorErrorCode} from '@joomcode/joom-form';
import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  saveButton: {
    defaultMessage: 'Update',
    description: '[button] Save button',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Cancel button',
  },
  errorNoData: {
    defaultMessage: 'No data to edit',
    description: 'User form: no data error',
  },
});

export const errorMessages = defineMessages({
  [StringValidatorErrorCode.STRING_REG_EXP_NOT_MATCH]: {
    defaultMessage: 'Use latin letters',
    description: 'User form: field error text',
  },
});
