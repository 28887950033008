import {boolean, ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const roleIdSchema = idSchema;

export const roleSchema = object({
  description: optional(string()),
  id: roleIdSchema,
  isHead: optionalWithDefault(false, boolean()),
  name: string(),
});

export type Role = ExtractSchemaType<typeof roleSchema>;
export type RoleId = ExtractSchemaType<typeof roleIdSchema>;
