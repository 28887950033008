import {findBestImageForSize} from '@joomcode/deprecated-utils/image/bundle';
import cn from 'classnames';
import {ImageBundle} from 'models/system/imageBundle';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './Avatar.css';

type AvatarSize = 's' | 'm' | 'l';

type Props = {
  name: string;
  imageBundle?: ImageBundle;
  placeholder?: string;
  size?: AvatarSize;
  icon?: React.ReactNode;
};

const IMAGE_SIZE_BY_AVATAR_SIZE: Record<AvatarSize, number> = {
  s: 48,
  m: 80,
  l: 160,
};

export function Avatar({name, imageBundle, placeholder, icon, size = 'm'}: Props) {
  const intl = useIntl();

  const minSizePx = IMAGE_SIZE_BY_AVATAR_SIZE[size];
  const src = imageBundle && findBestImageForSize(imageBundle.images, {height: minSizePx, width: minSizePx})?.url;
  const baseClassNames = [styles.root, styles[`avatarSize${size.toUpperCase() as Uppercase<AvatarSize>}`]];
  const placeholderText = placeholder || name.trim().charAt(0).toLocaleUpperCase(intl.locale);
  if (icon) {
    return <span className={cn(baseClassNames.concat([styles.withIcon]))}>{icon}</span>;
  }
  return (
    <span
      className={cn(baseClassNames, {
        [styles.withText]: !src,
        [styles.withSmallText]: !src && placeholderText.length > 3,
      })}
    >
      {src ? <img src={src} alt='' className={styles.image} /> : placeholderText}
    </span>
  );
}
