import {UserFullInfoColumn} from 'domain/userFullInfoRecord/model/columns';
import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  noCrInformation: {
    defaultMessage: 'No information',
    description: 'User full info table: no CR information text',
  },
  monthlySalaryError: {
    defaultMessage: 'This salary cannot be represented as a monthly rate, but you can switch back to the default.',
    description: 'User full info table: monthly salary conversion error text',
  },
  user: {
    defaultMessage: '{lastName}, {firstName}',
    description: 'User full info table: user column',
  },
});

export const hints = defineMessages({
  [UserFullInfoColumn.REMOTE_WORK_BALANCE]: {
    defaultMessage: 'Available days left for today.',
    description: 'User full info table: remote balance column hint',
  },
  [UserFullInfoColumn.VACATION_BALANCE]: {
    defaultMessage: 'Available days left for today.',
    description: 'User full info table: vacation balance column hint',
  },
  [UserFullInfoColumn.TIME_OFF_POLICY]: {
    defaultMessage: 'Country-specific holidays and vacation accumulation.',
    description: 'User full info table: time off policy column hint',
  },
  [UserFullInfoColumn.PRIMARY_LEGAL_ENTITY]: {
    defaultMessage: 'Main or sole legal entity for the employee.',
    description: 'User full info table: primary legal entity column hint',
  },
  [UserFullInfoColumn.JOB_CONTRACT_TIME_OFF_POLICY]: {
    defaultMessage: 'Holiday calendar of the legal entity, which may differ from the employee’s actual work schedule.',
    description: 'User full info table: job contract time off policy column hint',
  },
  [UserFullInfoColumn.SALARY_EMPLOYMENT_RATE]: {
    defaultMessage: 'The part of employee time fixed in employee contract.',
    description: 'User full info table: employment rate column hint',
  },
  [UserFullInfoColumn.SALARY_REGION]: {
    defaultMessage: 'The location on the basis of which the salary is fixed.',
    description: 'User full info table: salary region column hint',
  },
  [UserFullInfoColumn.SALARY_CR]: {
    defaultMessage:
      'Ratio of actual salary to the market’s target midpoint for the role and an indicator for salary positioning relative to the market.',
    description: 'User full info table: salary CR value column hint',
  },
  [UserFullInfoColumn.SALARY_RANGE_MINIMUM]: {
    defaultMessage: 'Minimum salary for given role according to market.',
    description: 'User full info table: salary range minimum column hint',
  },
  [UserFullInfoColumn.SALARY_RANGE_TARGET]: {
    defaultMessage: 'Recommended salary for given role according to market.',
    description: 'User full info table: salary range target column hint',
  },
  [UserFullInfoColumn.SALARY_RANGE_MAXIMUM]: {
    defaultMessage: 'Maximum salary for given role according to market.',
    description: 'User full info table: salary range maximum column hint',
  },
  [UserFullInfoColumn.SALARY_RANGE_COMPENSATION_TRACK]: {
    defaultMessage: 'Link to salary ranges for this region and function.',
    description: 'User full info table: salary range compensation track column hint',
  },
});
