import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {CompensationReviewRequestLayout} from 'domain/compensationReviewRequest/widgets/Layout';
import {RegularBonus} from 'domain/compensationReviewRequest/widgets/RegularBonus';
import {RegularBonusHeader} from 'domain/compensationReviewRequest/widgets/RegularBonusHeader';
import React, {useMemo} from 'react';

type Props = {
  newUserInfo: CompensationReviewRequest['newUserInfo'];
  oldUserInfo: CompensationReviewRequest['oldUserInfo'];
};
export function CompensationReviewRequestSectionRegularBonus({newUserInfo, oldUserInfo}: Props) {
  const regularBonuses = useMemo(
    () => [...(oldUserInfo.regularBonuses ?? []), ...(newUserInfo.regularBonuses ?? [])],
    [oldUserInfo.regularBonuses, newUserInfo.regularBonuses],
  );

  return (
    <CompensationReviewRequestLayout>
      <RegularBonusHeader />
      <Panel.Content withPadding>
        <Grid>
          {regularBonuses.map((regularBonus, index) => {
            const isNew = !oldUserInfo.regularBonuses?.some(
              (oldRegularBonus) => oldRegularBonus.id === regularBonus.id,
            );

            // eslint-disable-next-line react/no-array-index-key
            return <RegularBonus key={index} regularBonus={regularBonus} isBold={isNew} isNew={isNew} />;
          })}
        </Grid>
      </Panel.Content>
    </CompensationReviewRequestLayout>
  );
}
