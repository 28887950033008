import {Stay, StayGroup} from 'domain/compensations/stay/model';
import {CountryCode} from 'domain/countryCode/model';

export const groupStays = (stays: Stay[]): StayGroup[] =>
  stays.reduce<StayGroup[]>((stayGroups, currentStay) => {
    const lastStayGroup = stayGroups[stayGroups.length - 1];

    if (currentStay.countryCode === CountryCode.CN && lastStayGroup?.countryCode === CountryCode.CN) {
      lastStayGroup.endDate = currentStay.endDate;
      lastStayGroup.includedStays.push(currentStay);
    } else {
      stayGroups.push({
        ...currentStay,
        includedStays: [currentStay],
      });
    }

    return stayGroups;
  }, []);
