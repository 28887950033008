import {ValidationError} from '@joomcode/joom-form';
import {TimeOffRequestFormError} from 'domain/timeOff/request/model/error';

export const validateDatesOrder = (
  startDate: string | undefined,
  endDate: string | undefined,
): ValidationError<TimeOffRequestFormError> | undefined => {
  if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
    return {code: TimeOffRequestFormError.BAD_DATES_ORDER};
  }
  return undefined;
};
