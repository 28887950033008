import {DataState} from '@joomcode/deprecated-utils/dataState';
import {RedirectRoute} from 'domain/redirectRoute/model';
import {createStore} from 'effector';
import {createRedirectRouteFx, deleteRedirectRouteFx, getRedirectRoutesFx} from '.';

type State = {
  redirectRoutes: RedirectRoute[];
  dataState: DataState;
};

export const $redirectRoutes = createStore<State>({
  redirectRoutes: [],
  dataState: DataState.IDLE,
})
  .on(getRedirectRoutesFx, (state) => {
    return {
      ...state,
      dataState: DataState.LOADING,
    };
  })
  .on(getRedirectRoutesFx.fail, (state) => {
    return {
      ...state,
      redirectRoutes: [],
      dataState: DataState.FAILED,
    };
  })
  .on(getRedirectRoutesFx.done, (state, {result}) => {
    return {
      ...state,
      redirectRoutes: result,
      dataState: DataState.LOADED,
    };
  })
  .on(createRedirectRouteFx.done, (state, {result}) => {
    return {
      ...state,
      redirectRoutes: [...state.redirectRoutes, result],
    };
  })
  .on(deleteRedirectRouteFx.done, (state, {params}) => {
    return {
      ...state,
      redirectRoutes: state.redirectRoutes.filter((item) => item.id !== params.id),
    };
  });
