import {oneOfEnum, string, object} from '../jsonValidation';

export enum SortingDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const sortingBySchema = string();
export const sortingDirectionSchema = oneOfEnum(SortingDirection);

export const sortingSchema = object({
  by: sortingBySchema,
  direction: sortingDirectionSchema,
});

export type Sorting<T extends string = string> = {
  by: T;
  direction: SortingDirection;
};
