import {LocalesConfig} from '..';
import {getLocalesByAlias} from '../getLocalesByAlias';

export function getAcceptableLocale<AppLocale extends string>(
  userLocales: string[],
  appLocales: LocalesConfig<AppLocale>,
  defaultLocale: AppLocale,
): AppLocale {
  let result: AppLocale | undefined;
  const localesByAlias = getLocalesByAlias(appLocales);

  userLocales.some((locale: string) => {
    if (locale in appLocales) {
      result = locale as AppLocale;
      return true;
    }

    if (locale in localesByAlias) {
      result = localesByAlias[locale];
      return true;
    }

    const [lang] = locale.split('-');

    if (lang in localesByAlias) {
      result = localesByAlias[lang];
    }

    return result !== undefined;
  });

  return result ?? defaultLocale;
}
