import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {ReactComponent as BulletedListIcon} from '@joomcode/joom-ui/icons/core/bulletedList.svg';
import {ReactComponent as ListIcon} from '@joomcode/joom-ui/icons/core/list.svg';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  onToggleUsers: (showUsers: boolean) => void;
  showUsers: boolean;
};

export function TeamsToolbar({onToggleUsers, showUsers}: Props) {
  const intl = useIntl();
  return (
    <ButtonGroup size='m'>
      <TooltipAlt content={intl.formatMessage(messages.showUsers)} placement='bottom'>
        <Button
          kind='primary'
          intent={showUsers ? 'primary' : 'neutral'}
          iconLeft={<BulletedListIcon />}
          onClick={() => onToggleUsers(true)}
        />
      </TooltipAlt>
      <TooltipAlt content={intl.formatMessage(messages.hideUsers)} placement='bottom'>
        <Button
          kind='primary'
          intent={showUsers ? 'neutral' : 'primary'}
          iconLeft={<ListIcon />}
          onClick={() => onToggleUsers(false)}
        />
      </TooltipAlt>
    </ButtonGroup>
  );
}
