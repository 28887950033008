import {FinancialBulkUploadInput, FinancialBulkUploadResult} from 'domain/financialRecord/model';

export function convertBulkUploadResult(result: FinancialBulkUploadResult): FinancialBulkUploadInput | undefined {
  if (
    result.salaryBulkUploadRecords?.some(({record, user}) => !record || !user) ||
    result.marketDataBulkUploadRecords?.some(({record, user}) => !record || !user) ||
    result.regularBonusBulkUploadRecords?.some(({record, user}) => !record || !user)
  ) {
    return undefined;
  }

  return {
    id: result.id,
    salaryBulkUploadInputRecords: result.salaryBulkUploadRecords?.map(({record, oldRecord, user}) => ({
      record: record!,
      oldRecord,
      userId: user!.id,
    })),
    marketDataBulkUploadInputRecords: result.marketDataBulkUploadRecords?.map(({record, oldRecord, user}) => ({
      record: record!,
      oldRecord,
      userId: user!.id,
    })),
    regularBonusBulkUploadInputRecords: result.regularBonusBulkUploadRecords?.map(({record, oldRecord, user}) => ({
      record: record!,
      oldRecord,
      userId: user!.id,
    })),
  };
}
