import {Panel} from '@joomcode/joom-ui/Panel';
import {CompanyUnits} from 'domain/companyUnits/model';
import {UserInfo} from 'domain/compensationReviewRequest/model/userInfo';
import {CompensationReviewRequestFormSectionGeneral} from 'domain/compensationReviewRequest/widgets/FormSectionGeneral';
import {CompensationReviewRequestFormSectionOneTimeBonus} from 'domain/compensationReviewRequest/widgets/FormSectionOneTimeBonus';
import {CompensationReviewRequestFormSectionRegularBonus} from 'domain/compensationReviewRequest/widgets/FormSectionRegularBonus';
import {CompensationReviewRequestFormSectionSalary} from 'domain/compensationReviewRequest/widgets/FormSectionSalary';
import {CompensationReviewRequestFormSectionStockOptions} from 'domain/compensationReviewRequest/widgets/FormSectionStockOptions';
import {CompensationReviewRequestLayout} from 'domain/compensationReviewRequest/widgets/Layout';
import {getSalariesFromContractRecords} from 'domain/contract/utils/getSalariesFromContractRecord';
import {UserId} from 'domain/user/model';
import {getMainSalary} from 'domain/userFullInfoRecord/utils/getMainSalary';
import React, {useMemo} from 'react';

type Props = {
  companyUnits: CompanyUnits;
  onlySubordinates?: boolean;
  userId: UserId;
  userInfo: UserInfo;
};

export function CompensationReviewRequestFormRequestBody({companyUnits, onlySubordinates, userId, userInfo}: Props) {
  const salaries = useMemo(() => getSalariesFromContractRecords(userInfo.contracts), [userInfo.contracts]);
  const mainSalary = useMemo(() => getMainSalary(userInfo.contracts), [userInfo.contracts]);

  return (
    <Panel>
      <CompensationReviewRequestLayout>
        <CompensationReviewRequestFormSectionGeneral
          companyUnits={companyUnits}
          general={userInfo.general}
          onlySubordinates={onlySubordinates}
        />
        {salaries && salaries.length > 0 && <CompensationReviewRequestFormSectionSalary salaries={salaries} />}
        <CompensationReviewRequestFormSectionOneTimeBonus currency={mainSalary?.currency} />
        <CompensationReviewRequestFormSectionRegularBonus regularBonuses={userInfo.regularBonuses} />
        <CompensationReviewRequestFormSectionStockOptions userId={userId} initialStockOptions={userInfo.stockOptions} />
      </CompensationReviewRequestLayout>
    </Panel>
  );
}
