import {oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';
import {JsonSerializer, LocalStorage, SingleStorageManager} from '@joomcode/deprecated-utils/Storage';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Tabs} from '@joomcode/joom-ui/Tabs';
import {Filters, filtersSchema} from 'domain/compensationReviewRequest/model/filters';
import {securePermissionByTabId, TabId} from 'domain/compensationReviewRequest/model/tab';
import {tabAlternativeNames, tabNames} from 'domain/compensationReviewRequest/model/tab/messages';
import {CompensationReviewRequestsFilters} from 'domain/compensationReviewRequest/widgets/Filters';
import {CompensationReviewRequestsTab} from 'domain/compensationReviewRequest/widgets/Tab';
import {SecurePermission} from 'domain/permission/model/secure';
import {useStoredFilters} from 'hooks/useStoredFilters';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';

const lastActiveTabStorage = new SingleStorageManager(
  'compensationReviewRequests.lastActiveTab',
  new LocalStorage(),
  new JsonSerializer(oneOfEnum(TabId)),
);

type Props = {
  defaultTabId?: TabId;
  onlySubordinates?: boolean;
  onTabChange?: (tabId: TabId) => void;
};

export function CompensationReviewRequestsList({defaultTabId, onlySubordinates, onTabChange}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {filterValues, setFilterValues} = useStoredFilters<Filters>({
    id: 'compensationReviewRequests.filters',
    initialValues: {},
    valuesSchema: filtersSchema,
  });
  const tabs = useMemo(
    () =>
      Object.entries(securePermissionByTabId).reduce(
        (acc, [tabId, securePermission]: [TabId, SecurePermission]) =>
          acl.hasSecurePermission(securePermission)
            ? [
                ...acc,
                {
                  id: tabId,
                  label: intl.formatMessage(tabNames[tabId]),
                  alternaitveLabel:
                    tabId === TabId.ANY
                      ? intl.formatMessage(tabAlternativeNames[tabId])
                      : intl.formatMessage(tabNames[tabId]),
                  content: <CompensationReviewRequestsTab filters={filterValues} tabId={tabId} />,
                },
              ]
            : acc,
        [],
      ),
    [acl, intl, filterValues],
  );

  const isOnlyTab = tabs.length === 1;
  const restoredTab = lastActiveTabStorage.restore();
  const lastActiveTab = restoredTab && tabs.find((tab) => tab.id === restoredTab);
  const defaultTab = tabs.find((tab) => tab.id === defaultTabId);
  const firstTab = tabs[0];
  const [activeTabId, setActiveTabId] = useState(() => {
    const tabId = lastActiveTab?.id ?? defaultTab?.id ?? firstTab.id;
    onTabChange?.(tabId);
    return tabId;
  });
  const handleTabChange = useCallback(
    (tabId: TabId) => {
      setActiveTabId(tabId);
      lastActiveTabStorage.store(tabId);
      onTabChange?.(tabId);
    },
    [setActiveTabId, onTabChange],
  );

  return (
    <>
      <Panel>
        <CompensationReviewRequestsFilters
          values={filterValues}
          onChange={setFilterValues}
          onlySubordinates={onlySubordinates}
        />
      </Panel>
      <Panel title={isOnlyTab ? firstTab.alternaitveLabel : undefined} withSeparator={isOnlyTab}>
        {isOnlyTab ? (
          firstTab.content
        ) : (
          <Tabs<TabId> renderActiveTabPanelOnly activeTabId={activeTabId} onChange={handleTabChange} tabs={tabs} />
        )}
      </Panel>
    </>
  );
}
