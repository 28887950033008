export enum UserFullInfoColumn {
  USER = 'user',
  BIRTHDAY = 'birthday',
  LOGIN = 'login',
  EMAIL = 'email',
  GITHUB_LOGIN = 'githubLogin',
  PHONE = 'phone',
  TIME_OFF_POLICY = 'timeOffPolicy',
  VACATION_BALANCE = 'vacationBalance',
  REMOTE_WORK_BALANCE = 'remoteDaysBalance',
  HIRE_DATE = 'hireDate',
  OFFICE_POLICY = 'officePolicy',
  LEVEL_CODE = 'levelCode',
  USER_FUNCTION = 'userFunction',
  PRIMARY_LEGAL_ENTITY = 'primaryLegalEntity',
  DIVISION = 'division',
  DEPARTMENT = 'department',
  JOB_TITLE = 'jobTitle',
  LOCATION = 'location',
  MANAGER = 'manager',
  JOB_CONTRACT_EFFECTIVE_DATE = 'jobContractEffectiveDate',
  JOB_CONTRACT_CONTRACT_TYPE = 'jobContractContractType',
  JOB_CONTRACT_LEGAL_ENTITY = 'jobContractlegalEntity',
  JOB_CONTRACT_COMMENT = 'jobContractComment',
  JOB_CONTRACT_TIME_OFF_POLICY = 'jobContractTimeOffPolicy',
  JOB_CONTRACT_STATUS = 'jobContractStatus',
  SALARY_START_DATE = 'salaryStartDate',
  SALARY_LEGAL_ENTITY = 'salaryLegalEntity',
  SALARY_FIX_BASE = 'salaryFixBase',
  SALARY_EMPLOYMENT_RATE = 'salaryEmploymentRate',
  SALARY_REGION = 'salaryRegion',
  SALARY_CR = 'salaryCr',
  SALARY_RANGE_MINIMUM = 'salaryRangeMinimum',
  SALARY_RANGE_TARGET = 'salaryRangeTarget',
  SALARY_RANGE_MAXIMUM = 'salaryRangeMaximum',
  SALARY_RANGE_COMMENT = 'salaryRangeComment',
  SALARY_RANGE_COMPENSATION_TRACK = 'salaryRangeCompensationTrack',
  REGULAR_BONUS_PERIOD = 'regularBonusPeriod',
  REGULAR_BONUS = 'regularBonusAmount',
  OPTIONS_GRANTED_TOTAL = 'optionsGrantedTotal',
  OPTIONS_GRANTED_HISTORIC = 'optionsGrantedHistoric',
  OPTIONS_GRANTED_GENERAL = 'optionsGrantedGeneral',
  OPTIONS_GRANTED_PERFORMANCE = 'optionsGrantedPerformance',
  OPTIONS_VESTED = 'optionsVested',
  OPTIONS_AVAILABLE = 'optionsAvailable',
  OPTIONS_NEXT_12_MONTHS = 'optionsNext12Months',
  OPTIONS_AGREEMENTS = 'optionsAgreements',
}
