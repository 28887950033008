import {findBestImageForSize} from '@joomcode/deprecated-utils/image/bundle';
import {Stack} from '@joomcode/joom-ui/Stack';
import {Section} from 'components/ui/Section';
import {StyledLink} from 'components/ui/StyledLink';
import {NearestTimeOffs} from 'domain/timeOff/userTimeOff/widgets/NearestTimeOffs';
import {User} from 'domain/user/model';
import {UserContact} from 'domain/user/widgets/Contact';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import {generalMessages} from 'i18n/messages/general';
import React from 'react';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {outstaffUsersUrls} from 'routes/outstaffUsers/urls';
import {usersUrls} from 'routes/users/urls';
import {messages} from './messages';
import styles from './styles.css';

const PHOTO_WIDTH_PX = 336;

type Props = {
  user: User;
  manager?: User;
};

export function UserPreview({user, manager}: Props) {
  const intl = useIntl();
  const photoImage = user.avatarBundle && findBestImageForSize(user.avatarBundle.images, {width: PHOTO_WIDTH_PX * 2});
  const profileLink = user.isOutstaff
    ? outstaffUsersUrls.outstaffUser({email: user.email})
    : usersUrls.user({login: user.login});

  return (
    <div className={styles.root}>
      {photoImage && (
        <Link to={profileLink}>
          <img
            className={styles.avatar}
            key={user.id}
            src={photoImage.url}
            width={PHOTO_WIDTH_PX}
            height={photoImage.height * (PHOTO_WIDTH_PX / photoImage.width)}
            alt=''
          />
        </Link>
      )}
      <div className={styles.info}>
        <div className={styles.general}>
          <Stack vertical>
            <div>
              <h2 className={styles.fullName}>
                <StyledLink to={profileLink}>
                  {intl.formatMessage(generalMessages.fullName, {firstName: user.firstName, lastName: user.lastName})}
                </StyledLink>
              </h2>
              <div className={styles.position}>
                {user.jobTitle && <div>{user.jobTitle}</div>}
                {(user.department || user.division) && (
                  <div className={styles.department}>
                    {user.department}
                    {user.department && user.division && ', '}
                    {user.division}
                  </div>
                )}
              </div>
              <NearestTimeOffs timeOffs={user.offTime} />
              <div className={styles.fullProfileLink}>
                <StyledLink to={profileLink} colored>
                  {intl.formatMessage(messages.viewFullProfile)}
                </StyledLink>
              </div>
            </div>
            <Stack.Divider />
            <UserContact type='location' user={user} />
            <UserContact type='phone' user={user} />
            <UserContact type='slack' user={user} />
            <UserContact type='email' user={user} />
            <UserContact type='github' user={user} />
          </Stack>
        </div>
        {manager && (
          <Section title={intl.formatMessage(messages.manager)} bordered>
            <ListedUser user={manager} />
          </Section>
        )}
        {window.intranetEnv.APP_ENV === 'local' && (
          <Section title='Debug info (local env only)' bordered>
            <div className={styles.debugInfo}>id: {user.id}</div>
          </Section>
        )}
      </div>
    </div>
  );
}
