import {StyledLink} from 'components/ui/StyledLink';
import {User} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import React from 'react';
import {useIntl} from 'react-intl';
import {stockOptionsUrls} from 'routes/stockOptions/urls';

export type Props = {
  user: User;
};

export function StockOptionHolderLink({user}: Props) {
  const intl = useIntl();
  const fullName = intl.formatMessage(generalMessages.fullName, {
    firstName: user.firstName,
    lastName: user.lastName,
  });

  return (
    <StyledLink colored to={stockOptionsUrls.user({login: user.login})}>
      {fullName}
    </StyledLink>
  );
}
