import React, {useMemo} from 'react';
import cn from 'classnames';
import {createLocator} from '../create-locator';
import styles from './index.css';
import {ButtonGroupProps} from './types';
import {ButtonGroupContext} from './context';

export * from './types';
export * from './context';

export function ButtonGroup({
  align = 'left',
  children,
  multipleLines = false,
  disabled = false,
  spaced = false,
  size = 'm',
  ...restProps
}: ButtonGroupProps) {
  const locator = createLocator(restProps);
  const context = useMemo(() => ({size, disabled}), [size, disabled]);

  return (
    <div
      className={cn(styles.buttonGroup, styles[`${align}Align`], styles[`${size}Size`], {
        [styles.buttonGroupSpaced]: spaced,
        [styles.buttonGroupMultipleLines]: multipleLines,
      })}
      role='group'
      {...locator()}
    >
      <ButtonGroupContext.Provider value={context}>{children}</ButtonGroupContext.Provider>
    </div>
  );
}
