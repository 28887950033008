import {HttpStatus} from '@joomcode/deprecated-utils/http/status';
import axios, {AxiosError, AxiosResponse} from 'axios';
import axiosRetry from 'axios-retry';
import {resetSelf} from 'domain/self/stores/main';
import {apiErrorSchema} from 'models/system/apiError';
import {ClientError, ClientErrorStatus} from './ClientError';

const httpStatusToClientErrorStatus = {
  [HttpStatus.BadRequest]: ClientErrorStatus.BAD_REQUEST,
  [HttpStatus.Unauthorized]: ClientErrorStatus.UNAUTHORIZED,
  [HttpStatus.Forbidden]: ClientErrorStatus.FORBIDDEN,
  [HttpStatus.NotFound]: ClientErrorStatus.NOT_FOUND,
};

const commonIntranetOnFulfilled = (response: AxiosResponse) => response.data;

const commonIntranetOnReject = (error: AxiosError) => {
  if (!error.isAxiosError || !error.response) {
    throw error;
  }

  const {response, config} = error;
  if (response.status === HttpStatus.Unauthorized && config?.method?.toLowerCase() === 'get') {
    resetSelf();
  }
  if (httpStatusToClientErrorStatus[response.status as keyof typeof httpStatusToClientErrorStatus]) {
    const parsedApiError = apiErrorSchema.run(response.data);
    const errorMessage = parsedApiError.ok ? parsedApiError.result.error : error.message;
    throw new ClientError(
      errorMessage,
      httpStatusToClientErrorStatus[response.status as keyof typeof httpStatusToClientErrorStatus],
    );
  }

  throw error;
};

const intranetApi = axios.create({
  baseURL: window.intranetEnv.INTRANET_API_URL, // eslint-disable-line @typescript-eslint/naming-convention
  withCredentials: true,
});
intranetApi.interceptors.response.use(commonIntranetOnFulfilled, commonIntranetOnReject);
axiosRetry(intranetApi, {retryDelay: axiosRetry.exponentialDelay});

const intranetSecureApi = axios.create({
  baseURL: window.intranetEnv.INTRANET_SECURE_API_URL, // eslint-disable-line @typescript-eslint/naming-convention
  withCredentials: true,
});
intranetSecureApi.interceptors.response.use(commonIntranetOnFulfilled, commonIntranetOnReject);
axiosRetry(intranetSecureApi, {retryDelay: axiosRetry.exponentialDelay});

export {intranetApi, intranetSecureApi};
