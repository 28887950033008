import {TotalCountData} from '@joomcode/joom-admin/dataTypes/entityStore';
import {QueryRequestPayload} from 'api/types';
import {intranetApi} from 'apiClient';
import {
  CompensationReviewRequest,
  countableCompensationReviewRequstSchema,
} from 'domain/compensationReviewRequest/model';
import {Filters} from 'domain/compensationReviewRequest/model/filters';

export const getSubordinate = async ({
  filters,
  offset,
  limit,
}: QueryRequestPayload<Filters>): Promise<TotalCountData<CompensationReviewRequest>> => {
  const {data} = await intranetApi.post('/v1/secure/compensationReviewRequests/getSubordinate', filters, {
    params: {offset, limit},
  });
  return countableCompensationReviewRequstSchema.runWithException(data);
};
