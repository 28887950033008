import {identity, noop} from '@joomcode/deprecated-utils/function';
import {FieldTextarea} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {CompensationReviewRequestApproveDiff} from 'domain/compensationReviewRequest/api/approve';
import {User} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import React, {useMemo} from 'react';
import {Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import uuid from 'uuid/v4';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  isOpen: boolean;
  onBehalfOf?: User;
  onClose: () => void;
  onSubmit: FormProps<CompensationReviewRequestApproveDiff>['onSubmit'];
};

export function CompensationReviewRequestDialogApprove({isOpen, onBehalfOf, onClose, onSubmit}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const fullName = onBehalfOf
    ? intl.formatMessage(generalMessages.fullName, {
        firstName: onBehalfOf?.firstName,
        lastName: onBehalfOf?.lastName,
      })
    : undefined;

  return (
    <Form<CompensationReviewRequestApproveDiff> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid}) => (
        <Dialog
          ariaLabel={
            onBehalfOf
              ? intl.formatMessage(messages.dialogAriaLabelOnBehalf, {onBehalfOf: fullName})
              : intl.formatMessage(messages.dialogAriaLabel)
          }
          isOpen={isOpen}
          onClose={submitting ? noop : onClose}
          width={600}
        >
          <Dialog.Header>
            {onBehalfOf
              ? intl.formatMessage(messages.titleOnBehalf, {onBehalfOf: fullName})
              : intl.formatMessage(messages.title)}
          </Dialog.Header>
          <Dialog.Body withDefaultPadding>
            <form className={styles.form} onSubmit={handleSubmit} id={formId}>
              <FieldTextarea name='comment' label={intl.formatMessage(messages.label)} parse={identity} />
            </form>
          </Dialog.Body>
          <Dialog.Footer align='right'>
            <ButtonGroup spaced size='l'>
              <Button kind='text' intent='neutral' disabled={submitting} onClick={onClose}>
                {intl.formatMessage(messages.buttonCancel)}
              </Button>
              <Button
                form={formId}
                kind='primary'
                intent='primary'
                type='submit'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.buttonSubmit)}
              </Button>
            </ButtonGroup>
          </Dialog.Footer>
        </Dialog>
      )}
    </Form>
  );
}
