import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Employment',
    description: '[title] Offer details panel: employment info section title',
  },
});

export const labels = defineMessages({
  employmentLegalEntity: {
    defaultMessage: 'Job contract in legal entity',
    description: 'Offer details panel: field label',
  },
  legalEntityOfFurtherSalary: {
    defaultMessage: 'Further job contract legal entity',
    description: 'Offer details panel: field label',
  },
  positionTitle: {
    defaultMessage: 'Position title',
    description: 'Offer details panel: field label',
  },
});
