import {Decoder} from '@joomcode/deprecated-utils/jsonValidation';
import axios from 'axios';
import {UploadFn} from 'models/system/upload';
import {intranetApi} from '.';

export type UploadOptions<Result> = {
  endpoint: string;
  resultSchema: Decoder<Result>;
  mocker?: () => Result;
};

export function createUploadHandler<Params, Result>({
  endpoint,
  resultSchema,
  mocker,
}: UploadOptions<Result>): UploadFn<Params, Result> {
  return (file, params, options) => {
    const cancellationSource = axios.CancelToken.source();
    const formData = new FormData();
    formData.append('file', file);

    intranetApi
      .post(endpoint, formData, {
        cancelToken: cancellationSource.token,
        onUploadProgress: (event) => {
          return options.onProgress(event.event);
        },
        params,
      })
      .then(({data}) => resultSchema.runWithException(data))
      .then((result) => {
        options.onCompleteRef.current(result);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          if (mocker && window.intranetEnv.APP_ENV === 'local') {
            console.warn('API response has been substituted by mock data. See the error below.'); // eslint-disable-line no-console
            console.error(error); // eslint-disable-line no-console

            // hack: parse + stringify to turn date values back to strings
            options.onCompleteRef.current(JSON.parse(JSON.stringify(mocker())));
          } else {
            options.onError(error);
          }
        }
      });

    return {cancel: cancellationSource.cancel};
  };
}
