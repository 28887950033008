import {GetStockOptionsParams} from 'domain/offer/api/getStockOptions';
import {GetStockOptionsExistenceParams} from 'domain/offer/api/getStockOptionsExistence';
import {VersionedOfferStockOptionList} from 'domain/offer/model/stockOption';
import {createEffect} from 'effector';

export const getOfferStockOptionsFx = createEffect<GetStockOptionsParams, VersionedOfferStockOptionList>(
  'load offer stock options with version ids',
);
export const getOfferStockOptionsExistenceFx = createEffect<GetStockOptionsExistenceParams, boolean>(
  'get offer stock options existence',
);
