import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages<Exclude<ColumnId, ColumnId.ACTIONS>>({
  [ColumnId.EFFECTIVE_DATE]: {
    defaultMessage: 'Effective date',
    description: 'Salary ranges versions table column name',
  },
  [ColumnId.COMMENT]: {
    defaultMessage: 'Comment',
    description: 'Salary ranges versions table column name',
  },
});

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No salary ranges',
    description: 'Salary ranges versions table: empty',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load salary ranges history',
    description: 'Salary ranges versions table: error',
  },
});
