import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  roomIsBusy: {
    defaultMessage: 'Room is busy',
    description: 'Meeting room status',
  },
  roomIsAvailableButWillBeBusy: {
    defaultMessage: 'Room is available for now but will be busy in a few minutes',
    description: '"Cant book meeting room" status',
  },
  bookRoomUntil: {
    defaultMessage: 'Book this room until',
    description: 'Note before booking buttons',
  },
  viewEvent: {
    defaultMessage: 'View event in Google Calendar',
    description: 'View event test text',
  },
});
