import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {FieldOutstaffUser} from 'domain/outstaffUser/widgets/Field';
import {RoleId} from 'domain/role/model';
import {FieldRole, FieldRoleValue} from 'domain/role/widgets/Field';
import {TeamId} from 'domain/team/model/id';
import {requestMembershipFx, requestOutstaffMembershipFx} from 'domain/team/stores/main';
import {UserId} from 'domain/user/model';
import {FieldUser, FieldUserValue} from 'domain/user/widgets/Field';
import React, {useCallback, useMemo} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {labels, messages} from './messages';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  outstaff?: boolean;
  teamId: TeamId;
};

type FormState = {
  roleId: RoleId;
  userId: UserId;
};

export function TeamMembershipRequestDialog({isOpen, onClose, outstaff, teamId}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  const onSubmit = useCallback(
    ({userId, roleId}: FormState) => {
      if (outstaff) {
        return requestOutstaffMembershipFx({outstaffUserId: userId, roleId, teamId})
          .then(() => onClose())
          .catch(toaster.interceptThenThrowError);
      }

      return requestMembershipFx({userId, roleId, teamId})
        .then(() => onClose())
        .catch(toaster.interceptThenThrowError);
    },
    [teamId, outstaff, onClose],
  );

  return (
    <Dialog
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      isOpen={isOpen}
      onClose={onClose}
      width='min(90vw, 24rem)'
    >
      <Form<FormState> onSubmit={onSubmit} subscription={{submitting: true, invalid: true}}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(outstaff ? messages.outstaffTitle : messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <form onSubmit={handleSubmit} id={formId}>
                {outstaff ? (
                  <FieldOutstaffUser
                    name='userId'
                    disabled={submitting}
                    label={intl.formatMessage(labels.outstaffUser)}
                    required
                  />
                ) : (
                  <Field<FieldUserValue> name='userId' subscription={{error: true, touched: true}}>
                    {({meta}) => (
                      <FormControl
                        disabled={submitting}
                        label={intl.formatMessage(labels.user)}
                        error={getFieldErrorText(meta, {intl})}
                        required
                      >
                        {(formControlProps) => (
                          <FieldUser
                            disabled={submitting}
                            name='userId'
                            validate={validateFilled}
                            {...formControlProps}
                          />
                        )}
                      </FormControl>
                    )}
                  </Field>
                )}
                <Field<FieldRoleValue> name='roleId' subscription={{error: true, touched: true}}>
                  {({meta}) => (
                    <FormControl
                      disabled={submitting}
                      label={intl.formatMessage(labels.role)}
                      error={getFieldErrorText(meta, {intl})}
                      required
                    >
                      {(formControlProps) => (
                        <FieldRole
                          disabled={submitting}
                          name='roleId'
                          validate={validateFilled}
                          allowHeadRoles={!outstaff}
                          {...formControlProps}
                        />
                      )}
                    </FormControl>
                  )}
                </Field>
              </form>
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
