import {Panel} from '@joomcode/joom-ui/Panel';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {CompensationReviewRequestLayout} from 'domain/compensationReviewRequest/widgets/Layout';
import {CompensationReviewRequestSectionGeneral} from 'domain/compensationReviewRequest/widgets/RequestSectionGeneral';
import {CompensationReviewRequestSectionOneTimeBonus} from 'domain/compensationReviewRequest/widgets/RequestSectionOneTimeBonus';
import {CompensationReviewRequestSectionRegularBonus} from 'domain/compensationReviewRequest/widgets/RequestSectionRegularBonus';
import {CompensationReviewRequestSectionSalary} from 'domain/compensationReviewRequest/widgets/RequestSectionSalary';
import {CompensationReviewRequestSectionStockOptions} from 'domain/compensationReviewRequest/widgets/RequestSectionStockOptions';
import React, {useMemo} from 'react';

type Props = Pick<
  CompensationReviewRequest,
  'oldUserInfo' | 'newUserInfo' | 'oldCr' | 'newCr' | 'newSalaryRange' | 'user'
>;

export function CompensationReviewRequestBody(props: Props) {
  const {oneTimeBonuses, stockOptions} = props.newUserInfo;
  const regularBonuses = useMemo(
    () => [...(props.oldUserInfo.regularBonuses ?? []), ...(props.newUserInfo.regularBonuses ?? [])],
    [props.oldUserInfo.regularBonuses, props.newUserInfo.regularBonuses],
  );

  return (
    <Panel>
      <CompensationReviewRequestLayout isCompact>
        <CompensationReviewRequestSectionGeneral {...props} />
        <CompensationReviewRequestSectionSalary {...props} />
        {regularBonuses && regularBonuses.length > 0 && <CompensationReviewRequestSectionRegularBonus {...props} />}
        {oneTimeBonuses && oneTimeBonuses.length > 0 && <CompensationReviewRequestSectionOneTimeBonus {...props} />}
        {stockOptions && <CompensationReviewRequestSectionStockOptions {...props} />}
      </CompensationReviewRequestLayout>
    </Panel>
  );
}
