import classNames from 'classnames/bind';
import React from 'react';
import {FocusOn} from 'react-focus-on';
import {ReactFocusOnProps as FocusOnProps} from 'react-focus-on/dist/es5/types';
import {CSSTransition} from 'react-transition-group';
import {Portal} from 'react-portal';
import {RemoveAutoFocusInside} from '../RemoveAutoFocusInside';
import styles from './index.css';

const cn = classNames.bind(styles);

export type ModalPopupProps = {
  autoFocus?: boolean;
  animationDuration?: number;
  ariaLabel: string;
  backdropStyle?: React.CSSProperties;
  children: React.ReactNode;
  closeOnEscape?: boolean;
  closeOnOutsideClick?: boolean;
  isActive: boolean;
  onClose: () => void;
  afterClose?: () => void;
  /**
   * A list of Refs to be considered as a part of the Lock.
   * A way to properly handle portals or scattered lock.
   */
  shards?: FocusOnProps['shards'];
  returnFocus?: FocusOnProps['returnFocus'];
  zIndex?: string | number;
};

function ModalPopupContent({
  autoFocus,
  ariaLabel,
  children,
}: {
  autoFocus?: boolean;
  ariaLabel: string;
  children: React.ReactNode;
}) {
  const ariaLabeledChildren = (
    <div aria-label={ariaLabel} role='dialog'>
      {children}
    </div>
  );

  if (autoFocus) {
    return ariaLabeledChildren;
  }

  return <RemoveAutoFocusInside>{ariaLabeledChildren}</RemoveAutoFocusInside>;
}

export const ModalPopup = React.forwardRef<HTMLDivElement, ModalPopupProps>(function ModalPopup(
  {
    animationDuration = 300,
    ariaLabel,
    backdropStyle,
    children,
    closeOnEscape,
    closeOnOutsideClick,
    isActive,
    shards,
    onClose,
    afterClose,
    returnFocus,
    zIndex,
    autoFocus,
  },
  ref,
) {
  return (
    <Portal>
      <CSSTransition
        classNames={{
          enter: cn('modalPopupEnter'),
          enterActive: cn('modalPopupEnterActive'),
          exit: cn('modalPopupExit'),
          exitActive: cn('modalPopupExitActive'),
        }}
        in={isActive}
        timeout={animationDuration}
        onExited={afterClose}
        unmountOnExit
      >
        <div
          className={cn('modalPopup')}
          style={{
            // @ts-ignore
            zIndex,
            // @ts-ignore
            '--animation-duration': `${animationDuration}ms`,
          }}
          ref={ref}
        >
          {backdropStyle && <div className={cn('backdrop')} style={{...backdropStyle}} />}
          <FocusOn
            onEscapeKey={closeOnEscape ? onClose : undefined}
            onClickOutside={closeOnOutsideClick ? onClose : undefined}
            autoFocus={autoFocus}
            returnFocus={returnFocus}
            shards={shards}
          >
            <ModalPopupContent ariaLabel={ariaLabel} autoFocus={autoFocus}>
              {children}
            </ModalPopupContent>
          </FocusOn>
        </div>
      </CSSTransition>
    </Portal>
  );
});
