import {identity} from '@joomcode/deprecated-utils/function';
import {validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {HintIcon} from 'components/ui/HintIcon';
import {CountryCode} from 'domain/countryCode/model';
import {CountrySelect} from 'domain/countryCode/widgets/Select';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import React from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import styles from '../UserForm.css';
import {fieldLabels, messages} from './messages';

type Props = {
  submitting?: boolean;
  privateInfo: UserPrivateInfo;
};

export function UserFormAddress({submitting, privateInfo}: Props) {
  const intl = useIntl();

  return (
    <>
      <Panel.Header title={intl.formatMessage(messages.addressSectionTitle)} withSeparator />
      <Panel.Content withPadding>
        <div className={styles.form}>
          <Field<CountryCode>
            name='address.country'
            initialValue={privateInfo.address?.country}
            parse={identity}
            validate={validateFilled}
          >
            {({input, meta}) => (
              <FormControl
                disabled={submitting}
                label={intl.formatMessage(fieldLabels.country)}
                required
                error={getFieldErrorText(meta, {intl})}
              >
                {(formControlProps) => (
                  <CountrySelect clearable disabled={submitting} {...formControlProps} {...input} />
                )}
              </FormControl>
            )}
          </Field>
          <Field<string>
            name='address.city'
            initialValue={privateInfo.address?.city}
            parse={identity}
            validate={validateFilled}
          >
            {({input, meta}) => (
              <FormControl
                disabled={submitting}
                label={intl.formatMessage(fieldLabels.city)}
                required
                error={getFieldErrorText(meta, {intl})}
              >
                {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
              </FormControl>
            )}
          </Field>
          <div className={styles.fullRow}>
            <Field<string>
              name='address.streetAddress'
              initialValue={privateInfo.address?.streetAddress}
              parse={identity}
              validate={validateFilled}
            >
              {({input, meta}) => (
                <FormControl
                  disabled={submitting}
                  label={
                    <>
                      {intl.formatMessage(fieldLabels.streetAddress)}
                      <HintIcon>
                        {intl.formatMessage(messages.streetAddressHint, {
                          example: (
                            <span className={styles.addressExample}>
                              {intl.formatMessage(messages.streetAddressExample)}
                            </span>
                          ),
                        })}
                      </HintIcon>
                    </>
                  }
                  required
                  error={getFieldErrorText(meta, {intl})}
                >
                  {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                </FormControl>
              )}
            </Field>
          </div>
          <Field<string> name='address.postalCode' initialValue={privateInfo.address?.postalCode} parse={identity}>
            {({input}) => (
              <FormControl disabled={submitting} label={intl.formatMessage(fieldLabels.postalCode)}>
                {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
              </FormControl>
            )}
          </Field>
          <div className={styles.fullRow}>
            <Field<string> name='address.comments' initialValue={privateInfo.address?.comments} parse={identity}>
              {({input}) => (
                <FormControl disabled={submitting} label={intl.formatMessage(fieldLabels.comments)}>
                  {(formControlProps) => <Textarea {...formControlProps} {...input} disabled={submitting} />}
                </FormControl>
              )}
            </Field>
          </div>
        </div>
      </Panel.Content>
    </>
  );
}
