import {Permission} from 'domain/permission/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {hasPermission, hasSecurePermission} from 'services/acl/hasPermission';

type Handler<Args extends unknown[], Res> = (...args: Args) => Res;
type HandlerConfig<Args extends unknown[], Res> = {handler: Handler<Args, Res>} & (
  | {permission: Permission}
  | {securePermission: SecurePermission}
);
type HandlersConfig<Args extends unknown[], Res> = HandlerConfig<Args, Res>[];

export const createPermissionDependentHandler = <Args extends unknown[], Res>(
  configs: HandlersConfig<Args, Res>,
  defaultHandler: Handler<Args, Res>,
): Handler<Args, Res> => {
  return (...args: Args) => {
    for (const config of configs) {
      if (
        ('permission' in config && hasPermission(config.permission)) ||
        ('securePermission' in config && hasSecurePermission(config.securePermission))
      ) {
        return config.handler(...args);
      }
    }
    return defaultHandler(...args);
  };
};
