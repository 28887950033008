import {omit} from '@joomcode/deprecated-utils/object/omit';
import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {RoleBinding} from 'domain/roleBinding/model';
import {
  approveRoleBindingRequestFx,
  cancelRoleBindingRequestFx,
  rejectRoleBindingRequestFx,
} from 'domain/roleBinding/stores/main';
import {RoleBindingsCountState} from 'domain/roleBinding/stores/main/types';
import {createStore} from 'effector';
import {getRoleBindingRequestsFx, getRoleBindingRequestsWithCountFx} from '.';

export const $roleBindingRequests = createEntityStore({
  getEntityId: (roleBinding: RoleBinding) => roleBinding.id,
  query: {
    fx: getRoleBindingRequestsFx,
    getIdsFromParams: () => [],
  },
}).on(
  [approveRoleBindingRequestFx.doneData, cancelRoleBindingRequestFx.doneData, rejectRoleBindingRequestFx.doneData],
  (state, result) => {
    return {
      ...state,
      byId: omit(state.byId, [result.id]),
    };
  },
);

export const $roleBindingRequestList = createEntityListStore($roleBindingRequests);
export const $roleBindingRequestsCount = createStore<RoleBindingsCountState>({
  count: 0,
})
  .on(getRoleBindingRequestsWithCountFx.done, (state, {result}) => {
    return {
      ...state,
      count: result.count,
    };
  })
  .on([approveRoleBindingRequestFx.doneData, rejectRoleBindingRequestFx.doneData], (state) => ({
    ...state,
    count: state.count - 1,
  }));
