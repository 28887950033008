import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {SensitiveData} from 'components/ui/SensitiveData';
import {ActiveBadge} from 'components/widgets/ActiveBadge';
import {MarketDataRecord, MarketDataRecordId} from 'domain/marketDataRecord/model';
import {MarketDataRecordMenu} from 'domain/marketDataRecord/widgets/Menu';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';
import styles from './styles.css';

export type Props = {
  dataState: DataState;
  records: MarketDataRecord[];
  isSensitiveDataHidden: boolean;
  onDeleteItem?: (id: MarketDataRecordId) => void;
  onEditItem?: (record: MarketDataRecord) => void;
};

const getRowClassName = ({active}: MarketDataRecord) => (!active ? styles.inactiveRow : '');
const getRowKey = ({id}: MarketDataRecord) => id;

export function MarketDataRecordsTable({dataState, records, isSensitiveDataHidden, onDeleteItem, onEditItem}: Props) {
  const intl = useIntl();
  const columns: Column<MarketDataRecord>[] = useMemo(() => {
    const basicColumns: Column<MarketDataRecord>[] = [
      {
        defaultWidth: 10,
        id: ColumnId.EFFECTIVE_DATE,
        name: intl.formatMessage(columnNames[ColumnId.EFFECTIVE_DATE]),
        render: ({effectiveDate}) => <HumanDate value={effectiveDate} format='short' utc />,
      },
      {
        defaultWidth: 12,
        id: ColumnId.USER_FUNCTION,
        name: intl.formatMessage(columnNames[ColumnId.USER_FUNCTION]),
        render: ({userFunction}) => userFunction,
      },
      {
        defaultWidth: 7,
        id: ColumnId.LEVEL_CODE,
        name: intl.formatMessage(columnNames[ColumnId.LEVEL_CODE]),
        render: ({levelCode}) => <SensitiveData isHidden={isSensitiveDataHidden}>{levelCode}</SensitiveData>,
      },
      {
        defaultWidth: 5,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({active}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            <ActiveBadge active={active} />
          </SensitiveData>
        ),
      },
    ];

    const actionColumns: Column<MarketDataRecord>[] =
      onDeleteItem || onEditItem
        ? [
            {
              defaultWidth: 3,
              id: 'actions',
              name: '',
              render: (record) => <MarketDataRecordMenu record={record} onDelete={onDeleteItem} onEdit={onEditItem} />,
            },
          ]
        : [];

    return [...basicColumns, ...actionColumns];
  }, [intl, isSensitiveDataHidden, onDeleteItem, onEditItem]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={records}
      emptyMessage={intl.formatMessage(messages.empty)}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      storageName='marketDataRecords.list'
    />
  );
}
