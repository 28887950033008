import {defineMessages} from 'react-intl';
import {OfferStatus} from '.';

export const offerStatusLabels = defineMessages<OfferStatus>({
  [OfferStatus.CREATED]: {
    defaultMessage: 'Created',
    description: 'Offer status',
  },
  [OfferStatus.DECLINED]: {
    defaultMessage: 'Declined',
    description: 'Offer status',
  },
  [OfferStatus.ACCEPTED]: {
    defaultMessage: 'Accepted',
    description: 'Offer status',
  },
  [OfferStatus.REVIEW]: {
    defaultMessage: 'On review',
    description: 'Offer status',
  },
  [OfferStatus.DIGITIZED]: {
    defaultMessage: 'Digitized',
    description: 'Offer status',
  },
  [OfferStatus.APPROVING]: {
    defaultMessage: 'Approving',
    description: 'Offer status',
  },
  [OfferStatus.COMPLETED]: {
    defaultMessage: 'Completed',
    description: 'Offer status',
  },
});
