export enum TerminationReason {
  TIRED_OR_DOWNSHIFT = 'tiredOrDownshift',
  BORING_TASKS = 'boringTasks',
  CHANGING_JOB_AREA = 'changingJobArea',
  COMPANY_SIZE = 'companySize',
  RELOCATION = 'relocation',
  COMPENSATION = 'compensation',
  MANAGER_CONFLICT = 'managerConflict',
  POOR_PERFORMANCE = 'poorPerformance',
  PROBATION_FAILED = 'probationFailed',
  TEAM_CONFLICT = 'teamConflict',
  LACK_OF_GROWTH = 'lackOfGrowth',
  LAYOFF = 'layoff',
  DEAL = 'deal',
  OTHER = 'other',
}
