import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import addDays from 'date-fns/addDays';
import {Frequency} from 'domain/frequency/model';
import {mockRegularBonusValue} from 'domain/regularBonusRecord/model/value/mock';
import faker from 'faker';
import {dateToString} from 'models/system/formattedDate';
import {RegularBonusRecord} from '.';
import {RegularBonusType} from './type';

const frequencies = getEnumValues(Frequency);
const regularBonusTypes = getEnumValues(RegularBonusType);

export function mockRegularBonusRecord(data: Partial<RegularBonusRecord> = {}): RegularBonusRecord {
  const startDate = Math.random() > 0.5 ? faker.date.past() : faker.date.future();
  const type = faker.random.arrayElement(regularBonusTypes);

  return {
    active: faker.random.boolean(),
    comment: faker.lorem.text(),
    endDate:
      Math.random() > 0.5 ? dateToString(addDays(startDate, faker.random.number({min: 0, max: 1000}))) : undefined,
    frequency: faker.random.arrayElement(frequencies),
    id: createMockId(),
    startDate: dateToString(startDate),
    value: mockRegularBonusValue(type),
    type,
    ...data,
  };
}
