import {Feature, Features} from './model';

export const defaultFeatureAvailability: Features = {
  [Feature.INVADER_LOGO]: false,
  [Feature.SALARY_RANGES_ADMIN]: true,
  [Feature.SALARY_RANGES_MANAGER]: true,
  [Feature.OFFER_CR]: true,
  [Feature.ONE_TIME_BONUSES]: true,
  [Feature.OWN_ONE_TIME_BONUSES]: ['local', 'stage'].includes(window.intranetEnv.APP_ENV),
  [Feature.OFFICE_POLICY]: true,
  [Feature.JOB_CONTRACT_ON_HOLD]: true,
  [Feature.VPN]: true,
  [Feature.SUBORDINATE_INFORMATION]: true,
  [Feature.SUBORDINATE_INFORMATION_NEW_ICON]: false,
  [Feature.USERSNAP]: true,
  [Feature.COMPENSATION_REVIEW_REQUEST]: true,
  [Feature.CALENDAR_ISSUES]: false,
};
