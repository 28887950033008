import {identity} from '@joomcode/deprecated-utils/function';
import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {CompensationRequestRejectionDiff} from 'domain/compensations/request/api/reject';
import React, {useMemo} from 'react';
import {Field, Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  onClose: () => void;
  onSubmit: FormProps<CompensationRequestRejectionDiff>['onSubmit'];
};

export function RejectDialog({onClose, onSubmit}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  return (
    <Form<CompensationRequestRejectionDiff> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid}) => (
        <Dialog ariaLabel={intl.formatMessage(messages.dialogAriaLabel)} isOpen onClose={onClose} width={600}>
          <Dialog.Header>{intl.formatMessage(messages.header)}</Dialog.Header>
          <Dialog.Body withDefaultPadding>
            <form onSubmit={handleSubmit} id={formId}>
              <Field<string> name='comment' parse={identity} validate={validateFilled}>
                {({input, meta}) => (
                  <FormControl
                    disabled={submitting}
                    error={getFieldErrorText(meta, {intl})}
                    label={intl.formatMessage(messages.commentLabel)}
                    required
                  >
                    {(formControlProps) => <Textarea {...formControlProps} {...input} disabled={submitting} />}
                  </FormControl>
                )}
              </Field>
            </form>
          </Dialog.Body>
          <Dialog.Footer align='right'>
            <ButtonGroup spaced size='l'>
              <Button kind='text' intent='neutral' onClick={onClose}>
                {intl.formatMessage(messages.cancelButton)}
              </Button>
              <Button
                form={formId}
                kind='primary'
                intent='primary'
                type='submit'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.submitButton)}
              </Button>
            </ButtonGroup>
          </Dialog.Footer>
        </Dialog>
      )}
    </Form>
  );
}
