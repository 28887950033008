import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Create role',
    description: '[title] Page title',
  },
  submitButtonText: {
    defaultMessage: 'Create',
    description: '[button] role form submit button',
  },
});
