import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Checkbox} from '@joomcode/joom-ui/Checkbox';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Permission} from 'domain/permission/model';
import {ResourceId} from 'domain/resource/model';
import {ResourceSelectFormControl} from 'domain/resource/widgets/SelectFormControl';
import {FieldRole, FieldRoleValue} from 'domain/role/widgets/Field';
import {RoleBinding, RoleBindingRequestConfig} from 'domain/roleBinding/model';
import {RoleBindingStatus} from 'domain/roleBinding/model/status';
import {requestRoleBindingAsAdminFx, requestRoleBindingFx} from 'domain/roleBinding/stores/main';
import {TeamId} from 'domain/team/model/id';
import {FieldTeam, FieldTeamValue} from 'domain/team/widgets/Field';
import {generalMessages} from 'i18n/messages/general';
import React, {useCallback, useMemo} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {labels, messages} from './messages';
import styles from './styles.css';

type Props = {
  onClose: () => void;
  onSuccessfulGrant?: (roleBinding: RoleBinding) => void;
  onSuccessfulRequest?: (roleBinding: RoleBinding) => void;
  teamId?: TeamId;
  resourceId?: ResourceId;
};

type FormState = Partial<RoleBindingRequestConfig>;

export function RoleBindingCreationDialog({
  teamId,
  resourceId,
  onClose,
  onSuccessfulGrant,
  onSuccessfulRequest,
}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const requestHandler = acl.hasPermission(Permission.TEAM_ADMIN_WRITE)
    ? requestRoleBindingAsAdminFx
    : requestRoleBindingFx;
  const onSubmit = useCallback(
    (data: FormState) => {
      const resourceIdValue = resourceId || data.resourceId;
      const teamIdValue = teamId || data.teamId;

      if (!data.roleId || !resourceIdValue || !teamIdValue) {
        return Promise.reject().catch(() => toaster.error(intl.formatMessage(generalMessages.partiallyFilledError)));
      }

      return requestHandler({
        resourceId: resourceIdValue,
        teamId: teamIdValue,
        roleId: data.roleId,
        propagate: data.propagate,
      })
        .then((roleBinding: RoleBinding) => {
          onClose();
          if (roleBinding.status === RoleBindingStatus.APPROVED) {
            toaster.success(intl.formatMessage(messages.successfullyGranted));
            onSuccessfulGrant?.(roleBinding);
          } else {
            toaster.success(intl.formatMessage(messages.successfullyRequested));
            onSuccessfulRequest?.(roleBinding);
          }
        })
        .catch(toaster.interceptThenThrowError);
    },
    [onClose, onSuccessfulGrant, onSuccessfulRequest, resourceId, requestHandler, teamId],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 24rem)'>
      <Form<FormState> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <form onSubmit={handleSubmit} id={formId}>
                {!resourceId && (
                  <ResourceSelectFormControl
                    name='resourceId'
                    label={intl.formatMessage(labels.resource)}
                    submitting={submitting}
                  />
                )}
                {!teamId && (
                  <Field<FieldTeamValue> name='teamId'>
                    {({meta}) => (
                      <FormControl
                        disabled={submitting}
                        label={intl.formatMessage(labels.team)}
                        error={getFieldErrorText(meta, {intl})}
                        required
                      >
                        {(formControlProps) => (
                          <FieldTeam
                            name='teamId'
                            clearable
                            validate={validateFilled}
                            disabled={submitting}
                            {...formControlProps}
                          />
                        )}
                      </FormControl>
                    )}
                  </Field>
                )}
                <Field<FieldRoleValue> name='roleId'>
                  {({meta}) => (
                    <FormControl
                      disabled={submitting}
                      label={intl.formatMessage(labels.role)}
                      error={getFieldErrorText(meta, {intl})}
                      required
                    >
                      {(formControlProps) => (
                        <FieldRole
                          disabled={submitting}
                          name='roleId'
                          validate={validateFilled}
                          {...formControlProps}
                        />
                      )}
                    </FormControl>
                  )}
                </Field>
                {acl.hasPermission(Permission.ROLE_BINDING_WRITE_WITH_PROPAGATE) && (
                  <Field<boolean> name='propagate' type='checkbox' initialValue={false}>
                    {({input: {value, ...input}}) => (
                      <div className={styles.checkboxWrap}>
                        <Checkbox {...input} label={intl.formatMessage(labels.propagate)} disabled={submitting} />
                      </div>
                    )}
                  </Field>
                )}
              </form>
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
