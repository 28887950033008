import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {User, UserId} from 'domain/user/model';
import {UserAutocomplete} from 'domain/user/widgets/Autocomplete';
import {FieldValidator} from 'final-form';
import React, {useCallback, useMemo} from 'react';
import {useField} from 'react-final-form';

export type FieldUserValue = UserId | null;

type Props = {
  clearable?: boolean;
  disabled?: boolean;
  id?: string;
  initialValue?: FieldUserValue;
  name: string;
  suggestTerminatedUsers?: boolean;
  validate?: FieldValidator<string>;
  users?: User[];
};

export function FieldUser({
  initialValue = null,
  name,
  suggestTerminatedUsers = false,
  validate,
  users: defaultUsers,
  ...restProps
}: Props) {
  const {dataState, userByLogin, loginById} = useAllUsers({withTerminated: suggestTerminatedUsers});
  const users = useMemo(() => defaultUsers ?? Object.values(userByLogin), [defaultUsers, userByLogin]);

  const {
    input: {onChange, value, ...input},
  } = useField<FieldUserValue>(name, {allowNull: true, initialValue, validate});

  const handleChange = useCallback(
    (user: User | null) => {
      onChange(user?.id ?? null);
    },
    [onChange],
  );

  return (
    <UserAutocomplete
      disabled={dataState !== DataState.LOADED}
      items={users}
      onChange={handleChange}
      value={value ? userByLogin[loginById[value]] ?? null : null}
      {...restProps}
      {...input}
    />
  );
}
