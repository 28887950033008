import cn from 'classnames';
import React, {CSSProperties, ReactElement, ReactNode, useContext, useMemo, useEffect} from 'react';
import {useMenuState, MenuButton, Menu as BaseMenu, MenuProps as BaseMenuProps, MenuButtonHTMLProps} from 'reakit/Menu';
import {Portal} from 'react-portal';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../create-locator';
import {Listbox} from '../Listbox';
import styles from './index.css';
import {MenuContext, MenuState, SubmenuDisclosureContext} from './context';

export type MenuTestId = ConvertLocatorToTestId<MenuLocator>;
export type MenuLocator = Locator<void>;

export type MenuProps = {
  ariaLabel?: string;
  children: ReactNode;
  withDefaultContentPadding?: boolean;
  disabled?: boolean;
  disclosure: ReactElement | ((props: MenuButtonHTMLProps, state: {isOpen: boolean}) => ReactElement);
  minWidth?: string;
  maxHeight?: string;
  offset?: {
    horizontal?: string;
    vertical?: string;
  };
  onToggleMenu?: (value: boolean) => void;
  placement?: BaseMenuProps['placement'];
  renderToPortal?: boolean;
  isInitiallyOpened?: boolean;
} & Partial<Mark<MenuLocator>>;

function MenuWrapper({renderToPortal, children}: {renderToPortal: boolean; children: ReactNode}) {
  if (renderToPortal) {
    return <Portal>{children}</Portal>;
  }

  return <>{children}</>; // eslint-disable-line react/jsx-no-useless-fragment
}

function isValidElement(
  element: ReactElement | ((props: Record<string, unknown>, state: Record<string, unknown>) => ReactElement),
): element is ReactElement {
  return React.isValidElement(element);
}

export function Menu({
  ariaLabel,
  children,
  disabled,
  disclosure,
  minWidth,
  maxHeight,
  offset,
  placement,
  renderToPortal = true,
  onToggleMenu,
  isInitiallyOpened,
  withDefaultContentPadding = true,
  ...restProperties
}: MenuProps) {
  const locator = createLocator(restProperties);
  const menu = useMenuState({placement, visible: isInitiallyOpened});
  const parent = useContext(MenuContext);
  const submenuDisclosure = useContext(SubmenuDisclosureContext) ?? undefined;

  const menuState = useMemo<MenuState>(
    () => ({
      ...menu,
      onClose() {
        menu.hide();
        if (parent?.onClose) {
          parent.onClose();
        }
      },
    }),
    [menu, parent?.onClose],
  );

  useEffect(() => {
    if (onToggleMenu) {
      onToggleMenu(menu.visible);
    }
  }, [menu.visible]);

  return (
    <MenuContext.Provider value={menuState}>
      {/* @ts-expect-error reakit types don't support React 18 */}
      <MenuButton disabled={disabled} {...menu} {...submenuDisclosure}>
        {(disclosureProps) =>
          isValidElement(disclosure)
            ? React.cloneElement(React.Children.only(disclosure), disclosureProps)
            : disclosure(disclosureProps, {isOpen: menu.visible})
        }
      </MenuButton>

      <MenuWrapper renderToPortal={renderToPortal}>
        <BaseMenu
          aria-label={ariaLabel}
          className={cn(styles.menu, {
            [styles.submenu]: Boolean(parent),
          })}
          {...locator()}
          style={
            {
              ...(minWidth && {minWidth}),
              ...(maxHeight && {maxHeight}),
              ...(offset && {
                '--menu-offset-horizontal': offset.horizontal,
                '--menu-offset-vertical': offset.vertical,
              }),
            } as CSSProperties
          }
          {...menu}
        >
          <Listbox withDefaultPadding={withDefaultContentPadding}>{children}</Listbox>
        </BaseMenu>
      </MenuWrapper>
    </MenuContext.Provider>
  );
}
