import {array, object, oneOfEnum, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {userSchema} from 'domain/user/model';

export enum UploadResultWarningType {
  NOT_APPLICABLE = 'not applicable',
}

export const salaryRangesUploadResultWarningSchema = object({
  type: oneOfEnum(UploadResultWarningType),
  meta: object({
    users: optional(array(userSchema)),
  }),
});
