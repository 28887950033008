import {intranetApi} from 'apiClient';
import {CrResult, crResultSchema} from 'domain/compaRatio/model';
import {CountryCode} from 'domain/countryCode/model';
import {Currency} from 'domain/currency/model';
import {Frequency} from 'domain/frequency/model';
import {LevelCode} from 'domain/levelCode/model';

export type CalculateCrConfig = {
  amount: number;
  currency: Currency;
  frequency: Frequency;
  levelCode: LevelCode;
  region: CountryCode;
  userFunction: string;
};

export const calculateCr = async (config: CalculateCrConfig): Promise<CrResult> => {
  const {data} = await intranetApi.post('/v1/secure/salaryRanges/calculateCr', config);

  return crResultSchema.runWithException(data);
};
