import {
  array,
  boolean,
  dateFromString,
  ExtractSchemaType,
  number,
  object,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {serverPermissionSchema} from 'domain/permission/model';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const userRoleIdSchema = idSchema;

export const userRoleSchemaFromApi = object({
  canGrantAllRoles: optionalWithDefault(false, boolean()),
  canGrantRoles: optionalWithDefault([], array(idSchema)),
  id: userRoleIdSchema,
  managedBy: optionalWithDefault([], array(userRoleIdSchema)),
  name: string(),
  permissions: array(serverPermissionSchema),
  updatedTime: optional(dateFromString()),
  usedBy: optionalWithDefault(0, number()),
});

export type UserRoleFromApi = ExtractSchemaType<typeof userRoleSchemaFromApi>;

export const userRoleApiMapping = (userRoleFromApi: UserRoleFromApi) => ({
  ...userRoleFromApi,
  permissionsCount: userRoleFromApi?.permissions.length || 0,
});

export const userRoleSchema = userRoleSchemaFromApi.map(userRoleApiMapping);

export type UserRole = ExtractSchemaType<typeof userRoleSchema>;
export type UserRoleId = ExtractSchemaType<typeof userRoleIdSchema>;
