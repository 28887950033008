/**
 * Converts the first character of string to upper case.
 * If `locale` is provided as a second argument, conversion
 * is performed according to any locale-specific case mappings.
 *
 * Be aware that this function differs from `lodash.capitalize`,
 * which converts the remaining characters to lower case:
 * ```
 * _.capitalize('TEST') // Test
 * capitalize('TEST') // TEST
 * ```
 */
export function capitalize<T extends string>(string: T, locale?: string): Capitalize<T> {
  if (locale) {
    return (string.charAt(0).toLocaleUpperCase(locale) + string.slice(1)) as Capitalize<T>;
  }

  return (string.charAt(0).toUpperCase() + string.slice(1)) as Capitalize<T>;
}
