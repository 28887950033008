import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {useOffices} from 'domain/officeMap/office/hooks/useOffices';
import {TokenGenerationPanel} from 'domain/officeMap/office/widgets/TokenGenerationPanel';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/officeMap/titles';
import {officeUrls} from 'routes/officeMap/urls';
import {messages} from './messages';

export function OfficeRoomTokensPage() {
  const intl = useIntl();
  const officesStore = useOffices();

  return (
    <PageStateHandler data={officesStore} state={officesStore.dataState}>
      {({officesByAlias}) => {
        const offices = Object.values(officesByAlias);

        return (
          <Page
            title={intl.formatMessage(messages.title)}
            breadcrumbs={[{title: intl.formatMessage(pageTitles.office), href: officeUrls.root()}]}
          >
            {offices.map((office) => (
              <TokenGenerationPanel key={office.id} office={office} />
            ))}
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
