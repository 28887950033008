import {isNotNullish} from '@joomcode/deprecated-utils/function/isNotNullish';
import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import type {ValidationFunction} from '../../types';
import {StringValidatorErrorCode, StringValidatorOptions} from './types';

export const validateString: ValidationFunction<unknown, StringValidatorOptions, StringValidatorErrorCode> = (
  value,
  options = {},
) => {
  const {minLength, maxLength, regExp} = options;

  if (isNullish(value)) return undefined;

  if (typeof value !== 'string') {
    return {code: StringValidatorErrorCode.STRING_INVALID, options};
  }

  const {length} = value;

  if (isNotNullish(minLength) && isNotNullish(maxLength) && (length < minLength || length > maxLength)) {
    return {code: StringValidatorErrorCode.STRING_LENGTH_OUT_OF_RANGE, options};
  }

  if (isNotNullish(minLength) && length < minLength) {
    return {code: StringValidatorErrorCode.STRING_LENGTH_LESS_MIN, options};
  }

  if (isNotNullish(maxLength) && length > maxLength) {
    return {code: StringValidatorErrorCode.STRING_LENGTH_GREATER_MAX, options};
  }

  if (isNotNullish(regExp) && !regExp.test(value)) {
    return {code: StringValidatorErrorCode.STRING_REG_EXP_NOT_MATCH, options};
  }

  return undefined;
};
