import {loadSelf} from './loadSelf';
import {toggleDefaultUserPretence} from './toggleDefaultUserPretence';
import {updateSelf} from './update';
import {updateAvatar} from './updateAvatar';

export const selfApi = {
  loadSelf,
  updateAvatar,
  updateSelf,
  toggleDefaultUserPretence,
};
