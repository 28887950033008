import {CompensationRequest} from 'domain/compensations/request/model';
import {
  CompensationRequestsFilterValue,
  CompensationRequestsFilterValues,
} from 'domain/compensations/request/model/filters';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {UserId} from 'domain/user/model';

type Options = {
  availableFilters: CompensationRequestsFilterValue[];
  filterValues: CompensationRequestsFilterValues;
  request: CompensationRequest;
  selfUserId: UserId;
};

export const checkRequestFitsFilters = ({availableFilters, filterValues, request, selfUserId}: Options): boolean => {
  if (availableFilters.includes('statuses') && filterValues.statuses) {
    const statuses = filterValues.statuses.includes(CompensationRequestStatus.APPROVED)
      ? [...filterValues.statuses, CompensationRequestStatus.OFFICE_CARE_APPROVED]
      : filterValues.statuses;
    if (!statuses.includes(request.status)) {
      return false;
    }
  }

  if (
    availableFilters.includes('legalEntityIds') &&
    filterValues.legalEntityIds &&
    !filterValues.legalEntityIds.includes(request.legalEntity.id)
  ) {
    return false;
  }

  if (availableFilters.includes('showIndirect') && !filterValues.showIndirect && selfUserId !== request.approver.id) {
    return false;
  }

  return true;
};
