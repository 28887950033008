import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Sorting, SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDateRange, HumanDateTime} from '@joomcode/joom-ui/HumanDate';
import {ReactComponent as Checkmark} from '@joomcode/joom-ui/icons/core/checkmark.svg';
import {Pagination} from '@joomcode/joom-ui/Pagination';
import {HintIcon} from 'components/ui/HintIcon';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {TimeOffRequest} from 'domain/timeOff/request/model';
import {TimeOffRequestsTableMode} from 'domain/timeOff/request/model/mode';
import {ActionsMenu} from 'domain/timeOff/request/widgets/ActionsMenu';
import {TimeOffRequestStatusBadge} from 'domain/timeOff/request/widgets/StatusBadge';
import {formatTimeOffType} from 'domain/timeOff/type/model/formatter';
import {UserLink} from 'domain/user/widgets/Link';
import orderBy from 'lodash/orderBy';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {ColumnName} from './columnName';
import {DaysTaken} from './DaysTaken';
import {columnNames, messages} from './messages';

type Props = {
  timeOffRequests: TimeOffRequest[];
  dataState: DataState;
  mode: TimeOffRequestsTableMode;
  title?: string;
  pagination?: OffsetPagination;
  count?: number;
};

const getRowKey = ({id}: TimeOffRequest) => id;

export function TimeOffTable({timeOffRequests, dataState, mode, title, pagination, count}: Props) {
  const intl = useIntl();

  const columns: Column<TimeOffRequest>[] = useMemo(() => {
    const allColumns: Column<TimeOffRequest>[] = [
      {
        defaultWidth: 6,
        id: ColumnName.TYPE,
        name: intl.formatMessage(columnNames[ColumnName.TYPE]),
        render: ({type, createdBy, user}) => (
          <>
            {formatTimeOffType(type, intl, mode === TimeOffRequestsTableMode.ALL)}
            {user.id !== createdBy.id && (
              <HintIcon>
                {intl.formatMessage(messages.createdByHint, {
                  userLink: <UserLink user={createdBy} colored withAvatar={false} />,
                })}
              </HintIcon>
            )}
          </>
        ),
      },
      {
        defaultWidth: 8,
        id: ColumnName.CREATED_AT,
        name: intl.formatMessage(columnNames[ColumnName.CREATED_AT]),
        render: ({createdAt}) => <HumanDateTime value={createdAt} format='short' />,
        sortable: mode !== TimeOffRequestsTableMode.ALL,
      },
      {
        defaultWidth: 10,
        id: ColumnName.DATES,
        name: intl.formatMessage(columnNames[ColumnName.DATES]),
        render: ({startDate, endDate}) => <HumanDateRange range={{from: startDate, to: endDate}} format='short' utc />,
      },
      {
        defaultWidth: 4,
        id: ColumnName.DAYS_TAKEN,
        align: 'right',
        name: intl.formatMessage(columnNames[ColumnName.DAYS_TAKEN]),
        noWrap: true,
        render: ({start, end, daysTaken}) =>
          end && start && <DaysTaken totalDays={differenceInCalendarDays(end, start) + 1} balanceDays={daysTaken} />,
      },
      {
        defaultWidth: 6,
        id: ColumnName.STATUS,
        name: intl.formatMessage(columnNames[ColumnName.STATUS]),
        render: ({status}) => <TimeOffRequestStatusBadge status={status} />,
      },
    ];

    if (mode !== TimeOffRequestsTableMode.MY) {
      const nameColumn: Column<TimeOffRequest> = {
        defaultWidth: 10,
        id: ColumnName.USER,
        name: intl.formatMessage(columnNames[ColumnName.USER]),
        render: ({user}) => (user ? <UserLink colored user={user} withAvatar={false} /> : <DataTable.CellEmpty />),
      };
      allColumns.unshift(nameColumn);
    }

    if (mode === TimeOffRequestsTableMode.ALL) {
      const legalEntityColumn: Column<TimeOffRequest> = {
        defaultWidth: 8,
        id: ColumnName.LEGAL_ENTITY,
        name: intl.formatMessage(columnNames[ColumnName.LEGAL_ENTITY]),
        render: ({legalEntity}) => legalEntity?.title ?? <DataTable.CellEmpty />,
      };
      const recordedColumn: Column<TimeOffRequest> = {
        defaultWidth: 4,
        id: ColumnName.RECORDED,
        align: 'center',
        name: intl.formatMessage(columnNames[ColumnName.RECORDED]),
        render: ({recordedInAccountingSystem}) => (recordedInAccountingSystem ? <Checkmark /> : ''),
      };
      allColumns.push(legalEntityColumn, recordedColumn);
    }

    const actionsColumn: Column<TimeOffRequest> = {
      defaultWidth: 10,
      id: ColumnName.ACTIONS,
      name: intl.formatMessage(columnNames[ColumnName.ACTIONS]),
      render: (request) => <ActionsMenu mode={mode} timeOffRequest={request} />,
    };
    allColumns.push(actionsColumn);

    return allColumns;
  }, [intl, mode]);

  const [sorting, setSorting] = useState<Sorting<ColumnName> | undefined>({
    by: ColumnName.CREATED_AT,
    direction: SortingDirection.DESC,
  });

  const sortedRequests = useMemo(
    () => (sorting ? orderBy(timeOffRequests, sorting.by, sorting.direction) : timeOffRequests),
    [timeOffRequests, sorting],
  );

  const onSort = useCallback((sortingConfig: Sorting<ColumnName>) => setSorting(sortingConfig), [setSorting]);

  return (
    <DataTable<TimeOffRequest>
      columns={columns}
      dataState={dataState}
      data={sortedRequests}
      emptyMessage={intl.formatMessage(messages.noRequests)}
      getRowKey={getRowKey}
      key={mode}
      storageName='timeOff.requestList'
      title={title}
      sorting={sorting}
      onSort={onSort}
      toolbarBottom={
        pagination ? (
          <Pagination
            disabled={dataState === DataState.LOADING}
            limit={pagination.limit}
            onLimitChange={pagination.setLimit}
            onPageChange={pagination.setPage}
            page={pagination.page}
            pagesCount={count ?? 1}
          />
        ) : null
      }
    />
  );
}
