import {Button} from '@joomcode/joom-ui/Button';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plus.svg';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  onClick(): void;
};

export function AddRecordButton({onClick}: Props) {
  const intl = useIntl();

  return (
    <Button size='m' kind='secondary' intent='neutral' iconLeft={<PlusIcon />} onClick={onClick}>
      {intl.formatMessage(messages.button)}
    </Button>
  );
}
