import React from 'react';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../create-locator';
import {Button, Item, Link} from './Item';
import {MenuButton} from './Item/MenuButton';
import styles from './index.css';

export type {NavbarItemLocator, NavbarItemTestId} from './Item';
export type {NavbarMenuButtonLocator, NavbarMenuButtonTestId} from './Item/MenuButton';
export type {NavbarButtonLocator, NavbarButtonTestId} from './Item/Button';

export type NavbarTestId = ConvertLocatorToTestId<NavbarLocator>;
export type NavbarLocator = Locator<{
  logo: void;
  content: void;
  toolbar: void;
}>;

type Props = {
  content?: React.ReactNode;
  logo: React.ReactNode;
  toolbar?: React.ReactNode;
  background?: React.CSSProperties['background'];
} & Partial<Mark<NavbarLocator>>;

export function Navbar({content, logo, toolbar, background, ...restProps}: Props) {
  const locator = createLocator(restProps);

  return (
    <div className={styles.navbar} style={{background}} {...locator()}>
      <div className={styles.logo} {...locator.logo()}>
        {logo}
      </div>
      {content && (
        <div className={styles.content} {...locator.content()}>
          {content}
        </div>
      )}
      {toolbar && (
        <div className={styles.toolbar} {...locator.toolbar()}>
          {toolbar}
        </div>
      )}
    </div>
  );
}

Navbar.Button = Button;
Navbar.Item = Item;
Navbar.Link = Link;
Navbar.MenuButton = MenuButton;
