export enum UserFullInfoColumnAccess {
  CR = 'cr',
  JOB_CONTRACTS = 'jobContracts',
  MARKET_DATA = 'marketData',
  PRIVATE_INFO = 'privateInfo',
  REGULAR_BONUS = 'regularBonus',
  SALARY = 'salary',
  SALARY_RANGES = 'salaryRanges',
  STOCK_OPTIONS = 'stockOptions',
}
