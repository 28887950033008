import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  name: {
    defaultMessage: 'Name',
    description: 'User emergency contact property label (13+ chars long requires a visual review)',
  },
  phone: {
    defaultMessage: 'Phone',
    description: 'User emergency contact property label (13+ chars long requires a visual review)',
  },
  relationship: {
    defaultMessage: 'Relationship',
    description: 'User emergency contact property label (13+ chars long requires a visual review)',
  },
});
