import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {UpdateSalaryRecordParams} from 'domain/salaryRecord/api/types/update';
import {SalaryRecord, salaryRecordSchema} from 'domain/salaryRecord/model';

export const update = async ({id, userId, diff}: UpdateSalaryRecordParams): Promise<SalaryRecord[]> => {
  const {data} = await intranetApi.post('/v1/secure/users/salaryRecords/update', diff, {params: {id, userId}});

  return array(salaryRecordSchema).runWithException(data);
};
