import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  nothingFound: {
    defaultMessage: 'Nothing found',
    description: 'Users search: empty result',
  },
  searchPlaceholder: {
    defaultMessage: 'Search Joomers',
    description: 'Users search input placeholder',
  },
  title: {
    defaultMessage: 'People',
    description: '[title] People page: people panel title',
  },
  buttonShowTerminated: {
    defaultMessage: 'Show terminated',
    description: '[button] Showing terminated users button',
  },
  buttonHideTerminated: {
    defaultMessage: 'Hide terminated',
    description: '[button] Hiding terminated users button',
  },
});
