import {intranetApi} from 'apiClient';
import {SecureRoleId} from 'domain/secureRole/model';
import {User, UserId, userSchema} from 'domain/user/model';

export type SecureRoleRemoveParams = {
  userId: UserId;
  secureRoleId: SecureRoleId;
};

export const removeFromUser = async (params: SecureRoleRemoveParams): Promise<User> => {
  const {data} = await intranetApi.post('/v1/secureRoles/removeFromUser', params);

  return userSchema.runWithException(data);
};
