import {DataState, getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {
  createAutocompleteMultiselectFilter,
  createBooleanFilter,
  createGenericDateRangeFilter,
  createMultiSelectFilter,
  DataFilter,
  DataFilterConfig,
} from '@joomcode/joom-ui/DataFilter';
import {DataFilterSpinner} from 'components/ui/DataFilterSpinner';
import {useLegalEntities} from 'domain/legalEntity/hooks/useLegalEntities';
import {TimeOffRequestsSearchFilters} from 'domain/timeOff/request/model';
import {TimeOffRequestsTableMode} from 'domain/timeOff/request/model/mode';
import {TimeOffRequestStatus} from 'domain/timeOff/request/model/status';
import {timeOffRequestStatuses} from 'domain/timeOff/request/model/status/messages';
import {TimeOffType} from 'domain/timeOff/type/model';
import {formatTimeOffType} from 'domain/timeOff/type/model/formatter';
import {useUserFilter} from 'domain/user/hooks/useUserFilter';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Feature, useFeature} from 'services/features';
import {formatDateRange, parseDateRange} from './converters';
import {messages} from './messages';

type Props = {
  values: TimeOffRequestsSearchFilters;
  onChange(payload: TimeOffRequestsSearchFilters): void;
  mode: TimeOffRequestsTableMode;
};

export const TimeOffRequestFilters = ({onChange, values, mode}: Props) => {
  const intl = useIntl();
  const {dataState: legalEntitiesDataState, legalEntities, canReadLegalEntities} = useLegalEntities();
  const {dataState: usersDataState, userFilter} = useUserFilter(intl.formatMessage(messages.userId));
  const consolidatedDataState = getConsolidatedDataState(
    usersDataState,
    canReadLegalEntities ? legalEntitiesDataState : DataState.LOADED,
  );
  const officePolicyFeature = useFeature(Feature.OFFICE_POLICY);
  const timeOffTypes = useMemo(() => {
    const options = getEnumValues(TimeOffType);
    return officePolicyFeature.isAvailable
      ? options
      : options.filter((type) => type !== TimeOffType.REMOTE_WORK && type !== TimeOffType.SEMI_SICK_LEAVE);
  }, [officePolicyFeature.isAvailable]);

  const config = useMemo<DataFilterConfig<TimeOffRequestsSearchFilters>>(() => {
    return {
      startDate: createGenericDateRangeFilter(intl.formatMessage(messages.startDate), {
        alwaysVisible: true,
        formatValue: formatDateRange,
        parseValue: parseDateRange,
      }),
      endDate: createGenericDateRangeFilter(intl.formatMessage(messages.endDate), {
        alwaysVisible: true,
        formatValue: formatDateRange,
        parseValue: parseDateRange,
      }),
      types: createMultiSelectFilter(intl.formatMessage(messages.type), {
        alwaysVisible: true,
        options: timeOffTypes.map((type) => ({
          value: type,
          label: formatTimeOffType(type, intl),
        })),
      }),
      statuses: createMultiSelectFilter(intl.formatMessage(messages.status), {
        alwaysVisible: true,
        options: getEnumValues(TimeOffRequestStatus).map((status) => ({
          value: status,
          label: intl.formatMessage(timeOffRequestStatuses[status]),
        })),
      }),
      ...(mode !== TimeOffRequestsTableMode.MY && {
        userId: userFilter,
      }),
      ...(canReadLegalEntities &&
        mode === TimeOffRequestsTableMode.ALL && {
          legalEntityIds: createAutocompleteMultiselectFilter(intl.formatMessage(messages.legalEntityId), {
            alwaysVisible: true,
            options: legalEntities.map(({id, title}) => ({
              label: title,
              value: id,
            })),
            renderOption: ({label: title}) => title,
          }),
        }),
      ...(mode === TimeOffRequestsTableMode.ALL && {
        recordedInAccountingSystem: createBooleanFilter(intl.formatMessage(messages.recordedInAccountingSystem), {
          alwaysVisible: true,
        }),
      }),
    };
  }, [intl, userFilter, mode, timeOffTypes]);

  if (consolidatedDataState === DataState.IDLE || consolidatedDataState === DataState.LOADING) {
    return <DataFilterSpinner />;
  }

  return <DataFilter config={config} onChange={onChange} values={values} />;
};
