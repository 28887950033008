import {useFieldWithInitialValue} from '@joomcode/joom-form';
import {Checkbox} from '@joomcode/joom-ui/Checkbox';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {UserRole, UserRoleId} from 'domain/userRole/model';
import {UserRoleRolesField} from 'domain/userRole/widgets/Form/UserRoleRolesField';
import React, {useEffect} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';

const initialRoleIdsValue: UserRoleId[] = [];

type Props = {
  role?: UserRole;
  disabled: boolean;
};

export function UserRoleCanGrantRolesFields({role, disabled}: Props) {
  const intl = useIntl();
  const {change: changeForm} = useForm<UserRole>();

  const {input: canGrantAllInput} = useFieldWithInitialValue<boolean>('canGrantAllRoles', {
    initialValue: role?.canGrantAllRoles ?? false,
    type: 'checkbox',
  });

  useEffect(() => {
    if (canGrantAllInput.checked) {
      changeForm('canGrantRoles', []);
    }
  }, [canGrantAllInput.checked, changeForm]);

  const {value, ...canGrantAllRestInput} = canGrantAllInput;
  return (
    <>
      <UserRoleRolesField
        name='canGrantRoles'
        initialValue={role?.canGrantRoles ?? initialRoleIdsValue}
        disabled={disabled || (canGrantAllInput.checked ?? false)}
        label={intl.formatMessage(messages.canGrantRolesLabel)}
      />
      <FormControl disabled={disabled}>
        {(formControlProps) => (
          <Checkbox
            {...formControlProps}
            {...canGrantAllRestInput}
            disabled={disabled}
            label={intl.formatMessage(messages.canGrantAllRolesLabel)}
          />
        )}
      </FormControl>
    </>
  );
}
