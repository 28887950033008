import {CompensationRequestsFilterValue} from 'domain/compensations/request/model/filters';
import {CompensationRequestsMode} from 'domain/compensations/request/model/mode';

export function getAvailableFilters(
  mode: CompensationRequestsMode,
  hasIndirectSubordinates: boolean,
): CompensationRequestsFilterValue[] {
  if (mode === CompensationRequestsMode.ALL) {
    return ['statuses', 'legalEntityIds'];
  }

  if (mode === CompensationRequestsMode.SUBORDINATES) {
    const result: CompensationRequestsFilterValue[] = ['statuses'];
    if (hasIndirectSubordinates) {
      result.push('showIndirect');
    }
    return result;
  }

  return [];
}
