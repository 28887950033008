import {DataState, getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {Button} from '@joomcode/joom-ui/Button';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Breadcrumb, Page} from 'components/widgets/Page';
import {Permission} from 'domain/permission/model';
import {useTeamRoleBindings} from 'domain/roleBinding/hooks/useTeamRoleBindings';
import {RoleBinding} from 'domain/roleBinding/model';
import {RoleBindingCreationDialog} from 'domain/roleBinding/widgets/CreationDialog';
import {useTeams} from 'domain/team/hooks/useTeams';
import {teamIdSchema} from 'domain/team/model/id';
import {TeamRoleBindingMode} from 'domain/team/model/roleBindingMode';
import {getTeamBreadcrumbs} from 'domain/team/utils/getTeamBreadcrumbs';
import {isUserIndirectHead} from 'domain/team/utils/isUserIndirectHead';
import {TeamInheritedRoleBindingsPanel} from 'domain/team/widgets/InheritedRoleBindingsPanel';
import {TeamOwnRoleBindingsPanel} from 'domain/team/widgets/OwnRoleBindingsPanel';
import {UserFull} from 'domain/user/model';
import {NotFoundPage} from 'pages/NotFound';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {pageTitles} from 'routes/teams/titles';
import {teamsUrls} from 'routes/teams/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';

type Props = {
  selfUser: UserFull;
};

export function TeamRoleBindingsPage({selfUser}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const history = useHistory();
  const {id} = useTypedParams({id: teamIdSchema});
  const {byId: teamById, dataState: teamsDataState} = useTeams();
  const {data: roleBindings, dataState: roleBindingsDataState} = useTeamRoleBindings(id);
  const dataState = getConsolidatedDataState(teamsDataState, roleBindingsDataState);
  const roleBindingCreationDialog = usePopupState();

  const isTeamAdmin = acl.hasPermission(Permission.TEAM_ADMIN_WRITE);
  const canUpdateBindings = acl.hasPermission(Permission.ROLE_BINDING_WRITE);
  const onSuccessfulGrant = useCallback(
    ({teamWithAncestors: {team}}: RoleBinding) => {
      history.push(teamsUrls.roleBindings({id: team.id}, {mode: TeamRoleBindingMode.ACTIVE}));
    },
    [id],
  );
  const onSuccessfulRequest = useCallback(
    ({teamWithAncestors: {team}}: RoleBinding) => {
      history.push(teamsUrls.roleBindings({id: team.id}, {mode: TeamRoleBindingMode.MY}));
    },
    [id],
  );

  const breadcrumbs = useMemo(() => {
    if (!id) {
      return [];
    }
    const teamBreadcrumbs: Breadcrumb[] = getTeamBreadcrumbs(id, teamById, true).map((team) => ({
      title: team.name,
      href: teamsUrls.team({id: team.id}),
    }));
    return [{title: intl.formatMessage(pageTitles.teams), href: teamsUrls.teams()}, ...teamBreadcrumbs];
  }, [id, teamById, intl]);

  if (!id || (teamsDataState === DataState.LOADED && !teamById[id])) {
    return <NotFoundPage />;
  }

  return (
    <PageStateHandler data={roleBindings} state={dataState}>
      {({activeBindings, myBindings}) => {
        // We explicitly check team existence several lines above
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const team = teamById[id]!;
        const isSelfHead = isUserIndirectHead(selfUser.id, team.id, teamById);
        const activeOwnBindings = activeBindings.filter(({teamWithAncestors}) => teamWithAncestors.team.id === id);
        const inheritedBindings = activeBindings.filter(({teamWithAncestors}) => teamWithAncestors.team.id !== id);

        return (
          <Page
            title={intl.formatMessage(pageTitles.roleBindings, {teamName: team.name})}
            breadcrumbs={breadcrumbs}
            actions={
              (isTeamAdmin || isSelfHead) &&
              canUpdateBindings && (
                <Button size='m' kind='primary' intent='primary' onClick={roleBindingCreationDialog.open}>
                  {intl.formatMessage(messages.addBindingButton)}
                </Button>
              )
            }
          >
            <TeamOwnRoleBindingsPanel
              activeBindings={activeOwnBindings}
              myBindings={myBindings}
              showTitle={inheritedBindings.length > 0}
            />
            {inheritedBindings.length > 0 && <TeamInheritedRoleBindingsPanel roleBindings={inheritedBindings} />}
            {roleBindingCreationDialog.isOpen && (
              <RoleBindingCreationDialog
                teamId={team.id}
                onClose={roleBindingCreationDialog.close}
                onSuccessfulGrant={onSuccessfulGrant}
                onSuccessfulRequest={onSuccessfulRequest}
              />
            )}
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
