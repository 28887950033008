import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  yes: {
    defaultMessage: 'Yes',
    description: 'Boolean Filter value',
    id: 'boolean-filter-value-true',
  },
  no: {
    defaultMessage: 'No',
    description: 'Boolean Filter value',
    id: 'boolean-filter-value-false',
  },
});
