import {CreateMarketDataRecordConfig} from 'domain/marketDataRecord/api/create';
import {DeleteMarketDataRecordParams} from 'domain/marketDataRecord/api/delete';
import {UpdateMarketDataRecordConfig} from 'domain/marketDataRecord/api/update';
import {MarketDataRecord} from 'domain/marketDataRecord/model';
import {UserId} from 'domain/user/model';
import {createEffect} from 'effector';

export const createMarketDataRecordFx = createEffect<CreateMarketDataRecordConfig, MarketDataRecord[]>(
  'create new function and level record',
);
export const deleteMarketDataRecordFx = createEffect<DeleteMarketDataRecordParams, void>(
  'delete function and level record',
);
export const getMarketDataRecordsFx = createEffect<UserId, MarketDataRecord[]>('get user‘s function and level records');
export const getMyMarketDataRecordsFx = createEffect<UserId, MarketDataRecord[]>('get own function and level records');
export const getSubordinateMarketDataRecordsFx = createEffect<UserId, MarketDataRecord[]>(
  'get subordinate function and level records',
);
export const updateMarketDataRecordFx = createEffect<UpdateMarketDataRecordConfig, MarketDataRecord[]>(
  'update function and level record',
);
