import {validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {CompanyUnitFormControl} from 'components/widgets/CompanyUnitFormControl';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {CompanyUnits} from 'domain/companyUnits/model';
import {FieldLevelCode} from 'domain/levelCode/widgets/Field';
import {MarketDataRecord} from 'domain/marketDataRecord/model';
import React from 'react';
import {FormRenderProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  formId: string;
  submitting: boolean;
  recordToUpdate?: MarketDataRecord;
  companyUnits: CompanyUnits;
  onSubmit: FormRenderProps<MarketDataRecord>['handleSubmit'];
};

export function MarketDataRecordForm({formId, submitting, recordToUpdate, companyUnits, onSubmit}: Props) {
  const intl = useIntl();

  return (
    <form onSubmit={onSubmit} id={formId} noValidate autoComplete='off'>
      <Grid>
        <Grid.Item xl={3} xs={12}>
          <FieldDate
            name='effectiveDate'
            label={intl.formatMessage(labels.effectiveDate)}
            initialValue={recordToUpdate?.effectiveDate}
            required
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <CompanyUnitFormControl
            name='userFunction'
            type='userFunction'
            initialValue={recordToUpdate?.userFunction}
            disabled={submitting}
            items={companyUnits.userFunctions}
            required
            validate={validateFilled}
          />
        </Grid.Item>
        <Grid.Item xl={3} xs={12}>
          <FieldLevelCode
            name='levelCode'
            label={intl.formatMessage(labels.levelCode)}
            initialValue={recordToUpdate?.levelCode}
            required
            validate={validateFilled}
          />
        </Grid.Item>
      </Grid>
    </form>
  );
}
