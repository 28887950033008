import {
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {MarketLevelPosition} from './marketLevelPosition';
import {CrResultStatus} from './status';

export const crResultSchema = object({
  error: optional(string()),
  marketLevelPosition: optional(oneOfEnum(MarketLevelPosition)),
  status: oneOfEnum(CrResultStatus),
  value: optional(number()),
});

export type CrResult = ExtractSchemaType<typeof crResultSchema>;
