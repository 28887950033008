import {CompensationReviewRequestDraft} from 'domain/compensationReviewRequest/model/draft';
import {useCallback, useRef, useState} from 'react';

type ReturnShape = {
  draft: CompensationReviewRequestDraft | undefined;
  setDraft: (draft: CompensationReviewRequestDraft | undefined) => void;
  scrollRef: React.RefObject<HTMLDivElement>;
};

export function useDraftSwitching(): ReturnShape {
  const [draft, setDraft] = useState<CompensationReviewRequestDraft | undefined>(undefined);
  const scrollRef = useRef<HTMLDivElement>(null);
  const setDraftWithScrolling = useCallback(
    (draft: CompensationReviewRequestDraft | undefined) => {
      scrollRef.current?.scrollIntoView({behavior: 'instant'});
      setDraft(draft);
    },
    [setDraft],
  );

  return {
    draft,
    setDraft: setDraftWithScrolling,
    scrollRef,
  };
}
