import {getConsolidatedAccessDependentDataState} from '@joomcode/deprecated-utils/dataState';
import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {Page} from '@joomcode/joom-ui/Page';
import {Panel} from '@joomcode/joom-ui/Panel';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {useJobContracts} from 'domain/jobContract/hooks/useJobContracts';
import {JobContractPanels} from 'domain/jobContract/widgets/Panels';
import {LegalEntity} from 'domain/legalEntity/model';
import {Permission} from 'domain/permission/model';
import {buildUserGithubUrl} from 'domain/user/api/buildUserGithubUrl';
import {useUserLoader} from 'domain/user/hooks/useUserLoader';
import {useUserMainLegalEntity} from 'domain/user/hooks/useUserMainLegalEntity';
import {useUserPrivateInfo} from 'domain/user/hooks/useUserPrivateInfo';
import {UserFull} from 'domain/user/model';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import {InactiveUserCallout} from 'domain/user/widgets/InactiveUserCallout';
import {JobPanel} from 'domain/user/widgets/JobPanel';
import {UserProfile} from 'domain/user/widgets/Profile';
import {UserProfileLayout} from 'domain/user/widgets/ProfileLayout';
import {generalMessages} from 'i18n/messages/general';
import {NotFoundPage} from 'pages/NotFound';
import React, {useEffect, useRef} from 'react';
import Helmet from 'react-helmet';
import {useIntl} from 'react-intl';
import {RouteComponentProps, useParams} from 'react-router-dom';
import {pageTitles} from 'routes/users/titles';
import {useAcl} from 'services/acl';
import {useUsersnapApi, UserSnapEvents} from 'services/usersnap';
import {UserPageActions} from './Actions';
import {messages} from './messages';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function UserPage({selfUser}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {login} = useParams<{login: string}>();
  const githubDialog = usePopupState();
  const [githubLinked, setGithubLinked] = useQueryParam<string>('githubLinked', string(), '');
  const user = useUserLoader(login);
  const userPrivateInfo = useUserPrivateInfo(user.data?.id);
  const mainLegalEntity = useUserMainLegalEntity(user.data?.id);
  const isSelfUser = selfUser.login === login;
  const canSeeJobContracts = useJobContracts(user.data).hasReadAccess;
  const usersnapApi = useUsersnapApi();
  const dataState = getConsolidatedAccessDependentDataState(
    [user.dataState, true],
    [userPrivateInfo.dataState, userPrivateInfo.isVisibleToCurrentUser],
    [mainLegalEntity.dataState, mainLegalEntity.hasReadAccess],
  );
  const hasAccess = acl.hasPermission(Permission.USER_READ_ACTIVE) || isSelfUser;
  const usersnapTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (githubLinked) {
      setGithubLinked('');
      githubDialog.open();
    }
  }, [githubLinked, setGithubLinked, githubDialog]);

  useEffect(() => {
    if (hasAccess && usersnapApi && usersnapTimeoutId.current === null) {
      usersnapTimeoutId.current = setTimeout(() => usersnapApi?.logEvent(UserSnapEvents.OPEN_PROFILE), 7000);
    }

    return () => {
      if (usersnapTimeoutId.current) clearTimeout(usersnapTimeoutId.current);
    };
  }, [usersnapApi]);

  if (!hasAccess) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler
      data={user.data && [user.data, userPrivateInfo.data, mainLegalEntity.data]}
      entityError={user.error}
      state={dataState}
    >
      {([userData, userPrivateInfoData, mainLegalEntityData]: [
        UserFull,
        UserPrivateInfo | undefined,
        LegalEntity | undefined,
      ]) => (
        <Page
          title={intl.formatMessage(pageTitles.user)}
          actions={userData.isTerminated ? undefined : <UserPageActions isSelfUser={isSelfUser} user={userData} />}
          headerContent={userData.isTerminated && <InactiveUserCallout />}
        >
          <Helmet
            title={intl.formatMessage(generalMessages.fullName, {
              firstName: userData.firstName,
              lastName: userData.lastName,
            })}
          />
          <Panel>
            <UserProfileLayout user={userData} privateInfo={userPrivateInfoData} isSelfUser={isSelfUser} showLegalInfo>
              <UserProfile
                user={userData}
                legalEntity={mainLegalEntityData}
                privateInfo={userPrivateInfoData}
                isSelfUser={isSelfUser}
              />
            </UserProfileLayout>
          </Panel>
          <JobPanel user={userData} />
          {canSeeJobContracts && <JobContractPanels user={userData} />}
          {githubDialog.isOpen && isSelfUser && userData.verifiedGithubLogin && (
            <Dialog
              ariaLabel={intl.formatMessage(messages.githubAriaLabel)}
              isOpen
              onClose={githubDialog.close}
              width='30rem'
            >
              <Dialog.Header>{intl.formatMessage(messages.githubDialogTitle)}</Dialog.Header>
              <Dialog.Body withDefaultPadding>
                {intl.formatMessage(messages.githubDialogText, {
                  githubLogin: userData.verifiedGithubLogin,
                  link: (newGithubLogin: React.ReactNode) => (
                    <StyledExternalLink href={buildUserGithubUrl(newGithubLogin as string)} colored>
                      {newGithubLogin}
                    </StyledExternalLink>
                  ),
                })}
              </Dialog.Body>
              <Dialog.Footer>
                <ButtonGroup spaced size='l'>
                  <Button kind='text' intent='neutral' onClick={githubDialog.close}>
                    {intl.formatMessage(messages.githubDialogCancelButton)}
                  </Button>
                  <Button kind='primary' intent='primary' onClick={githubDialog.close}>
                    {intl.formatMessage(messages.githubDialogSuccessButton)}
                  </Button>
                </ButtonGroup>
              </Dialog.Footer>
            </Dialog>
          )}
        </Page>
      )}
    </EntityPageStateHandler>
  );
}
