import {defineMessages} from 'react-intl';
import {NumberValidatorErrorCode} from './types';

export const numberErrorMessages = defineMessages<NumberValidatorErrorCode>({
  [NumberValidatorErrorCode.NUMBER_INVALID]: {
    id: 'validator-number-wrong',
    description: 'Validation error about provided value is not a number',
    defaultMessage: 'A number is expected',
  },
  [NumberValidatorErrorCode.NUMBER_STRICTLY_LESS_MIN]: {
    id: 'validator-number-strictly-less-min',
    description: 'Validation error about provided number is less than min value',
    defaultMessage: 'Number must be greater than or equal to {min, number}',
  },
  [NumberValidatorErrorCode.NUMBER_LESS_OR_EQUAL_MIN]: {
    id: 'validator-number-less-or-equal-min',
    description: 'Validation error about provided number is less than or equal to min value',
    defaultMessage: 'Number must be greater than {min, number}',
  },
  [NumberValidatorErrorCode.NUMBER_STRICTLY_GREATER_MAX]: {
    id: 'validator-number-strictly-greater-max',
    description: 'Validation error about provided number is greater than max value',
    defaultMessage: 'Number must be less than or equal to {max, number}',
  },
  [NumberValidatorErrorCode.NUMBER_GREATER_OR_EQUAL_MAX]: {
    id: 'validator-number-greater-or-equal-max',
    description: 'Validation error about provided number is greater than or equal to max value',
    defaultMessage: 'Number must be less than {max, number}',
  },
  [NumberValidatorErrorCode.NUMBER_NOT_INTEGER]: {
    id: 'validator-number-not-integer',
    description: 'Validation error about provided number is not integer',
    defaultMessage: 'Number must be an integer',
  },
  [NumberValidatorErrorCode.NUMBER_NOT_DIVISIBLE]: {
    id: 'validator-number-not-multiple',
    description: 'Validation error about provided number is not integer',
    defaultMessage: 'Number must be a multiple of {multiple, number}',
  },
  [NumberValidatorErrorCode.NUMBER_MAX_DECIMAL_PLACES]: {
    id: 'validator-number-max-decimal-places',
    description: 'Validation error about provided number contains more decimal places than allowed',
    defaultMessage: '{maxDecimalPlaces, number} decimal places are allowed',
  },
});
