export function createNumbersComparator<T>(
  direction: 'ascending' | 'descending',
  getNumber: (item: T) => number,
): (a: T, b: T) => number {
  return (a: T, b: T) => {
    const numberA = getNumber(a);
    const numberB = getNumber(b);

    return direction === 'ascending' ? numberA - numberB : numberB - numberA;
  };
}
