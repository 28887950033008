import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Import',
    description: '[title] Financial records upload panel title',
  },
  annotation: {
    defaultMessage:
      'In all the replaced rows we will fill the End date field with the date preceding the Start date of the new record.',
    description: 'Financial records upload annotation',
  },
  success: {
    defaultMessage:
      'Your file has been successfully parsed. Check the result below and apply changes if everything is OK.',
    description: 'Financial records upload success message',
  },
});

export const errorMessages = defineMessages({
  wrongFileType: {
    defaultMessage: 'This file type is not supported',
    description: 'Financial records upload error',
  },
  unknownError: {
    defaultMessage: 'File import failed',
    description: 'Financial records upload error',
  },
  invalidRows: {
    defaultMessage: 'This file has some invalid rows',
    description: 'Financial records upload error',
  },
  noRows: {
    defaultMessage: 'This file has no rows',
    description: 'Financial records upload error',
  },
  alreadyApplied: {
    defaultMessage: 'This import has already been applied',
    description: 'Financial records upload error',
  },
});
