import {defineMessages} from 'react-intl';
import {ImageValidatorErrorCode} from './types';

export const imageErrorMessages = defineMessages<ImageValidatorErrorCode>({
  [ImageValidatorErrorCode.IMAGE_INVALID]: {
    id: 'validator-image-invalid',
    description: 'Validation error about provided value is not an image',
    defaultMessage: 'Invalid image',
  },
  [ImageValidatorErrorCode.IMAGE_IS_TOO_BIG]: {
    id: 'validator-image-too-big',
    description: 'Validation error about image’s width or height is greater than expected',
    defaultMessage: 'Image must not be greater than {maxWidth}×{maxHeight} pixels in size',
  },
  [ImageValidatorErrorCode.IMAGE_IS_TOO_SMALL]: {
    id: 'validator-image-too-small',
    description: 'Validation error about image’s width or height is smaller than expected',
    defaultMessage: 'Image must be at least {minWidth}×{minHeight} pixels in size',
  },
  [ImageValidatorErrorCode.IMAGE_ASPECT_RATIO_TOO_BIG]: {
    id: 'validator-image-ratio-too-big',
    description: 'Validation error about image’s width and height ratio is wrong',
    defaultMessage: 'Image aspect ratio must not be greater than {maxAspectRatio, number}',
  },
  [ImageValidatorErrorCode.IMAGE_ASPECT_RATIO_TOO_SMALL]: {
    id: 'validator-image-ratio-too-small',
    description: 'Validation error about image’s width and height ratio is wrong',
    defaultMessage: 'Image aspect ratio must not be less than {minAspectRatio, number}',
  },
});
