import {oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';

export enum EmploymentType {
  CONTRACTOR = 'contractor',
  FULL_TIME = 'fullTime',
  INTERN = 'intern',
  PART_TIME = 'partTime',
  TEMPORARY = 'temporary',
}

export const employmentTypeSchema = oneOfEnum(EmploymentType);
