import {teamsApi} from 'domain/team/api';
import {
  changeParentFx,
  createTeamFx,
  deleteTeamFx,
  getAllTeamsFx,
  requestMembershipFx,
  requestOutstaffMembershipFx,
  revokeMembershipFx,
  revokeOutstaffMembershipFx,
  updateTeamFx,
} from '.';

createTeamFx.use(teamsApi.create);
deleteTeamFx.use(teamsApi.delete);
getAllTeamsFx.use(teamsApi.getAll);
requestMembershipFx.use(teamsApi.requestMembership);
requestOutstaffMembershipFx.use(teamsApi.requestOutstaffMembership);
revokeMembershipFx.use(teamsApi.revokeMembership);
revokeOutstaffMembershipFx.use(teamsApi.revokeOutstaffMembership);
updateTeamFx.use(teamsApi.update);
changeParentFx.use(teamsApi.changeParent);
