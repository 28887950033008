import {SalaryRange} from 'domain/salaryRange/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  salaryRange: SalaryRange;
};

export function SalaryRange({salaryRange}: Props) {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <div className={styles.value}>
          {intl.formatMessage(messages.value, {
            value: intl.formatNumber(salaryRange.minAmount),
            currency: salaryRange.currency,
          })}
        </div>
        <div className={styles.label}>{intl.formatMessage(messages.labelMin)}</div>
      </div>
      <div className={styles.divider}>
        <div className={styles.border} />
      </div>
      <div className={styles.section}>
        <div className={styles.value}>
          {intl.formatMessage(messages.value, {
            value: intl.formatNumber(salaryRange.targetAmount),
            currency: salaryRange.currency,
          })}
        </div>
        <div className={styles.label}>{intl.formatMessage(messages.labelTarget)}</div>
      </div>
      <div className={styles.divider}>
        <div className={styles.border} />
      </div>
      <div className={styles.section}>
        <div className={styles.value}>
          {intl.formatMessage(messages.value, {
            value: intl.formatNumber(salaryRange.maxAmount),
            currency: salaryRange.currency,
          })}
        </div>
        <div className={styles.label}>{intl.formatMessage(messages.labelMax)}</div>
      </div>
    </div>
  );
}
