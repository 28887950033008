import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {formattedDateSchema} from 'models/system/formattedDate';

export const stockOptionAgreementFreezeIdSchema = idSchema;
export const stockOptionAgreementFreezeSchema = object({
  id: stockOptionAgreementFreezeIdSchema,
  startDate: formattedDateSchema,
  endDate: optional(formattedDateSchema),
});

export type StockOptionAgreementFreezeId = ExtractSchemaType<typeof stockOptionAgreementFreezeIdSchema>;
export type StockOptionAgreementFreeze = ExtractSchemaType<typeof stockOptionAgreementFreezeSchema>;
