import React from 'react';
import {useIntl} from 'react-intl';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {type Mark, type Locator, createLocator} from '../../create-locator';
import {Button} from '../../Button';
import {ButtonGroup} from '../../ButtonGroup';
import {ButtonIntent, type ButtonLocator} from '../../Button/types';
import {Dialog, type DialogBodyLocator, type DialogLocator} from '../../Dialog';
import styles from './index.css';
import {messages} from './messages';
import {promisify} from './promisify';

const noop = () => {};

export type ConfirmationDialogLocator = Locator<{
  dialog: DialogLocator;
  dialogBody: DialogBodyLocator;
  declineButton: ButtonLocator;
  acceptButton: ButtonLocator;
}>;

export type ConfirmationDialogProps = {
  ariaLabel?: string;
  afterClose?: () => void;
  confirmationText?: React.ReactNode;
  intent?: ButtonIntent;
  denialText?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<unknown> | unknown;
  text?: React.ReactNode;
  title: React.ReactNode;
  width?: string | number | undefined;
} & Partial<Mark<ConfirmationDialogLocator>>;

export function ConfirmationDialog({
  ariaLabel,
  afterClose,
  confirmationText,
  intent = 'primary',
  denialText,
  isOpen,
  onClose,
  onConfirm,
  text,
  title,
  width = '27rem',
  ...restProps
}: ConfirmationDialogProps) {
  const intl = useIntl();
  const locator = createLocator(restProps);
  const confirmationTask = useAsyncTask(promisify(onConfirm), [onConfirm]);
  const closeDialog = confirmationTask.isPerforming ? noop : onClose;

  return (
    <Dialog
      afterClose={afterClose}
      ariaLabel={ariaLabel ?? intl.formatMessage(messages.ariaLabel)}
      isOpen={isOpen}
      onClose={closeDialog}
      width={width}
      {...locator.dialog()}
    >
      <Dialog.Body {...locator.dialogBody()}>
        <div className={styles.body}>
          <h2 className={styles.title}>{title}</h2>
          {text ? <p className={styles.text}>{text}</p> : null}
          <div className={styles.actions}>
            <ButtonGroup align='center' spaced size='m'>
              <Button
                kind='secondary'
                intent='neutral'
                disabled={confirmationTask.isPerforming}
                onClick={closeDialog}
                {...locator.declineButton()}
              >
                {denialText ?? intl.formatMessage(messages.denialText)}
              </Button>

              <Button
                kind='primary'
                intent={intent}
                loading={confirmationTask.isPerforming}
                onClick={confirmationTask.perform}
                {...locator.acceptButton()}
              >
                {confirmationText ?? intl.formatMessage(messages.confirmationText)}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Dialog.Body>
    </Dialog>
  );
}
