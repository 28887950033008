import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {ReactNode} from 'react';
import {HumanDate, HumanDateTime} from '../../../HumanDate';
import {TooltipAlt} from '../../../TooltipAlt';
import {CellEmpty} from '../../components/CellEmpty';
import {Column} from '../../types';
import styles from './index.css';
import {Nullable} from '../types';

type DateType = Date | number | string;

type Options<Item, NestedItem = never> = Omit<Column<Item, NestedItem>, 'render' | 'renderNested'> & {
  getValue: (item: Item) => Nullable<DateType>;
  render?: (item: Item, formattedValue: React.ReactNode) => React.ReactNode;
  getNestedValue?: (nestedItem: NestedItem) => Nullable<DateType>;
  renderNested?: (nestedItem: NestedItem, item: Item, formattedValue: ReactNode) => ReactNode;
};

function formatDate(date: DateType): ReactNode {
  return (
    <TooltipAlt content={<HumanDateTime value={date} format='long' />} placement='top'>
      <span className={styles.date}>
        <HumanDate value={date} format='short' />
      </span>
    </TooltipAlt>
  );
}

export function createColumnDate<Item, NestedItem>({
  getNestedValue,
  getValue,
  render,
  renderNested,
  ...rest
}: Options<Item, NestedItem>): Column<Item, NestedItem> {
  return {
    align: 'center',

    render: (item) => {
      const value = getValue(item);

      if (isNotNullish(value)) {
        return render ? render(item, formatDate(value)) : formatDate(value);
      }
      return <CellEmpty />;
    },

    renderNested: (nestedItem, item) => {
      const value = getNestedValue?.(nestedItem);

      if (isNotNullish(value)) {
        return renderNested ? renderNested(nestedItem, item, formatDate(value)) : formatDate(value);
      }
      return <CellEmpty />;
    },

    ...rest,
  };
}
