import {array, ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {CountryCode} from 'domain/countryCode/model';
import {currencySchema} from 'domain/currency/model';
import {LevelCode} from 'domain/levelCode/model';

export const salaryRangesSearchFiltersSchema = object({
  currency: optional(array(currencySchema)),
  groupOfFunctions: optional(array(string())),
  levelCode: optional(array(oneOfEnum(LevelCode))),
  region: optional(array(oneOfEnum(CountryCode))),
  userFunction: optional(array(string())),
});

export type SalaryRangesSearchFilters = Partial<ExtractSchemaType<typeof salaryRangesSearchFiltersSchema>>;
