import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  download: {
    description: 'Hint for file pane’s download button (uses by screen readers)',
    defaultMessage: 'Download file',
  },
  remove: {
    id: 'file-pane-close',
    description: "Hint for file pane's close button (uses by screen readers)",
    defaultMessage: 'Remove file',
  },
  attachFileProgress: {
    description: 'File pane attach file progress',
    defaultMessage: 'Attaching file — {progress}',
  },
});
