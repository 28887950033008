import {ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {stockOptionBulkUploadMetaSchema} from 'domain/stockOption/bulkUpload/model/meta';
import {stockOptionAgreementSchema} from '.';

export const stockOptionAgreementRecordSchema = object({
  agreement: optional(stockOptionAgreementSchema),
  meta: stockOptionBulkUploadMetaSchema,
});

export type StockOptionAgreementRecord = ExtractSchemaType<typeof stockOptionAgreementRecordSchema>;
