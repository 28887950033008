import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {Pagination} from '@joomcode/joom-ui/Pagination';
import {JdmOwnersList} from 'components/widgets/JdmOwnersList';
import {Resource} from 'domain/resource/model';
import {ColumnId} from 'domain/resource/model/table/columnId';
import {columnNames} from 'domain/resource/model/table/messages';
import {ResourceLink} from 'domain/resource/widgets/Link';
import {ResourceStatusBadge} from 'domain/resource/widgets/StatusBadge';
import {ServiceLink} from 'domain/service/widgets/Link';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type ResourceColumn = Column<Resource> & {id: ColumnId};
type Props = {
  resources: Resource[];
  dataState: DataState;
  pagination: OffsetPagination;
  count: number;
  columnIds: ColumnId[];
};

const getRowKey = ({id}: Resource) => id;

export function ResourceTable({resources, dataState, pagination, count, columnIds}: Props) {
  const intl = useIntl();
  const columns = useMemo(() => {
    const allColumns: ResourceColumn[] = [
      {
        defaultWidth: 6,
        id: ColumnId.SERVICE,
        name: intl.formatMessage(columnNames[ColumnId.SERVICE]),
        render: ({service}) => <ServiceLink service={service} />,
      },
      {
        defaultWidth: 6,
        id: ColumnId.DISPLAY_NAME,
        name: intl.formatMessage(columnNames[ColumnId.DISPLAY_NAME]),
        render: (resource) => <ResourceLink resource={resource} />,
      },
      {
        defaultWidth: 6,
        id: ColumnId.VALUE,
        name: intl.formatMessage(columnNames[ColumnId.VALUE]),
        render: ({value}) => value,
      },
      {
        defaultWidth: 4,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({status}) => <ResourceStatusBadge status={status} />,
      },
      {
        defaultWidth: 10,
        id: ColumnId.OWNERS,
        name: intl.formatMessage(columnNames[ColumnId.OWNERS]),
        render: ({owners, owningMemberships}) =>
          owners.length > 0 || owningMemberships.length > 0 ? (
            <JdmOwnersList owners={owners} owningMemberships={owningMemberships} />
          ) : (
            <DataTable.CellEmpty />
          ),
      },
    ];

    return allColumns.filter(({id}) => columnIds.includes(id));
  }, [intl, columnIds]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={resources}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowKey={getRowKey}
      storageName='resource.list'
      toolbarBottom={
        <Pagination
          disabled={dataState === DataState.LOADING}
          limit={pagination.limit}
          onLimitChange={pagination.setLimit}
          onPageChange={pagination.setPage}
          page={pagination.page}
          pagesCount={Math.ceil(count / pagination.limit)}
        />
      }
    />
  );
}
