import {Panel} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {LegalInfoForm, LegalInfoFormState} from 'domain/legalInfo/widgets/Form';
import {Permission} from 'domain/permission/model';
import {useUserLegalInfo} from 'domain/user/hooks/useUserLegalInfo';
import {useUserLoader} from 'domain/user/hooks/useUserLoader';
import {setUserLegalInfoFx} from 'domain/user/stores/legalInfo';
import {EditPageTabs} from 'domain/user/widgets/EditPageTabs';
import {generalMessages} from 'i18n/messages/general';
import {NotFoundPage} from 'pages/NotFound';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps, useHistory, useParams} from 'react-router-dom';
import {pageTitles} from 'routes/users/titles';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

export function UserEditLegalInfoPage({match}: RouteComponentProps) {
  const acl = useAcl();
  const intl = useIntl();
  const history = useHistory();
  const {login} = useParams<{login: string}>();

  const {data: userData, error: userError, dataState: userDataState} = useUserLoader(login);
  const {data, dataState} = useUserLegalInfo(userData?.id);

  const onSubmit = useCallback(
    async ({legalInfo}: LegalInfoFormState) => {
      if (!userData || !legalInfo) {
        toaster.error(intl.formatMessage(generalMessages.partiallyFilledError));
        return;
      }
      await setUserLegalInfoFx({userId: userData.id, legalInfo})
        .then(() => {
          toaster.success(intl.formatMessage(messages.success));
          history.push(usersUrls.user({login: userData.login}));
        })
        .catch(toaster.interceptThenThrowError);
    },
    [history, userData],
  );

  if (userData?.isTerminated || !acl.hasPermission(Permission.USER_LEGAL_INFO_WRITE)) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={userData} entityError={userError} state={userDataState}>
      {(user) => {
        return (
          <Page
            title={intl.formatMessage(pageTitles.userEditLegalInfo)}
            breadcrumbs={[
              {
                title: intl.formatMessage(generalMessages.fullName, {
                  firstName: user.firstName,
                  lastName: user.lastName,
                }),
                href: usersUrls.user({login}),
              },
            ]}
            headerContent={<EditPageTabs login={login} userId={user.id} matchedPathPattern={match.path} />}
          >
            <Panel className={styles.panel}>
              <StateHandler state={dataState} data={data}>
                {({legalInfo}) => (
                  <LegalInfoForm
                    onSubmit={onSubmit}
                    legalInfoData={legalInfo ?? undefined}
                    cancelUrl={usersUrls.user({login})}
                  >
                    {({internationalPassportFields, localIdFields, legalAddressFields, nameAndGenderFields}) => (
                      <>
                        <Panel.Header title={intl.formatMessage(messages.personalData)} withSeparator />
                        <Panel.Content withPadding>
                          <div className={styles.form}>{nameAndGenderFields}</div>
                        </Panel.Content>
                        <Panel.Header title={intl.formatMessage(messages.internationalPassport)} withSeparator />
                        <Panel.Content withPadding>
                          <div className={styles.form}>{internationalPassportFields}</div>
                        </Panel.Content>
                        <Panel.Header title={intl.formatMessage(messages.localId)} withSeparator />
                        <Panel.Content withPadding>
                          <div className={styles.form}>{localIdFields}</div>
                        </Panel.Content>
                        <Panel.Header title={intl.formatMessage(messages.legalAddress)} withSeparator />
                        <Panel.Content withPadding>
                          <div className={styles.form}>{legalAddressFields}</div>
                        </Panel.Content>
                      </>
                    )}
                  </LegalInfoForm>
                )}
              </StateHandler>
            </Panel>
          </Page>
        );
      }}
    </EntityPageStateHandler>
  );
}
