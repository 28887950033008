import {intranetApi} from 'apiClient';
import {RegularBonusRecordId} from 'domain/regularBonusRecord/model';
import {UserId} from 'domain/user/model';

export type DeleteRegularBonusRecordParams = {
  id: RegularBonusRecordId;
  userId: UserId;
};

export const deleteRecord = (params: DeleteRegularBonusRecordParams): Promise<void> => {
  return intranetApi.post('/v1/secure/users/regularBonusRecords/delete', undefined, {params});
};
