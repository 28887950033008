import Downshift, {
  DownshiftState,
  StateChangeOptions,
  GetItemPropsOptions,
  GetInputPropsOptions,
  ControllerStateAndHelpers,
} from 'downshift';
import React, {useRef, useState, useCallback, ComponentPropsWithoutRef, useEffect} from 'react';
import {createLocator, type Locator, type Mark} from '../../create-locator';
import {Listbox} from '../../Listbox';
import {VisuallyHidden} from '../../VisuallyHidden';
import {Dropdown, Placement} from '../Dropdown';
import styles from './index.css';

export type SelectCoreLocator = Locator<void>;

export type DropdownPlacement = Placement;

type Props<Item> = Partial<Mark<SelectCoreLocator>> & {
  inputRef?: React.RefObject<HTMLInputElement>;
  inputValue?: string;
  disabled?: boolean;
  closeOnSelect: boolean;
  clearValueOnSelect?: boolean;
  preview?: React.ReactNode;
  renderControl: (props: {
    getInputProps: (
      options?: JSX.IntrinsicElements['input'],
    ) => GetInputPropsOptions & {ref: React.RefObject<HTMLInputElement>};
    selectedItem: Item | null;
    openMenu: () => void;
    isActive: boolean;
    isOpen: boolean;
    disabled: boolean;
    clearSelection: () => void;
  }) => React.ReactNode;
  renderItems: (props: {
    highlightedIndex: number | null;
    getItemProps: (options: GetItemPropsOptions<Item>) => ComponentPropsWithoutRef<'button'>;
    selectedItem: Item | null;
    inputValue: string | null;
    skipFilter?: boolean;
    closeDropdown(): void;
  }) => React.ReactNode;
  value: Item | null;
  onChange: (selectedItem: Item | null, stateAndHelpers: ControllerStateAndHelpers<Item>) => void;
  onInputValueChange?: (inputValue: string) => void;
  itemToString: (item: Item | null) => string;
  withInput: boolean;
  dropdownMinWidth?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  name?: string;
  id?: string;
  autoComplete?: string | null;
  dropdownPlacement?: DropdownPlacement;
};

type DownshiftInstance<Item> = Omit<Downshift<Item>, 'setState'> & {
  state: DownshiftState<Item>;
  setState(state: Partial<DownshiftState<Item>>): void;
};

export function SelectCore<Item>({
  value,
  onChange,
  disabled = false,
  inputRef = React.createRef(),
  inputValue,
  onInputValueChange,
  preview,
  renderControl,
  renderItems,
  closeOnSelect,
  clearValueOnSelect = false,
  itemToString,
  withInput,
  dropdownMinWidth,
  onFocus,
  onBlur,
  name,
  id,
  dropdownPlacement,
  autoComplete = 'off',
  ...restProperties
}: Props<Item>) {
  const locator = createLocator(restProperties);
  const buttonRef = useRef<HTMLInputElement>(null);
  const downshiftRef = useRef<DownshiftInstance<Item>>(null);
  const [active, setActive] = useState(false);

  // Fix to update `inputValue` after `itemToString` changed
  useEffect(() => {
    const downshift = downshiftRef.current;
    const newInputValue = itemToString(value);

    if (downshift && newInputValue !== downshift.state.inputValue) {
      downshift.setState({inputValue: newInputValue});
    }
  }, [itemToString]);

  const stateReducer = useCallback(
    (state: DownshiftState<Item>, changes: StateChangeOptions<Item>) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.keyDownEnter:
        case Downshift.stateChangeTypes.clickItem:
          return {
            ...changes,
            highlightedIndex: closeOnSelect ? changes.highlightedIndex : state.highlightedIndex,
            isOpen: !closeOnSelect,
            inputValue: clearValueOnSelect ? '' : changes.inputValue,
          };
        case Downshift.stateChangeTypes.keyDownEscape:
          return {
            ...changes,
            inputValue: itemToString(state.selectedItem),
            selectedItem: state.selectedItem,
          };
        default:
          return changes;
      }
    },
    [closeOnSelect, clearValueOnSelect],
  );

  return (
    <Downshift
      ref={downshiftRef}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
      itemToString={itemToString}
      onChange={onChange}
      selectedItem={value}
      stateReducer={stateReducer}
    >
      {({
        toggleMenu,
        isOpen,
        getMenuProps,
        getInputProps,
        selectedItem,
        highlightedIndex,
        getItemProps,
        inputValue, // eslint-disable-line @typescript-eslint/no-shadow
        openMenu,
        getToggleButtonProps,
        clearSelection,
      }) => (
        <div className={styles.container} {...locator()}>
          <Dropdown
            isOpen={isOpen}
            minWidth={dropdownMinWidth}
            getContentContainerProps={() => getMenuProps({}, {suppressRefError: true})}
            placement={dropdownPlacement}
            content={
              <Listbox>
                {isOpen &&
                  renderItems({
                    highlightedIndex,
                    getItemProps,
                    selectedItem,
                    inputValue,
                    skipFilter: inputValue === itemToString(selectedItem),
                    closeDropdown: () => toggleMenu({isOpen: false}),
                  })}
              </Listbox>
            }
          >
            <div // eslint-disable-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              onClick={(e) => {
                if (!disabled && e.target !== buttonRef.current) {
                  toggleMenu();
                }

                if (withInput && !isOpen && inputRef.current) {
                  inputRef.current.focus();
                }

                if (!withInput && !isOpen && buttonRef.current) {
                  buttonRef.current.focus();
                }
              }}
            >
              {!withInput && (
                <VisuallyHidden>
                  <button
                    id={id}
                    type='button'
                    ref={buttonRef}
                    {...getToggleButtonProps({
                      name,
                      disabled,
                      onFocus: () => {
                        setActive(true);
                        if (onFocus) {
                          onFocus();
                        }
                      },
                      onBlur: () => {
                        setActive(false);
                        if (onBlur) {
                          onBlur();
                        }
                      },
                    })}
                  />
                </VisuallyHidden>
              )}
              {renderControl({
                getInputProps: (rest?: JSX.IntrinsicElements['input']) =>
                  getInputProps({
                    ...rest,
                    autoComplete,
                    ref: inputRef,
                    onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
                      setActive(true);
                      if (onFocus) {
                        onFocus();
                      }
                      if (rest && rest.onFocus) {
                        rest.onFocus(e);
                      }
                    },
                    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                      setActive(false);
                      if (onBlur) {
                        onBlur();
                      }
                      if (rest && rest.onBlur) {
                        rest.onBlur(e);
                      }
                    },
                    disabled: !withInput || disabled,
                    name,
                  }),
                selectedItem,
                openMenu,
                isActive: active,
                isOpen,
                disabled,
                clearSelection,
              })}
            </div>
          </Dropdown>
          {preview}
        </div>
      )}
    </Downshift>
  );
}
