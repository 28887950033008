import React from 'react';
import styles from './index.css';

type Props = Omit<JSX.IntrinsicElements['div'], 'className'>;

export const GroupComponent = React.forwardRef<HTMLDivElement, Props>(function Group(props: Props, ref) {
  return (
    <div ref={ref} role='group' className={styles.group}>
      {props.children}
    </div>
  );
});
