import {Panel} from '@joomcode/joom-ui/Panel';
import {useAllStockOptionHolders} from 'domain/stockOption/holder/hooks/useAllStockOptionHolders';
import {
  AllStockOptionHoldersFilters,
  allStockOptionHoldersFiltersSchema,
} from 'domain/stockOption/holder/model/filters';
import {AllStockOptionHoldersDataFilters} from 'domain/stockOption/holder/widgets/AllHoldersDataFilters';
import {StockOptionHoldersTable} from 'domain/stockOption/holder/widgets/Table';
import {useStoredFilters} from 'hooks/useStoredFilters';
import React from 'react';

export function AllStockOptionHoldersList() {
  const {filterValues, setFilterValues} = useStoredFilters<AllStockOptionHoldersFilters>({
    id: 'stockOption.allHolders.filters',
    initialValues: {},
    valuesSchema: allStockOptionHoldersFiltersSchema,
  });
  const {stockOptionHolders, stockOptionHoldersCount, dataState, pagination} = useAllStockOptionHolders(filterValues);

  return (
    <>
      <Panel>
        <AllStockOptionHoldersDataFilters values={filterValues} onChange={setFilterValues} />
      </Panel>
      <Panel>
        <StockOptionHoldersTable
          holders={stockOptionHolders}
          count={stockOptionHoldersCount}
          dataState={dataState}
          pagination={pagination}
          storageName='stockOption.allHolders.list'
          showEmploymentStatus
        />
      </Panel>
    </>
  );
}
