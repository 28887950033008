import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {
  array,
  ExtractSchemaType,
  object,
  oneOfEnum,
  string,
  successesArray,
} from '@joomcode/deprecated-utils/jsonValidation';
import {emojiLandmarkSchema} from 'domain/officeMap/landmark/model';
import {officeRoomSchema} from 'domain/officeMap/room/model';
import {officeSeatSchema} from 'domain/officeMap/seat/model';
import {OfficeAlias} from './alias';

export const officeIdSchema = idSchema;

export const officeSchema = object({
  id: officeIdSchema,
  name: string(),
  alias: oneOfEnum(OfficeAlias),
  seats: array(officeSeatSchema),
  landmarks: successesArray(emojiLandmarkSchema),
  rooms: array(officeRoomSchema),
});

export type OfficeId = ExtractSchemaType<typeof officeIdSchema>;
export type Office = ExtractSchemaType<typeof officeSchema>;
