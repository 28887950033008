import {ReactComponent as CrossIcon} from '@joomcode/joom-ui/icons/core/cross.svg';
import {Role} from 'domain/role/model';
import React from 'react';
import styles from './styles.css';

type Props = {
  onRemove?: () => void;
  removeTooltip?: string;
  role: Role;
};

export function RoleBadge({onRemove, removeTooltip, role}: Props) {
  return (
    <div className={styles.root}>
      <span className={styles.role}>
        {role.name}
        {onRemove && (
          <button
            className={styles.removeButton}
            type='button'
            onClick={onRemove}
            title={removeTooltip}
            aria-label={removeTooltip}
          >
            <CrossIcon />
          </button>
        )}
      </span>
    </div>
  );
}
