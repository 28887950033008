import {defineMessages} from 'react-intl';
import {IdValidatorErrorCode} from './types';

export const idErrorMessages = defineMessages<IdValidatorErrorCode>({
  [IdValidatorErrorCode.ID_INVALID]: {
    id: 'validator-id-invalid',
    description: 'Validation error about provided id is invalid',
    defaultMessage: 'Invalid value',
  },
  [IdValidatorErrorCode.ID_INVALID_FORMAT]: {
    id: 'validator-id-invalid',
    description: 'Validation error about provided id is invalid',
    defaultMessage: 'Invalid value',
  },
});
