import {
  boolean,
  ExtractSchemaType,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {Frequency} from 'domain/frequency/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';
import {RegularBonusType} from './type';
import {regularBonusValueSchema} from './value';

export const regularBonusRecordIdSchema = idSchema;

export const regularBonusRecordSchema = object({
  active: boolean(),
  comment: optional(string()),
  endDate: optional(formattedDateSchema),
  frequency: oneOfEnum(Frequency),
  id: regularBonusRecordIdSchema,
  startDate: formattedDateSchema,
  value: regularBonusValueSchema,
}).map((record) => ({
  ...record,
  type: record.value.absoluteValue ? RegularBonusType.ABSOLUTE : RegularBonusType.PERCENT,
}));

export type RegularBonusRecordId = ExtractSchemaType<typeof regularBonusRecordIdSchema>;
export type RegularBonusRecord = ExtractSchemaType<typeof regularBonusRecordSchema>;
