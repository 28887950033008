import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  services: {
    defaultMessage: 'JDM Services',
    description: '[title] Page title',
  },
  resources: {
    defaultMessage: 'Resources',
    description: '[title] Page title',
  },
});
