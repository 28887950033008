import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CommonFieldProps, getFieldValidator} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Select} from '@joomcode/joom-ui/Select';
import {formatFurtherSalaryReason} from 'domain/offer/model/furtherSalary/formatter';
import {FurtherSalaryReason} from 'domain/offer/model/furtherSalary/reason';
import React, {useCallback} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

export type Props = CommonFieldProps<FurtherSalaryReason>;

export function FieldFurtherSalaryReason({
  name,
  label,
  hint,
  required,
  disabled,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const reasonToString = useCallback((reason: FurtherSalaryReason) => formatFurtherSalaryReason(reason, intl), [intl]);

  return (
    <Field<FurtherSalaryReason>
      name={name}
      validate={validate && getFieldValidator(validate)}
      initialValue={initialValue}
    >
      {({input, meta}) => (
        <FormControl
          hint={hint}
          label={label}
          disabled={meta.submitting || disabled}
          error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
          required={required}
          reserveSpaceForError={reserveSpaceForError}
        >
          {(formControlProps) => (
            <Select<FurtherSalaryReason>
              items={getEnumValues(FurtherSalaryReason)}
              getItemKey={identity}
              itemToString={reasonToString}
              renderItem={reasonToString}
              {...formControlProps}
              {...input}
              {...restProps}
              disabled={meta.submitting || disabled}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
}
