import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import {SecurePermission} from 'domain/permission/model/secure';
import {stockOptionAgreementApi} from 'domain/stockOption/agreement/api';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {useUserLegalInfo} from 'domain/user/hooks/useUserLegalInfo';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

type Props = {
  agreement: StockOptionAgreement;
};

export function DownloadStockOptionAgreementButton({agreement}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {data: legalInfoData} = useUserLegalInfo(agreement.user.id);
  const legalInfoEmpty = !legalInfoData?.legalInfo;

  if (!acl.hasSecurePermission(SecurePermission.STOCK_OPTION_DOWNLOAD_ANY)) {
    return null;
  }

  return (
    <TooltipAlt
      placement='top'
      content={intl.formatMessage(legalInfoEmpty ? messages.noLegalInfoHint : messages.buttonHint)}
    >
      {/* extra wrap fixes tippy for disabled buttons */}
      <span>
        <DownloadLinkButton
          size='m'
          kind='text'
          intent='neutral'
          disabled={legalInfoEmpty}
          href={stockOptionAgreementApi.buildDownloadLink({id: agreement.id, userId: agreement.user.id})}
          iconLeft={<DownloadIcon />}
          target='_blank'
        />
      </span>
    </TooltipAlt>
  );
}
