import {JsonSerializer, LocalStorage, SingleStorageManager} from '@joomcode/deprecated-utils/Storage';
import {StoredFeatures, storedFeaturesSchema} from './model';

const featureStorage = new SingleStorageManager(
  'features',
  new LocalStorage(),
  new JsonSerializer(storedFeaturesSchema),
);

export function getStoredFeatures(): StoredFeatures {
  const parsedValue = storedFeaturesSchema.run(featureStorage.restore());
  return parsedValue.ok ? parsedValue.result : ({} as StoredFeatures);
}

export function storeFeatures(features: StoredFeatures) {
  featureStorage.store(features);
}
