import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {RegularBonusRecord, regularBonusRecordSchema} from 'domain/regularBonusRecord/model';
import {RegularBonusRecordDiff} from 'domain/regularBonusRecord/model/diff';
import {UserId} from 'domain/user/model';

export type CreateRegularBonusRecordConfig = {
  userId: UserId;
  diff: RegularBonusRecordDiff;
};

export const create = async ({diff, ...params}: CreateRegularBonusRecordConfig): Promise<RegularBonusRecord[]> => {
  const {data} = await intranetApi.post('/v1/secure/users/regularBonusRecords/create', diff, {params});

  return array(regularBonusRecordSchema).runWithException(data);
};
