import {Grid} from '@joomcode/joom-ui/Grid';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {useFieldWithInitialValue} from '../../components';
import {messages} from './messages';
import {bothFilled, bothFilledOrEmpty} from './validators';
import {CommonFieldProps} from '../types';
import {getFieldErrorText} from '../../utils';
import {getFieldValidator, composeValidators} from '../../validation';
import {FieldCurrency} from '../FieldCurrency';
import {FieldInputNumber} from '../FieldInputNumber';

export type FieldPriceProps = CommonFieldProps<Record<string, unknown>> & {
  nameAmount?: string;
  nameCurrency?: string;
  currencies?: string[];
  allowNegative?: boolean;
};

export const FieldPrice = memo(function FieldPrice({
  hint,
  label,
  labelHint,
  name,
  required,
  disabled,
  error,
  initialValue,
  validateFields,
  reserveSpaceForError,
  currencies,
  nameAmount = 'amount',
  nameCurrency = 'currency',
  allowNegative = true,
  validate: validateCustom,
}: FieldPriceProps) {
  const validateDefault = required ? bothFilled(nameAmount, nameCurrency) : bothFilledOrEmpty(nameAmount, nameCurrency);
  const validate = getFieldValidator(
    validateCustom ? composeValidators(validateDefault, validateCustom) : validateDefault,
  );
  const fieldAmountName = `${name}.${nameAmount}`;
  const fieldCurrencyName = `${name}.${nameCurrency}`;

  const intl = useIntl();
  const {meta} = useFieldWithInitialValue(name, {
    validate,
    initialValue,
    validateFields,
  });
  const fieldAmount = useFieldWithInitialValue(fieldAmountName, {subscription: {touched: true}});
  const fieldCurrency = useFieldWithInitialValue(fieldCurrencyName, {subscription: {touched: true}});
  const touched = fieldAmount.meta.touched || fieldCurrency.meta.touched;
  const isDisabled = disabled || meta.submitting;

  return (
    <FormControl
      hint={hint}
      label={label}
      labelHint={labelHint}
      disabled={isDisabled}
      error={error || getFieldErrorText({...meta, touched}, {intl, extraMessages: messages})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <Grid>
          <Grid.Item xl={6}>
            <FieldInputNumber
              disabled={isDisabled}
              name={fieldAmountName}
              validateFields={[name]}
              reserveSpaceForError={false}
              id={formControlProps.id}
              min={allowNegative ? undefined : 0}
            />
          </Grid.Item>

          <Grid.Item xl={6}>
            <FieldCurrency
              disabled={isDisabled}
              currencies={currencies}
              label=''
              name={fieldCurrencyName}
              validateFields={[name]}
              reserveSpaceForError={false}
              clearable={!required}
            />
          </Grid.Item>
        </Grid>
      )}
    </FormControl>
  );
});
