import {StockOptionBulkUploadInput, StockOptionBulkUploadResult} from 'domain/stockOption/bulkUpload/model';

export function convertBulkUploadResult(result: StockOptionBulkUploadResult): StockOptionBulkUploadInput | undefined {
  if (result.agreementRecords?.some(({agreement}) => !agreement) || result.buyoutRecords?.some(({buyout}) => !buyout)) {
    return undefined;
  }

  return {
    id: result.id,
    agreementRecords: result.agreementRecords?.map(({agreement}) => agreement!),
    buyoutRecords: result.buyoutRecords?.map(({buyout}) => buyout!),
  };
}
