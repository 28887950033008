import {ListedProperty} from 'components/ui/ListedProperty';
import {Section} from 'components/ui/Section';
import {HrOnlyHint} from 'components/widgets/HrOnlyHint';
import {EmergencyContact} from 'domain/emergencyContact/model';
import {formatPhone} from 'models/system/phone';
import React from 'react';
import {useIntl} from 'react-intl';
import {labels} from './messages';

export type Props = {
  emergencyContact: EmergencyContact;
  title: string;
};

export function EmergencyContactSection({emergencyContact, title}: Props) {
  const intl = useIntl();
  return (
    <Section
      title={
        <>
          {title}
          <HrOnlyHint />
        </>
      }
      bordered
    >
      {emergencyContact.name && (
        <ListedProperty label={intl.formatMessage(labels.name)}>{emergencyContact.name}</ListedProperty>
      )}
      {emergencyContact.phone && (
        <ListedProperty label={intl.formatMessage(labels.phone)}>{formatPhone(emergencyContact.phone)}</ListedProperty>
      )}
      {emergencyContact.relationship && (
        <ListedProperty label={intl.formatMessage(labels.relationship)}>{emergencyContact.relationship}</ListedProperty>
      )}
    </Section>
  );
}
