import cn from 'classnames';
import React, {ReactNode} from 'react';
import {ReactComponent as CloseIcon} from '../../../icons/core/cross.svg';
import {SelectionState} from '../../types';
import styles from './index.css';
import {Title} from './Title';

type Props<Item> = {
  count?: number;
  selection?: SelectionState<Item>;
  selectionTitle?: ReactNode;
  selectionToolbar?: ReactNode;
  title?: ReactNode;
  toolbar?: ReactNode;
};

export function Header<Item>({count, selection, selectionTitle, selectionToolbar, title, toolbar}: Props<Item>) {
  if (selection && selection.hasSelectedItems && (selectionTitle || selectionToolbar)) {
    return (
      <div className={cn(styles.header, styles.selected)}>
        <div className={styles.headerInner}>
          {selectionTitle ? <Title>{selectionTitle}</Title> : null}

          <div className={cn(styles.toolbar, styles.toolbarSelected)}>
            <div className={styles.toolbarContent}>{selectionToolbar}</div>

            <button onClick={selection.deselectAll} className={styles.closeButton} type='button'>
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!title && !toolbar && count === undefined) {
    return null;
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerInner}>
        <div>{title || count !== undefined ? <Title count={count}>{title}</Title> : null}</div>

        {toolbar && (
          <div className={styles.toolbar}>
            <div className={styles.toolbarContent}>{toolbar}</div>
          </div>
        )}
      </div>
    </div>
  );
}
