import {intranetApi} from 'apiClient';
import {User, UserId, userSchema} from 'domain/user/model';
import {UserRoleId} from 'domain/userRole/model';

export type SetRolesParams = {
  id: UserId;
  roleIds: UserRoleId[];
};

export const setRoles = async ({id, roleIds}: SetRolesParams): Promise<User> => {
  const {data} = await intranetApi.post('/v1/users/setRoles', {roleIds}, {params: {id}});

  return userSchema.runWithException(data);
};
