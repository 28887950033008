import React, {ReactNode} from 'react';
import {MenuBar as BaseMenuBar, useMenuBarState} from 'reakit/Menu';
import {MenuContext} from './context';
import styles from './index.css';

type Props = {
  children: ReactNode;
  orientation: 'vertical';
};

export function MenuBar({children, orientation}: Props) {
  const menu = useMenuBarState({orientation});

  return (
    <MenuContext.Provider value={menu}>
      <BaseMenuBar {...menu} className={styles.menuBar}>
        {children}
      </BaseMenuBar>
    </MenuContext.Provider>
  );
}
