import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Permission} from 'domain/permission/model';
import {TeamMembers, teamMembersSchema} from 'domain/team/model/members';
import {OutstaffMembershipRequestConfig} from 'domain/team/model/members/membershipConfig';

export const requestOutstaffMembershipGeneric =
  (endpoint: string) =>
  async (membershipConfig: OutstaffMembershipRequestConfig): Promise<TeamMembers> => {
    const {data} = await intranetApi.post(endpoint, membershipConfig);

    return teamMembersSchema.runWithException(data);
  };

const requestOutstaffMembershipDefault = requestOutstaffMembershipGeneric('/v1/teams/outstaff/memberships/request');
const createOutstaffMembershipAsAdmin = requestOutstaffMembershipGeneric(
  '/v1/teams/outstaff/memberships/createAsAdmin',
);

export const requestOutstaffMembership = createPermissionDependentHandler(
  [{permission: Permission.OUTSTAFF_MEMBERSHIP_ADMIN_WRITE, handler: createOutstaffMembershipAsAdmin}],
  requestOutstaffMembershipDefault,
);
