import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {useLegalEntities} from 'domain/legalEntity/hooks/useLegalEntities';
import {LegalEntityDiff} from 'domain/legalEntity/model';
import {updateLegalEntityFx} from 'domain/legalEntity/stores/main';
import {LegalEntityForm} from 'domain/legalEntity/widgets/Form';
import {NotFoundPage} from 'pages/NotFound';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useHistory, useParams} from 'react-router-dom';
import {pageTitles} from 'routes/legalEntities/titles';
import {legalEntitiesUrls} from 'routes/legalEntities/urls';
import {toaster} from 'services/toaster';

export function LegalEntityPage() {
  const intl = useIntl();
  const history = useHistory();
  const {legalEntityId} = useParams<{legalEntityId: string}>();
  const {legalEntities, dataState} = useLegalEntities();
  const legalEntity = useMemo(() => legalEntities.find(({id}) => id === legalEntityId), [legalEntities, legalEntityId]);

  const onSubmit = useCallback(
    (legalEntityDiff: LegalEntityDiff) =>
      updateLegalEntityFx({id: legalEntityId, diff: legalEntityDiff})
        .then(() => history.push(legalEntitiesUrls.root()))
        .catch(toaster.interceptThenThrowError),
    [history, legalEntityId],
  );

  return (
    <PageStateHandler data={legalEntities} state={dataState}>
      {() =>
        legalEntity ? (
          <Page
            breadcrumbs={[{title: intl.formatMessage(pageTitles.legalEntities), href: legalEntitiesUrls.root()}]}
            title={legalEntity.title}
          >
            <LegalEntityForm legalEntity={legalEntity} onSubmit={onSubmit} />
          </Page>
        ) : (
          <NotFoundPage />
        )
      }
    </PageStateHandler>
  );
}
