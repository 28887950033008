import {getSalariesFromContractRecords} from 'domain/contract/utils/getSalariesFromContractRecord';
import {UserFullInfoRecord} from 'domain/userFullInfoRecord/model';
import {getMainSalary} from './getMainSalary';

export function getSalaryRelatedEntities<T>({contracts}: UserFullInfoRecord, entity: T): (T | undefined)[] {
  const salaries = getSalariesFromContractRecords(contracts);
  const mainSalary = getMainSalary(contracts);

  if (!salaries || !mainSalary) {
    return [entity];
  }

  return salaries.map((salary) => (salary.id === mainSalary.id ? entity : undefined));
}
