import {DataState} from '@joomcode/deprecated-utils/dataState';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {JobInfoRecord, JobInfoRecordId} from 'domain/jobInfoRecord/model';
import {deleteRecordFx} from 'domain/jobInfoRecord/stores/main';
import {JobInfoTable} from 'domain/jobInfoRecord/widgets/Table';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  userId: UserId;
  records: JobInfoRecord[];
  dataState: DataState;
  onDialogOpenWithPayload(record: JobInfoRecord): void;
};

export function JobInfo({userId, records, dataState, onDialogOpenWithPayload}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canEdit = acl.hasPermission(Permission.USER_JOB_INFO_RECORD_WRITE);
  const onDeleteRecordClick = useCallback(
    (jobInfoRecordId: JobInfoRecordId) => {
      confirm(
        {
          title: intl.formatMessage(messages.deletionConfirmationTitle),
          text: intl.formatMessage(messages.deletionConfirmationText),
          confirmationText: intl.formatMessage(messages.deletionConfirmationButton),
          intent: 'negative',
          onConfirm: () => deleteRecordFx({userId, jobInfoRecordId}).catch(toaster.interceptThenThrowError),
        },
        intl,
      );
    },
    [intl, userId],
  );

  return (
    <JobInfoTable
      jobInfoRecords={records}
      dataState={dataState}
      {...(canEdit && {
        onEditItem: onDialogOpenWithPayload,
        onDeleteItem: onDeleteRecordClick,
      })}
    />
  );
}
