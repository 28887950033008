import {Sorting, SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {TooltipFaqPlacement} from '../../../TooltipFaq';
import {CellAlign, Column, SortableCellOptions} from '../../types';

const DEFAULT_SORTING_DIRECTIONS: SortingDirection[] = [SortingDirection.ASC, SortingDirection.DESC];

export function getSortableCellOptions({
  column,
  sorting,
  onSort,
}: {
  column: Column<unknown>;
  sorting?: Sorting;
  onSort?: (sorting?: Sorting) => void;
}): SortableCellOptions | undefined {
  if (!column.sortable || !onSort) {
    return undefined;
  }

  return {
    availableDirections: Array.isArray(column.sortable) ? column.sortable : DEFAULT_SORTING_DIRECTIONS,
    direction: sorting?.by === column.id ? sorting.direction : undefined,
    onSort: (direction: SortingDirection) => onSort(direction && {by: column.id, direction}),
  };
}

export function getTooltipPlacementByColumnAlign(align: CellAlign = 'left'): TooltipFaqPlacement {
  const placementByAlign: Record<CellAlign, TooltipFaqPlacement> = {
    left: 'top-start',
    center: 'top',
    right: 'top-end',
  };
  return placementByAlign[align];
}
