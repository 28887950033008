import {defineMessages} from 'react-intl';
import {FileValidatorErrorCode} from './types';

export const fileErrorMessages = defineMessages<FileValidatorErrorCode>({
  [FileValidatorErrorCode.FILE_INVALID]: {
    id: 'validator-file-invalid',
    description: 'Validation error about given value on not a file',
    defaultMessage: 'Invalid value',
  },
  [FileValidatorErrorCode.FILE_SIZE_OUT_OF_RANGE]: {
    id: 'validator-file-size-out-of-range',
    description: 'Validation error about given file has invalid size',
    defaultMessage: 'The file size must be greater than {minFileSize}, but less than {maxFileSize}',
  },
  [FileValidatorErrorCode.FILE_SIZE_TOO_BIG]: {
    id: 'validator-file-file-size-to-big',
    description: 'Validation error about given file’ size too big',
    defaultMessage: 'The file size must be less than {fileSize}',
  },
  [FileValidatorErrorCode.FILE_SIZE_TOO_SMALL]: {
    id: 'validator-file-file-size-to-small',
    description: 'Validation error about given file’ size too small',
    defaultMessage: 'The file size must be greater than {fileSize}',
  },
  [FileValidatorErrorCode.FILE_INVALID_MIME_TYPE]: {
    id: 'validator-file-invalid-mime-type',
    description: 'Validation error about given file has invalid mime type',
    defaultMessage: 'The file extension must be one of the following: {fileExtensions}',
  },
  [FileValidatorErrorCode.FILE_INVALID_EXTENSION]: {
    id: 'validator-file-invalid-extension',
    description: 'Validation error about given file has invalid extension',
    defaultMessage: 'The file extension must be one of the following: {fileExtensions}',
  },
});
