import {ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {DecisionStatus} from 'domain/compensationReviewRequest/model/decisionStatus';
import {userSchema} from 'domain/user/model';
import {formattedDateSchema} from 'models/system/formattedDate';

export const decisionSchema = object({
  comment: optional(string()),
  createdAt: optional(formattedDateSchema),
  createdBy: optional(userSchema),
  status: optional(oneOfEnum(DecisionStatus)),
  user: userSchema,
});

export type Decision = ExtractSchemaType<typeof decisionSchema>;
