import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {usePrevious} from '@joomcode/deprecated-utils/react/usePrevious';
import {Button} from '@joomcode/joom-ui/Button';
import {Receipt, ReceiptDiff} from 'domain/compensations/receipt/model';
import {receiptToReceiptDiff} from 'domain/compensations/receipt/model/receiptToReceiptDiff';
import {ReceiptCard} from 'domain/compensations/receipt/widgets/Card';
import {CompensationReceiptForm} from 'domain/compensations/receipt/widgets/Form';
import {CompensationRequestId} from 'domain/compensations/request/model';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useField} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

const NAME = 'receipts';

type Props = {
  compensationRequestId: CompensationRequestId;
  disabled?: boolean;
  initialReceipts: Receipt[];
};

export function FieldReceipts({compensationRequestId, disabled, initialReceipts}: Props) {
  const intl = useIntl();
  const {open, close, isOpen} = usePopupState();
  const initialValue = useMemo(() => initialReceipts.map(receiptToReceiptDiff), [initialReceipts]);
  const {input} = useField<ReceiptDiff[]>(NAME, {initialValue});
  const [receipts, setReceipts] = useState<Receipt[]>(initialReceipts);
  const previousReceipts = usePrevious(receipts);

  const onSubmit = useCallback(
    (receipt: Receipt) => {
      close();
      setReceipts([...receipts, receipt]);
    },
    [close, receipts, setReceipts],
  );

  const onRemove = useCallback(
    (index: number) => {
      const newReceipts = [...receipts];
      newReceipts.splice(index, 1);
      setReceipts(newReceipts);
    },
    [receipts, setReceipts],
  );

  useEffect(() => {
    if (receipts !== previousReceipts) {
      input.onChange(receipts.map(receiptToReceiptDiff));
    }
  }, [receipts, input.onChange]);

  return (
    <>
      {receipts.map((receipt, index) => (
        <ReceiptCard
          canDownloadFiles
          compensationRequestId={compensationRequestId}
          disabled={disabled}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onRemove={() => onRemove(index)}
          receipt={receipt}
        />
      ))}
      <div className={styles.buttonWrap}>
        <Button intent='primary' kind='secondary' size='l' disabled={disabled} onClick={open}>
          {receipts.length
            ? intl.formatMessage(messages.addMoreReceiptButton)
            : intl.formatMessage(messages.addReceiptButton)}
        </Button>
      </div>

      {isOpen && <CompensationReceiptForm onSubmit={onSubmit} onClose={close} />}
    </>
  );
}
