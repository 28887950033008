import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Salary ranges import',
    description: '[title] Salary ranges upload panel title',
  },
  annotation: {
    defaultMessage:
      'Uploading a new file creates a new salary ranges version effective from the date in the file. The current version will no longer apply from this date and will be only accessible in the Version history.',
    description: 'Salary ranges upload annotation',
  },
  success: {
    defaultMessage:
      'Once the processed file is applied, it will instantly be visible in the Version history and become effective on <emphasis>{date}</emphasis>.{br}{comment, select, undefined {} other { The version will have the following comment: <emphasis>{comment}</emphasis>}}',
    description: 'Salary ranges upload success message',
  },
  errors: {
    defaultMessage: 'Errors:',
    description: 'Salary ranges upload section title',
  },
  warning: {
    defaultMessage: 'Warning:',
    description: 'Salary ranges upload section title',
  },
  warningNotApplicable: {
    defaultMessage: 'The file is not applicable to {users}.',
    description: 'Salary ranges upload warning: not applicable',
  },
});
