import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Resource Summary',
    description: '[title] Resource summary panel title',
  },
  serviceLabel: {
    defaultMessage: 'Service',
    description: 'Resource summary panel: property label',
  },
  valueLabel: {
    defaultMessage: 'Resource value',
    description: 'Resource summary panel: property label',
  },
  descriptionLabel: {
    defaultMessage: 'Description',
    description: 'Resource summary panel: property label',
  },
  ownersLabel: {
    defaultMessage: 'Owners',
    description: 'Resource summary panel: property label',
  },
  noOwners: {
    defaultMessage: 'No owners',
    description: 'Resource summary panel: empty owner list',
  },
});
