import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ClampedText} from 'components/ui/ClampedText';
import {SensitiveData} from 'components/ui/SensitiveData';
import {ActiveBadge} from 'components/widgets/ActiveBadge';
import {formatFrequency} from 'domain/frequency/model/formatter';
import {RegularBonusRecord, RegularBonusRecordId} from 'domain/regularBonusRecord/model';
import {FormattedRegularBonusValue} from 'domain/regularBonusRecord/widgets/FormattedValue';
import {RegularBonusRecordMenu} from 'domain/regularBonusRecord/widgets/Menu';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';
import styles from './styles.css';

export type Props = {
  dataState: DataState;
  records: RegularBonusRecord[];
  isSensitiveDataHidden: boolean;
  onDeleteItem?: (id: RegularBonusRecordId) => void;
  onEditItem?: (record: RegularBonusRecord) => void;
  onReplaceItem?: (record: RegularBonusRecord) => void;
};

const getRowClassName = ({active}: RegularBonusRecord) => (!active ? styles.inactiveRow : '');
const getRowKey = ({id}: RegularBonusRecord) => id;

export function RegularBonusRecordsTable({
  dataState,
  records,
  isSensitiveDataHidden,
  onDeleteItem,
  onEditItem,
  onReplaceItem,
}: Props) {
  const intl = useIntl();
  const columns: Column<RegularBonusRecord>[] = useMemo(() => {
    const basicColumns: Column<RegularBonusRecord>[] = [
      {
        defaultWidth: 7,
        id: ColumnId.START_DATE,
        name: intl.formatMessage(columnNames[ColumnId.START_DATE]),
        render: ({startDate}) => <HumanDate value={startDate} format='short' utc />,
      },
      {
        defaultWidth: 7,
        id: ColumnId.END_DATE,
        name: intl.formatMessage(columnNames[ColumnId.END_DATE]),
        render: ({endDate}) => (endDate ? <HumanDate value={endDate} format='short' utc /> : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: 10,
        id: ColumnId.AMOUNT,
        name: intl.formatMessage(columnNames[ColumnId.AMOUNT]),
        render: ({value}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            <FormattedRegularBonusValue value={value} />
          </SensitiveData>
        ),
      },
      {
        defaultWidth: 7,
        id: ColumnId.FREQUENCY,
        name: intl.formatMessage(columnNames[ColumnId.FREQUENCY]),
        render: ({frequency}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>{formatFrequency(frequency, intl)}</SensitiveData>
        ),
      },
      {
        defaultWidth: 18,
        id: ColumnId.COMMENT,
        name: intl.formatMessage(columnNames[ColumnId.COMMENT]),
        render: ({comment}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            {comment ? <ClampedText text={comment} /> : <DataTable.CellEmpty />}
          </SensitiveData>
        ),
      },
      {
        defaultWidth: 5,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({active}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            <ActiveBadge active={active} />
          </SensitiveData>
        ),
      },
    ];

    const actionColumns: Column<RegularBonusRecord>[] =
      onDeleteItem || onEditItem || onReplaceItem
        ? [
            {
              defaultWidth: 3,
              id: 'actions',
              name: '',
              render: (record) => (
                <RegularBonusRecordMenu
                  record={record}
                  onDelete={onDeleteItem}
                  onEdit={onEditItem}
                  onReplace={onReplaceItem}
                />
              ),
            },
          ]
        : [];

    return [...basicColumns, ...actionColumns];
  }, [intl, isSensitiveDataHidden, onDeleteItem, onEditItem, onReplaceItem]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={records}
      emptyMessage={intl.formatMessage(messages.empty)}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      storageName='regularBonusRecords.list'
    />
  );
}
