import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {omit} from '@joomcode/deprecated-utils/object/omit';
import {OfferId} from 'domain/offer/model';
import {VersionedOfferStockOptions} from 'domain/offer/model/stockOption';
import {updateOfferFx} from 'domain/offer/stores/main';
import {createStore} from 'effector';
import {getOfferStockOptionsExistenceFx, getOfferStockOptionsFx} from '.';

type OfferStockOptionsInfo = {
  exist: boolean;
  data: VersionedOfferStockOptions | undefined;
};

type State = {
  byOfferId: Record<OfferId, OfferStockOptionsInfo>;
  byOfferIdState: Record<OfferId, DataState>;
};

export const $offerStockOptions = createStore<State>({
  byOfferId: {},
  byOfferIdState: {},
})
  .on([getOfferStockOptionsFx, getOfferStockOptionsExistenceFx], (state, {id}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.LOADING,
    },
  }))
  .on([getOfferStockOptionsFx.fail, getOfferStockOptionsExistenceFx.fail], (state, {params: {id}}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.FAILED,
    },
  }))
  .on(getOfferStockOptionsFx.done, (state, {params: {id}, result}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.LOADED,
    },
    byOfferId: {
      ...state.byOfferId,
      [id]: {
        exist: Boolean(result.at(-1)?.stockOptions?.amount),
        data: arrayToObject(
          result,
          ({versionId}) => versionId,
          ({stockOptions}) => stockOptions,
        ),
      },
    },
  }))
  .on(getOfferStockOptionsExistenceFx.done, (state, {params: {id}, result}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.LOADED,
    },
    byOfferId: {
      ...state.byOfferId,
      [id]: {
        ...state.byOfferId[id],
        exist: result,
      },
    },
  }))
  .on(updateOfferFx.done, (state, payload) => {
    const {id} = payload.params.params;
    return {
      ...state,
      byOfferIdState: omit(state.byOfferIdState, [id]),
      byOfferId: omit(state.byOfferId, [id]),
    };
  });
