import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  permissionsLabel: {
    defaultMessage: 'Permissions',
    description: 'Form field label',
  },
  permissionsLoadError: {
    defaultMessage: 'No permissions loaded',
    description: 'Field error',
  },
  searchPlaceholder: {
    defaultMessage: 'Search permissions',
    description: '[input] Search placeholder',
  },
  nothingFound: {
    defaultMessage: 'Nothing found',
    description: 'Secure permissions search empty message',
  },
  buttonCollapse: {
    defaultMessage: 'Collapse',
    description: '[button] Collapse all sections',
  },
  buttonExpand: {
    defaultMessage: 'Expand',
    description: '[button] Expand all section',
  },
});
