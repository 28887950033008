import React from 'react';
import cn from 'classnames';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';

import styles from './index.css';
import {useDialogPrivateContext} from '../context';

type Props = {
  icon?: React.ReactNode;
  headerImageAlt: string;
  headerImageUrl?: string;
};

export const TopsidePicture = ({icon, headerImageAlt, headerImageUrl}: Props) => {
  const {size} = useDialogPrivateContext();

  if (headerImageUrl) {
    return <img alt={headerImageAlt} className={styles.headerImage} src={headerImageUrl} />;
  }

  if (icon) {
    return (
      <div className={cn(styles.headerIcon, styles[`headerIcon${capitalize(size)}` as keyof typeof styles])}>
        {icon}
      </div>
    );
  }

  return null;
};
