import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'About team',
    description: '[title] Team description panel title',
  },
  empty: {
    defaultMessage: 'Empty for now',
    description: 'Team description panel: empty message',
  },
});
