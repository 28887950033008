import {Panel} from '@joomcode/joom-ui/Panel';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {TimeOffRequestForm} from 'domain/timeOff/request/widgets/Form';
import {useUserPrivateInfo} from 'domain/user/hooks/useUserPrivateInfo';
import {UserFull} from 'domain/user/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps} from 'react-router-dom';
import {pageTitles} from 'routes/timeOff/titles';
import {timeOffUrls} from 'routes/timeOff/urls';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function TimeOffCreateRequestPage({selfUser}: Props) {
  const intl = useIntl();
  const userPrivateInfo = useUserPrivateInfo(selfUser.id);
  return (
    <PageStateHandler data={{privateInfo: userPrivateInfo.data}} state={userPrivateInfo.dataState}>
      {({privateInfo}) => (
        <Page
          breadcrumbs={[{title: intl.formatMessage(pageTitles.requests), href: timeOffUrls.requests()}]}
          title={intl.formatMessage(pageTitles.createRequest)}
        >
          <Panel withPadding>
            <TimeOffRequestForm
              availableVacationDays={privateInfo?.availableVacationDays}
              availableRemoteWorkDays={privateInfo?.availableRemoteWorkDays}
              user={selfUser}
            />
          </Panel>
        </Page>
      )}
    </PageStateHandler>
  );
}
