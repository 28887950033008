import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {Stay} from 'domain/compensations/stay/model';
import {Money, moneySchema} from 'domain/money/model';

export const calculateAllowance = async (stays: Stay[]): Promise<Money[]> => {
  const {data} = await intranetApi.post('/v1/compensations/calculateAllowance', stays);

  return array(moneySchema).runWithException(data);
};
