import {QueryRequestPayload} from 'api/types';
import {CountableRoleBindigns, RoleBinding, RoleBindingsSearchFilters} from 'domain/roleBinding/model';
import {createEffect} from 'effector';

export const getRoleBindingRequestsWithCountFx = createEffect<
  QueryRequestPayload<RoleBindingsSearchFilters>,
  CountableRoleBindigns
>('load role binding requests with pagination and count');
export const getRoleBindingRequestsFx = createEffect<QueryRequestPayload<RoleBindingsSearchFilters>, RoleBinding[]>(
  'load role binding requests with pagination',
);
