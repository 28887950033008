import cn from 'classnames';
import React from 'react';
import {FocusOn} from 'react-focus-on';
import {Tippy, TippyProps} from '../Tippy';
import styles from './index.css';

export type ContextPopupProps = {
  ariaLabel?: string;
  arrow?: boolean;
  children: TippyProps['children'];
  content: TippyProps['content'];
  closeOnInsideClick?: boolean;
  distance?: TippyProps['distance'];
  inline?: boolean;
  isOpen: boolean;
  maxWidth?: TippyProps['maxWidth'];
  placement?: TippyProps['placement'];
  /**
   * Should component return focus on previous position
   */
  returnFocus?: boolean;
  onClose: () => void;
};

export function ContextPopup({
  ariaLabel,
  arrow = true,
  children,
  closeOnInsideClick = true,
  content,
  distance,
  inline,
  isOpen,
  maxWidth,
  placement = 'auto',
  returnFocus = true,
  onClose,
}: ContextPopupProps) {
  return (
    <div className={cn(styles.contextPopup, {[styles.inline]: inline})}>
      <Tippy
        animation='fade'
        arrow={arrow}
        boundary='viewport'
        distance={distance}
        duration={200}
        interactive
        maxWidth={maxWidth}
        placement={placement}
        theme='light'
        trigger='manual'
        visible={isOpen}
        popperOptions={{
          modifiers: {
            computeStyle: {
              // Prevent blurry effect
              // https://github.com/atomiks/tippyjs/issues/163
              gpuAcceleration: false,
            },
          },
        }}
        /* Outside clicks should be handled by FocusOn ↓ for correct management of focus/scroll locking */
        hideOnClick={false}
        // @ts-ignore
        zIndex='var(--z-index-context-popup, 9999)'
        content={
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
          <div aria-label={ariaLabel} role='dialog' onClick={closeOnInsideClick ? onClose : undefined}>
            <FocusOn
              enabled={isOpen}
              /* Isolation mode adds/removes `aria-hidden` attribute. It causes slowdown in Chrome on pages with a lot of DOM elements. */
              noIsolation
              onEscapeKey={onClose}
              onClickOutside={onClose}
              scrollLock={false}
              /* Returns focus to previous position */
              returnFocus={returnFocus}
              /* Prevent disabling text selection in popup */
              shouldIgnore={(node) => !node.classList.contains('tippy-popper')}
            >
              {content}
            </FocusOn>
          </div>
        }
      >
        {children}
      </Tippy>
    </div>
  );
}
