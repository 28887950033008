import {oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {Page} from 'components/widgets/Page';
import {SecurePermission} from 'domain/permission/model/secure';
import {stockOptionHoldersApi} from 'domain/stockOption/holder/api';
import {AllStockOptionHoldersList} from 'domain/stockOption/holder/widgets/AllHoldersList';
import {SubordinateStockOptionHoldersList} from 'domain/stockOption/holder/widgets/SubordinateHoldersList';
import {useStockOptionViewModes} from 'domain/stockOption/viewMode/hooks/useModes';
import {StockOptionViewMode} from 'domain/stockOption/viewMode/model';
import {StockOptionModeTabs} from 'domain/stockOption/viewMode/widgets/Tabs';
import {UserFull} from 'domain/user/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {pageTitles} from 'routes/stockOptions/titles';
import {stockOptionsUrls} from 'routes/stockOptions/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function StockOptionsPage({selfUser}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {availableModes, defaultMode, showTabs} = useStockOptionViewModes(selfUser);
  const [mode] = useQueryParam('mode', oneOfEnum(StockOptionViewMode), defaultMode);

  if (mode === StockOptionViewMode.MY) {
    return <Redirect to={stockOptionsUrls.user({login: selfUser.login})} />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.title)}
      headerContent={showTabs && <StockOptionModeTabs availableModes={availableModes} activeMode={mode} />}
      actions={
        acl.hasSecurePermission(SecurePermission.STOCK_OPTION_DOWNLOAD_ANY) &&
        mode === StockOptionViewMode.ALL && (
          <DownloadLinkButton
            size='m'
            kind='text'
            intent='neutral'
            href={stockOptionHoldersApi.buildDownloadAllLink()}
            iconLeft={<DownloadIcon />}
            target='_blank'
          >
            {intl.formatMessage(messages.downloadButton)}
          </DownloadLinkButton>
        )
      }
    >
      {mode === StockOptionViewMode.ALL && <AllStockOptionHoldersList />}
      {mode === StockOptionViewMode.SUBORDINATES && <SubordinateStockOptionHoldersList />}
    </Page>
  );
}
