import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import maxDate from 'date-fns/max';
import {TimeOffType} from 'domain/timeOff/type/model';
import {TimeOff} from '.';

export const doesTimeOffIncludeToday = ({start, end}: TimeOff) => {
  const today = new Date();
  return differenceInCalendarDays(today, start) >= 0 && differenceInCalendarDays(end, today) >= 0;
};

export const getMergedNearestTimeOff = (sortedTimeOffs: TimeOff[]): TimeOff | undefined => {
  if (sortedTimeOffs.length === 0) {
    return undefined;
  }
  const mergedTimeOffStart = sortedTimeOffs[0].start;
  let mergedTimeOffEnd = sortedTimeOffs[0].end;
  let i = 1;
  while (sortedTimeOffs[i] && differenceInCalendarDays(sortedTimeOffs[i].start, mergedTimeOffEnd) <= 1) {
    mergedTimeOffEnd = maxDate([sortedTimeOffs[i].end, mergedTimeOffEnd]);
    i++;
  }
  return {
    type: sortedTimeOffs[0].type,
    start: mergedTimeOffStart,
    end: mergedTimeOffEnd,
  };
};

export const sortTimeOffs = (timeOffs: TimeOff[]): TimeOff[] =>
  timeOffs.sort((timeOff1, timeOff2) => timeOff1.start.getTime() - timeOff2.start.getTime());

export const getMergedOngoingTimeOff = (timeOffs: TimeOff[]): TimeOff | undefined => {
  if (timeOffs.length === 0) {
    return undefined;
  }
  const sortedTimeOffs = sortTimeOffs(timeOffs);
  if (!doesTimeOffIncludeToday(sortedTimeOffs[0])) {
    return undefined;
  }
  return getMergedNearestTimeOff(sortedTimeOffs);
};

export const isTimeOffVacation = (timeOff: TimeOff) => timeOff.type !== TimeOffType.BUSINESS_TRIP;
