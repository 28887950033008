import {ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {stockOptionBulkUploadMetaSchema} from 'domain/stockOption/bulkUpload/model/meta';
import {stockOptionBuyoutSchema} from '.';

export const stockOptionBuyoutRecordSchema = object({
  buyout: optional(stockOptionBuyoutSchema),
  meta: stockOptionBulkUploadMetaSchema,
});

export type StockOptionBuyoutRecord = ExtractSchemaType<typeof stockOptionBuyoutRecordSchema>;
