import {useDroppable} from '@dnd-kit/core';
import React from 'react';
import {useIntl} from 'react-intl';
import cn from 'classnames';
import styles from './index.css';
import {messages} from './messages';

export const ROOT_LEVEL_DROPPABLE_ID = 'rootLevelDroppable';

type Props = {
  active?: boolean;
  message?: React.ReactNode;
  isOver?: boolean;
};

export function RootDroppable({active, message, isOver}: Props) {
  const rootLevelDroppable = useDroppable({
    id: ROOT_LEVEL_DROPPABLE_ID,
  });

  const intl = useIntl();

  return (
    <div
      className={cn(styles.makeOrphan, {[styles.over]: isOver, [styles.active]: active})}
      ref={rootLevelDroppable.setNodeRef}
    >
      {message || intl.formatMessage(messages.makeOrphan)}
    </div>
  );
}
