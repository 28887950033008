import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {CountryCode} from 'domain/countryCode/model';
import {LevelCode} from 'domain/levelCode/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {NullableSalaryRange, nullableSalaryRangeSchema} from 'domain/salaryRange/model';

export type SalaryRangeParams = {
  levelCode: LevelCode;
  region: CountryCode;
  userFunction: string;
};

const getByParamsGeneric =
  (endpoint: string) =>
  async (params: SalaryRangeParams): Promise<NullableSalaryRange> => {
    const {data} = await intranetApi.post(endpoint, {...params});

    return nullableSalaryRangeSchema.runWithException(data);
  };

const getByParamsAsAdmin = getByParamsGeneric('/v1/secure/salaryRanges/getByParamsAsAdmin');
const getByParamsAsManager = getByParamsGeneric('/v1/secure/salaryRanges/getByParamsAsManager');

export const getByParams = createPermissionDependentHandler(
  [{securePermission: SecurePermission.SALARY_RANGE_READ_AS_ADMIN, handler: getByParamsAsAdmin}],
  getByParamsAsManager,
);
