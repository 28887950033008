import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Issue role to Joomer',
    description: '[title] field title',
  },
  confirmTitle: {
    defaultMessage: 'Issue role to Joomer',
    description: '[title] Confirmation dialog title',
  },
  confirmText: {
    defaultMessage:
      'Are you sure you want to issue role {role} to user {fullName} ({login})? This action may grant access to financial information.',
    description: 'Confirmation dialog text',
  },
  confirmApprove: {
    defaultMessage: 'Issue role',
    description: '[button] Confirmation dialog approve button',
  },
  loadUserFail: {
    defaultMessage: 'Can’t issue role: failed to load users',
    description: 'Error message',
  },
});
