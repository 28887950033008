import {memoizeOne} from '@joomcode/deprecated-utils/memoize/one';
import escapeStringRegexp from 'escape-string-regexp';
import React from 'react';

export type HighlighterComponent = React.ComponentType<{children: string}>;

const buildHighlightRegExp = memoizeOne((searchWords: string[]) => {
  const words = searchWords.map((word) => escapeStringRegexp(word));
  return new RegExp(`(${words.join('|')})`, 'gi');
});

export const highlightSearchWords = (source: string, searchWords: string[], Highlighter: HighlighterComponent) => {
  if (!searchWords.length) {
    return source;
  }
  return source.split(buildHighlightRegExp(searchWords)).map((part, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>{index % 2 ? <Highlighter>{part}</Highlighter> : part}</React.Fragment>
  ));
};

export const highlightIfNeeded = (s: string, highlightSearch?: (s: string) => React.ReactNode) =>
  highlightSearch ? highlightSearch(s) : s;
