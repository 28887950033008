import cn from 'classnames';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import {type PropertiesWithMarkConstraint, removeMarkFromProperties} from '../create-locator';
import styles from './index.css';
import {BaseButtonProps, defaultButtonSize} from './types';

function getButtonClassName(props: BaseButtonProps): string {
  return cn(
    styles.button,
    styles[`buttonKind${capitalize(props.kind)}` as keyof typeof styles],
    styles[`buttonIntent${capitalize(props.intent)}` as keyof typeof styles],
    styles[`buttonSize${capitalize(props.size ?? defaultButtonSize)}` as keyof typeof styles],
    {
      [styles.buttonDisabled]: props.disabled,
      [styles.buttonLoading]: props.loading,
      [styles.withShrink]: props.withShrink,
      [styles.buttonNoHorizontalPadding]: props.noHorizontalPadding,
    },
  );
}

export function getAnchorButtonProps(props: BaseButtonProps): {
  tabIndex: number;
  className: string;
  style: React.CSSProperties;
} {
  return {
    tabIndex: props.disabled || props.loading ? -1 : 0,
    className: getButtonClassName(props),
    style: {minWidth: props.minWidth},
  };
}

export function getNativeButtonProps(
  props: BaseButtonProps & JSX.IntrinsicElements['button'],
): JSX.IntrinsicElements['button'] {
  return {
    disabled: props.disabled || props.loading,
    className: getButtonClassName(props),
    type: props.type || 'button',
    style: {minWidth: props.minWidth},
    onClick: props.onClick,
  };
}

export function omitBaseButtonProps<T extends Partial<BaseButtonProps>>(props: T): Omit<T, keyof BaseButtonProps> {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const {
    children,
    disabled,
    iconLeft,
    iconRight,
    intent,
    kind,
    loading,
    minWidth,
    noHorizontalPadding,
    size,
    withShrink,
    ...restProps
  } = props;

  return removeMarkFromProperties(restProps as PropertiesWithMarkConstraint) as Omit<T, keyof BaseButtonProps>;
}
