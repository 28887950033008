import {User} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import {useIntl} from 'react-intl';

type Props = {
  user: User;
};

export function UserFullName({user: {firstName, lastName}}: Props) {
  const intl = useIntl();

  return intl.formatMessage(generalMessages.fullName, {
    firstName,
    lastName,
  });
}
