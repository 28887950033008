import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Generate link to the form',
    description: '[title] Generate newcomer application link',
  },
  generateButton: {
    defaultMessage: 'Generate',
    description: '[button] Generate newcomer application link',
  },
  tokenLifetimeOption: {
    defaultMessage: '{days, plural, one {# day} other {# days}}',
    description: 'Token lifetime select option label',
  },
  copyButton: {
    defaultMessage: 'Copy',
    description: '[button] Copy link to clipboard',
  },
});

export const fieldLabels = defineMessages({
  language: {
    defaultMessage: 'Language',
    description: 'Form label',
  },
  lifetimeDays: {
    defaultMessage: 'Token lifetime',
    description: 'Form label',
  },
});
