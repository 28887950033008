import cn from 'classnames';
import React, {useCallback, useMemo} from 'react';
import {SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {SortableCellOptions} from '../../types';
import {ReactComponent as AscIcon} from './asc.svg';
import {ReactComponent as DescIcon} from './desc.svg';
import styles from './index.css';

type Props = SortableCellOptions & {
  children?: React.ReactNode;
  disabled: boolean;
};
export function SortingButton({children, disabled, direction, availableDirections, onSort}: Props) {
  const nextDirection = useMemo<SortingDirection | undefined>(() => {
    const currentDirectionIndex = availableDirections.findIndex((dir) => dir === direction);
    return availableDirections[currentDirectionIndex + 1];
  }, [direction, availableDirections]);

  const handleClick = useCallback(() => onSort(nextDirection), [onSort, nextDirection]);

  return (
    <button className={styles.sortingButton} disabled={disabled} onClick={handleClick} type='button'>
      <span className={styles.content}>{children}</span>

      <span className={styles.iconWrapper}>
        {availableDirections.includes(SortingDirection.ASC) && (
          <AscIcon
            className={cn(styles.icon, {
              [styles.iconActive]: direction === 'asc',
              [styles.iconNext]: nextDirection === 'asc',
            })}
          />
        )}
        {availableDirections.includes(SortingDirection.DESC) && (
          <DescIcon
            className={cn(styles.icon, {
              [styles.iconActive]: direction === 'desc',
              [styles.iconNext]: nextDirection === 'desc',
            })}
          />
        )}
      </span>
    </button>
  );
}
