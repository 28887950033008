import {Page} from '@joomcode/joom-ui/Page';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {useLegalEntities} from 'domain/legalEntity/hooks/useLegalEntities';
import {LegalEntitiesTable} from 'domain/legalEntity/widgets/Table';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/legalEntities/titles';

export function LegalEntitiesPage() {
  const intl = useIntl();
  const {legalEntities, dataState} = useLegalEntities();

  return (
    <PageStateHandler data={legalEntities} state={dataState}>
      {() => (
        <Page title={intl.formatMessage(pageTitles.legalEntities)}>
          <LegalEntitiesTable legalEntities={legalEntities} />
        </Page>
      )}
    </PageStateHandler>
  );
}
