import {Range} from '@joomcode/deprecated-utils/range';
import React from 'react';
import {IntlShape, useIntl} from 'react-intl';
import {HumanDateFormat} from '@joomcode/deprecated-utils/humanDate';
import {formatHumanDate} from '../../formatters';
import {messages} from './messages';

export function formatHumanDateRange(
  intl: IntlShape,
  range: Range<number | string | Date>,
  format: HumanDateFormat = 'short',
  utc = false,
): string {
  const from = formatHumanDate({value: range.from, format, intl, utc});
  const to = formatHumanDate({value: range.to, format, intl, utc});

  return intl.formatMessage(messages.dateRange, {from, to});
}

export type HumanDateRangeProps = {
  range: Range<number | string | Date>;
  format?: HumanDateFormat;
  utc?: boolean;
};

export function HumanDateRange({range, format = 'short', utc = false}: HumanDateRangeProps) {
  const intl = useIntl();

  return <>{formatHumanDateRange(intl, range, format, utc)}</>;
}
