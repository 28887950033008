/* eslint-disable react/no-unused-prop-types */
import cn from 'classnames';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {memo, ReactNode} from 'react';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import type {SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {createLocator, getLocatorParameters, type Mark} from '../../../create-locator';
import type {CellAlign, CellVerticalAlign, StickyColumnOffset, CellLocator, CellStyle} from '../../types';
import styles from './index.css';

export type CellProps<T> = {
  addon?: ReactNode;
  align?: CellAlign;
  colSpan?: number;
  heading?: boolean;
  item: T;
  noWrap?: boolean;
  parentItem?: unknown;
  render: (item: T, parentItem: unknown) => ReactNode;
  stickyOffset?: StickyColumnOffset;

  /**
   * Appearance of the cell.
   */
  cellStyle?: CellStyle;

  verticalAlign?: CellVerticalAlign;
  width: number;
  onSorting?: (nextSorting: SortingDirection | undefined) => void | Promise<void>;
} & Partial<Mark<CellLocator>>;

export const Cell = memo(function Cell<T>({
  addon,
  align = 'left',
  colSpan,
  item,
  heading,
  noWrap,
  parentItem,
  render,
  stickyOffset,
  cellStyle = 'primary',
  verticalAlign,
  width,
  ...restProperties
}: CellProps<T>) {
  const locator = createLocator(restProperties);
  const locatorParameters = getLocatorParameters(restProperties);

  const Tag = heading ? 'div' : 'td';
  const content = render(item, parentItem);

  return (
    <Tag
      className={cn(
        styles.cell,
        styles[`align${capitalize(align)}`],
        verticalAlign ? {[styles[`verticalAlign${capitalize(verticalAlign)}`]]: true} : {},
        {
          [styles.heading]: heading,
          [styles.sticky]: stickyOffset,
          [styles.withAddon]: addon,
        },
      )}
      colSpan={colSpan}
      style={{
        maxWidth: width,
        minWidth: width,
        ...(stickyOffset && {[stickyOffset.side]: stickyOffset.value}),
      }}
      {...locator(locatorParameters)}
    >
      {isNotNullish(content) && (
        <div
          className={cn(
            styles.cellContent,
            {
              [styles.noWrap]: noWrap,
            },
            styles[`cellStyle${capitalize(cellStyle)}`],
          )}
          {...locator.content()}
        >
          {content}
        </div>
      )}

      {addon}
    </Tag>
  );
});
