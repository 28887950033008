import {compensationReviewRequestApi} from 'domain/compensationReviewRequest/api';
import {
  approveCompensationReviewRequestFx,
  approveCompensationReviewRequestOnBehalfFx,
  cancelCompensationReviewRequestFx,
  createCompensationReviewRequestFx,
  getAllCompensationReviewRequestsFx,
  getCompensationReviewRequestByIdFx,
  getMyCompensationReviewRequestsFx,
  getSubordinateCompensationReviewRequestsFx,
  rejectCompensationReviewRequestFx,
  rejectCompensationReviewRequestOnBehalfFx,
  setCompensationsRecordedFx,
  setStockOptionsRecordedFx,
} from '.';

approveCompensationReviewRequestFx.use(compensationReviewRequestApi.approve);
approveCompensationReviewRequestOnBehalfFx.use(compensationReviewRequestApi.approveOnBehalf);
cancelCompensationReviewRequestFx.use(compensationReviewRequestApi.cancel);
createCompensationReviewRequestFx.use(compensationReviewRequestApi.create);
getAllCompensationReviewRequestsFx.use(compensationReviewRequestApi.getAll);
getCompensationReviewRequestByIdFx.use(compensationReviewRequestApi.getById);
getMyCompensationReviewRequestsFx.use(compensationReviewRequestApi.getMy);
getSubordinateCompensationReviewRequestsFx.use(compensationReviewRequestApi.getSubordinate);
rejectCompensationReviewRequestFx.use(compensationReviewRequestApi.reject);
rejectCompensationReviewRequestOnBehalfFx.use(compensationReviewRequestApi.rejectOnBehalf);
setCompensationsRecordedFx.use(compensationReviewRequestApi.setCompensationsRecorded);
setStockOptionsRecordedFx.use(compensationReviewRequestApi.setStockOptionsRecorded);
