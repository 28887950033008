import {ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {phoneSchema} from 'models/system/phone';

export const emergencyContactSchema = object({
  name: string(),
  phone: optional(phoneSchema),
  relationship: optional(string()),
});

export type EmergencyContact = ExtractSchemaType<typeof emergencyContactSchema>;
