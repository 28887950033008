import {intranetApi} from 'apiClient';
import {TimeOffBalanceRecord, timeOffBalanceRecordSchema} from 'domain/timeOff/balanceRecord/model';
import {TimeOffBalanceRecordDiff} from 'domain/timeOff/balanceRecord/model/diff';
import {UserId} from 'domain/user/model';

export type CreateBalanceRecordParams = {
  userId: UserId;
  diff: TimeOffBalanceRecordDiff;
};

export const create = async ({userId, diff}: CreateBalanceRecordParams): Promise<TimeOffBalanceRecord> => {
  const {data} = await intranetApi.post('/v1/timeOffs/balanceRecords/create', diff, {params: {userId}});

  return timeOffBalanceRecordSchema.runWithException(data);
};
