import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Create option agreement',
    description: '[title] Stock option agreement creation dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Option agreement creation dialog',
    description: 'Stock option agreement creation dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Stock option agreement creation dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Create',
    description: '[button] Stock option agreement creation dialog: submit button',
  },
  success: {
    defaultMessage: 'Option agreement created. It has been sent to {email}.',
    description: 'Stock option agreement creation dialog: success message',
  },
});
