import {ReactComponent as CheckmarkIcon} from '@joomcode/joom-ui/icons/circle/checkmark.svg';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import cn from 'classnames';
import {MarketLevelPosition} from 'domain/compaRatio/model/marketLevelPosition';
import {formatMarketLevelPosition} from 'domain/compaRatio/model/marketLevelPosition/formatter';
import React from 'react';
import {useIntl} from 'react-intl';
import {ReactComponent as ArrowDownIcon} from './icons/arrowDown.svg';
import {ReactComponent as ArrowUpIcon} from './icons/arrowUp.svg';
import {ReactComponent as DoubleArrowDownIcon} from './icons/doubleArrowDown.svg';
import {ReactComponent as DoubleArrowUpIcon} from './icons/doubleArrowUp.svg';
import styles from './styles.css';

const iconByPosition: Record<MarketLevelPosition, React.ComponentType> = {
  [MarketLevelPosition.AT_MARKET_LEVEL]: CheckmarkIcon,
  [MarketLevelPosition.TOP_OF_MARKET_LEVEL]: ArrowUpIcon,
  [MarketLevelPosition.BOTTOM_OF_MARKET_LEVEL]: ArrowDownIcon,
  [MarketLevelPosition.ABOVE_MARKET_LEVEL]: DoubleArrowUpIcon,
  [MarketLevelPosition.BELOW_MARKET_LEVEL]: DoubleArrowDownIcon,
};

type Props = {
  marketLevelPosition: MarketLevelPosition;
  colored?: boolean;
};

export function CrHint({marketLevelPosition, colored}: Props) {
  const intl = useIntl();
  const Icon = iconByPosition[marketLevelPosition];

  return (
    <span
      className={cn(styles.root, {
        [styles.colored]: colored,
      })}
    >
      <TooltipFaq placement='right' content={formatMarketLevelPosition(marketLevelPosition, intl)}>
        <Icon />
      </TooltipFaq>
    </span>
  );
}
