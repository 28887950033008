import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {JobInfoRecord, jobInfoRecordSchema} from 'domain/jobInfoRecord/model';
import {UserId} from 'domain/user/model';

export const getRecordsByUserId = async (userId: UserId): Promise<JobInfoRecord[]> => {
  const {data} = await intranetApi.get('/v1/users/jobInfo/getByUserId', {params: {userId}});

  return array(jobInfoRecordSchema).runWithException(data);
};
