import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ClampedText} from 'components/ui/ClampedText';
import {ActiveBadge} from 'components/widgets/ActiveBadge';
import {OfficePolicyRecord, OfficePolicyRecordId} from 'domain/officePolicyRecord/model';
import {formatOfficePolicy} from 'domain/officePolicyRecord/model/policy/formatter';
import {OfficePolicyRecordMenu} from 'domain/officePolicyRecord/widgets/Menu';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';
import styles from './styles.css';

export type Props = {
  dataState: DataState;
  records: OfficePolicyRecord[];
  onDeleteItem?: (id: OfficePolicyRecordId) => void;
  onEditItem?: (record: OfficePolicyRecord) => void;
};

const getRowClassName = ({active}: OfficePolicyRecord) => (!active ? styles.inactiveRow : '');
const getRowKey = ({id}: OfficePolicyRecord) => id;

export function OfficePolicyRecordsTable({dataState, records, onDeleteItem, onEditItem}: Props) {
  const intl = useIntl();
  const columns: Column<OfficePolicyRecord>[] = useMemo(() => {
    const basicColumns: Column<OfficePolicyRecord>[] = [
      {
        defaultWidth: 5,
        id: ColumnId.EFFECTIVE_DATE,
        name: intl.formatMessage(columnNames[ColumnId.EFFECTIVE_DATE]),
        render: ({effectiveDate}) => <HumanDate value={effectiveDate} format='short' utc />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.POLICY,
        name: intl.formatMessage(columnNames[ColumnId.POLICY]),
        render: ({policy}) => formatOfficePolicy(policy, intl),
      },
      {
        defaultWidth: 12,
        id: ColumnId.COMMENT,
        name: intl.formatMessage(columnNames[ColumnId.COMMENT]),
        render: ({comment}) => (comment ? <ClampedText text={comment} /> : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: 5,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({active}) => <ActiveBadge active={active} />,
      },
    ];

    const actionColumns: Column<OfficePolicyRecord>[] =
      onDeleteItem || onEditItem
        ? [
            {
              defaultWidth: 3,
              id: ColumnId.ACTIONS,
              name: '',
              render: (record) => (
                <OfficePolicyRecordMenu record={record} onDelete={onDeleteItem} onEdit={onEditItem} />
              ),
            },
          ]
        : [];

    return [...basicColumns, ...actionColumns];
  }, [intl, onDeleteItem, onEditItem]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={records}
      emptyMessage={intl.formatMessage(messages.empty)}
      getRowClassName={getRowClassName}
      getRowKey={getRowKey}
      storageName='officePolicyRecords.list'
    />
  );
}
