import {formatError, ValidationFunction, ValidationOptions} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {FieldRolesInTeams, RoleInTeamValue} from 'domain/role/widgets/FieldRolesInTeams';
import React, {useState} from 'react';
import {useField, useForm} from 'react-final-form';
import {MessageDescriptor, useIntl} from 'react-intl';
import {getArrayFieldError} from 'utils/form/arrayFieldValidation';
import {errorMessages} from './messages';

export type Props = {
  disabled: boolean;
  errorMessages?: Record<string, MessageDescriptor>;
  initialValue?: RoleInTeamValue[];
  label: React.ReactNode;
  name: string;
  validate?: ValidationFunction<RoleInTeamValue[], ValidationOptions, unknown>;
};

export function RolesInTeamsFormControl({
  disabled,
  errorMessages: externalErrorMessages = {},
  initialValue,
  label,
  name,
  validate,
}: Props) {
  const intl = useIntl();
  const {meta} = useField<RoleInTeamValue>(name, {subscription: {error: true}});
  const {getFieldState, getRegisteredFields} = useForm();
  const [loadingError, setLoadingError] = useState<Error | undefined>();

  const fields = getRegisteredFields().filter((fieldName) => fieldName.startsWith(`${name}[`));
  const isAnyFieldTouched = fields.some((fieldName) => getFieldState(fieldName)?.touched);
  const isAnyFieldActive = fields.some((fieldName) => getFieldState(fieldName)?.active);

  const validationError = getArrayFieldError(meta.error);
  const validationErrorText =
    isAnyFieldTouched &&
    !isAnyFieldActive &&
    validationError &&
    formatError(validationError, intl, {...errorMessages, ...externalErrorMessages});
  const loadingErrorText = loadingError && intl.formatMessage(errorMessages.loadingFailed);

  return (
    <FormControl disabled={disabled} label={label} error={loadingErrorText ?? validationErrorText}>
      {(formControlProps) => (
        <FieldRolesInTeams
          name={name}
          disabled={disabled}
          initialValue={initialValue}
          onLoadingFail={setLoadingError}
          validate={validate}
          {...formControlProps}
        />
      )}
    </FormControl>
  );
}
