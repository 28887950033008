import {array, ExtractSchemaType, object, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {jobContractSchema} from 'domain/jobContract/model';
import {salaryRecordSchema} from 'domain/salaryRecord/model';

export const contractRecordSchema = object({
  contract: optional(jobContractSchema),
  activeSalaries: optional(array(salaryRecordSchema)),
});

export type ContractRecord = ExtractSchemaType<typeof contractRecordSchema>;
