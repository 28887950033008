import React from 'react';
import {Counter} from '../../../Counter';
import styles from './index.css';

type Props = {
  children?: React.ReactNode;
  count?: number;
};

export function Title({children, count}: Props) {
  return (
    <div className={styles.titleWrapper}>
      {children && <h3 className={styles.title}>{children}</h3>}
      {count !== undefined && (
        <span className={styles.counter}>
          <Counter value={count} />
        </span>
      )}
    </div>
  );
}
