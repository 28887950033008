import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Checkbox} from '@joomcode/joom-ui/Checkbox';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {Page} from 'components/widgets/Page';
import {stockOptionBulkUploadApi} from 'domain/stockOption/bulkUpload/api';
import {StockOptionBulkUploadResult} from 'domain/stockOption/bulkUpload/model';
import {BulkUploadResultStatus} from 'domain/stockOption/bulkUpload/model/status';
import {convertBulkUploadResult} from 'domain/stockOption/bulkUpload/utils/convertBulkUploadResult';
import {BulkUploadAgreementsPanel} from 'domain/stockOption/bulkUpload/widgets/AgreementsPanel';
import {BulkUploadBuyoutsPanel} from 'domain/stockOption/bulkUpload/widgets/BuyoutsPanel';
import {StockOptionsBulkUploadPanel} from 'domain/stockOption/bulkUpload/widgets/UploadPanel';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/stockOptions/titles';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

export function StockOptionsBulkUploadPage() {
  const intl = useIntl();
  const [uploadResult, setUploadResult] = useState<StockOptionBulkUploadResult | undefined>(undefined);
  const sendDocuments = useBooleanState(false);

  const onReset = useCallback(() => {
    setUploadResult(undefined);
  }, [setUploadResult]);

  const bulkApplyTask = useAsyncTask(async () => {
    if (!uploadResult) {
      toaster.error(intl.formatMessage(messages.nonExistentError));
      return;
    }

    const uploadInput = convertBulkUploadResult(uploadResult);
    if (!uploadInput) {
      toaster.error(intl.formatMessage(messages.malformedInputError));
      return;
    }

    await stockOptionBulkUploadApi
      .apply({input: uploadInput, sendDocuments: sendDocuments.value})
      .then(() => {
        onReset();
        bulkApplyTask.reset();
        toaster.success(intl.formatMessage(messages.success));
      })
      .catch(toaster.interceptThenThrowError);
  }, [uploadResult?.id, setUploadResult, onReset, sendDocuments.value]);

  return (
    <Page title={intl.formatMessage(pageTitles.bulkUpload)}>
      <StockOptionsBulkUploadPanel uploadResult={uploadResult} onUpload={setUploadResult} />
      {uploadResult && (
        <>
          <BulkUploadAgreementsPanel rows={uploadResult.agreementRecords} />
          <BulkUploadBuyoutsPanel rows={uploadResult.buyoutRecords} />
          <div className={styles.indent} />
          <SubmitFormPanel>
            {uploadResult.status === BulkUploadResultStatus.CREATED && (
              <div className={styles.checkbox}>
                <Checkbox
                  label={intl.formatMessage(messages.checkbox)}
                  checked={sendDocuments.value}
                  onChange={sendDocuments.toggle}
                />
              </div>
            )}
            <ButtonGroup spaced size='l'>
              {uploadResult.status === BulkUploadResultStatus.CREATED && (
                <Button
                  kind='primary'
                  intent='primary'
                  onClick={bulkApplyTask.perform}
                  loading={bulkApplyTask.isPerforming}
                >
                  {intl.formatMessage(messages.btnApply)}
                </Button>
              )}
              <Button kind='primary' intent='neutral' onClick={onReset}>
                {intl.formatMessage(messages.btnReset)}
              </Button>
            </ButtonGroup>
          </SubmitFormPanel>
        </>
      )}
    </Page>
  );
}
