import {isNotNullish} from '../../function';

export function getAncestors<T>(node: T, getParent: (node: T) => T | undefined | null): T[] {
  const result: T[] = [];
  let currentNode: T | undefined | null = node;

  while (isNotNullish(currentNode)) {
    currentNode = getParent(currentNode);

    if (currentNode) {
      result.unshift(currentNode);
    }
  }

  return result;
}
