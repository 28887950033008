import {getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {Panel} from '@joomcode/joom-ui/Panel';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {TimeOffRequestForm} from 'domain/timeOff/request/widgets/Form';
import {useUserLoader} from 'domain/user/hooks/useUserLoader';
import {useUserPrivateInfo} from 'domain/user/hooks/useUserPrivateInfo';
import {UserFull} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Redirect, RouteComponentProps, useParams} from 'react-router-dom';
import {pageTitles} from 'routes/timeOff/titles';
import {timeOffUrls} from 'routes/timeOff/urls';
import {usersUrls} from 'routes/users/urls';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function TimeOffCreateRequestOnBehalfPage({selfUser}: Props) {
  const intl = useIntl();
  const {login} = useParams<{login: string}>();
  const user = useUserLoader(login);
  const userPrivateInfo = useUserPrivateInfo(user.data?.id);
  const dataState = getConsolidatedDataState(user.dataState, userPrivateInfo.dataState);
  const fullName = useMemo(
    () =>
      intl.formatMessage(generalMessages.fullName, {
        firstName: user.data?.firstName,
        lastName: user.data?.lastName,
      }),
    [user.data, intl],
  );

  if (login === selfUser.login) {
    return <Redirect to={timeOffUrls.createRequest()} />;
  }

  return (
    <EntityPageStateHandler
      data={user.data && {privateInfo: userPrivateInfo.data, userData: user.data}}
      state={dataState}
      entityError={user.error}
    >
      {({privateInfo, userData}) => (
        <Page
          breadcrumbs={[{title: fullName, href: usersUrls.user({login})}]}
          title={intl.formatMessage(pageTitles.createRequestOnBehalf, {fullName})}
        >
          <Panel withPadding>
            <TimeOffRequestForm
              availableVacationDays={privateInfo?.availableVacationDays}
              availableRemoteWorkDays={privateInfo?.availableRemoteWorkDays}
              user={userData}
            />
          </Panel>
        </Page>
      )}
    </EntityPageStateHandler>
  );
}
