import {DataState} from '@joomcode/deprecated-utils/dataState';
import {validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Autocomplete} from '@joomcode/joom-ui/Select/Autocomplete';
import {useServices} from 'domain/service/hooks/useServices';
import {Service, ServiceId} from 'domain/service/model';
import React from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {messages} from './messages';

export type Props = {
  disabled?: boolean;
  initialValue?: ServiceId;
  label: React.ReactNode;
  name: string;
  submitting: boolean;
};

type ServiceSelectValue = Service | null;

const getServiceId = ({id}: Service) => id;
const getServiceName = (value: ServiceSelectValue) => value?.name ?? '';
const filterByQuery = (value: ServiceSelectValue, query: string) => getServiceName(value).toLowerCase().includes(query);

export function ServiceSelectFormControl({disabled: initialDisabled, initialValue, label, name, submitting}: Props) {
  const intl = useIntl();
  const {services, servicesById, servicesState} = useServices();
  const servicesLoadingError =
    servicesState === DataState.FAILED ? intl.formatMessage(messages.servicesLoadingFailed) : undefined;
  const disabled = initialDisabled || submitting || servicesState !== DataState.LOADED;

  return (
    <Field<ServiceId> name={name} validate={validateFilled} initialValue={initialValue}>
      {({input: {onChange, value, ...input}, meta}) => (
        <FormControl
          disabled={disabled}
          label={label}
          error={getFieldErrorText(meta, {intl}) ?? servicesLoadingError}
          required
        >
          {(formControlProps) => (
            <Autocomplete<Service>
              items={services}
              getItemKey={getServiceId}
              itemToString={getServiceName}
              renderItem={getServiceName}
              filter={filterByQuery}
              disabled={disabled}
              noItemsText={intl.formatMessage(messages.noItems)}
              onChange={(newValue: ServiceSelectValue) => onChange(newValue?.id ?? null)}
              value={servicesById[value] ?? null}
              {...formControlProps}
              {...input}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
}
