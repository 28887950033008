import cn from 'classnames';
import React, {FormEvent} from 'react';
import {FormattedMessage} from 'react-intl';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../../create-locator';
import {Button} from '../../Button';
import {ButtonLocator} from '../../Button/types';
import {ButtonGroup} from '../../ButtonGroup';
import styles from './index.css';

export type FilterControlTestId = ConvertLocatorToTestId<FilterControlLocator>;
export type FilterControlLocator = Locator<{
  cancelButton: ButtonLocator;
  submitButton: ButtonLocator;
}>;

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  onSubmit?: () => void;
  scrollable?: boolean;
  withFooter?: boolean;
  withPadding?: boolean;
  maxWidth?: React.CSSProperties['maxWidth'];
  minHeight?: React.CSSProperties['minHeight'];
} & Partial<Mark<FilterControlLocator>>;

export function Control({
  children,
  onClose,
  onSubmit,
  scrollable = true,
  withFooter = true,
  withPadding = true,
  maxWidth,
  minHeight,
  ...restProperties
}: Props) {
  const locator = createLocator(restProperties);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation(); // from submitting wrapped forms
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <form className={styles.control} onSubmit={handleSubmit}>
      <div
        className={cn(styles.content, {
          [styles.contentPadding]: withPadding,
          [styles.contentScrollable]: scrollable,
        })}
        style={
          {
            '--max-width': maxWidth,
            '--min-height': minHeight,
          } as React.CSSProperties
        }
      >
        {children}
      </div>

      {withFooter && (
        <div className={styles.footer}>
          <ButtonGroup align='right' spaced size='m'>
            <Button intent='neutral' kind='secondary' minWidth='5rem' onClick={onClose} {...locator.cancelButton()}>
              <FormattedMessage
                defaultMessage='Cancel'
                description='[button] Close filter popup'
                id='datafilter-control-cancel-button'
              />
            </Button>

            <Button intent='primary' kind='primary' minWidth='5rem' type='submit' {...locator.submitButton()}>
              <FormattedMessage
                defaultMessage='Save'
                description='[button] Save filter'
                id='datafilter-control-save-button'
              />
            </Button>
          </ButtonGroup>
        </div>
      )}
    </form>
  );
}
