import {getDescendants} from '@joomcode/deprecated-utils/tree/getDescendants';
import {validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {FieldCompensationReviewRequestType} from 'domain/compensationReviewRequest/widgets/FieldType';
import {CompensationReviewRequestLayout} from 'domain/compensationReviewRequest/widgets/Layout';
import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {User, UserId} from 'domain/user/model';
import {FieldUser} from 'domain/user/widgets/Field';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {hints, labels} from './messages';

type Props = {
  onlySubordinates?: boolean;
  submitting: boolean;
  userById: Record<UserId, User>;
};

export function CompensationReviewRequestFormStart({onlySubordinates, submitting, userById}: Props) {
  const intl = useIntl();
  const selfUser = useSelfUser();
  const users = useMemo(() => {
    const allUsers = Object.values(userById);
    const getChildren = (user: User) => allUsers.filter(({managerId}) => managerId === user.id);

    if (!onlySubordinates) {
      return allUsers;
    }

    return selfUser ? getDescendants(selfUser, getChildren) : [];
  }, [onlySubordinates, userById, selfUser]);

  return (
    <Panel>
      <CompensationReviewRequestLayout>
        <Panel.Content withPadding>
          <Grid>
            <Grid.Item xl={6} xs={12}>
              <FormControl
                disabled={submitting}
                label={intl.formatMessage(labels.userId)}
                required
                hint={intl.formatMessage(hints.userId)}
                reserveSpaceForError={false}
              >
                {(formControlProps) => (
                  <FieldUser
                    disabled={submitting}
                    name='userId'
                    validate={validateFilled}
                    users={users}
                    {...formControlProps}
                  />
                )}
              </FormControl>
            </Grid.Item>
            <Grid.Item xl={6} xs={12}>
              <FieldCompensationReviewRequestType
                name='type'
                label={intl.formatMessage(labels.type)}
                required
                onlySubordinates={onlySubordinates}
                reserveSpaceForError={false}
              />
            </Grid.Item>
          </Grid>
        </Panel.Content>
      </CompensationReviewRequestLayout>
    </Panel>
  );
}
