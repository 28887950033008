import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ReactComponent as TrashIcon} from '@joomcode/joom-ui/icons/core/trash.svg';
import {SecurePermission} from 'domain/permission/model/secure';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {deleteStockOptionAgreementFx} from 'domain/stockOption/agreement/stores/main';
import {StockOptionAgreementActionButton} from 'domain/stockOption/agreement/widgets/ActionButton';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import {getFullNameWithPossession} from 'domain/user/utils/getFullNameWithPossession';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  agreement: StockOptionAgreement;
};

export function DeleteStockOptionAgreementButton({agreement}: Props) {
  const acl = useAcl();
  const intl = useIntl();

  const handleClick = useCallback(() => {
    const {id, amount, issueDate, issuer, user} = agreement;
    confirm(
      {
        title: intl.formatMessage(messages.confirmationTitle),
        text: intl.formatMessage(messages.confirmationText, {
          ...getFullNameWithPossession(user),
          amount: <FormattedOptionsAmount amount={amount} />,
          issueDate: <HumanDate value={issueDate} format='short' utc />,
          issuer,
        }),
        confirmationText: intl.formatMessage(messages.confirmationButton),
        intent: 'negative',
        onConfirm: () =>
          deleteStockOptionAgreementFx({id, userId: user.id})
            .then(() => toaster.success(intl.formatMessage(messages.success)))
            .catch(toaster.interceptThenThrowError),
      },
      intl,
    );
  }, [intl, agreement]);

  if (!acl.hasSecurePermission(SecurePermission.STOCK_OPTION_WRITE_ANY)) {
    return null;
  }

  return (
    <StockOptionAgreementActionButton
      icon={<TrashIcon />}
      hint={intl.formatMessage(messages.buttonHint)}
      onClick={handleClick}
    />
  );
}
