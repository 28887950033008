import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {Panel, PanelProps} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {EmptyMessage} from 'components/ui/EmptyMessage';
import {ListedProperty} from 'components/ui/ListedProperty';
import {Section} from 'components/ui/Section';
import {formatGender} from 'domain/gender/model/formatter';
import {ExpirationDate} from 'domain/legalInfo/widgets/ExpirationDate';
import {useUserLegalInfo} from 'domain/user/hooks/useUserLegalInfo';
import {UserId} from 'domain/user/model';
import {stringToDate} from 'models/system/formattedDate';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {getCountryName} from 'utils/country/name';
import {labels, messages} from './messages';
import styles from './styles.css';

type Props = {
  stretchHeight?: PanelProps['stretchHeight'];
  userId: UserId;
};

export function LegalInfoPanel({stretchHeight = false, userId}: Props) {
  const intl = useIntl();
  const renderError = useCallback(() => <Error message={intl.formatMessage(messages.loadingError)} />, [intl]);
  const {data, dataState} = useUserLegalInfo(userId);

  return (
    <Panel
      className={styles.root}
      title={intl.formatMessage(messages.title)}
      withPadding
      withMarginBottom
      stretchHeight={stretchHeight}
    >
      <div className={styles.content}>
        <StateHandler data={data} state={dataState} renderError={renderError}>
          {({legalInfo}) => {
            if (legalInfo === null) {
              return <EmptyMessage>{intl.formatMessage(messages.empty)}</EmptyMessage>;
            }

            const {firstName, lastName, middleName, internationalPassport, localId, addressLine, legalAddress, gender} =
              legalInfo;
            const passportExpirationDate =
              internationalPassport?.expirationDate && stringToDate(internationalPassport.expirationDate);
            const fullName = [firstName, middleName, lastName].filter(Boolean).join(' ');

            return (
              <div className={styles.sections}>
                <Section title={intl.formatMessage(messages.personalData)} padded={false}>
                  {gender ? (
                    <>
                      <ListedProperty label={intl.formatMessage(labels.fullName)}>{fullName}</ListedProperty>
                      <ListedProperty label={intl.formatMessage(labels.gender)}>
                        {formatGender(gender, intl)}
                      </ListedProperty>
                    </>
                  ) : (
                    fullName
                  )}
                </Section>
                {internationalPassport && (
                  <Section title={intl.formatMessage(messages.internationalPassport)} padded={false}>
                    <ListedProperty label={intl.formatMessage(labels.country)}>
                      {getCountryName(internationalPassport.country, intl)}
                    </ListedProperty>
                    <ListedProperty label={intl.formatMessage(labels.number)}>
                      {internationalPassport.number}
                    </ListedProperty>
                    {passportExpirationDate && (
                      <ListedProperty label={intl.formatMessage(labels.expirationDate)}>
                        <ExpirationDate date={passportExpirationDate} />
                      </ListedProperty>
                    )}
                  </Section>
                )}
                <Section title={intl.formatMessage(messages.localId)} padded={false}>
                  <ListedProperty label={intl.formatMessage(labels.country)}>
                    {getCountryName(localId.country, intl)}
                  </ListedProperty>
                  <ListedProperty label={intl.formatMessage(labels.number)}>{localId.number}</ListedProperty>
                </Section>
                {(addressLine || legalAddress) && (
                  <Section title={intl.formatMessage(messages.legalAddress)} padded={false}>
                    {addressLine || legalAddress}
                  </Section>
                )}
              </div>
            );
          }}
        </StateHandler>
      </div>
    </Panel>
  );
}
