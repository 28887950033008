import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {ContextPopup} from '@joomcode/joom-ui/ContextPopup';
import {SalariesExportByDateDropdown} from 'domain/salaryRecord/widgets/ExportByDateDropdown';
import React from 'react';
import {useIntl} from 'react-intl';
import {ReactComponent as CalendarIcon} from './calendar.svg';
import {messages} from './messages';

export function SalariesExportByDateButton() {
  const intl = useIntl();
  const dropdown = usePopupState();

  return (
    <ContextPopup
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      isOpen={dropdown.isOpen}
      onClose={dropdown.close}
      closeOnInsideClick={false}
      content={<SalariesExportByDateDropdown onClose={dropdown.close} />}
      arrow={false}
      placement='bottom'
      distance={8}
    >
      <Button size='m' kind='secondary' intent='neutral' iconLeft={<CalendarIcon />} onClick={dropdown.open}>
        {intl.formatMessage(messages.buttonDownload)}
      </Button>
    </ContextPopup>
  );
}
