import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {identity} from '@joomcode/deprecated-utils/function';
import {FieldCountry, FieldInput, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {Toggle} from '@joomcode/joom-ui/Toggle';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {CountryCode} from 'domain/countryCode/model';
import {CountrySelect} from 'domain/countryCode/widgets/Select';
import {FieldGender} from 'domain/gender/widgets/Field';
import {FieldContractType} from 'domain/jobContract/widgets/FieldContractType';
import {LegalEntity} from 'domain/legalEntity/model';
import {LegalEntityFormControl} from 'domain/legalEntity/widgets/FormControl';
import {LegalInfo} from 'domain/legalInfo/model';
import {LegalAddress} from 'domain/legalInfo/model/address';
import {LegalInfoDiff} from 'domain/legalInfo/model/diff';
import {OfferJobContract} from 'domain/offer/model/jobContract';
import {OfferJobContractDiff} from 'domain/offer/model/jobContract/diff';
import {TimeOffPolicy, timeOffPolicyByCountry} from 'domain/timeOff/policy/model';
import {FieldTimeOffPolicy} from 'domain/timeOff/policy/widgets/Field';
import React, {useCallback, useMemo} from 'react';
import {Field, Form, FormProps} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {useIntl} from 'react-intl';
import {Feature, useFeature} from 'services/features';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {labels, messages} from './messages';
import styles from './styles.css';

type RenderProps = {
  internationalPassportFields: React.ReactNode;
  localIdFields: React.ReactNode;
  legalAddressFields: React.ReactNode;
  nameAndGenderFields: React.ReactNode;
  jobContractFields: React.ReactNode;
  furtherJobContractFields: React.ReactNode;
};

type Props = {
  address?: LegalAddress;
  cancelUrl?: string;
  children: (renderProps: RenderProps) => React.ReactNode;
  jobContractData?: OfferJobContract;
  legalEntities?: LegalEntity[];
  legalInfoData?: LegalInfo;
  onSubmit: (diff: LegalInfoFormState) => Promise<unknown>;
};

export type LegalInfoFormState = {
  legalInfo?: LegalInfoDiff;
  jobContract?: OfferJobContractDiff;
  countryOfTimeOffPolicy?: TimeOffPolicy; // It should be inside jobContract, but apparently we can't pass nested field names to form.change
  hasNoInternationalPassport?: boolean;
};

export function LegalInfoForm({
  address,
  cancelUrl,
  children,
  legalInfoData,
  onSubmit,
  legalEntities,
  jobContractData,
}: Props) {
  const intl = useIntl();
  const onHoldFeature = useFeature(Feature.JOB_CONTRACT_ON_HOLD);
  const formId = useMemo(uuid, []);
  const noPassportSwitchId = useMemo(uuid, []);
  const legalEntityById = useMemo(
    () =>
      arrayToObject(
        legalEntities || [],
        ({id}) => id,
        (entity) => entity,
      ),
    [legalEntities],
  );

  // tweak form state before sending to backend
  const onFormSubmit: FormProps<LegalInfoFormState>['onSubmit'] = useCallback(
    ({hasNoInternationalPassport, legalInfo, jobContract, countryOfTimeOffPolicy}: LegalInfoFormState) =>
      onSubmit({
        jobContract: jobContract
          ? {
              ...jobContract,
              countryOfTimeOffPolicy,
            }
          : undefined,
        legalInfo: legalInfo
          ? {
              ...legalInfo,
              internationalPassport: hasNoInternationalPassport ? null : legalInfo.internationalPassport,
            }
          : undefined,
      }),
    [onSubmit],
  );

  return (
    <Form<LegalInfoFormState> onSubmit={onFormSubmit}>
      {({handleSubmit, submitting, invalid, values, form}) => (
        <form onSubmit={handleSubmit} id={formId}>
          {children({
            jobContractFields: (
              <>
                {legalEntities && (
                  <>
                    <LegalEntityFormControl
                      disabled={submitting}
                      label={intl.formatMessage(labels.legalEntity)}
                      legalEntities={legalEntities}
                      name='jobContract.legalEntityId'
                      required
                      validate={validateFilled}
                      initialValue={jobContractData?.legalEntity?.id}
                    />
                    <FieldTextarea
                      name='jobContract.positionTitle'
                      label={intl.formatMessage(labels.positionTitle)}
                      rows={1}
                      parse={identity}
                      initialValue={jobContractData?.positionTitle}
                      required
                    />
                    <FieldContractType
                      name='jobContract.contractType'
                      label={intl.formatMessage(labels.contractType)}
                      initialValue={jobContractData?.contractType}
                      required
                    />
                    <FieldTimeOffPolicy
                      name='countryOfTimeOffPolicy'
                      label={intl.formatMessage(labels.timeOffPolicy)}
                      initialValue={jobContractData?.countryOfTimeOffPolicy}
                      required={onHoldFeature.isAvailable}
                    />
                    <OnChange name='jobContract.legalEntityId'>
                      {(chosenLegalEntityId) => {
                        const legalEntityCountry = legalEntityById[chosenLegalEntityId]?.countryCode;
                        const timeOffPolicy =
                          (legalEntityCountry &&
                            timeOffPolicyByCountry[legalEntityCountry as keyof typeof timeOffPolicyByCountry]) ||
                          '';
                        form.change('countryOfTimeOffPolicy', timeOffPolicy as TimeOffPolicy);
                      }}
                    </OnChange>
                    <FieldTextarea
                      name='jobContract.comment'
                      label={intl.formatMessage(labels.comment)}
                      rows={2}
                      parse={identity}
                      initialValue={jobContractData?.comment}
                    />
                  </>
                )}
              </>
            ),
            furtherJobContractFields: (
              <>
                {legalEntities && (
                  <LegalEntityFormControl
                    disabled={submitting}
                    label={intl.formatMessage(labels.legalEntity)}
                    legalEntities={legalEntities}
                    name='jobContract.furtherSalaryLegalEntityId'
                    required
                    validate={validateFilled}
                    initialValue={jobContractData?.furtherSalaryLegalEntity?.id}
                  />
                )}
              </>
            ),
            nameAndGenderFields: (
              <>
                <Field<string>
                  name='legalInfo.firstName'
                  validate={validateFilled}
                  initialValue={legalInfoData?.firstName}
                >
                  {({input, meta}) => (
                    <FormControl
                      disabled={submitting}
                      error={getFieldErrorText(meta, {intl})}
                      label={intl.formatMessage(labels.firstName)}
                      required
                    >
                      {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                    </FormControl>
                  )}
                </Field>
                <Field<string>
                  name='legalInfo.lastName'
                  validate={validateFilled}
                  initialValue={legalInfoData?.lastName}
                >
                  {({input, meta}) => (
                    <FormControl
                      disabled={submitting}
                      error={getFieldErrorText(meta, {intl})}
                      label={intl.formatMessage(labels.lastName)}
                      required
                    >
                      {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                    </FormControl>
                  )}
                </Field>
                <Field<string> name='legalInfo.middleName' initialValue={legalInfoData?.middleName}>
                  {({input}) => (
                    <FormControl disabled={submitting} label={intl.formatMessage(labels.middleName)}>
                      {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                    </FormControl>
                  )}
                </Field>
                <FieldGender
                  name='legalInfo.gender'
                  label={intl.formatMessage(labels.gender)}
                  initialValue={legalInfoData?.gender}
                  disabled={submitting}
                  required
                  validate={validateFilled}
                />
              </>
            ),
            internationalPassportFields: (
              <>
                <Field<boolean>
                  name='hasNoInternationalPassport'
                  initialValue={Boolean(legalInfoData && !legalInfoData.internationalPassport)}
                  type='checkbox'
                >
                  {({input: {value, ...input}}) => (
                    <div className={styles.switchField}>
                      <Toggle defaultChecked={value} disabled={submitting} id={noPassportSwitchId} {...input} />
                      <label htmlFor={noPassportSwitchId} className={styles.switchLabel}>
                        {intl.formatMessage(labels.hasNoInternationalPassport)}
                      </label>
                    </div>
                  )}
                </Field>
                {!values.hasNoInternationalPassport && (
                  <>
                    <Field<CountryCode>
                      name='legalInfo.internationalPassport.country'
                      validate={validateFilled}
                      initialValue={legalInfoData?.internationalPassport?.country}
                    >
                      {({input, meta}) => (
                        <FormControl
                          disabled={submitting}
                          error={getFieldErrorText(meta, {intl})}
                          label={intl.formatMessage(labels.countryOfIssue)}
                          required
                        >
                          {(formControlProps) => (
                            <CountrySelect disabled={submitting} {...formControlProps} {...input} />
                          )}
                        </FormControl>
                      )}
                    </Field>
                    <Field<string>
                      name='legalInfo.internationalPassport.number'
                      validate={validateFilled}
                      initialValue={legalInfoData?.internationalPassport?.number}
                    >
                      {({input, meta}) => (
                        <FormControl
                          disabled={submitting}
                          error={getFieldErrorText(meta, {intl})}
                          label={intl.formatMessage(labels.number)}
                          required
                        >
                          {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                        </FormControl>
                      )}
                    </Field>
                    <FieldDate
                      name='legalInfo.internationalPassport.expirationDate'
                      label={intl.formatMessage(labels.expirationDate)}
                      initialValue={legalInfoData?.internationalPassport?.expirationDate}
                    />
                  </>
                )}
              </>
            ),
            localIdFields: (
              <>
                <Field<CountryCode>
                  name='legalInfo.localId.country'
                  validate={validateFilled}
                  initialValue={legalInfoData?.localId.country}
                >
                  {({input, meta}) => (
                    <FormControl
                      disabled={submitting}
                      error={getFieldErrorText(meta, {intl})}
                      label={intl.formatMessage(labels.countryOfIssue)}
                      required
                    >
                      {(formControlProps) => <CountrySelect disabled={submitting} {...formControlProps} {...input} />}
                    </FormControl>
                  )}
                </Field>
                <Field<string>
                  name='legalInfo.localId.number'
                  validate={validateFilled}
                  initialValue={legalInfoData?.localId.number}
                >
                  {({input, meta}) => (
                    <FormControl
                      disabled={submitting}
                      error={getFieldErrorText(meta, {intl})}
                      label={intl.formatMessage(labels.number)}
                      required
                    >
                      {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                    </FormControl>
                  )}
                </Field>
              </>
            ),
            legalAddressFields: (
              <>
                <FieldInput
                  name='legalInfo.address.addressLine1'
                  label={intl.formatMessage(labels.addressLine1)}
                  required
                  initialValue={legalInfoData?.address.addressLine1 ?? address?.addressLine1}
                />
                <FieldInput
                  name='legalInfo.address.addressLine2'
                  label={intl.formatMessage(labels.addressLine2)}
                  required
                  initialValue={legalInfoData?.address.addressLine2 ?? address?.addressLine2}
                />
                <FieldInput
                  name='legalInfo.address.city'
                  label={intl.formatMessage(labels.city)}
                  required
                  initialValue={legalInfoData?.address.city ?? address?.city}
                />
                <FieldInput
                  name='legalInfo.address.region'
                  label={intl.formatMessage(labels.region)}
                  initialValue={legalInfoData?.address.region ?? address?.region}
                />
                <FieldCountry
                  name='legalInfo.address.country'
                  label={intl.formatMessage(labels.country)}
                  required
                  initialValue={legalInfoData?.address.country ?? address?.country}
                />
                <FieldInput
                  name='legalInfo.address.zip'
                  label={intl.formatMessage(labels.zip)}
                  initialValue={legalInfoData?.address.zip ?? address?.zip}
                />
              </>
            ),
          })}
          <SubmitFormPanel>
            <ButtonGroup spaced size='l'>
              <Button
                kind='primary'
                intent='primary'
                type='submit'
                form={formId}
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.btnSubmit)}
              </Button>
              {cancelUrl && (
                <RouterLinkButton size='l' intent='neutral' kind='secondary' to={cancelUrl}>
                  {intl.formatMessage(messages.btnCancel)}
                </RouterLinkButton>
              )}
            </ButtonGroup>
          </SubmitFormPanel>
        </form>
      )}
    </Form>
  );
}
