import {ListedProperty} from 'components/ui/ListedProperty';
import {Section} from 'components/ui/Section';
import {OfferEmploymentInfo} from 'domain/offer/model/employmentInfo';
import {employmentInfoFieldLabels} from 'domain/offer/model/employmentInfo/messages';
import {OfferJobContract} from 'domain/offer/model/jobContract';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {getCountryName} from 'utils/country/name';
import {labels, messages} from './messages';
import styles from './styles.css';

type Props = {
  employmentInfo: OfferEmploymentInfo;
  jobContract?: OfferJobContract;
};

export function OfferEmploymentDetailsSection({employmentInfo, jobContract}: Props) {
  const intl = useIntl();

  return (
    <Section title={intl.formatMessage(messages.title)}>
      <div className={styles.properties}>
        <ListedProperty label={intl.formatMessage(employmentInfoFieldLabels.employmentCountry)}>
          {getCountryName(employmentInfo.employmentCountry, intl)}
        </ListedProperty>
        <ListedProperty label={intl.formatMessage(labels.employmentLegalEntity)}>
          {jobContract?.legalEntity?.title}
        </ListedProperty>
        {jobContract?.positionTitle && (
          <ListedProperty label={intl.formatMessage(labels.positionTitle)}>{jobContract.positionTitle}</ListedProperty>
        )}
        <ListedProperty label={intl.formatMessage(employmentInfoFieldLabels.jobTitle)}>
          {employmentInfo.jobTitle}
        </ListedProperty>
        <ListedProperty label={intl.formatMessage(employmentInfoFieldLabels.manager)}>
          <UserLink user={employmentInfo.manager} withAvatar={false} colored />
        </ListedProperty>
        <ListedProperty label={intl.formatMessage(employmentInfoFieldLabels.division)}>
          {employmentInfo.division}
        </ListedProperty>
        <ListedProperty label={intl.formatMessage(employmentInfoFieldLabels.department)}>
          {employmentInfo.department}
        </ListedProperty>
        {employmentInfo.relocationCountry && (
          <ListedProperty label={intl.formatMessage(employmentInfoFieldLabels.relocationCountry)}>
            {getCountryName(employmentInfo.relocationCountry, intl)}
          </ListedProperty>
        )}
        {jobContract?.furtherSalaryLegalEntity && (
          <ListedProperty label={intl.formatMessage(labels.legalEntityOfFurtherSalary)}>
            {jobContract.furtherSalaryLegalEntity.title}
          </ListedProperty>
        )}
      </div>
    </Section>
  );
}
