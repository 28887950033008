import {CompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/model/access';
import {SecurePermission} from 'domain/permission/model/secure';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';

export function useCompensationReviewRequestReadAccess(): CompensationReviewRequestReadAccess | null {
  const acl = useAcl();
  const compensationReviewRequestFeature = useFeature(Feature.COMPENSATION_REVIEW_REQUEST);

  if (!compensationReviewRequestFeature.isAvailable) {
    return null;
  }

  if (acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY)) {
    return CompensationReviewRequestReadAccess.ANY;
  }

  if (acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_SUBORDINATE)) {
    return CompensationReviewRequestReadAccess.SUBORDINATE;
  }

  return null;
}
