import {DataTable} from '@joomcode/joom-ui/DataTable';
import {CalculatableSalaryRange} from 'domain/compensationReviewRequest/widgets/CalculatableSalaryRange';
import {SalaryRangeHeader} from 'domain/compensationReviewRequest/widgets/SalaryRangeHeader';
import {CountryCode} from 'domain/countryCode/model';
import {Frequency} from 'domain/frequency/model';
import {LevelCode} from 'domain/levelCode/model';
import React from 'react';
import styles from './styles.css';

type Props = {
  levelCode: LevelCode;
  region: CountryCode;
  userFunction: string;
  frequency?: Frequency;
  isOnlySalary: boolean;
  onReset?: () => void;
};

export function CompensationReviewRequestFormSalaryRange({
  levelCode,
  region,
  userFunction,
  frequency,
  isOnlySalary,
  onReset,
}: Props) {
  return (
    <>
      <SalaryRangeHeader frequency={frequency} onReset={onReset} />
      <div className={styles.salaryRange}>
        {isOnlySalary ? (
          <CalculatableSalaryRange
            levelCode={levelCode}
            region={region}
            userFunction={userFunction}
            isAnnual={frequency === Frequency.PER_YEAR}
          />
        ) : (
          <DataTable.CellEmpty />
        )}
      </div>
    </>
  );
}
