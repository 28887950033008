import {ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {CountryCode} from 'domain/countryCode/model';

export const addressSchema = object({
  city: optional(string()),
  comments: optional(string()),
  country: optional(oneOfEnum(CountryCode)),
  postalCode: optional(string()),
  streetAddress: optional(string()),
});

export type Address = ExtractSchemaType<typeof addressSchema>;
