import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonCreate: {
    defaultMessage: 'Create',
    description: '[button] Offers page: create offer button',
  },
  buttonDownloadAll: {
    defaultMessage: 'Download all offers',
    description: '[button] Offers page: download all offers button',
  },
});
