import {array, ExtractSchemaType, number, object} from '@joomcode/deprecated-utils/jsonValidation';
import {QueryRequestPayload} from 'api/types';
import {intranetApi} from 'apiClient';
import {timeOffRequestSchema, TimeOffRequestsSearchFilters} from 'domain/timeOff/request/model';

const countableRequestsSchema = object({
  items: array(timeOffRequestSchema),
  count: number(),
});

export type CountableTimeOffRequests = ExtractSchemaType<typeof countableRequestsSchema>;
export const all = async ({
  filters,
  offset,
  limit,
}: QueryRequestPayload<TimeOffRequestsSearchFilters>): Promise<CountableTimeOffRequests> => {
  const {data} = await intranetApi.post('/v1/timeOffs/requests/getAll', filters, {params: {offset, limit}});

  return countableRequestsSchema.runWithException(data);
};
