import {defineMessages} from 'react-intl';

export const columnNames = defineMessages({
  department: {
    defaultMessage: 'Department',
    description: '[title] Job Info table column name',
  },
  division: {
    defaultMessage: 'Business unit',
    description: '[title] Job Info table column name',
  },
  effectiveDate: {
    defaultMessage: 'Start date',
    description: '[title] Job Info table column name',
  },
  employmentType: {
    defaultMessage: 'Contract',
    description: '[title] Job Info table column name',
  },
  jobTitle: {
    defaultMessage: 'Job title',
    description: '[title] Job Info table column name',
  },
  legalEntity: {
    defaultMessage: 'Legal entity',
    description: '[title] Job Info table column name',
  },
  location: {
    defaultMessage: 'Office',
    description: '[title] Job Info table column name',
  },
  manager: {
    defaultMessage: 'Manager',
    description: '[title] Job Info table column name',
  },
});

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No records',
    description: 'Job Info table: empty message',
  },
  fail: {
    defaultMessage: 'Failed to load records',
    description: 'Job Info table: fail message',
  },
});
