import {DataState} from '@joomcode/deprecated-utils/dataState';
import {noop} from '@joomcode/deprecated-utils/function';
import {clamp} from '@joomcode/deprecated-utils/math/clamp';
import {UploadFn} from 'models/system/upload';
import {useCallback, useEffect, useRef, useState} from 'react';

type UploadOptions<ParamsType, ResultType> = {
  onComplete: (result: ResultType) => void;
  onError?: (error: Error) => void;
  params: ParamsType;
  upload: UploadFn<ParamsType, ResultType>;
};

type UploadState = {
  onAttach: (file: File) => void;
  cancel: () => void;
  progress: number;
  dataState: DataState;
};

export function useFileUpload<ParamsType, ResultType>({
  onComplete,
  onError,
  params,
  upload,
}: UploadOptions<ParamsType, ResultType>): UploadState {
  const cancelUpload = useRef(noop);
  const [progress, setProgress] = useState(0);
  const [dataState, setDataState] = useState<DataState>(DataState.IDLE);
  const handleComplete = useCallback(
    (result: ResultType) => {
      onComplete(result);
      setDataState(DataState.LOADED);
      setProgress(0);
    },
    [onComplete, setDataState, setProgress],
  );
  const onCompleteRef = useRef<(response: ResultType) => void>(handleComplete);

  useEffect(() => {
    setProgress(0);
  }, [upload, params]);

  useEffect(() => {
    onCompleteRef.current = handleComplete;
  }, [handleComplete]);

  const onAttach = useCallback(
    (file: File) => {
      cancelUpload.current();
      setDataState(DataState.LOADING);
      const {cancel} = upload(file, params, {
        onProgress: (event: ProgressEvent) => {
          setProgress(clamp(event.loaded / event.total, 0, 1));
        },
        onCompleteRef,
        onError: (error: Error) => {
          onError?.(error);
          setDataState(DataState.FAILED);
          setProgress(0);
        },
      });
      cancelUpload.current = cancel;
    },
    [upload, params, onComplete, setDataState, setProgress],
  );

  return {
    onAttach,
    cancel: cancelUpload.current,
    progress,
    dataState,
  };
}
