import {HintIcon} from 'components/ui/HintIcon';
import {ServerSecurePermission} from 'domain/permission/model/secure';
import {getSecurePermissionDescription} from 'domain/permission/utils/getSecurePermissionDescription';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  permission: ServerSecurePermission;
  highlightSearch: (s: string) => React.ReactNode;
};

export function SecurePermissionCheckboxLabel({permission, highlightSearch}: Props) {
  const intl = useIntl();
  const description = getSecurePermissionDescription(permission, intl);

  return (
    <>
      <label htmlFor={permission.id}>{highlightSearch(permission.name)}</label>
      {permission.nonGrantable && <HintIcon>{intl.formatMessage(messages.nonGrantablePermissionHint)}</HintIcon>}
      <div className={styles.description}>{highlightSearch(description)}</div>
    </>
  );
}
