import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  effectiveDate: {
    defaultMessage: 'Proposed effective date',
    description: 'Compensation review request form: field label',
  },
  reason: {
    defaultMessage: 'Reason',
    description: 'Compensation review request form: field label',
  },
});

export const hints = defineMessages({
  reason: {
    defaultMessage: 'Provide all relevant details to ensure decision-makers understand the context and urgency.',
    description: 'Compensation review request form: field hint',
  },
});
