import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {OfferId} from 'domain/offer/model';

type Params = {
  id: OfferId;
  lifetimeDays?: number;
};

export const generateToken = async ({id, lifetimeDays}: Params): Promise<string> => {
  const {data} = await intranetApi.post('/v1/offers/generateToken', {lifetimeDays}, {params: {id}});
  return string().runWithException(data);
};
