import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Current information on {onlySubordinates, select, true {reports} other {joomers}}',
    description: '[title] Manager console: subordinate panel title',
  },
  salariesToggle: {
    defaultMessage: 'Show monthly salaries',
    description: 'Manager console: toggle for switching between monthly and other salaries',
  },
});
