import {KnownServerPermission, Permission} from 'domain/permission/model';
import {$self} from 'domain/self/stores/main/state';
import {inVpn} from './inVpn';

type CheckFunction = () => boolean;

const isGranted = (permissions: KnownServerPermission[], withVpn = true): boolean => {
  if (withVpn && !inVpn()) {
    return false;
  }
  return permissions.every((permission) => $self.getState().permissions.has(permission));
};

export const checkByPermission: Record<Permission, CheckFunction> = {
  [Permission.COMPENSATION_CLOSE]: () => isGranted([KnownServerPermission.COMPENSATION_CLOSE], false),
  [Permission.COMPENSATION_CANCEL]: () => isGranted([KnownServerPermission.COMPENSATION_CANCEL], false),
  [Permission.COMPENSATION_DOWNLOAD_ALL]: () => isGranted([KnownServerPermission.COMPENSATION_DOWNLOAD_ALL], false),
  [Permission.COMPENSATION_READ]: () => isGranted([KnownServerPermission.COMPENSATION_READ], false),
  [Permission.COMPENSATION_READ_ALL]: () => isGranted([KnownServerPermission.COMPENSATION_READ_ALL], false),
  [Permission.COMPENSATION_WRITE]: () => isGranted([KnownServerPermission.COMPENSATION_WRITE], false),
  [Permission.COMPENSATION_WRITE_ADMIN]: () => isGranted([KnownServerPermission.COMPENSATION_WRITE_ADMIN], false),

  [Permission.JOB_CONTRACT_READ]: () => isGranted([KnownServerPermission.JOB_CONTRACT_READ], false),
  [Permission.JOB_CONTRACT_SUBORDINATE_READ]: () =>
    isGranted([KnownServerPermission.JOB_CONTRACT_SUBORDINATE_READ], false),
  [Permission.JOB_CONTRACT_WRITE]: () => isGranted([KnownServerPermission.JOB_CONTRACT_WRITE], false),

  [Permission.LEGAL_ENTITY_READ]: () => isGranted([KnownServerPermission.LEGAL_ENTITY_READ], false),
  [Permission.LEGAL_ENTITY_WRITE]: () => isGranted([KnownServerPermission.LEGAL_ENTITY_WRITE], false),

  [Permission.MEMBERSHIP_ADMIN_WRITE]: () => isGranted([KnownServerPermission.MEMBERSHIP_ADMIN_WRITE]),
  [Permission.MEMBERSHIP_WRITE]: () => isGranted([KnownServerPermission.MEMBERSHIP_WRITE]),

  [Permission.OFFER_CHANGE_STATUS]: () => isGranted([KnownServerPermission.OFFER_CHANGE_STATUS]),
  [Permission.OFFER_DOCUMENT_DOWNLOAD]: () => isGranted([KnownServerPermission.OFFER_DOCUMENT_DOWNLOAD]),
  [Permission.OFFER_ENHANCE]: () => isGranted([KnownServerPermission.OFFER_ENHANCE]),
  [Permission.OFFER_GENERATE_TOKEN]: () => isGranted([KnownServerPermission.OFFER_GENERATE_TOKEN]),
  [Permission.OFFER_READ]: () => isGranted([KnownServerPermission.OFFER_READ]),
  [Permission.OFFER_SIGNING_PAGES_DOWNLOAD]: () => isGranted([KnownServerPermission.OFFER_SIGNING_PAGES_DOWNLOAD]),
  [Permission.OFFER_UPDATE_GENERAL_ANY]: () => isGranted([KnownServerPermission.OFFER_UPDATE_GENERAL_ANY]),
  [Permission.OFFER_UPDATE_GENERAL_MY]: () => isGranted([KnownServerPermission.OFFER_UPDATE_GENERAL_MY]),
  [Permission.OFFER_WRITE]: () => isGranted([KnownServerPermission.OFFER_WRITE]),

  [Permission.OFFICE_MAP_SEAT_WRITE]: () => isGranted([KnownServerPermission.OFFICE_MAP_SEAT_WRITE], false),
  [Permission.OFFICE_MAP_SEAT_NUMBERS_SHOW]: () =>
    isGranted([KnownServerPermission.OFFICE_MAP_SEAT_NUMBERS_SHOW], false),
  [Permission.OFFICE_MAP_SEATS_DOWNLOAD_DATA]: () => isGranted([KnownServerPermission.OFFICE_MAP_SEATS_DOWNLOAD_DATA]),
  [Permission.OFFICE_MAP_VIEW]: () =>
    isGranted([KnownServerPermission.OFFICE_POINT_READ, KnownServerPermission.USER_READ_ACTIVE], false),
  [Permission.OFFICE_ROOM_TOKEN_GENERATE]: () => isGranted([KnownServerPermission.OFFICE_ROOM_TOKEN_GENERATE]),

  [Permission.OFFICE_POLICY_RECORD_READ]: () => isGranted([KnownServerPermission.OFFICE_POLICY_RECORD_READ]),
  [Permission.OFFICE_POLICY_RECORD_WRITE]: () => isGranted([KnownServerPermission.OFFICE_POLICY_RECORD_WRITE]),

  [Permission.OUTSTAFF_MEMBERSHIP_ADMIN_WRITE]: () =>
    isGranted([KnownServerPermission.OUTSTAFF_MEMBERSHIP_ADMIN_WRITE]),
  [Permission.OUTSTAFF_MEMBERSHIP_WRITE]: () => isGranted([KnownServerPermission.OUTSTAFF_MEMBERSHIP_WRITE]),

  [Permission.REDIRECT_READ]: () => isGranted([KnownServerPermission.REDIRECT_READ]),
  [Permission.REDIRECT_WRITE]: () => isGranted([KnownServerPermission.REDIRECT_WRITE]),

  [Permission.RESOURCE_READ]: () => isGranted([KnownServerPermission.RESOURCE_READ]),
  [Permission.RESOURCE_WRITE]: () => isGranted([KnownServerPermission.RESOURCE_WRITE]),

  [Permission.ROLE_BINDING_DOWNLOAD]: () => isGranted([KnownServerPermission.ROLE_BINDING_DOWNLOAD]),
  [Permission.ROLE_BINDING_READ]: () => isGranted([KnownServerPermission.ROLE_BINDING_READ]),
  [Permission.ROLE_BINDING_WRITE]: () => isGranted([KnownServerPermission.ROLE_BINDING_WRITE]),
  [Permission.ROLE_BINDING_WRITE_WITH_PROPAGATE]: () =>
    isGranted([KnownServerPermission.ROLE_BINDING_WRITE_WITH_PROPAGATE]),

  [Permission.RTCONF_READ]: () => isGranted([KnownServerPermission.RTCONF_READ]),
  [Permission.RTCONF_WRITE]: () => isGranted([KnownServerPermission.RTCONF_WRITE]),

  [Permission.SELF_JOB_INFO_RECORD_READ]: () => isGranted([KnownServerPermission.SELF_JOB_INFO_RECORD_READ]),

  [Permission.SELF_TIME_OFF_REQUEST_WRITE]: () => isGranted([KnownServerPermission.SELF_TIME_OFF_REQUEST_WRITE], false),
  [Permission.SELF_TIME_OFF_REQUEST_READ]: () => isGranted([KnownServerPermission.SELF_TIME_OFF_REQUEST_READ], false),
  [Permission.SELF_TIME_OFF_BALANCE_RECORD_READ]: () =>
    isGranted([KnownServerPermission.SELF_TIME_OFF_BALANCE_RECORD_READ], false),

  [Permission.SERVICE_READ]: () => isGranted([KnownServerPermission.SERVICE_READ]),
  [Permission.SERVICE_WRITE]: () => isGranted([KnownServerPermission.SERVICE_WRITE]),

  [Permission.SUBORDINATE_TIME_OFF_REQUEST_APPROVE]: () =>
    isGranted([KnownServerPermission.SUBORDINATE_TIME_OFF_REQUEST_APPROVE], false),
  [Permission.SUBORDINATE_TIME_OFF_REQUEST_REJECT]: () =>
    isGranted([KnownServerPermission.SUBORDINATE_TIME_OFF_REQUEST_REJECT], false),

  [Permission.TEAM_ADMIN_WRITE]: () => isGranted([KnownServerPermission.TEAM_ADMIN_WRITE]),
  [Permission.TEAM_CREATE]: () => isGranted([KnownServerPermission.TEAM_CREATE]),
  [Permission.TEAM_DELETE]: () => isGranted([KnownServerPermission.TEAM_DELETE]),
  [Permission.TEAM_READ]: () => isGranted([KnownServerPermission.TEAM_READ], false),
  [Permission.TEAM_ROLE_BINDING_READ]: () =>
    isGranted([KnownServerPermission.TEAM_READ, KnownServerPermission.ROLE_BINDING_READ], false),
  [Permission.TEAM_UPDATE]: () => isGranted([KnownServerPermission.TEAM_UPDATE]),

  [Permission.TIME_OFF_REQUEST_ENABLED]: () =>
    isGranted([KnownServerPermission.SELF_TIME_OFF_REQUEST_READ], false) ||
    isGranted([KnownServerPermission.TIME_OFF_REQUEST_READ], false),
  [Permission.TIME_OFF_REQUEST_WRITE]: () => isGranted([KnownServerPermission.TIME_OFF_REQUEST_WRITE], false),
  [Permission.TIME_OFF_REQUEST_WRITE_ON_BEHALF]: () =>
    isGranted([KnownServerPermission.TIME_OFF_REQUEST_WRITE_ON_BEHALF], false),
  [Permission.TIME_OFF_REQUEST_READ]: () => isGranted([KnownServerPermission.TIME_OFF_REQUEST_READ], false),
  [Permission.TIME_OFF_BALANCE_RECORD_WRITE]: () =>
    isGranted([KnownServerPermission.TIME_OFF_BALANCE_RECORD_WRITE], false),
  [Permission.TIME_OFF_BALANCE_RECORD_READ]: () =>
    isGranted([KnownServerPermission.TIME_OFF_BALANCE_RECORD_READ], false),

  [Permission.USER_ASSIGN_SEAT]: () => isGranted([KnownServerPermission.USER_ASSIGN_SEAT], false),
  [Permission.USER_READ_ACTIVE]: () => isGranted([KnownServerPermission.USER_READ_ACTIVE], false),
  [Permission.USER_READ_ANY]: () => isGranted([KnownServerPermission.USER_READ_ANY], false),
  [Permission.USER_WRITE]: () => isGranted([KnownServerPermission.USER_WRITE]),
  [Permission.OUTSTAFF_USER_READ]: () => isGranted([KnownServerPermission.OUTSTAFF_USER_READ], false),

  [Permission.USER_PRETEND_TO_BE_NORMAL]: () => isGranted([KnownServerPermission.USER_PRETEND_TO_BE_NORMAL]),

  [Permission.USER_EMPLOYMENT_READ]: () => isGranted([KnownServerPermission.USER_EMPLOYMENT_READ]),
  [Permission.USER_EMPLOYMENT_WRITE]: () => isGranted([KnownServerPermission.USER_EMPLOYMENT_WRITE]),

  [Permission.USER_JOB_INFO_RECORD_READ]: () => isGranted([KnownServerPermission.USER_JOB_INFO_RECORD_READ]),
  [Permission.USER_JOB_INFO_RECORD_WRITE]: () => isGranted([KnownServerPermission.USER_JOB_INFO_RECORD_WRITE]),

  [Permission.USER_LEGAL_INFO_DOWNLOAD]: () => isGranted([KnownServerPermission.USER_LEGAL_INFO_DOWNLOAD]),
  [Permission.USER_LEGAL_INFO_READ]: () => isGranted([KnownServerPermission.USER_LEGAL_INFO_READ]),
  [Permission.USER_LEGAL_INFO_WRITE]: () => isGranted([KnownServerPermission.USER_LEGAL_INFO_WRITE]),

  [Permission.USER_MAIN_LEGAL_ENTITY_READ]: () => isGranted([KnownServerPermission.USER_MAIN_LEGAL_ENTITY_READ], false),

  [Permission.USER_PRIVATE_INFO_READ]: () => isGranted([KnownServerPermission.USER_PRIVATE_INFO_READ], false),
  [Permission.USER_PRIVATE_INFO_WRITE]: () => isGranted([KnownServerPermission.USER_PRIVATE_INFO_WRITE]),

  [Permission.USER_INTERVIEW_LINK_READ]: () => isGranted([KnownServerPermission.USER_INTERVIEW_LINK_READ]),

  [Permission.USER_ROLE_READ]: () => isGranted([KnownServerPermission.USER_ROLE_READ]),
  [Permission.USER_ROLE_WRITE]: () => isGranted([KnownServerPermission.USER_ROLE_WRITE]),

  [Permission.USERS_DATA_DOWNLOAD]: () => isGranted([KnownServerPermission.USERS_DATA_DOWNLOAD]),

  [Permission.USERS_PAGE_VIEW]: () => true,
};
