import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {SecurePermission} from 'domain/permission/model/secure';

const buildLinkGeneric = (endpoint: string) => () =>
  intranetApi.getUri({
    url: `${intranetApi.defaults.baseURL}${endpoint}`,
  });

const buildLinkAll = buildLinkGeneric('/v1/secure/users/salaryRecords/downloadAll');
const buildLinkExtendedAll = buildLinkGeneric('/v1/secure/users/salaryRecords/downloadExtendedAll');

export const buildDownloadAllLink = createPermissionDependentHandler(
  [{securePermission: SecurePermission.SALARY_RECORD_DOWNLOAD_EXTENDED, handler: buildLinkExtendedAll}],
  buildLinkAll,
);
