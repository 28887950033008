import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {
  CompensationReviewRequest,
  CompensationReviewRequestId,
  compensationReviewRequestSchema,
} from 'domain/compensationReviewRequest/model';
import {SecurePermission} from 'domain/permission/model/secure';

const getByIdGeneric =
  (endpoint: string) =>
  async (id: CompensationReviewRequestId): Promise<CompensationReviewRequest> => {
    const {data} = await intranetApi.get(endpoint, {params: {id}});

    return compensationReviewRequestSchema.runWithException(data);
  };

const getByIdAsAdmin = getByIdGeneric('/v1/secure/compensationReviewRequests/getByIdAsAdmin');
const getByIdAsManager = getByIdGeneric('/v1/secure/compensationReviewRequests/getByIdAsManager');

export const getById = createPermissionDependentHandler(
  [{securePermission: SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY, handler: getByIdAsAdmin}],
  getByIdAsManager,
);
