import {identity} from '@joomcode/deprecated-utils/function';
import {DataTable} from '@joomcode/joom-ui/DataTable';
import isEqual from 'lodash/isEqual';
import React from 'react';
import styles from './styles.css';

type Props<T> = {
  oldValue?: T;
  newValue?: T;
  render?: (value: T) => React.ReactNode;
  showOldValue?: boolean;
};

export function ReplacedValue<T>({
  oldValue,
  newValue,
  render = identity as (value: T) => React.ReactNode,
  showOldValue = true,
}: Props<T>) {
  return (
    <>
      {newValue ? render(newValue) : <DataTable.CellEmpty />}
      {showOldValue && oldValue && !isEqual(newValue, oldValue) && (
        <div className={styles.oldValue}>{render(oldValue)}</div>
      )}
    </>
  );
}
