import {UserFull} from 'domain/user/model';
import {withAuth} from 'hocs/withAuth';
import React from 'react';
import {RouteConfig} from 'react-router-config';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {usersUrls} from 'routes/users/urls';
import {meUrlPatterns} from './urls';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export const config: RouteConfig = {
  path: meUrlPatterns.root,
  component: withAuth(({selfUser: {login}}: Props) => <Redirect to={usersUrls.user({login})} />),
};
