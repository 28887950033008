const isEmpty = (value: unknown) => value === undefined || value === null || value === '';

export const bothFilled = (nameAmount: string, nameCurrency: string) => (values?: Record<string, unknown>) => {
  if (isEmpty(values?.[nameAmount]) || isEmpty(values?.[nameCurrency])) {
    return {code: 'filledError'};
  }

  return undefined;
};

export const bothFilledOrEmpty = (nameAmount: string, nameCurrency: string) => (values?: Record<string, unknown>) => {
  // for non-required price field it's ok if both subfields are not set (to reset value)
  if (isEmpty(values?.[nameAmount]) !== isEmpty(values?.[nameCurrency])) {
    return {code: 'filledOrEmptyError'};
  }

  return undefined;
};
