import {intranetApi} from 'apiClient';
import {StockOptionAgreementId} from 'domain/stockOption/agreement/model';
import {StockOptionSummary, stockOptionSummarySchema} from 'domain/stockOption/summary/model';
import {UserId} from 'domain/user/model';

export type StockOptionAgreementDeleteConfig = {
  id: StockOptionAgreementId;
  userId: UserId;
};

export const deleteStockOptionAgreement = async (
  params: StockOptionAgreementDeleteConfig,
): Promise<StockOptionSummary> => {
  const {data} = await intranetApi.post('/v1/secure/stockOptions/agreements/delete', undefined, {
    params,
  });

  return stockOptionSummarySchema.runWithException(data);
};
