import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {CompanyUnits} from 'domain/companyUnits/model';
import {getCompanyUnitsFx} from 'domain/companyUnits/stores/main';
import {$companyUnits} from 'domain/companyUnits/stores/main/state';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  units: CompanyUnits | undefined;
  dataState: DataState;
};

type Options = {
  enabled?: boolean;
  force?: boolean;
};

export function useCompanyUnits({enabled = true, force = false}: Options = {}): ReturnShape {
  const {units, dataState} = useStore($companyUnits);

  useEffect(() => {
    if (enabled && (!isLoadingOrLoaded(dataState) || force)) {
      getCompanyUnitsFx().catch(toaster.interceptThenThrowError);
    }
  }, [enabled, force]);

  return {units, dataState};
}
