import {
  array,
  boolean,
  dateFromString,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {MINUTE} from '@joomcode/deprecated-utils/time';
import {coordinatesSchema} from 'domain/officeMap/coordinates/model';

const MEETING_RANGES = [15, 30, 60];

export const officeRoomIdSchema = string();
export type OfficeRoomId = ExtractSchemaType<typeof officeRoomIdSchema>;

export enum RoomAttribute {
  TV = 'tv',
  MARKER_WALL = 'markerWall',
  PROJECTOR = 'projector',
  TABLE = 'table',
  CAMERA = 'camera',
}

export const eventSchema = object({
  start: dateFromString(),
  end: dateFromString(),
});

export const officeRoomSchema = object({
  id: officeRoomIdSchema,
  coordinates: coordinatesSchema,
  text: string(),
  fullText: optional(string()),
  pinAngle: number(),
  disabled: optional(boolean()),
  description: optional(string()),
  capacity: optional(number()),
  attributes: optional(array(oneOfEnum(RoomAttribute))),
  schedule: optional(array(eventSchema)),
  canBook: boolean(),
});

export type OfficeRoom = ExtractSchemaType<typeof officeRoomSchema>;

export const isRoomBusyNow = (room: OfficeRoom): boolean => {
  if (room.schedule === undefined) {
    return false;
  }

  return room.schedule.some((event) => {
    const now = new Date();
    return event.start.getTime() < now.getTime() && event.end.getTime() > now.getTime();
  });
};

export const getAvailableEndTime = (now: Date): Date[] => {
  return MEETING_RANGES.map((offset) => {
    let addMin = offset;
    const newMin = now.getMinutes() + addMin;
    if (newMin % 15 >= 7) {
      addMin += 15 - (newMin % 15);
    } else {
      addMin -= newMin % 15;
    }

    const endTime = now.getTime() + addMin * MINUTE;
    const roundedTime = Math.floor(endTime / MINUTE) * MINUTE;
    return new Date(roundedTime);
  });
};

const meetingsIntersect = (start1: Date, end1: Date, start2: Date, end2: Date): boolean => {
  return Math.max(start1.getTime(), start2.getTime()) <= Math.min(end1.getTime(), end2.getTime());
};

const canBookUntil = (room: OfficeRoom, end: Date): boolean => {
  if (!room.canBook || room.disabled) {
    return false;
  }

  if (room.schedule === undefined) {
    return true;
  }

  const start = new Date();

  return room.schedule.every((event) => !meetingsIntersect(event.start, event.end, start, end));
};

export const isRoomBookable = (room: OfficeRoom): boolean => {
  return getAllBookingOptions(room).length > 0;
};

export const getAllBookingOptions = (room: OfficeRoom): Date[] => {
  return getAvailableEndTime(new Date()).filter((endTime) => canBookUntil(room, endTime));
};

export const isRoomFreeButNonBookable = (room: OfficeRoom): boolean => {
  return room.canBook && !isRoomBusyNow(room) && !isRoomBookable(room);
};
