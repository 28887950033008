import {intranetApi} from 'apiClient';
import {StockOptionAgreementId} from 'domain/stockOption/agreement/model';
import {UserId} from 'domain/user/model';

export type SendToHolderConfig = {
  id: StockOptionAgreementId;
  userId: UserId;
};

export const sendToHolder = async (params: SendToHolderConfig): Promise<void> => {
  return intranetApi.post('/v1/secure/stockOptions/agreements/sendToHolder', undefined, {
    params,
  });
};
