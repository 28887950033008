import {CompensationRequestType} from 'domain/compensations/request/model/type';
import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {CompensationsCreateRequestPage} from 'pages/CompensationsCreateRequest';
import {CompensationsReportPage} from 'pages/CompensationsReport';
import {CompensationsRequestPage} from 'pages/CompensationsRequest';
import {CompensationsRequestsPage} from 'pages/CompensationsRequests';
import React from 'react';
import {RouteConfig} from 'react-router-config';
import {Redirect} from 'react-router-dom';
import {compensationsUrlPatterns, compensationsUrls} from 'routes/compensations/urls';
import {config as notFoundRouteConfig} from 'routes/notFound';

export const config: RouteConfig = {
  component: withAuth(RegularLayout),
  path: compensationsUrlPatterns.root,
  routes: [
    {
      component: withPermission(CompensationsReportPage, Permission.COMPENSATION_WRITE),
      exact: true,
      path: compensationsUrlPatterns.report,
    },
    {
      component: withPermission(CompensationsCreateRequestPage, Permission.COMPENSATION_WRITE),
      exact: true,
      path: compensationsUrlPatterns.createRequest,
    },
    {
      component: withPermission(CompensationsRequestPage, Permission.COMPENSATION_READ),
      exact: true,
      path: compensationsUrlPatterns.request,
    },
    {
      component: withPermission(CompensationsRequestsPage, Permission.COMPENSATION_READ),
      exact: true,
      path: compensationsUrlPatterns.requests,
    },
    {
      component: () => <Redirect to={compensationsUrls.requests({type: CompensationRequestType.BUSINESS_TRIP})} />,
      exact: true,
      path: compensationsUrlPatterns.root,
    },
    notFoundRouteConfig,
  ],
};
