import {isNotBlank} from '@joomcode/deprecated-utils/string/isNotBlank';
import {Key} from '@joomcode/deprecated-utils/keyboard/keys';
import React, {useState, useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {createLocator} from '../../../create-locator';
import {FormControl} from '../../../FormControl';
import {Textarea} from '../../../Textarea';
import {Control} from '../../Control';
import {ControlRenderProps} from '../../types';
import {Options} from './types';
import styles from './index.css';

const messages = defineMessages({
  hintDividerAnyWhitespace: {
    defaultMessage: 'You may pass multiple values. Divider is any whitespace symbol. “Shift+Enter” for new line.',
    description: 'Hint for multiline text form field',
    id: 'joom-ui-data-filter-textarea-list-filter-hint-divider-any-whitespace',
  },
  hintDividerNewLine: {
    defaultMessage: 'You may pass multiple values. Divider is new line (“Shift+Enter”).',
    description: 'Hint for multiline text form field',
    id: 'joom-ui-data-filter-textarea-list-filter-hint-divider-new-line',
  },
});

export function TextareaListControl({value, onClose, onSubmit, name, options}: ControlRenderProps<string[], Options>) {
  const intl = useIntl();
  const locator = createLocator(options);
  const [localValue, setLocalValue] = useState(value?.join('\n') ?? '');
  const [error, setError] = useState<string | undefined>();
  const {validate} = options;

  const handleSubmit = useCallback(async () => {
    const preparedValue = localValue
      .split(options.dividerMode === 'newLine' ? /\n+/ : /\s+/)
      .filter(isNotBlank)
      .map((item) => item.trim());
    const validateResult = await validate?.(preparedValue);

    if (validateResult) {
      setError(validateResult);
      return;
    }

    onSubmit(preparedValue);
  }, [onSubmit, localValue, validate]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === Key.ENTER && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Control maxWidth='20rem' onClose={onClose} onSubmit={handleSubmit} withPadding={false} {...locator.control()}>
      <div className={styles.control}>
        <FormControl
          label=''
          hint={intl.formatMessage(
            options.dividerMode === 'anyWhitespace' ? messages.hintDividerAnyWhitespace : messages.hintDividerNewLine,
          )}
          reserveSpaceForError={false}
        >
          {() => (
            <Textarea
              aria-label={name}
              invalid={!!error}
              onChange={(event) => setLocalValue(event.currentTarget.value)}
              onKeyDown={handleKeyDown}
              placeholder={options.placeholder}
              value={localValue}
              {...locator.textarea()}
            />
          )}
        </FormControl>
        {error && (
          <div className={styles.errorContainer} role='alert'>
            {error}
          </div>
        )}
      </div>
    </Control>
  );
}
