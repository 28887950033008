import {FileDropArea} from '@joomcode/joom-ui/FileDropArea';
import {FilePane} from '@joomcode/joom-ui/FilePane';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {memo} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useFieldWithInitialValue} from '../../components';
import {CommonFieldProps} from '../types';
import styles from './index.css';
import {getFieldErrorText} from '../../utils';
import {
  validateFilled,
  bindValidatorOptions,
  validateFile,
  getFieldValidator,
  composeValidators,
} from '../../validation';
import {consolidatedFileValidator, getFilesFromInputValue} from './utils';

export type FieldFileProps = Omit<JSX.IntrinsicElements['input'], 'ref'> &
  CommonFieldProps<File | File[]> & {
    mimeTypes?: string[];
    maxSize?: number;
    progress?: number | number[];
    /**  Location before drop area could be filled with an additional information about uploading file */
    infoArea?: React.ReactNode;
  };

export const FieldFile = memo(function FieldFile({
  name,
  label,
  labelHint,
  hint,
  mimeTypes,
  maxSize,
  multiple,
  required,
  validate,
  initialValue,
  disabled,
  error: externalError,
  progress,
  reserveSpaceForError,
  infoArea,
}: FieldFileProps) {
  const validateRequired = required ? validateFilled : undefined;
  const validateSingleFile = bindValidatorOptions(validateFile, {mimeTypes, maxSize});
  const validateFileValue = multiple ? consolidatedFileValidator(validateSingleFile) : validateSingleFile;
  const validators = [validate, validateRequired, validateFileValue].filter(isNotNullish);
  const {input, meta} = useFieldWithInitialValue<File | File[]>(name, {
    initialValue,
    validate: validators.length > 0 ? getFieldValidator(composeValidators(...validators)) : undefined,
  });
  const intl = useIntl();
  const form = useForm();
  const error = getFieldErrorText(meta, {intl});
  const onChange = multiple ? input.onChange : (files: File[]) => input.onChange(files[0]);
  const files = getFilesFromInputValue(input.value);
  const controlDisabled = meta.submitting || disabled;

  return (
    <FormControl
      required={required}
      label={label}
      labelHint={labelHint}
      error={externalError || error}
      hint={hint}
      disabled={controlDisabled}
      reserveSpaceForError={reserveSpaceForError}
    >
      {({id}) => (
        <>
          {infoArea && <div className={styles.infoArea}>{infoArea}</div>}

          <div className={styles.dropArea}>
            <FileDropArea
              {...input}
              id={id}
              accept={mimeTypes}
              multiple={multiple}
              disabled={controlDisabled}
              value={files}
              onChange={onChange}
            />
          </div>

          {files.length > 0
            ? files.map((file, index) => (
                <FilePane
                  key={index} // eslint-disable-line react/no-array-index-key
                  file={file}
                  disabled={controlDisabled}
                  onRemove={() => {
                    const newFiles = files.filter((iteratingFile) => file !== iteratingFile);
                    form.change(name, newFiles.length > 0 ? newFiles : undefined);
                  }}
                  progress={Array.isArray(progress) ? progress[index] : progress}
                  hasError={Boolean(externalError || error)}
                >
                  {null}
                </FilePane>
              ))
            : null}
        </>
      )}
    </FormControl>
  );
});
