import {DataState} from '@joomcode/deprecated-utils/dataState';
import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {Measure} from '@joomcode/deprecated-utils/measure/types';
import {validateFile} from '@joomcode/joom-form';
import {formatFileSize} from '@joomcode/joom-ui/FormattedFileSize';
import {ReactComponent as PenIcon} from '@joomcode/joom-ui/icons/core/pen.svg';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import {useFileUpload} from 'hooks/useFileUpload';
import {UploadFn} from 'models/system/upload';
import React, {ChangeEvent, useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {errorMessages, messages} from './messages';
import styles from './styles.css';

type Props<ParamsType, ResultType> = React.PropsWithChildren<{
  editButtonTooltip: string;
  onComplete: (result: ResultType) => void;
  upload: UploadFn<ParamsType, ResultType>;
  uploadParams: ParamsType;
  maxFileSize?: Measure<FileSizeUnit>;
  acceptedMimeTypes?: string[];
}>;

const onError = (error: Error) => toaster.error(error.message);

export function ImageUpload<ParamsType, ResultType>({
  editButtonTooltip,
  children,
  onComplete,
  upload,
  uploadParams,
  maxFileSize,
  acceptedMimeTypes,
}: Props<ParamsType, ResultType>) {
  const intl = useIntl();

  const {onAttach, cancel, progress, dataState} = useFileUpload({
    upload,
    params: uploadParams,
    onComplete,
    onError,
  });

  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }
      const fileError = await validateFile(file, {
        mimeTypes: acceptedMimeTypes,
        maxSize: maxFileSize && fileSizeUtils.convert(maxFileSize, FileSizeUnit.Byte)?.value,
      });
      if (fileError) {
        toaster.error(
          intl.formatMessage(errorMessages[fileError.code as keyof typeof errorMessages], {
            maxFileSize: maxFileSize && formatFileSize(intl, maxFileSize),
          }),
        );
        return;
      }
      onAttach(file);
    },
    [onAttach, maxFileSize, acceptedMimeTypes, intl],
  );

  useEffect(() => () => cancel(), [cancel]);

  return (
    <div className={styles.root}>
      {children}
      {dataState === DataState.LOADING ? (
        <>
          <div className={styles.fade}>
            <Spinner color={styles.spinnerColor} />
            <div className={styles.spinnerComment}>
              {progress < 1
                ? intl.formatMessage(messages.progressStatusUploading)
                : intl.formatMessage(messages.progressStatusProcessing)}
            </div>
          </div>
        </>
      ) : (
        <TooltipAlt placement='right' content={editButtonTooltip}>
          <button className={styles.editButton} type='button'>
            <input
              className={styles.nativeInput}
              type='file'
              onChange={onChange}
              accept={acceptedMimeTypes?.join(',')}
            />
            <PenIcon />
          </button>
        </TooltipAlt>
      )}
    </div>
  );
}
