import {useCrValueText} from 'domain/compaRatio/hooks/useCrValueText';
import {CrResult} from 'domain/compaRatio/model';
import {CrHint} from 'domain/compaRatio/widgets/Hint';
import React from 'react';
import styles from './styles.css';

type Props = {
  cr: CrResult;
  withLabel?: boolean;
  withHint?: boolean;
  coloredIcon?: boolean;
};

export function CrValue({cr, withLabel = false, withHint = true, coloredIcon = false}: Props) {
  const text = useCrValueText(cr, withLabel);

  return (
    <div className={styles.root}>
      {text}
      {withHint && cr.marketLevelPosition && (
        <CrHint marketLevelPosition={cr.marketLevelPosition} colored={coloredIcon} />
      )}
    </div>
  );
}
