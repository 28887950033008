import MarkdownIt from 'markdown-it';
import Renderer from 'markdown-it/lib/renderer';

class MarkdownParser {
  private readonly md: MarkdownIt;

  public constructor() {
    this.md = new MarkdownIt({
      breaks: true,
      linkify: true,
    });
    this.addLinkTargetBlank(this.md);
  }

  /**
   * Source: https://github.com/markdown-it/markdown-it/blob/fbc6b0fed563ba7c00557ab638fd19752f8e759d/docs/architecture.md
   * @param {!Object} md
   */
  private addLinkTargetBlank(md: MarkdownIt): void {
    const defaultRender =
      md.renderer.rules.link_open ||
      function renderer(tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
      };

    const customRender: Renderer.RenderRule = (tokens, idx, options, env, self) => {
      const targetAttrIndex = tokens[idx].attrIndex('target');
      const relAttrIndex = tokens[idx].attrIndex('rel');

      if (targetAttrIndex < 0) {
        tokens[idx].attrPush(['target', '_blank']);
      } else {
        // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-non-null-assertion
        tokens[idx].attrs![targetAttrIndex][1] = '_blank';
      }
      if (relAttrIndex < 0) {
        tokens[idx].attrPush(['rel', 'noopener noreferrer']);
      } else {
        // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-non-null-assertion
        tokens[idx].attrs![relAttrIndex][1] = 'noopener noreferrer';
      }

      // pass token to default renderer.
      return defaultRender(tokens, idx, options, env, self);
    };

    // eslint-disable-next-line no-param-reassign
    md.renderer.rules.link_open = customRender;
  }

  public parse(text: string): string {
    return this.md.render(text);
  }
}

export const markdownParser = new MarkdownParser();
