import {Button} from '@joomcode/joom-ui/Button';
import {ButtonIntent, ButtonKind} from '@joomcode/joom-ui/Button/types';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {confirm, ConfirmProps} from '@joomcode/joom-ui/ConfirmationDialog';
import {ReactComponent as EllipsisIcon} from '@joomcode/joom-ui/icons/core/ellipsisHorizontal.svg';
import {Menu} from '@joomcode/joom-ui/Menu';
import {Permission} from 'domain/permission/model';
import {TimeOffRequest} from 'domain/timeOff/request/model';
import {TimeOffRequestAction} from 'domain/timeOff/request/model/action';
import {TimeOffRequestId} from 'domain/timeOff/request/model/id';
import {TimeOffRequestsTableMode} from 'domain/timeOff/request/model/mode';
import {TimeOffRequestStatus} from 'domain/timeOff/request/model/status';
import {
  approveSubordinateTimeOffRequestFx,
  approveTimeOffRequestFx,
  cancelTimeOffRequestFx,
  rejectSubordinateTimeOffRequestFx,
  rejectTimeOffRequestFx,
  setRecordedInAccountingSystemFx,
} from 'domain/timeOff/request/stores/main';
import {TimeOffType} from 'domain/timeOff/type/model';
import {resetUserPrivateInfo} from 'domain/user/stores/privateInfo';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type ActionConfig = {
  action: TimeOffRequestAction;
  buttonText: string;
  isApplicable: boolean;
  requiresAttention?: boolean;
  callback: (timeOffRequestId: TimeOffRequestId) => Promise<unknown> | void;
};

export type Props = {
  mode: TimeOffRequestsTableMode;
  timeOffRequest: TimeOffRequest;
};

export function ActionsMenu({mode, timeOffRequest}: Props) {
  const acl = useAcl();
  const intl = useIntl();

  const confirmRejection = useCallback(
    (onConfirm: ConfirmProps['onConfirm']) => {
      confirm(
        {
          title: intl.formatMessage(messages.rejectConfirmationTitle),
          text: intl.formatMessage(messages.rejectConfirmationText),
          confirmationText: intl.formatMessage(messages.rejectConfirmationButton),
          intent: 'negative',
          onConfirm,
        },
        intl,
      );
    },
    [intl],
  );

  const actionConfigs: ActionConfig[] = useMemo(
    () => [
      {
        action: TimeOffRequestAction.SET_RECORDED_IN_ACCOUNTING_SYSTEM,
        buttonText: intl.formatMessage(messages.setRecorded),
        isApplicable:
          mode === TimeOffRequestsTableMode.ALL &&
          !timeOffRequest.recordedInAccountingSystem &&
          [TimeOffType.VACATION, TimeOffType.UNPAID_VACATION].includes(timeOffRequest.type) &&
          [TimeOffRequestStatus.REQUESTED, TimeOffRequestStatus.APPROVED].includes(timeOffRequest.status) &&
          acl.hasPermission(Permission.TIME_OFF_REQUEST_WRITE),
        requiresAttention: true,
        callback: (requestId: TimeOffRequestId) =>
          setRecordedInAccountingSystemFx({id: requestId, recordedInAccountingSystem: true}).catch(
            toaster.interceptThenThrowError,
          ),
      },
      {
        action: TimeOffRequestAction.APPROVE,
        buttonText: intl.formatMessage(messages.approve),
        isApplicable:
          mode === TimeOffRequestsTableMode.ALL &&
          timeOffRequest.status === TimeOffRequestStatus.REQUESTED &&
          acl.hasPermission(Permission.TIME_OFF_REQUEST_WRITE),
        callback: (requestId: TimeOffRequestId) =>
          approveTimeOffRequestFx(requestId).catch(toaster.interceptThenThrowError),
      },
      {
        action: TimeOffRequestAction.APPROVE_SUBORDINATE,
        buttonText: intl.formatMessage(messages.approve),
        isApplicable:
          mode === TimeOffRequestsTableMode.SUBORDINATES &&
          timeOffRequest.status === TimeOffRequestStatus.REQUESTED &&
          acl.hasPermission(Permission.SUBORDINATE_TIME_OFF_REQUEST_APPROVE),
        requiresAttention: true,
        callback: (requestId: TimeOffRequestId) =>
          approveSubordinateTimeOffRequestFx(requestId).catch(toaster.interceptThenThrowError),
      },
      {
        action: TimeOffRequestAction.REJECT,
        buttonText: intl.formatMessage(messages.reject),
        isApplicable:
          mode === TimeOffRequestsTableMode.ALL &&
          timeOffRequest.status !== TimeOffRequestStatus.REJECTED &&
          timeOffRequest.status !== TimeOffRequestStatus.CANCELED &&
          acl.hasPermission(Permission.TIME_OFF_REQUEST_WRITE),
        callback: (requestId: TimeOffRequestId) => {
          confirmRejection(() =>
            rejectTimeOffRequestFx(requestId)
              .then(() => {
                resetUserPrivateInfo({userId: timeOffRequest.user.id});
              })
              .catch(toaster.interceptThenThrowError),
          );
        },
      },
      {
        action: TimeOffRequestAction.REJECT_SUBORDINATE,
        buttonText: intl.formatMessage(messages.reject),
        isApplicable:
          mode === TimeOffRequestsTableMode.SUBORDINATES &&
          timeOffRequest.status === TimeOffRequestStatus.REQUESTED &&
          acl.hasPermission(Permission.SUBORDINATE_TIME_OFF_REQUEST_REJECT),
        callback: (requestId: TimeOffRequestId) => {
          confirmRejection(() => rejectSubordinateTimeOffRequestFx(requestId).catch(toaster.interceptThenThrowError));
        },
      },
      {
        action: TimeOffRequestAction.CANCEL,
        buttonText: intl.formatMessage(messages.cancel),
        isApplicable:
          mode === TimeOffRequestsTableMode.MY &&
          acl.hasPermission(Permission.SELF_TIME_OFF_REQUEST_WRITE) &&
          timeOffRequest.isCancelable,
        callback: (requestId: TimeOffRequestId) => {
          confirm(
            {
              title: intl.formatMessage(messages.cancelConfirmationTitle),
              text: intl.formatMessage(messages.cancelConfirmationText),
              confirmationText: intl.formatMessage(messages.cancelConfirmationButton),
              intent: 'negative',
              onConfirm: () =>
                cancelTimeOffRequestFx(requestId)
                  .then(() => {
                    resetUserPrivateInfo({userId: timeOffRequest.user.id});
                  })
                  .catch(toaster.interceptThenThrowError),
            },
            intl,
          );
        },
      },
    ],
    [mode, timeOffRequest, intl],
  );

  const availableActions = useMemo(() => actionConfigs.filter(({isApplicable}) => isApplicable), [actionConfigs]);
  const defaultAction: ActionConfig | undefined = useMemo(() => availableActions[0], [availableActions]);
  const otherActions = useMemo(() => availableActions.slice(1), [availableActions]);
  const buttonAppearance: {intent: ButtonIntent; kind: ButtonKind} = defaultAction?.requiresAttention
    ? {intent: 'primary', kind: 'primary'}
    : {intent: 'neutral', kind: 'secondary'};

  return availableActions.length > 0 ? (
    <ButtonGroup align='right' size='m'>
      <Button onClick={() => defaultAction.callback(timeOffRequest.id)} {...buttonAppearance}>
        {defaultAction.buttonText}
      </Button>
      {otherActions.length > 0 && (
        <Menu
          ariaLabel={intl.formatMessage(messages.menuAriaLabel)}
          disclosure={<Button intent='neutral' kind='secondary' iconLeft={<EllipsisIcon />} />}
        >
          {otherActions.map(({action, buttonText, callback}) => (
            <Menu.Item key={action} onClick={() => callback(timeOffRequest.id)}>
              {buttonText}
            </Menu.Item>
          ))}
        </Menu>
      )}
    </ButtonGroup>
  ) : null;
}
