import cn from 'classnames';
import React from 'react';
import styles from './index.css';

type Props = {
  isActive?: boolean;
  children: (props: {className: string}) => React.ReactNode;
  disabled?: boolean;
};

export function Link({children, disabled = false, isActive}: Props) {
  return (
    <div className={cn(styles.item, styles.itemLink, {[styles.disabled]: disabled})}>
      {children({
        className: cn(styles.link, {
          [styles.linkActive]: isActive,
        }),
      })}
    </div>
  );
}
