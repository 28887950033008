import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CountryCode} from 'domain/countryCode/model';
import {Currency} from 'domain/currency/model';
import faker from 'faker';
import {LegalEntity} from '.';

const countryCodes = getEnumValues(CountryCode);
const currencies = getEnumValues(Currency);

export function mockLegalEntity(data: Partial<LegalEntity> = {}): LegalEntity {
  return {
    id: createMockId(),
    title: faker.company.companyName(),
    countryCode: faker.random.arrayElement(countryCodes),
    currency: faker.random.arrayElement(currencies),
    ...data,
  };
}
