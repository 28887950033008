import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  success: {
    defaultMessage: 'Salary ranges have been uploaded.',
    description: 'Salary ranges upload success',
  },
  nonExistentError: {
    defaultMessage: 'Attempt to apply non-existent salary ranges upload result.',
    description: 'Salary ranges upload applying error',
  },
  malformedInputError: {
    defaultMessage: 'Attempt to apply malformed salary ranges data.',
    description: 'Salary ranges upload applying error',
  },
});
