import cn from 'classnames';
import {RoomSvg} from 'domain/officeMap/room/widgets/RoomSvg';
import {SeatSvg} from 'domain/officeMap/seat/widgets/SeatSvg';
import React from 'react';
import styles from './styles.css';
import svgStyles from './svgStyles.css';

type Props = {
  children: React.ReactNode[];
  showExits: boolean;
  shadowElementId: string;
};

export function OfficeMapSvgLisbon({children, shadowElementId, showExits}: Props) {
  return (
    <svg viewBox='0 0 1168.2 816.9'>
      <defs>
        <filter id={shadowElementId}>
          <feDropShadow dx='-1' dy='1' stdDeviation='3' floodOpacity='0.25' />
        </filter>
      </defs>
      <g className={styles.sample}>
        <SeatSvg.Sample />
        <RoomSvg.Sample />
      </g>

      <g id='walls' style={{filter: `url(#${shadowElementId})`}}>
        <g id='outline'>
          <g>
            <path
              className={svgStyles.st0}
              d='M592.1,419.2l168.1,78.4v292.8c0,2.8-2.2,5-5,5l-231.9,0.2c-1.7,0-3.3-0.9-4.3-2.4l-59.8-96.4
				c-0.9-1.4-1-3.2-0.3-4.8l126.5-270.5C586.6,419.1,589.6,418,592.1,419.2z'
            />
          </g>
          <g>
            <path
              className={svgStyles.st0}
              d='M166.4,420.5L334.5,499v292.8c0,2.8-2.2,5-5,5L97.6,797c-1.7,0-3.3-0.9-4.3-2.4l-59.8-96.4
				c-0.9-1.4-1-3.2-0.3-4.8L159.7,423C160.9,420.5,163.9,419.4,166.4,420.5z'
            />
          </g>
          <g>
            <path
              className={svgStyles.st0}
              d='M594.6,19.8l168.1,78.4V391c0,2.8-2.2,5-5,5l-232,0.2c-1.7,0-3.3-0.9-4.3-2.4l-59.8-96.4
				c-0.9-1.4-1-3.2-0.3-4.8L588,22.2C589.2,19.7,592.1,18.6,594.6,19.8z'
            />
          </g>
          <g>
            <path
              className={svgStyles.st0}
              d='M644.6,43.3l10.7-22.9c1.2-2.5,4.2-3.6,6.7-2.4l97.9,45.9c1.8,0.8,2.9,2.6,2.9,4.5v29.8'
            />
          </g>
          <g>
            <path
              className={svgStyles.st0}
              d='M216.3,444l10.7-22.8c1.2-2.5,4.2-3.6,6.7-2.4l97.8,45.9c1.8,0.8,2.9,2.6,2.9,4.6V499'
            />
          </g>
          <g>
            <path
              className={svgStyles.st0}
              d='M830.2,692.1l126.5-270.4c1.2-2.5,4.2-3.6,6.7-2.4l50.7,23.9l10.8-23c1.2-2.5,4.2-3.6,6.7-2.4l97.1,45.6
				c1.8,0.8,2.9,2.6,2.9,4.5v322.5c0,2.8-2.2,5-5,5l-231.9,0.2c-1.7,0-3.4-0.9-4.3-2.4l-59.8-96.4
				C829.6,695.4,829.5,693.6,830.2,692.1z'
            />
          </g>
          <g>
            <path
              className={svgStyles.st0}
              d='M642,442.7l10.7-22.9c1.2-2.5,4.1-3.6,6.6-2.4l97.9,46c1.8,0.8,2.9,2.6,2.9,4.5v29.8'
            />
          </g>
          <g>
            <path
              className={svgStyles.st0}
              d='M214.8,40.2l10.7-22.9c1.2-2.5,4.2-3.6,6.7-2.4l97.9,45.9c1.8,0.8,2.9,2.6,2.9,4.5v29.8'
            />
          </g>
          <g>
            <path
              className={svgStyles.st0}
              d='M169.9,19.4L338,97.8v292.8c0,2.8-2.2,5-5,5l-232,0.2c-1.7,0-3.3-0.9-4.3-2.4L37.1,297
				c-0.9-1.4-1-3.2-0.3-4.8L163.3,21.8C164.4,19.3,167.4,18.2,169.9,19.4z'
            />
          </g>
        </g>
        <g>
          <line className={svgStyles.st1} x1='720.1' y1='139.7' x2='687.7' y2='139.7' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1068.4' y1='645.9' x2='1068.6' y2='645.9' />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M592.4,34.5c0.7-1.5,2.5-2.1,4-1.4l152.3,71c1.5,0.7,2.7,2.6,2.7,4.3v275.3c0,1.6-1.3,3-3,3l-218.1,0.2
			c-1.7,0-3.7-1.1-4.6-2.5L472,297.8c-0.9-1.4-1-3.8-0.3-5.3L592.4,34.5z'
          />
        </g>
        <g>
          <line className={svgStyles.st1} x1='751.5' y1='291.5' x2='683.4' y2='291.5' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M751.5,246.3h-65c-1.6,0-3-1.4-3-3v-45.1' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M751.5,139.6h-65.1c-1.7,0-3,1.4-3,3v35.6c0,1.7-0.5,4.3-1,5.8l-4.9,13.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='683.4' y1='139.6' x2='689.3' y2='127' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M694.4,130l1.2-13.4c0.2-1.6,1.3-3.9,2.5-5l9.8-9' />
        </g>
        <g>
          <g>
            <path
              className={svgStyles.st1}
              d='M605.3,275.3l1.7-3.5c0.7-1.5,0.1-3.3-1.4-4l-9.6-4.6c-1.5-0.7-3.3-0.1-4,1.4l-8,17.2c-0.7,1.5,0,3.3,1.4,4
				l9.3,4.4c1.5,0.7,3.6,0.3,4.8-0.9l10.2-10.9'
            />
          </g>
        </g>
        <g>
          <path className={svgStyles.st1} d='M571.9,78.3l47.4,22.1c1.5,0.7,2.8-0.1,3-1.7l1.2-13.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M554.9,114.8l47.4,22.1c1.5,0.7,2.8-0.1,3-1.7l1.2-13.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M537.9,150.9l47.4,22.1c1.5,0.7,2.8-0.1,3-1.7l1.2-13.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M521.1,187.2l47.4,22.1c1.5,0.7,2.8-0.1,3-1.7l1.2-13.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M504,223.6l47.4,22.1c1.5,0.7,2.8-0.1,3-1.7l1.2-13.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M478.3,278.4l47.4,22.1c1.5,0.7,2.8-0.1,3-1.7l1.2-13.3' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='611.1' y1='125.2' x2='622' y2='101.7' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='628.4' y1='88' x2='643.7' y2='55.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='594' y1='161.6' x2='605' y2='138.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='577.1' y1='197.8' x2='588' y2='174.3' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='560.2' y1='234' x2='571.2' y2='210.6' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='535' y1='288.1' x2='554.1' y2='247.1' />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M166.4,434.5c0.7-1.5,2.5-2.1,4-1.4l152.3,71c1.5,0.7,2.7,2.6,2.7,4.3v275.3c0,1.7-1.4,3-3,3l-218.1,0.1
			c-1.7,0-3.7-1.1-4.6-2.5L46,697.7c-0.9-1.4-1-3.8-0.3-5.3L166.4,434.5z'
          />
        </g>
        <g>
          <line className={svgStyles.st1} x1='325.5' y1='696.2' x2='256.5' y2='696.2' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M325.5,646.5h-65c-1.6,0-3-1.3-3-3v-45.1' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M325.5,539.9h-65.1c-1.6,0-3,1.4-3,3v35.6c0,1.7-0.5,4.3-1,5.8l-4.9,13.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='269.7' y1='680.4' x2='269.7' y2='646.5' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='269.7' y1='696.2' x2='275.6' y2='680.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M171.5,433.6l-6.2,13.3c-0.7,1.5-0.4,3.7,0.8,4.9l9.1,9.7' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M194.1,444.2l-6.2,13.3c-0.7,1.5-0.3,3.7,0.8,4.9l9,9.7' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M217.3,454.9l-6.2,13.4c-0.7,1.5-0.3,3.7,0.8,4.9l9.1,9.7' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M240.1,465.6l-6.2,13.4c-0.7,1.5-0.3,3.7,0.8,4.9l9,9.7' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='257.4' y1='539.9' x2='263.3' y2='527.2' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M268.5,530.3l1.2-13.4c0.1-1.6,1.3-3.9,2.5-5l9.8-9' />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M247.9,487.8c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M593.2,434.1c0.7-1.5,2.5-2.1,4-1.4l152.3,71c1.5,0.7,2.7,2.6,2.7,4.3v275.3c0,1.6-1.4,3-3,3l-218.1,0.2
			c-1.6,0-3.7-1.1-4.6-2.5l-53.7-86.6c-0.9-1.4-1-3.8-0.3-5.3L593.2,434.1z'
          />
        </g>
        <g>
          <line className={svgStyles.st1} x1='752.2' y1='695.8' x2='683.2' y2='695.8' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M752.2,646.1h-65c-1.7,0-3-1.4-3-3V598' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M752.2,539.4h-65.1c-1.7,0-3,1.3-3,3V578c0,1.7-0.5,4.3-1,5.8l-4.9,13.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='696.4' y1='679.9' x2='696.4' y2='646.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='696.4' y1='695.8' x2='702.3' y2='679.9' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='684.1' y1='539.4' x2='690.1' y2='526.8' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M695.2,529.8l1.2-13.3c0.2-1.6,0.9-4.2,1.6-5.7l12.1-25.5' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M598.3,433.1l-6.2,13.4c-0.7,1.5-0.3,3.7,0.8,4.9l9,9.7' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M620.9,443.7l-6.2,13.4c-0.7,1.5-0.3,3.7,0.8,4.9l9.1,9.7' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M644,454.5l-6.2,13.4c-0.7,1.5-0.4,3.7,0.8,4.9l9,9.7' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M666.8,465.1l-6.2,13.4c-0.7,1.5-0.3,3.7,0.8,4.9l9,9.7' />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M998.8,476.8l-97.2,209.4c-0.7,1.5-0.5,3.9,0.3,5.3l24.3,38.9c0.9,1.4,2.9,2.5,4.6,2.5H1120c1.7,0,3-1.4,3-3
			V501.1'
          />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1123.1' y1='501.1' x2='1012.3' y2='449.5' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1123.1' y1='501.1' x2='1123.2' y2='501.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1054.3' y1='663.1' x2='1054.3' y2='733' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1054.2' y1='539.5' x2='1054.2' y2='523.2' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M1059.4,523l1.2-13.3c0.2-1.6,0.9-4.2,1.6-5.7l12.1-25.5' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M1122.3,645.9h-65c-1.7,0-3-1.3-3-3v-45.1' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M1122.3,539.2h-65.1c-1.7,0-3,1.4-3,3v35.6c0,1.7-0.5,4.3-1,5.8l-4.9,13.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1060.2' y1='661.8' x2='1054.3' y2='645.9' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1054.3' y1='682.7' x2='1038.4' y2='688.5' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M1038.3,682.7h-27.4c-1.7,0-4.3,0.5-5.8,1l-13.1,4.8' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1007.9' y1='682.7' x2='1007.9' y2='733' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M991.7,682.7h-27.4c-1.7,0-4.3,0.5-5.8,1l-13.1,4.8' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='961.2' y1='682.7' x2='961.2' y2='733' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='945.3' y1='682.4' x2='903.4' y2='682.4' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M998.8,601l9.6-9.4c1.2-1.2,0.9-2.7-0.6-3.4L958.3,565' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M1002.9,605.7l-9.4,20.1c-0.7,1.5-2.5,2.1-4,1.4L940,604' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1093.5' y1='505' x2='1100.2' y2='491' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1093.5' y1='505' x2='1093.7' y2='505' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='1123.1' y1='520.2' x2='1093.5' y2='520.2' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M1099.4,504.3l-4.9,13.1c-0.6,1.5-0.6,4.1,0,5.6l4.9,13.1' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M1004.7,476.4l1.2-13.3c0.2-1.6,1-4.2,1.8-5.6l4.6-7.9' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='751.5' y1='124.1' x2='697.2' y2='113.4' />
        </g>

        <rect
          x='582.8'
          y='686.5'
          transform='matrix(0.4236 -0.9058 0.9058 0.4236 -287.9709 932.7837)'
          className={svgStyles.st2}
          width='12.4'
          height='12.4'
        />

        <rect
          x='581'
          y='287.4'
          transform='matrix(0.4236 -0.9058 0.9058 0.4236 72.528 701.1268)'
          className={svgStyles.st2}
          width='12.4'
          height='12.4'
        />
        <g>
          <path
            className={svgStyles.st1}
            d='M616.1,252.8l1.7-3.5c0.7-1.5,0.1-3.3-1.4-4l-9.6-4.6c-1.5-0.7-3.3-0.1-4,1.4l-8,17.2c-0.7,1.5,0,3.3,1.4,4
			l9.3,4.4c1.5,0.7,3.6,0.3,4.8-0.9l10.2-10.9'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M626.6,230.1l1.7-3.5c0.7-1.5,0.1-3.3-1.4-4l-9.6-4.6c-1.5-0.7-3.3-0.1-4,1.4l-8,17.2c-0.7,1.5,0,3.3,1.4,4
			l9.3,4.4c1.5,0.7,3.6,0.3,4.8-0.9l10.2-10.9'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M652.8,77.7l3.5,1.7c1.5,0.7,3.3,0.1,4-1.4l4.6-9.6c0.7-1.5,0.1-3.3-1.4-4l-17.2-8c-1.5-0.7-3.3,0-4,1.4
			l-4.4,9.3c-0.7,1.5-0.3,3.6,0.9,4.8l10.9,10.2'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M227.6,478.4c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M204.9,467.4c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M181.6,456.6c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M674.9,487.5c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M654.2,477.5c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M631.5,466.9c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M608.2,456.3c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <text transform='matrix(1 0 0 1 480.9604 75.361)' className={cn(svgStyles.st3, svgStyles.st4)}>
          4
        </text>
        <text transform='matrix(1 0 0 1 503.1604 75.361)' className={cn(svgStyles.st3, svgStyles.st5)}>
          {' '}
        </text>
        <text transform='matrix(1 0 0 1 458.8604 100.561)' className={cn(svgStyles.st3, svgStyles.st5)}>
          FLOOR
        </text>
        <text transform='matrix(1 0 0 1 52.7732 475.4224)' className={cn(svgStyles.st3, svgStyles.st4)}>
          5
        </text>
        <text transform='matrix(1 0 0 1 74.9732 475.4224)' className={cn(svgStyles.st3, svgStyles.st5)}>
          {' '}
        </text>
        <text transform='matrix(1 0 0 1 30.6732 500.6224)' className={cn(svgStyles.st3, svgStyles.st5)}>
          FLOOR
        </text>
        <text transform='matrix(1 0 0 1 483.1114 470.3258)' className={cn(svgStyles.st3, svgStyles.st4)}>
          6
        </text>
        <text transform='matrix(1 0 0 1 505.3114 470.3258)' className={cn(svgStyles.st3, svgStyles.st5)}>
          {' '}
        </text>
        <text transform='matrix(1 0 0 1 461.0114 495.5258)' className={cn(svgStyles.st3, svgStyles.st5)}>
          FLOOR
        </text>
        <text transform='matrix(1 0 0 1 850.1965 470.4446)' className={cn(svgStyles.st3, svgStyles.st4)}>
          7
        </text>
        <text transform='matrix(1 0 0 1 872.3965 470.4446)' className={cn(svgStyles.st3, svgStyles.st5)}>
          {' '}
        </text>
        <text transform='matrix(1 0 0 1 828.0964 495.6446)' className={cn(svgStyles.st3, svgStyles.st5)}>
          FLOOR
        </text>
        <g>
          <path
            className={svgStyles.st1}
            d='M167,34.5c0.7-1.5,2.5-2.1,4-1.4l152.3,71c1.5,0.7,2.7,2.6,2.7,4.3v275.3c0,1.6-1.4,3-3,3l-218,0.2
			c-1.7,0-3.7-1.1-4.6-2.5l-53.7-86.6c-0.9-1.4-1-3.8-0.3-5.3L167,34.5z'
          />
        </g>
        <g>
          <path className={svgStyles.st1} d='M206.5,51.4l-6.2,13.4c-0.7,1.5-0.3,3.7,0.8,4.9l9.1,9.7' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M229.4,62l-6.2,13.4c-0.7,1.5-0.3,3.7,0.8,4.9l9,9.7' />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M237.2,84.3c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <g>
          <path
            className={svgStyles.st1}
            d='M216.9,74.9c1.8,0.8,2.6,1.2,2.6,1.2c1.5,0.7,3.3,0.1,4-1.4c0,0,3.5-7.3,5.9-12.3'
          />
        </g>
        <text transform='matrix(1 0 0 1 52.8013 75.4223)' className={cn(svgStyles.st3, svgStyles.st4)}>
          3
        </text>
        <text transform='matrix(1 0 0 1 75.0013 75.4223)' className={cn(svgStyles.st3, svgStyles.st5)}>
          {' '}
        </text>
        <text transform='matrix(1 0 0 1 30.7013 100.6223)' className={cn(svgStyles.st3, svgStyles.st5)}>
          FLOOR
        </text>
        <g>
          <line className={svgStyles.st1} x1='208.4' y1='172.3' x2='121.6' y2='132.7' />
        </g>
        <circle className={svgStyles.st1} cx='169.9' cy='290.8' r='9.1' />
        <g>
          <path className={svgStyles.st1} d='M145.3,386.4V332c0-1.6,1.3-3,3-3h45.1' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='261.7' y1='330.5' x2='261.7' y2='386.4' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M246.8,330.5h-27.4c-1.7,0-4.2,0.5-5.8,1.1l-13.1,5.4' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='216.4' y1='331.7' x2='216.4' y2='386.4' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M326.1,328.9h-61.4c-1.6,0-3-1.4-3-3v-17.3' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M280.6,134.5l1.2-12.4c0.2-1.6,0.9-4.2,1.6-5.7l12-25.4' />
        </g>
        <g>
          <line className={svgStyles.st1} x1='257.5' y1='167.6' x2='263.4' y2='154.9' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M325.5,167.6h-65.1c-1.6,0-3,1.3-3,3v35.6c0,1.7-0.5,4.3-1,5.8l-4.9,13.1' />
        </g>
        <g>
          <path className={svgStyles.st1} d='M325.5,274.2h-60.9c-1.6,0-3-1.4-3-3v-45.1' />
        </g>
      </g>

      {showExits && (
        <g id='exits'>
          <g id='exit5floor_00000076565194487869230090000001362435186582115756_'>
            <g>
              <rect x='265' y='574.7' className={svgStyles.st6} width='54' height='27' />
              <path
                className={svgStyles.st7}
                d='M319,600.7c0,0.5-0.5,1-1,1h-52c-0.5,0-1-0.5-1-1v-25c0-0.5,0.5-1,1-1h52c0.5,0,1,0.5,1,1V600.7z'
              />
            </g>
            <g className={svgStyles.st8}>
              <path
                className={svgStyles.st9}
                d='M270,595.2v-15h10.9v1.8H272v4.6h8.3v1.8H272v5.1h9.2v1.8L270,595.2L270,595.2z'
              />
              <path
                className={svgStyles.st9}
                d='M282.5,595.2l5.8-7.8l-5.1-7.2h2.4l2.7,3.9c0.6,0.8,1,1.4,1.2,1.8c0.3-0.5,0.7-1.1,1.2-1.7l3-4h2.2l-5.3,7.1
				l5.7,7.9h-2.5l-3.8-5.4c-0.2-0.3-0.4-0.6-0.7-1c-0.3,0.5-0.6,0.9-0.7,1.1l-3.8,5.2h-2.3L282.5,595.2L282.5,595.2z'
              />
              <path className={svgStyles.st9} d='M298.4,595.2v-15h2v15H298.4z' />
              <path className={svgStyles.st9} d='M307.7,595.2v-13.3h-5v-1.8h11.9v1.8h-5v13.3H307.7z' />
            </g>
          </g>
          <g id='exit4floor_00000056386355027050479950000016425044379116004757_'>
            <g>
              <rect x='692.5' y='174.5' className={svgStyles.st6} width='54' height='27' />
              <path
                className={svgStyles.st7}
                d='M746.5,200.5c0,0.6-0.5,1-1,1h-52c-0.5,0-1-0.4-1-1v-25c0-0.6,0.5-1,1-1h52c0.5,0,1,0.4,1,1V200.5z'
              />
            </g>
            <g className={svgStyles.st8}>
              <path
                className={svgStyles.st9}
                d='M697.5,194.9v-15h10.9v1.8h-8.9v4.6h8.3v1.8h-8.3v5.1h9.2v1.8h-11.2V194.9z'
              />
              <path
                className={svgStyles.st9}
                d='M710,194.9l5.8-7.8l-5.1-7.2h2.4l2.7,3.9c0.6,0.8,1,1.4,1.2,1.8c0.3-0.5,0.7-1.1,1.2-1.7l3-4h2.2l-5.3,7.1
				l5.7,7.9h-2.5l-3.8-5.4c-0.2-0.3-0.4-0.6-0.7-1c-0.3,0.5-0.6,0.9-0.7,1.1l-3.8,5.2L710,194.9L710,194.9z'
              />
              <path className={svgStyles.st9} d='M725.9,194.9v-15h2v15H725.9z' />
              <path className={svgStyles.st9} d='M735.2,194.9v-13.3h-5v-1.8h11.9v1.8h-5v13.3H735.2z' />
            </g>
          </g>
          <g id='exit7floor_00000163787785653509700400000002297996215065787818_'>
            <g>
              <rect x='1062.3' y='577.6' className={svgStyles.st6} width='54' height='27' />
              <path
                className={svgStyles.st7}
                d='M1116.3,603.6c0,0.5-0.5,1-1,1h-52c-0.6,0-1-0.5-1-1v-25c0-0.6,0.4-1,1-1h52c0.5,0,1,0.4,1,1V603.6z'
              />
            </g>
            <g className={svgStyles.st8}>
              <path
                className={svgStyles.st9}
                d='M1067.4,598v-15h10.9v1.8h-8.9v4.6h8.3v1.8h-8.3v5.1h9.2v1.8L1067.4,598L1067.4,598z'
              />
              <path
                className={svgStyles.st9}
                d='M1079.8,598l5.8-7.8l-5.1-7.2h2.4l2.7,3.9c0.6,0.8,1,1.4,1.2,1.8c0.3-0.5,0.7-1.1,1.2-1.7l3-4h2.2l-5.3,7.1
				l5.7,7.9h-2.5l-3.8-5.4c-0.2-0.3-0.4-0.6-0.7-1c-0.3,0.5-0.6,0.9-0.7,1.1l-3.8,5.2h-2.3L1079.8,598L1079.8,598z'
              />
              <path className={svgStyles.st9} d='M1095.7,598v-15h2v15H1095.7z' />
              <path className={svgStyles.st9} d='M1105,598v-13.3h-5V583h11.9v1.8h-5V598H1105z' />
            </g>
          </g>
          <g id='exit6floor_00000179605850671387895710000009203978703216802944_'>
            <g>
              <rect x='691.7' y='577.7' className={svgStyles.st6} width='54' height='27' />
              <path
                className={svgStyles.st7}
                d='M745.7,603.7c0,0.5-0.5,1-1,1h-52c-0.5,0-1-0.5-1-1v-25c0-0.5,0.5-1,1-1h52c0.5,0,1,0.5,1,1V603.7z'
              />
            </g>
            <g className={svgStyles.st8}>
              <path
                className={svgStyles.st9}
                d='M696.8,598.1v-15h10.9v1.8h-8.9v4.6h8.3v1.8h-8.3v5.1h9.2v1.8L696.8,598.1L696.8,598.1z'
              />
              <path
                className={svgStyles.st9}
                d='M709.2,598.1l5.8-7.8l-5.1-7.2h2.4l2.7,3.9c0.6,0.8,1,1.4,1.2,1.8c0.3-0.5,0.7-1.1,1.2-1.7l3-4h2.2l-5.3,7.1
				l5.7,7.9h-2.5l-3.8-5.4c-0.2-0.3-0.4-0.6-0.7-1c-0.3,0.5-0.6,0.9-0.7,1.1l-3.8,5.2h-2.3L709.2,598.1L709.2,598.1z'
              />
              <path className={svgStyles.st9} d='M725.1,598.1v-15h2v15H725.1z' />
              <path className={svgStyles.st9} d='M734.4,598.1v-13.3h-5V583h11.9v1.8h-5v13.3H734.4z' />
            </g>
          </g>
          <g id='exit5floor_00000144302699038834731030000009857395869808474007_'>
            <g>
              <rect x='265.1' y='202.5' className={svgStyles.st6} width='54' height='27' />
              <path
                className={svgStyles.st7}
                d='M319.1,228.5c0,0.6-0.5,1-1,1h-52c-0.5,0-1-0.4-1-1v-25c0-0.6,0.5-1,1-1h52c0.5,0,1,0.4,1,1V228.5z'
              />
            </g>
            <g className={svgStyles.st8}>
              <path
                className={svgStyles.st9}
                d='M270.1,222.9v-15H281v1.8h-8.9v4.6h8.3v1.8h-8.3v5.1h9.2v1.8h-11.2V222.9z'
              />
              <path
                className={svgStyles.st9}
                d='M282.6,222.9l5.8-7.8l-5.1-7.2h2.4l2.7,3.9c0.6,0.8,1,1.4,1.2,1.8c0.3-0.5,0.7-1.1,1.2-1.7l3-4h2.2l-5.3,7.1
				l5.7,7.9h-2.5l-3.8-5.4c-0.2-0.3-0.4-0.6-0.7-1c-0.3,0.5-0.6,0.9-0.7,1.1l-3.8,5.2L282.6,222.9L282.6,222.9z'
              />
              <path className={svgStyles.st9} d='M298.5,222.9v-15h2v15H298.5z' />
              <path className={svgStyles.st9} d='M307.8,222.9v-13.3h-5v-1.8h11.9v1.8h-5v13.3H307.8z' />
            </g>
          </g>
        </g>
      )}
      <g id='seats'>{children}</g>
    </svg>
  );
}
