import {ColumnId} from 'domain/team/model/roleBindingsTableColumnId';
import {defineMessages} from 'react-intl';

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.SERVICE]: {
    defaultMessage: 'Service',
    description: 'Team role bindings table column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Status',
    description: 'Team role bindings table column name',
  },
  [ColumnId.ROLE]: {
    defaultMessage: 'Role',
    description: 'Team role bindings table column name',
  },
  [ColumnId.RESOURCE]: {
    defaultMessage: 'Resource',
    description: 'Team role bindings table column name',
  },
  [ColumnId.ACTIONS]: {
    defaultMessage: 'Actions',
    description: 'Team role bindings table column name',
  },
  [ColumnId.PROPAGATES]: {
    defaultMessage: 'Propagates',
    description: 'Team role bindings table column name',
  },
  [ColumnId.TEAM]: {
    defaultMessage: 'Inherited from team',
    description: 'Team role bindings table column name',
  },
});

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No accesses',
    description: 'Team role bindings table: empty',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load accesses',
    description: 'Team role bindings table: error',
  },
});
