import {
  boolean,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
} from '@joomcode/deprecated-utils/jsonValidation';
import {addressSchema} from 'domain/address/model';
import {emergencyContactSchema} from 'domain/emergencyContact/model';
import {legalEntitySchema} from 'domain/legalEntity/model';
import {ShirtSize} from 'domain/shirtSize/model';
import {userIdSchema} from 'domain/user/model';
import {formattedDateSchema, formattedMonthAndDaySchema} from 'models/system/formattedDate';

export const userPrivateInfoSchema = object({
  address: optional(addressSchema),
  availableRemoteWorkDays: number(),
  availableVacationDays: number(),
  dateOfBirth: optional(formattedDateSchema),
  emergencyContact: optional(emergencyContactSchema),
  isMonthAndDayOfBirthPublic: boolean(),
  isShirtSizePublic: boolean(),
  legalEntity: optional(legalEntitySchema),
  monthAndDayOfBirth: optional(formattedMonthAndDaySchema),
  plannedVacationDays: number(),
  shirtSize: optional(oneOfEnum(ShirtSize)),
  userId: userIdSchema,
});

export type UserPrivateInfo = ExtractSchemaType<typeof userPrivateInfoSchema>;
