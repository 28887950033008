import {
  array,
  boolean,
  dateFromString,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {legalEntityIdSchema, legalEntitySchema} from 'domain/legalEntity/model';
import {TimeOffType} from 'domain/timeOff/type/model';
import {userIdSchema, userSchema} from 'domain/user/model';
import {dateRangeFilterSchema} from 'models/system/filters/dataRange';
import {formattedDateSchema, stringToDate} from 'models/system/formattedDate';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {timeOffRequestIdSchema} from './id';
import {TimeOffRequestStatus} from './status';

export const timeOffRequestSchema = object({
  comment: optional(string()),
  createdAt: dateFromString(),
  createdBy: userSchema,
  daysTaken: optional(number()),
  endDate: formattedDateSchema,
  id: timeOffRequestIdSchema,
  isCancelable: boolean(),
  legalEntity: optional(legalEntitySchema),
  recordedInAccountingSystem: optionalWithDefault(false, boolean()),
  startDate: formattedDateSchema,
  status: oneOfEnum(TimeOffRequestStatus),
  type: oneOfEnum(TimeOffType),
  user: userSchema,
}).map((request) => ({
  ...request,
  start: stringToDate(request.startDate),
  end: stringToDate(request.endDate),
}));

export type TimeOffRequest = ExtractSchemaType<typeof timeOffRequestSchema>;

export const timeOffRequestCreationConfigSchema = object({
  comment: optional(string()),
  endDate: formattedDateSchema,
  startDate: formattedDateSchema,
  type: oneOfEnum(TimeOffType),
});

export type TimeOffRequestCreationConfig = ExtractSchemaType<typeof timeOffRequestCreationConfigSchema>;

export const timeOffRequestsSearchFiltersSchema = object({
  startDate: optional(dateRangeFilterSchema),
  endDate: optional(dateRangeFilterSchema),
  types: optional(array(oneOfEnum(TimeOffType))),
  statuses: optional(array(oneOfEnum(TimeOffRequestStatus))),
  userId: optional(userIdSchema),
  legalEntityIds: optional(array(legalEntityIdSchema)),
  id: optional(timeOffRequestIdSchema),
  recordedInAccountingSystem: optional(boolean()),
});

export type TimeOffRequestsSearchFilters = Partial<ExtractSchemaType<typeof timeOffRequestsSearchFiltersSchema>>;
