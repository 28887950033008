import {StyledLink} from 'components/ui/StyledLink';
import {Permission} from 'domain/permission/model';
import {Service} from 'domain/service/model';
import React from 'react';
import {jdmUrls} from 'routes/jdm/urls';
import {useAcl} from 'services/acl';

export type Props = {
  service: Service;
};

export function ServiceLink({service}: Props) {
  const acl = useAcl();

  return (
    <StyledLink
      colored
      to={jdmUrls.service({serviceId: service.id})}
      disabled={!acl.hasPermission(Permission.SERVICE_READ)}
    >
      {service.name}
    </StyledLink>
  );
}
