import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  globalTitle: {
    defaultMessage: 'Joomers',
    description: '[title] Teams page global title',
  },
  teamsPanelTitle: {
    defaultMessage: 'Teams',
    description: '[title] Teams page: teams panel title',
  },
});
