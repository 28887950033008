import {intranetApi} from 'apiClient';
import {
  CompensationReviewRequest,
  CompensationReviewRequestId,
  compensationReviewRequestSchema,
} from 'domain/compensationReviewRequest/model';

export const cancel = async (id: CompensationReviewRequestId): Promise<CompensationReviewRequest> => {
  const {data} = await intranetApi.post('/v1/secure/compensationReviewRequests/cancel', undefined, {params: {id}});

  return compensationReviewRequestSchema.runWithException(data);
};
