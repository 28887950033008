import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  label: {
    defaultMessage: 'Currency',
    description: 'Form field label',
  },
  noItemsText: {
    defaultMessage: 'Unknown currency',
    description: 'Placeholder for unknown currency on form field',
  },
});
