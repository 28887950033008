import {createAutocompleteMultiselectFilter, DataFilter, DataFilterConfig} from '@joomcode/joom-ui/DataFilter';
import {SecurePermission} from 'domain/permission/model/secure';
import {SalaryRange} from 'domain/salaryRange/model';
import {SalaryRangesSearchFilters} from 'domain/salaryRange/model/filters';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {getCountryComparator} from 'utils/country/compare';
import {getCountryName} from 'utils/country/name';
import {messages} from './messages';

type Props = {
  salaryRanges: SalaryRange[];
  values: SalaryRangesSearchFilters;
  onChange(payload: SalaryRangesSearchFilters): void;
};

export const SalaryRangesFilters = ({salaryRanges, values, onChange}: Props) => {
  const acl = useAcl();
  const intl = useIntl();
  const currencies = useMemo(() => uniqBy(salaryRanges, 'currency').map(({currency}) => currency), [salaryRanges]);
  const regions = useMemo(() => {
    const result = uniqBy(salaryRanges, 'region').map(({region}) => region);
    return (values.region ? uniq([...values.region, ...result]) : result).sort(getCountryComparator(intl));
  }, [intl, salaryRanges, values.region]);
  const levelCodes = useMemo(() => uniqBy(salaryRanges, 'levelCode').map(({levelCode}) => levelCode), [salaryRanges]);
  const userFunctions = useMemo(() => {
    const result = uniqBy(salaryRanges, 'userFunction').map(({userFunction}) => userFunction);
    return (values.userFunction ? uniq([...values.userFunction, ...result]) : result).sort();
  }, [salaryRanges, values.userFunction]);
  const groupsOfFunctions = useMemo(
    () =>
      uniqBy(salaryRanges, 'groupOfFunctions')
        .map(({groupOfFunctions}) => groupOfFunctions)
        .filter(Boolean) as string[],
    [salaryRanges],
  );

  const config = useMemo<DataFilterConfig<SalaryRangesSearchFilters>>(() => {
    return {
      region: createAutocompleteMultiselectFilter(intl.formatMessage(messages.region), {
        alwaysVisible: true,
        options: regions.map((country) => ({
          label: getCountryName(country, intl),
          value: country,
        })),
      }),
      userFunction: createAutocompleteMultiselectFilter(intl.formatMessage(messages.userFunction), {
        alwaysVisible: true,
        options: userFunctions.map((userFunction) => ({
          label: userFunction,
          value: userFunction,
        })),
      }),
      levelCode: createAutocompleteMultiselectFilter(intl.formatMessage(messages.levelCode), {
        alwaysVisible: true,
        options: levelCodes.map((levelCode) => ({
          label: levelCode,
          value: levelCode,
        })),
      }),
      ...(acl.hasSecurePermission(SecurePermission.SALARY_RANGE_READ_AS_ADMIN) && {
        groupOfFunctions: createAutocompleteMultiselectFilter(intl.formatMessage(messages.groupOfFunctions), {
          alwaysVisible: true,
          options: groupsOfFunctions.map((groupOfFunctions) => ({
            label: groupOfFunctions,
            value: groupOfFunctions,
          })),
        }),
      }),
      currency: createAutocompleteMultiselectFilter(intl.formatMessage(messages.currency), {
        alwaysVisible: true,
        options: currencies.map((currency) => ({
          label: currency,
          value: currency,
        })),
      }),
    };
  }, [acl, intl, userFunctions, groupsOfFunctions, currencies, regions]);

  return <DataFilter config={config} onChange={onChange} values={values} />;
};
