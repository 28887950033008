import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {useOfferUpdatePossibility} from 'domain/offer/hooks/useOfferUpdatePossibility';
import {Offer} from 'domain/offer/model';
import {VersionedOfferCompensations} from 'domain/offer/model/compensation';
import {VersionedOfferStockOptions} from 'domain/offer/model/stockOption';
import {OfferStockOptionsAccess} from 'domain/offer/model/stockOption/access';
import {OfferCompensationDetailsSection} from 'domain/offer/widgets/CompensationDetailsSection';
import {CorruptedDataPanel} from 'domain/offer/widgets/CorruptedDataPanel';
import {OfferEmploymentDetailsSection} from 'domain/offer/widgets/EmploymentDetailsSection';
import {OfferStockOptionsDetailsSection} from 'domain/offer/widgets/StockOptionsDetailsSection';
import React from 'react';
import {useIntl} from 'react-intl';
import {offerUrls} from 'routes/offer/urls';
import {messages} from './messages';

type Props = {
  offer: Offer;
  compensations: VersionedOfferCompensations | null;
  compensationsComment: string | null;
  stockOptions: VersionedOfferStockOptions | null;
  stockOptionsExist: boolean;
  stockOptionsReadAccess: OfferStockOptionsAccess | null;
};

export function OfferDetailsPanel({
  offer,
  compensations,
  compensationsComment,
  stockOptions,
  stockOptionsReadAccess,
  stockOptionsExist,
}: Props) {
  const intl = useIntl();
  const lastVersion = offer.versions.at(-1);
  const {canUpdateDetails} = useOfferUpdatePossibility(offer);

  if (!lastVersion) {
    return <CorruptedDataPanel title={intl.formatMessage(messages.title)} />;
  }

  const compensation = (compensations && lastVersion.id && compensations[lastVersion.id]) || null;
  const offerStockOptions = (stockOptions && lastVersion.id && stockOptions[lastVersion.id]) || null;

  return (
    <Panel
      title={intl.formatMessage(messages.title)}
      stretchHeight
      withPadding
      toolbarTop={
        canUpdateDetails && (
          <RouterLinkButton size='m' kind='primary' intent='neutral' to={offerUrls.update({id: offer.id})}>
            {intl.formatMessage(messages.buttonUpdate)}
          </RouterLinkButton>
        )
      }
    >
      <Grid>
        <Grid.Item xl={6} md={12}>
          <OfferEmploymentDetailsSection employmentInfo={lastVersion.employmentInfo} jobContract={offer.jobContract} />
        </Grid.Item>
        <Grid.Item xl={6} md={12}>
          {(compensation || compensationsComment) && (
            <OfferCompensationDetailsSection compensation={compensation} compensationsComment={compensationsComment} />
          )}
          {stockOptionsReadAccess && (
            <OfferStockOptionsDetailsSection stockOptions={offerStockOptions} stockOptionsExist={stockOptionsExist} />
          )}
        </Grid.Item>
      </Grid>
    </Panel>
  );
}
