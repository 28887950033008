import {CountryMultiSelect} from '@joomcode/joom-ui/CountryMultiSelect';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {CountryCode} from '@joomcode/deprecated-utils/countries';
import React, {memo, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useFieldWithInitialValue} from '../../components';
import {CommonFieldProps} from '../types';
import {messages} from './messages';
import {getFieldErrorText} from '../../utils';
import {validateFilled, composeValidators, getFieldValidator} from '../../validation';

export type FieldCountriesProps = CommonFieldProps<CountryCode[]> & {
  options?: CountryCode[];
};

export const FieldCountries = memo(function FieldCountries({
  disabled,
  error,
  hint,
  initialValue,
  label,
  labelHint,
  name,
  required,
  reserveSpaceForError,
  validate,
  validateFields,
  options,
}: FieldCountriesProps) {
  const intl = useIntl();

  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {input, meta} = useFieldWithInitialValue<CountryCode[]>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    validateFields,
  });
  const value = useMemo(() => input.value || [], [input.value]);

  return (
    <FormControl
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl})}
      hint={hint}
      label={label ?? intl.formatMessage(messages.label)}
      labelHint={labelHint}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <CountryMultiSelect
          {...formControlProps}
          {...input}
          value={value}
          disabled={meta.submitting || disabled}
          options={options}
        />
      )}
    </FormControl>
  );
});
