import {ReactComponent as LockIcon} from '@joomcode/joom-ui/icons/core/lockSmall.svg';
import React from 'react';
import styles from './styles.css';

export function NoAccessLock() {
  return (
    <div className={styles.root}>
      <LockIcon />
    </div>
  );
}
