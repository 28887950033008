import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {ListedProperty} from 'components/ui/ListedProperty';
import {MultilineText} from 'components/ui/MultilineText';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {formatChangeValueWithHint} from 'domain/compensationReviewRequest/model/changeValue/formatter';
import {formatType} from 'domain/compensationReviewRequest/model/type/formatter';
import {ApproversChain} from 'domain/compensationReviewRequest/widgets/ApproversChain';
import {StatusBadge} from 'domain/compensationReviewRequest/widgets/StatusBadge';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {labels} from './messages';
import styles from './styles.css';

type Props = {
  request: CompensationReviewRequest;
};

export function CompensationReviewRequestDetails({
  request: {
    compensationChange,
    compensationChangeDate,
    createdAt,
    createdBy,
    decisions,
    effectiveDate,
    reason,
    status,
    stockOptionsChange,
    stockOptionsChangeDate,
    type,
    user,
  },
}: Props) {
  const intl = useIntl();

  return (
    <Panel withPadding>
      <div className={styles.root}>
        <div className={styles.left}>
          <ListedProperty label={intl.formatMessage(labels.user)}>
            <UserLink user={user} withAvatar={false} colored />
          </ListedProperty>
          <ListedProperty label={intl.formatMessage(labels.createdAt)}>
            <HumanDate format='short' value={createdAt} utc />
          </ListedProperty>
          <ListedProperty label={intl.formatMessage(labels.createdBy)}>
            <UserLink user={createdBy} withAvatar={false} colored />
          </ListedProperty>
          <ListedProperty label={intl.formatMessage(labels.status)}>
            <StatusBadge status={status} />
          </ListedProperty>
          <ListedProperty label={intl.formatMessage(labels.effectiveDate)}>
            <HumanDate format='short' value={effectiveDate} utc />
          </ListedProperty>
          <ListedProperty label={intl.formatMessage(labels.type)}>{formatType(type, intl)}</ListedProperty>
        </div>
        <div className={styles.right}>
          <ListedProperty label={intl.formatMessage(labels.approvers)}>
            <ApproversChain decisions={decisions} />
          </ListedProperty>
          {reason && (
            <ListedProperty label={intl.formatMessage(labels.reason)}>
              <MultilineText text={reason} />
            </ListedProperty>
          )}
          <ListedProperty label={intl.formatMessage(labels.compensationChange)}>
            {formatChangeValueWithHint(compensationChange, compensationChangeDate, intl)}
          </ListedProperty>
          <ListedProperty label={intl.formatMessage(labels.stockOptionsChange)}>
            {formatChangeValueWithHint(stockOptionsChange, stockOptionsChangeDate, intl)}
          </ListedProperty>
        </div>
      </div>
    </Panel>
  );
}
