import {useCompensationReviewRequests} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequests';
import {Filters} from 'domain/compensationReviewRequest/model/filters';
import {TabId} from 'domain/compensationReviewRequest/model/tab';
import {CompensationReviewRequestsTable} from 'domain/compensationReviewRequest/widgets/Table';
import React from 'react';

type Props = {
  tabId: TabId;
  filters: Filters;
};

export function CompensationReviewRequestsTab({tabId, filters}: Props) {
  const {data, dataState, pagination, totalCount} = useCompensationReviewRequests({tabId, filters});

  return (
    <CompensationReviewRequestsTable
      data={data}
      dataState={dataState}
      pagination={pagination}
      totalCount={totalCount}
      ownRequestsOnly={tabId === TabId.MY}
    />
  );
}
