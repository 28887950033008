import {
  type ConvertLocatorToTestId,
  createLocator,
  type Locator,
  type Mark,
  type RemoveMarkFromProperties,
  removeMarkFromProperties,
} from '@joomcode/joom-ui/create-locator';
import {FormControl, FormControlLocator} from '@joomcode/joom-ui/FormControl';
import {Select, Props as SelectProps, SelectLocator} from '@joomcode/joom-ui/Select';
import React from 'react';
import {useIntl} from 'react-intl';
import {useFieldWithInitialValue} from '../../components';
import {getFieldErrorText} from '../../utils';
import {validateFilled, composeValidators, getFieldValidator} from '../../validation';
import {CommonFieldProps} from '../types';

export type FieldSelectTestId = ConvertLocatorToTestId<FieldSelectLocator>;
export type FieldSelectLocator = FormControlLocator &
  Locator<{
    select: SelectLocator;
  }>;

export type FieldSelectProps<T> = Partial<Mark<FieldSelectLocator>> &
  Omit<RemoveMarkFromProperties<SelectProps<T>>, 'onChange' | 'value' | 'getItemKey' | 'testId'> &
  CommonFieldProps<T> & {
    getItemKey?: (item: T) => string;
  };

export const FieldSelect = function FieldSelect<T>({
  disabled,
  error,
  hint,
  label,
  labelHint,
  name,
  required,
  reserveSpaceForError,
  format,
  validate,
  validateFields,
  renderItem,
  initialValue,
  getItemKey = String,
  extraErrorMessages,
  ...rest
}: FieldSelectProps<T>) {
  const locator = createLocator(rest);
  const selectProps = removeMarkFromProperties(rest);
  const intl = useIntl();

  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {input, meta} = useFieldWithInitialValue<T | undefined>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    validateFields,
    parse: (value: T | null) => (value === null ? undefined : value),
    format,
  });

  return (
    <FormControl
      hint={hint}
      label={label}
      labelHint={labelHint}
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
      {...locator()}
    >
      {(formControlProps) => {
        const {testId, ...selectPropsWithoutTestId} = selectProps;

        return (
          <Select<T>
            {...formControlProps}
            {...input}
            {...selectPropsWithoutTestId}
            {...locator.select()}
            getItemKey={getItemKey}
            value={input.value ?? null}
            disabled={meta.submitting || disabled}
            renderItem={renderItem ?? selectProps.itemToString}
          />
        );
      }}
    </FormControl>
  );
};
