import cn from 'classnames';
import React from 'react';
import styles from './index.css';

type Props = {
  innerWidth: number;
  visible: boolean;
};
export const Scrollbar = React.forwardRef<HTMLDivElement, Props>(function Scrollbar({innerWidth, visible}, ref) {
  return (
    <div ref={ref} className={cn(styles.scrollView, {[styles.scrollViewVisible]: visible})}>
      <div className={styles.spacer} style={{width: innerWidth}} />
    </div>
  );
});
