import {approve} from './approve';
import {create} from './create';
import {buildDownloadAllLink} from './downloadAll';
import {buildAvatarDownloadLink} from './downloadAvatar';
import {buildDocumentDownloadLink} from './downloadDocument';
import {enhance} from './enhance';
import {generateToken} from './generateToken';
import {getAll} from './getAll';
import {getBusyDates} from './getBusyDates';
import {getById} from './getById';
import {getIdByUserId} from './getByUserId';
import {getCompensations} from './getCompensations';
import {getCompensationsComment} from './getCompensationsComment';
import {getStockOptions} from './getStockOptions';
import {getStockOptionsExistence} from './getStockOptionsExistence';
import {buildDocumentPreviewLink} from './previewDocument';
import {setAccepted} from './setAccepted';
import {setDeclined} from './setDeclined';
import {update} from './update';
import {updateGeneral} from './updateGeneral';
import {updateLogin} from './updateLogin';
import {updateStartWorkingDate} from './updateStartWorkingDate';
import {validateLogin} from './validateLogin';

export const offersApi = {
  approve,
  buildAvatarDownloadLink,
  buildDocumentDownloadLink,
  buildDownloadAllLink,
  buildDocumentPreviewLink,
  create,
  enhance,
  generateToken,
  getAll,
  getById,
  getIdByUserId,
  getBusyDates,
  getCompensations,
  getCompensationsComment,
  getStockOptions,
  getStockOptionsExistence,
  setAccepted,
  setDeclined,
  update,
  updateGeneral,
  updateLogin,
  updateStartWorkingDate,
  validateLogin,
};
