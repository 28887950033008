import {identity} from '@joomcode/deprecated-utils/function';
import {FieldInputNumber, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {FieldCurrency} from 'domain/currency/widgets/Field';
import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import {OneTimeBonusCondition} from 'domain/oneTimeBonusRecord/model/condition';
import {FieldOneTimeBonusCondition} from 'domain/oneTimeBonusRecord/widgets/FieldCondition';
import {FieldOneTimeBonusType} from 'domain/oneTimeBonusRecord/widgets/FieldType';
import React from 'react';
import {FormRenderProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  formId: string;
  recordToUpdate?: OneTimeBonusRecord;
  values: OneTimeBonusRecord;
  onSubmit: FormRenderProps<OneTimeBonusRecord>['handleSubmit'];
};

export function OneTimeBonusRecordForm({formId, recordToUpdate, values, onSubmit}: Props) {
  const intl = useIntl();

  return (
    <form onSubmit={onSubmit} id={formId} noValidate autoComplete='off'>
      <Grid>
        <Grid.Item xl={6} xs={12}>
          <FieldDate name='date' label={intl.formatMessage(labels.date)} initialValue={recordToUpdate?.date} required />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldOneTimeBonusType
            name='type'
            label={intl.formatMessage(labels.type)}
            initialValue={recordToUpdate?.type}
            required
          />
        </Grid.Item>
        <Grid.Item xl={12}>
          <Grid>
            <Grid.Item xl={6} xs={12}>
              <FieldOneTimeBonusCondition
                name='condition'
                label={intl.formatMessage(labels.condition)}
                initialValue={recordToUpdate?.condition}
                required
              />
            </Grid.Item>
            {values.condition === OneTimeBonusCondition.REFUNDABLE && (
              <Grid.Item xl={6} xs={12}>
                <FieldDate
                  name='penalDeadline'
                  label={intl.formatMessage(labels.penalDeadline)}
                  initialValue={recordToUpdate?.penalDeadline}
                  required
                />
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldInputNumber
            name='amount'
            label={intl.formatMessage(labels.amount)}
            required
            min={0}
            step={1000}
            initialValue={recordToUpdate?.amount}
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldCurrency
            name='currency'
            label={intl.formatMessage(labels.currency)}
            required
            validate={validateFilled}
            initialValue={recordToUpdate?.currency}
          />
        </Grid.Item>
        <Grid.Item xl={12}>
          <FieldTextarea
            name='comment'
            label={intl.formatMessage(labels.comment)}
            rows={2}
            initialValue={recordToUpdate?.comment}
            parse={identity}
          />
        </Grid.Item>
      </Grid>
    </form>
  );
}
