import {Sorting, SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {levelCodeSortingOrder} from 'domain/levelCode/model';
import {SalaryRange} from 'domain/salaryRange/model';
import orderBy from 'lodash/orderBy';
import {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {getCountryName} from 'utils/country/name';

type ReturnShape = {
  sorting: Sorting | undefined;
  setSorting: (sorting: Sorting | undefined) => void;
  sortSalaryRanges: (salaryRanges: SalaryRange[], sorting: Sorting | undefined) => SalaryRange[];
};

const levelCodeIteratee = (salaryRange: SalaryRange) => levelCodeSortingOrder.indexOf(salaryRange.levelCode);

export function useSalaryRangesSorting(): ReturnShape {
  const intl = useIntl();
  const [sorting, setSorting] = useState<Sorting | undefined>({
    by: 'region',
    direction: SortingDirection.ASC,
  });

  const regionIteratee = useCallback((salaryRange: SalaryRange) => getCountryName(salaryRange.region, intl), [intl]);
  const sortSalaryRanges = useCallback(
    (salaryRanges: SalaryRange[], sorting: Sorting | undefined) => {
      if (!sorting) {
        return salaryRanges;
      }
      if (sorting.by === 'levelCode') {
        return orderBy(salaryRanges, levelCodeIteratee, sorting.direction);
      }
      if (sorting.by === 'region') {
        return orderBy(salaryRanges, regionIteratee, sorting.direction);
      }

      return orderBy(salaryRanges, sorting.by, sorting.direction);
    },
    [regionIteratee],
  );

  return {
    sorting,
    setSorting,
    sortSalaryRanges,
  };
}
