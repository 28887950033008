import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Permission} from 'domain/permission/model';
import {User, userSchema} from 'domain/user/model';

const getAllGeneric = (endpoint: string) => async (): Promise<User[]> => {
  const {data} = await intranetApi.get(endpoint);
  return array(userSchema).runWithException(data);
};

const getAllDefault = getAllGeneric('/v1/users/getAll');
const getAllWithTerminated = getAllGeneric('/v1/users/getAllWithTerminated');

export const getAll = createPermissionDependentHandler(
  [{permission: Permission.USER_READ_ANY, handler: getAllWithTerminated}],
  getAllDefault,
);
