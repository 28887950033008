import {PageTabs} from 'components/ui/PageTabs';
import {ManagerConsoleTab} from 'domain/managerConsole/model/tabs';
import {managerConsoleTabNames} from 'domain/managerConsole/model/tabs/messages';
import {pathByTab} from 'domain/managerConsole/model/tabs/paths';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  availableTabs: ManagerConsoleTab[];
  activeTab: ManagerConsoleTab;
};

export function ManagerConsolePageTabs({availableTabs, activeTab}: Props) {
  const intl = useIntl();

  return (
    <PageTabs>
      {availableTabs.map((tab) => (
        <PageTabs.Tab
          title={intl.formatMessage(managerConsoleTabNames[tab])}
          path={pathByTab[tab]}
          active={activeTab === tab}
          key={tab}
          isNew={tab === ManagerConsoleTab.COMPENSATION_REVIEW_REQUESTS}
        />
      ))}
    </PageTabs>
  );
}
