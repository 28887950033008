import {array, boolean, ExtractSchemaType, object} from '@joomcode/deprecated-utils/jsonValidation';
import {roleSchema} from 'domain/role/model';
import {userSchema} from 'domain/user/model';
import sortBy from 'lodash/sortBy';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const membershipIdSchema = idSchema;

export const teamMembersSchema = object({
  activeMembers: optionalWithDefault(
    [],
    array(
      object({
        memberships: array(
          object({
            membershipId: membershipIdSchema,
            role: roleSchema,
            isRevocable: optionalWithDefault(false, boolean()),
          }),
        ).map((memberships) => sortBy(memberships, ({role}) => !role.isHead)), // 'Head' role goes first
        user: userSchema,
      }),
    ).map((activeMembers) =>
      sortBy(activeMembers, [
        ({memberships}) => !memberships.some(({role}) => role.isHead), // Members with 'Head' role go first
        ({user}) => user.firstName.toLowerCase(),
        ({user}) => user.lastName.toLowerCase(),
      ]),
    ),
  ),
  membershipRequests: optionalWithDefault(
    [],
    array(
      object({
        membershipId: membershipIdSchema,
        role: roleSchema,
        user: userSchema,
      }),
    ),
  ),
});

export type TeamMembers = ExtractSchemaType<typeof teamMembersSchema>;
export type MembershipId = ExtractSchemaType<typeof membershipIdSchema>;
