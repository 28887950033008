import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {Panel} from '@joomcode/joom-ui/Panel';
import {EmptyMessage} from 'components/ui/EmptyMessage';
import {Role} from 'domain/role/model';
import {RoleBadge} from 'domain/role/widgets/Badge';
import {Team} from 'domain/team/model';
import {MembershipId} from 'domain/team/model/members';
import {revokeMembershipFx} from 'domain/team/stores/main';
import {TeamMembershipRequestDialog} from 'domain/team/widgets/MembershipRequestDialog';
import {User} from 'domain/user/model';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import {generalMessages} from 'i18n/messages/general';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  canEditMemberships: boolean;
  team: Team;
};

export function TeamMembersPanel({canEditMemberships, team}: Props) {
  const intl = useIntl();
  const membershipRequestDialog = usePopupState();
  const {members, id: teamId} = team;
  const activeMembers = useMemo(
    () => members.activeMembers.filter(({user}) => !user.isOutstaff),
    [members.activeMembers],
  );

  const revokeRole = (id: MembershipId, role: Role, {firstName, lastName}: User) =>
    confirm(
      {
        title: intl.formatMessage(messages.revokeConfirmationTitle),
        text: intl.formatMessage(messages.revokeConfirmationText, {
          emphasis: (text: React.ReactNode) => <span className={styles.emphasis}>{text}</span>,
          roleName: role.name,
          memberName: intl.formatMessage(generalMessages.fullName, {
            firstName,
            lastName,
          }),
          teamName: team.name,
        }),
        confirmationText: intl.formatMessage(messages.revokeConfirmationButton),
        onConfirm: () => revokeMembershipFx({id, teamId}).catch(toaster.interceptThenThrowError),
        intent: 'negative',
      },
      intl,
    );

  return (
    <Panel>
      <Panel.Header
        title={intl.formatMessage(messages.title)}
        withSeparator
        toolbar={
          canEditMemberships && (
            <Button size='m' kind='primary' intent='neutral' type='submit' onClick={membershipRequestDialog.open}>
              {intl.formatMessage(messages.btnRequestMembership)}
            </Button>
          )
        }
      />
      {activeMembers.length > 0 ? (
        <Panel.Content>
          {activeMembers.map(({user, memberships}) => (
            <div className={styles.user} key={user.id}>
              <ListedUser user={user}>
                {memberships.map(({membershipId, role, isRevocable}) => (
                  <RoleBadge
                    role={role}
                    key={membershipId}
                    onRemove={
                      canEditMemberships && isRevocable ? () => revokeRole(membershipId, role, user) : undefined
                    }
                    removeTooltip={intl.formatMessage(messages.revokeTooltip)}
                  />
                ))}
              </ListedUser>
            </div>
          ))}
        </Panel.Content>
      ) : (
        <Panel.Content withPadding>
          <EmptyMessage>{intl.formatMessage(messages.noMembers)}</EmptyMessage>
        </Panel.Content>
      )}
      <TeamMembershipRequestDialog
        isOpen={membershipRequestDialog.isOpen}
        onClose={membershipRequestDialog.close}
        teamId={teamId}
      />
    </Panel>
  );
}
