import {SecurePermission} from 'domain/permission/model/secure';
import {withAuth} from 'hocs/withAuth';
import {withSecurePermission} from 'hocs/withSecurePermission';
import {withVpn} from 'hocs/withVpn';
import {RegularLayout} from 'layouts/Regular';
import {NotFoundPage} from 'pages/NotFound';
import {StockOptionsPage} from 'pages/StockOptions';
import {StockOptionsBulkUploadPage} from 'pages/StockOptionsBulkUpload';
import {UserStockOptionsPage} from 'pages/UserStockOptions';
import {RouteConfig} from 'react-router-config';
import {stockOptionsUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: stockOptionsUrlPatterns.root,
  component: withVpn(withAuth(RegularLayout), NotFoundPage),
  routes: [
    {
      component: StockOptionsPage,
      exact: true,
      path: stockOptionsUrlPatterns.root,
    },
    {
      component: withSecurePermission(StockOptionsBulkUploadPage, SecurePermission.STOCK_OPTION_BULK_UPLOAD),
      exact: true,
      path: stockOptionsUrlPatterns.bulkUpload,
    },
    {
      component: UserStockOptionsPage,
      exact: true,
      path: stockOptionsUrlPatterns.user,
    },
  ],
};
