import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Sorting, SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StyledLink} from 'components/ui/StyledLink';
import {getCurrencyName} from 'domain/currency/model';
import {LegalEntity} from 'domain/legalEntity/model';
import {Permission} from 'domain/permission/model';
import orderBy from 'lodash/orderBy';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {legalEntitiesUrls} from 'routes/legalEntities/urls';
import {useAcl} from 'services/acl';
import {getCountryName} from 'utils/country/name';
import {columnNames} from './messages';
import styles from './styles.css';

type Props = {
  legalEntities: LegalEntity[];
};

const getRowKey = (legalEntity: LegalEntity) => legalEntity.id;
const sortLegalEntities = (legalEntities: LegalEntity[], sorting: Sorting | undefined) =>
  sorting ? orderBy(legalEntities, sorting.by, sorting.direction) : legalEntities;

export function LegalEntitiesTable({legalEntities}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  const columns: Column<LegalEntity>[] = useMemo(
    () => [
      {
        defaultWidth: 40,
        id: 'title',
        name: intl.formatMessage(columnNames.title),
        render: ({id, title}) => (
          <StyledLink
            to={legalEntitiesUrls.legalEntity({legalEntityId: id})}
            colored
            disabled={!acl.hasPermission(Permission.LEGAL_ENTITY_WRITE)}
          >
            {title}
          </StyledLink>
        ),
        sortable: true,
      },
      {
        defaultWidth: 30,
        id: 'countryCode',
        name: intl.formatMessage(columnNames.country),
        render: ({countryCode}) => countryCode && getCountryName(countryCode, intl),
      },
      {
        defaultWidth: 30,
        id: 'currency',
        name: intl.formatMessage(columnNames.currency),
        render: ({currency}) => currency && getCurrencyName(currency, intl),
      },
    ],
    [intl],
  );

  const [sorting, setSorting] = useState<Sorting | undefined>({
    by: 'title',
    direction: SortingDirection.ASC,
  });

  const sortedLegalEntities = useMemo(() => sortLegalEntities(legalEntities, sorting), [legalEntities, sorting]);

  return (
    <Panel>
      <div className={styles.wrap}>
        <DataTable
          columns={columns}
          dataState={DataState.LOADED}
          data={sortedLegalEntities}
          getRowKey={getRowKey}
          onSort={setSorting}
          sorting={sorting}
          storageName='legalEntitiesList'
        />
      </div>
    </Panel>
  );
}
