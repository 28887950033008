import {defineMessages} from 'react-intl';
import {ImageUrlValidatorErrorCode} from './types';

export const imageUrlErrorMessages = defineMessages<ImageUrlValidatorErrorCode>({
  [ImageUrlValidatorErrorCode.IMAGE_URL_INVALID]: {
    id: 'validator-image-url-invalid',
    description: 'Validation error about the value is not an url',
    defaultMessage: 'Given URL is not an image',
  },
  [ImageUrlValidatorErrorCode.IMAGE_URL_CANNOT_BE_RESOLVED]: {
    id: 'validator-image-url-cannot-be-resolved',
    description: 'Validation error about the url is not an image',
    defaultMessage: 'Given URL is not an image',
  },
});
