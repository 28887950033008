import {defineMessages} from 'react-intl';
import {ManagerConsoleTab} from '.';

export const managerConsoleTabNames = defineMessages<ManagerConsoleTab>({
  [ManagerConsoleTab.SALARY_RANGES]: {
    defaultMessage: 'Salary ranges',
    description: '[title] Manager console tab name',
  },
  [ManagerConsoleTab.SUBORDINATES]: {
    defaultMessage: 'Reports information',
    description: '[title] Manager console tab name',
  },
  [ManagerConsoleTab.COMPENSATION_REVIEW_REQUESTS]: {
    defaultMessage: 'Compensation review',
    description: '[title] Manager console tab name',
  },
});
