import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Report successfully submitted',
    description: 'Compensation report submission dialog: aria label',
  },
  title: {
    defaultMessage: 'You’re amazing!',
    description: '[title] Compensation report submission dialog: title',
  },
  text: {
    defaultMessage: 'We’ll get back to you in case of any further questions.',
    description: 'Compensation report submission dialog: text',
  },
  textForRussians: {
    defaultMessage:
      'We’ll get back to you in case of any further questions. If everything’s in order you’ll receive an expense report by email with details on the daily allowance and expenses. Please sign it on the same day and don’t forget to bring your physical boarding passes to HR Love&Care.',
    description: 'Compensation report submission dialog: text',
  },
  button: {
    defaultMessage: 'Ok ✨',
    description: '[button] Compensation report submission dialog: button',
  },
});
