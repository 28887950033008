import React from 'react';
import {useIntl} from 'react-intl';
import type {DatePickerProps} from './types';

const LazyDatePicker = React.lazy(() => import('./LazyDatePicker' /* webpackChunkName: 'datepicker' */));

export type DatePickerExternalProps = Omit<DatePickerProps, 'intl' | 'locale'>;

export function DatePicker(props: DatePickerExternalProps) {
  const intl = useIntl();

  return (
    <React.Suspense fallback={null}>
      <LazyDatePicker {...props} intl={intl} locale={intl.locale} />
    </React.Suspense>
  );
}
