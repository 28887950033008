import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Team creation dialog',
    description: 'Team creation dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Team creation dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Create',
    description: '[button] Team creation dialog: submit button',
  },
});
