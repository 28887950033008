export enum ColumnId {
  REGION = 'region',
  USER_FUNCTION = 'userFunction',
  LEVEL_CODE = 'levelCode',
  CURRENCY = 'currency',
  GROUP_OF_FUNCTIONS = 'groupOfFunctions',
  MAX_AMOUNT = 'maxAmount',
  TARGET_AMOUNT = 'targetAmount',
  MIN_AMOUNT = 'minAmount',
  COMMENT = 'comment',
  STOCK_OPTIONS_PROPOSAL = 'stockOptionsProposal',
}
