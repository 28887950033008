import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {SubmitFormPanel} from '@joomcode/joom-ui/SubmitFormPanel';
import {Page} from 'components/widgets/Page';
import {useHrConsolePageTabs} from 'domain/hrConsole/hooks/useHrConsolePageTabs';
import {HrConsoleTab} from 'domain/hrConsole/model/tabs';
import {HrConsolePageTabs} from 'domain/hrConsole/widgets/PageTabs';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSalaryRangesUploadResult} from 'domain/salaryRange/hooks/useSalaryRangesUploadResult';
import {SalaryRangesUploadPanel} from 'domain/salaryRange/widgets/UploadPanel';
import {SalaryRangesVersionsPanel} from 'domain/salaryRange/widgets/VersionsPanel';
import {BulkUploadStatus} from 'models/system/bulkUpload';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/hrConsole/titles';
import {useAcl} from 'services/acl';
import {messages} from './messages';

export function HrConsoleManagementPage() {
  const acl = useAcl();
  const intl = useIntl();
  const {showTabs, availableTabs} = useHrConsolePageTabs();
  const {apply, onUpload, isApplying, uploadResult, onReset} = useSalaryRangesUploadResult();
  const canSeeVersions = acl.hasSecurePermission(SecurePermission.SALARY_RANGE_VERSION_READ_AS_ADMIN);
  const canUpload = acl.hasSecurePermission(SecurePermission.SALARY_RANGE_UPLOAD);

  return (
    <Page
      title={intl.formatMessage(pageTitles.hrConsole)}
      headerContent={
        showTabs && <HrConsolePageTabs availableTabs={availableTabs} activeTab={HrConsoleTab.MANAGEMENT} />
      }
    >
      {canUpload && <SalaryRangesUploadPanel uploadResult={uploadResult} onUpload={onUpload} />}
      {canSeeVersions && <SalaryRangesVersionsPanel />}
      {canUpload && uploadResult && (
        <SubmitFormPanel>
          <ButtonGroup spaced size='l'>
            {uploadResult.status === BulkUploadStatus.CREATED && (
              <Button kind='primary' intent='primary' onClick={apply} loading={isApplying}>
                {intl.formatMessage(messages.buttonApply)}
              </Button>
            )}
            <Button kind='primary' intent='neutral' onClick={onReset} loading={isApplying}>
              {intl.formatMessage(messages.buttonReset)}
            </Button>
          </ButtonGroup>
        </SubmitFormPanel>
      )}
    </Page>
  );
}
