import React from 'react';
import {Link, type LinkProps} from 'react-router-dom';
import {
  type ConvertLocatorToTestId,
  createLocator,
  getLocatorParameters,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
  type RemoveMarkFromProperties,
} from '../create-locator';
import {getAnchorButtonProps, omitBaseButtonProps} from '../Button/props';
import type {BaseButtonProps} from '../Button/types';
import {ButtonContent} from '../Button/Content';

export type RouterLinkButtonTestId = ConvertLocatorToTestId<RouterLinkButtonLocator>;
export type RouterLinkButtonLocator = LocatorOfElement<void, {name?: string}, 'sameParameters'>;

export type RouterLinkButtonProps<LocationState = never> = RemoveMarkFromProperties<BaseButtonProps> &
  LinkProps<LocationState> &
  Partial<Mark<RouterLinkButtonLocator>>;

export function RouterLinkButton<LocationState>(propertiesWithMark: RouterLinkButtonProps<LocationState>) {
  const locator = createLocator(propertiesWithMark);
  const locatorParameters = getLocatorParameters(propertiesWithMark);
  const props = removeMarkFromProperties(propertiesWithMark);

  const restProps = omitBaseButtonProps(props);
  const linkProps: LinkProps = {...restProps, ...getAnchorButtonProps(props)};

  return (
    <Link {...linkProps} {...locator(locatorParameters)}>
      <ButtonContent {...props} />
    </Link>
  );
}
