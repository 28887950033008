import {identity} from '@joomcode/deprecated-utils/function';
import {FieldInputNumber, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {addYear, getClosestBusinessDayInNextMonth} from 'domain/compensationReviewRequest/utils/date';
import {RemovableSectionHeader} from 'domain/compensationReviewRequest/widgets/RemovableSectionHeader';
import {Currency} from 'domain/currency/model';
import {FieldCurrency} from 'domain/currency/widgets/Field';
import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import {OneTimeBonusCondition} from 'domain/oneTimeBonusRecord/model/condition';
import {availableCompensationReviewValues} from 'domain/oneTimeBonusRecord/model/type/availableValues';
import {FieldOneTimeBonusCondition} from 'domain/oneTimeBonusRecord/widgets/FieldCondition';
import {FieldOneTimeBonusType} from 'domain/oneTimeBonusRecord/widgets/FieldType';
import React, {useMemo} from 'react';
import {useForm} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {useIntl} from 'react-intl';
import {labels, messages} from './messages';

type Props = {
  currency: Currency | undefined;
  fieldName: string;
  onRemove: () => void;
  values?: OneTimeBonusRecord;
};

export function CompensationReviewRequestFormNewOneTimeBonus({currency, fieldName, onRemove, values}: Props) {
  const intl = useIntl();
  const minDate = useMemo(() => getClosestBusinessDayInNextMonth(new Date()), []);
  const penalDeadlineInitialValue = useMemo(() => (values?.date ? addYear(values.date) : undefined), [values?.date]);
  const {change} = useForm();

  return (
    <>
      <RemovableSectionHeader title={intl.formatMessage(messages.title)} onRemove={onRemove} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item xl={3} xs={6}>
            <FieldDate
              name={`${fieldName}.date`}
              label={intl.formatMessage(labels.date)}
              minDate={minDate}
              required
              reserveSpaceForError={false}
            />
            <OnChange name={`${fieldName}.date`}>
              {(date) => {
                if (values?.condition === OneTimeBonusCondition.REFUNDABLE && !values.penalDeadline) {
                  change(`${fieldName}.penalDeadline`, addYear(date));
                }
              }}
            </OnChange>
          </Grid.Item>
          <Grid.Item xl={3} xs={6}>
            <FieldOneTimeBonusType
              name={`${fieldName}.type`}
              label={intl.formatMessage(labels.type)}
              required
              reserveSpaceForError={false}
              items={availableCompensationReviewValues}
            />
          </Grid.Item>
          <Grid.Item xl={3} xs={6}>
            <FieldOneTimeBonusCondition
              name={`${fieldName}.condition`}
              label={intl.formatMessage(labels.condition)}
              labelHint={intl.formatMessage(messages.conditionHint)}
              required
              reserveSpaceForError={false}
            />
          </Grid.Item>
          {values?.condition === OneTimeBonusCondition.REFUNDABLE ? (
            <Grid.Item xl={3} xs={6}>
              <FieldDate
                name={`${fieldName}.penalDeadline`}
                label={intl.formatMessage(labels.penalDeadline)}
                labelHint={intl.formatMessage(messages.penalDeadlineHint)}
                required
                initialValue={penalDeadlineInitialValue}
                reserveSpaceForError={false}
              />
            </Grid.Item>
          ) : (
            <Grid.Item xl={3} xs={6}>
              <div /> {/* For correct placement */}
            </Grid.Item>
          )}
          <Grid.Item xl={3} xs={6}>
            <FieldInputNumber
              name={`${fieldName}.amount`}
              label={intl.formatMessage(labels.amount)}
              required
              min={0}
              step={1000}
              reserveSpaceForError={false}
            />
          </Grid.Item>
          <Grid.Item xl={3} xs={6}>
            <FieldCurrency
              name={`${fieldName}.currency`}
              label={intl.formatMessage(labels.currency)}
              required
              initialValue={currency}
              validate={validateFilled}
              reserveSpaceForError={false}
            />
          </Grid.Item>
          <Grid.Item xl={6} xs={12}>
            <FieldTextarea
              name={`${fieldName}.comment`}
              label={intl.formatMessage(labels.comment)}
              hint={intl.formatMessage(messages.commentHint)}
              rows={1}
              parse={identity}
              reserveSpaceForError={false}
            />
          </Grid.Item>
        </Grid>
      </Panel.Content>
    </>
  );
}
