import {DataState} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Select} from '@joomcode/joom-ui/Select';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {DEFAULT_ISSUER} from 'domain/stockOption/issuer/model';
import React from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  label: React.ReactNode;
  name: string;
  required?: boolean;
  reserveSpaceForError?: boolean;
};

export function StockOptionIssuerFormControl({label, name, required, reserveSpaceForError}: Props) {
  const intl = useIntl();
  const {dataState, units} = useCompanyUnits();
  const loadingError = dataState === DataState.FAILED ? intl.formatMessage(messages.loadingError) : undefined;
  const defaultIssuerExists = units && units.stockOptionIssuers.includes(DEFAULT_ISSUER);
  const disabled =
    dataState !== DataState.LOADED || (units && units.stockOptionIssuers.length <= 1) || defaultIssuerExists;
  const initialValue = defaultIssuerExists ? DEFAULT_ISSUER : units?.stockOptionIssuers[0];

  return (
    <Field<string> name={name} initialValue={initialValue}>
      {({input}) => (
        <FormControl
          disabled={disabled}
          label={label}
          required={required}
          reserveSpaceForError={reserveSpaceForError}
          error={loadingError}
        >
          {(formControlProps) => (
            <Select<string>
              items={units?.stockOptionIssuers ?? []}
              disabled={disabled}
              getItemKey={identity}
              {...formControlProps}
              {...input}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
}
