import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: '{isSelfUser, select, true {Your balance for today} other {Today’s balance of {fullName}}}',
    description: '[title] Request time off: balance calculator title',
  },
  vacationHint: {
    defaultMessage: 'Check out our <link>vacation policy in Notion</link>',
    description: 'Request time off: balance calculator hint',
  },
  remoteWorkHint: {
    defaultMessage: 'Check out our <link>remote work policy in Notion</link>',
    description: 'Request time off: balance calculator hint',
  },
});
