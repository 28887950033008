import cn from 'classnames';
import React from 'react';
import {useIntl} from 'react-intl';
import emptyStateIllustration from '../illustrations/emptyState.svg';
import sadGirlIllustration from '../illustrations/sadGirl.svg';
import styles from './index.css';
import {messages} from './messages';

type Props = {
  children?: React.ReactNode;
  illustrationSrc: string;
  title?: string;
  fullHeight?: boolean;
};

export function Placeholder({children, fullHeight = true, illustrationSrc, title}: Props) {
  return (
    <section
      className={cn(styles.root, {
        [styles.rootFullHeight]: fullHeight,
      })}
    >
      <img alt='' src={illustrationSrc} />
      <h2 className={styles.title}>{title}</h2>
      {children}
    </section>
  );
}

type SpecificPlaceholderProps = Omit<Props, 'illustrationSrc'>;

Placeholder.Error = function Error({title, ...restProps}: SpecificPlaceholderProps) {
  const intl = useIntl();

  return (
    <Placeholder
      {...restProps}
      title={title || intl.formatMessage(messages.titleError)}
      illustrationSrc={sadGirlIllustration}
    />
  );
};

Placeholder.RestrictedAccess = function Error({title, children, ...restProps}: SpecificPlaceholderProps) {
  const intl = useIntl();

  return (
    <Placeholder
      {...restProps}
      title={title || intl.formatMessage(messages.titleRestricted)}
      illustrationSrc={sadGirlIllustration}
    >
      {children || intl.formatMessage(messages.descriptionRestricted)}
    </Placeholder>
  );
};

Placeholder.EmptyState = function EmptyState({title, ...restProps}: SpecificPlaceholderProps) {
  const intl = useIntl();

  return (
    <Placeholder
      {...restProps}
      title={title || intl.formatMessage(messages.titleEmpty)}
      illustrationSrc={emptyStateIllustration}
    />
  );
};
