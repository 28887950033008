import cn from 'classnames';
import {clamp} from '@joomcode/deprecated-utils/math/clamp';
import React from 'react';
import styles from './index.css';

export const progressIntent = {
  negative: 'negative',
  warning: 'warning',
  positive: 'positive',
  primary: 'primary',
} as const;

export type ProgressIntent = keyof typeof progressIntent;

export const classNameByIntent: {[key in ProgressIntent]: string} = {
  [progressIntent.negative]: styles.progressNegative,
  [progressIntent.warning]: styles.progressWarning,
  [progressIntent.positive]: styles.progressPositive,
  [progressIntent.primary]: styles.progressPrimary,
};

type Props = {
  value: number;
  intent?: ProgressIntent;
};

export function Progress({value, intent = progressIntent.primary}: Props) {
  const invertedValue = clamp(1 - value, 0, 1);

  return (
    <div className={cn(styles.progress, classNameByIntent[intent])}>
      <div className={styles.progressBar} style={{transform: `translateX(-${invertedValue * 100}%)`}} />
    </div>
  );
}
