import cn from 'classnames';
import {isNotNullish, identity} from '@joomcode/deprecated-utils/function';
import React, {ReactNode} from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../../create-locator';
import {TooltipFaq} from '../../TooltipFaq';
import {ReactComponent as WarningIcon} from '../../icons/circle/warning.svg';
import styles from './index.css';

export const tabSize = {
  m: 'm',
  l: 'l',
} as const;

export const tabIntent = {
  negative: 'negative',
  none: 'none',
  warning: 'warning',
} as const;

export type TabSize = keyof typeof tabSize;
export type TabIntent = keyof typeof tabIntent;

export type TabLabelTestId = ConvertLocatorToTestId<TabLabelLocator>;
export type TabLabelLocator = LocatorOfElement<void, {item?: string}>;

export type Props = Partial<Mark<TabLabelLocator>> & {
  active: boolean;
  closeButton?: React.ReactNode;
  extra: ReactNode;
  id: string;
  intent?: TabIntent;
  maxWidth?: React.CSSProperties['maxWidth'];
  renderLabelWrapper?(children: React.ReactNode): React.ReactNode;
  size: TabSize;
  tooltip?: ReactNode;
};

export function TabLabel({
  active,
  closeButton,
  children,
  extra,
  maxWidth,
  renderLabelWrapper = identity<React.ReactNode>,
  size,
  id,
  intent = 'none',
  tooltip,
  ...restPropertiesWithMark
}: Props & JSX.IntrinsicElements['li']) {
  const locator = createLocator(restPropertiesWithMark);
  const restProps = removeMarkFromProperties(restPropertiesWithMark);

  return (
    <li
      {...restProps}
      {...locator({item: id})}
      style={{maxWidth}}
      className={cn(
        styles.tab,
        {
          [styles.active]: active,
          [styles.sizeM]: size === 'm',
          [styles.sizeL]: size === 'l',
          [styles.intentNegative]: intent === 'negative',
          [styles.intentWarning]: intent === 'warning',
          [styles.tabRestrictedWidth]: isNotNullish(maxWidth) && maxWidth !== '',
        },
        restPropertiesWithMark.className,
      )}
      dir='auto'
    >
      {renderLabelWrapper(
        <>
          <span className={styles.content}>{children}</span>
          {extra && <span className={styles.extra}>{extra}</span>}
          {(intent === 'negative' || intent === 'warning') && (
            <span className={styles.icon}>
              {tooltip ? (
                <TooltipFaq content={tooltip}>
                  <WarningIcon />
                </TooltipFaq>
              ) : (
                <WarningIcon />
              )}
            </span>
          )}
          {closeButton ? <span className={styles.closeButton}>{closeButton}</span> : null}
        </>,
      )}
    </li>
  );
}
