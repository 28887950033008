import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {CompensationRequest, compensationRequestSchema} from 'domain/compensations/request/model';
import {CompensationRequestType} from 'domain/compensations/request/model/type';

export const getAvailable = async ({type}: {type?: CompensationRequestType}): Promise<CompensationRequest[]> => {
  const {data} = await intranetApi.get('/v1/compensations/requests/available', {params: {type}});

  return array(compensationRequestSchema).runWithException(data);
};
