import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  title: {
    defaultMessage: 'Options',
    description: '[title] Page title',
  },
  ownStockOptions: {
    defaultMessage: 'My options',
    description: '[title] Page title',
  },
  userStockOptions: {
    defaultMessage: '{fullName}{possession} options',
    description: '[title] Page title',
  },
  bulkUpload: {
    defaultMessage: 'Options data upload',
    description: '[title] Page title',
  },
});
