import {ClientErrorStatus} from 'apiClient/ClientError';
import {createEntityGetter, EntityResponse, EntityResponseStatus} from 'apiClient/entityResponse';
import {usersApi} from 'domain/user/api';
import {UserFull} from 'domain/user/model';
import {
  freeSeatFx,
  loadActiveUsersFx,
  loadAllUsersFx,
  loadUserByLoginFx,
  loadUserEverWorkedByLoginFx,
  setRolesFx,
  takeSeatFx,
  unassignSeatFx,
  updateUserFx,
} from '.';

loadActiveUsersFx.use(usersApi.getActive);
loadAllUsersFx.use(usersApi.getAll);
loadUserByLoginFx.use(createEntityGetter(usersApi.getByLogin));
updateUserFx.use(usersApi.update);
takeSeatFx.use(usersApi.takeSeat);
freeSeatFx.use(usersApi.freeSeat);
setRolesFx.use(usersApi.setRoles);
unassignSeatFx.use(usersApi.unassignSeat);

loadUserEverWorkedByLoginFx.use((params: {login: string}) =>
  createEntityGetter(usersApi.getByLogin)(params).then((entityResponse: EntityResponse<UserFull>) => {
    if (
      entityResponse.status === EntityResponseStatus.REJECTED &&
      entityResponse.reason === ClientErrorStatus.NOT_FOUND
    ) {
      return createEntityGetter(usersApi.getTerminatedUserByLogin)(params);
    }
    return entityResponse;
  }),
);
