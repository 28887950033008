import {SalaryRangesUploadInput, SalaryRangesUploadResult} from 'domain/salaryRange/model/upload';

export function convertUploadResult(result: SalaryRangesUploadResult): SalaryRangesUploadInput | undefined {
  if (!result.salaryRangeUploadRecords?.every(({record}) => record) || !result.versionUploadRecord.record) {
    return undefined;
  }

  return {
    id: result.id,
    salaryRangeRecords: result.salaryRangeUploadRecords.map(({record}) => record!),
    versionRecord: result.versionUploadRecord.record,
  };
}
