import {MarketDataReadAccess} from 'domain/marketDataRecord/model/access';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFull} from 'domain/user/model';
import {useAcl} from 'services/acl';

export function useMarketDataReadAccess(user: UserFull | undefined): MarketDataReadAccess | null {
  const acl = useAcl();
  const selfUserId = useSelfUserId();

  const canReadAny = acl.hasSecurePermission(SecurePermission.MARKET_DATA_RECORD_READ_ANY);

  if (!user || !acl.inVpn()) {
    return null;
  }

  if (canReadAny) {
    return MarketDataReadAccess.ANY;
  }
  if (selfUserId && user.ancestorIds.includes(selfUserId)) {
    return MarketDataReadAccess.SUBORDINATE;
  }
  if (user.id === selfUserId) {
    return MarketDataReadAccess.MY;
  }

  return null;
}
