import {ReactComponent as UserIcon} from '@joomcode/joom-ui/icons/core/user.svg';
import {DottedList} from 'components/ui/DottedList';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {PointInfo} from 'domain/officeMap/office/widgets/PointInfo';
import {OfficeTooltip} from 'domain/officeMap/office/widgets/Tooltip';
import {OfficeRoom} from 'domain/officeMap/room/model';
import {roomAttributeMessages, roomMessages} from 'domain/officeMap/room/model/messages';
import {RoomBooking} from 'domain/officeMap/room/widgets/Booking';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {officeUrls} from 'routes/officeMap/urls';
import {useUsersnapApi, UserSnapEvents} from 'services/usersnap';
import {messages} from './messages';
import styles from './roomTooltip.css';

type Props = {
  room: OfficeRoom;
  officeAlias: OfficeAlias;
  onRoomSelect: (room: OfficeRoom | null, officeAlias?: OfficeAlias) => void;
};

export function RoomTooltip({onRoomSelect, officeAlias, room}: Props) {
  const intl = useIntl();
  const usersnapApi = useUsersnapApi();
  const onRoomHide = useCallback(() => {
    onRoomSelect(null);
    usersnapApi?.logEvent(UserSnapEvents.OFFICE_MAP_USAGE);
  }, [onRoomSelect]);

  const content = (
    <PointInfo
      description={room.canBook && <RoomBooking room={room} officeAlias={officeAlias} />}
      copyIconLabel={intl.formatMessage(messages.copyIconLabel)}
      title={intl.formatMessage(messages.roomTitle, {name: room.fullText || room.text})}
      pointUrl={officeUrls.room({alias: officeAlias}, room)}
    >
      {room.capacity !== undefined && (
        <div>
          <span className={styles.capacityIcon}>
            <UserIcon />
          </span>{' '}
          {intl.formatMessage(roomMessages.capacity, {count: room.capacity})}
        </div>
      )}
      {room.attributes && (
        <div className={styles.equipment}>
          <DottedList>
            {room.attributes?.map((attribute) => (
              <DottedList.Item key={attribute}>{intl.formatMessage(roomAttributeMessages[attribute])}</DottedList.Item>
            ))}
          </DottedList>
        </div>
      )}
      {room.description && <div className={styles.description}>{room.description}</div>}
    </PointInfo>
  );

  return (
    <OfficeTooltip content={content} onHide={onRoomHide} type='room'>
      <g>
        <circle className={styles.dot} cx={room.coordinates.x} cy={room.coordinates.y} r='0.5' fill='#000000' />
      </g>
    </OfficeTooltip>
  );
}
