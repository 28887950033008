import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  country: {
    defaultMessage: 'Country',
    description: 'User address property label (13+ chars long requires a visual review)',
  },
  city: {
    defaultMessage: 'City',
    description: 'User address property label (13+ chars long requires a visual review)',
  },
  postalCode: {
    defaultMessage: 'Postal code',
    description: 'User address property label (13+ chars long requires a visual review)',
  },
  streetAddress: {
    defaultMessage: 'Address',
    description: 'User address property label (13+ chars long requires a visual review)',
  },
  comments: {
    defaultMessage: 'Comment',
    description: 'User address property label (13+ chars long requires a visual review)',
  },
});
