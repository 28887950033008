import {defineMessages} from 'react-intl';
import {OneTimeBonusType} from '.';

export const oneTimeBonusTypes = defineMessages<OneTimeBonusType>({
  [OneTimeBonusType.SIGN_ON]: {
    defaultMessage: 'Sign-on',
    description: 'One-time bonus type',
  },
  [OneTimeBonusType.REFERRAL]: {
    defaultMessage: 'Referral',
    description: 'One-time bonus type',
  },
  [OneTimeBonusType.RELOCATION]: {
    defaultMessage: 'Relocation',
    description: 'One-time bonus type',
  },
  [OneTimeBonusType.RETENTION]: {
    defaultMessage: 'Retention',
    description: 'One-time bonus type',
  },
  [OneTimeBonusType.OTHER]: {
    defaultMessage: 'Other',
    description: 'One-time bonus type',
  },
});
