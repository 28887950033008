import React, {FC, ReactNode} from 'react';
import styles from './index.css';

type Props = {
  children: ReactNode;
};

/** Make numbers inside the cell to be all of the same size (monospaced) */
export const CellNumeric: FC<Props> = ({children}: Props) => {
  return <span className={styles.root}>{children}</span>;
};
