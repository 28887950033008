import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  keyLabel: {
    defaultMessage: 'Rtconf key',
    description: 'Form label',
  },
  valueLabel: {
    defaultMessage: 'Rtconf value. Choose very carefully, wrong value type can easily break functionality.',
    description: 'Form label',
  },
  createButton: {
    defaultMessage: 'Create',
    description: '[button] Form submit button',
  },
  keyHint: {
    defaultMessage: 'Key can contain only latin letters, numbers and symbols ‘.’, ‘-’, ‘_’',
    description: 'RTConf Key validation error',
  },
  valueHint: {
    defaultMessage: `Value must be a valid JSON`,
    description: 'RTConf Value validation error',
  },
});
