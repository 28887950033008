import {createAutocompleteMultiselectFilter, FilterItem} from '@joomcode/joom-ui/DataFilter';
import {AutocompleteMultiSelectFilterOptions} from '@joomcode/joom-ui/DataFilter/FilterCreators/AutocompleteMultiSelect/types';
import {User, UserId} from 'domain/user/model';
import {UserSuggestion} from 'domain/user/widgets/Suggestion';
import {generalMessages} from 'i18n/messages/general';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Options = {
  label: string;
  users: User[];
  userById: Record<UserId, User>;
};

export const useUsersFilter = ({
  label,
  users,
  userById,
}: Options): FilterItem<UserId[], AutocompleteMultiSelectFilterOptions<UserId>> => {
  const intl = useIntl();
  const usersFilter = useMemo(
    () =>
      createAutocompleteMultiselectFilter(label, {
        alwaysVisible: true,
        options: users.map(({firstName, lastName, login, id}) => ({
          label: intl.formatMessage(messages.usersFilterLabel, {
            fullName: intl.formatMessage(generalMessages.fullName, {
              firstName,
              lastName,
            }),
            login,
          }),
          value: id,
        })),
        renderOption: ({value: userId}) => <UserSuggestion user={userById[userId]} />,
      }),
    [intl, label, users, userById],
  );

  return usersFilter;
};
