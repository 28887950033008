import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import {changeParentFx} from 'domain/team/stores/main';
import {FieldTeam, FieldTeamValue} from 'domain/team/widgets/Field';
import React, {useCallback, useMemo} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  team: Team;
  onClose: () => void;
};

export function ChangeParentDialog({team, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const onSubmit = useCallback(
    ({parentTeamId}: {parentTeamId: TeamId}) =>
      changeParentFx({id: team.id, parentTeamId})
        .then(() => {
          onClose();
        })
        .catch(toaster.interceptThenThrowError),
    [team.id, onClose],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 24rem)'>
      <Form<{parentTeamId: TeamId}> onSubmit={onSubmit} id={formId}>
        {({submitting, invalid, handleSubmit}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <form onSubmit={handleSubmit} id={formId}>
                <Field<FieldTeamValue> name='parentTeamId'>
                  {({meta}) => (
                    <FormControl
                      disabled={submitting}
                      label={intl.formatMessage(messages.parentTeamLabel)}
                      error={getFieldErrorText(meta, {intl})}
                      required
                    >
                      {(formControlProps) => (
                        <FieldTeam name='parentTeamId' initialValue={team.parentTeamId} {...formControlProps} />
                      )}
                    </FormControl>
                  )}
                </Field>
              </form>
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
