import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plus.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {useJobContracts} from 'domain/jobContract/hooks/useJobContracts';
import {JobContractRecordDialog} from 'domain/jobContract/widgets/Dialog';
import {JobContractPanel} from 'domain/jobContract/widgets/Panel';
import {Permission} from 'domain/permission/model';
import {UserFull} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

type Props = {
  user: UserFull;
};

export function JobContractPanels({user}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canEdit = acl.hasPermission(Permission.JOB_CONTRACT_WRITE);
  const {data: jobContractsData, dataState: jobContractsDataState} = useJobContracts(user);
  const addDialog = usePopupState();

  const renderError = useCallback(
    () => (
      <Panel withPadding>
        <Error message={intl.formatMessage(messages.loadingError)} />
      </Panel>
    ),
    [intl],
  );

  return (
    <StateHandler state={jobContractsDataState} data={jobContractsData} renderError={renderError}>
      {(jobContracts) => {
        if (!jobContracts) {
          return null;
        }

        const userHasMultipleContracts = jobContracts.length > 1;
        return (
          <>
            {jobContracts.map((jobContract) => (
              <JobContractPanel
                key={jobContract.legalEntity.id}
                jobContract={jobContract}
                userHasMultipleContracts={userHasMultipleContracts}
                user={user}
              />
            ))}
            {canEdit && (
              <>
                <Panel withPadding>
                  <ButtonGroup align='center' spaced size='m'>
                    <Button kind='text' intent='neutral' iconLeft={<PlusIcon />} onClick={addDialog.open}>
                      {intl.formatMessage(messages.buttonAdd)}
                    </Button>
                  </ButtonGroup>
                </Panel>
                <JobContractRecordDialog user={user} isOpen={addDialog.isOpen} onClose={addDialog.close} />
              </>
            )}
          </>
        );
      }}
    </StateHandler>
  );
}
