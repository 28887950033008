import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'General',
    description: '[title] Compensation review request section title',
  },
});

export const labels = defineMessages({
  division: {
    defaultMessage: 'Business unit',
    description: 'Compensation review request label',
  },
  department: {
    defaultMessage: 'Department',
    description: 'Compensation review request label',
  },
  managerId: {
    defaultMessage: 'Manager',
    description: 'Compensation review request label',
  },
  userFunction: {
    defaultMessage: 'Function',
    description: 'Compensation review request label',
  },
  levelCode: {
    defaultMessage: 'Level',
    description: 'Compensation review request label',
  },
  jobTitle: {
    defaultMessage: 'Job title',
    description: 'Compensation review request label',
  },
});
