import {detectCsvFileDelimiter} from '@joomcode/deprecated-utils/csv';
import {mimeType} from '@joomcode/deprecated-utils/file/mime';
import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {ValidationFunction} from '../../types';
import {validateFile} from '../file';
import {CsvValidatorErrorCode, CsvValidatorOptions} from './types';

export const validateCsv: ValidationFunction<unknown, CsvValidatorOptions, CsvValidatorErrorCode> = async (
  value,
  options = {},
) => {
  const {allowedDelimiters} = options;

  if (isNullish(value)) return undefined;

  if (await validateFile(value, {mimeTypes: [mimeType.csv]})) {
    return {code: CsvValidatorErrorCode.CSV_FILE_INVALID, options};
  }

  if (allowedDelimiters) {
    const delimiter = await detectCsvFileDelimiter(value as File);

    if (!delimiter) {
      return {code: CsvValidatorErrorCode.CSV_DELIMITER_NOT_FOUND, options};
    }

    if (!allowedDelimiters.includes(delimiter)) {
      return {code: CsvValidatorErrorCode.CSV_DELIMITER_INVALID, options};
    }
  }

  return undefined;
};
