import React from 'react';

export type SideMenuContextShape = {
  level: number;
};
export const SideMenuContext = React.createContext<SideMenuContextShape>({
  level: 0,
});

SideMenuContext.displayName = 'SideMenu.Context';
