import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Compensation request receipt: cancel',
  },
  submitButton: {
    defaultMessage: 'Done',
    description: '[button] Compensation request receipt: submit',
  },
  dialogAriaLabel: {
    defaultMessage: 'Expense details',
    description: 'Compensation request receipt: aria label',
  },
  header: {
    defaultMessage: 'Expense details',
    description: '[title] Compensation request receipt: title',
  },
  commentHint: {
    defaultMessage:
      'If your receipt is in a foreign language, please provide a short summary here: where it’s from; what the service provided was.',
    description: 'Compensation request receipt: comment field hint',
  },
  commentHintForLostReceipt: {
    defaultMessage: 'Write down how the receipt was lost. This is necessary for financial reporting.',
    description: 'Compensation request receipt: comment field hint (lost receipt case)',
  },
  lostReceiptHint: {
    defaultMessage: 'If the receipt was lost we can compensate your expenses upon your request.',
    description: 'Compensation request receipt: lost receipt field hint',
  },
  attachReceiptsButton: {
    defaultMessage: 'Attach a receipt',
    description: '[button] Compensation request receipt: attach receipt',
  },
  attachMoreReceiptsButton: {
    defaultMessage: 'Attach another receipt',
    description: '[button] Compensation request receipt: attach more receipts',
  },
  notice: {
    defaultMessage:
      'You can attach a good quality photo, a scan or an electronic receipt. Enter the total amount paid and the currency used. In case of several receipts, add them in separate forms.',
    description: 'Compensation report form: section annotation',
  },
});

export const fieldLabels = defineMessages({
  amount: {
    defaultMessage: 'Amount',
    description: 'Compensation request receipt field label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'Compensation request receipt field label',
  },
  comment: {
    defaultMessage: 'Comments',
    description: 'Compensation request receipt field label',
  },
  lostReceipt: {
    defaultMessage: 'I lost my receipt',
    description: 'Compensation request receipt field label',
  },
  name: {
    defaultMessage: 'Name',
    description: 'Compensation request receipt field label',
  },
});

export const errorMessages = defineMessages({
  emptyFiles: {
    defaultMessage: 'Attach a file (unless you lost the receipt)',
    description: 'Compensation request receipt field error',
  },
});
