import {Autocomplete, AutocompleteProps} from '@joomcode/joom-ui/Select/Autocomplete';
import {Resource} from 'domain/resource/model';
import sortBy from 'lodash/sortBy';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

const getItemKey = ({id}: Resource) => id;
const itemToString = ({value, service}: Resource) => `${service.name} → ${value}`;
const filter = (resource: Resource, inputValue: string) => {
  const words = inputValue
    .split(' ')
    .filter((s) => s)
    .map((s) => s.toLowerCase());

  return words.every(
    (w) => resource.value.toLowerCase().includes(w) || resource.service.name.toLowerCase().includes(w),
  );
};

export type Props = Omit<
  AutocompleteProps<Resource>,
  'filter' | 'getItemKey' | 'noItemsText' | 'onInputValueChange' | 'renderItem' | 'itemToString'
>;

export function ResourceAutocomplete({items, ...props}: Props) {
  const intl = useIntl();
  const sortedItems = useMemo(() => sortBy(items, [({service}) => service.name, ({value}) => value]), [items]);

  return (
    <Autocomplete<Resource>
      getItemKey={getItemKey}
      items={sortedItems}
      itemToString={itemToString}
      filter={filter}
      noItemsText={intl.formatMessage(messages.noItems)}
      renderItem={itemToString}
      {...props}
    />
  );
}
