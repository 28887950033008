import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {KnownServerSecurePermission, ServerSecurePermission} from 'domain/permission/model/secure';
import {permissionDescriptions} from 'domain/permission/model/secure/messages';
import {IntlShape} from 'react-intl';

const allKnownPermissionsObject: Record<KnownServerSecurePermission, KnownServerSecurePermission> = arrayToObject(
  getEnumValues(KnownServerSecurePermission),
  identity,
);

function isKnownServerPermission(permissionName: string): permissionName is KnownServerSecurePermission {
  return permissionName in allKnownPermissionsObject;
}

export function getSecurePermissionDescription(permission: ServerSecurePermission, intl: IntlShape): string {
  return isKnownServerPermission(permission.name) ? intl.formatMessage(permissionDescriptions[permission.name]) : '';
}
