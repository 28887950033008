import {isImageMimeType, mimeType} from '@joomcode/deprecated-utils/file/mime';
import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {FormattedFileSize} from '@joomcode/joom-ui/FormattedFileSize';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {buildDocumentPreviewLink} from 'domain/offer/api/previewDocument';
import {OfferId} from 'domain/offer/model';
import {FileMeta} from 'models/system/fileMeta';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  offerId: OfferId;
  fileMeta: FileMeta;
  altText: string;
};

export function DocumentPreview({offerId, fileMeta, altText}: Props) {
  const intl = useIntl();
  const link = buildDocumentPreviewLink({offerId, fileId: fileMeta.id});

  if (fileMeta.mimeType === mimeType.pdf) {
    return <iframe className={styles.iframe} src={`${link}#toolbar=0&navpanes=0`} title={altText} />;
  }

  if (fileMeta.mimeType && isImageMimeType(fileMeta.mimeType)) {
    return <img className={styles.image} src={link} alt={altText} />;
  }

  return (
    <DownloadLinkButton
      size='m'
      kind='secondary'
      intent='neutral'
      href={link}
      iconLeft={<DownloadIcon />}
      target='_blank'
    >
      {intl.formatMessage(messages.btnDownload, {
        fileSize: <FormattedFileSize value={fileSizeUtils.createMeasure(fileMeta.size, FileSizeUnit.Byte)} />,
      })}
    </DownloadLinkButton>
  );
}
