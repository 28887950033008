import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonTerminate: {
    defaultMessage: 'Terminate',
    description: '[button] User job info panel: employments tab: termination button',
  },
  buttonCancelTermination: {
    defaultMessage: 'Cancel termination',
    description: '[button] User job info panel: employments tab: termination cancelling button',
  },
  cancelTerminationConfirmTitle: {
    defaultMessage: 'Cancel termination',
    description: '[title] Cancel termination confirmation: title',
  },
  cancelTerminationConfirmText: {
    defaultMessage:
      'Are you sure you want to cancel the employee’s termination? This will erase all termination information from the current employment record.',
    description: 'Cancel termination confirmation: text',
  },
  cancelTerminationConfirmButton: {
    defaultMessage: 'Cancel termination',
    description: '[button] Cancel termination confirmation: button',
  },
});
