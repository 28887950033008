import isEqual from 'lodash/isEqual';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import type {Decoder} from '../jsonValidation';
import {isNotNullish} from '../function';

type SetState<T> = (value: T) => void;

export function useQueryParam<T extends string>(name: string, schema: Decoder<T>, initial: T): [T, SetState<T>] {
  const history = useHistory();
  const previousRef = useRef<T | undefined>();

  const value = useMemo<T>(() => {
    const params = new URLSearchParams(history.location.search);
    const res = schema.run(params.get(name));

    if (res.ok) {
      if (isNotNullish(previousRef.current) && isEqual(res.result, previousRef.current)) {
        return previousRef.current;
      }
      previousRef.current = res.result;
      return res.result;
    }

    return initial;
  }, [history.location.search]);

  const setValue = useCallback<SetState<T>>(
    (newValue) => {
      const params = new URLSearchParams(history.location.search);

      if (isNotNullish(newValue) && newValue !== initial) {
        params.set(name, newValue);
      } else {
        params.delete(name);
      }

      const search = `?${params}`;
      if (search !== history.location.search) {
        history.replace({...history.location, search});
      }
    },
    [history],
  );

  useEffect(() => {
    if (isNotNullish(value) && isEqual(initial, value)) {
      setValue(initial);
    }
  }, []);

  return [value ?? initial, setValue];
}
