import {DataState, getConsolidatedAccessDependentDataState} from '@joomcode/deprecated-utils/dataState';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {Tab, Tabs} from '@joomcode/joom-ui/Tabs';
import {EmptyMessage} from 'components/ui/EmptyMessage';
import {SensitiveDataSwitch} from 'components/ui/SensitiveDataSwitch';
import {useMarketDataReadAccess} from 'domain/marketDataRecord/hooks/useMarketDataReadAccess';
import {useOfficePolicyAvailability} from 'domain/officePolicyRecord/hooks/useOfficePolicyAvailability';
import {Permission} from 'domain/permission/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {useRegularBonusesAvailability} from 'domain/regularBonusRecord/hooks/useRegularBonusesAvailability';
import {useRegularBonusReadAccess} from 'domain/regularBonusRecord/hooks/useRegularBonusReadAccess';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {useEmploymentTab} from 'domain/user/hooks/useEmploymentTab';
import {useJobInfoTab} from 'domain/user/hooks/useJobInfoTab';
import {useMarketDataTab} from 'domain/user/hooks/useMarketDataTab';
import {useOfficePolicyTab} from 'domain/user/hooks/useOfficePolicyTab';
import {useRegularBonusTab} from 'domain/user/hooks/useRegularBonusTab';
import {UserFull} from 'domain/user/model';
import {withVpn} from 'hocs/withVpn';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  user: UserFull;
};

export enum JobTabId {
  JOB_INFO = 'jobInfo',
  EMPLOYMENTS = 'employments',
  REGULAR_BONUS = 'regularBonus',
  MARKET_DATA = 'marketData',
  OFFICE_POLICY = 'officePolicy',
}

export type JobPanelTab<Record> = {
  addon?: React.ReactNode;
  content: React.ReactNode;
  dialog: React.ReactNode;
  records: Record[];
  dataState: DataState;
};

export const JobPanel = withVpn(({user}: Props) => {
  const acl = useAcl();
  const intl = useIntl();
  const selfUserId = useSelfUserId();
  const [isSensitiveDataHidden, setSensitiveDataHidden] = useState<boolean>(true);
  const onSensitiveDataToggle = useCallback(() => {
    setSensitiveDataHidden((isHidden) => !isHidden);
  }, [setSensitiveDataHidden]);
  const tabsWithSensitiveData: Set<JobTabId> = useMemo(
    () => new Set([JobTabId.REGULAR_BONUS, JobTabId.MARKET_DATA]),
    [],
  );

  const jobInfoTab = useJobInfoTab(user.id);
  const employmentTab = useEmploymentTab(user.id);
  const regularBonusTab = useRegularBonusTab(user, isSensitiveDataHidden);
  const marketDataTab = useMarketDataTab(user, isSensitiveDataHidden);
  const officePolicyTab = useOfficePolicyTab(user);

  const canSeeSelfJobInfo = acl.hasPermission(Permission.SELF_JOB_INFO_RECORD_READ);
  const canSeeOthersJobInfo = acl.hasPermission(Permission.USER_JOB_INFO_RECORD_READ);
  const canSeeJobInfo = (user.id === selfUserId && canSeeSelfJobInfo) || canSeeOthersJobInfo;
  const canSeeEmployments = acl.hasPermission(Permission.USER_EMPLOYMENT_READ);
  const canSeeRegularBonusRecords = useRegularBonusesAvailability(user, regularBonusTab.records);
  const canRequestRegularBonusRecords = useRegularBonusReadAccess(user);
  const canSeeMarketDataRecords = useMarketDataReadAccess(user);
  const canSeeOfficePolicyRecords = useOfficePolicyAvailability(user);

  const canEditJobInfoRecords = acl.hasPermission(Permission.USER_JOB_INFO_RECORD_WRITE);
  const canEditRegularBonusRecords = acl.hasSecurePermission(SecurePermission.REGULAR_BONUS_RECORD_WRITE);
  const canEditMarketDataRecords = acl.hasSecurePermission(SecurePermission.MARKET_DATA_RECORD_WRITE);
  const canEditEmployments = acl.hasPermission(Permission.USER_EMPLOYMENT_WRITE);
  const canEditOfficePolicyRecords = acl.hasPermission(Permission.OFFICE_POLICY_RECORD_WRITE);

  const tabs = useMemo(() => {
    const result: Tab<JobTabId>[] = [];
    if (canSeeJobInfo) {
      result.push({
        id: JobTabId.JOB_INFO,
        label: intl.formatMessage(messages.tabJobInfo),
        content: jobInfoTab.content,
      });
    }
    if (canSeeEmployments) {
      result.push({
        id: JobTabId.EMPLOYMENTS,
        label: intl.formatMessage(messages.tabEmployments),
        content: employmentTab.content,
      });
    }
    if (canSeeRegularBonusRecords) {
      result.push({
        id: JobTabId.REGULAR_BONUS,
        label: intl.formatMessage(messages.tabRegularBonus),
        content: regularBonusTab.content,
      });
    }
    if (canSeeMarketDataRecords) {
      result.push({
        id: JobTabId.MARKET_DATA,
        label: intl.formatMessage(messages.tabMarketData),
        content: marketDataTab.content,
      });
    }
    if (canSeeOfficePolicyRecords) {
      result.push({
        id: JobTabId.OFFICE_POLICY,
        label: intl.formatMessage(messages.tabOfficePolicy),
        content: officePolicyTab.content,
      });
    }
    return result;
  }, [
    canSeeJobInfo,
    canSeeEmployments,
    canSeeRegularBonusRecords,
    canSeeMarketDataRecords,
    jobInfoTab.content,
    employmentTab.content,
    regularBonusTab.content,
    marketDataTab.content,
    officePolicyTab.content,
    isSensitiveDataHidden,
    intl,
    user,
  ]);

  const tabAddonByTabId: Record<JobTabId, React.ReactNode> = useMemo(
    () => ({
      [JobTabId.JOB_INFO]: jobInfoTab.addon,
      [JobTabId.EMPLOYMENTS]: employmentTab.addon,
      [JobTabId.REGULAR_BONUS]: regularBonusTab.addon,
      [JobTabId.MARKET_DATA]: marketDataTab.addon,
      [JobTabId.OFFICE_POLICY]: officePolicyTab.addon,
    }),
    [jobInfoTab.addon, employmentTab.addon, regularBonusTab.addon, marketDataTab.addon, officePolicyTab.addon],
  );

  const [activeTabId, setActiveTabId] = useState<JobTabId>(tabs[0]?.id ?? JobTabId.JOB_INFO);

  if (tabs.length === 0) {
    return null;
  }

  return (
    <Panel>
      <StateHandler
        data={regularBonusTab.records}
        state={getConsolidatedAccessDependentDataState([
          regularBonusTab.dataState,
          Boolean(canRequestRegularBonusRecords),
        ])}
      >
        {() => (
          <>
            <Panel.Header title={intl.formatMessage(messages.title)} withSeparator />
            {tabs.length > 0 ? (
              <Tabs<JobTabId>
                renderActiveTabPanelOnly
                activeTabId={activeTabId}
                onChange={setActiveTabId}
                tabs={tabs}
                addonRight={
                  <div className={styles.toolbar}>
                    <ButtonGroup spaced size='m'>
                      {tabsWithSensitiveData.has(activeTabId) && (
                        <SensitiveDataSwitch isDataHidden={isSensitiveDataHidden} onClick={onSensitiveDataToggle} />
                      )}
                      {tabAddonByTabId[activeTabId]}
                    </ButtonGroup>
                  </div>
                }
              />
            ) : (
              <Panel.Content withPadding>
                <EmptyMessage>{intl.formatMessage(messages.empty)}</EmptyMessage>
              </Panel.Content>
            )}
            {canEditJobInfoRecords && <>{jobInfoTab.dialog}</>}
            {canEditEmployments && <>{employmentTab.dialog}</>}
            {canEditRegularBonusRecords && <>{regularBonusTab.dialog}</>}
            {canEditMarketDataRecords && <>{marketDataTab.dialog}</>}
            {canEditOfficePolicyRecords && <>{officePolicyTab.dialog}</>}
          </>
        )}
      </StateHandler>
    </Panel>
  );
});
