import {useValueRef} from '@joomcode/deprecated-utils/react/useValueRef';
import cn from 'classnames';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {ConvertLocatorToTestId, Locator, Mark, createLocator} from '../create-locator';
import {Checkbox, CheckboxLocator} from '../Checkbox';
import styles from './index.css';
import {messages} from './messages';
import {useCheckboxGroupUi} from './useCheckboxGroupUi';
import type {Props, Option} from './useCheckboxGroupUi';
import type {Direction} from './types';
import {CheckboxGroupItem, CheckboxGroupItemLocator} from './Item';

export type {Option} from './useCheckboxGroupUi';
export type {Direction} from './types';

export type CheckboxGroupTestId = ConvertLocatorToTestId<CheckboxGroupLocator>;
export type CheckboxGroupLocator = Locator<{
  selectAll: CheckboxLocator;
  checkboxGroupItem: CheckboxGroupItemLocator;
}>;

export type CheckboxGroupProps<Item> = Props<Item> & {
  direction?: Direction;
  invalid?: boolean;
  name?: string;
  renderItem?: (option: Option<Item>, props: JSX.IntrinsicElements['input']) => React.ReactNode;
  selectAllAllowed?: boolean;
  selectAllLabel?: string;
  itemToString?: (item: Item) => string;
} & Partial<Mark<CheckboxGroupLocator>>;

export function CheckboxGroup<Item>(props: CheckboxGroupProps<Item>) {
  const intl = useIntl();
  const checkboxGroup = useCheckboxGroupUi<Item>(props);
  const {
    direction = 'vertical',
    disabled,
    invalid,
    onChange,
    options,
    renderItem,
    itemToString,
    selectAllAllowed,
    selectAllLabel,
    values,
    ...rest
  } = props;

  // Wrap values in ref to make memoized callback `handleChange` independent from values change
  const valuesRef = useValueRef(values);
  const locator = createLocator(rest);

  const handleChange = useCallback(
    ({option, checked}: {option: Option<Item>; checked: boolean}) => {
      const currentValues = valuesRef.current;

      if (checked && !currentValues.includes(option.value)) {
        onChange([...currentValues, option.value]);
      }

      if (!checked) {
        onChange(currentValues.filter((item) => item !== option.value));
      }
    },
    [onChange],
  );

  return (
    <>
      {selectAllAllowed ? (
        <div className={styles.allControl}>
          <Checkbox
            disabled={disabled}
            invalid={invalid}
            indeterminate={values.length > 0 && options.length !== values.length}
            checked={options.length === values.length}
            label={selectAllLabel ?? intl.formatMessage(messages.selectAll)}
            onChange={(e) => (e.target.checked ? checkboxGroup.checkAll() : checkboxGroup.uncheckAll())}
            {...locator.selectAll()}
          />
        </div>
      ) : null}

      <div {...checkboxGroup.getRootProps()} className={cn({[styles.columns]: direction === 'columns'})}>
        {options.map((option) => {
          return (
            <CheckboxGroupItem
              key={String(option.value)}
              direction={direction}
              renderItem={renderItem}
              itemToString={itemToString}
              option={option}
              {...checkboxGroup.getCheckboxProps({value: option.value, disabled: option.disabled})}
              onChange={handleChange}
              {...locator.checkboxGroupItem()}
            />
          );
        })}
      </div>
    </>
  );
}
