import {intranetApi} from 'apiClient';
import {Offer, OfferId, offerSchema} from 'domain/offer/model';

export type OfferSetDeclinedConfig = {
  id: OfferId;
  reasons: string[];
};

export const setDeclined = async ({id, reasons}: OfferSetDeclinedConfig): Promise<Offer> => {
  const {data} = await intranetApi.post('/v1/offers/setDeclined', {reasons}, {params: {id}});

  return offerSchema.runWithException(data);
};
