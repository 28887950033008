import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Breadcrumb, Page} from 'components/widgets/Page';
import {useCompensationReviewRequest} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequest';
import {useCompensationReviewRequestReadAccess} from 'domain/compensationReviewRequest/hooks/useCompensationReviewRequestReadAccess';
import {compensationReviewRequestIdSchema} from 'domain/compensationReviewRequest/model';
import {CompensationReviewRequestActions} from 'domain/compensationReviewRequest/widgets/Actions';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/widgets/Request';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';

type Props = {
  breadcrumbs: Breadcrumb[];
  notionGuide?: string;
  title: string;
};

export function CompensationReviewRequestPage({breadcrumbs, notionGuide, title}: Props) {
  const readAccess = useCompensationReviewRequestReadAccess();
  const {id} = useTypedParams({id: compensationReviewRequestIdSchema});
  const {data, dataState, clientErrorStatus} = useCompensationReviewRequest(id);

  if (!readAccess || !id) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={data} entityError={clientErrorStatus} state={dataState}>
      {(request) => {
        return (
          <Page
            title={title}
            breadcrumbs={breadcrumbs}
            actions={<CompensationReviewRequestActions request={request} />}
            notionGuide={notionGuide}
          >
            <CompensationReviewRequest request={request} />
          </Page>
        );
      }}
    </EntityPageStateHandler>
  );
}
