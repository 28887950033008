import React, {useCallback, useMemo, useRef, useState} from 'react';
import {usePageLocker} from '../usePageLocker';
import {SharedPageLockerAdmin} from './types';

export const SharedPageLockerContext = React.createContext<SharedPageLockerAdmin | null>(null);

export type SharedPageLockerProviderProps = {
  children: React.ReactNode;
};

export function SharedPageLockerProvider<T>({children}: SharedPageLockerProviderProps) {
  const locker = usePageLocker();
  const [tokens, setTokens] = useState<Set<T>>(new Set());
  const tokensRef = useRef<Set<T>>(new Set());

  const updateState = useCallback(() => {
    if (tokensRef.current.size > 0) {
      locker.lock();
    } else {
      locker.unlock();
    }
    setTokens(new Set(tokensRef.current));
  }, []);

  const lock = useCallback((token: T) => {
    tokensRef.current.add(token);
    updateState();
  }, []);

  const unlock = useCallback((token: T) => {
    tokensRef.current.delete(token);
    updateState();
  }, []);

  const unlockAll = useCallback(() => {
    tokensRef.current.clear();
    updateState();
  }, []);

  const sharedLocker = useMemo<SharedPageLockerAdmin<T>>(
    () => ({
      lock,
      unlock,
      unlockAll,
      tokens,
      locked: locker.locked,
    }),
    [locker.locked, tokens],
  );

  return <SharedPageLockerContext.Provider value={sharedLocker}>{children}</SharedPageLockerContext.Provider>;
}
