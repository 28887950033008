import {Button} from '@joomcode/joom-ui/Button';
import {usePageLocker} from '@joomcode/joom-ui/PageLock';
import {useOfferToggableSections} from 'domain/offer/hooks/useOfferToggableSections';
import {createOfferFx} from 'domain/offer/stores/main';
import {OfferForm} from 'domain/offer/widgets/Form';
import {convertRegularBonusValue} from 'domain/regularBonusRecord/utils/convertFormValues';
import {DEFAULT_ISSUER} from 'domain/stockOption/issuer/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {offerUrls} from 'routes/offer/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import {OfferFormState} from './state';

export function OfferCreateForm() {
  const intl = useIntl();
  const history = useHistory();
  const toggableSections = useOfferToggableSections({
    hasStockOptions: false,
    hasFurtherSalary: false,
  });
  const {hasStockOptions} = toggableSections;
  const pageLocker = usePageLocker();

  const onSubmit = useCallback(
    (state: OfferFormState) => {
      // Convert data to format suitable for backend
      const {regularBonus, signOnBonus, relocationBonus} = state.compensation;
      const diff: OfferFormState = {
        ...state,
        compensation: {
          ...state.compensation,
          regularBonus: regularBonus?.type
            ? {
                ...regularBonus,
                value: convertRegularBonusValue(regularBonus.type, regularBonus.value),
              }
            : undefined,
          signOnBonus: signOnBonus?.amount && signOnBonus?.currency ? signOnBonus : undefined,
          relocationBonus: relocationBonus?.amount && relocationBonus?.currency ? relocationBonus : undefined,
        },
        stockOptions:
          hasStockOptions && state.stockOptions?.amount
            ? {
                ...state.stockOptions,
                issuer: DEFAULT_ISSUER,
              }
            : undefined,
      };

      return createOfferFx(diff)
        .then(({id}) => {
          pageLocker.unlock();
          history.push(offerUrls.offer({id}));
        })
        .catch(toaster.interceptThenThrowError);
    },
    [hasStockOptions],
  );

  return (
    <OfferForm<OfferFormState>
      extended
      toggableSections={toggableSections}
      pageLocker={pageLocker}
      onStockOptionsProposalLoad={toggableSections.enableStockOptions}
      onSubmit={onSubmit}
    >
      {({formId, invalid, submitting}) => (
        <Button
          size='l'
          kind='primary'
          intent='primary'
          type='submit'
          form={formId}
          disabled={invalid}
          loading={submitting}
        >
          {intl.formatMessage(messages.buttonCreate)}
        </Button>
      )}
    </OfferForm>
  );
}
