import {intranetApi} from 'apiClient';
import {JobContractRecordId} from 'domain/jobContract/model/record';
import {LegalEntityId} from 'domain/legalEntity/model';
import {UserId} from 'domain/user/model';

export type DeleteJobContractRecordParams = {
  id: JobContractRecordId;
  userId: UserId;
  legalEntityId: LegalEntityId;
};

export const deleteRecord = async ({id, userId}: DeleteJobContractRecordParams): Promise<void> => {
  return intranetApi.post('/v1/users/jobContractRecords/delete', undefined, {params: {id, userId}});
};
