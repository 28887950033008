import {Grid} from '@joomcode/joom-ui/Grid';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import cn from 'classnames';
import {ClampedText} from 'components/ui/ClampedText';
import {formatFrequency} from 'domain/frequency/model/formatter';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {FormattedRegularBonusValue} from 'domain/regularBonusRecord/widgets/FormattedValue';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  regularBonus: RegularBonusRecord;
  isNew?: boolean;
  isBold?: boolean;
};

export function RegularBonus({regularBonus, isBold, isNew}: Props) {
  const intl = useIntl();

  return (
    <>
      <Grid.Item xl={6} xs={12}>
        <div className={cn(styles.title, {[styles.bold]: isBold})}>
          <span className={styles.frequency}>{formatFrequency(regularBonus.frequency, intl)}</span>
          <span className={styles.note}>{intl.formatMessage(isNew ? messages.new : messages.current)}</span>
        </div>
        <div className={styles.value}>
          <FormattedRegularBonusValue value={regularBonus.value} />
          <div className={styles.dates}>
            {intl.formatMessage(messages.dates, {
              from: <HumanDate value={regularBonus.startDate} format='short' utc />,
              to: regularBonus.endDate ? <HumanDate value={regularBonus.endDate} format='short' utc /> : undefined,
            })}
          </div>
        </div>
      </Grid.Item>
      <Grid.Item xl={6} xs={12}>
        <ClampedText text={regularBonus.comment || ''} />
      </Grid.Item>
    </>
  );
}
