import {defineMessages} from 'react-intl';
import {StockOptionAgreementDocumentsStatus} from '.';

export const stockOptionAgreementDocumentsStatuses = defineMessages({
  [StockOptionAgreementDocumentsStatus.SENT_TO_EMPLOYEE]: {
    defaultMessage: 'Sent',
    description: 'Stock option agreement documents status',
  },
  [StockOptionAgreementDocumentsStatus.RECEIVED_WITH_EMPLOYEE_SIGNATURE]: {
    defaultMessage: 'Received signed',
    description: 'Stock option agreement documents status',
  },
  [StockOptionAgreementDocumentsStatus.SIGNED_BY_EMPLOYER]: {
    defaultMessage: 'Signed by Joom',
    description: 'Stock option agreement documents status',
  },
  [StockOptionAgreementDocumentsStatus.ISSUED_TO_EMPLOYEE]: {
    defaultMessage: 'Sent signed by Joom',
    description: 'Stock option agreement documents status',
  },
});
