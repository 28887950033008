import {
  array,
  boolean,
  ExtractSchemaType,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {MarketLevelPosition} from 'domain/compaRatio/model/marketLevelPosition';
import {LevelCode} from 'domain/levelCode/model';
import {TimeOffPolicy} from 'domain/timeOff/policy/model';

export const userFullInfoFiltersSchema = object({
  countriesOfTimeOffPolicy: optional(array(oneOfEnum(TimeOffPolicy))),
  crMarketLevelPositions: optional(array(oneOfEnum(MarketLevelPosition))),
  divisions: optional(array(string())),
  levelCodes: optional(array(oneOfEnum(LevelCode))),
  managerIds: optional(array(string())),
  showIndirect: optional(boolean()),
  userFunctions: optional(array(string())),
  userIds: optional(array(string())),
});

export type UserFullInfoFilters = Partial<ExtractSchemaType<typeof userFullInfoFiltersSchema>>;
