import {ExtractSchemaType, string} from '@joomcode/deprecated-utils/jsonValidation';

export const serverPermissionSchema = string();
export type ServerPermission = ExtractSchemaType<typeof serverPermissionSchema>;

export enum KnownServerPermission {
  COMPENSATION_CLOSE = 'CompensationClose',
  COMPENSATION_CANCEL = 'CompensationCancel',
  COMPENSATION_DOWNLOAD_ALL = 'CompensationDownloadAll',
  COMPENSATION_READ = 'CompensationRead',
  COMPENSATION_READ_ALL = 'CompensationReadAll',
  COMPENSATION_WRITE = 'CompensationWrite',
  COMPENSATION_WRITE_ADMIN = 'CompensationWriteAdmin',

  JOB_CONTRACT_READ = 'JobContractRead',
  JOB_CONTRACT_SUBORDINATE_READ = 'JobContractSubordinateRead',
  JOB_CONTRACT_WRITE = 'JobContractWrite',

  LEGAL_ENTITY_READ = 'LegalEntityRead',
  LEGAL_ENTITY_WRITE = 'LegalEntityWrite',

  MEMBERSHIP_ADMIN_WRITE = 'MembershipAdminWrite',
  MEMBERSHIP_WRITE = 'MembershipWrite',

  OFFER_CHANGE_STATUS = 'OfferChangeStatus',
  OFFER_DOCUMENT_DOWNLOAD = 'OfferDocumentDownload',
  OFFER_ENHANCE = 'OfferEnhance',
  OFFER_GENERATE_TOKEN = 'OfferGenerateToken',
  OFFER_READ = 'OfferRead',
  OFFER_SIGNING_PAGES_DOWNLOAD = 'OfferSigningPagesDownload',
  OFFER_UPDATE_GENERAL_ANY = 'OfferUpdateGeneralAny',
  OFFER_UPDATE_GENERAL_MY = 'OfferUpdateGeneralMy',
  OFFER_WRITE = 'OfferWrite',

  OFFICE_MAP_SEAT_WRITE = 'OfficeMapSeatWrite',
  OFFICE_MAP_SEAT_NUMBERS_SHOW = 'OfficeMapSeatNumbersShow',
  OFFICE_MAP_SEATS_DOWNLOAD_DATA = 'OfficeMapSeatsDataDownload',
  OFFICE_POINT_READ = 'OfficePointRead',
  OFFICE_ROOM_TOKEN_GENERATE = 'OfficeRoomTokenGenerate',

  OFFICE_POLICY_RECORD_READ = 'OfficePolicyRecordRead',
  OFFICE_POLICY_RECORD_WRITE = 'OfficePolicyRecordWrite',

  OUTSTAFF_MEMBERSHIP_ADMIN_WRITE = 'OutstaffMembershipAdminWrite',
  OUTSTAFF_MEMBERSHIP_WRITE = 'OutstaffMembershipWrite',

  REDIRECT_READ = 'RedirectRead',
  REDIRECT_WRITE = 'RedirectWrite',

  RESOURCE_READ = 'ResourceRead',
  RESOURCE_WRITE = 'ResourceWrite',

  ROLE_BINDING_DOWNLOAD = 'RoleBindingDownload',
  ROLE_BINDING_READ = 'RoleBindingRead',
  ROLE_BINDING_WRITE = 'RoleBindingWrite',
  ROLE_BINDING_WRITE_WITH_PROPAGATE = 'RoleBindingWriteWithPropagate',

  RTCONF_READ = 'RTConfRead',
  RTCONF_WRITE = 'RTConfWrite',

  SELF_JOB_INFO_RECORD_READ = 'SelfJobInfoRecordRead',

  SELF_TIME_OFF_REQUEST_READ = 'SelfTimeOffRequestRead',
  SELF_TIME_OFF_REQUEST_WRITE = 'SelfTimeOffRequestWrite',
  SELF_TIME_OFF_BALANCE_RECORD_READ = 'SelfBalanceRecordsRead',

  SERVICE_READ = 'ServiceRead',
  SERVICE_WRITE = 'ServiceWrite',

  SUBORDINATE_TIME_OFF_REQUEST_APPROVE = 'SubordinateTimeOffRequestApprove',
  SUBORDINATE_TIME_OFF_REQUEST_REJECT = 'SubordinateTimeOffRequestReject',

  TEAM_ADMIN_WRITE = 'TeamAdminWrite',
  TEAM_CREATE = 'TeamCreate',
  TEAM_DELETE = 'TeamDelete',
  TEAM_READ = 'TeamRead',
  TEAM_UPDATE = 'TeamUpdate',

  TIME_OFF_REQUEST_READ = 'TimeOffRequestRead',
  TIME_OFF_REQUEST_WRITE = 'TimeOffRequestWrite',
  TIME_OFF_REQUEST_WRITE_ON_BEHALF = 'TimeOffRequestWriteOnBehalf',
  TIME_OFF_BALANCE_RECORD_READ = 'BalanceRecordsRead',
  TIME_OFF_BALANCE_RECORD_WRITE = 'BalanceRecordsWrite',

  USER_ASSIGN_SEAT = 'UserAssignSeat',
  USER_READ_ACTIVE = 'UserReadActive',
  USER_READ_ANY = 'UserReadAny',
  USER_WRITE = 'UserWrite',

  USER_PRETEND_TO_BE_NORMAL = 'UserPretendToBeNormal',

  OUTSTAFF_USER_READ = 'OutstaffUserRead',

  USER_EMPLOYMENT_READ = 'UserEmploymentRead',
  USER_EMPLOYMENT_WRITE = 'UserEmploymentWrite',

  USER_JOB_INFO_RECORD_READ = 'UserJobInfoRecordRead',
  USER_JOB_INFO_RECORD_WRITE = 'UserJobInfoRecordWrite',

  USER_LEGAL_INFO_DOWNLOAD = 'UserLegalInfoDownload',
  USER_LEGAL_INFO_READ = 'UserLegalInfoRead',
  USER_LEGAL_INFO_WRITE = 'UserLegalInfoWrite',

  USER_MAIN_LEGAL_ENTITY_READ = 'UserMainLegalEntityRead',

  USER_PRIVATE_INFO_READ = 'UserPrivateInfoRead',
  USER_PRIVATE_INFO_WRITE = 'UserPrivateInfoWrite',

  USER_INTERVIEW_LINK_READ = 'UserInterviewLinkRead',

  USER_ROLE_READ = 'UserRoleRead',
  USER_ROLE_WRITE = 'UserRoleWrite',

  USERS_DATA_DOWNLOAD = 'UsersDataDownload',
}

export enum Permission {
  COMPENSATION_CLOSE = 'CompensationClose',
  COMPENSATION_CANCEL = 'CompensationCancel',
  COMPENSATION_DOWNLOAD_ALL = 'CompensationDownloadAll',
  COMPENSATION_READ = 'CompensationRead',
  COMPENSATION_READ_ALL = 'CompensationReadAll',
  COMPENSATION_WRITE = 'CompensationWrite',
  COMPENSATION_WRITE_ADMIN = 'CompensationWriteAdmin',

  JOB_CONTRACT_READ = 'JobContractRead',
  JOB_CONTRACT_SUBORDINATE_READ = 'JobContractSubordinateRead',
  JOB_CONTRACT_WRITE = 'JobContractWrite',

  LEGAL_ENTITY_READ = 'LegalEntityRead',
  LEGAL_ENTITY_WRITE = 'LegalEntityWrite',

  MEMBERSHIP_ADMIN_WRITE = 'MembershipAdminWrite',
  MEMBERSHIP_WRITE = 'MembershipWrite',

  OFFER_CHANGE_STATUS = 'OfferChangeStatus',
  OFFER_DOCUMENT_DOWNLOAD = 'OfferDocumentDownload',
  OFFER_ENHANCE = 'OfferEnhance',
  OFFER_GENERATE_TOKEN = 'OfferGenerateToken',
  OFFER_READ = 'OfferRead',
  OFFER_SIGNING_PAGES_DOWNLOAD = 'OfferSigningPagesDownload',
  OFFER_UPDATE_GENERAL_ANY = 'OfferUpdateGeneralAny',
  OFFER_UPDATE_GENERAL_MY = 'OfferUpdateGeneralMy',
  OFFER_WRITE = 'OfferWrite',

  OFFICE_MAP_SEAT_WRITE = 'OfficeMapSeatWrite',
  OFFICE_MAP_SEAT_NUMBERS_SHOW = 'OfficeMapSeatNumbersShow',
  OFFICE_MAP_SEATS_DOWNLOAD_DATA = 'OfficeMapSeatsDataDownload',
  OFFICE_MAP_VIEW = 'OfficeMapView',
  OFFICE_ROOM_TOKEN_GENERATE = 'OfficeRoomTokenGenerate',

  OFFICE_POLICY_RECORD_READ = 'OfficePolicyRecordRead',
  OFFICE_POLICY_RECORD_WRITE = 'OfficePolicyRecordWrite',

  OUTSTAFF_MEMBERSHIP_ADMIN_WRITE = 'OutstaffMembershipAdminWrite',
  OUTSTAFF_MEMBERSHIP_WRITE = 'OutstaffMembershipWrite',

  REDIRECT_READ = 'RedirectRead',
  REDIRECT_WRITE = 'RedirectWrite',

  RESOURCE_READ = 'ResourceRead',
  RESOURCE_WRITE = 'ResourceWrite',

  ROLE_BINDING_DOWNLOAD = 'RoleBindingDownload',
  ROLE_BINDING_READ = 'RoleBindingRead',
  ROLE_BINDING_WRITE = 'RoleBindingWrite',
  ROLE_BINDING_WRITE_WITH_PROPAGATE = 'RoleBindingWriteWithPropagate',

  RTCONF_READ = 'RTConfRead',
  RTCONF_WRITE = 'RTConfWrite',

  SELF_JOB_INFO_RECORD_READ = 'SelfJobInfoRecordRead',

  SELF_TIME_OFF_REQUEST_READ = 'SelfTimeOffRequestRead',
  SELF_TIME_OFF_REQUEST_WRITE = 'SelfTimeOffRequestWrite',
  SELF_TIME_OFF_BALANCE_RECORD_READ = 'SelfBalanceRecordsRead',

  SERVICE_READ = 'ServiceRead',
  SERVICE_WRITE = 'ServiceWrite',

  SUBORDINATE_TIME_OFF_REQUEST_APPROVE = 'SubordinateTimeOffRequestApprove',
  SUBORDINATE_TIME_OFF_REQUEST_REJECT = 'SubordinateTimeOffRequestReject',

  TEAM_ADMIN_WRITE = 'TeamAdminWrite',
  TEAM_CREATE = 'TeamCreate',
  TEAM_DELETE = 'TeamDelete',
  TEAM_READ = 'TeamRead',
  TEAM_ROLE_BINDING_READ = 'TeamRoleBindingRead',
  TEAM_UPDATE = 'TeamUpdate',

  TIME_OFF_REQUEST_ENABLED = 'TimeOffRequestEnabled',
  TIME_OFF_REQUEST_READ = 'TimeOffRequestRead',
  TIME_OFF_REQUEST_WRITE = 'TimeOffRequestWrite',
  TIME_OFF_REQUEST_WRITE_ON_BEHALF = 'TimeOffRequestWriteOnBehalf',
  TIME_OFF_BALANCE_RECORD_READ = 'BalanceRecordsRead',
  TIME_OFF_BALANCE_RECORD_WRITE = 'BalanceRecordsWrite',

  USER_ASSIGN_SEAT = 'UserAssignSeat',
  USER_READ_ACTIVE = 'UserReadActive',
  USER_READ_ANY = 'UserReadAny',
  USER_WRITE = 'UserWrite',

  USER_PRETEND_TO_BE_NORMAL = 'UserPretendToBeNormal',

  OUTSTAFF_USER_READ = 'OutstaffUserRead',

  USER_EMPLOYMENT_READ = 'UserEmploymentRead',
  USER_EMPLOYMENT_WRITE = 'UserEmploymentWrite',

  USER_JOB_INFO_RECORD_READ = 'UserJobInfoRecordRead',
  USER_JOB_INFO_RECORD_WRITE = 'UserJobInfoRecordWrite',

  USER_LEGAL_INFO_DOWNLOAD = 'UserLegalInfoDownload',
  USER_LEGAL_INFO_READ = 'UserLegalInfoRead',
  USER_LEGAL_INFO_WRITE = 'UserLegalInfoWrite',

  USER_MAIN_LEGAL_ENTITY_READ = 'UserMainLegalEntityRead',

  USER_PRIVATE_INFO_READ = 'UserPrivateInfoRead',
  USER_PRIVATE_INFO_WRITE = 'UserPrivateInfoWrite',

  USER_INTERVIEW_LINK_READ = 'UserInterviewLinkRead',

  USER_ROLE_READ = 'UserRoleRead',
  USER_ROLE_WRITE = 'UserRoleWrite',

  USERS_DATA_DOWNLOAD = 'UsersDataDownload',

  USERS_PAGE_VIEW = 'UsersPageView',
}
