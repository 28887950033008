import {SalaryReadAccess} from 'domain/salaryRecord/model/access';
import {UserFull} from 'domain/user/model';
import {useSalaryReadAccess} from './useSalaryReadAccess';

export function useSalariesAvailability(user?: UserFull): boolean {
  const salaryAccess = useSalaryReadAccess(user);

  const canSeeOwnSalaryRecords = salaryAccess === SalaryReadAccess.MY;
  const canSeeSalaryRecords =
    salaryAccess &&
    [SalaryReadAccess.ANY_EXTENDED, SalaryReadAccess.ANY, SalaryReadAccess.SUBORDINATE].includes(salaryAccess);

  return canSeeSalaryRecords || canSeeOwnSalaryRecords;
}
