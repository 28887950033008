import {getExtensionsByMimeType} from '@joomcode/deprecated-utils/file/mime';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {FileSizeUnit} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {Measure} from '@joomcode/deprecated-utils/measure/types';
import {usePrevious} from '@joomcode/deprecated-utils/react/usePrevious';
import {Button} from '@joomcode/joom-ui/Button';
import {FileUpload} from 'components/widgets/FileUpload';
import {FileMeta} from 'models/system/fileMeta';
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  acceptedMimeTypes?: string[];
  attachButtonLabel?: string;
  attachMoreButtonLabel?: string;
  disabled?: boolean;
  initialFileMetas?: FileMeta[];
  maxFileSize?: Measure<FileSizeUnit>;
  onBlur?: () => void;
  onChange: (fileMetas: FileMeta[]) => void;
  onFocus?: () => void;
};

type Attachment = {
  file?: File;
  fileMeta?: FileMeta;
};

const emptyValue: FileMeta[] = [];

export function MultipleFilesUpload({
  acceptedMimeTypes,
  attachButtonLabel,
  attachMoreButtonLabel,
  disabled,
  initialFileMetas = emptyValue,
  maxFileSize,
  onBlur,
  onChange,
  onFocus,
}: Props) {
  const intl = useIntl();
  const [attachments, setAttachments] = useState<Attachment[]>(
    initialFileMetas.map((fileMeta) => ({
      fileMeta,
    })),
  );
  const previousAttachments = usePrevious(attachments);

  useEffect(() => {
    if (attachments !== previousAttachments) {
      onChange(attachments.map((attachment) => attachment.fileMeta).filter(isNotNullish));
    }
  }, [attachments, onChange]);

  const onFilesAttach = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {files} = event.target;
      if (!files?.length) {
        return;
      }
      setAttachments([
        ...attachments,
        ...Array.from(files).map(
          (file): Attachment => ({
            file,
          }),
        ),
      ]);
    },
    [attachments, setAttachments],
  );

  const removeAttachment = (index: number) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  const updateAttachment = (index: number, fileMeta: FileMeta) => {
    const newAttachments = [...attachments];
    newAttachments[index] = {
      ...attachments[index],
      fileMeta,
    };
    setAttachments(newAttachments);
  };

  const acceptedExtensions = acceptedMimeTypes?.map((type) => getExtensionsByMimeType(type)).flat();
  const buttonLabel = attachButtonLabel ?? intl.formatMessage(messages.attachFilesButton);
  const moreButtonLabel =
    attachMoreButtonLabel ?? attachButtonLabel ?? intl.formatMessage(messages.attachMoreFilesButton);

  return (
    <>
      {attachments.map((attachment, index) => (
        <FileUpload
          disabled={disabled}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          acceptedMimeTypes={acceptedMimeTypes}
          file={attachment.file}
          fileMeta={attachment.fileMeta}
          maxFileSize={maxFileSize}
          onUploadComplete={(fileMeta) => updateAttachment(index, fileMeta)}
          onRemove={() => removeAttachment(index)}
        />
      ))}
      <div className={styles.buttonWrap}>
        <Button intent='primary' kind='secondary' size='l' disabled={disabled}>
          {attachments.length === 0 ? buttonLabel : moreButtonLabel}
          <input
            className={styles.nativeInput}
            type='file'
            onChange={onFilesAttach}
            onFocus={onFocus}
            onBlur={onBlur}
            accept={acceptedMimeTypes?.join(',')}
            multiple
            tabIndex={-1}
          />
        </Button>
      </div>
      {acceptedExtensions && (
        <div className={styles.uploadHint}>
          {intl.formatMessage(messages.fileTypesHint, {
            types: intl.formatList(acceptedExtensions, {type: 'conjunction', style: 'narrow'}),
          })}
        </div>
      )}
    </>
  );
}
