import {StyledLink} from 'components/ui/StyledLink';
import {RoleInTeam} from 'domain/role/model/roleInTeam';
import {User} from 'domain/user/model';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {teamsUrls} from 'routes/teams/urls';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  owners: User[];
  owningMemberships: RoleInTeam[];
};

export function JdmOwnersList({owners, owningMemberships}: Props) {
  const intl = useIntl();

  return (
    <>
      {intl.formatList(
        [
          ...owners.map((user) => <UserLink key={user.id} colored user={user} withAvatar={false} />),
          ...owningMemberships.map(({role, teamWithAncestors: {team}}) => (
            <span className={styles.roleInTeam} key={`${team.id}_${role.id}`}>
              {intl.formatMessage(messages.roleInTeam, {
                role: role.name,
                team: (
                  <StyledLink to={teamsUrls.team({id: team.id})} colored>
                    {team.name}
                  </StyledLink>
                ),
              })}
            </span>
          )),
        ],
        {type: 'conjunction', style: 'narrow'},
      )}
    </>
  );
}
