import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Candidate information',
    description: '[title] Offer form section title',
  },
});

export const labels = defineMessages({
  firstName: {
    defaultMessage: 'First name',
    description: 'Offer form field label',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: 'Offer form field label',
  },
  phone: {
    defaultMessage: 'Phone number',
    description: 'Offer form field label',
  },
});
