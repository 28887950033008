import {identity} from '@joomcode/deprecated-utils/function';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {composeValidators, getFieldValidator, validateFilled, validateJson} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {StyledLink} from 'components/ui/StyledLink';
import {Permission} from 'domain/permission/model';
import {RtconfItem} from 'domain/rtconf/model';
import {rtconfValueErrorMessages} from 'domain/rtconf/model/messages';
import {deleteRtconfItemFx, updateRtconfItemFx} from 'domain/rtconf/stores/main';
import React, {useCallback} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {rtconfUrls} from 'routes/rtconf/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {messages} from './messages';
import styles from './styles.css';

const renderKey = (key: string, setAsLink: boolean) => {
  if (!setAsLink) {
    return <>{key}</>;
  }

  return (
    <StyledLink colored to={rtconfUrls.item({itemKey: key})}>
      {key}
    </StyledLink>
  );
};

type Props = {
  rtconf: RtconfItem;
  setKeyAsLink?: boolean;
};

export const RtconfForm = ({rtconf, setKeyAsLink = false}: Props) => {
  const intl = useIntl();
  const history = useHistory();
  const acl = useAcl();
  const valueValidator = composeValidators(validateFilled, validateJson);

  const deleteItemTask = useAsyncTask(
    () =>
      deleteRtconfItemFx({key: rtconf.key})
        .then(
          () => history.location.pathname === rtconfUrls.item({itemKey: rtconf.key}) && history.push(rtconfUrls.root()),
        )
        .catch(toaster.interceptThenThrowError),
    [rtconf, history],
  );

  const updateItemTask = useAsyncTask(
    ({value}: RtconfItem) =>
      updateRtconfItemFx({key: rtconf.key, value})
        .catch(toaster.interceptThenThrowError)
        .then(() => undefined),
    [rtconf],
  );

  const onDeleteClick = useCallback(
    () =>
      confirm(
        {
          title: intl.formatMessage(messages.confirmDeleteTitle),
          text: intl.formatMessage(messages.confirmDeleteText),
          confirmationText: intl.formatMessage(messages.deleteButton),
          onConfirm: deleteItemTask.perform,
        },
        intl,
      ),
    [deleteItemTask, intl],
  );

  const onSubmit = useCallback(
    (item: RtconfItem) =>
      confirm(
        {
          title: intl.formatMessage(messages.confirmUpdateTitle),
          text: intl.formatMessage(messages.confirmUpdateText),
          confirmationText: intl.formatMessage(messages.updateButton),
          onConfirm: () => updateItemTask.perform(item),
        },
        intl,
      ),
    [deleteItemTask, intl],
  );

  const canChange = acl.hasPermission(Permission.RTCONF_WRITE);
  return (
    <Form<RtconfItem> onSubmit={onSubmit}>
      {({handleSubmit, submitting, valid}) => (
        <form onSubmit={handleSubmit}>
          <Field<string>
            name='value'
            parse={identity}
            initialValue={rtconf.value}
            validate={getFieldValidator(valueValidator)}
          >
            {({input, meta}) => (
              <>
                <FormControl
                  disabled={submitting}
                  error={getFieldErrorText(meta, {intl, extraMessages: rtconfValueErrorMessages})}
                  label={renderKey(rtconf.key, setKeyAsLink)}
                  reserveSpaceForError={false}
                >
                  {(formControlProps) => (
                    <Textarea readOnly={!canChange} {...formControlProps} {...input} disabled={submitting} />
                  )}
                </FormControl>

                {canChange && (
                  <div className={styles.buttonWrapper}>
                    <div className={styles.button}>
                      <Button
                        size='l'
                        kind='primary'
                        intent='primary'
                        type='submit'
                        disabled={!valid || input.value === rtconf.value}
                        loading={submitting}
                      >
                        {intl.formatMessage(messages.updateButton)}
                      </Button>
                    </div>
                    <div className={styles.button}>
                      <Button
                        size='l'
                        kind='primary'
                        intent='negative'
                        loading={deleteItemTask.isPerforming}
                        onClick={onDeleteClick}
                      >
                        {intl.formatMessage(messages.deleteButton)}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Field>
        </form>
      )}
    </Form>
  );
};
