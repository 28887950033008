import {defineMessages} from 'react-intl';
import {ResourceStatus} from '.';

export const resourceStatuses = defineMessages<ResourceStatus>({
  [ResourceStatus.APPROVED]: {
    defaultMessage: 'Approved',
    description: 'Resource status',
  },
  [ResourceStatus.ARCHIVED]: {
    defaultMessage: 'Archived',
    description: 'Resource status',
  },
  [ResourceStatus.CANCELED]: {
    defaultMessage: 'Canceled',
    description: 'Resource status',
  },
  [ResourceStatus.PENDING]: {
    defaultMessage: 'Pending',
    description: 'Resource status',
  },
  [ResourceStatus.REJECTED]: {
    defaultMessage: 'Rejected',
    description: 'Resource status',
  },
});
