import React, {useMemo} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {LabelRenderProps} from '../../types';
import {TreeMultiSelectFilterOptions} from './types';
import {listOptionsUtils} from '../listOptions';

const messages = defineMessages({
  label: {
    id: 'datafilter-tree-multiselect-label-many-values',
    description: 'DataFilter multiple values label, e.g. "Color: red, blue, yellow"',
    defaultMessage: '{filterName}: {filterValue}',
  },
});

export function TreeMultiSelectLabel<T extends string>({
  name,
  options,
  value = [],
  ValueWrapper,
}: LabelRenderProps<T[], TreeMultiSelectFilterOptions>) {
  const intl = useIntl();
  const valueLabelMap = useMemo(() => listOptionsUtils.getLabelByValueMap(options.options), [options]);
  const valueLabels = useMemo(() => value.map((item) => valueLabelMap[item]), [value, valueLabelMap]);

  return (
    <>
      {intl.formatMessage(messages.label, {
        filterName: name,
        filterValue: (
          <ValueWrapper title={intl.formatList(valueLabels, {type: 'conjunction', style: 'narrow'})}>
            {intl.formatList(valueLabels, {type: 'conjunction', style: 'narrow'})}
          </ValueWrapper>
        ),
      })}
    </>
  );
}
