import {ReactComponent as LockIcon} from '@joomcode/joom-ui/icons/core/lockSmall.svg';
import {HintIcon} from 'components/ui/HintIcon';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function HrOnlyHint() {
  const intl = useIntl();
  return <HintIcon iconComponent={LockIcon}>{intl.formatMessage(messages.hint)}</HintIcon>;
}
