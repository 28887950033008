import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';

export const searchTeams = (teams: Team[], searchQuery: string): Set<Team> => {
  const teamById = arrayToObject(teams, ({id}) => id);
  const resultTeams: Set<Team> = new Set();
  const addTeamWithAllAncestors = (id: TeamId) => {
    const team = teamById[id];
    if (!team) {
      return; // Safety net for the case of broken parentTeamId in team
    }
    resultTeams.add(team);
    if (team.parentTeamId) {
      addTeamWithAllAncestors(team.parentTeamId);
    }
  };
  for (const team of teams) {
    if (!resultTeams.has(team) && team.name.toLowerCase().includes(searchQuery)) {
      addTeamWithAllAncestors(team.id);
    }
  }
  return resultTeams;
};
