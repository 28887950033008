import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {ContextPopup} from '@joomcode/joom-ui/ContextPopup';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {TimeOffRequestsDownloadDropdown} from 'domain/timeOff/request/widgets/DownloadDropdown';
import {withVpn} from 'hocs/withVpn';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export const TimeOffRequestsDownloadButton = withVpn(() => {
  const intl = useIntl();
  const dropdown = usePopupState();

  return (
    <ContextPopup
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      isOpen={dropdown.isOpen}
      onClose={dropdown.close}
      closeOnInsideClick={false}
      content={<TimeOffRequestsDownloadDropdown onClose={dropdown.close} />}
      arrow={false}
      placement='bottom'
      distance={8}
    >
      <Button kind='text' intent='neutral' size='m' iconLeft={<DownloadIcon />} onClick={dropdown.open}>
        {intl.formatMessage(messages.buttonDownload)}
      </Button>
    </ContextPopup>
  );
});
