import {
  boolean,
  ExtractSchemaType,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {JobContractStatus} from 'domain/jobContract/model/status';
import {ContractType} from 'domain/jobContract/model/type';
import {TimeOffPolicy} from 'domain/timeOff/policy/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const jobContractRecordIdSchema = idSchema;

export const jobContractRecordSchema = object({
  active: optionalWithDefault(false, boolean()),
  comment: optional(string()),
  contractType: optional(oneOfEnum(ContractType)),
  countryOfTimeOffPolicy: optional(oneOfEnum(TimeOffPolicy)),
  effectiveDate: formattedDateSchema,
  id: jobContractRecordIdSchema,
  onHoldReason: optional(string()),
  positionTitle: optional(string()),
  status: oneOfEnum(JobContractStatus),
});

export type JobContractRecordId = ExtractSchemaType<typeof jobContractRecordIdSchema>;
export type JobContractRecord = ExtractSchemaType<typeof jobContractRecordSchema>;
