import {DataState} from '@joomcode/deprecated-utils/dataState';
import {bindValidatorOptions, formatError, validateFile} from '@joomcode/joom-form';
import {salaryRangesUploadApi} from 'domain/salaryRange/api/upload';
import {SalaryRangesUploadResult} from 'domain/salaryRange/model/upload';
import {useFileUpload} from 'hooks/useFileUpload';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {getErrorFromImportResult} from './getErrorFromImportResult';

type Props = {
  acceptedMimeTypes: string[];
  uploadResult: SalaryRangesUploadResult | undefined;
  onUpload: (result: SalaryRangesUploadResult) => void;
};

type ReturnShape = {
  error?: string;
  dataState: DataState;
  onChange: (files: File[]) => void;
};

export function useSalaryRangesUpload({acceptedMimeTypes, uploadResult, onUpload}: Props): ReturnShape {
  const intl = useIntl();
  const [error, setError] = useState<string | undefined>(undefined);
  const onError = useCallback(({message}: Error) => setError(message), [setError]);
  const validateFileMimeType = bindValidatorOptions(validateFile, {mimeTypes: acceptedMimeTypes});

  const {onAttach, dataState} = useFileUpload<void, SalaryRangesUploadResult>({
    upload: salaryRangesUploadApi.create,
    params: undefined,
    onComplete: onUpload,
    onError,
  });

  useEffect(() => {
    setError(getErrorFromImportResult(uploadResult, intl));
  }, [uploadResult, setError, intl]);

  const onChange = useCallback(
    async (files: File[]) => {
      const file = files[0];
      if (!file) {
        return;
      }

      const error = await validateFileMimeType(file);
      if (error) {
        setError(formatError(error, intl));
        return;
      }

      setError(undefined);
      onAttach(file);
    },
    [onAttach, setError, intl],
  );

  return {
    error,
    dataState,
    onChange,
  };
}
