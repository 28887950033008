import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataTable} from '@joomcode/joom-ui/DataTable';
import {FormattedPrice} from '@joomcode/joom-ui/FormattedPrice';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {CrBadge} from 'domain/compaRatio/widgets/Badge';
import {CrValue} from 'domain/compaRatio/widgets/Value';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {DefaultErrorCr} from 'domain/compensationReviewRequest/widgets/DefaultErrorCr';
import {CompensationReviewRequestLayout} from 'domain/compensationReviewRequest/widgets/Layout';
import {NewValue} from 'domain/compensationReviewRequest/widgets/NewValue';
import {OldValue} from 'domain/compensationReviewRequest/widgets/OldValue';
import {SalaryDescription} from 'domain/compensationReviewRequest/widgets/SalaryDescription';
import {SalaryHeader} from 'domain/compensationReviewRequest/widgets/SalaryHeader';
import {SalaryRange} from 'domain/compensationReviewRequest/widgets/SalaryRange';
import {SalaryRangeHeader} from 'domain/compensationReviewRequest/widgets/SalaryRangeHeader';
import {getSalariesFromContractRecords} from 'domain/contract/utils/getSalariesFromContractRecord';
import {Frequency} from 'domain/frequency/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {convertToAnnual} from 'domain/salaryRange/utils/convertToAnnual';
import React, {useMemo} from 'react';
import {useAcl} from 'services/acl';
import styles from './styles.css';

type Props = {
  newCr: CompensationReviewRequest['newCr'];
  newSalaryRange: CompensationReviewRequest['newSalaryRange'];
  newUserInfo: CompensationReviewRequest['newUserInfo'];
  oldCr: CompensationReviewRequest['oldCr'];
  oldUserInfo: CompensationReviewRequest['oldUserInfo'];
};

export function CompensationReviewRequestSectionSalary({
  newCr,
  newSalaryRange,
  newUserInfo,
  oldCr,
  oldUserInfo,
}: Props) {
  const acl = useAcl();
  const canCalculateCr = acl.hasSecurePermission(SecurePermission.SALARY_RANGE_CALCULATE_CR);
  const newSalaries = useMemo(() => getSalariesFromContractRecords(newUserInfo.contracts), [newUserInfo.contracts]);
  const oldSalariesById = useMemo(
    () => arrayToObject(getSalariesFromContractRecords(oldUserInfo.contracts) ?? [], ({id}) => id),
    [oldUserInfo.contracts],
  );
  const isOnlySalary = newSalaries?.length === 1;
  const salaryRange =
    isOnlySalary && newSalaryRange
      ? newSalaries[0].frequency === Frequency.PER_YEAR
        ? convertToAnnual(newSalaryRange)
        : newSalaryRange
      : undefined;
  const isSalaryRangeNew =
    oldUserInfo.general.levelCode !== newUserInfo.general.levelCode ||
    oldUserInfo.general.userFunction !== newUserInfo.general.userFunction;

  if (!newSalaries || newSalaries.length === 0) {
    return null;
  }

  return (
    <CompensationReviewRequestLayout>
      {newSalaries.map((salary) => {
        const oldSalary = oldSalariesById[salary.id];
        const salaryChanged = oldSalary && oldSalary.amount !== salary.amount;
        const crChanged = oldCr?.value !== newCr?.value;

        return (
          <Grid key={salary.id}>
            <Grid.Item xl={6} xs={12}>
              <SalaryHeader salary={salary} />
              <Panel.Content withPadding>
                <div className={styles.salary}>
                  <div className={styles.amount}>
                    {salaryChanged ? (
                      <NewValue value={<FormattedPrice value={salary} />} />
                    ) : (
                      <FormattedPrice value={salary} />
                    )}
                  </div>
                  {canCalculateCr && (
                    <div className={styles.cr}>{isOnlySalary ? <CrBadge cr={newCr} /> : <DefaultErrorCr />}</div>
                  )}
                </div>
                {(salaryChanged || crChanged) && (
                  <div className={styles.salary}>
                    <div className={styles.amount}>
                      {salaryChanged && (
                        <OldValue value={<FormattedPrice value={oldSalary} />} className={styles.oldAmount} />
                      )}
                    </div>
                    <div className={styles.cr}>
                      {isOnlySalary && crChanged ? (
                        <OldValue value={<CrValue cr={oldCr} withLabel coloredIcon />} className={styles.oldCr} />
                      ) : null}
                    </div>
                  </div>
                )}
                <SalaryDescription salary={salary} />
              </Panel.Content>
            </Grid.Item>
            <Grid.Item xl={6} xs={12}>
              <SalaryRangeHeader frequency={salaryRange && salary.frequency} isNew={salaryRange && isSalaryRangeNew} />
              <div className={styles.salaryRange}>
                {salaryRange ? <SalaryRange salaryRange={salaryRange} /> : <DataTable.CellEmpty />}
              </div>
            </Grid.Item>
          </Grid>
        );
      })}
    </CompensationReviewRequestLayout>
  );
}
