import {ExtractSchemaType, string, stringPattern} from '@joomcode/deprecated-utils/jsonValidation';

export const formattedDateSchema = string().where((value) => !Number.isNaN(Date.parse(value)), 'incorrect date format');

export const formattedMonthAndDaySchema = stringPattern(/^\d{2}-\d{2}$/).where(
  (value) => !Number.isNaN(Date.parse(`0000-${value}`)),
  'incorrect month-and-day format',
);

export type FormattedDate = ExtractSchemaType<typeof formattedDateSchema>;
export type FormattedDayAndMonth = ExtractSchemaType<typeof formattedMonthAndDaySchema>;

export const dateToString = (date: Date | null): string => (date ? date.toISOString().slice(0, 10) : '');
export const stringToDate = (dateString: string): Date | null =>
  formattedDateSchema.run(dateString).ok ? new Date(dateString) : null;

export const datesToStrings = (dates: (Date | null)[]): string[] => dates.map(dateToString);
export const stringsToDates = (dateStrings: string[]): (Date | null)[] => dateStrings.map(stringToDate);

// A hacky wrapper for mistyped dates (string, instead of actual Date | null)
// Fixes final-form value typing, see https://github.com/final-form/react-final-form/issues/929
// Can be removed after switching to final-form v6.5.4 +
export const fixDateType = (value: string | Date | null): Date | null => (value ? new Date(value) : null);
