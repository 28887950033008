import {ReactComponent as CoinIcon} from '@joomcode/joom-ui/icons/core/coinBold.svg';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {User} from 'domain/user/model';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './styles.css';

type Props = {
  referrers: User[];
};

export function ReferrersHint({referrers}: Props) {
  const intl = useIntl();

  return (
    <span className={styles.root}>
      <TooltipFaq
        content={
          <>
            {intl.formatList(
              referrers.map((referrer) => <UserLink key={referrer.id} user={referrer} withAvatar={false} colored />),
              {type: 'conjunction', style: 'narrow'},
            )}
          </>
        }
      >
        <CoinIcon />
      </TooltipFaq>
    </span>
  );
}
