import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  addButton: {
    defaultMessage: 'Add role',
    description: 'Field in role form control: button label',
  },
  removeButton: {
    defaultMessage: 'Remove role',
    description: 'Field in role form control: button label',
  },
  inTeam: {
    defaultMessage: 'in team',
    description: 'Field in role form control text. Usage: [role select] in team [team select]',
  },
});
