import {DataState} from '@joomcode/deprecated-utils/dataState';
import {StockOptionAgreementId} from 'domain/stockOption/agreement/model';
import {StockOptionAgreementWithSummary} from 'domain/stockOption/agreement/model/withSummary';
import {
  createStockOptionAgreementFx,
  deleteStockOptionAgreementFx,
  setDocumentsStatusFx,
} from 'domain/stockOption/agreement/stores/main';
import {
  createStockOptionAgreementFreezeFx,
  deleteStockOptionAgreementFreezeFx,
  updateStockOptionAgreementFreezeFx,
} from 'domain/stockOption/agreementFreeze/stores/main';
import {createStockOptionBuyoutFx} from 'domain/stockOption/buyout/stores/main';
import {UserStockOptionData} from 'domain/stockOption/holder/model/user';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {getMyStockOptionDataFx, getSubordinateStockOptionDataFx, getUserStockOptionDataByIdFx} from '.';

type State = {
  byUserId: Record<UserId, UserStockOptionData>;
  dataStateByUserId: Record<UserId, DataState>;
};

type AgreementFreezeUpdatePayload = {
  params: {
    userId: UserId;
    agreementId: StockOptionAgreementId;
  };
  result: StockOptionAgreementWithSummary;
};

const updateAgreementFreezes = (
  state: State,
  {params: {userId, agreementId}, result}: AgreementFreezeUpdatePayload,
) => ({
  ...state,
  byUserId: {
    ...state.byUserId,
    [userId]: {
      ...state.byUserId[userId],
      agreements: state.byUserId[userId].agreements.map((agreement) =>
        agreement.id === agreementId ? result.agreement : agreement,
      ),
      summary: result.summary,
    },
  },
});

export const $userStockOptionData = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on([getMyStockOptionDataFx, getSubordinateStockOptionDataFx, getUserStockOptionDataByIdFx], (state, userId) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on(
    [getMyStockOptionDataFx.fail, getSubordinateStockOptionDataFx.fail, getUserStockOptionDataByIdFx.fail],
    (state, {params: userId}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.FAILED,
        },
      };
    },
  )
  .on(
    [getMyStockOptionDataFx.done, getSubordinateStockOptionDataFx.done, getUserStockOptionDataByIdFx.done],
    (state, {params: userId, result}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: result,
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADED,
        },
      };
    },
  )
  .on(createStockOptionAgreementFx.done, (state, {params: {userId}, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: {
          ...state.byUserId[userId],
          agreements: [...state.byUserId[userId].agreements, result.agreement],
          summary: result.summary,
        },
      },
    };
  })
  .on(deleteStockOptionAgreementFx.done, (state, {params: {id, userId}, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: {
          ...state.byUserId[userId],
          agreements: state.byUserId[userId].agreements.filter((agreement) => agreement.id !== id),
          summary: result,
        },
      },
    };
  })
  .on(
    [
      createStockOptionAgreementFreezeFx.done,
      updateStockOptionAgreementFreezeFx.done,
      deleteStockOptionAgreementFreezeFx.done,
    ],
    updateAgreementFreezes,
  )
  .on(setDocumentsStatusFx.done, (state, {params: {id, userId}, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: {
          ...state.byUserId[userId],
          agreements: state.byUserId[userId].agreements.map((agreement) => (agreement.id === id ? result : agreement)),
        },
      },
    };
  })
  .on(createStockOptionBuyoutFx.done, (state, {params: {userId}, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: {
          ...state.byUserId[userId],
          buyouts: [...state.byUserId[userId].buyouts, result.buyout],
          summary: result.summary,
        },
      },
    };
  });
