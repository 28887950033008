import {TreeViewState} from './types';

export function getSubtreeIds<ItemId extends string>(rootId: ItemId, treeViewState: TreeViewState<ItemId>): ItemId[] {
  const subtreeIds: ItemId[] = [];

  function addIds(ids: ItemId[]) {
    for (const id of ids) {
      if (treeViewState.itemsById[id]) {
        subtreeIds.push(id);
        addIds(treeViewState.itemsById[id].subitemIds);
      }
    }
  }

  addIds([rootId]);
  return subtreeIds;
}
