export enum StatusIconStyle {
  CLIP = 'clip',
  CHECK_OUTLINE = 'checkOutline',
  SPINNER = 'spinner',
  ERROR = 'error',
}

export type FileObject = {
  name: string;
  size?: number;
};

export type FilePaneProps = {
  /**
   * Custom error message.
   */
  children?: React.ReactChild | null;
  disabled?: boolean;
  file: FileObject;
  hasError?: boolean;
  icon?: `${StatusIconStyle}`;
  onDownload?: () => void | Promise<void>;
  onRemove?: () => void | Promise<void>;
  /**
   * The value is between 0 and 1.
   */
  progress?: number | null;
};
