import {isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {Panel} from '@joomcode/joom-ui/Panel';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {loadAllRtconfItemsFx} from 'domain/rtconf/stores/main';
import {$rtconfItems} from 'domain/rtconf/stores/main/state';
import {RtconfForm} from 'domain/rtconf/widgets/Form';
import {useStore} from 'effector-react';
import {NotFoundPage} from 'pages/NotFound';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {pageTitles} from 'routes/rtconf/titles';
import {rtconfUrls} from 'routes/rtconf/urls';
import {toaster} from 'services/toaster';

export function RtconfItemPage() {
  const intl = useIntl();
  const {itemKey} = useParams<{itemKey: string}>();
  const rtconfItems = useStore($rtconfItems);

  useEffect(() => {
    if (!isLoadingOrLoaded(rtconfItems.dataState)) {
      loadAllRtconfItemsFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return (
    <PageStateHandler data={rtconfItems.items} state={rtconfItems.dataState}>
      {(items) => {
        const rtconfItem = items.find(({key}) => key === itemKey);
        if (rtconfItem === undefined) {
          return <NotFoundPage />;
        }

        return (
          <Page
            breadcrumbs={[
              {
                title: intl.formatMessage(pageTitles.root),
                href: rtconfUrls.root(),
              },
            ]}
            title={intl.formatMessage(pageTitles.rtconfItem, {itemKey})}
          >
            <Panel withPadding>
              <RtconfForm rtconf={rtconfItem} />
            </Panel>
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
