import cn from 'classnames';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {OfficeRoom} from 'domain/officeMap/room/model';
import React from 'react';
import {PIN_TEMPLATE_ID, RoomSvgSample} from './Sample';
import styles from './styles.css';

const PIN_CENTER_DISTANCE = 29.57; // distance between the bubble center and the rotation origin

type Props = {
  room: OfficeRoom;
  onRoomSelect: (room: OfficeRoom | null, officeAlias?: OfficeAlias) => void;
  shadowElementId: string;
  isActive: boolean;
  faded: boolean;
};

export function RoomSvgComponent({room, onRoomSelect, isActive, shadowElementId, faded}: Props) {
  const yAxisTiltAngle = ((45 + room.pinAngle) * Math.PI) / 180; // angle between the pin axis and Y axis
  const textX = PIN_CENTER_DISTANCE * Math.sin(yAxisTiltAngle);
  const textY = -PIN_CENTER_DISTANCE * Math.cos(yAxisTiltAngle);
  return (
    <>
      {isActive && (
        <g
          transform={`translate(${room.coordinates.x} ${room.coordinates.y})`}
          onClick={() => onRoomSelect(room)}
          className={cn(styles.room, {[styles.faded]: faded})}
        >
          <use
            filter={`url(#${shadowElementId})`}
            xlinkHref={`#${PIN_TEMPLATE_ID}`}
            transform={`rotate(${room.pinAngle})`}
          />
          <text x={textX} y={textY} className={styles.text}>
            {room.text}
          </text>
        </g>
      )}
    </>
  );
}

export const RoomSvg = Object.assign(RoomSvgComponent, {
  Sample: RoomSvgSample, // eslint-disable-line @typescript-eslint/naming-convention
});
