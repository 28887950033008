import {TimeOffType} from 'domain/timeOff/type/model';
import {TimeOff} from 'domain/timeOff/userTimeOff/model';
import {doesTimeOffIncludeToday} from 'domain/timeOff/userTimeOff/model/utils';
import React from 'react';
import {useIntl} from 'react-intl';
import {formatDateRange} from 'utils/date/formatDateRange';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  timeOff: TimeOff;
  longPhrase?: boolean;
};

export function TimeOffNote({timeOff, longPhrase = false}: Props) {
  const intl = useIntl();

  const isOngoing = doesTimeOffIncludeToday(timeOff);
  const formattedDates = formatDateRange(timeOff.start, timeOff.end, intl, !longPhrase);
  const isWorkingRemotely = timeOff.type === TimeOffType.REMOTE_WORK || timeOff.type === TimeOffType.SEMI_SICK_LEAVE;
  const fullText = isWorkingRemotely
    ? isOngoing
      ? intl.formatMessage(messages.remoteWorkText, {dateInterval: formattedDates})
      : intl.formatMessage(messages.futureRemoteWorkText, {dateInterval: formattedDates})
    : isOngoing
      ? intl.formatMessage(messages.outText, {dateInterval: formattedDates})
      : intl.formatMessage(messages.futureOutText, {dateInterval: formattedDates});

  return (
    <div className={styles.root}>
      <span className={styles.backing}>{fullText}</span>
    </div>
  );
}
