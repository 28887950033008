import {Team} from 'domain/team/model';
import {TeamDiff} from 'domain/team/model/diff';
import {TeamId} from 'domain/team/model/id';
import {TeamMembers} from 'domain/team/model/members';
import {
  MembershipRequestConfig,
  MembershipRevokeConfig,
  OutstaffMembershipRequestConfig,
  OutstaffMembershipRevokeConfig,
} from 'domain/team/model/members/membershipConfig';
import {createEffect} from 'effector';

export const createTeamFx = createEffect<{parentTeamId: TeamId; diff: TeamDiff}, Team>('create new team');
export const deleteTeamFx = createEffect<{id: TeamId}, Team>('delete team');
export const getAllTeamsFx = createEffect<void, Team[]>('load all teams');
export const requestMembershipFx = createEffect<MembershipRequestConfig, TeamMembers>('request team membership');
export const requestOutstaffMembershipFx = createEffect<OutstaffMembershipRequestConfig, TeamMembers>(
  'request team outstaff membership',
);
export const revokeMembershipFx = createEffect<MembershipRevokeConfig, TeamMembers>('revoke team membership');
export const revokeOutstaffMembershipFx = createEffect<OutstaffMembershipRevokeConfig, TeamMembers>(
  'revoke team outstaff membership',
);
export const updateTeamFx = createEffect<{id: TeamId; diff: TeamDiff}, Team>('update team');
export const changeParentFx = createEffect<{id: TeamId; parentTeamId: TeamId}, Team>("change team's parent");
