import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {ResourceLink} from 'domain/resource/widgets/Link';
import {RoleBadge} from 'domain/role/widgets/Badge';
import {RoleBinding} from 'domain/roleBinding/model';
import {RoleBindingActions} from 'domain/roleBinding/widgets/Actions';
import {RoleBindingPropagationIcon} from 'domain/roleBinding/widgets/PropagationIcon';
import {RoleBindingStatusBadge} from 'domain/roleBinding/widgets/StatusBadge';
import {$self} from 'domain/self/stores/main/state';
import {ServiceLink} from 'domain/service/widgets/Link';
import {ColumnId} from 'domain/team/model/roleBindingsTableColumnId';
import {TeamPath} from 'domain/team/widgets/Path';
import {useStore} from 'effector-react';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {columnNames, messages} from './messages';

type Props = {
  data: RoleBinding[];
  columnWidths: Partial<Record<ColumnId, number>>;
  storageName: string;
};

const getRowKey = ({id}: RoleBinding) => id;

export function TeamRoleBindingsTable({data, columnWidths, storageName}: Props) {
  const intl = useIntl();
  const {userId: selfUserId} = useStore($self);
  const columns = useMemo(() => {
    const result: Column<RoleBinding>[] = [
      {
        defaultWidth: columnWidths[ColumnId.SERVICE],
        id: ColumnId.SERVICE,
        name: intl.formatMessage(columnNames[ColumnId.SERVICE]),
        render: ({resource}) => <ServiceLink service={resource.service} />,
      },
      {
        defaultWidth: columnWidths[ColumnId.RESOURCE],
        id: ColumnId.RESOURCE,
        name: intl.formatMessage(columnNames[ColumnId.RESOURCE]),
        render: ({resource}) => <ResourceLink resource={resource} />,
      },
      {
        defaultWidth: columnWidths[ColumnId.ROLE],
        id: ColumnId.ROLE,
        name: intl.formatMessage(columnNames[ColumnId.ROLE]),
        render: ({role}) => (role ? <RoleBadge role={role} /> : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: columnWidths[ColumnId.TEAM],
        id: ColumnId.TEAM,
        name: intl.formatMessage(columnNames[ColumnId.TEAM]),
        render: ({teamWithAncestors}) => <TeamPath {...teamWithAncestors} />,
      },
      {
        defaultWidth: columnWidths[ColumnId.PROPAGATES],
        align: 'center',
        id: ColumnId.PROPAGATES,
        name: intl.formatMessage(columnNames[ColumnId.PROPAGATES]),
        render: ({role, teamWithAncestors, propagate}) =>
          propagate ? (
            <RoleBindingPropagationIcon role={role} team={teamWithAncestors.team} />
          ) : (
            <DataTable.CellEmpty />
          ),
      },
      {
        defaultWidth: columnWidths[ColumnId.STATUS],
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({status}) => <RoleBindingStatusBadge status={status} />,
      },
      {
        defaultWidth: columnWidths[ColumnId.ACTIONS],
        id: ColumnId.ACTIONS,
        name: intl.formatMessage(columnNames[ColumnId.ACTIONS]),
        render: (roleBinding) => <RoleBindingActions roleBinding={roleBinding} selfUserId={selfUserId} />,
      },
    ];

    return result.filter(({defaultWidth}) => defaultWidth);
  }, [intl, columnWidths, selfUserId]);

  return (
    <DataTable
      columns={columns}
      dataState={DataState.LOADED}
      data={data}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowKey={getRowKey}
      storageName={storageName}
    />
  );
}
