import {createLocator, type Locator, type Mark} from 'create-locator';
import React from 'react';
import styles from './index.css';

export type TooltipFaqHeadingLocator = Locator<void>;

type Props = {
  children: React.ReactNode;
} & Partial<Mark<TooltipFaqHeadingLocator>>;

export function Heading({children, ...rest}: Props) {
  const locator = createLocator(rest);

  return (
    <p className={styles.heading} {...locator()}>
      {children}
    </p>
  );
}
