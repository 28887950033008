import {identity} from '@joomcode/deprecated-utils/function';
import {bindValidatorOptions} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {HintIcon} from 'components/ui/HintIcon';
import {MarkdownCheatsheetLink} from 'components/ui/MarkdownCheatsheetLink';
import {roleInTeamToDiff} from 'domain/role/utils/roleInTeamToDiff';
import {RolesInTeamsFormControl} from 'domain/role/widgets/RolesInTeamsFormControl';
import {Service} from 'domain/service/model';
import {ServiceDiff} from 'domain/service/model/diff';
import {UsersFormControl} from 'domain/user/widgets/FormControlMultiple';
import React, {useMemo} from 'react';
import {Field, FormRenderProps, useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {errorMessages, hints, labels} from './messages';
import {validateOwnersFilled} from './validate';

type Props = {
  formId: string;
  onSubmit: FormRenderProps<ServiceDiff>['handleSubmit'];
  serviceToUpdate?: Service;
  submitting: boolean;
};

export const normalizeValues = (diff: ServiceDiff): ServiceDiff => ({
  ...diff,
  // filter out empty rows:
  owningMemberships: diff.owningMemberships?.filter(({roleId, teamId}) => roleId || teamId) ?? [],
});

export function ServiceForm({formId, onSubmit, serviceToUpdate, submitting}: Props) {
  const intl = useIntl();
  const initialOwnerIds = useMemo(() => serviceToUpdate?.owners.map(({id}) => id), [serviceToUpdate]);
  const initialOwningMemberships = useMemo(
    () => serviceToUpdate?.owningMemberships.map(roleInTeamToDiff),
    [serviceToUpdate],
  );
  const {getState} = useForm<ServiceDiff>();
  const validateOwners = bindValidatorOptions(validateOwnersFilled, {getFormState: getState});

  return (
    <form onSubmit={onSubmit} id={formId}>
      <Field<string> name='name' initialValue={serviceToUpdate?.name}>
        {({input}) => (
          <FormControl
            disabled={submitting}
            label={intl.formatMessage(labels.name)}
            hint={intl.formatMessage(hints.useLatin)}
            required
          >
            {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
          </FormControl>
        )}
      </Field>
      <Field<string> name='description' parse={identity} initialValue={serviceToUpdate?.description}>
        {({input}) => (
          <FormControl
            disabled={submitting}
            label={intl.formatMessage(labels.description)}
            hint={intl.formatMessage(hints.description, {
              link: (text) => <MarkdownCheatsheetLink>{text}</MarkdownCheatsheetLink>,
            })}
          >
            {(formControlProps) => <Textarea {...formControlProps} {...input} disabled={submitting} />}
          </FormControl>
        )}
      </Field>
      <UsersFormControl
        disabled={submitting}
        errorMessages={errorMessages}
        initialValue={initialOwnerIds}
        label={
          <>
            {intl.formatMessage(labels.owners)}
            <HintIcon>{intl.formatMessage(hints.owners)}</HintIcon>
          </>
        }
        name='ownerIds'
        validate={validateOwners}
      />
      <RolesInTeamsFormControl
        disabled={submitting}
        errorMessages={errorMessages}
        initialValue={initialOwningMemberships}
        label={
          <>
            {intl.formatMessage(labels.owningMemberships)}
            <HintIcon>{intl.formatMessage(hints.owningMemberships)}</HintIcon>
          </>
        }
        name='owningMemberships'
        validate={validateOwners}
      />
    </form>
  );
}
