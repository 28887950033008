import {defineMessages} from 'react-intl';

export const errorMessages = defineMessages({
  unknownError: {
    defaultMessage: 'File import failed.',
    description: 'Salary ranges upload error',
  },
  invalidRows: {
    defaultMessage: 'This file has some invalid rows.',
    description: 'Salary ranges upload error',
  },
  noRows: {
    defaultMessage: 'This file has no rows.',
    description: 'Salary ranges upload error',
  },
  alreadyApplied: {
    defaultMessage: 'This import has already been applied.',
    description: 'Salary ranges upload error',
  },
});
