import {UserId} from 'domain/user/model';
import {UserFullInfoRecord} from 'domain/userFullInfoRecord/model';
import {UserFullInfoFilters} from 'domain/userFullInfoRecord/model/filters';

export const filterRecords = (
  records: UserFullInfoRecord[],
  ancestorIdsByUserId: Record<UserId, UserId[]>,
  {
    countriesOfTimeOffPolicy,
    crMarketLevelPositions,
    divisions,
    levelCodes,
    managerIds,
    showIndirect,
    userFunctions,
    userIds,
  }: UserFullInfoFilters,
) => {
  return records.filter(({activeMarketDataRecord, cr, user}) => {
    if (
      countriesOfTimeOffPolicy &&
      (!user.countryOfTimeOffPolicy || !countriesOfTimeOffPolicy.includes(user.countryOfTimeOffPolicy))
    ) {
      return false;
    }

    if (
      crMarketLevelPositions &&
      (!cr?.marketLevelPosition || !crMarketLevelPositions.includes(cr.marketLevelPosition))
    ) {
      return false;
    }

    if (divisions && (!user.division || !divisions.includes(user.division))) {
      return false;
    }

    if (levelCodes && (!activeMarketDataRecord?.levelCode || !levelCodes.includes(activeMarketDataRecord.levelCode))) {
      return false;
    }

    if (managerIds) {
      if (!user.managerId) {
        return false;
      }

      if (showIndirect) {
        if (!managerIds.some((managerId) => (ancestorIdsByUserId[user.id] || []).includes(managerId))) {
          return false;
        }
      } else if (!managerIds.includes(user.managerId)) {
        return false;
      }
    }

    if (
      userFunctions &&
      (!activeMarketDataRecord?.userFunction || !userFunctions.includes(activeMarketDataRecord.userFunction))
    ) {
      return false;
    }

    if (userIds && !userIds.includes(user.id)) {
      return false;
    }

    return true;
  });
};
