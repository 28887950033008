const EMPTY_VALUES = ['', null, undefined, false] as const;
const emptyValuesSet = new Set(EMPTY_VALUES);

type Empty = (typeof EMPTY_VALUES)[number];

type NotEmpty<T> = T extends Empty ? never : T;

export function isNotEmpty<T>(value: T): value is NotEmpty<T> {
  return !emptyValuesSet.has(value as unknown as Empty);
}
