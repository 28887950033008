import React from 'react';
import {useIntl} from 'react-intl';
import {StyledExternalLink} from '../StyledLink';
import {messages} from './messages';

type Props = {
  children?: React.ReactNode;
};

export function MarkdownCheatsheetLink({children}: Props) {
  const intl = useIntl();
  return (
    <StyledExternalLink href='https://www.markdownguide.org/basic-syntax/'>
      {children ?? intl.formatMessage(messages.linkText)}
    </StyledExternalLink>
  );
}
