import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {IntentionedText} from '@joomcode/joom-ui/IntentionedText';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInMonths from 'date-fns/differenceInMonths';
import React from 'react';

type Props = {
  date: Date;
};

export function ExpirationDate({date}: Props) {
  const today = new Date();

  if (differenceInMonths(date, today) > 0) {
    return <HumanDate format='short' value={date} utc />;
  }
  return (
    <IntentionedText intent={differenceInCalendarDays(date, today) > 0 ? 'warning' : 'negative'}>
      <HumanDate format='short' value={date} utc />
    </IntentionedText>
  );
}
