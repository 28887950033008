import React from 'react';
import {FormattedMessage} from 'react-intl';
import {HumanDate} from '../../../HumanDate';
import {LabelRenderProps} from '../../types';
import {Options, DataRangeValue} from './types';

export function DateRangeLabel({name, value, ValueWrapper}: LabelRenderProps<DataRangeValue, Options>) {
  return (
    <FormattedMessage
      defaultMessage='{filterName} {fromDate} {toDate}'
      description='DataFilter date range label'
      id='datafilter-date-range-label'
      values={{
        filterName: name,
        fromDate: value?.from && (
          <ValueWrapper>
            <FormattedMessage
              id='datafilter-data-range-label-from'
              description='Label for start date at date range filter'
              defaultMessage='from {date}'
              values={{
                date: <HumanDate value={value.from} format='short' />,
              }}
            />
          </ValueWrapper>
        ),
        toDate: value?.to && (
          <ValueWrapper>
            <FormattedMessage
              id='datafilter-data-range-label-to'
              description='Label for end date at date range filter'
              defaultMessage='to {date}'
              values={{
                date: <HumanDate value={value.to} format='short' />,
              }}
            />
          </ValueWrapper>
        ),
      }}
    />
  );
}
