import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {UserInfo} from 'domain/compensationReviewRequest/model/userInfo';
import {getUserInfoFx} from 'domain/compensationReviewRequest/stores/userInfo';
import {$userInfo} from 'domain/compensationReviewRequest/stores/userInfo/state';
import {SecurePermission} from 'domain/permission/model/secure';
import {UserId} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: UserInfo;
  dataState: DataState;
};

export function useUserInfo(userId: UserId): ReturnShape {
  const acl = useAcl();
  const data = useStoreMap({
    store: $userInfo,
    keys: [userId],
    fn: ({byUserId}, [id]) => byUserId[id],
  });
  const dataState = useStoreMap({
    store: $userInfo,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => dataStateByUserId[id] || DataState.IDLE,
  });
  const canReadUserInfo =
    acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY) ||
    acl.hasSecurePermission(SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_SUBORDINATE);

  useEffect(() => {
    if (canReadUserInfo && !isLoadingOrLoaded(dataState)) {
      getUserInfoFx(userId).catch((error: Error) => toaster.error(error.message));
    }
  }, [canReadUserInfo, userId]);

  return {
    data,
    dataState,
  };
}
