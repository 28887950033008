import {useMultiSelect} from '@joomcode/deprecated-utils/react/useMultiSelect';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Tag} from '../../../Tag';
import {Listbox} from '../../../Listbox';
import {AutocompleteInput} from '../../AutocompleteInput';
import {Control} from '../../Control';
import {ControlRenderProps} from '../../types';
import {autocompleteMessages} from '../../messages';
import {AutocompleteMultiSelectFilterOptions} from './types';
import {createLocator} from '../../../create-locator';
import styles from './AutocompleteMultiSelectControl.css';
import {containsSearchString} from '../../utils';

type Props<T> = ControlRenderProps<T[], AutocompleteMultiSelectFilterOptions<T>>;

export function AutocompleteMultiSelectControl<T extends string>({
  name,
  value = [],
  onSubmit,
  onClose,
  options,
}: Props<T>) {
  const intl = useIntl();
  const locator = createLocator(options);
  const selection = useMultiSelect(options.options, (item) => value.includes(item.value));
  const [inputValue, setInputValue] = useState<string>('');

  const chosenOptions = useMemo(selection.getSelectedItems, [selection]);

  const filteredOptions = useMemo(() => {
    return options.options
      .filter((option) => !selection.isItemSelected(option))
      .filter(({label}) => containsSearchString(label, inputValue));
  }, [options.options, inputValue, selection]);

  const handleSubmit = () => onSubmit(selection.getSelectedItems().map((item) => item.value));

  return (
    <Control onClose={onClose} onSubmit={handleSubmit} scrollable={false} withPadding={false} {...locator.control()}>
      <div className={styles.control}>
        {chosenOptions.length > 0 && (
          <div className={styles.tags}>
            {chosenOptions.map((option) => (
              <Tag
                onRemove={() => selection.deselectItem(option)}
                key={option.value}
                {...locator.tag({id: option.value})}
              >
                {option.label}
              </Tag>
            ))}
          </div>
        )}

        {chosenOptions.length < options.options.length && (
          <>
            <AutocompleteInput
              name={name}
              onChange={setInputValue}
              placeholder={intl.formatMessage(autocompleteMessages.placeholder)}
              value={inputValue}
              {...locator.input()}
            />

            <div className={styles.list}>
              <Listbox withDefaultPadding {...locator.list()}>
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((option) => (
                    <Listbox.Option
                      onClick={() => selection.selectItem(option)}
                      key={option.value}
                      title={option.label}
                      {...locator.option({item: option.value})}
                    >
                      {options.renderOption ? options.renderOption(option) : option.label}
                    </Listbox.Option>
                  ))
                ) : (
                  <Listbox.Option isHighlighted={false} disabled>
                    {intl.formatMessage(autocompleteMessages.empty)}
                  </Listbox.Option>
                )}
              </Listbox>
            </div>
          </>
        )}
      </div>
    </Control>
  );
}
