import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  placeholderMax: {
    defaultMessage: 'Less than or equal to {max, number}',
    description: 'Number filter placeholder',
    id: 'datafilter-number-control-placeholder-max',
  },
  placeholderMin: {
    defaultMessage: 'Greater than or equal to {min, number}',
    description: 'Number filter placeholder',
    id: 'datafilter-number-control-placeholder-min',
  },
  placeholderMinMax: {
    defaultMessage: 'From {min, number} to {max, number}',
    description: 'Number filter placeholder',
    id: 'datafilter-number-control-placeholder-minmax',
  },
});
