import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {MultilineText} from '../MultilineText';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  text: string;
  maxLines?: number;
};

export function ClampedText({maxLines = 2, text}: Props) {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const calculateRef = useRef<HTMLDivElement>(null);
  const [calculated, setCalculated] = useState(false);
  const [needsToBeTruncated, setNeedsToBeTruncated] = useState(false);
  const truncated = useBooleanState(false);
  const [truncatedText, setTruncatedText] = useState(text);
  const expandPlainMessage = intl.formatMessage(messages.expandPlain);
  const expandMessage = intl.formatMessage(messages.expand, {
    button: (text) => (
      <button className={styles.button} onClick={truncated.setFalse}>
        {text}
      </button>
    ),
  });
  const collapseMessage = intl.formatMessage(messages.collapse, {
    button: (text) => (
      <button className={styles.button} onClick={truncated.setTrue}>
        {text}
      </button>
    ),
  });

  useEffect(() => {
    if (!calculated && ref.current && calculateRef.current && calculateRef.current.clientHeight > 0) {
      const oneLineHeight = calculateRef.current.clientHeight;
      const originalTextLinesCount = Math.ceil(ref.current.clientHeight / oneLineHeight);

      if (originalTextLinesCount > maxLines) {
        let start = 0;
        let end = text.length;
        let truncatedText = '';

        while (start < end) {
          const mid = Math.floor((start + end) / 2);
          const truncatedTextCandidate = text.slice(0, mid).trim();
          calculateRef.current.innerHTML = `${truncatedTextCandidate.replaceAll('\n', '<br>')}${expandPlainMessage}`;
          const linesCount = Math.ceil(calculateRef.current.clientHeight / oneLineHeight);

          if (linesCount > maxLines) {
            end = mid;
          } else {
            start = mid + 1;
            truncatedText = truncatedTextCandidate;
          }
        }

        truncated.setTrue();
        setTruncatedText(truncatedText);
      }

      setNeedsToBeTruncated(originalTextLinesCount > maxLines);
      setCalculated(true);
    }
  }, [
    ref.current,
    calculateRef.current,
    calculated,
    text,
    maxLines,
    setCalculated,
    setTruncatedText,
    setNeedsToBeTruncated,
  ]);

  return (
    <div>
      <div ref={ref}>
        {calculated && needsToBeTruncated ? (
          <>
            {truncated.value ? (
              <>
                <MultilineText text={truncatedText} />
                {expandMessage}
              </>
            ) : (
              <>
                <MultilineText text={text} />
                <span className={styles.collapse}>{collapseMessage}</span>
              </>
            )}
          </>
        ) : (
          <MultilineText text={text} />
        )}
      </div>
      {!calculated && <div ref={calculateRef}>A</div>}
    </div>
  );
}
