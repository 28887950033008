import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ListedProperty} from 'components/ui/ListedProperty';
import {StyledExternalLink, StyledLink} from 'components/ui/StyledLink';
import {HrOnlyHint} from 'components/widgets/HrOnlyHint';
import {getLanguageName} from 'domain/language/model';
import {LegalEntity} from 'domain/legalEntity/model';
import {OfficePolicy} from 'domain/officePolicyRecord/model/policy';
import {formatOfficePolicy} from 'domain/officePolicyRecord/model/policy/formatter';
import {shirtSizeLabels} from 'domain/shirtSize/model/messages';
import {formatTimeOffPolicy} from 'domain/timeOff/policy/model/formatter';
import {UserFull} from 'domain/user/model';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import {UserGithubAccount} from 'domain/user/widgets/GithubAccount';
import {formatPhone} from 'models/system/phone';
import React from 'react';
import {useIntl} from 'react-intl';
import {officeUrls} from 'routes/officeMap/urls';
import {Feature, useFeature} from 'services/features';
import {getHumanMonthAndDay} from 'utils/date/getHumanMonthAndDay';
import {labels, messages} from './messages';

type Props = {
  user: UserFull;
  legalEntity?: LegalEntity;
  privateInfo?: UserPrivateInfo;
  isSelfUser: boolean;
};

export function UserProperties({user, legalEntity, privateInfo, isSelfUser}: Props) {
  const intl = useIntl();
  const monthAndDayOfBirth = user.monthAndDayOfBirth ?? privateInfo?.monthAndDayOfBirth;
  const shirtSize = user.shirtSize ?? privateInfo?.shirtSize;
  const officePolicyFeature = useFeature(Feature.OFFICE_POLICY);
  const mainLegalEntity = legalEntity ?? privateInfo?.legalEntity;

  return (
    <>
      {user.phone && (
        <ListedProperty label={intl.formatMessage(labels.phone)}>
          {formatPhone(user.phone) || user.phone}
        </ListedProperty>
      )}
      {user.slackDisplayName && user.slackLink && (
        <ListedProperty label={intl.formatMessage(labels.slack)}>
          <StyledExternalLink href={user.slackLink} colored>
            {user.slackDisplayName}
          </StyledExternalLink>
        </ListedProperty>
      )}
      <ListedProperty label={intl.formatMessage(labels.email)}>{user.email}</ListedProperty>
      {user.seat && (
        <ListedProperty label={intl.formatMessage(labels.seat)}>
          <StyledLink colored to={officeUrls.seat({alias: user.seat.officeAlias}, user.seat)}>
            {user.seat.number || intl.formatMessage(messages.placeOnOfficeMap)}
          </StyledLink>
        </ListedProperty>
      )}
      {officePolicyFeature.isAvailable && user.officePolicy && (
        <ListedProperty label={intl.formatMessage(labels.officePolicy)}>
          {formatOfficePolicy(user.officePolicy, intl)}
        </ListedProperty>
      )}
      {user.location && user.officePolicy !== OfficePolicy.REMOTE && (
        <ListedProperty label={intl.formatMessage(labels.location)}>{user.location}</ListedProperty>
      )}
      {mainLegalEntity && (
        <ListedProperty label={intl.formatMessage(labels.legalEntity)}>
          {mainLegalEntity.title}
          <HrOnlyHint />
        </ListedProperty>
      )}
      {user.countryOfTimeOffPolicy && (
        <ListedProperty label={intl.formatMessage(labels.countryOfTimeOffPolicy)}>
          {formatTimeOffPolicy(user.countryOfTimeOffPolicy, intl)}
        </ListedProperty>
      )}
      {user.hireDate && (
        <ListedProperty label={intl.formatMessage(labels.hireDate)}>
          <HumanDate format='long' value={user.hireDate} utc />
        </ListedProperty>
      )}
      {monthAndDayOfBirth && (
        <ListedProperty label={intl.formatMessage(labels.monthAndDayOfBirth)}>
          {getHumanMonthAndDay(monthAndDayOfBirth, intl)}
          {privateInfo && !privateInfo.isMonthAndDayOfBirthPublic && <HrOnlyHint />}
        </ListedProperty>
      )}
      {shirtSize && (
        <ListedProperty label={intl.formatMessage(labels.shirtSize)}>
          {intl.formatMessage(shirtSizeLabels[shirtSize])}
          {privateInfo && !privateInfo.isShirtSizePublic && <HrOnlyHint />}
        </ListedProperty>
      )}
      {user.primaryLanguage && (
        <ListedProperty label={intl.formatMessage(labels.primaryLanguage)}>
          {getLanguageName(user.primaryLanguage, intl)}
        </ListedProperty>
      )}
      {user.otherLanguages && (
        <ListedProperty label={intl.formatMessage(labels.otherLanguages)}>
          {intl.formatList(
            user.otherLanguages.map((language) => getLanguageName(language, intl)),
            {type: 'conjunction', style: 'narrow'},
          )}
        </ListedProperty>
      )}
      {(isSelfUser || user.verifiedGithubLogin) && (
        <ListedProperty label={intl.formatMessage(labels.github)}>
          <UserGithubAccount isSelfUser={isSelfUser} verifiedGithubLogin={user.verifiedGithubLogin} />
        </ListedProperty>
      )}
    </>
  );
}
