import {StyledLink} from 'components/ui/StyledLink';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {formatType} from 'domain/compensationReviewRequest/model/type/formatter';
import {checkRequestNeedsReaction} from 'domain/compensationReviewRequest/utils/request';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import React from 'react';
import {useIntl} from 'react-intl';
import {useRouteMatch} from 'react-router-dom';
import {hrConsoleUrlPatterns, hrConsoleUrls} from 'routes/hrConsole/urls';
import {managerConsoleUrls} from 'routes/managerConsole/urls';
import {useAcl} from 'services/acl';
import styles from './styles.css';

type Props = {
  request: CompensationReviewRequest;
};

export function CompensationReviewRequestLink({request}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {path} = useRouteMatch();
  const selfUserId = useSelfUserId();
  const needsReaction = checkRequestNeedsReaction(request, selfUserId, acl);
  const {id, type} = request;
  const to = path.startsWith(hrConsoleUrlPatterns.root)
    ? hrConsoleUrls.compensationReviewRequest({id})
    : managerConsoleUrls.compensationReviewRequest({id});

  return (
    <StyledLink to={to} colored className={needsReaction ? styles.needsReaction : undefined}>
      {formatType(type, intl)}
    </StyledLink>
  );
}
