import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Employment} from 'domain/employment/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {cancelTerminationFx, getEmploymentsByUserIdFx, terminateFx, updateTerminationFx} from '.';

type State = {
  byUserId: Record<UserId, Employment[]>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $employments = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on(getEmploymentsByUserIdFx, (state, userId) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on(getEmploymentsByUserIdFx.fail, (state, {params: userId}) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.FAILED,
      },
    };
  })
  .on(getEmploymentsByUserIdFx.done, (state, {params: userId, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: result,
      },
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADED,
      },
    };
  })
  .on([cancelTerminationFx.done, terminateFx.done, updateTerminationFx.done], (state, {params: {userId}, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: state.byUserId[userId]?.map((employment) => (employment.id === result.id ? result : employment)),
      },
    };
  });
