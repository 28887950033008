import {SalaryRangesUploadResult} from 'domain/salaryRange/model/upload';
import {BulkUploadStatus} from 'models/system/bulkUpload';
import {IntlShape} from 'react-intl';
import {errorMessages} from './messages';

export const getErrorFromImportResult = (
  importResult: SalaryRangesUploadResult | undefined,
  intl: IntlShape,
): string | undefined => {
  if (importResult === undefined) {
    return undefined;
  }
  if (importResult.error !== undefined) {
    return importResult.error;
  }
  if (importResult.status === BulkUploadStatus.ERROR) {
    return intl.formatMessage(errorMessages.unknownError);
  }
  if (importResult.status === BulkUploadStatus.APPLIED) {
    return intl.formatMessage(errorMessages.alreadyApplied);
  }
  if (importResult.status === BulkUploadStatus.CREATED_WITH_ERRORS) {
    return intl.formatMessage(errorMessages.invalidRows);
  }
  if (!importResult.salaryRangeUploadRecords?.length) {
    return intl.formatMessage(errorMessages.noRows);
  }
  return undefined;
};
