import styles from './index.css';
import {CalloutIntent} from './calloutIntent';

export const classNameByCalloutIntent: Record<CalloutIntent, string> = {
  negative: styles.calloutIntentNegative,
  neutral: styles.calloutIntentNeutral,
  positive: styles.calloutIntentPositive,
  primary: styles.calloutIntentPrimary,
  warning: styles.calloutIntentWarning,
};
