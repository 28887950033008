import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  startDate: {
    defaultMessage: 'Start date',
    description: 'Regular bonus record form: field label',
  },
  endDate: {
    defaultMessage: 'End date',
    description: 'Regular bonus record form: field label',
  },
  type: {
    defaultMessage: 'Calculation approach',
    description: 'Regular bonus record form: field label',
  },
  amount: {
    defaultMessage: 'Amount',
    description: 'Regular bonus record form: field label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'Regular bonus record form: field label',
  },
  frequency: {
    defaultMessage: 'Frequency',
    description: 'Regular bonus record form: field label',
  },
  comment: {
    defaultMessage: 'Comment',
    description: 'Regular bonus record form: field label',
  },
});
