import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {OfficeRoomId} from 'domain/officeMap/room/model';

export type GenerateTokenDiff = {
  roomId: OfficeRoomId;
};

export const generateToken = async (body: GenerateTokenDiff): Promise<string> => {
  const {data} = await intranetApi.post('/v1/offices/rooms/generateToken', body);

  return string().runWithException(data);
};
