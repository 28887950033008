import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {CommonFieldProps, FieldSelect} from '@joomcode/joom-form';
import {OfficePolicy} from 'domain/officePolicyRecord/model/policy';
import {formatOfficePolicy} from 'domain/officePolicyRecord/model/policy/formatter';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

type Props = CommonFieldProps<OfficePolicy>;

export function FieldOfficePolicy(props: Props) {
  const intl = useIntl();
  const policyToString = useCallback((policy: OfficePolicy) => formatOfficePolicy(policy, intl), [intl]);

  return (
    <FieldSelect<OfficePolicy>
      items={getEnumValues(OfficePolicy)}
      itemToString={policyToString}
      renderItem={policyToString}
      {...props}
    />
  );
}
