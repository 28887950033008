import {Page} from '@joomcode/joom-ui/Page';
import {Panel} from '@joomcode/joom-ui/Panel';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {useOutstaffUser} from 'domain/outstaffUser/hooks/useOutstaffUser';
import {OutstaffUserCallout} from 'domain/outstaffUser/widgets/Callout';
import {Permission} from 'domain/permission/model';
import {UserFull} from 'domain/user/model';
import {UserProfile} from 'domain/user/widgets/Profile';
import {UserProfileLayout} from 'domain/user/widgets/ProfileLayout';
import {generalMessages} from 'i18n/messages/general';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import Helmet from 'react-helmet';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {pageTitles} from 'routes/users/titles';
import {useAcl} from 'services/acl';

export function OutstaffUserPage() {
  const acl = useAcl();
  const intl = useIntl();
  const {email} = useParams<{email: string}>();
  const {outstaffUser, dataState} = useOutstaffUser({email});

  if (!acl.hasPermission(Permission.OUTSTAFF_USER_READ)) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={outstaffUser} state={dataState}>
      {(userData: UserFull) => (
        <Page title={intl.formatMessage(pageTitles.outstaffUser)} headerContent={<OutstaffUserCallout />}>
          <Helmet
            title={intl.formatMessage(generalMessages.fullName, {
              firstName: userData.firstName,
              lastName: userData.lastName,
            })}
          />
          <Panel>
            <UserProfileLayout
              user={userData}
              isSelfUser={false}
              showLegalInfo={false}
              showHiringProtocol={false}
              showTimeOffInfo={false}
            >
              <UserProfile user={userData} isSelfUser={false} />
            </UserProfileLayout>
          </Panel>
        </Page>
      )}
    </EntityPageStateHandler>
  );
}
