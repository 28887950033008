import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Panel} from '@joomcode/joom-ui/Panel';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {salaryRecordsApi} from 'domain/salaryRecord/api';
import {dateToString} from 'models/system/formattedDate';
import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import uuid from 'uuid/v4';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  onClose(): void;
};

type FormState = {
  date?: string;
};

const today = new Date();

export function SalariesExportByDateDropdown({onClose}: Props) {
  const intl = useIntl();

  // Hack: don't memoize calendarPortalId and create a new portal on each render instead.
  // While reusing the same portal it gets 'data-focus-on-hidden' attribute after parent dropdown is closed.
  // This leads to the calendar being irresponsive on re-open.
  const calendarPortalId = uuid();
  const onSubmit = useCallback(
    (formState: FormState) => {
      if (!formState.date) {
        return;
      }
      window.open(salaryRecordsApi.buildDownloadByDateLink(formState.date));
      onClose();
    },
    [onClose],
  );

  return (
    <div className={styles.dropdown}>
      <Form<FormState> onSubmit={onSubmit}>
        {({handleSubmit, invalid}) => (
          <form onSubmit={handleSubmit}>
            <Panel.Section>
              <Panel.Content withPadding>
                <div className={styles.content}>
                  <FieldDate
                    name='date'
                    label={intl.formatMessage(messages.dateLabel)}
                    initialValue={dateToString(today)}
                    required
                    reserveSpaceForError={false}
                    portalId={calendarPortalId}
                  />
                </div>
              </Panel.Content>
            </Panel.Section>
            <Panel.Section>
              <Panel.Content withPadding>
                <div className={styles.buttons}>
                  <ButtonGroup spaced align='center' size='m'>
                    <Button kind='primary' intent='neutral' onClick={onClose}>
                      {intl.formatMessage(messages.buttonCancel)}
                    </Button>
                    <Button kind='primary' intent='primary' type='submit' disabled={invalid}>
                      {intl.formatMessage(messages.buttonDownload)}
                    </Button>
                  </ButtonGroup>
                </div>
              </Panel.Content>
            </Panel.Section>
          </form>
        )}
      </Form>
    </div>
  );
}
