import {isNonEmptyArray} from '@joomcode/deprecated-utils/function/isNonEmptyArray';
import {
  composeValidators,
  getFieldValidator,
  useFieldArrayWithInitialValue,
  ValidationFunction,
  ValidationOptions,
} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plusSmall.svg';
import {ReactComponent as TrashIcon} from '@joomcode/joom-ui/icons/core/trash.svg';
import {RoleInTeamDiff} from 'domain/role/model/roleInTeam';
import {FieldRole} from 'domain/role/widgets/Field';
import {FieldTeam} from 'domain/team/widgets/Field';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {getArrayFieldValidator} from 'utils/form/arrayFieldValidation';
import {messages} from './messages';
import styles from './styles.css';
import {validateRolesInTeamsNoPartial} from './validate';

export type RoleInTeamValue = Partial<RoleInTeamDiff>;

export type Props = {
  disabled: boolean;
  initialValue?: RoleInTeamValue[];
  name: string;
  onLoadingFail?: (error: Error) => void;
  validate?: ValidationFunction<RoleInTeamValue[], ValidationOptions, unknown>;
};

const emptyValue: RoleInTeamValue[] = [{}];

export function FieldRolesInTeams({
  disabled,
  initialValue: initialValueProp,
  name,
  onLoadingFail,
  validate: externalValidate,
}: Props) {
  const intl = useIntl();
  const initialValue = isNonEmptyArray(initialValueProp) ? initialValueProp : emptyValue;
  const validate = externalValidate
    ? composeValidators(validateRolesInTeamsNoPartial, externalValidate)
    : validateRolesInTeamsNoPartial;
  const fields = useFieldArrayWithInitialValue<RoleInTeamValue>(name, {
    initialValue,
    validate: getArrayFieldValidator(getFieldValidator(validate)),
  });
  const addRow = useCallback(() => fields.push({}), [fields]);

  return (
    <>
      {fields.map((fieldName, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.row} key={index}>
            <div className={styles.role}>
              <FieldRole
                name={`${fieldName}.roleId`}
                disabled={disabled}
                initialValue={initialValue[index]?.roleId}
                onLoadingFail={onLoadingFail}
                clearable
              />
            </div>
            <div className={styles.text}>{intl.formatMessage(messages.inTeam)}</div>
            <div className={styles.teamAndRemove}>
              <div className={styles.team}>
                <FieldTeam
                  name={`${fieldName}.teamId`}
                  disabled={disabled}
                  initialValue={initialValue[index]?.teamId}
                  onLoadingFail={onLoadingFail}
                  clearable
                />
              </div>
              {fields.length > 1 && (
                <div className={styles.removeButtonWrap}>
                  <Button
                    aria-label={intl.formatMessage(messages.removeButton)}
                    iconLeft={<TrashIcon />}
                    size='m'
                    kind='text'
                    intent='neutral'
                    type='button'
                    onClick={() => fields.remove(index)}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div className={styles.addButtonWrap}>
        <Button
          iconLeft={<PlusIcon />}
          size='m'
          kind='secondary'
          intent='neutral'
          type='button'
          onClick={addRow}
          disabled={disabled}
        >
          {intl.formatMessage(messages.addButton)}
        </Button>
      </div>
    </>
  );
}
