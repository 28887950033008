import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {ReplaceSalaryRecordParams} from 'domain/salaryRecord/api/types/replace';
import {ExtendedSalaryRecord, extendedSalaryRecordSchema} from 'domain/salaryRecord/model';

export const replaceExtended = async ({
  previousRecordId,
  userId,
  diff,
}: ReplaceSalaryRecordParams): Promise<ExtendedSalaryRecord[]> => {
  const {data} = await intranetApi.post('/v1/secure/users/salaryRecords/replaceExtended', diff, {
    params: {previousRecordId, userId},
  });

  return array(extendedSalaryRecordSchema).runWithException(data);
};
