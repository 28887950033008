import {ReactComponent as PenIcon} from '@joomcode/joom-ui/icons/core/pen.svg';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {buildGithubVerificationLink} from 'domain/user/api/buildGithubVerificationLink';
import {buildUserGithubUrl} from 'domain/user/api/buildUserGithubUrl';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  isSelfUser: boolean;
  verifiedGithubLogin?: string;
};

export function UserGithubAccount({isSelfUser, verifiedGithubLogin}: Props) {
  const intl = useIntl();
  return (
    <>
      {verifiedGithubLogin ? (
        <>
          <StyledExternalLink href={buildUserGithubUrl(verifiedGithubLogin)} colored>
            {verifiedGithubLogin}
          </StyledExternalLink>
          {isSelfUser && (
            <TooltipAlt content={intl.formatMessage(messages.githubHint)}>
              <StyledExternalLink href={buildGithubVerificationLink()} className={styles.editIconLink}>
                <PenIcon />
              </StyledExternalLink>
            </TooltipAlt>
          )}
        </>
      ) : (
        <StyledExternalLink href={buildGithubVerificationLink()} colored withIcon>
          {intl.formatMessage(messages.linkAccount)}
        </StyledExternalLink>
      )}
    </>
  );
}
