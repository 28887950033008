import {memoize} from '@joomcode/deprecated-utils/memoize';

const defaultRem = 16;

/** Get `1rem` value in pixels. */
export const getRemInPxRaw = (): number | undefined => {
  const {fontSize} = getComputedStyle(document.documentElement);
  const value = Number(fontSize.replace('px', ''));
  return Number.isNaN(value) ? undefined : value;
};

/** Get `1rem` value in pixels. Memo result. Use `16px` as fallback. */
export const getRemInPx: () => number = memoize(() => getRemInPxRaw() ?? defaultRem);
