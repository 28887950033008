import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {LevelCode} from 'domain/levelCode/model';
import faker from 'faker';
import {dateToString} from 'models/system/formattedDate';
import {MarketDataRecord} from '.';

export function mockMarketDataRecord(data: Partial<MarketDataRecord> = {}): MarketDataRecord {
  const date = Math.random() > 0.5 ? faker.date.past() : faker.date.future();

  return {
    active: faker.random.boolean(),
    levelCode: faker.random.arrayElement(getEnumValues(LevelCode)),
    id: createMockId(),
    effectiveDate: dateToString(date),
    userFunction: faker.name.jobArea(),
    ...data,
  };
}
