import {Key} from '@joomcode/deprecated-utils/keyboard/keys';
import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import {useLayoutEffect, useRef} from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import {activeTabIdCalculator} from './activeTabCalculator';
import {
  TabId,
  TAB_ID_ATTRIBUTE_NAME,
  TAB_CLOSABLE_ATTRIBUTE_NAME,
  TabListProps,
  TabPanelProps,
  TabProps,
  TabCloseButtonProps,
} from './types';
import {getTabIdFromEvent, getTabNodeId, getTabPanelNodeId, getTabNodeFromEvent, isTabClosable} from './utils';

export function useTabs({
  activeTabId,
  onChange,
  onClose,
  tabsOrder,
  vertical = false,
}: {
  activeTabId: TabId;
  onChange(id: TabId): void;
  onClose?: (id: TabId) => void;
  tabsOrder: TabId[];
  vertical?: boolean;
}): {
  getTabProps(options: {id: TabId; closable?: boolean}): TabProps;
  getTabListProps(): TabListProps;
  getTabPanelProps(options: {id: TabId}): TabPanelProps;
  getTabCloseButtonProps(options: {id: TabId}): TabCloseButtonProps;
} {
  const tabListRef = useRef<HTMLUListElement>(null);
  const shouldUpdateFocus = useRef(false);

  useLayoutEffect(() => {
    const tabListNode = tabListRef.current;

    if (shouldUpdateFocus.current && tabListNode) {
      const activeTabNode = tabListNode.querySelector(`[${TAB_ID_ATTRIBUTE_NAME}="${activeTabId}"]`) as HTMLElement;

      if (activeTabNode) {
        activeTabNode.focus();
        shouldUpdateFocus.current = false;
      }
    }
  }, [activeTabId]);

  useUpdateEffect(() => {
    const activeTabNode = tabListRef.current?.querySelector(`[${TAB_ID_ATTRIBUTE_NAME}="${activeTabId}"]`);

    if (activeTabNode) {
      scrollIntoView(activeTabNode, {
        behavior: 'smooth',
        inline: 'nearest',
        boundary: tabListRef.current,
      });
    }

    return undefined;
  }, [activeTabId]);

  return {
    getTabCloseButtonProps: ({id}) => ({
      tabIndex: -1,
      onClick: (event) => {
        if (!onClose) {
          return;
        }

        event.stopPropagation(); // Prevent triggering tab click handler
        onClose(id);

        if (activeTabId === id) {
          const newActiveTabId = activeTabIdCalculator.afterClose(tabsOrder, id);

          if (newActiveTabId) {
            onChange(newActiveTabId);
            shouldUpdateFocus.current = true;
          }
        }
      },
    }),
    getTabProps: ({id, closable}) => ({
      [TAB_ID_ATTRIBUTE_NAME]: id,
      [TAB_CLOSABLE_ATTRIBUTE_NAME]: closable ?? undefined,
      'aria-controls': getTabPanelNodeId(id),
      'aria-selected': id === activeTabId,
      id: getTabNodeId(id),
      role: 'tab',
      tabIndex: id === activeTabId ? 0 : -1,
    }),
    getTabListProps: () => ({
      onClick: (event) => {
        const clickedTabId = getTabIdFromEvent(event.nativeEvent);

        if (clickedTabId && clickedTabId !== activeTabId) {
          onChange(clickedTabId);
        }
      },
      onKeyDown: (event) => {
        const tabNode = getTabNodeFromEvent(event.nativeEvent);
        const currentTabClosable = tabNode ? isTabClosable(tabNode) : false;
        const currentTabId = getTabIdFromEvent(event.nativeEvent);

        /**
         * Do not handle keyboard navigation if there is no active tab or if the user is using a modifier key.
         * Modifier keys are used in browser shortcuts and should not be intercepted. See UI-162 for more details.
         */
        if (!currentTabId || event.metaKey || event.ctrlKey || event.shiftKey || event.altKey) {
          return;
        }

        let newTabId: TabId | undefined;

        if ((vertical && event.key === Key.ARROW_DOWN) || (!vertical && event.key === Key.ARROW_RIGHT)) {
          event.preventDefault();
          newTabId = activeTabIdCalculator.next(tabsOrder, currentTabId);
        } else if ((vertical && event.key === Key.ARROW_UP) || (!vertical && event.key === Key.ARROW_LEFT)) {
          event.preventDefault();
          newTabId = activeTabIdCalculator.previous(tabsOrder, currentTabId);
        } else if (event.key === Key.END) {
          newTabId = activeTabIdCalculator.last(tabsOrder);
        } else if (event.key === Key.HOME) {
          newTabId = activeTabIdCalculator.first(tabsOrder);
        } else if (event.key === Key.DELETE && currentTabClosable && onClose) {
          newTabId = activeTabIdCalculator.afterClose(tabsOrder, currentTabId);
          onClose(currentTabId);
        }

        if (newTabId !== undefined && newTabId !== currentTabId) {
          onChange(newTabId);
          shouldUpdateFocus.current = true;
        }
      },
      ref: tabListRef,
      role: 'tablist',
      'aria-orientation': vertical ? 'vertical' : 'horizontal',
    }),
    getTabPanelProps: ({id}) => ({
      'aria-labelledby': getTabNodeId(id),
      id: getTabPanelNodeId(id),
      role: 'tabpanel',
      tabIndex: 0,
    }),
  };
}
