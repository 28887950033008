import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {PointInfo} from 'domain/officeMap/office/widgets/PointInfo';
import {OfficeSeat} from 'domain/officeMap/seat/model';
import {SeatNumberEdit} from 'domain/officeMap/seat/widgets/NumberEdit';
import {Permission} from 'domain/permission/model';
import {User} from 'domain/user/model';
import {freeSeatFx, unassignSeatFx} from 'domain/user/stores/main';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import React from 'react';
import {useIntl} from 'react-intl';
import {officeUrls} from 'routes/officeMap/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  isMine: boolean;
  user: User;
  seat: OfficeSeat;
};

export function TakenSeatCard({isMine, user, seat}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canUnassignSeats = acl.hasPermission(Permission.USER_ASSIGN_SEAT);
  const canUpdateSeats = acl.hasPermission(Permission.OFFICE_MAP_SEAT_WRITE);

  const freeSeatTask = useAsyncTask(
    () => (canUnassignSeats ? unassignSeatFx(user.id) : freeSeatFx()).catch(toaster.interceptThenThrowError),
    [canUnassignSeats, user.id],
  );

  const title = seat.number
    ? intl.formatMessage(messages.seatNumber, {number: seat.number})
    : intl.formatMessage(messages.seatWithoutNumber);

  return (
    <PointInfo
      copyIconLabel={intl.formatMessage(messages.copyIconLabel)}
      title={canUpdateSeats ? <SeatNumberEdit seatId={seat.id} seatNumber={seat.number} /> : title}
      pointUrl={officeUrls.seat({alias: seat.officeAlias}, seat)}
      button={
        (canUnassignSeats || isMine) && (
          <Button
            loading={freeSeatTask.isPerforming}
            onClick={freeSeatTask.perform}
            intent='neutral'
            kind='primary'
            size='m'
          >
            {intl.formatMessage(canUnassignSeats ? messages.unassignSeat : messages.freeSeat)}
          </Button>
        )
      }
    >
      <ListedUser user={user} />
    </PointInfo>
  );
}
