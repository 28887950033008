import {bindValidatorOptions, validateString} from '@joomcode/joom-form';

const EU_DIACRITIC_CHARS =
  'ÁáÀàÂâÃãÄäĂăĀāÅåĄąÆæÉéÈèÊêËëĚěĒēĖėĘęÍíÌìÎîÏïĪīĮįIıÓóÒòÔôÕõÖöŐőØøŒœÚúÙùÛûÜüŰűŪūůÝýĆćČčçĎďĐđĐðĢģĞğĶķŁłĻļŃńÑñŇňŅņŊŋŘřŚśŠšŞşßŤťŦŧÞþŢţŻżŹźŽž';
const LATIN_NAME_REGEXP = new RegExp(`^[a-zA-Z- ${EU_DIACRITIC_CHARS}]+$`);
const LATIN_NAME_REGEXP_WITHOUT_DIACRITIC = /^[a-zA-Z- ]+$/;

export const validateLatinName = bindValidatorOptions(validateString, {regExp: LATIN_NAME_REGEXP});
export const validateLatinNameWithoutDiacritic = bindValidatorOptions(validateString, {
  regExp: LATIN_NAME_REGEXP_WITHOUT_DIACRITIC,
});
