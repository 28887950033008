import {defineMessages} from 'react-intl';
import {UrlValidatorErrorCode} from './types';

export const urlErrorMessages = defineMessages<UrlValidatorErrorCode>({
  [UrlValidatorErrorCode.URL_INVALID]: {
    id: 'validator-url-invalid',
    description: 'Validation error about provided url is invalid',
    defaultMessage: 'URL is invalid',
  },
  [UrlValidatorErrorCode.URL_INVALID_FORMAT]: {
    id: 'validator-url-invalid-format',
    description: 'Validation error about provided url is invalid',
    defaultMessage: 'URL is invalid',
  },
  [UrlValidatorErrorCode.URL_INVALID_SCHEMA]: {
    id: 'validator-url-invalid-schema',
    description: 'Validation error about provided url has invalid schema',
    defaultMessage: 'We accept URLs that begin with {schemas}',
  },
});
