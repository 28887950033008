import {constant, union} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {LegalEntity, legalEntitySchema} from 'domain/legalEntity/model';
import {UserId} from 'domain/user/model';

export const getMainLegalEntity = async (userId: UserId): Promise<LegalEntity | null> => {
  const {data} = await intranetApi.get('/v1/users/getMainLegalEntity', {params: {userId}});

  return union(constant(null), legalEntitySchema).runWithException(data);
};
