import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Further salary',
    description: '[title] Offer form section title',
  },
  reasonDetailsHint: {
    defaultMessage: 'Specify why the employee’s salary will change',
    description: 'Further salary reason field hint',
  },
  buttonRemove: {
    defaultMessage: 'Remove further salary',
    description: '[button] Removing further salary button text',
  },
});

export const fieldLabels = defineMessages({
  furtherSalaryAmount: {
    defaultMessage: 'Salary (gross)',
    description: 'Offer form field label',
  },
  furtherSalaryCurrency: {
    defaultMessage: 'Currency',
    description: 'Offer form field label',
  },
  furtherSalaryFrequency: {
    defaultMessage: 'Frequency',
    description: 'Offer form field label',
  },
});
