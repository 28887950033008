import {validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {CompanyUnitFormControl} from 'components/widgets/CompanyUnitFormControl';
import {FieldJobTitle} from 'components/widgets/fields/FieldJobTitle';
import {CompanyUnits} from 'domain/companyUnits/model';
import {FieldLevelCode} from 'domain/levelCode/widgets/Field';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {compensationFieldLabels} from 'domain/offer/model/compensation/messages';
import {OfferEmploymentInfo} from 'domain/offer/model/employmentInfo';
import {employmentInfoFieldLabels} from 'domain/offer/model/employmentInfo/messages';
import {OfferFormSectionTitle} from 'domain/offer/widgets/FormSectionTitle';
import {FieldUser, FieldUserValue} from 'domain/user/widgets/Field';
import React from 'react';
import {Field, useFormState} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {messages} from './messages';

type Props = {
  companyUnits: CompanyUnits;
  compensation?: OfferCompensation;
  employmentInfo?: OfferEmploymentInfo;
};

export function OfferJobInfoFields({companyUnits, compensation, employmentInfo}: Props) {
  const intl = useIntl();
  const {values} = useFormState();

  return (
    <>
      <OfferFormSectionTitle title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item xl={12}>
            <Field<FieldUserValue>
              name='employmentInfo.managerId'
              validate={validateFilled}
              initialValue={employmentInfo?.manager.id}
            >
              {({input, meta}) => (
                <FormControl
                  label={intl.formatMessage(employmentInfoFieldLabels.manager)}
                  required
                  error={getFieldErrorText(meta, {intl})}
                  reserveSpaceForError={false}
                >
                  {(formControlProps) => <FieldUser name={input.name} {...formControlProps} />}
                </FormControl>
              )}
            </Field>
          </Grid.Item>
          <Grid.Item sm={12} xl={6}>
            <CompanyUnitFormControl
              name='employmentInfo.division'
              type='division'
              items={companyUnits.divisions}
              required
              validate={validateFilled}
              allowCreatingNewItems={false}
              reserveSpaceForError={false}
              initialValue={employmentInfo?.division}
            />
          </Grid.Item>
          <Grid.Item sm={12} xl={6}>
            <CompanyUnitFormControl
              name='employmentInfo.department'
              type='department'
              items={companyUnits.departments}
              required
              validate={validateFilled}
              allowCreatingNewItems={false}
              reserveSpaceForError={false}
              initialValue={employmentInfo?.department}
            />
          </Grid.Item>
          <Grid.Item sm={8} xl={6}>
            <CompanyUnitFormControl
              name='compensation.userFunction'
              type='userFunction'
              items={companyUnits.userFunctions}
              required
              validate={validateFilled}
              allowCreatingNewItems={false}
              reserveSpaceForError={false}
              initialValue={compensation?.userFunction}
            />
          </Grid.Item>
          <Grid.Item sm={4} xl={6}>
            <FieldLevelCode
              label={intl.formatMessage(compensationFieldLabels.levelCode)}
              name='compensation.levelCode'
              required
              validate={validateFilled}
              initialValue={compensation?.levelCode}
              reserveSpaceForError={false}
            />
          </Grid.Item>
          <Grid.Item xl={12}>
            <FieldJobTitle
              name='employmentInfo.jobTitle'
              jobTitles={companyUnits.jobTitles}
              reserveSpaceForError={false}
              initialValue={employmentInfo?.jobTitle}
              levelCode={values.compensation?.levelCode}
              userFunction={values.compensation?.userFunction}
            />
          </Grid.Item>
        </Grid>
      </Panel.Content>
    </>
  );
}
