import cn from 'classnames';
import {LandmarkConfig, LandmarkType} from 'domain/officeMap/landmark/model';
import {EmojiLandmarksSvg} from 'domain/officeMap/landmark/widgets/EmojiLandmarksSvg';
import {Office} from 'domain/officeMap/office/model';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {OfficeMapSvgChina} from 'domain/officeMap/office/widgets/MapSvgChina';
import {OfficeMapSvgLisbon} from 'domain/officeMap/office/widgets/MapSvgLisbon';
import {OfficeMapSvgMoscow} from 'domain/officeMap/office/widgets/MapSvgMoscow';
import {OfficeRoom, OfficeRoomId} from 'domain/officeMap/room/model';
import {RoomsSvg} from 'domain/officeMap/room/widgets/RoomsSvg';
import {RoomTooltip} from 'domain/officeMap/room/widgets/Tooltip';
import {OfficeSeat, OfficeSeatId, SeatIdentification} from 'domain/officeMap/seat/model';
import {SeatsSvg} from 'domain/officeMap/seat/widgets/SeatsSvg';
import {User, UserFull} from 'domain/user/model';
import React from 'react';
import styles from './styles.css';

const ROOM_SHADOW_TEMPLATE_ID = 'roomShadowId';
type MapSvgProps = {
  children: React.ReactNode[];
  showExits: boolean;
  shadowElementId: string;
  showWc: boolean;
};
const mapSvgByAlias: Record<OfficeAlias, React.FC<MapSvgProps>> = {
  [OfficeAlias.LISBON]: OfficeMapSvgLisbon,
  [OfficeAlias.MOSCOW]: OfficeMapSvgMoscow,
  [OfficeAlias.CHINA]: OfficeMapSvgChina,
};

type Props = {
  onSeatSelect: (seat: OfficeSeat | null, officeAlias?: OfficeAlias) => void;
  selectedSeat: SeatIdentification | null;
  selfUser: UserFull;
  usersBySeatId: Record<OfficeSeatId, User>;
  onRoomSelect: (room: OfficeRoom | null, officeAlias?: OfficeAlias) => void;
  selectedRoomId: OfficeRoomId | null;
  landmarkConfig: LandmarkConfig;
  roomsById: Record<OfficeRoomId, OfficeRoom>;
  office: Office;
};

export function OfficeMap({
  usersBySeatId,
  selectedSeat,
  onSeatSelect,
  selfUser,
  selectedRoomId,
  onRoomSelect,
  landmarkConfig,
  roomsById,
  office,
}: Props) {
  const MapSvg = mapSvgByAlias[office.alias];

  return (
    <div className={cn(styles.mapContainer, styles[office.alias])}>
      <MapSvg
        shadowElementId={ROOM_SHADOW_TEMPLATE_ID}
        showExits={landmarkConfig[LandmarkType.ENTRANCE]}
        showWc={landmarkConfig[LandmarkType.WC]}
      >
        <SeatsSvg
          seats={office.seats}
          onSeatSelect={onSeatSelect}
          selectedSeat={selectedSeat}
          selfUser={selfUser}
          usersBySeatId={usersBySeatId}
          showSeatNumbers={landmarkConfig[LandmarkType.SEAT_NUMBER] === true}
        />
        {selectedRoomId !== null && roomsById[selectedRoomId] !== undefined && (
          <RoomTooltip room={roomsById[selectedRoomId]} onRoomSelect={onRoomSelect} officeAlias={office.alias} />
        )}
        <EmojiLandmarksSvg emojiLandmarks={office.landmarks} landmarkConfig={landmarkConfig} />
        <RoomsSvg
          isActive={landmarkConfig[LandmarkType.ROOM]}
          rooms={office.rooms}
          onRoomSelect={onRoomSelect}
          shadowElementId={ROOM_SHADOW_TEMPLATE_ID}
          selectedRoomId={selectedRoomId}
        />
      </MapSvg>
    </div>
  );
}
