import {PageLock, usePageLocker} from '@joomcode/joom-ui/PageLock';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {CompensationReviewRequestDraft as Draft} from 'domain/compensationReviewRequest/model/draft';
import {UserInfo} from 'domain/compensationReviewRequest/model/userInfo';
import {CompensationReviewRequestDraft} from 'domain/compensationReviewRequest/widgets/Draft';
import {CompensationReviewRequestFormRest} from 'domain/compensationReviewRequest/widgets/FormRest';
import {CompensationReviewRequestFormStart} from 'domain/compensationReviewRequest/widgets/FormStart';
import {SalaryRecord, SalaryRecordId} from 'domain/salaryRecord/model';
import {StockOptionSummary} from 'domain/stockOption/summary/model';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {UserId} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useHistory} from 'react-router-dom';
import uuid from 'uuid/v4';
import styles from './styles.css';

type Props = {
  draft?: Draft;
  onlySubordinates?: boolean;
  setDraft: (draft: Draft | undefined) => void;
};

type State = Omit<Draft, 'user' | 'salaries'> & {
  userId: UserId;
  userInfo: UserInfo;
  salaries: Record<SalaryRecordId, Pick<SalaryRecord, 'amount'>>;
  totalStockOptions?: StockOptionSummary;
};

export function CompensationReviewRequestForm({draft, onlySubordinates, setDraft}: Props) {
  const formId = useMemo(uuid, []);
  const {userById, dataState} = useAllUsers();
  const history = useHistory();
  const pageLocker = usePageLocker();

  const onSubmit = useCallback(
    ({userInfo, userId, totalStockOptions, ...data}: State) => {
      setDraft({
        ...data,
        user: userById[userId],
        salaries: data.salaries ? Object.entries(data.salaries).map(([id, {amount}]) => ({id, amount})) : [],
        newUserInfo: {
          ...userInfo,
          contracts: data.oldUserInfo.contracts.map((contract) => ({
            ...contract,
            activeSalaries: contract.activeSalaries
              ? contract.activeSalaries.map((salary) => ({
                  ...salary,
                  amount: data.salaries[salary.id].amount,
                }))
              : undefined,
          })),
          general: {
            ...userInfo.general,
            manager: userById[userInfo.general.manager.id],
          },
          stockOptions: totalStockOptions ?? data.oldUserInfo.stockOptions,
        },
      });
    },
    [userById, pageLocker, history],
  );

  const goBack = useCallback(() => {
    setDraft(undefined);
  }, [setDraft]);

  return (
    <StateHandler data={userById} state={dataState}>
      {() => (
        <>
          <div className={draft ? styles.hidden : undefined}>
            <Form<State> onSubmit={onSubmit}>
              {({handleSubmit, dirty, submitting, values}) => (
                <form id={formId} onSubmit={handleSubmit} className={styles.form}>
                  <PageLock pageLocker={pageLocker} when={dirty} />
                  <CompensationReviewRequestFormStart
                    onlySubordinates={onlySubordinates}
                    submitting={submitting}
                    userById={userById}
                  />
                  {values.userId && values.type && (
                    <CompensationReviewRequestFormRest
                      formId={formId}
                      userId={values.userId}
                      onlySubordinates={onlySubordinates}
                    />
                  )}
                </form>
              )}
            </Form>
          </div>
          {draft && (
            <CompensationReviewRequestDraft
              draft={draft}
              goBack={goBack}
              onlySubordinates={onlySubordinates}
              pageLocker={pageLocker}
            />
          )}
        </>
      )}
    </StateHandler>
  );
}
