import {defineMessages} from 'react-intl';
import {ChangeValue} from '.';

export const messages = defineMessages({
  hint: {
    defaultMessage: 'Effective from {date}',
    description: 'Compensation review request change value hint',
  },
});

export const changeValueMessages = defineMessages<ChangeValue>({
  [ChangeValue.YES]: {
    defaultMessage: 'Yes',
    description: 'Compensation review request change value',
  },
  [ChangeValue.NO]: {
    defaultMessage: 'No',
    description: 'Compensation review request change value',
  },
  [ChangeValue.RECORDED]: {
    defaultMessage: 'Applied',
    description: 'Compensation review request change value',
  },
});
