import {OverflowState} from './types';

const isOverflowsTop = (node: HTMLElement) => node.scrollTop > 0;
const isOverflowsLeft = (node: HTMLElement) => node.scrollLeft > 0;
const isOverflowsRight = (node: HTMLElement) => node.scrollWidth > node.clientWidth + node.scrollLeft;
const isOverflowsBottom = (node: HTMLElement) => node.scrollHeight > node.clientHeight + node.scrollTop;

export const getNodeOverflows = (node: HTMLElement): OverflowState => ({
  bottom: isOverflowsBottom(node),
  left: isOverflowsLeft(node),
  right: isOverflowsRight(node),
  top: isOverflowsTop(node),
});

export const getInitialOverflows = (): OverflowState => ({
  bottom: false,
  left: false,
  right: false,
  top: false,
});
