import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {GetSalaryRecordsParams} from 'domain/salaryRecord/api/types/get';
import {SalaryRecord, salaryRecordSchema} from 'domain/salaryRecord/model';

export const getMy = async ({legalEntityId}: GetSalaryRecordsParams): Promise<SalaryRecord[]> => {
  const {data} = await intranetApi.get('/v1/secure/users/salaryRecords/getMyByLegalEntityId', {
    params: {legalEntityId},
  });

  return array(salaryRecordSchema).runWithException(data);
};
