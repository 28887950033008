import {DataState} from '@joomcode/deprecated-utils/dataState';
import {getSalaryRangeByParamsFx} from 'domain/salaryRange/stores/main';
import {createStore} from 'effector';

type State = {
  dataState: DataState;
  amount: number | undefined;
};

export const $stockOptionsProposal = createStore<State>({
  dataState: DataState.LOADED,
  amount: undefined,
})
  .on(getSalaryRangeByParamsFx, (state) => ({
    ...state,
    dataState: DataState.LOADING,
  }))
  .on(getSalaryRangeByParamsFx.fail, (state) => ({
    ...state,
    amount: undefined,
    dataState: DataState.FAILED,
  }))
  .on(getSalaryRangeByParamsFx.doneData, (state, result) => ({
    ...state,
    amount: result?.stockOptionsProposal,
    dataState: DataState.LOADED,
  }));
