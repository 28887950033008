import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Sorting, SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StyledLink} from 'components/ui/StyledLink';
import {SecureRole} from 'domain/secureRole/model';
import orderBy from 'lodash/orderBy';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {secureRolesUrls} from 'routes/secureRoles/urls';
import {columnNames} from './messages';

type Props = {
  secureRoles: SecureRole[];
};

const getRowKey = (role: SecureRole) => role.id;
const sortRoles = (roles: SecureRole[], sorting: Sorting | undefined) =>
  sorting ? orderBy(roles, sorting.by, sorting.direction) : roles;

export function SecureRolesTable({secureRoles}: Props) {
  const intl = useIntl();
  const columns: Column<SecureRole>[] = useMemo(
    () => [
      {
        defaultWidth: 40,
        id: 'id',
        name: intl.formatMessage(columnNames.id),
        render: ({id}) => (
          <StyledLink to={secureRolesUrls.role({id})} colored>
            {id}
          </StyledLink>
        ),
      },
      {
        defaultWidth: 30,
        id: 'name',
        name: intl.formatMessage(columnNames.name),
        render: ({id, title}) => (
          <StyledLink to={secureRolesUrls.role({id})} colored>
            {title}
          </StyledLink>
        ),
        sortable: true,
      },
      {
        defaultWidth: 30,
        id: 'usedBy',
        name: intl.formatMessage(columnNames.usedBy),
        render: ({holders}) => intl.formatNumber(holders.length),
        sortable: true,
      },
      {
        defaultWidth: 30,
        id: 'permissionsCount',
        name: intl.formatMessage(columnNames.permissionGranted),
        render: ({permissions}) => intl.formatNumber(permissions.length),
        sortable: true,
      },
    ],
    [intl],
  );

  const [sorting, setSorting] = useState<Sorting | undefined>({
    by: 'usedBy',
    direction: SortingDirection.DESC,
  });

  const sortedRoles = useMemo(() => sortRoles(secureRoles, sorting), [secureRoles, sorting]);

  return (
    <Panel>
      <DataTable
        columns={columns}
        dataState={DataState.LOADED}
        data={sortedRoles}
        getRowKey={getRowKey}
        onSort={setSorting}
        sorting={sorting}
        storageName='secureRolesList'
      />
    </Panel>
  );
}
