import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {isFriendlyId, isId} from '@joomcode/deprecated-utils/joomId';
import {isBlank} from '@joomcode/deprecated-utils/string/isBlank';
import type {ValidationFunction} from '../../types';
import {IdFormat, IdValidatorErrorCode, IdValidatorOptions} from './types';

type ValidateIdFunction = (id: string) => boolean;

const validatorsByFormat: Record<IdFormat, ValidateIdFunction> = {
  short: (id) => isFriendlyId(id),
  long: (id) => isId(id),
};

export const validateId: ValidationFunction<unknown, IdValidatorOptions, IdValidatorErrorCode> = (
  value,
  options = {},
) => {
  const {format} = options;

  if (isNullish(value) || (typeof value === 'string' && isBlank(value))) return undefined;

  if (typeof value !== 'string') {
    return {code: IdValidatorErrorCode.ID_INVALID, options};
  }

  if (format) {
    const validateFormat = validatorsByFormat[format];
    if (validateFormat && !validateFormat(value)) {
      return {code: IdValidatorErrorCode.ID_INVALID_FORMAT, options};
    }
  } else {
    // eslint-disable-next-line no-restricted-syntax
    for (const validate of Object.values(validatorsByFormat)) {
      if (validate(value)) return undefined;
    }
    return {code: IdValidatorErrorCode.ID_INVALID_FORMAT, options};
  }

  return undefined;
};
