import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {TimeOffRequest} from 'domain/timeOff/request/model';
import {
  approveSubordinateTimeOffRequestFx,
  approveTimeOffRequestFx,
  cancelTimeOffRequestFx,
  createTimeOffRequestFx,
  createTimeOffRequestOnBehalfFx,
  rejectSubordinateTimeOffRequestFx,
  rejectTimeOffRequestFx,
  setRecordedInAccountingSystemFx,
} from 'domain/timeOff/request/stores/main';
import {createStore} from 'effector';
import {getAllTimeOffRequestsFx, getAllTimeOffRequestsWithCountFx} from '.';

export const $paginatedTimeOffRequestsList = createEntityListStore(
  createEntityStore({
    getEntityId: (timeOffRequest: TimeOffRequest) => timeOffRequest.id,
    query: {
      fx: getAllTimeOffRequestsFx,
      getIdsFromParams: () => [],
    },
  })
    .on([setRecordedInAccountingSystemFx.doneData], (state, result) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [result.id]: result,
        },
      };
    })
    .reset(
      createTimeOffRequestFx.done,
      createTimeOffRequestOnBehalfFx.done,
      cancelTimeOffRequestFx.done,
      rejectTimeOffRequestFx.done,
      rejectSubordinateTimeOffRequestFx.done,
      approveTimeOffRequestFx.done,
      approveSubordinateTimeOffRequestFx.done,
    ),
);

export type State = {
  count: number;
};

export const $timeOffRequestsCount = createStore<State>({
  count: 0,
}).on(getAllTimeOffRequestsWithCountFx.done, (state, {result}) => {
  return {
    ...state,
    count: result.count,
  };
});
