import {DataState} from '@joomcode/deprecated-utils/dataState';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {JobContract} from 'domain/jobContract/model';
import {JobContractRecord} from 'domain/jobContract/model/record';
import {AddRecordButton} from 'domain/jobContract/widgets/AddRecordButton';
import {JobContractRecordDialog} from 'domain/jobContract/widgets/Dialog';
import {JobContractHistoryTab} from 'domain/jobContract/widgets/HistoryTab';
import {JobContractPanelTab} from 'domain/jobContract/widgets/Panel';
import {LegalEntityId} from 'domain/legalEntity/model';
import {Permission} from 'domain/permission/model';
import {UserFull} from 'domain/user/model';
import React, {useMemo} from 'react';
import {useAcl} from 'services/acl';

type Props = {
  user: UserFull;
  legalEntityId: LegalEntityId;
  jobContract: JobContract;
};

export function useHistoryTab({user, legalEntityId, jobContract}: Props): JobContractPanelTab<JobContractRecord> {
  const acl = useAcl();
  const canEdit = acl.hasPermission(Permission.JOB_CONTRACT_WRITE);
  const jobContractRecordDialog = usePopupState<JobContractRecord>();
  const activeRecord = useMemo(
    () => jobContract.jobContractRecords.find(({active}) => active),
    [jobContract.jobContractRecords],
  );

  const result = useMemo(
    () => ({
      addon: canEdit ? <AddRecordButton onClick={jobContractRecordDialog.open} /> : null,
      content: (
        <JobContractHistoryTab
          jobContract={jobContract}
          onDialogOpenWithPayload={jobContractRecordDialog.openWithPayload}
          userId={user.id}
        />
      ),
      dialog: canEdit ? (
        <JobContractRecordDialog
          user={user}
          legalEntityId={legalEntityId}
          recordToUpdate={jobContractRecordDialog.payload}
          activeRecord={activeRecord}
          isPrimary={jobContract.isPrimary}
          isOpen={jobContractRecordDialog.isOpen}
          onClose={jobContractRecordDialog.close}
        />
      ) : null,
      records: jobContract.jobContractRecords,
      dataState: DataState.LOADED,
    }),
    [canEdit, user, legalEntityId, jobContract, jobContractRecordDialog],
  );

  return result;
}
