import {DataState} from '@joomcode/deprecated-utils/dataState';
import {mimeType} from '@joomcode/deprecated-utils/file/mime';
import {FileDropArea} from '@joomcode/joom-ui/FileDropArea';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {IntentionedText} from '@joomcode/joom-ui/IntentionedText';
import {Panel} from '@joomcode/joom-ui/Panel';
import {useSalaryRangesUpload} from 'domain/salaryRange/hooks/useSalaryRangesUpload';
import {SalaryRangesUploadResult} from 'domain/salaryRange/model/upload';
import {UploadResultWarningType} from 'domain/salaryRange/model/upload/warning';
import {UserLink} from 'domain/user/widgets/Link';
import {BulkUploadStatus} from 'models/system/bulkUpload';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

const ACCEPTED_MIME_TYPES = [mimeType.excel.xlsx];

type Props = {
  uploadResult?: SalaryRangesUploadResult;
  onUpload(result: SalaryRangesUploadResult | undefined): void;
};

export function SalaryRangesUploadPanel({uploadResult, onUpload}: Props) {
  const intl = useIntl();
  const {error, dataState, onChange} = useSalaryRangesUpload({
    acceptedMimeTypes: ACCEPTED_MIME_TYPES,
    uploadResult,
    onUpload,
  });
  const errors = useMemo(
    () =>
      uploadResult?.salaryRangeUploadRecords?.reduce(
        (acc, {meta}) => [...acc, ...meta.errors],
        uploadResult.versionUploadRecord.meta.errors,
      ) ?? [],
    [uploadResult],
  );

  return (
    <Panel className={styles.root} title={intl.formatMessage(messages.title)}>
      {!uploadResult && <Panel.Content withPadding>{intl.formatMessage(messages.annotation)}</Panel.Content>}
      {error && (
        <Panel.Content withPadding>
          <IntentionedText intent='negative'>{error}</IntentionedText>
        </Panel.Content>
      )}
      {uploadResult?.status === BulkUploadStatus.CREATED_WITH_ERRORS && errors.length > 0 && (
        <div className={styles.error}>
          <Panel.Content withPadding>
            <div>{intl.formatMessage(messages.errors)}</div>
            {errors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </Panel.Content>
        </div>
      )}
      {uploadResult?.warning?.type === UploadResultWarningType.NOT_APPLICABLE &&
        uploadResult.warning.meta.users?.length && (
          <div className={styles.warning}>
            <Panel.Content withPadding>
              <div>{intl.formatMessage(messages.warning)}</div>
              <div>
                {intl.formatMessage(messages.warningNotApplicable, {
                  users: intl.formatList(
                    uploadResult.warning.meta.users.map((user) => (
                      <UserLink key={user.id} user={user} withAvatar={false} />
                    )),
                    {type: 'conjunction', style: 'long'},
                  ),
                })}
              </div>
            </Panel.Content>
          </div>
        )}
      {uploadResult?.status === BulkUploadStatus.CREATED && uploadResult.versionUploadRecord.record && (
        <Panel.Content withPadding>
          {intl.formatMessage(messages.success, {
            br: <br />,
            emphasis: (text: React.ReactNode) => <span className={styles.emphasis}>{text}</span>,
            date: <HumanDate value={uploadResult.versionUploadRecord.record.effectiveDate} format='long' utc />,
            comment: uploadResult.versionUploadRecord.record.comment || undefined,
          })}
        </Panel.Content>
      )}
      {!uploadResult && (
        <Panel.Content withPadding>
          <FileDropArea
            accept={ACCEPTED_MIME_TYPES}
            multiple={false}
            onChange={onChange}
            processing={dataState === DataState.LOADING}
          />
        </Panel.Content>
      )}
    </Panel>
  );
}
