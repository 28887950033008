import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Create a Resource',
    description: '[title] Resource creation dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Resource creation dialog',
    description: 'Resource creation dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Resource creation dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Create',
    description: '[button] Resource creation dialog: submit button',
  },
});
