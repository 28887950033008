import {defineMessages} from 'react-intl';
import {TimeOffType} from '.';

export const timeOffTypeMessages = defineMessages<TimeOffType>({
  [TimeOffType.AUTHORS_DAY_OFF]: {
    defaultMessage: 'Author’s day off',
    description: 'Time off type',
  },
  [TimeOffType.BLOOD_DONATION]: {
    defaultMessage: 'Blood donation day off',
    description: 'Time off type',
  },
  [TimeOffType.BUSINESS_TRIP]: {
    defaultMessage: 'Business trip',
    description: 'Time off type',
  },
  [TimeOffType.FAMILY_LEAVE]: {
    defaultMessage: 'Family leave',
    description: 'Time off type',
  },
  [TimeOffType.REMOTE_WORK]: {
    defaultMessage: 'Remote work',
    description: 'Time off type',
  },
  [TimeOffType.SEMI_SICK_LEAVE]: {
    defaultMessage: 'Semi-sick leave',
    description: 'Time off type',
  },
  [TimeOffType.SICK_LEAVE]: {
    defaultMessage: 'Sick leave',
    description: 'Time off type',
  },
  [TimeOffType.TEMPORARILY_RELOCATED]: {
    defaultMessage: 'Temporary relocation',
    description: 'Time off type',
  },
  [TimeOffType.UNPAID_VACATION]: {
    defaultMessage: 'Vacation at my own expense',
    description: 'Time off type',
  },
  [TimeOffType.VACATION]: {
    defaultMessage: 'Vacation',
    description: 'Time off type',
  },
  [TimeOffType.WORK_AT_WEEKEND]: {
    defaultMessage: 'Time off in exchange of a weekend',
    description: 'Time off type',
  },
  [TimeOffType.MATERNITY_LEAVE]: {
    defaultMessage: 'Maternity leave',
    description: 'Time off type',
  },
  [TimeOffType.STUDY_LEAVE]: {
    defaultMessage: 'Study leave',
    description: 'Time off type',
  },
  [TimeOffType.PARENTAL_LEAVE]: {
    defaultMessage: 'Parental leave',
    description: 'Time off type',
  },
});
