import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination, useOffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {StockOptionHolder} from 'domain/stockOption/holder/model';
import {SubordinateStockOptionHoldersFilters} from 'domain/stockOption/holder/model/filters';
import {getSubordinateStockOptionHoldersFx} from 'domain/stockOption/holder/stores/main';
import {
  $subordinateStockOptionHoldersList,
  $subordinateStockOptionHolders,
  $subordinateStockOptionHoldersCount,
} from 'domain/stockOption/holder/stores/main/state';
import {UserId} from 'domain/user/model';
import {useStore} from 'effector-react';
import {useEffect, useMemo} from 'react';
import {toaster} from 'services/toaster';

type PaginationOptions = {
  initialLimit: number;
  initialPage: number;
};

type ReturnShape = {
  stockOptionHolders: StockOptionHolder[];
  stockOptionHolderById: Record<UserId, StockOptionHolder | undefined>;
  stockOptionHoldersCount: number;
  dataState: DataState;
  pagination: OffsetPagination;
};

export function useSubordinateStockOptionHolders(
  filters: SubordinateStockOptionHoldersFilters = {},
  paginationOptions: PaginationOptions = {initialLimit: 25, initialPage: 1},
): ReturnShape {
  const {data: stockOptionHolders, dataState} = useStore($subordinateStockOptionHoldersList);
  const {count: stockOptionHoldersCount} = useStore($subordinateStockOptionHoldersCount);
  const {byId: stockOptionHolderById} = useStore($subordinateStockOptionHolders);
  const pagination = useOffsetPagination(paginationOptions);
  const pagingAndFilterOptions = useMemo(() => {
    return {
      offset: pagination.offset,
      limit: pagination.limit,
      filters,
    };
  }, [pagination.offset, pagination.limit, filters]);

  useEffect(() => {
    getSubordinateStockOptionHoldersFx(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
  }, [pagingAndFilterOptions]);

  return {
    stockOptionHolders,
    stockOptionHolderById,
    stockOptionHoldersCount,
    dataState,
    pagination,
  };
}
