import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Job information',
    description: '[title] User job panel: title',
  },
  btnEdit: {
    defaultMessage: 'Edit',
    description: '[button] User job panel: edit button',
  },
  tabJobInfo: {
    defaultMessage: 'Work history',
    description: '[title] User job panel: tab label',
  },
  tabEmployments: {
    defaultMessage: 'Employment',
    description: '[title] User job panel: tab label',
  },
  tabSalary: {
    defaultMessage: 'Compensation',
    description: '[title] User job panel: tab label',
  },
  tabRegularBonus: {
    defaultMessage: 'Bonus',
    description: '[title] User job panel: tab label',
  },
  tabMarketData: {
    defaultMessage: 'Function and level',
    description: '[title] User job panel: tab label',
  },
  tabOfficePolicy: {
    defaultMessage: 'Office policy',
    description: '[title] User job panel: tab label',
  },
  empty: {
    defaultMessage: 'No job information available',
    description: 'User job panel: empty message',
  },
});
