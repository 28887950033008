import {IntlShape} from 'react-intl';
import {ValidationError, ValidationFormatter} from '../../types';
import {urlErrorMessages} from './messages';
import {UrlValidatorErrorCode, UrlValidatorOptions} from './types';

type UrlValidationError = ValidationError<UrlValidatorErrorCode, UrlValidatorOptions>;

export const urlErrorFormatters: Record<UrlValidatorErrorCode, ValidationFormatter<UrlValidationError>> = {
  [UrlValidatorErrorCode.URL_INVALID_SCHEMA]: (error: UrlValidationError, intl: IntlShape) => {
    return intl.formatMessage(urlErrorMessages[UrlValidatorErrorCode.URL_INVALID_SCHEMA], {
      schemas: intl.formatList(error.options?.schemas || [], {type: 'disjunction'}),
    });
  },
  [UrlValidatorErrorCode.URL_INVALID_FORMAT]: (error: UrlValidationError, intl: IntlShape) => {
    return intl.formatMessage(urlErrorMessages[UrlValidatorErrorCode.URL_INVALID_FORMAT]);
  },
  [UrlValidatorErrorCode.URL_INVALID]: (error: UrlValidationError, intl: IntlShape) => {
    return intl.formatMessage(urlErrorMessages[UrlValidatorErrorCode.URL_INVALID]);
  },
};
