import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {SalaryRange} from 'domain/salaryRange/model';
import {getSalaryRangesFx} from 'domain/salaryRange/stores/main';
import {$salaryRanges} from 'domain/salaryRange/stores/main/state';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: SalaryRange[];
  dataState: DataState;
};

export function useSalaryRanges(): ReturnShape {
  const {data, dataState} = useStore($salaryRanges);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      getSalaryRangesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return {
    data,
    dataState,
  };
}
