import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No records',
    description: 'Office policy table: empty message',
  },
});

export const columnNames = defineMessages<Exclude<ColumnId, ColumnId.ACTIONS>>({
  [ColumnId.COMMENT]: {
    defaultMessage: 'Comment',
    description: 'Office policy table column name',
  },
  [ColumnId.EFFECTIVE_DATE]: {
    defaultMessage: 'Effective date',
    description: 'Office policy table column name',
  },
  [ColumnId.POLICY]: {
    defaultMessage: 'Policy',
    description: 'Office policy table column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Status',
    description: 'Office policy table column name',
  },
});
