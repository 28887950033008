import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {IntentionedText} from '@joomcode/joom-ui/IntentionedText';
import {FormattedTimeOffDays} from 'components/widgets/FormattedTimeOffDays';
import {TimeOffBalanceRecord} from 'domain/timeOff/balanceRecord/model';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {columnNames, messages} from './messages';
import styles from './styles.css';

type Props = {
  records: TimeOffBalanceRecord[];
};

export function BalanceRecordsTable({records}: Props) {
  const intl = useIntl();

  const getRowClassName = (timeOffBalanceRecord: TimeOffBalanceRecord) =>
    records.length && timeOffBalanceRecord.id === records[0].id ? '' : styles.inactiveRow;

  const columns: Column<TimeOffBalanceRecord>[] = useMemo(() => {
    return [
      {
        defaultWidth: 15,
        id: 'createdTime',
        name: intl.formatMessage(columnNames.createdTime),
        render: ({createdTime}) => <HumanDate value={createdTime} format='short' />,
      },
      {
        defaultWidth: 10,
        id: 'daysAdded',
        align: 'right',
        name: intl.formatMessage(columnNames.daysAdded),
        render: ({daysAdded}) => {
          const formattedDays = <FormattedTimeOffDays days={daysAdded} onlyNumber />;
          return daysAdded < 0 ? <IntentionedText intent='negative'>{formattedDays}</IntentionedText> : formattedDays;
        },
      },
      {
        defaultWidth: 10,
        id: 'totalBalance',
        align: 'right',
        name: intl.formatMessage(columnNames.totalBalance),
        render: ({totalBalance}) => <FormattedTimeOffDays days={totalBalance} onlyNumber />,
      },
      {
        defaultWidth: 65,
        id: 'reason',
        name: intl.formatMessage(columnNames.reason),
        render: ({reason}) => reason,
      },
    ];
  }, [intl]);

  return (
    <DataTable<TimeOffBalanceRecord>
      columns={columns}
      dataState={DataState.LOADED}
      data={records}
      emptyMessage={intl.formatMessage(messages.noBalanceRecords)}
      getRowClassName={getRowClassName}
      getRowKey={({id}) => id}
      storageName='timeOff.balanceRecords'
    />
  );
}
