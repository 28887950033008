import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.FULL_NAME_OFFER]: {
    defaultMessage: 'Full name (offer)',
    description: 'Offer table column name',
  },
  [ColumnId.FULL_NAME_APPLICATION]: {
    defaultMessage: 'Full name (application)',
    description: 'Offer table column name',
  },
  [ColumnId.DIVISION]: {
    defaultMessage: 'Business unit',
    description: 'Offer table column name',
  },
  [ColumnId.JOB_TITLE]: {
    defaultMessage: 'Job title',
    description: 'Offer table column name',
  },
  [ColumnId.START_DATE]: {
    defaultMessage: 'Start date',
    description: 'Offer table column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Status',
    description: 'Offer table column name',
  },
  [ColumnId.LOGIN]: {
    defaultMessage: 'Login',
    description: 'Offer table column name',
  },
  [ColumnId.LEGAL_ENTITY]: {
    defaultMessage: 'Legal entity',
    description: 'Offer table column name',
  },
  [ColumnId.REFERRERS]: {
    defaultMessage: 'Referral bonus',
    description: 'Offer table column name',
  },
  [ColumnId.COUNTRY]: {
    defaultMessage: 'Country',
    description: 'Offer table column name',
  },
});
