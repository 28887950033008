import {useDebouncedValue} from '@joomcode/deprecated-utils/react/useDebouncedValue';
import {PanelSearch} from 'components/ui/PanelSearch';
import React, {ChangeEvent, useCallback, useEffect} from 'react';

type Props = {
  onSearch: (searchQuery: string) => void;
  placeholder?: string;
  searchQuery: string;
};

export function PanelInstantSearch({onSearch, placeholder, searchQuery}: Props) {
  const debouncedQuery = useDebouncedValue<string>(searchQuery, 200);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      debouncedQuery.setValue(event.target.value);
    },
    [debouncedQuery.setValue],
  );

  const onClear = useCallback(() => {
    debouncedQuery.setValue('');
  }, []);

  useEffect(() => {
    onSearch(debouncedQuery.debouncedValue);
  }, [debouncedQuery.debouncedValue, onSearch]);

  useEffect(() => {
    debouncedQuery.setValue(searchQuery);
  }, [debouncedQuery.setValue, searchQuery]);

  return (
    <PanelSearch onChange={handleChange} onClear={onClear} placeholder={placeholder} value={debouncedQuery.value} />
  );
}
