import {CountryCode, compareCountryNames} from '@joomcode/deprecated-utils/countries';
import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import React, {useCallback, useMemo} from 'react';
import {IntlShape, useIntl} from 'react-intl';
import {Autocomplete, AutocompleteLocator} from '../Select/Autocomplete';
import {messages} from './messages';
import {Mark} from '../create-locator';

export type GenericRenderItem<Option> = (data: {code: Option; name: string}) => React.ReactNode;

export type CountrySelectProps<Option> = {
  autoComplete?: string | null;
  clearable?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange: (countryCode: Option | null) => void;
  onFocus?: () => void;
  options?: Option[];
  value: Option | null;
  renderItem?: GenericRenderItem<Option>;
  // Sort options before rendering. By default, options are sorted alphabetically.
  sortOptions?: <Code extends string = CountryCode>(a: Code, b: Code, intl: IntlShape) => number;
} & Partial<Mark<AutocompleteLocator>>;

export function GenericCountrySelect<Option extends string = CountryCode>({
  autoComplete,
  clearable,
  disabled,
  invalid,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  renderItem,
  value,
  sortOptions = compareCountryNames,
  ...rest
}: CountrySelectProps<Option>) {
  const intl = useIntl();
  const sortedOptions = useMemo<Option[]>(
    () => [...(options ?? [])].sort((a, b) => sortOptions(a, b, intl)),
    [options, sortOptions, intl],
  );

  const getNameByCode = useCallback((code: Option) => intl.formatDisplayName(code, {type: 'region'}) ?? code, [intl]);

  const filter = useCallback(
    (code: Option, query: string) => getNameByCode(code).toLowerCase().includes(query.toLowerCase()),
    [getNameByCode],
  );

  const sort = useCallback(
    (a: Option, b: Option, query: string) => {
      const nameA = getNameByCode(a).toLowerCase();
      const nameB = getNameByCode(b).toLowerCase();
      const lowerCasedQuery = query.toLowerCase();
      const aStartsWithQuery = nameA.startsWith(lowerCasedQuery);
      const bStartsWithQuery = nameB.startsWith(lowerCasedQuery);

      if (aStartsWithQuery && !bStartsWithQuery) {
        // a goes first
        return -1;
      }
      if (!aStartsWithQuery && bStartsWithQuery) {
        // b goes first
        return 1;
      }
      // in this case both a and b start or don't start with query, so we compare them alphabetically
      return sortOptions(a, b, intl);
    },
    [getNameByCode, sortOptions, intl],
  );

  const renderCountry = useCallback(
    (code: Option) => {
      const nameInner = getNameByCode(code);
      if (renderItem === undefined) {
        return nameInner;
      }
      return renderItem({code, name: nameInner});
    },
    [getNameByCode, renderItem],
  );

  return (
    <Autocomplete<Option>
      autoComplete={autoComplete}
      name={name}
      disabled={disabled}
      invalid={invalid}
      clearable={clearable}
      onFocus={onFocus}
      onBlur={onBlur}
      noItemsText={intl.formatMessage(messages.noItemsText)}
      filter={filter}
      sort={sort}
      items={sortedOptions}
      getItemKey={identity}
      itemToString={getNameByCode}
      renderItem={renderCountry}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
}

/**
 * Export easy to use CountrySelect that is based on the CountryCode enum
 */

const DEFAULT_OPTIONS = getEnumValues(CountryCode);

export type RenderItem = GenericRenderItem<CountryCode>;

export function CountrySelect(props: CountrySelectProps<CountryCode>) {
  return <GenericCountrySelect<CountryCode> {...props} options={props.options ?? DEFAULT_OPTIONS} />;
}
