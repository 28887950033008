import {omit} from '@joomcode/deprecated-utils/object/omit';
import {pick} from '@joomcode/deprecated-utils/object/pick';
import {SideMenuItemProps} from './types';

type SplittedProps<Props> = {
  itemProps: SideMenuItemProps;
  nativeProps: Omit<Props, keyof SideMenuItemProps>;
};

export function splitProps<Props extends SideMenuItemProps>(props: Props): SplittedProps<Props> {
  const propNames: (keyof SideMenuItemProps)[] = [
    'addon',
    'badge',
    'children',
    'disabled',
    'icon',
    'iconRight',
    'isChildrenActive',
    'isActive',
    'isCompact',
    'isOpen',
    'subItems',
    'tooltip',
  ];

  return {
    itemProps: pick(props, propNames),
    nativeProps: omit(props, propNames),
  };
}
