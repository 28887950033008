import {DataState} from '@joomcode/deprecated-utils/dataState';
import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {Offer} from 'domain/offer/model';
import {createStore} from 'effector';
import {
  approveNewcomerApplicationFx,
  createOfferFx,
  enhanceOfferFx,
  getOfferByIdFx,
  getOffersFx,
  getOffersWithCountFx,
  setOfferAcceptedFx,
  setOfferDeclinedFx,
  updateGeneralInfoFx,
  updateNewcomerLoginFx,
  updateNewcomerStartWorkingDateFx,
  updateOfferFx,
} from '.';

type OffersCountState = {
  count: number;
};

export const $offers = createEntityStore({
  getEntityId: (offer: Offer) => offer.id,
  loadByIdFx: getOfferByIdFx,
  query: {
    fx: getOffersFx,
    getIdsFromParams: () => [],
  },
})
  .on(
    [
      approveNewcomerApplicationFx.doneData,
      createOfferFx.doneData,
      enhanceOfferFx.doneData,
      setOfferAcceptedFx.doneData,
      setOfferDeclinedFx.doneData,
      updateOfferFx.doneData,
      updateGeneralInfoFx.doneData,
    ],
    (state, result) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [result.id]: result,
        },
        byIdState: {
          ...state.byIdState,
          [result.id]: DataState.LOADED,
        },
      };
    },
  )
  .on(
    [updateNewcomerLoginFx.done, updateNewcomerStartWorkingDateFx.done],
    (state, {params: {offerId}, result: newcomerInOffer}) => {
      const storedOffer = state.byId[offerId];
      if (!storedOffer) {
        return state;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [offerId]: {
            ...storedOffer,
            newcomer: newcomerInOffer,
          },
        },
      };
    },
  );

export const $offerList = createEntityListStore($offers);
export const $offersCount = createStore<OffersCountState>({
  count: 0,
}).on(getOffersWithCountFx.done, (state, {result}) => {
  return {
    ...state,
    count: result.count,
  };
});
