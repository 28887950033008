import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Members',
    description: '[title] Team members panel: title',
  },
  noMembers: {
    defaultMessage: 'No members',
    description: 'Team members panel: empty message',
  },
  btnRequestMembership: {
    defaultMessage: 'Add member or role',
    description: '[button] Team members panel: membership request button',
  },
  revokeConfirmationTitle: {
    defaultMessage: 'Revoke role',
    description: '[title] Revoke role confirmation: title',
  },
  revokeConfirmationText: {
    defaultMessage:
      'Are you sure you want to revoke the <emphasis>{roleName}</emphasis> role in team <emphasis>{teamName}</emphasis> from <emphasis>{memberName}</emphasis>?',
    description: 'Revoke role confirmation: text',
  },
  revokeConfirmationButton: {
    defaultMessage: 'Revoke',
    description: '[button] Revoke role confirmation: button',
  },
  revokeTooltip: {
    defaultMessage: 'Revoke role',
    description: 'Role revoke icon: tooltip',
  },
});
