import {DataState} from '@joomcode/deprecated-utils/dataState';
import {LegalEntity} from 'domain/legalEntity/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {getMainLegalEntityFx} from '.';

type State = {
  byUserId: Record<UserId, LegalEntity | undefined>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $userMainLegalEntities = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on(getMainLegalEntityFx, (state, userId) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on(getMainLegalEntityFx.fail, (state, {params: userId}) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.FAILED,
      },
    };
  })
  .on(getMainLegalEntityFx.done, (state, {params: userId, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: result ?? undefined,
      },
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADED,
      },
    };
  });
