import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {Feature, useFeature} from 'services/features';

export function useSubordinateTableAvailabitilty() {
  const selfUser = useSelfUser();
  const subordinateInformationFeature = useFeature(Feature.SUBORDINATE_INFORMATION);
  const isManager = Boolean(selfUser?.subordinates && selfUser.subordinates.length > 0);

  return isManager && subordinateInformationFeature.isAvailable;
}
