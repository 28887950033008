import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  createHint: {
    defaultMessage: 'Set freeze date',
    description: 'Freeze stock option agreement button hint',
  },
  updateHint: {
    defaultMessage: 'Update freeze date',
    description: 'Freeze stock option agreement button hint',
  },
});
