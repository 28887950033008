import {defineMessages} from 'react-intl';
import {TabId} from '.';

export const tabNames = defineMessages<TabId>({
  [TabId.ANY]: {
    defaultMessage: 'All requests',
    description: 'Compensation review requests tab name',
  },
  [TabId.MY]: {
    defaultMessage: 'My requests',
    description: 'Compensation review requests tab name',
  },
  [TabId.SUBORDINATE]: {
    defaultMessage: 'My report requests',
    description: 'Compensation review requests tab name',
  },
});

export const tabAlternativeNames = defineMessages<Extract<TabId, TabId.ANY>>({
  [TabId.ANY]: {
    defaultMessage: 'All compensation review requests',
    description: 'Compensation review requests tab name',
  },
});
