import {create} from './create';
import {deleteRecord} from './delete';
import {buildDownloadAllLink} from './downloadAll';
import {buildDownloadSubordinatesLink} from './downloadSubordinates';
import {getAll} from './getAll';
import {getMy} from './getMy';
import {getSubordinate} from './getSubordinate';
import {update} from './update';

export const marketDataApi = {
  buildDownloadAllLink,
  buildDownloadSubordinatesLink,
  create,
  delete: deleteRecord,
  getAll,
  getMy,
  getSubordinate,
  update,
};
