import {QueryRequestPayload} from 'api/types';
import {CountableRoleBindigns, RoleBinding, RoleBindingsSearchFilters} from 'domain/roleBinding/model';
import {createEffect} from 'effector';

export const getActiveRoleBindingsWithCountFx = createEffect<
  QueryRequestPayload<RoleBindingsSearchFilters>,
  CountableRoleBindigns
>('load approved role bindings with pagination and count');
export const getActiveRoleBindingsFx = createEffect<QueryRequestPayload<RoleBindingsSearchFilters>, RoleBinding[]>(
  'load approved role bindings with pagination',
);
