import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {CompensationRequestId} from 'domain/compensations/request/model';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {approveRequestAsAdminFx, approveRequestAsManagerFx} from 'domain/compensations/request/stores/main';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  id: CompensationRequestId;
  status: CompensationRequestStatus;
};

export function CompensationRequestApproveButton({id, status}: Props) {
  const intl = useIntl();
  const action = useMemo(
    () => (status === CompensationRequestStatus.MANAGER_APPROVED ? approveRequestAsAdminFx : approveRequestAsManagerFx),
    [status],
  );

  const approve = useAsyncTask(
    () =>
      action(id)
        .then(() => toaster.success(intl.formatMessage(messages.success)))
        .catch(toaster.interceptThenThrowError),
    [id, intl, action],
  );

  return (
    <Button intent='primary' kind='primary' loading={approve.isPerforming} onClick={approve.perform} size='m'>
      {intl.formatMessage(messages.button)}
    </Button>
  );
}
