import {array, ExtractSchemaType, number, object, oneOfEnum, string} from '@joomcode/deprecated-utils/jsonValidation';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export enum StockOptionBulkUploadRowStatus {
  ERROR = 'error',
  SUCCESS = 'success',
  NOT_CHANGED = 'notChanged',
}

export const stockOptionBulkUploadMetaSchema = object({
  errors: optionalWithDefault([], array(string())),
  row: number(),
  status: oneOfEnum(StockOptionBulkUploadRowStatus),
});

export type StockOptionBulkUploadMeta = ExtractSchemaType<typeof stockOptionBulkUploadMetaSchema>;
