import {useCallback, useMemo, useRef, useState} from 'react';
import {UnregisterCallback} from 'history';
import {useHistory} from 'react-router-dom';
import {defineMessages, useIntl} from 'react-intl';

const messages = defineMessages({
  leaveWarning: {
    id: 'joom-ui-page-locker-leave-warning',
    description: 'Warning message when user try to leave the page',
    defaultMessage: 'You are trying to leave the page without saving the changes. All changes will be lost.',
  },
});

export type PageLocker = {
  lock: () => void;
  unlock: () => void;
  locked: boolean;
};

export const usePageLocker = (confirmationMessage?: string): PageLocker => {
  const [locked, setLocked] = useState(false);
  const history = useHistory();
  const unblock = useRef<UnregisterCallback | null>(null);
  const intl = useIntl();

  if (!confirmationMessage) {
    // eslint-disable-next-line no-param-reassign
    confirmationMessage = intl.formatMessage(messages.leaveWarning);
  }

  const listener = useCallback((e: BeforeUnloadEvent) => {
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }, []);

  const lock = useCallback(() => {
    if (locked) {
      return;
    }

    unblock.current = history.block(confirmationMessage);
    window.addEventListener('beforeunload', listener);
    setLocked(true);
  }, [locked]);

  const unlock = useCallback(() => {
    if (unblock.current) {
      unblock.current();
    }

    window.removeEventListener('beforeunload', listener);
    setLocked(false);
  }, []);

  const pageLocker = useMemo<PageLocker>(() => ({lock, unlock, locked}), [locked]);

  return pageLocker;
};
