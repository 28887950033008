import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {JobContract, jobContractSchema} from 'domain/jobContract/model';
import {JobContractRecordId} from 'domain/jobContract/model/record';
import {JobContractRecordDiff} from 'domain/jobContract/model/record/diff';
import {UserId} from 'domain/user/model';

export type UpdateJobContractRecordConfig = JobContractRecordDiff & {
  id: JobContractRecordId;
  userId: UserId;
};

export const updateRecord = async ({id, userId, ...body}: UpdateJobContractRecordConfig): Promise<JobContract[]> => {
  const {data} = await intranetApi.post('/v1/users/jobContractRecords/update', body, {params: {id, userId}});

  return array(jobContractSchema).runWithException(data);
};
