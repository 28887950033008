import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  annotation: {
    defaultMessage: 'Click to copy',
    description: 'Copy button tooltip annotation',
    id: 'copy-annotation',
  },
  copied: {
    defaultMessage: 'Successfully copied',
    description: 'Successful copy annotation',
    id: 'copy-copied',
  },
});
