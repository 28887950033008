import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonHint: {
    defaultMessage: 'Send agreement to holder',
    description: 'Send stock option agreement button hint',
  },
  noLegalInfoHint: {
    defaultMessage: 'Sending agreement to holder is unavailable: Joomer‘s legal data is not filled.',
    description: 'Send stock option agreement button: disabled hint',
  },
  confirmationTitle: {
    defaultMessage: 'Send option agreement',
    description: '[title] Stock option agreement sending confirmation dialog: dialog title',
  },
  confirmationText: {
    defaultMessage: 'Are you sure you want to send option agreement to {email}?',
    description: 'Stock option agreement sending confirmation dialog: dialog text',
  },
  confirmationButton: {
    defaultMessage: 'Send',
    description: '[button] Stock option agreement sending confirmation dialog: confirmation button',
  },
  success: {
    defaultMessage: 'Option agreement has been sent to {email}',
    description: 'Stock option agreement successful sending text',
  },
});
