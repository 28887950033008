import {FieldCountry, FieldInputNumber, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Grid} from '@joomcode/joom-ui/Grid';
import {ReactComponent as TrashIcon} from '@joomcode/joom-ui/icons/core/trash.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {CalculatableCrBadge} from 'domain/compaRatio/widgets/CalculatableBadge';
import {CountryCode} from 'domain/countryCode/model';
import {FieldCurrency} from 'domain/currency/widgets/Field';
import {frequencyBySalaryRangeCountry} from 'domain/frequency/model';
import {FieldFrequency} from 'domain/frequency/widgets/Field';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {OfferEmploymentInfo} from 'domain/offer/model/employmentInfo';
import {employmentInfoFieldLabels} from 'domain/offer/model/employmentInfo/messages';
import {furtherSalaryFieldLabels} from 'domain/offer/model/furtherSalary/messages';
import {FurtherSalaryReason} from 'domain/offer/model/furtherSalary/reason';
import {FieldFurtherSalaryReason} from 'domain/offer/widgets/FieldFurtherSalaryReason';
import {OfferFormSectionTitle} from 'domain/offer/widgets/FormSectionTitle';
import {SecurePermission} from 'domain/permission/model/secure';
import {FieldSalaryAmount} from 'domain/salaryRecord/widgets/FieldAmount';
import React, {useCallback} from 'react';
import {useForm} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';
import {fieldLabels, messages} from './messages';
import styles from './styles.css';

type Props = {
  employmentInfo?: OfferEmploymentInfo;
  compensation?: OfferCompensation;
  onRemove(): void;
};

export function FurtherSalaryFields({employmentInfo, compensation, onRemove}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {change, getState} = useForm();
  const reason = getState().values.compensation?.furtherSalary?.reason;
  const offerCrFeature = useFeature(Feature.OFFER_CR);
  const showCrBadge = offerCrFeature.isAvailable && acl.hasSecurePermission(SecurePermission.SALARY_RANGE_CALCULATE_CR);

  const getValuesForCalculating = useCallback(() => {
    const {values} = getState();
    const amount = values.compensation?.furtherSalary?.salary?.money?.amount;
    const currency = values.compensation?.furtherSalary?.salary?.money?.currency;
    const frequency = values.compensation?.furtherSalary?.salary?.frequency;
    const levelCode = values.compensation?.levelCode;
    const userFunction = values.compensation?.userFunction;
    const region =
      values.compensation?.furtherSalary?.reason === FurtherSalaryReason.RELOCATION
        ? values.employmentInfo?.relocationCountry
        : values.employmentInfo?.employmentCountry;

    if (!amount || !currency || !frequency || !levelCode || !userFunction || !region) {
      return undefined;
    }

    return {
      amount,
      currency,
      frequency,
      levelCode,
      userFunction,
      region,
    };
  }, [getState]);

  return (
    <>
      <OfferFormSectionTitle title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item sm={12} xl={6}>
            <FieldFurtherSalaryReason
              name='compensation.furtherSalary.reason'
              label={intl.formatMessage(furtherSalaryFieldLabels.reason)}
              required
              validate={validateFilled}
              reserveSpaceForError={false}
              initialValue={compensation?.furtherSalary?.reason}
            />
          </Grid.Item>
          <Grid.Item sm={12} xl={6}>
            <FieldInputNumber
              name='compensation.furtherSalary.employmentRate'
              label={intl.formatMessage(furtherSalaryFieldLabels.employmentRate)}
              required
              initialValue={compensation?.furtherSalary?.employmentRate ?? 1}
              reserveSpaceForError={false}
              min={0}
              step={0.1}
              minExcluded
            />
          </Grid.Item>
          {reason === FurtherSalaryReason.RELOCATION && (
            <Grid.Item xl={12}>
              <FieldCountry
                name='employmentInfo.relocationCountry'
                label={intl.formatMessage(employmentInfoFieldLabels.relocationCountry)}
                required
                reserveSpaceForError={false}
                initialValue={employmentInfo?.relocationCountry}
              />
            </Grid.Item>
          )}
          <Grid.Item xl={12}>
            <Grid>
              <Grid.Item xl={4} sm={6}>
                <FieldSalaryAmount
                  name='compensation.furtherSalary.salary.money.amount'
                  label={intl.formatMessage(fieldLabels.furtherSalaryAmount)}
                  required
                  validate={validateFilled}
                  reserveSpaceForError={false}
                  initialValue={compensation?.furtherSalary?.salary.money.amount}
                />
              </Grid.Item>
              <Grid.Item xl={4} sm={6}>
                <FieldCurrency
                  name='compensation.furtherSalary.salary.money.currency'
                  label={intl.formatMessage(fieldLabels.furtherSalaryCurrency)}
                  required
                  validate={validateFilled}
                  reserveSpaceForError={false}
                  initialValue={compensation?.furtherSalary?.salary.money.currency}
                />
              </Grid.Item>
              <Grid.Item xl={4} sm={6}>
                <FieldFrequency
                  name='compensation.furtherSalary.salary.frequency'
                  label={intl.formatMessage(fieldLabels.furtherSalaryFrequency)}
                  required
                  validate={validateFilled}
                  reserveSpaceForError={false}
                  initialValue={compensation?.furtherSalary?.salary.frequency}
                />
                <OnChange name='employmentInfo.relocationCountry'>
                  {(relocationCountry: CountryCode) => {
                    const frequency = frequencyBySalaryRangeCountry[relocationCountry];
                    if (frequency && !getState().values.compensation?.furtherSalary?.salary?.frequency) {
                      change('compensation.furtherSalary.salary.frequency', frequency);
                    }
                  }}
                </OnChange>
              </Grid.Item>
            </Grid>
          </Grid.Item>
          {reason === FurtherSalaryReason.OTHER && (
            <Grid.Item xl={12}>
              <FieldTextarea
                name='compensation.furtherSalary.reasonDetails'
                label={intl.formatMessage(furtherSalaryFieldLabels.reasonDetails)}
                hint={intl.formatMessage(messages.reasonDetailsHint)}
                required
                validate={validateFilled}
                reserveSpaceForError={false}
                initialValue={compensation?.furtherSalary?.reasonDetails}
              />
            </Grid.Item>
          )}
          {showCrBadge && (
            <Grid.Item xl={12}>
              <CalculatableCrBadge
                fieldNames={[
                  'compensation.furtherSalary.salary.money.amount',
                  'compensation.furtherSalary.salary.money.currency',
                  'compensation.furtherSalary.salary.frequency',
                  'compensation.levelCode',
                  'compensation.userFunction',
                  'employmentInfo.employmentCountry',
                  'employmentInfo.relocationCountry',
                ]}
                debouncedFieldNames={['compensation.furtherSalary.salary.money.amount']}
                getValuesForCalculating={getValuesForCalculating}
              />
            </Grid.Item>
          )}
        </Grid>
        <div className={styles.button}>
          <Button kind='secondary' intent='neutral' size='m' iconLeft={<TrashIcon />} onClick={onRemove}>
            {intl.formatMessage(messages.buttonRemove)}
          </Button>
        </div>
      </Panel.Content>
    </>
  );
}
