import {createMockId} from '@joomcode/deprecated-utils/joomId/mock';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import addDays from 'date-fns/addDays';
import {CountryCode} from 'domain/countryCode/model';
import {Currency} from 'domain/currency/model';
import {Frequency} from 'domain/frequency/model';
import {mockLegalEntity} from 'domain/legalEntity/model/mock';
import faker from 'faker';
import {dateToString} from 'models/system/formattedDate';
import {ExtendedSalaryRecord, SalaryRecord} from '.';

const countryCodes = getEnumValues(CountryCode);
const currencies = getEnumValues(Currency);
const frequencies = getEnumValues(Frequency);

export function mockSalaryRecord(data: Partial<SalaryRecord> = {}): SalaryRecord {
  const startDate = Math.random() > 0.5 ? faker.date.past() : faker.date.future();

  return {
    active: faker.random.boolean(),
    amount: faker.random.number({min: 10, max: 1000}) * 1000,
    comment: faker.company.bs(),
    currency: faker.random.arrayElement(currencies),
    employmentRate: faker.random.number({min: 0, max: 1, precision: 0.1}),
    endDate:
      Math.random() > 0.5 ? dateToString(addDays(startDate, faker.random.number({min: 0, max: 1000}))) : undefined,
    frequency: faker.random.arrayElement(frequencies),
    id: createMockId(),
    legalEntity: mockLegalEntity(),
    reason: faker.company.bs(),
    region: faker.random.arrayElement(countryCodes),
    startDate: dateToString(startDate),
    ...data,
  };
}

export function mockExtendedSalaryRecord(data: Partial<ExtendedSalaryRecord> = {}): ExtendedSalaryRecord {
  const startDate = Math.random() > 0.5 ? faker.date.past() : faker.date.future();

  return {
    active: faker.random.boolean(),
    amount: faker.random.number({min: 10, max: 1000}) * 1000,
    comment: faker.company.bs(),
    currency: faker.random.arrayElement(currencies),
    employmentRate: faker.random.number({min: 0, max: 1, precision: 0.1}),
    endDate:
      Math.random() > 0.5 ? dateToString(addDays(startDate, faker.random.number({min: 0, max: 1000}))) : undefined,
    frequency: faker.random.arrayElement(frequencies),
    id: createMockId(),
    legalEntity: mockLegalEntity(),
    reason: faker.company.bs(),
    region: faker.random.arrayElement(countryCodes),
    specialArrangements: faker.company.bs(),
    startDate: dateToString(startDate),
    ...data,
  };
}
