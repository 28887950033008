import cn from 'classnames';
import React, {forwardRef} from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../../create-locator';
import styles from './index.css';

export type NavbarButtonTestId = ConvertLocatorToTestId<NavbarButtonLocator>;
export type NavbarButtonLocator = LocatorOfElement<void>;

type Props = Partial<Mark<NavbarButtonLocator>> & Omit<JSX.IntrinsicElements['button'], 'className'>;

export const Button = forwardRef<HTMLButtonElement, Props>(({...propertiesWithMark}, ref) => {
  const locator = createLocator(propertiesWithMark);
  const props = removeMarkFromProperties(propertiesWithMark);

  return (
    <div className={cn(styles.item, styles.itemButton)}>
      <button type='button' {...props} className={styles.button} ref={ref} {...locator()} />
    </div>
  );
});
