import React from 'react';
import type {ConvertLocatorToTestId, LocatorOfElement, Mark} from '../create-locator';

export type ButtonSize = 's' | 'm' | 'l' | 'xl';

export const buttonSizes: ButtonSize[] = ['s', 'm', 'l', 'xl'];

export const defaultButtonSize: ButtonSize = 'm';

export type ButtonIntent =
  | 'ghost'
  | 'negative'
  | 'neutralGray'
  | 'neutral' // deprecated
  | 'invertedNeutral' // deprecated
  | 'positive'
  | 'primary'
  | 'warning';

export const buttonIntents: ButtonIntent[] = ['primary', 'warning', 'negative', 'positive', 'neutralGray', 'ghost'];
export const deprecatedButtonIntents: ButtonIntent[] = ['neutral', 'invertedNeutral'];

export type ButtonKind = 'primary' | 'secondary' | 'outline' | 'text';

export const buttonKinds: ButtonKind[] = ['primary', 'secondary', 'outline', 'text'];

export type ButtonTestId = ConvertLocatorToTestId<ButtonLocator>;
export type ButtonLocator = LocatorOfElement<void>;

export type BaseButtonProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  intent: ButtonIntent;
  kind: ButtonKind;
  loading?: boolean;
  /**
   * For cases when the button text should be vertically aligned with another text above or below button
   */
  noHorizontalPadding?: boolean;
  size?: ButtonSize;
  minWidth?: string;
  withShrink?: boolean;
} & Partial<Mark<ButtonLocator>>;
