import React from 'react';
import {ToastType} from './types';

export type ToastContextProps = {
  type: ToastType;
};

export const ToastContext = React.createContext<ToastContextProps>({
  type: 'info',
});
