import {identity} from '@joomcode/deprecated-utils/function/identity';
import {Textarea} from '@joomcode/joom-ui/Textarea';
import {EditableValue} from 'components/ui/EditableValue';
import {MultilineText} from 'components/ui/MultilineText';
import {UpdateCommentFromAdminDiff} from 'domain/compensations/request/api/updateCommentFromAdmin';
import {CompensationRequestId} from 'domain/compensations/request/model';
import {updateCommentFromAdminFx} from 'domain/compensations/request/stores/main';
import {Permission} from 'domain/permission/model';
import React, {useCallback} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  id: CompensationRequestId;
  value: string | undefined;
};

export function CommentFromAdmin({id, value}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canEdit = acl.hasPermission(Permission.COMPENSATION_WRITE_ADMIN);
  const onSubmit = useCallback(
    (diff: UpdateCommentFromAdminDiff) => updateCommentFromAdminFx({id, diff}).catch(toaster.interceptThenThrowError),
    [id],
  );

  return (
    <Form<UpdateCommentFromAdminDiff> onSubmit={onSubmit}>
      {({handleSubmit, submitting}) => {
        const disabled = !canEdit || submitting;
        return (
          <Field name='commentFromAdmin' initialValue={value} parse={identity}>
            {({input}) => (
              <EditableValue<string | undefined>
                onSubmit={handleSubmit}
                disableSubmit={disabled}
                disableEdit={disabled}
                processing={submitting}
                value={value}
                renderValue={(value) =>
                  value ? (
                    <MultilineText text={value} />
                  ) : (
                    <span className={styles.empty}>{intl.formatMessage(messages.emptyComment)}</span>
                  )
                }
              >
                <Textarea autoComplete='off' disabled={disabled} rows={2} {...input} />
              </EditableValue>
            )}
          </Field>
        );
      }}
    </Form>
  );
}
