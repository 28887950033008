import uuid from 'uuid/v1';
import React, {useState, useMemo} from 'react';
import {createLocator} from '../../../create-locator';
import {Radio} from '../../../Radio';
import {RemoveAutoScrollInside} from '../../../RemoveAutoScrollInside';
import {RemoveAutoFocusInside} from '../../../RemoveAutoFocusInside';
import {RadioGroup} from '../../../RadioGroup';
import {Control} from '../../Control';
import {ControlRenderProps, Value} from '../../types';
import {SelectFilterOptions} from './types';

type Props<T> = ControlRenderProps<T, SelectFilterOptions<T>>;

export function SelectControl<T extends string>({name, value, onSubmit, onClose, options}: Props<T>) {
  const id = useMemo(uuid, []);
  const locator = createLocator(options);
  const [localValue, setLocalValue] = useState<Value<T>>(value);

  return (
    <Control onClose={onClose} onSubmit={() => onSubmit(localValue)} {...locator.control()}>
      <RemoveAutoFocusInside>
        <RemoveAutoScrollInside>
          <RadioGroup ariaLabel={name}>
            {options.options.map(({label, value: optionValue}) => (
              <Radio
                key={optionValue}
                value={optionValue}
                name={id}
                label={label}
                checked={optionValue === localValue}
                onChange={(e) => setLocalValue(e.currentTarget.value as Value<T>)}
                {...locator.radio()}
              />
            ))}
          </RadioGroup>
        </RemoveAutoScrollInside>
      </RemoveAutoFocusInside>
    </Control>
  );
}
