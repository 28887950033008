import {NewcomerFormTemplateCountry} from 'domain/offer/model/templateCountry';
import {defineMessages, MessageDescriptor} from 'react-intl';

export const messages = defineMessages({
  jobContract: {
    defaultMessage: 'Job contract',
    description: '[title] Newcomer documents form section title',
  },
  furtherJobContract: {
    defaultMessage: 'Further job contract',
    description: '[title] Newcomer documents form section title',
  },
  furtherJobContractHint: {
    defaultMessage: 'Will not be added to the profile automatically',
    description: '[title] Newcomer documents form section title',
  },
  personalData: {
    defaultMessage: 'Personal data',
    description: '[title] Newcomer documents form section title',
  },
  internationalPassport: {
    defaultMessage: 'International passport',
    description: '[title] Newcomer documents form section title',
  },
  localId: {
    defaultMessage: 'Local passport (ID)',
    description: '[title] Newcomer documents form section title',
  },
  localIdAddress: {
    defaultMessage: 'Local passport address page',
    description: '[title] Newcomer documents form section title',
  },
  placeOfCurrentResidence: {
    defaultMessage: 'Place of current residence',
    description: '[title] Newcomer documents form section title',
  },
  internationalPassportPreviewAlt: {
    defaultMessage: 'International passport preview',
    description: 'Newcomer document preview alt text',
  },
  localIdPreviewAlt: {
    defaultMessage: 'Local passport (ID) preview',
    description: 'Newcomer document preview alt text',
  },
  localIdAddressPreviewAlt: {
    defaultMessage: 'Local passport address page preview',
    description: 'Newcomer document preview alt text',
  },
});

export const messagesByCountry: Partial<
  Record<NewcomerFormTemplateCountry, Partial<Record<string, MessageDescriptor>>>
> = {
  [NewcomerFormTemplateCountry.PORTUGAL]: defineMessages({
    localId: {
      defaultMessage: 'Citizen or residence card or visa',
      description: '[title] Portugal: Newcomer documents form section title',
    },
  }),
};
