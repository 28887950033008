import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OfferId} from 'domain/offer/model';
import {createStore} from 'effector';
import {getOfferCompensationsCommentFx} from '.';

type State = {
  byOfferId: Record<OfferId, string | undefined>;
  byOfferIdState: Record<OfferId, DataState>;
};

export const $offerCompensationsComment = createStore<State>({
  byOfferId: {},
  byOfferIdState: {},
})
  .on(getOfferCompensationsCommentFx, (state, {id}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.LOADING,
    },
  }))
  .on(getOfferCompensationsCommentFx.fail, (state, {params: {id}}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.FAILED,
    },
  }))
  .on(getOfferCompensationsCommentFx.done, (state, {params: {id}, result}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.LOADED,
    },
    byOfferId: {
      ...state.byOfferId,
      [id]: result.comment,
    },
  }));
