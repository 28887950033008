import {Button} from '@joomcode/joom-ui/Button';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {OfferId} from 'domain/offer/model';
import {setOfferDeclinedFx} from 'domain/offer/stores/main';
import {OfferDeclineForm} from 'domain/offer/widgets/DeclineForm';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  offerId: OfferId;
  onClose(): void;
};

type FormState = {
  reasons?: string[];
};

export function OfferDeclineDialog({offerId, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  const onSubmit = useCallback(
    ({reasons}: FormState) => {
      if (!reasons || !reasons.length) {
        return Promise.reject().catch(() => toaster.error(intl.formatMessage(messages.error)));
      }
      return setOfferDeclinedFx({id: offerId, reasons}).then(onClose).catch(toaster.interceptThenThrowError);
    },
    [onClose, offerId],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 24rem)'>
      <Form<FormState> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <OfferDeclineForm formId={formId} onSubmit={handleSubmit} />
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                form={formId}
                size='l'
                kind='primary'
                intent='primary'
                type='submit'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.buttonSubmit)}
              </Button>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
