import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Textarea, TextareaProps} from '@joomcode/joom-ui/Textarea';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';
import {useFieldWithInitialValue} from '../../components';
import {getFieldErrorText} from '../../utils';
import {composeValidators, getFieldValidator, validateFilled} from '../../validation';
import {CommonFieldProps} from '../types';

export type FieldTextareaProps = Omit<TextareaProps, 'label'> &
  CommonFieldProps<string> & {
    parse?: (value: string, name: string) => string;
  };

export const FieldTextarea = memo(function FieldTextarea({
  disabled,
  error,
  hint,
  initialValue,
  label,
  labelHint,
  name,
  required,
  reserveSpaceForError,
  rows = 3,
  validate,
  validateFields,
  extraErrorMessages,
  parse,
  ...textareaProps
}: FieldTextareaProps) {
  const intl = useIntl();

  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {input, meta} = useFieldWithInitialValue<string>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    validateFields,
    parse,
  });

  return (
    <FormControl
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      hint={hint}
      label={label}
      labelHint={labelHint}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <Textarea
          {...formControlProps}
          {...input}
          rows={rows}
          {...textareaProps}
          disabled={meta.submitting || disabled}
        />
      )}
    </FormControl>
  );
});
