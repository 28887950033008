import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  placeOnOfficeMap: {
    defaultMessage: 'On the office map',
    description: 'User properties: user seat link',
  },
});

export const labels = defineMessages({
  phone: {
    defaultMessage: 'Phone number',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  slack: {
    defaultMessage: 'Slack',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  email: {
    defaultMessage: 'Email',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  seat: {
    defaultMessage: 'Place',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  location: {
    defaultMessage: 'Office',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  github: {
    defaultMessage: 'GitHub',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  legalEntity: {
    defaultMessage: 'Legal entity',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  hireDate: {
    defaultMessage: 'Joined',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  countryOfTimeOffPolicy: {
    defaultMessage: 'Work calendar',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  officePolicy: {
    defaultMessage: 'Office policy',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  monthAndDayOfBirth: {
    defaultMessage: 'Birthday',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  shirtSize: {
    defaultMessage: 'T-shirt',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  primaryLanguage: {
    defaultMessage: 'Language',
    description: 'User property label (13+ chars long requires a visual review)',
  },
  otherLanguages: {
    defaultMessage: 'Other languages',
    description: 'User property label (13+ chars long requires a visual review)',
  },
});
