import {officePolicyRecordsApi} from 'domain/officePolicyRecord/api';
import {
  createOfficePolicyRecordFx,
  deleteOfficePolicyRecordFx,
  getAllOfficePolicyRecordsFx,
  getMyOfficePolicyRecordsFx,
  getSubordinateOfficePolicyRecordsFx,
  updateOfficePolicyRecordFx,
} from '.';

createOfficePolicyRecordFx.use(officePolicyRecordsApi.create);
deleteOfficePolicyRecordFx.use(officePolicyRecordsApi.deleteRecord);
getAllOfficePolicyRecordsFx.use(officePolicyRecordsApi.getAll);
getMyOfficePolicyRecordsFx.use(officePolicyRecordsApi.getMy);
getSubordinateOfficePolicyRecordsFx.use(officePolicyRecordsApi.getSubordinate);
updateOfficePolicyRecordFx.use(officePolicyRecordsApi.update);
