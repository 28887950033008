import React from 'react';
import cn from 'classnames';
import styles from './index.css';
import {ReactComponent as CloseIcon} from './close.svg';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const CloseButton = ({className, onClick}: Props) => (
  <button className={cn(styles.root, className)} type='button' onClick={onClick}>
    <CloseIcon />
  </button>
);
