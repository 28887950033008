import {DataState} from '@joomcode/deprecated-utils/dataState';
import {UserFullInfoRecord} from 'domain/userFullInfoRecord/model';
import {getUserFullInfoRecordsFx} from 'domain/userFullInfoRecord/stores/main';
import {$userFullInfoRecords} from 'domain/userFullInfoRecord/stores/main/state';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: UserFullInfoRecord[];
  dataState: DataState;
};

export function useUserFullInfoRecords(): ReturnShape {
  const {data, dataState} = useStore($userFullInfoRecords);

  useEffect(() => {
    getUserFullInfoRecordsFx().catch(toaster.interceptThenThrowError);
  }, []);

  return {
    data,
    dataState,
  };
}
