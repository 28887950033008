import {StockOptionAgreementCreateConfig} from 'domain/stockOption/agreement/api/create';
import {StockOptionAgreementDeleteConfig} from 'domain/stockOption/agreement/api/delete';
import {SendToHolderConfig} from 'domain/stockOption/agreement/api/sendToHolder';
import {SetDocumentsStatusConfig} from 'domain/stockOption/agreement/api/setDocumentsStatus';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {StockOptionAgreementWithSummary} from 'domain/stockOption/agreement/model/withSummary';
import {StockOptionSummary} from 'domain/stockOption/summary/model';
import {createEffect} from 'effector';

export const createStockOptionAgreementFx = createEffect<
  StockOptionAgreementCreateConfig,
  StockOptionAgreementWithSummary
>('create stock option agreement');

export const deleteStockOptionAgreementFx = createEffect<StockOptionAgreementDeleteConfig, StockOptionSummary>(
  'delete stock option agreement',
);

export const sendStockOptionAgreementToHolderFx = createEffect<SendToHolderConfig, void>(
  'send stock option agreement to holder',
);

export const setDocumentsStatusFx = createEffect<SetDocumentsStatusConfig, StockOptionAgreement>(
  'set stock option agreement documents status',
);
