import {ReactComponent as QuestionIcon} from '@joomcode/joom-ui/icons/circle/question.svg';
import {TooltipFaq, TooltipFaqPlacement, TooltipFaqProps} from '@joomcode/joom-ui/TooltipFaq';
import cn from 'classnames';
import React from 'react';
import styles from './styles.css';

type Props = React.PropsWithChildren<{
  iconComponent?: React.ComponentType;
  tooltipPlacement?: TooltipFaqPlacement;
  trigger?: TooltipFaqProps['trigger'];
  colored?: boolean;
}>;

export function HintIcon({
  children,
  iconComponent: IconComponent,
  tooltipPlacement = 'right',
  trigger,
  colored = true,
}: Props) {
  return (
    <span
      className={cn(styles.root, {
        [styles.clickable]: trigger === 'click',
        [styles.colored]: colored,
      })}
    >
      <TooltipFaq placement={tooltipPlacement} content={children} trigger={trigger}>
        {IconComponent ? <IconComponent /> : <QuestionIcon />}
      </TooltipFaq>
    </span>
  );
}
