import {CountryCode} from 'domain/countryCode/model';

export enum Frequency {
  PER_HOUR = 'perHour',
  PER_DAY = 'perDay',
  PER_MONTH = 'perMonth',
  PER_QUARTER = 'perQuarter',
  SEMI_ANNUAL = 'semiAnnual',
  PER_YEAR = 'perYear',
  OTHER = 'other',
}

export const frequencyBySalaryRangeCountry: Partial<Record<CountryCode, Frequency>> = {
  [CountryCode.AM]: Frequency.PER_MONTH,
  [CountryCode.LV]: Frequency.PER_MONTH,
  [CountryCode.RU]: Frequency.PER_MONTH,
  [CountryCode.BR]: Frequency.PER_MONTH,
  [CountryCode.CN]: Frequency.PER_YEAR,
  [CountryCode.DE]: Frequency.PER_YEAR,
  [CountryCode.PT]: Frequency.PER_YEAR,
};
