import {$self} from 'domain/self/stores/main/state';
import {UserFull} from 'domain/user/model';
import {isUserFull} from 'domain/user/model/utils';
import {$users} from 'domain/user/stores/main/state';
import {useStore, useStoreMap} from 'effector-react';

export const useSelfUser = (): UserFull | undefined => {
  const {login} = useStore($self);
  const user = useStoreMap({
    store: $users,
    keys: [login],
    fn: (users, [userLogin]) => (userLogin === undefined ? null : users.byLogin[userLogin]),
  });
  return user && isUserFull(user) ? user : undefined;
};
