import {SideCalculator} from 'components/ui/SideCalculator';
import {StyledExternalLink} from 'components/ui/StyledLink';
import {FormattedTimeOffDays} from 'components/widgets/FormattedTimeOffDays';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {TimeOffType} from 'domain/timeOff/type/model';
import {User} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  availableDays: number;
  type: TimeOffType;
  user: User;
};

const REMOTE_WORK_LINK = 'https://www.notion.so/joomteam/Office-4-1-policy-3ad52ed7b02e45d1bed3d5a5abc51c78';
const VACATION_LINK = 'https://www.notion.so/joomteam/Time-off-7f1064fed9d7459d9c3a722761e00221';

export function Balance({availableDays, type, user}: Props) {
  const intl = useIntl();
  const selfUserId = useSelfUserId();
  const isSelfUser = user.id === selfUserId;
  const hint = useMemo(() => (type === TimeOffType.VACATION ? messages.vacationHint : messages.remoteWorkHint), [type]);
  const link = useMemo(() => (type === TimeOffType.VACATION ? VACATION_LINK : REMOTE_WORK_LINK), [type]);

  return (
    <SideCalculator
      title={intl.formatMessage(messages.title, {
        isSelfUser,
        fullName: intl.formatMessage(generalMessages.fullName, {
          firstName: user.firstName,
          lastName: user.lastName,
        }),
      })}
      value={<FormattedTimeOffDays days={availableDays} />}
      hint={intl.formatMessage(hint, {
        link: (text) => (
          <StyledExternalLink colored href={link}>
            {text}
          </StyledExternalLink>
        ),
      })}
    />
  );
}
