import React from 'react';
import {identity, isNullish} from '@joomcode/deprecated-utils/function';
import {FilterItem, Value} from '../../types';
import {BooleanControl} from './BooleanControl';
import {BooleanLabel} from './BooleanLabel';
import {Options} from './types';

type InnerValue = boolean;

export function createGenericBooleanFilter<ExternalValue>(
  filterName: string,
  options: Options & {
    parseValue(innerValue: Value<InnerValue>): Value<ExternalValue>;
    formatValue(value: Value<ExternalValue>): Value<InnerValue>;
  },
): FilterItem<ExternalValue, Options> {
  return {
    name: filterName,
    options,
    isEmpty: (value) => {
      const formattedValue = options.formatValue(value);

      return isNullish(formattedValue);
    },
    renderControl: (props) => (
      <BooleanControl
        {...props}
        onSubmit={(value) => props.onSubmit(options.parseValue(value))}
        value={options.formatValue(props.value)}
      />
    ),
    renderLabel: (props) => <BooleanLabel {...props} value={options.formatValue(props.value)} />,
  };
}

export function createBooleanFilter(filterName: string, options: Options = {}): FilterItem<InnerValue, Options> {
  return createGenericBooleanFilter(filterName, {
    ...options,
    parseValue: identity,
    formatValue: identity,
  });
}
