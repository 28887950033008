import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination, useOffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {EntityListStore, TotalCountData} from '@joomcode/joom-admin/dataTypes/entityStore';
import {QueryRequestPayload} from 'api/types';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {Filters} from 'domain/compensationReviewRequest/model/filters';
import {securePermissionByTabId, TabId} from 'domain/compensationReviewRequest/model/tab';
import {
  getAllCompensationReviewRequestsFx,
  getMyCompensationReviewRequestsFx,
  getSubordinateCompensationReviewRequestsFx,
} from 'domain/compensationReviewRequest/stores/main';
import {
  $allCompensationReviewRequests,
  $myCompensationReviewRequests,
  $subordinateCompensationReviewRequests,
} from 'domain/compensationReviewRequest/stores/main/state';
import {Effect} from 'effector';
import {useStore} from 'effector-react';
import {useEffect, useMemo} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type Options = {
  tabId: TabId;
  filters?: Filters;
  paginationOptions?: PaginationOptions;
};

type PaginationOptions = {
  initialLimit: number;
  initialPage: number;
};

type ReturnShape = {
  data: CompensationReviewRequest[];
  dataState: DataState;
  pagination: OffsetPagination;
  totalCount: number;
};

const defaultFilters = {};
const effectByTabId: Record<TabId, Effect<QueryRequestPayload<Filters>, TotalCountData<CompensationReviewRequest>>> = {
  [TabId.ANY]: getAllCompensationReviewRequestsFx,
  [TabId.MY]: getMyCompensationReviewRequestsFx,
  [TabId.SUBORDINATE]: getSubordinateCompensationReviewRequestsFx,
};
const storeByTabId: Record<TabId, EntityListStore<CompensationReviewRequest>> = {
  [TabId.ANY]: $allCompensationReviewRequests,
  [TabId.MY]: $myCompensationReviewRequests,
  [TabId.SUBORDINATE]: $subordinateCompensationReviewRequests,
};

export function useCompensationReviewRequests({tabId, filters = defaultFilters}: Options): ReturnShape {
  const acl = useAcl();
  const securePermission = securePermissionByTabId[tabId];
  const store = storeByTabId[tabId];
  const handler = effectByTabId[tabId];

  const {data, dataState, totalCount} = useStore(store);
  const pagination = useOffsetPagination({initialLimit: 25, initialPage: 1});
  const pagingAndFilterOptions = useMemo(() => {
    return {
      offset: pagination.offset,
      limit: pagination.limit,
      filters,
    };
  }, [pagination.offset, pagination.limit, filters]);

  useEffect(() => {
    if (acl.hasSecurePermission(securePermission)) {
      handler(pagingAndFilterOptions).catch(toaster.interceptThenThrowError);
    }
  }, [acl, securePermission, handler, pagingAndFilterOptions]);

  return {
    data,
    dataState,
    pagination,
    totalCount: totalCount ?? 0,
  };
}
