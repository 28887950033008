import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Options',
    description: '[title] Offer details panel: stock options section title',
  },
  noOptions: {
    defaultMessage: 'No option agreement',
    description: '[text] Offer details panel: no options message',
  },
  hasOptions: {
    defaultMessage: 'Has an option agreement',
    description: '[text] Offer details panel: has options message',
  },
});
