import React, {forwardRef} from 'react';
import {useLinkUi, LinkUiProps} from '../Link';

export type ExternalLinkProps = Omit<JSX.IntrinsicElements['a'] & Omit<LinkUiProps, 'external'>, 'ref'>;

export const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(function ExternalLink(
  {children, ...restProps},
  ref,
) {
  const linkProps = useLinkUi({external: true, ...restProps});

  return (
    <a {...linkProps} ref={ref}>
      {children}
    </a>
  );
});
