import {intranetApi} from 'apiClient';
import {
  CompensationReviewRequest,
  CompensationReviewRequestId,
  compensationReviewRequestSchema,
} from 'domain/compensationReviewRequest/model';

export type CompensationReviewRequestApproveDiff = {
  comment?: string;
};
export type CompensationReviewRequestApproveConfig = CompensationReviewRequestApproveDiff & {
  id: CompensationReviewRequestId;
};

const approveGeneric =
  (endpoint: string) =>
  async ({id, comment}: CompensationReviewRequestApproveConfig): Promise<CompensationReviewRequest> => {
    const {data} = await intranetApi.post(endpoint, {comment}, {params: {id}});

    return compensationReviewRequestSchema.runWithException(data);
  };

export const approve = approveGeneric('/v1/secure/compensationReviewRequests/approve');
export const approveOnBehalf = approveGeneric('/v1/secure/compensationReviewRequests/approveOnBehalf');
