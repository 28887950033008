import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  menuAriaLabel: {
    defaultMessage: 'One-time bonus record menu',
    description: 'One-time bonus menu: icon label',
  },
  edit: {
    defaultMessage: 'Edit',
    description: 'One-time bonus menu: item',
  },
  delete: {
    defaultMessage: 'Delete',
    description: 'One-time bonus menu: item',
  },
});
