import {intranetApi} from 'apiClient';
import {MarketDataRecordId} from 'domain/marketDataRecord/model';
import {UserId} from 'domain/user/model';

export type DeleteMarketDataRecordParams = {
  id: MarketDataRecordId;
  userId: UserId;
};

export const deleteRecord = (params: DeleteMarketDataRecordParams): Promise<void> => {
  return intranetApi.post('/v1/secure/users/marketDataRecords/delete', undefined, {params});
};
