import {MultiSelect, MultiSelectProps} from '@joomcode/joom-ui/Select/MultiSelect';
import {User} from 'domain/user/model';
import {UserSuggestion} from 'domain/user/widgets/Suggestion';
import {generalMessages} from 'i18n/messages/general';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

const renderOption = (user: User) => <UserSuggestion user={user} />;

type Props = Omit<MultiSelectProps<User, unknown>, 'items' | 'itemToString' | 'groupedItems' | 'groupToString'> & {
  items: User[];
};

export function UserMultiSelect({items, onChange, ...props}: Props) {
  const intl = useIntl();

  const itemToString = useCallback(
    ({firstName, lastName, login}: User) =>
      intl.formatMessage(messages.userSuggestItem, {
        fullName: intl.formatMessage(generalMessages.fullName, {
          firstName,
          lastName,
        }),
        login,
      }),
    [intl],
  );

  const suggestionFilter = useCallback(
    (user: User, query: string) => (query ? itemToString(user).toLowerCase().includes(query.toLowerCase()) : true),
    [itemToString],
  );

  return (
    <MultiSelect<User>
      suggestionFilter={suggestionFilter}
      items={items}
      itemToString={itemToString}
      noSuggestionsPlaceholder={intl.formatMessage(messages.noItems)}
      onChange={onChange}
      renderOption={renderOption}
      {...props}
    />
  );
}
