import {ReactComponent as LoupeIcon} from '@joomcode/joom-ui/icons/core/loupe.svg';
import {Input as LibInput} from '@joomcode/joom-ui/Input';
import {CloseButton} from '@joomcode/joom-ui/TagCloseButton';
import cn from 'classnames';
import {GetInputPropsOptions} from 'downshift';
import React, {PropsWithoutRef, useCallback, useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import styles from './Input.css';
import {messages} from './messages';

type Props = {
  autoFocus: boolean;
  inputProps: PropsWithoutRef<GetInputPropsOptions>;
  onClear: () => void;
};

export const Input = ({autoFocus, inputProps, onClear}: Props) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClear = useCallback(
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (inputRef.current) {
        inputRef.current.value = '';
        inputRef.current.focus();
      }
      onClear();
    },
    [onClear, inputRef],
  );
  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <div className={styles.wrap}>
      <span className={styles.searchIconWrap}>
        <LoupeIcon />
      </span>
      <LibInput
        className={cn(styles.input, {[styles.empty]: !inputProps.value})}
        placeholder={intl.formatMessage(messages.searchPlaceholder)}
        ref={inputRef}
        {...inputProps}
        autoComplete='off'
      />
      <span className={styles.clearButtonWrap}>{inputProps.value && <CloseButton onClick={handleClear} />}</span>
    </div>
  );
};
