import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {RedirectorPage} from 'pages/Redirector';
import {RouteConfig} from 'react-router-config';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {redirectorUrlPatterns} from 'routes/redirector/urls';

export const config: RouteConfig = {
  component: withAuth(RegularLayout),
  path: redirectorUrlPatterns.root,
  routes: [
    {
      component: withPermission(RedirectorPage, Permission.REDIRECT_READ),
      exact: true,
      path: redirectorUrlPatterns.root,
    },
    notFoundRouteConfig,
  ],
};
