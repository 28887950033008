import {ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {coordinatesWithAngleSchema} from 'domain/officeMap/coordinates/model';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {idSchema} from 'models/system/id';

export const officeSeatSchema = object({
  id: idSchema,
  label: optional(string()),
  coordinates: coordinatesWithAngleSchema,
  number: optional(string()),
  officeAlias: oneOfEnum(OfficeAlias),
});

export type OfficeSeat = ExtractSchemaType<typeof officeSeatSchema>;

export const officeSeatIdSchema = idSchema;
export type OfficeSeatId = ExtractSchemaType<typeof officeSeatIdSchema>;

export type SeatIdentification = {type: 'id'; id: OfficeSeatId} | {type: 'number'; number: string};

export const seatIdentificationFromString = (str: string): SeatIdentification | null => {
  if (officeSeatIdSchema.run(str).ok) {
    return {type: 'id', id: str};
  }
  return {type: 'number', number: str};
};
