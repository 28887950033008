import {
  array,
  boolean,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
} from '@joomcode/deprecated-utils/jsonValidation';
import {resourceIdSchema, resourceSchema} from 'domain/resource/model';
import {roleIdSchema, roleSchema} from 'domain/role/model';
import {teamIdSchema} from 'domain/team/model/id';
import {teamLiteWithAncestorsSchema} from 'domain/team/model/liteWithAncestors';
import {userSchema} from 'domain/user/model';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {RoleBindingStatus} from './status';

export const roleBindingIdSchema = idSchema;

export const roleBindingSchema = object({
  id: roleBindingIdSchema,
  createdBy: userSchema,
  propagate: optionalWithDefault(false, boolean()),
  resource: resourceSchema,
  role: roleSchema,
  status: oneOfEnum(RoleBindingStatus),
  teamWithAncestors: teamLiteWithAncestorsSchema,
});

export const roleBindingRequestConfigSchema = object({
  resourceId: resourceIdSchema,
  roleId: roleIdSchema,
  teamId: teamIdSchema,
  propagate: optional(boolean()),
});

export const roleBindingsSearchFiltersSchema = object({
  resourceId: optional(resourceIdSchema),
  statuses: optional(array(oneOfEnum(RoleBindingStatus))),
});

export const countableRoleBindingsSchema = object({
  items: array(roleBindingSchema),
  count: number(),
});

export const teamRoleBindingsSchema = object({
  activeBindings: array(roleBindingSchema),
  myBindings: array(roleBindingSchema),
});

export type RoleBinding = ExtractSchemaType<typeof roleBindingSchema>;
export type RoleBindingId = ExtractSchemaType<typeof roleBindingIdSchema>;
export type RoleBindingRequestConfig = ExtractSchemaType<typeof roleBindingRequestConfigSchema>;
export type RoleBindingsSearchFilters = Partial<ExtractSchemaType<typeof roleBindingsSearchFiltersSchema>>;
export type CountableRoleBindigns = ExtractSchemaType<typeof countableRoleBindingsSchema>;
export type TeamRoleBindings = ExtractSchemaType<typeof teamRoleBindingsSchema>;
