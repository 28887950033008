import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {
  CommonFieldProps,
  composeValidators,
  getFieldErrorText,
  getFieldValidator,
  useField,
  validateFilled,
} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {Type} from 'domain/compensationReviewRequest/model/type';
import {formatType} from 'domain/compensationReviewRequest/model/type/formatter';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {hints} from './messages';
import styles from './styles.css';

export type Props = CommonFieldProps<Type> & {
  onlySubordinates?: boolean;
};

export function FieldCompensationReviewRequestType({
  disabled,
  hint,
  initialValue,
  label,
  name,
  required,
  reserveSpaceForError,
  validate,
  extraErrorMessages,
  onlySubordinates,
  ...restProps
}: Props) {
  const intl = useIntl();
  const itemToString = useCallback((type: Type) => formatType(type, intl), [intl]);
  const renderItem = useCallback(
    (type: Type) => (
      <div className={styles.option}>
        {formatType(type, intl)}
        {(type === Type.PROMOTION || type === Type.RETENTION) && (
          <TooltipFaq content={intl.formatMessage(hints[type])} placement='left'>
            <div className={styles.tooltip} />
          </TooltipFaq>
        )}
      </div>
    ),
    [intl],
  );
  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const items = useMemo(() => {
    const types = getEnumValues(Type);
    return onlySubordinates ? types.filter((type) => type !== Type.ROTATION) : types;
  }, [onlySubordinates]);
  const {input, meta} = useField<Type>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
  });

  return (
    <FormControl
      disabled={meta.submitting || disabled}
      error={getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      label={label}
      hint={hint}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <SimpleAutocomplete<Type>
          items={items}
          getItemKey={identity}
          itemToString={itemToString}
          renderItem={renderItem}
          {...formControlProps}
          {...input}
          {...restProps}
          disabled={meta.submitting || disabled}
        />
      )}
    </FormControl>
  );
}
