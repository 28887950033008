import React from 'react';
import {ToastContainer as ToastContainerCore, Slide} from 'react-toastify';
import {convertToastOptionsToToastCoreOptions} from '../utils';
import {ToastOptions} from '../types';
import 'react-toastify/dist/ReactToastify.css';
import styles from './index.css';

export function ToastContainer({autoClose = 8_000, noCloseButton}: ToastOptions) {
  return (
    <ToastContainerCore
      className={styles.root}
      position='top-right'
      theme='colored'
      transition={Slide}
      hideProgressBar
      closeOnClick={false}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      {...convertToastOptionsToToastCoreOptions({autoClose, noCloseButton})}
    />
  );
}
