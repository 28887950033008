import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Move team',
    description: '[title] Change parent team dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Change parent team dialog',
    description: 'Change parent team dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Change parent team dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Change parent',
    description: '[button] Change parent team dialog: submit button',
  },
  parentTeamLabel: {
    defaultMessage: 'New parent team',
    description: 'Change parent team dialog: field label',
  },
});
