import {defineMessages} from 'react-intl';

export const tabNames = defineMessages({
  teams: {
    defaultMessage: 'Teams',
    description: '[title] Joomers page tab name',
  },
  outstaff: {
    defaultMessage: 'Outstaff',
    description: '[title] Joomers page tab name',
  },
  users: {
    defaultMessage: 'People',
    description: '[title] Joomers page tab name',
  },
});
