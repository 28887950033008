import React, {useEffect} from 'react';
import {GetInputPropsOptions} from 'downshift';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../../../create-locator';
import styles from './index.css';

export type SelectInputTestId = ConvertLocatorToTestId<SelectInputLocator>;
export type SelectInputLocator = Locator<void>;

type Props = {
  inputProps: GetInputPropsOptions;

  /*
  `onChange` is called every time the input value changes
  (regardless it’s reason — user entry or option selection),
  whereas `inputProps.onChange` is not called when selected option changes.
   */
  onChange?: (inputValue: string) => void;
} & Partial<Mark<SelectInputLocator>>;

export function SelectInput({inputProps, onChange, ...restProps}: Props) {
  const locator = createLocator(restProps);

  useEffect(() => {
    if (onChange) {
      onChange(inputProps.value?.toString() || '');
    }
  }, [inputProps.value]);

  return <input {...inputProps} {...locator()} className={styles.input} />;
}
