import React from 'react';
import {getSafeArrayLength} from '@joomcode/deprecated-utils/array/getSafeArrayLength';
import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {AutocompleteMultiSelectControl} from './AutocompleteMultiSelectControl';
import {AutocompleteMultiSelectLabel} from './AutocompleteMultiSelectLabel';
import {FilterItem, FilterItemWithTransform, Value} from '../../types';
import {AutocompleteMultiSelectFilterOptions} from './types';

type InnerValueGeneric<Item> = Item[];

type BaseFilterItem<InnerValue> = FilterItem<InnerValue[], AutocompleteMultiSelectFilterOptions<InnerValue>>;

export function createAutocompleteMultiselectFilter<InnerValue extends string>(
  filterName: string,
  filterOptions: AutocompleteMultiSelectFilterOptions<InnerValue>,
): BaseFilterItem<InnerValue>;

export function createAutocompleteMultiselectFilter<InnerValue extends string, OuterValue extends string = InnerValue>(
  filterName: string,
  filterOptions: AutocompleteMultiSelectFilterOptions<InnerValue>,
  transformValue: (val?: OuterValue[]) => InnerValue[],
  preprocessValueBeforeSubmit: (val?: InnerValue[]) => OuterValue[],
): FilterItemWithTransform<BaseFilterItem<InnerValue>, OuterValue[]>;

export function createAutocompleteMultiselectFilter<InnerValue extends string, OuterValue extends string = InnerValue>(
  filterName: string,
  filterOptions: AutocompleteMultiSelectFilterOptions<InnerValue>,
  transformValue?: (val?: OuterValue[]) => InnerValue[],
  preprocessValueBeforeSubmit?: (val?: InnerValue[]) => OuterValue[],
): BaseFilterItem<InnerValue> | FilterItemWithTransform<BaseFilterItem<InnerValue>, OuterValue[]> {
  return {
    name: filterName,
    options: filterOptions,
    transformValue,
    preprocessValueBeforeSubmit,
    isEmpty: (value) => isNullish(value) || value.length === 0,
    renderControl: (props) => <AutocompleteMultiSelectControl {...props} />,
    renderLabel: (props) => <AutocompleteMultiSelectLabel {...props} />,
    getSelectedAmount: (value) => getSafeArrayLength(value),
  };
}

export function createGenericAutocompleteMultiselectFilter<ExternalValue>(
  filterName: string,
  options: AutocompleteMultiSelectFilterOptions<string> & {
    parseValue(innerValue: Value<InnerValueGeneric<string>>): Value<ExternalValue>;
    formatValue(value: Value<ExternalValue>): Value<InnerValueGeneric<string>>;
  },
): FilterItem<ExternalValue, AutocompleteMultiSelectFilterOptions<string>> {
  return {
    name: filterName,
    options,
    isEmpty: (value) => {
      const formattedValue = options.formatValue(value);

      return isNullish(formattedValue) || formattedValue.length === 0;
    },
    renderControl: (props) => (
      <AutocompleteMultiSelectControl
        {...props}
        onSubmit={(value) => props.onSubmit(options.parseValue(value))}
        value={options.formatValue(props.value)}
      />
    ),
    renderLabel: (props) => <AutocompleteMultiSelectLabel {...props} value={options.formatValue(props.value)} />,
    getSelectedAmount: (value) => {
      const formattedValue = options.formatValue(value);
      return getSafeArrayLength(formattedValue);
    },
  };
}
