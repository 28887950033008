import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {CompensationRequestClosureDiff} from 'domain/compensations/request/api/close';
import {CompensationRequestRejectionDiff} from 'domain/compensations/request/api/reject';
import {CompensationRequest} from 'domain/compensations/request/model';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {cancelRequestFx, closeRequestFx, rejectRequestFx} from 'domain/compensations/request/stores/main';
import {Permission} from 'domain/permission/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {compensationsUrls} from 'routes/compensations/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {CompensationRequestApproveButton} from './ApproveButton';
import {CloseDialog} from './CloseDialog';
import {messages} from './messages';
import {RejectDialog} from './RejectDialog';

type Props = {
  compensationRequest: CompensationRequest;
  isApprover: boolean;
  isOwner: boolean;
};

export function CompensationRequestActions({compensationRequest: {id, report, status}, isApprover, isOwner}: Props) {
  const intl = useIntl();
  const acl = useAcl();

  const {open: openRejectDialog, close: closeRejectDialog, isOpen: isRejectDialogOpen} = usePopupState();
  const {open: openCloseDialog, close: closeCloseDialog, isOpen: isCloseDialogOpen} = usePopupState();
  const isAdmin = acl.hasPermission(Permission.COMPENSATION_WRITE_ADMIN);
  const canCancelRequests =
    acl.hasPermission(Permission.COMPENSATION_CANCEL) || (isOwner && status === CompensationRequestStatus.CREATED);

  const cancel = useAsyncTask(() => cancelRequestFx(id).catch(toaster.interceptThenThrowError), [id]);
  const close = useAsyncTask(
    (diff: CompensationRequestClosureDiff) => closeRequestFx({diff, id}).catch(toaster.interceptThenThrowError),
    [id],
  );
  const reject = useAsyncTask(
    async (diff: CompensationRequestRejectionDiff) =>
      rejectRequestFx({diff, id}).catch(toaster.interceptThenThrowError),
    [id],
  );

  const onCancelClick = useCallback(
    () =>
      confirm(
        {
          denialText: intl.formatMessage(messages.cancelDialogDenyButton),
          title: intl.formatMessage(messages.cancelDialogTitle),
          text: intl.formatMessage(messages.cancelDialogText),
          confirmationText: intl.formatMessage(messages.cancelDialogConfirmButton),
          onConfirm: cancel.perform,
        },
        intl,
      ),
    [cancel, intl],
  );

  const onRejectSubmit = useCallback(
    (diff: CompensationRequestRejectionDiff) => {
      closeRejectDialog();
      return reject.perform(diff);
    },
    [reject.perform, closeRejectDialog],
  );

  const onCloseSubmit = useCallback(
    (diff: CompensationRequestClosureDiff) => {
      closeCloseDialog();
      return close.perform(diff);
    },
    [close.perform, closeCloseDialog],
  );

  return (
    <ButtonGroup spaced size='m'>
      {canCancelRequests && (
        <Button intent='primary' kind='primary' loading={cancel.isPerforming} onClick={onCancelClick}>
          {intl.formatMessage(messages.cancelButton)}
        </Button>
      )}
      {isOwner && report && status === CompensationRequestStatus.NEEDS_REPORT && (
        <RouterLinkButton
          kind='primary'
          intent='primary'
          size='m'
          to={compensationsUrls.report({compensationRequestId: id})}
        >
          {report?.hasData
            ? intl.formatMessage(messages.continueReportButton)
            : intl.formatMessage(messages.fillReportButton)}
        </RouterLinkButton>
      )}
      {((isApprover && status === CompensationRequestStatus.CREATED) ||
        (isAdmin && status === CompensationRequestStatus.MANAGER_APPROVED)) && (
        <>
          <CompensationRequestApproveButton id={id} status={status} />
          <Button intent='negative' kind='primary' loading={reject.isPerforming} onClick={openRejectDialog}>
            {intl.formatMessage(messages.rejectButton)}
          </Button>
        </>
      )}
      {acl.hasPermission(Permission.COMPENSATION_CLOSE) && status === CompensationRequestStatus.REPORTED && (
        <Button intent='primary' kind='primary' loading={close.isPerforming} onClick={openCloseDialog}>
          {intl.formatMessage(messages.closeButton)}
        </Button>
      )}
      {isRejectDialogOpen && <RejectDialog onClose={closeRejectDialog} onSubmit={onRejectSubmit} />}
      {isCloseDialogOpen && <CloseDialog onClose={closeCloseDialog} onSubmit={onCloseSubmit} />}
    </ButtonGroup>
  );
}
