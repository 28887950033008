import {ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {SocialMedia} from 'domain/socialMedia/model';

export const userSocialMediaLinksSchema = object({
  [SocialMedia.FACEBOOK]: optional(string()),
  [SocialMedia.INSTAGRAM]: optional(string()),
  [SocialMedia.LINKED_IN]: optional(string()),
  [SocialMedia.TWITTER]: optional(string()),
});

export type UserSocialMediaLinks = ExtractSchemaType<typeof userSocialMediaLinksSchema>;
