import {DataState} from '@joomcode/deprecated-utils/dataState';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {SecurePermission} from 'domain/permission/model/secure';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {stockOptionAgreementDocumentsStatuses} from 'domain/stockOption/agreement/model/documentsStatus/messages';
import {StockOptionAgreementStatus} from 'domain/stockOption/agreement/model/status';
import {formatStockOptionAgreementStatus} from 'domain/stockOption/agreement/model/status/formatter';
import {formatStockOptionAgreementType} from 'domain/stockOption/agreement/model/type/formatter';
import {StockOptionAgreementActions} from 'domain/stockOption/agreement/widgets/Actions';
import {StockOptionAgreementCreationDialog} from 'domain/stockOption/agreement/widgets/CreationDialog';
import {StockOptionAgreementFreezeHint} from 'domain/stockOption/agreementFreeze/widgets/Hint';
import {useStockOptionTypesVisibility} from 'domain/stockOption/holder/hooks/useTypesVisibility';
import {StockOptionReadAccess} from 'domain/stockOption/holder/model/access';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import {StockOptionValueEstimation} from 'domain/stockOption/holder/widgets/ValueEstimation';
import {User} from 'domain/user/model';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

interface Props {
  user: User;
  agreements: StockOptionAgreement[];
  readAccess: StockOptionReadAccess | null;
}

const getRowKey = (agreement: StockOptionAgreement) => agreement.id;

export function StockOptionAgreementsPanel({user, agreements, readAccess}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canReadAnyOptionsData = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_ANY);
  const canManageAgreements = acl.hasSecurePermission(SecurePermission.STOCK_OPTION_WRITE_ANY);
  const canSeeOptionTypes = useStockOptionTypesVisibility(user);
  const agreementCreationDialog = usePopupState();

  const columns = useMemo(() => {
    const result: Column<StockOptionAgreement>[] = [
      {
        defaultWidth: 4,
        id: ColumnId.AMOUNT,
        align: 'right',
        name: intl.formatMessage(columnNames[ColumnId.AMOUNT]),
        render: ({amount}) => (
          <>
            <FormattedOptionsAmount amount={amount} />
            {(readAccess === StockOptionReadAccess.MY || readAccess === StockOptionReadAccess.SUBORDINATE) && (
              <div>
                <StockOptionValueEstimation amount={amount} />
              </div>
            )}
          </>
        ),
      },
      {
        defaultWidth: 4,
        id: ColumnId.VESTED,
        align: 'right',
        name: intl.formatMessage(columnNames[ColumnId.VESTED]),
        render: ({vested}) => <FormattedOptionsAmount amount={vested} />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({status, freezes}) => {
          const startDate = freezes[0]?.startDate;
          const statusText = formatStockOptionAgreementStatus(status, intl);

          return (
            <>
              {statusText}
              {startDate && (
                <StockOptionAgreementFreezeHint
                  freezeDate={startDate}
                  frozen={status === StockOptionAgreementStatus.FROZEN}
                />
              )}
            </>
          );
        },
      },
      {
        defaultWidth: 8,
        id: ColumnId.ISSUER,
        name: intl.formatMessage(columnNames[ColumnId.ISSUER]),
        render: ({issuer}) => issuer,
      },
      {
        defaultWidth: 5,
        id: ColumnId.ISSUE_DATE,
        name: intl.formatMessage(columnNames[ColumnId.ISSUE_DATE]),
        render: ({issueDate}) => <HumanDate value={issueDate} format='short' utc />,
      },
    ];

    if (canSeeOptionTypes) {
      result.push(
        {
          defaultWidth: 5,
          id: ColumnId.END_VESTING_DATE,
          name: intl.formatMessage(columnNames[ColumnId.END_VESTING_DATE]),
          render: ({endVestingDate}) =>
            endVestingDate ? <HumanDate value={endVestingDate} format='short' utc /> : <DataTable.CellEmpty />,
        },
        {
          defaultWidth: 5,
          id: ColumnId.TYPE,
          name: intl.formatMessage(columnNames[ColumnId.TYPE]),
          render: ({type}) => formatStockOptionAgreementType(type, intl),
        },
      );
    }

    if (canReadAnyOptionsData) {
      result.push(
        {
          defaultWidth: 8,
          id: ColumnId.DOCUMENTS_STATUS,
          name: intl.formatMessage(columnNames[ColumnId.DOCUMENTS_STATUS]),
          render: ({documentsStatus}) => intl.formatMessage(stockOptionAgreementDocumentsStatuses[documentsStatus]),
        },
        {
          defaultWidth: 8,
          id: ColumnId.ACTIONS,
          name: intl.formatMessage(columnNames[ColumnId.ACTIONS]),
          render: (agreement) => <StockOptionAgreementActions agreement={agreement} />,
        },
      );
    }

    return result;
  }, [intl, canReadAnyOptionsData, canSeeOptionTypes]);

  return (
    <>
      <Panel
        withSeparator
        withMarginBottom={false}
        title={intl.formatMessage(messages.title)}
        toolbarTop={
          canManageAgreements && (
            <Button size='m' kind='primary' intent='primary' onClick={agreementCreationDialog.open}>
              {intl.formatMessage(messages.btnCreate)}
            </Button>
          )
        }
      >
        <DataTable
          columns={columns}
          dataState={DataState.LOADED}
          data={agreements}
          getRowKey={getRowKey}
          storageName='stockOption.agreements.list'
        />
      </Panel>
      {agreementCreationDialog.isOpen && (
        <StockOptionAgreementCreationDialog user={user} onClose={agreementCreationDialog.close} />
      )}
    </>
  );
}
