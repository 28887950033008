import {EmptyMessage} from 'components/ui/EmptyMessage';
import {RoleInTeam} from 'domain/role/model/roleInTeam';
import {ListedRoleInTeam} from 'domain/role/widgets/ListedRoleInTeam';
import {User} from 'domain/user/model';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  owners?: User[];
  owningMemberships?: RoleInTeam[];
};

export function JdmOwners({owners = [], owningMemberships = []}: Props) {
  const intl = useIntl();
  return (
    <div className={styles.root}>
      {owners.length > 0 && (
        <div className={styles.ownerList}>
          {owners.map((owner) => (
            <div key={owner.id} className={styles.owner}>
              <UserLink user={owner} withAvatar />
            </div>
          ))}
        </div>
      )}
      {owningMemberships.length > 0 && (
        <div className={styles.owningMembershipList}>
          {owningMemberships.map((roleInTeam) => (
            <ListedRoleInTeam
              roleInTeam={roleInTeam}
              key={`${roleInTeam.teamWithAncestors.team.id}_${roleInTeam.role.id}`}
            />
          ))}
        </div>
      )}
      {owners.length === 0 && owningMemberships.length === 0 && (
        <EmptyMessage>{intl.formatMessage(messages.noOwners)}</EmptyMessage>
      )}
    </div>
  );
}
