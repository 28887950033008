import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {ReplaceSalaryRecordParams} from 'domain/salaryRecord/api/types/replace';
import {SalaryRecord, salaryRecordSchema} from 'domain/salaryRecord/model';

export const replace = async ({previousRecordId, userId, diff}: ReplaceSalaryRecordParams): Promise<SalaryRecord[]> => {
  const {data} = await intranetApi.post('/v1/secure/users/salaryRecords/replace', diff, {
    params: {previousRecordId, userId},
  });

  return array(salaryRecordSchema).runWithException(data);
};
