import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {ReactComponent as CoinIcon} from '@joomcode/joom-ui/icons/core/coinBold.svg';
import {ReactComponent as CalendarIcon} from '@joomcode/joom-ui/icons/core/datePicker.svg';
import {ReactComponent as DocumentIcon} from '@joomcode/joom-ui/icons/core/document.svg';
import {ReactComponent as DollarIcon} from '@joomcode/joom-ui/icons/core/dollar.svg';
import {ReactComponent as GearIcon} from '@joomcode/joom-ui/icons/core/gear.svg';
import {ReactComponent as HandWithKeyIcon} from '@joomcode/joom-ui/icons/core/handWithKey.svg';
import {ReactComponent as LockIcon} from '@joomcode/joom-ui/icons/core/lock.svg';
import {ReactComponent as PeopleIcon} from '@joomcode/joom-ui/icons/core/people.svg';
import {ReactComponent as ReplyIcon} from '@joomcode/joom-ui/icons/core/reply.svg';
import {ReactComponent as StoreIcon} from '@joomcode/joom-ui/icons/core/store.svg';
import {ReactComponent as ShirtIcon} from '@joomcode/joom-ui/icons/core/tShirt.svg';
import {NotificationBadge} from '@joomcode/joom-ui/NotificationBadge';
import {SideMenu} from '@joomcode/joom-ui/SideMenu';
import {Permission} from 'domain/permission/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {$self} from 'domain/self/stores/main/state';
import {useStore} from 'effector-react';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {compensationsUrlPatterns, compensationsUrls} from 'routes/compensations/urls';
import {hrConsoleUrlPatterns, hrConsoleUrls} from 'routes/hrConsole/urls';
import {jdmUrlPatterns, jdmUrls} from 'routes/jdm/urls';
import {legalEntitiesUrlPatterns, legalEntitiesUrls} from 'routes/legalEntities/urls';
import {managerConsoleUrlPatterns, managerConsoleUrls} from 'routes/managerConsole/urls';
import {offerUrlPatterns, offerUrls} from 'routes/offer/urls';
import {officeUrlPatterns, officeUrls} from 'routes/officeMap/urls';
import {outstaffUsersUrlPatterns} from 'routes/outstaffUsers/urls';
import {redirectorUrlPatterns, redirectorUrls} from 'routes/redirector/urls';
import {rtconfUrlPatterns, rtconfUrls} from 'routes/rtconf/urls';
import {secureRolesUrlPatterns, secureRolesUrls} from 'routes/secureRoles/urls';
import {sensitiveDataUrlPatterns, sensitiveDataUrls} from 'routes/sensitiveData/urls';
import {stockOptionsUrlPatterns, stockOptionsUrls} from 'routes/stockOptions/urls';
import {teamsUrlPatterns} from 'routes/teams/urls';
import {timeOffUrlPatterns, timeOffUrls} from 'routes/timeOff/urls';
import {userRolesUrlPatterns, userRolesUrls} from 'routes/userRoles/urls';
import {usersUrlPatterns, usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {useAnalytics} from 'services/analytics/useAnalytics';
import {Feature, useFeature} from 'services/features';
import {ReactComponent as BusinessTripIcon} from './icons/businessTrip.svg';
import {ReactComponent as GraduationHatIcon} from './icons/graduationHat.svg';
import {ReactComponent as HomeHeartIcon} from './icons/homeHeart.svg';
import {ReactComponent as HumanWithControlPanelIcon} from './icons/humanWithControlPanel.svg';
import {ReactComponent as KeyIcon} from './icons/key.svg';
import {ReactComponent as PalmIcon} from './icons/palm.svg';
import {ReactComponent as PinIcon} from './icons/pin.svg';
import {confirmationDialog, messages, navItems} from './messages';
import styles from './styles.css';
import {ConsoleAccess, useConsoleAccess} from './useConsoleAccess';

enum AnalyticsElement {
  ACCESS_CENTER = 'side_navigation_access_center',
  OFFICE_CARE = 'side_navigation_office_care',
  LEARNING_AND_DEVELOPMENT = 'side_navigation_learning_and_development',
  MERCH = 'side_navigation_merch',
  BIRTHDAY_CALENDAR = 'side_navigation_birthday_calendar',
  TIME_OFF_CALENDAR = 'side_navigation_whos_out_calendar',
}

type Props = {
  matchedPathPattern: string;
  isCompact: boolean;
  toggleCompact: () => void;
};

export function SideNavigation({matchedPathPattern, isCompact, toggleCompact}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  const {gtag} = useAnalytics();
  const {isStockOptionHolder} = useStore($self);
  const canSeeOthersStockOptions =
    acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_ANY) ||
    acl.hasSecurePermission(SecurePermission.STOCK_OPTION_READ_SUBORDINATE);
  const history = useHistory();
  const managerConsoleNewIconFeature = useFeature(Feature.SUBORDINATE_INFORMATION_NEW_ICON);
  const consoleAccess = useConsoleAccess();

  const clickHandlers = useMemo(
    () =>
      arrayToObject(
        getEnumValues(AnalyticsElement),
        (key) => key,
        (key) => () => gtag.logEvent('element_click', {element: key}),
      ),
    [gtag],
  );

  const onOptionsLinkClick = useCallback(
    (e: React.SyntheticEvent<HTMLAnchorElement>) => {
      if (matchedPathPattern === stockOptionsUrlPatterns.root) {
        return undefined;
      }

      e.preventDefault();

      return confirm(
        {
          title: intl.formatMessage(confirmationDialog.title),
          text: intl.formatMessage(
            canSeeOthersStockOptions ? confirmationDialog.othersOptionsWarning : confirmationDialog.ownOptionsWarning,
          ),
          confirmationText: intl.formatMessage(confirmationDialog.button),
          onConfirm: () => {
            history.push(stockOptionsUrls.root());
          },
        },
        intl,
      );
    },
    [intl],
  );

  return (
    <div className={styles.root}>
      <SideMenu isCompact={isCompact} toggleCompact={toggleCompact}>
        {acl.hasPermission(Permission.USERS_PAGE_VIEW) && acl.hasPermission(Permission.USER_READ_ACTIVE) && (
          <SideMenu.Link
            icon={<PeopleIcon />}
            href={usersUrls.users()}
            isCompact={isCompact}
            isActive={
              matchedPathPattern === usersUrlPatterns.users ||
              matchedPathPattern === teamsUrlPatterns.teams ||
              matchedPathPattern === outstaffUsersUrlPatterns.outstaffUsers
            }
          >
            {intl.formatMessage(navItems.staff)}
          </SideMenu.Link>
        )}
        {acl.hasPermission(Permission.OFFICE_MAP_VIEW) && (
          <SideMenu.Link
            icon={<PinIcon />}
            href={officeUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === officeUrlPatterns.root}
          >
            {intl.formatMessage(navItems.officeMap)}
          </SideMenu.Link>
        )}
        {acl.hasPermission(Permission.TIME_OFF_REQUEST_ENABLED) && (
          <SideMenu.Link
            icon={<PalmIcon />}
            href={timeOffUrls.requests()}
            isCompact={isCompact}
            isActive={matchedPathPattern === timeOffUrlPatterns.root}
          >
            {intl.formatMessage(navItems.timeOffRequests)}
          </SideMenu.Link>
        )}
        {acl.hasPermission(Permission.COMPENSATION_READ) && (
          <SideMenu.Link
            icon={<BusinessTripIcon />}
            href={compensationsUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === compensationsUrlPatterns.root}
          >
            {intl.formatMessage(navItems.compensations)}
          </SideMenu.Link>
        )}
        {acl.hasPermission(Permission.OFFER_READ) && (
          <SideMenu.Link
            icon={<DocumentIcon />}
            href={offerUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === offerUrlPatterns.root}
          >
            {intl.formatMessage(navItems.offers)}
          </SideMenu.Link>
        )}
        {acl.hasPermission(Permission.RTCONF_READ) && (
          <SideMenu.Link
            icon={<GearIcon />}
            href={rtconfUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === rtconfUrlPatterns.root}
          >
            {intl.formatMessage(navItems.rtconf)}
          </SideMenu.Link>
        )}
        {acl.hasPermission(Permission.LEGAL_ENTITY_WRITE) && (
          <SideMenu.Link
            icon={<StoreIcon />}
            href={legalEntitiesUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === legalEntitiesUrlPatterns.root}
          >
            {intl.formatMessage(navItems.legalEntities)}
          </SideMenu.Link>
        )}
        {consoleAccess === ConsoleAccess.HR && (
          <SideMenu.Link
            icon={<HumanWithControlPanelIcon />}
            href={hrConsoleUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === hrConsoleUrlPatterns.root}
          >
            {intl.formatMessage(navItems.hrConsole)}
          </SideMenu.Link>
        )}
        {consoleAccess === ConsoleAccess.MANAGER && (
          <SideMenu.Link
            icon={<HumanWithControlPanelIcon />}
            href={managerConsoleUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === managerConsoleUrlPatterns.root}
          >
            <div className={styles.item}>
              {intl.formatMessage(navItems.managerConsole)}
              {managerConsoleNewIconFeature.isAvailable && (
                <NotificationBadge intent='primary' position='inlineEnd'>
                  {intl.formatMessage(messages.newBadge)}
                </NotificationBadge>
              )}
            </div>
          </SideMenu.Link>
        )}
        {acl.hasSecurePermission(SecurePermission.SENSITIVE_DATA_VIEW_ADMIN_UI) && (
          <SideMenu.Link
            icon={<DollarIcon />}
            href={sensitiveDataUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === sensitiveDataUrlPatterns.root}
          >
            {intl.formatMessage(navItems.sensitiveData)}
          </SideMenu.Link>
        )}
        {acl.inVpn() && (isStockOptionHolder || canSeeOthersStockOptions) && (
          <SideMenu.Link
            onClick={onOptionsLinkClick}
            icon={<CoinIcon />}
            href={stockOptionsUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === stockOptionsUrlPatterns.root}
          >
            {intl.formatMessage(navItems.options)}
          </SideMenu.Link>
        )}
        {acl.hasPermission(Permission.USER_ROLE_READ) && (
          <SideMenu.Link
            icon={<LockIcon />}
            href={userRolesUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === userRolesUrlPatterns.root}
          >
            {intl.formatMessage(navItems.roles)}
          </SideMenu.Link>
        )}
        {acl.hasSecurePermission(SecurePermission.SECURE_ROLE_READ) && (
          <SideMenu.Link
            icon={<LockIcon />}
            href={secureRolesUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === secureRolesUrlPatterns.root}
          >
            {intl.formatMessage(navItems.secureRoles)}
          </SideMenu.Link>
        )}
        {acl.hasPermission(Permission.SERVICE_READ) && (
          <SideMenu.Link
            icon={<HandWithKeyIcon />}
            href={jdmUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === jdmUrlPatterns.root}
          >
            {intl.formatMessage(navItems.jdm)}
          </SideMenu.Link>
        )}
        {acl.hasPermission(Permission.REDIRECT_READ) && (
          <SideMenu.Link
            icon={<ReplyIcon />}
            href={redirectorUrls.root()}
            isCompact={isCompact}
            isActive={matchedPathPattern === redirectorUrlPatterns.root}
          >
            {intl.formatMessage(navItems.redirector)}
          </SideMenu.Link>
        )}
        <div className={styles.section}>
          {!isCompact && <h3 className={styles.sectionTitle}>{intl.formatMessage(messages.externalLinksTitle)}</h3>}
          <SideMenu.Link
            icon={<KeyIcon />}
            href='https://joom-team.atlassian.net/servicedesk/customer/portal/27'
            isCompact={isCompact}
            kind='external'
            onClick={clickHandlers[AnalyticsElement.ACCESS_CENTER]}
          >
            {intl.formatMessage(navItems.accessCenter)}
          </SideMenu.Link>
          <SideMenu.Link
            icon={<HomeHeartIcon />}
            href='https://joom-team.atlassian.net/servicedesk/customer/portal/5'
            isCompact={isCompact}
            kind='external'
            onClick={clickHandlers[AnalyticsElement.OFFICE_CARE]}
          >
            {intl.formatMessage(navItems.officeCare)}
          </SideMenu.Link>
          <SideMenu.Link
            icon={<GraduationHatIcon />}
            href='https://www.notion.so/joomteam/People-s-Development-fc748cf9bb914e62a1b13769ce84f375'
            isCompact={isCompact}
            kind='external'
            onClick={clickHandlers[AnalyticsElement.LEARNING_AND_DEVELOPMENT]}
          >
            {intl.formatMessage(navItems.learningAndDevelopment)}
          </SideMenu.Link>
          <SideMenu.Link
            icon={<ShirtIcon />}
            href='https://merch.joom.it'
            isCompact={isCompact}
            kind='external'
            onClick={clickHandlers[AnalyticsElement.MERCH]}
          >
            {intl.formatMessage(navItems.merch)}
          </SideMenu.Link>
        </div>
        <div className={styles.section}>
          {!isCompact && <h3 className={styles.sectionTitle}>{intl.formatMessage(messages.calendarsTitle)}</h3>}
          <SideMenu.Link
            icon={<CalendarIcon />}
            href='https://calendar.google.com/calendar/r?cid=c_39866tk7kmq5bild31kktibs20@group.calendar.google.com'
            isCompact={isCompact}
            kind='external'
            onClick={clickHandlers[AnalyticsElement.BIRTHDAY_CALENDAR]}
          >
            {intl.formatMessage(navItems.birthdaysCalendar)}
          </SideMenu.Link>
          <SideMenu.Link
            icon={<CalendarIcon />}
            href='https://calendar.google.com/calendar/r?cid=c_q7aq77rlmcqkbul0a09lu65858@group.calendar.google.com'
            isCompact={isCompact}
            kind='external'
            onClick={clickHandlers[AnalyticsElement.TIME_OFF_CALENDAR]}
          >
            {intl.formatMessage(navItems.timeOffCalendar)}
          </SideMenu.Link>
        </div>
      </SideMenu>
    </div>
  );
}
