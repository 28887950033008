import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  label: {
    defaultMessage: 'Job title',
    description: 'Job title label',
  },
  recommendation: {
    defaultMessage: 'Recommended —',
    description: 'Job title recommendation',
  },
});
