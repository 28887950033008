import React from 'react';

type Props = {
  text: string;
};

export function MultilineText({text}: Props) {
  return (
    <>
      {text.split('\n').map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {index > 0 && <br />}
          {item}
        </React.Fragment>
      ))}
    </>
  );
}
