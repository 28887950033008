import {defineMessages} from 'react-intl';
import {Gender} from '.';

export const genderLabels = defineMessages<Gender>({
  [Gender.FEMALE]: {
    defaultMessage: 'Female',
    description: 'Gender',
  },
  [Gender.MALE]: {
    defaultMessage: 'Male',
    description: 'Gender',
  },
});
