import {defineMessages} from 'react-intl';
import {OfferPriority} from '.';

export const offerPriorityLabels = defineMessages<OfferPriority>({
  [OfferPriority.HOT]: {
    defaultMessage: 'Hot',
    description: 'Offer priority',
  },
  [OfferPriority.NORMAL]: {
    defaultMessage: 'Normal',
    description: 'Offer priority',
  },
  [OfferPriority.RELAXED]: {
    defaultMessage: 'Relaxed',
    description: 'Offer priority',
  },
  [OfferPriority.UNKNOWN]: {
    defaultMessage: 'Unknown',
    description: 'Offer priority',
  },
});
