import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Pagination} from '@joomcode/joom-ui/Pagination';
import {StyledLink} from 'components/ui/StyledLink';
import {StockOptionHolder} from 'domain/stockOption/holder/model';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import {StockOptionHolderLink} from 'domain/stockOption/holder/widgets/Link';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {usersUrls} from 'routes/users/urls';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';
import styles from './styles.css';

type Props = {
  holders: StockOptionHolder[];
  dataState: DataState;
  pagination: OffsetPagination;
  count: number;
  storageName: string;
  showEmploymentStatus?: boolean;
};

const getRowKey = ({user}: StockOptionHolder) => user.id;
const getRowClassName = ({user}: StockOptionHolder) => (user.isTerminated ? styles.inactiveRow : '');

export function StockOptionHoldersTable({
  holders,
  dataState,
  pagination,
  count,
  storageName,
  showEmploymentStatus,
}: Props) {
  const intl = useIntl();
  const columns: Column<StockOptionHolder>[] = useMemo(() => {
    const basicColumns: Column<StockOptionHolder>[] = [
      {
        defaultWidth: 6,
        id: ColumnId.USER,
        name: intl.formatMessage(columnNames[ColumnId.USER]),
        render: ({user}) => <StockOptionHolderLink user={user} />,
      },
      {
        defaultWidth: 4,
        id: ColumnId.LOGIN,
        name: intl.formatMessage(columnNames[ColumnId.LOGIN]),
        render: ({user}) => <StyledLink to={usersUrls.user({login: user.login})}>{user.login}</StyledLink>,
      },
      {
        defaultWidth: 4,
        id: ColumnId.GRANTED,
        align: 'right',
        name: intl.formatMessage(columnNames[ColumnId.GRANTED]),
        render: ({amount}) => <FormattedOptionsAmount amount={amount} />,
      },
      {
        defaultWidth: 4,
        id: ColumnId.AVAILABLE,
        align: 'right',
        name: intl.formatMessage(columnNames[ColumnId.AVAILABLE]),
        render: ({available}) => <FormattedOptionsAmount amount={available} />,
      },
      {
        defaultWidth: 4,
        id: ColumnId.HIRE_DATE,
        align: 'right',
        name: intl.formatMessage(columnNames[ColumnId.HIRE_DATE]),
        render: ({user}) =>
          user.hireDate ? <HumanDate format='short' value={user.hireDate} utc /> : <DataTable.CellEmpty />,
      },
    ];
    return showEmploymentStatus
      ? [
          ...basicColumns,
          {
            defaultWidth: 4,
            id: ColumnId.EMPLOYMENT_STATUS,
            name: intl.formatMessage(columnNames[ColumnId.EMPLOYMENT_STATUS]),
            render: ({user}) =>
              intl.formatMessage(
                user.isTerminated ? messages.employmentStatusTerminated : messages.employmentStatusActive,
              ),
          },
        ]
      : basicColumns;
  }, [intl, showEmploymentStatus]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={holders}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowKey={getRowKey}
      getRowClassName={getRowClassName}
      storageName={storageName}
      toolbarBottom={
        <Pagination
          disabled={dataState === DataState.LOADING}
          limit={pagination.limit}
          onLimitChange={pagination.setLimit}
          onPageChange={pagination.setPage}
          page={pagination.page}
          pagesCount={Math.ceil(count / pagination.limit)}
        />
      }
    />
  );
}
