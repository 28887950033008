import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Sorting, SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Pagination} from '@joomcode/joom-ui/Pagination';
import {CompensationReviewRequest} from 'domain/compensationReviewRequest/model';
import {formatChangeValueWithHint} from 'domain/compensationReviewRequest/model/changeValue/formatter';
import {CompensationReviewRequestLink} from 'domain/compensationReviewRequest/widgets/Link';
import {StatusBadge} from 'domain/compensationReviewRequest/widgets/StatusBadge';
import {UserLink} from 'domain/user/widgets/Link';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

type CompensationReviewRequestColumn = Column<CompensationReviewRequest> & {id: ColumnId};
type Props = {
  data: CompensationReviewRequest[];
  dataState: DataState;
  pagination: OffsetPagination;
  totalCount: number;
  ownRequestsOnly?: boolean;
};

const getRowKey = ({id}: CompensationReviewRequest) => id;

export function CompensationReviewRequestsTable({data, dataState, pagination, totalCount, ownRequestsOnly}: Props) {
  const intl = useIntl();
  const [sorting, setSorting] = useState<Sorting | undefined>({
    by: ColumnId.CREATED_AT,
    direction: SortingDirection.DESC,
  });

  const columns: CompensationReviewRequestColumn[] = useMemo(() => {
    const allColumns: CompensationReviewRequestColumn[] = [
      {
        defaultWidth: 113,
        id: ColumnId.CREATED_AT,
        name: intl.formatMessage(columnNames[ColumnId.CREATED_AT]),
        render: ({createdAt}) => <HumanDate value={createdAt} format='short' utc />,
        sortable: true,
      },
      {
        defaultWidth: 120,
        id: ColumnId.TYPE,
        name: intl.formatMessage(columnNames[ColumnId.TYPE]),
        render: (request) => <CompensationReviewRequestLink request={request} />,
      },
      {
        defaultWidth: 181,
        id: ColumnId.CREATOR,
        name: intl.formatMessage(columnNames[ColumnId.CREATOR]),
        render: ({createdBy}) => <UserLink user={createdBy} colored withAvatar={false} />,
      },
      {
        defaultWidth: 181,
        id: ColumnId.USER,
        name: intl.formatMessage(columnNames[ColumnId.USER]),
        render: ({user}) => <UserLink user={user} colored withAvatar={false} />,
      },
      {
        defaultWidth: 115,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({status}) => <StatusBadge status={status} />,
      },
      {
        defaultWidth: 100,
        id: ColumnId.COMPENSATIONS_RECORDED,
        name: intl.formatMessage(columnNames[ColumnId.COMPENSATIONS_RECORDED]),
        render: ({compensationChange, compensationChangeDate}) =>
          formatChangeValueWithHint(compensationChange, compensationChangeDate, intl),
      },
      {
        defaultWidth: 107,
        id: ColumnId.STOCK_OPTIONS_RECORDED,
        name: intl.formatMessage(columnNames[ColumnId.STOCK_OPTIONS_RECORDED]),
        render: ({stockOptionsChange, stockOptionsChangeDate}) =>
          formatChangeValueWithHint(stockOptionsChange, stockOptionsChangeDate, intl),
      },
    ];

    return ownRequestsOnly ? allColumns.filter(({id}) => id !== ColumnId.CREATOR) : allColumns;
  }, [intl, ownRequestsOnly]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={data}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowKey={getRowKey}
      sorting={sorting}
      onSort={setSorting}
      storageName='compensationReviewRequests.list'
      toolbarBottom={
        <Pagination
          disabled={dataState === DataState.LOADING}
          limit={pagination.limit}
          onLimitChange={pagination.setLimit}
          onPageChange={pagination.setPage}
          page={pagination.page}
          pagesCount={Math.ceil(totalCount / pagination.limit)}
        />
      }
    />
  );
}
