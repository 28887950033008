import {User} from '.';

const indexOfOrInf = (s: string, query: string): number => {
  const idx = s.toLowerCase().indexOf(query.toLowerCase());
  return idx === -1 ? Infinity : idx;
};

export const compareUsersByQuery = (a: User, b: User, query: string): number => {
  // Best logins match
  const idxA = indexOfOrInf(a.login, query);
  const idxB = indexOfOrInf(b.login, query);
  if (idxA !== idxB) {
    return idxA - idxB;
  }

  // If logins match is identical we look for the best match in firstName or lastName
  const minA = Math.min(indexOfOrInf(a.firstName, query), indexOfOrInf(a.lastName, query));
  const minB = Math.min(indexOfOrInf(b.firstName, query), indexOfOrInf(b.lastName, query));
  if (minA !== minB) {
    return minA - minB;
  }

  // If logins match and names match are identical, we prefer short logins
  return a.login.length - b.login.length;
};
