import {Spinner} from '@joomcode/joom-ui/Spinner';
import cn from 'classnames';
import {CrResult} from 'domain/compaRatio/model';
import {MarketLevelPosition} from 'domain/compaRatio/model/marketLevelPosition';
import {getCrIntent} from 'domain/compaRatio/utilts/getCrIntent';
import {CrValue} from 'domain/compaRatio/widgets/Value';
import React, {useMemo} from 'react';
import styles from './styles.css';

export type Props = {
  cr: CrResult;
  loading?: boolean;
};

const styleByPosition: Record<MarketLevelPosition, string> = {
  [MarketLevelPosition.AT_MARKET_LEVEL]: styles.insideRange,
  [MarketLevelPosition.ABOVE_MARKET_LEVEL]: styles.outsideRange,
  [MarketLevelPosition.BELOW_MARKET_LEVEL]: styles.outsideRange,
  [MarketLevelPosition.BOTTOM_OF_MARKET_LEVEL]: styles.onTheEdgeOfRange,
  [MarketLevelPosition.TOP_OF_MARKET_LEVEL]: styles.onTheEdgeOfRange,
};

export function CrBadge({cr, loading}: Props) {
  const intent = useMemo(() => getCrIntent(cr), [cr]);

  return (
    <div
      className={cn(styles.root, cr.marketLevelPosition && styleByPosition[cr.marketLevelPosition], {
        [styles.error]: cr.error,
      })}
    >
      <CrValue cr={cr} withLabel />
      {loading && (
        <div className={styles.loader}>
          <Spinner intent={intent} />
        </div>
      )}
    </div>
  );
}
