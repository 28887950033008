import {HintIcon} from 'components/ui/HintIcon';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export function StockOptionCalculatorHint() {
  const intl = useIntl();

  return (
    <HintIcon trigger='click'>
      <ul className={styles.list}>
        <li className={styles.item}>{intl.formatMessage(messages.granting)}</li>
        <li className={styles.item}>{intl.formatMessage(messages.currentValue)}</li>
        <li className={styles.item}>{intl.formatMessage(messages.valueChange)}</li>
        <li className={styles.item}>{intl.formatMessage(messages.buybacks)}</li>
        <li className={styles.item}>{intl.formatMessage(messages.disclaimer)}</li>
      </ul>
    </HintIcon>
  );
}
