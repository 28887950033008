import {Frequency} from '.';

export const availableBonusFrequencies = [
  Frequency.PER_MONTH,
  Frequency.PER_QUARTER,
  Frequency.SEMI_ANNUAL,
  Frequency.PER_YEAR,
  Frequency.OTHER,
];

export const availableSalaryFrequencies = [
  Frequency.PER_HOUR,
  Frequency.PER_DAY,
  Frequency.PER_MONTH,
  Frequency.PER_YEAR,
];
