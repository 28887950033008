import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  confirmationTitle: {
    defaultMessage: 'Delete salary ranges version',
    description: '[title] Salary range version deletion confirmation dialog: dialog title',
  },
  confirmationText: {
    defaultMessage: 'Are you sure you want to delete the salary range version with the effective date {effectiveDate}?',
    description: 'Salary range version deletion confirmation dialog: dialog text',
  },
  confirmationButton: {
    defaultMessage: 'Delete',
    description: '[button] Salary range version deletion confirmation dialog: confirmation button',
  },
  success: {
    defaultMessage: 'The salary range version has been deleted',
    description: 'Salary range version successful deletion text',
  },
});
