import {formatFrequency} from 'domain/frequency/model/formatter';
import {SalaryRecord} from 'domain/salaryRecord/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {getCountryName} from 'utils/country/name';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  salary: SalaryRecord;
};

export function SalaryDescription({salary}: Props) {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      {intl.formatMessage(messages.description, {
        frequency: formatFrequency(salary.frequency, intl),
        region: getCountryName(salary.region, intl),
        employmentRate: salary.employmentRate,
        showEmploymentRate: salary.employmentRate !== 1,
      })}
    </div>
  );
}
