import {FieldInput, validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {FieldPhone} from 'components/widgets/fields/FieldPhone';
import {OfferFormSectionTitle} from 'domain/offer/widgets/FormSectionTitle';
import React from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {validateLatinNameWithoutDiacritic} from 'utils/form/latinNameValidation';
import {labels, messages} from './messages';

export function OfferCandidateInfoFields() {
  const intl = useIntl();

  return (
    <>
      <OfferFormSectionTitle title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item xl={4} sm={6}>
            <FieldInput
              name='general.firstName'
              label={intl.formatMessage(labels.firstName)}
              required
              reserveSpaceForError={false}
              validate={validateLatinNameWithoutDiacritic}
            />
          </Grid.Item>
          <Grid.Item xl={4} sm={6}>
            <FieldInput
              name='general.lastName'
              label={intl.formatMessage(labels.lastName)}
              required
              reserveSpaceForError={false}
              validate={validateLatinNameWithoutDiacritic}
            />
          </Grid.Item>
          <Grid.Item xl={4} sm={12}>
            <Field<string> name='general.phone'>
              {({input, meta}) => (
                <FormControl
                  label={intl.formatMessage(labels.phone)}
                  required
                  error={getFieldErrorText(meta, {intl})}
                  reserveSpaceForError={false}
                >
                  {(formControlProps) => (
                    <FieldPhone {...formControlProps} name={input.name} validate={validateFilled} />
                  )}
                </FormControl>
              )}
            </Field>
          </Grid.Item>
        </Grid>
      </Panel.Content>
    </>
  );
}
