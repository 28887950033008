import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  daysAdded: {
    defaultMessage: 'Number of days',
    description: 'Balance record field description',
  },
  reason: {
    defaultMessage: 'Reason',
    description: 'Balance record field description',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Cancel new balance record',
  },
  submitButton: {
    defaultMessage: 'Create',
    description: '[button] Submit new balance record form',
  },
  dialogTitle: {
    defaultMessage: 'Create new balance record',
    description: '[title] Dialog title',
  },
  dialogTitleRemoteWork: {
    defaultMessage: 'Create new remote work balance record',
    description: '[title] Dialog title',
  },
  dialogTitleVacation: {
    defaultMessage: 'Create new vacation balance record',
    description: '[title] Dialog title',
  },
  dialogAriaLabel: {
    defaultMessage: 'Create new balance record',
    description: 'Balance record: aria label',
  },
  addDaysButton: {
    defaultMessage: 'Add days',
    description: '[button] Radio button to chose which operation to use',
  },
  subtractDaysButton: {
    defaultMessage: 'Subtract days',
    description: '[button] Radio button to chose which operation to use',
  },
  expectedBalanceHint: {
    defaultMessage: 'Expected balance {days}',
    description: 'Total balance hint',
  },
});
