import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {Employment} from 'domain/employment/model';
import {getEmploymentsByUserIdFx} from 'domain/employment/stores/main';
import {$employments} from 'domain/employment/stores/main/state';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: Employment[];
  dataState: DataState;
  error?: Error;
};

type Options = {
  enabled?: boolean;
  force?: boolean;
  handleError?: boolean;
};

export function useUsersEmployments(
  userId: UserId,
  {enabled = true, force = false, handleError = true}: Options = {},
): ReturnShape {
  const acl = useAcl();
  const canReadAll = acl.hasPermission(Permission.USER_EMPLOYMENT_READ);
  const [error, setError] = useState<Error>();
  const [data, dataState] = useStoreMap({
    store: $employments,
    keys: [userId],
    fn: ({byUserId, dataStateByUserId}, [id]) => [byUserId[id] || [], dataStateByUserId[id] || DataState.IDLE],
  });

  useEffect(() => {
    if (enabled && canReadAll && (!isLoadingOrLoaded(dataState) || force)) {
      getEmploymentsByUserIdFx(userId).catch(setError);
    }
  }, [userId, canReadAll, enabled, force]);

  useEffect(() => {
    if (error && handleError) {
      toaster.error(error.message);
    }
  }, [error, handleError]);

  return {data, dataState, error};
}
