import debounce from 'lodash/debounce';
import {useCallback, useMemo, useState} from 'react';
import {useIsMounted} from './useIsMounted';

export function useDebouncedValue<T>(initialValue: T, timeout: number) {
  const isMounted = useIsMounted();
  const [value, internalSetValue] = useState<T>(initialValue);
  const [debouncedValue, internalSetDebouncedValue] = useState<T>(initialValue);
  const setDebouncedValue = useMemo(
    () =>
      debounce((innerValue: T) => {
        if (isMounted()) {
          internalSetDebouncedValue(innerValue);
        }
      }, timeout),
    [internalSetDebouncedValue, timeout],
  );
  const setValue = useCallback(
    (newValue: T) => {
      internalSetValue(newValue);
      setDebouncedValue(newValue);
    },
    [internalSetValue, setDebouncedValue],
  );

  return {
    debouncedValue,
    setValue,
    value,
  };
}
