import {intranetApi} from 'apiClient';
import {Team, teamSchema} from 'domain/team/model';
import {TeamDiff} from 'domain/team/model/diff';
import {TeamId} from 'domain/team/model/id';

export const create = async ({parentTeamId, diff}: {parentTeamId: TeamId; diff: TeamDiff}): Promise<Team> => {
  const {data} = await intranetApi.post('/v1/teams/create', diff, {
    params: {parentTeamId},
  });

  return teamSchema.runWithException(data);
};
