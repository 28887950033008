import {Panel} from '@joomcode/joom-ui/Panel';
import {SalaryRecord} from 'domain/salaryRecord/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  salary: SalaryRecord;
};

export function SalaryHeader({salary}: Props) {
  const intl = useIntl();

  return <Panel.Header title={intl.formatMessage(messages.title, {legalEntity: salary.legalEntity.title})} />;
}
