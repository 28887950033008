import {getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {Field} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Checkbox} from '@joomcode/joom-ui/Checkbox';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {JobContractRecord} from 'domain/jobContract/model/record';
import {JobContractRecordDiff} from 'domain/jobContract/model/record/diff';
import {createJobContractRecordFx, updateJobContractRecordFx} from 'domain/jobContract/stores/main';
import {JobContractRecordForm} from 'domain/jobContract/widgets/Form';
import {JobContractRecordFormWithOnHold} from 'domain/jobContract/widgets/FormWithOnHold';
import {LegalEntityId} from 'domain/legalEntity/model';
import {useUserTimeOffBalances} from 'domain/user/hooks/useUserTimeOffBalances';
import {UserFull} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {Feature, useFeature} from 'services/features';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  user: UserFull;
  legalEntityId?: LegalEntityId;
  recordToUpdate?: JobContractRecord;
  activeRecord?: JobContractRecord;
  isPrimary?: boolean;
  isOpen: boolean;
  onClose(): void;
};

type FormState = JobContractRecordDiff & {
  legalEntityId: LegalEntityId;
};
type FormSubmitHandler = FormProps<FormState>['onSubmit'];

export function JobContractRecordDialog({
  user,
  legalEntityId,
  recordToUpdate,
  activeRecord,
  isPrimary,
  isOpen,
  onClose,
}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const onHoldFeature = useFeature(Feature.JOB_CONTRACT_ON_HOLD);
  const companyUnits = useCompanyUnits();
  const timeOffBalances = useUserTimeOffBalances(user);
  const dataState = getConsolidatedDataState(companyUnits.dataState, timeOffBalances.dataState);
  const renderError = useCallback(
    () => (
      <Dialog.Body withDefaultPadding>
        <Error message={intl.formatMessage(messages.loadingError)} />
      </Dialog.Body>
    ),
    [intl],
  );
  const onSubmit: FormSubmitHandler = useCallback(
    ({legalEntityId, ...state}) => {
      if (recordToUpdate) {
        return updateJobContractRecordFx({id: recordToUpdate.id, userId: user.id, ...state})
          .then(onClose)
          .catch(toaster.interceptThenThrowError);
      }

      return createJobContractRecordFx({userId: user.id, legalEntityId, ...state})
        .then(onClose)
        .catch(toaster.interceptThenThrowError);
    },
    [user.id, recordToUpdate, onClose],
  );

  return (
    <Dialog
      isOpen={isOpen}
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      onClose={onClose}
      width='min(90vw, 48rem)'
    >
      <Form<FormState> onSubmit={onSubmit} subscription={{submitting: true, invalid: true, values: true}}>
        {({handleSubmit, submitting, invalid, values}) => (
          <StateHandler
            data={
              companyUnits.units && timeOffBalances.data && {units: companyUnits.units, balances: timeOffBalances.data}
            }
            state={dataState}
            renderError={renderError}
          >
            {({units, balances}) => {
              const resettingBalanceIsAvailable =
                isPrimary &&
                activeRecord &&
                values.countryOfTimeOffPolicy &&
                values.countryOfTimeOffPolicy !== activeRecord?.countryOfTimeOffPolicy &&
                (!recordToUpdate || recordToUpdate?.id === activeRecord.id);
              return (
                <>
                  <Dialog.Header>
                    {intl.formatMessage(recordToUpdate ? messages.titleUpdate : messages.titleAdd)}
                  </Dialog.Header>
                  <Dialog.Body withDefaultPadding>
                    {onHoldFeature.isAvailable ? (
                      <JobContractRecordFormWithOnHold
                        companyUnits={units}
                        values={values}
                        legalEntityId={legalEntityId}
                        recordToUpdate={recordToUpdate}
                        activeRecord={activeRecord}
                        submitting={submitting}
                        resettingBalanceIsAvailable={resettingBalanceIsAvailable}
                        formId={formId}
                        onSubmit={handleSubmit}
                      />
                    ) : (
                      <JobContractRecordForm
                        legalEntityId={legalEntityId}
                        recordToUpdate={recordToUpdate}
                        submitting={submitting}
                        formId={formId}
                        onSubmit={handleSubmit}
                      />
                    )}
                  </Dialog.Body>
                  <Dialog.Footer align='spaceBetween'>
                    {resettingBalanceIsAvailable && balances.availableVacationDays > 0 ? (
                      <Field<boolean> name='resetBalance' type='checkbox' initialValue={false}>
                        {({input: {value, ...input}}) => (
                          <Checkbox
                            {...input}
                            label={intl.formatMessage(messages.resetBalance, {
                              currentBalance: balances.availableVacationDays,
                            })}
                            disabled={submitting}
                          />
                        )}
                      </Field>
                    ) : (
                      <div /> // For correct buttons placement
                    )}
                    <ButtonGroup spaced size='l'>
                      <Button kind='text' intent='neutral' onClick={onClose}>
                        {intl.formatMessage(messages.cancelButton)}
                      </Button>
                      <Button
                        form={formId}
                        kind='primary'
                        intent='primary'
                        type='submit'
                        disabled={invalid}
                        loading={submitting}
                      >
                        {intl.formatMessage(messages.submitButton)}
                      </Button>
                    </ButtonGroup>
                  </Dialog.Footer>
                </>
              );
            }}
          </StateHandler>
        )}
      </Form>
    </Dialog>
  );
}
