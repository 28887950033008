import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {JobContract, jobContractSchema} from 'domain/jobContract/model';
import {GetJobContractsParams} from './getByUserId';

export const getSubordinate = async (params: GetJobContractsParams): Promise<JobContract[]> => {
  const {data} = await intranetApi.get('/v1/users/jobContractRecords/getAllAsManager', {params});

  return array(jobContractSchema).runWithException(data);
};
