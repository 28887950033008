import cn from 'classnames';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {FC, ReactNode, useContext, useMemo} from 'react';
import {NotificationBadge} from '../../NotificationBadge';
import {SideMenuContext} from '../context';
import styles from './index.css';
import {SideMenuItemProps} from './types';
import {TooltipAlt} from '../../TooltipAlt';

type SideMenuItemRenderProps = {
  className: string;
  children: ReactNode;
};

type Props = SideMenuItemProps & {
  render: (navItem: SideMenuItemRenderProps) => ReactNode;
};

export const SideMenuItem: FC<Props> = function SideMenuItem({
  addon,
  iconRight,
  badge,
  icon,
  children,
  disabled,
  isActive,
  isCompact = false,
  isOpen,
  render,
  subItems,
  tooltip,
}) {
  const {level} = useContext(SideMenuContext);
  const isTopLevel = level === 0;
  const inPopup = isCompact ? level > 0 : level > 1;
  const isCompactSelf = isCompact && isTopLevel;

  const content = useMemo(
    () =>
      !isOpen ? (
        <TooltipAlt content={children} enabled={isCompactSelf} placement='right'>
          <span className={styles.icon}>{icon}</span>
        </TooltipAlt>
      ) : (
        <span className={styles.icon}>{icon}</span>
      ),
    [isOpen, children, isCompactSelf],
  );

  return (
    <div className={styles.container}>
      <TooltipAlt content={tooltip} enabled={!!tooltip}>
        <span>
          {render({
            children: (
              <span className={styles.content}>
                {icon || (!isCompact && level === 1) ? content : null}
                {!isCompactSelf && <div className={styles.label}>{children}</div>}
                {badge && (
                  <span className={styles.badge}>
                    <NotificationBadge intent={badge} position={isCompactSelf ? 'topEnd' : 'inlineEnd'}>
                      {!isCompactSelf ? '!' : undefined}
                    </NotificationBadge>
                  </span>
                )}
                {!isCompactSelf &&
                  React.Children.map(
                    addon,
                    (addonItem) => addonItem && <div className={styles.addon}>{addonItem}</div>,
                  )}
                {isNotNullish(iconRight) && <span className={styles.iconRight}>{iconRight}</span>}
              </span>
            ),
            className: cn(styles.navItem, {
              [styles.navDisabled]: disabled,
              [styles.navItemActive]: isActive,
              [styles.navItemCompact]: isTopLevel && isCompact,
              [styles.navItemInPopup]: inPopup,
            }),
          })}
        </span>
      </TooltipAlt>

      {subItems}
    </div>
  );
};
