import {isNotNullish} from '@joomcode/deprecated-utils/function';
import {SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import React, {ReactNode} from 'react';
import {FormattedNumber, FormatNumberOptions} from 'react-intl';
import {Column} from '../types';
import {CellEmpty} from '../components/CellEmpty';
import {Nullable} from './types';
import {CellNumeric} from '../components/CellNumeric';

export type Options<Item, NestedItem = never> = Omit<Column<Item, NestedItem>, 'render' | 'renderNested'> & {
  format?: FormatNumberOptions;
  getValue: (item: Item) => Nullable<number>;
  render?: (item: Item, formattedValue: ReactNode) => ReactNode;
  getNestedValue?: (nestedItem: NestedItem) => Nullable<number>;
  renderNested?: (nestedItem: NestedItem, item: Item, formattedValue: ReactNode) => ReactNode;
};

export function createColumnNumber<Item, NestedItem>({
  format,
  getValue,
  getNestedValue,
  render,
  renderNested,
  sortable,
  summary,
  ...rest
}: Options<Item, NestedItem>): Column<Item, NestedItem> {
  const formatValue = (value: number) => <FormattedNumber {...format} value={value} />;

  return {
    align: 'right',
    noWrap: true,
    sortable: sortable === true ? [SortingDirection.DESC, SortingDirection.ASC] : sortable,
    summary: summary && {
      ...summary,
      value: typeof summary.value === 'number' ? formatValue(summary.value) : summary.value,
    },

    render(item) {
      const value = getValue(item);

      if (isNotNullish(value)) {
        return <CellNumeric>{render ? render(item, formatValue(value)) : formatValue(value)}</CellNumeric>;
      }
      return <CellEmpty />;
    },

    renderNested(nestedItem, item) {
      const value = getNestedValue?.(nestedItem);

      if (isNotNullish(value)) {
        return (
          <CellNumeric>
            {renderNested ? renderNested(nestedItem, item, formatValue(value)) : formatValue(value)}
          </CellNumeric>
        );
      }
      return <CellEmpty />;
    },

    ...rest,
  };
}
