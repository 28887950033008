import {array, ExtractSchemaType, number, object} from '@joomcode/deprecated-utils/jsonValidation';
import {userSchema} from 'domain/user/model';

export const stockOptionHolderSchema = object({
  user: userSchema,
  amount: number(),
  available: number(),
});

export const countableStockOptionHolderSchema = object({
  items: array(stockOptionHolderSchema),
  count: number(),
});

export type StockOptionHolder = ExtractSchemaType<typeof stockOptionHolderSchema>;
export type CountableStockOptionHolders = ExtractSchemaType<typeof countableStockOptionHolderSchema>;
