import {regularBonusRecordsApi} from 'domain/regularBonusRecord/api';
import {
  createRegularBonusRecordFx,
  deleteRegularBonusRecordFx,
  getMyRegularBonusRecordsFx,
  getRegularBonusRecordsFx,
  getSubordinateRegularBonusRecordsFx,
  replaceRegularBonusRecordFx,
  updateRegularBonusRecordFx,
} from '.';

createRegularBonusRecordFx.use(regularBonusRecordsApi.create);
deleteRegularBonusRecordFx.use(regularBonusRecordsApi.delete);
getRegularBonusRecordsFx.use(regularBonusRecordsApi.getAll);
getMyRegularBonusRecordsFx.use(regularBonusRecordsApi.getMy);
getSubordinateRegularBonusRecordsFx.use(regularBonusRecordsApi.getSubordinate);
replaceRegularBonusRecordFx.use(regularBonusRecordsApi.replace);
updateRegularBonusRecordFx.use(regularBonusRecordsApi.update);
