import delay from 'delay';
import {DependencyList, useCallback, useState} from 'react';
import {DataState} from '../dataState';
import {useIsMounted} from './useIsMounted';

export type AsyncTaskFn<Args extends unknown[], Result> = (...args: Args) => Promise<Result>;

export type AsyncTask<Args extends unknown[], Result> = {
  dataState: DataState;
  error: unknown | undefined;
  isPerforming: boolean;
  perform: AsyncTaskFn<Args, Result>;
  result: Result | undefined;
  reset: () => void;
};

type Options = {
  /**
   * Allows to prevent UI blinking when task is performed too fast
   */
  minDuration?: number;
  saveResultWhileLoading?: boolean;
};

export function useAsyncTask<Args extends unknown[], Result>(
  task: AsyncTaskFn<Args, Result>,
  deps: DependencyList,
  {minDuration = 0, saveResultWhileLoading = false}: Options = {},
): AsyncTask<Args, Result> {
  const isMounted = useIsMounted();
  const [dataState, setDataState] = useState<DataState>(DataState.IDLE);
  const [result, setResult] = useState<Result>();
  const [error, setError] = useState<unknown>();
  const reset = useCallback(() => {
    setError(undefined);
    setResult(undefined);
    setDataState(DataState.IDLE);
  }, []);
  const perform = useCallback(async (...args: Args) => {
    try {
      if (!saveResultWhileLoading) {
        setResult(undefined);
      }
      setDataState(DataState.LOADING);
      const [innerResult] = await Promise.all([task(...args), delay(minDuration)]);

      if (isMounted()) {
        setResult(innerResult);
        setDataState(DataState.LOADED);
      }

      return innerResult;
    } catch (innerError) {
      if (isMounted()) {
        setDataState(DataState.FAILED);
        setError(innerError);
      }

      throw innerError;
    }
  }, deps);

  return {
    dataState,
    error,
    isPerforming: dataState === DataState.LOADING,
    perform,
    result,
    reset,
  };
}
