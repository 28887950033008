import {array, ExtractSchemaType, number, object, oneOfEnum, string} from '@joomcode/deprecated-utils/jsonValidation';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export enum BulkUploadStatus {
  APPLIED = 'applied',
  CREATED = 'created',
  CREATED_WITH_ERRORS = 'createdWithErrors',
  ERROR = 'error',
}

export enum BulkUploadRowStatus {
  ERROR = 'error',
  SUCCESS = 'success',
  NOT_CHANGED = 'notChanged',
  REPLACE = 'replace',
}

export const bulkUploadMetaSchema = object({
  errors: optionalWithDefault([], array(string())),
  row: number(),
  status: oneOfEnum(BulkUploadRowStatus),
});

export type BulkUploadMeta = ExtractSchemaType<typeof bulkUploadMetaSchema>;
