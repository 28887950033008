import {DataFilter, DataFilterProps, DataFilterLocator} from './DataFilter';
import {Control} from './Control';

export * from './FilterCreators';
export * from './types';
export * from './utils';

Object.assign(DataFilter, {
  Control, // eslint-disable-line @typescript-eslint/naming-convention
});

export {DataFilter};
export type {DataFilterProps, DataFilterLocator};
