import React from 'react';
import {ColumnsSelect} from '../../components/ColumnsSelect';
import {ColumnsTreeSelect} from '../../components/ColumnsTreeSelect';
import {Column, ColumnTreeOptions} from '../../types';
import styles from './index.css';

export const CONFIG_COLUMN_ID = '__config';

type Options<Item> = {
  ariaLabel: string;
  availableColumns: Column<Item>[];
  visibleColumnIds: string[];
  columnTreeOptions?: ColumnTreeOptions;
  onColumnsVisibilityChange: (visibleColumns: string[]) => void;
};
export function getConfigColumn<Item>({columnTreeOptions, ...options}: Options<Item>): Column<Item> | null {
  return {
    id: CONFIG_COLUMN_ID,
    defaultWidth: 64,
    resizable: false,
    noStretch: true,
    sticky: 'right',
    align: 'right',
    name: (
      <div className={styles.configCell}>
        {columnTreeOptions ? (
          <ColumnsTreeSelect columnTreeOptions={columnTreeOptions} {...options} />
        ) : (
          <ColumnsSelect {...options} />
        )}
      </div>
    ),
    render: () => null,
  };
}
