import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {getUserInterviewFeedbackLinkFx} from 'domain/hiringProcessInfo/stores/linkToInterviewFeedback';
import {$userLinksToInterviewFeedback} from 'domain/hiringProcessInfo/stores/linkToInterviewFeedback/state';
import {UserFull} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';
import {useHiringProcessAccess} from './useHiringProcessAccess';

type ReturnShape = {
  interviewFeedbackLink: string | undefined;
  dataState: DataState;
};

export function useInterviewFeedbackLink(user: UserFull): ReturnShape {
  const {canReadInterviewFeedbackLink} = useHiringProcessAccess(user);
  const interviewFeedbackLink = useStoreMap({
    store: $userLinksToInterviewFeedback,
    keys: [user.id],
    fn: ({byUserId}, [userId]) => byUserId[userId],
  });
  const dataState = useStoreMap({
    store: $userLinksToInterviewFeedback,
    keys: [user.id],
    fn: ({dataStateByUserId}, [userId]) => dataStateByUserId[userId] || DataState.IDLE,
  });

  useEffect(() => {
    if (canReadInterviewFeedbackLink && !isLoadingOrLoaded(dataState)) {
      getUserInterviewFeedbackLinkFx(user.id).catch((error: Error) => toaster.error(error.message));
    }
  }, [canReadInterviewFeedbackLink, user.id]);

  return {
    interviewFeedbackLink,
    dataState,
  };
}
