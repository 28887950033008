import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {CreateSalaryRecordParams} from 'domain/salaryRecord/api/types/create';
import {ExtendedSalaryRecord, extendedSalaryRecordSchema} from 'domain/salaryRecord/model';

export const createExtended = async ({diff, ...params}: CreateSalaryRecordParams): Promise<ExtendedSalaryRecord[]> => {
  const {data} = await intranetApi.post('/v1/secure/users/salaryRecords/createExtended', diff, {params});

  return array(extendedSalaryRecordSchema).runWithException(data);
};
