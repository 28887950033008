import {defineMessages} from 'react-intl';
import {TimeOffRequestStatus} from '.';

export const timeOffRequestStatuses = defineMessages<TimeOffRequestStatus>({
  [TimeOffRequestStatus.APPROVED]: {
    defaultMessage: 'Approved',
    description: 'Time off status',
  },
  [TimeOffRequestStatus.CANCELED]: {
    defaultMessage: 'Canceled',
    description: 'Time off status',
  },
  [TimeOffRequestStatus.REJECTED]: {
    defaultMessage: 'Rejected',
    description: 'Time off status',
  },
  [TimeOffRequestStatus.REQUESTED]: {
    defaultMessage: 'Requested',
    description: 'Time off status',
  },
});
