import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {PageTabs} from 'components/ui/PageTabs';
import {Permission} from 'domain/permission/model';
import {useFormModeAccess} from 'domain/user/hooks/useFormModeAccess';
import {UserId} from 'domain/user/model';
import {UserFormMode} from 'domain/user/model/formMode';
import React from 'react';
import {useIntl} from 'react-intl';
import {usersUrlPatterns, usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {messages, modeTabNames} from './messages';

export type Props = {
  activeMode?: UserFormMode;
  login: string;
  matchedPathPattern?: string;
  userId?: UserId;
};

export function EditPageTabs({activeMode, login, matchedPathPattern, userId}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const tabVisibilityByMode = useFormModeAccess({userId});

  return (
    <PageTabs>
      {getEnumValues(UserFormMode).map((tabMode) => (
        <PageTabs.Tab
          title={intl.formatMessage(modeTabNames[tabMode])}
          path={usersUrls.userEdit({login}, {mode: tabMode})}
          active={activeMode === tabMode}
          hidden={!tabVisibilityByMode[tabMode]}
          key={tabMode}
        />
      ))}
      <PageTabs.Tab
        title={intl.formatMessage(messages.editLegalInfoTab)}
        path={usersUrls.userEditLegalInfo({login})}
        active={matchedPathPattern === usersUrlPatterns.userEditLegalInfo}
        hidden={!acl.hasPermission(Permission.USER_LEGAL_INFO_WRITE)}
      />
    </PageTabs>
  );
}
