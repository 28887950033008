import {getEnumFormatter} from '@joomcode/deprecated-utils/intl/getEnumFormatter';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {HelpIcon} from 'domain/compensationReviewRequest/widgets/HelpIcon';
import React from 'react';
import {IntlShape} from 'react-intl';
import {ChangeValue} from '.';
import {changeValueMessages, messages} from './messages';

export const formatChangeValue = getEnumFormatter(changeValueMessages);
export const formatChangeValueWithHint = (value: ChangeValue, date: string | undefined, intl: IntlShape) => {
  const message = formatChangeValue(value, intl);

  return value === ChangeValue.RECORDED ? (
    <>
      {message}
      {date && (
        <HelpIcon
          hint={intl.formatMessage(messages.hint, {
            date: <HumanDate value={date} format='short' utc />,
          })}
        />
      )}
    </>
  ) : (
    message
  );
};
