import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {isBlank} from '@joomcode/deprecated-utils/string/isBlank';
import type {ValidationFunction} from '../../types';
import {EmailValidatorErrorCode, EmailValidatorOptions} from './types';

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail: ValidationFunction<unknown, EmailValidatorOptions, EmailValidatorErrorCode> = async (
  value,
  options = {},
) => {
  if (isNullish(value) || (typeof value === 'string' && isBlank(value))) return undefined;

  if (typeof value !== 'string') {
    return {code: EmailValidatorErrorCode.EMAIL_INVALID, options};
  }

  if (!emailRegExp.test(value.trim())) {
    return {code: EmailValidatorErrorCode.EMAIL_INVALID, options};
  }

  return undefined;
};
