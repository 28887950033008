import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {DataTable} from '@joomcode/joom-ui/DataTable';
import {SkeletonShape} from '@joomcode/joom-ui/Skeleton';
import {SalaryRange} from 'domain/compensationReviewRequest/widgets/SalaryRange';
import {salaryRangesApi} from 'domain/salaryRange/api';
import {SalaryRangeParams} from 'domain/salaryRange/api/getByParams';
import {NullableSalaryRange} from 'domain/salaryRange/model';
import {convertToAnnual} from 'domain/salaryRange/utils/convertToAnnual';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = SalaryRangeParams & {
  isAnnual?: boolean;
};

export function CalculatableSalaryRange({levelCode, region, userFunction, isAnnual}: Props) {
  const intl = useIntl();
  const {change} = useForm();
  const [salaryRange, setSalaryRange] = useState<NullableSalaryRange>();
  const getSalaryRange = useAsyncTask<[SalaryRangeParams], void>(
    (params) =>
      salaryRangesApi.getByParams(params).then((result) => {
        setSalaryRange(result && isAnnual ? convertToAnnual(result) : result);
        change('newSalaryRange', result);
      }),
    [setSalaryRange],
  );

  useEffect(() => {
    getSalaryRange.perform({levelCode, region, userFunction});
  }, [levelCode, region, userFunction]);

  if (getSalaryRange.isPerforming) {
    return <SkeletonShape kind='rectangle' width='100%' height='3.5rem' />;
  }

  if (getSalaryRange.error || salaryRange === null) {
    return <div className={styles.error}>{intl.formatMessage(messages.loadingError)}</div>;
  }

  if (salaryRange === undefined) {
    return <DataTable.CellEmpty />;
  }

  return <SalaryRange salaryRange={salaryRange} />;
}
