import {intranetApi} from 'apiClient';
import {
  CompensationRequest,
  CompensationRequestId,
  compensationRequestSchema,
} from 'domain/compensations/request/model';

export type CompensationRequestRejectionDiff = {
  comment: string;
};

export const reject = async ({
  diff,
  id,
}: {
  diff: CompensationRequestRejectionDiff;
  id: CompensationRequestId;
}): Promise<CompensationRequest> => {
  const {data} = await intranetApi.post('/v1/compensations/requests/reject', diff, {
    params: {id},
  });

  return compensationRequestSchema.runWithException(data);
};
