import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Salary record dialog',
    description: 'Salary record dialog: aria label',
  },
  titleAdd: {
    defaultMessage: 'Add salary record',
    description: '[title] Salary record dialog: title (addition mode)',
  },
  titleUpdate: {
    defaultMessage: 'Update salary record',
    description: '[title] Salary record dialog: title (update mode)',
  },
  titleReplace: {
    defaultMessage: 'Replace salary record with a new one',
    description: '[title] Salary record dialog: title (replace mode)',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Salary record dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Submit',
    description: '[button] Salary record dialog: submit button',
  },
  loadingError: {
    defaultMessage: 'Failed to load company units data. Try to refresh the page.',
    description: 'Salary record dialog: error message',
  },
});
