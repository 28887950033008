import React, {memo} from 'react';
import {getFlexibleColumns} from '../../utils/getFlexibleColumns';
import {RowProps} from '../Row';
import {SummaryCell} from '../SummaryCell';
import styles from './index.css';

type SummaryRowProps = {
  columns: RowProps<unknown, never>['columns'];
  getColumnOffset: RowProps<unknown, never>['getColumnOffset'];
  getColumnWidth: RowProps<unknown, never>['getColumnWidth'];
};

export const SummaryRow = memo(function SummaryRow({columns, getColumnOffset, getColumnWidth}: SummaryRowProps) {
  const flexibleColumns = getFlexibleColumns(columns, {
    isFlexible: (column) => !!column.summary?.flexible,
    hasContent: (column) => !!column.summary?.value,
  });

  return (
    <tbody className={styles.row}>
      <tr>
        {flexibleColumns.columns.map((column) => (
          <SummaryCell
            align={column.align}
            colSpan={flexibleColumns.getCellColSpan(column)}
            noWrap={column.noWrap}
            value={column.summary?.value}
            hint={column.summary?.hint}
            stickyOffset={getColumnOffset(column)}
            width={getColumnWidth(column)}
            key={column.id}
          />
        ))}
      </tr>
    </tbody>
  );
});
