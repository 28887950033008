import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {getAllOutstaffUsersFx} from 'domain/outstaffUser/stores/main';
import {$outstaffUsers} from 'domain/outstaffUser/stores/main/state';
import {Permission} from 'domain/permission/model';
import {User, UserId} from 'domain/user/model';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  outstaffUsers: User[];
  outstaffUsersById: Record<UserId, User>;
  dataState: DataState;
};

export function useOutstaffUsers(): ReturnShape {
  const acl = useAcl();
  const {outstaffUsers, outstaffUsersById, dataState} = useStore($outstaffUsers);

  useEffect(() => {
    if (acl.hasPermission(Permission.OUTSTAFF_USER_READ) && !isLoadingOrLoaded(dataState)) {
      getAllOutstaffUsersFx().catch((error) => {
        toaster.interceptThenThrowError(error);
      });
    }
  }, [acl]);

  return {outstaffUsers, outstaffUsersById, dataState};
}
