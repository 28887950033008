import {DataState} from '@joomcode/deprecated-utils/dataState';
import {OffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Pagination} from '@joomcode/joom-ui/Pagination';
import {Offer} from 'domain/offer/model';
import {ColumnId} from 'domain/offer/model/table/columnId';
import {columnNames} from 'domain/offer/model/table/messages';
import {OfferLink} from 'domain/offer/widgets/Link';
import {ReferrersHint} from 'domain/offer/widgets/ReferrersHint';
import {OfferStatusBadge} from 'domain/offer/widgets/StatusBadge';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {formatFullName} from 'utils/format/formatFullName';
import {messages} from './messages';

type OfferColumn = Column<Offer> & {id: ColumnId};
type Props = {
  offers: Offer[];
  dataState: DataState;
  pagination: OffsetPagination;
  count: number;
};

const getRowKey = ({id}: Offer) => id;

export function OfferTable({offers, dataState, pagination, count}: Props) {
  const intl = useIntl();
  const columns = useMemo<OfferColumn[]>(() => {
    return [
      {
        defaultWidth: 7,
        id: ColumnId.FULL_NAME_OFFER,
        name: intl.formatMessage(columnNames[ColumnId.FULL_NAME_OFFER]),
        render: (offer) => <OfferLink offer={offer} />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.DIVISION,
        name: intl.formatMessage(columnNames[ColumnId.DIVISION]),
        render: ({versions}) => versions.at(-1)?.employmentInfo.division ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.JOB_TITLE,
        name: intl.formatMessage(columnNames[ColumnId.JOB_TITLE]),
        render: ({versions}) => versions.at(-1)?.employmentInfo.jobTitle ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 7,
        id: ColumnId.FULL_NAME_APPLICATION,
        name: intl.formatMessage(columnNames[ColumnId.FULL_NAME_APPLICATION]),
        render: ({newcomer}) =>
          formatFullName(newcomer?.firstName, newcomer?.lastName, intl) ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.START_DATE,
        name: intl.formatMessage(columnNames[ColumnId.START_DATE]),
        render: ({newcomer}) =>
          newcomer?.startWorkingDate ? (
            <HumanDate value={newcomer.startWorkingDate} format='short' utc />
          ) : (
            <DataTable.CellEmpty />
          ),
      },
      {
        defaultWidth: 5,
        id: ColumnId.STATUS,
        name: intl.formatMessage(columnNames[ColumnId.STATUS]),
        render: ({status}) => <OfferStatusBadge status={status} />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.LOGIN,
        name: intl.formatMessage(columnNames[ColumnId.LOGIN]),
        render: ({newcomer}) => newcomer?.login ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.LEGAL_ENTITY,
        name: intl.formatMessage(columnNames[ColumnId.LEGAL_ENTITY]),
        render: ({jobContract}) => jobContract?.legalEntity?.title ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 3,
        id: ColumnId.COUNTRY,
        name: intl.formatMessage(columnNames[ColumnId.COUNTRY]),
        render: ({versions}) => versions.at(-1)?.employmentInfo.employmentCountry ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 3,
        id: ColumnId.REFERRERS,
        name: intl.formatMessage(columnNames[ColumnId.REFERRERS]),
        render: ({general}) =>
          general.referrers?.length > 0 ? <ReferrersHint referrers={general.referrers} /> : <DataTable.CellEmpty />,
      },
    ];
  }, [intl]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={offers}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowKey={getRowKey}
      storageName='offers.list'
      toolbarBottom={
        <Pagination
          disabled={dataState === DataState.LOADING}
          limit={pagination.limit}
          onLimitChange={pagination.setLimit}
          onPageChange={pagination.setPage}
          page={pagination.page}
          pagesCount={Math.ceil(count / pagination.limit)}
        />
      }
    />
  );
}
