import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  menuAriaLabel: {
    defaultMessage: 'Work history record menu',
    description: 'Job Info record menu: icon label',
  },
  edit: {
    defaultMessage: 'Edit',
    description: 'Job Info record menu: item',
  },
  delete: {
    defaultMessage: 'Delete',
    description: 'Job Info record menu: item',
  },
});
