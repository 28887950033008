import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {useTeamAcl} from 'domain/team/hooks/useTeamAcl';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import {TeamCreationDialog} from 'domain/team/widgets/CreationDialog';
import {TeamsTreePanel} from 'domain/team/widgets/TreePanel';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  teamById: Record<TeamId, Team | undefined>;
  team: Team;
};

export function SubteamsPanel({teamById, team}: Props) {
  const intl = useIntl();
  const creationDialog = usePopupState();
  const {canCreateTeam} = useTeamAcl(team.id);

  return (
    <>
      <TeamsTreePanel
        controls={
          canCreateTeam && (
            <Button size='m' kind='primary' intent='neutral' type='submit' onClick={creationDialog.open}>
              {intl.formatMessage(messages.btnCreateSubteam)}
            </Button>
          )
        }
        emptyMessage={intl.formatMessage(messages.noSubteams)}
        title={intl.formatMessage(messages.title)}
        isExpandedByDefault={false}
        subtreeParentId={team.id}
        teamById={teamById}
      />
      {creationDialog.isOpen && (
        <TeamCreationDialog
          parentTeamId={team.id}
          title={intl.formatMessage(messages.createSubteamDialogHeader, {teamName: team.name})}
          onClose={creationDialog.close}
        />
      )}
    </>
  );
}
