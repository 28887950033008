import get from 'lodash/get';
import {ArrayValidator, ObjectValidator, UnknownValidationFunction} from './types';

export function array(itemValidator: ArrayValidator | ObjectValidator | UnknownValidationFunction): ArrayValidator {
  return async (arrayValue) => {
    const errorArray = [];
    const length = Array.isArray(arrayValue) ? arrayValue.length : 0;
    for (let i = 0; i < length; i++) {
      // eslint-disable-next-line no-await-in-loop
      const error = await itemValidator(get(arrayValue, i));
      if (error) {
        errorArray[i] = error;
      }
    }

    return errorArray.length ? errorArray : undefined;
  };
}
