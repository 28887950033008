import type {FormatNumberOptions} from 'react-intl';
import {createColumnNumber, Options} from './createColumnNumber';
import {Column} from '../types';

const formatPercentOptions: FormatNumberOptions = {
  style: 'percent',
  maximumFractionDigits: 2,
};

export function createColumnPercent<Item>({format, ...rest}: Options<Item>): Column<Item> {
  return createColumnNumber({
    format: {
      ...formatPercentOptions,
      ...format,
    },
    ...rest,
  });
}
