import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Review comments',
    description: '[title] Compensation review request comments panel title',
  },
  hint: {
    defaultMessage: 'On behalf of {user}',
    description: '[hint] Compensation review request comments panel: comment author hint',
  },
});
