import {identity} from '@joomcode/deprecated-utils/function';
import {FieldInputNumber, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Panel} from '@joomcode/joom-ui/Panel';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {getClosestBusinessDayInNextMonth} from 'domain/compensationReviewRequest/utils/date';
import {RemovableSectionHeader} from 'domain/compensationReviewRequest/widgets/RemovableSectionHeader';
import {FieldCurrency} from 'domain/currency/widgets/Field';
import {availableBonusFrequencies} from 'domain/frequency/model/availableValues';
import {FieldFrequency} from 'domain/frequency/widgets/Field';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {RegularBonusType} from 'domain/regularBonusRecord/model/type';
import {FieldRegularBonusType} from 'domain/regularBonusRecord/widgets/FieldType';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {labels, messages} from './messages';

type Props = {
  fieldName: string;
  onRemove: () => void;
  values?: RegularBonusRecord;
};

export function CompensationReviewRequestFormNewRegularBonus({fieldName, onRemove, values}: Props) {
  const intl = useIntl();
  const minDate = useMemo(() => getClosestBusinessDayInNextMonth(new Date()), []);

  return (
    <>
      <RemovableSectionHeader onRemove={onRemove} title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item xl={3} xs={6}>
            <FieldRegularBonusType
              name={`${fieldName}.type`}
              label={intl.formatMessage(labels.type)}
              required
              initialValue={RegularBonusType.PERCENT}
              reserveSpaceForError={false}
            />
          </Grid.Item>
          <Grid.Item xl={3} xs={6}>
            <FieldFrequency
              name={`${fieldName}.frequency`}
              label={intl.formatMessage(labels.frequency)}
              required
              validate={validateFilled}
              items={availableBonusFrequencies}
              reserveSpaceForError={false}
            />
          </Grid.Item>
          {values?.type === RegularBonusType.ABSOLUTE ? (
            <>
              <Grid.Item xl={3} xs={6}>
                <FieldInputNumber
                  name={`${fieldName}.value.absoluteValue.amount`}
                  label={intl.formatMessage(labels.amount)}
                  required
                  min={0}
                  step={1000}
                  reserveSpaceForError={false}
                />
              </Grid.Item>
              <Grid.Item xl={3} xs={6}>
                <FieldCurrency
                  name={`${fieldName}.value.absoluteValue.currency`}
                  label={intl.formatMessage(labels.currency)}
                  required
                  validate={validateFilled}
                  reserveSpaceForError={false}
                />
              </Grid.Item>
            </>
          ) : (
            <>
              <Grid.Item xl={3} xs={6}>
                <FieldInputNumber
                  name={`${fieldName}.value.percentValue.amount`}
                  label={intl.formatMessage(labels.amount)}
                  required
                  min={0}
                  step={0.01}
                  reserveSpaceForError={false}
                />
              </Grid.Item>
              <Grid.Item xl={3} xs={6}>
                <div /> {/* For correct placement */}
              </Grid.Item>
            </>
          )}
          <Grid.Item xl={3} xs={6}>
            <FieldDate
              name={`${fieldName}.startDate`}
              label={intl.formatMessage(labels.startDate)}
              minDate={minDate}
              required
              reserveSpaceForError={false}
            />
          </Grid.Item>
          <Grid.Item xl={3} xs={6}>
            <FieldDate
              name={`${fieldName}.endDate`}
              label={intl.formatMessage(labels.endDate)}
              minDate={minDate}
              reserveSpaceForError={false}
            />
          </Grid.Item>
          <Grid.Item xl={6} xs={12}>
            <FieldTextarea
              name={`${fieldName}.comment`}
              label={intl.formatMessage(labels.comment)}
              rows={1}
              parse={identity}
              reserveSpaceForError={false}
            />
          </Grid.Item>
        </Grid>
      </Panel.Content>
    </>
  );
}
