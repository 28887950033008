import {deleteVersion} from './deleteVersion';
import {buildDownloadLink} from './download';
import {buildDownloadByVersionLink} from './downloadByVersion';
import {getSalaryRanges} from './get';
import {getByParams} from './getByParams';
import {getVersions} from './getVersions';

export const salaryRangesApi = {
  buildDownloadLink,
  buildDownloadByVersionLink,
  deleteVersion,
  getByParams,
  getSalaryRanges,
  getVersions,
};
