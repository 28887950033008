import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Target bonus',
    description: 'Compensation review request form: section title',
  },
  buttonAdd: {
    defaultMessage: 'Add new target bonus',
    description: '[button] Compensation review request form: add regular bonus button',
  },
});
