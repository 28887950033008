import {defineMessages} from 'react-intl';
import {StockOptionBuyoutDocumentsStatus} from '.';

export const stockOptionBuyoutDocumentsStatuses = defineMessages<StockOptionBuyoutDocumentsStatus>({
  [StockOptionBuyoutDocumentsStatus.SENT_TO_EMPLOYEE]: {
    defaultMessage: 'Sent',
    description: 'Stock option buyout documents status',
  },
  [StockOptionBuyoutDocumentsStatus.RECEIVED_WITH_EMPLOYEE_SIGNATURE]: {
    defaultMessage: 'Received signed',
    description: 'Stock option buyout documents status',
  },
  [StockOptionBuyoutDocumentsStatus.SIGNED_BY_EMPLOYER]: {
    defaultMessage: 'Signed by Joom',
    description: 'Stock option buyout documents status',
  },
  [StockOptionBuyoutDocumentsStatus.ISSUED_TO_EMPLOYEE]: {
    defaultMessage: 'Sent signed by Joom',
    description: 'Stock option buyout documents status',
  },
});
