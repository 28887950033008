import {
  boolean,
  ExtractSchemaType,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {TerminationInitiatedBy} from './terminationInitiatedBy';
import {TerminationReason} from './terminationReason';
import {TerminationType} from './terminationType';

export const employmentIdSchema = idSchema;

export const employmentSchema = object({
  active: optionalWithDefault(false, boolean()),
  hireDate: formattedDateSchema,
  id: employmentIdSchema,
  terminated: optionalWithDefault(false, boolean()),
  terminationComment: optional(string()),
  terminationDate: optional(formattedDateSchema),
  terminationInitiatedBy: optional(oneOfEnum(TerminationInitiatedBy)),
  terminationReason: optional(oneOfEnum(TerminationReason)),
  terminationType: optional(oneOfEnum(TerminationType)),
  legalTerminationDate: optional(formattedDateSchema),
});

export type Employment = ExtractSchemaType<typeof employmentSchema>;
export type EmploymentId = ExtractSchemaType<typeof employmentIdSchema>;
