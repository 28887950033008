import {intranetApi} from 'apiClient';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {OfficeRoomId} from 'domain/officeMap/room/model';
import {OfficeRoomBookingResponse, officeRoomBookingResponseSchema} from 'domain/officeMap/room/model/bookingResponse';

export type BookRoomConfig = {
  endTime: Date;
  roomId: OfficeRoomId;
  officeAlias: OfficeAlias; // used in store
};

export const bookRoom = async ({endTime, roomId}: BookRoomConfig): Promise<OfficeRoomBookingResponse> => {
  const {data} = await intranetApi.post('/v1/offices/rooms/book', {end: endTime.toISOString(), roomId});

  return officeRoomBookingResponseSchema.runWithException(data);
};
