import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {useFieldWithInitialValue} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {MultiSelect} from '@joomcode/joom-ui/Select/MultiSelect';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {UserRoleId} from 'domain/userRole/model';
import {loadAllUserRolesFx} from 'domain/userRole/stores/main';
import {$userRoles} from 'domain/userRole/stores/main/state';
import {useStore} from 'effector-react';
import {FieldValidator} from 'final-form';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  name: string;
  initialValue: UserRoleId[];
  disabled: boolean;
  label: string;
};

const validateRoleIds = (state: DataState, error: string): FieldValidator<UserRoleId[] | undefined> => {
  return () => {
    if (state === DataState.FAILED) {
      return error;
    }

    return undefined;
  };
};

export function UserRoleRolesField({name, initialValue, disabled, label}: Props) {
  const intl = useIntl();
  const {rolesDataState: dataState, rolesById} = useStore($userRoles);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      loadAllUserRolesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  const permissionsValidator = validateRoleIds(dataState, intl.formatMessage(messages.rolesLoadError));

  const {
    input: {value, ...restInput},
    meta,
  } = useFieldWithInitialValue<UserRoleId[]>(name, {initialValue, parse: identity, validate: permissionsValidator});

  useEffect(() => {
    if (dataState === DataState.LOADED || dataState === DataState.FAILED) {
      // this needed to force re-validation of field
      restInput.onChange([]);
      restInput.onChange(value);
    }
  }, [dataState]);

  const items = useMemo(() => Object.keys(rolesById), [rolesById]);
  const roleIdToString = useCallback((id: UserRoleId): UserRoleId => rolesById[id]?.name || id, [rolesById]);

  return (
    <FormControl error={dataState === DataState.FAILED && meta.error} label={label} disabled={disabled}>
      {(formControlProps) => (
        <>
          {dataState === DataState.LOADING && <Spinner />}
          {dataState === DataState.LOADED && (
            <MultiSelect<UserRoleId>
              items={items}
              disabled={disabled}
              itemToString={roleIdToString}
              value={value}
              {...restInput}
              {...formControlProps}
            />
          )}
        </>
      )}
    </FormControl>
  );
}
