import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  btnDelete: {
    defaultMessage: 'Delete team',
    description: '[button] Team deletion panel: delete button',
  },
  confirmTitle: {
    defaultMessage: 'Delete team',
    description: '[title] Team deletion panel: confirmation dialog title',
  },
  confirmText: {
    defaultMessage: 'Are you sure you want to delete {teamName}?',
    description: 'Team deletion panel: confirmation dialog text',
  },
  confirmButton: {
    defaultMessage: 'Delete',
    description: '[button] Team deletion panel: confirmation button',
  },
  deletionWithSubteams: {
    defaultMessage:
      'You can’t delete teams with subteams yet. Write to {slackChannel} and we will help you with the rearrangement!',
    description: 'Team deletion: subteams note',
  },
});
