import {getDisplayName} from '@joomcode/deprecated-utils/react/getDisplayName';
import {Permission} from 'domain/permission/model';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {RouteProps} from 'react-router-dom';
import {useAcl} from 'services/acl/useAcl';

type Props = RouteProps;

export function withPermission<ComponentProps>(
  Component: React.ComponentType<ComponentProps>,
  permission: Permission,
  FallbackComponent: React.ComponentType = NotFoundPage,
) {
  function PrivateRoute(props: Props & ComponentProps) {
    const acl = useAcl();

    if (!acl.hasPermission(permission)) {
      return <FallbackComponent />;
    }

    return <Component {...props} />;
  }

  PrivateRoute.displayName = `withPermission(${getDisplayName(Component)})`;
  return PrivateRoute;
}
