export enum LevelCode {
  INTERN = 'Intern',
  P_1 = 'P1',
  P_2 = 'P2',
  P_2_1 = 'P2.1',
  P_2_2 = 'P2.2',
  P_3 = 'P3',
  P_3_1 = 'P3.1',
  P_3_2 = 'P3.2',
  P_4 = 'P4',
  P_5 = 'P5',
  P_6 = 'P6',
  M_1 = 'M1',
  M_2 = 'M2',
  M_3 = 'M3',
  M_4 = 'M4',
  M_5 = 'M5',
  TOP = 'TOP',
  // TODO INTRANET-2190 Remove all the items below
  GRADE_8 = '8',
  GRADE_9 = '9',
  GRADE_10 = '10',
  GRADE_11 = '11',
  GRADE_12 = '12',
  GRADE_13 = '13',
  GRADE_14 = '14',
  GRADE_15 = '15',
  GRADE_16 = '16',
  GRADE_17 = '17',
  GRADE_18 = '18',
  GRADE_19 = '19',
}

export const levelCodeSortingOrder: LevelCode[] = [
  LevelCode.GRADE_8,
  LevelCode.GRADE_9,
  LevelCode.GRADE_10,
  LevelCode.GRADE_11,
  LevelCode.GRADE_12,
  LevelCode.GRADE_13,
  LevelCode.GRADE_14,
  LevelCode.GRADE_15,
  LevelCode.GRADE_16,
  LevelCode.GRADE_17,
  LevelCode.GRADE_18,
  LevelCode.GRADE_19,
  LevelCode.INTERN,
  LevelCode.P_1,
  LevelCode.P_2,
  LevelCode.P_2_1,
  LevelCode.P_2_2,
  LevelCode.P_3,
  LevelCode.P_3_1,
  LevelCode.P_3_2,
  LevelCode.P_4,
  LevelCode.P_5,
  LevelCode.P_6,
  LevelCode.M_1,
  LevelCode.M_2,
  LevelCode.M_3,
  LevelCode.M_4,
  LevelCode.M_5,
  LevelCode.TOP,
];
