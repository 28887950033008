import {Button} from '@joomcode/joom-ui/Button';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import React from 'react';

type Props = {
  disabled?: boolean;
  icon: React.ReactNode;
  hint: string;
  onClick(): void;
};

export function StockOptionAgreementActionButton({disabled, icon, hint, onClick}: Props) {
  return (
    <TooltipAlt placement='top' content={hint}>
      {/* extra wrap fixes tippy for disabled buttons */}
      <span>
        <Button intent='neutral' kind='text' size='m' onClick={onClick} iconLeft={icon} disabled={disabled} />
      </span>
    </TooltipAlt>
  );
}
