import cn from 'classnames';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import React from 'react';
import {ReactComponent as CrossIcon} from '../icons/core/cross.svg';
import styles from './index.css';

export enum CloseButtonSize {
  M = 'm',
  S = 's',
}

type Props = Omit<JSX.IntrinsicElements['button'], 'className'> & {size?: `${CloseButtonSize}`};

export function CloseButton({size = CloseButtonSize.M, ...restProps}: Props) {
  return (
    <button
      className={cn(styles.button, styles[`crossIconSize${capitalize(size)}` as keyof typeof styles])}
      type='button'
      {...restProps}
    >
      <CrossIcon />
    </button>
  );
}
