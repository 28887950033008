import {OfficeRoom} from '.';

const indexOfOrInf = (s: string, query: string): number => {
  const idx = s.toLowerCase().indexOf(query.toLowerCase());
  return idx === -1 ? Infinity : idx;
};

export const compareRoomsByQuery = (a: OfficeRoom, b: OfficeRoom, query: string): number => {
  // Firstly we look for the best match in room name
  const idxA = indexOfOrInf(a.text, query);
  const idxB = indexOfOrInf(b.text, query);
  if (idxA !== idxB) {
    return idxA - idxB;
  }

  const aNum = parseInt(a.text, 10);
  const bNum = parseInt(b.text, 10);

  // If both rooms have name as number we sort them in ascending order
  if (!Number.isNaN(aNum) && !Number.isNaN(bNum)) {
    return aNum - bNum;
  }

  // If both rooms have name as letter we sort them alphabetically
  if (Number.isNaN(aNum) && Number.isNaN(aNum)) {
    return a.text < b.text ? -1 : 1;
  }

  // If one room in number and the other is letter – we prefer number first
  return Number.isNaN(aNum) ? 1 : -1;
};
