import {Serializer, Storage, StorageManager} from './types';
import {isEmptyValue} from './utils';

export class SingleStorageManager<T> implements StorageManager<T> {
  private name: string;

  private storage: Storage;

  private serializer: Serializer<T>;

  public constructor(name: string, storage: Storage, serializer: Serializer<T>) {
    this.name = name;
    this.storage = storage;
    this.serializer = serializer;
  }

  public exists(): boolean {
    return !isEmptyValue(this.storage.get(this.name));
  }

  public remove(): void {
    this.storage.remove(this.name);
  }

  public store(data: T | null) {
    const value = this.serializer.serialize(data);

    if (isEmptyValue(value)) {
      this.remove();
    } else {
      this.storage.set(this.name, value);
    }
  }

  public restore(): T | null {
    const value = this.storage.get(this.name);
    return value ? this.serializer.deserialize(value) : null;
  }
}
