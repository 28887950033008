import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Calculator',
    description: '[title] Stock option calculator panel title',
  },
  label: {
    defaultMessage: 'Option value (USD)',
    description: 'Stock option calculator input label',
  },
  disclaimer: {
    defaultMessage:
      'The calculator and the amounts indicated on this page are for convenience only and are not a promise of payment.',
    description: 'Stock option calculator disclaimer',
  },
});
