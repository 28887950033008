import {isNotNullish} from '@joomcode/deprecated-utils/function/isNotNullish';
import {RefObject, useEffect} from 'react';
import {syncHorizontalScroll, syncVerticalScroll} from './utils';
import {ScrollSyncOptions} from './types';

export function useScrollSync(refs: RefObject<HTMLElement>[], options: ScrollSyncOptions): void {
  useEffect(() => {
    let skipEventsCount = 0; // prevent circular syncing

    const elements = refs.map((ref) => ref.current).filter(isNotNullish);

    const syncElement = (source: unknown) => {
      if (!(source instanceof Element)) return;

      if (skipEventsCount > 0) {
        skipEventsCount--;
        return;
      }

      const targets = refs
        .map((ref) => ref.current)
        .filter(isNotNullish)
        .filter((pane) => pane !== source);

      skipEventsCount = targets.length;

      if (options.horizontal) {
        syncHorizontalScroll(source, targets);
      }
      if (options.vertical) {
        syncVerticalScroll(source, targets);
      }
    };

    const handlePaneScroll = ({target: source}: Event) => syncElement(source);

    elements.forEach(syncElement);

    elements.forEach((element) => element.addEventListener('scroll', handlePaneScroll, {passive: true}));
    return () => {
      elements.forEach((element) => element.removeEventListener('scroll', handlePaneScroll));
    };
  }, [options.horizontal, options.vertical, ...refs.map((ref) => ref.current)]);
}
