/* eslint-disable @typescript-eslint/naming-convention */
import cn from 'classnames';
import React, {forwardRef} from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  getLocatorParameters,
  type Locator,
  type Mark,
} from '../create-locator';
import {PanelContent, PanelContentLocator, PanelContentTestId} from './Content';
import {PanelHeader, PanelHeaderLocator, PanelHeaderTestId} from './Header';
import styles from './index.css';
import {PanelSection, PanelSectionLocator, PanelSectionTestId} from './Section';
import {PanelSeparator} from './Separator';
import {PanelTitleDisclosure} from './TitleDisclosure';

export type {
  PanelContentLocator,
  PanelContentTestId,
  PanelHeaderLocator,
  PanelHeaderTestId,
  PanelSectionLocator,
  PanelSectionTestId,
};

export type PanelTestId = ConvertLocatorToTestId<PanelLocator>;
export type PanelLocator = Locator<
  {content: PanelContentLocator; header: PanelHeaderLocator},
  {item?: string},
  'sameParameters'
>;

export type PanelProps = {
  children?: React.ReactNode;
  className?: string;
  size?: 'm' | 's';
  stretchHeight?: boolean;
  subTitle?: React.ReactNode;
  title?: React.ReactNode;
  toolbarTop?: React.ReactNode;
  withMarginBottom?: boolean;
  withPadding?: boolean;
  withSeparator?: boolean;
} & Partial<Mark<PanelLocator>>;

export const PanelComponent = forwardRef<HTMLDivElement, PanelProps>(function Panel(
  {
    size = 'm',
    title,
    subTitle,
    toolbarTop,
    children,
    className,
    stretchHeight,
    withMarginBottom = true,
    withPadding,
    withSeparator,
    ...restProperties
  },
  ref,
) {
  const locator = createLocator(restProperties);
  const locatorParameters = getLocatorParameters(restProperties);

  return (
    <div
      ref={ref}
      className={cn(className, styles.panel, {
        [styles.panelSizeM]: size === 'm',
        [styles.panelSizeS]: size === 's',
        [styles.panelStretched]: stretchHeight,
        [styles.withMarginBottom]: withMarginBottom,
      })}
      {...locator(locatorParameters)}
    >
      {(title || subTitle || toolbarTop) && (
        <PanelHeader
          title={title}
          subTitle={subTitle}
          toolbar={toolbarTop}
          withPaddingBottom={withSeparator || !children}
          withSeparator={withSeparator}
          {...locator.header()}
        />
      )}

      {children && (
        <PanelContent withPadding={withPadding} {...locator.content()}>
          {children}
        </PanelContent>
      )}
    </div>
  );
});

export const Panel = Object.assign(PanelComponent, {
  Header: PanelHeader,
  Content: PanelContent,
  Section: PanelSection,
  Separator: PanelSeparator,
  TitleDisclosure: PanelTitleDisclosure,
});
