import {RoleInTeam} from 'domain/role/model/roleInTeam';
import {User} from 'domain/user/model';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';

interface EntityWithOwners {
  owners: User[];
  owningMemberships: RoleInTeam[];
}

const checkEntityListsAreSame = <T>(firstList: T[], secondList: T[]) =>
  isEmpty(xorWith(firstList, secondList, isEqual));

export const checkOwnersAreSame = (a: EntityWithOwners, b: EntityWithOwners) =>
  checkEntityListsAreSame(a.owners, b.owners) && checkEntityListsAreSame(a.owningMemberships, b.owningMemberships);
