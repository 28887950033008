import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useUserMarketDataRecords} from 'domain/marketDataRecord/hooks/useMarketDataRecords';
import {MarketDataRecord} from 'domain/marketDataRecord/model';
import {MarketDataRecordDialog} from 'domain/marketDataRecord/widgets/Dialog';
import {UserFull} from 'domain/user/model';
import {JobPanelTab} from 'domain/user/widgets/JobPanel';
import {MarketData} from 'domain/user/widgets/MarketData';
import {MarketDataTabAddon} from 'domain/user/widgets/MarketDataTabAddon';
import React, {useMemo} from 'react';

export function useMarketDataTab(user: UserFull, isSensitiveDataHidden: boolean): JobPanelTab<MarketDataRecord> {
  const marketDataRecords = useUserMarketDataRecords(user);
  const marketDataRecordDialog = usePopupState<MarketDataRecord>();

  const result = useMemo(
    () => ({
      addon: <MarketDataTabAddon onDialogOpen={marketDataRecordDialog.open} />,
      content: (
        <MarketData
          userId={user.id}
          records={marketDataRecords.data}
          dataState={marketDataRecords.dataState}
          isSensitiveDataHidden={isSensitiveDataHidden}
          onDialogOpenWithPayload={marketDataRecordDialog.openWithPayload}
        />
      ),
      dialog: (
        <MarketDataRecordDialog
          userId={user.id}
          recordToUpdate={marketDataRecordDialog.payload}
          isOpen={marketDataRecordDialog.isOpen}
          onClose={marketDataRecordDialog.close}
        />
      ),
      records: marketDataRecords.data,
      dataState: marketDataRecords.dataState,
    }),
    [user, marketDataRecords.data, marketDataRecords.dataState, isSensitiveDataHidden, marketDataRecordDialog],
  );

  return result;
}
