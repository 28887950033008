import {ReactComponent as CrossIcon} from '@joomcode/joom-ui/icons/circle/cross.svg';
import cn from 'classnames';
import {FormattedPrice} from 'components/ui/FormattedPrice';
import {MultilineText} from 'components/ui/MultilineText';
import {Receipt} from 'domain/compensations/receipt/model';
import {CompensationReportFile} from 'domain/compensations/report/widgets/File';
import {CompensationRequestId} from 'domain/compensations/request/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  canDownloadFiles: boolean;
  compensationRequestId: CompensationRequestId;
  disabled?: boolean;
  onRemove?: () => void;
  receipt: Receipt;
};

export function ReceiptCard({
  canDownloadFiles,
  compensationRequestId,
  disabled = false,
  onRemove,
  receipt: {comment, lostReceipt, name, money, receiptFiles},
}: Props) {
  const intl = useIntl();

  const renderReceiptFiles = () => {
    if (lostReceipt) {
      return <div className={styles.lostReceipt}>{intl.formatMessage(messages.lostReceipt)}</div>;
    }
    if (!receiptFiles.length) {
      return <div className={styles.empty}>{intl.formatMessage(messages.noReceiptFiles)}</div>;
    }
    return receiptFiles.map((receiptFile) => (
      <div className={styles.file} key={receiptFile.id}>
        <CompensationReportFile
          compensationRequestId={compensationRequestId}
          downloadable={canDownloadFiles}
          fileMeta={receiptFile}
        />
      </div>
    ));
  };

  return (
    <div className={cn(styles.root, {[styles.removable]: Boolean(onRemove)})}>
      <div className={styles.money}>
        <FormattedPrice value={money} />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
        {comment && (
          <div className={styles.comment}>
            <MultilineText text={comment} />
          </div>
        )}
        <div className={styles.files}>{renderReceiptFiles()}</div>
      </div>
      {onRemove && (
        <div className={styles.removeButtonWrap}>
          <button
            type='button'
            className={styles.removeButton}
            disabled={disabled}
            onClick={onRemove}
            title={intl.formatMessage(messages.removeReceipt)}
          >
            <CrossIcon />
          </button>
        </div>
      )}
    </div>
  );
}
