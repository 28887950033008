import {intranetApi} from 'apiClient';
import {OfferId} from 'domain/offer/model';
import {CompensationsComment, compensationsCommentSchema} from 'domain/offer/model/compensation';
import {OfferVersionId} from 'domain/offer/model/version';
import {stringify} from 'qs';

export type GetCompensationsCommentParams = {
  id: OfferId;
  versionIds: OfferVersionId[];
};

export const getCompensationsComment = async (params: GetCompensationsCommentParams): Promise<CompensationsComment> => {
  const {data} = await intranetApi.get('/v1/secure/offers/getCompensationsCommentAny', {
    params,
    paramsSerializer: (params) => stringify(params, {arrayFormat: 'repeat'}),
  });

  return compensationsCommentSchema.runWithException(data);
};
