import {useOffsetPagination} from '@joomcode/deprecated-utils/pagination/offset';
import {Panel} from '@joomcode/joom-ui/Panel';
import {SensitiveDataSwitch} from 'components/ui/SensitiveDataSwitch';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSalaryRanges} from 'domain/salaryRange/hooks/useSalaryRanges';
import {useSalaryRangesSorting} from 'domain/salaryRange/hooks/useSalaryRangesSorting';
import {useUrlFilters} from 'domain/salaryRange/hooks/useUrlFilters';
import {SalaryRange} from 'domain/salaryRange/model';
import {SalaryRangesSearchFilters, salaryRangesSearchFiltersSchema} from 'domain/salaryRange/model/filters';
import {SalaryRangesFilters} from 'domain/salaryRange/widgets/Filters';
import {SalaryRangesTable} from 'domain/salaryRange/widgets/Table';
import {ColumnId} from 'domain/salaryRange/widgets/Table/columnId';
import {useStoredFilters} from 'hooks/useStoredFilters';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {UserSnapEvents, useUsersnapApi} from 'services/usersnap';
import {messages} from './messages';

const basicColumnsIds = [
  ColumnId.REGION,
  ColumnId.USER_FUNCTION,
  ColumnId.LEVEL_CODE,
  ColumnId.CURRENCY,
  ColumnId.MIN_AMOUNT,
  ColumnId.MAX_AMOUNT,
  ColumnId.TARGET_AMOUNT,
  ColumnId.COMMENT,
];
const adminColumnsIds = [ColumnId.GROUP_OF_FUNCTIONS, ColumnId.STOCK_OPTIONS_PROPOSAL];
const PAGINATION_OPTIONS = {initialLimit: 25, initialPage: 1};
const filterSalaryRanges = (
  salaryRanges: SalaryRange[],
  {region, userFunction, groupOfFunctions, levelCode, currency}: SalaryRangesSearchFilters,
): SalaryRange[] => {
  return salaryRanges.filter((salaryRange): boolean => {
    if (region && !region.includes(salaryRange.region)) return false;
    if (userFunction && !userFunction.includes(salaryRange.userFunction)) return false;
    if (
      groupOfFunctions &&
      (!salaryRange.groupOfFunctions || !groupOfFunctions.includes(salaryRange.groupOfFunctions))
    ) {
      return false;
    }
    if (levelCode && !levelCode.includes(salaryRange.levelCode)) return false;
    if (currency && !currency.includes(salaryRange.currency)) return false;

    return true;
  });
};

export function SalaryRangesList() {
  const acl = useAcl();
  const intl = useIntl();
  const {data, dataState} = useSalaryRanges();
  const usersnapTimeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const usersnapApi = useUsersnapApi();
  const [isSensitiveDataHidden, setSensitiveDataHidden] = useState<boolean>(true);
  const onSensitiveDataToggle = useCallback(() => {
    setSensitiveDataHidden((isHidden) => !isHidden);
    if (usersnapApi && usersnapTimeoutId.current === null) {
      usersnapTimeoutId.current = setTimeout(() => usersnapApi?.logEvent(UserSnapEvents.USE_SALARY_RANGES), 8000);
    }
  }, [setSensitiveDataHidden, usersnapApi, usersnapTimeoutId]);
  const pagination = useOffsetPagination(PAGINATION_OPTIONS);
  const {sorting, setSorting, sortSalaryRanges} = useSalaryRangesSorting();
  const {urlFilters, resetUrlFilters} = useUrlFilters();
  const {filterValues, setFilterValues} = useStoredFilters<SalaryRangesSearchFilters>({
    id: 'salaryRanges.filters',
    initialValues: {},
    valuesSchema: salaryRangesSearchFiltersSchema,
  });
  const filters = urlFilters ?? filterValues;
  useEffect(() => {
    return () => {
      if (usersnapTimeoutId.current) clearTimeout(usersnapTimeoutId.current);
    };
  }, [usersnapTimeoutId]);

  const sortedSalaryRanges = useMemo(() => sortSalaryRanges(data, sorting), [data, sorting, sortSalaryRanges]);
  const filteredSalaryRanges = useMemo(
    () => filterSalaryRanges(sortedSalaryRanges, filters),
    [sortedSalaryRanges, filters],
  );
  const pageSalaryRanges = useMemo(
    () => filteredSalaryRanges.slice(pagination.offset, pagination.offset + pagination.limit),
    [filteredSalaryRanges, pagination.offset, pagination.limit],
  );

  const handleFiltersChange = useCallback(
    (values: SalaryRangesSearchFilters) => {
      pagination.setPage(1);
      resetUrlFilters();
      setFilterValues(values);
    },
    [pagination, setFilterValues],
  );

  return (
    <>
      <Panel>
        <SalaryRangesFilters salaryRanges={data} values={filters} onChange={handleFiltersChange} />
      </Panel>
      <Panel
        title={intl.formatMessage(messages.title)}
        toolbarTop={<SensitiveDataSwitch isDataHidden={isSensitiveDataHidden} onClick={onSensitiveDataToggle} />}
        withSeparator
      >
        <SalaryRangesTable
          data={pageSalaryRanges}
          dataState={dataState}
          isSensitiveDataHidden={isSensitiveDataHidden}
          columnIds={
            acl.hasSecurePermission(SecurePermission.SALARY_RANGE_READ_AS_ADMIN)
              ? [...basicColumnsIds, ...adminColumnsIds]
              : basicColumnsIds
          }
          pagination={pagination}
          count={filteredSalaryRanges.length}
          sorting={sorting}
          onSort={setSorting}
        />
      </Panel>
    </>
  );
}
