import cn from 'classnames';
import {isNotNullish} from '@joomcode/deprecated-utils/function/isNotNullish';
import React from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  getLocatorParameters,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../../create-locator';
import styles from './index.css';

export type OptionTestId = ConvertLocatorToTestId<OptionLocator>;
export type OptionLocator = LocatorOfElement<void, {item?: string}, 'sameParameters'>;

type Props = JSX.IntrinsicElements['button'] &
  Partial<Mark<OptionLocator>> & {
    iconRight?: React.ReactNode;
    isActive?: boolean;
    isHighlighted?: boolean;
    postfix?: React.ReactNode;
    toolbar?: React.ReactNode;
  };

const OptionComponent = React.forwardRef<HTMLButtonElement, Props>(function Option(
  {children, disabled, iconRight, isActive, isHighlighted, postfix, toolbar, ...propertiesWithMark}: Props,
  ref,
) {
  const locator = createLocator(propertiesWithMark);
  const locatorParameters = getLocatorParameters(propertiesWithMark);
  const props = removeMarkFromProperties(propertiesWithMark);

  return (
    <button
      {...props}
      {...locator(locatorParameters)}
      aria-selected={isActive || undefined}
      className={cn(styles.option, {
        [styles.optionActive]: isActive,
        [styles.optionDisabled]: disabled,
        [styles.optionHighlighted]: isHighlighted,
      })}
      data-highlighted={isHighlighted}
      disabled={disabled}
      ref={ref}
      role='option'
      type='button'
    >
      <div className={styles.content}>{children}</div>
      {isNotNullish(toolbar) && <div className={styles.toolbar}>{toolbar}</div>}
      {isNotNullish(postfix) && <div className={styles.postfix}>{postfix}</div>}
      {isNotNullish(iconRight) && <div className={styles.iconRight}>{iconRight}</div>}
    </button>
  );
});

export const Option = Object.assign(OptionComponent, {
  highlightedSelector: '[data-highlighted="true"]',
});
