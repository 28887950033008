import {DataState} from '@joomcode/deprecated-utils/dataState';
import {omit} from '@joomcode/deprecated-utils/object/omit';
import {updateSelfFx} from 'domain/self/stores/main';
import {UserId} from 'domain/user/model';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import {updateUserFx} from 'domain/user/stores/main';
import {createStore} from 'effector';
import {getMyPrivateInfoFx, getUserPrivateInfoByIdFx, resetUserPrivateInfo, setMainLegalEntityFx} from '.';

type State = {
  byUserId: Record<UserId, UserPrivateInfo>;
  dataStateByUserId: Record<UserId, DataState>;
};

const resetUserPrivateInfoState = (state: State, userId: UserId) => ({
  ...state,
  byUserId: omit(state.byUserId, [userId]),
  dataStateByUserId: omit(state.dataStateByUserId, [userId]),
});

export const $usersPrivateInfo = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on([getMyPrivateInfoFx, getUserPrivateInfoByIdFx], (state, {userId}) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on([getMyPrivateInfoFx.fail, getUserPrivateInfoByIdFx.fail], (state, {params: {userId}}) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.FAILED,
      },
    };
  })
  .on([getMyPrivateInfoFx.done, getUserPrivateInfoByIdFx.done, setMainLegalEntityFx.done], (state, {result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [result.userId]: result,
      },
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [result.userId]: DataState.LOADED,
      },
    };
  })
  .on([updateUserFx.doneData, updateSelfFx.doneData], (state, result) => {
    return resetUserPrivateInfoState(state, result.id);
  })
  .on(resetUserPrivateInfo, (state, {userId}) => {
    return resetUserPrivateInfoState(state, userId);
  });
