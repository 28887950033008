import {User} from 'domain/user/model';

type ReturnShape = {
  fullName: string;
  possession: string;
};

export function getFullNameWithPossession({firstName, lastName}: User): ReturnShape {
  const possession = lastName.endsWith('s') ? '’' : '’s';

  return {
    fullName: `${firstName} ${lastName}`,
    possession,
  };
}
