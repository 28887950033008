import {array, ExtractSchemaType, object} from '@joomcode/deprecated-utils/jsonValidation';
import {stockOptionAgreementSchema} from 'domain/stockOption/agreement/model';
import {stockOptionBuyoutSchema} from 'domain/stockOption/buyout/model';
import {stockOptionSummarySchema} from 'domain/stockOption/summary/model';

export const userStockOptionDataSchema = object({
  agreements: array(stockOptionAgreementSchema),
  buyouts: array(stockOptionBuyoutSchema),
  summary: stockOptionSummarySchema,
});

export type UserStockOptionData = ExtractSchemaType<typeof userStockOptionDataSchema>;
