import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonAdd: {
    defaultMessage: 'One more date',
    description: '[button] Add date to event dates list',
  },
  buttonRemove: {
    defaultMessage: 'Remove date',
    description: 'Event dates form control: button label',
  },
});
