import {intranetApi} from 'apiClient';
import {LegalInfoDiff} from 'domain/legalInfo/model/diff';
import {Offer, OfferId, offerSchema} from 'domain/offer/model';
import {OfferJobContractDiff} from 'domain/offer/model/jobContract/diff';

export type EnhanceOfferDiff = {
  legalInfo: LegalInfoDiff;
  jobContract: OfferJobContractDiff;
};

export type EnhanceOfferConfig = {
  id: OfferId;
  diff: EnhanceOfferDiff;
};

export const enhance = async ({id, diff}: EnhanceOfferConfig): Promise<Offer> => {
  const {data} = await intranetApi.post('/v1/offers/enhance', diff, {params: {id}});

  return offerSchema.runWithException(data);
};
