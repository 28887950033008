import {Image} from '.';

export function mockImage(width: number, optionalHeight?: number): Image {
  const height = typeof optionalHeight === 'number' ? optionalHeight : width;

  return {
    height,
    url: `https://loremflickr.com/${width}/${height}`,
    width,
  };
}
