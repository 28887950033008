import {intranetApi} from 'apiClient';
import {StockOptionAgreementType} from 'domain/stockOption/agreement/model/type';
import {
  StockOptionAgreementWithSummary,
  stockOptionAgreementWithSummarySchema,
} from 'domain/stockOption/agreement/model/withSummary';
import {UserId} from 'domain/user/model';

export type StockOptionAgreementCreateConfig = {
  userId: UserId;
  issuer: string;
  issueDate: string;
  amount: number;
  type: StockOptionAgreementType;
};

export const create = async ({
  userId,
  ...body
}: StockOptionAgreementCreateConfig): Promise<StockOptionAgreementWithSummary> => {
  const {data} = await intranetApi.post('/v1/secure/stockOptions/agreements/create', body, {params: {userId}});

  return stockOptionAgreementWithSummarySchema.runWithException(data);
};
