import {createContext, useContext} from 'react';

export type InputTokensContextValue = {
  id: string;
  name?: string;
  disabled?: boolean;
};

export const InputTokensContext = createContext<InputTokensContextValue>({
  id: '',
  disabled: false,
});

export function useInputTokensContext() {
  return useContext(InputTokensContext);
}
