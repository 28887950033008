import React from 'react';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../../create-locator';
import styles from './index.css';

export type AutocompleteInputTestId = ConvertLocatorToTestId<AutocompleteInputLocator>;
export type AutocompleteInputLocator = LocatorOfElement<void>;

type Props = Omit<JSX.IntrinsicElements['input'], 'className' | 'onChange'> & {
  onChange(value: string): void;
} & Partial<Mark<AutocompleteInputLocator>>;

export const AutocompleteInput = React.forwardRef<HTMLInputElement, Props>(function AutocompleteInput(
  {onChange, ...propertiesWithMark},
  ref,
) {
  const locator = createLocator(propertiesWithMark);
  const props = removeMarkFromProperties(propertiesWithMark);

  return (
    <input
      {...props}
      ref={ref}
      autoComplete='off'
      className={styles.input}
      {...locator()}
      type='text'
      onChange={(event) => onChange(event.target.value)}
    />
  );
});
