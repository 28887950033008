import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonApply: {
    defaultMessage: 'Apply changes',
    description: '[button] Financial data upload result applying',
  },
  buttonReset: {
    defaultMessage: 'Start over',
    description: '[button] Financial data upload reset',
  },
  success: {
    defaultMessage: 'Financial data have been successfully changed',
    description: 'Financial data upload success',
  },
  nonExistentError: {
    defaultMessage: 'Attempt to apply non-existent financial data upload result',
    description: 'Financial data upload applying error',
  },
  malformedInputError: {
    defaultMessage: 'Attempt to apply malformed financial data',
    description: 'Financial data upload applying error',
  },
});
