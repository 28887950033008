import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {ResourceDiff} from 'domain/resource/model/diff';
import {createResourceFx} from 'domain/resource/stores/main';
import {normalizeValues, ResourceForm} from 'domain/resource/widgets/Form';
import {ServiceId} from 'domain/service/model';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  serviceId?: ServiceId;
  onClose: () => void;
  onSuccess?: () => void;
};

export function ResourceCreationDialog({serviceId, onClose, onSuccess}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const onSubmit = useCallback(
    (diff: ResourceDiff) =>
      createResourceFx(normalizeValues(diff))
        .then(() => {
          onClose();
          onSuccess?.();
        })
        .catch(toaster.interceptThenThrowError),
    [onClose],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 48rem)'>
      <Form<ResourceDiff> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <ResourceForm formId={formId} onSubmit={handleSubmit} submitting={submitting} serviceId={serviceId} />
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
