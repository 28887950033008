import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  dirtyValueWithReset: {
    defaultMessage: 'We’ll subtract the current balance and start accumulation according to the new policy.',
    description: 'Time off policy field: dirty value warning',
  },
  dirtyValue: {
    defaultMessage: 'We’ll start accumulation according to the new policy.',
    description: 'Time off policy field: dirty value warning',
  },
  emptyValue: {
    defaultMessage: 'If no value is entered, the policy of country of the legal entity is applied.',
    description: 'Time off policy field: empty value warning',
  },
  dirtyEmptyValue: {
    defaultMessage:
      'We’ll subtract the current balance and start accumulation according to the new policy. If no value is entered, the policy of country of the legal entity is applied.',
    description: 'Time off policy field: dirty empty value warning',
  },
});
