import {getIn} from 'final-form';
// eslint-disable-next-line no-restricted-syntax
import {useFormState} from 'react-final-form';
import {useFieldArray as useFieldArrayBase, UseFieldArray} from './useFieldArray';

/**
 * The hook solves the problem that the initial values specified
 * when creating the field overwrite the initial values from the form.
 * This problem appeared after the decision in the issue
 * https://github.com/final-form/react-final-form/issues/823
 */
export const useFieldArrayWithInitialValue: UseFieldArray = (
  name: string,
  {initialValue, ...restConfig}: Record<string, unknown> = {},
) => {
  const {initialValues} = useFormState();
  return useFieldArrayBase(name, {initialValue: getIn(initialValues, name) ?? initialValue, ...restConfig});
};
