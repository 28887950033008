import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import {UserId} from 'domain/user/model';

export function isUserIndirectHead(userId: UserId, teamId: TeamId, teamById: Record<TeamId, Team | undefined>) {
  const isDirectHead = (team: Team) => {
    return team.members.activeMembers.some(
      ({memberships, user}) => user.id === userId && memberships.some(({role}) => role.isHead),
    );
  };
  let team = teamId && teamById[teamId];
  while (team) {
    if (isDirectHead(team)) {
      return true;
    }
    const id = team.parentTeamId;
    team = id && teamById[id];
  }
  return false;
}
