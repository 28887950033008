import {defineMessages} from 'react-intl';
import {readFileAsText} from '../file/readFileAsText';
import {detectDelimiter} from './utils/detectDelimiter';
import {Delimiter} from './types';
import {parseCsv, OnRow} from './parseCsv/parseCsv';
import {CsvErrorCode} from './parseCsv/types';

export const detectCsvFileDelimiter = (file: File): Promise<Delimiter | null> =>
  readFileAsText(file).then(detectDelimiter);

// TODO add supported for one column file
export const parseCsvFile = async (file: File, onRow: OnRow): Promise<void> => {
  return readFileAsText(file).then((content) => {
    return new Promise((resolve, reject) => {
      const delimiter = detectDelimiter(content);

      if (delimiter) {
        parseCsv({
          content,
          delimiter,
          onRow,
          onComplete: resolve,
          onError: reject,
        });
      } else {
        onRow(
          {data: [], error: {code: CsvErrorCode.UndetectableDelimiter, row: 0, line: ''}, row: 0, delimiter: ''},
          {abort: () => {}},
        );
        resolve();
      }
    });
  });
};

export const delimiterNameMap: {[key in Delimiter]: string} = {
  [Delimiter.COMMA]: 'comma',
  [Delimiter.PIPE]: 'pipe',
  [Delimiter.SEMICOLON]: 'semicolon',
  [Delimiter.TAB]: 'tab',
};

export const delimiterMessages = defineMessages<Delimiter>({
  [Delimiter.COMMA]: {
    defaultMessage: 'comma',
    description: 'CSV delimiter name',
    id: 'utils-csv-delimiter-comma',
  },
  [Delimiter.PIPE]: {
    defaultMessage: 'pipe',
    description: 'CSV delimiter name',
    id: 'utils-csv-delimiter-pipe',
  },
  [Delimiter.SEMICOLON]: {
    defaultMessage: 'semicolon',
    description: 'CSV delimiter name',
    id: 'utils-csv-delimiter-semicolon',
  },
  [Delimiter.TAB]: {
    defaultMessage: 'tab',
    description: 'CSV delimiter name',
    id: 'utils-csv-delimiter-tab',
  },
});
