import {FieldInput, validateFilled, validateUrl} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {Offer} from 'domain/offer/model';
import {NewcomerFormTemplateCountry} from 'domain/offer/model/templateCountry';
import {setOfferAcceptedFx} from 'domain/offer/stores/main';
import {FieldTemplateCountry} from 'domain/offer/widgets/FieldTemplateCountry';
import {generalMessages} from 'i18n/messages/general';
import React, {useCallback, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {labels, messages} from './messages';

type Props = {
  offer: Offer;
  onClose(): void;
};

type FormState = {
  linkToInterviewFeedback?: string;
  templateCountry: NewcomerFormTemplateCountry;
};

const URL_FIELD_PLACEHOLDER = 'https://';

export function OfferAcceptDialog({offer, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const onSubmit = useCallback(
    ({linkToInterviewFeedback, templateCountry}: FormState) => {
      if (!templateCountry) {
        return Promise.reject().catch(() => toaster.error(intl.formatMessage(generalMessages.partiallyFilledError)));
      }

      return setOfferAcceptedFx({id: offer.id, linkToInterviewFeedback, templateCountry})
        .then(onClose)
        .catch(toaster.interceptThenThrowError);
    },
    [onClose, offer.id],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 32rem)'>
      <Form<FormState> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <form id={formId} onSubmit={handleSubmit}>
                <FieldTemplateCountry
                  name='templateCountry'
                  label={intl.formatMessage(labels.templateCountry)}
                  hint={intl.formatMessage(messages.templateCountryHint)}
                  required
                  validate={validateFilled}
                  reserveSpaceForError={false}
                />
                <FieldInput
                  name='linkToInterviewFeedback'
                  label={intl.formatMessage(labels.linkToInterviewFeedback)}
                  reserveSpaceForError={false}
                  placeholder={URL_FIELD_PLACEHOLDER}
                  initialValue={offer.general.linkToInterviewFeedback}
                  validate={validateUrl}
                />
              </form>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                form={formId}
                size='l'
                kind='primary'
                intent='primary'
                type='submit'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.buttonSubmit)}
              </Button>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
