import React from 'react';
import {Error} from './Error';
import {Warning} from './Warning';

export function renderError(error: React.ReactNode) {
  if (typeof error === 'string') {
    return <Error message={error} />;
  }

  return error;
}

export function renderWarning(warning: React.ReactNode) {
  if (typeof warning === 'string') {
    return <Warning message={warning} />;
  }

  return warning;
}
