import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonHint: {
    defaultMessage: 'Delete agreement',
    description: 'Delete stock option agreement button hint',
  },
  confirmationTitle: {
    defaultMessage: 'Delete option agreement',
    description: '[title] Stock option agreement deletion confirmation dialog: dialog title',
  },
  confirmationText: {
    defaultMessage:
      'Are you sure you want to delete {fullName}{possession} option agreement from {issueDate} for {amount} options issued by {issuer}?',
    description: 'Stock option agreement deletion confirmation dialog: dialog text',
  },
  confirmationButton: {
    defaultMessage: 'Delete',
    description: '[button] Stock option agreement deletion confirmation dialog: confirmation button',
  },
  success: {
    defaultMessage: 'Option agreement deleted',
    description: 'Stock option agreement successful deletion text',
  },
});
