import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Export',
    description: '[title] Financial data export panel',
  },
  buttonDownloadMarketData: {
    defaultMessage: 'Functions and levels history',
    description: '[button] Market data export',
  },
  buttonDownloadSalaries: {
    defaultMessage: 'Compensations history',
    description: '[button] Salaries export',
  },
  buttonDownloadLegalData: {
    defaultMessage: 'Legal data',
    description: '[button] Legal data export',
  },
});
