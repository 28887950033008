import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Office policy record dialog',
    description: 'Office policy record dialog: aria label',
  },
  titleAdd: {
    defaultMessage: 'Add record',
    description: '[title] Office policy record dialog: title (addition mode)',
  },
  titleUpdate: {
    defaultMessage: 'Update record',
    description: '[title] Office policy record dialog: title (update mode)',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Office policy record dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Submit',
    description: '[button] Office policy record dialog: submit button',
  },
});
