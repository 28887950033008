import {ListedProperty} from 'components/ui/ListedProperty';
import {Section} from 'components/ui/Section';
import {OfferStockOptions} from 'domain/offer/model/stockOption';
import {stockOptionFieldLabels} from 'domain/offer/model/stockOption/messages';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  stockOptions: OfferStockOptions | null;
  stockOptionsExist: boolean;
};

export function OfferStockOptionsDetailsSection({stockOptions, stockOptionsExist}: Props) {
  const intl = useIntl();

  return (
    <Section title={intl.formatMessage(messages.title)}>
      <div className={styles.properties}>
        <ListedProperty label={intl.formatMessage(stockOptionFieldLabels.amount)}>
          {stockOptions
            ? intl.formatNumber(stockOptions.amount)
            : intl.formatMessage(stockOptionsExist ? messages.hasOptions : messages.noOptions)}
        </ListedProperty>
      </div>
    </Section>
  );
}
