import {DataState} from '@joomcode/deprecated-utils/dataState';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {MarketDataRecord, MarketDataRecordId} from 'domain/marketDataRecord/model';
import {deleteMarketDataRecordFx} from 'domain/marketDataRecord/stores/main';
import {MarketDataRecordsTable} from 'domain/marketDataRecord/widgets/Table';
import {SecurePermission} from 'domain/permission/model/secure';
import {UserId} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  userId: UserId;
  records: MarketDataRecord[];
  dataState: DataState;
  isSensitiveDataHidden: boolean;
  onDialogOpenWithPayload(record: MarketDataRecord): void;
};

export function MarketData({userId, records, dataState, isSensitiveDataHidden, onDialogOpenWithPayload}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canEdit = acl.hasSecurePermission(SecurePermission.MARKET_DATA_RECORD_WRITE);
  const onDeleteRecordClick = useCallback(
    (id: MarketDataRecordId) => {
      confirm(
        {
          title: intl.formatMessage(messages.deletionConfirmationTitle),
          text: intl.formatMessage(messages.deletionConfirmationText),
          confirmationText: intl.formatMessage(messages.deletionConfirmationButton),
          intent: 'negative',
          onConfirm: () => deleteMarketDataRecordFx({id, userId}).catch(toaster.interceptThenThrowError),
        },
        intl,
      );
    },
    [intl, userId],
  );

  return (
    <MarketDataRecordsTable
      records={records}
      dataState={dataState}
      isSensitiveDataHidden={isSensitiveDataHidden}
      {...(canEdit && {
        onEditItem: onDialogOpenWithPayload,
        onDeleteItem: onDeleteRecordClick,
      })}
    />
  );
}
