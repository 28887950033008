import {
  array,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {stockOptionAgreementFreezeSchema} from 'domain/stockOption/agreementFreeze/model';
import {userSchema} from 'domain/user/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {StockOptionAgreementDocumentsStatus} from './documentsStatus';
import {StockOptionAgreementStatus} from './status';
import {StockOptionAgreementType} from './type';

export const stockOptionAgreementIdSchema = idSchema;
export const stockOptionAgreementSchema = object({
  id: stockOptionAgreementIdSchema,
  amount: number(),
  vested: number(),
  user: userSchema,
  issueDate: formattedDateSchema,
  endVestingDate: optional(formattedDateSchema),
  issuer: string(),
  status: oneOfEnum(StockOptionAgreementStatus),
  freezes: optionalWithDefault([], array(stockOptionAgreementFreezeSchema)),
  documentsStatus: oneOfEnum(StockOptionAgreementDocumentsStatus),
  type: oneOfEnum(StockOptionAgreementType),
});

export type StockOptionAgreementId = ExtractSchemaType<typeof stockOptionAgreementIdSchema>;
export type StockOptionAgreement = ExtractSchemaType<typeof stockOptionAgreementSchema>;
