import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  granted: {
    defaultMessage: 'Granted',
    description: 'Compensation review request form: granted options',
  },
  nextYear: {
    defaultMessage: 'Next 12 months vesting',
    description: 'Compensation review request form: next year options',
  },
  vested: {
    defaultMessage: 'Vested',
    description: 'Compensation review request form: vested options',
  },
  available: {
    defaultMessage: 'Available',
    description: 'Compensation review request form: available options',
  },
  error: {
    defaultMessage: 'Failed to calculate options',
    description: 'Compensation review request form: error message',
  },
});
