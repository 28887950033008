import cn from 'classnames';
import {createLocator, type Mark, type Locator} from 'create-locator';
import {isNotNullish} from '@joomcode/deprecated-utils/function';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import styles from './index.css';

export type PageLocator = Locator<{
  content: void;
  header: void;
  headerActions: void;
  headerBreadcrumbs: void;
  headerContent: void;
  headerTitle: void;
  headerTitleAddon: void;
  headerTitleContent: void;
  tabsContent: void;
}>;

export type Props = {
  actions?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
  children?: React.ReactNode;
  contentRef?: React.RefObject<HTMLDivElement>;
  documentTitle?: string;
  inlineTitle?: boolean;
  tabsContent?: React.ReactNode;
  title: string;
  titleAddon?: React.ReactNode;
  headerContent?: React.ReactNode;
  showTitle?: boolean;

  /**
   * Whether the header should be sticky. It is useful in cases where the user needs to see
   * available actions no matter how far they scroll page down.
   *
   * You can achieve the same behavior by using the `stretchToViewport` flag. The only difference
   * is that when using the `stickyHeader` flag, there is no additional scroll created when
   * there are other elements before the page.
   */
  stickyHeader?: boolean;
  stretchToViewport?: boolean;
} & Partial<Mark<PageLocator>>;

export function Page({
  actions,
  breadcrumbs,
  children,
  contentRef,
  documentTitle,
  inlineTitle,
  tabsContent,
  title,
  titleAddon,
  headerContent,
  showTitle = true,
  stickyHeader = false,
  stretchToViewport,
  ...rest
}: Props) {
  const locator = createLocator(rest);

  return (
    <div className={cn({[styles.pageStretched]: stretchToViewport})} {...locator()}>
      <Helmet title={documentTitle ?? title} />

      <div className={cn(styles.header, {[styles.stickyHeader]: stickyHeader})} {...locator.header()}>
        {breadcrumbs && (
          <div className={styles.breadcrumbs} {...locator.headerBreadcrumbs()}>
            {breadcrumbs}
          </div>
        )}

        {(showTitle || actions) && (
          <div className={styles.headerTitle}>
            {showTitle && (
              <h1 className={cn(styles.title, {[styles.inlineTitle]: inlineTitle})} {...locator.headerTitle()}>
                <span
                  className={cn(styles.titleText, {[styles.inlineTitleText]: inlineTitle})}
                  title={inlineTitle ? title : undefined}
                  {...locator.headerTitleContent()}
                >
                  {title}
                </span>

                {isNotNullish(titleAddon) && (
                  <span className={styles.titleAddon} {...locator.headerTitleAddon()}>
                    {titleAddon}
                  </span>
                )}
              </h1>
            )}

            {actions && (
              <div className={styles.actions} {...locator.headerActions()}>
                {actions}
              </div>
            )}
          </div>
        )}

        {headerContent && (
          <div className={styles.headerContent} {...locator.headerContent()}>
            {headerContent}
          </div>
        )}

        {tabsContent && (
          <div className={styles.tabsContent} {...locator.tabsContent()}>
            {tabsContent}
          </div>
        )}
      </div>

      <div className={styles.content} ref={contentRef} {...locator.content()}>
        {children}
      </div>
    </div>
  );
}

Page.Spreader = function Spreader({children}: {children: React.ReactNode}) {
  return <div className={styles.contentSpreader}>{children}</div>;
};
