import type {WithElementProps} from '@joomcode/deprecated-utils/types/withElementProps';
import React, {ElementType, useContext, useCallback, Ref, forwardRef} from 'react';
import {MenuItem as BaseMenuItem, MenuItemProps as BaseMenuItemProps, MenuStateReturn} from 'reakit/Menu';
import {type ConvertLocatorToTestId, createLocator, type LocatorOfElement, type Mark} from '../create-locator';
import {Listbox} from '../Listbox';
import {MenuContext} from './context';

export type MenuItemTestId = ConvertLocatorToTestId<MenuItemLocator>;
export type MenuItemLocator = LocatorOfElement<void>;

type Props<As> = Omit<BaseMenuItemProps, keyof MenuStateReturn | 'className'> & {
  as?: As;
  closeOnClick?: boolean;
} & Partial<Mark<MenuItemLocator>>;

export const MenuItem = forwardRef(function MenuItem(
  {closeOnClick = true, onClick, ...restProps}: WithElementProps<Props<ElementType>, ElementType>,
  ref: Ref<unknown>,
) {
  const locator = createLocator(restProps);
  const menu = useContext(MenuContext);

  if (!menu) {
    throw Error(`<MenuItem> must be used inside of a <Menu> component`);
  }

  const handleOnClick = useCallback(
    (event: React.MouseEvent) => {
      if (closeOnClick && menu.onClose) {
        menu.onClose();
      }
      if (onClick) {
        onClick(event);
      }
    },
    [menu, onClick, closeOnClick],
  );

  return (
    // @ts-ignore
    <BaseMenuItem as={Listbox.Option} {...restProps} {...menu} ref={ref} onClick={handleOnClick} {...locator()} />
  );
});
