import {Panel} from '@joomcode/joom-ui/Panel';
import React from 'react';
import styles from './styles.css';

type Props = {
  title: string;
};

export function OfferFormSectionTitle({title}: Props) {
  return (
    <div className={styles.root}>
      <Panel.Header title={title} />
    </div>
  );
}
