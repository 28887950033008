import {identity} from '@joomcode/deprecated-utils/function';
import {composeValidators, getFieldValidator, validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {JobTitleWithRecommendation} from 'components/widgets/JobTitleWithRecommendation';
import {LevelCode} from 'domain/levelCode/model';
import React, {useCallback, useState} from 'react';
import {Field, useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {jobTitleErrorMessages, messages} from './messages';
import styles from './styles.css';
import {validateJobTitle} from './validate';

type Props = {
  disabled?: boolean;
  initialValue?: string;
  jobTitles: string[];
  levelCode: LevelCode;
  name: string;
  reserveSpaceForError?: boolean;
  userFunction: string;
};

export function FieldJobTitle({
  disabled,
  initialValue,
  jobTitles,
  levelCode,
  name,
  reserveSpaceForError,
  userFunction,
}: Props) {
  const intl = useIntl();
  const form = useForm();
  const [freeInput, setFreeInput] = useState<boolean>(false);
  const toggleFreeInput = useCallback(() => {
    form.change(name, '');
    setFreeInput(!freeInput);
  }, [form, freeInput, setFreeInput]);
  const jobTitleValidator = composeValidators(validateFilled, validateJobTitle);

  return (
    <Field<string | null>
      name={name}
      initialValue={initialValue ?? null}
      parse={identity}
      validate={getFieldValidator(jobTitleValidator)}
    >
      {({input, meta}) => (
        <FormControl
          disabled={disabled}
          label={
            <JobTitleWithRecommendation
              levelCode={levelCode}
              userFunction={userFunction}
              jobTitle={input.value ?? ''}
              onClick={(recommendedJobTitle) => form.change(name, recommendedJobTitle)}
            />
          }
          hint={intl.formatMessage(messages.hint)}
          error={getFieldErrorText(meta, {intl, extraMessages: jobTitleErrorMessages})}
          required
          reserveSpaceForError={reserveSpaceForError}
        >
          {(formControlProps) => (
            <div className={styles.wrap}>
              <div className={styles.field}>
                {freeInput ? (
                  <Input disabled={disabled} {...formControlProps} {...input} value={input.value ?? ''} />
                ) : (
                  <SimpleAutocomplete
                    items={jobTitles}
                    getItemKey={identity}
                    disabled={disabled}
                    clearable
                    {...formControlProps}
                    {...input}
                  />
                )}
              </div>
              <div className={styles.or}> {intl.formatMessage(messages.or)} </div>
              <Button intent='neutral' kind='primary' size='m' onClick={toggleFreeInput}>
                {freeInput ? intl.formatMessage(messages.selectExisting) : intl.formatMessage(messages.createNew)}
              </Button>
            </div>
          )}
        </FormControl>
      )}
    </Field>
  );
}
