import {usePrevious} from '@joomcode/deprecated-utils/react/usePrevious';
import {useCallback, useEffect, useMemo, useState} from 'react';
import difference from 'lodash/difference';
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import {isEmptyValue} from '@joomcode/deprecated-utils/Storage/utils';
import {objectKeys} from '@joomcode/deprecated-utils/object/keys';
import intersection from 'lodash/intersection';
import {DataFilterConfig} from './types';

export type FilterOptions<Values> = {
  config: DataFilterConfig<Values>;
  orderedIds?: string[];
  values: Partial<Values>;
  onChange: (values: Partial<Values>) => void;
};
export type DataFilterState<Values> = {
  visibleFilterIds: (keyof Values)[];
  hiddenFilterIds: (keyof Values)[];
  isNewFilter: (id: keyof Values) => boolean;
  addFilter?: (id: keyof Values) => void;
  removeFilter: (id: keyof Values) => void;
  clearFilters: () => void;
  onFilterChange: <T>(id: keyof Values, value: T) => void;
};
export function useFilterState<Values extends Record<string, unknown>>({
  values,
  onChange,
  orderedIds,
  config,
}: FilterOptions<Values>): DataFilterState<Values> {
  const allFilterIds = useMemo(() => orderedIds ?? objectKeys(config), [orderedIds, config]);
  const filledIds = useMemo(() => {
    return allFilterIds.filter((id) => !config[id].isEmpty(values[id]));
  }, [allFilterIds, config, values]);

  const [editedIds, setEditedIds] = useState<(keyof Values)[]>(() => filledIds);
  const addFilter = useCallback((id: keyof Values) => setEditedIds((ids) => uniq(ids.concat(id))), []);
  const removeFilter = useCallback((id: keyof Values) => setEditedIds((ids) => ids.filter((_id) => _id !== id)), []);

  useEffect(() => {
    setEditedIds((prevEditedIds) => intersection(prevEditedIds, allFilterIds));
  }, [config]);

  const alwaysVisibleIds = useMemo(
    () => allFilterIds.filter((id) => config[id].options?.alwaysVisible || config[id].options?.renderEmpty),
    [config],
  );
  const visibleFilterIds = useMemo(() => {
    const initialFilterIds = difference(filledIds, editedIds);
    return intersection([...alwaysVisibleIds, ...initialFilterIds, ...editedIds], allFilterIds);
  }, [allFilterIds, alwaysVisibleIds, editedIds, filledIds]);
  const hiddenFilterIds = useMemo(() => difference(allFilterIds, visibleFilterIds), [config, visibleFilterIds]);

  const isNewFilter = useCallback(
    (id: keyof Values) => {
      return id === last(editedIds) && config[id].isEmpty(values[id]);
    },
    [editedIds, values],
  );

  const handleFilterChange = useCallback(
    <T>(id: keyof Values, value: T) => {
      onChange({...values, [id]: value});
    },
    [values, onChange],
  );

  const handleRemove = useCallback(
    (id: keyof Values) => {
      if (id in values && values[id] !== undefined) {
        onChange(omit(values as Record<string, unknown>, [id] as string[]));
      }
      removeFilter(id);
    },
    [values, onChange, removeFilter],
  );

  const handleClear = useCallback(() => {
    onChange({});
    setEditedIds([]);
  }, [onChange]);

  const prevValues: Partial<Values> = usePrevious(values) || {};
  useEffect(() => {
    objectKeys(prevValues || {}).forEach((id: keyof Values) => {
      if (!isEmptyValue(prevValues[id]) && isEmptyValue(values[id])) {
        removeFilter(id);
      }
    });
  }, [prevValues, values]);

  return {
    visibleFilterIds,
    hiddenFilterIds,
    isNewFilter,
    addFilter: visibleFilterIds.some(isNewFilter) ? undefined : addFilter,
    removeFilter: handleRemove,
    clearFilters: handleClear,
    onFilterChange: handleFilterChange,
  };
}
