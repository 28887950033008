import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  request: {
    defaultMessage: 'Business trip',
    description: '[title] Page title',
  },
  requests: {
    defaultMessage: 'Business trips',
    description: '[title] Page title',
  },
  reportBusinessTrip: {
    defaultMessage: '{dateRange} business trip report',
    description: '[title] Page title',
  },
});
