import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'One-time bonuses',
    description: '[title] Offer form section title',
  },
});

export const labels = defineMessages({
  signOnBonusAmount: {
    defaultMessage: 'Sign-on bonus',
    description: 'Offer field label',
  },
  signOnBonusCurrency: {
    defaultMessage: 'Currency',
    description: 'Offer field label',
  },
  relocationBonusAmount: {
    defaultMessage: 'Relocation bonus',
    description: 'Offer field label',
  },
  relocationBonusCurrency: {
    defaultMessage: 'Currency',
    description: 'Offer field label',
  },
});
