import {DataState} from '@joomcode/deprecated-utils/dataState';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {ServerPermission} from 'domain/permission/model';
import {ServerSecurePermission} from 'domain/permission/model/secure';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {loadSelfFx, resetSelf} from '.';

type State = {
  login: string | undefined;
  permissions: Set<ServerPermission>;
  securePermissions: Set<ServerSecurePermission['name']>;
  userId: UserId | undefined;
  dataState: DataState;
  isStockOptionHolder: boolean;
};

const initialState: State = {
  login: undefined,
  permissions: new Set(),
  securePermissions: new Set(),
  userId: undefined,
  dataState: DataState.IDLE,
  isStockOptionHolder: false,
};

export const $self = createStore<State>(initialState)
  .on(loadSelfFx, (state) => ({
    ...state,
    dataState: DataState.LOADING,
  }))
  .on(loadSelfFx.fail, (state, {error}) => {
    const isUnauthorized = error instanceof ClientError && error.status === ClientErrorStatus.UNAUTHORIZED;
    return {
      ...state,
      // in case of 401 error, keep showing loader while the redirect is processed
      dataState: isUnauthorized ? DataState.LOADING : DataState.FAILED,
    };
  })
  .on(loadSelfFx.doneData, (state, self) => {
    return {
      ...state,
      login: self.user.login,
      permissions: new Set(self.permissions ?? []),
      securePermissions: new Set(self.securePermissions.map(({name}) => name) ?? []),
      userId: self.user.id,
      isStockOptionHolder: self.isStockOptionHolder,
      dataState: DataState.LOADED,
    };
  })
  // resetSelf is triggered on all 401s via apiClient, not to exclude the very first `self` request
  // we only want to flush the state if `self` is not currently loading
  .on(resetSelf, (state) => (state.dataState === DataState.LOADING ? state : initialState));

export const $isAuthenticated = $self.map((state) => state.login !== undefined);
