import {FileToDownload} from 'components/ui/FileToDownload';
import {offersApi} from 'domain/offer/api';
import {OfferId} from 'domain/offer/model';
import {Permission} from 'domain/permission/model';
import {FileMeta} from 'models/system/fileMeta';
import React from 'react';
import {useAcl} from 'services/acl';

type Props = {
  document: FileMeta;
  offerId: OfferId;
};

export function NewcomerDocumentInfo({document, offerId}: Props) {
  const acl = useAcl();
  return (
    <FileToDownload
      fileMeta={document}
      link={offersApi.buildDocumentDownloadLink({offerId, fileId: document.id})}
      disabled={!acl.hasPermission(Permission.OFFER_DOCUMENT_DOWNLOAD)}
    />
  );
}
