import React from 'react';
import {ReactComponent as JoomLogo} from '../icons/core/joom.svg';
import styles from './index.css';

type Props = {
  children: React.ReactNode;
};

export function JoomServiceLogo({children}: Props) {
  return (
    <span className={styles.root}>
      <span className={styles.icon}>
        <JoomLogo />
      </span>
      {children}
    </span>
  );
}
