import {areIntlLocalesSupported} from '@joomcode/deprecated-utils/intl/areIntlLocalesSupported';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {Locale} from 'i18n/locale/types';

export async function loadLocalePolyfills(locale: Locale) {
  await Promise.all([
    !areIntlLocalesSupported([locale], [Intl.DateTimeFormat, Intl.NumberFormat]) &&
      import(`@joomcode/deprecated-utils/intl/polyfills/${locale}/intl` /* webpackChunkName: "[request]" */),
    !areIntlLocalesSupported([locale], [Intl.PluralRules]) &&
      import(`@joomcode/deprecated-utils/intl/polyfills/${locale}/pluralRules` /* webpackChunkName: "[request]" */),
    // @ts-ignore
    !areIntlLocalesSupported([locale], [Intl.RelativeTimeFormat]) &&
      import(
        `@joomcode/deprecated-utils/intl/polyfills/${locale}/relativeTimeFormat` /* webpackChunkName: "[request]" */
      ),
    // @ts-ignore
    !areIntlLocalesSupported([locale], [Intl.ListFormat]) &&
      import(`@joomcode/deprecated-utils/intl/polyfills/${locale}/listFormat` /* webpackChunkName: "[request]" */),
    // @ts-ignore
    !areIntlLocalesSupported([locale], [Intl.DisplayNames]) &&
      import(`@joomcode/deprecated-utils/intl/polyfills/${locale}/displayNames` /* webpackChunkName: "[request]" */),
  ]);
}

export async function loadIntlPolyfills() {
  const locales = getEnumValues(Locale);

  if (typeof Intl === 'undefined') {
    await import('@joomcode/deprecated-utils/intl/polyfills/intl' /* webpackChunkName: 'intl-polyfill' */);
  }

  await Promise.all([
    !areIntlLocalesSupported(locales, [Intl.PluralRules]) &&
      import(
        '@joomcode/deprecated-utils/intl/polyfills/pluralRules' /* webpackChunkName: 'intl-pluralrules-polyfill' */
      ),
    // @ts-ignore
    !areIntlLocalesSupported(locales, [Intl.RelativeTimeFormat]) &&
      import(
        '@joomcode/deprecated-utils/intl/polyfills/relativeTimeFormat' /* webpackChunkName: 'intl-relativetimeformat-polyfill' */
      ),
    // @ts-ignore
    !areIntlLocalesSupported(locales, [Intl.ListFormat]) &&
      import(
        '@joomcode/deprecated-utils/intl/polyfills/listFormat' /* webpackChunkName: 'intl-pluralrules-polyfill' */
      ),
    // @ts-ignore
    !areIntlLocalesSupported(locales, [Intl.DisplayNames]) &&
      import(
        '@joomcode/deprecated-utils/intl/polyfills/displayNames' /* webpackChunkName: 'intl-displaynames-polyfill' */
      ),
  ]);
}
