import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {getUserFullInfoRecordsFx} from '.';

export const $userFullInfoRecords = createEntityListStore(
  createEntityStore({
    getEntityId: ({user}) => user.id,
    query: {
      fx: getUserFullInfoRecordsFx,
      getIdsFromParams: () => [],
    },
  }),
);
