import cn from 'classnames';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import React from 'react';
import {useIntl} from 'react-intl';
import {ReactComponent as CrossIcon} from '../../../icons/core/cross.svg';
import {ReactComponent as DownloadIcon} from '../../../icons/core/download.svg';
import {FormattedFileSize} from '../../../FormattedFileSize';
import {FormattedPercent} from '../../../FormattedPercent';
import styles from './index.css';
import {messages} from '../../messages';
import {FileObject} from '../../types';

const noop = async () => {};

type Props = {
  children?: React.ReactChild | null;
  closeButtonLabel?: string;
  disabled?: boolean;
  downloadButtonLabel?: string;
  file: FileObject;
  hasError?: boolean;
  onDownload?: () => void | Promise<void>;
  onRemove?: () => void | Promise<void>;
  progress?: number | null;
};

export const FilePaneContent = ({
  children,
  closeButtonLabel,
  downloadButtonLabel,
  disabled = false,
  file: {name, size},
  hasError,
  onDownload,
  onRemove,
  progress,
}: Props) => {
  const intl = useIntl();
  const removeTask = useAsyncTask(onRemove ? async () => onRemove() : noop, [onRemove]);
  const downloadTask = useAsyncTask(onDownload ? async () => onDownload() : noop, [onRemove]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.nameCol}>{name}</div>

        <div className={cn(styles.serviceCol, {[styles.serviceColDisabled]: disabled})}>
          {size && <FormattedFileSize value={fileSizeUtils.createMeasure(size, FileSizeUnit.Byte)} />}

          {onDownload && (
            <button
              type='button'
              className={cn(styles.iconButton, styles.downloadIconButton)}
              disabled={disabled || downloadTask.isPerforming}
              onClick={downloadTask.perform}
              title={downloadButtonLabel || intl.formatMessage(messages.download)}
            >
              <DownloadIcon />
            </button>
          )}

          {onRemove && (
            <button
              type='button'
              className={cn(styles.iconButton, styles.removeIconButton)}
              disabled={disabled || removeTask.isPerforming}
              onClick={removeTask.perform}
              title={closeButtonLabel || intl.formatMessage(messages.remove)}
            >
              <CrossIcon />
            </button>
          )}
        </div>
      </div>

      {progress && (
        <div className={styles.row}>
          <div className={styles.progressCol}>
            {intl.formatMessage(messages.attachFileProgress, {progress: <FormattedPercent value={progress} />})}
          </div>
        </div>
      )}

      {children && (
        <div className={styles.row}>
          <div className={cn(styles.contentCol, {[styles.error]: hasError})}>{children}</div>
        </div>
      )}
    </>
  );
};
