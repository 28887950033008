import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {JobInfoRecord} from 'domain/jobInfoRecord/model';
import {getMyRecordsFx, getRecordsByUserIdFx} from 'domain/jobInfoRecord/stores/main';
import {$jobInfoRecords} from 'domain/jobInfoRecord/stores/main/state';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: JobInfoRecord[];
  dataState: DataState;
};

export function useUsersJobInfoRecords(userId: UserId): ReturnShape {
  const acl = useAcl();
  const canReadAll = acl.hasPermission(Permission.USER_JOB_INFO_RECORD_READ);

  const [data, dataState] = useStoreMap({
    store: $jobInfoRecords,
    keys: [userId],
    fn: ({byUserId, dataStateByUserId}, [id]) => [byUserId[id] || [], dataStateByUserId[id] || DataState.IDLE],
  });

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      (canReadAll ? getRecordsByUserIdFx : getMyRecordsFx)(userId).catch(toaster.interceptThenThrowError);
    }
  }, [userId, canReadAll]);

  return {data, dataState};
}
