import type {ValidationFunction} from '../../types';
import {EqualValidatorErrorCode, EqualValidatorOptions} from './types';

export const validateEqual: ValidationFunction<unknown, EqualValidatorOptions, EqualValidatorErrorCode> = async (
  value,
  options = {value: undefined},
) => {
  const {value: shouldBeValue} = options;

  if (value !== shouldBeValue) {
    return {code: EqualValidatorErrorCode.WRONG_VALUE, options};
  }

  return undefined;
};
