import {Spinner} from '@joomcode/joom-ui/Spinner';
import React from 'react';
import styles from './styles.css';

export const DataFilterSpinner = () => {
  return (
    <div className={styles.root}>
      <Spinner color={styles.spinnerColor} />
    </div>
  );
};
