import {maxNumeric} from '../../array/maxNumeric';
import {Size} from '../../math/size';

type ImageSize = {
  height: number;
  width: number;
};

export type ImageBundleItem = ImageSize & {
  url: string;
};

function lessOrEqualsThan(item1: number, item2: number): boolean {
  return item1 <= item2;
}

function lessThan(item1: number, item2: number): boolean {
  return item1 < item2;
}

export function findImageGreaterThan<Image extends ImageSize>(
  images: Image[],
  options: {
    width?: number;
    height?: number;
    equals?: boolean;
  },
): Image | undefined {
  const func = options.equals ? lessOrEqualsThan : lessThan;

  return images.find((image) => func(options.width || 0, image.width) && func(options.height || 0, image.height));
}

export function findImageGreaterOrEqualsThan<Image extends ImageSize>(
  images: Image[],
  {width, height}: Partial<Size>,
): Image | undefined {
  return findImageGreaterThan(images, {width: width || 0, height: height || 0, equals: true});
}

export function findMaxImage<Image extends ImageSize>(images: Image[]): Image | undefined {
  return maxNumeric(images, (image) => image.width) ?? undefined;
}

export function findBestImageForSize<Image extends ImageSize>(images: Image[], size: Partial<Size>): Image | undefined {
  return findImageGreaterOrEqualsThan(images, size) ?? findMaxImage(images);
}

export function makeSrcSet<Image extends ImageBundleItem>(images: Image[]): string | undefined {
  return images.length ? images.map((img) => `${img.url} ${img.width}w`).join(',') : undefined;
}
