import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {OutstaffUserPage} from 'pages/OutstaffUser';
import {OutstaffUsersPage} from 'pages/OutstaffUsers';
import {RouteConfig} from 'react-router-config';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {outstaffUsersUrlPatterns} from 'routes/outstaffUsers/urls';

export const config: RouteConfig = {
  path: outstaffUsersUrlPatterns.outstaffUsers,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: withPermission(OutstaffUsersPage, Permission.OUTSTAFF_USER_READ),
      exact: true,
      path: outstaffUsersUrlPatterns.outstaffUsers,
    },
    {
      component: withPermission(OutstaffUserPage, Permission.OUTSTAFF_USER_READ),
      exact: true,
      path: outstaffUsersUrlPatterns.outstaffUser,
    },
    notFoundRouteConfig,
  ],
};
