import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {
  array,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {crResultSchema} from 'domain/compaRatio/model';
import {salaryRangeSchema} from 'domain/salaryRange/model';
import {userSchema} from 'domain/user/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {ChangeValue} from './changeValue';
import {decisionSchema} from './decision';
import {Status} from './status';
import {Type} from './type';
import {userInfoSchema} from './userInfo';

export const compensationReviewRequestIdSchema = idSchema;
export const compensationReviewRequestSchema = object({
  compensationChange: oneOfEnum(ChangeValue),
  compensationChangeDate: optional(formattedDateSchema),
  createdAt: formattedDateSchema,
  createdBy: userSchema,
  decisions: array(decisionSchema),
  effectiveDate: formattedDateSchema,
  id: compensationReviewRequestIdSchema,
  newCr: crResultSchema,
  newSalaryRange: optional(salaryRangeSchema),
  newUserInfo: userInfoSchema,
  oldCr: crResultSchema,
  oldUserInfo: userInfoSchema,
  reason: optional(string()),
  status: oneOfEnum(Status),
  stockOptionsChange: oneOfEnum(ChangeValue),
  stockOptionsChangeDate: optional(formattedDateSchema),
  type: oneOfEnum(Type),
  user: userSchema,
});

export const countableCompensationReviewRequstSchema = object({
  items: array(compensationReviewRequestSchema),
  totalCount: number(),
});

export type CompensationReviewRequestId = ExtractSchemaType<typeof compensationReviewRequestIdSchema>;
export type CompensationReviewRequest = ExtractSchemaType<typeof compensationReviewRequestSchema>;
export type CountableCompensationReviewRequests = ExtractSchemaType<typeof countableCompensationReviewRequstSchema>;
