import {isNullish} from '@joomcode/deprecated-utils/function';
import {DataTable} from '@joomcode/joom-ui/DataTable';
import {Price} from '@joomcode/joom-ui/FormattedPrice';
import {FormattedPrice} from 'components/ui/FormattedPrice';
import {ListedProperty} from 'components/ui/ListedProperty';
import {MultilineText} from 'components/ui/MultilineText';
import {CrResult} from 'domain/compaRatio/model';
import {CrValue} from 'domain/compaRatio/widgets/Value';
import {CountryCode} from 'domain/countryCode/model';
import {OfferSalary} from 'domain/offer/model/compensation';
import {formatFurtherSalaryReason} from 'domain/offer/model/furtherSalary/formatter';
import {OfferRegularBonus} from 'domain/offer/model/regularBonus';
import {FormattedRegularBonus} from 'domain/regularBonusRecord/widgets/FormattedRegularBonus';
import {FormattedSalary} from 'domain/salaryRecord/widgets/FormattedSalary';
import {User} from 'domain/user/model';
import {UserLink} from 'domain/user/widgets/Link';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {getCountryName} from 'utils/country/name';
import styles from './styles.css';

type Value = OfferSalary | OfferRegularBonus | Price | User | CountryCode | number | string | CrResult;

type Props = {
  property: string;
  label: string;
  oldValue?: Value;
  newValue?: Value;
};

export function OfferValueDiff({property, oldValue, newValue, label}: Props) {
  const intl = useIntl();

  const renderValue = useCallback(
    (value: Value | undefined, isNew?: boolean): React.ReactNode => {
      if (isNullish(value)) {
        return <DataTable.CellEmpty />;
      }

      switch (property) {
        case 'salary':
          return <FormattedSalary {...(value as OfferSalary)} />;
        case 'regularBonus':
          return <FormattedRegularBonus {...(value as OfferRegularBonus)} />;
        case 'signOnBonus':
        case 'relocationBonus':
          return <FormattedPrice value={value as Price} />;
        case 'manager':
          return <UserLink user={value as User} withAvatar={false} colored={isNew} />;
        case 'reason':
          return formatFurtherSalaryReason(value as string, intl);
        case 'employmentCountry':
        case 'relocationCountry':
          return getCountryName(value as CountryCode, intl);
        case 'amount':
          return intl.formatNumber(value as number);
        case 'crResult':
          return <CrValue cr={value as CrResult} coloredIcon />;
        case 'comment':
        case 'commentForHrAdmin':
          return <MultilineText text={value as string} />;
        default:
          return value as string;
      }
    },
    [property, intl],
  );

  return (
    <ListedProperty label={label}>
      <div>{renderValue(newValue, true)}</div>
      <div className={styles.oldValue}>{renderValue(oldValue)}</div>
    </ListedProperty>
  );
}
