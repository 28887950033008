import {isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {SecurePermission} from 'domain/permission/model/secure';
import {loadAllSecureRolesFx} from 'domain/secureRole/stores/main';
import {$secureRoles} from 'domain/secureRole/stores/main/state';
import {SecureRoleForm} from 'domain/secureRole/widgets/SecureRoleForm';
import {SecureRoleIssue} from 'domain/secureRole/widgets/SecureRoleIssue';
import {SecureRoleOwners} from 'domain/secureRole/widgets/SecureRoleOwners';
import {useStore} from 'effector-react';
import {NotFoundPage} from 'pages/NotFound';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {pageTitles} from 'routes/secureRoles/titles';
import {secureRolesUrls} from 'routes/secureRoles/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

export function SecureRolePage() {
  const intl = useIntl();
  const {id} = useParams<{id: string}>();
  const acl = useAcl();
  const {byId, dataState} = useStore($secureRoles);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      loadAllSecureRolesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return (
    <PageStateHandler data={byId} state={dataState}>
      {(roles) => {
        const role = roles[id];
        if (role === undefined) {
          return <NotFoundPage />;
        }

        return (
          <Page
            breadcrumbs={[
              {
                title: intl.formatMessage(pageTitles.root),
                href: secureRolesUrls.root(),
              },
            ]}
            title={intl.formatMessage(messages.title, {name: role.title})}
          >
            <SecureRoleForm role={role} />
            {acl.hasSecurePermission(SecurePermission.SECURE_ROLE_GRANT) && <SecureRoleIssue role={role} />}
            <SecureRoleOwners role={role} />
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
