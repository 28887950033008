import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  verticalAlign?: HTMLElement['style']['verticalAlign'];
};

export function VteamBadge({verticalAlign}: Props) {
  const intl = useIntl();
  return (
    <span className={styles.root} style={{verticalAlign}}>
      {intl.formatMessage(messages.vTeam)}
    </span>
  );
}
