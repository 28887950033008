import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  servicesLoadingFailed: {
    defaultMessage: 'Failed to load service list. Please refresh the page.',
    description: 'Service select: error message',
  },
  noItems: {
    defaultMessage: 'No services',
    description: 'Service select: empty message',
  },
});
