import cookie, {CookieSerializeOptions} from 'cookie';
import {Storage} from '../types';

const DEFAULT_OPTIONS: CookieSerializeOptions = {
  maxAge: 60 * 60 * 24 * 365 * 10,
  path: '/',
};

export class CookieStorage implements Storage {
  public get(name: string): string | null {
    return cookie.parse(document.cookie)[name] || null;
  }

  public remove(name: string): void {
    document.cookie = cookie.serialize(name, 'deleted', {
      expires: new Date(0),
    });
  }

  public set(name: string, value: string): void {
    document.cookie = cookie.serialize(name, value, DEFAULT_OPTIONS);
  }
}
