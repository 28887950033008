import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Runtime config',
    description: '[title] Page title',
  },
  createButton: {
    defaultMessage: 'Create',
    description: '[button] Create button',
  },
});
