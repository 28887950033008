import {CreateSalaryRecordParams} from 'domain/salaryRecord/api/types/create';
import {DeleteSalaryRecordParams} from 'domain/salaryRecord/api/types/delete';
import {GetSalaryRecordsParams} from 'domain/salaryRecord/api/types/get';
import {ReplaceSalaryRecordParams} from 'domain/salaryRecord/api/types/replace';
import {UpdateSalaryRecordParams} from 'domain/salaryRecord/api/types/update';
import {ExtendedSalaryRecord, SalaryRecord} from 'domain/salaryRecord/model';
import {createEffect} from 'effector';

export const createSalaryRecordFx = createEffect<CreateSalaryRecordParams, SalaryRecord[]>('create salary record');
export const createExtendedSalaryRecordFx = createEffect<CreateSalaryRecordParams, ExtendedSalaryRecord[]>(
  'create extended salary record',
);
export const deleteSalaryRecordFx = createEffect<DeleteSalaryRecordParams, void>('delete salary record');
export const getAnyExtendedSalaryRecordsFx = createEffect<GetSalaryRecordsParams, ExtendedSalaryRecord[]>(
  'get extended salary records of any joomer',
);
export const getAnySalaryRecordsFx = createEffect<GetSalaryRecordsParams, SalaryRecord[]>(
  'get salary records of any joomer',
);
export const getMySalaryRecordsFx = createEffect<GetSalaryRecordsParams, SalaryRecord[]>('get own salary records');
export const getSubordinateSalaryRecordsFx = createEffect<GetSalaryRecordsParams, SalaryRecord[]>(
  'get salary records of a subordinate',
);
export const replaceSalaryRecordFx = createEffect<ReplaceSalaryRecordParams, SalaryRecord[]>(
  'create new salary record based on existing one',
);
export const replaceExtendedSalaryRecordFx = createEffect<ReplaceSalaryRecordParams, ExtendedSalaryRecord[]>(
  'create new extended salary record based on existing one',
);
export const updateSalaryRecordFx = createEffect<UpdateSalaryRecordParams, SalaryRecord[]>('update salary record');
export const updateExtendedSalaryRecordFx = createEffect<UpdateSalaryRecordParams, ExtendedSalaryRecord[]>(
  'update extended salary record',
);
