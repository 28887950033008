import React from 'react';
import {TooltipFaq} from '../../../TooltipFaq';
import {ReactComponent as InfoIcon} from '../../../icons/circle/info.svg';
import styles from './index.css';

type Props = {
  name: React.ReactNode;
  description?: React.ReactNode;
};

export function HeaderTitle({name, description}: Props) {
  return (
    <div className={styles.title}>
      <span className={styles.name}>{name}</span>
      {description && (
        <TooltipFaq content={description} placement='top'>
          <div className={styles.icon}>
            <InfoIcon />
          </div>
        </TooltipFaq>
      )}
    </div>
  );
}
