import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ClampedText} from 'components/ui/ClampedText';
import {FormattedPrice} from 'components/ui/FormattedPrice';
import {HintIcon} from 'components/ui/HintIcon';
import {SensitiveData} from 'components/ui/SensitiveData';
import {OneTimeBonusRecord, OneTimeBonusRecordId} from 'domain/oneTimeBonusRecord/model';
import {formatOneTimeBonusCondition} from 'domain/oneTimeBonusRecord/model/condition/formatter';
import {formatOneTimeBonusType} from 'domain/oneTimeBonusRecord/model/type/formatter';
import {OneTimeBonusRecordMenu} from 'domain/oneTimeBonusRecord/widgets/Menu';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

export type Props = {
  dataState: DataState;
  records: OneTimeBonusRecord[];
  isSensitiveDataHidden: boolean;
  onDeleteItem?: (id: OneTimeBonusRecordId) => void;
  onEditItem?: (record: OneTimeBonusRecord) => void;
};

const getRowKey = ({id}: OneTimeBonusRecord) => id;

export function OneTimeBonusRecordsTable({dataState, records, isSensitiveDataHidden, onDeleteItem, onEditItem}: Props) {
  const intl = useIntl();
  const columns: Column<OneTimeBonusRecord>[] = useMemo(() => {
    const basicColumns: Column<OneTimeBonusRecord>[] = [
      {
        defaultWidth: 5,
        id: ColumnId.DATE,
        name: intl.formatMessage(columnNames[ColumnId.DATE]),
        render: ({date}) => <HumanDate value={date} format='short' utc />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.AMOUNT,
        name: intl.formatMessage(columnNames[ColumnId.AMOUNT]),
        render: (record) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            <FormattedPrice value={record} />
          </SensitiveData>
        ),
      },
      {
        defaultWidth: 5,
        id: ColumnId.TYPE,
        name: intl.formatMessage(columnNames[ColumnId.TYPE]),
        render: ({type}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>{formatOneTimeBonusType(type, intl)}</SensitiveData>
        ),
      },
      {
        defaultWidth: 5,
        id: ColumnId.CONDITION,
        name: (
          <>
            {intl.formatMessage(columnNames[ColumnId.CONDITION])}
            <HintIcon>{intl.formatMessage(messages.conditionHint)}</HintIcon>
          </>
        ),
        render: ({condition}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>{formatOneTimeBonusCondition(condition, intl)}</SensitiveData>
        ),
      },
      {
        defaultWidth: 5,
        id: ColumnId.PENAL_DEADLINE,
        name: intl.formatMessage(columnNames[ColumnId.PENAL_DEADLINE]),
        render: ({penalDeadline}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            {penalDeadline ? <HumanDate value={penalDeadline} format='short' utc /> : <DataTable.CellEmpty />}
          </SensitiveData>
        ),
      },
      {
        defaultWidth: 12,
        id: ColumnId.COMMENT,
        name: intl.formatMessage(columnNames[ColumnId.COMMENT]),
        render: ({comment}) => (
          <SensitiveData isHidden={isSensitiveDataHidden}>
            {comment ? <ClampedText text={comment} /> : <DataTable.CellEmpty />}
          </SensitiveData>
        ),
      },
    ];

    const actionColumns: Column<OneTimeBonusRecord>[] =
      onDeleteItem || onEditItem
        ? [
            {
              defaultWidth: 3,
              id: ColumnId.ACTIONS,
              name: '',
              render: (record) => (
                <OneTimeBonusRecordMenu record={record} onDelete={onDeleteItem} onEdit={onEditItem} />
              ),
            },
          ]
        : [];

    return [...basicColumns, ...actionColumns];
  }, [intl, onDeleteItem, onEditItem, isSensitiveDataHidden]);

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={records}
      emptyMessage={intl.formatMessage(messages.empty)}
      getRowKey={getRowKey}
      storageName='oneTimeBonusRecords.list'
    />
  );
}
