import {
  array,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {legalAddressSchema} from 'domain/legalInfo/model/address';
import {ComputerType} from 'domain/offer/model/newcomer/computerType';
import {newcomerDocumentsSchema} from 'domain/offer/model/newcomer/documents';
import {EducationLevel} from 'domain/offer/model/newcomer/educationLevel';
import {ExtraHardware} from 'domain/offer/model/newcomer/extraHardware';
import {FiscalSituation} from 'domain/offer/model/newcomer/fiscalSituation';
import {MaritalStatus} from 'domain/offer/model/newcomer/maritalStatus';
import {OperatingSystem} from 'domain/offer/model/newcomer/operatingSystem';
import {ShirtSize} from 'domain/shirtSize/model';
import {userSchema} from 'domain/user/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';
import {imageBundleSchema} from 'models/system/imageBundle';

export const newcomerInOfferSchema = object({
  accountNumber: optional(string()),
  avatar: optional(imageBundleSchema),
  bankDetailsFileId: optional(idSchema),
  bankIdentificationCode: optional(string()),
  bankName: optional(string()),
  children: optional(number()),
  computerInfo: optional(string()),
  computerType: optional(oneOfEnum(ComputerType)),
  creator: optional(userSchema),
  department: optional(string()),
  division: optional(string()),
  documents: optional(newcomerDocumentsSchema),
  educationLevel: optional(oneOfEnum(EducationLevel)),
  extraHardware: optional(array(oneOfEnum(ExtraHardware))),
  firstName: string(),
  fiscalSituation: optional(oneOfEnum(FiscalSituation)),
  hardwareNotes: optional(string()),
  idCardFileId: optional(idSchema),
  insuranceNumber: optional(string()),
  insuranceNumberFileId: optional(idSchema),
  internalPassportAddressPageFileId: optional(idSchema),
  internalPassportMainPageFileId: optional(idSchema),
  lastName: string(),
  linkToGoogleDrive: optional(string()),
  login: optional(string()),
  maritalStatus: optional(oneOfEnum(MaritalStatus)),
  operatingSystem: optional(oneOfEnum(OperatingSystem)),
  placeOfCurrentResidence: optional(legalAddressSchema),
  placeOfFirstWorkingDay: optional(string()),
  placeOfResidence: optional(string()), // deprecated, use placeOfCurrentResidence instead
  shirtSize: optional(oneOfEnum(ShirtSize)),
  startWorkingDate: optional(formattedDateSchema),
  taxNumber: optional(string()),
  taxNumberFileId: optional(idSchema),
});

export type NewcomerInOffer = ExtractSchemaType<typeof newcomerInOfferSchema>;
