import {TabId, TAB_ID_ATTRIBUTE_NAME, TAB_CLOSABLE_ATTRIBUTE_NAME} from './types';

export function getTabNodeFromEvent(event: Event): Element | undefined {
  if (!event.target) {
    return undefined;
  }

  const target = event.target as HTMLElement;

  if (target.hasAttribute(TAB_ID_ATTRIBUTE_NAME)) {
    return target;
  }

  return target.closest(`[role="tab"]`) ?? undefined;
}

export function getTabIdFromEvent(event: Event): TabId | null {
  const tabNode = getTabNodeFromEvent(event);

  return tabNode?.getAttribute(TAB_ID_ATTRIBUTE_NAME) || null;
}

export function isTabClosable(tabNode: Element): boolean {
  return tabNode.getAttribute(TAB_CLOSABLE_ATTRIBUTE_NAME) === 'true';
}

export function getTabNodeId(id: TabId): string {
  return `tab-${id}`;
}

export function getTabPanelNodeId(id: TabId): string {
  return `tab-panel-${id}`;
}
