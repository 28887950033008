import {salaryRecordsApi} from 'domain/salaryRecord/api';
import {
  createExtendedSalaryRecordFx,
  createSalaryRecordFx,
  deleteSalaryRecordFx,
  getAnyExtendedSalaryRecordsFx,
  getAnySalaryRecordsFx,
  getMySalaryRecordsFx,
  getSubordinateSalaryRecordsFx,
  replaceExtendedSalaryRecordFx,
  replaceSalaryRecordFx,
  updateExtendedSalaryRecordFx,
  updateSalaryRecordFx,
} from '.';

createSalaryRecordFx.use(salaryRecordsApi.create);
createExtendedSalaryRecordFx.use(salaryRecordsApi.createExtended);
deleteSalaryRecordFx.use(salaryRecordsApi.delete);
getAnyExtendedSalaryRecordsFx.use(salaryRecordsApi.getAnyExtended);
getAnySalaryRecordsFx.use(salaryRecordsApi.getAny);
getSubordinateSalaryRecordsFx.use(salaryRecordsApi.getSubordinate);
getMySalaryRecordsFx.use(salaryRecordsApi.getMy);
replaceSalaryRecordFx.use(salaryRecordsApi.replace);
replaceExtendedSalaryRecordFx.use(salaryRecordsApi.replaceExtended);
updateSalaryRecordFx.use(salaryRecordsApi.update);
updateExtendedSalaryRecordFx.use(salaryRecordsApi.updateExtended);
