import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {CopyButton} from '@joomcode/joom-ui/Copy/Button';
import {Input} from '@joomcode/joom-ui/Input';
import {officesApi} from 'domain/officeMap/office/api';
import {OfficeRoom} from 'domain/officeMap/room/model';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  room: OfficeRoom;
};

export function FieldTokenGeneration({room}: Props) {
  const intl = useIntl();
  const [token, setToken] = useState<string | undefined>();
  const generateToken = useAsyncTask(
    () => officesApi.generateToken({roomId: room.id}).then(setToken).catch(toaster.interceptThenThrowError),
    [room.id, setToken],
  );

  return (
    <div className={styles.root}>
      <span className={styles.label}>{`${room.fullText || room.text}:`}</span>
      {token && (
        <span className={styles.input}>
          <Input disabled value={token} />
        </span>
      )}
      <span className={styles.button}>
        {token ? (
          <CopyButton
            size='m'
            kind='primary'
            intent='primary'
            label={intl.formatMessage(messages.buttonCopy)}
            value={token}
          />
        ) : (
          <Button
            size='m'
            intent='primary'
            kind='primary'
            loading={generateToken.isPerforming}
            onClick={generateToken.perform}
          >
            {intl.formatMessage(messages.buttonGenerate)}
          </Button>
        )}
      </span>
    </div>
  );
}
