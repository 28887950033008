import {mimeType} from '@joomcode/deprecated-utils/file/mime';
import {identity} from '@joomcode/deprecated-utils/function';
import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {getFieldError, useFieldWithInitialValue} from '@joomcode/joom-form';
import {MultipleFilesUpload} from 'components/widgets/MultipleFilesUpload';
import {FieldValidator} from 'final-form';
import {FileMeta} from 'models/system/fileMeta';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {errorMessages, messages} from './messages';
import styles from './styles.css';

const NAME = 'boardingPasses';
const ACCEPTED_MIME_TYPES = [mimeType.image.jpeg, mimeType.image.png, mimeType.pdf];
const MAX_FILE_SIZE = fileSizeUtils.createMeasure(8, FileSizeUnit.Megabyte);

type Props = {
  disabled?: boolean;
  initialFileMetas: FileMeta[];
};

export function FieldBoardingPasses({disabled, initialFileMetas}: Props) {
  const intl = useIntl();
  const validate: FieldValidator<string[]> = useCallback(
    (value, allValues: Record<string, unknown>) =>
      // eslint-disable-next-line dot-notation
      !allValues['hasNoFlights'] && value.length === 0
        ? intl.formatMessage(errorMessages.emptyBoardingPasses)
        : undefined,
    [intl],
  );
  const initialFileIds = useMemo(() => initialFileMetas.map((fileMeta) => fileMeta.id), [initialFileMetas]);
  const {input, meta} = useFieldWithInitialValue<string[]>(NAME, {
    initialValue: initialFileIds,
    parse: identity,
    validate,
  });
  const errorMessage = getFieldError(meta);
  const onFileMetasChange = useCallback(
    (fileMetas: FileMeta[]) => input.onChange(fileMetas.map(({id}) => id)),
    [input.onChange],
  );

  return (
    <>
      <MultipleFilesUpload
        acceptedMimeTypes={ACCEPTED_MIME_TYPES}
        attachButtonLabel={intl.formatMessage(messages.attachBoardingPassesButton)}
        attachMoreButtonLabel={intl.formatMessage(messages.attachMoreBoardingPassesButton)}
        disabled={disabled}
        initialFileMetas={initialFileMetas}
        maxFileSize={MAX_FILE_SIZE}
        onChange={onFileMetasChange}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
      />
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </>
  );
}
