import {array, ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {roleInTeamSchema} from 'domain/role/model/roleInTeam';
import {serviceIdSchema, serviceSchema} from 'domain/service/model';
import {userSchema} from 'domain/user/model';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';
import {ResourceStatus} from './status';

export const resourceIdSchema = idSchema;

export const resourceSchema = object({
  createdBy: userSchema,
  description: optional(string()),
  id: resourceIdSchema,
  name: optional(string()),
  owners: optionalWithDefault([], array(userSchema)),
  owningMemberships: optionalWithDefault([], array(roleInTeamSchema)),
  status: oneOfEnum(ResourceStatus),
  service: serviceSchema,
  value: string(),
}).map((resource) => ({...resource, displayName: resource.name ?? resource.value}));

export type Resource = ExtractSchemaType<typeof resourceSchema>;
export type ResourceId = ExtractSchemaType<typeof resourceIdSchema>;

export const resourceSearchFiltersSchema = object({
  serviceIds: optional(array(serviceIdSchema)),
  statuses: optional(array(oneOfEnum(ResourceStatus))),
});

export type ResourcesSearchFilters = Partial<ExtractSchemaType<typeof resourceSearchFiltersSchema>>;
