import {intranetApi} from 'apiClient';
import {
  CompensationReviewRequest,
  CompensationReviewRequestId,
  compensationReviewRequestSchema,
} from 'domain/compensationReviewRequest/model';

export type CompensationReviewRequestSetRecordedDiff = {
  date: string;
};

export type CompensationReviewRequestSetRecordedConfig = CompensationReviewRequestSetRecordedDiff & {
  id: CompensationReviewRequestId;
};

const setRecordedGeneric =
  (endpoint: string) =>
  async (params: CompensationReviewRequestSetRecordedConfig): Promise<CompensationReviewRequest> => {
    const {data} = await intranetApi.post(endpoint, undefined, {
      params,
    });

    return compensationReviewRequestSchema.runWithException(data);
  };

export const setCompensationsRecorded = setRecordedGeneric(
  '/v1/secure/compensationReviewRequests/setCompensationsRecorded',
);
export const setStockOptionsRecorded = setRecordedGeneric(
  '/v1/secure/compensationReviewRequests/setStockOptionsRecorded',
);
