import {Button} from '@joomcode/joom-ui/Button';
import {CloseButton} from '@joomcode/joom-ui/CloseButton';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {CountryCode} from 'domain/countryCode/model';
import {LegalEntity} from 'domain/legalEntity/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  onClose: () => void;
  userLegalEntity?: LegalEntity;
};

export function CompensationReportedPopup({onClose, userLegalEntity}: Props) {
  const intl = useIntl();
  const text =
    userLegalEntity?.countryCode === CountryCode.RU
      ? intl.formatMessage(messages.textForRussians)
      : intl.formatMessage(messages.text);
  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='max(60vw, 30rem)'>
      <Dialog.Body>
        <div className={styles.root}>
          <div className={styles.closeButtonWrap}>
            <CloseButton onClick={onClose} />
          </div>
          <h2 className={styles.title}>{intl.formatMessage(messages.title)}</h2>
          <div className={styles.text}>{text}</div>
          <div className={styles.buttonWrap}>
            <Button kind='primary' intent='primary' size='l' onClick={onClose}>
              {intl.formatMessage(messages.button)}
            </Button>
          </div>
        </div>
      </Dialog.Body>
    </Dialog>
  );
}
