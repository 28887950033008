import cn from 'classnames';
import React from 'react';
import styles from './styles.css';

type Props = React.PropsWithChildren<{
  bordered?: boolean;
  padded?: boolean;
  title: React.ReactNode;
}>;

export function Section({bordered = false, padded = true, children, title}: Props) {
  return (
    <div className={cn({[styles.bordered]: bordered, [styles.padded]: padded})}>
      <h3 className={styles.title}>{title}</h3>
      {children}
    </div>
  );
}
