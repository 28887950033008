import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.REGION]: {
    defaultMessage: 'Region',
    description: 'Salary ranges table column name',
  },
  [ColumnId.USER_FUNCTION]: {
    defaultMessage: 'Function',
    description: 'Salary ranges table column name',
  },
  [ColumnId.LEVEL_CODE]: {
    defaultMessage: 'Level code',
    description: 'Salary ranges table column name',
  },
  [ColumnId.CURRENCY]: {
    defaultMessage: 'Currency',
    description: 'Salary ranges table column name',
  },
  [ColumnId.GROUP_OF_FUNCTIONS]: {
    defaultMessage: 'Group of functions',
    description: 'Salary ranges table column name',
  },
  [ColumnId.MAX_AMOUNT]: {
    defaultMessage: 'Maximum',
    description: 'Salary ranges table column name',
  },
  [ColumnId.TARGET_AMOUNT]: {
    defaultMessage: 'Midpoint',
    description: 'Salary ranges table column name',
  },
  [ColumnId.MIN_AMOUNT]: {
    defaultMessage: 'Minimum',
    description: 'Salary ranges table column name',
  },
  [ColumnId.COMMENT]: {
    defaultMessage: 'Comment',
    description: 'Salary ranges table column name',
  },
  [ColumnId.STOCK_OPTIONS_PROPOSAL]: {
    defaultMessage: 'Options proposal',
    description: 'Salary ranges table column name',
  },
});

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No salary ranges',
    description: 'Salary ranges table: empty',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load salary ranges',
    description: 'Salary ranges table: error',
  },
});
