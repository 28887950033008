import {intranetApi} from 'apiClient';
import {StockOptionAgreementId} from 'domain/stockOption/agreement/model';
import {
  StockOptionAgreementWithSummary,
  stockOptionAgreementWithSummarySchema,
} from 'domain/stockOption/agreement/model/withSummary';
import {StockOptionAgreementFreezeId} from 'domain/stockOption/agreementFreeze/model';
import {UserId} from 'domain/user/model';

export type StockOptionAgreementFreezeDeleteConfig = {
  userId: UserId;
  agreementId: StockOptionAgreementId;
  id: StockOptionAgreementFreezeId;
};

export const deleteStockOptionAgreementFreeze = async (
  params: StockOptionAgreementFreezeDeleteConfig,
): Promise<StockOptionAgreementWithSummary> => {
  const {data} = await intranetApi.post('/v1/secure/stockOptions/agreements/freezes/delete', undefined, {
    params,
  });

  return stockOptionAgreementWithSummarySchema.runWithException(data);
};
