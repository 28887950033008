import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  reportTitle: {
    defaultMessage: 'Report',
    description: '[title] Compensation request page: report title',
  },
  fillReportButton: {
    defaultMessage: 'Fill out the report',
    description: '[button] Compensation request page: report button',
  },
  continueReportButton: {
    defaultMessage: 'Continue filling out the report',
    description: '[button] Compensation request page: report button',
  },
});
