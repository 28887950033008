import {Panel} from '@joomcode/joom-ui/Panel';
import {useStockOptionTypesVisibility} from 'domain/stockOption/holder/hooks/useTypesVisibility';
import {StockOptionReadAccess} from 'domain/stockOption/holder/model/access';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import {StockOptionValueEstimation} from 'domain/stockOption/holder/widgets/ValueEstimation';
import {StockOptionSummary, StockOptionSummaryValue} from 'domain/stockOption/summary/model';
import {isValueBrokenDownByType} from 'domain/stockOption/summary/utils/isValueBrokenDownByType';
import {User} from 'domain/user/model';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {labels, messages, subrowLabels} from './messages';
import styles from './styles.css';

type Props = {
  summary: StockOptionSummary;
  readAccess: StockOptionReadAccess | null;
  user: User;
  showUser?: boolean;
};

export function StockOptionSummaryPanel({summary, readAccess, user, showUser}: Props) {
  const intl = useIntl();
  const canSeeOptionTypes = useStockOptionTypesVisibility(user);
  const showEstimation = readAccess === StockOptionReadAccess.MY || readAccess === StockOptionReadAccess.SUBORDINATE;
  const rows = useMemo(
    () =>
      Object.keys(summary).map((key) => {
        const value: StockOptionSummaryValue = summary[key as keyof StockOptionSummary];
        const isBrokenDownByType = isValueBrokenDownByType(value);
        const amount = isBrokenDownByType ? value.total : value;
        const subrows =
          canSeeOptionTypes && isBrokenDownByType
            ? Object.entries(value.byType)
                .filter(([_, amount]) => amount)
                .map(([key, amount]) => ({
                  key,
                  amount,
                  label: intl.formatMessage(subrowLabels[key as keyof typeof subrowLabels]),
                }))
            : [];

        return {
          key,
          amount,
          subrows,
          label: intl.formatMessage(labels[key as keyof typeof labels]),
        };
      }),
    [intl, summary, canSeeOptionTypes],
  );

  return (
    <Panel withMarginBottom={false} stretchHeight>
      {showUser && (
        <Panel.Section>
          <Panel.Content withPadding>
            <ListedUser user={user} />
          </Panel.Content>
        </Panel.Section>
      )}
      <Panel.Section>
        <Panel.Header title={intl.formatMessage(messages.title)} />
        <Panel.Content withPadding>
          <table className={styles.table}>
            <tbody>
              {rows.map((row) => (
                <>
                  <tr key={row.key}>
                    <td className={styles.label}>{row.label}</td>
                    <td className={styles.amount}>
                      <FormattedOptionsAmount amount={row.amount} />
                    </td>
                    {showEstimation && (
                      <td className={styles.estimation}>
                        <StockOptionValueEstimation amount={row.amount} />
                      </td>
                    )}
                  </tr>
                  {row.subrows.map((subrow) => (
                    <tr key={subrow.key}>
                      <td className={styles.subrowLabel}>{subrow.label}</td>
                      <td className={styles.subrowAmount}>
                        <FormattedOptionsAmount amount={subrow.amount} />
                      </td>
                      {showEstimation && <td className={styles.subrowEstimation} />}
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </table>
        </Panel.Content>
      </Panel.Section>
    </Panel>
  );
}
