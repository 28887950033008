import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import {OneTimeBonusReadAccess} from 'domain/oneTimeBonusRecord/model/access';
import {UserFull} from 'domain/user/model';
import {useOneTimeBonusReadAccess} from './useOneTimeBonusReadAccess';

export function useOneTimeBonusesAvailability(user: UserFull | undefined, records: OneTimeBonusRecord[]): boolean {
  const readAccess = useOneTimeBonusReadAccess(user);
  const recordsExist = records.length > 0;

  const canSeeAnyOneTimeBonuses = readAccess === OneTimeBonusReadAccess.ANY;
  const canSeeSubordinateOneTimeBonuses = recordsExist && readAccess === OneTimeBonusReadAccess.SUBORDINATE;
  const canSeeOwnOneTimeBonuses = recordsExist && readAccess === OneTimeBonusReadAccess.MY;

  return canSeeAnyOneTimeBonuses || canSeeSubordinateOneTimeBonuses || canSeeOwnOneTimeBonuses;
}
