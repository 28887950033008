import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {
  array,
  dateFromString,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {TimeOffBalanceRecordType} from './type';

export const timeOffBalanceRecordIdSchema = idSchema;
export const timeOffBalanceRecordSchema = object({
  createdTime: dateFromString(),
  id: timeOffBalanceRecordIdSchema,
  reason: optional(string()),
  daysAdded: number(),
  totalBalance: number(),
  type: oneOfEnum(TimeOffBalanceRecordType),
});
export const timeOffBalanceRecordsByTypeSchema = object({
  remoteWorkBalanceRecords: array(timeOffBalanceRecordSchema),
  vacationBalanceRecords: array(timeOffBalanceRecordSchema),
});

export type TimeOffBalanceRecordId = ExtractSchemaType<typeof timeOffBalanceRecordIdSchema>;
export type TimeOffBalanceRecord = ExtractSchemaType<typeof timeOffBalanceRecordSchema>;
export type TimeOffBalanceRecordsByType = ExtractSchemaType<typeof timeOffBalanceRecordsByTypeSchema>;
