type Options<Key, Value> = {
  maxSize: number;
  onChange?: (entries: [Key, Value][]) => void;
};

export class FifoCache<Key, Value> {
  private cache: Map<Key, Value>;

  private keyQueue: Key[];

  private readonly options: Options<Key, Value>;

  public constructor(initialValue: [Key, Value][], options: Options<Key, Value>) {
    const slicedInitialValue = initialValue.slice(options.maxSize * -1);

    this.cache = new Map(slicedInitialValue);
    this.keyQueue = slicedInitialValue.map(([key]) => key);
    this.options = options;
  }

  public get(key: Key): Value | null {
    return this.cache.get(key) || null;
  }

  public set(key: Key, value: Value): void {
    this.cache.set(key, value);
    this.keyQueue = this.keyQueue.filter((item) => item !== key).concat(key);

    if (this.keyQueue.length > this.options.maxSize) {
      const discardedItemKey = this.keyQueue.shift();

      if (discardedItemKey) {
        this.cache.delete(discardedItemKey);
      }
    }

    if (this.options.onChange) {
      this.options.onChange(this.entries);
    }
  }

  public delete(key: Key): void {
    this.cache.delete(key);
    this.keyQueue = this.keyQueue.filter((item) => item !== key);

    if (this.options.onChange) {
      this.options.onChange(this.entries);
    }
  }

  public get size(): number {
    return this.cache.size;
  }

  public get entries(): [Key, Value][] {
    const entries: [Key, Value][] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key of this.keyQueue) {
      const value = this.cache.get(key);

      if (typeof value !== 'undefined') {
        entries.push([key, value]);
      }
    }

    return entries;
  }
}
