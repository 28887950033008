import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Permission} from 'domain/permission/model';
import {Team, teamSchema} from 'domain/team/model';
import {TeamDiff} from 'domain/team/model/diff';
import {TeamId} from 'domain/team/model/id';

export const updateGeneric =
  (endpoint: string) =>
  async ({id, diff}: {id: TeamId; diff: TeamDiff}): Promise<Team> => {
    const {data} = await intranetApi.post(endpoint, diff, {
      params: {id},
    });

    return teamSchema.runWithException(data);
  };

export const updateDefault = updateGeneric('/v1/teams/update');
export const updateAsAdmin = updateGeneric('/v1/teams/updateAsAdmin');

export const update = createPermissionDependentHandler(
  [{permission: Permission.TEAM_ADMIN_WRITE, handler: updateAsAdmin}],
  updateDefault,
);
