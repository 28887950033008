import {intranetApi} from 'apiClient';
import {TimeOffRequest, TimeOffRequestCreationConfig, timeOffRequestSchema} from 'domain/timeOff/request/model';
import {UserId} from 'domain/user/model';

export type TimeOffRequestCreationOnBehalfConfig = {
  userId: UserId;
  diff: TimeOffRequestCreationConfig;
};

export const createOnBehalf = async ({diff, userId}: TimeOffRequestCreationOnBehalfConfig): Promise<TimeOffRequest> => {
  const {data} = await intranetApi.post('/v1/timeOffs/requests/createOnBehalf', diff, {params: {userId}});

  return timeOffRequestSchema.runWithException(data);
};
