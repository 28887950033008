import {DataState} from '@joomcode/deprecated-utils/dataState';
import {TeamRoleBindings} from 'domain/roleBinding/model';
import {RoleBindingStatus} from 'domain/roleBinding/model/status';
import {
  cancelRoleBindingRequestFx,
  requestRoleBindingAsAdminFx,
  requestRoleBindingFx,
  revokeRoleBindingFx,
} from 'domain/roleBinding/stores/main';
import {TeamId} from 'domain/team/model/id';
import {createStore} from 'effector';
import {getRoleBindingsByTeamIdFx} from '.';

type TeamRoleBindingsState = {
  byTeamId: Record<TeamId, TeamRoleBindings>;
  byTeamIdState: Record<TeamId, DataState>;
};

export const $teamRoleBindings = createStore<TeamRoleBindingsState>({
  byTeamId: {},
  byTeamIdState: {},
})
  .on(getRoleBindingsByTeamIdFx, (state, teamId) => {
    return {
      ...state,
      byTeamIdState: {
        ...state.byTeamIdState,
        [teamId]: DataState.LOADING,
      },
    };
  })
  .on(getRoleBindingsByTeamIdFx.fail, (state, {params: teamId}) => {
    return {
      ...state,
      byTeamIdState: {
        ...state.byTeamIdState,
        [teamId]: DataState.FAILED,
      },
    };
  })
  .on(getRoleBindingsByTeamIdFx.done, (state, {params: teamId, result}) => {
    return {
      ...state,
      byTeamId: {
        ...state.byTeamId,
        [teamId]: result,
      },
      byTeamIdState: {
        ...state.byTeamIdState,
        [teamId]: DataState.LOADED,
      },
    };
  })
  .on([requestRoleBindingFx.doneData, requestRoleBindingAsAdminFx.doneData], (state, result) => {
    const teamId = result.teamWithAncestors.team.id;
    const updatedBindings =
      result.status === RoleBindingStatus.APPROVED
        ? {activeBindings: [result, ...(state.byTeamId[teamId]?.activeBindings ?? [])]}
        : {myBindings: [result, ...(state.byTeamId[teamId]?.myBindings ?? [])]};

    return {
      ...state,
      byTeamId: {
        ...state.byTeamId,
        [teamId]: {
          ...state.byTeamId[teamId],
          ...updatedBindings,
        },
      },
    };
  })
  .on(cancelRoleBindingRequestFx.done, (state, {params: id, result}) => {
    const teamId = result.teamWithAncestors.team.id;

    return {
      ...state,
      byTeamId: {
        ...state.byTeamId,
        [teamId]: {
          ...state.byTeamId[teamId],
          myBindings: state.byTeamId[teamId].myBindings.map((roleBinding) =>
            roleBinding.id === id ? result : roleBinding,
          ),
        },
      },
    };
  })
  .on(revokeRoleBindingFx.done, (state, {params: id, result}) => {
    const teamId = result.teamWithAncestors.team.id;

    return {
      ...state,
      byTeamId: {
        ...state.byTeamId,
        [teamId]: {
          ...state.byTeamId[teamId],
          activeBindings: state.byTeamId[teamId].activeBindings.filter((roleBinding) => roleBinding.id !== id),
        },
      },
    };
  });
