import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Update service',
    description: '[title] Service update dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Service update dialog',
    description: 'Service update dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Service update dialog: cancel button',
  },
  submitButton: {
    defaultMessage: 'Update',
    description: '[button] Service update dialog: submit button',
  },
});
