import {getDescendants} from '@joomcode/deprecated-utils/tree/getDescendants';
import {MarketLevelPosition} from 'domain/compaRatio/model/marketLevelPosition';
import {LevelCode} from 'domain/levelCode/model';
import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {TimeOffPolicy} from 'domain/timeOff/policy/model';
import {User, UserId} from 'domain/user/model';
import {UserFullInfoRecord} from 'domain/userFullInfoRecord/model';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import {useCallback, useMemo} from 'react';

type Options = {
  userById: Record<UserId, User>;
  records: UserFullInfoRecord[];
  onlySubordinates?: boolean;
};

type ReturnShape = {
  countriesOfTimeOffPolicy: (TimeOffPolicy | undefined)[];
  crMarketLevelPositions: (MarketLevelPosition | undefined)[];
  divisions: (string | undefined)[];
  levelCodes: (LevelCode | undefined)[];
  managers: User[];
  userFunctions: (string | undefined)[];
  users: User[];
};

export function useAvailableFilterValues({userById, records, onlySubordinates}: Options): ReturnShape {
  const selfUser = useSelfUser();
  const activeMarketDataRecords = useMemo(
    () => records.map(({activeMarketDataRecord}) => activeMarketDataRecord),
    [records],
  );
  const countriesOfTimeOffPolicy = useMemo(() => uniq(records.map(({user}) => user.countryOfTimeOffPolicy)), [records]);
  const crMarketLevelPositions = useMemo(() => uniq(records.map(({cr}) => cr?.marketLevelPosition)), [records]);
  const divisions = useMemo(() => uniq(records.map(({user}) => user.division)), [records]);
  const levelCodes = useMemo(
    () => uniqBy(activeMarketDataRecords, 'levelCode').map((record) => record?.levelCode),
    [activeMarketDataRecords],
  );
  const userFunctions = useMemo(
    () => uniqBy(activeMarketDataRecords, 'userFunction').map((record) => record?.userFunction),
    [activeMarketDataRecords],
  );

  const allUsers = useMemo(() => Object.values(userById), [userById]);
  const getChildren = useCallback((user: User) => allUsers.filter(({managerId}) => managerId === user.id), [allUsers]);
  const subordinateUsers = useMemo(
    () => (selfUser ? getDescendants(selfUser, getChildren) : []),
    [selfUser, getChildren],
  );
  const allManagers = useMemo(
    () => allUsers.filter(({id}) => allUsers.some(({managerId}) => id === managerId)),
    [allUsers],
  );
  const subordinateManagersWithSelf = useMemo(() => {
    const managers = subordinateUsers.filter(({id}) => subordinateUsers.some(({managerId}) => id === managerId));
    return selfUser ? [selfUser, ...managers] : managers;
  }, [selfUser, subordinateUsers]);

  return {
    countriesOfTimeOffPolicy,
    crMarketLevelPositions,
    divisions,
    levelCodes,
    managers: onlySubordinates ? subordinateManagersWithSelf : allManagers,
    userFunctions,
    users: onlySubordinates ? subordinateUsers : allUsers,
  };
}
