import {noop} from '@joomcode/deprecated-utils/function';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {LegalEntityId} from 'domain/legalEntity/model';
import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import {OneTimeBonusRecordDiff} from 'domain/oneTimeBonusRecord/model/diff';
import {createOneTimeBonusRecordFx, updateOneTimeBonusRecordFx} from 'domain/oneTimeBonusRecord/stores/main';
import {OneTimeBonusRecordForm} from 'domain/oneTimeBonusRecord/widgets/Form';
import {UserId} from 'domain/user/model';
import React, {useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  userId: UserId;
  legalEntityId: LegalEntityId;
  recordToUpdate?: OneTimeBonusRecord;
  isOpen: boolean;
  onClose: () => void;
};

export function OneTimeBonusRecordDialog({userId, legalEntityId, recordToUpdate, isOpen, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const submitTask = useAsyncTask(
    (diff: OneTimeBonusRecordDiff) => {
      if (recordToUpdate) {
        return updateOneTimeBonusRecordFx({id: recordToUpdate.id, userId, legalEntityId, diff})
          .then(onClose)
          .catch(toaster.interceptThenThrowError);
      }
      return createOneTimeBonusRecordFx({userId, legalEntityId, diff})
        .then(onClose)
        .catch(toaster.interceptThenThrowError);
    },
    [userId, legalEntityId, recordToUpdate, onClose],
  );

  return (
    <Dialog
      ariaLabel={intl.formatMessage(messages.ariaLabel)}
      isOpen={isOpen}
      onClose={submitTask.isPerforming ? noop : onClose}
      width='min(90vw, 48rem)'
    >
      <Form<OneTimeBonusRecord>
        onSubmit={submitTask.perform}
        subscription={{submitting: true, invalid: true, values: true}}
      >
        {({handleSubmit, submitting, values, invalid}) => (
          <>
            <Dialog.Header>
              {intl.formatMessage(recordToUpdate ? messages.titleUpdate : messages.titleAdd)}
            </Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <OneTimeBonusRecordForm
                formId={formId}
                recordToUpdate={recordToUpdate}
                values={values}
                onSubmit={handleSubmit}
              />
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' disabled={submitting} onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
