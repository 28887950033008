import {DataState} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {JobContractRecord} from 'domain/jobContract/model/record';
import {JobContractRecordDiff} from 'domain/jobContract/model/record/diff';
import {FieldContractStatus} from 'domain/jobContract/widgets/FieldContractStatus';
import {FieldContractType} from 'domain/jobContract/widgets/FieldContractType';
import {useLegalEntities} from 'domain/legalEntity/hooks/useLegalEntities';
import {LegalEntityId} from 'domain/legalEntity/model';
import {LegalEntityFormControl} from 'domain/legalEntity/widgets/FormControl';
import {FieldTimeOffPolicy} from 'domain/timeOff/policy/widgets/Field';
import React from 'react';
import {FormRenderProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  formId: string;
  legalEntityId?: LegalEntityId;
  recordToUpdate?: JobContractRecord;
  submitting: boolean;
  onSubmit: FormRenderProps<JobContractRecordDiff>['handleSubmit'];
};

export function JobContractRecordForm({formId, legalEntityId, recordToUpdate, submitting, onSubmit}: Props) {
  const intl = useIntl();
  const {legalEntities, dataState} = useLegalEntities();

  return (
    <form onSubmit={onSubmit} id={formId} noValidate autoComplete='off'>
      <Grid>
        <Grid.Item xl={6} xs={12}>
          <FieldDate
            name='effectiveDate'
            label={intl.formatMessage(labels.effectiveDate)}
            initialValue={recordToUpdate?.effectiveDate}
            required
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <LegalEntityFormControl
            disabled={submitting || dataState !== DataState.LOADED || Boolean(legalEntityId)}
            label={intl.formatMessage(labels.legalEntity)}
            legalEntities={legalEntities}
            name='legalEntityId'
            required
            validate={validateFilled}
            initialValue={legalEntityId}
          />
        </Grid.Item>
        <Grid.Item xl={12}>
          <FieldTimeOffPolicy
            name='countryOfTimeOffPolicy'
            label={intl.formatMessage(labels.timeOffPolicy)}
            initialValue={recordToUpdate?.countryOfTimeOffPolicy}
            showWarnings
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldContractType
            name='contractType'
            label={intl.formatMessage(labels.contractType)}
            initialValue={recordToUpdate?.contractType}
            required
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldContractStatus
            name='status'
            label={intl.formatMessage(labels.status)}
            initialValue={recordToUpdate?.status}
            required
          />
        </Grid.Item>
        <Grid.Item xl={12} xs={12}>
          <FieldTextarea
            name='comment'
            label={intl.formatMessage(labels.comment)}
            rows={2}
            initialValue={recordToUpdate?.comment}
            parse={identity}
          />
        </Grid.Item>
      </Grid>
    </form>
  );
}
