import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {NotificationBadge} from '@joomcode/joom-ui/NotificationBadge';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Tabs} from '@joomcode/joom-ui/Tabs';
import {Permission} from 'domain/permission/model';
import {ResourceId} from 'domain/resource/model';
import {ResourceActiveRoleBindingsTable} from 'domain/resource/widgets/ActiveRoleBindingsTable';
import {ResourceRoleBindingsTable} from 'domain/resource/widgets/RoleBindingsTable';
import {ColumnId} from 'domain/resource/widgets/RoleBindingsTable/columnId';
import {useResourceActiveRoleBindings} from 'domain/roleBinding/hooks/useResourceActiveRoleBindings';
import {useResourceRoleBindingRequests} from 'domain/roleBinding/hooks/useResourceRoleBindingRequests';
import {RoleBindingCreationDialog} from 'domain/roleBinding/widgets/CreationDialog';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

enum TabId {
  ACTIVE = 'active',
  REQUESTS = 'requests',
}

type Props = {
  resourceId: ResourceId;
  canManage: boolean;
};

export function ResourceRoleBindingsPanel({resourceId, canManage}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  const {
    activeRoleBindings,
    activeRoleBindingsCount,
    activeRoleBindingsDataState,
    activeRoleBindingsPagination,
    setActiveRoleBindingsOutdated,
  } = useResourceActiveRoleBindings(resourceId);
  const {roleBindingRequests, roleBindingRequestsCount, roleBindingRequestsDataState, roleBindingRequestsPagination} =
    useResourceRoleBindingRequests(resourceId);

  const roleBindingCreationDialog = usePopupState();

  const tabs = useMemo(() => {
    return [
      {
        id: TabId.ACTIVE,
        label: intl.formatMessage(messages.tabActive),
        content: (
          <ResourceActiveRoleBindingsTable
            roleBindings={activeRoleBindings}
            count={activeRoleBindingsCount}
            dataState={activeRoleBindingsDataState}
            pagination={activeRoleBindingsPagination}
            canManage={canManage}
          />
        ),
      },
      {
        id: TabId.REQUESTS,
        label: intl.formatMessage(messages.tabRequests),
        content: (
          <ResourceRoleBindingsTable
            roleBindings={roleBindingRequests}
            count={roleBindingRequestsCount}
            dataState={roleBindingRequestsDataState}
            pagination={roleBindingRequestsPagination}
            columnIds={[ColumnId.ROLE, ColumnId.TEAM, ColumnId.CREATED_BY, ColumnId.ACTIONS]}
            canManage={canManage}
          />
        ),
        extra: (
          <NotificationBadge intent='primary' position='inlineEnd'>
            {roleBindingRequestsCount}
          </NotificationBadge>
        ),
      },
    ];
  }, [
    intl,
    canManage,
    activeRoleBindings,
    activeRoleBindingsCount,
    activeRoleBindingsDataState,
    activeRoleBindingsPagination,
    roleBindingRequests,
    roleBindingRequestsCount,
    roleBindingRequestsDataState,
    roleBindingRequestsPagination,
  ]);

  const [activeTabId, setActiveTabId] = useState<TabId>(tabs[0].id);
  const handleTabChange = useCallback(
    (id: TabId) => {
      setActiveTabId(id);
      if (id === TabId.ACTIVE) {
        setActiveRoleBindingsOutdated(true);
      }
    },
    [setActiveTabId, setActiveRoleBindingsOutdated],
  );

  return (
    <Panel>
      <Panel.Header
        withSeparator
        title={intl.formatMessage(messages.title)}
        toolbar={
          canManage &&
          acl.hasPermission(Permission.ROLE_BINDING_WRITE) && (
            <Button size='m' kind='primary' intent='primary' onClick={roleBindingCreationDialog.open}>
              {intl.formatMessage(messages.btnAddAccess)}
            </Button>
          )
        }
      />
      <Panel.Content>
        {canManage && roleBindingRequestsCount > 0 ? (
          <Tabs<TabId> renderActiveTabPanelOnly activeTabId={activeTabId} onChange={handleTabChange} tabs={tabs} />
        ) : (
          <ResourceActiveRoleBindingsTable
            roleBindings={activeRoleBindings}
            count={activeRoleBindingsCount}
            dataState={activeRoleBindingsDataState}
            pagination={activeRoleBindingsPagination}
            canManage={canManage}
          />
        )}
        {roleBindingCreationDialog.isOpen && (
          <RoleBindingCreationDialog
            resourceId={resourceId}
            onClose={roleBindingCreationDialog.close}
            onSuccessfulGrant={() => setActiveRoleBindingsOutdated(true)}
          />
        )}
      </Panel.Content>
    </Panel>
  );
}
