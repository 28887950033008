import {Button, DownloadLinkButton} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {ReactComponent as DownloadIcon} from '@joomcode/joom-ui/icons/core/download.svg';
import {ReactComponent as TrashIcon} from '@joomcode/joom-ui/icons/core/trash.svg';
import {SecurePermission} from 'domain/permission/model/secure';
import {salaryRangesApi} from 'domain/salaryRange/api';
import {SalaryRangesVersion} from 'domain/salaryRange/model/version';
import {deleteSalaryRangesVersionFx} from 'domain/salaryRange/stores/versions';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  version: SalaryRangesVersion;
};

export function SalaryRangeVersionActions({version}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {id, effectiveDate} = version;

  const handleDeleteButtonClick = useCallback(() => {
    confirm(
      {
        title: intl.formatMessage(messages.confirmationTitle),
        text: intl.formatMessage(messages.confirmationText, {
          effectiveDate: <HumanDate value={effectiveDate} format='short' utc />,
        }),
        confirmationText: intl.formatMessage(messages.confirmationButton),
        intent: 'negative',
        onConfirm: () =>
          deleteSalaryRangesVersionFx(id)
            .then(() => toaster.success(intl.formatMessage(messages.success)))
            .catch(toaster.interceptThenThrowError),
      },
      intl,
    );
  }, [intl, id, effectiveDate]);

  return (
    <div className={styles.root}>
      <DownloadLinkButton
        size='m'
        kind='text'
        intent='neutral'
        href={salaryRangesApi.buildDownloadByVersionLink(id)}
        iconLeft={<DownloadIcon />}
        target='_blank'
      />
      {acl.hasSecurePermission(SecurePermission.SALARY_RANGE_VERSION_WRITE) && (
        <Button size='m' kind='text' intent='neutral' onClick={handleDeleteButtonClick} iconLeft={<TrashIcon />} />
      )}
    </div>
  );
}
