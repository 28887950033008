import React from 'react';
import {Column} from '../types';
import {CellEmpty} from '../components/CellEmpty';
import {isNotEmpty} from './utils';

export function createColumnPlaceholdered<Item, NestedItem>({
  render,
  renderNested,
  ...rest
}: Column<Item, NestedItem>): Column<Item, NestedItem> {
  return {
    render: (item) => {
      const rendered = render(item);
      return isNotEmpty(rendered) ? rendered : <CellEmpty />;
    },

    renderNested: (nestedItem, item) => {
      const rendered = renderNested?.(nestedItem, item);
      return isNotEmpty(rendered) ? rendered : <CellEmpty />;
    },

    ...rest,
  };
}
