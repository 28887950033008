/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import {
  type ConvertLocatorToTestId,
  type Locator,
  type Mark,
  createLocator,
  RemoveMarkFromProperties,
} from '../create-locator';
import {type TooltipFaqLocator, TooltipFaq, TooltipFaqProps} from '../TooltipFaq';
import {ReactComponent as IconQuestion} from '../icons/circle/question.svg';
import styles from './index.css';

export type HelpPopupLocator = Locator<{
  questionMarkTooltip: TooltipFaqLocator;
  questionMark: void;
}>;
export type HelpPopupTestId = ConvertLocatorToTestId<HelpPopupLocator>;

export type Props = Omit<RemoveMarkFromProperties<TooltipFaqProps>, 'content' | 'children'> & {
  children: React.ReactNode;
  tabIndex?: number;
} & Partial<Mark<HelpPopupLocator>>;

function HelpPopupComponent({children, ...rest}: Props) {
  const locator = createLocator(rest);

  return (
    <TooltipFaq
      content={children}
      placement='top'
      trigger='mouseenter focus'
      {...rest}
      {...locator.questionMarkTooltip()}
    >
      <IconQuestion className={styles.icon} tabIndex={rest.tabIndex} {...locator.questionMark()} />
    </TooltipFaq>
  );
}

export const HelpPopup = Object.assign(HelpPopupComponent, {
  Paragraph: TooltipFaq.Paragraph,
});
