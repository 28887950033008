/* eslint-disable @typescript-eslint/naming-convention */
import {noop} from '@joomcode/deprecated-utils/function';
import React, {FC, useLayoutEffect, useState} from 'react';
import ReactDOM from 'react-dom';

type RefCallback<T> = (instance: T | null) => void;

type Teleport = {
  Source: FC<Record<'children', React.ReactNode>>;
  handleTargetRef: RefCallback<Element>;
};
export function createTeleport(): Teleport {
  let targetElement: Element | null = null;
  let renderSource: RefCallback<Element> = noop;

  const handleTargetRef: RefCallback<Element> = (element) => {
    targetElement = element;
    renderSource(element);
  };

  // eslint-disable-next-line react/function-component-definition
  const Source: FC<Record<'children', React.ReactNode>> = ({children}) => {
    const [target, setTarget] = useState<Element | null>(targetElement);

    useLayoutEffect(() => {
      if (targetElement !== target) {
        setTarget(targetElement);
      }
      renderSource = setTarget;
      return () => {
        renderSource = noop;
      };
    }, []);

    return target ? ReactDOM.createPortal(children, target) : null;
  };

  return {
    Source,
    handleTargetRef,
  };
}
