/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/naming-convention */
import cn from 'classnames';
import React, {FC} from 'react';
import styles from './index.css';
import {StackProps} from './types';

const StackComponent: FC<StackProps> = ({children, fullSize = true, gap = 'm', vertical = false}) => {
  return (
    <div
      className={cn(styles.stack, {
        [styles.fullSize]: fullSize,
        [styles.gapM]: gap === 'm',
        [styles.gapL]: gap === 'l',
        [styles.stackX]: !vertical,
        [styles.stackY]: vertical,
      })}
    >
      {children}
    </div>
  );
};
StackComponent.displayName = 'Stack';

const Spacer: FC = () => <div className={styles.spacer} />;
Spacer.displayName = 'Stack.Spacer';

const Divider: FC = () => <div className={styles.divider} />;
Spacer.displayName = 'Stack.Divider';

export const Stack = Object.assign(StackComponent, {
  Divider,
  Spacer,
});
