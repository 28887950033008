import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {OfficePolicyRecord, officePolicyRecordSchema} from 'domain/officePolicyRecord/model';
import {OfficePolicyRecordDiff} from 'domain/officePolicyRecord/model/diff';
import {UserId} from 'domain/user/model';

export type CreateOfficePolicyRecordConfig = {
  userId: UserId;
  diff: OfficePolicyRecordDiff;
};

export const create = async ({diff, ...params}: CreateOfficePolicyRecordConfig): Promise<OfficePolicyRecord[]> => {
  const {data} = await intranetApi.post('/v1/users/officePolicyRecords/create', diff, {params});

  return array(officePolicyRecordSchema).runWithException(data);
};
