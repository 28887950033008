import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {BulkUploadRowStatus} from 'domain/stockOption/bulkUpload/widgets/Status';
import {stockOptionBuyoutDocumentsStatuses} from 'domain/stockOption/buyout/model/documentsStatus/messages';
import {StockOptionBuyoutRecord} from 'domain/stockOption/buyout/model/record';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import {UserLink} from 'domain/user/widgets/Link';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';
import styles from './styles.css';

export type Props = {
  rows: StockOptionBuyoutRecord[];
};

const getRowKey = ({meta}: StockOptionBuyoutRecord) => meta.row.toString();

export function BulkUploadBuyoutsPanel({rows}: Props) {
  const intl = useIntl();
  const columns: Column<StockOptionBuyoutRecord>[] = useMemo(
    () => [
      {
        defaultWidth: 5,
        id: ColumnId.PARSING_STATUS,
        name: intl.formatMessage(columnNames[ColumnId.PARSING_STATUS]),
        resizable: false,
        render: ({meta}) => <BulkUploadRowStatus meta={meta} />,
      },
      {
        defaultWidth: 12,
        id: ColumnId.USER,
        name: intl.formatMessage(columnNames[ColumnId.USER]),
        resizable: false,
        render: ({buyout}) =>
          buyout?.user ? <UserLink user={buyout.user} colored withAvatar={false} /> : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 15,
        id: ColumnId.EMAIL,
        name: intl.formatMessage(columnNames[ColumnId.EMAIL]),
        resizable: false,
        render: ({buyout}) => (buyout ? buyout.user.email : <DataTable.CellEmpty />),
      },
      {
        align: 'right',
        defaultWidth: 6,
        id: ColumnId.AMOUNT,
        name: intl.formatMessage(columnNames[ColumnId.AMOUNT]),
        resizable: false,
        render: ({buyout}) => (buyout ? <FormattedOptionsAmount amount={buyout.amount} /> : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: 8,
        id: ColumnId.DATE,
        name: intl.formatMessage(columnNames[ColumnId.DATE]),
        resizable: false,
        render: ({buyout}) => (buyout ? <HumanDate value={buyout.date} format='short' utc /> : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: 10,
        id: ColumnId.ISSUER,
        name: intl.formatMessage(columnNames[ColumnId.ISSUER]),
        resizable: false,
        render: ({buyout}) => buyout?.issuer ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 12,
        id: ColumnId.DOCUMENTS_STATUS,
        name: intl.formatMessage(columnNames[ColumnId.DOCUMENTS_STATUS]),
        resizable: false,
        render: ({buyout}) =>
          buyout ? (
            intl.formatMessage(stockOptionBuyoutDocumentsStatuses[buyout.documentsStatus])
          ) : (
            <DataTable.CellEmpty />
          ),
      },
    ],
    [intl],
  );

  return (
    <Panel title={intl.formatMessage(messages.title)} withSeparator>
      <div className={styles.content}>
        <div className={styles.table}>
          <DataTable<StockOptionBuyoutRecord>
            columns={columns}
            dataState={DataState.LOADED}
            data={rows}
            getRowKey={getRowKey}
            storageName='stockOption.bulkUpload.buyouts.list'
          />
        </div>
      </div>
    </Panel>
  );
}
