import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Subteams',
    description: '[title] Team page: title of Subteams panel',
  },
  noSubteams: {
    defaultMessage: 'No subteams',
    description: 'Team page: empty state of Subteams panel',
  },
  btnCreateSubteam: {
    defaultMessage: 'Create subteam',
    description: '[button] Team page: Create Subteam button',
  },
  createSubteamDialogHeader: {
    defaultMessage: 'Create subteam in {teamName}',
    description: '[title] Team creation dialog: header',
  },
});
