import cn from 'classnames';
import {StyledExternalLink, StyledLink} from 'components/ui/StyledLink';
import React, {MouseEventHandler} from 'react';
import styles from './styles.css';

type Props = React.PropsWithChildren<{
  link?: string;
  coloredLink?: boolean;
  externalLink?: boolean;
  icon: React.ReactNode;
  iconLabel: string;
  iconClassName?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}>;

export function LinkWithIcon({
  children,
  link,
  coloredLink = false,
  externalLink = false,
  icon,
  iconLabel,
  iconClassName,
  onClick,
}: Props) {
  return (
    <div className={styles.root}>
      <span className={cn(styles.icon, iconClassName)} role='img' aria-label={iconLabel}>
        {icon}
      </span>
      {externalLink ? (
        <StyledExternalLink href={link} disabled={!link} colored={coloredLink} onClick={onClick}>
          {children}
        </StyledExternalLink>
      ) : (
        <StyledLink to={link ?? ''} disabled={!link} colored={coloredLink} onClick={onClick}>
          {children}
        </StyledLink>
      )}
    </div>
  );
}
