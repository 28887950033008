import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useUsersJobInfoRecords} from 'domain/jobInfoRecord/hooks/useUsersJobInfoRecords';
import {JobInfoRecord} from 'domain/jobInfoRecord/model';
import {JobInfoRecordDialog} from 'domain/jobInfoRecord/widgets/Dialog';
import {UserId} from 'domain/user/model';
import {JobInfo} from 'domain/user/widgets/JobInfo';
import {JobInfoTabAddon} from 'domain/user/widgets/JobInfoTabAddon';
import {JobPanelTab} from 'domain/user/widgets/JobPanel';
import React, {useMemo} from 'react';

export function useJobInfoTab(userId: UserId): JobPanelTab<JobInfoRecord> {
  const jobInfo = useUsersJobInfoRecords(userId);
  const jobInfoRecordDialog = usePopupState<JobInfoRecord>();
  const activeRecord = useMemo(() => jobInfo.data.find(({active}) => active), [jobInfo.data]);

  const result = useMemo(
    () => ({
      addon: <JobInfoTabAddon onDialogOpen={jobInfoRecordDialog.open} />,
      content: (
        <JobInfo
          userId={userId}
          records={jobInfo.data}
          dataState={jobInfo.dataState}
          onDialogOpenWithPayload={jobInfoRecordDialog.openWithPayload}
        />
      ),
      dialog: (
        <JobInfoRecordDialog
          activeRecord={activeRecord}
          isOpen={jobInfoRecordDialog.isOpen}
          onClose={jobInfoRecordDialog.close}
          recordToUpdate={jobInfoRecordDialog.payload}
          userId={userId}
        />
      ),
      records: jobInfo.data,
      dataState: jobInfo.dataState,
    }),
    [userId, jobInfo.data, jobInfo.dataState, activeRecord, jobInfoRecordDialog],
  );

  return result;
}
