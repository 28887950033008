import {isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {ReactComponent as PlusIcon} from '@joomcode/joom-ui/icons/core/plus.svg';
import {Page} from '@joomcode/joom-ui/Page';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Permission} from 'domain/permission/model';
import {loadAllUserRolesFx} from 'domain/userRole/stores/main';
import {$userRoles} from 'domain/userRole/stores/main/state';
import {UserRolesTable} from 'domain/userRole/widgets/Table';
import {useStore} from 'effector-react';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {userRolesUrls} from 'routes/userRoles/urls';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

export function UserRolesPage() {
  const intl = useIntl();
  const acl = useAcl();
  const {rolesById, rolesDataState} = useStore($userRoles);

  useEffect(() => {
    if (!isLoadingOrLoaded(rolesDataState)) {
      loadAllUserRolesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  return (
    <PageStateHandler data={Object.values(rolesById)} state={rolesDataState}>
      {(roles) => (
        <Page
          title={intl.formatMessage(messages.title)}
          actions={
            acl.hasPermission(Permission.USER_ROLE_WRITE) && (
              <RouterLinkButton
                to={userRolesUrls.create}
                size='m'
                kind='primary'
                intent='primary'
                type='submit'
                iconLeft={<PlusIcon />}
              >
                {intl.formatMessage(messages.createButton)}
              </RouterLinkButton>
            )
          }
        >
          <UserRolesTable roles={roles} />
        </Page>
      )}
    </PageStateHandler>
  );
}
