import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {omit} from '@joomcode/deprecated-utils/object/omit';
import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {SalaryRangesVersion} from 'domain/salaryRange/model/version';
import {applySalaryRangesUploadFx} from 'domain/salaryRange/stores/upload';
import {deleteSalaryRangesVersionFx, getSalaryRangesVersionsFx} from '.';

export const $salaryRangesVersions = createEntityListStore(
  createEntityStore({
    getEntityId: (version: SalaryRangesVersion) => version.id,
    query: {
      fx: getSalaryRangesVersionsFx,
      getIdsFromParams: () => [],
    },
  })
    .on(applySalaryRangesUploadFx.done, (state, {result}) => {
      const list = result.map(({id}) => id);
      return {
        ...state,
        byId: arrayToObject(result, ({id}) => id),
        byIdState: arrayToObject(list, identity, () => DataState.LOADED),
        list,
        listState: DataState.LOADED,
      };
    })
    .on(deleteSalaryRangesVersionFx.done, (state, {params: id}) => {
      return {
        ...state,
        byId: omit(state.byId, [id]),
        byIdState: omit(state.byIdState, [id]),
        list: state.list.filter((versionId) => versionId !== id),
      };
    }),
);
