import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {statusMessages} from 'domain/compensations/request/model/status/messages';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  status: CompensationRequestStatus;
};

const intentByStatus: Record<CompensationRequestStatus, BadgeIntent> = {
  [CompensationRequestStatus.NEEDS_REPORT]: 'warning',
  [CompensationRequestStatus.REPORTED]: 'positive',
  [CompensationRequestStatus.REJECTED]: 'negative',
  [CompensationRequestStatus.APPROVED]: 'positive',
  [CompensationRequestStatus.CREATED]: 'primary',
  [CompensationRequestStatus.CANCELED]: 'neutral',
  [CompensationRequestStatus.CLOSED]: 'invertedNeutral',
  [CompensationRequestStatus.MANAGER_APPROVED]: 'primary',
  [CompensationRequestStatus.OFFICE_CARE_APPROVED]: 'positive',
};

export function CompensationRequestStatusBadge({status}: Props) {
  const intl = useIntl();
  return <Badge intent={intentByStatus[status]}>{intl.formatMessage(statusMessages[status])}</Badge>;
}
