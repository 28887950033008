import {boolean} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {OfferId} from 'domain/offer/model';

export type GetStockOptionsExistenceParams = {
  id: OfferId;
};

export const getStockOptionsExistence = async (params: GetStockOptionsExistenceParams): Promise<boolean> => {
  const {data} = await intranetApi.get('/v1/secure/offers/doStockOptionsExist', {params});

  return boolean().runWithException(data);
};
