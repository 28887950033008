import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {ReactComponent as CheckListIcon} from '@joomcode/joom-ui/icons/core/checkList.svg';
import {SecurePermission} from 'domain/permission/model/secure';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {StockOptionAgreementActionButton} from 'domain/stockOption/agreement/widgets/ActionButton';
import {UpdateDocumentsStatusDialog} from 'domain/stockOption/agreement/widgets/UpdateDocumentsStatusDialog';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

type Props = {
  agreement: StockOptionAgreement;
};

export function UpdateDocumentsStatusButton({agreement}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const updateDialog = usePopupState();

  if (!acl.hasSecurePermission(SecurePermission.STOCK_OPTION_WRITE_ANY)) {
    return null;
  }

  return (
    <>
      <StockOptionAgreementActionButton
        icon={<CheckListIcon />}
        hint={intl.formatMessage(messages.buttonHint)}
        onClick={updateDialog.open}
      />
      {updateDialog.isOpen && <UpdateDocumentsStatusDialog agreement={agreement} onClose={updateDialog.close} />}
    </>
  );
}
