import {identity} from '@joomcode/deprecated-utils/function/identity';
import {Input} from '@joomcode/joom-ui/Input';
import {EditableValue} from 'components/ui/EditableValue';
import {updateSeatFx} from 'domain/officeMap/office/stores/main';
import {OfficeSeatId} from 'domain/officeMap/seat/model';
import React, {useCallback} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  seatId: OfficeSeatId;
  seatNumber?: string;
};

type Diff = {
  number: string;
};

export function SeatNumberEdit({seatId, seatNumber}: Props) {
  const intl = useIntl();

  const onSubmit = useCallback(
    ({number}: Diff) => updateSeatFx({id: seatId, diff: {number}}).catch(toaster.interceptThenThrowError),
    [seatId],
  );

  return (
    <Form<Diff> onSubmit={onSubmit}>
      {({handleSubmit, submitting}) => (
        <Field name='number' initialValue={seatNumber} parse={identity}>
          {({input}) => (
            <EditableValue<string | undefined>
              onSubmit={handleSubmit}
              disableSubmit={submitting}
              processing={submitting}
              value={seatNumber}
              renderValue={(number?: string) => number ?? intl.formatMessage(messages.noNumber)}
            >
              <Input autoComplete='off' disabled={submitting} size={6} {...input} />
            </EditableValue>
          )}
        </Field>
      )}
    </Form>
  );
}
