import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  addReceiptButton: {
    defaultMessage: 'Add first expense',
    description: '[button] Compensation receipts: Add expense',
  },
  addMoreReceiptButton: {
    defaultMessage: 'Add more expenses',
    description: '[button] Compensation receipts: Add one more expense',
  },
});
