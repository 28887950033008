import {defineMessages} from 'react-intl';
import {TerminationInitiatedBy} from '.';

export const terminationInitiatedByMessages = defineMessages<TerminationInitiatedBy>({
  [TerminationInitiatedBy.EMPLOYEE]: {
    defaultMessage: 'Employee',
    description: 'Value of "Termination initiated by" field',
  },
  [TerminationInitiatedBy.COMPANY]: {
    defaultMessage: 'Company',
    description: 'Value of "Termination initiated by" field',
  },
  [TerminationInitiatedBy.BOTH]: {
    defaultMessage: 'Both',
    description: 'Value of "Termination initiated by" field',
  },
});
