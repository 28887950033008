import {getMimeType} from '@joomcode/deprecated-utils/file/mime';
import {mimeTypeMatch} from '@joomcode/deprecated-utils/file/mimeTypeMatch';
import {parseFilename} from '@joomcode/deprecated-utils/file/name';
import {isNotNullish} from '@joomcode/deprecated-utils/function/isNotNullish';
import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {ValidationFunction} from '../../types';
import {FileValidatorErrorCode, FileValidatorOptions} from './types';

export const validateFile: ValidationFunction<unknown, FileValidatorOptions, FileValidatorErrorCode> = async (
  value,
  options = {},
) => {
  const {minSize, maxSize, mimeTypes, extensions} = options;

  if (isNullish(value)) return undefined;

  if (!(value instanceof File)) {
    return {code: FileValidatorErrorCode.FILE_INVALID, options};
  }

  if (mimeTypes && mimeTypes.every((mimeType) => !mimeTypeMatch(getMimeType(value), mimeType))) {
    return {code: FileValidatorErrorCode.FILE_INVALID_MIME_TYPE, options};
  }

  if (isNotNullish(minSize) && isNotNullish(maxSize) && (value.size < minSize || value.size > maxSize)) {
    return {code: FileValidatorErrorCode.FILE_SIZE_OUT_OF_RANGE, options};
  }

  if (isNotNullish(minSize) && value.size < minSize) {
    return {code: FileValidatorErrorCode.FILE_SIZE_TOO_SMALL, options};
  }

  if (isNotNullish(maxSize) && value.size > maxSize) {
    return {code: FileValidatorErrorCode.FILE_SIZE_TOO_BIG, options};
  }

  if (extensions) {
    const parsedName = parseFilename(value.name);
    if (!parsedName.extension || !extensions.includes(parsedName.extension)) {
      return {code: FileValidatorErrorCode.FILE_INVALID_EXTENSION, options};
    }
  }

  return undefined;
};
