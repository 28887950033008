import {defineMessages} from 'react-intl';
import type {SocialContactType} from './types';

export const iconLabels = defineMessages<SocialContactType>({
  phone: {
    defaultMessage: 'Phone number',
    description: 'User contact item label',
  },
  email: {
    defaultMessage: 'Email',
    description: 'User contact item label',
  },
  slack: {
    defaultMessage: 'Slack',
    description: 'User contact item label',
  },
  location: {
    defaultMessage: 'Location',
    description: 'User contact item label',
  },
  notion: {
    defaultMessage: 'Notion',
    description: 'User contact item label',
  },
  github: {
    defaultMessage: 'GitHub',
    description: 'User contact item label',
  },
});
