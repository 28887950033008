import {FinancialBulkUploadResult} from 'domain/financialRecord/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  uploadResult: FinancialBulkUploadResult | undefined;
};

export function RowErrors({uploadResult}: Props) {
  const intl = useIntl();

  const salaryErrors =
    uploadResult?.salaryBulkUploadRecords?.reduce((acc, {meta}) => [...acc, ...meta.errors], []) ?? [];
  const regularBonusErrors =
    uploadResult?.regularBonusBulkUploadRecords?.reduce((acc, {meta}) => [...acc, ...meta.errors], []) ?? [];
  const marketDataErrors =
    uploadResult?.marketDataBulkUploadRecords?.reduce((acc, {meta}) => [...acc, ...meta.errors], []) ?? [];

  return (
    <div>
      {salaryErrors.length > 0 && (
        <>
          <div className={styles.title}>{intl.formatMessage(messages.salaryErrors)}</div>
          {salaryErrors.map((error) => (
            <div className={styles.error} key={error}>
              {error}
            </div>
          ))}
        </>
      )}
      {regularBonusErrors.length > 0 && (
        <>
          <div className={styles.title}>{intl.formatMessage(messages.regularBonusErrors)}</div>
          {regularBonusErrors.map((error) => (
            <div className={styles.error} key={error}>
              {error}
            </div>
          ))}
        </>
      )}
      {marketDataErrors.length > 0 && (
        <>
          <div className={styles.title}>{intl.formatMessage(messages.marketDataErrors)}</div>
          {marketDataErrors.map((error) => (
            <div className={styles.error} key={error}>
              {error}
            </div>
          ))}
        </>
      )}
    </div>
  );
}
