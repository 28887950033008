import {Autocomplete, AutocompleteProps} from '@joomcode/joom-ui/Select/Autocomplete';
import {Role} from 'domain/role/model';
import sortBy from 'lodash/sortBy';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

const getItemKey = ({id}: Role) => id;
const itemToString = ({name}: Role) => name;
const filter = ({name}: Role, inputValue: string) => name.toLowerCase().includes(inputValue.toLowerCase());

type Props = Omit<
  AutocompleteProps<Role>,
  'filter' | 'getItemKey' | 'noItemsText' | 'onInputValueChange' | 'renderItem' | 'itemToString'
>;

export function RoleAutocomplete({items, ...props}: Props) {
  const intl = useIntl();
  const sortedItems = useMemo(() => sortBy(items, [({isHead}) => !isHead, ({name}) => name]), [items]);

  return (
    <Autocomplete<Role>
      getItemKey={getItemKey}
      items={sortedItems}
      itemToString={itemToString}
      filter={filter}
      noItemsText={intl.formatMessage(messages.noItems)}
      renderItem={itemToString}
      {...props}
    />
  );
}
