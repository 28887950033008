import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import {TimeOffType} from 'domain/timeOff/type/model';
import {TimeOff} from 'domain/timeOff/userTimeOff/model';
import {RemoteWorkIcon} from 'domain/timeOff/userTimeOff/widgets/RemoteWorkIcon';
import React from 'react';
import {useIntl} from 'react-intl';
import {formatDateRange} from 'utils/date/formatDateRange';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  timeOff: TimeOff;
};

const timeOffTypeToIcon = (timeOffType: TimeOffType | undefined): string => {
  switch (timeOffType) {
    case TimeOffType.BUSINESS_TRIP:
      return '💼';
    case TimeOffType.SICK_LEAVE:
      return '🤒';
    case TimeOffType.MATERNITY_LEAVE:
    case TimeOffType.PARENTAL_LEAVE:
      return '🐣';
    default:
      return '🌴';
  }
};

export function TimeOffIcon({timeOff}: Props) {
  const intl = useIntl();
  const formattedDates = formatDateRange(timeOff.start, timeOff.end, intl, true);
  const tooltip = intl.formatMessage(messages.timeOffTooltip, {dateInterval: formattedDates});

  if (timeOff.type === TimeOffType.REMOTE_WORK || timeOff.type === TimeOffType.SEMI_SICK_LEAVE) {
    return <RemoteWorkIcon dateInterval={formattedDates} />;
  }

  return (
    <TooltipAlt content={tooltip} placement='right'>
      <span className={styles.root}>
        <span role='img' aria-label={tooltip}>
          {timeOffTypeToIcon(timeOff.type)}
        </span>
      </span>
    </TooltipAlt>
  );
}
