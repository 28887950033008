import React from 'react';
import {identity, isNullish} from '@joomcode/deprecated-utils/function';
import type {FilterItem, Value} from '../../types';
import type {AutocompleteFilterOptions} from './types';
import {AutocompleteControl} from './AutocompleteControl';
import {SelectLabel} from '../Select/SelectLabel';

export * from './types';

export function createGenericAutocompleteFilter<InnerValue extends string, ExternalValue>(
  name: string,
  options: AutocompleteFilterOptions<InnerValue> & {
    parseValue(innerValue: Value<InnerValue>): Value<ExternalValue>;
    formatValue(value: Value<ExternalValue>): Value<InnerValue>;
  },
): FilterItem<ExternalValue, AutocompleteFilterOptions<InnerValue>> {
  return {
    name,
    options,
    isEmpty: (value) => {
      const formattedValue = options.formatValue(value);

      return isNullish(formattedValue) || formattedValue.length === 0;
    },
    renderControl: (props) => (
      <AutocompleteControl
        {...props}
        onSubmit={(value) => props.onSubmit(options.parseValue(value))}
        value={options.formatValue(props.value)}
      />
    ),
    renderLabel: ({options: optionsWithMark, ...props}) => {
      return <SelectLabel {...props} options={optionsWithMark} value={options.formatValue(props.value)} />;
    },
  };
}

export function createAutocompleteFilter<InnerValue extends string>(
  name: string,
  options: AutocompleteFilterOptions<InnerValue>,
): FilterItem<InnerValue, AutocompleteFilterOptions<InnerValue>> {
  return createGenericAutocompleteFilter(name, {
    ...options,
    parseValue: identity,
    formatValue: identity,
  });
}
