import {defineMessages} from 'react-intl';
import {RestrictedValueValidatorErrorCode} from 'utils/form/validateForRestrictedValues';

export const messages = defineMessages({
  titleLabel: {
    defaultMessage: 'Role title',
    description: 'Form field label',
  },
});

export const errorMessages = defineMessages<RestrictedValueValidatorErrorCode>({
  [RestrictedValueValidatorErrorCode.RESTRICTED_VALUE_USED]: {
    defaultMessage: 'Financial Role with this name already exists',
    description: 'Validation error message',
  },
});
