import {config} from 'config';
import {$locale} from 'domain/locale/stores/main/state';
import {useStore} from 'effector-react';
import {messages} from 'i18n';
import React from 'react';
import {IntlProvider as ReactIntlProvider, ReactIntlErrorCode, ResolvedIntlConfig} from 'react-intl';

const handleReactIntlError: ResolvedIntlConfig['onError'] = (error) => {
  if (window.intranetEnv.APP_ENV === 'local' && error.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
    return;
  }

  // eslint-disable-next-line no-console
  console.error(error);
};

type Props = {
  children: React.ReactNode;
};

export function IntlProvider({children}: Props) {
  const {locale} = useStore($locale);
  return (
    <ReactIntlProvider
      defaultLocale={config.defaultLocale}
      locale={locale}
      messages={messages[locale]}
      onError={handleReactIntlError}
    >
      {children}
    </ReactIntlProvider>
  );
}
