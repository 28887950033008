import {intranetApi} from 'apiClient';
import {Service, ServiceId, serviceSchema} from 'domain/service/model';
import {ServiceDiff} from 'domain/service/model/diff';

export const update = async ({id, diff}: {id: ServiceId; diff: ServiceDiff}): Promise<Service> => {
  const {data} = await intranetApi.post('/v1/jdm/services/update', diff, {
    params: {id},
  });

  return serviceSchema.runWithException(data);
};
