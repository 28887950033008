import {intranetApi} from 'apiClient';
import {StockOptionAgreementId} from 'domain/stockOption/agreement/model';
import {UserId} from 'domain/user/model';

type StockOptionAgreementDownloadConfig = {
  id: StockOptionAgreementId;
  userId: UserId;
};

export const buildDownloadLink = (params: StockOptionAgreementDownloadConfig) =>
  intranetApi.getUri({
    params,
    url: `${intranetApi.defaults.baseURL}/v1/secure/stockOptions/agreements/download`,
  });
