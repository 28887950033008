import {DataState} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {SimpleAutocomplete} from 'components/ui/SimpleAutcomplete';
import {CountryCode} from 'domain/countryCode/model';
import {useLegalEntities} from 'domain/legalEntity/hooks/useLegalEntities';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {getCountryComparator} from 'utils/country/compare';
import {getCountryName} from 'utils/country/name';

export type Props = {
  clearable?: boolean;
  disabled?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange: (countryCode: CountryCode | null) => void;
  onFocus?: () => void;
  options?: CountryCode[];
  value: CountryCode | null;
};

export function CountrySelect({value, ...restProps}: Props) {
  const intl = useIntl();
  const {legalEntities, dataState: legalEntitiesDataState} = useLegalEntities();
  const countryToString = useCallback((countryCode: CountryCode) => getCountryName(countryCode, intl), [intl]);

  const countryCodes: CountryCode[] = useMemo(() => {
    const allCodesAlphabetically = getEnumValues(CountryCode).sort(getCountryComparator(intl));
    if (legalEntities.length === 0) {
      return allCodesAlphabetically;
    }
    // Collect each country's occurrences in legal entities
    const frequencyByCountryCode = legalEntities.reduce(
      (acc: Record<string, number>, {countryCode}) =>
        countryCode ? {...acc, [countryCode]: (acc[countryCode] ?? 0) + 1} : acc,
      {},
    );
    // Sort all country codes by frequency of occurrence in legal entities
    return allCodesAlphabetically.sort(
      (countryA, countryB) => (frequencyByCountryCode[countryB] ?? 0) - (frequencyByCountryCode[countryA] ?? 0),
    );
  }, [intl, legalEntities]);

  return (
    <SimpleAutocomplete<CountryCode>
      items={countryCodes}
      getItemKey={identity}
      itemToString={countryToString}
      renderItem={countryToString}
      value={value || null}
      disabled={legalEntitiesDataState === DataState.LOADING}
      {...restProps}
    />
  );
}
