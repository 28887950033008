import React from 'react';
import {FormatNumberOptions, FormattedNumber} from 'react-intl';

type Props = FormatNumberOptions & {
  value: number;
};

export const formatPercentOptions: FormatNumberOptions = {
  style: 'percent',
  maximumFractionDigits: 2,
};

export function FormattedPercent(props: Props) {
  return <FormattedNumber {...formatPercentOptions} {...props} />;
}
