import {oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {Page} from 'components/widgets/Page';
import {Permission} from 'domain/permission/model';
import {timeOffRequestIdSchema} from 'domain/timeOff/request/model/id';
import {TimeOffRequestsTableMode} from 'domain/timeOff/request/model/mode';
import {TimeOffRequestsDownloadButton} from 'domain/timeOff/request/widgets/DownloadButton';
import {TimeOffRequestsList} from 'domain/timeOff/request/widgets/TimeOffRequestsList';
import {UserFull} from 'domain/user/model';
import {NotFoundPage} from 'pages/NotFound';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps} from 'react-router-dom';
import {pageTitles} from 'routes/timeOff/titles';
import {timeOffUrls} from 'routes/timeOff/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import {ModeTabs} from './ModeTabs';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function TimeOffRequestsPage({selfUser}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const [mode] = useQueryParam('mode', oneOfEnum(TimeOffRequestsTableMode), TimeOffRequestsTableMode.MY);
  const [id, setId] = useQueryParam('id', timeOffRequestIdSchema, '');
  const hasSubordinates = Boolean(selfUser.subordinates && selfUser.subordinates.length > 0);
  const canViewAllRequests = acl.hasPermission(Permission.TIME_OFF_REQUEST_READ);

  const dropId = useCallback(() => {
    setId('');
  }, [setId]);

  if (mode === TimeOffRequestsTableMode.ALL && !canViewAllRequests) {
    return <NotFoundPage />;
  }

  return (
    <Page
      title={intl.formatMessage(pageTitles.requests)}
      actions={
        <>
          {acl.hasPermission(Permission.SELF_TIME_OFF_REQUEST_WRITE) && mode === TimeOffRequestsTableMode.MY && (
            <RouterLinkButton to={timeOffUrls.createRequest()} size='m' kind='primary' intent='primary' type='submit'>
              {intl.formatMessage(messages.createButton)}
            </RouterLinkButton>
          )}
          {canViewAllRequests && mode === TimeOffRequestsTableMode.ALL && <TimeOffRequestsDownloadButton />}
        </>
      }
      headerContent={
        (hasSubordinates || canViewAllRequests) && (
          <ModeTabs activeMode={mode} canViewAllRequests={canViewAllRequests} hasSubordinates={hasSubordinates} />
        )
      }
    >
      <TimeOffRequestsList
        mode={mode}
        hasSubordinates={hasSubordinates}
        id={id === '' ? undefined : id}
        dropId={dropId}
      />
    </Page>
  );
}
