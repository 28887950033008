import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {usePageLocker} from '@joomcode/joom-ui/PageLock';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {UpdateOfferDiff} from 'domain/offer/api/update';
import {useOfferToggableSections} from 'domain/offer/hooks/useOfferToggableSections';
import {OfferId} from 'domain/offer/model';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {OfferEmploymentInfo} from 'domain/offer/model/employmentInfo';
import {OfferStockOptions} from 'domain/offer/model/stockOption';
import {OfferUpdateReason} from 'domain/offer/model/updateReason';
import {updateOfferFx} from 'domain/offer/stores/main';
import {OfferForm} from 'domain/offer/widgets/Form';
import {OfferUpdateDialog} from 'domain/offer/widgets/UpdateDialog';
import {convertRegularBonusValue} from 'domain/regularBonusRecord/utils/convertFormValues';
import {DEFAULT_ISSUER} from 'domain/stockOption/issuer/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {offerUrls} from 'routes/offer/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import {OfferFormState} from './state';

type Props = {
  offerId: OfferId;
  employmentInfo: OfferEmploymentInfo;
  compensation?: OfferCompensation;
  stockOptions?: OfferStockOptions;
};

export function OfferUpdateForm({offerId, employmentInfo, compensation, stockOptions}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const toggableSections = useOfferToggableSections({
    hasStockOptions: Boolean(stockOptions?.amount),
    hasFurtherSalary: Boolean(compensation?.furtherSalary),
  });
  const {hasStockOptions, hasFurtherSalary} = toggableSections;
  const reasonDialog = usePopupState();
  const pageLocker = usePageLocker();

  const onSubmit = useCallback(
    ({updateReason, ...state}: OfferFormState) => {
      const regularBonusType = state.compensation.regularBonus?.type;
      const diff: UpdateOfferDiff = {
        ...state,
        stockOptions:
          hasStockOptions && state.stockOptions?.amount
            ? {
                ...state.stockOptions,
                issuer: DEFAULT_ISSUER,
              }
            : undefined,
        compensation: {
          ...state.compensation,
          furtherSalary: hasFurtherSalary ? state.compensation.furtherSalary : undefined,
          regularBonus:
            state.compensation.regularBonus && regularBonusType
              ? {
                  ...state.compensation.regularBonus,
                  value: convertRegularBonusValue(regularBonusType, state.compensation.regularBonus.value),
                }
              : undefined,
        },
      };

      return updateOfferFx({
        params: {
          id: offerId,
          isTypo: updateReason === OfferUpdateReason.TYPO,
        },
        diff,
      })
        .then(({id}) => {
          pageLocker.unlock();
          history.push(offerUrls.offer({id}));
        })
        .catch(toaster.interceptThenThrowError);
    },
    [offerId, hasFurtherSalary, hasStockOptions, history],
  );

  return (
    <OfferForm<OfferFormState>
      employmentInfo={employmentInfo}
      compensation={compensation}
      stockOptions={stockOptions}
      toggableSections={toggableSections}
      pageLocker={pageLocker}
      onSubmit={onSubmit}
    >
      {({formId, values, invalid, submitting}) => (
        <>
          <ButtonGroup spaced size='l'>
            <Button
              kind='primary'
              intent='primary'
              type='button'
              form={formId}
              disabled={invalid || reasonDialog.isOpen}
              loading={submitting}
              onClick={reasonDialog.open}
            >
              {intl.formatMessage(messages.buttonUpdate)}
            </Button>
            <RouterLinkButton size='l' intent='neutral' kind='secondary' to={offerUrls.offer({id: offerId})}>
              {intl.formatMessage(messages.buttonCancel)}
            </RouterLinkButton>
          </ButtonGroup>

          {reasonDialog.isOpen && (
            <OfferUpdateDialog
              formId={formId}
              values={values}
              submitting={submitting}
              invalid={invalid}
              onClose={reasonDialog.close}
            />
          )}
        </>
      )}
    </OfferForm>
  );
}
