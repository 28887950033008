import React, {FC} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.css';

const messages = defineMessages({
  hint: {
    id: 'data-table-empty-cell-hint',
    description: 'The hint for dashes in table cells, which explains a cell does not have data in it',
    defaultMessage: 'No data',
  },
});

export const CellEmpty: FC = () => {
  const intl = useIntl();

  return <span className={styles.dash} title={intl.formatMessage(messages.hint)} />;
};
