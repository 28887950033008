import {DataState, getConsolidatedDataState} from '@joomcode/deprecated-utils/dataState';
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Grid} from '@joomcode/joom-ui/Grid';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Breadcrumb, Page} from 'components/widgets/Page';
import {Permission} from 'domain/permission/model';
import {useTeamAcl} from 'domain/team/hooks/useTeamAcl';
import {useTeams} from 'domain/team/hooks/useTeams';
import {teamIdSchema} from 'domain/team/model/id';
import {getTeamBreadcrumbs} from 'domain/team/utils/getTeamBreadcrumbs';
import {getTeamLinksAvailability} from 'domain/team/utils/getTeamLinksAvailability';
import {ChangeParentDialog} from 'domain/team/widgets/ChangeParentDialog';
import {DeletingTeamCallout} from 'domain/team/widgets/DeletingTeamCallout';
import {TeamDeletionPanel} from 'domain/team/widgets/DeletionPanel';
import {TeamDescriptionPanel} from 'domain/team/widgets/DescriptionPanel';
import {TeamLinksPanel} from 'domain/team/widgets/LinksPanel';
import {TeamMembersPanel} from 'domain/team/widgets/MembersPanel';
import {TeamOutstaffMembersPanel} from 'domain/team/widgets/OutstaffMembersPanel';
import {TeamUpdateDialog} from 'domain/team/widgets/UpdateDialog';
import {VteamBadge} from 'domain/team/widgets/VteamBadge';
import {NotFoundPage} from 'pages/NotFound';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/teams/titles';
import {teamsUrls} from 'routes/teams/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import {SubteamsPanel} from './SubteamsPanel';

export function TeamPage() {
  const acl = useAcl();
  const intl = useIntl();
  const {id} = useTypedParams({id: teamIdSchema});
  const {byId, teams, dataState} = useTeams();
  const updateDialog = usePopupState();
  const moveToAnotherTeamDialog = usePopupState();
  const {
    canEditMemberships,
    canEditOutstaffMemberships,
    isTeamAdmin,
    canCreateTeam,
    canUpdateTeam,
    canDeleteTeam,
    dataState: teamAclDataState,
  } = useTeamAcl(id);

  const canSeeOutstaffUsers = acl.hasPermission(Permission.OUTSTAFF_USER_READ);

  const breadcrumbs = useMemo(() => {
    if (!id) {
      return [];
    }
    const teamBreadcrumbs: Breadcrumb[] = getTeamBreadcrumbs(id, byId).map((team) => ({
      title: team.name,
      href: teamsUrls.team({id: team.id}),
    }));
    return [{title: intl.formatMessage(pageTitles.teams), href: teamsUrls.teams()}, ...teamBreadcrumbs];
  }, [id, byId, intl]);

  if (!id || (dataState === DataState.LOADED && !byId[id])) {
    return <NotFoundPage />;
  }

  return (
    <PageStateHandler data={byId} state={getConsolidatedDataState(dataState, teamAclDataState)}>
      {(teamById) => {
        // We explicitly check team existence several lines above
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const team = teamById[id]!;
        const hasSubteams = teams.some(({parentTeamId}) => parentTeamId === id);
        const showTeamLinks = getTeamLinksAvailability(team, acl);

        return (
          <Page
            breadcrumbs={breadcrumbs}
            title={team.name}
            titleAddon={team.vTeam ? <VteamBadge verticalAlign='text-bottom' /> : undefined}
            actions={
              <ButtonGroup spaced size='m'>
                {isTeamAdmin && (
                  <Button kind='primary' intent='primary' type='submit' onClick={moveToAnotherTeamDialog.open}>
                    {intl.formatMessage(messages.btnMoveToAnotherTeam)}
                  </Button>
                )}
                {canUpdateTeam && (
                  <Button kind='primary' intent='primary' type='submit' onClick={updateDialog.open}>
                    {intl.formatMessage(messages.btnEdit)}
                  </Button>
                )}
              </ButtonGroup>
            }
            headerContent={team.deleting && <DeletingTeamCallout />}
          >
            <Grid>
              <Grid.Item xl={showTeamLinks ? 8 : 12} md={12}>
                <TeamDescriptionPanel team={team} />
              </Grid.Item>
              {showTeamLinks && (
                <Grid.Item xl={4} md={12}>
                  <TeamLinksPanel team={team} />
                </Grid.Item>
              )}
              <Grid.Item xl={12}>
                <TeamMembersPanel canEditMemberships={canEditMemberships} team={team} />
              </Grid.Item>
            </Grid>
            {canSeeOutstaffUsers && (
              <TeamOutstaffMembersPanel canEditMemberships={canEditOutstaffMemberships} team={team} />
            )}
            {(canCreateTeam || hasSubteams) && <SubteamsPanel teamById={teamById} team={team} />}
            {canDeleteTeam && <TeamDeletionPanel hasSubteams={hasSubteams} team={team} />}
            {updateDialog.isOpen && <TeamUpdateDialog onClose={updateDialog.close} team={team} />}
            {moveToAnotherTeamDialog.isOpen && (
              <ChangeParentDialog team={team} onClose={moveToAnotherTeamDialog.close} />
            )}
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
