import {intranetApi} from 'apiClient';
import {CompensationReportDiff, CompensationReportId} from 'domain/compensations/report/model';
import {CompensationRequest, compensationRequestSchema} from 'domain/compensations/request/model';

type Params = {
  diff: CompensationReportDiff;
  id: CompensationReportId;
};

export const update = async ({diff, id}: Params): Promise<CompensationRequest> => {
  const {data} = await intranetApi.post('/v1/compensations/reports/update', diff, {
    params: {reportId: id},
  });

  return compensationRequestSchema.runWithException(data);
};
