import {identity} from '@joomcode/deprecated-utils/function';
import {FieldCountry, FieldInputNumber, FieldTextarea, validateFilled} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {CompanyUnitFormControl} from 'components/widgets/CompanyUnitFormControl';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {CompanyUnits} from 'domain/companyUnits/model';
import {FieldCurrency} from 'domain/currency/widgets/Field';
import {FieldFrequency} from 'domain/frequency/widgets/Field';
import {ExtendedSalaryRecord} from 'domain/salaryRecord/model';
import {AnySalaryRecordDiff} from 'domain/salaryRecord/model/diff';
import React from 'react';
import {FormRenderProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  formId: string;
  submitting: boolean;
  companyUnits: CompanyUnits;
  recordToUpdate?: ExtendedSalaryRecord;
  needToReplace?: boolean;
  extendedMode: boolean;
  onSubmit: FormRenderProps<AnySalaryRecordDiff>['handleSubmit'];
};

export function SalaryRecordForm({
  formId,
  submitting,
  companyUnits,
  recordToUpdate,
  needToReplace,
  extendedMode,
  onSubmit,
}: Props) {
  const intl = useIntl();

  return (
    <form onSubmit={onSubmit} id={formId} noValidate autoComplete='off'>
      <Grid>
        <Grid.Item xl={6} xs={12}>
          <FieldDate
            name='startDate'
            label={intl.formatMessage(labels.startDate)}
            initialValue={needToReplace ? undefined : recordToUpdate?.startDate}
            required
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldDate
            name='endDate'
            label={intl.formatMessage(labels.endDate)}
            initialValue={needToReplace ? undefined : recordToUpdate?.endDate}
            emptyValue={recordToUpdate && !needToReplace ? null : undefined}
          />
        </Grid.Item>
        <Grid.Item xl={4} sm={6}>
          <FieldInputNumber
            name='amount'
            label={intl.formatMessage(labels.amount)}
            required
            min={0}
            step={1000}
            initialValue={recordToUpdate?.amount}
          />
        </Grid.Item>
        <Grid.Item xl={4} sm={6}>
          <FieldCurrency
            name='currency'
            label={intl.formatMessage(labels.currency)}
            required
            validate={validateFilled}
            initialValue={recordToUpdate?.currency}
          />
        </Grid.Item>
        <Grid.Item xl={4} sm={6}>
          <FieldFrequency
            name='frequency'
            label={intl.formatMessage(labels.frequency)}
            required
            validate={validateFilled}
            initialValue={recordToUpdate?.frequency}
          />
        </Grid.Item>
        <Grid.Item xl={3} xs={4}>
          <FieldInputNumber
            name='employmentRate'
            label={intl.formatMessage(labels.employmentRate)}
            required
            min={0}
            step={0.1}
            minExcluded
            initialValue={recordToUpdate?.employmentRate}
          />
        </Grid.Item>
        <Grid.Item xl={3} xs={8}>
          <FieldCountry
            name='region'
            label={intl.formatMessage(labels.region)}
            required
            initialValue={recordToUpdate?.region}
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <CompanyUnitFormControl
            name='reason'
            type='changeReason'
            disabled={submitting}
            items={companyUnits.salaryChangeReasons}
            required
            validate={validateFilled}
            initialValue={needToReplace ? undefined : recordToUpdate?.reason}
          />
        </Grid.Item>
        <Grid.Item xl={12}>
          <FieldTextarea
            name='comment'
            label={intl.formatMessage(labels.comment)}
            rows={2}
            initialValue={needToReplace ? undefined : recordToUpdate?.comment}
            parse={identity}
          />
        </Grid.Item>
        {extendedMode && (
          <Grid.Item xl={12}>
            <FieldTextarea
              name='specialArrangements'
              label={intl.formatMessage(labels.specialArrangements)}
              rows={2}
              initialValue={recordToUpdate?.specialArrangements}
              parse={identity}
            />
          </Grid.Item>
        )}
      </Grid>
    </form>
  );
}
