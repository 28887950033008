import {FieldRenderProps, useField as useFieldBase, UseFieldConfig} from 'react-final-form';

/* Fix type inferring from original `onChange(newValue: any)` */
export type UseFieldShape<FieldValue, T extends HTMLElement = HTMLElement, InputValue = FieldValue> = {
  meta: FieldRenderProps<FieldValue, T, InputValue>['meta'];
  input: FieldRenderProps<FieldValue, T, InputValue>['input'] & {
    onChange(newValue: FieldValue): void;
  };
};

export const useField = <FieldValue, T extends HTMLElement = HTMLElement, InputValue = FieldValue>(
  name: string,
  config: UseFieldConfig<FieldValue> = {},
): UseFieldShape<FieldValue, T, InputValue> => {
  const validateFields = config.validateFields || [];
  const subscription = config.subscription || {};

  const extraConfig: UseFieldConfig<FieldValue> = {
    ...config,
    subscription: {
      dirtySinceLastSubmit: true,
      error: true,
      submitError: true,
      touched: true,
      value: true,
      valid: true,
      invalid: true,
      submitting: true,
      ...subscription,
    },
    validateFields: [...validateFields],
  };

  return useFieldBase<FieldValue, T, InputValue>(name, extraConfig);
};

export type UseField = typeof useField;
