import {defineMessages} from 'react-intl';
import {StockOptionAgreementType} from '.';

export const stockOptionAgreementTypes = defineMessages<StockOptionAgreementType>({
  [StockOptionAgreementType.HISTORIC]: {
    defaultMessage: 'Historic',
    description: 'Stock option agreement type',
  },
  [StockOptionAgreementType.GENERAL]: {
    defaultMessage: 'General',
    description: 'Stock option agreement type',
  },
  [StockOptionAgreementType.PERFORMANCE]: {
    defaultMessage: 'Performance',
    description: 'Stock option agreement type',
  },
});
