import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {Offer} from 'domain/offer/model';
import {VersionedOfferCompensations} from 'domain/offer/model/compensation';
import {getOfferCompensationsFx} from 'domain/offer/stores/compensations';
import {$offerCompensations} from 'domain/offer/stores/compensations/state';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: VersionedOfferCompensations | null;
  dataState: DataState;
  clientErrorStatus?: ClientErrorStatus;
  hasReadAccess: boolean;
};

export function useOfferCompensations(offer: Offer | null): ReturnShape {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const hasReadAccess =
    (selfUserId === offer?.general.recruiter?.id &&
      acl.hasSecurePermission(SecurePermission.OFFER_COMPENSATION_READ_MY)) ||
    acl.hasSecurePermission(SecurePermission.OFFER_COMPENSATION_READ_ANY);
  const [clientErrorStatus, setClientErrorStatus] = useState<ClientErrorStatus>();
  const data = useStoreMap({
    store: $offerCompensations,
    keys: [offer?.id],
    fn: ({byOfferId}, [offerId]) => (offerId && byOfferId[offerId]) || null,
  });
  const dataState = useStoreMap({
    store: $offerCompensations,
    keys: [offer?.id],
    fn: ({byOfferIdState}, [offerId]) => (offerId && byOfferIdState[offerId]) || DataState.IDLE,
  });

  useEffect(() => {
    setClientErrorStatus(undefined);

    if (offer && !isLoadingOrLoaded(dataState) && hasReadAccess) {
      const {id, versions} = offer;
      const versionIds = versions.map((version) => version.id);

      getOfferCompensationsFx({id, versionIds}).catch((error: Error) => {
        if (error instanceof ClientError && error.status === ClientErrorStatus.NOT_FOUND) {
          setClientErrorStatus(error.status);
        } else {
          toaster.error(error.message);
        }
      });
    }
  }, [offer, setClientErrorStatus, acl]);

  return {
    clientErrorStatus,
    dataState,
    data,
    hasReadAccess,
  };
}
