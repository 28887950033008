import cn from 'classnames';
import React, {ReactNode} from 'react';
import styles from './index.css';

export const textIntent = ['negative', 'neutral', 'none', 'positive', 'primary', 'warning'] as const;

export type TextIntent = (typeof textIntent)[number];

export const classNameByIntent: {[key in TextIntent]: string} = {
  negative: styles.textIntentNegative,
  neutral: styles.textIntentNeutral,
  none: '',
  positive: styles.textIntentPositive,
  primary: styles.textIntentPrimary,
  warning: styles.textIntentWarning,
};

type Props = {
  children?: ReactNode;
  intent: TextIntent;
};

export function IntentionedText({children, intent}: Props) {
  return <span className={cn(styles.text, classNameByIntent[intent])}>{children}</span>;
}
