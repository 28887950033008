import cn from 'classnames';
import {type Locator} from 'create-locator';
import React from 'react';
import {ReactComponent as InfoIcon} from '../icons/circle/info.svg';
import styles from './index.css';
import {BadgeProps, createBadgeLocator, classNameByIntent, isPropsWithIntent, isPropsWithColor} from './props';
import {TooltipFaq} from '../TooltipFaq';

export * from './props';

export type BadgeLocator = Locator<void>;

export function Badge(props: BadgeProps) {
  const {children, icon, hint, hintPlacement, noMargin, isCardBadge, title} = props;
  const isOnlyIcon = !children && !hint;

  const locator = createBadgeLocator(props);

  const intentClassName = isPropsWithIntent(props) ? classNameByIntent[props.intent] : undefined;
  const colorStyles = isPropsWithColor(props)
    ? {color: props.color, backgroundColor: props.backgroundColor}
    : undefined;

  const className = cn(styles.badge, intentClassName, {
    [styles.badgeNoMargin]: noMargin,
    [styles.cardBadge]: isCardBadge,
    [styles.onlyIcon]: isOnlyIcon,
  });

  return (
    <div className={className} style={colorStyles} title={title} {...locator()}>
      {icon && <div className={styles.iconWrap}>{icon}</div>}
      {children && <div>{children}</div>}
      {!isOnlyIcon && hint && (
        <div className={styles.hintWrap}>
          <TooltipFaq content={hint} placement={hintPlacement}>
            <InfoIcon className={styles.hintIcon} />
          </TooltipFaq>
        </div>
      )}
    </div>
  );
}
