import {intranetApi} from 'apiClient';
import {UserFull, userFullSchema} from 'domain/user/model';
import {UserDiff} from 'domain/user/model/diff';

export type UpdateParams = {
  id: string;
  userData: UserDiff;
};

export const update = async ({id, userData}: UpdateParams): Promise<UserFull> => {
  const {data} = await intranetApi.post('/v1/users/updateAsAdmin', userData, {params: {id}});

  return userFullSchema.runWithException(data);
};
