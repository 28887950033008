import {defineMessages} from 'react-intl';

export const columnNames = defineMessages({
  title: {
    defaultMessage: 'Title',
    description: '[title] Legal entities column name',
  },
  country: {
    defaultMessage: 'Country',
    description: '[title] Legal entities column name',
  },
  currency: {
    defaultMessage: 'Currency',
    description: '[title] Legal entities column name',
  },
});
