import {DataState} from '@joomcode/deprecated-utils/dataState';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {LegalEntityId} from 'domain/legalEntity/model';
import {useSalaryReadAccess} from 'domain/salaryRecord/hooks/useSalaryReadAccess';
import {useUserSalaryRecords} from 'domain/salaryRecord/hooks/useUserSalaryRecords';
import {AnySalaryRecord, ExtendedSalaryRecord, SalaryRecordId} from 'domain/salaryRecord/model';
import {deleteSalaryRecordFx} from 'domain/salaryRecord/stores/main';
import {SalaryRecordsTable} from 'domain/salaryRecord/widgets/Table';
import {UserFull} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  user: UserFull;
  legalEntityId: LegalEntityId;
  canEdit: boolean;
  isSensitiveDataHidden: boolean;
  onDialogOpenWithPayload(record: ExtendedSalaryRecord): void;
  setNeedToReplace: (value: boolean) => void;
  records: AnySalaryRecord[];
  dataState: DataState;
};

export function JobContractSalaryTab({
  user,
  legalEntityId,
  canEdit,
  isSensitiveDataHidden,
  onDialogOpenWithPayload,
  setNeedToReplace,
}: Props) {
  const intl = useIntl();
  const readAccess = useSalaryReadAccess(user);
  const {data, dataState} = useUserSalaryRecords(user, legalEntityId);
  const onDeleteRecordClick = useCallback(
    (id: SalaryRecordId) => {
      confirm(
        {
          title: intl.formatMessage(messages.deletionConfirmationTitle),
          text: intl.formatMessage(messages.deletionConfirmationText),
          confirmationText: intl.formatMessage(messages.deletionConfirmationButton),
          intent: 'negative',
          onConfirm: () =>
            deleteSalaryRecordFx({id, userId: user.id, legalEntityId}).catch(toaster.interceptThenThrowError),
        },
        intl,
      );
    },
    [intl, user.id, legalEntityId],
  );
  const onEditRecordClick = useCallback(
    (record: ExtendedSalaryRecord) => {
      setNeedToReplace(false);
      return onDialogOpenWithPayload(record);
    },
    [onDialogOpenWithPayload, setNeedToReplace],
  );
  const onReplaceRecordClick = useCallback(
    (record: ExtendedSalaryRecord) => {
      setNeedToReplace(true);
      return onDialogOpenWithPayload(record);
    },
    [onDialogOpenWithPayload, setNeedToReplace],
  );

  return (
    <SalaryRecordsTable
      readAccess={readAccess}
      records={data}
      dataState={dataState}
      isSensitiveDataHidden={isSensitiveDataHidden}
      {...(canEdit && {
        onDeleteItem: onDeleteRecordClick,
        onEditItem: onEditRecordClick,
        onReplaceItem: onReplaceRecordClick,
      })}
    />
  );
}
