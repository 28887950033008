import {mimeType} from '@joomcode/deprecated-utils/file/mime';
import {findBestImageForSize} from '@joomcode/deprecated-utils/image/bundle';
import {FileSizeUnit, fileSizeUtils} from '@joomcode/deprecated-utils/measure/measures/fileSize';
import {ReactComponent as UserIcon} from '@joomcode/joom-ui/icons/core/user.svg';
import cn from 'classnames';
import {ImageUpload} from 'components/widgets/ImageUpload';
import {selfApi} from 'domain/self/api';
import {usersApi} from 'domain/user/api';
import {UpdateAvatarParams} from 'domain/user/api/updateAvatar';
import {User, UserFull} from 'domain/user/model';
import {updateUser} from 'domain/user/stores/main';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

const MIN_IMAGE_WIDTH_PX = 608;
const ACCEPTED_MIME_TYPES = [mimeType.image.jpeg, mimeType.image.png];
const MAX_FILE_SIZE = fileSizeUtils.createMeasure(8, FileSizeUnit.Megabyte);

type Props = {
  editable?: boolean;
  isSelfUser?: boolean;
  user: User | UserFull;
};

export function UserProfilePhoto({editable, isSelfUser, user: {avatarBundle, id}}: Props) {
  const intl = useIntl();
  const src = avatarBundle && findBestImageForSize(avatarBundle.images, {width: MIN_IMAGE_WIDTH_PX})?.url;

  const content = (
    <div className={cn([styles.root, !src && styles.noImage])}>
      {src ? <img src={src} alt='' className={styles.image} key={id} /> : <UserIcon />}
    </div>
  );
  if (!editable) {
    return content;
  }

  const editButtonTooltip = intl.formatMessage(messages.uploadTooltip);

  if (isSelfUser) {
    return (
      <ImageUpload<void, UserFull>
        onComplete={updateUser}
        upload={selfApi.updateAvatar}
        uploadParams={undefined}
        editButtonTooltip={editButtonTooltip}
        maxFileSize={MAX_FILE_SIZE}
        acceptedMimeTypes={ACCEPTED_MIME_TYPES}
      >
        {content}
      </ImageUpload>
    );
  }

  return (
    <ImageUpload<UpdateAvatarParams, UserFull>
      onComplete={updateUser}
      upload={usersApi.updateAvatar}
      uploadParams={{id}}
      editButtonTooltip={editButtonTooltip}
      maxFileSize={MAX_FILE_SIZE}
      acceptedMimeTypes={ACCEPTED_MIME_TYPES}
    >
      {content}
    </ImageUpload>
  );
}
