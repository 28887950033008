import {ReactComponent as QuestionIcon} from '@joomcode/joom-ui/icons/circle/question.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {Decision} from 'domain/compensationReviewRequest/model/decision';
import {UserLink} from 'domain/user/widgets/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  decisions: Decision[];
};

export function CompensationReviewRequestComments({decisions}: Props) {
  const intl = useIntl();

  return (
    <Panel withPadding title={intl.formatMessage(messages.title)}>
      {decisions.map(({createdAt, createdBy, comment, user}, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.root}>
          {createdBy && createdAt && (
            <>
              <div className={styles.meta}>
                <div className={styles.user}>
                  <UserLink user={createdBy} withAvatar={false} colored />
                  {createdBy.id !== user.id && (
                    <div className={styles.hint}>
                      <TooltipFaq
                        content={intl.formatMessage(messages.hint, {
                          user: <UserLink user={user} withAvatar={false} colored />,
                        })}
                      >
                        <QuestionIcon />
                      </TooltipFaq>
                    </div>
                  )}
                </div>
                <div className={styles.date}>
                  {intl.formatDate(createdAt, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                  })}
                </div>
              </div>
              <div className={styles.comment}>{comment}</div>
            </>
          )}
        </div>
      ))}
    </Panel>
  );
}
