import {intranetApi} from 'apiClient';
import {Offer, OfferId, offerSchema} from 'domain/offer/model';
import {NewcomerFormTemplateCountry} from 'domain/offer/model/templateCountry';

export type OfferSetAcceptedConfig = {
  id: OfferId;
  linkToInterviewFeedback?: string;
  templateCountry: NewcomerFormTemplateCountry;
};

export const setAccepted = async ({id, ...body}: OfferSetAcceptedConfig): Promise<Offer> => {
  const {data} = await intranetApi.post('/v1/offers/setAccepted', body, {params: {id}});

  return offerSchema.runWithException(data);
};
