import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {ReactComponent as CrossIcon} from '@joomcode/joom-ui/icons/core/crossSmall.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {TooltipAlt} from '@joomcode/joom-ui/TooltipAlt';
import {SecurePermission} from 'domain/permission/model/secure';
import {SecureRole} from 'domain/secureRole/model';
import {removeSecureRoleFromUserFx} from 'domain/secureRole/stores/main';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {User} from 'domain/user/model';
import {ListedUser} from 'domain/user/widgets/ListedUser';
import {generalMessages} from 'i18n/messages/general';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  role: SecureRole;
};

export function SecureRoleOwners({role}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  const selfUserId = useSelfUserId();

  const removeRoleFromUser = useAsyncTask(
    async (user: User) =>
      removeSecureRoleFromUserFx({userId: user.id, secureRoleId: role.id})
        .then(() => {})
        .catch(toaster.interceptThenThrowError),
    [role],
  );

  const onRevokeClick = useCallback(
    (user: User) =>
      confirm(
        {
          title: intl.formatMessage(messages.confirmTitle),
          text: intl.formatMessage(messages.confirmText, {
            fullName: intl.formatMessage(generalMessages.fullName, {
              firstName: user.firstName,
              lastName: user.lastName,
            }),
            login: user.login,
            role: role.title,
          }),
          confirmationText: intl.formatMessage(messages.confirmApprove),
          onConfirm: () => removeRoleFromUser.perform(user),
        },
        intl,
      ),
    [removeRoleFromUser, intl, role],
  );

  return (
    <Panel>
      {role.holders.length === 0 ? (
        <Panel.Content withPadding>{intl.formatMessage(messages.roleIsNotUsed)}</Panel.Content>
      ) : (
        <>
          <Panel.Header title={intl.formatMessage(messages.title, {name: role.title, quantity: role.holders.length})} />
          <Panel.Content withPadding>
            <div className={styles.columns}>
              {role.holders.map((holder) => (
                <div key={holder.id} className={styles.item}>
                  <ListedUser user={holder} />
                  {acl.hasSecurePermission(SecurePermission.SECURE_ROLE_REVOKE) && holder.id !== selfUserId && (
                    <div className={styles.revokeButton}>
                      <TooltipAlt content={intl.formatMessage(messages.revokeTooltip)}>
                        <Button intent='neutral' kind='text' size='m' onClick={() => onRevokeClick(holder)}>
                          <span className={styles.revokeIcon}>
                            <CrossIcon />
                          </span>
                        </Button>
                      </TooltipAlt>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Panel.Content>
        </>
      )}
    </Panel>
  );
}
