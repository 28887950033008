import {boolean, object} from '@joomcode/deprecated-utils/jsonValidation';
import {useUpdateEffect} from '@joomcode/deprecated-utils/react/useUpdateEffect';
import {SingleStorageManager, JsonSerializer, LocalStorage} from '@joomcode/deprecated-utils/Storage';
import {useMemo} from 'react';
import {useDisclosureState, DisclosureState} from './state';

const schema = object({
  isExpanded: boolean(),
});

export function useStoredDisclosureState(
  id: string,
  options: {
    initiallyExpanded?: boolean;
  } = {},
): DisclosureState {
  const storage = useMemo(() => new SingleStorageManager(id, new LocalStorage(), new JsonSerializer(schema)), [id]);
  const restoredIsExpanded = useMemo<boolean | undefined>(() => storage.restore()?.isExpanded, [storage.restore]);

  const disclosureState = useDisclosureState({initiallyExpanded: restoredIsExpanded ?? options.initiallyExpanded});

  useUpdateEffect(() => {
    storage.store({isExpanded: disclosureState.isExpanded});
  }, [disclosureState.isExpanded]);

  return disclosureState;
}
