import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonCancel: {
    defaultMessage: 'Cancel',
    description: '[button] Compensation review request rejection: cancel',
  },
  buttonSubmit: {
    defaultMessage: 'Reject',
    description: '[button] Compensation review request rejection: submit',
  },
  dialogAriaLabel: {
    defaultMessage: 'Reject compensation review request',
    description: 'Compensation review request rejection: aria label',
  },
  dialogAriaLabelOnBehalf: {
    defaultMessage: 'Reject on behalf of {onBehalfOf}',
    description: 'Compensation review request rejection on behalf of other user: aria label',
  },
  title: {
    defaultMessage: 'Reject compensation review request',
    description: '[title] Compensation review request rejection: title',
  },
  titleOnBehalf: {
    defaultMessage: 'Reject on behalf of {onBehalfOf}',
    description: '[title] Compensation review request rejection on behalf of other user: title',
  },
  label: {
    defaultMessage: 'Rejection reason',
    description: 'Compensation review request rejection: comment field label',
  },
});
