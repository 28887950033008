import {defineMessages} from 'react-intl';

export const columnNames = defineMessages({
  created: {
    defaultMessage: 'Created',
    description: 'Compensation requests column name',
  },
  owner: {
    defaultMessage: 'Joomer',
    description: 'Compensation requests column name',
  },
  country: {
    defaultMessage: 'Country',
    description: 'Compensation requests column name',
  },
  startDate: {
    defaultMessage: 'Start date',
    description: 'Compensation requests column name',
  },
  endDate: {
    defaultMessage: 'End date',
    description: 'Compensation requests column name',
  },
  status: {
    defaultMessage: 'Status',
    description: 'Compensation requests column name',
  },
  legalEntity: {
    defaultMessage: 'Legal entity',
    description: 'Compensation requests column name',
  },
});

export const messages = defineMessages({
  noRequests: {
    defaultMessage: 'No requests yet',
    description: 'Compensation requests: empty list',
  },
});
