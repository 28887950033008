import {identity} from '@joomcode/deprecated-utils/function';
import {Suggest} from '@joomcode/joom-ui/Suggest';
import {ClearableInput} from 'components/ui/ClearableInput';
import React from 'react';

type Props = JSX.IntrinsicElements['input'] & {
  items: string[];
  onBlur?: () => void;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onSelect?: (item: string) => void;
  value: string;
};

const filterItems = (items: string[], value: string) =>
  items.filter((item) => item.toLowerCase().includes(value.toLowerCase()));

export function SuggestedInput(props: Props) {
  return (
    <Suggest<string>
      {...props}
      getItemKey={identity}
      items={filterItems(props.items, props.value)}
      showSuggestionsOnFocus
    >
      {({getInputProps}) => (
        <ClearableInput
          onClear={() => {
            props.onChange('');
            if (props.onFocus && props.onBlur) {
              // mark field touched
              props.onFocus();
              props.onBlur();
            }
          }}
          {...getInputProps()}
        />
      )}
    </Suggest>
  );
}
