import {oneOfEnum} from '@joomcode/deprecated-utils/jsonValidation';
import {JsonSerializer, LocalStorage, SingleStorageManager} from '@joomcode/deprecated-utils/Storage';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import React from 'react';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {officeUrls} from 'routes/officeMap/urls';

export const favoriteOfficeStorage = new SingleStorageManager(
  'favoriteOffice',
  new LocalStorage(),
  new JsonSerializer(oneOfEnum(OfficeAlias)),
);

const DEFAULT_FAVORITE_OFFICE = OfficeAlias.MOSCOW;

export function FavoriteOfficeRedirect({location: {search}}: RouteComponentProps) {
  const favoriteOffice = favoriteOfficeStorage.restore() ?? DEFAULT_FAVORITE_OFFICE;
  return <Redirect to={{pathname: officeUrls.office({alias: favoriteOffice}), search}} />;
}
