import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import cn from 'classnames';
import {HintIcon} from 'components/ui/HintIcon';
import {StyledLink} from 'components/ui/StyledLink';
import {FormattedTimeOffDays} from 'components/widgets/FormattedTimeOffDays';
import {OfficePolicy} from 'domain/officePolicyRecord/model/policy';
import {Permission} from 'domain/permission/model';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {useBalanceRecordsReadAccess} from 'domain/timeOff/balanceRecord/hooks/useBalanceRecordsReadAccess';
import {useUserTimeOffBalances} from 'domain/user/hooks/useUserTimeOffBalances';
import {UserFull} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {timeOffUrls} from 'routes/timeOff/urls';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {useAnalytics} from 'services/analytics/useAnalytics';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  user: UserFull;
};

export function UserTimeOffBalancePanel({user}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {gtag} = useAnalytics();
  const selfUserId = useSelfUserId();
  const isSelfUser = selfUserId === user.id;
  const {data, dataState} = useUserTimeOffBalances(user);
  const readAccess = useBalanceRecordsReadAccess(user.id);
  const canRequestForSelf = isSelfUser && acl.hasPermission(Permission.SELF_TIME_OFF_REQUEST_WRITE);
  const canRequestOnBehalf = !isSelfUser && acl.hasPermission(Permission.TIME_OFF_REQUEST_WRITE_ON_BEHALF);
  const renderError = useCallback(() => <Error message={intl.formatMessage(messages.loadingError)} />, [intl]);
  const requestUrl = useMemo(
    () => (isSelfUser ? timeOffUrls.createRequest() : timeOffUrls.createRequestOnBehalf({login: user.login})),
    [isSelfUser, user.login],
  );
  const onRequestClick = useCallback(() => {
    gtag.logEvent('element_click', {
      element: isSelfUser ? 'time_off_balance_panel_request' : 'time_off_balance_panel_request_on_behalf',
    });
  }, [isSelfUser, gtag]);

  const showRemoteSection = user.officePolicy === OfficePolicy.COMMON || user.officePolicy === OfficePolicy.EXCLUSIVE;

  return (
    <div className={styles.root}>
      <Panel
        title={intl.formatMessage(showRemoteSection ? messages.title : messages.vacations)}
        toolbarTop={
          readAccess && (
            <div className={styles.toolbar}>
              <StyledLink to={usersUrls.timeOffBalance({login: user.login})} colored>
                {intl.formatMessage(messages.details)}
              </StyledLink>
            </div>
          )
        }
        withPadding
        withMarginBottom
      >
        <StateHandler data={data} state={dataState} renderError={renderError}>
          {({availableRemoteWorkDays, availableVacationDays, plannedVacationDays}) => (
            <>
              <div className={styles.sections}>
                <div className={styles.section}>
                  {showRemoteSection && <div className={styles.title}>{intl.formatMessage(messages.vacations)}</div>}
                  <div className={styles.valueRow}>
                    <div className={styles.value}>
                      <FormattedTimeOffDays days={availableVacationDays} onlyNumber />
                    </div>
                    {intl.formatMessage(messages.available)}
                    {plannedVacationDays > 0 && (
                      <span className={styles.hint}>
                        <HintIcon>{intl.formatMessage(messages.hint)}</HintIcon>
                      </span>
                    )}
                  </div>
                  <div className={cn(styles.valueRow, styles.requested)}>
                    <div className={styles.value}>
                      <FormattedTimeOffDays days={plannedVacationDays} onlyNumber />
                    </div>
                    {intl.formatMessage(messages.requested)}
                  </div>
                </div>
                {showRemoteSection && (
                  <div className={styles.section}>
                    <div className={styles.title}>{intl.formatMessage(messages.remoteWork)}</div>
                    <div className={styles.valueRow}>
                      <div className={styles.value}>
                        <FormattedTimeOffDays days={availableRemoteWorkDays} onlyNumber />
                      </div>
                      {intl.formatMessage(messages.available)}
                    </div>
                  </div>
                )}
              </div>
              {(canRequestForSelf || canRequestOnBehalf) && (
                <>
                  <div className={styles.separator} />
                  <div className={styles.requestSection}>
                    <RouterLinkButton
                      to={requestUrl}
                      kind='secondary'
                      intent='neutral'
                      size='m'
                      onClick={onRequestClick}
                    >
                      {intl.formatMessage(messages.requestButton, {isSelfUser})}
                    </RouterLinkButton>
                  </div>
                </>
              )}
            </>
          )}
        </StateHandler>
      </Panel>
    </div>
  );
}
