import {Button} from '@joomcode/joom-ui/Button';
import {CopyButton} from '@joomcode/joom-ui/Copy/Button';
import {Input} from '@joomcode/joom-ui/Input';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Select} from '@joomcode/joom-ui/Select';
import cn from 'classnames';
import {Language} from 'domain/language/model';
import {LanguageSelect} from 'domain/language/widgets/Select';
import {offersApi} from 'domain/offer/api';
import {OfferId} from 'domain/offer/model';
import {NewcomerFormTemplateCountry} from 'domain/offer/model/templateCountry';
import React, {useCallback, useMemo, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {fieldLabels, messages} from './messages';
import styles from './styles.css';

type FormState = {
  language: Language;
  lifetimeDays: number;
};

type Props = {
  templateCountry: NewcomerFormTemplateCountry | undefined;
  nameToken: string;
  offerId: OfferId;
};

const TOKEN_LIFETIME_OPTIONS = [90, 60, 30, 14, 7, 1];
const TOKEN_LIFETIME_DEFAULT = 14;
const APPLICATION_LANGUAGES = [Language.RU, Language.EN];

const DEFAULT_LANGUAGE = Language.EN;
const DEFAULT_LANGUAGE_BY_COUNTRY: Record<NewcomerFormTemplateCountry, Language> = {
  [NewcomerFormTemplateCountry.ARMENIA]: Language.RU,
  [NewcomerFormTemplateCountry.CHINA]: Language.EN,
  [NewcomerFormTemplateCountry.GERMANY]: Language.EN,
  [NewcomerFormTemplateCountry.LATVIA]: Language.EN,
  [NewcomerFormTemplateCountry.LUXEMBOURG]: Language.EN,
  [NewcomerFormTemplateCountry.PORTUGAL]: Language.EN,
  [NewcomerFormTemplateCountry.RUSSIA]: Language.RU,
  [NewcomerFormTemplateCountry.UKRAINE]: Language.RU,
  [NewcomerFormTemplateCountry.OTHER]: Language.EN,
};

const generateLink = (token: string, nameToken: string, language: Language) => {
  const newcomerHost = window.intranetEnv.NEWCOMER_WEB_URL;
  return `${newcomerHost}/${language}/${encodeURIComponent(nameToken)}?token=${encodeURIComponent(token)}`;
};

const getLanguageSelectId = (formId: string) => `${formId}-language`;
const getLifetimeSelectId = (formId: string) => `${formId}-lifetime`;

export function NewcomerLinkGeneratorPanel({templateCountry, nameToken, offerId}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const [link, setLink] = useState<string | undefined>();
  const getLifetimeOptionLabel = useCallback(
    (days: number) => intl.formatMessage(messages.tokenLifetimeOption, {days}),
    [intl],
  );

  const onSubmit = useCallback(
    ({language, lifetimeDays}: FormState) =>
      offersApi
        .generateToken({id: offerId, lifetimeDays})
        .then((token) => {
          setLink(generateLink(token, nameToken, language));
        })
        .catch(toaster.interceptThenThrowError),
    [offerId, nameToken],
  );

  return (
    <Panel withPadding title={intl.formatMessage(messages.title)}>
      {link ? (
        <div className={styles.result}>
          <span className={cn(styles.field, styles.resultInput)}>
            <Input disabled value={link} />
          </span>
          <span className={styles.buttonWrap}>
            <CopyButton
              size='m'
              kind='primary'
              intent='primary'
              label={intl.formatMessage(messages.copyButton)}
              value={link}
            />
          </span>
        </div>
      ) : (
        <Form<FormState> onSubmit={onSubmit}>
          {({handleSubmit, submitting}) => (
            <form id={formId} onSubmit={handleSubmit} className={styles.form}>
              <span className={styles.field}>
                <label htmlFor={getLanguageSelectId(formId)} className={styles.label}>
                  {intl.formatMessage(fieldLabels.language)}
                </label>
                <Field<Language>
                  name='language'
                  initialValue={templateCountry ? DEFAULT_LANGUAGE_BY_COUNTRY[templateCountry] : DEFAULT_LANGUAGE}
                >
                  {({input}) => (
                    <span className={styles.input}>
                      <LanguageSelect
                        {...input}
                        disabled={submitting}
                        id={getLanguageSelectId(formId)}
                        items={APPLICATION_LANGUAGES}
                      />
                    </span>
                  )}
                </Field>
              </span>
              <span className={styles.field}>
                <label htmlFor={getLifetimeSelectId(formId)} className={styles.label}>
                  {intl.formatMessage(fieldLabels.lifetimeDays)}
                </label>
                <Field<number> name='lifetimeDays' initialValue={TOKEN_LIFETIME_DEFAULT}>
                  {({input}) => (
                    <span className={styles.input}>
                      <Select<number>
                        {...input}
                        disabled={submitting}
                        id={getLifetimeSelectId(formId)}
                        items={TOKEN_LIFETIME_OPTIONS}
                        getItemKey={String}
                        itemToString={getLifetimeOptionLabel}
                        renderItem={getLifetimeOptionLabel}
                      />
                    </span>
                  )}
                </Field>
              </span>
              <span className={styles.buttonWrap}>
                <Button size='m' kind='primary' intent='primary' type='submit' form={formId} loading={submitting}>
                  {intl.formatMessage(messages.generateButton)}
                </Button>
              </span>
            </form>
          )}
        </Form>
      )}
    </Panel>
  );
}
