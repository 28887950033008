import {Button} from '@joomcode/joom-ui/Button';
import {Panel} from '@joomcode/joom-ui/Panel';
import {useSecurePersmissionsReadAccess} from 'domain/permission/hooks/useSecurePersmissionsReadAccess';
import {SecurePermission, SecurePermissionId} from 'domain/permission/model/secure';
import {SecureRole, SecureRoleDiff} from 'domain/secureRole/model';
import {updateSecureRoleFx} from 'domain/secureRole/stores/main';
import React, {useMemo} from 'react';
import {Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import {SecureRoleNameField} from './SecureRoleNameField';
import {SecureRolePermissionsField} from './SecureRolePermissionsField';

type Props = {
  role: SecureRole;
};

const initialPermissionsValue: SecurePermissionId[] = [];

export function SecureRoleForm({role}: Props) {
  const intl = useIntl();
  const acl = useAcl();
  const hasReadAccess = useSecurePersmissionsReadAccess();
  const canChange = acl.hasSecurePermission(SecurePermission.SECURE_ROLE_UPDATE);
  const initialValue = useMemo(
    () => role?.permissions.map((permission) => permission.id) ?? initialPermissionsValue,
    [role],
  );

  const updateRole = (diff: Partial<SecureRoleDiff>) =>
    updateSecureRoleFx({id: role.id, diff})
      .then(() => {})
      .catch(toaster.interceptThenThrowError);

  return (
    <Panel withPadding>
      <Form<Partial<SecureRoleDiff>> onSubmit={updateRole}>
        {({handleSubmit, submitting, valid}) => (
          <form onSubmit={handleSubmit}>
            <SecureRoleNameField
              disabled={!canChange || submitting}
              name='title'
              initialValue={role?.title ?? ''}
              currentRoleId={role?.id}
            />
            {hasReadAccess && (
              <SecureRolePermissionsField
                disabled={!canChange || submitting}
                name='permissionIds'
                initialValue={initialValue}
                allowedPermissionIds={role?.allowedPermissionIds}
              />
            )}
            <Button
              size='l'
              kind='primary'
              intent='primary'
              type='submit'
              disabled={!valid || !canChange}
              loading={submitting}
            >
              {intl.formatMessage(messages.submit)}
            </Button>
          </form>
        )}
      </Form>
    </Panel>
  );
}
