import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {RegularBonusRecord, regularBonusRecordSchema} from 'domain/regularBonusRecord/model';
import {UserId} from 'domain/user/model';

export const getMy = async (selfUserId: UserId): Promise<RegularBonusRecord[]> => {
  const {data} = await intranetApi.get('/v1/secure/users/regularBonusRecords/getMy');

  return array(regularBonusRecordSchema).runWithException(data);
};
