import {DataState} from '@joomcode/deprecated-utils/dataState';
import {MarketDataRecord} from 'domain/marketDataRecord/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {
  createMarketDataRecordFx,
  deleteMarketDataRecordFx,
  getMarketDataRecordsFx,
  getMyMarketDataRecordsFx,
  getSubordinateMarketDataRecordsFx,
  updateMarketDataRecordFx,
} from '.';

type State = {
  byUserId: Record<UserId, MarketDataRecord[]>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $marketDataRecords = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on([getMarketDataRecordsFx, getMyMarketDataRecordsFx, getSubordinateMarketDataRecordsFx], (state, userId) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on(
    [getMarketDataRecordsFx.fail, getMyMarketDataRecordsFx.fail, getSubordinateMarketDataRecordsFx.fail],
    (state, {params: userId}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.FAILED,
        },
      };
    },
  )
  .on(
    [getMarketDataRecordsFx.done, getMyMarketDataRecordsFx.done, getSubordinateMarketDataRecordsFx.done],
    (state, {params: userId, result}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: result,
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADED,
        },
      };
    },
  )
  .on([createMarketDataRecordFx.done, updateMarketDataRecordFx.done], (state, {params: {userId}, result}) => {
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: result,
      },
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADED,
      },
    };
  })
  .on(deleteMarketDataRecordFx.done, (state, {params: {userId, id}}) => {
    if (state.dataStateByUserId[userId] !== DataState.LOADED) {
      return state;
    }
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: state.byUserId[userId].filter((record) => record.id !== id),
      },
    };
  });
