import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const managerConsoleUrlPatterns = {
  root: '/manager-console',
  salaryRanges: '/manager-console/salary-ranges',
  subordinates: '/manager-console/subordinates',
  compensationReviewRequest: '/manager-console/compensation-review/:id',
  compensationReviewRequests: '/manager-console/compensation-review',
  compensationReviewRequestCreate: '/manager-console/compensation-review/create',
};

export const managerConsoleUrls = {
  root: () => managerConsoleUrlPatterns.root,
  salaryRanges: (searchParams?: {region?: string; userFunction?: string}) =>
    addSearchParams(managerConsoleUrlPatterns.salaryRanges, searchParams),
  subordinates: () => managerConsoleUrlPatterns.subordinates,
  compensationReviewRequest: (pathParams: {id: string}) =>
    generatePath(managerConsoleUrlPatterns.compensationReviewRequest, pathParams),
  compensationReviewRequests: () => managerConsoleUrlPatterns.compensationReviewRequests,
  compensationReviewRequestCreate: () => managerConsoleUrlPatterns.compensationReviewRequestCreate,
};
