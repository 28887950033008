import React from 'react';
import {ReactComponent as QuestionIcon} from '../../../icons/circle/question.svg';
import {TooltipFaq} from '../../../TooltipFaq';
import styles from './index.css';

type Props = {
  children: React.ReactNode;
  tooltip?: React.ReactNode;
};

export function PanelHeaderTitle({children, tooltip}: Props) {
  return (
    <span className={styles.headerTitle}>
      <span>{children}</span>
      {tooltip && (
        <span className={styles.tooltip}>
          <TooltipFaq content={tooltip}>
            <span className={styles.icon}>
              <QuestionIcon />
            </span>
          </TooltipFaq>
        </span>
      )}
    </span>
  );
}
