import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {SecurePermission} from 'domain/permission/model/secure';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {StockOptionAgreementActionButton} from 'domain/stockOption/agreement/widgets/ActionButton';
import {StockOptionAgreementFreezeCreateDialog} from 'domain/stockOption/agreement/widgets/FreezeCreateDialog';
import {StockOptionAgreementFreezeUpdateDialog} from 'domain/stockOption/agreement/widgets/FreezeUpdateDialog';
import {StockOptionAgreementFreeze} from 'domain/stockOption/agreementFreeze/model';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';
import {ReactComponent as SnowflakeIcon} from './snowflake.svg';

type Props = {
  agreement: StockOptionAgreement;
};

export function FreezeStockOptionAgreementButton({agreement}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const freezeCreateDialog = usePopupState();
  const freezeUpdateDialog = usePopupState();
  const lastFreeze: StockOptionAgreementFreeze | undefined = useMemo(
    () => agreement.freezes.at(-1),
    [agreement.freezes],
  );

  if (!acl.hasSecurePermission(SecurePermission.STOCK_OPTION_WRITE_ANY)) {
    return null;
  }

  return (
    <>
      <StockOptionAgreementActionButton
        icon={<SnowflakeIcon />}
        hint={intl.formatMessage(lastFreeze ? messages.updateHint : messages.createHint)}
        onClick={lastFreeze ? freezeUpdateDialog.open : freezeCreateDialog.open}
      />
      {freezeCreateDialog.isOpen && (
        <StockOptionAgreementFreezeCreateDialog agreement={agreement} onClose={freezeCreateDialog.close} />
      )}
      {freezeUpdateDialog.isOpen && lastFreeze && (
        <StockOptionAgreementFreezeUpdateDialog
          agreement={agreement}
          freeze={lastFreeze}
          onClose={freezeUpdateDialog.close}
        />
      )}
    </>
  );
}
