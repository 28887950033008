import {omit} from '@joomcode/deprecated-utils/object/omit';
import {createEntityListStore, createEntityStore} from '@joomcode/joom-admin/dataTypes/entityStore';
import {RoleBinding} from 'domain/roleBinding/model';
import {revokeRoleBindingFx} from 'domain/roleBinding/stores/main';
import {RoleBindingsCountState} from 'domain/roleBinding/stores/main/types';
import {createStore} from 'effector';
import {getActiveRoleBindingsFx, getActiveRoleBindingsWithCountFx} from '.';

export const $activeRoleBindings = createEntityStore({
  getEntityId: (roleBinding: RoleBinding) => roleBinding.id,
  query: {
    fx: getActiveRoleBindingsFx,
    getIdsFromParams: () => [],
  },
}).on(revokeRoleBindingFx.doneData, (state, result) => {
  return {
    ...state,
    byId: omit(state.byId, [result.id]),
  };
});

export const $activeRoleBindingList = createEntityListStore($activeRoleBindings);
export const $activeRoleBindingsCount = createStore<RoleBindingsCountState>({
  count: 0,
}).on(getActiveRoleBindingsWithCountFx.done, (state, {result}) => {
  return {
    ...state,
    count: result.count,
  };
});
