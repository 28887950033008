import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  editButton: {
    defaultMessage: 'Edit',
    description: '[button] Action on user profile page',
  },
  authenticateAs: {
    defaultMessage: 'Login as {name}',
    description: '[button] Action on user profile page',
  },
  pretendToBeNormal: {
    defaultMessage: 'Pretend to be normal',
    description: '[button] Action on user profile page',
  },
  backToSelf: {
    defaultMessage: 'Back to myself',
    description: '[button] Action on user profile page',
  },
});
