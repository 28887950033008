import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {omit} from '@joomcode/deprecated-utils/object/omit';
import {OfferId} from 'domain/offer/model';
import {VersionedOfferCompensations} from 'domain/offer/model/compensation';
import {updateOfferFx} from 'domain/offer/stores/main';
import {createStore} from 'effector';
import {getOfferCompensationsFx} from '.';

type State = {
  byOfferId: Record<OfferId, VersionedOfferCompensations>;
  byOfferIdState: Record<OfferId, DataState>;
};

export const $offerCompensations = createStore<State>({
  byOfferId: {},
  byOfferIdState: {},
})
  .on(getOfferCompensationsFx, (state, {id}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.LOADING,
    },
  }))
  .on(getOfferCompensationsFx.fail, (state, {params: {id}}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.FAILED,
    },
  }))
  .on(getOfferCompensationsFx.done, (state, {params: {id}, result}) => ({
    ...state,
    byOfferIdState: {
      ...state.byOfferIdState,
      [id]: DataState.LOADED,
    },
    byOfferId: {
      ...state.byOfferId,
      [id]: arrayToObject(
        result,
        ({versionId}) => versionId,
        ({compensation}) => compensation,
      ),
    },
  }))
  .on(updateOfferFx.done, (state, payload) => {
    const {id} = payload.params.params;
    return {
      ...state,
      byOfferIdState: omit(state.byOfferIdState, [id]),
      byOfferId: omit(state.byOfferId, [id]),
    };
  });
