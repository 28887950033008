import emptyStateIllustration from '@joomcode/joom-ui/illustrations/emptyState.svg';
import sadGirlIllustration from '@joomcode/joom-ui/illustrations/sadGirl.svg';
import * as React from 'react';
import styles from './Placeholder.css';

type Props = {
  children?: React.ReactNode;
  illustrationSrc: string;
  title: string;
};

export function Placeholder({children, illustrationSrc, title}: Props) {
  return (
    <section className={styles.root}>
      <img alt='' src={illustrationSrc} />
      <h2 className={styles.title}>{title}</h2>
      {children}
    </section>
  );
}

type SpecificPlaceholderProps = Omit<Props, 'illustrationSrc'>;

Placeholder.Error = (props: SpecificPlaceholderProps) => (
  <Placeholder {...props} illustrationSrc={sadGirlIllustration} />
);

Placeholder.EmptyState = (props: SpecificPlaceholderProps) => (
  <Placeholder {...props} illustrationSrc={emptyStateIllustration} />
);
