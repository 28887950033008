import {EntityResponse} from 'apiClient/entityResponse';
import {CompensationReportDiff, CompensationReportId} from 'domain/compensations/report/model';
import {CompensationRequestClosureDiff} from 'domain/compensations/request/api/close';
import {CompensationRequestRejectionDiff} from 'domain/compensations/request/api/reject';
import {UpdateCommentFromAdminConfig} from 'domain/compensations/request/api/updateCommentFromAdmin';
import {
  CompensationRequest,
  CompensationRequestCreationConfig,
  CompensationRequestId,
} from 'domain/compensations/request/model';
import {CompensationRequestType} from 'domain/compensations/request/model/type';
import {createEffect} from 'effector';

export const approveRequestAsAdminFx = createEffect<CompensationRequestId, CompensationRequest>(
  'approve compensation request as admin',
);
export const approveRequestAsManagerFx = createEffect<CompensationRequestId, CompensationRequest>(
  'approve compensation request as manager',
);
export const cancelRequestFx = createEffect<CompensationRequestId, CompensationRequest>('cancel compensation request');
export const cancelRequestAsHrFx = createEffect<CompensationRequestId, CompensationRequest>(
  'cancel compensation request as HR',
);
export const closeRequestFx = createEffect<
  {diff: CompensationRequestClosureDiff; id: CompensationRequestId},
  CompensationRequest
>('close compensation request');
export const createRequestFx = createEffect<CompensationRequestCreationConfig, CompensationRequest>(
  'create compensation request',
);
export const getAllRequestsByTypeFx = createEffect<{type: CompensationRequestType}, CompensationRequest[]>(
  'get all compensation requests by type',
);
export const getAvailableRequestsByTypeFx = createEffect<{type: CompensationRequestType}, CompensationRequest[]>(
  'get available compensation requests by type',
);
export const getRequestByIdFx = createEffect<CompensationRequestId, EntityResponse<CompensationRequest>>(
  'get compensation request by id',
);
export const rejectRequestFx = createEffect<
  {diff: CompensationRequestRejectionDiff; id: CompensationRequestId},
  CompensationRequest
>('reject compensation request');
export const submitReportFx = createEffect<
  {diff: CompensationReportDiff; id: CompensationReportId},
  CompensationRequest
>('submit compensation report');
export const updateCommentFromAdminFx = createEffect<UpdateCommentFromAdminConfig, CompensationRequest>(
  'update comment from admin',
);
export const updateReportFx = createEffect<
  {diff: CompensationReportDiff; id: CompensationReportId},
  CompensationRequest
>('update compensation report');
