import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No records',
    description: 'Functions and levels records table: empty message',
  },
});

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.EFFECTIVE_DATE]: {
    defaultMessage: 'Effective date',
    description: 'Functions and levels table column name',
  },
  [ColumnId.USER_FUNCTION]: {
    defaultMessage: 'Function',
    description: 'Functions and levels table column name',
  },
  [ColumnId.LEVEL_CODE]: {
    defaultMessage: 'Level code',
    description: 'Functions and levels table column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Status',
    description: 'Functions and levels table column name',
  },
});
