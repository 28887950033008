import {defineMessages} from 'react-intl';
import {bindValidatorOptions} from '../utils';
import {validateString, StringValidatorErrorCode} from '../validators/string';

// eslint-disable-next-line
export const asciiString = bindValidatorOptions(validateString, {regExp: /^[\x00-\x7F]*$/});
export const asciiStringErrorMessages = defineMessages({
  [StringValidatorErrorCode.STRING_REG_EXP_NOT_MATCH]: {
    id: 'validator-string-reg-exp-not-match-ascii-regexp',
    description: 'String validation error (string doesn’t match expected format)',
    defaultMessage: 'The text must only contain Latin letters (a-z), digits (0-9), and simple punctuation',
  },
});
