import {UserFullInfoColumn} from '.';
import {UserFullInfoColumnAccess} from './access';
import {UserFullInfoColumnCategory} from './category';

export type UserFullInfoColumnTreeItem = {
  key: UserFullInfoColumn | UserFullInfoColumnCategory;
  parent?: UserFullInfoColumnCategory;
  access?: UserFullInfoColumnAccess;
  sensitive?: boolean;
};

export const fullColumnsTree: UserFullInfoColumnTreeItem[] = [
  {
    key: UserFullInfoColumn.USER,
  },
  {
    key: UserFullInfoColumnCategory.PERSONAL,
  },
  {
    key: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumnCategory.SALARY,
    access: UserFullInfoColumnAccess.SALARY,
  },
  {
    key: UserFullInfoColumnCategory.SALARY_RANGE,
    access: UserFullInfoColumnAccess.SALARY_RANGES,
  },
  {
    key: UserFullInfoColumnCategory.REGULAR_BONUS,
    access: UserFullInfoColumnAccess.REGULAR_BONUS,
  },
  {
    key: UserFullInfoColumnCategory.JOB_CONTRACT,
    access: UserFullInfoColumnAccess.JOB_CONTRACTS,
  },
  {
    key: UserFullInfoColumnCategory.OPTIONS,
    access: UserFullInfoColumnAccess.STOCK_OPTIONS,
  },
  {
    key: UserFullInfoColumn.EMAIL,
    parent: UserFullInfoColumnCategory.PERSONAL,
  },
  {
    key: UserFullInfoColumn.BIRTHDAY,
    parent: UserFullInfoColumnCategory.PERSONAL,
    access: UserFullInfoColumnAccess.PRIVATE_INFO,
  },
  {
    key: UserFullInfoColumn.LOGIN,
    parent: UserFullInfoColumnCategory.PERSONAL,
  },
  {
    key: UserFullInfoColumn.GITHUB_LOGIN,
    parent: UserFullInfoColumnCategory.PERSONAL,
  },
  {
    key: UserFullInfoColumn.PHONE,
    parent: UserFullInfoColumnCategory.PERSONAL,
  },
  {
    key: UserFullInfoColumn.HIRE_DATE,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.PRIMARY_LEGAL_ENTITY,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.TIME_OFF_POLICY,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.LOCATION,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.OFFICE_POLICY,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.REMOTE_WORK_BALANCE,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.VACATION_BALANCE,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.DIVISION,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.DEPARTMENT,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.JOB_TITLE,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.MANAGER,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.LEVEL_CODE,
    parent: UserFullInfoColumnCategory.JOB_INFO,
    access: UserFullInfoColumnAccess.MARKET_DATA,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.USER_FUNCTION,
    parent: UserFullInfoColumnCategory.JOB_INFO,
  },
  {
    key: UserFullInfoColumn.SALARY_START_DATE,
    parent: UserFullInfoColumnCategory.SALARY,
    access: UserFullInfoColumnAccess.SALARY,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_LEGAL_ENTITY,
    parent: UserFullInfoColumnCategory.SALARY,
    access: UserFullInfoColumnAccess.SALARY,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_FIX_BASE,
    parent: UserFullInfoColumnCategory.SALARY,
    access: UserFullInfoColumnAccess.SALARY,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_EMPLOYMENT_RATE,
    parent: UserFullInfoColumnCategory.SALARY,
    access: UserFullInfoColumnAccess.SALARY,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_REGION,
    parent: UserFullInfoColumnCategory.SALARY,
    access: UserFullInfoColumnAccess.SALARY,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_CR,
    parent: UserFullInfoColumnCategory.SALARY,
    access: UserFullInfoColumnAccess.CR,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_RANGE_MINIMUM,
    parent: UserFullInfoColumnCategory.SALARY_RANGE,
    access: UserFullInfoColumnAccess.SALARY_RANGES,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_RANGE_TARGET,
    parent: UserFullInfoColumnCategory.SALARY_RANGE,
    access: UserFullInfoColumnAccess.SALARY_RANGES,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_RANGE_MAXIMUM,
    parent: UserFullInfoColumnCategory.SALARY_RANGE,
    access: UserFullInfoColumnAccess.SALARY_RANGES,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_RANGE_COMMENT,
    parent: UserFullInfoColumnCategory.SALARY_RANGE,
    access: UserFullInfoColumnAccess.SALARY_RANGES,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.SALARY_RANGE_COMPENSATION_TRACK,
    parent: UserFullInfoColumnCategory.SALARY_RANGE,
    access: UserFullInfoColumnAccess.SALARY_RANGES,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.REGULAR_BONUS_PERIOD,
    parent: UserFullInfoColumnCategory.REGULAR_BONUS,
    access: UserFullInfoColumnAccess.REGULAR_BONUS,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.REGULAR_BONUS,
    parent: UserFullInfoColumnCategory.REGULAR_BONUS,
    access: UserFullInfoColumnAccess.REGULAR_BONUS,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.JOB_CONTRACT_LEGAL_ENTITY,
    parent: UserFullInfoColumnCategory.JOB_CONTRACT,
    access: UserFullInfoColumnAccess.JOB_CONTRACTS,
  },
  {
    key: UserFullInfoColumn.JOB_CONTRACT_CONTRACT_TYPE,
    parent: UserFullInfoColumnCategory.JOB_CONTRACT,
    access: UserFullInfoColumnAccess.JOB_CONTRACTS,
  },
  {
    key: UserFullInfoColumn.JOB_CONTRACT_EFFECTIVE_DATE,
    parent: UserFullInfoColumnCategory.JOB_CONTRACT,
    access: UserFullInfoColumnAccess.JOB_CONTRACTS,
  },
  {
    key: UserFullInfoColumn.JOB_CONTRACT_STATUS,
    parent: UserFullInfoColumnCategory.JOB_CONTRACT,
    access: UserFullInfoColumnAccess.JOB_CONTRACTS,
  },
  {
    key: UserFullInfoColumn.JOB_CONTRACT_COMMENT,
    parent: UserFullInfoColumnCategory.JOB_CONTRACT,
    access: UserFullInfoColumnAccess.JOB_CONTRACTS,
  },
  {
    key: UserFullInfoColumn.JOB_CONTRACT_TIME_OFF_POLICY,
    parent: UserFullInfoColumnCategory.JOB_CONTRACT,
    access: UserFullInfoColumnAccess.JOB_CONTRACTS,
  },
  {
    key: UserFullInfoColumn.OPTIONS_GRANTED_TOTAL,
    parent: UserFullInfoColumnCategory.OPTIONS,
    access: UserFullInfoColumnAccess.STOCK_OPTIONS,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.OPTIONS_GRANTED_HISTORIC,
    parent: UserFullInfoColumnCategory.OPTIONS,
    access: UserFullInfoColumnAccess.STOCK_OPTIONS,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.OPTIONS_GRANTED_GENERAL,
    parent: UserFullInfoColumnCategory.OPTIONS,
    access: UserFullInfoColumnAccess.STOCK_OPTIONS,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.OPTIONS_GRANTED_PERFORMANCE,
    parent: UserFullInfoColumnCategory.OPTIONS,
    access: UserFullInfoColumnAccess.STOCK_OPTIONS,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.OPTIONS_VESTED,
    parent: UserFullInfoColumnCategory.OPTIONS,
    access: UserFullInfoColumnAccess.STOCK_OPTIONS,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.OPTIONS_AVAILABLE,
    parent: UserFullInfoColumnCategory.OPTIONS,
    access: UserFullInfoColumnAccess.STOCK_OPTIONS,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.OPTIONS_NEXT_12_MONTHS,
    parent: UserFullInfoColumnCategory.OPTIONS,
    access: UserFullInfoColumnAccess.STOCK_OPTIONS,
    sensitive: true,
  },
  {
    key: UserFullInfoColumn.OPTIONS_AGREEMENTS,
    parent: UserFullInfoColumnCategory.OPTIONS,
    access: UserFullInfoColumnAccess.STOCK_OPTIONS,
    sensitive: true,
  },
];
