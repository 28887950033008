import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {User} from 'domain/user/model';
import {setRolesFx} from 'domain/user/stores/main';
import {UserAutocomplete} from 'domain/user/widgets/Autocomplete';
import {UserRole} from 'domain/userRole/model';
import {loadUserRoleByIdFx} from 'domain/userRole/stores/main';
import {generalMessages} from 'i18n/messages/general';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  role: UserRole;
};

export function UserRoleIssue({role}: Props) {
  const intl = useIntl();
  const [activeUser, setActiveUser] = useState<User | null>(null);

  const issueRoleToUser = useAsyncTask(
    async (user: User) =>
      setRolesFx({id: user.id, roleIds: user.roleIds.concat([role.id])})
        .then(() => {
          loadUserRoleByIdFx({id: role.id});
          setActiveUser(null);
        })
        .catch(toaster.interceptThenThrowError),
    [role],
  );

  const {dataState, userByLogin} = useAllUsers();

  const onIssueClick = useCallback(
    (user: User | null) => {
      setActiveUser(user);

      if (user === null) {
        return;
      }

      confirm(
        {
          title: intl.formatMessage(messages.confirmTitle),
          text: intl.formatMessage(messages.confirmText, {
            fullName: intl.formatMessage(generalMessages.fullName, {
              firstName: user.firstName,
              lastName: user.lastName,
            }),
            login: user.login,
            role: role.name,
          }),
          confirmationText: intl.formatMessage(messages.confirmApprove),
          onConfirm: () => issueRoleToUser.perform(user),
        },
        intl,
      );
    },
    [issueRoleToUser, intl],
  );

  const users = useMemo(
    () => Object.values(userByLogin).filter((user) => !user.roleIds.includes(role.id)),
    [userByLogin],
  );

  return (
    <Panel withPadding>
      {dataState === DataState.LOADING && <Spinner />}
      {dataState === DataState.FAILED && <Error message={intl.formatMessage(messages.loadUserFail)} />}
      {dataState === DataState.LOADED && (
        <div className={styles.wrapper}>
          <h5 className={styles.title}>{intl.formatMessage(messages.title)}</h5>
          <UserAutocomplete items={users} value={activeUser} onChange={onIssueClick} clearable />
        </div>
      )}
    </Panel>
  );
}
