export enum IdValidatorErrorCode {
  ID_INVALID = 'id_invalid',
  ID_INVALID_FORMAT = 'id_invalid_format',
}

export type IdFormat = 'short' | 'long';

export type IdValidatorOptions = {
  format?: IdFormat;
};
