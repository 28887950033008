/* eslint-disable @typescript-eslint/naming-convention */
import cn from 'classnames';
import {isFunction} from '@joomcode/deprecated-utils/guards';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import {useValueRef} from '@joomcode/deprecated-utils/react/useValueRef';
import React, {useCallback, useMemo, useState} from 'react';
import {createLocator, getLocatorParameters} from 'create-locator';
import {ModalPopup} from '../ModalPopup';
import {DialogPrivateContext, DialogPublicContext} from './context';
import {DialogProps, DialogPrivateContextValue, DialogPublicContextValue, DialogLockFn, DialogSize} from './types';
import {Header} from './Header';
import {TopsidePicture} from './TopsidePicture';
import styles from './index.css';
import {Footer} from './Footer';
import {Body} from './Body';

export {useDialogContext} from './context';
export type {DialogLocator, DialogBodyLocator, DialogFooterLocator, DialogHeaderLocator} from './types';

const DialogComponent = React.forwardRef<HTMLDivElement, DialogProps>(function Dialog(
  {
    ariaLabel,
    afterClose,
    closeOnEscape = true,
    closeOnOutsideClick = true,
    icon,
    isOpen,
    children,
    headerImageAlt = '',
    headerImageUrl,
    shards,
    size = DialogSize.M,
    onClose,
    width,
    height,
    maxHeight,
    autoFocus,
    ...rest
  },
  ref,
) {
  const locator = createLocator(rest);
  const locatorParameters = getLocatorParameters(rest);
  const forceCloseRef = useValueRef(onClose);
  const [locks, setLocks] = useState<DialogLockFn[]>(() => []);
  const locksRef = useValueRef(locks);

  const close = useCallback(() => {
    const currentLocks = locksRef.current;

    if (currentLocks.length === 0) {
      forceCloseRef.current();
      return;
    }

    let lockIndex = 0;
    const processLock = () => {
      const targetLock = currentLocks[lockIndex];
      targetLock().then(({confirmed, forceClose}) => {
        if (confirmed) {
          lockIndex++;
          if (!forceClose && lockIndex < currentLocks.length) {
            processLock();
          } else {
            forceCloseRef.current();
          }
        }
      });
    };

    processLock();
  }, []);

  const [publicContextValue, privateContextValue] = useMemo<
    [DialogPublicContextValue, DialogPrivateContextValue]
  >(() => {
    return [
      {
        close,
        forceClose: forceCloseRef.current,
      },
      {
        size,
        registerLock: (fn) =>
          setLocks((currentLocks) => (currentLocks.includes(fn) ? currentLocks : [...currentLocks, fn])),
        unregisterLock: (fn) => setLocks((currentLocks) => currentLocks.filter((item) => item !== fn)),
        close,
        forceClose: forceCloseRef.current,
      },
    ];
  }, [close, size]);

  const style: React.CSSProperties = {maxWidth: width, maxHeight};
  if (height) {
    style.height = height;
  }

  return (
    <ModalPopup
      autoFocus={autoFocus}
      afterClose={afterClose}
      closeOnEscape={closeOnEscape}
      closeOnOutsideClick={closeOnOutsideClick}
      ariaLabel={ariaLabel}
      isActive={isOpen}
      shards={shards}
      onClose={privateContextValue.close}
      backdropStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
      ref={ref}
    >
      <div className={styles.wrapper} {...locator.wrapper(locatorParameters)}>
        <div
          style={style}
          className={cn(styles.container, styles[`containerSize${capitalize(size)}` as keyof typeof styles], {
            [styles.containerWithPicture]: Boolean(headerImageUrl) || Boolean(icon),
          })}
        >
          <DialogPublicContext.Provider value={publicContextValue}>
            <DialogPrivateContext.Provider value={privateContextValue}>
              <TopsidePicture headerImageAlt={headerImageAlt} headerImageUrl={headerImageUrl} icon={icon} />
              {isFunction(children) ? children(publicContextValue) : children}
            </DialogPrivateContext.Provider>
          </DialogPublicContext.Provider>
        </div>
      </div>
    </ModalPopup>
  );
});

export const Dialog = Object.assign(DialogComponent, {
  Header,
  Body,
  Footer,
});
