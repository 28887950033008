import {defineMessages} from 'react-intl';
import {CompanyUnitType} from './types';

export const labels = defineMessages<CompanyUnitType>({
  location: {
    defaultMessage: 'Office',
    description: 'Company unit field label',
  },
  division: {
    defaultMessage: 'Business unit',
    description: 'Company unit field label',
  },
  department: {
    defaultMessage: 'Department',
    description: 'Company unit field label',
  },
  jobTitle: {
    defaultMessage: 'Job title',
    description: 'Company unit field label',
  },
  userFunction: {
    defaultMessage: 'Function',
    description: 'Company unit field label',
  },
  changeReason: {
    defaultMessage: 'Change reason',
    description: 'Company unit field label',
  },
  offerSource: {
    defaultMessage: 'Source',
    description: 'Company unit field label',
  },
  regularBonusBasis: {
    defaultMessage: 'Basis',
    description: 'Company unit field label',
  },
  jobContractOnHoldReason: {
    defaultMessage: 'On hold reason',
    description: 'Company unit field label',
  },
});

export const warnings = defineMessages<CompanyUnitType>({
  location: {
    defaultMessage: 'You are creating a new office',
    description: 'Company unit field warning',
  },
  division: {
    defaultMessage: 'You are creating a new business unit',
    description: 'Company unit field warning',
  },
  department: {
    defaultMessage: 'You are creating a new department',
    description: 'Company unit field warning',
  },
  jobTitle: {
    defaultMessage: 'You are creating a new job title',
    description: 'Company unit field warning',
  },
  userFunction: {
    defaultMessage: 'You are creating a new function',
    description: 'Company unit field warning',
  },
  changeReason: {
    defaultMessage: 'You are creating a new reason',
    description: 'Company unit field warning',
  },
  offerSource: {
    defaultMessage: 'You are creating a new source',
    description: 'Company unit field warning',
  },
  regularBonusBasis: {
    defaultMessage: 'You are creating a new bonus basis',
    description: 'Company unit field warning',
  },
  jobContractOnHoldReason: {
    defaultMessage: 'You are creating a new reason',
    description: 'Company unit field warning',
  },
});
