import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {BulkUploadRowStatusIcon} from 'components/widgets/BulkUploadRowStatusIcon';
import {FinancialBulkUploadRow} from 'domain/financialRecord/model';
import {MarketDataRecord} from 'domain/marketDataRecord/model';
import {UserLink} from 'domain/user/widgets/Link';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

export type Props = {
  rows: FinancialBulkUploadRow<MarketDataRecord>[];
};

const getRowKey = ({meta}: FinancialBulkUploadRow<MarketDataRecord>) => meta.row.toString();

export function MarketDataUploadPanel({rows}: Props) {
  const intl = useIntl();
  const columns: Column<FinancialBulkUploadRow<MarketDataRecord>>[] = useMemo(
    () => [
      {
        defaultWidth: 5,
        id: ColumnId.PARSING_STATUS,
        name: intl.formatMessage(columnNames[ColumnId.PARSING_STATUS]),
        render: ({meta}) => <BulkUploadRowStatusIcon meta={meta} />,
      },
      {
        defaultWidth: 5,
        id: ColumnId.ROW_NUMBER,
        name: intl.formatMessage(columnNames[ColumnId.ROW_NUMBER]),
        render: ({meta}) => meta.row,
      },
      {
        defaultWidth: 12,
        id: ColumnId.USER,
        name: intl.formatMessage(columnNames[ColumnId.USER]),
        render: ({user}) => (user ? <UserLink user={user} colored withAvatar={false} /> : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: 15,
        id: ColumnId.EMAIL,
        name: intl.formatMessage(columnNames[ColumnId.EMAIL]),
        render: ({user}) => user?.email ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 12,
        id: ColumnId.USER_FUNCTION,
        name: intl.formatMessage(columnNames[ColumnId.USER_FUNCTION]),
        render: ({record}) => record?.userFunction ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 7,
        id: ColumnId.LEVEL_CODE,
        name: intl.formatMessage(columnNames[ColumnId.LEVEL_CODE]),
        render: ({record}) => record?.levelCode ?? <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 10,
        id: ColumnId.EFFECTIVE_DATE,
        name: intl.formatMessage(columnNames[ColumnId.EFFECTIVE_DATE]),
        render: ({record}) =>
          record ? <HumanDate value={record.effectiveDate} format='short' utc /> : <DataTable.CellEmpty />,
      },
    ],
    [intl],
  );

  return (
    <Panel title={intl.formatMessage(messages.title)} withSeparator>
      <DataTable<FinancialBulkUploadRow<MarketDataRecord>>
        columns={columns}
        dataState={DataState.LOADED}
        data={rows}
        getRowKey={getRowKey}
        storageName='financialRecord.marketData.list'
      />
    </Panel>
  );
}
