import cn from 'classnames';
import React, {CSSProperties} from 'react';
import styles from './index.css';

export const spinnerIntents = ['neutral', 'primary', 'warning', 'negative', 'positive', 'inverted'] as const;
export type SpinnerIntent = (typeof spinnerIntents)[number];

export const spinnerSizes = ['s', 'm', 'l'] as const;
export type SpinnerSize = (typeof spinnerSizes)[number];

type SpinnerBaseProps = {
  /**
   * Component size from the sizing grid. If the value is not set, the parent's
   * font-size is taken into account. You are not limited to size grid, if necessary,
   * you can set any size using the `font-size` property. In this case, you
   * do not need to specify the `size` prop.
   */
  size?: SpinnerSize;

  /**
   * Custom component color in any format, including the use of CSS variables.
   * To avoid unpredictable behavior, do not use this property in conjunction
   * with `intent`.
   */
  color?: CSSProperties['color'];

  /**
   * The appearance of the component corresponding to the intention. To avoid
   * unpredictable behavior, do not use this property in conjunction with `color`.
   */
  intent?: SpinnerIntent;
};

type SpinnerColorProps = {color?: CSSProperties['color']; intent?: never};
type SpinnerIntentProps = {color?: never; intent?: SpinnerIntent};

export type SpinnerProps = SpinnerBaseProps & (SpinnerColorProps | SpinnerIntentProps);

const classNameBySize: Record<SpinnerSize, string> = {
  s: styles.sizeS,
  m: styles.sizeM,
  l: styles.sizeL,
};

/**
 * A component for visual representation of the loading status.
 *
 * Used during data loading, slow processing of the request, or performing an action with a delay.
 *
 * [Design spec (v1.1)](<https://www.figma.com/file/Xm8CPTZ36ZQ9m77wk9Qh2p/B2B-Design-System-(NEW)?type=design&node-id=2763-27847&mode=dev>)
 */
export function Spinner({color, intent = 'neutral', size}: SpinnerProps) {
  return (
    <div
      className={cn(styles.spinner, styles[intent as keyof typeof styles], size && classNameBySize[size])}
      style={{
        // @ts-expect-error
        '--spinner-color': color,
      }}
    />
  );
}
