import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No resources',
    description: 'Resources table: empty',
  },
  loadingFailed: {
    defaultMessage: 'Failed to load resources',
    description: 'Resources table: error',
  },
});
