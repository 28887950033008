import React from 'react';
import styles from './styles.css';

type Props = {
  children: React.ReactNode;
};

function Item({children}: Props) {
  return (
    <>
      <span className={styles.item}>{children}</span>
      <span className={styles.dot}>&nbsp;• </span>
    </>
  );
}

export function DottedList({children}: Props) {
  return <span>{children}</span>;
}

DottedList.Item = Item;
