import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {getById} from 'domain/user/api/getById';
import {User, UserId} from 'domain/user/model';
import React, {useEffect} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {usersUrls} from 'routes/users/urls';
import {toaster} from 'services/toaster';

export function UserByIdPage() {
  const {id} = useParams<{id: UserId}>();
  const task = useAsyncTask<[], User>(() => getById({id}).catch(toaster.interceptThenThrowError), [id]);
  useEffect(() => {
    task.perform();
  }, [id]);

  return (
    <PageStateHandler data={task.result?.login} state={task.dataState}>
      {(login) => <Redirect to={usersUrls.user({login})} />}
    </PageStateHandler>
  );
}
