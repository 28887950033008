import {StyledExternalLink} from 'components/ui/StyledLink';
import {EmojiLandmark} from 'domain/officeMap/landmark/model';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './landmarkTooltip.css';
import {messages} from './messages';

type Props = {
  landmark: EmojiLandmark;
};

const renderContent = (landmark: EmojiLandmark) => {
  if (landmark.link) {
    return (
      <StyledExternalLink colored href={landmark.link}>
        {landmark.name}
      </StyledExternalLink>
    );
  }
  return <div className={styles.name}>{landmark.name}</div>;
};

export const LandmarkTooltip = ({landmark}: Props) => {
  const intl = useIntl();
  return (
    <div>
      {renderContent(landmark)}
      {landmark.disabled && <div className={styles.closedTag}>{intl.formatMessage(messages.closed)}</div>}
    </div>
  );
};
