import {Permission} from 'domain/permission/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {withSecurePermission} from 'hocs/withSecurePermission';
import {RegularLayout} from 'layouts/Regular';
import {OfferPage} from 'pages/Offer';
import {OfferCreatePage} from 'pages/OfferCreate';
import {OfferEditGeneralInfoPage} from 'pages/OfferEditGeneral';
import {OfferEnhancePage} from 'pages/OfferEnhance';
import {OffersPage} from 'pages/Offers';
import {OfferUpdatePage} from 'pages/OfferUpdate';
import {RouteConfig} from 'react-router-config';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {offerUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: offerUrlPatterns.root,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: withPermission(OffersPage, Permission.OFFER_READ),
      exact: true,
      path: offerUrlPatterns.root,
    },
    {
      component: withSecurePermission(OfferCreatePage, SecurePermission.OFFER_CREATE),
      exact: true,
      path: offerUrlPatterns.create,
    },
    {
      component: withPermission(OfferPage, Permission.OFFER_READ),
      exact: true,
      path: offerUrlPatterns.offer,
    },
    {
      component: OfferEditGeneralInfoPage,
      exact: true,
      path: offerUrlPatterns.editGeneralInfo,
    },
    {
      component: OfferUpdatePage,
      exact: true,
      path: offerUrlPatterns.update,
    },
    {
      component: withPermission(OfferEnhancePage, Permission.OFFER_ENHANCE),
      exact: true,
      path: offerUrlPatterns.enhance,
    },
    notFoundRouteConfig,
  ],
};
