import {isNotNullish, isNullish} from '@joomcode/deprecated-utils/function';
import {ValidationFunction} from '@joomcode/joom-form';

export enum PaymentFieldsError {
  PARTIALLY_FILLED = 'partiallyFilled',
}
type PaymentFieldValue = number | string | null | undefined;
export type PaymentFieldsValidatorOptions = {
  getValues: () => PaymentFieldValue[];
};

export const validatePaymentFields: ValidationFunction<
  PaymentFieldValue,
  PaymentFieldsValidatorOptions,
  PaymentFieldsError
> = (value: PaymentFieldValue, options: PaymentFieldsValidatorOptions) => {
  const values = options.getValues();
  if (values.some(isNotNullish) && values.some(isNullish)) {
    return {code: PaymentFieldsError.PARTIALLY_FILLED, options};
  }
  return undefined;
};
