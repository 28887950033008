import {bookRoom} from './bookRoom';
import {buildSeatsDataDownloadLink} from './buildSeatsDataDownloadLink';
import {generateToken} from './generateToken';
import {getAll} from './getAll';
import {updateSeat} from './updateSeat';

export const officesApi = {
  getAll,
  generateToken,
  bookRoom,
  buildSeatsDataDownloadLink,
  updateSeat,
};
