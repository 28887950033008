import {IntlShape} from 'react-intl';

export enum Language {
  AR = 'ar',
  HY = 'hy',
  AZ = 'az',
  BE = 'be',
  BG = 'bg',
  ZH = 'zh',
  CS = 'cs',
  DA = 'da',
  NL = 'nl',
  EN = 'en',
  ET = 'et',
  FR = 'fr',
  DE = 'de',
  EL = 'el',
  HE = 'he',
  IT = 'it',
  JA = 'ja',
  KK = 'kk',
  KO = 'ko',
  NB = 'nb',
  PL = 'pl',
  PT = 'pt',
  RO = 'ro',
  RU = 'ru',
  ES = 'es',
  SV = 'sv',
  TH = 'th',
  TR = 'tr',
  UK = 'uk',
  LV = 'lv',
}

export function getLanguageName(language: Language, intl: IntlShape) {
  return intl.formatDisplayName(language, {type: 'language'}) ?? language;
}
