import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Resource accesses',
    description: '[title] Resource summary panel title',
  },
  tabActive: {
    defaultMessage: 'Granted accesses',
    description: 'Resource role bindings panel: tab label',
  },
  tabRequests: {
    defaultMessage: 'New requests',
    description: 'Resource role bindings panel: tab label',
  },
  btnAddAccess: {
    defaultMessage: 'Add access',
    description: '[button] Resource role bindings panel: add access button',
  },
});
