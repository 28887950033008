import {TreeItemViewState, TreeViewInput, TreeViewState} from './types';

export function calculateExpanded<ItemId extends string>(itemsById: TreeViewState<ItemId>['itemsById']) {
  return Object.values(itemsById).some((item: TreeItemViewState<ItemId>) => item.expanded && !item.initialExpanded);
}

export function prepareInitialState<Item, ItemId extends string>({
  isExpanded,
  isExpandedByDefault,
  items,
  getItemId,
  getParentId,
  prepareItemIds = (ids: ItemId[]) => ids,
  subtreeParentId,
}: TreeViewInput<Item, ItemId>): TreeViewState<ItemId> {
  const allItemIds: Set<ItemId> = new Set(items.map(getItemId));
  const treeRootIds: ItemId[] = [];
  const subItemIdsById = {} as Record<ItemId, ItemId[]>;
  const itemsById = {} as Record<ItemId, Item>;
  const descendantsCountById = {} as Record<ItemId, number>;

  // Calculate subitem ids for every item id
  for (const item of items) {
    const id = getItemId(item);
    const parentId = getParentId(item);

    itemsById[id] = item;
    subItemIdsById[id] = subItemIdsById[id] ?? [];
    if (parentId && allItemIds.has(parentId)) {
      subItemIdsById[parentId] = subItemIdsById[parentId] ?? [];
      subItemIdsById[parentId].push(id);
    } else {
      treeRootIds.push(id);
    }
  }

  // Calculate descendants count for each item
  function calculateDescendantsCount(id: ItemId) {
    let count = 0;
    for (const subItemId of subItemIdsById[id]) {
      count += 1 + calculateDescendantsCount(subItemId);
    }
    return count;
  }

  for (const item of items) {
    const id = getItemId(item);
    descendantsCountById[id] = calculateDescendantsCount(id);
  }

  // Build view state
  const rootIds = prepareItemIds(
    subtreeParentId ? subItemIdsById[subtreeParentId] : treeRootIds,
    subItemIdsById,
    itemsById,
  );
  const itemStateById = {} as TreeViewState<ItemId>['itemsById'];
  let itemsCount = 0;
  function prepareState(ids: ItemId[], depth: number) {
    for (const id of ids) {
      itemsCount++;
      const subitemIds = prepareItemIds(subItemIdsById[id], subItemIdsById, itemsById);
      itemStateById[id] = {
        expanded: typeof isExpanded === 'function' ? isExpanded(depth, itemsById[id], subitemIds) : isExpanded,
        initialExpanded:
          typeof isExpandedByDefault === 'function'
            ? isExpandedByDefault(depth, itemsById[id], subitemIds)
            : isExpandedByDefault,
        subitemIds,
        descendantsCount: descendantsCountById[id],
      };
      prepareState(subitemIds, depth + 1);
    }
  }
  prepareState(rootIds, 0);

  return {
    itemsById: itemStateById,
    itemsCount,
    rootIds,
    expanded: calculateExpanded(itemStateById),
  };
}
