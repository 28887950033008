import React from 'react';
import {identity, isNullish} from '@joomcode/deprecated-utils/function';
import {FilterItem, Value} from '../../types';
import {SelectFilterOptions} from './types';
import {SelectControl} from './SelectControl';
import {SelectLabel} from './SelectLabel';

export function createGenericSelectFilter<T extends string, ExternalValue>(
  name: string,
  options: SelectFilterOptions<T> & {
    parseValue(innerValue: Value<T>): Value<ExternalValue>;
    formatValue(value: Value<ExternalValue>): Value<T>;
  },
): FilterItem<ExternalValue, SelectFilterOptions<T>> {
  return {
    name,
    options,
    isEmpty: (value) => {
      const formattedValue = options.formatValue(value);

      return isNullish(formattedValue) || formattedValue.length === 0;
    },
    renderControl: (props) => (
      <SelectControl
        {...props}
        onSubmit={(value) => props.onSubmit(options.parseValue(value))}
        value={options.formatValue(props.value)}
      />
    ),
    renderLabel: ({options: optionsWithMark, ...props}) => (
      <SelectLabel {...props} options={optionsWithMark} value={options.formatValue(props.value)} />
    ),
  };
}

export function createSelectFilter<T extends string>(
  name: string,
  options: SelectFilterOptions<T>,
): FilterItem<T, SelectFilterOptions<T>> {
  return createGenericSelectFilter(name, {
    ...options,
    parseValue: identity,
    formatValue: identity,
  });
}
