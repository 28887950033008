import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Panel} from '@joomcode/joom-ui/Panel';
import {useResources} from 'domain/resource/hooks/useResources';
import {ColumnId} from 'domain/resource/model/table/columnId';
import {ResourceCreationButton} from 'domain/resource/widgets/CreationButton';
import {ResourceCreationDialog} from 'domain/resource/widgets/CreationDialog';
import {ResourceTable} from 'domain/resource/widgets/Table';
import {ServiceId} from 'domain/service/model';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  serviceId: ServiceId;
  showCreateButton: boolean;
};

export function ServiceResourcesPanel({serviceId, showCreateButton}: Props) {
  const intl = useIntl();
  const filter = useMemo(
    () => ({
      serviceIds: [serviceId],
    }),
    [serviceId],
  );
  const {resources, resourcesCount, dataState, pagination, setOutdated} = useResources(filter);
  const creationDialog = usePopupState();

  return (
    <Panel>
      <Panel.Header
        withSeparator
        title={intl.formatMessage(messages.title)}
        toolbar={showCreateButton && <ResourceCreationButton onClick={creationDialog.open} />}
      />
      <Panel.Content>
        <ResourceTable
          resources={resources}
          dataState={dataState}
          columnIds={[ColumnId.DISPLAY_NAME, ColumnId.VALUE, ColumnId.OWNERS]}
          pagination={pagination}
          count={resourcesCount}
        />
      </Panel.Content>

      {creationDialog.isOpen && (
        <ResourceCreationDialog
          serviceId={serviceId}
          onClose={creationDialog.close}
          onSuccess={() => setOutdated(true)}
        />
      )}
    </Panel>
  );
}
