import {SecurePermission} from 'domain/permission/model/secure';

export enum TabId {
  MY = 'my',
  SUBORDINATE = 'subordinate',
  ANY = 'any',
}

export const securePermissionByTabId: Record<TabId, SecurePermission> = {
  [TabId.MY]: SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_SUBORDINATE,
  [TabId.SUBORDINATE]: SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_SUBORDINATE,
  [TabId.ANY]: SecurePermission.COMPENSATION_REVIEW_REQUEST_READ_ANY,
};
