import {intranetApi} from 'apiClient';
import {LegalInfo, legalInfoSchema} from 'domain/legalInfo/model';
import {LegalInfoDiff} from 'domain/legalInfo/model/diff';
import {UserId} from 'domain/user/model';

export type UpdateParams = {
  userId: UserId;
  legalInfo: LegalInfoDiff;
};

export const setLegalInfo = async ({userId, legalInfo}: UpdateParams): Promise<LegalInfo> => {
  const {data} = await intranetApi.post('/v1/users/legalInfo/set', legalInfo, {params: {userId}});

  return legalInfoSchema.runWithException(data);
};
