import {defineMessages} from 'react-intl';
import {ShirtSize} from '.';

export const shirtSizeLabels = defineMessages<ShirtSize>({
  [ShirtSize.XS]: {
    defaultMessage: 'XS',
    description: 'Shirt size',
  },
  [ShirtSize.S]: {
    defaultMessage: 'S',
    description: 'Shirt size',
  },
  [ShirtSize.M]: {
    defaultMessage: 'M',
    description: 'Shirt size',
  },
  [ShirtSize.L]: {
    defaultMessage: 'L',
    description: 'Shirt size',
  },
  [ShirtSize.XL]: {
    defaultMessage: 'XL',
    description: 'Shirt size',
  },
  [ShirtSize.XXL]: {
    defaultMessage: '2XL',
    description: 'Shirt size',
  },
  [ShirtSize.XXXL]: {
    defaultMessage: '3XL',
    description: 'Shirt size',
  },
});
