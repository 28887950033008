import {DataState} from '@joomcode/deprecated-utils/dataState';
import {getDisplayName} from '@joomcode/deprecated-utils/react/getDisplayName';
import {buildKeycloakStartUrl} from 'api/auth/buildKeycloakStartUrl';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {$locale} from 'domain/locale/stores/main/state';
import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {loadSelfFx} from 'domain/self/stores/main';
import {$isAuthenticated, $self} from 'domain/self/stores/main/state';
import {UserFull} from 'domain/user/model';
import {useStore} from 'effector-react';
import React, {useContext, useEffect} from 'react';
import {RouteProps} from 'react-router-dom';
import {useAnalytics} from 'services/analytics/useAnalytics';
import {Feature, useFeature} from 'services/features';
import {toaster} from 'services/toaster';
import {UsersnapContext, loadUsersnapSpace} from 'services/usersnap';

type Props = RouteProps;

export function withAuth(Component: React.ComponentType<Props & {selfUser: UserFull}>) {
  function PrivateRoute(props: Props) {
    const {dataState} = useStore($self);
    const isAuthenticated = useStore($isAuthenticated);
    const [usersnapApi, setUsersnapApi] = useContext(UsersnapContext);
    const selfUser = useSelfUser();
    const {gtag} = useAnalytics();
    const usersnapFeature = useFeature(Feature.USERSNAP);
    const {locale} = useStore($locale);

    const authLink = new URL(buildKeycloakStartUrl());
    authLink.search = new URLSearchParams({redirectUri: window.location.href}).toString();

    useEffect(() => {
      if (!isAuthenticated && dataState === DataState.IDLE) {
        loadSelfFx()
          .then(({user}) => {
            gtag.setUserProperties(user.id);
            if (!usersnapApi && usersnapFeature.isAvailable) loadUsersnapSpace(setUsersnapApi, user, locale);
          })
          .catch((error) => {
            if (error instanceof ClientError && error.status === ClientErrorStatus.UNAUTHORIZED) {
              window.location.href = authLink.toString();
              return;
            }
            toaster.interceptThenThrowError(error);
          });
      }
    }, [authLink, dataState, isAuthenticated, setUsersnapApi, locale]);

    return (
      <PageStateHandler data={selfUser} state={dataState}>
        {(selfUserData) => <Component {...props} selfUser={selfUserData} />}
      </PageStateHandler>
    );
  }

  PrivateRoute.displayName = `withAuth(${getDisplayName(Component)})`;
  return PrivateRoute;
}
