import React, {FC} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.css';

const messages = defineMessages({
  hint: {
    id: 'empty-dash-hint',
    description: 'The hint for dash which indicates the lack of data',
    defaultMessage: 'No data',
  },
});

export const EmptyDash: FC = () => {
  const intl = useIntl();

  return <span className={styles.dash} title={intl.formatMessage(messages.hint)} />;
};
