import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  titleError: {
    defaultMessage: 'Something Went Wrong',
    description: '[title] Error placeholder title',
    id: 'joom-ui-placeholder-title-error',
  },
  titleEmpty: {
    defaultMessage: 'Nothing Found',
    description: '[title] Empty state placeholder title',
    id: 'joom-ui-placeholder-title-empty',
  },
  titleRestricted: {
    defaultMessage: 'Access Restricted',
    description: '[title] Restricted placeholder title',
    id: 'joom-ui-placeholder-title-restricted',
  },
  descriptionRestricted: {
    defaultMessage: 'You have no permission to view this page',
    description: '[title] Restricted placeholder description',
    id: 'joom-ui-placeholder-description-restricted',
  },
});
