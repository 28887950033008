import {boolean, ExtractSchemaType, object, oneOfEnum, string} from '@joomcode/deprecated-utils/jsonValidation';
import {LevelCode} from 'domain/levelCode/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';

export const marketDataRecordIdSchema = idSchema;

export const marketDataRecordSchema = object({
  active: boolean(),
  levelCode: oneOfEnum(LevelCode),
  id: marketDataRecordIdSchema,
  effectiveDate: formattedDateSchema,
  userFunction: string(),
});

export type MarketDataRecordId = ExtractSchemaType<typeof marketDataRecordIdSchema>;
export type MarketDataRecord = ExtractSchemaType<typeof marketDataRecordSchema>;
