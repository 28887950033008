import {KeyboardEventHandler, MouseEventHandler, RefObject} from 'react';

export const TAB_ID_ATTRIBUTE_NAME = 'data-tab-id';
export const TAB_CLOSABLE_ATTRIBUTE_NAME = 'data-tab-closable';

export type TabId = string;

export type TabProps = {
  [TAB_ID_ATTRIBUTE_NAME]: TabId;
  'aria-controls': string;
  'aria-selected': boolean;
  id: string;
  role: string;
  tabIndex: number;
};

export type TabCloseButtonProps = {
  tabIndex: number;
  onClick: MouseEventHandler<HTMLElement>;
};

export type TabListProps = {
  'aria-orientation': 'vertical' | 'horizontal';
  ref: RefObject<HTMLUListElement>;
  role: string;
  onClick: MouseEventHandler<HTMLElement>;
  onKeyDown: KeyboardEventHandler<HTMLElement>;
};

export type TabPanelProps = {
  id: string;
  'aria-labelledby': string;
  role: string;
  tabIndex: number;
};
