import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  root: {
    defaultMessage: 'Runtime config',
    description: '[title] Page title',
  },
  rtconfCreate: {
    defaultMessage: 'Create runtime config item',
    description: '[title] Page title',
  },
  rtconfItem: {
    defaultMessage: '{itemKey} runtime config item',
    description: '[title] Page title',
  },
});
