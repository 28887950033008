import {defineMessages} from 'react-intl';
import {OfferCompensation} from '.';

export const compensationFieldLabels = defineMessages<keyof OfferCompensation>({
  userFunction: {
    defaultMessage: 'Function',
    description: 'Offer field label',
  },
  levelCode: {
    defaultMessage: 'Level code',
    description: 'Offer field label',
  },
  salary: {
    defaultMessage: 'Salary',
    description: 'Offer field label',
  },
  crResult: {
    defaultMessage: 'CR',
    description: 'Offer field label',
  },
  regularBonus: {
    defaultMessage: 'Regular bonus',
    description: 'Offer field label',
  },
  signOnBonus: {
    defaultMessage: 'Sign-on bonus',
    description: 'Offer field label',
  },
  relocationBonus: {
    defaultMessage: 'Relocation bonus',
    description: 'Offer field label',
  },
  commentForHrAdmin: {
    defaultMessage: 'Comment for HR Admins',
    description: 'Offer field label',
  },
  furtherSalary: {
    defaultMessage: 'Further salary',
    description: 'Offer field label',
  },
  employmentRate: {
    defaultMessage: 'Employment rate',
    description: 'Offer field label',
  },
});
