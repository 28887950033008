import {ReactComponent as PinIcon} from '@joomcode/joom-ui/icons/core/geoPin.svg';
import {ReactComponent as PhoneIcon} from '@joomcode/joom-ui/icons/core/phoneHandset.svg';
import {ReactComponent as SlackIcon} from '@joomcode/joom-ui/icons/social/slack.svg';
import {LinkWithIcon} from 'components/ui/LinkWithIcon';
import React, {MouseEventHandler} from 'react';
import {useIntl} from 'react-intl';
import {ReactComponent as EnvelopeIcon} from './icons/envelope.svg';
import {ReactComponent as GithubIcon} from './icons/github.svg';
import {ReactComponent as NotionIcon} from './icons/notion.svg';
import {iconLabels} from './messages';
import {SocialContactType} from './types';

type Props = React.PropsWithChildren<{
  coloredLink?: boolean;
  externalLink?: boolean;
  iconClassName?: string;
  link?: string;
  type: SocialContactType;
  onClick?: MouseEventHandler<HTMLElement>;
}>;

const iconComponentByContactType: Record<SocialContactType, React.ReactElement> = {
  email: <EnvelopeIcon />,
  location: <PinIcon />,
  notion: <NotionIcon />,
  phone: <PhoneIcon />,
  slack: <SlackIcon />,
  github: <GithubIcon />,
};

export function SocialContact({children, type, ...restProps}: Props) {
  const intl = useIntl();

  return (
    <LinkWithIcon
      icon={iconComponentByContactType[type]}
      iconLabel={intl.formatMessage(iconLabels[type])}
      {...restProps}
    >
      {children}
    </LinkWithIcon>
  );
}
