import {DataState} from '@joomcode/deprecated-utils/dataState';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {
  createRegularBonusRecordFx,
  deleteRegularBonusRecordFx,
  getMyRegularBonusRecordsFx,
  getRegularBonusRecordsFx,
  getSubordinateRegularBonusRecordsFx,
  replaceRegularBonusRecordFx,
  updateRegularBonusRecordFx,
} from '.';

type State = {
  byUserId: Record<UserId, RegularBonusRecord[]>;
  dataStateByUserId: Record<UserId, DataState>;
};

export const $regularBonusRecords = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on([getRegularBonusRecordsFx, getMyRegularBonusRecordsFx, getSubordinateRegularBonusRecordsFx], (state, userId) => {
    return {
      ...state,
      dataStateByUserId: {
        ...state.dataStateByUserId,
        [userId]: DataState.LOADING,
      },
    };
  })
  .on(
    [getRegularBonusRecordsFx.fail, getMyRegularBonusRecordsFx.fail, getSubordinateRegularBonusRecordsFx.fail],
    (state, {params: userId}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.FAILED,
        },
      };
    },
  )
  .on(
    [getRegularBonusRecordsFx.done, getMyRegularBonusRecordsFx.done, getSubordinateRegularBonusRecordsFx.done],
    (state, {params: userId, result}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: result,
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADED,
        },
      };
    },
  )
  .on(
    [createRegularBonusRecordFx.done, replaceRegularBonusRecordFx.done, updateRegularBonusRecordFx.done],
    (state, {params: {userId}, result}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: result,
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: DataState.LOADED,
        },
      };
    },
  )
  .on(deleteRegularBonusRecordFx.done, (state, {params: {userId, id}}) => {
    if (state.dataStateByUserId[userId] !== DataState.LOADED) {
      return state;
    }
    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: state.byUserId[userId].filter((record) => record.id !== id),
      },
    };
  });
