import {getDisplayName} from '@joomcode/deprecated-utils/react/getDisplayName';
import React from 'react';
import {RouteProps} from 'react-router-dom';
import {inVpn} from 'services/acl/inVpn';

export function withVpn<T>(Component: React.ComponentType<T>, FallbackComponent?: React.ComponentType) {
  function PrivateComponent(props: T & RouteProps) {
    if (!inVpn()) {
      return FallbackComponent ? <FallbackComponent /> : null;
    }

    return <Component {...props} />;
  }

  PrivateComponent.displayName = `withVpn(${getDisplayName(Component)})`;
  return PrivateComponent;
}
