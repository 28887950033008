import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Select} from '@joomcode/joom-ui/Select';
import {SetDocumentsStatusConfig} from 'domain/stockOption/agreement/api/setDocumentsStatus';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {StockOptionAgreementDocumentsStatus} from 'domain/stockOption/agreement/model/documentsStatus';
import {formatDocumentsStatus} from 'domain/stockOption/agreement/model/documentsStatus/formatter';
import {setDocumentsStatusFx} from 'domain/stockOption/agreement/stores/main';
import React, {useCallback, useMemo} from 'react';
import {Field, Form} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {labels, messages} from './messages';

type Props = {
  agreement: StockOptionAgreement;
  onClose(): void;
};

type FormState = Partial<Pick<SetDocumentsStatusConfig, 'documentsStatus'>>;

export function UpdateDocumentsStatusDialog({agreement, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const renderDocumentsStatusOption = useCallback(
    (documentsStatus: StockOptionAgreementDocumentsStatus) => formatDocumentsStatus(documentsStatus, intl),
    [intl],
  );
  const onSubmit = useCallback(
    ({documentsStatus}: FormState) => {
      if (!documentsStatus) {
        return Promise.reject().catch(() => toaster.error(intl.formatMessage(messages.error)));
      }

      return setDocumentsStatusFx({
        id: agreement.id,
        userId: agreement.user.id,
        documentsStatus,
      })
        .then(() => {
          onClose();
          toaster.success(intl.formatMessage(messages.success));
        })
        .catch(toaster.interceptThenThrowError);
    },
    [onClose, agreement],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 24rem)'>
      <Form<FormState> onSubmit={onSubmit}>
        {({handleSubmit, submitting}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <form onSubmit={handleSubmit} id={formId}>
                <Field<StockOptionAgreementDocumentsStatus>
                  name='documentsStatus'
                  initialValue={agreement.documentsStatus}
                  validate={validateFilled}
                >
                  {({input}) => (
                    <FormControl label={intl.formatMessage(labels.documentsStatus)} required>
                      {(formControlProps) => (
                        <Select<StockOptionAgreementDocumentsStatus>
                          items={getEnumValues(StockOptionAgreementDocumentsStatus)}
                          getItemKey={identity}
                          itemToString={renderDocumentsStatusOption}
                          renderItem={renderDocumentsStatusOption}
                          {...formControlProps}
                          {...input}
                        />
                      )}
                    </FormControl>
                  )}
                </Field>
              </form>
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button form={formId} kind='primary' intent='primary' type='submit' loading={submitting}>
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
