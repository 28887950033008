import {ExtractSchemaType, object, positiveNumber, string} from '@joomcode/deprecated-utils/jsonValidation';
import {Size} from '@joomcode/deprecated-utils/math/size';

export const imageSchema = object({
  height: positiveNumber(),
  url: string(),
  width: positiveNumber(),
});

export type Image = ExtractSchemaType<typeof imageSchema>;
export type ImageSize = Partial<Size>;
