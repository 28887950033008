import {DataState} from '@joomcode/deprecated-utils/dataState';
import {mimeType} from '@joomcode/deprecated-utils/file/mime';
import {FileDropArea} from '@joomcode/joom-ui/FileDropArea';
import {IntentionedText} from '@joomcode/joom-ui/IntentionedText';
import {Panel} from '@joomcode/joom-ui/Panel';
import {financialRecordsBulkUploadApi} from 'domain/financialRecord/api';
import {FinancialBulkUploadResult} from 'domain/financialRecord/model';
import {useFileUpload} from 'hooks/useFileUpload';
import {BulkUploadStatus} from 'models/system/bulkUpload';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {RowErrors} from '../RowErrors';
import {getErrorFromImportResult} from './getErrorFromImportResult';
import {errorMessages, messages} from './messages';
import styles from './styles.css';

const ACCEPTED_MIME_TYPES = [mimeType.excel.xlsx];

type Props = {
  uploadResult?: FinancialBulkUploadResult;
  onUpload(result: FinancialBulkUploadResult | undefined): void;
};

export function FinancialRecordsBulkUploadPanel({uploadResult, onUpload}: Props) {
  const intl = useIntl();
  const [error, setError] = useState<string | undefined>(undefined);
  const onError = useCallback(({message}: Error) => setError(message), [setError]);

  const {onAttach, dataState} = useFileUpload<void, FinancialBulkUploadResult>({
    upload: financialRecordsBulkUploadApi.create,
    params: undefined,
    onComplete: onUpload,
    onError,
  });

  useEffect(() => {
    setError(getErrorFromImportResult(uploadResult, intl));
  }, [uploadResult, setError, intl]);

  const onChange = useCallback(
    (files: File[]) => {
      const file = files[0];
      if (!file) {
        return;
      }
      if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
        setError(intl.formatMessage(errorMessages.wrongFileType));
        return;
      }
      setError(undefined);
      onAttach(file);
    },
    [onAttach, setError, intl],
  );

  return (
    <Panel className={styles.root} title={intl.formatMessage(messages.title)}>
      {!uploadResult && <Panel.Content withPadding>{intl.formatMessage(messages.annotation)}</Panel.Content>}
      {error && (
        <Panel.Content withPadding>
          <IntentionedText intent='negative'>{error}</IntentionedText>
        </Panel.Content>
      )}
      {uploadResult?.status === BulkUploadStatus.CREATED_WITH_ERRORS && (
        <Panel.Content withPadding>
          <RowErrors uploadResult={uploadResult} />
        </Panel.Content>
      )}
      {uploadResult?.status === BulkUploadStatus.CREATED && (
        <Panel.Content withPadding>{intl.formatMessage(messages.success)}</Panel.Content>
      )}
      {!uploadResult && (
        <Panel.Content withPadding>
          <FileDropArea
            accept={ACCEPTED_MIME_TYPES}
            multiple={false}
            onChange={onChange}
            processing={dataState === DataState.LOADING}
          />
        </Panel.Content>
      )}
    </Panel>
  );
}
