import {defineMessages} from 'react-intl';
import {MaritalStatus} from '.';

export const maritalStatusLabels = defineMessages<MaritalStatus>({
  [MaritalStatus.SINGLE]: {
    defaultMessage: 'Single',
    description: 'Marital status',
  },
  [MaritalStatus.NON_MARITAL_PARTNERSHIP]: {
    defaultMessage: 'Non-marital partnership',
    description: 'Marital status',
  },
  [MaritalStatus.MARRIED_WITH_ONE_INCOME]: {
    defaultMessage: 'Married with 1 income',
    description: 'Marital status',
  },
  [MaritalStatus.MARRIED_WITH_TWO_INCOME]: {
    defaultMessage: 'Married with 2 income',
    description: 'Marital status',
  },
  [MaritalStatus.WIDOWED]: {
    defaultMessage: 'Widowed',
    description: 'Marital status',
  },
  [MaritalStatus.DIVORCED]: {
    defaultMessage: 'Divorced',
    description: 'Marital status',
  },
});
