import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  frozen: {
    defaultMessage: 'From {date}',
    description: 'Stock option agreement freeze tooltip content',
  },
  willBeFrozen: {
    defaultMessage: 'Agreement will be frozen on {date}',
    description: 'Stock option agreement freeze tooltip content',
  },
});
