import {DataState, getConsolidatedAccessDependentDataState} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Panel} from '@joomcode/joom-ui/Panel';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {Tab, Tabs} from '@joomcode/joom-ui/Tabs';
import {SensitiveDataSwitch} from 'components/ui/SensitiveDataSwitch';
import {useHistoryTab} from 'domain/jobContract/hooks/useHistoryTab';
import {useOneTimeBonusTab} from 'domain/jobContract/hooks/useOneTimeBonusTab';
import {useSalaryTab} from 'domain/jobContract/hooks/useSalaryTab';
import {JobContract} from 'domain/jobContract/model';
import {JobContractStatus} from 'domain/jobContract/model/status';
import {JobContractStatusBadge} from 'domain/jobContract/widgets/StatusBadge';
import {useOneTimeBonusesAvailability} from 'domain/oneTimeBonusRecord/hooks/useOneTimeBonusesAvailability';
import {useOneTimeBonusReadAccess} from 'domain/oneTimeBonusRecord/hooks/useOneTimeBonusReadAccess';
import {Permission} from 'domain/permission/model';
import {useSalariesAvailability} from 'domain/salaryRecord/hooks/useSalariesAvailability';
import {useUserPrivateInfo} from 'domain/user/hooks/useUserPrivateInfo';
import {UserFull} from 'domain/user/model';
import {setMainLegalEntityFx} from 'domain/user/stores/privateInfo';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

export enum TabId {
  HISTORY = 'history',
  SALARY = 'salary',
  ONE_TIME_BONUS = 'oneTimeBonus',
}

export type JobContractPanelTab<Record> = {
  addon: React.ReactNode;
  content: React.ReactNode;
  dialog: React.ReactNode;
  records: Record[];
  dataState: DataState;
};

type Props = {
  jobContract: JobContract;
  userHasMultipleContracts: boolean;
  user: UserFull;
};

export function JobContractPanel({jobContract, userHasMultipleContracts, user}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const showPrimaryLabel = userHasMultipleContracts && jobContract.isPrimary;
  const activeRecord = jobContract.jobContractRecords.find(({active}) => active);
  const currentStatus = activeRecord?.status;
  const legalEntityId = jobContract.legalEntity.id;
  const mainLegalEntityUpdateTask = useAsyncTask(
    () =>
      setMainLegalEntityFx({legalEntityId, userId: user.id})
        .then(() => toaster.success(intl.formatMessage(messages.mainLegalEntityUpdateSuccess)))
        .catch(toaster.interceptThenThrowError),
    [legalEntityId, user.id, intl],
  );
  const isSensitiveDataHidden = useBooleanState(true);
  const tabsWithSensitiveData: Set<TabId> = useMemo(() => new Set([TabId.SALARY, TabId.ONE_TIME_BONUS]), []);

  const historyTab = useHistoryTab({user, legalEntityId, jobContract});
  const salaryTab = useSalaryTab({user, legalEntityId, isSensitiveDataHidden: isSensitiveDataHidden.value});
  const oneTimeBonusTab = useOneTimeBonusTab({user, legalEntityId, isSensitiveDataHidden: isSensitiveDataHidden.value});

  const canSeeOneTimeBonuses = useOneTimeBonusesAvailability(user, oneTimeBonusTab.records);
  const canRequestOneTimeBonus = useOneTimeBonusReadAccess(user);
  const canSeeSalaries = useSalariesAvailability(user);
  const canSetMainLegalEntity = useUserPrivateInfo(user.id, {enabled: false}).canEdit;
  const canEditHistory = acl.hasPermission(Permission.JOB_CONTRACT_WRITE);

  const tabs = useMemo(() => {
    const result: Tab<TabId>[] = [
      {
        id: TabId.HISTORY,
        label: intl.formatMessage(messages.tabHistory),
        content: historyTab.content,
      },
    ];
    if (canSeeSalaries) {
      result.push({
        id: TabId.SALARY,
        label: intl.formatMessage(messages.tabSalary),
        content: salaryTab.content,
      });
    }
    if (canSeeOneTimeBonuses) {
      result.push({
        id: TabId.ONE_TIME_BONUS,
        label: intl.formatMessage(messages.tabOneTimeBonus),
        content: oneTimeBonusTab.content,
      });
    }
    return result;
  }, [intl, canSeeSalaries, canSeeOneTimeBonuses, historyTab.content, salaryTab.content, oneTimeBonusTab.content]);

  const tabAddonByTabId = useMemo(
    () => ({
      [TabId.HISTORY]: historyTab.addon,
      [TabId.SALARY]: salaryTab.addon,
      [TabId.ONE_TIME_BONUS]: oneTimeBonusTab.addon,
    }),
    [historyTab.addon, salaryTab.addon, oneTimeBonusTab.addon],
  );
  const [activeTabId, setActiveTabId] = useState<TabId>(TabId.HISTORY);

  return (
    <Panel
      title={
        <div className={styles.title}>
          {intl.formatMessage(messages.title, {legalEntityName: jobContract.legalEntity.title, showPrimaryLabel})}
          {currentStatus && (
            <div className={styles.status}>
              <JobContractStatusBadge status={currentStatus} />
            </div>
          )}
        </div>
      }
      toolbarTop={
        <ButtonGroup spaced size='m'>
          {canSetMainLegalEntity &&
            !jobContract.isPrimary &&
            currentStatus &&
            currentStatus !== JobContractStatus.TERMINATED && (
              <Button
                kind='secondary'
                intent='neutral'
                onClick={mainLegalEntityUpdateTask.perform}
                loading={mainLegalEntityUpdateTask.isPerforming}
              >
                {intl.formatMessage(messages.setMainLegalEntityButton)}
              </Button>
            )}
          {canEditHistory && tabs.length === 1 && historyTab.addon}
        </ButtonGroup>
      }
      withSeparator
    >
      <StateHandler
        data={oneTimeBonusTab.records}
        state={getConsolidatedAccessDependentDataState([oneTimeBonusTab.dataState, Boolean(canRequestOneTimeBonus)])}
      >
        {() => (
          <>
            {tabs.length > 1 ? (
              <Tabs<TabId>
                renderActiveTabPanelOnly
                activeTabId={activeTabId}
                onChange={setActiveTabId}
                tabs={tabs}
                addonRight={
                  <div className={styles.toolbar}>
                    <ButtonGroup spaced size='m'>
                      {tabsWithSensitiveData.has(activeTabId) && (
                        <SensitiveDataSwitch
                          isDataHidden={isSensitiveDataHidden.value}
                          onClick={isSensitiveDataHidden.toggle}
                        />
                      )}
                      {tabAddonByTabId[activeTabId]}
                    </ButtonGroup>
                  </div>
                }
              />
            ) : (
              tabs[0].content
            )}
            {historyTab.dialog}
            {salaryTab.dialog}
            {oneTimeBonusTab.dialog}
          </>
        )}
      </StateHandler>
    </Panel>
  );
}
