import {ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {ContractType} from 'domain/jobContract/model/type';
import {legalEntitySchema} from 'domain/legalEntity/model';
import {TimeOffPolicy} from 'domain/timeOff/policy/model';

export const offerJobContractSchema = object({
  furtherSalaryLegalEntity: optional(legalEntitySchema),
  legalEntity: legalEntitySchema,
  comment: optional(string()),
  contractType: optional(oneOfEnum(ContractType)),
  countryOfTimeOffPolicy: optional(oneOfEnum(TimeOffPolicy)),
  positionTitle: optional(string()),
});

export type OfferJobContract = ExtractSchemaType<typeof offerJobContractSchema>;
