import {
  boolean,
  ExtractSchemaType,
  intersection,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {CountryCode} from 'domain/countryCode/model';
import {currencySchema} from 'domain/currency/model';
import {Frequency} from 'domain/frequency/model';
import {legalEntitySchema} from 'domain/legalEntity/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';

export const salaryRecordIdSchema = idSchema;

export const salaryRecordSchema = object({
  active: boolean(),
  amount: number(),
  comment: optional(string()),
  currency: currencySchema,
  employmentRate: number(),
  endDate: optional(formattedDateSchema),
  frequency: oneOfEnum(Frequency),
  id: salaryRecordIdSchema,
  legalEntity: legalEntitySchema,
  reason: optional(string()),
  region: oneOfEnum(CountryCode),
  startDate: formattedDateSchema,
});

export const extendedSalaryRecordSchema = intersection(
  salaryRecordSchema,
  object({
    specialArrangements: optional(string()),
  }),
);

export type SalaryRecordId = ExtractSchemaType<typeof salaryRecordIdSchema>;
export type SalaryRecord = ExtractSchemaType<typeof salaryRecordSchema>;
export type ExtendedSalaryRecord = ExtractSchemaType<typeof extendedSalaryRecordSchema>;
export type AnySalaryRecord = SalaryRecord | ExtendedSalaryRecord;
