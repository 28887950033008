import {isNotNullish} from '@joomcode/deprecated-utils/function';
import React, {ReactNode} from 'react';
import {HumanDate} from '../../HumanDate';
import {CellEmpty} from '../components/CellEmpty';
import {Column} from '../types';
import {Nullable} from './types';

type DateRange = [Date, Date];

type Options<Item, NestedItem = never> = Omit<Column<Item, NestedItem>, 'render' | 'renderNested'> & {
  getValue: (item: Item) => Nullable<DateRange>;
  render?: (item: Item, formattedValue: React.ReactNode) => React.ReactNode;
  getNestedValue?: (nestedItem: NestedItem) => Nullable<DateRange>;
  renderNested?: (nestedItem: NestedItem, item: Item, formattedValue: ReactNode) => ReactNode;
};

function formatDateRage(range: DateRange): ReactNode {
  return (
    <>
      <HumanDate value={range[0]} format='short' />
      {' — '}
      <HumanDate value={range[1]} format='short' />
    </>
  );
}

export function createColumnDateRange<Item, NestedItem>({
  getNestedValue,
  getValue,
  render,
  renderNested,
  ...rest
}: Options<Item, NestedItem>): Column<Item, NestedItem> {
  return {
    render: (item) => {
      const value = getValue(item);

      if (isNotNullish(value)) {
        return render ? render(item, formatDateRage(value)) : formatDateRage(value);
      }
      return <CellEmpty />;
    },

    renderNested: (nestedItem, item) => {
      const value = getNestedValue?.(nestedItem);

      if (isNotNullish(value)) {
        return renderNested ? renderNested(nestedItem, item, formatDateRage(value)) : formatDateRage(value);
      }
      return <CellEmpty />;
    },

    ...rest,
  };
}
