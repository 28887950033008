import {CompensationReviewRequestPage} from 'pages/CompensationReviewRequest';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/hrConsole/titles';
import {hrConsoleUrls} from 'routes/hrConsole/urls';

export function HrConsoleCompensationReviewRequestPage() {
  const intl = useIntl();

  return (
    <CompensationReviewRequestPage
      breadcrumbs={[
        {title: intl.formatMessage(pageTitles.hrConsole), href: hrConsoleUrls.compensationReviewRequests()},
      ]}
      title={intl.formatMessage(pageTitles.compensationReviewRequest)}
    />
  );
}
