/* eslint-disable @typescript-eslint/naming-convention */
import composeRefs from '@seznam/compose-react-refs';
import {createLocator, type Locator, type Mark} from 'create-locator';
import React from 'react';
import {Tippy, TippyProps} from '../Tippy';
import {Heading} from './Heading';
import styles from './index.css';
import {Paragraph} from './Paragraph';

export type TooltipFaqLocator = Locator<void>;

export type TooltipFaqPlacement = TippyProps['placement'];

export type TooltipFaqProps = {
  content: React.ReactNode;
  children: TippyProps['children'];
  interactive?: TippyProps['interactive'];
  placement?: TooltipFaqPlacement;
  enabled?: TippyProps['enabled'];
  onShow?: TippyProps['onShow'];
  onHide?: TippyProps['onHide'];
  trigger?: TippyProps['trigger'];
  maxWidth?: TippyProps['maxWidth'];
  zIndex?: TippyProps['zIndex'];
  showOnInit?: TippyProps['showOnInit'];
} & Partial<Mark<TooltipFaqLocator>>;

interface CompoundedComponent extends React.ForwardRefExoticComponent<TooltipFaqProps & React.RefAttributes<unknown>> {
  Heading: typeof Heading;
  Paragraph: typeof Paragraph;
}

export const TooltipFaq = React.forwardRef<unknown>(
  (
    {
      content,
      enabled = true,
      children,
      interactive = true,
      onShow,
      onHide,
      placement = 'auto',
      trigger = 'mouseenter',
      maxWidth,
      zIndex,
      showOnInit,
      ...rest
    }: TooltipFaqProps,
    ref,
  ) => {
    const locator = createLocator(rest);
    const onlyChildren = React.Children.only(children);

    return (
      <Tippy
        trigger={trigger}
        enabled={enabled}
        boundary='viewport'
        content={
          <section className={styles.content} {...locator()}>
            {content}
          </section>
        }
        delay={[
          100, // avoid irrelevant flickering on unintentional mouseover
          0,
        ]}
        duration={500}
        interactive={interactive}
        placement={placement}
        showOnInit={showOnInit}
        {...{...(onShow && {onShow}), ...(onHide && {onHide}), ...(maxWidth && {maxWidth}), ...(zIndex && {zIndex})}} // undefined overrides original tippy.js handler and causes error
      >
        {React.cloneElement(onlyChildren, {
          // @ts-ignore
          ref: composeRefs(ref, onlyChildren.ref),
        })}
      </Tippy>
    );
  },
) as CompoundedComponent;

TooltipFaq.Heading = Heading;
TooltipFaq.Paragraph = Paragraph;
