import {Menu} from '@joomcode/joom-ui/Menu';
import {MenuButton} from 'components/ui/MenuButton';
import {JobContractRecord, JobContractRecordId} from 'domain/jobContract/model/record';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export type Props = {
  jobContractRecord: JobContractRecord;
  onDelete?: (id: JobContractRecordId) => void;
  onEdit?: (record: JobContractRecord) => void;
};

export function JobContractRecordMenu({jobContractRecord, onDelete, onEdit}: Props) {
  const intl = useIntl();
  return (
    <Menu ariaLabel={intl.formatMessage(messages.menuAriaLabel)} disclosure={<MenuButton />}>
      {onEdit && <Menu.Item onClick={() => onEdit(jobContractRecord)}>{intl.formatMessage(messages.edit)}</Menu.Item>}
      {onDelete && (
        <Menu.Item onClick={() => onDelete(jobContractRecord.id)}>{intl.formatMessage(messages.delete)}</Menu.Item>
      )}
    </Menu>
  );
}
