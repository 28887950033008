import {objectKeys} from '@joomcode/deprecated-utils/object/keys';
import {normalizeSearchString} from '@joomcode/deprecated-utils/string/normalizeSearchString';
import {DataFilterConfig} from './types';

export function isDataFilterEmpty<T extends Record<string, unknown>>(filterConfig: DataFilterConfig<T>, values: T) {
  return objectKeys(filterConfig).every((name) => {
    return !(name in values) || filterConfig[name].isEmpty(values[name]);
  });
}

export function containsSearchString(sourceString: string, searchString: string) {
  return normalizeSearchString(sourceString).includes(normalizeSearchString(searchString));
}
