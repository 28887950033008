import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonCollapse: {
    defaultMessage: 'Collapse',
    description: '[button] Tree header: collapse all items',
  },
  buttonExpand: {
    defaultMessage: 'Expand',
    description: '[button] Tree header: expand all items',
  },
});
