import React from 'react';
import {identity, isNullish} from '@joomcode/deprecated-utils/function';
import {TextControl} from './TextControl';
import type {FilterItem, Value} from '../../types';
import {TextLabel} from './TextLabel';
import type {Options} from './types';

type InnerValue = string;

export type GenericTextFilterOptions<ExternalValue> = Options & {
  parseValue(innerValue: Value<InnerValue>): Value<ExternalValue>;
  formatValue(value: Value<ExternalValue>): Value<InnerValue>;
};

export function createGenericTextFilter<ExternalValue>(
  name: string,
  options: GenericTextFilterOptions<ExternalValue>,
): FilterItem<ExternalValue, Options> {
  return {
    name,
    options,
    isEmpty: (value) => {
      const formattedValue = options.formatValue(value);

      return isNullish(formattedValue) || formattedValue.length === 0;
    },
    renderControl: (props) => (
      <TextControl
        {...props}
        mask={options?.mask}
        maskPlaceholder={options?.maskPlaceholder}
        onSubmit={(value) => props.onSubmit(options.parseValue(value))}
        value={options.formatValue(props.value)}
      />
    ),
    renderLabel: (props) => <TextLabel {...props} value={options.formatValue(props.value)} />,
  };
}

export function createTextFilter(name: string, options: Options = {type: 'contains'}): FilterItem<InnerValue, Options> {
  return createGenericTextFilter(name, {
    ...options,
    parseValue: identity,
    formatValue: identity,
  });
}
