import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  userPaneTitle: {
    defaultMessage: 'Profile',
    description: '[title] User pane title',
  },
  downloadDirectoryData: {
    defaultMessage: 'Download directory as XLS',
    description: '[button] Users page: toolbar action',
  },
});
