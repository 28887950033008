import {MainLegalEntityUpdateParams} from 'domain/user/api/setMainLegalEntity';
import {UserId} from 'domain/user/model';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import {createEffect, createEvent} from 'effector';

export const getMyPrivateInfoFx = createEffect<{userId: UserId}, UserPrivateInfo>('get own private info');
export const getUserPrivateInfoByIdFx = createEffect<{userId: UserId}, UserPrivateInfo>('get user’s private info');
export const setMainLegalEntityFx = createEffect<MainLegalEntityUpdateParams, UserPrivateInfo>(
  'set user’s main legal entity',
);
export const resetUserPrivateInfo = createEvent<{userId: UserId}>('reset user’s private info');
