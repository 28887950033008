const DEFAULT_QUALITY = 0.92;
const DEFAULT_FILE_TYPE = 'image/jpeg';

export function convertCanvasToBlob(canvas: HTMLCanvasElement, fileType: string, quality: number): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob: Blob | null) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      },
      fileType,
      quality,
    );
  });
}

export enum ResizeDimension {
  WIDTH = 'width',
  HEIGHT = 'height',
  MAX_SIDE = 'maxSide',
  MIN_SIDE = 'minSide',
}

export type ResizeOptions = {
  dimension: ResizeDimension;
  valuePx: number;
};

export function resizeImage(
  image: HTMLImageElement,
  options: ResizeOptions,
  fileType: string = DEFAULT_FILE_TYPE,
  quality: number = DEFAULT_QUALITY,
): Promise<Blob> {
  const {dimension, valuePx} = options;
  const {width: imageWidth, height: imageHeight} = image;
  const ratio = imageWidth / imageHeight;

  let resizeBy = dimension;
  if (dimension === ResizeDimension.MAX_SIDE) {
    resizeBy = ratio > 1 ? ResizeDimension.WIDTH : ResizeDimension.HEIGHT;
  }
  if (dimension === ResizeDimension.MIN_SIDE) {
    resizeBy = ratio > 1 ? ResizeDimension.HEIGHT : ResizeDimension.WIDTH;
  }

  const width = resizeBy === ResizeDimension.WIDTH ? valuePx : valuePx * ratio;
  const height = resizeBy === ResizeDimension.HEIGHT ? valuePx : valuePx / ratio;

  const canvas = document.createElement('canvas');

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.drawImage(image, 0, 0, width, height);
  }

  return convertCanvasToBlob(canvas, fileType, quality);
}

export function getImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = (e) => {
      reject(e);
    };
    img.onload = () => {
      resolve(img);
    };
    img.src = src;
  });
}
