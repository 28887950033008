import {DataState, getConsolidatedDataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {CompensationReportSummary} from 'domain/compensations/report/widgets/Summary';
import {compensationRequestIdSchema} from 'domain/compensations/request/model';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {getRequestByIdFx} from 'domain/compensations/request/stores/main';
import {$compensationRequests} from 'domain/compensations/request/stores/main/state';
import {CompensationRequestActions} from 'domain/compensations/request/widgets/Actions';
import {CompensationReportedPopup} from 'domain/compensations/request/widgets/ReportedPopup';
import {CompensationRequestSummary} from 'domain/compensations/request/widgets/Summary';
import {useUserPrivateInfo} from 'domain/user/hooks/useUserPrivateInfo';
import {UserFull} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {NotFoundPage} from 'pages/NotFound';
import React, {useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps} from 'react-router-dom';
import {pageTitles} from 'routes/compensations/titles';
import {compensationsUrls} from 'routes/compensations/urls';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function CompensationsRequestPage({selfUser}: Props) {
  const intl = useIntl();
  const {data: privateInfo, dataState: privateInfoDataState} = useUserPrivateInfo(selfUser.id);
  const {compensationRequestId} = useTypedParams({
    compensationRequestId: compensationRequestIdSchema,
  });
  const [justReported, setJustReported] = useQueryParam('reported', string(), '');
  const [compensationRequestData, error, state] = useStoreMap({
    store: $compensationRequests,
    keys: [compensationRequestId],
    fn: (compensationsState, [id]) =>
      id === undefined
        ? [undefined, undefined, DataState.IDLE]
        : [
            compensationsState.requestsById[id],
            compensationsState.requestErrorsById[id],
            compensationsState.requestStatesById[id],
          ],
  });
  const onReportedPopupClose = useCallback(() => {
    setJustReported('');
  }, [setJustReported]);

  useEffect(() => {
    if (!compensationRequestId || isLoadingOrLoaded(state)) {
      return;
    }
    getRequestByIdFx(compensationRequestId).catch(toaster.interceptThenThrowError);
  }, [compensationRequestId]);

  return compensationRequestId ? (
    <EntityPageStateHandler
      data={compensationRequestData}
      entityError={error}
      state={getConsolidatedDataState(state, privateInfoDataState)}
    >
      {(compensationRequest) => {
        const needsReport = compensationRequest.status === CompensationRequestStatus.NEEDS_REPORT;
        const compensationReport = compensationRequest.report;
        const isOwner = compensationRequest.owner.id === selfUser.id;
        const isApprover = compensationRequest.approver.id === selfUser.id;
        return (
          <Page
            actions={
              <CompensationRequestActions
                compensationRequest={compensationRequest}
                isApprover={isApprover}
                isOwner={isOwner}
              />
            }
            breadcrumbs={[{title: intl.formatMessage(pageTitles.requests), href: compensationsUrls.root()}]}
            title={intl.formatMessage(pageTitles.request)}
          >
            {justReported && (
              <CompensationReportedPopup onClose={onReportedPopupClose} userLegalEntity={privateInfo?.legalEntity} />
            )}
            <CompensationRequestSummary compensationRequest={compensationRequest} />
            {compensationReport && (!needsReport || isOwner) && (
              <Panel title={intl.formatMessage(messages.reportTitle)} withPadding>
                {isOwner && needsReport ? (
                  <RouterLinkButton
                    kind='primary'
                    intent='primary'
                    size='l'
                    to={compensationsUrls.report({compensationRequestId: compensationRequest.id})}
                  >
                    {compensationReport.hasData
                      ? intl.formatMessage(messages.continueReportButton)
                      : intl.formatMessage(messages.fillReportButton)}
                  </RouterLinkButton>
                ) : (
                  <CompensationReportSummary
                    compensationReport={compensationReport}
                    compensationRequestId={compensationRequest.id}
                    isOwner={isOwner}
                    isApprover={isApprover}
                  />
                )}
              </Panel>
            )}
          </Page>
        );
      }}
    </EntityPageStateHandler>
  ) : (
    <NotFoundPage />
  );
}
