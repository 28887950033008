import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Placeholder} from 'components/ui/Placeholder';
import {Page} from 'components/widgets/Page';
import {CompensationRequestCreationConfig} from 'domain/compensations/request/model';
import {createRequestFx} from 'domain/compensations/request/stores/main';
import {CompensationsRequestForm} from 'domain/compensations/request/widgets/Form';
import {useUserPrivateInfo} from 'domain/user/hooks/useUserPrivateInfo';
import {UserFull} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {pageTitles} from 'routes/compensations/titles';
import {compensationsUrls} from 'routes/compensations/urls';
import {toaster} from 'services/toaster';
import {UserSnapEvents, useUsersnapApi} from 'services/usersnap';
import {messages} from './messages';

type Props = RouteComponentProps & {
  selfUser: UserFull;
};

export function CompensationsCreateRequestPage({selfUser}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const usersnapApi = useUsersnapApi();

  const {data: privateInfo, dataState: privateInfoDataState} = useUserPrivateInfo(selfUser.id);

  const onSubmit = useCallback((config: CompensationRequestCreationConfig) => {
    const normalizedConfig: CompensationRequestCreationConfig = {
      ...config,
      reasonDetails: {
        ...config.reasonDetails,
        eventDates: config.reasonDetails.eventDates?.filter(Boolean),
      },
    };

    return createRequestFx(normalizedConfig)
      .then(({id}) => {
        history.push(compensationsUrls.request({compensationRequestId: id}));
        usersnapApi?.logEvent(UserSnapEvents.CREATE_BUSINESS_TRIP_REQUEST);
      })
      .catch(toaster.interceptThenThrowError);
  }, []);

  return (
    <PageStateHandler data={privateInfo} state={privateInfoDataState}>
      {(data) => {
        const {legalEntity} = data;
        if (!legalEntity || !legalEntity.countryCode || !legalEntity.currency) {
          return (
            <Placeholder.Error title={intl.formatMessage(messages.noLegalEntityTitle)}>
              {intl.formatMessage(messages.noLegalEntityError)}
            </Placeholder.Error>
          );
        }

        return (
          <Page
            breadcrumbs={[{title: intl.formatMessage(pageTitles.requests), href: compensationsUrls.root()}]}
            title={intl.formatMessage(messages.title)}
          >
            <CompensationsRequestForm userCountryCode={legalEntity.countryCode} onSubmit={onSubmit} />
          </Page>
        );
      }}
    </PageStateHandler>
  );
}
