import React, {CSSProperties} from 'react';
import {createLocator, Mark} from 'create-locator';
import {omit} from '@joomcode/deprecated-utils/object/omit';
import styles from './index.css';
import {TooltipFaqPlacement} from '../TooltipFaq';
import {BadgeLocator} from './index';

export const badgeIntent = {
  negative: 'negative',
  neutral: 'neutral',
  positive: 'positive',
  invertedPositive: 'invertedPositive',
  primary: 'primary',
  invertedPrimary: 'invertedPrimary',
  warning: 'warning',
  invertedNeutral: 'invertedNeutral',
  invertedNegative: 'invertedNegative',
  invertedWarning: 'invertedWarning',
} as const;

export type BadgeIntent = keyof typeof badgeIntent;

export const classNameByIntent: {[key in BadgeIntent]: string} = {
  [badgeIntent.negative]: styles.badgeIntentNegative,
  [badgeIntent.invertedNegative]: styles.badgeIntentInvertedNegative,
  [badgeIntent.neutral]: styles.badgeIntentNeutral,
  [badgeIntent.positive]: styles.badgeIntentPositive,
  [badgeIntent.invertedPositive]: styles.badgeIntentInvertedPositive,
  [badgeIntent.primary]: styles.badgeIntentPrimary,
  [badgeIntent.invertedPrimary]: styles.badgeIntentInvertedPrimary,
  [badgeIntent.warning]: styles.badgeIntentWarning,
  [badgeIntent.invertedNeutral]: styles.badgeIntentInvertedNeutral,
  [badgeIntent.invertedWarning]: styles.badgeIntentInvertedWarning,
};

type BadgeBaseProps = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  hint?: React.ReactChild;
  hintPlacement?: TooltipFaqPlacement;
  noMargin?: boolean;
  isCardBadge?: boolean;
  title?: string;
};

export type BadgePropsWithIntent = BadgeBaseProps & {
  intent: BadgeIntent;
};

export type BadgePropsWithColor = BadgeBaseProps & {
  backgroundColor: CSSProperties['backgroundColor'];
  color: CSSProperties['color'];
};

export type BadgeProps = (BadgePropsWithIntent | BadgePropsWithColor) & Partial<Mark<BadgeLocator>>;

export const isPropsWithIntent = (props: BadgeProps): props is BadgePropsWithIntent =>
  'intent' in props && Object.prototype.hasOwnProperty.call(badgeIntent, props.intent);

export const isPropsWithColor = (props: BadgeProps): props is BadgePropsWithColor =>
  'backgroundColor' in props && 'color' in props;

type IntersectedBadgeProps = Partial<BadgePropsWithIntent & BadgePropsWithColor & Mark<BadgeLocator>>;
type IntersectedKeysToOmit = keyof BadgePropsWithIntent | keyof BadgePropsWithColor;

export const createBadgeLocator = (props: BadgeProps) => {
  const locatorProps = omit<IntersectedBadgeProps, IntersectedKeysToOmit>(props, [
    'backgroundColor',
    'color',
    'intent',
  ]);
  return createLocator(locatorProps);
};
