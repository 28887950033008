import React, {ReactNode} from 'react';
import cn from 'classnames';
import styles from './index.css';

export type PagePropertyProps = {
  title?: ReactNode;
  children?: ReactNode;
  inline?: boolean;
};

export function PageProperty({title, children, inline}: PagePropertyProps) {
  return (
    <div className={cn(styles.root, {[styles.inline]: inline})}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
