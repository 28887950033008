import {SecurePermission} from 'domain/permission/model/secure';
import {RegularBonusReadAccess} from 'domain/regularBonusRecord/model/access';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {UserFull} from 'domain/user/model';
import {useAcl} from 'services/acl';

export function useRegularBonusReadAccess(user: UserFull | undefined): RegularBonusReadAccess | null {
  const acl = useAcl();
  const selfUserId = useSelfUserId();

  const canReadAny = acl.hasSecurePermission(SecurePermission.REGULAR_BONUS_RECORD_READ_ANY);
  const canReadSubordinate = acl.hasSecurePermission(SecurePermission.REGULAR_BONUS_RECORD_READ_SUBORDINATE);

  if (!user || !acl.inVpn()) {
    return null;
  }

  if (canReadAny) {
    return RegularBonusReadAccess.ANY;
  }
  if (canReadSubordinate && selfUserId && user.ancestorIds.includes(selfUserId)) {
    return RegularBonusReadAccess.SUBORDINATE;
  }
  if (user.id === selfUserId) {
    return RegularBonusReadAccess.MY;
  }

  return null;
}
