import {VisuallyHidden} from '@joomcode/joom-ui/VisuallyHidden';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './IntranetLogo.css';
import {messages} from './messages';
import {ReactComponent as SpaceLogoSvg} from './spaceLogo.svg';

export function IntranetLogo() {
  const intl = useIntl();

  return (
    <span className={styles.root}>
      <VisuallyHidden>{intl.formatMessage(messages.serviceName)}</VisuallyHidden>
      <SpaceLogoSvg />
    </span>
  );
}
