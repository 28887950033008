import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  loadingError: {
    defaultMessage: 'Failed to load job contracts',
    description: 'User job contract panels: loading error',
  },
  buttonAdd: {
    defaultMessage: 'Add job contract',
    description: '[button] User job contract panels: adding button text',
  },
});
