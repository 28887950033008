import {Delimiter} from '../types';

const stringToDelimiter = new Map<string, Delimiter>([
  [',', Delimiter.COMMA],
  ['|', Delimiter.PIPE],
  [';', Delimiter.SEMICOLON],
  ['\t', Delimiter.TAB],
]);

export const getDelimiterByString = (string: string): Delimiter | null => {
  if (stringToDelimiter.has(string)) {
    return stringToDelimiter.get(string) as Delimiter;
  }
  return null;
};
