import {array, ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {roleInTeamSchema} from 'domain/role/model/roleInTeam';
import {userSchema} from 'domain/user/model';
import {idSchema} from 'models/system/id';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const serviceIdSchema = idSchema;

export const serviceSchema = object({
  id: serviceIdSchema,
  name: string(),
  description: optional(string()),
  owners: optionalWithDefault([], array(userSchema)),
  owningMemberships: optionalWithDefault([], array(roleInTeamSchema)),
});

export type Service = ExtractSchemaType<typeof serviceSchema>;
export type ServiceId = ExtractSchemaType<typeof serviceIdSchema>;
