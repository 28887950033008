import {useCallback, useMemo} from 'react';
import {useSet, SetApi} from '../useSet';

export type MultiSelectState<T> = {
  getSelectedItems: () => T[];
  getAllItems: () => T[];
  isItemSelected: (item: T) => boolean;

  isAllSelected: boolean;
  hasSelectedItems: boolean;
  isPartiallySelected: boolean;
  selectedItemsCount: number;

  selectItem: (item: T) => void;
  deselectItem: (item: T) => void;
  toggleItem: (item: T) => void;

  selectOnlyItems: (items: T[]) => void;

  selectAll: () => void;
  deselectAll: () => void;
  toggleAll: () => void;
};

export function useMultiSelect<T>(
  items: T[],
  isItemSelectedInitially?: (item: T, index: number) => boolean,
  onChange?: (updatedItems: T[]) => void,
): MultiSelectState<T> {
  const initialSelectedItems = useMemo(() => {
    return isItemSelectedInitially ? items.filter(isItemSelectedInitially) : [];
  }, [items]);
  const onSetChange = useCallback(
    (api: SetApi<T>) => {
      if (onChange) {
        onChange(Array.from(api));
      }
    },
    [onChange],
  );

  const selectedSet = useSet<T>(initialSelectedItems, onSetChange);
  const selectedItemsCount = selectedSet.size;
  const hasSelectedItems = selectedItemsCount !== 0;
  const isAllSelected = selectedItemsCount > 0 && selectedItemsCount === items.length;
  const isPartiallySelected = hasSelectedItems && !isAllSelected;

  const getSelectedItems = useCallback(() => Array.from(selectedSet), [selectedSet]);
  const getAllItems = useCallback(() => items, [items]);
  const isItemSelected = useCallback((item: T) => selectedSet.has(item), [selectedSet, selectedSet.size]);
  const selectItem = useCallback(
    (item: T): void => {
      selectedSet.add(item);
    },
    [selectedSet],
  );
  const deselectItem = useCallback(
    (item: T): void => {
      selectedSet.delete(item);
    },
    [selectedSet],
  );

  const selectOnlyItems = useCallback(
    (selected: T[]) => {
      selectedSet.clear();
      selectedSet.batchAdd(selected);
    },
    [selectedSet],
  );

  const toggleItem = useCallback(
    (item: T) => (isItemSelected(item) ? deselectItem(item) : selectItem(item)),
    [deselectItem, isItemSelected, selectItem],
  );
  const selectAll = useCallback(() => {
    selectedSet.batchAdd(items);
  }, [items, selectedSet]);
  const deselectAll = useCallback(() => {
    selectedSet.clear();
  }, [selectedSet]);
  const toggleAll = isAllSelected ? deselectAll : selectAll;

  return {
    getSelectedItems,
    getAllItems,
    hasSelectedItems,
    isAllSelected,
    isItemSelected,
    isPartiallySelected,
    selectedItemsCount,

    selectItem,
    selectOnlyItems,
    selectAll,
    deselectItem,
    deselectAll,
    toggleItem,
    toggleAll,
  };
}
