import cn from 'classnames';
import React from 'react';
import {Spinner, SpinnerIntent, SpinnerSize} from '../Spinner';
import styles from './index.css';
import {BaseButtonProps, ButtonIntent, ButtonKind, ButtonSize, defaultButtonSize} from './types';

const spinnerSizeMap: Record<ButtonSize, SpinnerSize> = {
  s: 's',
  m: 'm',
  l: 'm',
  xl: 'l',
};

const spinnerIntentMap: Record<ButtonIntent, Record<ButtonKind, SpinnerIntent>> = {
  primary: {primary: 'inverted', secondary: 'primary', outline: 'primary', text: 'primary'},
  negative: {primary: 'inverted', secondary: 'negative', outline: 'negative', text: 'negative'},
  warning: {primary: 'inverted', secondary: 'warning', outline: 'warning', text: 'warning'},
  positive: {primary: 'inverted', secondary: 'positive', outline: 'positive', text: 'positive'},
  ghost: {primary: 'neutral', secondary: 'neutral', outline: 'neutral', text: 'neutral'},
  neutralGray: {primary: 'inverted', secondary: 'neutral', outline: 'neutral', text: 'neutral'},
  neutral: {primary: 'neutral', secondary: 'neutral', outline: 'neutral', text: 'neutral'},
  invertedNeutral: {primary: 'inverted', secondary: 'inverted', outline: 'inverted', text: 'inverted'},
};

export function ButtonContent({
  children,
  iconLeft,
  iconRight,
  loading,
  withShrink,
  intent,
  kind,
  size = defaultButtonSize,
}: BaseButtonProps) {
  return (
    <>
      <span className={styles.backdrop} />
      <span
        className={cn(styles.content, {
          [styles.contentLoading]: loading,
        })}
      >
        {iconLeft ? <span className={styles.icon}>{iconLeft}</span> : null}
        {children ? <span className={cn(styles.text, {[styles.textWithOverflow]: withShrink})}>{children}</span> : null}
        {iconRight ? <span className={styles.icon}>{iconRight}</span> : null}
      </span>
      {loading && (
        <span className={styles.loader}>
          <Spinner intent={spinnerIntentMap[intent][kind]} size={spinnerSizeMap[size]} />
        </span>
      )}
    </>
  );
}
