import {RoomSvg} from 'domain/officeMap/room/widgets/RoomSvg';
import {SeatSvg} from 'domain/officeMap/seat/widgets/SeatSvg';
import React from 'react';
import styles from './styles.css';
import svgStyles from './svgStyles.css';

type Props = {
  children: React.ReactNode[];
  showExits: boolean;
  shadowElementId: string;
};

export function OfficeMapSvgMoscow({children, shadowElementId, showExits}: Props) {
  return (
    <svg viewBox='0 0 800 800'>
      <defs>
        <filter id={shadowElementId}>
          <feDropShadow dx='-1' dy='1' stdDeviation='3' floodOpacity='0.25' />
        </filter>
      </defs>
      <g className={styles.sample}>
        <SeatSvg.Sample />
        <RoomSvg.Sample />
      </g>

      <g id='walls' style={{filter: `url(#${shadowElementId})`}}>
        <g>
          <path
            className={svgStyles.st0}
            d='M38.791,762.331c-4.418,0-8-3.582-8-8V398.89h-9.322c-4.418,0-8-3.582-8-8V55.661c0-4.418,3.582-8,8-8 h689.516c4.418,0,8,3.582,8,8v1.066h69.071c4.418,0,8,3.582,8,8v246.669c0,4.418-3.582,8-8,8H299.929v434.936c0,4.418-3.582,8-8,8  H38.791z'
          />
        </g>
        <g>
          <polygon
            className={svgStyles.st1}
            points='720.255,202.133 720.526,265.728 692.377,265.864 692.106,223.241 699.549,223.309 699.549,203.215  699.549,201.998 720.255,202.133 		'
          />
          <path
            className={svgStyles.st2}
            d='M717.255,202.113c1.65,0.011,3.006,1.37,3.013,3.02l0.245,57.595c0.007,1.65-1.337,3.006-2.987,3.014  l-22.149,0.107c-1.65,0.008-3.009-1.336-3.019-2.986l-0.233-36.623c-0.01-1.65,1.331-2.988,2.981-2.973l1.444,0.013  c1.65,0.015,3-1.323,3-2.973v-14.094c0-1.65,0-3.274,0-3.609s1.35-0.6,3-0.589L717.255,202.113z'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='598.591' y1='251.792' x2='623.966' y2='251.792' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='5.161' y1='165.058' x2='5.161' y2='165.058' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='466.981' y1='311.395' x2='466.981' y2='311.395' />
        </g>
        <g>
          <path
            className={svgStyles.st3}
            d='M469.981,311.395c-1.65,0-4.35,0-6,0H294.929c-1.65,0-3,1.35-3,3v436.936c0,1.65-1.35,3-3,3H41.791  c-1.65,0-3-1.35-3-3V393.89c0-1.65-1.35-3-3-3H24.469c-1.65,0-3-1.35-3-3V58.661c0-1.65,1.35-3,3-3h683.516c1.65,0,3,1.35,3,3  v3.066c0,1.65,1.35,3,3,3h71.072c1.65,0,3,1.35,3,3v240.669c0,1.65-1.35,3-3,3H469.981z'
          />
        </g>
        <g>
          <path className={svgStyles.st2} d='M383.481,56.066v65.331c0,1.65,1.35,3,3,3h44.975' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='741.908' y1='64.726' x2='741.908' y2='113.776' />
        </g>
        <g>
          <polygon
            className={svgStyles.st1}
            points='788.056,195.503 741.908,195.503 741.908,210.319 741.908,231.495 788.056,231.495 788.056,195.503  		'
          />
          <path
            className={svgStyles.st2}
            d='M788.056,198.503c0-1.65-1.35-3-3-3h-40.148c-1.65,0-3,1.35-3,3v8.816c0,1.65,0,4.35,0,6v15.176  c0,1.65,1.35,3,3,3h40.148c1.65,0,3-1.35,3-3V198.503z'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='741.908' y1='242.658' x2='741.908' y2='311.395' />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M687.166,192.188h-85.575c-1.65,0-3,1.35-3,3v32.022c0,1.65-1.266,2.532-2.814,1.96l-8.351-3.087'
          />
        </g>
        <g>
          <path className={svgStyles.st2} d='M598.591,120.744v68.443c0,1.65-1.35,3-3,3h-23.457' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='657.935' y1='145.574' x2='598.591' y2='145.574' />
        </g>
        <g>
          <polygon
            className={svgStyles.st1}
            points='572.134,120.744 598.591,120.744 598.591,192.188 572.134,192.188 572.134,120.744 		'
          />
          <path
            className={svgStyles.st2}
            d='M572.134,123.744c0-1.65,1.35-3,3-3h20.457c1.65,0,3,1.35,3,3v65.443c0,1.65-1.35,3-3,3h-20.457  c-1.65,0-3-1.35-3-3V123.744z'
          />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M572.134,192.188h-12.157c-1.65,0-3,1.35-3,3v32.225c0,1.65,1.35,3,3,3h27.45'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='598.591' y1='120.744' x2='646.567' y2='120.744' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M540.196,56.405v104.03c0,1.65-1.244,2.477-2.765,1.837l-8.332-3.505' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M529.302,180.822H516.4c-1.65,0-3-1.35-3-3v-4.713' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='494.251' y1='222.497' x2='494.251' y2='222.497' />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M529.099,185.422l8.326-3.452c1.524-0.632,2.771,0.201,2.771,1.851v35.675c0,1.65-1.35,3-3,3h-42.945'
          />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M494.251,173.109H510.4c1.65,0,3-1.35,3-3v-3.269c0-1.65,1.35-3,3-3h12.766'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='657.799' y1='251.792' x2='657.799' y2='251.792' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='646.634' y1='255.919' x2='657.799' y2='251.792' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='657.799' y1='251.792' x2='657.799' y2='311.395' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='525.445' y1='251.792' x2='646.634' y2='251.792' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='564.082' y1='251.792' x2='564.082' y2='311.395' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M439.983,311.395v-56.604c0-1.65,1.35-3,3-3h59.929' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='21.469' y1='102.748' x2='72.624' y2='102.748' />
        </g>
        <g>
          <polyline
            className={svgStyles.st1}
            points='175.341,125.142 175.341,324.656 140.831,324.656 140.831,155.857 175.341,155.857 		'
          />
          <path
            className={svgStyles.st2}
            d='M175.341,125.142v196.514c0,1.65-1.35,3-3,3h-28.51c-1.65,0-3-1.35-3-3V158.857c0-1.65,1.35-3,3-3h31.51'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='211.136' y1='346.982' x2='211.136' y2='346.982' />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M211.136,358.28v70.135c0,1.65-1.35,3-3,3h-64.305c-1.65,0-3-1.35-3-3v-78.433c0-1.65,1.35-3,3-3h67.305'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='211.136' y1='346.982' x2='206.941' y2='358.145' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M291.929,311.395h-22.036c-1.65,0-3.985,0.924-5.188,2.052l-22.916,21.492' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='315.748' y1='66.959' x2='315.748' y2='133.937' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M88.593,653.525l3.087-8.349c0.572-1.548-0.31-2.814-1.96-2.814h-50.93' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M92.721,653.661v39.893c0,1.65-1.35,3-3,3h-50.93' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M92.721,696.554h25.352c1.65,0,3,1.35,3,3v8.41c0,1.65,1.35,3,3,3h29.48' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='234.21' y1='410.171' x2='234.21' y2='410.171' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='238.338' y1='399.008' x2='234.21' y2='410.171' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='234.21' y1='410.171' x2='291.929' y2='410.171' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='234.007' y1='343.667' x2='234.007' y2='343.667' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='233.94' y1='398.941' x2='234.007' y2='343.667' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='234.007' y1='343.667' x2='244.834' y2='338.66' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='291.929' y1='364.369' x2='234.007' y2='364.369' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M209.648,511.856v45.847c0,1.65-1.35,3-3,3h-80.77' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='145.568' y1='500.626' x2='145.568' y2='560.703' />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M209.648,489.463v-9.381c0-1.65-1.35-3-3-3h-78.312c-1.65,0-3,1.35-3,3v9.313'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='363.791' y1='204.569' x2='300.252' y2='204.569' />
        </g>
        <g>
          <polygon
            className={svgStyles.st1}
            points='230.759,168.373 201.122,168.373 201.122,157.143 201.122,141.65 201.122,133.937 230.759,133.937  230.759,168.373 		'
          />
          <path
            className={svgStyles.st2}
            d='M230.759,165.373c0,1.65-1.35,3-3,3h-23.638c-1.65,0-3-1.35-3-3v-5.231c0-1.65,0-4.35,0-6v-9.493  c0-1.65,0-4.35,0-6v-1.713c0-1.65,1.35-3,3-3h23.638c1.65,0,3,1.35,3,3V165.373z'
          />
        </g>
        <g>
          <polygon
            className={svgStyles.st1}
            points='300.252,252.942 259.247,252.942 259.247,205.516 259.247,185.49 263.171,185.49 263.171,168.373  263.171,133.937 315.748,133.937 315.748,168.035 300.252,168.035 300.252,204.569 300.252,252.942 		'
          />
          <path
            className={svgStyles.st2}
            d='M300.252,249.942c0,1.65-1.35,3-3,3h-35.006c-1.65,0-3-1.35-3-3v-41.426c0-1.65,0-4.35,0-6V188.49  c0-1.65,0.883-3,1.962-3c1.079,0,1.962-1.35,1.962-3v-11.117c0-1.65,0-4.35,0-6v-28.436c0-1.65,1.35-3,3-3h46.576  c1.65,0,3,1.35,3,3v28.098c0,1.65-1.35,3-3,3h-9.496c-1.65,0-3,1.35-3,3v30.534c0,1.65,0,4.35,0,6V249.942z'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='167.492' y1='477.082' x2='167.492' y2='500.626' />
        </g>
        <g>
          <polygon
            className={svgStyles.st1}
            points='494.251,173.109 494.251,124.398 468.267,124.398 468.267,222.497 494.251,222.497 494.251,173.109  		'
          />
          <path
            className={svgStyles.st2}
            d='M494.251,176.109c0-1.65,0-4.35,0-6v-42.711c0-1.65-1.35-3-3-3h-19.984c-1.65,0-3,1.35-3,3v92.099  c0,1.65,1.35,3,3,3h19.984c1.65,0,3-1.35,3-3V176.109z'
          />
        </g>
        <g>
          <polygon
            className={svgStyles.st1}
            points='164.853,754.331 271.359,754.331 271.359,735.861 219.391,735.861 219.391,710.964 164.853,710.964  164.853,754.331 		'
          />
          <path
            className={svgStyles.st2}
            d='M164.853,751.331c0,1.65,1.35,3,3,3h100.506c1.65,0,3-1.35,3-3v-12.47c0-1.65-1.35-3-3-3h-45.967  c-1.65,0-3-1.35-3-3v-18.897c0-1.65-1.35-3-3-3h-48.539c-1.65,0-3,1.35-3,3V751.331z'
          />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M259.247,252.942h-35.908c-1.65,0-3,1.35-3,3v41.155c0,1.65-1.35,3-3,3h-16.217'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='164.853' y1='710.964' x2='153.62' y2='715.091' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M223.519,710.829l-3.087-8.349c-0.572-1.548,0.31-2.814,1.96-2.814h69.538' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='205.655' y1='511.789' x2='209.783' y2='500.626' />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M205.655,489.395l3.093,8.415c0.569,1.549-0.315,2.816-1.965,2.816h-36.291c-1.65,0-4.35,0-6,0H128.2  c-1.65,0-2.528-1.265-1.95-2.81l3.145-8.42'
          />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M216.888,185.49h-12.766c-1.65,0-3,1.35-3,3v14.026c0,1.65,1.35,3,3,3h55.125'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='230.759' y1='133.937' x2='263.171' y2='133.937' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='228.256' y1='185.49' x2='217.091' y2='189.685' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='228.256' y1='185.49' x2='239.488' y2='189.685' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='259.247' y1='185.49' x2='239.488' y2='185.49' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='246.864' y1='168.373' x2='235.699' y2='164.246' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='246.864' y1='168.373' x2='258.029' y2='164.246' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='319.875' y1='66.823' x2='315.748' y2='55.661' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='359.663' y1='66.823' x2='363.791' y2='55.661' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M72.759,98.621l8.351,3.087c1.548,0.572,2.814-0.31,2.814-1.96V55.661' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='442.689' y1='124.398' x2='442.689' y2='124.398' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='431.524' y1='128.592' x2='442.689' y2='124.398' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='442.689' y1='124.398' x2='540.196' y2='124.398' />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M646.77,124.871l8.351-3.087c1.548-0.572,2.814,0.31,2.814,1.96v18.829c0,1.65,1.35,3,3,3h26.57'
          />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M687.572,149.633l8.357-3.14c1.545-0.58,4.158-1.043,5.808-1.028l9.225,0.082  c1.65,0.015,3.002,1.377,3.004,3.027l0.059,40.614c0.002,1.65-1.346,3-2.996,3h-9.698c-1.65,0-4.266-0.468-5.814-1.04  l-8.351-3.087'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='502.979' y1='256.121' x2='514.144' y2='251.995' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M525.309,256.121l-8.351-3.087c-1.548-0.572-2.814,0.31-2.814,1.96v56.536' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='746.036' y1='242.658' x2='741.908' y2='231.495' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='746.036' y1='136.034' x2='741.908' y2='124.871' />
        </g>
        <g>
          <path className={svgStyles.st2} d='M746.036,113.708l-3.087,8.349c-0.572,1.548,0.31,2.814,1.96,2.814h43.148' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='741.908' y1='195.503' x2='741.908' y2='136.305' />
        </g>
        <g>
          <polyline
            className={svgStyles.st1}
            points='39,405.3 39,390.89 39,390.89 84.33,390.89 84.33,405.3 39,405.3 		'
          />
          <path
            className={svgStyles.st2}
            d='M39,405.3v-11.41c0-1.65,1.35-3,3-3h39.33c1.65,0,3,1.35,3,3v8.41c0,1.65-1.35,3-3,3H39'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='287.734' y1='264.105' x2='291.929' y2='252.942' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='291.929' y1='283.251' x2='291.929' y2='283.251' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='287.734' y1='272.088' x2='291.929' y2='283.251' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='291.929' y1='283.251' x2='291.929' y2='311.395' />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M383.887,264.105l-3.087-8.349c-0.572-1.548-1.04-4.164-1.04-5.814V233.21c0-1.65,1.35-3,3-3h12.27  c1.65,0,3-1.35,3-3v-19.641c0-1.65-1.35-3-3-3h-28.239c-1.65,0-3-1.35-3-3V66.891'
          />
        </g>
        <g>
          <line className={svgStyles.st2} x1='379.76' y1='283.251' x2='379.76' y2='283.251' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='383.887' y1='272.088' x2='379.76' y2='283.251' />
        </g>
        <g>
          <line className={svgStyles.st2} x1='379.76' y1='283.251' x2='379.76' y2='311.395' />
        </g>
        <g>
          <path
            className={svgStyles.st2}
            d='M469.981,311.395c-1.65,0-4.35,0-6,0H294.929c-1.65,0-3,1.35-3,3v436.936c0,1.65-1.35,3-3,3H41.791  c-1.65,0-3-1.35-3-3V393.89c0-1.65-1.35-3-3-3H24.469c-1.65,0-3-1.35-3-3V58.661c0-1.65,1.35-3,3-3h683.516c1.65,0,3,1.35,3,3  v3.066c0,1.65,1.35,3,3,3h71.072c1.65,0,3,1.35,3,3v240.669c0,1.65-1.35,3-3,3H469.981z'
          />
        </g>
      </g>
      {showExits && (
        <g id='exits'>
          <path
            id='exit4'
            className={svgStyles.st5}
            d='M641.567,158.513c0-0.912,0.908-1.652,2.028-1.652h40.565c1.12,0,2.028,0.74,2.028,1.652v19.412  c0,0.912-0.908,1.652-2.028,1.652h-40.565c-1.12,0-2.028-0.74-2.028-1.652V158.513z M670.884,174.956  c0-2.636,2.157-5.189,2.157-5.189s2.157,2.554,2.157,5.189c5.449,0,6.528-4.393,4.371-7.028c-1.162-1.42-3.561-3.025-3.501-5.726  c0.014-0.633-0.808-1.049-1.502-0.765c-0.879,0.36-1.904,0.924-2.578,1.756c-2.314,2.856-1.148,5.645-1.148,5.645  s-1.471-0.683-2.715-1.891c-0.485-0.471-1.408-0.373-1.73,0.183C664.864,169.775,664.527,174.956,670.884,174.956z   M655.596,172.702v-2.21h5.409v-4.543h-5.409V163.6l-8.472,4.468L655.596,172.702L655.596,172.702z'
          />
          <path
            id='exit3'
            className={svgStyles.st5}
            d='M106.713,722.513c0-0.912,0.908-1.652,2.028-1.652h40.565c1.12,0,2.028,0.74,2.028,1.652v19.412  c0,0.912-0.908,1.652-2.028,1.652h-40.565c-1.12,0-2.028-0.74-2.028-1.652V722.513z M136.03,738.956  c0-2.636,2.157-5.189,2.157-5.189s2.157,2.554,2.157,5.189c5.449,0,6.528-4.393,4.371-7.028c-1.162-1.42-3.561-3.025-3.501-5.726  c0.014-0.633-0.808-1.049-1.502-0.765c-0.879,0.36-1.904,0.924-2.578,1.756c-2.314,2.856-1.148,5.645-1.148,5.645  s-1.471-0.683-2.715-1.891c-0.485-0.471-1.408-0.373-1.73,0.183C130.01,733.775,129.673,738.956,136.03,738.956z M120.742,736.702  v-2.21h5.409v-4.543h-5.409V727.6l-8.472,4.468L120.742,736.702L120.742,736.702z'
          />
          <path
            id='exit2'
            className={svgStyles.st5}
            d='M329.983,115.127c-0.912,0-1.652-0.908-1.652-2.028V72.533c0-1.12,0.74-2.028,1.652-2.028h19.412  c0.912,0,1.652,0.908,1.652,2.028v40.565c0,1.12-0.74,2.028-1.652,2.028H329.983z M346.425,85.81c-2.636,0-5.189-2.157-5.189-2.157  s2.554-2.157,5.189-2.157c0-5.449-4.393-6.528-7.028-4.371c-1.42,1.162-3.025,3.561-5.726,3.501  c-0.633-0.014-1.049,0.808-0.765,1.502c0.36,0.879,0.924,1.904,1.756,2.578c2.856,2.314,5.645,1.148,5.645,1.148  s-0.683,1.471-1.891,2.715c-0.471,0.485-0.373,1.408,0.183,1.73C341.245,91.83,346.425,92.167,346.425,85.81z M344.171,101.098  h-2.21v-5.409h-4.543v5.409h-2.348l4.468,8.472L344.171,101.098L344.171,101.098z'
          />
          <g id='exit1'>
            <g>
              <rect x='317.712' y='209.055' className={svgStyles.st6} width='43.811' height='21.905' />
              <path
                className={svgStyles.st7}
                d='M361.523,230.149c0,0.446-0.365,0.811-0.811,0.811h-42.188c-0.446,0-0.811-0.365-0.811-0.811v-20.283  	c0-0.446,0.365-0.811,0.811-0.811h42.188c0.446,0,0.811,0.365,0.811,0.811V230.149z'
              />
            </g>
            <g>
              <path
                className={svgStyles.st8}
                d='M321.84,225.623v-12.196h8.818v1.439h-7.205v3.735h6.747v1.431h-6.747v4.151h7.487v1.439H321.84z'
              />
              <path
                className={svgStyles.st8}
                d='M331.931,225.623l4.717-6.356l-4.16-5.84h1.922l2.212,3.128c0.46,0.649,0.788,1.148,0.982,1.498  	c0.271-0.444,0.593-0.907,0.965-1.389l2.454-3.236h1.755l-4.284,5.749l4.617,6.447h-1.997l-3.07-4.351  	c-0.172-0.25-0.35-0.521-0.533-0.815c-0.271,0.444-0.466,0.749-0.582,0.915l-3.062,4.251H331.931z'
              />
              <path className={svgStyles.st8} d='M344.809,225.623v-12.196h1.614v12.196H344.809z' />
              <path
                className={svgStyles.st8}
                d='M352.371,225.623v-10.756h-4.018v-1.439h9.667v1.439h-4.035v10.756H352.371z'
              />
            </g>
          </g>
        </g>
      )}
      <g id='seats'>{children}</g>
    </svg>
  );
}
