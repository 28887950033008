import {defineMessages} from 'react-intl';

export const columnNames = defineMessages({
  reason: {
    defaultMessage: 'Reason',
    description: 'Balance record table column name',
  },
  createdTime: {
    defaultMessage: 'Creation date',
    description: 'Balance record table column name',
  },
  daysAdded: {
    defaultMessage: 'Days added',
    description: 'Balance record table column name',
  },
  totalBalance: {
    defaultMessage: 'Total balance',
    description: 'Balance record table column name',
  },
});

export const messages = defineMessages({
  noBalanceRecords: {
    defaultMessage: 'No balance records yet',
    description: 'Balance record: empty list',
  },
});
