import {BookRoomConfig} from 'domain/officeMap/office/api/bookRoom';
import {SeatUpdateConfig} from 'domain/officeMap/office/api/updateSeat';
import {Office} from 'domain/officeMap/office/model';
import {OfficeRoomBookingResponse} from 'domain/officeMap/room/model/bookingResponse';
import {OfficeSeat} from 'domain/officeMap/seat/model';
import {createEffect} from 'effector';

export const loadAllOfficesFx = createEffect<void, Office[]>('load all offices');
export const loadAllOfficesInBackgroundFx = createEffect<void, Office[]>('load all offices in background');
export const bookRoomFx = createEffect<BookRoomConfig, OfficeRoomBookingResponse>('book room');
export const updateSeatFx = createEffect<SeatUpdateConfig, OfficeSeat>('update office seat');
