import cn from 'classnames';
import {StyledLink} from 'components/ui/StyledLink';
import {TeamLite} from 'domain/team/model/lite';
import React from 'react';
import {teamsUrls} from 'routes/teams/urls';
import styles from './styles.css';

type Props = {
  team: TeamLite;
  teamAncestors: TeamLite[];
  colored?: boolean;
};

export function TeamPath({team, teamAncestors, colored}: Props) {
  return (
    <span>
      {teamAncestors.map(({id, name}) => (
        <React.Fragment key={id}>
          <span className={styles.team}>
            <StyledLink to={teamsUrls.team({id})}>{name}</StyledLink>
          </span>
          {' / '}
        </React.Fragment>
      ))}
      <span className={cn({[styles.emphasis]: !colored}, styles.team)}>
        <StyledLink to={teamsUrls.team({id: team.id})} colored={colored}>
          {team.name}
        </StyledLink>
      </span>
    </span>
  );
}
