import {defineMessages} from 'react-intl';
import {ColumnName} from './columnName';

export const columnNames = defineMessages<ColumnName>({
  [ColumnName.TYPE]: {
    defaultMessage: 'Time off type',
    description: 'Time off request table column name',
  },
  [ColumnName.DATES]: {
    defaultMessage: 'Dates',
    description: 'Time off request table column name',
  },
  [ColumnName.USER]: {
    defaultMessage: 'Joomer',
    description: 'Time off request table column name',
  },
  [ColumnName.STATUS]: {
    defaultMessage: 'Status',
    description: 'Time off request table column name',
  },
  [ColumnName.DAYS_TAKEN]: {
    defaultMessage: 'Days',
    description: 'Time off request table column name',
  },
  [ColumnName.LEGAL_ENTITY]: {
    defaultMessage: 'Legal entity',
    description: 'Time off request table column name',
  },
  [ColumnName.CREATED_AT]: {
    defaultMessage: 'Creation time',
    description: 'Time off request table column name',
  },
  [ColumnName.ACTIONS]: {
    defaultMessage: 'Actions',
    description: 'Time off request table column name',
  },
  [ColumnName.RECORDED]: {
    defaultMessage: 'Recorded',
    description: 'Time off request table column name',
  },
});

export const messages = defineMessages({
  noRequests: {
    defaultMessage: 'No requests yet',
    description: 'Time off requests: empty list',
  },
  createdByHint: {
    defaultMessage: 'Created by {userLink}',
    description: 'Time off request created by hint',
  },
});
