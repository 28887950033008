import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  team: {
    defaultMessage: 'Team',
    description: '[title] Page title',
  },
  teams: {
    defaultMessage: 'Teams',
    description: '[title] Page title',
  },
  roleBindings: {
    defaultMessage: '{teamName} accesses',
    description: '[title] Page title',
  },
});
