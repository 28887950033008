import React, {memo, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {type ConvertLocatorToTestId, createLocator, type Locator, type Mark} from '../create-locator';
import styles from './index.css';
import {messages} from './messages';

export const DEFAULT_LIMITS = [10, 25, 50, 100];

export type PaginationTestId = ConvertLocatorToTestId<PaginationLocator>;
export type PaginationLocator = Locator<{
  select: void;
  buttonForward: void;
  buttonBackward: void;
}>;

export type Props = {
  availableLimits?: number[];
  disabled?: boolean;
  limit: number;
  onLimitChange: (limit: number) => void;
  onPageChange: (page: number) => void;
  page: number;
  pagesCount: number;
} & Partial<Mark<PaginationLocator>>;

export const Pagination = memo(function Pagination({
  availableLimits = DEFAULT_LIMITS,
  disabled,
  limit,
  onLimitChange,
  onPageChange,
  page,
  pagesCount,
  ...restProperties
}: Props) {
  const locator = createLocator(restProperties);
  const intl = useIntl();

  const handleClickBackward = useCallback(() => onPageChange(page - 1), [onPageChange, page]);
  const handleClickForward = useCallback(() => onPageChange(page + 1), [onPageChange, page]);
  const handleChangeLimit = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newLimit = parseInt(event.target.value, 10);

      if (!Number.isNaN(newLimit)) {
        onLimitChange(newLimit);
      }
    },
    [onLimitChange],
  );

  return (
    <div className={styles.pagination}>
      {Boolean(availableLimits?.length) && (
        <div className={styles.limit}>
          {intl.formatMessage(messages.itemsPerPage)}{' '}
          <select value={limit} onChange={handleChangeLimit} disabled={disabled} {...locator.select()}>
            {availableLimits.map((item) => (
              <option key={item} value={item}>
                {intl.formatNumber(item)}
              </option>
            ))}
          </select>
        </div>
      )}

      <button
        disabled={page <= 1 || disabled}
        className={styles.button}
        onClick={handleClickBackward}
        type='button'
        {...locator.buttonBackward()}
      >
        ←
      </button>

      {intl.formatNumber(page)}

      <button
        disabled={page >= pagesCount || disabled}
        className={styles.button}
        onClick={handleClickForward}
        type='button'
        {...locator.buttonForward()}
      >
        →
      </button>
    </div>
  );
});
