import {ManagerConsoleRedirect} from 'domain/managerConsole/widgets/Redirect';
import {SecurePermission} from 'domain/permission/model/secure';
import {withAuth} from 'hocs/withAuth';
import {withSecurePermission} from 'hocs/withSecurePermission';
import {withVpn} from 'hocs/withVpn';
import {RegularLayout} from 'layouts/Regular';
import {ManagerConsoleCompensationReviewRequestPage} from 'pages/ManagerConsoleCompensationReviewRequest';
import {ManagerConsoleCompensationReviewRequestCreatePage} from 'pages/ManagerConsoleCompensationReviewRequestCreate';
import {ManagerConsoleCompensationReviewRequestsPage} from 'pages/ManagerConsoleCompensationReviewRequests';
import {ManagerConsoleSalaryRangesPage} from 'pages/ManagerConsoleSalaryRanges';
import {ManagerConsoleSubordinatesPage} from 'pages/ManagerConsoleSubordinates';
import {NotFoundPage} from 'pages/NotFound';
import {RouteConfig} from 'react-router-config';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {managerConsoleUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: managerConsoleUrlPatterns.root,
  component: withVpn(withAuth(RegularLayout), NotFoundPage),
  routes: [
    {
      component: ManagerConsoleRedirect,
      exact: true,
      path: managerConsoleUrlPatterns.root,
    },
    {
      component: withSecurePermission(ManagerConsoleSalaryRangesPage, SecurePermission.SALARY_RANGE_READ_AS_MANAGER),
      exact: true,
      path: managerConsoleUrlPatterns.salaryRanges,
    },
    {
      component: ManagerConsoleSubordinatesPage,
      exact: true,
      path: managerConsoleUrlPatterns.subordinates,
    },
    {
      component: ManagerConsoleCompensationReviewRequestsPage,
      exact: true,
      path: managerConsoleUrlPatterns.compensationReviewRequests,
    },
    {
      component: withSecurePermission(
        ManagerConsoleCompensationReviewRequestCreatePage,
        SecurePermission.COMPENSATION_REVIEW_REQUEST_CREATE,
      ),
      exact: true,
      path: managerConsoleUrlPatterns.compensationReviewRequestCreate,
    },
    {
      component: ManagerConsoleCompensationReviewRequestPage,
      exact: true,
      path: managerConsoleUrlPatterns.compensationReviewRequest,
    },
    notFoundRouteConfig,
  ],
};
