import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {Button} from '@joomcode/joom-ui/Button';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {Permission} from 'domain/permission/model';
import {useService} from 'domain/service/hooks/useService';
import {serviceIdSchema} from 'domain/service/model';
import {ServiceResourcesPanel} from 'domain/service/widgets/ResourcesPanel';
import {ServiceSummaryPanel} from 'domain/service/widgets/SummaryPanel';
import {ServiceUpdateDialog} from 'domain/service/widgets/UpdateDialog';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/jdm/titles';
import {jdmUrls} from 'routes/jdm/urls';
import {useAcl} from 'services/acl';
import {messages} from './messages';

export function ServicePage() {
  const intl = useIntl();
  const acl = useAcl();
  const {serviceId} = useTypedParams({serviceId: serviceIdSchema});
  const {data, dataState, clientErrorStatus, isSelfOwner} = useService(serviceId);
  const updateDialog = usePopupState();

  if (!serviceId) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={data} state={dataState} entityError={clientErrorStatus}>
      {(service) => {
        return (
          <Page
            title={service.name}
            breadcrumbs={[
              {
                title: intl.formatMessage(pageTitles.services),
                href: jdmUrls.services(),
              },
            ]}
            actions={
              acl.hasPermission(Permission.SERVICE_WRITE) &&
              isSelfOwner && (
                <Button size='m' kind='primary' intent='primary' onClick={updateDialog.open}>
                  {intl.formatMessage(messages.btnEdit)}
                </Button>
              )
            }
          >
            <ServiceSummaryPanel service={service} />
            {acl.hasPermission(Permission.RESOURCE_READ) && (
              <ServiceResourcesPanel serviceId={service.id} showCreateButton={isSelfOwner} />
            )}
            {updateDialog.isOpen && <ServiceUpdateDialog service={service} onClose={updateDialog.close} />}
          </Page>
        );
      }}
    </EntityPageStateHandler>
  );
}
