import {DataState} from '@joomcode/deprecated-utils/dataState';
import {identity} from '@joomcode/deprecated-utils/function';
import {
  bindValidatorOptions,
  composeValidators,
  getFieldValidator,
  useFieldWithInitialValue,
  validateFilled,
} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input} from '@joomcode/joom-ui/Input';
import {useSecureRoles} from 'domain/secureRole/hooks/useSecureRoles';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import {validateForRestrictedValues} from 'utils/form/validateForRestrictedValues';
import {errorMessages, messages} from './messages';
import styles from './styles.css';

type Props = {
  name: string;
  initialValue: string;
  currentRoleId?: string;
  disabled: boolean;
};

export function SecureRoleNameField({name, initialValue, currentRoleId, disabled}: Props) {
  const intl = useIntl();
  const {rolesById, dataState} = useSecureRoles();

  const existingRoleNames: Set<string> = useMemo(
    () =>
      new Set(
        Object.values(rolesById)
          .filter(({id}) => id !== currentRoleId)
          .map(({title}) => title),
      ),
    [rolesById, currentRoleId],
  );

  const composedValidator = composeValidators(
    validateFilled,
    bindValidatorOptions(validateForRestrictedValues, {restrictedValues: existingRoleNames}),
  );

  const {input, meta} = useFieldWithInitialValue<string>(name, {
    initialValue,
    validate: getFieldValidator(composedValidator),
    parse: identity,
  });

  const fieldDisabled = disabled || dataState !== DataState.LOADED;

  return (
    <FormControl
      error={getFieldErrorText(meta, {intl, extraMessages: errorMessages})}
      label={<div className={styles.label}>{intl.formatMessage(messages.titleLabel)}</div>}
      disabled={fieldDisabled}
    >
      {(formControlProps) => <Input disabled={fieldDisabled} {...formControlProps} {...input} />}
    </FormControl>
  );
}
