import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {TimeOffBalanceRecord} from 'domain/timeOff/balanceRecord/model';
import {BalanceRecordsReadAccess} from 'domain/timeOff/balanceRecord/model/access';
import {
  getMyTimeOffBalanceRecords,
  getMyTimeOffBalanceRecordsByTypeFx,
  getTimeOffBalanceRecords,
  getTimeOffBalanceRecordsByTypeFx,
} from 'domain/timeOff/balanceRecord/stores/main/index';
import {$balanceRecords} from 'domain/timeOff/balanceRecord/stores/main/state';
import {UserId} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {Feature, useFeature} from 'services/features';
import {toaster} from 'services/toaster';
import {useBalanceRecordsReadAccess} from './useBalanceRecordsReadAccess';

type ReturnShape = {
  vacationBalanceRecords: TimeOffBalanceRecord[];
  remoteWorkBalanceRecords: TimeOffBalanceRecord[];
  dataState: DataState;
};

export const useBalanceRecords = (userId?: UserId): ReturnShape => {
  const readAccess = useBalanceRecordsReadAccess(userId);
  const officePolicyFeature = useFeature(Feature.OFFICE_POLICY);

  const vacationBalanceRecords = useStoreMap({
    store: $balanceRecords,
    keys: [userId],
    fn: ({byUserId}, [id]) => (id && byUserId[id] ? byUserId[id].vacationBalanceRecords : []),
  });
  const remoteWorkBalanceRecords = useStoreMap({
    store: $balanceRecords,
    keys: [userId],
    fn: ({byUserId}, [id]) => (id && byUserId[id] ? byUserId[id].remoteWorkBalanceRecords : []),
  });
  const dataState = useStoreMap({
    store: $balanceRecords,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => (id && dataStateByUserId[id]) || DataState.IDLE,
  });

  useEffect(() => {
    if (userId && readAccess && !isLoadingOrLoaded(dataState)) {
      let handler;
      if (officePolicyFeature.isAvailable) {
        handler =
          readAccess === BalanceRecordsReadAccess.ANY
            ? getTimeOffBalanceRecordsByTypeFx
            : getMyTimeOffBalanceRecordsByTypeFx;
      } else {
        handler = readAccess === BalanceRecordsReadAccess.ANY ? getTimeOffBalanceRecords : getMyTimeOffBalanceRecords;
      }
      handler(userId).catch(toaster.interceptThenThrowError);
    }
  }, [userId, readAccess]);

  return {vacationBalanceRecords, remoteWorkBalanceRecords, dataState};
};
