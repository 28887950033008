/* eslint-disable @typescript-eslint/naming-convention */
import {Measure} from '../types';
import {constructMeasureConverter} from '..';

export enum FileSizeUnit {
  Byte = 'B',
  Kilobyte = 'KB',
  Megabyte = 'MB',
  Gigabyte = 'GB',
  Terabyte = 'TB',
}

export type FileSizeMeasure = Measure<FileSizeUnit>;

export const fileSizeUtils = constructMeasureConverter([
  {unit: FileSizeUnit.Byte, rate: 1},
  {unit: FileSizeUnit.Kilobyte, rate: 1e3},
  {unit: FileSizeUnit.Megabyte, rate: 1e6},
  {unit: FileSizeUnit.Gigabyte, rate: 1e9},
  {unit: FileSizeUnit.Terabyte, rate: 1e12},
]);
