import {loadSpace} from '@usersnap/browser';
import {config} from 'config';
import {UserFull} from 'domain/user/model';
import {Locale} from 'i18n/locale/types';
import React, {useContext} from 'react';
import {usersnapLogger} from 'services/logger';

export const UsersnapContext = React.createContext<[UserSnap | null, (api: UserSnap) => void]>([null, () => {}]);

export enum UserSnapEvents {
  OFFICE_MAP_USAGE = 'initiate_map_usage_feedback',
  OPEN_PROFILE = 'profile_open',
  CREATE_TIME_OFF_REQUEST = 'timeoff_request_created',
  CREATE_BUSINESS_TRIP_REQUEST = 'business_trip_request_created',
  USE_USER_TREE = 'use_user_tree',
  USE_SALARY_RANGES = 'use_salary_ranges',
  MANAGER_CONSOLE_FOR_SALARY_MANAGER = 'manager_console_for_salary_manager',
  MANAGER_CONSOLE_FOR_NON_SALARY_MANAGER = 'manager_console_for_non_salary_manager',
  MANAGER_CONSOLE_SUBORINATES_OPEN = 'manager_console_subordinates_open',
  MANAGER_CONSOLE_COMPENSATION_REVIEW_OPEN = 'manager_console_compensation_review_open',
}

export type UserSnap = Omit<Awaited<ReturnType<typeof loadSpace>>, 'logEvent'> & {
  logEvent: (eventName: UserSnapEvents) => Promise<void>;
};

export const loadUsersnapSpace = (onLoad: (api: UserSnap | null) => void, user: UserFull, locale: Locale) => {
  try {
    loadSpace(config.usersnapKey).then((api) => {
      const originalLogEvent = api.logEvent;

      api.logEvent = (eventName: UserSnapEvents): Promise<void> => {
        const event = `${window.intranetEnv.APP_ENV !== 'production' ? 'dev_' : ''}${eventName}`;
        return originalLogEvent(event);
      };

      onLoad(api);
      api.init({user: {email: user.email, userId: user.id}, locale});
    });
  } catch (e) {
    usersnapLogger.error('Usersnap: loading failed', e);
  }
};

export function useUsersnapApi(): UserSnap | undefined {
  const [usersnapApi] = useContext(UsersnapContext);
  if (!usersnapApi) {
    return undefined;
  }

  return usersnapApi;
}
