import {Props, StateHandler as BaseStateHandler} from '@joomcode/joom-ui/StateHandler';
import {ClientErrorStatus} from 'apiClient/ClientError';
import {PagePreloader} from 'components/ui/PagePreloader';
import {ErrorPage} from 'pages/Error';
import {NotFoundPage} from 'pages/NotFound';
import React from 'react';

type PageStateHandlerProps<Data> = Omit<Props<Data>, 'renderError' | 'renderPlaceholder'>;

export function PageStateHandler<Data>(props: PageStateHandlerProps<Data>) {
  return <BaseStateHandler renderError={ErrorPage} renderPlaceholder={PagePreloader} {...props} />;
}

type EntityPageStateHandlerProps<Data> = PageStateHandlerProps<Data> & {
  entityError?: ClientErrorStatus;
};

export function EntityPageStateHandler<Data>({entityError, ...restProps}: EntityPageStateHandlerProps<Data>) {
  if (entityError) {
    if (entityError === ClientErrorStatus.NOT_FOUND) {
      return <NotFoundPage />;
    }
    return <ErrorPage />;
  }
  return <PageStateHandler {...restProps} />;
}
