import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {UserId} from 'domain/user/model';
import {checkIsSubordinate} from 'domain/user/utils/checkIsSubordinate';
import {useMemo} from 'react';
import {useAllUsers} from './useAllUsers';

export function useIsSubordinate(userId: UserId): boolean {
  const selfUser = useSelfUser();
  const {userById} = useAllUsers();
  const users = useMemo(() => Object.values(userById), [userById]);

  return checkIsSubordinate(selfUser, userId, users);
}
