import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {Page} from '@joomcode/joom-ui/Page';
import {Panel} from '@joomcode/joom-ui/Panel';
import {useResources} from 'domain/resource/hooks/useResources';
import {resourceSearchFiltersSchema, ResourcesSearchFilters} from 'domain/resource/model';
import {ColumnId} from 'domain/resource/model/table/columnId';
import {ResourceCreationButton} from 'domain/resource/widgets/CreationButton';
import {ResourceCreationDialog} from 'domain/resource/widgets/CreationDialog';
import {ResourceFilters} from 'domain/resource/widgets/Filters';
import {ResourceTable} from 'domain/resource/widgets/Table';
import {useStoredFilters} from 'hooks/useStoredFilters';
import React from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/jdm/titles';

export function ResourcesPage() {
  const intl = useIntl();
  const {filterValues, setFilterValues} = useStoredFilters<ResourcesSearchFilters>({
    id: 'resource.filters',
    initialValues: {},
    valuesSchema: resourceSearchFiltersSchema,
  });
  const {resources, resourcesCount, dataState, pagination, setOutdated} = useResources(filterValues);
  const creationDialog = usePopupState();

  return (
    <Page
      title={intl.formatMessage(pageTitles.resources)}
      actions={<ResourceCreationButton onClick={creationDialog.open} />}
    >
      <Panel>
        <ResourceFilters values={filterValues} onChange={setFilterValues} />
      </Panel>
      <Panel>
        <ResourceTable
          resources={resources}
          dataState={dataState}
          columnIds={[ColumnId.SERVICE, ColumnId.DISPLAY_NAME, ColumnId.VALUE, ColumnId.STATUS, ColumnId.OWNERS]}
          pagination={pagination}
          count={resourcesCount}
        />
      </Panel>

      {creationDialog.isOpen && (
        <ResourceCreationDialog onClose={creationDialog.close} onSuccess={() => setOutdated(true)} />
      )}
    </Page>
  );
}
