import {balanceRecordsApi} from 'domain/timeOff/balanceRecord/api';
import {
  createTimeOffBalanceRecord,
  getMyTimeOffBalanceRecords,
  getMyTimeOffBalanceRecordsByTypeFx,
  getTimeOffBalanceRecords,
  getTimeOffBalanceRecordsByTypeFx,
} from '.';

getMyTimeOffBalanceRecordsByTypeFx.use(balanceRecordsApi.getAllTypesMy);
getTimeOffBalanceRecordsByTypeFx.use(balanceRecordsApi.getAllTypesByUserId);
getTimeOffBalanceRecords.use(balanceRecordsApi.getByUserId);
getMyTimeOffBalanceRecords.use(balanceRecordsApi.getMy);
createTimeOffBalanceRecord.use(balanceRecordsApi.create);
