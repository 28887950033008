import {PaymentControlsConfig} from 'components/widgets/PaymentControls';
import {OfferCompensation} from 'domain/offer/model/compensation';
import {BasicFormState} from 'domain/offer/widgets/Form';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type ReturnShape = {
  signOnBonusPaymentControlsConfig: PaymentControlsConfig;
  relocationBonusPaymentControlsConfig: PaymentControlsConfig;
};

export function usePaymentControlsConfigs<State extends BasicFormState>(compensation?: OfferCompensation): ReturnShape {
  const intl = useIntl();

  const signOnBonusPaymentControlsConfig: PaymentControlsConfig = useMemo(
    () => ({
      amount: {
        getValueFromFormState: (state: State) => state.compensation?.signOnBonus?.amount ?? null,
        gridItemBreakpoints: {xl: 4, sm: 6},
        label: intl.formatMessage(labels.signOnBonusAmount),
        name: 'compensation.signOnBonus.amount',
        initialValue: compensation?.signOnBonus?.amount,
      },
      currency: {
        getValueFromFormState: (state: State) => state.compensation?.signOnBonus?.currency ?? null,
        gridItemBreakpoints: {xl: 4, sm: 6},
        label: intl.formatMessage(labels.signOnBonusCurrency),
        name: 'compensation.signOnBonus.currency',
        initialValue: compensation?.signOnBonus?.currency,
      },
    }),
    [intl],
  );
  const relocationBonusPaymentControlsConfig: PaymentControlsConfig = useMemo(
    () => ({
      amount: {
        getValueFromFormState: (state: State) => state.compensation?.relocationBonus?.amount ?? null,
        gridItemBreakpoints: {xl: 4, sm: 6},
        label: intl.formatMessage(labels.relocationBonusAmount),
        name: 'compensation.relocationBonus.amount',
        initialValue: compensation?.relocationBonus?.amount,
      },
      currency: {
        getValueFromFormState: (state: State) => state.compensation?.relocationBonus?.currency ?? null,
        gridItemBreakpoints: {xl: 4, sm: 6},
        label: intl.formatMessage(labels.relocationBonusCurrency),
        name: 'compensation.relocationBonus.currency',
        initialValue: compensation?.relocationBonus?.currency,
      },
    }),
    [intl],
  );

  return {
    signOnBonusPaymentControlsConfig,
    relocationBonusPaymentControlsConfig,
  };
}
