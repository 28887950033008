import {DataState} from '@joomcode/deprecated-utils/dataState';
import {mimeType} from '@joomcode/deprecated-utils/file/mime';
import {FileDropArea} from '@joomcode/joom-ui/FileDropArea';
import {IntentionedText} from '@joomcode/joom-ui/IntentionedText';
import {Panel} from '@joomcode/joom-ui/Panel';
import {stockOptionBulkUploadApi} from 'domain/stockOption/bulkUpload/api';
import {StockOptionBulkUploadResult} from 'domain/stockOption/bulkUpload/model';
import {BulkUploadResultStatus} from 'domain/stockOption/bulkUpload/model/status';
import {useFileUpload} from 'hooks/useFileUpload';
import React, {useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

const ACCEPTED_MIME_TYPES = [mimeType.excel.xlsx];

type Props = {
  uploadResult?: StockOptionBulkUploadResult;
  onUpload(result: StockOptionBulkUploadResult | undefined): void;
};

export function StockOptionsBulkUploadPanel({uploadResult, onUpload}: Props) {
  const intl = useIntl();
  const [error, setError] = useState<string | undefined>(undefined);
  const onError = useCallback(({message}: Error) => setError(message), [setError]);

  const {onAttach, dataState} = useFileUpload<void, StockOptionBulkUploadResult>({
    upload: stockOptionBulkUploadApi.create,
    params: undefined,
    onComplete: onUpload,
    onError,
  });

  const onChange = useCallback(
    (files: File[]) => {
      const file = files[0];
      if (!file) {
        return;
      }
      if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
        setError(intl.formatMessage(messages.wrongFileType));
        return;
      }
      setError(undefined);
      onAttach(file);
    },
    [onAttach, setError, intl],
  );

  return (
    <Panel className={styles.root}>
      <Panel.Content withPadding>{intl.formatMessage(messages.annotation)}</Panel.Content>
      {(error || uploadResult?.error) && (
        <Panel.Content withPadding>
          <IntentionedText intent='negative'>{error || uploadResult?.error}</IntentionedText>
        </Panel.Content>
      )}
      {uploadResult?.status === BulkUploadResultStatus.CREATED && (
        <Panel.Content withPadding>{intl.formatMessage(messages.success)}</Panel.Content>
      )}
      {!uploadResult && (
        <Panel.Content withPadding>
          <FileDropArea
            accept={ACCEPTED_MIME_TYPES}
            multiple={false}
            onChange={onChange}
            processing={dataState === DataState.LOADING}
          />
        </Panel.Content>
      )}
    </Panel>
  );
}
