import {intranetApi} from 'apiClient';
import {
  CompensationRequest,
  CompensationRequestId,
  compensationRequestSchema,
} from 'domain/compensations/request/model';
import {Money} from 'domain/money/model';

export type CompensationRequestClosureDiff = {
  actualCompensationPaid: Money;
};

export const close = async ({
  diff,
  id,
}: {
  diff: CompensationRequestClosureDiff;
  id: CompensationRequestId;
}): Promise<CompensationRequest> => {
  const {data} = await intranetApi.post('/v1/compensations/admin/requests/close', diff, {
    params: {id},
  });

  return compensationRequestSchema.runWithException(data);
};
