import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {SortingDirection} from '@joomcode/deprecated-utils/sort/types';
import {Button} from '@joomcode/joom-ui/Button';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {ExternalLink} from '@joomcode/joom-ui/ExternalLink';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Permission} from 'domain/permission/model';
import {RedirectRoute, RedirectRouteId} from 'domain/redirectRoute/model';
import {deleteRedirectRouteFx} from 'domain/redirectRoute/stores/main';
import orderBy from 'lodash/orderBy';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {columnNames, messages} from './messages';
import styles from './styles.css';

type Props = {
  redirectRoutes: RedirectRoute[];
};

const getRowKey = (redirectRoute: RedirectRoute) => redirectRoute.id;
const sortRedirectRoutes = (redirectRoutes: RedirectRoute[]) =>
  orderBy(
    redirectRoutes,
    ['domain', 'path', 'isWildcard'],
    [SortingDirection.ASC, SortingDirection.ASC, SortingDirection.DESC],
  );

export function RedirectRoutesTable({redirectRoutes}: Props) {
  const intl = useIntl();
  const acl = useAcl();

  const deleteRedirectRouteTask = useAsyncTask(
    (id: RedirectRouteId) =>
      deleteRedirectRouteFx({id})
        .then(() => undefined)
        .catch(toaster.interceptThenThrowError),
    [redirectRoutes],
  );

  const onDeleteClick = useCallback(
    (id: RedirectRouteId) =>
      confirm(
        {
          title: intl.formatMessage(messages.confirmDeleteTitle),
          text: intl.formatMessage(messages.confirmDeleteText),
          confirmationText: intl.formatMessage(messages.deleteButton),
          intent: 'negative',
          onConfirm: () => deleteRedirectRouteTask.perform(id),
        },
        intl,
      ),
    [deleteRedirectRouteTask, intl],
  );

  const columns: Column<RedirectRoute>[] = useMemo(() => {
    const basicColumns: Column<RedirectRoute>[] = [
      {
        id: 'short',
        name: intl.formatMessage(columnNames.short),
        defaultWidth: 20,
        render: ({domain, path, isWildcard}) => (
          <>
            <b>{domain}</b>
            {path}
            {isWildcard && <span className={styles.wildcardAsterisk}>*</span>}
          </>
        ),
      },
      {
        id: 'target',
        name: intl.formatMessage(columnNames.target),
        defaultWidth: 50,
        render: ({target, isWildcard}) => (
          <>
            <ExternalLink href={target}>{target}</ExternalLink>
            {isWildcard && <span className={styles.wildcardAsterisk}>*</span>}
          </>
        ),
      },
    ];

    const deleteColumn: Column<RedirectRoute> = {
      id: 'delete',
      name: '',
      defaultWidth: 10,
      align: 'right',
      render: ({id}) => (
        <Button
          size='m'
          kind='primary'
          intent='negative'
          onClick={() => onDeleteClick(id)}
          loading={deleteRedirectRouteTask.isPerforming}
        >
          {intl.formatMessage(messages.deleteButton)}
        </Button>
      ),
    };

    return acl.hasPermission(Permission.REDIRECT_WRITE) ? [...basicColumns, deleteColumn] : basicColumns;
  }, [intl]);

  const sortedRedirectRoutes = useMemo(() => sortRedirectRoutes(redirectRoutes), [redirectRoutes]);

  return (
    <Panel>
      <DataTable
        columns={columns}
        dataState={DataState.LOADED}
        data={sortedRedirectRoutes}
        getRowKey={getRowKey}
        storageName='redirectRoutesList'
      />
    </Panel>
  );
}
