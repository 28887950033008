import {HintIcon} from 'components/ui/HintIcon';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {Stay} from 'domain/compensations/stay/model';
import {stringToDate} from 'models/system/formattedDate';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  disabled?: boolean;
  fieldName: string;
  label: string;
  stayValues: Partial<Stay>;
  minDate?: Date;
  maxDate?: Date;
} & ({selectsStart: true; selectsEnd?: false} | {selectsStart?: false; selectsEnd: true});

export function StayDateControl({
  disabled,
  fieldName,
  label,
  maxDate,
  minDate,
  selectsEnd,
  selectsStart,
  stayValues,
}: Props) {
  const intl = useIntl();
  const startDate = stringToDate(stayValues.startDate ?? '');
  const endDate = stringToDate(stayValues.endDate ?? '');

  return (
    <div className={styles.root}>
      <FieldDate
        name={fieldName}
        label={
          <>
            {label}
            <HintIcon>{intl.formatMessage(messages.staysDateFieldHint)}</HintIcon>
          </>
        }
        reserveSpaceForError={false}
        required
        disabled={disabled}
        startDate={startDate ?? undefined}
        endDate={endDate ?? undefined}
        maxDate={maxDate}
        minDate={minDate}
        selectsEnd={selectsEnd}
        selectsStart={selectsStart}
      />
    </div>
  );
}
