import VendorTippy from '@tippy.js/react';
import type {TippyProps} from '@tippy.js/react';
import 'tippy.js/themes/light.css'; // eslint-disable-line import/no-extraneous-dependencies
import React from 'react';

export type {TippyProps};

export function Tippy({arrow = true, ...props}: TippyProps) {
  return <VendorTippy animation='fade' arrow={arrow} theme='light' animateFill={false} {...props} />;
}
