import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Not found',
    description: '[title] Page title',
  },
  description: {
    defaultMessage: 'Not found',
    description: 'Not found description',
  },
});
