import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonAddFurtherSalary: {
    defaultMessage: 'Add further salary',
    description: '[button] Adding further salary button text',
  },
  buttonAddStockOptions: {
    defaultMessage: 'Add options',
    description: '[button] Adding options button text',
  },
});
