import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Options',
    description: '[title] Offer form section title',
  },
  buttonRemove: {
    defaultMessage: 'Remove options',
    description: '[button] Removing options button text',
  },
});
