import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {SecurePermission} from 'domain/permission/model/secure';
import {UserFullInfoRecord, userFullInfoRecordSchema} from 'domain/userFullInfoRecord/model';

const getGeneric = (endpoint: string) => async (): Promise<UserFullInfoRecord[]> => {
  const {data} = await intranetApi.post(endpoint, {});

  return array(userFullInfoRecordSchema).runWithException(data);
};

const getSubordinates = getGeneric('/v1/secure/users/fullInfoRecords/getSubordinates');
const getAll = getGeneric('/v1/secure/users/fullInfoRecords/getAll');

export const get = createPermissionDependentHandler(
  [{securePermission: SecurePermission.USER_FULL_INFO_RECORD_READ, handler: getAll}],
  getSubordinates,
);
