import React from 'react';
import cn from 'classnames';
import {capitalize} from '@joomcode/deprecated-utils/string/capitalize';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../../../create-locator';
import {CloseButton, type CloseButtonLocator} from '../../../TagCloseButton';
import {IconCaret} from '../IconCaret';
import styles from './index.css';

export enum InputSizeMeasure {
  L = 'l',
  M = 'm',
  XL = 'xl',
}

export type SelectControlTestId = ConvertLocatorToTestId<SelectControlLocator>;
export type SelectControlLocator = LocatorOfElement<{
  closeButton: CloseButtonLocator;
}>;

type Props = Omit<JSX.IntrinsicElements['div'], 'className'> & {
  isOpen: boolean;
  borderless?: boolean;
  disabled: boolean;
  inputSize?: `${InputSizeMeasure}`;
  isActive: boolean;
  clearable?: boolean;
  invalid?: boolean;
  onClearClick?: () => void;
  preview?: React.ReactNode;
} & Partial<Mark<SelectControlLocator>>;

export function SelectControl({
  borderless = false,
  disabled,
  inputSize = 'm',
  invalid,
  isActive,
  isOpen,
  children,
  clearable,
  onClearClick,
  preview,
  ...restPropertiesWithMark
}: Props) {
  const locator = createLocator(restPropertiesWithMark);
  const restProps = removeMarkFromProperties(restPropertiesWithMark);

  return (
    <div
      {...restProps}
      className={cn(styles.control, styles[`inputSize${capitalize(inputSize)}` as keyof typeof styles], {
        [styles.controlActive]: isActive,
        [styles.controlDisabled]: disabled,
        [styles.controlInvalid]: invalid,
        [styles.controlBorderless]: borderless,
        [styles.controlWithPreview]: preview,
      })}
      {...locator()}
    >
      {children}
      {clearable && (
        <CloseButton
          {...locator.closeButton()}
          onClick={(event) => {
            event.stopPropagation(); // от срабатываний событий инпута, который находится под кнопкой
            if (onClearClick) {
              onClearClick();
            }
          }}
        />
      )}
      <div className={styles.caret}>
        <IconCaret disabled={disabled} isActive={isActive} isOpen={isOpen} />
      </div>
    </div>
  );
}
