import {Frequency} from 'domain/frequency/model';
import {SalaryRecord} from 'domain/salaryRecord/model';

export function getMonthlySalary(salary: SalaryRecord): SalaryRecord | undefined {
  switch (salary.frequency) {
    case Frequency.PER_MONTH:
      return salary;
    case Frequency.PER_YEAR:
      return {
        ...salary,
        amount: salary.amount / 12,
        frequency: Frequency.PER_MONTH,
      };
    default:
      return undefined;
  }
}
