import {intranetApi} from 'apiClient';
import {UserRole, userRoleSchema} from 'domain/userRole/model';

export type UpdateRoleParams = {
  id: string;
  roleData: Partial<UserRole>;
};

export const update = async ({id, roleData}: UpdateRoleParams): Promise<UserRole> => {
  const {data} = await intranetApi.post('/v1/userRoles/update', roleData, {params: {userRoleId: id}});

  return userRoleSchema.runWithException(data);
};
