import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  dates: {
    defaultMessage: 'from {from} {to, select, undefined {} other { to {to}}}',
    description: 'Compensation review request: regular bonus: dates',
  },
  current: {
    defaultMessage: 'current',
    description: 'Compensation review request: regular bonus: current label',
  },
  new: {
    defaultMessage: 'new',
    description: 'Compensation review request: regular bonus: new label',
  },
});
