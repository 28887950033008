import {defineMessages} from 'react-intl';
import {EducationLevel} from '.';

export const educationLevelLabels = defineMessages<EducationLevel>({
  [EducationLevel.SECONDARY_EDUCATION]: {
    defaultMessage: 'Secondary education, including technical',
    description: 'Education level',
  },
  [EducationLevel.INCOMPLETE_HIGHER_EDUCATION]: {
    defaultMessage: 'Incomplete higher education',
    description: 'Education level',
  },
  [EducationLevel.BACHELOR]: {
    defaultMessage: "Bachelor's degree",
    description: 'Education level',
  },
  [EducationLevel.MASTER]: {
    defaultMessage: "Master's degree",
    description: 'Education level',
  },
  [EducationLevel.SPECIALIST]: {
    defaultMessage: 'Specialist degree',
    description: 'Education level',
  },
  [EducationLevel.PHD]: {
    defaultMessage: 'PhD and higher',
    description: 'Education level',
  },
});
