import {addDays, addMonths, addYears, isWeekend, startOfMonth} from 'date-fns';
import {dateToString} from 'models/system/formattedDate';

export const getClosestBusinessDayInNextMonth = (date: Date): Date => {
  let closestBusinessDay = startOfMonth(addMonths(date, 1));

  while (isWeekend(closestBusinessDay)) {
    closestBusinessDay = addDays(closestBusinessDay, 1);
  }

  return closestBusinessDay;
};

export const addYear = (date: string) => dateToString(addYears(new Date(date), 1));
