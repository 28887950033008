import {QueryRequestPayload} from 'api/types';
import {stockOptionHoldersApi} from 'domain/stockOption/holder/api';
import {StockOptionHolder} from 'domain/stockOption/holder/model';
import {
  AllStockOptionHoldersFilters,
  SubordinateStockOptionHoldersFilters,
} from 'domain/stockOption/holder/model/filters';
import {
  getAllStockOptionHoldersFx,
  getAllStockOptionHoldersWithCountFx,
  getSubordinateStockOptionHoldersFx,
  getSubordinateStockOptionHoldersWithCountFx,
} from '.';

const getAllStockOptionHoldersWithoutCount = async (
  query: QueryRequestPayload<AllStockOptionHoldersFilters>,
): Promise<StockOptionHolder[]> => {
  const {items} = await getAllStockOptionHoldersWithCountFx(query);
  return items;
};

const getSubordinateStockOptionHoldersWithoutCount = async (
  query: QueryRequestPayload<SubordinateStockOptionHoldersFilters>,
): Promise<StockOptionHolder[]> => {
  const {items} = await getSubordinateStockOptionHoldersWithCountFx(query);
  return items;
};

getAllStockOptionHoldersWithCountFx.use(stockOptionHoldersApi.getAll);
getAllStockOptionHoldersFx.use(getAllStockOptionHoldersWithoutCount);
getSubordinateStockOptionHoldersWithCountFx.use(stockOptionHoldersApi.getSubordinates);
getSubordinateStockOptionHoldersFx.use(getSubordinateStockOptionHoldersWithoutCount);
