import {LocalesConfig} from '@joomcode/deprecated-utils/intl/locale';
import {Locale} from 'i18n/locale/types';

const PROD_GTAG_ID = 'G-6Y1751S7M6';
const DEV_GTAG_ID = 'G-WDL7MLPRQ4';

type Config = {
  usersnapKey: string;
  sentryDsn: string;
  defaultLocale: Locale;
  gtagId: string;
  locales: LocalesConfig<Locale>;
  version: string;
};

export const config: Config = {
  usersnapKey: '0730e4e0-042a-4ae7-92b3-554960e2f813',
  sentryDsn: 'https://80ac9e10e0f40cd31cc495e1257d4e97@sentry.joom.it/16',
  defaultLocale: Locale.EN,
  gtagId: window.intranetEnv.APP_ENV === 'production' ? PROD_GTAG_ID : DEV_GTAG_ID,
  locales: {
    [Locale.EN]: {
      aliases: ['en'],
      title: 'English',
    },
  },
  version: __APP_BUILD_VERSION__,
};
