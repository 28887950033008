import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {TimeOffCreateRequestPage} from 'pages/TimeOffCreateRequest';
import {TimeOffCreateRequestOnBehalfPage} from 'pages/TimeOffCreateRequestOnBehalf';
import {TimeOffRequestsPage} from 'pages/TimeOffRequests';
import React from 'react';
import {RouteConfig} from 'react-router-config';
import {Redirect} from 'react-router-dom';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {timeOffUrlPatterns, timeOffUrls} from './urls';

export const config: RouteConfig = {
  path: timeOffUrlPatterns.root,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: withPermission(TimeOffCreateRequestPage, Permission.SELF_TIME_OFF_REQUEST_WRITE),
      exact: true,
      path: timeOffUrlPatterns.createRequest,
    },
    {
      component: withPermission(TimeOffCreateRequestOnBehalfPage, Permission.TIME_OFF_REQUEST_WRITE_ON_BEHALF),
      exact: true,
      path: timeOffUrlPatterns.createRequestOnBehalf,
    },
    {
      component: withPermission(TimeOffRequestsPage, Permission.TIME_OFF_REQUEST_ENABLED),
      exact: true,
      path: timeOffUrlPatterns.requests,
    },
    {
      component: () => <Redirect to={timeOffUrls.requests()} />,
      exact: true,
      path: timeOffUrlPatterns.root,
    },
    notFoundRouteConfig,
  ],
};
