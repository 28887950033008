import {User, UserId} from 'domain/user/model';

export function getAncestorIds(usersById: Record<UserId, User>, selectedUserId?: UserId) {
  const result = new Set();
  if (!selectedUserId) {
    return result;
  }

  let ancestorId = usersById[selectedUserId]?.managerId;
  while (ancestorId) {
    if (result.has(ancestorId)) {
      break;
    }
    result.add(ancestorId);
    ancestorId = usersById[ancestorId]?.managerId;
  }

  return result;
}
