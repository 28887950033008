import cn from 'classnames';
import React, {forwardRef, useMemo} from 'react';
import uuid from 'uuid/v1';
import {isFunction} from '@joomcode/deprecated-utils/guards';
import {CloseButton, type CloseButtonLocator} from '../TagCloseButton';
import {InputAutosize, InputAutosizeLocator} from '../InputAutosize';
import {Tag, TagLocator} from '../Tag';
import {
  createLocator,
  removeMarkFromProperties,
  type ConvertLocatorToTestId,
  type Locator,
  type Mark,
} from '../create-locator';
import {InputTokensContext, useInputTokensContext} from './context';
import styles from './index.css';

export type InputTokensTestId = ConvertLocatorToTestId<InputTokensLocator>;
export type InputTokensLocator = Locator<{
  tag: TagLocator;
  label: void;
  clearButton: CloseButtonLocator;
}>;

type Props<Token> = {
  ariaLabel: string;
  borderless?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  iconRight?: React.ReactNode;
  id?: string;
  invalid?: boolean;
  name?: string;
  onChange(tokens: Token[]): void;
  renderToken?: (token: Token, index?: number) => JSX.Element | string;
  tokenToString(token: Token): string;
  noTag?: boolean;
  value: Token[];
  onClear?: () => void;
} & Partial<Mark<InputTokensLocator>>;

export function InputTokens<Token>({
  ariaLabel,
  borderless,
  children,
  disabled,
  iconRight,
  id: externalId,
  invalid = false,
  name,
  onChange,
  onClear,
  tokenToString,
  renderToken = tokenToString,
  value,
  noTag = false,
  ...restProperties
}: Props<Token>) {
  const locator = createLocator(restProperties);

  const internalId = useMemo(uuid, []);
  const id = externalId || internalId;

  const contextValue = useMemo(() => ({id, name, disabled}), [id, name, disabled]);
  const showClear = isFunction(onClear);

  return (
    <div
      className={cn(styles.control, {
        [styles.controlDisabled]: disabled,
        [styles.controlBorderless]: borderless,
        [styles.controlIconRight]: iconRight,
        [styles.controlInvalid]: invalid,
        [styles.controlHasIconRight]: iconRight || showClear,
      })}
      {...locator()}
    >
      <label htmlFor={id} className={styles.label} {...locator.label()}>
        {ariaLabel}

        {iconRight && <span className={styles.iconRight}>{iconRight}</span>}
        {showClear && (
          <span className={styles.iconRight}>
            <CloseButton onClick={onClear} />
          </span>
        )}
      </label>

      <InputTokensContext.Provider value={contextValue}>{children}</InputTokensContext.Provider>

      {value.map((token, index) =>
        noTag ? (
          renderToken(token, index)
        ) : (
          <Tag
            key={tokenToString(token)}
            disabled={disabled}
            onRemove={() => onChange(value.filter((item) => item !== token))}
            {...locator.tag()}
          >
            {renderToken(token, index)}
          </Tag>
        ),
      )}
    </div>
  );
}

InputTokens.Input = forwardRef<
  HTMLInputElement,
  Omit<JSX.IntrinsicElements['input'], 'className'> & Partial<Mark<InputAutosizeLocator>>
>((propertiesWithMark, ref) => {
  const {id, disabled, name} = useInputTokensContext();
  const props = removeMarkFromProperties(propertiesWithMark);

  return <InputAutosize {...props} className={styles.input} id={id} name={name} disabled={disabled} ref={ref} />;
});
