import React, {ForwardedRef, forwardRef, useLayoutEffect} from 'react';
import {FixedSizeList, ListOnScrollProps} from 'react-window';
import {DataTableVirtualizationOptions} from '../../types';
import {ListProps} from './types';
import {ManualScrollWrapper, ScrollWrapper} from './ScrollWrapper';
import {InnerTable} from './InnerTable';

type Props<Item> = ListProps<Item> & {virtualization: DataTableVirtualizationOptions};

export const VirtualizedList = forwardRef(function VirtualizedList<Item>(
  {
    items,
    summaryPosition,
    renderRow,
    summaryRow,
    virtualization: {rowHeight, summaryRowHeight = rowHeight, maxVisibleRows, manual},
  }: Props<Item>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const componentRef = React.useRef<FixedSizeList>(null);
  const hasSummaryRow = summaryRow !== undefined;

  const itemCount = hasSummaryRow ? items.length + 1 : items.length;

  const getItem = (index: number) => {
    if (hasSummaryRow && summaryPosition === 'top') {
      return items[index - 1];
    }

    return items[index];
  };

  const height = Math.min(items.length, maxVisibleRows) * rowHeight + (hasSummaryRow ? summaryRowHeight : 0);

  useLayoutEffect(() => {
    if (manual && componentRef.current) {
      componentRef.current.setState(
        (prevState: ListOnScrollProps): Partial<ListOnScrollProps> => ({
          scrollOffset: manual?.scrollOffset,
          scrollDirection: prevState.scrollOffset < manual?.scrollOffset ? 'forward' : 'backward',
        }),
      );
    }
  }, [manual?.scrollOffset]);

  return (
    <FixedSizeList
      ref={componentRef}
      width='auto'
      outerRef={ref}
      outerElementType={manual ? ManualScrollWrapper : ScrollWrapper}
      innerElementType={InnerTable}
      height={height}
      itemCount={itemCount}
      itemSize={rowHeight}
      overscanCount={Math.floor(maxVisibleRows / 2)}
    >
      {({index, style}) => {
        const finalStyle = {...style, width: 'auto'};

        if (
          hasSummaryRow &&
          ((summaryPosition === 'top' && index === 0) || (summaryPosition === 'bottom' && index === itemCount - 1))
        ) {
          return (
            <div key='data-table-summary' style={finalStyle}>
              {summaryRow}
            </div>
          );
        }

        const item = getItem(index);

        return renderRow(item, finalStyle);
      }}
    </FixedSizeList>
  );
});
