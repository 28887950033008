/* eslint-disable @typescript-eslint/naming-convention */
import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {useForceUpdate} from '@joomcode/deprecated-utils/react/useForceUpdate';
import {useOnResize} from '@joomcode/deprecated-utils/react/useOnResize';
import React, {useMemo, useCallback, useRef} from 'react';
import {ContextPopup, ContextPopupProps} from '../../ContextPopup';
import {createLocator, type Mark} from '../../create-locator';
import {Label} from '../Label';
import type {FilterItem, FilterItemWithTransform, FilterLocator, Value} from '../types';

type ConfigSimple<Inner> = FilterItem<Inner>;
type ConfigWithTransform<Inner, Outer> = FilterItemWithTransform<FilterItem<Inner>, Outer>;

export type Props<InnerValue> = {
  initiallyOpen: boolean;
  config: ConfigSimple<InnerValue>;
  dropdownPlacement: ContextPopupProps['placement'];
  value: Value<InnerValue>;
  onChange: (value: Value<InnerValue>) => void;
  onRemove: () => void;
};

type PropsWithTransform<InnerValue, OuterValue> = Omit<Props<OuterValue>, 'config'> & {
  config: ConfigWithTransform<InnerValue, OuterValue>;
};

function transparentTransform<From, To>(value: From): To {
  return value as unknown as To;
}

function configHasTransform<Inner, Outer>(
  config: ConfigSimple<Inner> | ConfigWithTransform<Inner, Outer>,
): config is ConfigWithTransform<Inner, Outer> {
  return Boolean(
    (config as ConfigWithTransform<Inner, Outer>).transformValue &&
      (config as ConfigWithTransform<Inner, Outer>).preprocessValueBeforeSubmit,
  );
}

export function Filter<InnerValue>(props: Props<InnerValue>): JSX.Element;

export function Filter<InnerValue, OuterValue>(props: PropsWithTransform<InnerValue, OuterValue>): JSX.Element;

export function Filter<InnerValue, OuterValue = InnerValue>({
  config,
  dropdownPlacement,
  initiallyOpen,
  value: valueProp,
  onChange: onChangeProp,
  onRemove,
}: PropsWithTransform<InnerValue, OuterValue>) {
  const maybeFilterLocator = createLocator(config.options as Mark<FilterLocator>);

  const popup = usePopupState(initiallyOpen);
  const {name, options = {}, renderControl, renderLabel, isEmpty, getSelectedAmount} = config;
  const {renderEmpty} = options;

  const containerRef = useRef<HTMLDivElement>(null);
  const forceUpdate = useForceUpdate();
  useOnResize(containerRef, forceUpdate, [popup.isOpen]);

  let transformValue: ConfigWithTransform<InnerValue, OuterValue>['transformValue'];
  let preprocessValueBeforeSubmit: ConfigWithTransform<InnerValue, OuterValue>['preprocessValueBeforeSubmit'];

  if (configHasTransform(config)) {
    transformValue = config.transformValue;
    preprocessValueBeforeSubmit = config.preprocessValueBeforeSubmit;
  } else {
    transformValue = transparentTransform;
    preprocessValueBeforeSubmit = transparentTransform;
  }

  const value = useMemo(() => transformValue(valueProp), [valueProp, transformValue]);
  const onChange = useCallback(
    (innerValue: Value<InnerValue>) => onChangeProp(preprocessValueBeforeSubmit(innerValue)),
    [preprocessValueBeforeSubmit, onChangeProp],
  );

  const isEmptyValue = isEmpty(value);

  const selectedAmount = getSelectedAmount ? getSelectedAmount(value) : undefined;

  const removeIfEmpty = (val: Value<InnerValue>) => {
    if (isEmpty(val)) {
      onRemove();
    }
  };

  return (
    <ContextPopup
      closeOnInsideClick={false}
      isOpen={popup.isOpen}
      content={
        popup.isOpen ? (
          <div ref={containerRef}>
            {renderControl({
              name,
              value,
              options,
              onSubmit: (submitValue: Value<InnerValue>) => {
                if (isEmpty(submitValue)) {
                  onRemove();
                } else {
                  onChange(submitValue);
                }
                popup.close();
              },
              onClose: () => {
                removeIfEmpty(value);
                popup.close();
              },
            })}
          </div>
        ) : (
          <div />
        )
      }
      placement={dropdownPlacement}
      onClose={() => {
        removeIfEmpty(value);
        popup.close();
      }}
    >
      <Label
        active={popup.isOpen}
        filled={!isEmptyValue || !!renderEmpty}
        selectedAmount={selectedAmount}
        onClear={isEmptyValue && renderEmpty ? undefined : onRemove}
        onClick={popup.open}
        {...maybeFilterLocator?.label()}
      >
        {({ValueWrapper}) => {
          if (isEmptyValue && !popup.isOpen && !renderEmpty) {
            return name;
          }
          if (isEmptyValue && !popup.isOpen && renderEmpty) {
            return renderEmpty({name, ValueWrapper});
          }
          return renderLabel({name, options, value, ValueWrapper});
        }}
      </Label>
    </ContextPopup>
  );
}
