import {
  createLocator,
  removeMarkFromProperties,
  type Mark,
  type RemoveMarkFromProperties,
} from '@joomcode/joom-ui/create-locator';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Input, InputProps} from '@joomcode/joom-ui/Input';
import React, {memo, RefObject} from 'react';
import {useIntl} from 'react-intl';
import {useFieldWithInitialValue} from '../../components';
import {getFieldErrorText} from '../../utils';
import {bindValidatorOptions, validateFilled, composeValidators, getFieldValidator} from '../../validation';
import {CommonFieldProps, FieldInputLocator} from '../types';

export type FieldInputProps = Omit<RemoveMarkFromProperties<InputProps>, 'ref'> &
  CommonFieldProps<string> & {
    parse?: (value: unknown, name: string) => string;
    // eslint-disable-next-line react/no-unused-prop-types
    inputRef?: RefObject<HTMLInputElement>;
  } & Partial<Mark<FieldInputLocator>>;

export const FieldInput = memo(function FieldInput({
  disabled,
  error,
  hint,
  label,
  labelHint,
  name,
  required,
  reserveSpaceForError,
  validate,
  validateFields,
  initialValue,
  inputRef,
  extraErrorMessages,
  parse,
  ...rest
}: FieldInputProps) {
  const locator = createLocator(rest);
  const inputProps = removeMarkFromProperties(rest);
  const intl = useIntl();

  const validateRequired = required ? bindValidatorOptions(validateFilled, {trimBeforeCheck: true}) : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {input, meta} = useFieldWithInitialValue<string>(name, {
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    validateFields,
    initialValue,
    parse,
  });

  return (
    <FormControl
      {...locator.formControl()}
      hint={hint}
      label={label}
      labelHint={labelHint}
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <Input
          {...formControlProps}
          {...input}
          {...inputProps}
          {...locator.input()}
          disabled={meta.submitting || disabled}
          ref={inputRef}
        />
      )}
    </FormControl>
  );
});
