import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {UserRolePage} from 'pages/UserRole';
import {UserRoleCreatePage} from 'pages/UserRoleCreate';
import {UserRolesPage} from 'pages/UserRoles';
import {RouteConfig} from 'react-router-config';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {userRolesUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: userRolesUrlPatterns.root,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: withPermission(UserRolesPage, Permission.USER_ROLE_READ),
      exact: true,
      path: userRolesUrlPatterns.root,
    },
    {
      component: withPermission(UserRoleCreatePage, Permission.USER_ROLE_WRITE),
      exact: true,
      path: userRolesUrlPatterns.create,
    },
    {
      component: withPermission(UserRolePage, Permission.USER_ROLE_READ),
      exact: true,
      path: userRolesUrlPatterns.item,
    },
    notFoundRouteConfig,
  ],
};
