import {Callout} from '@joomcode/joom-ui/Callout';
import {Panel} from '@joomcode/joom-ui/Panel';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function BookingCallout() {
  const intl = useIntl();

  return (
    <Panel>
      <Callout intent='warning' withIcon>
        {intl.formatMessage(messages.text)}
      </Callout>
    </Panel>
  );
}
