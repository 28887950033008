import {CrResult} from 'domain/compaRatio/model';
import {UserFullInfoRecord} from 'domain/userFullInfoRecord/model';
import {getSalaryRelatedEntities} from 'domain/userFullInfoRecord/utils/getSalaryRelatedEntities';
import {SalaryRelatedEntity} from 'domain/userFullInfoRecord/widgets/SalaryRelatedEntity';
import React from 'react';

type Props = {
  record: UserFullInfoRecord;
  renderValue: (cr: CrResult | undefined) => React.ReactNode;
};

export function SalaryCrValue({record, renderValue}: Props) {
  const crResults = getSalaryRelatedEntities(record, record.cr);

  return <SalaryRelatedEntity entities={crResults} renderValue={renderValue} />;
}
