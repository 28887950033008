import {FieldDate} from 'components/widgets/fields/FieldDate';
import {StockOptionAgreementFreeze} from 'domain/stockOption/agreementFreeze/model';
import React, {FormEventHandler} from 'react';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  freeze?: StockOptionAgreementFreeze;
  onSubmit: FormEventHandler<HTMLFormElement>;
  formId: string;
  required?: boolean;
};

export function StockOptionAgreementFreezeForm({freeze, onSubmit, formId, required}: Props) {
  const intl = useIntl();

  return (
    <form onSubmit={onSubmit} id={formId}>
      <FieldDate
        name='freezeDate'
        label={intl.formatMessage(labels.freezeDate)}
        initialValue={freeze?.startDate}
        required={required}
        showClearButton={!required}
      />
    </form>
  );
}
