import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Spinner} from '@joomcode/joom-ui/Spinner';
import {SecureRole} from 'domain/secureRole/model';
import {addSecureRoleToUserFx} from 'domain/secureRole/stores/main';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {User, UserId} from 'domain/user/model';
import {UserAutocomplete} from 'domain/user/widgets/Autocomplete';
import {generalMessages} from 'i18n/messages/general';
import React, {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  role: SecureRole;
};

export function SecureRoleIssue({role}: Props) {
  const intl = useIntl();

  const {dataState, userByLogin} = useAllUsers();
  const [activeUser, setActiveUser] = useState<User | null>(null);

  const issueRoleToUser = useAsyncTask(
    async (user: User) =>
      addSecureRoleToUserFx({userId: user.id, secureRoleId: role.id})
        .then(() => {
          setActiveUser(null);
        })
        .catch(toaster.interceptThenThrowError),
    [role],
  );

  const onIssueClick = useCallback(
    (user: User | null) => {
      setActiveUser(user);

      if (user === null) {
        return;
      }

      confirm(
        {
          title: intl.formatMessage(messages.confirmTitle),
          text: intl.formatMessage(messages.confirmText, {
            fullName: intl.formatMessage(generalMessages.fullName, {
              firstName: user.firstName,
              lastName: user.lastName,
            }),
            login: user.login,
            role: role.title,
          }),
          confirmationText: intl.formatMessage(messages.confirmApprove),
          onConfirm: () => issueRoleToUser.perform(user),
        },
        intl,
      );
    },
    [issueRoleToUser, role, intl],
  );

  const holders: Set<UserId> = useMemo(() => new Set<UserId>(role.holders.map((holder) => holder.id)), [role.holders]);

  const users = useMemo(
    () => Object.values(userByLogin).filter((user) => !holders.has(user.id)),
    [userByLogin, holders],
  );

  return (
    <Panel title={intl.formatMessage(messages.title)} withPadding>
      {dataState === DataState.LOADING && <Spinner />}
      {dataState === DataState.FAILED && <Error message={intl.formatMessage(messages.loadUserFail)} />}
      {dataState === DataState.LOADED && (
        <div className={styles.wrapper}>
          <UserAutocomplete items={users} value={activeUser} onChange={onIssueClick} clearable />
        </div>
      )}
    </Panel>
  );
}
