import {ListedProperty} from 'components/ui/ListedProperty';
import {Section} from 'components/ui/Section';
import {HrOnlyHint} from 'components/widgets/HrOnlyHint';
import {Address} from 'domain/address/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {getCountryName} from 'utils/country/name';
import styles from '../styles.css';
import {labels} from './messages';

export type Props = {
  address: Address;
  title: string;
};

export function AddressSection({address, title}: Props) {
  const intl = useIntl();
  return (
    <Section
      title={
        <>
          {title}
          <HrOnlyHint />
        </>
      }
      bordered
    >
      {address.country && (
        <ListedProperty label={intl.formatMessage(labels.country)}>
          {getCountryName(address.country, intl)}
        </ListedProperty>
      )}
      {(address.city || address.postalCode) && (
        <div className={styles.columned}>
          {address.city && <ListedProperty label={intl.formatMessage(labels.city)}>{address.city}</ListedProperty>}
          {address.postalCode && (
            <ListedProperty label={intl.formatMessage(labels.postalCode)}>{address.postalCode}</ListedProperty>
          )}
        </div>
      )}
      {address.streetAddress && (
        <ListedProperty label={intl.formatMessage(labels.streetAddress)}>{address.streetAddress}</ListedProperty>
      )}
      {address.comments && (
        <ListedProperty label={intl.formatMessage(labels.comments)}>{address.comments}</ListedProperty>
      )}
    </Section>
  );
}
