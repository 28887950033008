import {array, boolean, ExtractSchemaType, object} from '@joomcode/deprecated-utils/jsonValidation';
import {serverPermissionSchema} from 'domain/permission/model';
import {serverSecurePermissionSchema} from 'domain/permission/model/secure';
import {userFullSchema} from 'domain/user/model';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const selfSchema = object({
  user: userFullSchema,
  permissions: array(serverPermissionSchema),
  securePermissions: optionalWithDefault([], array(serverSecurePermissionSchema)),
  isStockOptionHolder: optionalWithDefault(false, boolean()),
});

export type Self = ExtractSchemaType<typeof selfSchema>;
