import {Badge, BadgeIntent} from '@joomcode/joom-ui/Badge';
import {RoleBindingStatus} from 'domain/roleBinding/model/status';
import {roleBindingStatuses} from 'domain/roleBinding/model/status/messages';
import React from 'react';
import {useIntl} from 'react-intl';

type Props = {
  status: RoleBindingStatus;
};

const intentByStatus: Record<RoleBindingStatus, BadgeIntent> = {
  [RoleBindingStatus.APPROVED]: 'positive',
  [RoleBindingStatus.CANCELED]: 'neutral',
  [RoleBindingStatus.PENDING]: 'neutral',
  [RoleBindingStatus.REJECTED]: 'negative',
  [RoleBindingStatus.REVOKED]: 'negative',
};

export function RoleBindingStatusBadge({status}: Props) {
  const intl = useIntl();
  return <Badge intent={intentByStatus[status]}>{intl.formatMessage(roleBindingStatuses[status])}</Badge>;
}
