import {Offer} from 'domain/offer/model';
import {OfferStockOptionsAccess} from 'domain/offer/model/stockOption/access';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {useAcl} from 'services/acl';

export function useOfferStockOptionsAccess(offer: Offer | null): OfferStockOptionsAccess | null {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const hasFullAccess =
    (selfUserId === offer?.general.recruiter?.id &&
      acl.hasSecurePermission(SecurePermission.OFFER_STOCK_OPTION_READ_MY)) ||
    acl.hasSecurePermission(SecurePermission.OFFER_STOCK_OPTION_READ_ANY);
  const hasExistenceAccess = acl.hasSecurePermission(SecurePermission.OFFER_STOCK_OPTION_EXISTENCE_READ);

  if (hasFullAccess) {
    return OfferStockOptionsAccess.FULL;
  }

  if (hasExistenceAccess) {
    return OfferStockOptionsAccess.EXISTENCE;
  }

  return null;
}
