import {
  type PropertiesWithMarkWithParametersConstraint,
  createLocator as originalCreateLocator,
  createRootLocator,
  type CreateLocator,
  getLocatorParameters as originalGetLocatorParameters,
  type Locator,
  type Mark as OriginalMark,
  removeMarkFromProperties as originalRemoveMarkFromProperties,
} from 'create-locator';

type AllStringKeys<T> = T extends unknown ? string & keyof T : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyLocator = (...args: any) => any;
type IsKeysEmpty<Tree> = AllStringKeys<Tree> extends never ? true : false;
type Properties = {testId?: unknown; testItem?: string} & PropertiesWithMarkWithParametersConstraint;

const options = {pathAttribute: 'data-test-id'};

// @ts-expect-error
export type ConvertLocatorToTestId<Loc> = ConvertNormalizedLocatorToTestId<CreateLocator<Loc, undefined>>;

type ConvertNormalizedLocatorToTestId<NormalizedLocator> =
  IsKeysEmpty<NormalizedLocator> extends true
    ? unknown
    : {[Key in string & keyof NormalizedLocator]: ConvertNormalizedLocatorToTestId<NormalizedLocator[Key]>};

const cache: Record<string, object> = Object.create(null);

export const createLocator: typeof originalCreateLocator = ((properties: Properties) => {
  if (properties?.testId) {
    const testId = String(properties.testId);

    if (cache[testId]) {
      return cache[testId];
    }

    const locator = createRootLocator<Locator<void>>(testId, options);

    cache[testId] = locator;

    return locator;
  }

  return originalCreateLocator(properties);
}) as typeof originalCreateLocator;

export const getLocatorParameters: typeof originalGetLocatorParameters = ((properties: Properties) => {
  const locatorParameters = originalGetLocatorParameters(properties);

  if (typeof properties?.testItem === 'string') {
    return {item: properties.testItem};
  }

  return locatorParameters;
}) as typeof originalGetLocatorParameters;

// @ts-expect-error
export type Mark<SomeLocator extends AnyLocator> = OriginalMark<SomeLocator> & {
  testId?: ConvertLocatorToTestId<SomeLocator>;
} & ('item' extends AllStringKeys<Parameters<SomeLocator>[0]> ? {testItem?: string} : void);

export type {
  Locator,
  LocatorOfElement,
  Node,
  PropertiesWithMarkConstraint,
  RemoveMarkFromProperties,
} from 'create-locator';

export const removeMarkFromProperties: typeof originalRemoveMarkFromProperties = ((properties: Properties) => {
  const {testId, testItem, ...restProperties} = properties || {};

  return originalRemoveMarkFromProperties(restProperties);
}) as typeof originalRemoveMarkFromProperties;
