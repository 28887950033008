import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {StockOptionReadAccess} from 'domain/stockOption/holder/model/access';
import {UserStockOptionData} from 'domain/stockOption/holder/model/user';
import {
  getMyStockOptionDataFx,
  getSubordinateStockOptionDataFx,
  getUserStockOptionDataByIdFx,
} from 'domain/stockOption/holder/stores/user';
import {$userStockOptionData} from 'domain/stockOption/holder/stores/user/state';
import {UserId} from 'domain/user/model';
import {Effect} from 'effector';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';
import {useStockOptionReadAccess} from './useReadAccess';

const handlerByAccess: Record<StockOptionReadAccess, Effect<UserId, UserStockOptionData>> = {
  [StockOptionReadAccess.MY]: getMyStockOptionDataFx,
  [StockOptionReadAccess.SUBORDINATE]: getSubordinateStockOptionDataFx,
  [StockOptionReadAccess.ANY]: getUserStockOptionDataByIdFx,
};

type ReturnShape = {
  readAccess: StockOptionReadAccess | null;
  data: UserStockOptionData | null;
  dataState: DataState;
  clientErrorStatus?: ClientErrorStatus;
};

export function useUserStockOptionData(userId?: UserId, userAncestorIds: UserId[] = []): ReturnShape {
  const readAccess = useStockOptionReadAccess({userId, userAncestorIds});
  const [clientErrorStatus, setClientErrorStatus] = useState<ClientErrorStatus>();

  const data = useStoreMap({
    store: $userStockOptionData,
    keys: [userId],
    fn: ({byUserId}, [id]) => (id && byUserId[id]) || null,
  });
  const dataState = useStoreMap({
    store: $userStockOptionData,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => (id && dataStateByUserId[id]) || DataState.IDLE,
  });

  useEffect(() => {
    setClientErrorStatus(undefined);

    if (readAccess && userId && !isLoadingOrLoaded(dataState)) {
      handlerByAccess[readAccess](userId).catch((error: Error) => {
        if (error instanceof ClientError && error.status === ClientErrorStatus.NOT_FOUND) {
          setClientErrorStatus(error.status);
        } else {
          toaster.error(error.message);
        }
      });
    }
  }, [readAccess, userId]);

  return {
    readAccess,
    data,
    dataState,
    clientErrorStatus,
  };
}
