import {intranetApi} from 'apiClient';
import {LegalEntity, LegalEntityDiff, LegalEntityId, legalEntitySchema} from 'domain/legalEntity/model';

type Params = {
  diff: LegalEntityDiff;
  id: LegalEntityId;
};

export const update = async ({id, diff}: Params): Promise<LegalEntity> => {
  const {data} = await intranetApi.post('/v1/legalEntities/update', diff, {params: {id}});

  return legalEntitySchema.runWithException(data);
};
