import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  userId: {
    defaultMessage: 'Joomer',
    description: 'Compensation review request form: field label',
  },
  type: {
    defaultMessage: 'Type',
    description: 'Compensation review request form: field label',
  },
});

export const hints = defineMessages({
  userId: {
    defaultMessage: 'Employee’s confidential information will be displayed after selection.',
    description: 'Compensation review request form: field hint',
  },
});
