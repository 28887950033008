import {defineMessages} from 'react-intl';
import {OfficePolicy} from '.';

export const officePolicies = defineMessages<OfficePolicy>({
  [OfficePolicy.COMMON]: {
    defaultMessage: 'Common',
    description: 'Office policy',
  },
  [OfficePolicy.EXCLUSIVE]: {
    defaultMessage: 'Individual',
    description: 'Office policy',
  },
  [OfficePolicy.NOT_APPLICABLE]: {
    defaultMessage: 'Not applicable',
    description: 'Office policy',
  },
  [OfficePolicy.REMOTE]: {
    defaultMessage: 'Remote',
    description: 'Office policy',
  },
});
