import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {array, boolean, ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {moneySchema} from 'domain/money/model';
import {fileMetaSchema} from 'models/system/fileMeta';
import {optionalWithDefault} from 'utils/jsonValidation/optionalWithDefault';

export const receiptSchema = object({
  comment: optional(string()),
  receiptFiles: optionalWithDefault([], array(fileMetaSchema)),
  lostReceipt: optional(boolean()),
  money: moneySchema,
  name: string(),
});
export type Receipt = ExtractSchemaType<typeof receiptSchema>;

export const receiptDiffSchema = object({
  comment: optional(string()),
  receiptFiles: optional(array(idSchema)),
  lostReceipt: optional(boolean()),
  money: moneySchema,
  name: string(),
});
export type ReceiptDiff = ExtractSchemaType<typeof receiptDiffSchema>;
