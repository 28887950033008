import {generalMessages} from 'i18n/messages/general';
import {IntlShape} from 'react-intl';

export const formatFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
  intl: IntlShape,
): string | undefined => {
  if (firstName && lastName) {
    return intl.formatMessage(generalMessages.fullName, {firstName, lastName});
  }
  return firstName || lastName || undefined;
};
