import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {Offer, OfferId, offerSchema} from 'domain/offer/model';
import {OfferGeneralInfo} from 'domain/offer/model/generalInfo';
import {Permission} from 'domain/permission/model';

export type UpdateGeneralInfoDiff = Partial<
  Omit<OfferGeneralInfo, 'recruiter' | 'legalEntity' | 'legalEntityOfFurtherSalary'>
>;
export type UpdateGeneralInfoConfig = {
  id: OfferId;
  diff: UpdateGeneralInfoDiff;
};

const updateGeneric =
  (endpoint: string) =>
  async ({id, diff}: UpdateGeneralInfoConfig): Promise<Offer> => {
    const {data} = await intranetApi.post(endpoint, diff, {
      params: {id},
    });

    return offerSchema.runWithException(data);
  };

const updateGeneralAny = updateGeneric('/v1/offers/updateGeneralAny');
const updateGeneralMy = updateGeneric('/v1/offers/updateGeneralMy');

export const updateGeneral = createPermissionDependentHandler(
  [{permission: Permission.OFFER_UPDATE_GENERAL_ANY, handler: updateGeneralAny}],
  updateGeneralMy,
);
