import {defineMessages} from 'react-intl';
import {OneTimeBonusCondition} from '.';

export const oneTimeBonusConditions = defineMessages<OneTimeBonusCondition>({
  [OneTimeBonusCondition.REFUNDABLE]: {
    defaultMessage: 'Refundable',
    description: 'One-time bonus condition',
  },
  [OneTimeBonusCondition.NON_REFUNDABLE]: {
    defaultMessage: 'Non-refundable',
    description: 'One-time bonus condition',
  },
});
