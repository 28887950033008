import {CrResult} from 'domain/compaRatio/model';
import {formatCrValue} from 'domain/compaRatio/utilts/formatCrValue';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function useCrValueText(cr: CrResult, withLabel: boolean): string {
  const intl = useIntl();
  const text = useMemo(() => {
    if (!cr.value) {
      return cr.error ? cr.error : intl.formatMessage(messages.empty);
    }

    return intl.formatMessage(messages.value, {withLabel, value: formatCrValue(cr.value)});
  }, [intl, cr]);

  return text;
}
