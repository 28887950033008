import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const outstaffUsersUrlPatterns = {
  outstaffUsers: '/outstaff',
  outstaffUser: '/outstaff/:email',
};

export const outstaffUsersUrls = {
  outstaffUsers: (searchParams?: {email?: string; search?: string}) =>
    addSearchParams(generatePath(outstaffUsersUrlPatterns.outstaffUsers), searchParams),
  outstaffUser: (pathParams: {email: string}) => generatePath(outstaffUsersUrlPatterns.outstaffUser, pathParams),
};
