import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {DeleteStockOptionAgreementButton} from 'domain/stockOption/agreement/widgets/DeleteButton';
import {FreezeStockOptionAgreementButton} from 'domain/stockOption/agreement/widgets/FreezeButton';
import {SendToHolderButton} from 'domain/stockOption/agreement/widgets/SendToHolderButton';
import {UpdateDocumentsStatusButton} from 'domain/stockOption/agreement/widgets/UpdateDocumuntsStatusButton';
import React from 'react';
import {DownloadStockOptionAgreementButton} from '../DownloadButton';
import styles from './styles.css';

type Props = {
  agreement: StockOptionAgreement;
};

export function StockOptionAgreementActions({agreement}: Props) {
  return (
    <div className={styles.root}>
      <DownloadStockOptionAgreementButton agreement={agreement} />
      <SendToHolderButton agreement={agreement} />
      <FreezeStockOptionAgreementButton agreement={agreement} />
      <UpdateDocumentsStatusButton agreement={agreement} />
      <DeleteStockOptionAgreementButton agreement={agreement} />
    </div>
  );
}
