import {ReactComponent as IconInfo} from '@joomcode/joom-ui/icons/circle/info.svg';
import React from 'react';
import styles from './styles.css';

export type Props = {
  children: React.ReactNode;
};

export function DialogInfoStripe({children}: Props) {
  return (
    <div className={styles.root}>
      <div className={styles.infoIconWrap}>
        <IconInfo />
      </div>
      <div>{children}</div>
    </div>
  );
}
