import cn from 'classnames';
import * as React from 'react';
import {createLocator, type Locator, type Mark} from '../../create-locator';
import {renderError, renderWarning} from '../utils';
import styles from './index.css';

export type ErrorContainerLocator = Locator<{
  error: void;
  warning: void;
}>;

type Props = {
  error?: React.ReactNode;
  /** Only shown if there is no error */
  warning?: React.ReactNode;
  reserveSpaceForError?: boolean;
} & Partial<Mark<ErrorContainerLocator>>;

export const ErrorContainer = ({error, warning, reserveSpaceForError = true, ...rest}: Props) => {
  const locator = createLocator(rest);

  return (
    <div
      className={cn({
        [styles.minHeight]: reserveSpaceForError,
      })}
      role='alert'
    >
      {error && <div {...locator.error()}>{renderError(error)}</div>}
      {warning && !error && <div {...locator.warning()}>{renderWarning(warning)}</div>}
    </div>
  );
};
