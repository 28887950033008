import {defineMessages} from 'react-intl';
import {ColumnId} from './columnId';

export const columnNames = defineMessages<ColumnId>({
  [ColumnId.AMOUNT]: {
    defaultMessage: 'Options',
    description: 'Stock option agreements table column name',
  },
  [ColumnId.VESTED]: {
    defaultMessage: 'Vested',
    description: 'Stock option agreements table column name',
  },
  [ColumnId.STATUS]: {
    defaultMessage: 'Status',
    description: 'Stock option agreements table column name',
  },
  [ColumnId.ISSUE_DATE]: {
    defaultMessage: 'Issue date',
    description: 'Stock option agreements table column name',
  },
  [ColumnId.ISSUER]: {
    defaultMessage: 'Issuer',
    description: 'Stock option agreements table column name',
  },
  [ColumnId.TYPE]: {
    defaultMessage: 'Type',
    description: 'Stock option agreements table column name',
  },
  [ColumnId.END_VESTING_DATE]: {
    defaultMessage: 'Vesting ends at',
    description: 'Stock option agreements table column name',
  },
  [ColumnId.DOCUMENTS_STATUS]: {
    defaultMessage: 'Documents status',
    description: 'Stock option agreements table column name',
  },
  [ColumnId.ACTIONS]: {
    defaultMessage: 'Actions',
    description: 'Stock option agreements table column name',
  },
});

export const messages = defineMessages({
  title: {
    defaultMessage: 'Agreements',
    description: '[title] Stock option agreements panel: title',
  },
  btnCreate: {
    defaultMessage: 'Add agreement',
    description: '[button] Stock option agreements panel: agreement creation button',
  },
});
