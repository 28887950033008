export enum ColumnId {
  USER = 'user',
  EMAIL = 'email',
  AMOUNT = 'amount',
  VESTED = 'vested',
  TYPE = 'type',
  ISSUE_DATE = 'issueDate',
  PARSING_STATUS = 'parsingStatus',
  AGREEMENT_STATUS = 'agreementStatus',
  DOCUMENTS_STATUS = 'documentsStatus',
}
