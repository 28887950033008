import {assertNever} from '@joomcode/deprecated-utils/types';
import {ReactComponent as CheckmarkIcon} from '@joomcode/joom-ui/icons/circle/checkmark.svg';
import {ReactComponent as CrossIcon} from '@joomcode/joom-ui/icons/circle/cross.svg';
import {IntentionedText, TextIntent} from '@joomcode/joom-ui/IntentionedText';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {StockOptionBulkUploadMeta, StockOptionBulkUploadRowStatus} from 'domain/stockOption/bulkUpload/model/meta';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

export type Props = {
  meta: StockOptionBulkUploadMeta;
};

const iconByStatus: Record<StockOptionBulkUploadRowStatus, React.ComponentType> = {
  [StockOptionBulkUploadRowStatus.SUCCESS]: CheckmarkIcon,
  [StockOptionBulkUploadRowStatus.NOT_CHANGED]: CheckmarkIcon,
  [StockOptionBulkUploadRowStatus.ERROR]: CrossIcon,
};

const intentByStatus: Record<StockOptionBulkUploadRowStatus, TextIntent> = {
  [StockOptionBulkUploadRowStatus.SUCCESS]: 'positive',
  [StockOptionBulkUploadRowStatus.NOT_CHANGED]: 'neutral',
  [StockOptionBulkUploadRowStatus.ERROR]: 'negative',
};

export function BulkUploadRowStatus({meta}: Props) {
  const intl = useIntl();
  const {status} = meta;
  const Icon = iconByStatus[status];

  const renderContent = () => {
    if (status === StockOptionBulkUploadRowStatus.SUCCESS) {
      return intl.formatMessage(messages.success);
    }
    if (status === StockOptionBulkUploadRowStatus.NOT_CHANGED) {
      return intl.formatMessage(messages.notChanged);
    }
    if (status === StockOptionBulkUploadRowStatus.ERROR) {
      return (
        <IntentionedText intent='negative'>
          {meta.errors.map((error) => (
            <TooltipFaq.Paragraph key={error}>{error}</TooltipFaq.Paragraph>
          ))}
        </IntentionedText>
      );
    }
    assertNever(status);
    return null;
  };

  return (
    <TooltipFaq content={renderContent()}>
      <div className={styles.iconWrap}>
        <IntentionedText intent={intentByStatus[status]}>
          <Icon />
        </IntentionedText>
      </div>
    </TooltipFaq>
  );
}
