import {Offer} from 'domain/offer/model';
import {OfferStatus} from 'domain/offer/model/status';
import {Permission} from 'domain/permission/model';
import {SecurePermission} from 'domain/permission/model/secure';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {useMemo} from 'react';
import {useAcl} from 'services/acl';

type ReturnShape = {
  canUpdateGeneralInfo: boolean;
  canUpdateDetails: boolean;
};

export function useOfferUpdatePossibility(offer: Offer | null): ReturnShape {
  const acl = useAcl();
  const selfUserId = useSelfUserId();

  return useMemo(() => {
    const canUpdateAnyOffer = offer && ![OfferStatus.DECLINED, OfferStatus.APPROVING].includes(offer.status);
    const canUpdateOwnOffer =
      offer &&
      offer.general.recruiter?.id === selfUserId &&
      ![OfferStatus.DECLINED, OfferStatus.APPROVING, OfferStatus.COMPLETED].includes(offer.status);

    const canUpdateOwnOfferGeneralInfo = canUpdateOwnOffer && acl.hasPermission(Permission.OFFER_UPDATE_GENERAL_MY);
    const canUpdateAnyOfferGeneralInfo = canUpdateAnyOffer && acl.hasPermission(Permission.OFFER_UPDATE_GENERAL_ANY);
    const canUpdateOwnOfferDetails = canUpdateOwnOffer && acl.hasSecurePermission(SecurePermission.OFFER_UPDATE_MY);
    const canUpdateAnyOfferDetails = canUpdateAnyOffer && acl.hasSecurePermission(SecurePermission.OFFER_UPDATE_ANY);

    return {
      canUpdateGeneralInfo: Boolean(canUpdateOwnOfferGeneralInfo || canUpdateAnyOfferGeneralInfo),
      canUpdateDetails: Boolean(canUpdateOwnOfferDetails || canUpdateAnyOfferDetails),
    };
  }, [acl, selfUserId, offer]);
}
