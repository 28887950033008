import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  type: {
    defaultMessage: 'Time off type',
    description: 'Time off request form: label',
  },
  startDate: {
    defaultMessage: 'Start date',
    description: 'Time off request form: label',
  },
  endDate: {
    defaultMessage: 'End date',
    description: 'Time off request form: label',
  },
});

export const messages = defineMessages({
  submitButton: {
    defaultMessage: 'Submit request',
    description: '[button] Time off request form: submit button',
  },
  familyLeaveHint: {
    defaultMessage: 'Family leave refers to two additional paid days of leave in case of the loss of a close relative.',
    description: 'Time off request form: family leave hint',
  },
});
