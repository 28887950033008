import {PageProperty} from '@joomcode/joom-ui/PageProperty';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Markdown} from 'components/ui/Markdown';
import {JdmOwners} from 'components/widgets/JdmOwners';
import {Service} from 'domain/service/model';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  service: Service;
};

export function ServiceSummaryPanel({service}: Props) {
  const intl = useIntl();

  return (
    <Panel>
      <Panel.Header withSeparator title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <div className={styles.content}>
          {service.description && (
            <div className={styles.section}>
              <PageProperty title={intl.formatMessage(messages.serviceDescription)}>
                <Markdown source={service.description} />
              </PageProperty>
            </div>
          )}
          <div className={styles.section}>
            <PageProperty title={intl.formatMessage(messages.owners)}>
              <JdmOwners owners={service.owners} owningMemberships={service.owningMemberships} />
            </PageProperty>
          </div>
        </div>
      </Panel.Content>
    </Panel>
  );
}
