import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {OfferId} from 'domain/offer/model';
import {VersionedOfferCompensationList, versionedOfferCompensationListSchema} from 'domain/offer/model/compensation';
import {OfferVersionId} from 'domain/offer/model/version';
import {SecurePermission} from 'domain/permission/model/secure';
import {stringify} from 'qs';

export type GetCompensationsParams = {
  id: OfferId;
  versionIds: OfferVersionId[];
};

const getCompensationsGeneric =
  (endpoint: string) =>
  async ({id, versionIds}: GetCompensationsParams): Promise<VersionedOfferCompensationList> => {
    const {data} = await intranetApi.get(endpoint, {
      params: {id, versionIds},
      paramsSerializer: (params) => stringify(params, {arrayFormat: 'repeat'}),
    });

    return versionedOfferCompensationListSchema.runWithException(data);
  };

const getCompensationsAny = getCompensationsGeneric('/v1/secure/offers/getCompensationsAny');
const getCompensationsMy = getCompensationsGeneric('/v1/secure/offers/getCompensationsMy');

export const getCompensations = createPermissionDependentHandler(
  [{securePermission: SecurePermission.OFFER_COMPENSATION_READ_ANY, handler: getCompensationsAny}],
  getCompensationsMy,
);
