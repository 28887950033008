import {identity} from '@joomcode/deprecated-utils/function';
import {CommonFieldProps, getFieldValidator} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Select} from '@joomcode/joom-ui/Select';
import {OfferPriority} from 'domain/offer/model/priority';
import {availableOfferPriorityValues} from 'domain/offer/model/priority/availableValues';
import {formatOfferPriority} from 'domain/offer/model/priority/formatter';
import React, {useCallback} from 'react';
import {Field} from 'react-final-form';
import {useIntl} from 'react-intl';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';

export type Props = CommonFieldProps<OfferPriority>;

export function FieldOfferPriority({
  name,
  label,
  hint,
  required,
  disabled,
  initialValue,
  reserveSpaceForError,
  error,
  extraErrorMessages,
  validate,
  ...restProps
}: Props) {
  const intl = useIntl();
  const priorityToString = useCallback((priority: OfferPriority) => formatOfferPriority(priority, intl), [intl]);

  return (
    <Field<OfferPriority> name={name} validate={validate && getFieldValidator(validate)} initialValue={initialValue}>
      {({input, meta}) => (
        <FormControl
          hint={hint}
          label={label}
          disabled={meta.submitting || disabled}
          error={error || getFieldErrorText(meta, {intl, extraMessages: extraErrorMessages})}
          required={required}
          reserveSpaceForError={reserveSpaceForError}
        >
          {(formControlProps) => (
            <Select<OfferPriority>
              items={availableOfferPriorityValues}
              getItemKey={identity}
              itemToString={priorityToString}
              renderItem={priorityToString}
              {...formControlProps}
              {...input}
              {...restProps}
              disabled={meta.submitting || disabled}
            />
          )}
        </FormControl>
      )}
    </Field>
  );
}
