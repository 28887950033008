import {isNullish} from '@joomcode/deprecated-utils/function';
import {isNotNullish} from '@joomcode/deprecated-utils/function/isNotNullish';
import {isBlank} from '@joomcode/deprecated-utils/string/isBlank';
import {isNumber} from '@joomcode/deprecated-utils/guards';
import type {ValidationFunction} from '../../types';
import {NumberValidatorErrorCode, NumberValidatorOptions} from './types';

const NUMBER_PATTERN = /^\s*[-+]?[\d+]+\.?[\d+]*\s*$/;

export const validateNumber: ValidationFunction<unknown, NumberValidatorOptions, NumberValidatorErrorCode> = (
  value,
  options = {},
) => {
  const {min, max, integer, minExcluded, maxExcluded, maxDecimalPlaces, multiple} = options;

  if (isNullish(value) || (typeof value === 'string' && isBlank(value))) {
    return undefined;
  }

  const number = parseFloat(String(value));

  if (Number.isNaN(number) || !NUMBER_PATTERN.test(String(value))) {
    return {code: NumberValidatorErrorCode.NUMBER_INVALID, options};
  }

  if (integer && !Number.isInteger(number)) {
    return {code: NumberValidatorErrorCode.NUMBER_NOT_INTEGER, options};
  }

  if (isNotNullish(min) && minExcluded && number <= min) {
    return {code: NumberValidatorErrorCode.NUMBER_LESS_OR_EQUAL_MIN, options};
  }

  if (isNotNullish(min) && !minExcluded && number < min) {
    return {code: NumberValidatorErrorCode.NUMBER_STRICTLY_LESS_MIN, options};
  }

  if (isNotNullish(max) && maxExcluded && number >= max) {
    return {code: NumberValidatorErrorCode.NUMBER_GREATER_OR_EQUAL_MAX, options};
  }

  if (isNotNullish(max) && !maxExcluded && number > max) {
    return {code: NumberValidatorErrorCode.NUMBER_STRICTLY_GREATER_MAX, options};
  }

  if (isNotNullish(multiple) && number % multiple) {
    return {code: NumberValidatorErrorCode.NUMBER_NOT_DIVISIBLE, options};
  }

  if (isNumber(maxDecimalPlaces) && (String(number).split('.')[1]?.length ?? 0) > maxDecimalPlaces) {
    return {code: NumberValidatorErrorCode.NUMBER_MAX_DECIMAL_PLACES, options};
  }

  return undefined;
};
