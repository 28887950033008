import {SensitiveData} from 'components/ui/SensitiveData';
import {Column} from 'domain/managerConsole/model/column';
import {fullColumnsTree} from 'domain/userFullInfoRecord/model/columns/tree';
import React from 'react';

export function makeColumnSensitive({id, render, ...rest}: Column, isSensitiveDataHidden: boolean): Column {
  return {
    id,
    render: (item) => {
      if (fullColumnsTree.find(({key}) => key === id)?.sensitive) {
        return <SensitiveData isHidden={isSensitiveDataHidden}>{render(item)}</SensitiveData>;
      }
      return render(item);
    },
    ...rest,
  };
}
