import cn from 'classnames';
import React, {useLayoutEffect, useRef, useMemo} from 'react';
import uuid from 'uuid/v1';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../create-locator';
import styles from './index.css';

export type CheckboxTestId = ConvertLocatorToTestId<CheckboxLocator>;
export type CheckboxLocator = LocatorOfElement<{
  input: void;
  text: void;
}>;

type CheckboxProps = {
  className?: string;
  defaultIndeterminate?: boolean;
  indeterminate?: boolean;
  label?: React.ReactNode;
  invalid?: boolean;
} & Partial<Mark<CheckboxLocator>>;
type Props = CheckboxProps & Omit<JSX.IntrinsicElements['input'], 'className'>;

export function Checkbox({
  defaultIndeterminate,
  className,
  disabled,
  id: baseId,
  indeterminate,
  label,
  invalid,
  readOnly,
  ...restPropertiesWithMark
}: Props) {
  const locator = createLocator(restPropertiesWithMark);
  const restProps = removeMarkFromProperties(restPropertiesWithMark);
  const id = useMemo(() => baseId ?? uuid(), [baseId]);
  const input = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    const indeterminateState = indeterminate !== undefined ? indeterminate : defaultIndeterminate;

    if (input.current && indeterminateState !== undefined) {
      input.current.indeterminate = indeterminateState;
    }
  }, [indeterminate, input.current]);

  return (
    <div className={styles.checkboxContainer}>
      <input
        {...restProps}
        disabled={disabled || readOnly}
        className={cn(styles.input, {[styles.inputReadOnly]: readOnly, [styles.inputDisabled]: disabled})}
        id={id}
        type='checkbox'
        ref={input}
        {...locator.input()}
      />
      <label
        htmlFor={id}
        className={cn(className || styles.checkboxLayout, styles.checkbox, {
          [styles.checkboxInvalid]: invalid,
        })}
        {...locator()}
      >
        <div className={styles.square} />
        {label && (
          <span {...locator.text()} className={styles.label}>
            {label}
          </span>
        )}
      </label>
    </div>
  );
}
