import {defineMessages} from 'react-intl';
import {EmailValidatorErrorCode} from './types';

export const emailErrorMessages = defineMessages<EmailValidatorErrorCode>({
  [EmailValidatorErrorCode.EMAIL_INVALID]: {
    id: 'validator-email-invalid',
    description: 'Validation error about the email is invalid',
    defaultMessage: 'Email is invalid',
  },
});
