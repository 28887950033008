import React, {useMemo} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {LabelRenderProps} from '../../types';
import {MultiSelectFilterOptions} from './types';
import {listOptionsUtils} from '../listOptions';

export const multiSelectMessages = defineMessages({
  label: {
    id: 'datafilter-multiselect-label-many-values',
    description: 'DataFilter multiple values label, e.g. "Color: red, blue, yellow"',
    defaultMessage: '{filterName}: {filterValue}',
  },
});

export function MultiSelectLabel<T>({
  name,
  options: {options, optionValueToString = String},
  value = [],
  ValueWrapper,
}: LabelRenderProps<T[], MultiSelectFilterOptions<T>>) {
  const intl = useIntl();
  const valueLabelMap = useMemo(
    () =>
      listOptionsUtils.getLabelByValueMap(
        options.map((option) => ({
          ...option,
          value: optionValueToString(option.value),
        })),
      ),
    [options, optionValueToString],
  );
  const valueLabels = useMemo(
    () => value.map((item) => valueLabelMap[optionValueToString(item)]),
    [value, valueLabelMap, optionValueToString],
  );

  return (
    <>
      {intl.formatMessage(multiSelectMessages.label, {
        filterName: name,
        filterValue: (
          <ValueWrapper title={intl.formatList(valueLabels, {type: 'conjunction', style: 'narrow'})}>
            {intl.formatList(valueLabels, {type: 'conjunction', style: 'narrow'})}
          </ValueWrapper>
        ),
      })}
    </>
  );
}
