import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';

export enum EntityResponseStatus {
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

type ValueResponse<T> = {
  status: EntityResponseStatus.FULFILLED;
  value: T;
};

type ErrorResponse = {
  status: EntityResponseStatus.REJECTED;
  reason: ClientErrorStatus;
};

export type EntityResponse<T> = ValueResponse<T> | ErrorResponse;

type EntityLoader<Params, Entity> = (params: Params) => Promise<Entity>;

export function createEntityGetter<Params, Entity>(
  loader: EntityLoader<Params, Entity>,
): EntityLoader<Params, EntityResponse<Entity>> {
  return (params) =>
    loader(params)
      .then(
        (entity): ValueResponse<Entity> => ({
          status: EntityResponseStatus.FULFILLED,
          value: entity,
        }),
      )
      .catch((error): ErrorResponse => {
        if (error instanceof ClientError) {
          return {
            status: EntityResponseStatus.REJECTED,
            reason: error.status,
          };
        }
        throw error;
      });
}
