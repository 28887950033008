import {EntityResponse} from 'apiClient/entityResponse';
import {OfficeSeatId} from 'domain/officeMap/seat/model';
import {SetRolesParams} from 'domain/user/api/setRoles';
import {User, UserFull, UserId} from 'domain/user/model';
import {UserDiff} from 'domain/user/model/diff';
import {createEffect, createEvent} from 'effector';

export const loadActiveUsersFx = createEffect<void, User[]>('load active users');
export const loadAllUsersFx = createEffect<void, User[]>('load all users');
export const loadUserByLoginFx = createEffect<{login: string}, EntityResponse<UserFull>>('load user by login');
export const loadUserEverWorkedByLoginFx = createEffect<{login: string}, EntityResponse<UserFull>>(
  'load user (terminated or not) by login',
);
export const updateUser = createEvent<UserFull>('update user');
export const updateUserFx = createEffect<{id: UserId; userData: UserDiff}, UserFull>('update user');
export const takeSeatFx = createEffect<OfficeSeatId, User>('take seat');
export const freeSeatFx = createEffect<void, User>('free seat');
export const unassignSeatFx = createEffect<UserId, User>('unassign seat from user');
export const setRolesFx = createEffect<SetRolesParams, User>('set roles');
