export enum UrlValidatorErrorCode {
  URL_INVALID = 'url_invalid',
  URL_INVALID_FORMAT = 'url_invalid_format',
  URL_INVALID_SCHEMA = 'url_invalid_schema',
}

export enum Schema {
  FTP = 'ftp',
  FTPS = 'ftps',
  HTTP = 'http',
  HTTPS = 'https',
  SFTP = 'sftp',
}

export type UrlValidatorOptions = {
  schemas?: Schema[];
};
