import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {
  CompensationRequest,
  CompensationRequestId,
  compensationRequestSchema,
} from 'domain/compensations/request/model';
import {Permission} from 'domain/permission/model';

const getByIdGeneric =
  (endpoint: string) =>
  async (compensationRequestId: CompensationRequestId): Promise<CompensationRequest> => {
    const {data} = await intranetApi.get(endpoint, {params: {id: compensationRequestId}});

    return compensationRequestSchema.runWithException(data);
  };

const getByIdDefault = getByIdGeneric('/v1/compensations/requests/getById');
const getByIdAsAdmin = getByIdGeneric('/v1/compensations/admin/requests/getById');

export const getById = createPermissionDependentHandler(
  [{permission: Permission.COMPENSATION_READ_ALL, handler: getByIdAsAdmin}],
  getByIdDefault,
);
