import {intranetApi} from 'apiClient';
import {OfficePolicyRecordId} from 'domain/officePolicyRecord/model';
import {UserId} from 'domain/user/model';

export type DeleteOfficePolicyRecordParams = {
  id: OfficePolicyRecordId;
  userId: UserId;
};

export const deleteRecord = (params: DeleteOfficePolicyRecordParams): Promise<void> => {
  return intranetApi.post('/v1/users/officePolicyRecords/delete', undefined, {params});
};
