import {boolean, ExtractSchemaType, object} from '@joomcode/deprecated-utils/jsonValidation';

export const userFullInfoAvailabilitySchema = object({
  cr: boolean(),
  jobContracts: boolean(),
  marketData: boolean(),
  privateInfo: boolean(),
  regularBonus: boolean(),
  salary: boolean(),
  salaryRanges: boolean(),
  stockOptions: boolean(),
});

export type UserFullInfoAvailability = ExtractSchemaType<typeof userFullInfoAvailabilitySchema>;
