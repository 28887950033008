import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  btnApply: {
    defaultMessage: 'Apply changes',
    description: '[button] Options data upload result applying',
  },
  btnReset: {
    defaultMessage: 'Start over',
    description: '[button] Options data upload reset',
  },
  success: {
    defaultMessage: 'Options data have been successfully changed',
    description: 'Options data upload success',
  },
  nonExistentError: {
    defaultMessage: 'Attempt to apply non-existent options data upload result',
    description: 'Options data upload applying error',
  },
  malformedInputError: {
    defaultMessage: 'Attempt to apply malformed options data',
    description: 'Options data upload applying error',
  },
  checkbox: {
    defaultMessage: 'Send all new option agreements',
    description: 'Checkbox label',
  },
});
