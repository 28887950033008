import {defineMessages} from 'react-intl';

export const columnNames = defineMessages({
  id: {
    defaultMessage: 'ID',
    description: 'User role column name',
  },
  name: {
    defaultMessage: 'Name',
    description: 'User role column name',
  },
  usedBy: {
    defaultMessage: 'Users with role',
    description: 'User role column name',
  },
  permissionGranted: {
    defaultMessage: 'Permissions granted',
    description: 'User role column name',
  },
});
