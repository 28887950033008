import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {
  CompensationRequest,
  CompensationRequestId,
  compensationRequestSchema,
} from 'domain/compensations/request/model';
import {Permission} from 'domain/permission/model';

const cancelGeneric =
  (endpoint: string) =>
  async (compensationRequestId: CompensationRequestId): Promise<CompensationRequest> => {
    const {data} = await intranetApi.post(endpoint, undefined, {
      params: {id: compensationRequestId},
    });

    return compensationRequestSchema.runWithException(data);
  };

const cancelDefault = cancelGeneric('/v1/compensations/requests/cancel');
const cancelAsHr = cancelGeneric('/v1/compensations/requests/cancelAsHr');

export const cancel = createPermissionDependentHandler(
  [{permission: Permission.COMPENSATION_CANCEL, handler: cancelAsHr}],
  cancelDefault,
);
