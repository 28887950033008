import {ExtractSchemaType, number, object, optional} from '@joomcode/deprecated-utils/jsonValidation';

export const coordinatesSchema = object({
  x: number(),
  y: number(),
});

export const coordinatesWithAngleSchema = object({
  x: number(),
  y: number(),
  angle: optional(number()),
});

export type Coordinates = ExtractSchemaType<typeof coordinatesSchema>;
export type CoordinatesWithAngle = ExtractSchemaType<typeof coordinatesWithAngleSchema>;
