import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {
  createAutocompleteMultiselectFilter,
  createMultiSelectFilter,
  DataFilter,
  DataFilterConfig,
} from '@joomcode/joom-ui/DataFilter';
import {Error} from '@joomcode/joom-ui/FormControl/Error';
import {StateHandler} from '@joomcode/joom-ui/StateHandler';
import {DataFilterSpinner} from 'components/ui/DataFilterSpinner';
import {createSimpleBooleanFilter} from 'components/ui/SimpleBooleanFilter';
import {useCompanyUnits} from 'domain/companyUnits/hooks/useCompanyUnits';
import {CountryCode} from 'domain/countryCode/model';
import {OffersSearchFilters} from 'domain/offer/model/filters';
import {statusSortingOrder} from 'domain/offer/model/status';
import {formatOfferStatus} from 'domain/offer/model/status/formatter';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {getCountryComparator} from 'utils/country/compare';
import {getCountryName} from 'utils/country/name';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  values: OffersSearchFilters;
  onChange(payload: OffersSearchFilters): void;
};

export function OfferFilters({values, onChange}: Props) {
  const intl = useIntl();
  const {dataState, units} = useCompanyUnits();
  const divisions = units?.divisions;

  const filters = useMemo<DataFilterConfig<OffersSearchFilters>>(
    () => ({
      statuses: createMultiSelectFilter(intl.formatMessage(messages.status), {
        alwaysVisible: true,
        options: statusSortingOrder.map((status) => ({
          label: formatOfferStatus(status, intl),
          value: status,
        })),
      }),
      divisions: createMultiSelectFilter(intl.formatMessage(messages.division), {
        alwaysVisible: true,
        options: (divisions || []).map((division) => ({
          label: division,
          value: division,
        })),
      }),
      countriesInclude: createAutocompleteMultiselectFilter(intl.formatMessage(messages.countries), {
        alwaysVisible: true,
        options: getEnumValues(CountryCode)
          .sort(getCountryComparator(intl))
          .map((country) => ({
            label: getCountryName(country, intl),
            value: country,
          })),
      }),
      countriesExclude: createAutocompleteMultiselectFilter(intl.formatMessage(messages.countriesExclude), {
        alwaysVisible: true,
        options: getEnumValues(CountryCode)
          .sort(getCountryComparator(intl))
          .map((country) => ({
            label: getCountryName(country, intl),
            value: country,
          })),
      }),
      hideEmployed: createSimpleBooleanFilter(intl.formatMessage(messages.hideEmployed), {
        alwaysVisible: true,
      }),
    }),
    [intl, divisions],
  );

  const renderError = useCallback(
    () => (
      <div className={styles.error}>
        <Error message={intl.formatMessage(messages.loadingError)} />
      </div>
    ),
    [intl],
  );
  const renderPlaceholder = useCallback(() => <DataFilterSpinner />, []);

  return (
    <StateHandler data={filters} state={dataState} renderPlaceholder={renderPlaceholder} renderError={renderError}>
      {(config) => <DataFilter config={config} onChange={onChange} values={values} />}
    </StateHandler>
  );
}
