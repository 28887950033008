import {SalaryRange} from 'domain/salaryRange/model';
import {UserFullInfoRecord} from 'domain/userFullInfoRecord/model';
import {getSalaryRelatedEntities} from 'domain/userFullInfoRecord/utils/getSalaryRelatedEntities';
import {SalaryRelatedEntity} from 'domain/userFullInfoRecord/widgets/SalaryRelatedEntity';
import React from 'react';

type Props = {
  record: UserFullInfoRecord;
  renderValue: (salaryRange: SalaryRange | undefined) => React.ReactNode;
};

export function SalaryRangeValue({record, renderValue}: Props) {
  const salaryRanges = getSalaryRelatedEntities(record, record.salaryRange);

  return <SalaryRelatedEntity entities={salaryRanges} renderValue={renderValue} />;
}
