import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {getUserOfferIdFx} from 'domain/hiringProcessInfo/stores/offerId';
import {$userOfferIds} from 'domain/hiringProcessInfo/stores/offerId/state';
import {UserFull} from 'domain/user/model';
import {useStoreMap} from 'effector-react';
import {useEffect} from 'react';
import {offerUrls} from 'routes/offer/urls';
import {toaster} from 'services/toaster';
import {useHiringProcessAccess} from './useHiringProcessAccess';

type ReturnShape = {
  offerLink: string | undefined;
  dataState: DataState;
};

export function useOfferLink(user: UserFull): ReturnShape {
  const {canReadOfferLink} = useHiringProcessAccess(user);
  const offerId = useStoreMap({
    store: $userOfferIds,
    keys: [user.id],
    fn: ({byUserId}, [userId]) => byUserId[userId],
  });
  const dataState = useStoreMap({
    store: $userOfferIds,
    keys: [user.id],
    fn: ({dataStateByUserId}, [userId]) => dataStateByUserId[userId] || DataState.IDLE,
  });

  useEffect(() => {
    if (canReadOfferLink && !isLoadingOrLoaded(dataState)) {
      getUserOfferIdFx(user.id).catch((error: Error) => toaster.error(error.message));
    }
  }, [canReadOfferLink, user.id]);

  return {
    offerLink: offerId ? offerUrls.offer({id: offerId}) : undefined,
    dataState,
  };
}
