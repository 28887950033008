import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {ServerSecurePermission} from 'domain/permission/model/secure';
import {loadAllSecurePermissionsFx} from 'domain/permission/stores/secure/';
import {$securePermissions} from 'domain/permission/stores/secure/state';
import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {toaster} from 'services/toaster';
import {useSecurePersmissionsReadAccess} from './useSecurePersmissionsReadAccess';

type ReturnShape = {
  permissions: ServerSecurePermission[];
  dataState: DataState;
};

export function useSecurePermissions(): ReturnShape {
  const hasReadAccess = useSecurePersmissionsReadAccess();
  const {permissions, dataState} = useStore($securePermissions);

  useEffect(() => {
    if (hasReadAccess && !isLoadingOrLoaded(dataState)) {
      loadAllSecurePermissionsFx().catch(toaster.interceptThenThrowError);
    }
  }, [hasReadAccess]);

  return {
    permissions,
    dataState,
  };
}
