import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {RegularBonusReadAccess} from 'domain/regularBonusRecord/model/access';
import {UserFull} from 'domain/user/model';
import {useRegularBonusReadAccess} from './useRegularBonusReadAccess';

export function useRegularBonusesAvailability(user: UserFull | undefined, records: RegularBonusRecord[]): boolean {
  const readAccess = useRegularBonusReadAccess(user);
  const recordsExist = records.length > 0;

  const canSeeAnyRegularBonuses = readAccess === RegularBonusReadAccess.ANY;
  const canSeeSubordinateRegularBonuses = recordsExist && readAccess === RegularBonusReadAccess.SUBORDINATE;
  const canSeeOwnRegularBonuses = recordsExist && readAccess === RegularBonusReadAccess.MY;

  return canSeeAnyRegularBonuses || canSeeSubordinateRegularBonuses || canSeeOwnRegularBonuses;
}
