import {QueryRequestPayload} from 'api/types';
import {timeOffRequestsApi} from 'domain/timeOff/request/api';
import {TimeOffRequest, TimeOffRequestsSearchFilters} from 'domain/timeOff/request/model';
import {getAllTimeOffRequestsFx, getAllTimeOffRequestsWithCountFx} from '.';

const getAllRequestsWithoutCount = async (
  query: QueryRequestPayload<TimeOffRequestsSearchFilters>,
): Promise<TimeOffRequest[]> => {
  const requestsWithCount = await getAllTimeOffRequestsWithCountFx(query);
  return requestsWithCount.items;
};

getAllTimeOffRequestsFx.use(getAllRequestsWithoutCount);
getAllTimeOffRequestsWithCountFx.use(timeOffRequestsApi.all);
