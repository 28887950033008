import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {Markdown} from 'components/ui/Markdown';
import {JdmOwnersList} from 'components/widgets/JdmOwnersList';
import {Service} from 'domain/service/model';
import {ServiceLink} from 'domain/service/widgets/Link';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

type Props = {
  services: Service[];
  dataState: DataState;
};

const getRowKey = ({id}: Service) => id;

export function ServiceTable({services, dataState}: Props) {
  const intl = useIntl();
  const columns: Column<Service>[] = useMemo(
    () => [
      {
        defaultWidth: 5,
        id: ColumnId.NAME,
        name: intl.formatMessage(columnNames[ColumnId.NAME]),
        render: (service) => <ServiceLink service={service} />,
      },
      {
        defaultWidth: 12,
        id: ColumnId.DESCRIPTION,
        name: intl.formatMessage(columnNames[ColumnId.DESCRIPTION]),
        render: ({description}) => (description ? <Markdown source={description} /> : <DataTable.CellEmpty />),
      },
      {
        defaultWidth: 10,
        id: ColumnId.OWNERS,
        name: intl.formatMessage(columnNames[ColumnId.OWNERS]),
        render: ({owners, owningMemberships}) =>
          owners.length > 0 || owningMemberships.length > 0 ? (
            <JdmOwnersList owners={owners} owningMemberships={owningMemberships} />
          ) : (
            <DataTable.CellEmpty />
          ),
      },
    ],
    [intl],
  );

  return (
    <DataTable
      columns={columns}
      dataState={dataState}
      data={services}
      emptyMessage={intl.formatMessage(messages.empty)}
      failedMessage={intl.formatMessage(messages.loadingFailed)}
      getRowKey={getRowKey}
      storageName='service.list'
    />
  );
}
