/* eslint-disable @typescript-eslint/naming-convention */
import cn from 'classnames';
import * as React from 'react';
import uuid from 'uuid/v4';
import {HelpPopup} from '../HelpPopup';
import {
  createLocator,
  getLocatorParameters,
  type ConvertLocatorToTestId,
  type Locator,
  type Mark,
} from '../create-locator';
import {Error} from './Error';
import {ErrorContainer, type ErrorContainerLocator} from './ErrorContainer';
import {Fieldset} from './Fieldset';
import styles from './index.css';
import {Warning} from './Warning';

export type RenderProps = {
  invalid: boolean;
  id: string;
  'aria-describedby': string;
};

export type FormControlTestId = ConvertLocatorToTestId<FormControlLocator>;
export type FormControlLocator = Locator<
  {
    label: void;
    hint: void;
    labelHint: void;
    content: void;
    errorContainer: ErrorContainerLocator;
  },
  {name?: string},
  'sameParameters'
>;

export type FormControlProps = {
  children: (options: RenderProps) => React.ReactNode;
  disabled?: boolean;
  error?: React.ReactNode;
  /** Only shown if there is no error */
  warning?: React.ReactNode;
  hint?: React.ReactNode;
  label?: React.ReactNode;
  labelHint?: React.ReactNode;
  required?: boolean;
  reserveSpaceForError?: boolean;
} & Partial<Mark<FormControlLocator>>;

export class FormControl extends React.Component<FormControlProps> {
  public static Fieldset = Fieldset;

  public static Error = Error;

  public static Warning = Warning;

  public static ErrorContainer = ErrorContainer;

  public id = uuid();

  private hintId = uuid();

  public render() {
    const {
      children,
      disabled,
      error,
      warning,
      hint,
      label,
      labelHint,
      reserveSpaceForError = true,
      ...restProperties
    } = this.props;
    const required = Boolean(this.props.required);

    const locator = createLocator(restProperties);
    const locatorParameters = getLocatorParameters(restProperties);

    return (
      <div
        {...locator(locatorParameters)}
        className={cn(styles.formControl, {
          [styles.formControlRequired]: required,
        })}
      >
        {label && (
          <div className={styles.labelWrapper}>
            <label
              {...locator.label()}
              className={cn(styles.label, {[styles.textDisabled]: disabled})}
              htmlFor={this.id}
            >
              {label}
            </label>
            {labelHint && <HelpPopup {...locator.labelHint()}>{labelHint}</HelpPopup>}
          </div>
        )}

        <div {...locator.content()} className={styles.content}>
          {children({id: this.id, invalid: !!error, 'aria-describedby': this.hintId})}
        </div>

        {hint && (
          <div id={this.hintId} className={cn(styles.hint, {[styles.textDisabled]: disabled})}>
            {hint}
          </div>
        )}

        <ErrorContainer
          error={error}
          warning={warning}
          reserveSpaceForError={reserveSpaceForError}
          {...locator.errorContainer()}
        />
      </div>
    );
  }
}
