import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {createPermissionDependentHandler} from 'apiClient/createPermissionDependentHandler';
import {SecurePermission} from 'domain/permission/model/secure';
import {SalaryRange, salaryRangeSchema} from 'domain/salaryRange/model';

const getSalaryRangesGeneric = (endpoint: string) => async (): Promise<SalaryRange[]> => {
  const {data} = await intranetApi.get(endpoint);

  return array(salaryRangeSchema).runWithException(data);
};

const getSalaryRangesAsAdmin = getSalaryRangesGeneric('/v1/secure/salaryRanges/getAsAdmin');
const getSalaryRangesAsManager = getSalaryRangesGeneric('/v1/secure/salaryRanges/getAsManager');

export const getSalaryRanges = createPermissionDependentHandler(
  [{securePermission: SecurePermission.SALARY_RANGE_READ_AS_ADMIN, handler: getSalaryRangesAsAdmin}],
  getSalaryRangesAsManager,
);
