import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import faker from 'faker';
import {BulkUploadMeta, BulkUploadRowStatus} from '.';

export function mockBulkUploadMeta(data: Partial<BulkUploadMeta> = {}): BulkUploadMeta {
  const hasErrors = Math.random() > 0.8;
  const status = hasErrors
    ? BulkUploadRowStatus.ERROR
    : faker.random.arrayElement(
        getEnumValues(BulkUploadRowStatus).filter((status) => status !== BulkUploadRowStatus.ERROR),
      );
  const errors = hasErrors
    ? Array.from({length: faker.random.number({min: 1, max: 2})}).map(() => faker.lorem.sentence())
    : [];

  return {
    row: faker.random.number(),
    errors,
    status,
    ...data,
  };
}
