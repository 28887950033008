import React from 'react';
import styles from '../../DataTable.css';

export const ScrollWrapper = React.forwardRef<HTMLDivElement, JSX.IntrinsicElements['div']>((props, ref) => {
  return <div ref={ref} {...props} className={styles.scrollView} />;
});

export const ManualScrollWrapper = React.forwardRef<HTMLDivElement, JSX.IntrinsicElements['div']>(
  ({style, onScroll, ...props}, ref) => {
    return <ScrollWrapper ref={ref} {...props} style={{...style, height: 'auto'}} />;
  },
);
