import {createIdFilter, DataFilter, DataFilterConfig} from '@joomcode/joom-ui/DataFilter';
import {TimeOffRequestId} from 'domain/timeOff/request/model/id';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import {IdFilters} from './types';

type Props = {
  onChange(payload: IdFilters): void;
  id?: TimeOffRequestId;
};

export const TimeOffRequestIdFilters = ({onChange, id}: Props) => {
  const intl = useIntl();

  const config: DataFilterConfig<IdFilters> = useMemo(() => {
    return {
      id: createIdFilter(intl.formatMessage(messages.id), {
        alwaysVisible: true,
      }),
    };
  }, [intl]);

  const values = useMemo(() => ({id}), [id]);

  return <DataFilter config={config} onChange={onChange} values={values} />;
};
