import {FavoriteOfficeRedirect} from 'domain/officeMap/office/widgets/FavoriteOfficeRedirect';
import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {RegularLayout} from 'layouts/Regular';
import {OfficeMapPage} from 'pages/OfficeMap';
import {OfficeRoomTokensPage} from 'pages/OfficeRoomTokens';
import {RouteConfig} from 'react-router-config';
import {officeUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: officeUrlPatterns.root,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: withPermission(OfficeRoomTokensPage, Permission.OFFICE_ROOM_TOKEN_GENERATE),
      exact: true,
      path: officeUrlPatterns.roomTokens,
    },
    {
      component: withPermission(OfficeMapPage, Permission.OFFICE_MAP_VIEW),
      exact: true,
      path: officeUrlPatterns.office,
    },
    {
      component: FavoriteOfficeRedirect,
      exact: true,
      path: officeUrlPatterns.root,
    },
  ],
};
