import {DataState} from '@joomcode/deprecated-utils/dataState';
import {LegalEntity} from 'domain/legalEntity/model';
import {createStore} from 'effector';
import {getLegalEntitiesFx, updateLegalEntityFx} from '.';

type State = {
  legalEntities: LegalEntity[];
  dataState: DataState;
};

export const $legalEntities = createStore<State>({
  legalEntities: [],
  dataState: DataState.IDLE,
})
  .on(getLegalEntitiesFx, (state) => {
    return {
      ...state,
      dataState: DataState.LOADING,
    };
  })
  .on(getLegalEntitiesFx.fail, (state) => {
    return {
      ...state,
      legalEntities: [],
      dataState: DataState.FAILED,
    };
  })
  .on(getLegalEntitiesFx.done, (state, {result}) => {
    return {
      ...state,
      legalEntities: result,
      dataState: DataState.LOADED,
    };
  })
  .on(updateLegalEntityFx.done, (state, {result}) => {
    return state.dataState === DataState.LOADED
      ? {
          ...state,
          legalEntities: state.legalEntities.map((legalEntity) =>
            legalEntity.id === result.id ? result : legalEntity,
          ),
        }
      : state;
  });
