import {useUserManager} from 'domain/user/hooks/useUserManager';
import {User} from 'domain/user/model';
import {UserPreview} from 'domain/user/widgets/Preview';
import React from 'react';

type Props = {
  user: User;
};

export function UserPreviewWrapper({user}: Props) {
  const manager = useUserManager(user);
  return <UserPreview user={user} manager={manager} />;
}
