import cn from 'classnames';
import {type Mark, createLocator, getLocatorParameters, type Locator} from 'create-locator';
import React from 'react';
import styles from './index.css';
import {intentToIcon} from './utils/intentToIcon';
import {CalloutIntent} from './utils/calloutIntent';
import {classNameByCalloutIntent} from './utils/classNameByCalloutIntent';
import {CloseButton} from './utils/CloseButton';

export * from './utils/calloutIntent';
export * from './utils/classNameByCalloutIntent';

export type CalloutLocator<T extends {[key: string]: string} = {[key: string]: string}> = Locator<
  {
    content: void;
    contentAddon: void;
    contentData: void;
    icon: void;
    title: void;
  },
  T,
  'sameParameters'
>;

export enum CalloutSize {
  M = 'm',
  L = 'l',
}

export type CalloutProps = {
  inline?: boolean;
  /** @deprecated Custom icon won’t be supported in the future, consider using prop `withIcon`  */
  icon?: React.ReactNode;
  addon?: React.ReactNode;
  children?: React.ReactNode;
  intent: CalloutIntent;
  title?: React.ReactNode;
  withCloseButton?: boolean;
  withIcon?: boolean;
  withMarginBottom?: boolean;
  onClose?: () => void;
} & Partial<Mark<CalloutLocator>>;

export function Callout({
  icon: iconFromProps,
  addon,
  children,
  intent,
  title,
  inline,
  withCloseButton = false,
  onClose,
  withIcon = false,
  withMarginBottom = false,
  ...restProps
}: CalloutProps) {
  const locator = createLocator(restProps);
  const locatorParameters = getLocatorParameters(restProps);
  const icon = iconFromProps || withIcon ? intentToIcon(intent) : undefined;

  return (
    <div
      className={cn(styles.callout, classNameByCalloutIntent[intent], {
        [styles.inline]: inline,
        [styles.withMarginBottom]: withMarginBottom,
      })}
      {...locator(locatorParameters)}
    >
      {icon ? (
        <div className={styles.icon} {...locator.icon()}>
          {icon}
        </div>
      ) : null}
      <div className={styles.content} {...locator.content()}>
        {title ? (
          <div className={styles.title} {...locator.title()}>
            {title}
          </div>
        ) : null}
        {children ? (
          <div className={styles.contentData} {...locator.contentData()}>
            {children}
          </div>
        ) : null}
        {addon ? (
          <div className={styles.contentAddon} {...locator.contentAddon()}>
            {addon}
          </div>
        ) : null}
      </div>
      {withCloseButton && <CloseButton className={styles.closeButton} onClick={onClose} />}
    </div>
  );
}
