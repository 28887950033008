import {ValidationFunction, ValidationOptions} from '@joomcode/joom-form';
import {formatPhone} from 'models/system/phone';
import {isValidPhoneNumber} from 'react-phone-number-input';

export enum PhoneValidatorErrorCode {
  PHONE_INVALID = 'phone_invalid',
}

export const validatePhone: ValidationFunction<string, ValidationOptions, PhoneValidatorErrorCode> = (
  value: string,
) => {
  return value && !isValidPhoneNumber(formatPhone(value)) ? {code: PhoneValidatorErrorCode.PHONE_INVALID} : undefined;
};
