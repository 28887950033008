import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Filter by key',
    description: 'Search input placeholder',
  },
  header: {
    defaultMessage: 'Filter rtconf items',
    description: '[title] Search Header',
  },
});
