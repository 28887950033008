import {identity} from '@joomcode/deprecated-utils/function';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {OmitUnion} from '@joomcode/deprecated-utils/types';
import {MultiSelect, MultiSelectProps} from '@joomcode/joom-ui/Select/MultiSelect';
import {SimpleAutocomplete, SimpleAutocompleteProps} from 'components/ui/SimpleAutcomplete';
import {getLanguageName, Language} from 'domain/language/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';

type LanguageSelectProps = Omit<
  SimpleAutocompleteProps<Language>,
  'items' | 'getItemKey' | 'itemToString' | 'renderItem'
> & {
  items?: Language[];
};
type LanguagesSelectProps = OmitUnion<
  MultiSelectProps<Language, unknown>,
  // MultiSelectProps is a union type that assumes either `items` or `groupedItems` and `groupToString` params to be passed
  // To redefine `items` prop we need to exclude the keys from the other branch of the union (groupedItems & groupToString)
  'items' | 'itemToString' | 'value' | 'groupedItems' | 'groupToString'
> & {
  items?: Language[];
  value: Language[] | undefined;
};

const emptyLanguageArray: Language[] = [];
const allLanguages = getEnumValues(Language);

export function LanguageSelect(props: LanguageSelectProps) {
  const intl = useIntl();
  const getLanguageLabel = useCallback((language: Language) => getLanguageName(language, intl), [intl]);
  const items = props.items ?? allLanguages;

  return (
    <SimpleAutocomplete<Language>
      {...props}
      items={items}
      getItemKey={identity}
      itemToString={getLanguageLabel}
      renderItem={getLanguageLabel}
      value={props.value || null}
    />
  );
}

export function LanguagesSelect({value, ...props}: LanguagesSelectProps) {
  const intl = useIntl();
  const getLanguageLabel = useCallback((language: Language) => getLanguageName(language, intl), [intl]);
  const items = props.items ?? allLanguages;

  return (
    <MultiSelect<Language>
      {...props}
      items={items}
      itemToString={getLanguageLabel}
      value={value || emptyLanguageArray}
    />
  );
}
