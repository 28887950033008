import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  buttonGenerate: {
    defaultMessage: 'Generate',
    description: '[button] Generate token',
  },
  buttonCopy: {
    defaultMessage: 'Copy',
    description: '[button] Copy token to clipboard',
  },
});
