import {QueryRequestPayload} from 'api/types';
import {CountableRoleBindigns, RoleBinding, RoleBindingsSearchFilters} from 'domain/roleBinding/model';
import {RoleBindingStatus} from 'domain/roleBinding/model/status';
import {getRoleBindingsWithCountFx} from 'domain/roleBinding/stores/main';
import {getRoleBindingRequestsFx, getRoleBindingRequestsWithCountFx} from '.';

const getRoleBindingRequestsWithCount = async (
  query: QueryRequestPayload<RoleBindingsSearchFilters>,
): Promise<CountableRoleBindigns> =>
  getRoleBindingsWithCountFx({
    ...query,
    filters: {
      ...query.filters,
      statuses: [RoleBindingStatus.PENDING],
    },
  });

const getRoleBindingRequestsWithoutCount = async (
  query: QueryRequestPayload<RoleBindingsSearchFilters>,
): Promise<RoleBinding[]> => {
  const {items} = await getRoleBindingRequestsWithCountFx(query);
  return items;
};

getRoleBindingRequestsWithCountFx.use(getRoleBindingRequestsWithCount);
getRoleBindingRequestsFx.use(getRoleBindingRequestsWithoutCount);
