import {isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {Page} from '@joomcode/joom-ui/Page';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {Permission} from 'domain/permission/model';
import {RedirectRouteCreationConfig} from 'domain/redirectRoute/model';
import {createRedirectRouteFx, getRedirectRoutesFx} from 'domain/redirectRoute/stores/main';
import {$redirectRoutes} from 'domain/redirectRoute/stores/main/state';
import {RedirectRouteForm} from 'domain/redirectRoute/widgets/Form';
import {RedirectRoutesTable} from 'domain/redirectRoute/widgets/Table';
import {useStore} from 'effector-react';
import React, {useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/redirector/titles';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

export function RedirectorPage() {
  const acl = useAcl();
  const intl = useIntl();
  const {redirectRoutes, dataState} = useStore($redirectRoutes);

  useEffect(() => {
    if (!isLoadingOrLoaded(dataState)) {
      getRedirectRoutesFx().catch(toaster.interceptThenThrowError);
    }
  }, []);

  const onSubmit = useCallback(
    (config: RedirectRouteCreationConfig) =>
      createRedirectRouteFx(config)
        .then(() => undefined)
        .catch(toaster.interceptThenThrowError),
    [],
  );

  return (
    <PageStateHandler data={redirectRoutes} state={dataState}>
      {() => (
        <Page title={intl.formatMessage(pageTitles.redirector)}>
          {acl.hasPermission(Permission.REDIRECT_WRITE) && <RedirectRouteForm onSubmit={onSubmit} />}
          <RedirectRoutesTable redirectRoutes={redirectRoutes} />
        </Page>
      )}
    </PageStateHandler>
  );
}
