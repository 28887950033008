import React from 'react';
import {ReactComponent as Icon} from '../../icons/core/copy.svg';
import {CopyHeadless} from '../Headless';
import {Button} from '../../Button';
import {ButtonIntent, ButtonKind, ButtonSize} from '../../Button/types';

export type CopyButtonProps = {
  intent?: ButtonIntent;
  kind?: ButtonKind;
  label?: string;
  size?: ButtonSize;
  value: string;
  withIcon?: boolean;
};

export function CopyButton({
  intent = 'neutral',
  kind = 'primary',
  label,
  size = 'm',
  value,
  withIcon,
}: CopyButtonProps) {
  return (
    <CopyHeadless value={value}>
      {({copy, label: defaultLabel}) => (
        <Button iconLeft={withIcon && <Icon />} intent={intent} kind={kind} onClick={copy} size={size}>
          {label ?? defaultLabel}
        </Button>
      )}
    </CopyHeadless>
  );
}
