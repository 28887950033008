import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  active: {
    defaultMessage: 'Active',
    description: 'Active badge',
  },
  inactive: {
    defaultMessage: 'Inactive',
    description: 'Active badge',
  },
});
