import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  region: {
    defaultMessage: 'Region',
    description: 'Salary ranges filter label',
  },
  levelCode: {
    defaultMessage: 'Level code',
    description: 'Salary ranges filter label',
  },
  userFunction: {
    defaultMessage: 'Function',
    description: 'Salary ranges filter label',
  },
  groupOfFunctions: {
    defaultMessage: 'Group of functions',
    description: 'Salary ranges filter label',
  },
  currency: {
    defaultMessage: 'Currency',
    description: 'Salary ranges filter label',
  },
});
