import {CompensationRequest} from 'domain/compensations/request/model';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {UserId} from 'domain/user/model';

export enum CompensationRequestsMode {
  ME = 'me',
  SUBORDINATES = 'subordinates',
  ALL = 'all',
}

export const checkRequestNeedsReaction = (
  {status, approver}: CompensationRequest,
  mode: CompensationRequestsMode,
  selfUserId: UserId,
): boolean =>
  (mode === CompensationRequestsMode.ME && status === CompensationRequestStatus.NEEDS_REPORT) ||
  (mode === CompensationRequestsMode.SUBORDINATES &&
    status === CompensationRequestStatus.CREATED &&
    approver.id === selfUserId) ||
  (mode === CompensationRequestsMode.ALL && status === CompensationRequestStatus.REPORTED);
