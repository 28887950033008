import {generatePath} from 'react-router-dom';
import {addSearchParams} from 'routes/addSearchParams';

export const hrConsoleUrlPatterns = {
  root: '/hr-console',
  users: '/hr-console/joomers',
  currentSalaryRanges: '/hr-console/salary-ranges/current',
  management: '/hr-console/salary-ranges/manage',
  compensationReviewRequest: '/hr-console/compensation-review/:id',
  compensationReviewRequests: '/hr-console/compensation-review',
  compensationReviewRequestCreate: '/hr-console/compensation-review/create',
};

export const hrConsoleUrls = {
  root: () => hrConsoleUrlPatterns.root,
  users: () => hrConsoleUrlPatterns.users,
  currentSalaryRanges: (searchParams?: {region?: string; userFunction?: string}) =>
    addSearchParams(hrConsoleUrlPatterns.currentSalaryRanges, searchParams),
  management: () => hrConsoleUrlPatterns.management,
  compensationReviewRequest: (pathParams: {id: string}) =>
    generatePath(hrConsoleUrlPatterns.compensationReviewRequest, pathParams),
  compensationReviewRequests: () => hrConsoleUrlPatterns.compensationReviewRequests,
  compensationReviewRequestCreate: () => hrConsoleUrlPatterns.compensationReviewRequestCreate,
};
