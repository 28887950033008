import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {PageTabs} from 'components/ui/PageTabs';
import {TimeOffRequestsTableMode} from 'domain/timeOff/request/model/mode';
import {timeOffRequestModes} from 'domain/timeOff/request/model/mode/messages';
import {TimeOffRequestStatus} from 'domain/timeOff/request/model/status';
import {$subordinatesTimeOffRequests} from 'domain/timeOff/request/stores/main/state';
import {useStore} from 'effector-react';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {timeOffUrls} from 'routes/timeOff/urls';

type Props = {
  activeMode: TimeOffRequestsTableMode;
  canViewAllRequests: boolean;
  hasSubordinates: boolean;
};

export function ModeTabs({activeMode, canViewAllRequests, hasSubordinates}: Props) {
  const intl = useIntl();
  const {data: subordinateRequests} = useStore($subordinatesTimeOffRequests);
  const tabVisibilityByMode = {
    [TimeOffRequestsTableMode.MY]: true,
    [TimeOffRequestsTableMode.SUBORDINATES]: hasSubordinates,
    [TimeOffRequestsTableMode.ALL]: canViewAllRequests,
  };

  const notificationCountByMode = useMemo(
    () => ({
      [TimeOffRequestsTableMode.MY]: 0,
      [TimeOffRequestsTableMode.SUBORDINATES]: subordinateRequests.filter(
        (r) => r.status === TimeOffRequestStatus.REQUESTED,
      ).length,
      [TimeOffRequestsTableMode.ALL]: 0,
    }),
    [subordinateRequests],
  );

  return (
    <PageTabs>
      {getEnumValues(TimeOffRequestsTableMode).map((mode) =>
        tabVisibilityByMode[mode] ? (
          <PageTabs.Tab
            title={intl.formatMessage(timeOffRequestModes[mode])}
            path={timeOffUrls.requests({mode})}
            active={activeMode === mode}
            notificationsCount={notificationCountByMode[mode]}
            key={mode}
          />
        ) : null,
      )}
    </PageTabs>
  );
}
