import {defineMessages} from 'react-intl';

export const autocompleteMessages = defineMessages({
  placeholder: {
    defaultMessage: 'Search',
    description: 'Autocomplete filter placeholder',
    id: 'autocomplete-filter-placeholder',
  },
  empty: {
    defaultMessage: 'Not found',
    description: 'Autocomplete filter empty options',
    id: 'autocomplete-filter-empty',
  },
});

export const asyncFilterMessages = defineMessages({
  loading: {
    defaultMessage: 'Loading...',
    description: 'Async filter loading options placeholder',
    id: 'async-select-filter-loading',
  },
  error: {
    defaultMessage: 'Failed to load filter options',
    description: 'Async filter loading options error',
    id: 'async-select-filter-error',
  },
});

export const dataFilterMessages = defineMessages({
  addFilterMenuAriaLabel: {
    defaultMessage: 'Add filter',
    description: 'Add filter menu aria label',
  },
  searchFiltersPlaceholder: {
    defaultMessage: 'Search filters',
    description: 'Placeholder in filters adding autocomplete',
  },
});
