import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  btnEdit: {
    defaultMessage: 'Edit',
    description: '[button] Team page: Edit button',
  },
  btnMoveToAnotherTeam: {
    defaultMessage: 'Move to another team',
    description: '[button] Team page: Move to another team button',
  },
});
