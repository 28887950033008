import {FieldInputNumber} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {Grid} from '@joomcode/joom-ui/Grid';
import {ReactComponent as TrashIcon} from '@joomcode/joom-ui/icons/core/trash.svg';
import {Panel} from '@joomcode/joom-ui/Panel';
import {OfferStockOptions} from 'domain/offer/model/stockOption';
import {stockOptionFieldLabels} from 'domain/offer/model/stockOption/messages';
import {OfferFormSectionTitle} from 'domain/offer/widgets/FormSectionTitle';
import {StockOptionsProposal} from 'domain/offer/widgets/StockOptionsProposal';
import React from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  stockOptions?: OfferStockOptions;
  onRemove(): void;
};

export function OfferStockOptionsFields({stockOptions, onRemove}: Props) {
  const intl = useIntl();
  const {change, getState} = useForm();

  return (
    <>
      <OfferFormSectionTitle title={intl.formatMessage(messages.title)} />
      <Panel.Content withPadding>
        <Grid>
          <Grid.Item xl={4} sm={12}>
            <FieldInputNumber
              name='stockOptions.amount'
              label={intl.formatMessage(stockOptionFieldLabels.amount)}
              initialValue={stockOptions?.amount}
              min={0}
              step={100}
              minExcluded
              reserveSpaceForError={false}
              required
            />
          </Grid.Item>
          <Grid.Item xl={8} sm={12}>
            <div className={styles.proposal}>
              <StockOptionsProposal
                enteredAmount={getState().values.stockOptions?.amount}
                onRecommendationClick={(amount) => change('stockOptions.amount', amount)}
              />
            </div>
          </Grid.Item>
        </Grid>
        <div className={styles.button}>
          <Button kind='secondary' intent='neutral' size='m' iconLeft={<TrashIcon />} onClick={onRemove}>
            {intl.formatMessage(messages.buttonRemove)}
          </Button>
        </div>
      </Panel.Content>
    </>
  );
}
