import {defineMessages} from 'react-intl';
import {CompensationRequestsMode} from '.';

export const modeNames = defineMessages<CompensationRequestsMode>({
  [CompensationRequestsMode.ME]: {
    defaultMessage: 'My requests',
    description: '[title] Compensations page tab name',
  },
  [CompensationRequestsMode.SUBORDINATES]: {
    defaultMessage: 'My report requests',
    description: '[title] Compensations page tab name',
  },
  [CompensationRequestsMode.ALL]: {
    defaultMessage: 'All requests',
    description: '[title] Compensations page tab name',
  },
});
