import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {useTypedParams} from '@joomcode/deprecated-utils/react/useTypedParams';
import {EntityPageStateHandler} from 'components/ui/PageStateHandler';
import {Page} from 'components/widgets/Page';
import {CompensationReportForm} from 'domain/compensations/report/widgets/Form';
import {compensationRequestIdSchema} from 'domain/compensations/request/model';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {getRequestByIdFx} from 'domain/compensations/request/stores/main';
import {$compensationRequests} from 'domain/compensations/request/stores/main/state';
import {useStoreMap} from 'effector-react';
import {NotFoundPage} from 'pages/NotFound';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {pageTitles} from 'routes/compensations/titles';
import {compensationsUrls} from 'routes/compensations/urls';
import {toaster} from 'services/toaster';
import {formatDateRange} from 'utils/date/formatDateRange';

export function CompensationsReportPage() {
  const intl = useIntl();
  const {compensationRequestId} = useTypedParams({
    compensationRequestId: compensationRequestIdSchema,
  });

  const [compensationRequest, error, dataState] = useStoreMap({
    store: $compensationRequests,
    keys: [compensationRequestId],
    fn: (compensations, [requestId]) =>
      requestId === undefined
        ? [undefined, undefined, DataState.IDLE]
        : [
            compensations.requestsById[requestId],
            compensations.requestErrorsById[requestId],
            compensations.requestStatesById[requestId] ?? DataState.IDLE,
          ],
  });

  useEffect(() => {
    if (compensationRequestId && !isLoadingOrLoaded(dataState)) {
      getRequestByIdFx(compensationRequestId).catch(toaster.interceptThenThrowError);
    }
  }, [compensationRequestId]);

  if (!compensationRequestId) {
    return <NotFoundPage />;
  }

  return (
    <EntityPageStateHandler data={compensationRequest} entityError={error} state={dataState}>
      {(request) =>
        request.status === CompensationRequestStatus.NEEDS_REPORT && request.report ? (
          <Page
            breadcrumbs={[
              {title: intl.formatMessage(pageTitles.requests), href: compensationsUrls.root()},
              {
                title: intl.formatMessage(pageTitles.request),
                href: compensationsUrls.request({compensationRequestId}),
              },
            ]}
            title={intl.formatMessage(pageTitles.reportBusinessTrip, {
              dateRange: formatDateRange(request.startDate, request.endDate, intl),
            })}
          >
            <CompensationReportForm compensationRequestId={request.id} report={request.report} />
          </Page>
        ) : (
          <NotFoundPage />
        )
      }
    </EntityPageStateHandler>
  );
}
