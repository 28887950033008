import {idSchema} from '@joomcode/deprecated-utils/joomId';
import {ExtractSchemaType, object, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {CountryCode, countryCodeSchema} from 'domain/countryCode/model';
import {Currency, currencySchema} from 'domain/currency/model';

export const legalEntityIdSchema = idSchema;
export type LegalEntityId = string;

export const legalEntitySchema = object({
  countryCode: optional(countryCodeSchema),
  currency: optional(currencySchema),
  id: legalEntityIdSchema,
  title: string(),
});

export type LegalEntity = ExtractSchemaType<typeof legalEntitySchema>;

export type LegalEntityDiff = {
  countryCode: CountryCode;
  currency: Currency;
};
