import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {ServerPermission} from 'domain/permission/model';
import {UserRole, UserRoleId} from 'domain/userRole/model';
import {createStore} from 'effector';
import {createUserRoleFx, loadAllPermissionsFx, loadAllUserRolesFx, loadUserRoleByIdFx, updateUserRoleFx} from '.';

type State = {
  rolesById: Record<UserRoleId, UserRole>;
  rolesDataState: DataState;

  permissions: ServerPermission[];
  permissionsDataState: DataState;
};

export const $userRoles = createStore<State>({
  rolesById: {},
  rolesDataState: DataState.IDLE,

  permissions: [],
  permissionsDataState: DataState.IDLE,
})
  .on(loadAllUserRolesFx, (state) => {
    return {
      ...state,
      rolesDataState: DataState.LOADING,
    };
  })
  .on(loadAllUserRolesFx.fail, (state) => {
    if (state.rolesDataState !== DataState.LOADING) {
      return state;
    }
    return {
      ...state,
      rolesById: {},
      rolesDataState: DataState.FAILED,
    };
  })
  .on(loadAllUserRolesFx.done, (state, {result}) => {
    if (state.rolesDataState !== DataState.LOADING) {
      return state;
    }
    return {
      ...state,
      rolesById: arrayToObject(result, (userRole) => userRole.id),
      rolesDataState: DataState.LOADED,
    };
  })

  .on(loadAllPermissionsFx, (state) => {
    return {
      ...state,
      permissionsDataState: DataState.LOADING,
    };
  })
  .on(loadAllPermissionsFx.fail, (state) => {
    if (state.permissionsDataState !== DataState.LOADING) {
      return state;
    }
    return {
      ...state,
      permissions: [],
      permissionsDataState: DataState.FAILED,
    };
  })
  .on(loadAllPermissionsFx.done, (state, {result}) => {
    return {
      ...state,
      permissions: result,
      permissionsDataState: DataState.LOADED,
    };
  })

  .on(updateUserRoleFx.fail, (state) => {
    if (state.rolesDataState !== DataState.LOADING) {
      return state;
    }
    return {
      ...state,
      rolesById: {},
      rolesDataState: DataState.FAILED,
    };
  })
  .on([updateUserRoleFx.doneData, createUserRoleFx.doneData, loadUserRoleByIdFx.doneData], (state, result) => {
    return {
      ...state,
      rolesById: {...state.rolesById, [result.id]: result},
    };
  });
