import {CountryCode} from 'domain/countryCode/model';

export enum TimeOffPolicy {
  ARMENIA = 'AM',
  BRAZIL = 'BR',
  CHINA = 'CN',
  GERMANY = 'DE',
  HONG_KONG = 'HK',
  KOREA = 'KR',
  LATVIA = 'LV',
  LUXEMBOURG = 'LU',
  PORTUGAL = 'PT',
  RUSSIA = 'RU',
  THAILAND = 'TH',
  NO_POLICY = 'noPolicy',
}

export const timeOffPolicyByCountry = {
  [CountryCode.AM]: TimeOffPolicy.ARMENIA,
  [CountryCode.BR]: TimeOffPolicy.BRAZIL,
  [CountryCode.CN]: TimeOffPolicy.CHINA,
  [CountryCode.DE]: TimeOffPolicy.GERMANY,
  [CountryCode.HK]: TimeOffPolicy.HONG_KONG,
  [CountryCode.KR]: TimeOffPolicy.KOREA,
  [CountryCode.LV]: TimeOffPolicy.LATVIA,
  [CountryCode.LU]: TimeOffPolicy.LUXEMBOURG,
  [CountryCode.PT]: TimeOffPolicy.PORTUGAL,
  [CountryCode.RU]: TimeOffPolicy.RUSSIA,
  [CountryCode.TH]: TimeOffPolicy.THAILAND,
} as const;

export const timeOffPolicySortingOrder = [
  TimeOffPolicy.ARMENIA,
  TimeOffPolicy.BRAZIL,
  TimeOffPolicy.CHINA,
  TimeOffPolicy.GERMANY,
  TimeOffPolicy.HONG_KONG,
  TimeOffPolicy.KOREA,
  TimeOffPolicy.LATVIA,
  TimeOffPolicy.LUXEMBOURG,
  TimeOffPolicy.PORTUGAL,
  TimeOffPolicy.RUSSIA,
  TimeOffPolicy.THAILAND,
  TimeOffPolicy.NO_POLICY,
];
