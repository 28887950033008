import Downshift, {ControllerStateAndHelpers, GetInputPropsOptions, DownshiftProps} from 'downshift';
import {Key} from '@joomcode/deprecated-utils/keyboard/keys';
import React, {PropsWithoutRef, useCallback, useMemo} from 'react';
import {stateReducer} from './stateReducer';
import {Option, OptionOrGroup, createOption, filterCategorizedItemsAndGroups} from './groups';

/**
 * Downshift’s GetInputPropsOptions uses React.LegacyRef, which is incompatible with React.forwardRef
 */
type GetInputPropsOptionsRef = PropsWithoutRef<GetInputPropsOptions>;

type SuggestionFilter<Item> = (item: Item, query: string) => boolean;

type ChildrenRenderProps<Item, Group> = {
  isOpen: boolean;
  filteredItems: OptionOrGroup<Item, Group>[];
  getInputProps: <T extends JSX.IntrinsicElements['input']>(options?: T) => GetInputPropsOptionsRef;
  downshift: ControllerStateAndHelpers<Item>;
};

// The grouping implementation is based on the implementation from the 'react-select' library
// https://github.com/JedWatson/react-select/blob/4e06d22e47bbd82a4f9f67452c7f16787c4ce529/packages/react-select/src/Select.tsx
export type HeadlessMultiSelectProps<Item, Group> = (
  | {
      items: Item[];
      groupedItems?: never;
      groupToString?: never;
    }
  | {
      items?: never;
      groupedItems: OptionOrGroup<Item, Group>[];
      groupToString: (group: Group) => string;
    }
) & {
  children(props: ChildrenRenderProps<Item, Group>): JSX.Element | null;
  disabled?: boolean;
  itemToString(item: Item): string;
  isOpen?: boolean;
  onBlur?(): void;
  onChange(items: Item[]): void;
  onFocus?(): void;
  suggestionFilter?: SuggestionFilter<Item>;
  value: Item[];
  onInputValueChange?: DownshiftProps<Item>['onInputValueChange'];
  optionsAreAlwaysVisible?: boolean;
};

function MultiSelectWithDownshift<Item, Group>({
  children,
  downshift,
  disabled,
  items,
  groupedItems,
  itemToString,
  onBlur,
  onChange,
  onFocus,
  optionsAreAlwaysVisible,
  suggestionFilter: customSuggestionFilter,
  value,
}: HeadlessMultiSelectProps<Item, Group> & {downshift: ControllerStateAndHelpers<Item>}) {
  const isOpen = !disabled && downshift.isOpen;

  const options: OptionOrGroup<Item, Group>[] = groupedItems ?? items?.map(createOption) ?? [];

  const suggestionFilter: SuggestionFilter<Item> =
    customSuggestionFilter || ((item, query) => itemToString(item).toLowerCase().includes(query.toLowerCase()));

  const {inputValue} = downshift;
  const valueAsString = useMemo(() => value.map((item) => itemToString(item)), [value, itemToString]);

  const filterFunction = (item: Option<Item>) =>
    !valueAsString.includes(itemToString(item.data)) && (!inputValue || suggestionFilter(item.data, inputValue));

  const filteredItemsOrGroups = useMemo(
    () => filterCategorizedItemsAndGroups(options, filterFunction),
    [options, valueAsString, inputValue],
  );

  return children({
    downshift,
    filteredItems: filteredItemsOrGroups,
    getInputProps: (settings) =>
      downshift.getInputProps<JSX.IntrinsicElements['input']>({
        ...settings,
        onKeyDown(event: DownshiftedReactKeyboardEvent<HTMLInputElement>) {
          if (event.key === Key.BACKSPACE && downshift.inputValue === '') {
            onChange(value.slice(0, -1));
          }

          /**
           * By default downshift resets state and calls event.preventDefault() on escape press.
           * But if state is already reset, event should not be prevented, it should be handled
           * by the upper scope, e.g. by popup that contains this component.
           */
          if (event.key === Key.ESCAPE && downshift.inputValue === '') {
            if (optionsAreAlwaysVisible || !downshift.isOpen) {
              // eslint-disable-next-line no-param-reassign
              event.nativeEvent.preventDownshiftDefault = true;
            }
          }
        },
        onBlur,
        onFocus() {
          onFocus?.();
          downshift.openMenu();
          downshift.setHighlightedIndex(0);
        },
      }),
    isOpen,
  });
}

export function HeadlessMultiSelect<Item, Group>(props: HeadlessMultiSelectProps<Item, Group>) {
  const handleSelect: Downshift<Item>['props']['onSelect'] = useCallback(
    (selectedItem: Item | null) => {
      if (selectedItem === null) {
        return;
      }

      const {value} = props;
      const newValue = value.includes(selectedItem)
        ? value.filter((item) => item !== selectedItem)
        : value.concat(selectedItem);

      props.onChange(newValue);
    },
    [props.value, props.onChange],
  );

  const itemToString = useCallback(
    (item: Item | null) => {
      if (item === null) {
        return '';
      }

      return props.itemToString(item);
    },
    [props.itemToString],
  );

  return (
    <Downshift
      onInputValueChange={props.onInputValueChange}
      isOpen={props.isOpen}
      onSelect={handleSelect}
      selectedItem={null}
      stateReducer={stateReducer}
      itemToString={itemToString}
    >
      {(downshift) => {
        return (
          <div>
            <MultiSelectWithDownshift {...props} downshift={downshift}>
              {props.children}
            </MultiSelectWithDownshift>
          </div>
        );
      }}
    </Downshift>
  );
}
