import {
  array,
  ExtractSchemaType,
  number,
  object,
  oneOfEnum,
  optional,
  string,
} from '@joomcode/deprecated-utils/jsonValidation';
import {crResultSchema} from 'domain/compaRatio/model';
import {LevelCode} from 'domain/levelCode/model';
import {moneySchema} from 'domain/money/model';
import {furtherSalarySchema} from 'domain/offer/model/furtherSalary';
import {offerRegularBonusSchema} from 'domain/offer/model/regularBonus';
import {offerSalarySchema} from 'domain/offer/model/salary';
import {OfferVersionId, offerVersionIdSchema} from 'domain/offer/model/version';

export const offerCompensationSchema = object({
  userFunction: optional(string()),
  levelCode: optional(oneOfEnum(LevelCode)),
  salary: offerSalarySchema,
  regularBonus: optional(offerRegularBonusSchema),
  signOnBonus: optional(moneySchema),
  relocationBonus: optional(moneySchema),
  employmentRate: number(),
  commentForHrAdmin: optional(string()),
  furtherSalary: optional(furtherSalarySchema),
  crResult: optional(crResultSchema),
});

export const versionedOfferCompensationListSchema = array(
  object({
    versionId: offerVersionIdSchema,
    compensation: optional(offerCompensationSchema),
  }),
);

export const compensationsCommentSchema = object({
  comment: optional(string()),
});

export type OfferSalary = ExtractSchemaType<typeof offerSalarySchema>;
export type OfferCompensation = ExtractSchemaType<typeof offerCompensationSchema>;
export type VersionedOfferCompensationList = ExtractSchemaType<typeof versionedOfferCompensationListSchema>;
export type VersionedOfferCompensations = Record<OfferVersionId, OfferCompensation | undefined>;
export type CompensationsComment = ExtractSchemaType<typeof compensationsCommentSchema>;
