import {isNullish} from '@joomcode/deprecated-utils/function/isNullish';
import {isBlank} from '@joomcode/deprecated-utils/string/isBlank';
import type {ValidationFunction} from '../../types';
import {JsonValidatorErrorCode, JsonValidatorOptions} from './types';

export const validateJson: ValidationFunction<unknown, JsonValidatorOptions, JsonValidatorErrorCode> = (
  value,
  options = {},
) => {
  if (isNullish(value) || (typeof value === 'string' && isBlank(value))) return undefined;

  if (typeof value !== 'string') {
    return {code: JsonValidatorErrorCode.JSON_INVALID, options};
  }

  try {
    JSON.parse(value);
    return undefined;
  } catch (error) {
    return {code: JsonValidatorErrorCode.JSON_INVALID, options};
  }
};
