import {Permission} from 'domain/permission/model';
import {useSelfUserId} from 'domain/self/hooks/useSelfUserId';
import {BalanceRecordsReadAccess} from 'domain/timeOff/balanceRecord/model/access';
import {UserId} from 'domain/user/model';
import {useAcl} from 'services/acl';

export function useBalanceRecordsReadAccess(userId: UserId | undefined): BalanceRecordsReadAccess | null {
  const acl = useAcl();
  const selfUserId = useSelfUserId();
  const isSelfUser = selfUserId === userId;

  const canReadSelfRecords = isSelfUser && acl.hasPermission(Permission.SELF_TIME_OFF_BALANCE_RECORD_READ);
  const canReadAnyRecords = acl.hasPermission(Permission.TIME_OFF_BALANCE_RECORD_READ);

  if (!userId) {
    return null;
  }

  if (canReadAnyRecords) {
    return BalanceRecordsReadAccess.ANY;
  }
  if (canReadSelfRecords) {
    return BalanceRecordsReadAccess.MY;
  }

  return null;
}
