import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {SecurePermission} from 'domain/permission/model/secure';
import {SecureRole, SecureRoleId} from 'domain/secureRole/model';
import {loadAllSecureRolesFx} from 'domain/secureRole/stores/main';
import {$secureRoles} from 'domain/secureRole/stores/main/state';
import {useStore} from 'effector-react';
import {useEffect, useState} from 'react';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';

type ReturnShape = {
  allowed: boolean;
  rolesById: Record<SecureRoleId, SecureRole>;
  dataState: DataState;
  error?: Error;
};

type Options = {
  enabled?: boolean;
  force?: boolean;
  handleError?: boolean;
};

export function useSecureRoles({enabled = true, force = false, handleError = true}: Options = {}): ReturnShape {
  const acl = useAcl();
  const allowed = acl.hasSecurePermission(SecurePermission.SECURE_ROLE_READ);

  const [error, setError] = useState<Error>();
  const {byId: rolesById, dataState} = useStore($secureRoles);

  useEffect(() => {
    if (allowed && enabled && (!isLoadingOrLoaded(dataState) || force)) {
      loadAllSecureRolesFx().catch(setError);
    }
  }, [allowed, enabled, force]);

  useEffect(() => {
    if (error && handleError) {
      toaster.error(error.message);
    }
  }, [error, handleError]);

  return {
    allowed,
    rolesById,
    dataState,
    error,
  };
}
