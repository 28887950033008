import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {createLocator} from '../../../create-locator';
import {Listbox} from '../../../Listbox';
import {AutocompleteInput} from '../../AutocompleteInput';
import {Control} from '../../Control';
import type {ControlRenderProps} from '../../types';
import styles from './AutocompleteControl.css';
import {autocompleteMessages} from '../../messages';
import type {AutocompleteFilterOptions} from './types';
import {containsSearchString} from '../../utils';

type Props<T> = ControlRenderProps<T, AutocompleteFilterOptions<T>>;

export function AutocompleteControl<T extends string>({name, value, onSubmit, onClose, options}: Props<T>) {
  const locator = createLocator(options);
  const intl = useIntl();
  const [inputValue, setInputValue] = useState<string>('');

  const filteredOptions = useMemo(() => {
    return options.options.filter((option) => {
      return containsSearchString(option.value, inputValue) || containsSearchString(option.label, inputValue);
    });
  }, [options.options, inputValue]);

  return (
    <Control onClose={onClose} scrollable={false} {...locator.control()} withFooter={false} withPadding={false}>
      <AutocompleteInput
        name={name}
        placeholder={options.placeholder ?? intl.formatMessage(autocompleteMessages.placeholder)}
        value={inputValue}
        onChange={setInputValue}
        {...locator.input()}
      />

      <div className={styles.searchResultSection}>
        <Listbox withDefaultPadding>
          {filteredOptions.length !== 0 ? (
            filteredOptions.map((option) => (
              <Listbox.Option
                key={option.value}
                isHighlighted={option.value === value}
                onClick={() => onSubmit(option.value)}
                {...locator.option({})}
              >
                {options.renderOption ? options.renderOption(option) : option.label}
              </Listbox.Option>
            ))
          ) : (
            <Listbox.Option disabled isHighlighted={false} {...locator.option({})}>
              {intl.formatMessage(autocompleteMessages.empty)}
            </Listbox.Option>
          )}
        </Listbox>
      </div>
    </Control>
  );
}
