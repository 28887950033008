export enum NumberValidatorErrorCode {
  NUMBER_INVALID = 'number_invalid',
  NUMBER_STRICTLY_LESS_MIN = 'number_strictly_less_min',
  NUMBER_LESS_OR_EQUAL_MIN = 'number_less_or_equal_min',
  NUMBER_STRICTLY_GREATER_MAX = 'number_strictly_greater_max',
  NUMBER_GREATER_OR_EQUAL_MAX = 'number_greater_or_equal_max',
  NUMBER_NOT_INTEGER = 'number_not_integer',
  NUMBER_NOT_DIVISIBLE = 'number_not_divisible',
  NUMBER_MAX_DECIMAL_PLACES = 'number_max_decimal_places',
}

export type NumberValidatorOptions = {
  min?: number;
  max?: number;
  minExcluded?: boolean;
  maxExcluded?: boolean;
  maxDecimalPlaces?: number;
  integer?: boolean;
  multiple?: number;
};
