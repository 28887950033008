import {Panel} from '@joomcode/joom-ui/Panel';
import {RouterLinkButton} from '@joomcode/joom-ui/RouterLinkButton';
import {HintIcon} from 'components/ui/HintIcon';
import {StyledLink} from 'components/ui/StyledLink';
import {FormattedTimeOffDays} from 'components/widgets/FormattedTimeOffDays';
import {Permission} from 'domain/permission/model';
import {useBalanceRecordsReadAccess} from 'domain/timeOff/balanceRecord/hooks/useBalanceRecordsReadAccess';
import {TimeOffRequestsTableMode} from 'domain/timeOff/request/model/mode';
import {User} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {timeOffUrls} from 'routes/timeOff/urls';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {useAnalytics} from 'services/analytics/useAnalytics';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  availableDays: number;
  plannedDays?: number;
  isSelfUser: boolean;
  user: User;
};

export function VacationBalance({availableDays, plannedDays = 0, isSelfUser, user}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {gtag} = useAnalytics();
  const readAccess = useBalanceRecordsReadAccess(user.id);
  const canRequestForSelf = isSelfUser && acl.hasPermission(Permission.SELF_TIME_OFF_REQUEST_WRITE);
  const canRequestOnBehalf = !isSelfUser && acl.hasPermission(Permission.TIME_OFF_REQUEST_WRITE_ON_BEHALF);
  const requestUrl = useMemo(
    () => (isSelfUser ? timeOffUrls.createRequest() : timeOffUrls.createRequestOnBehalf({login: user.login})),
    [isSelfUser, user.login],
  );

  const onRequestClick = useCallback(() => {
    gtag.logEvent('element_click', {
      element: isSelfUser ? 'vacation_balance_request' : 'vacation_balance_request_on_behalf',
    });
  }, [isSelfUser, gtag]);

  const onHistoryClick = useCallback(() => {
    gtag.logEvent('element_click', {element: 'vacation_balance_history'});
  }, [gtag]);

  return (
    <div className={styles.root}>
      <Panel withPadding withMarginBottom>
        <h3 className={styles.title}>{intl.formatMessage(messages.title)}</h3>
        {intl.formatMessage(messages.availableDays)}
        {plannedDays > 0 && <HintIcon>{intl.formatMessage(messages.hint)}</HintIcon>}
        <div className={styles.valueRow}>
          <div className={styles.value}>
            <FormattedTimeOffDays days={availableDays} onlyNumber />
          </div>
          {readAccess && (
            <StyledLink to={usersUrls.timeOffBalance({login: user.login})} colored>
              {intl.formatMessage(messages.details)}
            </StyledLink>
          )}
        </div>
        <Panel.Separator />
        <div className={styles.planned}>
          {intl.formatMessage(messages.plannedDays)}
          <div className={styles.valueRow}>
            <div className={styles.value}>
              <FormattedTimeOffDays days={plannedDays} onlyNumber />
            </div>
            {isSelfUser && acl.hasPermission(Permission.SELF_TIME_OFF_REQUEST_READ) && (
              <StyledLink
                to={timeOffUrls.requests({mode: TimeOffRequestsTableMode.MY})}
                colored
                onClick={onHistoryClick}
              >
                {intl.formatMessage(messages.historyLink)}
              </StyledLink>
            )}
          </div>
        </div>
        {(canRequestForSelf || canRequestOnBehalf) && (
          <>
            <Panel.Separator />
            <div className={styles.requestSection}>
              <RouterLinkButton to={requestUrl} kind='secondary' intent='neutral' size='m' onClick={onRequestClick}>
                {intl.formatMessage(messages.requestButton, {isSelfUser})}
              </RouterLinkButton>
            </div>
          </>
        )}
      </Panel>
    </div>
  );
}
