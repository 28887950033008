import {FormControl} from '@joomcode/joom-ui/FormControl';
import {currencyCodesWithoutTechnicalValues} from '@joomcode/deprecated-utils/currency';
import {Autocomplete, AutocompleteProps} from '@joomcode/joom-ui/Select/Autocomplete';
import React, {memo, useCallback} from 'react';
import {IntlShape, useIntl} from 'react-intl';
import {useFieldWithInitialValue} from '../../components';
import {CommonFieldProps} from '../types';
import {messages} from './messages';
import {getFieldErrorText} from '../../utils';
import {validateFilled, composeValidators, getFieldValidator} from '../../validation';

export type FieldCurrencyProps = CommonFieldProps<string> &
  Pick<AutocompleteProps<string>, 'itemToString' | 'renderItem' | 'clearable' | 'autoComplete'> & {
    currencies?: string[];
  };

function formatCurrency(currencyCode: string, intl: IntlShape) {
  const formattedText = currencyCode ? intl.formatDisplayName(currencyCode, {type: 'currency'}) : undefined;
  const formattedCode = currencyCode.toUpperCase();
  return formattedText && formattedText !== formattedCode ? `${formattedCode} (${formattedText})` : formattedCode;
}

export const FieldCurrency = memo(function FieldCurrency({
  disabled,
  error,
  hint,
  initialValue,
  itemToString: externalItemToString,
  label,
  labelHint,
  name,
  renderItem,
  required,
  reserveSpaceForError,
  validate,
  validateFields,
  currencies = currencyCodesWithoutTechnicalValues,
  ...restProps
}: FieldCurrencyProps) {
  const intl = useIntl();

  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {
    input: {value, ...restInput},
    meta,
  } = useFieldWithInitialValue<string | undefined>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    validateFields,
    parse: (value: string | null) => (value === null ? undefined : value),
  });

  const defaultItemToString = useCallback((item: string) => formatCurrency(item, intl), [intl]);
  const itemToString = externalItemToString || defaultItemToString;

  const filter = useCallback(
    (currencyCode: string, inputValue: string) => {
      const formattedText = (intl.formatDisplayName(currencyCode, {type: 'currency'}) || '').toLowerCase();
      const searchText = inputValue.trim().toLowerCase();

      return (
        formattedText.includes(searchText) ||
        currencyCode.toLowerCase().includes(searchText) ||
        itemToString(currencyCode).toLowerCase().includes(searchText)
      );
    },
    [itemToString],
  );

  return (
    <FormControl
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl})}
      hint={hint}
      label={label ?? intl.formatMessage(messages.label)}
      labelHint={labelHint}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <Autocomplete<string>
          disabled={meta.submitting || disabled}
          getItemKey={(code) => code}
          items={currencies}
          itemToString={itemToString}
          renderItem={renderItem || itemToString}
          filter={filter}
          noItemsText={intl.formatMessage(messages.noItemsText)}
          {...restProps}
          {...formControlProps}
          {...restInput}
          value={value ?? null}
        />
      )}
    </FormControl>
  );
});
