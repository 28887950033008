import {boolean} from '@joomcode/deprecated-utils/jsonValidation';
import {JsonSerializer, LocalStorage, SingleStorageManager} from '@joomcode/deprecated-utils/Storage';
import {JoomServiceLogo} from '@joomcode/joom-ui/JoomServiceLogo';
import {Navbar} from '@joomcode/joom-ui/Navbar';
import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import {Avatar} from 'components/ui/Avatar';
import {IntranetLogo} from 'components/widgets/IntranetLogo';
import {UserFull} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import React, {useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {mainUrls} from 'routes/main/urls';
import {usersUrls} from 'routes/users/urls';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';
import {messages} from './messages';
import styles from './styles.css';
import {breadcrumbsTeleport, notionGuideTeleport} from './teleport';

const vpnTooltipShown = new SingleStorageManager('vpnTooltipShown', new LocalStorage(), new JsonSerializer(boolean()));

type Props = {
  selfUser: UserFull;
};

export function Header({selfUser}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const breadcrumbsRef = breadcrumbsTeleport.handleTargetRef;
  const notionGuideRef = notionGuideTeleport.handleTargetRef;
  const selfFullName = intl.formatMessage(generalMessages.fullName, {
    firstName: selfUser.firstName,
    lastName: selfUser.lastName,
  });
  const invaderFeature = useFeature(Feature.INVADER_LOGO);
  const inVpn = acl.inVpn();
  const showTooltipOnInit = useMemo(() => !vpnTooltipShown.restore(), []);

  useEffect(() => {
    if (!inVpn) {
      vpnTooltipShown.store(true);
    }
  }, [inVpn]);

  useEffect(() => {
    if (selfUser.about?.includes('Joom Space')) {
      invaderFeature.setAvailability(true);
    }
  }, [selfUser.about]);

  return (
    <Navbar
      logo={
        <div className={styles.logo}>
          <Link to={mainUrls.root()}>
            <JoomServiceLogo>
              <IntranetLogo />
              {invaderFeature.isAvailable && ' 👾'}
            </JoomServiceLogo>
          </Link>
          {!inVpn && (
            <TooltipFaq showOnInit={showTooltipOnInit} content={intl.formatMessage(messages.tooltipContent)}>
              <span className={styles.limited}>{intl.formatMessage(messages.limited)}</span>
            </TooltipFaq>
          )}
        </div>
      }
      content={<div ref={breadcrumbsRef} />}
      toolbar={
        <>
          <div ref={notionGuideRef} />
          <Navbar.Link>
            {({className}) => (
              <Link to={usersUrls.user({login: selfUser.login})} className={className}>
                <span className={styles.selfName}>{selfFullName}</span>
                <Avatar name={selfFullName} imageBundle={selfUser.avatarBundle} size='s' />
              </Link>
            )}
          </Navbar.Link>
        </>
      }
    />
  );
}
