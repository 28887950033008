import {generatePath} from 'react-router-dom';

export const secureRolesUrlPatterns = {
  root: '/financialRoles',
  role: '/financialRoles/:id',
};

export const secureRolesUrls = {
  root: () => secureRolesUrlPatterns.root,
  role: (pathParams: {id: string}) => generatePath(secureRolesUrlPatterns.role, pathParams),
};
