import cn from 'classnames';
import * as React from 'react';
import uuid from 'uuid/v1';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type LocatorOfElement,
  type Mark,
  removeMarkFromProperties,
} from '../create-locator';
import styles from './index.css';

export type RadioTestId = ConvertLocatorToTestId<RadioLocator>;
export type RadioLocator = LocatorOfElement<{
  input: void;
  label: void;
}>;

export type RadioProps = {
  invalid?: boolean;
  readOnly?: boolean;
  label: React.ReactNode;
} & Partial<Mark<RadioLocator>>;

export class Radio extends React.Component<RadioProps & Omit<React.HTMLProps<HTMLInputElement>, 'label'>> {
  public id = uuid();

  public render() {
    const {label, className, invalid, disabled, readOnly, ...restPropsWithMark} = this.props;
    const locator = createLocator(restPropsWithMark);
    const restProps = removeMarkFromProperties(restPropsWithMark);

    return (
      <div
        className={cn(styles.radio, {
          [styles.radioInvalid]: invalid,
        })}
        {...locator()}
      >
        <input
          {...restProps}
          className={cn(className, styles.input, {[styles.inputReadOnly]: readOnly})}
          disabled={disabled || readOnly}
          id={this.id}
          type='radio'
          {...locator.input()}
        />
        <label htmlFor={this.id} className={styles.label} {...locator.label()}>
          {label}
        </label>
      </div>
    );
  }
}
