import {useHiringProcessAccess} from 'domain/hiringProcessInfo/hooks/useHiringProcessAccess';
import {HiringProcessInfoPanel} from 'domain/hiringProcessInfo/widgets/Panel';
import {LegalInfoPanel} from 'domain/legalInfo/widgets/Panel';
import {Permission} from 'domain/permission/model';
import {useUserLegalInfo} from 'domain/user/hooks/useUserLegalInfo';
import {useUserTimeOffBalances} from 'domain/user/hooks/useUserTimeOffBalances';
import {UserFull} from 'domain/user/model';
import {UserPrivateInfo} from 'domain/user/model/privateInfo';
import {UserProfilePhoto} from 'domain/user/widgets/ProfilePhoto';
import {UserTimeOffBalancePanel} from 'domain/user/widgets/TimeOffBalance';
import {VacationBalance} from 'domain/user/widgets/VacationBalance';
import React from 'react';
import {useAcl} from 'services/acl';
import {Feature, useFeature} from 'services/features';
import styles from './styles.css';

type Props = React.PropsWithChildren<{
  isSelfUser: boolean;
  privateInfo?: UserPrivateInfo;
  showLegalInfo?: boolean;
  showHiringProtocol?: boolean;
  showTimeOffInfo?: boolean;
  user: UserFull;
}>;

export function UserProfileLayout({
  children,
  isSelfUser,
  privateInfo,
  showLegalInfo,
  user,
  showHiringProtocol = true,
  showTimeOffInfo = true,
}: Props) {
  const acl = useAcl();
  const {hasReadAccess: canReadLegalInfo} = useUserLegalInfo();
  const {hasReadAccess: canReadTimeOffBalances} = useUserTimeOffBalances(user);
  const {canReadInterviewFeedbackLink, canReadOfferLink} = useHiringProcessAccess(user);
  const officePolicyFeature = useFeature(Feature.OFFICE_POLICY);

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <UserProfilePhoto
          user={user}
          editable={(isSelfUser || acl.hasPermission(Permission.USER_WRITE)) && !user.isTerminated && !user.isOutstaff}
          isSelfUser={isSelfUser}
        />
        {showTimeOffInfo && officePolicyFeature.isAvailable
          ? canReadTimeOffBalances && <UserTimeOffBalancePanel user={user} />
          : privateInfo && (
              <VacationBalance
                availableDays={privateInfo.availableVacationDays}
                plannedDays={privateInfo.plannedVacationDays}
                isSelfUser={isSelfUser}
                user={user}
              />
            )}
        {showLegalInfo && canReadLegalInfo && <LegalInfoPanel userId={user.id} />}
        {showHiringProtocol && (canReadInterviewFeedbackLink || canReadOfferLink) && (
          <HiringProcessInfoPanel user={user} />
        )}
      </div>
      <div className={styles.right}>{children}</div>
    </div>
  );
}
