import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Job contract with {legalEntityName}{showPrimaryLabel, select, true { (primary)} other {}}',
    description: '[title] User job contract panel: title',
  },
  setMainLegalEntityButton: {
    defaultMessage: 'Mark as primary contract',
    description: '[button] User job contract panel: action button',
  },
  mainLegalEntityUpdateSuccess: {
    defaultMessage: 'Primary contract successfully updated',
    description: 'User job contract panel: action success message',
  },
  tabHistory: {
    defaultMessage: 'History',
    description: 'Job contract panel: tab label',
  },
  tabSalary: {
    defaultMessage: 'Salary',
    description: 'Job contract panel: tab label',
  },
  tabOneTimeBonus: {
    defaultMessage: 'One-time bonus',
    description: 'Job contract panel: tab label',
  },
});
