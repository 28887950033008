import {Badge} from '@joomcode/joom-ui/Badge';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  active: boolean;
};

export function ActiveBadge({active}: Props) {
  const intl = useIntl();

  return (
    <Badge intent={active ? 'positive' : 'neutral'}>
      {intl.formatMessage(active ? messages.active : messages.inactive)}
    </Badge>
  );
}
