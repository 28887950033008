import {createLocator, type Mark, ConvertLocatorToTestId, Locator} from '@joomcode/joom-ui/create-locator';
import {CountrySelectProps, GenericCountrySelect, GenericRenderItem} from '@joomcode/joom-ui/CountrySelect';
import {FormControl, FormControlLocator} from '@joomcode/joom-ui/FormControl';
import {CountryCode} from '@joomcode/deprecated-utils/countries';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';
import {AutocompleteLocator} from '@joomcode/joom-ui/Select/Autocomplete';
import {useFieldWithInitialValue} from '../../components';
import {CommonFieldProps} from '../types';
import {messages} from './messages';
import {getFieldErrorText} from '../../utils';
import {validateFilled, composeValidators, getFieldValidator} from '../../validation';

export type FieldCountryTestId = ConvertLocatorToTestId<FieldCountryLocator>;
export type FieldCountryLocator = Locator<{
  formControl: FormControlLocator;
  input: AutocompleteLocator;
}>;

export type GenericFieldCountryProps<Option> = CommonFieldProps<Option> & {
  clearable?: boolean;
  autoComplete?: string | null;
  options?: Option[];
  renderItem?: GenericRenderItem<Option>;
  sortOptions?: CountrySelectProps<Option>['sortOptions'];
} & Partial<Mark<FieldCountryLocator>>;

export const GenericFieldCountry = memo(function FieldCountry<Option extends string = CountryCode>({
  autoComplete,
  clearable,
  disabled,
  error,
  hint,
  initialValue,
  label,
  labelHint,
  name,
  options,
  required,
  reserveSpaceForError,
  validate,
  validateFields,
  renderItem,
  sortOptions,
  ...rest
}: GenericFieldCountryProps<Option>) {
  const intl = useIntl();
  const locator = createLocator(rest);

  const validateRequired = required ? validateFilled : undefined;
  const composedValidators =
    validate && validateRequired ? composeValidators(validateRequired, validate) : validate || validateRequired;

  const {input, meta} = useFieldWithInitialValue<Option>(name, {
    initialValue,
    validate: composedValidators ? getFieldValidator(composedValidators) : undefined,
    validateFields,
  });

  return (
    <FormControl
      {...locator.formControl()}
      disabled={meta.submitting || disabled}
      error={error || getFieldErrorText(meta, {intl})}
      hint={hint}
      label={label ?? intl.formatMessage(messages.label)}
      labelHint={labelHint}
      required={required}
      reserveSpaceForError={reserveSpaceForError}
    >
      {(formControlProps) => (
        <GenericCountrySelect
          {...formControlProps}
          {...input}
          {...locator.input()}
          autoComplete={autoComplete}
          clearable={clearable}
          disabled={meta.submitting || disabled}
          options={options}
          renderItem={renderItem}
          sortOptions={sortOptions}
        />
      )}
    </FormControl>
  );
});

/**
 * Export easy to use FieldCountry that is based on the CountryCode enum
 */

const DEFAULT_OPTIONS = getEnumValues(CountryCode);

export type FieldCountryProps = GenericFieldCountryProps<CountryCode>;

export function FieldCountry(props: FieldCountryProps) {
  return <GenericFieldCountry {...props} options={props.options ?? DEFAULT_OPTIONS} />;
}
