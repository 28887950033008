import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  attachBoardingPassesButton: {
    defaultMessage: 'Attach boarding passes',
    description: '[button] Compensation report: upload boarding passes',
  },
  attachMoreBoardingPassesButton: {
    defaultMessage: 'Attach more boarding passes',
    description: '[button] Compensation report: upload more boarding passes',
  },
});

export const errorMessages = defineMessages({
  emptyBoardingPasses: {
    defaultMessage: 'We need your boarding passes',
    description: 'Compensation report field error',
  },
});
