import React from 'react';
import {FormattedNumber, useIntl} from 'react-intl';
import {truncate} from 'utils/number/truncate';
import {messages} from './messages';

export type Props = {
  days: number;
  onlyNumber?: boolean;
};

export function FormattedTimeOffDays({days, onlyNumber = false}: Props) {
  const intl = useIntl();
  const roundedDays = truncate(days, 2);
  const formattedDays = <FormattedNumber value={roundedDays} />;
  if (onlyNumber) {
    return formattedDays;
  }
  return (
    <>
      {intl.formatMessage(messages.days, {
        days: roundedDays,
        formattedDays,
      })}
    </>
  );
}
