import {identity} from '@joomcode/deprecated-utils/function';
import {FieldTextarea} from '@joomcode/joom-form';
import {Grid} from '@joomcode/joom-ui/Grid';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {OfficePolicyRecord} from 'domain/officePolicyRecord/model';
import {FieldOfficePolicy} from 'domain/officePolicyRecord/widgets/FieldPolicy';
import React from 'react';
import {FormRenderProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  formId: string;
  recordToUpdate?: OfficePolicyRecord;
  onSubmit: FormRenderProps<OfficePolicyRecord>['handleSubmit'];
};

export function OfficePolicyRecordForm({formId, recordToUpdate, onSubmit}: Props) {
  const intl = useIntl();

  return (
    <form onSubmit={onSubmit} id={formId} noValidate autoComplete='off'>
      <Grid>
        <Grid.Item xl={6} xs={12}>
          <FieldDate
            name='effectiveDate'
            label={intl.formatMessage(labels.effectiveDate)}
            initialValue={recordToUpdate?.effectiveDate}
            required
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FieldOfficePolicy
            name='policy'
            label={intl.formatMessage(labels.policy)}
            initialValue={recordToUpdate?.policy}
            required
          />
        </Grid.Item>
        <Grid.Item xl={12}>
          <FieldTextarea
            name='comment'
            label={intl.formatMessage(labels.comment)}
            rows={2}
            initialValue={recordToUpdate?.comment}
            parse={identity}
          />
        </Grid.Item>
      </Grid>
    </form>
  );
}
