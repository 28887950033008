import {CrResult} from 'domain/compaRatio/model';
import {CrResultStatus} from 'domain/compaRatio/model/status';
import {CrBadge} from 'domain/compaRatio/widgets/Badge';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

export function DefaultErrorCr() {
  const intl = useIntl();
  const defaultErrorCr = useMemo<CrResult>(
    () => ({
      value: undefined,
      marketLevelPosition: undefined,
      status: CrResultStatus.ERROR,
      error: intl.formatMessage(messages.crError),
    }),
    [intl],
  );

  return <CrBadge cr={defaultErrorCr} />;
}
