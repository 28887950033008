import {
  array,
  boolean,
  ExtractSchemaType,
  object,
  oneOfEnum,
  optional,
} from '@joomcode/deprecated-utils/jsonValidation';
import {CompensationRequestStatus} from 'domain/compensations/request/model/status';
import {legalEntityIdSchema} from 'domain/legalEntity/model';

export const compensationRequestsFilterValuesSchema = object({
  legalEntityIds: optional(array(legalEntityIdSchema)),
  statuses: optional(array(oneOfEnum(CompensationRequestStatus))),
  showIndirect: optional(boolean()),
});

export type CompensationRequestsFilterValues = Partial<
  ExtractSchemaType<typeof compensationRequestsFilterValuesSchema>
>;
export type CompensationRequestsFilterValue = keyof CompensationRequestsFilterValues;
