import {CreateOneTimeBonusRecordConfig} from 'domain/oneTimeBonusRecord/api/create';
import {DeleteOneTimeBonusRecordParams} from 'domain/oneTimeBonusRecord/api/delete';
import {GetOneTimeBonusRecordsParams} from 'domain/oneTimeBonusRecord/api/getAny';
import {UpdateOneTimeBonusRecordConfig} from 'domain/oneTimeBonusRecord/api/update';
import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import {createEffect} from 'effector';

export const createOneTimeBonusRecordFx = createEffect<CreateOneTimeBonusRecordConfig, OneTimeBonusRecord[]>(
  'create new one-time bonus record',
);
export const deleteOneTimeBonusRecordFx = createEffect<DeleteOneTimeBonusRecordParams, void>(
  'delete one-time bonus record',
);
export const getAnyOneTimeBonusRecordsFx = createEffect<GetOneTimeBonusRecordsParams, OneTimeBonusRecord[]>(
  'get user‘s one-time bonus records',
);
export const getMyOneTimeBonusRecordsFx = createEffect<GetOneTimeBonusRecordsParams, OneTimeBonusRecord[]>(
  'get own one-time bonus records',
);
export const getSubordinateOneTimeBonusRecordsFx = createEffect<GetOneTimeBonusRecordsParams, OneTimeBonusRecord[]>(
  'get subordinate one-time bonus records',
);
export const updateOneTimeBonusRecordFx = createEffect<UpdateOneTimeBonusRecordConfig, OneTimeBonusRecord[]>(
  'update one-time bonus record',
);
