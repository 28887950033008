import {defineMessages} from 'react-intl';
import {OfferUpdateReason} from '.';

export const offerUpdateReasonLabels = defineMessages<OfferUpdateReason>({
  [OfferUpdateReason.TYPO]: {
    defaultMessage: 'Typo',
    description: 'Offer update reason',
  },
  [OfferUpdateReason.NEW_AGREEMENT]: {
    defaultMessage: 'New agreement',
    description: 'Offer update reason',
  },
});
