import {Column, ColumnsWidthMap} from '../types';

export function getDefaultColumnWidth(column: Column<unknown>): number {
  return column.defaultWidth || 100;
}

export function getDefaultColumnsWidthMap(columns: Column<unknown>[]): ColumnsWidthMap {
  return columns.reduce<Record<string, number>>((acc, column) => {
    acc[column.id] = getDefaultColumnWidth(column);
    return acc;
  }, {});
}

export function computeColumnsHash(columns: Column<unknown>[]): string {
  return columns.reduce((result, column) => `${result}${column.id}`, '');
}

export function getVisibleColumns(columns: Column<unknown>[], visibleColumns: string[]): Column<unknown>[] {
  return columns.filter((column) => (column.hideable ? visibleColumns.includes(column.id) : true));
}

export function getDefaultVisibleColumnIds(columns: Column<unknown>[]): string[] {
  return columns.filter((column) => !column.defaultHidden).map((column) => column.id);
}

export function getColumnWidthsSum(widthMap: ColumnsWidthMap): number {
  return Object.values(widthMap).reduce((acc, width) => acc + width, 0);
}

export function stretchColumnWidths(options: {widthMap: ColumnsWidthMap; targetWidth: number}): ColumnsWidthMap {
  const {widthMap, targetWidth} = options;
  const entries = Object.entries(widthMap);
  const sourceWidth = getColumnWidthsSum(widthMap);
  const multiplier = targetWidth / sourceWidth;
  let restWidth = targetWidth;

  if (multiplier <= 1) {
    return widthMap;
  }

  return entries.reduce<Record<string, number>>((acc, [id, width], index) => {
    if (index < entries.length - 1) {
      acc[id] = Math.round(width * multiplier);
      restWidth -= acc[id];
    } else {
      acc[id] = restWidth;
    }
    return acc;
  }, {});
}

export function isColumnStretchable(column: Column<unknown>): boolean {
  return !column.noStretch;
}

export function isColumnNotStretchable(column: Column<unknown>): boolean {
  return !isColumnStretchable(column);
}

export function getStretchableColumnIds(columns: Column<unknown>[]): string[] {
  return columns.filter(isColumnStretchable).map((column) => column.id);
}

export function getFixedColumns<T>(columns: Column<T>[]): Column<T>[] {
  return columns.filter(isColumnNotStretchable);
}
