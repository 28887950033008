import {ReactComponent as CircleCrossIcon} from '@joomcode/joom-ui/icons/circle/cross.svg';
import {ReactComponent as LoupeIcon} from '@joomcode/joom-ui/icons/core/loupe.svg';
import {Input} from '@joomcode/joom-ui/Input';
import React, {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClear: () => void;
  placeholder?: string;
  value: string;
};

export function PanelSearch({onChange, onClear, placeholder, value}: Props) {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.iconWrap}>
        <LoupeIcon />
      </div>
      <Input className={styles.input} placeholder={placeholder} onChange={onChange} value={value} ref={inputRef} />
      {Boolean(value) && (
        <button
          aria-label={intl.formatMessage(messages.clearAriaLabel)}
          className={styles.clearButton}
          onClick={onClear}
          type='button'
        >
          <CircleCrossIcon />
        </button>
      )}
    </div>
  );
}
