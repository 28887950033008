import {array, ExtractSchemaType, object, oneOfEnum, optional, string} from '@joomcode/deprecated-utils/jsonValidation';
import {contractRecordSchema} from 'domain/contract/model';
import {LevelCode} from 'domain/levelCode/model';
import {oneTimeBonusRecordSchema} from 'domain/oneTimeBonusRecord/model';
import {regularBonusRecordSchema} from 'domain/regularBonusRecord/model';
import {stockOptionSummarySchema} from 'domain/stockOption/summary/model';
import {userSchema} from 'domain/user/model';

export const userInfoSchema = object({
  contracts: array(contractRecordSchema),
  general: object({
    division: string(),
    department: string(),
    manager: userSchema,
    jobTitle: optional(string()),
    userFunction: optional(string()),
    levelCode: optional(oneOfEnum(LevelCode)),
  }),
  oneTimeBonuses: optional(array(oneTimeBonusRecordSchema)),
  regularBonuses: optional(array(regularBonusRecordSchema)),
  stockOptions: optional(stockOptionSummarySchema),
});

export type UserInfo = ExtractSchemaType<typeof userInfoSchema>;
