// TODO remove this redirect after some time INTRANET-1369
import React from 'react';
import {RouteConfig} from 'react-router-config';
import {Redirect, RouteComponentProps, useParams} from 'react-router-dom';
import {teamsUrls} from './urls';

export const legacyTeamsUrlPatterns = {
  team: '/teams/business/:id',
  teams: '/teams/business',
};

export const legacyTeamsRedirects: RouteConfig[] = [
  {
    path: legacyTeamsUrlPatterns.team,
    exact: true,
    component: function LegacyTeamRedirect({location: {search}}: RouteComponentProps) {
      const {id} = useParams<{id: string}>();
      return <Redirect to={{pathname: teamsUrls.team({id}), search}} />;
    },
  },
  {
    path: legacyTeamsUrlPatterns.teams,
    exact: true,
    component: ({location: {search}}: RouteComponentProps) => <Redirect to={{pathname: teamsUrls.teams(), search}} />,
  },
];
