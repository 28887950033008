import {ContractRecord} from 'domain/contract/model';
import {getPrimaryLegalEntity} from 'domain/contract/utils/getPrimaryLegalEntity';
import {getSalariesFromContractRecords} from 'domain/contract/utils/getSalariesFromContractRecord';
import {SalaryRecord} from 'domain/salaryRecord/model';
import orderBy from 'lodash/orderBy';

export function getMainSalary(contracts: ContractRecord[] | undefined): SalaryRecord | undefined {
  const salaries = getSalariesFromContractRecords(contracts);
  const primaryLegalEntityId = contracts && getPrimaryLegalEntity(contracts)?.id;

  if (!primaryLegalEntityId || !salaries) {
    return undefined;
  }

  return orderBy(
    salaries.filter((salary) => salary.legalEntity.id === primaryLegalEntityId),
    'amount',
    'desc',
  )[0];
}
