/* eslint-disable import/no-cycle */
import {DataTable as DataTableComponent} from './DataTable';
import {CellEmpty} from './components/CellEmpty';
import {CellNumeric} from './components/CellNumeric';
import {createColumnBoolean} from './presets/createColumnBoolean';
import {createColumnCopyIcon} from './presets/createColumnCopyIcon';
import {createColumnDate} from './presets/createColumnDate';
import {createColumnDateTime} from './presets/createColumnDateTime';
import {createColumnNumber} from './presets/createColumnNumber';
import {createColumnPercent} from './presets/createColumnPercent';
import {createColumnPlaceholdered} from './presets/createColumnPlaceholdered';
import {createColumnDateRange} from './presets/createColumnDateRange';

export type {Props, Column, DataTableLocator, DataTableTestId} from './types';

export * from './hooks/useDataTableSelection';

export const DataTable = Object.assign(DataTableComponent, {
  CellEmpty, // eslint-disable-line @typescript-eslint/naming-convention
  CellNumeric, // eslint-disable-line @typescript-eslint/naming-convention
  createColumnBoolean,
  createColumnCopyIcon,
  createColumnDate,
  createColumnDateTime,
  createColumnNumber,
  createColumnPercent,
  createColumnPlaceholdered,
  createColumnDateRange,
});
