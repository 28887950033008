import {intranetApi} from 'apiClient';
import {LegalEntityId} from 'domain/legalEntity/model';
import {UserId} from 'domain/user/model';
import {UserPrivateInfo, userPrivateInfoSchema} from 'domain/user/model/privateInfo';

export type MainLegalEntityUpdateParams = {
  legalEntityId: LegalEntityId;
  userId: UserId;
};

export const setMainLegalEntity = async ({
  legalEntityId,
  userId,
}: MainLegalEntityUpdateParams): Promise<UserPrivateInfo> => {
  const {data} = await intranetApi.post('/v1/users/privateInfo/setMainLegalEntity', undefined, {
    params: {id: legalEntityId, userId},
  });

  return userPrivateInfoSchema.runWithException(data);
};
