import {getSafeArrayLength} from '@joomcode/deprecated-utils/array/getSafeArrayLength';
import {identity, isNullish} from '@joomcode/deprecated-utils/function';
import React from 'react';
import {FilterItem, Value} from '../../types';
import {MultiSelectControl} from './MultiSelectControl';
import {MultiSelectLabel} from './MultiSelectLabel';
import {MultiSelectFilterOptions} from './types';

type InnerValue<Item> = Item[];

export function createGenericMultiSelectFilter<Item, ExternalValue>(
  filterName: string,
  options: MultiSelectFilterOptions<Item> & {
    parseValue(innerValue: Value<InnerValue<Item>>): Value<ExternalValue>;
    formatValue(value: Value<ExternalValue>): Value<InnerValue<Item>>;
  },
): FilterItem<ExternalValue, MultiSelectFilterOptions<Item>> {
  return {
    name: filterName,
    options,
    isEmpty: (value) => {
      const formattedValue = options.formatValue(value);

      return isNullish(formattedValue) || formattedValue.length === 0;
    },
    renderControl: (props) => (
      <MultiSelectControl
        {...props}
        onSubmit={(value) => props.onSubmit(options.parseValue(value))}
        value={options.formatValue(props.value)}
      />
    ),
    renderLabel: (props) => <MultiSelectLabel {...props} value={options.formatValue(props.value)} />,
    getSelectedAmount: (value) => {
      const formattedValue = options.formatValue(value);
      return getSafeArrayLength(formattedValue);
    },
  };
}

export function createMultiSelectFilter<Item>(
  filterName: string,
  options: MultiSelectFilterOptions<Item>,
): FilterItem<InnerValue<Item>, MultiSelectFilterOptions<Item>> {
  return createGenericMultiSelectFilter<Item, InnerValue<Item>>(filterName, {
    ...options,
    parseValue: identity,
    formatValue: identity,
  });
}
