import {QueryRequestPayload} from 'api/types';
import {intranetApi} from 'apiClient';
import {AllStockOptionHoldersFilters} from 'domain/stockOption/holder/model/filters';
import {CountableStockOptionHolders, countableStockOptionHolderSchema} from '../model';

export const getAll = async ({
  filters,
  offset,
  limit,
}: QueryRequestPayload<AllStockOptionHoldersFilters>): Promise<CountableStockOptionHolders> => {
  const {data} = await intranetApi.post('/v1/secure/stockOptions/holders/getAll', filters, {params: {offset, limit}});

  return countableStockOptionHolderSchema.runWithException(data);
};
