import {intranetApi} from 'apiClient';
import {JobInfoRecordId} from 'domain/jobInfoRecord/model';
import {UserId} from 'domain/user/model';

export type DeleteParams = {
  userId: UserId;
  jobInfoRecordId: JobInfoRecordId;
};

export const deleteRecord = async ({userId, jobInfoRecordId}: DeleteParams): Promise<void> => {
  return intranetApi.post('v1/users/jobInfo/deleteRecord', undefined, {
    params: {userId, jobInfoRecordId},
  });
};
