import {DataState, getConsolidatedDataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {useAsyncTask} from '@joomcode/deprecated-utils/react/useAsyncTask';
import {usePrevious} from '@joomcode/deprecated-utils/react/usePrevious';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {checkIsMine} from 'domain/service/api/checkIsMine';
import {Service, ServiceId} from 'domain/service/model';
import {getServiceByIdFx} from 'domain/service/stores/main';
import {$services} from 'domain/service/stores/main/state';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';
import {checkOwnersAreSame} from 'utils/jdm/checkOwnersAreSame';

type ReturnShape = {
  clientErrorStatus?: ClientErrorStatus;
  data: Service | null;
  dataState: DataState;
  isSelfOwner: boolean;
};

export function useService(id?: ServiceId): ReturnShape {
  const [clientErrorStatus, setClientErrorStatus] = useState<ClientErrorStatus>();
  const data = useStoreMap({
    store: $services,
    keys: [id],
    fn: ({byId}, [serviceId]) => (serviceId && byId[serviceId]) || null,
  });
  const dataState = useStoreMap({
    store: $services,
    keys: [id],
    fn: ({byIdState}, [serviceId]) => (serviceId && byIdState[serviceId]) || DataState.IDLE,
  });
  const previousData = usePrevious(data);
  const checkOwnership = useAsyncTask<[ServiceId], boolean>(
    () => (id ? checkIsMine(id).catch(toaster.interceptThenThrowError) : Promise.resolve(false)),
    [id],
  );

  useEffect(() => {
    setClientErrorStatus(undefined);

    if (id && !isLoadingOrLoaded(dataState)) {
      getServiceByIdFx(id).catch((error: Error) => {
        if (error instanceof ClientError && error.status === ClientErrorStatus.NOT_FOUND) {
          setClientErrorStatus(error.status);
        } else {
          toaster.error(error.message);
        }
      });
    }

    if (id) {
      checkOwnership.perform(id);
    }
  }, [id]);

  // if data was updated we should double-check the ownership
  useEffect(() => {
    if (id && data && previousData && !checkOwnersAreSame(data, previousData)) {
      checkOwnership.perform(id);
    }
  }, [data]);

  return {
    clientErrorStatus,
    data,
    dataState: getConsolidatedDataState(dataState, checkOwnership.dataState),
    isSelfOwner: checkOwnership.result ?? false,
  };
}
