import {DataState} from '@joomcode/deprecated-utils/dataState';
import {UserFullInfoAvailability} from 'domain/userFullInfoRecord/model/availability';
import {fullColumnsTree, UserFullInfoColumnTreeItem} from 'domain/userFullInfoRecord/model/columns/tree';
import {createStore} from 'effector';
import {getUserFullInfoAvailabilityFx} from '.';

type State = {
  availability: UserFullInfoAvailability;
  availableColumns: UserFullInfoColumnTreeItem[];
  dataState: DataState;
};

export const $userFullInfoColumns = createStore<State>({
  availability: {
    cr: false,
    jobContracts: false,
    marketData: false,
    privateInfo: false,
    regularBonus: false,
    salary: false,
    salaryRanges: false,
    stockOptions: false,
  },
  availableColumns: [],
  dataState: DataState.IDLE,
})
  .on(getUserFullInfoAvailabilityFx, (state) => ({
    ...state,
    dataState: DataState.LOADING,
  }))
  .on(getUserFullInfoAvailabilityFx.fail, (state) => ({
    ...state,
    dataState: DataState.FAILED,
  }))
  .on(getUserFullInfoAvailabilityFx.doneData, (state, availability) => {
    return {
      availability,
      availableColumns: fullColumnsTree.filter(({access}) => !access || availability[access]),
      dataState: DataState.LOADED,
    };
  });
