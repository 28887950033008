import {defineMessages} from 'react-intl';

export const labels = defineMessages({
  effectiveDate: {
    defaultMessage: 'Effective date',
    description: 'Job contract record form: field label',
  },
  legalEntity: {
    defaultMessage: 'Legal entity',
    description: 'Job contract record form: field label',
  },
  contractType: {
    defaultMessage: 'Contract type',
    description: 'Job contract record form: field label',
  },
  timeOffPolicy: {
    defaultMessage: 'Time off policy',
    description: 'Job contract record form: field label',
  },
  comment: {
    defaultMessage: 'Comment',
    description: 'Job contract record form: field label',
  },
  positionTitle: {
    defaultMessage: 'Position title',
    description: 'Job contract record form: field label',
  },
  status: {
    defaultMessage: 'Status',
    description: 'Job contract record form: field label',
  },
});
