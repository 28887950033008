import {defineMessages} from 'react-intl';
import {FurtherSalary} from '.';
import {FurtherSalaryReason} from './reason';

export const furtherSalaryReasonMessages = defineMessages<FurtherSalaryReason>({
  [FurtherSalaryReason.END_OF_TRIAL]: {
    defaultMessage: 'End of trial',
    description: 'Further salary reason',
  },
  [FurtherSalaryReason.RELOCATION]: {
    defaultMessage: 'Relocation',
    description: 'Further salary reason',
  },
  [FurtherSalaryReason.OTHER]: {
    defaultMessage: 'Other',
    description: 'Further salary reason',
  },
});

export const furtherSalaryFieldLabels = defineMessages<keyof FurtherSalary>({
  salary: {
    defaultMessage: 'Further salary',
    description: 'Offer field label',
  },
  crResult: {
    defaultMessage: 'Further salary CR',
    description: 'Offer field label',
  },
  reason: {
    defaultMessage: 'Further salary reason',
    description: 'Offer field label',
  },
  reasonDetails: {
    defaultMessage: 'Further salary details',
    description: 'Offer field label',
  },
  employmentRate: {
    defaultMessage: 'Employment rate',
    description: 'Offer field label',
  },
});
