import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {MarketDataRecord, marketDataRecordSchema} from 'domain/marketDataRecord/model';
import {UserId} from 'domain/user/model';

export const getSubordinate = async (userId: UserId): Promise<MarketDataRecord[]> => {
  const {data} = await intranetApi.get('/v1/secure/users/marketDataRecords/getSubordinate', {params: {userId}});

  return array(marketDataRecordSchema).runWithException(data);
};
