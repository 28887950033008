import {createUploadHandler} from 'apiClient/createUploadHandler';
import {UserFull, userFullSchema, UserId} from 'domain/user/model';

export type UpdateAvatarParams = {
  id: UserId;
};

export const updateAvatar = createUploadHandler<UpdateAvatarParams, UserFull>({
  endpoint: '/v1/users/updateAvatarAsAdmin',
  resultSchema: userFullSchema,
});
