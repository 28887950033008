import {CopyHeadless} from '@joomcode/joom-ui/Copy/Headless';
import {ReactComponent as LinkIcon} from '@joomcode/joom-ui/icons/core/link.svg';
import React from 'react';
import styles from './styles.css';

export type Props = {
  label?: string;
  value: string;
};

export function CopyLinkIcon({label, value}: Props) {
  return (
    <div className={styles.root}>
      <CopyHeadless label={label} value={value}>
        {({copy, label: labelInner}) => (
          <button aria-label={labelInner} className={styles.button} onClick={copy} type='button'>
            <LinkIcon />
          </button>
        )}
      </CopyHeadless>
    </div>
  );
}
