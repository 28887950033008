import {defineMessages} from 'react-intl';

export const pageTitles = defineMessages({
  offers: {
    defaultMessage: 'Offers',
    description: '[title] Page title',
  },
  createOffer: {
    defaultMessage: 'Create offer',
    description: '[title] Page title',
  },
  editGeneralInfo: {
    defaultMessage: 'Edit general info',
    description: '[title] Page title',
  },
  update: {
    defaultMessage: 'Update offer',
    description: '[title] Page title',
  },
  enhance: {
    defaultMessage: 'Digitize newcomer documents',
    description: '[title] Page title',
  },
});
