import {array, ExtractSchemaType, object, oneOfEnum, optional} from '@joomcode/deprecated-utils/jsonValidation';
import {ChangeValue} from 'domain/compensationReviewRequest/model/changeValue';
import {Status} from 'domain/compensationReviewRequest/model/status';
import {userIdSchema} from 'domain/user/model';

export const filtersSchema = object({
  compensation: optional(oneOfEnum(ChangeValue)),
  statuses: optional(array(oneOfEnum(Status))),
  stockOptions: optional(oneOfEnum(ChangeValue)),
  userIds: optional(array(userIdSchema)),
});

export type Filters = Partial<ExtractSchemaType<typeof filtersSchema>>;
