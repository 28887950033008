import {ExtractSchemaType, number, object, oneOfEnum, string} from '@joomcode/deprecated-utils/jsonValidation';
import {userSchema} from 'domain/user/model';
import {formattedDateSchema} from 'models/system/formattedDate';
import {idSchema} from 'models/system/id';
import {StockOptionBuyoutDocumentsStatus} from './documentsStatus';

export const stockOptionBuyoutIdSchema = idSchema;
export const stockOptionBuyoutSchema = object({
  id: stockOptionBuyoutIdSchema,
  amount: number(),
  user: userSchema,
  date: formattedDateSchema,
  issuer: string(),
  documentsStatus: oneOfEnum(StockOptionBuyoutDocumentsStatus),
});

export type StockOptionBuyoutId = ExtractSchemaType<typeof stockOptionBuyoutIdSchema>;
export type StockOptionBuyout = ExtractSchemaType<typeof stockOptionBuyoutSchema>;
