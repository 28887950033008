import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Role, RoleId} from 'domain/role/model';
import {createStore} from 'effector';
import {getAllRolesFx} from '.';

type State = {
  byId: Record<RoleId, Role>;
  dataState: DataState;
};

export const $roles = createStore<State>({
  byId: {},
  dataState: DataState.IDLE,
})
  .on(getAllRolesFx, (state) => {
    return {
      ...state,
      dataState: DataState.LOADING,
    };
  })
  .on(getAllRolesFx.fail, (state) => {
    return {
      ...state,
      dataState: DataState.FAILED,
    };
  })
  .on(getAllRolesFx.done, (state, {result}) => {
    return {
      ...state,
      byId: arrayToObject(result, ({id}) => id),
      dataState: DataState.LOADED,
    };
  });
