import cn from 'classnames';
import {Avatar} from 'components/ui/Avatar';
import {User} from 'domain/user/model';
import {generalMessages} from 'i18n/messages/general';
import React from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  user: User;
};

export function UserSuggestion({user}: Props) {
  const intl = useIntl();
  const fullName = intl.formatMessage(generalMessages.fullName, {
    firstName: user.firstName,
    lastName: user.lastName,
  });

  return (
    <div className={cn(styles.root, {[styles.terminated]: user.isTerminated})}>
      <Avatar
        name={fullName}
        imageBundle={user.avatarBundle}
        placeholder={user.isOutstaff ? intl.formatMessage(messages.outstaffPlaceholder) : undefined}
      />
      <div className={styles.info}>
        <div className={styles.title}>
          <span className={styles.name}>{fullName}</span> <span className={styles.login}>({user.login})</span>
        </div>
        <div className={styles.jobTitle}>{user.jobTitle}</div>
      </div>
    </div>
  );
}
