import {QueryRequestPayload} from 'api/types';
import {CountableStockOptionHolders, StockOptionHolder} from 'domain/stockOption/holder/model';
import {
  AllStockOptionHoldersFilters,
  SubordinateStockOptionHoldersFilters,
} from 'domain/stockOption/holder/model/filters';
import {createEffect} from 'effector';

export const getAllStockOptionHoldersFx = createEffect<
  QueryRequestPayload<AllStockOptionHoldersFilters>,
  StockOptionHolder[]
>('get filtered stock option holders with pagination');

export const getAllStockOptionHoldersWithCountFx = createEffect<
  QueryRequestPayload<AllStockOptionHoldersFilters>,
  CountableStockOptionHolders
>('get filtered stock option holders with pagination and count');

export const getSubordinateStockOptionHoldersFx = createEffect<
  QueryRequestPayload<SubordinateStockOptionHoldersFilters>,
  StockOptionHolder[]
>('get filtered subordinate stock option holders with pagination');

export const getSubordinateStockOptionHoldersWithCountFx = createEffect<
  QueryRequestPayload<SubordinateStockOptionHoldersFilters>,
  CountableStockOptionHolders
>('get filtered subordinate stock option holders with pagination and count');
