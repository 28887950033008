import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  deletionConfirmationTitle: {
    defaultMessage: 'Delete record',
    description: '[title] User job contract panel: delete one-time bonus record confirmation dialog',
  },
  deletionConfirmationText: {
    defaultMessage: 'Are you sure you want to delete this one-time bonus record?',
    description: 'User job contract panel: delete one-time bonus record confirmation text',
  },
  deletionConfirmationButton: {
    defaultMessage: 'Delete',
    description: '[button] User job contract panel: delete one-time bonus record confirmation button',
  },
});
