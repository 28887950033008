import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Column, DataTable} from '@joomcode/joom-ui/DataTable';
import {HumanDate} from '@joomcode/joom-ui/HumanDate';
import {Panel} from '@joomcode/joom-ui/Panel';
import {stockOptionAgreementDocumentsStatuses} from 'domain/stockOption/agreement/model/documentsStatus/messages';
import {StockOptionAgreementRecord} from 'domain/stockOption/agreement/model/record';
import {StockOptionAgreementStatus} from 'domain/stockOption/agreement/model/status';
import {formatStockOptionAgreementStatus} from 'domain/stockOption/agreement/model/status/formatter';
import {formatStockOptionAgreementType} from 'domain/stockOption/agreement/model/type/formatter';
import {StockOptionAgreementFreezeHint} from 'domain/stockOption/agreementFreeze/widgets/Hint';
import {BulkUploadRowStatus} from 'domain/stockOption/bulkUpload/widgets/Status';
import {FormattedOptionsAmount} from 'domain/stockOption/holder/widgets/FormattedOptionsAmount';
import {UserLink} from 'domain/user/widgets/Link';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {ColumnId} from './columnId';
import {columnNames, messages} from './messages';

export type Props = {
  rows: StockOptionAgreementRecord[];
};

const getRowKey = ({meta}: StockOptionAgreementRecord) => meta.row.toString();

export function BulkUploadAgreementsPanel({rows}: Props) {
  const intl = useIntl();
  const columns: Column<StockOptionAgreementRecord>[] = useMemo(
    () => [
      {
        defaultWidth: 5,
        id: ColumnId.PARSING_STATUS,
        name: intl.formatMessage(columnNames[ColumnId.PARSING_STATUS]),
        render: ({meta}) => <BulkUploadRowStatus meta={meta} />,
      },
      {
        defaultWidth: 12,
        id: ColumnId.USER,
        name: intl.formatMessage(columnNames[ColumnId.USER]),
        render: ({agreement}) =>
          agreement?.user ? <UserLink user={agreement.user} colored withAvatar={false} /> : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 15,
        id: ColumnId.EMAIL,
        name: intl.formatMessage(columnNames[ColumnId.EMAIL]),
        render: ({agreement}) => (agreement ? agreement.user.email : <DataTable.CellEmpty />),
      },
      {
        align: 'right',
        defaultWidth: 8,
        id: ColumnId.AMOUNT,
        name: intl.formatMessage(columnNames[ColumnId.AMOUNT]),
        render: ({agreement}) =>
          agreement ? <FormattedOptionsAmount amount={agreement.amount} /> : <DataTable.CellEmpty />,
      },
      {
        align: 'right',
        defaultWidth: 8,
        id: ColumnId.VESTED,
        name: intl.formatMessage(columnNames[ColumnId.VESTED]),
        render: ({agreement}) =>
          agreement ? <FormattedOptionsAmount amount={agreement.vested} /> : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 8,
        id: ColumnId.TYPE,
        name: intl.formatMessage(columnNames[ColumnId.TYPE]),
        render: ({agreement}) =>
          agreement ? formatStockOptionAgreementType(agreement.type, intl) : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 8,
        id: ColumnId.AGREEMENT_STATUS,
        name: intl.formatMessage(columnNames[ColumnId.AGREEMENT_STATUS]),
        render: ({agreement}) => {
          if (!agreement) {
            return <DataTable.CellEmpty />;
          }

          const {status, freezes} = agreement;
          const startDate = freezes[0]?.startDate;
          const statusText = formatStockOptionAgreementStatus(status, intl);

          return (
            <>
              {statusText}
              {startDate && (
                <StockOptionAgreementFreezeHint
                  freezeDate={startDate}
                  frozen={status === StockOptionAgreementStatus.FROZEN}
                />
              )}
            </>
          );
        },
      },
      {
        defaultWidth: 10,
        id: ColumnId.ISSUE_DATE,
        name: intl.formatMessage(columnNames[ColumnId.ISSUE_DATE]),
        render: ({agreement}) =>
          agreement ? <HumanDate value={agreement.issueDate} format='short' utc /> : <DataTable.CellEmpty />,
      },
      {
        defaultWidth: 12,
        id: ColumnId.DOCUMENTS_STATUS,
        name: intl.formatMessage(columnNames[ColumnId.DOCUMENTS_STATUS]),
        render: ({agreement}) =>
          agreement ? (
            intl.formatMessage(stockOptionAgreementDocumentsStatuses[agreement.documentsStatus])
          ) : (
            <DataTable.CellEmpty />
          ),
      },
    ],
    [intl],
  );

  return (
    <Panel title={intl.formatMessage(messages.title)} withSeparator>
      <DataTable<StockOptionAgreementRecord>
        columns={columns}
        dataState={DataState.LOADED}
        data={rows}
        getRowKey={getRowKey}
        storageName='stockOption.bulkUpload.agreements.list'
      />
    </Panel>
  );
}
