import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {ClientError, ClientErrorStatus} from 'apiClient/ClientError';
import {Offer, OfferId} from 'domain/offer/model';
import {getOfferByIdFx} from 'domain/offer/stores/main';
import {$offers} from 'domain/offer/stores/main/state';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';

type ReturnShape = {
  data: Offer | null;
  dataState: DataState;
  clientErrorStatus?: ClientErrorStatus;
};

export function useOffer(id?: OfferId): ReturnShape {
  const [clientErrorStatus, setClientErrorStatus] = useState<ClientErrorStatus>();
  const data = useStoreMap({
    store: $offers,
    keys: [id],
    fn: ({byId}, [offerId]) => (offerId && byId[offerId]) || null,
  });
  const dataState = useStoreMap({
    store: $offers,
    keys: [id],
    fn: ({byIdState}, [offerId]) => (offerId && byIdState[offerId]) || DataState.IDLE,
  });

  useEffect(() => {
    setClientErrorStatus(undefined);

    if (id && !isLoadingOrLoaded(dataState)) {
      getOfferByIdFx(id).catch((error: Error) => {
        if (error instanceof ClientError && error.status === ClientErrorStatus.NOT_FOUND) {
          setClientErrorStatus(error.status);
        } else {
          toaster.error(error.message);
        }
      });
    }
  }, [id, setClientErrorStatus]);

  return {
    clientErrorStatus,
    dataState,
    data,
  };
}
