import {validateFilled} from '@joomcode/joom-form';
import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {Input} from '@joomcode/joom-ui/Input';
import {Stack} from '@joomcode/joom-ui/Stack';
import {FieldFloat, FieldFloatValue} from 'components/widgets/fields/FieldFloat';
import {FormattedTimeOffDays} from 'components/widgets/FormattedTimeOffDays';
import {TimeOffBalanceRecordDiff} from 'domain/timeOff/balanceRecord/model/diff';
import {TimeOffBalanceRecordType} from 'domain/timeOff/balanceRecord/model/type';
import {createTimeOffBalanceRecord} from 'domain/timeOff/balanceRecord/stores/main';
import {UserId} from 'domain/user/model/';
import React, {useMemo, useState} from 'react';
import {Field, Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {Feature, useFeature} from 'services/features';
import {toaster} from 'services/toaster';
import {getFieldErrorText} from 'utils/form/getFieldErrorText';
import uuid from 'uuid/v4';
import {messages} from './messages';
import styles from './styles.css';

type Props = {
  currentBalance: number;
  onClose: () => void;
  type: TimeOffBalanceRecordType;
  userId: UserId;
};

export enum AddOrSubtract {
  ADD = 'add',
  SUBTRACT = 'subtract',
}

export function BalanceRecordForm({onClose, userId, currentBalance, type}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);
  const officePolicyFeature = useFeature(Feature.OFFICE_POLICY);
  const title = useMemo(() => {
    if (officePolicyFeature.isAvailable) {
      return type === TimeOffBalanceRecordType.VACATION ? messages.dialogTitleVacation : messages.dialogTitleRemoteWork;
    }
    return messages.dialogTitle;
  }, [officePolicyFeature.isAvailable, type]);
  const [addOrSubtract, setAddOrSubtract] = useState<AddOrSubtract>(AddOrSubtract.ADD);
  const onSubmit: FormProps<TimeOffBalanceRecordDiff>['onSubmit'] = (diff) =>
    createTimeOffBalanceRecord({
      diff: {
        ...diff,
        type,
        daysToAdd: addOrSubtract === AddOrSubtract.ADD ? diff.daysToAdd : diff.daysToAdd * -1,
      },
      userId,
    })
      .then(onClose)
      .catch(toaster.interceptThenThrowError);

  return (
    <Form<TimeOffBalanceRecordDiff> onSubmit={onSubmit}>
      {({handleSubmit, submitting, invalid, values}) => (
        <Dialog ariaLabel={intl.formatMessage(messages.dialogAriaLabel)} isOpen onClose={onClose} width={600}>
          <Dialog.Header>{intl.formatMessage(title)}</Dialog.Header>
          <Dialog.Body withDefaultPadding>
            <form id={formId} onSubmit={handleSubmit}>
              <Grid>
                <Grid.Item xl={4} xs={12}>
                  <Field<FieldFloatValue> name='daysToAdd' subscription={{error: true, touched: true}}>
                    {({meta}) => (
                      <FormControl
                        disabled={submitting}
                        error={getFieldErrorText(meta, {intl})}
                        label={intl.formatMessage(messages.daysAdded)}
                        required
                      >
                        {(formControlProps) => (
                          <FieldFloat
                            {...formControlProps}
                            disabled={submitting}
                            name='daysToAdd'
                            validate={validateFilled}
                          />
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Grid.Item>
                <Grid.Item xl={8} xs={12}>
                  <Field<string> name='reason' validate={validateFilled}>
                    {({input, meta}) => (
                      <FormControl
                        disabled={submitting}
                        error={getFieldErrorText(meta, {intl})}
                        label={intl.formatMessage(messages.reason)}
                        required
                      >
                        {(formControlProps) => <Input {...formControlProps} {...input} disabled={submitting} />}
                      </FormControl>
                    )}
                  </Field>
                </Grid.Item>
              </Grid>
              <Stack>
                <ButtonGroup align='left' size='m'>
                  <Button
                    intent={addOrSubtract === AddOrSubtract.ADD ? 'primary' : 'neutral'}
                    kind='primary'
                    onClick={() => setAddOrSubtract(AddOrSubtract.ADD)}
                  >
                    {intl.formatMessage(messages.addDaysButton)}
                  </Button>
                  <Button
                    intent={addOrSubtract === AddOrSubtract.SUBTRACT ? 'primary' : 'neutral'}
                    kind='primary'
                    onClick={() => setAddOrSubtract(AddOrSubtract.SUBTRACT)}
                  >
                    {intl.formatMessage(messages.subtractDaysButton)}
                  </Button>
                </ButtonGroup>
                {values.daysToAdd > 0 && (
                  <>
                    <Stack.Spacer />
                    <p className={styles.balanceHint}>
                      {intl.formatMessage(messages.expectedBalanceHint, {
                        days: (
                          <FormattedTimeOffDays
                            days={
                              addOrSubtract === AddOrSubtract.ADD
                                ? currentBalance + values.daysToAdd
                                : currentBalance - values.daysToAdd
                            }
                          />
                        ),
                      })}
                    </p>
                  </>
                )}
              </Stack>
            </form>
          </Dialog.Body>
          <Dialog.Footer align='right'>
            <ButtonGroup spaced size='l'>
              <Button kind='text' intent='neutral' onClick={onClose}>
                {intl.formatMessage(messages.cancelButton)}
              </Button>
              <Button
                form={formId}
                kind='primary'
                intent='primary'
                type='submit'
                disabled={invalid}
                loading={submitting}
              >
                {intl.formatMessage(messages.submitButton)}
              </Button>
            </ButtonGroup>
          </Dialog.Footer>
        </Dialog>
      )}
    </Form>
  );
}
