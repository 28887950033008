import {FileToDownload} from 'components/ui/FileToDownload';
import {buildFileDownloadLink, buildFileDownloadLinkForAdmin} from 'domain/compensations/request/api/downloadFile';
import {CompensationRequestId} from 'domain/compensations/request/model';
import {Permission} from 'domain/permission/model';
import {FileMeta} from 'models/system/fileMeta';
import React from 'react';
import {useAcl} from 'services/acl';

type Props = {
  compensationRequestId: CompensationRequestId;
  downloadable: boolean;
  fileMeta: FileMeta;
};

export function CompensationReportFile({compensationRequestId, downloadable, fileMeta}: Props) {
  const acl = useAcl();
  const isDownloadAdmin = acl.hasPermission(Permission.COMPENSATION_DOWNLOAD_ALL);
  const downloadLinkBuilder = isDownloadAdmin ? buildFileDownloadLinkForAdmin : buildFileDownloadLink;
  return (
    <FileToDownload
      fileMeta={fileMeta}
      link={downloadLinkBuilder({compensationRequestId, fileId: fileMeta.id})}
      disabled={!downloadable}
    />
  );
}
