import {config} from 'config';
import {Locale} from 'i18n/locale/types';
import {GtagClient} from './gtag';

export class Analytics {
  public constructor(locale: Locale) {
    this.gtag = new GtagClient({
      id: config.gtagId,
      locale,
    });
  }

  public gtag: GtagClient;
}
