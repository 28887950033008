import {TooltipFaq} from '@joomcode/joom-ui/TooltipFaq';
import cn from 'classnames';
import {EmojiLandmark, LandmarkConfig} from 'domain/officeMap/landmark/model';
import {LandmarkTooltip} from 'domain/officeMap/landmark/widgets/Tooltip';
import React from 'react';
import styles from './styles.css';

type Props = {
  emojiLandmarks: EmojiLandmark[];
  landmarkConfig: LandmarkConfig;
};

export const EmojiLandmarksSvg = React.memo(({landmarkConfig, emojiLandmarks}: Props) => {
  const allLandmarkObjects = emojiLandmarks
    .filter((landmark) => landmarkConfig[landmark.type] === true)
    .map((landmark) => {
      const element = (
        <text
          className={cn({[styles.landmark]: true, [styles.disabled]: landmark.disabled === true})}
          key={landmark.id}
          x={landmark.coordinates.x}
          y={landmark.coordinates.y}
        >
          {landmark.icon}
        </text>
      );

      return landmark.name ? (
        <TooltipFaq key={landmark.id} placement='top' content={<LandmarkTooltip landmark={landmark} />}>
          {element}
        </TooltipFaq>
      ) : (
        element
      );
    });

  return <>{allLandmarkObjects}</>;
});
