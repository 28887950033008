import cn from 'classnames';
import React from 'react';
import styles from './index.css';
import {Spinner} from '../../../Spinner';
import {ReactComponent as CheckIcon} from '../../../icons/circle/checkmarkOutline.svg';
import {ReactComponent as ClipIcon} from '../../../icons/core/clip.svg';
import {ReactComponent as CrossIcon} from '../../../icons/circle/crossOutline.svg';
import {StatusIconStyle} from '../../types';

const iconByStyle: Record<StatusIconStyle, React.ReactElement> = {
  [StatusIconStyle.CHECK_OUTLINE]: <CheckIcon className={cn(styles.icon, styles.success)} />,
  [StatusIconStyle.CLIP]: <ClipIcon className={styles.icon} />,
  [StatusIconStyle.SPINNER]: (
    <div className={styles.spinner}>
      <Spinner color='var(--joom-global-color-base-primary)' />
    </div>
  ),
  [StatusIconStyle.ERROR]: <CrossIcon className={cn(styles.icon, styles.error)} />,
};

type Props = {
  iconStyle: `${StatusIconStyle}`;
};

export const StatusIcon = ({iconStyle}: Props) => {
  return iconByStyle[iconStyle];
};
