import {constant, oneOf} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {Panel} from '@joomcode/joom-ui/Panel';
import {Tabs} from '@joomcode/joom-ui/Tabs';
import {RoleBinding} from 'domain/roleBinding/model';
import {TeamRoleBindingMode} from 'domain/team/model/roleBindingMode';
import {ColumnId} from 'domain/team/model/roleBindingsTableColumnId';
import {TeamRoleBindingsTable} from 'domain/team/widgets/RoleBindingsTable';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  activeBindings: RoleBinding[];
  myBindings: RoleBinding[];
  showTitle: boolean;
};

export function TeamOwnRoleBindingsPanel({activeBindings, myBindings, showTitle}: Props) {
  const intl = useIntl();
  const tabs = useMemo(() => {
    const availableTabs = [
      {
        id: TeamRoleBindingMode.ACTIVE,
        label: intl.formatMessage(messages.approvedBindingsTab),
        content: (
          <TeamRoleBindingsTable
            data={activeBindings}
            storageName='team.activeRoleBindings.list'
            columnWidths={{
              [ColumnId.SERVICE]: 7,
              [ColumnId.RESOURCE]: 7,
              [ColumnId.ROLE]: 16,
              [ColumnId.PROPAGATES]: 4,
            }}
          />
        ),
      },
    ];

    if (myBindings.length > 0) {
      availableTabs.push({
        id: TeamRoleBindingMode.MY,
        label: intl.formatMessage(messages.myRequestsTab),
        content: (
          <TeamRoleBindingsTable
            data={myBindings}
            storageName='team.myRoleBindings.list'
            columnWidths={{
              [ColumnId.SERVICE]: 7,
              [ColumnId.RESOURCE]: 7,
              [ColumnId.ROLE]: 7,
              [ColumnId.PROPAGATES]: 4,
              [ColumnId.STATUS]: 5,
              [ColumnId.ACTIONS]: 5,
            }}
          />
        ),
      });
    }

    return availableTabs;
  }, [activeBindings, myBindings]);

  const availableTabIdSchemas = useMemo(() => tabs.map(({id}) => constant(id)), [tabs]);
  const [activeTabId, setActiveTabId] = useQueryParam(
    'mode',
    oneOf(...availableTabIdSchemas),
    TeamRoleBindingMode.ACTIVE,
  );

  return (
    <Panel>
      {showTitle && <Panel.Header withSeparator title={intl.formatMessage(messages.title)} />}
      {tabs.length > 1 ? (
        <Tabs<TeamRoleBindingMode>
          renderActiveTabPanelOnly
          activeTabId={activeTabId}
          onChange={setActiveTabId}
          tabs={tabs}
        />
      ) : (
        tabs[0].content
      )}
    </Panel>
  );
}
