export enum CompensationRequestStatus {
  APPROVED = 'approved',
  CANCELED = 'canceled',
  CLOSED = 'closed',
  CREATED = 'created',
  MANAGER_APPROVED = 'manager approved',
  NEEDS_REPORT = 'needsReport',
  OFFICE_CARE_APPROVED = 'office care approved',
  REJECTED = 'rejected',
  REPORTED = 'reported',
}
