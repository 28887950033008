import {intranetApi} from 'apiClient';
import {
  CompensationRequest,
  CompensationRequestCreationConfig,
  compensationRequestSchema,
} from 'domain/compensations/request/model';

export const create = async (config: CompensationRequestCreationConfig): Promise<CompensationRequest> => {
  const {data} = await intranetApi.post('/v1/compensations/requests/create', config);

  return compensationRequestSchema.runWithException(data);
};
