import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Set freeze date',
    description: '[title] Stock option agreement freeze create dialog: title',
  },
  ariaLabel: {
    defaultMessage: 'Option agreement freeze creation dialog',
    description: 'Stock option agreement freeze create dialog: aria label',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: '[button] Stock option agreement freeze create dialog: cancel button',
  },
  createButton: {
    defaultMessage: 'Set freeze date',
    description: '[button] Stock option agreement freeze create dialog: create button',
  },
  success: {
    defaultMessage: 'Freeze date has been set',
    description: 'Stock option agreement freeze create dialog: success message',
  },
  error: {
    defaultMessage: 'Freeze date is not specified',
    description: 'Stock option agreement freeze create dialog: error message',
  },
  note: {
    defaultMessage: 'You need to draw up a freeze agreement yourself.',
    description: 'Stock option agreement freeze create dialog: note',
  },
});
