import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {MarketDataRecord} from 'domain/marketDataRecord/model';
import {MarketDataReadAccess} from 'domain/marketDataRecord/model/access';
import {
  getMarketDataRecordsFx,
  getMyMarketDataRecordsFx,
  getSubordinateMarketDataRecordsFx,
} from 'domain/marketDataRecord/stores/main';
import {$marketDataRecords} from 'domain/marketDataRecord/stores/main/state';
import {UserFull, UserId} from 'domain/user/model';
import {Effect} from 'effector';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';
import {useMarketDataReadAccess} from './useMarketDataReadAccess';

const handlerByAccess: Record<MarketDataReadAccess, Effect<UserId, MarketDataRecord[]>> = {
  [MarketDataReadAccess.ANY]: getMarketDataRecordsFx,
  [MarketDataReadAccess.MY]: getMyMarketDataRecordsFx,
  [MarketDataReadAccess.SUBORDINATE]: getSubordinateMarketDataRecordsFx,
};

type ReturnShape = {
  data: MarketDataRecord[];
  dataState: DataState;
};

export function useUserMarketDataRecords(user: UserFull | undefined): ReturnShape {
  const readAccess = useMarketDataReadAccess(user);
  const [error, setError] = useState<Error>();
  const userId = user?.id;

  const data = useStoreMap({
    store: $marketDataRecords,
    keys: [userId],
    fn: ({byUserId}, [id]) => (id && byUserId[id]) || [],
  });
  const dataState = useStoreMap({
    store: $marketDataRecords,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => (id && dataStateByUserId[id]) || DataState.IDLE,
  });

  useEffect(() => {
    if (userId && readAccess && !isLoadingOrLoaded(dataState)) {
      handlerByAccess[readAccess](userId).catch(setError);
    }
  }, [readAccess, userId]);

  useEffect(() => {
    if (error) {
      toaster.error(error.message);
    }
  }, [error]);

  return {
    data,
    dataState,
  };
}
