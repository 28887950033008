import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  ariaLabel: {
    defaultMessage: 'Export salary data by date dialog',
    description: 'Salaries export by date: aria label',
  },
  buttonDownload: {
    defaultMessage: 'Compensations for a date',
    description: '[button] Salaries export by date',
  },
});
