import {string} from '@joomcode/deprecated-utils/jsonValidation';
import {useQueryParam} from '@joomcode/deprecated-utils/react/useQueryParam';
import {Page} from '@joomcode/joom-ui/Page';
import {PageStateHandler} from 'components/ui/PageStateHandler';
import {JoomersPageTabs} from 'components/widgets/JoomersPageTabs';
import {Permission} from 'domain/permission/model';
import {useTeams} from 'domain/team/hooks/useTeams';
import {TeamsTreePanel, TeamsTreePanelProps} from 'domain/team/widgets/TreePanel';
import React from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {messages} from './messages';

const EXPANDED_DEPTH = 1;
const isExpandedByDefault: TeamsTreePanelProps['isExpandedByDefault'] = (depth) => depth < EXPANDED_DEPTH;

export function TeamsPage() {
  const acl = useAcl();
  const intl = useIntl();
  const {byId, dataState} = useTeams();
  const [searchQuery, setSearchQuery] = useQueryParam('search', string(), '');

  return (
    <PageStateHandler data={byId} state={dataState}>
      {(teamById) => (
        <Page
          title={intl.formatMessage(messages.globalTitle)}
          headerContent={
            acl.hasPermission(Permission.USER_READ_ACTIVE) ? <JoomersPageTabs activeTab='teams' /> : undefined
          }
        >
          <TeamsTreePanel
            isExpandedByDefault={isExpandedByDefault}
            onSearch={setSearchQuery}
            searchQuery={searchQuery}
            stretchHeight
            teamById={teamById}
            title={intl.formatMessage(messages.teamsPanelTitle)}
            withMarginBottom={false}
          />
        </Page>
      )}
    </PageStateHandler>
  );
}
