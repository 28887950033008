import {defineMessages} from 'react-intl';

export const columnNames = defineMessages({
  hireDate: {
    defaultMessage: 'Hire date',
    description: '[title] Employments table column name',
  },
  terminationDate: {
    defaultMessage: 'Termination date',
    description: '[title] Employments table column name',
  },
  legalTerminationDate: {
    defaultMessage: 'Legal termination date',
    description: '[title] Employments table column name',
  },
  terminationReason: {
    defaultMessage: 'Termination reason',
    description: '[title] Employments table column name',
  },
  terminationType: {
    defaultMessage: 'Termination type',
    description: '[title] Employments table column name',
  },
  terminationComment: {
    defaultMessage: 'Comments on the termination',
    description: '[title] Employments table column name',
  },
  terminationInitiatedBy: {
    defaultMessage: 'Termination initiated by',
    description: '[title] Employments table column name',
  },
});

export const messages = defineMessages({
  empty: {
    defaultMessage: 'No records',
    description: 'Employments table: empty message',
  },
  fail: {
    defaultMessage: 'Failed to load records',
    description: 'Employments table: fail message',
  },
  editButtonAlt: {
    defaultMessage: 'Edit termination',
    description: 'Employments table: edit button tooltip',
  },
});
