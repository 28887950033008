import {boolean} from '@joomcode/deprecated-utils/jsonValidation';
import {JsonSerializer, LocalStorage, SingleStorageManager} from '@joomcode/deprecated-utils/Storage';
import {Header} from 'components/widgets/Header';
import {SideNavigation} from 'components/widgets/SideNavigation';
import {UserFull} from 'domain/user/model';
import {useIsMobile} from 'hooks/useIsMobile';
import {LayoutProps} from 'layouts/types';
import React, {useCallback, useState} from 'react';
import {renderRoutes} from 'react-router-config';
import {RouteComponentProps} from 'react-router-dom';
import styles from './index.css';

const compactNavigationStorage = new SingleStorageManager(
  'useCompactNavigation',
  new LocalStorage(),
  new JsonSerializer(boolean()),
);

type Props = RouteComponentProps & LayoutProps & {selfUser: UserFull};

export const RegularLayout = ({route, children, selfUser, match}: Props) => {
  const isMobile = useIsMobile();
  const [isNavigationCompact, setIsNavigationCompact] = useState<boolean>(
    isMobile || (compactNavigationStorage.restore() ?? false),
  );
  const toggleNavigationCompact = useCallback(() => {
    const newValue = !isNavigationCompact;
    setIsNavigationCompact(newValue);
    compactNavigationStorage.store(newValue);
  }, [isNavigationCompact]);
  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <Header selfUser={selfUser} />
      </header>

      <aside className={styles.navigation}>
        <SideNavigation
          matchedPathPattern={match.path}
          isCompact={isNavigationCompact}
          toggleCompact={toggleNavigationCompact}
        />
      </aside>

      <main className={styles.main}>
        <div className={styles.mainContent}>{route ? renderRoutes(route.routes, {selfUser}) : children}</div>
      </main>
    </div>
  );
};
