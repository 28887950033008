import {DataState, isLoadingOrLoaded} from '@joomcode/deprecated-utils/dataState';
import {RegularBonusRecord} from 'domain/regularBonusRecord/model';
import {RegularBonusReadAccess} from 'domain/regularBonusRecord/model/access';
import {
  getMyRegularBonusRecordsFx,
  getRegularBonusRecordsFx,
  getSubordinateRegularBonusRecordsFx,
} from 'domain/regularBonusRecord/stores/main';
import {$regularBonusRecords} from 'domain/regularBonusRecord/stores/main/state';
import {UserFull, UserId} from 'domain/user/model';
import {Effect} from 'effector';
import {useStoreMap} from 'effector-react';
import {useEffect, useState} from 'react';
import {toaster} from 'services/toaster';
import {useRegularBonusReadAccess} from './useRegularBonusReadAccess';

const handlerByAccess: Record<RegularBonusReadAccess, Effect<UserId, RegularBonusRecord[]>> = {
  [RegularBonusReadAccess.ANY]: getRegularBonusRecordsFx,
  [RegularBonusReadAccess.SUBORDINATE]: getSubordinateRegularBonusRecordsFx,
  [RegularBonusReadAccess.MY]: getMyRegularBonusRecordsFx,
};

type ReturnShape = {
  data: RegularBonusRecord[];
  dataState: DataState;
};

export function useUserRegularBonusRecords(user: UserFull | undefined): ReturnShape {
  const readAccess = useRegularBonusReadAccess(user);
  const [error, setError] = useState<Error>();
  const userId = user?.id;

  const data = useStoreMap({
    store: $regularBonusRecords,
    keys: [userId],
    fn: ({byUserId}, [id]) => (id && byUserId[id]) || [],
  });
  const dataState = useStoreMap({
    store: $regularBonusRecords,
    keys: [userId],
    fn: ({dataStateByUserId}, [id]) => (id && dataStateByUserId[id]) || DataState.IDLE,
  });

  useEffect(() => {
    if (userId && readAccess && !isLoadingOrLoaded(dataState)) {
      handlerByAccess[readAccess](userId).catch(setError);
    }
  }, [readAccess, userId]);

  useEffect(() => {
    if (error) {
      toaster.error(error.message);
    }
  }, [error]);

  return {
    data,
    dataState,
  };
}
