import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  emptyStockOptions: {
    defaultMessage: 'No options',
    description: 'Compensation review request form: empty options message',
  },
  noAccess: {
    defaultMessage: 'No access to information',
    description: 'Compensation review request form: empty options message',
  },
});
