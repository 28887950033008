import {DataState} from '@joomcode/deprecated-utils/dataState';
import {LegalEntityId} from 'domain/legalEntity/model';
import {OneTimeBonusRecord} from 'domain/oneTimeBonusRecord/model';
import {UserId} from 'domain/user/model';
import {createStore} from 'effector';
import {
  createOneTimeBonusRecordFx,
  deleteOneTimeBonusRecordFx,
  getAnyOneTimeBonusRecordsFx,
  getMyOneTimeBonusRecordsFx,
  getSubordinateOneTimeBonusRecordsFx,
  updateOneTimeBonusRecordFx,
} from '.';

type OneTimeBonusRecordsByLegalEntityId = Record<LegalEntityId, OneTimeBonusRecord[]>;
type DataStateByLegalEntityId = Record<LegalEntityId, DataState>;
type State = {
  byUserId: Record<UserId, OneTimeBonusRecordsByLegalEntityId>;
  dataStateByUserId: Record<UserId, DataStateByLegalEntityId>;
};

export const $oneTimeBonusRecords = createStore<State>({
  byUserId: {},
  dataStateByUserId: {},
})
  .on(
    [getAnyOneTimeBonusRecordsFx, getMyOneTimeBonusRecordsFx, getSubordinateOneTimeBonusRecordsFx],
    (state, {userId, legalEntityId}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: {
            ...state.dataStateByUserId[userId],
            [legalEntityId]: DataState.LOADING,
          },
        },
      };
    },
  )
  .on(
    [getAnyOneTimeBonusRecordsFx.fail, getMyOneTimeBonusRecordsFx.fail, getSubordinateOneTimeBonusRecordsFx.fail],
    (state, {params: {userId, legalEntityId}}) => {
      return {
        ...state,
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: {
            ...state.dataStateByUserId[userId],
            [legalEntityId]: DataState.FAILED,
          },
        },
      };
    },
  )
  .on(
    [getAnyOneTimeBonusRecordsFx.done, getMyOneTimeBonusRecordsFx.done, getSubordinateOneTimeBonusRecordsFx.done],
    (state, {params: {userId, legalEntityId}, result}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: {
            ...state.byUserId[userId],
            [legalEntityId]: result,
          },
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: {
            ...state.dataStateByUserId[userId],
            [legalEntityId]: DataState.LOADED,
          },
        },
      };
    },
  )
  .on(
    [createOneTimeBonusRecordFx.done, updateOneTimeBonusRecordFx.done],
    (state, {params: {userId, legalEntityId}, result}) => {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [userId]: {
            ...state.byUserId[userId],
            [legalEntityId]: result,
          },
        },
        dataStateByUserId: {
          ...state.dataStateByUserId,
          [userId]: {
            ...state.dataStateByUserId[userId],
            [legalEntityId]: DataState.LOADED,
          },
        },
      };
    },
  )
  .on(deleteOneTimeBonusRecordFx.done, (state, {params: {id, userId, legalEntityId}}) => {
    if (state.dataStateByUserId[userId]?.[legalEntityId] !== DataState.LOADED) {
      return state;
    }

    return {
      ...state,
      byUserId: {
        ...state.byUserId,
        [userId]: {
          ...state.byUserId[userId],
          [legalEntityId]: state.byUserId[userId]?.[legalEntityId].filter((record) => record.id !== id),
        },
      },
    };
  });
