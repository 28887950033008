import {Permission} from 'domain/permission/model';
import {withAuth} from 'hocs/withAuth';
import {withPermission} from 'hocs/withPermission';
import {withVpn} from 'hocs/withVpn';
import {RegularLayout} from 'layouts/Regular';
import {NotFoundPage} from 'pages/NotFound';
import {UserPage} from 'pages/User';
import {UserByIdPage} from 'pages/UserById';
import {UserEditPage} from 'pages/UserEdit';
import {UserEditLegalInfoPage} from 'pages/UserEditLegalInfo';
import {UsersPage} from 'pages/Users';
import {UserTimeOffBalancePage} from 'pages/UserTimeOffBalance';
import {RouteConfig} from 'react-router-config';
import {config as notFoundRouteConfig} from 'routes/notFound';
import {usersUrlPatterns} from './urls';

export const config: RouteConfig = {
  path: usersUrlPatterns.users,
  component: withAuth(RegularLayout),
  routes: [
    {
      component: UserPage,
      exact: true,
      path: usersUrlPatterns.user,
    },
    {
      component: withPermission(UsersPage, Permission.USER_READ_ACTIVE),
      exact: true,
      path: usersUrlPatterns.users,
    },
    {
      component: withVpn(UserEditPage, NotFoundPage),
      exact: true,
      path: usersUrlPatterns.userEdit,
    },
    {
      component: withPermission(UserEditLegalInfoPage, Permission.USER_LEGAL_INFO_WRITE),
      exact: true,
      path: usersUrlPatterns.userEditLegalInfo,
    },
    {
      component: withPermission(UserByIdPage, Permission.USER_READ_ACTIVE),
      exact: true,
      path: usersUrlPatterns.userById,
    },
    {
      component: UserTimeOffBalancePage,
      exact: true,
      path: usersUrlPatterns.timeOffBalance,
    },
    notFoundRouteConfig,
  ],
};
