import {QueryRequestPayload} from 'api/types';
import {CountableResources} from 'domain/resource/api/getAll';
import {Resource, ResourceId, ResourcesSearchFilters} from 'domain/resource/model';
import {ResourceDiff} from 'domain/resource/model/diff';
import {createEffect} from 'effector';

export const createResourceFx = createEffect<ResourceDiff, Resource>('create a resource');
export const getResourceByIdFx = createEffect<ResourceId, Resource>('get resource by ID');
export const getResourcesFx = createEffect<QueryRequestPayload<ResourcesSearchFilters>, Resource[]>(
  'load filtered resources with pagination',
);
export const getResourcesWithCountFx = createEffect<QueryRequestPayload<ResourcesSearchFilters>, CountableResources>(
  'load filtered resources with pagination and count',
);
export const updateResourceFx = createEffect<{id: ResourceId; diff: ResourceDiff}, Resource>('update a resource');
