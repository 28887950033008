import {Panel} from '@joomcode/joom-ui/Panel';
import {CrResult} from 'domain/compaRatio/model';
import {CalculatableCrBadge} from 'domain/compaRatio/widgets/CalculatableBadge';
import {CrValue} from 'domain/compaRatio/widgets/Value';
import {DefaultErrorCr} from 'domain/compensationReviewRequest/widgets/DefaultErrorCr';
import {Hint} from 'domain/compensationReviewRequest/widgets/Hint';
import {SalaryDescription} from 'domain/compensationReviewRequest/widgets/SalaryDescription';
import {SalaryHeader} from 'domain/compensationReviewRequest/widgets/SalaryHeader';
import {SecurePermission} from 'domain/permission/model/secure';
import {SalaryRecord} from 'domain/salaryRecord/model';
import {FieldSalaryAmount} from 'domain/salaryRecord/widgets/FieldAmount';
import React, {useCallback, useState} from 'react';
import {useForm} from 'react-final-form';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import styles from './styles.css';

type Props = {
  salary: SalaryRecord;
  isOnlySalary: boolean;
};

export function CompensationReviewRequestFormSalary({salary, isOnlySalary}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {getState, change} = useForm();
  const {
    values: {salaries},
    submitting,
  } = getState();
  const canCalculateCr = acl.hasSecurePermission(SecurePermission.SALARY_RANGE_CALCULATE_CR);
  const [initialCr, setInitialCr] = useState<CrResult>();
  const [currentCr, setCurrentCr] = useState<CrResult>();
  const onCrCalculate = useCallback(
    (cr: CrResult) => {
      if (!initialCr) {
        setInitialCr(cr);
        change('oldCr', cr);
      }
      setCurrentCr(cr);
      change('newCr', cr);
    },
    [initialCr, setInitialCr, setCurrentCr],
  );
  const getValuesForCalculating = useCallback(() => {
    const {values} = getState();
    const {currency, frequency, region} = salary;
    const {levelCode, userFunction} = values.userInfo.general;
    const amount = values.salaries[salary.id]?.amount;

    if (!amount || !currency || !frequency || !levelCode || !userFunction || !region) {
      return undefined;
    }

    return {
      amount,
      currency,
      frequency,
      levelCode,
      userFunction,
      region,
    };
  }, [getState, salary]);

  return (
    <>
      <SalaryHeader salary={salary} />
      <Panel.Content withPadding>
        <div className={styles.salary}>
          <div className={styles.input}>
            <FieldSalaryAmount
              currency={salary.currency}
              initialValue={salary.amount}
              name={`salaries.${salary.id}.amount`}
              disabled={submitting}
              reserveSpaceForError={false}
              hint={
                salaries?.[salary.id]?.amount !== salary.amount ? (
                  <Hint value={intl.formatNumber(salary.amount)} />
                ) : undefined
              }
            />
          </div>
          {canCalculateCr && (
            <div className={styles.cr}>
              {isOnlySalary ? (
                <CalculatableCrBadge
                  fieldNames={[
                    'userInfo.general.levelCode',
                    'userInfo.general.userFunction',
                    `salaries.${salary.id}.amount`,
                  ]}
                  debouncedFieldNames={[`salaries.${salary.id}.amount`]}
                  getValuesForCalculating={getValuesForCalculating}
                  onCalculate={onCrCalculate}
                />
              ) : (
                <DefaultErrorCr />
              )}
              {initialCr?.value && currentCr?.value !== initialCr.value && (
                <Hint value={<CrValue cr={initialCr} withLabel coloredIcon />} withStyles />
              )}
            </div>
          )}
        </div>
        <SalaryDescription salary={salary} />
      </Panel.Content>
    </>
  );
}
