/* eslint-disable no-param-reassign */
import React from 'react';
import {Portal} from 'react-portal';
import {useFloating, offset, flip, size, autoUpdate, Placement} from '@floating-ui/react-dom';
import styles from './index.css';

export type {Placement} from '@floating-ui/react-dom';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  getContentContainerProps?: () => JSX.IntrinsicElements['div'];
  isOpen?: boolean;
  minWidth?: string;
  placement?: Placement;
};

/*
 * The max height is handpicked a way to show a last item partially. It needed
 * to mac users understand there are more items in a list under scroll.
 */
const maxHeight = '20.75rem';

/** Drowpdown offset from reference and from viewport */
const offsetValue = 5; // px

export function Dropdown({
  children,
  content,
  getContentContainerProps,
  isOpen,
  minWidth,
  placement = 'bottom-start',
}: Props) {
  const {refs, floatingStyles} = useFloating<HTMLElement>({
    placement,
    open: isOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetValue),
      flip({padding: offsetValue}),
      size({
        apply({rects, elements, availableHeight}) {
          Object.assign(elements.floating.style, {
            maxHeight: `min(${availableHeight}px, ${maxHeight})`,
            width: `${rects.reference.width}px`,
            minWidth,
          });
        },
        padding: offsetValue,
      }),
    ],
  });

  return (
    <div ref={refs.setReference}>
      {children}

      {isOpen && (
        <Portal>
          <div ref={refs.setFloating} className={styles.dropdown} style={floatingStyles}>
            <div {...getContentContainerProps?.()}>{content}</div>
          </div>
        </Portal>
      )}
    </div>
  );
}
