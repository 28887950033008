import {Button} from '@joomcode/joom-ui/Button';
import {ButtonGroup} from '@joomcode/joom-ui/ButtonGroup';
import {Dialog} from '@joomcode/joom-ui/Dialog';
import {createStockOptionAgreementFx} from 'domain/stockOption/agreement/stores/main';
import {FormState, StockOptionAgreementCreationForm} from 'domain/stockOption/agreement/widgets/CreationForm';
import {User} from 'domain/user/model';
import React, {useCallback, useMemo} from 'react';
import {Form, FormProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {toaster} from 'services/toaster';
import uuid from 'uuid/v4';
import {messages} from './messages';

type Props = {
  user: User;
  onClose(): void;
};

type FormSubmitHandler = FormProps<FormState>['onSubmit'];

export function StockOptionAgreementCreationDialog({user, onClose}: Props) {
  const intl = useIntl();
  const formId = useMemo(uuid, []);

  const onSubmit: FormSubmitHandler = useCallback(
    (state) =>
      createStockOptionAgreementFx({
        ...state,
        userId: user.id,
      })
        .then(() => {
          onClose();
          toaster.success(intl.formatMessage(messages.success, {email: user.email}));
        })
        .catch(toaster.interceptThenThrowError),
    [user, onClose],
  );

  return (
    <Dialog ariaLabel={intl.formatMessage(messages.ariaLabel)} isOpen onClose={onClose} width='min(90vw, 48rem)'>
      <Form<FormState> onSubmit={onSubmit}>
        {({handleSubmit, submitting, invalid}) => (
          <>
            <Dialog.Header>{intl.formatMessage(messages.title)}</Dialog.Header>
            <Dialog.Body withDefaultPadding>
              <StockOptionAgreementCreationForm formId={formId} onSubmit={handleSubmit} />
            </Dialog.Body>
            <Dialog.Footer>
              <ButtonGroup spaced size='l'>
                <Button kind='text' intent='neutral' onClick={onClose}>
                  {intl.formatMessage(messages.cancelButton)}
                </Button>
                <Button
                  form={formId}
                  kind='primary'
                  intent='primary'
                  type='submit'
                  disabled={invalid}
                  loading={submitting}
                >
                  {intl.formatMessage(messages.submitButton)}
                </Button>
              </ButtonGroup>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
}
