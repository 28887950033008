import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  canGrantAllRolesLabel: {
    defaultMessage: 'Can grant any role',
    description: 'Form field label',
  },
  canGrantRolesLabel: {
    defaultMessage: 'Can grant roles',
    description: 'Form field label',
  },
});
