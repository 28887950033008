import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'General',
    description: 'Compensation review request form: section title',
  },
});

export const labels = defineMessages({
  managerId: {
    defaultMessage: 'Manager',
    description: 'Compensation review request form: field label',
  },
  levelCode: {
    defaultMessage: 'Level',
    description: 'Compensation review request form: field label',
  },
});
