import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {ReactComponent as EnvelopeIcon} from '@joomcode/joom-ui/icons/core/envelope.svg';
import {SecurePermission} from 'domain/permission/model/secure';
import {StockOptionAgreement} from 'domain/stockOption/agreement/model';
import {sendStockOptionAgreementToHolderFx} from 'domain/stockOption/agreement/stores/main';
import {StockOptionAgreementActionButton} from 'domain/stockOption/agreement/widgets/ActionButton';
import {useUserLegalInfo} from 'domain/user/hooks/useUserLegalInfo';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  agreement: StockOptionAgreement;
};

export function SendToHolderButton({agreement}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const {data: legalInfoData} = useUserLegalInfo(agreement.user.id);
  const legalInfoEmpty = !legalInfoData?.legalInfo;

  const handleClick = useCallback(() => {
    const {id, user} = agreement;
    confirm(
      {
        title: intl.formatMessage(messages.confirmationTitle),
        text: intl.formatMessage(messages.confirmationText, {
          email: user.email,
        }),
        confirmationText: intl.formatMessage(messages.confirmationButton),
        intent: 'primary',
        onConfirm: () => {
          sendStockOptionAgreementToHolderFx({id, userId: user.id})
            .then(() => toaster.success(intl.formatMessage(messages.success, {email: user.email})))
            .catch(toaster.interceptThenThrowError);
        },
      },
      intl,
    );
  }, [intl, agreement]);

  if (!acl.hasSecurePermission(SecurePermission.STOCK_OPTION_SEND_AGREEMENT_TO_HOLDER)) {
    return null;
  }

  return (
    <StockOptionAgreementActionButton
      disabled={legalInfoEmpty}
      icon={<EnvelopeIcon />}
      hint={intl.formatMessage(legalInfoEmpty ? messages.noLegalInfoHint : messages.buttonHint)}
      onClick={handleClick}
    />
  );
}
