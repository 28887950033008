import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {DataState} from '@joomcode/deprecated-utils/dataState';
import {Office} from 'domain/officeMap/office/model';
import {OfficeAlias} from 'domain/officeMap/office/model/alias';
import {OfficeRoom, OfficeRoomId} from 'domain/officeMap/room/model';
import {createStore, Store} from 'effector';
import {bookRoomFx, loadAllOfficesFx, loadAllOfficesInBackgroundFx, updateSeatFx} from './index';

export type RoomBookingState = {
  dataState: DataState;
  link?: string;
};

type State = {
  officesByAlias: Partial<Record<OfficeAlias, Office>>;
  roomsById: Record<OfficeRoomId, OfficeRoom>;
  dataState: DataState;
  bookRoomState: Record<OfficeRoomId, RoomBookingState>;
};

type StateWithoutRoomIndex = Omit<State, 'roomsById'>;

export const $offices: Store<State> = createStore<StateWithoutRoomIndex>({
  officesByAlias: {},
  dataState: DataState.IDLE,
  bookRoomState: {},
})
  .on(loadAllOfficesFx, (state) => {
    return {
      ...state,
      dataState: DataState.LOADING,
    };
  })
  .on(loadAllOfficesFx.fail, (state) => {
    return {
      ...state,
      officesByAlias: {},
      dataState: DataState.FAILED,
    };
  })
  .on([loadAllOfficesFx.done, loadAllOfficesInBackgroundFx.done], (state, {result}) => {
    return {
      ...state,
      officesByAlias: arrayToObject(result, (office) => office.alias),
      dataState: DataState.LOADED,
    };
  })
  .on(bookRoomFx, (state, params) => {
    return {
      ...state,
      bookRoomState: {
        ...state.bookRoomState,
        [params.roomId]: {
          dataState: DataState.LOADING,
        },
      },
    };
  })
  .on(bookRoomFx.fail, (state, {params}) => {
    return {
      ...state,
      bookRoomState: {
        ...state.bookRoomState,
        [params.roomId]: {
          dataState: DataState.FAILED,
        },
      },
    };
  })
  .on(bookRoomFx.done, (state, {params, result: {room: newRoom, link}}) => {
    const alias = params.officeAlias;
    const newRooms = state.officesByAlias[alias]?.rooms.map(
      (room): OfficeRoom => (room.id === newRoom.id ? newRoom : room),
    );
    if (!newRooms) {
      return state;
    }
    return {
      ...state,
      officesByAlias: {
        ...state.officesByAlias,
        [alias]: {
          ...state.officesByAlias[alias],
          rooms: newRooms,
        },
      },
      bookRoomState: {
        ...state.bookRoomState,
        [params.roomId]: {
          dataState: DataState.LOADED,
          link,
        },
      },
    };
  })
  .on(updateSeatFx.done, (state, {result: newSeat}) => {
    const alias = newSeat.officeAlias;
    const oldOfficeData = state.officesByAlias[alias];
    if (!oldOfficeData) {
      return state;
    }
    return {
      ...state,
      officesByAlias: {
        ...state.officesByAlias,
        [alias]: {
          ...oldOfficeData,
          seats: oldOfficeData.seats.map((seat) => (seat.id === newSeat.id ? newSeat : seat)),
        },
      },
    };
  })
  .map((state) => ({
    ...state,
    roomsById: arrayToObject(
      Object.values(state.officesByAlias).flatMap((office) => office.rooms),
      (room) => room.id,
    ),
  }));
