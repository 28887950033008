import {DataState} from '@joomcode/deprecated-utils/dataState';
import {getEnumValues} from '@joomcode/deprecated-utils/ts-enum';
import {
  createAutocompleteMultiselectFilter,
  createSelectFilter,
  DataFilter,
  DataFilterConfig,
} from '@joomcode/joom-ui/DataFilter';
import {DataFilterSpinner} from 'components/ui/DataFilterSpinner';
import {ChangeValue} from 'domain/compensationReviewRequest/model/changeValue';
import {formatChangeValue} from 'domain/compensationReviewRequest/model/changeValue/formatter';
import {Filters} from 'domain/compensationReviewRequest/model/filters';
import {Status} from 'domain/compensationReviewRequest/model/status';
import {formatStatus} from 'domain/compensationReviewRequest/model/status/formatter';
import {useSelfUser} from 'domain/self/hooks/useSelfUser';
import {useAllUsers} from 'domain/user/hooks/useAllUsers';
import {useUsersFilter} from 'domain/user/hooks/useUsersFilter';
import {checkIsSubordinate} from 'domain/user/utils/checkIsSubordinate';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

type Props = {
  values: Filters;
  onChange(payload: Filters): void;
  onlySubordinates?: boolean;
};

export const CompensationReviewRequestsFilters = ({values, onChange, onlySubordinates}: Props) => {
  const intl = useIntl();
  const selfUser = useSelfUser();
  const {userById, dataState} = useAllUsers();
  const users = useMemo(() => {
    const allUsers = Object.values(userById);
    return onlySubordinates ? allUsers.filter(({id}) => checkIsSubordinate(selfUser, id, allUsers)) : allUsers;
  }, [userById, selfUser, onlySubordinates]);
  const usersFilter = useUsersFilter({
    label: intl.formatMessage(messages.userIds),
    users,
    userById,
  });

  const config = useMemo<DataFilterConfig<Filters>>(() => {
    return {
      userIds: usersFilter,
      statuses: createAutocompleteMultiselectFilter(intl.formatMessage(messages.statuses), {
        alwaysVisible: true,
        options: getEnumValues(Status).map((status) => ({
          label: formatStatus(status, intl),
          value: status,
        })),
      }),
      compensation: createSelectFilter(intl.formatMessage(messages.compensation), {
        alwaysVisible: true,
        options: getEnumValues(ChangeValue).map((value) => ({
          label: formatChangeValue(value, intl),
          value,
        })),
      }),
      stockOptions: createSelectFilter(intl.formatMessage(messages.stockOptions), {
        alwaysVisible: true,
        options: getEnumValues(ChangeValue).map((value) => ({
          label: formatChangeValue(value, intl),
          value,
        })),
      }),
    };
  }, [intl, usersFilter]);

  if (dataState === DataState.IDLE || dataState === DataState.LOADING) {
    return <DataFilterSpinner />;
  }

  return <DataFilter config={config} onChange={onChange} values={values} />;
};
