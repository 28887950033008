import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  dateLabel: {
    defaultMessage: 'Date',
    description: 'Salaries export by date dropdown: field label',
  },
  buttonCancel: {
    defaultMessage: 'Cancel',
    description: '[button] Salaries export by date dropdown',
  },
  buttonDownload: {
    defaultMessage: 'Download',
    description: '[button] Salaries export by date dropdown',
  },
});
