import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  hideEmployed: {
    defaultMessage: 'Hide employed',
    description: 'Offers filter label',
  },
  status: {
    defaultMessage: 'Status',
    description: 'Offers filter label',
  },
  division: {
    defaultMessage: 'Business unit',
    description: 'Offers filter label',
  },
  countries: {
    defaultMessage: 'Countries',
    description: 'Offers filter label',
  },
  countriesExclude: {
    defaultMessage: 'Exclude countries',
    description: 'Offers filter label',
  },
  loadingError: {
    defaultMessage: 'Failed to load filters data. Try to refresh the page.',
    description: 'Offer filters: data loading error',
  },
});
