import {Button} from '@joomcode/joom-ui/Button';
import {useRoleBindingActions} from 'domain/roleBinding/hooks/useRoleBindingActions';
import {RoleBinding} from 'domain/roleBinding/model';
import {UserId} from 'domain/user/model';
import React from 'react';
import styles from './styles.css';

type Props = {
  selfUserId?: UserId;
  roleBinding: RoleBinding;
  canManage?: boolean;
};

export function RoleBindingActions({roleBinding, selfUserId, canManage = false}: Props) {
  const {actions} = useRoleBindingActions(roleBinding, canManage, selfUserId);

  return actions.length > 0 ? (
    <div className={styles.root}>
      {actions.map(({action, buttonText, buttonKind, buttonIntent, callback}) => (
        <Button key={action} size='m' kind={buttonKind} intent={buttonIntent} onClick={() => callback(roleBinding.id)}>
          {buttonText}
        </Button>
      ))}
    </div>
  ) : null;
}
