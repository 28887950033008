import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {getAncestors} from '@joomcode/deprecated-utils/tree/getAncestors';
import {getDescendants} from '@joomcode/deprecated-utils/tree/getDescendants';
import cn from 'classnames';
import intersection from 'lodash/intersection';
import union from 'lodash/union';
import React, {useCallback, useMemo} from 'react';
import {ReactComponent as GearIcon} from '../../../icons/core/gear.svg';
import {Menu} from '../../../Menu';
import {TreeItem} from '../../../Select/TreeViewMultiSelect';
import {TreeView} from '../../../TreeView';
import {useTreeMultiSelect} from '../../../TreeView/useTreeMultiSelect';
import {Column, ColumnTreeOptions} from '../../types';
import styles from './styles.css';

type Props = {
  ariaLabel: string;
  availableColumns: Column<unknown>[];
  columnTreeOptions: ColumnTreeOptions;
  visibleColumnIds: string[];
  onColumnsVisibilityChange: (visibleColumns: string[]) => void;
};

export function ColumnsTreeSelect({
  ariaLabel,
  availableColumns,
  columnTreeOptions,
  visibleColumnIds,
  onColumnsVisibilityChange,
}: Props) {
  const {items, getItemChildren, getItemKey, getItemParent, renderItem} = columnTreeOptions;
  const ancestorsByItemKey = useMemo<Record<string, string[]>>(
    () => arrayToObject(items, getItemKey, (item) => getAncestors(item, getItemParent).map(getItemKey)),
    [items, getItemKey, getItemParent],
  );
  const descendantsByItemKey = useMemo<Record<string, string[]>>(
    () => arrayToObject(items, getItemKey, (item) => getDescendants(item, getItemChildren).map(getItemKey)),
    [items, getItemKey, getItemChildren],
  );
  const isItemDisabled = useCallback(
    (key: string) => {
      const isLastVisibleColumn = visibleColumnIds.length === 1 && visibleColumnIds[0] === key;
      const isLastVisibleGroupColumn = visibleColumnIds.every(
        (id) => key === id || descendantsByItemKey[key].includes(id),
      );
      const isDescendantOfLastVisibleGroupColumn = ancestorsByItemKey[key].some((id) =>
        visibleColumnIds.every((visibleId) => visibleId === id || descendantsByItemKey[id].includes(visibleId)),
      );
      const isSelfOrDescendantsHideable = availableColumns.some(
        ({id, hideable}) => hideable && (id === key || descendantsByItemKey[key].includes(id)),
      );

      return (
        isLastVisibleColumn ||
        isLastVisibleGroupColumn ||
        isDescendantOfLastVisibleGroupColumn ||
        !isSelfOrDescendantsHideable
      );
    },
    [availableColumns, visibleColumnIds, descendantsByItemKey],
  );
  const selection = useTreeMultiSelect({
    items,
    getItemKey,
    getItemChildren,
    getItemParent,
    isItemDisabled,
    isItemSelectedInitially: (id) => visibleColumnIds.includes(id),
    onlyLeaves: true,
  });

  const handleItemSelect = useCallback(
    (item: TreeItem) => {
      selection.selectItem(item);
      onColumnsVisibilityChange(union(visibleColumnIds, selection.getSelectedItems()));
    },
    [visibleColumnIds, onColumnsVisibilityChange, selection.selectItem, selection.getSelectedItems],
  );
  const handleItemDeselect = useCallback(
    (item: TreeItem) => {
      selection.deselectItem(item);
      onColumnsVisibilityChange(intersection(visibleColumnIds, selection.getSelectedItems()));
    },
    [visibleColumnIds, onColumnsVisibilityChange, selection.deselectItem, selection.getSelectedItems],
  );

  return (
    <Menu
      ariaLabel={ariaLabel}
      // eslint-disable-next-line react/no-unstable-nested-components
      disclosure={(props, {isOpen}) => (
        <button
          {...props}
          type='button'
          className={cn(styles.disclosure, {[styles.disclosureOpen]: isOpen})}
          aria-label={ariaLabel}
        >
          <GearIcon />
        </button>
      )}
    >
      <div>
        <TreeView
          items={items}
          expanded={false}
          getItemChildren={getItemChildren}
          getItemKey={getItemKey}
          getItemParent={getItemParent}
          isItemIndeterminate={selection.isItemIndeterminate}
          isItemDisabled={selection.isItemDisabled}
          isItemSelected={selection.isItemSelected}
          onItemDeselect={handleItemDeselect}
          onItemSelect={handleItemSelect}
          renderItem={renderItem}
          multiselect
        />
      </div>
    </Menu>
  );
}
