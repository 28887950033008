import {arrayToObject} from '@joomcode/deprecated-utils/array/toObject';
import {Autocomplete, AutocompleteProps} from '@joomcode/joom-ui/Select/Autocomplete';
import {Team} from 'domain/team/model';
import {TeamId} from 'domain/team/model/id';
import React, {useMemo, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {messages} from './messages';

const getItemKey = (id: TeamId) => id;

type TeamsProp = {
  teams: Team[];
};

export type Props = Omit<
  AutocompleteProps<TeamId>,
  'filter' | 'getItemKey' | 'noItemsText' | 'onInputValueChange' | 'renderItem' | 'itemToString'
> &
  TeamsProp;

const MAX_SYMBOLS_IN_TEAM = 56;

const stripStringifiedTeam = (stringifiedTeam: string): string =>
  stringifiedTeam.length <= MAX_SYMBOLS_IN_TEAM
    ? stringifiedTeam
    : `…${stringifiedTeam.slice(-MAX_SYMBOLS_IN_TEAM + 1)}`;

export function TeamSelect({teams, ...restProps}: Props) {
  const intl = useIntl();
  const teamById = useMemo(() => arrayToObject(teams, (team) => team.id), [teams]);

  const filter = useMemo(
    () => (teamId: TeamId, inputValue: string) => {
      const team = teamById[teamId];
      if (!team) return false;
      const words = inputValue
        .split(' ')
        .filter((s) => s)
        .map((s) => s.toLowerCase());

      return words.every((w) => team.name.toLowerCase().includes(w));
    },
    [teamById],
  );

  const stringifiedTeams: Record<TeamId, string> = useMemo(
    () =>
      arrayToObject(
        teams,
        ({id}) => id,
        (team) => {
          const teamWithAncestors = [team.name];
          let parentId = team.parentTeamId;
          while (parentId !== undefined) {
            const parent = teamById[parentId];
            if (!parent) break;
            teamWithAncestors.push(parent.name);
            parentId = parent.parentTeamId;
          }
          return teamWithAncestors.reverse().join(' → ');
        },
      ),
    [teams],
  );

  const itemToString = useCallback((teamId: TeamId) => (teamById[teamId] ? teamById[teamId].name : ''), [teamById]);
  const renderItem = useCallback(
    (teamId: TeamId) => stripStringifiedTeam(stringifiedTeams[teamId]),
    [stringifiedTeams],
  );
  const getItemTitle = useCallback((teamId: TeamId) => stringifiedTeams[teamId], [stringifiedTeams]);

  return (
    <Autocomplete<TeamId>
      getItemKey={getItemKey}
      itemToString={itemToString}
      getItemTitle={getItemTitle}
      filter={filter}
      noItemsText={intl.formatMessage(messages.noItems)}
      renderItem={renderItem}
      dropdownMinWidth='30rem'
      {...restProps}
    />
  );
}
