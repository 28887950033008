import {array} from '@joomcode/deprecated-utils/jsonValidation';
import {intranetApi} from 'apiClient';
import {TimeOffBalanceRecord, timeOffBalanceRecordSchema} from 'domain/timeOff/balanceRecord/model';
import {UserId} from 'domain/user/model';

export const getByUserId = async (userId: UserId): Promise<TimeOffBalanceRecord[]> => {
  const {data} = await intranetApi.get('/v1/timeOffs/balanceRecords/getByUserId', {params: {userId}});

  return array(timeOffBalanceRecordSchema).runWithException(data);
};
