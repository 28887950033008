import {isFunction} from '@joomcode/deprecated-utils/guards';
import cn from 'classnames';
import React, {type ComponentProps, type ComponentType} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import {
  type ConvertLocatorToTestId,
  createLocator,
  type Locator,
  type Mark,
  removeMarkFromProperties,
} from '../create-locator';
import {CloseButton, type CloseButtonLocator} from '../TagCloseButton';
import styles from './index.css';

export type TextareaTestId = ConvertLocatorToTestId<TextareaLocator>;
export type TextareaLocator = Locator<{input: void; clearButton: CloseButtonLocator}>;

type TextareaAutosizeProps = ComponentProps<typeof TextareaAutosize>;

// we need to update react-autosize-textarea, but the repo is archived
// https://github.com/ant-design/ant-design/issues/47886
type FixedTextareaAutosizeProps = Omit<Omit<TextareaAutosizeProps, 'onPointerEnterCapture'>, 'onPointerLeaveCapture'>;

export type TextareaProps = Omit<FixedTextareaAutosizeProps, 'className'> & {
  autoSize?: boolean;
  invalid?: boolean;
  onClear?: () => void;
} & Partial<Mark<TextareaLocator>>;

export const Textarea = React.forwardRef(function Textarea(
  {autoSize = true, invalid, onClear, ...propsWithMark}: TextareaProps,
  ref: React.Ref<HTMLTextAreaElement>,
) {
  const locator = createLocator(propsWithMark);
  const props = removeMarkFromProperties(propsWithMark);

  const showClear = isFunction(onClear);
  const className = cn(styles.textarea, {
    [styles.invalid]: invalid,
    [styles.clearShown]: showClear,
  });

  let input;

  if (autoSize) {
    input = (
      <TextareaAutosize
        {...props}
        {...locator.input()}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className={className}
        ref={ref}
      />
    );
  } else {
    // force type of onResizeProp, because react-autosize-textarea doesn't support React higher than 16
    input = (
      <textarea
        {...(props as React.InputHTMLAttributes<HTMLTextAreaElement>)}
        {...locator.input()}
        className={className}
        ref={ref}
      />
    );
  }

  return (
    <span className={styles.clearFix}>
      {input}
      {showClear ? (
        <span className={styles.clearButton}>
          <CloseButton onClick={onClear} {...locator.clearButton()} />
        </span>
      ) : null}
    </span>
  );
});

export const UnstyledTextarea: ComponentType<FixedTextareaAutosizeProps> = TextareaAutosize;
UnstyledTextarea.defaultProps = {
  rows: 3,
};
