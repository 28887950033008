import {validateFilled} from '@joomcode/joom-form';
import {FormControl} from '@joomcode/joom-ui/FormControl';
import {Grid} from '@joomcode/joom-ui/Grid';
import {CompanyUnitFormControl} from 'components/widgets/CompanyUnitFormControl';
import {FieldDate} from 'components/widgets/fields/FieldDate';
import {CompanyUnits} from 'domain/companyUnits/model';
import {JobInfoRecord} from 'domain/jobInfoRecord/model';
import {JobInfoRecordDiff} from 'domain/jobInfoRecord/model/diff';
import {FieldUser} from 'domain/user/widgets/Field';
import React from 'react';
import {FormRenderProps} from 'react-final-form';
import {useIntl} from 'react-intl';
import {labels} from './messages';

type Props = {
  activeRecord?: JobInfoRecord;
  companyUnits: CompanyUnits;
  formId: string;
  onSubmit: FormRenderProps<JobInfoRecordDiff>['handleSubmit'];
  recordToUpdate?: JobInfoRecord;
  submitting: boolean;
};

export function JobInfoRecordForm({activeRecord, companyUnits, formId, onSubmit, recordToUpdate, submitting}: Props) {
  const intl = useIntl();
  const initialValues = recordToUpdate ?? activeRecord;

  return (
    <form onSubmit={onSubmit} id={formId}>
      <Grid>
        <Grid.Item xl={6} xs={12}>
          <FieldDate
            name='effectiveDate'
            label={intl.formatMessage(labels.effectiveDate)}
            initialValue={recordToUpdate?.effectiveDate}
            required
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <CompanyUnitFormControl
            name='location'
            type='location'
            initialValue={initialValues?.location}
            disabled={submitting}
            items={companyUnits.locations}
            required
            validate={validateFilled}
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <CompanyUnitFormControl
            name='division'
            type='division'
            initialValue={initialValues?.division}
            disabled={submitting}
            items={companyUnits.divisions}
            required
            validate={validateFilled}
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <CompanyUnitFormControl
            name='department'
            type='department'
            initialValue={initialValues?.department}
            disabled={submitting}
            items={companyUnits.departments}
            required
            validate={validateFilled}
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <CompanyUnitFormControl
            name='jobTitle'
            type='jobTitle'
            initialValue={initialValues?.jobTitle}
            disabled={submitting}
            items={companyUnits.jobTitles}
            required
            validate={validateFilled}
          />
        </Grid.Item>
        <Grid.Item xl={6} xs={12}>
          <FormControl disabled={submitting} label={intl.formatMessage(labels.manager)}>
            {(formControlProps) => (
              <FieldUser
                disabled={submitting}
                name='managerId'
                initialValue={initialValues?.manager?.id}
                clearable
                {...formControlProps}
              />
            )}
          </FormControl>
        </Grid.Item>
      </Grid>
    </form>
  );
}
