import {confirm} from '@joomcode/joom-ui/ConfirmationDialog';
import {JobContract} from 'domain/jobContract/model';
import {JobContractRecord, JobContractRecordId} from 'domain/jobContract/model/record';
import {deleteJobContractRecordFx} from 'domain/jobContract/stores/main';
import {JobContractRecordsTable} from 'domain/jobContract/widgets/Table';
import {Permission} from 'domain/permission/model';
import {UserId} from 'domain/user/model';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useAcl} from 'services/acl';
import {toaster} from 'services/toaster';
import {messages} from './messages';

type Props = {
  jobContract: JobContract;
  onDialogOpenWithPayload(record: JobContractRecord): void;
  userId: UserId;
};

export function JobContractHistoryTab({jobContract, onDialogOpenWithPayload, userId}: Props) {
  const acl = useAcl();
  const intl = useIntl();
  const canEdit = acl.hasPermission(Permission.JOB_CONTRACT_WRITE);
  const legalEntityId = jobContract.legalEntity.id;
  const onDeleteRecordClick = useCallback(
    (id: JobContractRecordId) => {
      confirm(
        {
          title: intl.formatMessage(messages.deletionConfirmationTitle),
          text: intl.formatMessage(messages.deletionConfirmationText),
          confirmationText: intl.formatMessage(messages.deletionConfirmationButton),
          intent: 'negative',
          onConfirm: () =>
            deleteJobContractRecordFx({id, userId, legalEntityId}).catch(toaster.interceptThenThrowError),
        },
        intl,
      );
    },
    [intl, userId, legalEntityId],
  );

  return (
    <JobContractRecordsTable
      records={jobContract.jobContractRecords}
      {...(canEdit && {
        onDeleteItem: onDeleteRecordClick,
        onEditItem: onDialogOpenWithPayload,
      })}
    />
  );
}
