import {defineMessages} from 'react-intl';
import {StringValidatorErrorCode} from './types';

export const stringErrorMessages = defineMessages<StringValidatorErrorCode>({
  [StringValidatorErrorCode.STRING_INVALID]: {
    id: 'validator-string-invalid',
    description: 'Validation error about provided value is not a string (as expected)',
    defaultMessage: 'Invalid value',
  },
  [StringValidatorErrorCode.STRING_LENGTH_OUT_OF_RANGE]: {
    id: 'validator-string-length-out-of-range',
    description: 'Validation error about provided string’s length is less than min value or greater than max value',
    defaultMessage: 'String length is out of range, it must be between {minLength, number} and {maxLength, number}',
  },
  [StringValidatorErrorCode.STRING_LENGTH_LESS_MIN]: {
    id: 'validator-string-length-less-min',
    description: 'Validation error about provided string’s length is less than min',
    defaultMessage: 'String length must be greater than {minLength, number}',
  },
  [StringValidatorErrorCode.STRING_LENGTH_GREATER_MAX]: {
    id: 'validator-string-length-greater-max',
    description: 'Validation error about provided string’s length is greater than max value',
    defaultMessage: 'String length must be less than {maxLength, number}',
  },
  [StringValidatorErrorCode.STRING_REG_EXP_NOT_MATCH]: {
    id: 'validator-string-reg-exp-not-match',
    description: 'String validation error (string doesn’t match expected format)',
    defaultMessage: 'Invalid format',
  },
});
